import React from 'react';
import {CommunityCreating} from '../../components/Auth/CommunityCreating';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {useLinks} from '../../hooks/common';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';
import {useCommunities} from '../../hooks/community';
import {useViewer} from '../../hooks/user';
import {GQLCommunityWhereInput} from '../../graphql.schema';
import {CommunityTypes} from '../../helpers/community';
import {useAutoAddToCommunity} from '../../hooks/auth';

export const CommunityCreationConfirm = () => {
  const {getLink} = useLinks();
  const {push} = useHistory();
  const viewer = useViewer();
  const {addByKey} = useAutoAddToCommunity();
  const {data} = useCommunities({
    where: {
      AND: [
        {alias: {equalTo: viewer?.Location?.name?.replaceAll(' ', '-')?.replaceAll('.', '').toLowerCase()}},
        {type: {equalTo: CommunityTypes.neighbourhood}},
      ],
    } as GQLCommunityWhereInput,
    first: 1,
    skip: !viewer?.Location?.name,
  });
  const onCreate = () => push(getLink(route.communityNotFound.get({user: TypeCommunity.resident})));
  const onSkip = async () => {
    if (data?.[0]?.objectId) {
      await addByKey(data?.[0]?.objectId);
    }
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})));
  };
  return (
    <WrapperAuth step={3}>
      <CommunityCreating onCreateCommunity={onCreate} skipCreateCommunity={onSkip} />
    </WrapperAuth>
  );
};
