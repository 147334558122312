import {route} from '../../constants/routes';
import {AccountCheckListType} from '../../queries/types/checklist';
import {TypeCommunity} from '../../types/auth';

interface AccountCheckListItemType {
  dataKey: keyof AccountCheckListType;
  to?: string;
  roles: TypeCommunity[];
  isInactiveState?: (checklist: AccountCheckListType) => boolean | undefined;
}

type CheckListType = AccountCheckListItemType[];

export const AccountCheckList: CheckListType = [
  {
    dataKey: 'avatarSet',
    to: route.personalSettings.get(),
    roles: [TypeCommunity.manager, TypeCommunity.resident, TypeCommunity.admin],
  },
  {
    dataKey: 'phoneVerify',
    to: route.pageUser.get() + '?verify-user=true',
    roles: [TypeCommunity.manager, TypeCommunity.resident, TypeCommunity.admin],
  },
  {
    dataKey: 'communityProfileFill',
    to: route.communitySettings.get(),
    roles: [TypeCommunity.manager],
  },
  {
    dataKey: 'joinCommunity',
    to: route.searchCommunityResident.get(),
    roles: [TypeCommunity.resident, TypeCommunity.admin],
  },
  {
    dataKey: 'addListing',
    to: route.createItem.get(),
    roles: [TypeCommunity.manager, TypeCommunity.resident, TypeCommunity.admin],
    isInactiveState: (checklist) => {
      if (!checklist.joinCommunity.isChecked) return true;
    },
  },
  {
    dataKey: 'addPost',
    to: route.loop.get() + '?type=message',
    roles: [TypeCommunity.manager, TypeCommunity.resident, TypeCommunity.admin],
    isInactiveState: (checklist) => {
      if (!checklist.joinCommunity.isChecked) return true;
    },
  },
  {
    dataKey: 'makeOrder',
    to: route.listings.get(),
    roles: [TypeCommunity.resident, TypeCommunity.admin],
    isInactiveState: (checklist) => {
      if (!checklist.joinCommunity.isChecked) return true;
    },
  },
  {
    dataKey: 'inviteResident',
    to: route.inviteCommunity.get(),
    roles: [TypeCommunity.manager],
  },
];

export const listingsCheckListKeys = ['addListing', 'makeOrder'];
