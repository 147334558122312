import React from 'react';
import {SmallSoldLabel, SoldItemInfoWrapper, SoldItemLabelsWrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';

const SoldItemInfo: React.FC<{title?: string; price?: string}> = ({title, price}) => {
  const {t} = useTranslation();

  return (
    <SoldItemInfoWrapper>
      <Text size={18} color={'octonary'}>
        {title}
      </Text>
      <SoldItemLabelsWrapper>
        <SmallSoldLabel>{t('listings:card.labels.sold')}</SmallSoldLabel>
        <Text size={12} color={'septenary'} weight={'semi-bold'}>
          {price}
        </Text>
      </SoldItemLabelsWrapper>
    </SoldItemInfoWrapper>
  );
};

const MemoSoldItemInfo = React.memo(SoldItemInfo);
export {MemoSoldItemInfo};
