import React from 'react';
import {WrapperPageListings, WrapperSearch} from './styles';
import {TEvent} from '../../types/event';
import {CommunityStateType} from '../../states/community';
import {ListEventsWithPreview} from './ListEventsWithPreview';
import {TypeCommunity} from '../../types/auth';
import {SetEventsSearchParams} from '../../containers/Events/types';
import {Search} from './Search';
import {MobileSearch} from './MobileSearch';
import {route} from '../../constants/routes';
import {EmptyState} from '../EmptyState/EmptyState';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {LikeState} from '../../hooks/item';

interface IProps {
  community: CommunityStateType;
  ownEvents?: Partial<TEvent>[];
  loading?: boolean;
  allEvents?: Partial<TEvent>[];
  fetchDataAllEvents: () => void;
  hasMoreAllEvents: boolean;
  newEvents?: Partial<TEvent>[];
  fetchDataNewEvents: () => void;
  hasMoreNewEvents: boolean;
  onDelete?: (id?: string) => Promise<boolean>;
  setSearchParams?: SetEventsSearchParams;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  onUnPublish: (id?: string) => () => Promise<void>;
  onPublish: (id?: string) => () => Promise<void>;
  onLike?: (id: string) => void;
  liked?: LikeState;
}

export const PageManager: React.FC<IProps> = ({
  allEvents,
  hasMoreAllEvents,
  fetchDataAllEvents,
  newEvents,
  fetchDataNewEvents,
  hasMoreNewEvents,
  community,
  loading,
  setSearchParams,
  userId,
  reportActions,
  getSignValue,
  onDelete,
  onUnPublish,
  onPublish,
  liked,
  onLike,
}) => {
  const {getLink} = useLinks();
  return (
    <WrapperPageListings>
      <WrapperSearch>
        <Search setSearchParams={setSearchParams} />
        <MobileSearch setSearchParams={setSearchParams} />
      </WrapperSearch>

      {newEvents?.[0] && (
        <ListEventsWithPreview
          communityId={community?.id}
          events={newEvents}
          fetchDataEvents={fetchDataNewEvents}
          hasMoreEvents={hasMoreNewEvents}
          isPreview={true}
          typeUser={TypeCommunity.manager}
          loading={loading}
          userId={userId}
          onDelete={onDelete}
          reportActions={reportActions}
          getSignValue={getSignValue}
        />
      )}

      {!allEvents?.length && !newEvents?.length && !loading ? (
        <EmptyState />
      ) : (
        <ListEventsWithPreview
          communityId={community?.id}
          events={allEvents}
          fetchDataEvents={fetchDataAllEvents}
          hasMoreEvents={hasMoreAllEvents}
          isPreview={false}
          typeUser={TypeCommunity.manager}
          featuredDivided={true}
          loading={loading}
          onDelete={onDelete}
          userId={userId}
          reportActions={reportActions}
          getSignValue={getSignValue}
          isHideLabel={true}
          onUnPublish={onUnPublish}
          onPublish={onPublish}
          liked={liked}
          onLike={onLike}
        />
      )}
      <AddButtonPortal link={getLink(route.createEvent.path)} />
    </WrapperPageListings>
  );
};
