import {gql} from '@apollo/client';
import {AppFileFragment} from './file';
import MemberFragment from './people';

export const EventFragment = gql`
  fragment EventFragment on Event {
    id
    objectId
    createdAt
    updatedAt
    name
    descr
    rewardType
    startTime
    endTime
    locationDetails
    showAttendees
    maxAttendees
    allowGuests
    maxGuestsPerAttendee
    totalAttendees
    isPublished
    isDeleted
    totalViews
    openTo
    eventType
    isLike
    countLikes
    expiresDate
    Published {
      ... on Community {
        id
        objectId
      }
    }
    Attendees {
      ... on UserConnection {
        count
        edges {
          cursor
          node {
            objectId
            firstName
            firstLetter
            aptSuite
            onlineDate
            Avatar {
              ...AppFileFragment
            }
          }
        }
      }
    }
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Lister {
      ...MemberFragment
    }
  }

  ${MemberFragment}
  ${AppFileFragment}
`;

export const EventShortFragment = gql`
  fragment EventShortFragment on Event {
    id
    objectId
    createdAt
    updatedAt
    name
    descr
    rewardType
    startTime
    endTime
    locationDetails
    showAttendees
    maxAttendees
    allowGuests
    maxGuestsPerAttendee
    totalAttendees
    isPublished
    isDeleted
    totalViews
    openTo
    eventType
    isLike
    countLikes
    expiresDate
    Published {
      ... on Community {
        id
        objectId
      }
    }
    Attendees {
      ... on UserConnection {
        count
        edges {
          cursor
          node {
            objectId
            firstName
            firstLetter
            aptSuite
            onlineDate
            Avatar {
              ...AppFileFragment
            }
          }
        }
      }
    }
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Lister {
      ...MemberFragment
    }
  }

  ${MemberFragment}
  ${AppFileFragment}
`;
