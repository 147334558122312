import React from 'react';
import {RequestBoxProps} from './types';
import {MessageRequestButton, MessageRequestContainer, MessageTime} from './styles';
import {IconSvg} from '../../Icon/Svg';
import {format} from 'date-fns';

export const RequestBox: React.FC<RequestBoxProps> = ({requestText, onRequestClick, isOwner, isEnd, messageTime}) => {
  return (
    <MessageRequestContainer $isOwner={isOwner}>
      <MessageRequestButton onClick={onRequestClick}>
        <span>{requestText}</span>
        <IconSvg type={'chevrone-left'} />
      </MessageRequestButton>
      {isEnd && <MessageTime>{messageTime && format(new Date(messageTime), 'p')}</MessageTime>}
    </MessageRequestContainer>
  );
};
