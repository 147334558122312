import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Media} from '../../ui-kit/theme';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {MobTabMsgs, TContact} from '../../types/messages';
import {ContactListContent} from './ContactListContent';
import {
  DesktopButtonWrapper,
  HeaderSideCard,
  InnerBodyCard,
  InnerSideCard,
  SubTitleHeaderMsgList,
  TitleHeaderCard,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';

type TProps = {
  count?: number;
  contactsList?: TContact[] | null;
  setActiveContact: (user: TContact | null) => void;
  setActiveMobTab?: (tab: MobTabMsgs | null) => void;
  contactsLoading: boolean;
  activeContact?: TContact | null;
  setIsSearching: (value: boolean) => void;
  isSearching: boolean;
  showSearchButton?: boolean;
  handleSetSearch?: () => void;
};

export const ContactList: React.FC<TProps> = ({
  contactsList,
  contactsLoading,
  setActiveContact,
  setActiveMobTab,
  activeContact,
  count,
  setIsSearching,
  isSearching,
  showSearchButton,
  handleSetSearch,
}) => {
  const {t} = useTranslation();

  const handleSetSearching = () => setIsSearching(true);
  const [scrolling, setScrolling] = useState(false);
  return (
    <InnerSideCard>
      <HiddenElement mq={Media.down.m}>
        <HeaderSideCard>
          <TitleHeaderCard>{t('messages:messaging')}</TitleHeaderCard>
          {!!count && (
            <HiddenElement mq={Media.down.l}>
              <SubTitleHeaderMsgList>
                {count} {t('messages:messages')}
              </SubTitleHeaderMsgList>
            </HiddenElement>
          )}
          {!isSearching && (
            <DesktopButtonWrapper onClick={handleSetSearching}>
              <IconSvg type={'new-message'} viewBox={'0 3 26 26'} stroke={'gray'} />
            </DesktopButtonWrapper>
          )}
          {/*<Button type={'button'}>*/}
          {/*  <IconSvg type={'controls-alt'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'gray'} />*/}
          {/*</Button>*/}
        </HeaderSideCard>
      </HiddenElement>
      <InnerBodyCard>
        <ContactListContent
          contactsList={contactsList}
          setActiveContact={setActiveContact}
          setActiveMobTab={setActiveMobTab}
          contactsLoading={contactsLoading}
          activeContact={activeContact}
          setScrolling={setScrolling}
        />
      </InnerBodyCard>
      {showSearchButton && (
        <HiddenElement mq={Media.up.m}>
          <AddButtonPortal
            onClick={handleSetSearch}
            iconType={'send'}
            viewBox={'0 0 24 24'}
            animated
            animatedText={t('common:addButtons.new')}
            externalScrolling={scrolling}
            blockInterval={true}
          />
        </HiddenElement>
      )}
    </InnerSideCard>
  );
};
