import React, {useMemo} from 'react';
import {LinkBackWrapper, LinkText} from './styles';
import {useLinks} from '../../../hooks/common';
import {route} from '../../../constants/routes';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {useTranslation} from 'react-i18next';

type LinkBackProps = {
  hideText?: boolean;
  type: 'events' | 'amenities' | 'groups';
  onClick?: (e?: React.MouseEvent) => void;
  to?: string;
};

export const LinkBack: React.FC<LinkBackProps> = ({hideText, type, to, onClick}) => {
  const {getLink} = useLinks();
  const {t} = useTranslation();
  const links = useMemo(() => {
    return {
      events: getLink(route.events.get()),
      amenities: getLink(route.amenities.get()),
      groups: getLink(route.groups.get()),
    };
  }, [getLink]);

  return (
    <LinkBackWrapper onClick={onClick} to={to || links?.[type] || ''}>
      <IconSvg type={'chevrone-left'} />
      <LinkText>{!hideText && t(`${type}:backLink`)}</LinkText>
    </LinkBackWrapper>
  );
};
