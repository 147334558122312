import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {MemoNotInCommunity} from '../../components/common/NotInCommunity';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {UserPage} from './UserPage';
import {userPermissions} from '../../states/community';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {PermissionsList} from '../../types/settings';

import {isInCommunity, isInJoinRequests} from '../../hooks/community';
import {useNewViewer} from '../../hooks/notifications';
import {useViewer} from '../../hooks/user';

export const Profile = () => {
  const {id} = useParams<{id: string}>();
  const [typeU] = useRecoilState(typeUser);
  const isManager = typeU === TypeCommunity.manager;
  const [permissions] = useRecoilState(userPermissions);
  const canManage = useMemo(
    () => (permissions?.includes(PermissionsList.managePeople) && typeU === TypeCommunity.admin) || isManager,
    [permissions, typeU],
  );
  const user = useViewer();
  const createViewer = useNewViewer();
  const isOwner = user?.id === id || user?.objectId === id;
  const haveRequestToCommunity = isInJoinRequests(id);
  const data = isInCommunity(id);
  const showForManager = data?.inCommunity?.exist || (canManage && haveRequestToCommunity.exist);
  const showForResident = data?.inCommunity?.exist && typeU === TypeCommunity.resident;
  useEffect(() => {
    if (!isOwner) {
      createViewer(id, 'User').catch((e) => console.log(e));
    }
  }, [id]);

  if (!id) return null;

  if ((!data?.inCommunity && data?.loading) || !typeU || haveRequestToCommunity.loading) {
    return <LottieLoader allScreen={true} $isVisible={!data?.inCommunity} />;
  }
  if (showForManager || showForResident) {
    return <UserPage id={id} userType={typeU} isOwner={isOwner} />;
  }

  return <MemoNotInCommunity type={'user'} />;
};
