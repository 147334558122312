import React from 'react';
import {FileInput} from '../../../ui-kit/Form/ImageLoader/FileInput';
import {PhotoCard} from './PhotoCard';
import {AspectRatio, ResponsiveAspectRatio} from '../../../ui-kit/Responsive';
import {PointerFile} from '../../../types/common';

export type PhotoLoaderProps = {
  handleChange?: (next: any) => void;
  name?: string;
  dataId?: string;
  numberId?: number;
  src?: File | string | PointerFile;
  fallbackSrc?: string;
  aspectRatio?: AspectRatio | ResponsiveAspectRatio;
  className?: string;
  draggable?: boolean;
  onDragStart?: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragLeave?: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLLabelElement>) => void;
  onClick?: () => void;
  isUnderCard?: boolean;
  typeCursor?: string;
  width?: string;
  height?: string;
  bordered?: boolean;
  multiple?: boolean;
  showError?: boolean;
  CoverElement?: React.ReactNode;
  handleRemove?: (id: number) => void;
  isListing?: boolean;
};
export const PhotoLoader: React.FC<PhotoLoaderProps> = (props) => {
  return <FileInput label={PhotoCard} {...props} accept={'.jpg, .png, .jpeg, .webp, .svg, .gif, .bmp'} />;
};
