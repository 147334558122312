import {gql} from '@apollo/client';
import {Connection, Pointer, PointerInput} from './types/parse';
import {MessageFragment} from './fragments/message';
import {Message} from './types/message';
import {GQLChatPointerInput} from '../graphql.schema';
import {PointerFile} from '../types/common';

export interface CreateMessageResponseType {
  createMessage: {
    message: Message;
  };
}

export interface CreateMessageRequestType {
  fields: {
    Order?: Partial<PointerInput>;
    Author: Partial<PointerInput>;
    ShowTo: Pointer[];
    text: string;
    type?: string;
    Attachments?: (Partial<PointerFile> | undefined)[];
    Chat?: GQLChatPointerInput;
    localRef?: string;
  };
}

export const CreateMessage = gql`
  mutation createMessage($fields: CreateMessageFieldsInput) {
    createMessage(input: {fields: $fields}) {
      message {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export interface UpdateMessageResponseType {
  updateMessage: {
    message: Message;
  };
}

export interface UpdateMessageRequestType {
  fields: Message;
  id: string;
}

export const UpdateMessage = gql`
  mutation updateMessage($id: ID!, $fields: UpdateMessageFieldsInput) {
    updateMessage(input: {id: $id, fields: $fields}) {
      message {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export interface SetSeenMsgResponseType {
  messageSetSeen: boolean;
}

export interface SetSeenMsgRequestType {
  messageIds: string[];
}

export const SetSeenMessage = gql`
  mutation messageSetSeen($messageIds: [String]!) {
    messageSetSeen(input: {messageIds: $messageIds})
  }
`;

export interface GetMessagesWithUserResponse {
  messages: Connection<{
    objectId?: string;
    ShowTo?: {objectId?: string}[];
  }>;
}

export const getMessagesWithUser = gql`
  query getMessages($where: MessageWhereInput) {
    messages(where: $where) {
      edges {
        node {
          objectId
          ShowTo {
            ... on User {
              objectId
            }
          }
        }
      }
    }
  }
`;
