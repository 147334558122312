import {icon} from '../../../ui-kit/Icon/Svg/types';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {MenuItem} from '../../../ui-kit/Navigation/components/MenuItem';
import {
  BottomHighLightsWrapper,
  HideMob,
  HighlightExploreEventsWrapper,
  HighlightExploreListingsWrapper,
  HighlightHelpDeskWrapper,
  HighlightHomeWrapper,
  HighlightMessagesWrapper,
  HighlightPeopleWrapper,
  HighlightProfileWrapper,
  HighlightSwitchWrapper,
  HomeWrapperMob,
  MobMenuItemHighlight,
  SwitchText,
} from './styles';
import {User} from '../../../queries/types/user';
import {UserInfo} from '../../../ui-kit/Navigation/components/UserInfo';
import {getStrokeColor, NoticesIconWrapper} from '../../../ui-kit/Navigation/styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {MenuItemType} from '../../../ui-kit/Navigation/types';
import {MenuItemWrapperText} from '../../../ui-kit/Navigation/mobile/styles';
import {ttSteps} from './index';
import {HiddenElement} from '../Hidden/HiddenElement';
import {Media} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Text';

export type highlightsExists = {
  helpDeskAndMore?: boolean;
  events?: boolean;
  listings?: boolean;
  amenities?: boolean;
  people?: boolean;
  groups?: boolean;
  localBiz?: boolean;
  hasMultiplyCommunity?: boolean;
};

type highlightProps = {
  commName?: string;
  exList: highlightsExists;
};

const calcOffsetMenu = (exList: highlightsExists) => {
  let offset = 0;
  if (!exList.listings) ++offset;
  if (!exList.events) ++offset;
  if (!exList.amenities) ++offset;
  if (!exList.localBiz) ++offset;
  if (!exList.people) ++offset;
  if (!exList.groups) ++offset;
  return offset * 38;
};

export const HighlightHome: React.FC<highlightProps> = ({commName, exList}) => {
  const item = {
    content: commName,
    type: 'home-alt' as icon,
    to: '#',
    path: 'loop',
  };
  return (
    <>
      <HideMob>
        <HighlightHomeWrapper>
          <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
        </HighlightHomeWrapper>
      </HideMob>
      <MobBottomItems active={ttSteps.home} commName={commName} exList={exList} />
    </>
  );
};

export const HighlightPeople: React.FC<highlightProps> = ({exList}) => {
  const {t} = useTranslation();
  const item = {
    content: t('common:menu.people'),
    type: 'people' as icon,
    to: '#',
    path: '#',
  };
  const offsetTop = calcOffsetMenu({...exList, groups: false});
  return (
    <>
      <HideMob>
        <HighlightPeopleWrapper offset={offsetTop}>
          <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
        </HighlightPeopleWrapper>
      </HideMob>

      <MobBottomItems active={ttSteps.people} exList={exList} />
    </>
  );
};

export const HighlightExploreListings: React.FC<highlightProps> = ({exList}) => {
  const {t} = useTranslation();
  const item = {content: t('common:menu.listings'), type: 'Box' as icon, to: '#', path: '#'};
  return (
    <>
      <HideMob>
        <HighlightExploreListingsWrapper>
          <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
        </HighlightExploreListingsWrapper>
      </HideMob>

      <MobBottomItems active={ttSteps.explore} exList={exList} />
    </>
  );
};
export const HighlightExploreEvents: React.FC<highlightProps> = ({exList}) => {
  const {t} = useTranslation();
  const item = {content: t('common:menu.events'), type: 'calendar-2' as icon, to: '#', path: '#'};
  const offset = calcOffsetMenu({...exList, groups: false});
  return (
    <HighlightExploreEventsWrapper offset={offset}>
      <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
    </HighlightExploreEventsWrapper>
  );
};

export const HighlightMessages: React.FC<highlightProps> = ({exList}) => {
  const {t} = useTranslation();
  const item = {content: t('common:menu.messages'), type: 'chat' as icon, to: '#', path: '#'};
  const offset = calcOffsetMenu(exList);
  return (
    <>
      <HideMob>
        <HighlightMessagesWrapper offset={offset}>
          <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
        </HighlightMessagesWrapper>
      </HideMob>

      <MobBottomItems active={ttSteps.messages} exList={exList} />
    </>
  );
};

export const HighlightHelpDesk: React.FC<highlightProps> = ({exList}) => {
  const {t} = useTranslation();
  const item = {content: t('common:menu.helpDesk'), type: 'help' as icon, to: '#', path: '#'};
  return (
    <>
      <HideMob>
        <HighlightHelpDeskWrapper>
          <MenuItem variant={'web'} mode={'light'} $isOpenNav={true} currentMenuName={''} {...item} />
        </HighlightHelpDeskWrapper>
      </HideMob>

      <MobBottomItems active={ttSteps.helpDesk} exList={exList} />
    </>
  );
};

export const HighlightSwitch: React.FC<highlightProps> = ({commName}) => {
  const {t} = useTranslation();
  const text = t('common:menu.home');
  return (
    <>
      <HiddenElement mq={Media.down.l}>
        <HighlightSwitchWrapper>
          <IconSvg type={'home-alt'} stroke={'aqua'} width={'50px'} viewBox={'0 -3 32 32'} />
          <SwitchText>{commName}</SwitchText>
          <IconSvg type={'arrow-down'} viewBox={'2 0 10 10'} width={'50px'} />
        </HighlightSwitchWrapper>
      </HiddenElement>
      <HiddenElement mq={Media.up.l}>
        <HighlightSwitchWrapper>
          <HomeWrapperMob>
            <Text size={23}>{text}</Text>
            <IconSvg type={'arrow-down'} viewBox={'2 0 10 10'} width={'50px'} />
          </HomeWrapperMob>
          <Text size={14}>{commName}</Text>
        </HighlightSwitchWrapper>
      </HiddenElement>
    </>
  );
};

export const HighlightProfile: React.FC<{user: User}> = ({user}) => {
  const name = user?.firstName + ' ' + user?.lastName;

  return (
    <HighlightProfileWrapper>
      <UserInfo variant={'web'} homePathUser={'#'} name={name} avatar={user?.Avatar?.file?.url} />
    </HighlightProfileWrapper>
  );
};

const MobBottomItems: React.FC<{active: ttSteps; commName?: string; exList: highlightsExists}> = ({active, exList}) => {
  const {t} = useTranslation();
  const itemHome = {
    content: t('common:menu.home'),
    type: 'home-alt' as icon,
    to: '#',
    path: 'loop',
  };
  const itemPeople = {
    content: t('common:menu.people'),
    type: 'people' as icon,
    to: '#',
    path: '#',
  };
  const itemExplore = {
    content: t('common:menu.listings'),
    type: 'Box' as icon,
    to: '#',
    path: '#',
  };
  const itemMessages = {
    content: t('common:menu.inbox'),
    type: 'chat' as icon,
    to: '#',
    path: '#',
  };
  const itemHelpDesk = {content: t('common:menu.more'), type: 'hamburger' as icon, to: '#', path: '#'};
  return (
    <BottomHighLightsWrapper>
      <MobMenuItem item={itemHome} active={active === ttSteps.home} />
      <MobMenuItem item={itemPeople} active={active === ttSteps.people} />
      {(exList.events || exList.listings) && <MobMenuItem item={itemExplore} active={active === ttSteps.explore} />}
      <MobMenuItem item={itemMessages} active={active === ttSteps.messages} />
      {exList.helpDeskAndMore && <MobMenuItem item={itemHelpDesk} active={active === ttSteps.helpDesk} />}
    </BottomHighLightsWrapper>
  );
};

const MobMenuItem: React.FC<{item: MenuItemType; active?: boolean}> = ({item, active}) => {
  return (
    <MobMenuItemHighlight $active={active}>
      <MenuItemWrapperText>
        <NoticesIconWrapper>
          <IconSvg stroke={getStrokeColor('light')} type={item.type} />
        </NoticesIconWrapper>
        {item.content}
      </MenuItemWrapperText>
    </MobMenuItemHighlight>
  );
};
