import React from 'react';
import {NavMenu} from './NavMenu';
import {WrapperContent} from './styles';
import {NavigationProps} from './types';
import {useLocation} from 'react-router-dom';
import {NavHeader} from './NavHeader';
import {MobileMenu} from './mobile/MobileMenu';

export const Navigation: React.FC<NavigationProps> = ({
  isChecklistCompleted,
  activeStepsComp,
  closeProgressMenuHandler,
  openProgressMenuHandler,
  progressMenuOpen,
  progressMenuComponent,
  addMenuComponent,
  addMenuOpen,
  openAddMenuHandler,
  closeAddMenuHandler,
  children,
  plusIcon,
  className,
  alias,
  profMenuComponent,
  commMenuComponent,
  communityCount,
  navOffsets,
  goBack,
  checklistButtonName,
  communityLoading,
  onOpenSetting,
  isVisibleSettings,
  ...props
}) => {
  const pathIndex = alias ? 2 : 1;
  const currentPathName = useLocation().pathname.split('/')?.[pathIndex];
  const settingsPathName = useLocation().pathname.split('/')?.slice(pathIndex).join('/');
  const currentMenuName = (currentPathName === 'settings' ? settingsPathName : currentPathName) || '/';
  const setStateNav = props.setStateNav as React.Dispatch<React.SetStateAction<boolean>>;
  return (
    <>
      <NavHeader
        checklistButtonName={checklistButtonName}
        isChecklistCompleted={isChecklistCompleted}
        activeStepsComp={activeStepsComp}
        closeProgressMenuHandler={closeProgressMenuHandler}
        openProgressMenuHandler={openProgressMenuHandler}
        progressMenuOpen={progressMenuOpen}
        progressMenuComponent={progressMenuComponent}
        addMenuComponent={addMenuComponent}
        addMenuOpen={addMenuOpen}
        openAddMenuHandler={openAddMenuHandler}
        closeAddMenuHandler={closeAddMenuHandler}
        currentMenuName={currentMenuName}
        profMenuComponent={profMenuComponent}
        commMenuComponent={commMenuComponent}
        communityCount={communityCount}
        plusIcon={plusIcon}
        goBack={goBack}
        alias={alias}
        communityLoading={communityLoading}
        onOpenSetting={onOpenSetting}
        isVisibleSettings={isVisibleSettings}
        {...props}
        $isHidden={props.hideMobNavigation || props?.hideHeader}
      />
      <NavMenu {...props} $isOpenNav={props.$isOpenNav} setStateNav={setStateNav} currentMenuName={currentMenuName} />
      <MobileMenu {...props} currentMenuName={currentMenuName} $isHidden={props.hideMobNavigation} />
      <WrapperContent
        className={className}
        $isOpenNav={props.$isOpenNav}
        viewMode={props.viewMode}
        $hideNav={props.hideMobNavigation}
        navOffsets={navOffsets}>
        {children}
      </WrapperContent>
    </>
  );
};
