import React, {useEffect, useState} from 'react';
import {CommunityStateType} from '../../../states/community';
import {useLocation} from 'react-router-dom';
import {useItemPageContent, useManagerActionsItem, useResidentActionsItem} from '../../../hooks/item';
import {GQLButtonsManagerTypes, GQLButtonsResidentTypes, GQLItemWhereInput} from '../../../graphql.schema';
import {PageManager} from '../../../components/Listings/PageManager';
import {entities, useReport} from '../../../hooks/report';
import {useGetCurrencyValue} from '../../../hooks/currency';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
};

export const PageManagerContainer: React.FC<TProps> = ({viewerId, community}) => {
  const [searchParams, setSearchParams] = useState<GQLItemWhereInput[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const location = useLocation();
  const actions = useReport(entities.listing);
  const {getSignValue} = useGetCurrencyValue();
  const {
    loadingAllItems,
    refetchAllItems,
    hasMoreAllItems,
    fetchDataAllItems,
    communityItems,
    refetchAppAndFeat,
    approvedItems,
    featured,
    liked,
    onLike,
    featureLoading,
  } = useItemPageContent(searchParams, community);

  /*const {
    data: newItems,
    fetchData: fetchDataNewItems,
    hasMore: hasMoreNewItems,
    loading: loadingNewItems,
    refetch: refetchNewItems,
  } = useListItems({
    where: {
      AND: [
        {
          ApproveReq: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        {
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
    },
    first: 12,
    order: [GQLItemOrder.createdAt_DESC, GQLItemOrder.isSold_ASC],
  });*/

  const onSuccess = () => {
    refetchAppAndFeat();
    refetchAllItems();
  };

  const {onSubmit: onActionsItemResident, loading: loadActionResident} = useResidentActionsItem({
    communityId: community?.objectId,
    onSuccess,
  });
  const {onSubmit: onActionsItemManager, loading: loadActionManager} = useManagerActionsItem({
    communityId: community?.objectId,
    onSuccess,
  });

  useEffect(() => {
    if (!firstLoad) {
      refetchAllItems();
      setFirstLoad(false);
    }
    refetchAppAndFeat();
  }, [location]);

  const unlistItem = (itemId?: string) => () => {
    onActionsItemResident({itemId, typeBtn: GQLButtonsResidentTypes.unlist});
  };

  const onPublishItem = (itemId?: string) => () => {
    onActionsItemResident({itemId, typeBtn: GQLButtonsResidentTypes.publish});
  };

  const hideItem = (itemId?: string) => () => {
    onActionsItemManager({
      typeBtn: GQLButtonsManagerTypes.hide,
      itemId: itemId,
    });
  };

  const featureItem = (itemId?: string) => () => {
    onActionsItemManager({
      typeBtn: GQLButtonsManagerTypes.feature,
      itemId: itemId,
    });
  };

  const unFeatureItem = (itemId?: string) => () => {
    onActionsItemManager({
      typeBtn: GQLButtonsManagerTypes.unfeature,
      itemId: itemId,
    });
  };

  const approveItem = (itemId?: string) => () => {
    onActionsItemManager({
      typeBtn: GQLButtonsManagerTypes.approve,
      itemId: itemId,
    });
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityItems.length && refetchFeaturedItems({first: communityItems.length});
  //     communityItems.length && refetchAllItems({first: communityItems.length});
  //     newItems.length && refetchNewItems({first: newItems.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityItems.length, newItems.length]);
  const loading =
    !communityItems?.length || !featured?.length
      ? loadingAllItems || loadActionResident || loadActionManager || featureLoading
      : false;
  return (
    <PageManager
      community={community}
      loading={loading}
      allItems={communityItems}
      fetchDataAllItems={fetchDataAllItems}
      hasMoreAllItems={hasMoreAllItems}
      newItems={approvedItems}
      hideItem={hideItem}
      featureItem={featureItem}
      unFeatureItem={unFeatureItem}
      approveItem={approveItem}
      onUnlist={unlistItem}
      onPublish={onPublishItem}
      setSearchParams={setSearchParams}
      userId={viewerId}
      reportActions={actions}
      getSignValue={getSignValue}
      featured={featured}
      liked={liked}
      onLike={onLike}
    />
  );
};
