import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {NavContainer} from '../../containers/Navigation';
import {makeSeoKeywords} from '../../helpers/common';
import {useLinks} from '../../hooks/common';
import {TCommunity} from '../../types/community';
import {TCompany} from '../../types/company';
import {Box} from '../common/Box';
import {Linkify} from '../common/Buttons/Linkify';
import {RichText} from '../common/RichText/RichText';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {Seo} from '../SEO';
import {AsideCompanyInfo} from './AsideCompanyInfo';
import {EditFormAside} from './EditFormAside';
import {Slider} from './Slider';
import {
  DesktopView,
  InnerItemView,
  ItemViewDescr,
  ItemViewSubTitle,
  ItemViewTitle,
  Line,
  MainContItemView,
  MobileView,
  ViewCompanyPageWrapper,
  WrapperItemView,
} from './styles';
import {Gallery} from 'react-photoswipe-gallery';

interface IProps {
  item?: Partial<TCompany>;
  loading?: boolean;
  community?: Partial<TCommunity> | null;
  isManager?: boolean;
  managersCommunities?: number;
  viewerId: string;

  onUnpublish?: () => void;
  onPublish?: () => void;
  onDelete?: () => void;
  onCTA?: () => void;
}
const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};
export const ViewCompany: React.FC<IProps> = ({
  item,
  loading,
  isManager,
  managersCommunities,
  onUnpublish,
  onDelete,
  onPublish,
  viewerId,
  onCTA,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const textSeo = item?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  const itemOg = useMemo(() => {
    return {
      title: item?.name,
      description: item?.description,
      image: item?.images?.[0]?.file?.url,
    };
  }, [item]);
  const isInterested = useMemo(
    () => Boolean(item?.interestedUsers?.find((el) => el.id === viewerId)),
    [item, viewerId],
  );

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} og={itemOg} />
      <NavContainer viewMode={'hideNavOnMobile'} navOffsets={{remove: true}}>
        {loading ? (
          <ListingViewSkeleton />
        ) : (
          <ViewCompanyPageWrapper>
            <DesktopView>
              <MainContItemView>
                <WrapperItemView>
                  <InnerItemView>
                    <ItemViewTitle>
                      <Linkify>{item?.name}</Linkify>
                    </ItemViewTitle>
                    <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                      <Slider images={item?.images} />
                    </Gallery>
                    {isManager ? (
                      <EditFormAside
                        editPath={getLink(route.editBiz.get({id: item?.id as string}))}
                        isPublished={Boolean(item?.isPublished)}
                        manyCommunities={Boolean(managersCommunities)}
                        managePublishingPath={getLink(route.configureBizPublishing.get({id: item?.id as string}))}
                        onUnpublish={onUnpublish}
                        onPublish={onPublish}
                        onDelete={onDelete}
                        countViews={item?.countViews}
                        countClicks={item?.countClicks}
                        countCommunities={item?.Published?.length}
                      />
                    ) : (
                      <AsideCompanyInfo
                        name={(item as TCompany).name}
                        category={(item as TCompany).category}
                        promoDescr={(item as TCompany).promoDescr}
                        promoTag={(item as TCompany).promoTagline}
                        companyAvatar={(item as TCompany).images[0]?.file.url}
                        phone={(item as TCompany).phone}
                        typeCTA={(item as TCompany).promoCTA}
                        website={(item as TCompany).website}
                        revealCode={(item as TCompany).revealCode}
                        visitWebsite={(item as TCompany).visitedWebsite}
                        isPromo={(item as TCompany).promotion}
                        isInterested={isInterested}
                        onCTA={onCTA}
                      />
                    )}
                  </InnerItemView>
                </WrapperItemView>
              </MainContItemView>
              <MainContItemView>
                <WrapperItemView offsetTop={'0'}>
                  <InnerItemView>
                    <ItemViewSubTitle>{t('common:descr')}</ItemViewSubTitle>
                    <ItemViewDescr>
                      <RichText html={item?.description} />
                    </ItemViewDescr>
                  </InnerItemView>
                </WrapperItemView>
              </MainContItemView>
            </DesktopView>

            <MobileView>
              <MainContItemView>
                <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                  <Slider images={item?.images} />
                </Gallery>
                <WrapperItemView offsetTop={'16px'}>
                  <InnerItemView>
                    <ItemViewTitle>{item?.name}</ItemViewTitle>
                  </InnerItemView>
                </WrapperItemView>
              </MainContItemView>

              <Line />

              <MainContItemView>
                <WrapperItemView offsetTop={'16px'}>
                  <InnerItemView>
                    <ItemViewSubTitle>{t('common:descr')}</ItemViewSubTitle>

                    <ItemViewDescr>
                      <RichText html={item?.description} />
                    </ItemViewDescr>
                  </InnerItemView>

                  <Box padding={'16px'}>
                    {isManager ? (
                      <EditFormAside
                        editPath={getLink(route.editBiz.get({id: item?.id as string}))}
                        isPublished={Boolean(item?.isPublished)}
                        manyCommunities={Boolean(managersCommunities)}
                        managePublishingPath={getLink(route.configureBizPublishing.get({id: item?.id as string}))}
                        onUnpublish={onUnpublish}
                        onPublish={onPublish}
                        onDelete={onDelete}
                        countViews={item?.countViews}
                        countClicks={item?.countClicks}
                        countCommunities={item?.Published?.length}
                      />
                    ) : (
                      <AsideCompanyInfo
                        name={(item as TCompany).name}
                        category={(item as TCompany).category}
                        promoDescr={(item as TCompany).promoDescr}
                        promoTag={(item as TCompany).promoTagline}
                        companyAvatar={(item as TCompany).images[0]?.file.url}
                        phone={(item as TCompany).phone}
                        typeCTA={(item as TCompany).promoCTA}
                        website={(item as TCompany).website}
                        revealCode={(item as TCompany).revealCode}
                        visitWebsite={(item as TCompany).visitedWebsite}
                        isPromo={(item as TCompany).promotion}
                        isInterested={isInterested}
                        onCTA={onCTA}
                      />
                    )}
                  </Box>
                </WrapperItemView>
              </MainContItemView>
            </MobileView>
          </ViewCompanyPageWrapper>
        )}
      </NavContainer>
    </>
  );
};
