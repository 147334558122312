import {SITE_URL} from '../constants/env';
import {Connection} from '../queries/types/parse';
import {Referral} from '../queries/types/referral';
import {ReferralT} from '../types/referral';

export const createReferralLink = (referral?: string) => `${SITE_URL}/?ref=${referral}`;

export const referralsToState = (data?: Connection<Referral>, excludeId?: string): Partial<ReferralT>[] | undefined => {
  return (
    data?.edges
      ?.map((r) => ({...r?.node, Referrals: r?.node?.Referrals?.edges?.map((el) => ({...el.node}))}))
      ?.filter((el) => (excludeId ? el?.User?.objectId !== excludeId : true)) || undefined
  );
};

export const referralToState = (data?: Referral): Partial<ReferralT> | undefined => {
  return {...data, Referrals: data?.Referrals?.edges?.map((el) => ({...el.node}))};
};
