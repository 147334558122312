import React from 'react';
import {
  AvatarWrapper,
  ContentWrapper,
  ReviewContentWrapper,
  ReviewTextAreaWrapper,
  TextContainerEntry,
  UserDataReviewWrapper,
  ReviewStarsContainer,
  ContentWrapperFlex,
} from './styles';
import {CardReviewSectionProps, UserType, ReviewState, ItemType, ReviewInfo, RatingInfo, ReviewT} from './types';
import {Avatar} from '../Avatar/Avatar';
import {TextArea} from '../Form/TextArea/TextArea';
import {StarsSection} from './StarsSection';
import {getFullName} from './utils';

interface ReviewEntryProps {
  user?: UserType;
  item?: ItemType;
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

interface ReadyReviewEntryProps {
  user?: UserType;
  item?: ItemType;
  noReview: string;
  review: ReviewInfo;
  rating: RatingInfo;
  readyReviewsData?: ReviewT[] | null;
}

const ReviewEntry: React.FC<ReviewEntryProps> = ({user, item, placeholder, value, onChange}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value);
  if (user)
    return (
      <ReviewContentWrapper>
        <UserDataReviewWrapper>
          <AvatarWrapper>
            <Avatar size={'xxs'} src={user?.avatar} />
          </AvatarWrapper>
          <TextContainerEntry bold={true}>{getFullName(user)}</TextContainerEntry>
        </UserDataReviewWrapper>
        <ReviewTextAreaWrapper>
          <TextArea placeholder={placeholder} value={value} onChange={handleChange} />
        </ReviewTextAreaWrapper>
      </ReviewContentWrapper>
    );
  if (item)
    return (
      <ReviewContentWrapper>
        <UserDataReviewWrapper>
          <AvatarWrapper>
            <Avatar size={'xxs'} src={item?.avatar} />
          </AvatarWrapper>
          <TextContainerEntry bold={true}>{item?.name}</TextContainerEntry>
        </UserDataReviewWrapper>
        <ReviewTextAreaWrapper>
          <TextArea placeholder={placeholder} value={value} onChange={handleChange} />
        </ReviewTextAreaWrapper>
      </ReviewContentWrapper>
    );
  return null;
};

const ReadyReviewEntry: React.FC<ReadyReviewEntryProps> = ({
  user,
  noReview,
  item,
  rating,
  review,
  readyReviewsData,
}) => {
  const itemReview = readyReviewsData?.filter((rev) => rev.subject === 'item')?.[0];
  const userReview = readyReviewsData?.filter((rev) => rev.subject === 'user')?.[0];
  if (user)
    return (
      <ReviewContentWrapper offsetTop={10}>
        <UserDataReviewWrapper>
          <AvatarWrapper>
            <Avatar size={'xxs'} src={user.avatar} />
          </AvatarWrapper>
          <ContentWrapperFlex direction={'column'}>
            <ReviewStarsContainer>
              <StarsSection
                text={getFullName(user)}
                rating={userReview?.stars || rating.input.user.value || user.reviews?.rating || 0}
                small={true}
                notEditable={true}
              />
            </ReviewStarsContainer>
            <TextContainerEntry>
              {userReview?.text || review.input.user.value || user.reviews?.review || <em>{noReview}</em>}
            </TextContainerEntry>
          </ContentWrapperFlex>
        </UserDataReviewWrapper>
      </ReviewContentWrapper>
    );

  if (item)
    return (
      <ReviewContentWrapper>
        <UserDataReviewWrapper>
          <AvatarWrapper>
            <Avatar size={'xxs'} src={item.avatar} />
          </AvatarWrapper>
          <ContentWrapperFlex direction={'column'}>
            <ReviewStarsContainer>
              <StarsSection
                text={item?.name}
                rating={itemReview?.stars || rating.input.item.value || item.reviews?.rating || 0}
                small={true}
                notEditable={true}
              />
            </ReviewStarsContainer>
            <TextContainerEntry>
              {itemReview?.text || review.input.item.value || item.reviews?.review || <em>{noReview}</em>}
            </TextContainerEntry>
          </ContentWrapperFlex>
        </UserDataReviewWrapper>
      </ReviewContentWrapper>
    );

  return null;
};

export const CardReviewSection: React.FC<CardReviewSectionProps> = ({
  info,
  reviewState,
  review,
  rating,
  readyReviewsData,
  isRequester,
}) => {
  const [user, item] = info;
  return reviewState === ReviewState.Writing ? (
    <ContentWrapper>
      <TextContainerEntry>{review.title}</TextContainerEntry>
      <ReviewEntry
        user={user}
        placeholder={review.input.user.placeholder}
        value={review.input.user.value}
        onChange={review.input.user.onChange}
      />
      {isRequester && (
        <ReviewEntry
          item={item}
          placeholder={review.input.item.placeholder}
          value={review.input.item.value}
          onChange={review.input.item.onChange}
        />
      )}
    </ContentWrapper>
  ) : (
    <ContentWrapper>
      <ReadyReviewEntry
        user={user}
        review={review}
        rating={rating}
        readyReviewsData={readyReviewsData}
        noReview={review.noReview}
      />
      {isRequester && (
        <ReadyReviewEntry
          item={item}
          review={review}
          rating={rating}
          readyReviewsData={readyReviewsData}
          noReview={review.noReview}
        />
      )}
    </ContentWrapper>
  );
};
