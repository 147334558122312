import React, {useEffect} from 'react';
import {
  AddressTitle,
  AddressWrapper,
  ContentWrapper,
  ContentWrapperFlex,
  InputWrapper,
  SubtitleWrapper,
  NoteWrapper,
} from '../../../Auth/styles';
import {useTranslation} from 'react-i18next';
import {CleaveInput, Input} from '../../../../ui-kit/Form/Input';
import {Button} from '../../../../ui-kit/Button/Button';
import {Select} from '../../../../ui-kit/Form/Select';
import {useLocationOptions} from '../../../../hooks/locations';
import {AddressFormSchema} from '../../../Settings/schemas';
import {Profile} from '../../../../types/profile';
import {ErrorMessage} from '../../../Settings/components';
import {useChangeLocation, useUpdateProfileForm} from '../../../../hooks/settings';
import {Location} from '../../../../queries/types/locations';
import {Note} from '../../../../ui-kit/Note';

export interface AddressFormData {
  aptSuite: string;
  address: string;
  zip: string;
  Location: {
    link?: string;
  };
}

interface TProps {
  onSubmit: () => void;
  onCancel?: () => void;
  autoAddress?: boolean;
  communityZip?: string;
  communityAddress?: string;
  communityLocation?: Location;
  successLoading?: boolean;
}

export const AddressForm: React.FC<TProps> = ({
  onSubmit,
  autoAddress,
  communityAddress,
  communityLocation,
  communityZip,
  successLoading,
}) => {
  const {t} = useTranslation();
  const initialFromState =
    autoAddress && communityAddress && communityZip ? {address: communityAddress, zip: communityZip} : {};
  const form = useUpdateProfileForm<AddressFormData, Profile>({
    initialState: initialFromState,
    onSuccess: () => onSubmit(),
    validationSchema: AddressFormSchema,
  });
  // const [apartment, setIsApartment] = useRecoilState(isApartment);
  // const toggleApartment = () => setIsApartment((prev) => !prev);
  const {country, state, city, handleChangeDropdown, setDefoultCountry} = useChangeLocation(
    autoAddress ? communityLocation : undefined,
  );

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.onChange({name: e.target.name, value: e.target.value});
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.values.Location?.link) {
      form.values.Location = {link: city?.value};
    }
    form.onSubmit();
  };
  const {countries, states, cities} = useLocationOptions({
    country: country?.label,
    state: state?.label,
  });
  const addressOpen = {
    city: !!country.label && !!state.label,
    other: !!city.label,
  };

  useEffect(() => {
    const canada = countries?.find((item) => item.code === 'CA');
    if (canada) {
      setDefoultCountry(canada);
    }
  }, [countries?.length]);

  return (
    <AddressWrapper onSubmit={handleSubmit}>
      <ContentWrapper>
        <AddressTitle>{t('auth:address.title')}</AddressTitle>
      </ContentWrapper>
      <SubtitleWrapper>{t('auth:address.subtitle')}</SubtitleWrapper>
      <NoteWrapper>
        <Note iconType={'lock'} text={t('auth:address.note')} />
      </NoteWrapper>
      <ContentWrapperFlex>
        <InputWrapper>
          <Select
            values={country.label ? [country] : []}
            options={countries}
            onChange={handleChangeDropdown('country')}
            name={t('auth:address.country')}
            label={t('auth:address.country')}
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            values={state.label ? [state] : []}
            options={states}
            onChange={handleChangeDropdown('state')}
            name={t('auth:address.state')}
            label={t('auth:address.state')}
          />
        </InputWrapper>
      </ContentWrapperFlex>
      <ContentWrapperFlex>
        <InputWrapper>
          {addressOpen?.city && (
            <Select
              values={city.label ? [city] : []}
              options={cities}
              onChange={handleChangeDropdown('city')}
              name={t('auth:address.city')}
              label={t('auth:address.city')}
            />
          )}
        </InputWrapper>
        <InputWrapper>
          {addressOpen?.other && (
            <CleaveInput
              options={{
                blocks: [3, 3],
                uppercase: true,
              }}
              $isError={Boolean(form.error.zip)}
              name={'zip'}
              type={'text'}
              label={t('auth:address.zipCode')}
              value={form.values.zip}
              onChange={handleChange}
            />
          )}
        </InputWrapper>
      </ContentWrapperFlex>
      <ContentWrapperFlex>
        <InputWrapper>
          {addressOpen?.other && (
            <Input
              $isError={Boolean(form.error.address)}
              name={'address'}
              type={'text'}
              label={t('auth:address.street')}
              value={form.values.address}
              onChange={handleChange}
            />
          )}
        </InputWrapper>
        <InputWrapper>
          {addressOpen?.other && (
            <Input
              $isError={Boolean(form.error.aptSuite)}
              name={'aptSuite'}
              type={'text'}
              label={t('auth:address.house')}
              value={form.values.aptSuite}
              onChange={handleChange}
            />
          )}
        </InputWrapper>
      </ContentWrapperFlex>
      {/* <ContentWrapperFlex $isRow>
        <CheckButton onClick={toggleApartment} width={'88%'}>
          <IconSvg
            type={apartment ? 'checked-circle' : 'not-checked-circle'}
            stroke={apartment ? 'white' : 'gray'}
            fill={apartment ? 'aqua' : 'white'}
            width={'25px'}
            height={'25px'}
            viewBox={'0 0 30 30'}
          />
          <Text variant={'primary'} color={'quaternary'} size={14}>
            {t('auth:address.checkBox')}
          </Text>
        </CheckButton>
        <MemoInfoHint hintText={t('auth:address.checkBoxHint')} float={'right'} zIndex={1} />
      </ContentWrapperFlex> */}
      <ContentWrapper>
        <ErrorMessage error={form.error.message} />
      </ContentWrapper>
      <ContentWrapper>
        <Button variant={'primary'} type={'submit'} loading={form.loading || successLoading}>
          {t('auth:address.button')}
        </Button>
      </ContentWrapper>
    </AddressWrapper>
  );
};
