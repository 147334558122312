import React, {useState} from 'react';
import {TResCreateHelpDesk, TResEditHelpDesk} from '../../hooks/helpDesk';
import {FormWrapper, InnerItems, FormRow, FormActions, MobFocused} from './styles';
import {Input} from '../../ui-kit/Form/Input';
import {useTranslation} from 'react-i18next';
import {HelpDeskFields} from '../../types/helpDesk';
import {RichTextEditor} from '../common/RichText/Editor';
import {Select} from '../../ui-kit/Form/Select';
import {getAdminsOptions, getHelpDeskTypeOptions, HelpDeskCreateTypes} from '../../helpers/helpDesk';
import {ErrorMessage} from '../Auth/styles';
import {correctTranslate} from '../../helpers/common';
import {Button} from '../../ui-kit/Button/Button';
import {SelectOption} from '../../types/common';
import {EmojiTab} from '../common/EmojiTab/EmojiTab';
import {isDesktop} from 'react-device-detect';
import {useEditorRef} from '../../hooks/common';

type TOnChange = (next: {name: string; value: string}) => void;
type FormProps = {
  createData: TResCreateHelpDesk;
  editData: TResEditHelpDesk;
  close: () => void;
  refetch?: () => void;
  isEditing?: boolean;
};

export const Form: React.FC<FormProps> = ({createData, close, refetch, editData, isEditing}) => {
  const data = isEditing ? editData : createData;
  const {error, values, onChange, loading, onSubmit, reset, community} = data;
  const {t} = useTranslation();
  const [emoji, setEmoji] = useState<string>('');
  const {setRef} = useEditorRef({autoBlur: true});

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleSelect = (name: string) => (data: SelectOption<string>[]) => {
    const value = data[0]?.value;
    onChange({name: name, value: value || ''});
  };

  const typeOptions = getHelpDeskTypeOptions();
  const adminsOptions = getAdminsOptions(community);
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  const onCreate = () => {
    close();
    reset();
    refetch?.();
  };
  const isFAQ = values.type === HelpDeskCreateTypes.faq;
  const isLink = values.type === HelpDeskCreateTypes.link;
  const isMessage = values.type === HelpDeskCreateTypes.message;

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  return (
    <FormWrapper>
      <FormRow>
        <Input
          $isError={Boolean(error.title)}
          name={HelpDeskFields.title}
          value={values.title}
          onChange={handleChange}
          label={t('helpDesk:form.labels.title')}
        />
      </FormRow>
      <FormRow>
        <MobFocused $isDesktop={isDesktop}>
          <RichTextEditor
            $isError={Boolean(error.descr)}
            label={t('forms:descr')}
            name={HelpDeskFields.descr}
            value={values?.descr}
            onChange={onChange as TOnChange}
            rows={8}
            emoji={emoji}
            setEmoji={setEmoji}
            inputRef={setRef}
          />
          <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.descr} />
        </MobFocused>
      </FormRow>
      <FormRow>
        <InnerItems>
          <Select
            $isError={Boolean(error.type)}
            width={'100%'}
            name={HelpDeskFields.type}
            label={t('helpDesk:form.labels.type')}
            values={typeOptions?.filter((it) => it.value === values.type)}
            options={typeOptions}
            onChange={handleSelect(HelpDeskFields.type)}
          />
          {(isMessage || isLink) && (
            <Input
              $isError={Boolean(error.buttonTitle)}
              name={HelpDeskFields.buttonTitle}
              value={values.buttonTitle}
              onChange={handleChange}
              label={t('helpDesk:form.labels.buttonTitle')}
            />
          )}
        </InnerItems>
      </FormRow>
      <FormRow>
        {!isFAQ &&
          (isMessage ? (
            <Select
              $isError={Boolean(error.Assignee)}
              width={'100%'}
              name={HelpDeskFields.Assignee}
              label={t('helpDesk:form.labels.assigned')}
              values={adminsOptions?.filter((it) => it.value === values.Assignee)}
              options={adminsOptions}
              onChange={handleSelect(HelpDeskFields.Assignee)}
            />
          ) : isLink ? (
            <Input
              $isError={Boolean(error.assignTo)}
              name={HelpDeskFields.assignTo}
              value={values.assignTo}
              onChange={handleChange}
              label={t('helpDesk:form.labels.assignTo')}
            />
          ) : (
            <></>
          ))}
      </FormRow>
      <FormRow>
        <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
      </FormRow>
      <FormActions>
        <Button
          variant={'danger'}
          width={'200px'}
          onClick={() => {
            close();
            reset();
          }}>
          {t(`helpDesk:form.buttons.cancel`)}
        </Button>
        <Button width={'200px'} onClick={() => onSubmit(onCreate)} loading={loading} disabled={loading}>
          {t(`helpDesk:form.buttons.save`)}
        </Button>
      </FormActions>
    </FormWrapper>
  );
};
