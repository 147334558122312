import styled from 'styled-components';

import {Button} from '../../../ui-kit/Button/Button';
import {Input} from '../../../ui-kit/Form/Input';
import {Select} from '../../../ui-kit/Form/Select';
import {TransparentButton} from '../../../ui-kit/Modals/Confirmation/styles';
import {Media, theme} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Text';
import {AdditionalInfo, InfoPartWrapper} from '../commonStyles';

export const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

export const ContentWrapper = styled.div`
  max-width: 220px;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

export const WrapperFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobFocused = styled.div<{$isDesktop: boolean}>`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
  .rt-editor {
    padding-right: ${({$isDesktop}) => ($isDesktop ? '38px' : null)} !important;
  }

  ${Media.down.m} {
    & .rt-editor .DraftEditor-root {
      height: 100% !important;
      & .public-DraftEditor-content > div {
        height: 100%;
      }
    }
  }
`;

export const AliasInfo = styled(AdditionalInfo)`
  width: 100%;
  margin: 5px 0;
  padding: 3px 15px;
  color: ${({theme}) => theme.palette.text.duodecimal};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  .fieldset-box__input {
    color: ${({theme}) => theme.palette.text.duodecimal};
  }
`;

export const ModalAdressWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 30px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.primary};
  border: ${({theme}) => `1px solid ${theme.palette.text.hexadecimal}`};
  ${Media.down.s} {
    padding: 16px 21px 40px;
    top: initial;
    bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const Title = styled(Text)`
  ${Media.down.s} {
    display: inline-block;
    width: 250px;
  }
`;

export const Br = styled.br<{indent: number; mobileIndent?: number}>`
  display: block;
  content: '';
  margin-top: ${({indent}) => `${indent}px`};

  ${Media.down.s} {
    ${({mobileIndent}) => mobileIndent && `margin-top: ${mobileIndent}px`}
  }
`;

export const SettingsInfoWrapper = styled.div<{noGap?: boolean}>`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${({noGap}) => (!noGap ? '20px' : '0')};
  margin: 10px;
`;

export const SettingsInfoFormWrapper = styled(SettingsInfoWrapper)`
  margin: 20px 0 0;
  grid-row-gap: 20px;
`;

export const OkButton = styled(Button)`
  display: inline-flex;
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const OkButtonAddress = styled(Button)`
  display: inline-flex;
  border-radius: 25px;
  width: 46%;
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const CancelButton = styled(Button)`
  display: inline-flex;
  max-width: 120px;
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const InfoPartFormWrapper = styled(InfoPartWrapper)<{$columnMob?: boolean}>`
  margin-top: 20px;
  grid-gap: 20px;
  ${Media.down.m} {
    ${({$columnMob}) => $columnMob && ` grid-template-columns: 1fr;`}
  }
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  top: 25px;
  right: 25px;
  ${Media.down.s} {
    top: 18px;
    right: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const AddFieldStyles = styled.div`
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  background: ${theme.palette.grayscale.white};
  border: ${({theme}) => `1px solid ${theme.palette.text.hexadecimal}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;
  width: 100%;
  height: auto;
`;

export const ConfirmBtn = styled.button`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 14px;
  box-sizing: border-box;
  align-items: center;
  background-color: transparent;
  border: ${({theme}) => `2px solid ${theme.palette.text.octonary}`};
  color: ${({theme}) => theme.palette.text.octonary};
  display: flex;
  justify-content: center;
  padding: 5px;
  position: relative;
  width: 40%;
  height: 40px;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  border-radius: 25px;
  ${Media.down.m} {
    width: 100%;
  }
`;

export const AddFieldTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddFieldText = styled.div`
  width: 238px;
  font-size: 16px;
  font-family: ${({theme}) => theme.font.tertiary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.semiBold.fontWeight};
  font-style: normal;
  ${Media.down.m} {
    width: 180px;
  }
`;

export const InviteSectionWrapper = styled.div`
  margin: 20px 0 10px 0;
  width: 100%;
  position: relative;
`;
