import React from 'react';
import {useTranslation} from 'react-i18next';
import {CommunityStateType} from '../../states/community';
import {TypeCommunity} from '../../types/auth';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {Label} from '../../ui-kit/Labels';
import {CardWrapper, CheckboxWrapper, InfoWrapper, PersonName} from '../People/styles';
import {CommunityCardLink, CommunityPendingLabelWrapper} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';

interface CommunityLabelProps {
  community: CommunityStateType;
  icon: JSX.Element;
  count?: number;
  isCheckboxMode?: boolean;
  isChecked?: boolean;
  typeUser?: TypeCommunity;
  onChange?: (value: boolean) => void;
  isPublished?: boolean;
}

export const CommunityLabel: React.FC<CommunityLabelProps> = (props) => {
  const {community, isChecked, onChange, isCheckboxMode, isPublished, typeUser} = props;
  const {t} = useTranslation();

  const handleChange = () => {
    onChange?.(!isChecked);
  };

  return (
    <CommunityCardLink $isChecked={isChecked}>
      <CardWrapper>
        <Avatar src={community?.avatar} size={'sm'} $isOnline={false} />
        <InfoWrapper>
          <PersonName>{community?.name}</PersonName>

          {isCheckboxMode && (
            <CommunityPendingLabelWrapper>
              <Label squared={true} variant={'quaternary'}>
                {typeUser === TypeCommunity.manager
                  ? t('common:typeCommunity.admin')
                  : t('common:typeCommunity.manager')}
              </Label>
            </CommunityPendingLabelWrapper>
          )}

          {isPublished && (
            <CommunityPendingLabelWrapper>
              <Label squared={true} variant={'quinary'}>
                {t('events:created.published')}
              </Label>
            </CommunityPendingLabelWrapper>
          )}
        </InfoWrapper>
      </CardWrapper>

      {isCheckboxMode && onChange && (
        <CheckboxWrapper onClick={handleChange}>
          <IconSvg
            type={isChecked ? 'checked-circle' : 'not-checked-circle'}
            stroke={isChecked ? 'white' : 'gray'}
            fill={isChecked ? 'aqua' : 'white'}
            width={'25px'}
            height={'25px'}
            viewBox={'0 0 30 30'}
          />
        </CheckboxWrapper>
      )}
    </CommunityCardLink>
  );
};
