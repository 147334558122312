import React from 'react';

import amex from './assets/amex.png';
import back from './assets/back.png';
import bell from './assets/bell.png';
import helloPrimary from './assets/Hello-primary.png';
import helloYellow from './assets/Hello-yellow.png';
import mastercard from './assets/mastercard.png';
import visa from './assets/visa.png';
import yogaRelax from './assets/Yoga-relax.png';
import hello from './assets/Hello.png';
import socialDistancing from './assets/Social-Distancing.png';
import videoCall from './assets/Video-Call.png';
import videoCall2 from './assets/Video-Call2.png';
import celebrate from './assets/Music-Celebrate.png';
import landscape from './assets/Landscape-Weather.png';
import appStore from './assets/appStore.png';
import googlePlay from './assets/googlePlay.png';
import music from './assets/Music-Soulful.png';

import {Icon as SIcon} from './styles';
import {StyledIconProps} from './types';

const getSrc = (type?: string) => {
  switch (type) {
    case 'bell':
      return bell;
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'amex':
      return amex;
    case 'back':
      return back;
    case 'music':
      return music;
    case 'hello-primary':
      return helloPrimary;
    case 'hello-yellow':
      return helloYellow;
    case 'yoga-relax':
      return yogaRelax;
    case 'hello':
      return hello;
    case 'socialDistancing':
      return socialDistancing;
    case 'videoCall':
      return videoCall;
    case 'videoCall2':
      return videoCall2;
    case 'celebrate':
      return celebrate;
    case 'landscape':
      return landscape;
    case 'googlePlay':
      return googlePlay;
    case 'appStore':
      return appStore;
    default:
      return '';
  }
};

export const IconImage: React.FC<StyledIconProps> = ({className, type, alt, width, height}) => {
  if (!type) return null;
  return <SIcon className={className} src={getSrc(type)} width={width} height={height} alt={alt || 'Image'} />;
};
