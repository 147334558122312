import styled from 'styled-components';
import {Media, theme} from '../../../ui-kit/theme';
import {fontPrimaryReg} from '../styles';

type Error = {$isError?: boolean};

export const EditorWrapper = styled.div<{
  width?: string;
  $rows?: number;
  $isError?: boolean;
  $loading?: boolean;
  $disabled?: boolean;
}>`
  background: ${theme.palette.background.primary};
  &,
  & .rt-wrapper {
    height: ${({$rows}) => ($rows || 4) * 20}px;
    max-height: ${({$rows}) => ($rows || 4) * 20}px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: none;
    position: relative;
    z-index: 0;
    border-radius: 12px;
    padding: 0;
    border: none;
    width: ${({width}) => width || '100%'};
    transition: 0.2s all;
    ${({$loading}) => $loading && ` opacity: 0.4; pointer-events: none;`};
    ${({$disabled}) => $disabled && ` opacity: 0.7; pointer-events: none;`};
  }
  & {
    border: ${({$isError, theme}) =>
      $isError ? `2px solid ${theme.palette.common.red}` : `1px solid ${theme.palette.common.gray}`};
  }
  & .rt-editor {
    ${({theme}) => theme.font.tertiary.regular};
    position: absolute;
    left: 0;
    margin: 0;
    top: 0;
    width: 100%;
    background-color: transparent;
    border-radius: 12px;
    border: none;
    box-sizing: border-box;
    display: block;
    padding: 16px;
    font-size: 14px;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.quinary};
    cursor: text;
    ${Media.down.m} {
      font-size: 12px;
    }
    &:disabled {
      color: #848c9d;
    }
    & .public-DraftStyleDefault-block {
      margin: 0;
    }
    & .DraftEditor-root {
      height: auto;
      font-size: 14px;
    }
    & a {
      color: #0097b8;
    }
    & .rdw-link-decorator-icon {
      display: none;
    }
  }
`;

export const Label = styled.label<Error>`
  position: absolute;
  background-color: ${({theme}) => theme.palette.background.primary};
  top: -7px;
  left: 7px;
  ${theme.font.tertiary.regular};
  color: ${({$isError, theme}) => ($isError ? theme.palette.common.red : 'rgba(0, 0, 0, 0.5)')};
  font-size: 12px;
  white-space: nowrap;
  padding: 3px 4px;
  box-sizing: border-box;
`;

export const EmojiAction = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 6px;
  outline: none;
`;

// export const ShowMore = styled(ShowMoreText)`
//   ${fontPrimaryReg};
//   line-height: 24px;
//
//   .showMore {
//     font-size: inherit;
//     font-family: inherit;
//     line-height: inherit;
//     white-space: nowrap;
//     font-weight: 500;
//     color: inherit;
//   }
// `;

export const RichTextLinkify = styled.div<{$showMore?: boolean}>`
  ${fontPrimaryReg};
  line-height: 24px;
  white-space: pre-wrap;
  & a {
    color: ${({theme}) => theme.palette.common.aqua};
  }
`;

export const RichTextLinkifyExpanded = styled.div<{$showMore?: boolean; $isForProfile?: boolean}>`
  ${fontPrimaryReg};
  line-height: 21px;
  white-space: normal;
  color: ${({theme, $isForProfile}) => $isForProfile && theme.palette.text.quinary};
  ${($isForProfile) => $isForProfile && 'font-family: Roboto, sans-serif;'}
  font-family: Roboto, sans-serif;
  word-break: break-word;
  & a {
    color: ${({theme}) => theme.palette.common.aqua};
  }
  & p {
    margin-block-start: 2px;
    margin-block-end: 2px;
    ${Media.down.m} {
      margin-block-start: 3px;
      margin-block-end: 2px;
    }
  }
  br {
    line-height: 16px;
  }
`;

export const RichTextDefault = styled.div`
  ${fontPrimaryReg};
  line-height: 24px;
  white-space: normal;
`;

export const RichTextDescription = styled.div`
  ${fontPrimaryReg};
  line-height: 24px;
  & p {
    display: inline;
    margin: 0;
  }
`;
