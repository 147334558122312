import {gql} from '@apollo/client';
import MemberFragment from './people';
import {ItemFragment} from './item';
import {EventFragment} from './event';
import {AmenityFragment} from './amenity';

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    Community {
      objectId
      name
      type
    }
    Item {
      ...ItemFragment
    }
    Event {
      ...EventFragment
    }
    Amenity {
      ...AmenityFragment
    }
    Lister {
      ...MemberFragment
    }
    Requester {
      ...MemberFragment
    }
    Currency {
      code
    }
    createdAt
    endTime
    id
    objectId
    startTime
    status
    oldStatus
    updatedAt
    period
    rewardType
    listingType
    price
    pricePeriod
    cfListerAmt
    cfRequesterAmt
    listerTotalAmt
    payMethod
    orderType
    attendeeGuests
    recurringEvent
    hangehDiscount
  }
  ${MemberFragment}
  ${ItemFragment}
  ${EventFragment}
  ${AmenityFragment}
`;

export const ShortOrderFragment = gql`
  fragment ShortOrderFragment on Order {
    Community {
      objectId
      name
      type
    }
    Item {
      objectId
    }
    Event {
      objectId
    }
    Amenity {
      objectId
    }
    Lister {
      objectId
    }
    Requester {
      objectId
    }
    Currency {
      code
    }
    createdAt
    endTime
    id
    objectId
    startTime
    status
    period
    orderType
    attendeeGuests
    recurringEvent
  }
`;
