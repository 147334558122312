import {Connection} from './types/parse';
import {gql} from '@apollo/client';
import {CurrencyFragment} from './fragments/currency';
import {Currency} from './types/currency';
import {PaymentPlan} from './types/payments';

export type CurrenciesResponse = {
  currencies: Connection<Currency>;
};

export const GetCurrenciesRequest = gql`
  query currencies($cursor: String, $first: Int, $where: CurrencyWhereInput, $order: [CurrencyOrder!]) {
    currencies(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...CurrencyFragment
        }
      }
    }
  }

  ${CurrencyFragment}
`;

export interface TGetCurrencyByCountryResponse {
  paymentPlans: Connection<PaymentPlan>;
}

export interface TGetCurrencyByCountryRequest {
  countryId: string;
}

export const GetCurrencyByCountryQuery = gql`
  query getPaymentPlan($countryId: ID!) {
    paymentPlans(
      where: {
        AND: [
          {Location: {have: {objectId: {equalTo: $countryId}}}}
          {Currency: {exists: true}}
          {status: {equalTo: "active"}}
        ]
      }
      first: 1
    ) {
      edges {
        node {
          id
          objectId
          Currency {
            ...CurrencyFragment
          }
        }
      }
    }
  }
  ${CurrencyFragment}
`;
