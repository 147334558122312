import {gql} from '@apollo/client';
import userFragment from './user';

export default gql`
  fragment SessionFragment on Viewer {
    sessionToken
    user {
      ...UserFragment
    }
  }
  ${userFragment}
`;
