// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import {StyledProps} from './types';
import {ReactDatePickerProps} from 'react-datepicker';
import {DatePicker, InnerDataPiker} from './styles';
import {Input} from './Input';

export const DatePickerInput: React.FC<StyledProps & ReactDatePickerProps & {icon?: React.ReactNode}> = (props) => {
  return (
    <InnerDataPiker>
      <DatePicker {...props} customInput={<Input {...props} />} />
    </InnerDataPiker>
  );
};
