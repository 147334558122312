import React from 'react';
import {Input} from './Input';
import {HTMLInputProps} from './types';
import {MinimalInputWrapper, SearchButtonWrapper} from './styles';
import {IconSvg} from '../../Icon/Svg';

interface MinimalSearchInputProps extends HTMLInputProps {
  onSearch?: (text?: string) => void;
}

export const MinimalSearchInput: React.FC<MinimalSearchInputProps> = ({onSearch, ...props}) => {
  const handleSearch = () => onSearch?.(props.value as string);
  const handleSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSearch) {
      handleSearch();
    }
  };

  return (
    <MinimalInputWrapper>
      <Input {...props} onKeyPress={handleSearchEnter} />
      <SearchButtonWrapper onClick={handleSearch}>
        <IconSvg type="search" stroke="white" fill="white" width="16px" height="16px" viewBox="-1 -1 25 25" />
      </SearchButtonWrapper>
    </MinimalInputWrapper>
  );
};
