import React, {useState} from 'react';
import {CardDate, CardDatesWrapperGhost, CardDateTitle, CardDateWrapper, DatePickerWrapper} from '../styles';
import {DateLabelProps} from '../types';
import {DateRangePicker} from '../../Search/inputs/DateRangePicker';
import {format} from 'date-fns';
import {Popup} from '../../Popup';
import {PickerWrapperWithBtnsWrapper} from './DateTimePicker';

export const DateLabel: React.FC<DateLabelProps> = ({
  dates,
  setDates,
  canEdit,
  placeholder,
  small,
  captions,
  buttonSelectText,
  busyDates,
  pricePeriod,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const closePopup = () => {
    setIsEditing(false);
  };
  const handleEditStart = () => {
    // if (!accessRequest) return showWarnPopup?.();
    if (!canEdit) return;
    setIsEditing(true);
  };
  const handleEditStop = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsEditing(false);
  };
  const [fromDate, untilDate] = dates;
  const canSelect = !!fromDate || !!untilDate;
  const strDots = (str?: string) => `${str}`;
  return (
    <CardDatesWrapperGhost onClick={handleEditStart} tabIndex={-1} zIndex={200}>
      <CardDateWrapper $independent>
        <CardDateTitle>{fromDate ? placeholder?.start : strDots(placeholder?.selectStart)}</CardDateTitle>
        <CardDate>{fromDate ? format(fromDate, 'MM/dd/yyyy') : ''}</CardDate>
      </CardDateWrapper>
      <CardDateWrapper $independent $ml>
        <CardDateTitle>{untilDate ? placeholder?.end : strDots(placeholder?.selectEnd)}</CardDateTitle>
        <CardDate>{untilDate ? format(untilDate, 'MM/dd/yyyy') : ''}</CardDate>
      </CardDateWrapper>
      <DatePickerWrapper zIndex={1001}>
        <Popup isOpen={isEditing} onClose={closePopup} mobileCentered={true}>
          {setDates && (
            <PickerWrapperWithBtnsWrapper
              dateCaption={captions?.dateDuration}
              onClose={handleEditStop}
              onSelect={handleEditStop}
              textButton={buttonSelectText}
              isReady={canSelect}>
              <DateRangePicker
                values={dates}
                setValues={setDates}
                excludeDates={busyDates}
                pricePeriod={pricePeriod}
                size={small && 'small'}
              />
            </PickerWrapperWithBtnsWrapper>
          )}
        </Popup>
      </DatePickerWrapper>
    </CardDatesWrapperGhost>
  );
};
