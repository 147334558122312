import React, {useEffect} from 'react';
import {useUpdateUser, useViewer, useViewerId} from '../../hooks/user';
import {useNewViewer} from '../../hooks/notifications';
import {useCheckItemForAvailability, useItemLike} from '../../hooks/item';
import {ListingType, RewardType} from '../../queries/types/item';
import {getDatesArray, getHours, getMonths, getWeeks} from '../../helpers/dates';
import {useViewerCards} from '../../hooks/payments';
import {useCreateMessage} from '../../hooks/message';
import {formatDataInvoice, TDataLister, useCreateOrder} from '../../hooks/order';
import {ViewListing} from '../../components/Listings/View';
import {ItemFormAction, TItem} from '../../types/item';
import {CommunityStateType} from '../../states/community';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {typeUser} from '../../states/typeUser';
import {userState} from '../../states';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';
import {SearchKey} from '../../constants/routes';
import {analyticsTrackFN} from '../../helpers/account';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useLocation} from 'react-router-dom';
import {format} from 'date-fns';
import {useGetUserCredits} from '../../hooks/referral';

type TProps = {
  id?: string;
  community: CommunityStateType;
  dataLister: TDataLister;
  loading?: boolean;
  item: Partial<TItem>;
  manyCommunities: boolean;
  commList?: {objectId?: string; name?: string}[];
};

const getPricePerion = (pricePeriod: string, startTime: Date, endTime: Date) => {
  let period: (number | Date)[] = [];
  switch (pricePeriod) {
    case 'day':
      period = getDatesArray(new Date(startTime), new Date(endTime));
      break;
    case 'hour':
      period = getHours(new Date(startTime), new Date(endTime));
      break;
    case 'week':
      period = getWeeks(new Date(startTime), new Date(endTime));
      break;
    case 'month':
      period = getMonths(new Date(startTime), new Date(endTime));
      break;
    default:
      period = [1];
      break;
  }
  return period?.length;
};

export const ViewItem: React.FC<TProps> = ({community, dataLister, id, loading, item, manyCommunities, commList}) => {
  const viewerObjId = useViewerId('objectId');
  const urlSearch = useUrlSearchManager();
  const isPreview = urlSearch.get(SearchKey.preview) === 'true';
  const createViewer = useNewViewer(isPreview);
  const {handleLike} = useItemLike(item?.objectId);
  const [type] = useRecoilState(typeUser);
  const {available, dates} = useCheckItemForAvailability({
    itemId: id,
    strictCheck: item?.listingType === ListingType.sell,
  });
  const {currency} = useGetCurrencyValue();
  const {state} = useLocation();
  const busyDates = dates?.map((el) => getDatesArray(el.start, el.end)).flat();
  const {cards, loading: loadCards, refetch} = useViewerCards(viewerObjId);
  const dataCreateMsg = useCreateMessage({
    idActiveContact: item?.Lister?.objectId,
    communityId: community?.objectId,
    initialState: {
      Author: viewerObjId,
      text: ``,
    },
    prepend: `${item?.name}: `,
  });
  const viewer = useViewer();
  const isRequester = viewer?.objectId !== item?.Lister?.objectId;
  const setUser = useSetRecoilState(userState);
  const userUpdate = useUpdateUser();
  const {totalCreditsInCents} = useGetUserCredits();
  const dataOrder = useCreateOrder({
    communityId: community?.objectId,
    itemId: item?.objectId,
    pricePeriod: item?.pricePeriod,
    listingType: item?.listingType,
    busyDates,
    onSuccess: () => {
      const date = new Date();
      analyticsTrackFN('Listing Requested', {
        listingId: item?.objectId,
        listingType: item?.listingType, // service, rental, buy/sell
        listedFor: item?.rewardType, // coffee, free, money
        photoCount: item?.images?.length,
        title: item?.name,
        description: item?.descr,
        rate: item?.price,
        currency: currency?.code,
        bookingType: item?.pricePeriod,
        category: item?.category,
        listerName: `${dataLister?.firstName}`,
        listerEmail: dataLister?.email,
        listerId: dataLister?.objectId,
        requesterName: `${viewer?.firstName}`,
        requesterEmail: viewer?.email,
        requesterId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type, // building, neighbourhood, circle
        requestDate: format(new Date(date), 'P'),
        requestTime: format(new Date(date), 'p'),
        requestDuration: `${getPricePerion(
          item?.pricePeriod || '',
          new Date(dataOrder?.startEndTime?.start),
          new Date(dataOrder?.startEndTime?.end),
        )} hours`,
        paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
        paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
        paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
        paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
      });
    },
  });

  const dataInvoice = formatDataInvoice({
    data: dataOrder.preCalcData,
    pricePeriod: item?.pricePeriod,
    startDate: dataOrder.dates?.[0],
    endDate: dataOrder.dates?.[1],
    hangehDiscount: isRequester ? totalCreditsInCents || 0 : 0,
  });

  const dataOrderHandleSubmit = async () => {
    await dataOrder.onSubmit();
    if (viewer?.id && !viewer.checkList?.makeOrder.isChecked) {
      const newUserState = await userUpdate({id: viewer?.id});
      newUserState.data?.updateUser.user && setUser(newUserState.data?.updateUser.user);
    }
  };
  useEffect(() => {
    if (item?.id && item?.Lister?.objectId !== viewerObjId) {
      createViewer(item.id, 'Item');
      analyticsTrackFN('Listing Viewed', {
        listingId: item?.id,
        listingType: item?.listingType, // service, rental, buy/sell
        listedFor: item?.rewardType, // coffee, free, money
        photoCount: item?.images?.length,
        title: item?.name,
        description: item?.descr,
        rate: item?.price,
        currency: currency?.code,
        bookingType: item?.pricePeriod,
        category: item?.category,
        listerName: `${item?.Lister?.firstName}`,
        listerEmail: item?.Lister?.email,
        listerId: item?.Lister?.objectId,
        requesterName: `${viewer?.firstName}`,
        requesterEmail: viewer?.email,
        requesterId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type, // building, neighbourhood, circle
        source: state, // marketplace, loop, profile
      });
    }
  }, [id, item?.Lister?.objectId, viewerObjId]);
  const requestAccess = item?.rewardType === RewardType.fee ? !!cards?.length : true;
  return (
    <ViewListing
      dataOrderSubmit={dataOrderHandleSubmit}
      community={community || undefined}
      dataCreateMsg={dataCreateMsg}
      viewerObjId={viewerObjId}
      action={ItemFormAction.view}
      item={item}
      $loading={loading}
      dataOrder={dataOrder}
      dataLister={dataLister}
      dataInvoice={dataInvoice}
      isAvailable={available}
      requestAccess={loadCards ? true : requestAccess}
      busyDates={busyDates}
      refetch={refetch}
      typeUser={type}
      manyCommunities={manyCommunities}
      commList={commList}
      isPreview={isPreview}
      handleLike={handleLike}
    />
  );
};
