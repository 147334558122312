export enum HowDidYouHearType {
  university = 'University',
  google = 'Google Search',
  facebook = 'Facebook',
  instagram = 'Instagram',
  twitter = 'Twitter',
  company = 'Company',
  email = 'Email',
  tv = 'TV',
  blog = 'Blog',
  newspaper = 'Newspaper',
  word = 'Word of mouth',
  friends = 'Friends',
  family = 'Family',
  other = 'Other',
}

export enum InterestedInType {
  neetingNeighbours = 'Meeting neighbours',
  buyingOrSelling = 'Buying or selling from/to neighbours',
  renting = 'Renting from/to neighbours',
  organizingEvents = 'Organizing events and activities',
  // keepingUpToDate = "Keeping up to date with what's happing",
  attendingEvents = 'Attending events',
  connectingWithPeople = 'Connecting with people in my profession',
  // meetingPeople = 'Meeting people with similar interests',
  makingFriends = 'Making friends',
  offeringMyServices = 'Offering my services',
  communityUpdates = 'Community updates',
  notSure = 'Not sure',
  other = 'Other',
}

export enum LivingWithType {
  myself = 'Myself',
  spouse = 'Spouse',
  family = 'Family',
  roommates = 'Roommates',
  pets = 'Pets',
  preferNotToShare = 'Prefer not to share',
}

export enum TypeSteps {
  profile = 'profile',
  searchCommunity = 'searchCommunity',
  done = 'done',
}
