import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Tabs, TabsPageWrapper} from '../../components/common/Tabs';
import {tabArrayItem} from '../../components/common/Tabs/types';
import {PlanOptionsValues} from '../../constants/plan';
import {route} from '../../constants/routes';
import {useHasPlanOption} from '../../helpers/community';
import {getMobileTabs, getMobPath, inboxPaths, inboxPathsT, PathsList} from '../../helpers/mobNavigation';
import {useLinks, usePageView} from '../../hooks/common';
import {useGetDocuments} from '../../hooks/document';
import {useUnreadMessages} from '../../hooks/message';
import {currentCommunity, enableSwipeableLoop, userPermissions} from '../../states/community';
import {mobTab} from '../../states/navState';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {PermissionsList} from '../../types/settings';
import {ContentLoader} from '../../ui-kit/Loader/ContentLoader';
import {navOffsetsT} from '../../ui-kit/Navigation/types';
import {Dashboard} from '../Account/Dashboard';
import {AmenitiesPage} from '../Amenities/Page';
import {DocumentsContainer} from '../Documents/DocumentsContainer';
import {EventsPage} from '../Events/Page';
import {Groups} from '../Groups';
import {ListingsPage} from '../Listings/Page';
import {LocalBizPageContainer as LocalBizPage} from '../LocalBiz';
import {LoopContainer} from '../Loop';
import {MessagesContainer} from '../Messages';
import {RequestsColumn} from '../Messages/RequestsColumn';
import {NavContainer} from '../Navigation';
import {People} from '../People';
import {HomeTabsProps, PageTabs} from './types';
import {isFromApp} from '../../ui-kit/Navigation/mobile/styles';

export type MobContainerProps = {
  hideNav?: boolean;
};

export const MobileTabs: React.FC<{current: number}> = ({children, current}) => {
  const location = useLocation();
  const {t} = useTranslation();
  const [userType] = useRecoilState(typeUser);
  const [comm] = useRecoilState(currentCommunity);
  const [permissions] = useRecoilState(userPermissions);
  const setMobTab = useSetRecoilState(mobTab);
  const isManager = userType === TypeCommunity.manager;
  const path = getMobPath(location.pathname);
  const {count: documentsCount} = useGetDocuments();
  const options = getMobileTabs({t, path, isManager, documentsCount, permissions});
  const handleSetMobTab = (tab: PathsList | null) => setMobTab(tab);

  // if (homePaths.includes(path as homePathsT) && options) {
  //   return (
  //     <HomeTabs
  //       options={options}
  //       current={current}
  //       isManager={isManager}
  //       documentsCount={documentsCount}
  //       setMobTab={handleSetMobTab}
  //       permissions={permissions}
  //     />
  //   );
  // }

  // if (peoplePaths.includes(path as peoplePathsT) && options) {
  //   return <PeopleTabs options={options} current={current} setMobTab={handleSetMobTab} />;
  // }

  // if (explorePaths.includes(path as explorePathsT) && options) {
  //   return <ExploreTabs options={options} current={current} setMobTab={handleSetMobTab} />;
  // }

  if (inboxPaths.includes(path as inboxPathsT) && options) {
    return <InboxTabs options={options} current={current} community={comm} setMobTab={handleSetMobTab} />;
  }
  return <>{children}</>;
};

export const HomeTabs: React.FC<HomeTabsProps> = ({
  options,
  current,
  isManager,
  documentsCount,
  setMobTab,
  permissions,
}) => {
  const indexes: tabArrayItem[] = useMemo(
    () => options?.paths.map((el, i) => ({path: el.path, title: el.title, index: i})),
    [options],
  );

  const [viewed, setViewed] = useState<number[]>([]);
  const [index, setIndex] = useState(current);
  useEffect(() => {
    setMobTab(indexes.find((el) => el.index === index)?.path || null);
    setViewed((prev) => (!prev.includes(index) ? [index] : prev));
  }, [index]);
  const changeIndex = (n: number) => {
    setMobTab(indexes.find((el) => el.index === n)?.path || null);
    setIndex(n);
    window?.scrollTo(0, 0);
  };
  const hasReportingDashboard = useHasPlanOption(PlanOptionsValues.reportingDashboard);
  const hasDocuments = useHasPlanOption(PlanOptionsValues.communityDocuments);
  const showDocs = hasDocuments && documentsCount;
  const showDash = hasReportingDashboard && (isManager || permissions?.includes(PermissionsList.dashboardAccess));
  const loopIndex = useMemo(() => indexes?.find((el) => el.path === PathsList.loop)?.index ?? null, [indexes]);
  const docIndex = useMemo(() => indexes?.find((el) => el.path === PathsList.documents)?.index ?? null, [indexes]);
  const dashIndex = useMemo(() => indexes?.find((el) => el.path === PathsList.insights)?.index ?? null, [indexes]);
  const loopComp = useMemo(() => {
    return [
      loopIndex !== null && viewed.includes(loopIndex) ? (
        <LoopContainer key={1} hideNav={true} />
      ) : (
        <ContentLoader key={2} padding={'30px'} />
      ),
    ];
  }, [loopIndex, viewed]);
  const docsComp = useMemo(
    () =>
      showDocs
        ? [
            docIndex !== null && viewed.includes(docIndex) ? (
              <DocumentsContainer key={3} hideNav={true} />
            ) : (
              <ContentLoader key={4} padding={'30px'} />
            ),
          ]
        : [],
    [docIndex, viewed],
  );
  const dashComp = useMemo(
    () =>
      showDash
        ? [
            dashIndex !== null && viewed.includes(dashIndex) ? (
              <Dashboard key={5} hideNav={true} />
            ) : (
              <ContentLoader key={6} padding={'30px'} />
            ),
          ]
        : [],
    [dashIndex, viewed],
  );
  const components = useMemo(() => {
    return [...loopComp, ...docsComp, ...dashComp];
  }, [loopComp, docsComp, dashComp]);
  const isSwipeable = useRecoilValue(enableSwipeableLoop);
  const swipeableStyle: React.CSSProperties = {position: 'relative', zIndex: isSwipeable ? 302 : 'unset'};
  return (
    <NavContainer>
      <TabsPageWrapper>
        <Tabs index={index} tabsArray={indexes} onChange={changeIndex} />
        <SwipeableViews style={swipeableStyle} disabled={isSwipeable} index={index} onChangeIndex={changeIndex}>
          {components}
        </SwipeableViews>
      </TabsPageWrapper>
    </NavContainer>
  );
};

export const PeopleTabs: React.FC<PageTabs> = ({options, current, setMobTab}) => {
  const [viewed, setViewed] = useState<number[]>([]);
  const [index, setIndex] = useState(current);
  useEffect(() => {
    setMobTab(options.paths.find((el) => el.index === index)?.path || null);
    setViewed((prev) => (!prev.includes(index) ? [index] : prev));
  }, [index]);
  const changeIndex = (n: number) => {
    setMobTab(options.paths.find((el) => el.index === n)?.path || null);
    setIndex(n);
    window?.scrollTo(0, 0);
  };

  return (
    <NavContainer>
      <TabsPageWrapper>
        <Tabs index={index} tabsArray={options?.paths} onChange={changeIndex} />
        <SwipeableViews index={index} onChangeIndex={changeIndex}>
          {viewed.includes(0) ? <People hideNav={true} /> : <ContentLoader padding={'30px'} />}
          {viewed.includes(1) ? <Groups hideNav={true} /> : <ContentLoader padding={'30px'} />}
        </SwipeableViews>
      </TabsPageWrapper>
    </NavContainer>
  );
};

const moTabPaths = (path?: PathsList) => {
  if (path === PathsList.amenities) return route.amenities.path;
  if (path === PathsList.listings) return route.listings.path;
  if (path === PathsList.events) return route.events.path;
  if (path === PathsList.localBiz) return route.localBiz.path;
  return null;
};

export const ExploreTabs: React.FC<PageTabs> = ({options, current, setMobTab}) => {
  const {getLink} = useLinks();
  const {push} = useHistory();
  const [viewed, setViewed] = useState<number[]>([]);
  const [index, setIndex] = useState(current);

  useEffect(() => {
    setMobTab(options.paths.find((el) => el.index === index)?.path || null);
    setViewed((prev) => (!prev.includes(index) ? [index] : prev));
  }, [index]);
  const changeIndex = (n: number) => {
    const path = options.paths.find((el) => el.index === n)?.path;
    setMobTab(path || null);
    const routePath = moTabPaths(path);
    setIndex(n);
    window?.scrollTo(0, 0);
    if (routePath) push(getLink(routePath));
  };
  return (
    <NavContainer>
      <TabsPageWrapper>
        <Tabs index={index} tabsArray={options?.paths} onChange={changeIndex} />
        <SwipeableViews index={index} onChangeIndex={changeIndex}>
          {viewed.includes(0) ? <ListingsPage hideNav={true} /> : <ContentLoader padding={'30px'} />}
          {viewed.includes(1) ? <EventsPage hideNav={true} /> : <ContentLoader padding={'30px'} />}
          {viewed.includes(2) ? <LocalBizPage hideNav={true} /> : <ContentLoader padding={'30px'} />}
          {viewed.includes(3) ? <AmenitiesPage hideNav={true} /> : <ContentLoader padding={'30px'} />}
        </SwipeableViews>
      </TabsPageWrapper>
    </NavContainer>
  );
};

const navOffsets: navOffsetsT = {
  remove: true,
  pOffset: isFromApp() ? '90px 0 88px 0' : '100px 0 88px 0',
  mOffset: '0px',
};

export const InboxTabs: React.FC<PageTabs> = ({options, current, setMobTab, community}) => {
  const [viewed, setViewed] = useState<number[]>([]);
  const [index, setIndex] = useState(current);
  const {view, setView} = usePageView();
  useEffect(() => {
    const tab = options.paths.find((el) => el.index === index);
    setMobTab(tab?.path || null);
    setViewed((prev) => (!prev.includes(index) ? [index] : prev));
    if (!view && tab?.path && tab?.path !== PathsList.messages) {
      setView(tab.path);
    }
    if (view && tab?.path) {
      setView(tab.path);
    }
  }, [index]);
  useEffect(() => {
    const tabByIndex = options.paths.find((el) => el.index === index);
    const tab = options.paths.find((el) => el.path === view);
    if (view && tab?.index && view !== tabByIndex?.path) changeIndex(tab.index);
  }, [view]);
  const changeIndex = (n: number) => {
    setMobTab(options.paths.find((el) => el.index === n)?.path || null);
    setIndex(n);
    window?.scrollTo(0, 0);
  };
  const {count} = useUnreadMessages(community?.objectId);
  const redDotIndex = !!count ? 0 : null;
  return (
    <NavContainer navOffsets={navOffsets}>
      <TabsPageWrapper exclude={true}>
        <Tabs index={index} tabsArray={options?.paths} redDotIndex={redDotIndex} onChange={changeIndex} />
        <SwipeableViews index={index} onChangeIndex={changeIndex}>
          {viewed.includes(0) ? <MessagesContainer hideNav={true} /> : <ContentLoader padding={'30px'} />}
          {viewed.includes(1) ? <RequestsColumn /> : <ContentLoader padding={'30px'} />}
        </SwipeableViews>
      </TabsPageWrapper>
    </NavContainer>
  );
};

export const EmptyTag: React.FC = ({children}) => {
  return <>{children}</>;
};
