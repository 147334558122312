import styled, {css} from 'styled-components';
import {Message} from '../../ui-kit/Form/Message';
import {Text as UiText, TextProps} from '../../ui-kit/Typography/Text';
import {Img} from './Img';

//400
export const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

//500
export const fontPrimaryMedium = css`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
`;

//600
export const fontPrimarySemiBold = css`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
`;

//400
export const fontTertiaryReg = css`
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
`;

export const Centered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled(Img)<{
  className?: string;
  width?: string;
  height?: string;
}>`
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || '100%'};
  object-fit: cover;
  position: relative;
  box-sizing: border-box;
  border-radius: 12px;
`;

export const Text = styled(UiText)<
  {
    offsetTop?: number;
    offsetBottom?: number;
    offsetRight?: number;
    offsetLeft?: number;
  } & TextProps
>`
  line-height: 1.5;
  font-size: 12px;
  margin-top: ${({offsetTop}) => offsetTop || 0}px;
  margin-bottom: ${({offsetBottom}) => offsetBottom || 0}px;
  margin-right: ${({offsetRight}) => offsetRight || 0}px;
  margin-left: ${({offsetLeft}) => offsetLeft || 0}px;
`;

export const SuccessMessage = styled(Message).attrs({variant: 'success'})`
  margin: 15px 0;
  color: white;
`;
