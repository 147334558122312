import React, {useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {isDesktop} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {PhotosList} from './PhotosList';
import {
  InnerItems,
  InnerItemsGrid,
  ListingForm,
  SubTitle,
  WrapperForm,
  MobFocused,
  DatePickerWrapper,
  InputEntity,
  DatePickerPopup,
} from './styles';
import {NavContainer} from '../../containers/Navigation';
import {correctTranslate} from '../../helpers/common';
import {checkSelectedTime} from '../../helpers/event';
import {optionsI18n} from '../../libs/i18nextUtils';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {SelectOption} from '../../types/common';
import {EventFields, EventFormAction} from '../../types/event';
import {Button} from '../../ui-kit/Button/Button';
import {Input} from '../../ui-kit/Form/Input';
import {DatePickerLanguageProvider} from '../../ui-kit/Form/Input/DatePickerLanguageProvider';
import {NumberFormatInput} from '../../ui-kit/Form/Input/NumberFormatInput';
import {Select} from '../../ui-kit/Form/Select';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Popup} from '../../ui-kit/Popup';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {ErrorMessage} from '../Auth/styles';
import {EmojiTab} from '../common/EmojiTab/EmojiTab';
import {MemoInfoHint} from '../common/InfoHint';
import {HintWrapperInput} from '../common/InfoHint/styles';
import {RichTextEditor} from '../common/RichText/Editor';
import {LottieLoader} from '../Loader/LottieLoader';

import {TEventFormData, TResCreateEvent, TResEditEvent} from '../../hooks/event';
import {useEditorRef} from '../../hooks/common';
import {GoBack} from '../common/Buttons/GoBack';
import {route} from '../../constants/routes';

type TOnChange = (next: {name: string; value: string}) => void;

type TProps = {
  action: EventFormAction;
  data: TResEditEvent | TResCreateEvent;
  formData: TEventFormData;
  currSign: string;
  communityId?: string;
  onPublish?: () => void;
  onDelete?: () => void;
};

export const EventForm: React.FC<TProps> = ({action, data, formData}) => {
  const {t} = useTranslation();
  const {values, onChange, onSubmit, error, loading} = data;
  const {toggleOptions, rewardTypeOptions, startTimeOptions, endTimeOptions, openToOptions, eventTypeOptions} =
    formData;
  const [datePicker, setDatePicker] = useState(false);
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;
  const title = t(`events:titlesForm.${action}`);
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  const {setRef} = useEditorRef({autoBlur: true});
  const [emoji, setEmoji] = useState<string>('');

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleSelect =
    (name: string) => (data: SelectOption<string | ListingType | RewardType | PricePeriodType>[]) => {
      const value = data[0]?.value;
      onChange({name: name, value: value});
    };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const openDataPicker = () => {
    setDatePicker(true);
  };

  const onChangeDataPicker = (dateN: Date) => {
    onChange({name: EventFields.eventDate, value: dateN});
    setDatePicker(false);
  };

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };
  const goBack = () => window?.history.back();

  return (
    <NavContainer>
      <WrapperForm>
        <GoBack goBack={goBack} title={title} redirectPath={route.events.path} />
        <ListingForm onSubmit={handleSubmit}>
          <SubTitle>{t(`events:titlesForm.photos.${action}`)}</SubTitle>
          <PhotosList cards={values.images || []} onChange={onChange} error={!!error?.message} />
          <SubTitle>{t(`events:titlesForm.eventDetails`)}</SubTitle>
          <InnerItems>
            <Input
              $isError={Boolean(error.name)}
              name={EventFields.name}
              value={values.name}
              onChange={handleChange}
              label={t('forms:name')}
            />
            <MobFocused $isDesktop={isDesktop}>
              <RichTextEditor
                $isError={Boolean(error.descr)}
                label={t('forms:descr')}
                name={EventFields.descr}
                value={values?.descr}
                onChange={onChange as TOnChange}
                rows={8}
                emoji={emoji}
                setEmoji={setEmoji}
                inputRef={setRef}
              />
              <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.descr} />
            </MobFocused>
            <Select
              $isError={Boolean(error.rewardType)}
              width={'100%'}
              name={EventFields.rewardType}
              label={t('forms:listFor')}
              values={rewardTypeOptions.filter((it) => it.value === values.rewardType)}
              options={rewardTypeOptions}
              onChange={handleSelect(EventFields.rewardType)}
              searchable={false}
            />
            <HintWrapperInput $isSelect>
              <Select
                $isError={Boolean(error?.openTo)}
                width={'100%'}
                name={EventFields.openTo}
                label={t('forms:openTo')}
                values={openToOptions.filter((it) => it.value === values.openTo)}
                options={openToOptions}
                onChange={handleSelect(EventFields.openTo)}
                searchable={false}
              />
              <MemoInfoHint hintText={t('events:hints.openTo')} float={'right'} />
            </HintWrapperInput>
            <Select
              $isError={Boolean(error?.eventType)}
              width={'100%'}
              name={EventFields.eventType}
              label={t('forms:eventType')}
              values={eventTypeOptions.filter((it) => it.value === values.eventType)}
              options={eventTypeOptions}
              onChange={handleSelect(EventFields.eventType)}
              searchable={false}
            />
          </InnerItems>
          <DatePickerWrapper>
            <InputEntity onClick={openDataPicker}>
              <Input
                $isError={Boolean(error.eventDate)}
                label={t('forms:eventDate')}
                name={EventFields.eventDate}
                value={values?.eventDate && format(values?.eventDate, 'PPP', getLanguage())}
                icon={<IconSvg type={'calendar'} width={'24px'} height={'24px'} />}
                disabled={true}
              />
            </InputEntity>
            <Popup isOpen={datePicker} onClose={() => setDatePicker(false)} mobileCentered={true}>
              <DatePickerPopup>
                <DatePickerLanguageProvider>
                  <ReactDatePicker
                    selected={values?.eventDate}
                    onChange={onChangeDataPicker}
                    minDate={new Date()}
                    inline
                    locale={getLanguage()}
                  />
                </DatePickerLanguageProvider>
              </DatePickerPopup>
            </Popup>
          </DatePickerWrapper>
          <InnerItemsGrid>
            <Select
              $isError={Boolean(error.startTime)}
              label={t('forms:startTime')}
              name={EventFields.startTime}
              values={checkSelectedTime(startTimeOptions, values?.startTime)}
              options={startTimeOptions}
              onChange={handleSelect(EventFields.startTime)}
              searchable={false}
            />
            <Select
              $isError={Boolean(error.endTime)}
              label={t('forms:endTime')}
              name={EventFields.endTime}
              values={checkSelectedTime(endTimeOptions, values?.endTime)}
              options={endTimeOptions}
              onChange={handleSelect(EventFields.endTime)}
              searchable={false}
            />
          </InnerItemsGrid>
          <Input
            $isError={Boolean(error.locationDetails)}
            label={t('forms:location')}
            name={EventFields.locationDetails}
            value={values.locationDetails}
            onChange={handleChange}
          />
          <Select
            $isError={Boolean(error.showAttendees)}
            name={EventFields.showAttendees}
            label={t('forms:showAttendees')}
            values={toggleOptions.filter((el) => el.value === values.showAttendees)}
            options={toggleOptions}
            onChange={handleSelect(EventFields.showAttendees)}
            searchable={false}
          />
          <InnerItemsGrid>
            <HintWrapperInput>
              <NumberFormatInput
                $isError={Boolean(error.maxAttendees)}
                label={t('forms:maxAttendees')}
                name={EventFields.maxAttendees}
                value={values.maxAttendees}
                onChange={handleChange}
                allowNegative={false}
              />
              <MemoInfoHint hintText={t('events:hints.maxAttendees')} float={'right'} zIndex={1} />
            </HintWrapperInput>
            <HintWrapperInput>
              <NumberFormatInput
                $isError={Boolean(error.maxGuestsPerAttendee)}
                label={t('forms:maxGuests')}
                name={EventFields.maxGuestsPerAttendee}
                value={values.maxGuestsPerAttendee}
                onChange={handleChange}
                allowNegative={false}
              />
              <MemoInfoHint hintText={t('events:hints.maxGuests')} float={'right'} zIndex={1} />
            </HintWrapperInput>
          </InnerItemsGrid>
          <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
          <Button type={'submit'} loading={loading} disabled={loading}>
            {t(`events:buttons.form.${action}`)}
          </Button>
        </ListingForm>
        <LottieLoader $isVisible={loading} allScreen={true} />
      </WrapperForm>
    </NavContainer>
  );
};
