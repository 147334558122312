import {
  NotificationsEventTypes,
  NotificationsType,
  NotifyType,
  receivingVariants,
  SettingNotificationT,
  SettingNotificationTypes,
  StateNotificationSettingsType,
} from '../types/notifications';
import {route, SearchKey} from '../constants/routes';
import {userSection} from '../containers/HomePageUser/types';
import {alphabetSort, getReactLinkFromDeep} from './common';
import {createLoopLink} from './feed';
import {PostTypeT} from '../types/post';
import {translateFn} from '../types/common';
type formatLinkProps = {
  event: NotificationsEventTypes;
  data: NotificationsType['data'];
  from: NotificationsType['from'];
  to: NotificationsType['to'];
};
const prepareNotificationLinks = (data: NotificationsType['data'], from: NotificationsType['from']) => {
  return {
    [NotificationsEventTypes.joinRequest]: route.people.path,
    [NotificationsEventTypes.newComment]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newLike]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newLikeRecommendation]: data?.post
      ? createLoopLink(route.loop.path, PostTypeT.recommendation)
      : '',
    [NotificationsEventTypes.userReview]: route.pageUser.path + `?${SearchKey.section}=${userSection.reviews}`,
    [NotificationsEventTypes.newListing]: data?.listingID ? route.listingView.get({id: data?.listingID}) : '',
    [NotificationsEventTypes.listingUpdate]: route.listings.path,
    [NotificationsEventTypes.approveRequest]: route.loop.path,
    [NotificationsEventTypes.itemReview]: data?.listingID ? route.listingView.get({id: data?.listingID}) : '',
    [NotificationsEventTypes.itemView]: data?.listingID ? route.listingView.get({id: data?.listingID}) : '',
    [NotificationsEventTypes.newPost]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newTag]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newGroupPost]: data?.groupId ? route.groupView.get({id: data?.groupId}) : '',
    [NotificationsEventTypes.newResident]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.newResidentForManager]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.orderRequest]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderRentAccepted]: data?.listerId
      ? `${route.messages.get({contactId: data.listerId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderRentRequest]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderRentRejected]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderSellAccepted]: data?.listerId
      ? `${route.messages.get({contactId: data.listerId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderSellRequest]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderSellRejected]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderServiceAccepted]: data?.listerId
      ? `${route.messages.get({contactId: data.listerId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderServiceRequest]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.orderServiceRejected]: data?.requesterId
      ? `${route.messages.get({contactId: data.requesterId})}?orderId=${data.orderId}`
      : '',
    [NotificationsEventTypes.rejectRequest]: route.searchCommunityResident.path,
    [NotificationsEventTypes.userView]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.newJoinLike]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.newJoinWave]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.newJoinSmile]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.transactionCredit]: '',
    [NotificationsEventTypes.transactionInvoice]: '',
    [NotificationsEventTypes.reviewRequestAboutCooperation]:
      data?.receiverId && data?.orderId
        ? `${route.messages.get({contactId: data.receiverId})}?orderId=${data.orderId}`
        : '',
    [NotificationsEventTypes.newListingMultiply]: route.listings.path,
    [NotificationsEventTypes.listingPublishRequest]: route.listings.path,
    [NotificationsEventTypes.listingApproved]: data?.listingID ? route.listingView.get({id: data?.listingID}) : '',
    [NotificationsEventTypes.newManagerAdminPost]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newMessage]: route.messages.path,
    [NotificationsEventTypes.reportPostListing]: '',
    [NotificationsEventTypes.newNotice]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.newPostRequest]: data?.post ? route.post.get({id: data.post}) : '',
    [NotificationsEventTypes.reportPost]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.reportListing]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.reportUser]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.subscrPaymentFail]: route.paymentsSettings.get(),
    [NotificationsEventTypes.amenityOrderBooked]: data?.amenityId ? route.amenityView.get({id: data.amenityId}) : '',
    [NotificationsEventTypes.amenityAutoBooked]: data?.amenityId ? route.amenityView.get({id: data.amenityId}) : '',
    [NotificationsEventTypes.amenityOrderCanceled]: data?.amenityId ? route.amenityView.get({id: data.amenityId}) : '',
    [NotificationsEventTypes.amenityOrderPending]: data?.amenityId ? route.amenityView.get({id: data.amenityId}) : '',
    [NotificationsEventTypes.amenityReview]: data?.amenityId ? route.amenityView.get({id: data.amenityId}) : '',
    [NotificationsEventTypes.eventOrderAttending]: data?.eventId ? route.eventView.get({id: data.eventId}) : '',
    [NotificationsEventTypes.eventOrderPending]: data?.eventId ? route.eventView.get({id: data.eventId}) : '',
    [NotificationsEventTypes.eventOrderCanceled]: data?.eventId ? route.eventView.get({id: data.eventId}) : '',
    [NotificationsEventTypes.eventReview]: data?.eventId ? route.eventView.get({id: data.eventId}) : '',
    [NotificationsEventTypes.eventAmenityReminder]: data?.amenityId
      ? route.amenityView.get({id: data.amenityId})
      : data?.eventId
      ? route.eventView.get({id: data.eventId})
      : '',
    [NotificationsEventTypes.eventAndAmenityReminder]: data?.amenityId
      ? route.amenityView.get({id: data.amenityId})
      : data?.eventId
      ? route.eventView.get({id: data.eventId})
      : '',
    [NotificationsEventTypes.waveUser]: from?.objectId ? route.profile.get({id: from.objectId}) : '',
    [NotificationsEventTypes.inviteNeighbours]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.pollAdded]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.gettingViewed]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.gettingViewedListing]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.peopleLooking]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.recommendationCheckOut]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.pollAddedSecond]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
    [NotificationsEventTypes.recommendationCheckOutSecond]: data?.deepLink ? getReactLinkFromDeep(data.deepLink) : '',
  };
};
export const formatNotificationLink = ({event, data, from}: formatLinkProps) => {
  const links = prepareNotificationLinks(data, from);
  return links[event];
};

export const formatAdditionalTranslate = (event: NotificationsEventTypes, toManager?: boolean) => {
  const toChange = [NotificationsEventTypes.amenityAutoBooked];
  if (toChange.includes(event) && toManager)
    return `${NotificationsEventTypes.amenityAutoBooked}Manager` as NotificationsEventTypes;
  return event;
};
export enum NotificationKeys {
  notifyMarketingAndFeatures = 'notifyMarketingAndFeatures',
  notifyOrderRequest = 'notifyOrderRequest',
  notifyLoopPost = 'notifyLoopPost',
  notifyManagerAdminPost = 'notifyManagerAdminPost',
  notifyLikeOrComment = 'notifyLikeOrComment',
  notifyNewResidentListings = 'notifyNewResidentListings',
  notifyNewResidents = 'notifyNewResidents',
  notifyProfileView = 'notifyProfileView',
  notifyListingView = 'notifyListingView',
  notifyJoinRequest = 'notifyJoinRequest',
  notifyListingsRequest = 'notifyListingsRequest',
  notifyDigest = 'notifyDigest',
  notifyMessage = 'notifyMessage',
  notifyNewMembers = 'notifyNewMembers',
  notifyNewCommunities = 'notifyNewCommunities',
  notifyNewRequests = 'notifyNewRequests',
  notifyNewTransaction = 'notifyNewTransaction',
  //new
  notifyNewMember = 'notifyNewMember',
  notifyAmenityReminder = 'notifyAmenityReminder',
  notifyEventReminder = 'notifyEventReminder',
  notifyHelpCentre = 'notifyHelpCentre',
  notifyNewListing = 'notifyNewListing',
  notifyEventAttendees = 'notifyEventAttendees',
  notifyUnmanagedAmenities = 'notifyUnmanagedAmenities',
  notifyManagedAmenities = 'notifyManagedAmenities',
}
export const getSettingsNotifications = (t: translateFn): SettingNotificationT[] => {
  return [
    {
      type: SettingNotificationTypes.communityPromotional,
      name: t(`settings:notifications.types.${SettingNotificationTypes.communityPromotional}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.communityPromotional}.descr`),
      notifications: [NotificationKeys.notifyMarketingAndFeatures],
    },
    {
      type: SettingNotificationTypes.communityDigest,
      name: t(`settings:notifications.types.${SettingNotificationTypes.communityDigest}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.communityDigest}.descr`),
      notifications: [NotificationKeys.notifyDigest],
    },
    {
      type: SettingNotificationTypes.communityActivity,
      name: t(`settings:notifications.types.${SettingNotificationTypes.communityActivity}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.communityActivity}.descr`),
      notifications: [
        NotificationKeys.notifyLoopPost,
        NotificationKeys.notifyManagerAdminPost,
        NotificationKeys.notifyLikeOrComment,
        NotificationKeys.notifyNewResidents,
        NotificationKeys.notifyJoinRequest,
        NotificationKeys.notifyNewMember,
      ],
    },
    {
      type: SettingNotificationTypes.personalInteractions,
      name: t(`settings:notifications.types.${SettingNotificationTypes.personalInteractions}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.personalInteractions}.descr`),
      notifications: [NotificationKeys.notifyMessage],
      required: true,
    },
    {
      type: SettingNotificationTypes.bookingRequests,
      name: t(`settings:notifications.types.${SettingNotificationTypes.bookingRequests}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.bookingRequests}.descr`),
      notifications: [
        NotificationKeys.notifyOrderRequest,
        NotificationKeys.notifyNewResidentListings,
        NotificationKeys.notifyListingsRequest,
        NotificationKeys.notifyAmenityReminder,
        NotificationKeys.notifyEventReminder,
        NotificationKeys.notifyNewListing,
        NotificationKeys.notifyEventAttendees,
        NotificationKeys.notifyUnmanagedAmenities,
        NotificationKeys.notifyManagedAmenities,
      ],
      required: true,
    },
    {
      type: SettingNotificationTypes.contentView,
      name: t(`settings:notifications.types.${SettingNotificationTypes.contentView}.name`),
      descr: t(`settings:notifications.types.${SettingNotificationTypes.contentView}.descr`),
      notifications: [NotificationKeys.notifyProfileView, NotificationKeys.notifyListingView],
    },
    // {
    //   type: SettingNotificationTypes.contentModeration,
    //   name: t(`settings:notifications.types.${SettingNotificationTypes.contentModeration}.name`),
    //   descr: t(`settings:notifications.types.${SettingNotificationTypes.contentModeration}.descr`),
    //   notifications: [],
    // },
  ];
};

type StateNotifKey = keyof StateNotificationSettingsType;
export const getNotificationValues = (
  notification?: SettingNotificationT,
  formValues?: StateNotificationSettingsType,
) => {
  const values = {
    sms: true,
    push: true,
    email: true,
  };
  notification?.notifications?.forEach((el) => {
    if (!formValues?.[el as StateNotifKey]?.includes('sms')) values.sms = false;
    if (!formValues?.[el as StateNotifKey]?.includes('push')) values.push = false;
    if (!formValues?.[el as StateNotifKey]?.includes('email')) values.email = false;
  });
  return values;
};

//toggling push/sms/email
export const changeNotificationState = (
  key: receivingVariants,
  value: boolean,
  notification?: SettingNotificationT,
  formValues?: StateNotificationSettingsType,
) => {
  const newValue = notification?.notifications?.map((el) => {
    const currentValue = formValues?.[el as StateNotifKey] || [];
    const isContains = currentValue?.includes(key);
    if (isContains && !value) return {notifKey: el, value: [...currentValue?.filter((el) => el !== key)]};
    if (!isContains && value) return {notifKey: el, value: [...currentValue, key]};
    return null;
  });
  return newValue?.filter((el) => !!el) as {notifKey: string; value: string[]}[];
};

export const engagementNotifications = [
  NotificationsEventTypes.inviteNeighbours,
  NotificationsEventTypes.pollAdded,
  NotificationsEventTypes.gettingViewed,
  NotificationsEventTypes.gettingViewedListing,
  NotificationsEventTypes.peopleLooking,
  NotificationsEventTypes.recommendationCheckOut,
  NotificationsEventTypes.pollAddedSecond,
  NotificationsEventTypes.recommendationCheckOutSecond,
];

export const getUpdatedValues = (
  current: Partial<StateNotificationSettingsType>,
  old?: Partial<StateNotificationSettingsType>,
) => {
  const updated: {type: string; options: {email?: boolean; sms?: boolean; push?: boolean}}[] = [];
  Object.keys(current)?.forEach((key) => {
    const p1 = current?.[key as keyof StateNotificationSettingsType];
    const p2 = old?.[key as keyof StateNotificationSettingsType];
    if (p1?.sort(alphabetSort).join('') !== p2?.sort(alphabetSort).join(''))
      updated.push({
        type: key,
        options: {
          email: p1?.some((el) => el === NotifyType.Email),
          push: p1?.some((el) => el === NotifyType.Push),
          sms: p1?.some((el) => el === NotifyType.SMS),
        },
      });
  });

  return updated;
};
