import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {HomePageUser} from '../../components/HomePageUser';
import {PlanOptionsValues} from '../../constants/plan';
import {route, SearchKey} from '../../constants/routes';
import {GQLItemOrder} from '../../graphql.schema';
import {hasPlanOption} from '../../helpers/community';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useItems} from '../../hooks/item';
import {useCheckUserType, useGetAdminsLabels} from '../../hooks/people';
import {entities, useReport} from '../../hooks/report';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';
import {useCheckIsSupport, useUpdateProfilePhoto, useViewer, useUserReviews} from '../../hooks/user';
import {currentCommunity, onlineUsers, userPermissions} from '../../states/community';
import {prevLocation, prevPaths} from '../../states/navState';
import {userState} from '../../states/session';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {VerifyPhonePage} from '../Auth/VerifyPhone';
import {useLinks} from '../../hooks/common';
import {reviewsToReceivedAndGiven} from '../../helpers/reviews';
import {excludePrevPaths} from '../../helpers/mobNavigation';
import {deletedState} from '../../states/preload';
import {checkDeletedItems} from '../../helpers/item';
import {useGetGroupLinks} from '../../hooks/groups';

export const HomePageUserContainer: React.FC = () => {
  const {push, goBack} = useHistory();
  const [typeUser] = useRecoilState(StateTypeUser);
  const setCurrentLocation = useSetRecoilState(prevLocation);
  const user = useViewer();
  const fetchOptions = {
    where: {Lister: {have: {objectId: {equalTo: user?.objectId}}}},
    order: GQLItemOrder.updatedAt_DESC,
  };
  const isSupport = useCheckIsSupport({email: user?.email});
  const deleted = useRecoilValue(deletedState);
  const {data: items, loading} = useItems(fetchOptions);
  const requestedUserType = useCheckUserType(user?.objectId);
  const {call: updateAvatar, loading: loadingAvatar} = useUpdateProfilePhoto();
  const [community] = useRecoilState(currentCommunity);
  const {data: interestsLink} = useGetGroupLinks(community?.objectId);
  const {search} = useLocation();
  const verifyUserMode = new URLSearchParams(search).get('verify-user');
  const [showVerifyPopup, setShowPopupMode] = useState<boolean>(!!verifyUserMode);
  const PopupWrapper = useRef<HTMLDivElement>(null);
  const updateUser = useSetRecoilState(userState);
  const urlSearch = useUrlSearchManager();
  const section = urlSearch.get(SearchKey.section) || 'default';
  const resetSection = () => push(urlSearch.delete(SearchKey.section));
  const actions = useReport(entities.listing);
  const {getSignValue} = useGetCurrencyValue();
  const adminsLabels = useGetAdminsLabels();
  const permissions = useRecoilValue(userPermissions);
  const {getLink} = useLinks();
  const {data: reviewsData, loading: reviewsLoading} = useUserReviews(user?.objectId);
  const usersOnline = useRecoilValue(onlineUsers);
  const reviews = reviewsToReceivedAndGiven(reviewsData, user?.id, usersOnline);
  const previousPaths = useRecoilValue(prevPaths);
  useEffect(() => {
    setCurrentLocation(route.pageUser.path);
  }, []);

  const showPopup = () => {
    setShowPopupMode(true);
  };

  const closePopup = () => {
    setShowPopupMode(false);
    push(route.pageUser.get());
  };

  const onSubmit = () => {
    closePopup();
    updateUser((currVal) => currVal && {...currVal, isVerified: true});
  };
  const onRedirect = () => push(getLink(route.people.path));

  const onGoBack = () => {
    if (previousPaths.prev && previousPaths?.current !== previousPaths.prev && excludePrevPaths(previousPaths.prev)) {
      goBack();
    } else {
      onRedirect();
    }
  };

  const goToGroup = (link?: string) => {
    push(getLink(link || route.groups.path));
  };

  useEffect(() => {
    if (!PopupWrapper.current) return;
    PopupWrapper.current?.addEventListener('click', (e) => {
      if (e.target === PopupWrapper.current) {
        closePopup();
      }
    });
  }, [PopupWrapper.current, showVerifyPopup]);

  useEffect(() => {
    const verifyUserMode = new URLSearchParams(search).get('verify-user');
    setShowPopupMode(!!verifyUserMode);
  }, [search]);

  const listingsAvailable = hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.featureListings);
  const filteredItems = checkDeletedItems(deleted.items, items);
  return (
    <>
      <HomePageUser
        showPopup={showPopup}
        loading={loading}
        user={user}
        typeUser={typeUser}
        items={filteredItems}
        updateAvatar={updateAvatar}
        loadingAvatar={loadingAvatar}
        communityId={community?.id}
        reqUserType={requestedUserType}
        section={section}
        resetSection={resetSection}
        reportActions={actions}
        getSignValue={getSignValue}
        adminsLabels={adminsLabels}
        listingsAvailable={listingsAvailable}
        isSupport={isSupport}
        permissions={permissions}
        goBack={onGoBack}
        reviews={reviews}
        reviewsLoading={reviewsLoading}
        interestsLink={interestsLink}
        goToGroup={goToGroup}
      />
      {showVerifyPopup && <VerifyPhonePage onSubmit={onSubmit} refOnPopupWrapper={PopupWrapper} onClose={closePopup} />}
    </>
  );
};
