import React from 'react';
import {useTranslation} from 'react-i18next';
import {IsVerified, Listings, Status} from '../../../../ui-kit/ProfileIntro/labels';
import {Label} from '../../../../ui-kit/Labels';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {getResize} from '../../../../helpers/file';
import {PostHeadProps} from '../types';
import {PostTypeT} from '../../../../types/post';
import {TypeCommunity} from '../../../../types/auth';
import {
  ListingsLabelWrapper,
  PostAvatar,
  PostHeadLine,
  PostUserLine,
  PostUserName,
  WhiteLabel,
  AvatarWrapper,
} from '../styles';
import {Link} from 'react-router-dom';
import {getLastName} from '../../../../helpers/common';

export const PostHead: React.FC<PostHeadProps> = ({
  Author,
  isComment,
  type,
  listingsCount,
  profileLink,
  preventRedirect,
}) => {
  const {t} = useTranslation();
  const announcementText = t('community:loop.post.labels.announcement');

  return (
    <PostHeadLine isComment={isComment}>
      <AvatarWrapper onClick={preventRedirect}>
        <Link to={profileLink || '#'}>
          <PostAvatar showMob={true} isComment={isComment}>
            <Avatar size={'sm'} src={getResize(Author?.Avatar?.file?.url, 'lg')} $isOnline={Author?.isOnline} />
          </PostAvatar>
        </Link>
      </AvatarWrapper>
      <PostUserLine $isComment={isComment} to={profileLink || '#'} $nonClickable={profileLink === '#'}>
        <div>
          <PostUserName>
            {/*{getUserName(typeUser, Author?.firstName, Author?.lastName || Author?.firstLetter)}*/}
            {Author?.firstName} {getLastName(Author?.lastName, Author?.firstLetter)}
          </PostUserName>
          {Author?.isVerified && <IsVerified isVerified={Author?.isVerified} labelTruthy={''} />}
        </div>
        <div>
          {Author?.role && (
            <>
              {type === 'announcement' || (Author.role === TypeCommunity.resident && isComment) ? (
                <WhiteLabel>
                  <Label squared={true} variant={'quinary'}>
                    {t('common:entityType', {context: Author?.role})}
                  </Label>
                </WhiteLabel>
              ) : (
                <Status
                  status={Author?.role === TypeCommunity.resident ? 'secondary' : 'tertiary'}
                  name={Author.adminLabel || t('common:entityType', {context: Author?.role})}
                />
              )}
            </>
          )}
          {Author?.lastName !== '' && (
            <ListingsLabelWrapper listingsCount={listingsCount ?? 0}>
              <Listings count={listingsCount || 0} label={t('search:labels.listings')} />
            </ListingsLabelWrapper>
          )}
          {type === PostTypeT.announcement && (
            <WhiteLabel>
              <Label squared={true} variant={'quinary'}>
                {announcementText}
              </Label>
            </WhiteLabel>
          )}
        </div>
      </PostUserLine>
    </PostHeadLine>
  );
};
