import {Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {isValid} from '../dates';
import {Validation} from './Validation';

export class ValidationDate<T extends anyObject> extends Validation<T> {
  constructor(message?: Message) {
    super(message);
    this.$validators = [];
    this.$validators.push(Validator.of((date: unknown) => isValid(date as Date), message));
  }
}
