import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {MainWrapper, FormTopTitle, CategoryGrid, CategoryElement, SkipBtn} from '../styles';
import {Box} from '../../../common/Box';
import {onboardingServices} from '../../../../hooks/item';
import {IconImage} from '../../../../ui-kit/Icon/Image';
import {Button} from '../../../../ui-kit/Button/Button';

interface IProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selected: string[];
  nextStep: () => void;
  onSkipListing?: () => void;
  canSkipServices?: boolean;
}

export const ListingCategory: React.FC<IProps> = ({
  setSelected,
  selected,
  nextStep,
  canSkipServices,
  onSkipListing,
}) => {
  const {t} = useTranslation();

  const handleSelect = (item: keyof typeof onboardingServices) => () => {
    if (!selected?.includes(item)) {
      setSelected((prevState) => {
        const index = prevState.findIndex((el) => el === ('others' as keyof typeof onboardingServices));
        let removed: string | string[] = [];
        if (index !== -1) {
          removed = prevState.splice(index, 1);
        }
        return [...(prevState || []), item, ...removed];
      });
    } else {
      setSelected((prevState) => prevState?.filter((el) => el !== item));
    }
  };
  return (
    <>
      <MainWrapper>
        <FormTopTitle $noOffset>
          <Text variant={'secondary'} color={'quaternary'} size={38}>
            {t('auth:firstListing.firstTitle')}
          </Text>
        </FormTopTitle>
        <Box direction="column" display="flex" gap="16px">
          <Box margin="0 0 2px 0" textAlign="left">
            <Text variant={'primary'} color={'octonary'} size={16}>
              {t('auth:firstListing.firstSubtitle')}
            </Text>
            <CategoryGrid>
              {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /*@ts-ignore */}
              {Object.keys(onboardingServices)?.map((item: keyof typeof onboardingServices) => {
                return (
                  <CategoryElement $isSelected={selected?.includes(item)} onClick={handleSelect(item)} key={item}>
                    <IconImage width={'114px'} height={'114px'} type={onboardingServices?.[item]?.image} />
                    <Box display={'flex'} width={'100%'} gap={'2px'} direction={'column'}>
                      <Text variant={'primary'} size={16} weight={'bold'}>
                        {t(`common:onboarding_services.${item}.title`)}
                      </Text>
                      <Text variant={'primary'} size={14}>
                        {t(`common:onboarding_services.${item}.description`)}
                      </Text>
                    </Box>
                  </CategoryElement>
                );
              })}
            </CategoryGrid>
            <Button width={'100%'} disabled={!selected?.length} height={'46px'} onClick={nextStep}>
              {t(`auth:firstListing.buttons.continue`)}
            </Button>
          </Box>
        </Box>
        {canSkipServices && (
          <SkipBtn onClick={onSkipListing} $isRow={true}>
            <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
              {t('auth:firstPost.skip')}
            </Text>
          </SkipBtn>
        )}
      </MainWrapper>
    </>
  );
};
