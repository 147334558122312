import React from 'react';
import {inputPostType, InputsProps} from './types';
import {MessageInput} from './MessageInput';
import {PollInput} from './PollInput';
import {VideoInput} from './VideoInput';
import {CommentInput} from './CommentInput';

export const Inputs: React.FC<InputsProps> = ({
  resetType,
  inputType,
  user,
  handleSubmit,
  handleChange,
  success,
  values,
  typeUser,
  error,
  handleChangePostType,
  inputRef,
  generateData,
  loadingGenerate,
  ...props
}) => {
  if (
    inputType === inputPostType.message ||
    inputType === inputPostType.announcement ||
    inputType === inputPostType.request
  )
    return (
      <MessageInput
        resetType={resetType}
        user={user}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        success={success}
        values={values}
        typeUser={typeUser}
        error={error}
        isAnnouncement={inputType === inputPostType.announcement}
        isRequest={inputType === inputPostType.request}
        handleChangePostType={handleChangePostType}
        inputType={inputType}
        key={inputType}
        inputRef={inputRef}
        loadingGenerate={loadingGenerate}
        generateData={generateData}
        {...props}
      />
    );

  if (inputType === inputPostType.comment)
    return (
      <CommentInput
        resetType={resetType}
        user={user}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        success={success}
        values={values}
        typeUser={typeUser}
        error={error}
        handleChangePostType={handleChangePostType}
        inputType={inputType}
        key={inputType}
        isEdit={props.isEdit}
        {...props}
      />
    );

  if (inputType === inputPostType.poll)
    return (
      <PollInput
        resetType={resetType}
        user={user}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        success={success}
        values={values}
        typeUser={typeUser}
        error={error}
        handleChangePostType={handleChangePostType}
        inputType={inputType}
        {...props}
      />
    );

  if (inputType === inputPostType.video)
    return (
      <VideoInput
        resetType={resetType}
        user={user}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        success={success}
        values={values}
        typeUser={typeUser}
        error={error}
        handleChangePostType={handleChangePostType}
        inputType={inputType}
        {...props}
      />
    );

  return null;
};
