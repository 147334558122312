import {useMutation, useQuery} from '@apollo/client';
import {
  GetSubscrPayments,
  GetSubscrPaymentsRequest,
  GetSubscrPaymentsResponse,
  GetTransactions,
  GetTransactionsRequest,
  GetTransactionsResponse,
} from '../queries/transactions';
import {GQLOrderWhereInput, GQLTransactionWhereInput} from '../graphql.schema';
import {convertCentToDollar} from '../helpers/payment';
import {useOrders} from './order';
import {PaySubscription, PaySubscriptionRequestType, PaySubscriptionResponseType} from '../queries/payments';
import {useState} from 'react';

type useTransactionsOptions = {
  userId?: string;
  type?: 'transfer' | 'order';
  skip?: number;
  first?: number;
  where?: GQLTransactionWhereInput;
};

export const useTransactions = (options: useTransactionsOptions) => {
  const {data, loading, ...other} = useQuery<GetTransactionsResponse, GetTransactionsRequest>(GetTransactions, {
    variables: {
      where: {
        User: {
          have: {
            objectId: {
              equalTo: options?.userId,
            },
          },
        },
        ...(options?.type ? {type: {equalTo: options.type}} : {}),
        ...(options?.where ? options.where : {}),
      },
      skip: options.skip,
      first: options.first,
    },
  });

  const transactions = data?.transactions.edges.map((e) => e.node);

  return {data: transactions, count: data?.transactions.count, loading, ...other};
};

export type useGetPayoutsOrdersInfo = {
  isManager?: boolean;
  listerId?: string;
  where?: GQLOrderWhereInput;
};

export const useGetPayoutsOrdersInfo = (options: useGetPayoutsOrdersInfo) => {
  const {data, count, loading} = useOrders({
    skip: 0,
    where: options.where,
  });

  const totalAmount = options?.isManager
    ? convertCentToDollar(
        data?.reduce(
          (prev, current) =>
            (options?.listerId === current?.Lister?.objectId ? current.listerTotalAmt : 0) +
            (current.cfListerAmt + current.cfRequesterAmt) +
            prev,
          0,
        ),
      )
    : null;

  return {totalCount: count, totalAmount, infoLoading: loading};
};

export const useSubscrPayments = (userId: string, commId: string) => {
  const {data, loading, ...other} = useQuery<GetSubscrPaymentsResponse, GetSubscrPaymentsRequest>(GetSubscrPayments, {
    variables: {
      where: {
        User: {
          have: {
            id: {
              equalTo: userId,
            },
          },
        },
        Subscr: {
          have: {
            Communities: {
              have: {
                objectId: {
                  equalTo: commId,
                },
              },
            },
          },
        },
      },
    },
  });

  const payments = data?.subscrPayments.edges.map((e) => e.node);

  return {data: payments, loading, ...other};
};

export type useSubscrPayDataT = {
  paySubscr: (subscrId: string, after?: () => void, onSuccess?: () => void) => Promise<boolean>;
  success?: boolean | null;
  error?: string | null;
};

export const useSubscrPay = (): useSubscrPayDataT => {
  const [pay] = useMutation<PaySubscriptionResponseType, PaySubscriptionRequestType>(PaySubscription);
  const [success, setSuccess] = useState<boolean | null>();
  const [error, setError] = useState<string | null>();

  const paySubscr = async (subscrId: string, after?: () => void, onSuccess?: () => void) => {
    try {
      const response = await pay({
        variables: {
          subscrPaymentId: subscrId,
        },
      });

      setSuccess(response.data?.paySubscription?.success);
      setError(response.data?.paySubscription?.error);
      after?.();
      if (response.data?.paySubscription?.success) onSuccess?.();
      setTimeout(() => {
        setSuccess(null);
        setError(null);
      }, 5000);
      return true;
    } catch (e) {
      setError(e?.message);
      return false;
    }
  };

  return {paySubscr, success, error};
};
