import React from 'react';
import {Link} from '../../ui-kit/Typography/Link';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {MemoLabelCard} from './LabelCard';
import {ContainerListingCard, InnerListingCard} from './styles';
import {TCompany} from '../../types/company';

interface IProps {
  company: Partial<TCompany>;
  to: string;
  src: string;
}

const SimpleCompanyCard: React.FC<IProps> = ({company, to, src}) => {
  const {category, name, promoTagline, promotion} = company;
  return (
    <ContainerListingCard>
      <Link to={to}>
        <InnerListingCard>
          <PhotoCard aspectRatio={'300:200'} src={src} />
          <MemoLabelCard name={name} category={category} promotionTag={promoTagline} isPromotion={promotion || false} />
        </InnerListingCard>
      </Link>
    </ContainerListingCard>
  );
};

const MemoSimpleCompanyCard = React.memo(SimpleCompanyCard);
export {MemoSimpleCompanyCard};
