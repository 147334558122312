import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {useRecoilState} from 'recoil';

import {
  GoogleParamsType,
  FBparamsType,
  AppleParamsType,
  AuthDataTypeGoogle,
  AuthDataTypeFB,
  AuthDataTypeApple,
  UserSocialDataT,
  ConnectSocialInfoT,
  SocialLoadingButtonT,
} from '../types/auth';
import {
  SignWithFBRequestType,
  SignWithGoogleRequestType,
  SocialConnect,
  SocialDisconnect,
  TSocialDisconnectRequest,
} from '../queries/auth';
import {userState} from '../states';
import {User} from '../queries/types/user';
import {isFromApp} from '../ui-kit/Navigation/mobile/styles';
import {isAndroid, isIOS} from 'react-device-detect';
import {RNMessages, RNreceiveKey, SendMessageToRNApp} from '../helpers/common';

export type SocialRequestType = <T, D>(
  authData?: T,
  email?: string,
  userData?: UserSocialDataT,
  provider?: string,
  connectData?: D,
) => void;

// Google
type GoogleAuthorizeType = (
  reqWithSocial?: SocialRequestType,
  resetError?: () => void,
) => {
  reqWIthGoogle: (responce?: GoogleParamsType) => void;
  error?: string | null;
};

export const useGoogleAuth: GoogleAuthorizeType = (reqWithSocial, resetError) => {
  const [error, setError] = useState<string | null>();

  const reqWIthGoogle = (googleResponse?: GoogleParamsType) => {
    try {
      resetError?.();
      if (!googleResponse?.clientId) return;
      const {credential}: GoogleParamsType = googleResponse;
      const profileObj = JSON.parse(atob(credential?.split('.')?.[1]?.replace(/-/g, '+').replace(/_/g, '/') || ''));
      if (!profileObj) throw new Error('Registration failed');

      const data = {id: profileObj.sub, access_token: 'accessToken', id_token: credential};
      const authData: AuthDataTypeGoogle = {
        google: data,
      };

      const userData = {
        firstName: profileObj.given_name || '',
        lastName: profileObj.family_name || '',
        imageUrl: profileObj.picture,
      };

      const email = profileObj.email;
      const provider = 'google';
      const connectData = data;
      reqWithSocial?.(authData, email, userData, provider, connectData);
    } catch (error) {
      console.log(error);
      setError(error.message);
      return;
    }
  };

  useGetNativeResponse({
    singFN: reqWIthGoogle,
    receiveKey: RNreceiveKey.loginGoogle,
  });
  return {reqWIthGoogle, error};
};

//Facebook
type FacebookAuthorizeType = (
  reqWithSocial?: SocialRequestType,
  resetError?: () => void,
) => {
  reqWIthFacebook: (responce?: FBparamsType) => void;
  error?: string | null;
};

export const useFacebookAuth: FacebookAuthorizeType = (reqWithSocial, resetError) => {
  const [error, setError] = useState<string | null>();

  const reqWIthFacebook = (facebookResponse?: FBparamsType) => {
    try {
      resetError?.();
      if (!facebookResponse?.id) return;
      const {
        id,
        accessToken,
        data_access_expiration_time: expirationDate,
        email,
        name,
        picture,
      }: FBparamsType = facebookResponse;
      const correctExpirationDate: string = new Date(+new Date() - +new Date(expirationDate)).toISOString();
      const data = {
        id,
        access_token: accessToken,
        expiration_date: correctExpirationDate,
      };
      const authData: AuthDataTypeFB = {
        facebook: data,
      };
      const userData = {
        firstName: name?.split(' ')[0] || '',
        lastName: name?.split(' ')[1] || '',
        imageUrl: picture?.data?.url,
      };
      const provider = 'facebook';
      const connectData = data;

      reqWithSocial?.(authData, email, userData, provider, connectData);
    } catch (error) {
      console.log(error);
      setError(error.message);
      return;
    }
  };

  useGetNativeResponse({
    singFN: reqWIthFacebook,
    receiveKey: RNreceiveKey.loginFacebook,
  });
  return {reqWIthFacebook, error};
};

// Apple
type AppleAuthorizeType = (
  reqWithSocial?: SocialRequestType,
  resetError?: () => void,
) => {
  reqWIthApple: (responce?: AppleParamsType) => void;
  error?: string | null;
};

export const useAppleAuth: AppleAuthorizeType = (reqWithSocial, resetError) => {
  const [error, setError] = useState<string | null>();

  const reqWIthApple = (appleResponse?: AppleParamsType) => {
    const token = appleResponse?.authorization?.id_token || '';
    try {
      resetError?.();
      if (!appleResponse?.authorization?.id_token) return;
      const email = appleResponse?.user?.email;
      const data = {
        id: JSON.parse(atob(token?.split('.')?.[1] || ''))?.sub || '',
        token: appleResponse?.authorization?.id_token || '',
      };
      const authData: AuthDataTypeApple = {
        apple: data,
      };
      const userData = {
        firstName: appleResponse?.user?.name?.firstName || '',
        lastName: appleResponse?.user?.name?.lastName || '',
      };
      const provider = 'apple';
      const connectData = data;

      reqWithSocial?.(authData, email, userData, provider, connectData);
    } catch (error) {
      console.log(error);
      setError(error.message);
      return;
    }
  };

  return {reqWIthApple, error};
};

// linking an account to social networks
type SocialConnectT = () => {
  reqWIthGoogle: (response?: GoogleParamsType) => void;
  reqWIthFacebook: (response?: FBparamsType) => void;
  reqWIthApple: (response?: any) => void;
  connectSocialInfo?: ConnectSocialInfoT;
  disconnectAccount?: (provider: string) => void;
  loadingButton?: SocialLoadingButtonT;
  error?: string | null;
};

export const useSocialConnect: SocialConnectT = () => {
  const [user, updateUser] = useRecoilState(userState);
  const [error, setError] = useState<string | null>();
  const [connectSocialInfo, setConnectSocialInfo] = useState<ConnectSocialInfoT>();
  const [loadingButton, setLoadingButton] = useState<SocialLoadingButtonT>({
    google: false,
    facebook: false,
    apple: false,
  });
  const [SocialConnectRequest] = useMutation<
    TSocialDisconnectRequest | SignWithFBRequestType | SignWithGoogleRequestType
  >(SocialConnect);
  const [SocialDisconnectRequest] = useMutation<TSocialDisconnectRequest>(SocialDisconnect);
  const {reqWIthGoogle} = useGoogleAuth(connectAccount, resetError);
  const {reqWIthFacebook} = useFacebookAuth(connectAccount, resetError);
  const {reqWIthApple} = useAppleAuth(connectAccount, resetError);

  function resetError() {
    setError(null);
  }

  const checkSocialconnect = (user?: User | null) => {
    if (!user) return;
    const data = {
      isFacebook: !!user?.authData?.facebook?.id,
      isGoogle: !!user?.authData?.google?.id,
      isApple: !!user?.authData?.apple?.id,
    };
    setConnectSocialInfo(data);
  };

  useEffect(() => {
    checkSocialconnect(user);
  }, [user?.authData]);

  const disconnectAccount = async (provider: string) => {
    try {
      setLoadingButton({...loadingButton, [provider]: true});
      const result = await SocialDisconnectRequest({
        variables: {
          provider: provider,
        },
      });
      if (!result) return false;
      updateUser((prev) => ({...((prev || {}) as User), authData: {...prev?.authData, [provider]: null}}));
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setLoadingButton({...loadingButton, [provider]: false});
    }
    return true;
  };

  async function connectAccount<T, D>(
    authData?: T,
    email?: string,
    userData?: UserSocialDataT,
    provider?: string,
    connectData?: D,
  ): Promise<boolean> {
    if (!provider) return false;
    try {
      setLoadingButton({...loadingButton, [provider]: true});

      const result = await SocialConnectRequest({
        variables: {
          provider: provider,
          authData: connectData,
        },
      });
      if (!result || !user) return false;
      updateUser({
        ...user,
        authData: {...user.authData, ...authData},
      });
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setLoadingButton({...loadingButton, [provider]: false});
    }
    return true;
  }

  return {
    reqWIthGoogle,
    reqWIthFacebook,
    reqWIthApple,
    connectSocialInfo,
    disconnectAccount,
    loadingButton,
    error: error,
  };
};

export const useFacebookAppFix = () => {
  const fbReady = !isFromApp();
  const onFBClick = () => {
    if (fbReady) return;
    SendMessageToRNApp(RNMessages.facebookLoginClicked);
  };
  return {fbReady, onFBClick};
};

export const useGoogleIosAppFix = () => {
  const googleReady = !isFromApp();
  const onGoogleLoginClick = () => {
    if (googleReady) return;
    SendMessageToRNApp(RNMessages.googleLoginClicked);
  };
  return {googleReady, onGoogleLoginClick};
};

type UseGetNativeResponsePropsT = {
  singFN: (data: any) => void;
  receiveKey: string;
};

export const useGetNativeResponse = ({singFN, receiveKey}: UseGetNativeResponsePropsT) => {
  useEffect(() => {
    if (!isFromApp()) return;
    const handler = (event: any) => {
      try {
        const messageObject = JSON.parse(event.data);
        if (messageObject?.key !== receiveKey) return;
        const data = messageObject?.data;
        singFN(data);
      } catch (error) {
        console.log('post message error', error);
      }
    };
    isIOS && window?.addEventListener('message', handler);
    isAndroid && document?.addEventListener('message', handler);
    return () => {
      isIOS && window?.removeEventListener('message', handler);
      isAndroid && document?.addEventListener('message', handler);
    };
  }, [singFN]);
};
