import React, {useMemo, useRef, useState} from 'react';
import {PersonCardT} from '../types';
import {CardWrapper, InfoWrapper, LastColumn, PeopleCard, PersonLabels, PersonName} from '../styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {Listings} from '../../../ui-kit/ProfileIntro/labels';
import {PersonActions} from './PersonActions';
import {useTranslation} from 'react-i18next';
import {route} from '../../../constants/routes';
import {PersonType} from './PersonType';
import {IconSvg} from '../../../ui-kit/ProfileIntro/styles';
import {useLinks} from '../../../hooks/common';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {Media} from '../../../ui-kit/theme';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {MobileMenuButton} from '../../../ui-kit/Button/MobileMenuButton';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {entities} from '../../../hooks/report';
import {getResize} from '../../../helpers/file';
import {getUserName} from '../../../helpers/user';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../../ui-kit/Popup';
import {EmptyTag} from '../../../containers/MobileTabs';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';

export const PersonCard: React.FC<PersonCardT> = ({
  objectId: userId,
  //id: _id,
  firstName,
  lastName,
  Avatar: avatar,
  isVerified,
  actionsType,
  manageActions,
  isManager,
  isOnline,
  joinRequestId,
  isAdmin,
  viewerId,
  reportActions,
  adminLabels,
  typeUser,
  //listingCount: memberListingCount,
  listingsCount,
}) => {
  const lc = useRef<number>(0);
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const profileLink = viewerId !== userId ? getLink(route.profile.get({id: userId})) : getLink(route.pageUser.path);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const item = {objectId: userId};

  const report = () => {
    setIsShowReportModal(true);
  };

  const menuOptions = [
    ...(viewerId !== userId && !isAdmin && !isManager
      ? ([
          {
            title: t('people:actions.report'),
            onClick: report,
            render: 'danger',
          },
        ] as MenuItemType[])
      : []),
  ];

  const mobileMenuOptions = useMemo(
    () =>
      menuOptions.map((o) => ({
        title: o.title ?? '',
        onClick: () => o.onClick?.(userId),
        to: o.to?.(''),
        type: o.render as 'regular' | 'danger',
      })),
    [menuOptions, userId],
  );

  const reportParams = {
    title: t('common:report.title', {entity: t(`common:entities.${entities.user}`)}),
    subtitle: t('common:report.subtitle'),
    okButton: t('common:report.confirmReport'),
    textAreaLabel: t('common:report.textAreaLabel'),
    close: () => {
      setIsShowReportModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowReportModal(false);
        setIsShowInfoModal(true);
      };

      return reportActions?.report?.({id: userId, onSuccess, text});
    },
  };

  const infoParams = {
    title: t('common:report.success.title', {entity: t(`common:entities.${entities.user}`)}),
    subtitle: t('common:report.success.subtitle'),
    close: () => {
      setIsShowInfoModal(false);
    },
  };
  const PortalWrapper = isMobile ? BasePortal : EmptyTag;
  if (!lc.current && listingsCount) {
    lc.current = listingsCount;
  }
  return (
    <>
      <PeopleCard to={profileLink}>
        <CardWrapper>
          <Avatar src={getResize(avatar?.file?.url, 'md')} size={'sm'} $isOnline={!!isOnline} />
          <InfoWrapper>
            <PersonName>
              {getUserName(typeUser, firstName, lastName)}
              {isVerified && <IconSvg type="verified-2" stroke="green" width={'14'} height={'14'} />}
            </PersonName>
            <PersonLabels>
              <PersonType isAdmin={isAdmin} isManager={isManager} adminLabels={adminLabels} userId={userId} />
              <Listings count={lc.current || 0} label={t('search:labels.listings')} />
            </PersonLabels>
          </InfoWrapper>
          <LastColumn className={'menu-btn__wrapper'}>
            {!!menuOptions.length && (
              <HiddenElement mq={Media.down.s}>
                <MenuButton item={item} menu={Menu} options={menuOptions} prevent={true} />
              </HiddenElement>
            )}
            {!!mobileMenuOptions.length && (
              <HiddenElement mq={Media.up.m}>
                <MobileMenuButton entries={mobileMenuOptions} isPortal={true} portalProvider={IsModalProvider} />
              </HiddenElement>
            )}
          </LastColumn>
        </CardWrapper>
        <PersonActions actions={manageActions} actionsType={actionsType} joinRequestId={joinRequestId} />
      </PeopleCard>

      {isShowReportModal && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              isHaveTextArea
              title={reportParams.title}
              subTitle={reportParams.subtitle}
              buttonType={'danger'}
              textAreaLabel={reportParams.textAreaLabel}
              okText={reportParams.okButton}
              onClose={reportParams.close}
              onOk={reportParams.onOk}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
      {isShowInfoModal && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              title={infoParams.title}
              subTitle={infoParams.subtitle}
              buttonType={'primary'}
              onClose={infoParams.close}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
    </>
  );
};
