import {Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';
import {Image} from '../Image';

export class ValidationImage<T extends anyObject> extends Validation<T> {
  constructor(message?: Message) {
    super(message);
    this.$validators.push(Validator.of((value: unknown) => Image.isImage(value), message));
  }

  public maxSize(size: number, message?: Message) {
    this.$message = message;

    const checkSize = (value: T[keyof T]) => {
      if (!(value instanceof File)) return false;
      return value.size <= size;
    };

    this.$validators.push(Validator.of(checkSize, this.$message));
    return this;
  }
}
