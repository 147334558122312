import styled from 'styled-components';
import {fontPrimaryMedium, fontPrimaryReg} from '../../common/styles';
import {Button} from '../../../ui-kit/Button/Button';
import {Media} from '../../../ui-kit/theme/breakpoints';

export const LabelsWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  margin-bottom: 12px;
  & > * {
    margin-right: 8px;
  }
`;

export const AttendingLabel = styled.div`
  font-size: 10px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._13};
`;

export const EventInfoRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const EventInfoTitle = styled.div`
  font-size: 12px;
  margin-bottom: 6px;
  color: ${({theme}) => theme.palette.grayscale._13};
`;
export const EventInfoDescr = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
`;

export const ReqButton = styled(Button)<{$isDisabled?: boolean}>`
  ${({$isDisabled}) => $isDisabled && ` opacity: 0.5; pointer-events: none;`}
`;

export const DatepickerLine = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CardTitleWrapper = styled.div`
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: block;
    white-space: nowrap;
  }
`;

export const EventDatePickerRow = styled.div`
  margin-bottom: 10px;
`;

export const RecurringModalWrapper = styled.div`
  padding: 24px 24px 24px 24px;
  box-sizing: border-box;
  max-width: 390px;
  margin: 0 auto;
`;

export const RMTitle = styled.div`
  font-size: 20px;
  ${fontPrimaryMedium};
  margin-bottom: 9px;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${Media.down.xxs} {
    font-size: 16px;
  }
`;

export const RMDescr = styled.div`
  font-size: 15px;
  ${fontPrimaryReg};
  line-height: 150%;
  margin-bottom: 18px;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${Media.down.xxs} {
    font-size: 13px;
  }
`;

export const RMButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RMButton = styled(Button)`
  width: 150px;
  ${Media.down.xxs} {
    width: 120px;
    font-size: 14px;
  }
`;
