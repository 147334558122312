import {route} from '../constants/routes';
import {TypeCommunity} from '../types/auth';
import {PointerFile} from '../types/common';
import {MemberT} from '../types/people';
import {PostFormValues, PostTypeT, TPost} from '../types/post';

export const createProfileLink = (
  getLink: (route?: any, alias?: string | undefined) => any,
  Author?: MemberT,
  type?: PostTypeT,
  sentAs?: string,
  typeUser?: TypeCommunity,
) => {
  const avatarRedirectRoute =
    type === PostTypeT.announcement
      ? getLink(route.loop.path)
      : Author?.id && getLink(route.profile.get({id: Author?.objectId || ''}));

  const profileLink: string =
    typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin
      ? avatarRedirectRoute
      : sentAs === 'community'
      ? '#'
      : avatarRedirectRoute;
  return profileLink;
};

export const initLocalPosts = (postOptions: {label: string; value: string}[]) => {
  const initial: Record<string, TPost[]> = {};
  postOptions?.forEach((el) => {
    initial[el.value] = [];
  });
  return initial;
};

export const toLocalEditedState = (
  values: Partial<PostFormValues>,
  Attachments: Partial<PointerFile>[],
): Partial<TPost> => {
  return {
    text: values?.text,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Attachments: Attachments?.map((att) => ({
      file: {...att?.file},
      value: {...att, ...att?.file},
    })),
    Options: values?.options?.map((el) => ({
      ...el,
    })),
  };
};
