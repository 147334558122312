export const ItemOptions = {
  rent: 'Rent',
  sell: 'Sell',
  service: 'Service',
  month: 'Month',
  week: 'Week',
  hour: 'Hour',
  day: 'Day',
  free: 'Free',
  coffee: 'Coffee',
  fee: 'Money',
  allListings: 'Show all',
  myListings: 'My listings',
  liked: 'Liked',
};
