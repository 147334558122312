import React, {useRef} from 'react';
import {UserMenuListWrapper, UserMenuContainer} from './styles';
import {AvatarWrapper, EntryButtonWrapper, RelativeWrapper, TextBlock, Title, WrapperFlex} from './../Settings/styles';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {useViewer} from '../../../../hooks/user';
import {MemberSkeletonShort} from '../../../common/Skeletons/PeopleSkeleton';
import {currentCommunity} from '../../../../states/community';
import {useRecoilState} from 'recoil';
import {ItemsSearchParams} from '../../../../containers/People/types';
import {useGetCommunityPeople} from '../../../../hooks/people';
import {useTagMenuCorrectPosition} from '../../../../hooks/common';

export const UserMenu = (props: {
  top: number;
  left: number;
  handleSetUser: (e: React.SyntheticEvent, user: string, name: string, setUserProfile: (param: string) => void) => void;
  setUserProfile: (param: string) => void;
  searchText?: string;
}) => {
  const viewer = useViewer();
  const menuRef = useRef<HTMLDivElement>(null);
  const [comm] = useRecoilState(currentCommunity);
  const searchParams: ItemsSearchParams = {
    firstName: {
      matchesRegex: props.searchText?.toLocaleLowerCase() ?? '',
      options: 'i',
    },
  };
  const {data: members} = useGetCommunityPeople({
    id: comm?.id,
    where: searchParams,
  });
  let isAdmin = false;
  members.AllMembers.forEach((member) => {
    if (member?.id === viewer?.id) {
      isAdmin = Boolean(member?.isAdmin);
    }
  });
  const listOfUsers = members.AllMembers.filter((member) => member?.id !== viewer?.id);
  useTagMenuCorrectPosition(menuRef);
  return (
    <UserMenuContainer style={{top: props.top, left: props.left}} ref={menuRef}>
      <UserMenuListWrapper>
        {members !== undefined && members.Residents !== undefined && members?.Residents?.length > 0 ? (
          listOfUsers.map((el: any, index: number) => (
            <UserMenuItem
              user={el}
              isAdmin={isAdmin}
              key={index}
              handleSetUser={props.handleSetUser}
              setUserProfile={props.setUserProfile}
            />
          ))
        ) : (
          <MemberSkeletonShort />
        )}
      </UserMenuListWrapper>
    </UserMenuContainer>
  );
};

export const UserMenuItem = (props: {
  user: any;
  isAdmin: boolean;
  handleSetUser: (e: React.SyntheticEvent, user: string, name: string, setUserProfile: (param: string) => void) => void;
  setUserProfile: (param: string) => void;
}) => {
  const user = props.user;
  return (
    <RelativeWrapper>
      <EntryButtonWrapper
        onClick={(e) => {
          props.handleSetUser(e, user?.objectId, user?.firstName + ' ' + user?.lastName[0] + '.', props.setUserProfile);
        }}>
        <WrapperFlex flexStart={true}>
          <AvatarWrapper>
            <Avatar src={user?.Avatar?.file?.url} size={'sm'} />
          </AvatarWrapper>
          <TextBlock>
            <Title $overflow={true}>
              {user.firstName + ' ' + (props.isAdmin ? user.lastName : user.lastName[0] + '.')}
            </Title>
          </TextBlock>
        </WrapperFlex>
      </EntryButtonWrapper>
    </RelativeWrapper>
  );
};
