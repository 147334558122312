import {Community, CommunityAdmin} from './types/community';
import {gql} from '@apollo/client';
import MemberFragment, {AdminFragment, MemberWithNameFragment} from './fragments/people';
import {Connection} from './types/parse';
import {memberJoinRequest} from '../types/people';
import {User} from './types/user';
import {GQLUpdateCommunityAdminFieldsInput} from '../graphql.schema';
import {LocationFragment} from './fragments/locations';

export type GetCommunityMembersReqType = {
  id: string;
};

export type CommunityMembersResponseT = Pick<Community, 'id' | 'objectId' | 'countMembers' | 'Residents' | 'Owner'> & {
  Admins: Connection<CommunityAdmin>;
};

export type GetCommunityMembersResType = {
  community: CommunityMembersResponseT;
};

export const GetCommunityMembersRequest = gql`
  query community(
    $cursor: String
    $skip: Int
    $first: Int
    $where: UserWhereInput
    $id: ID!
    $order: [UserOrder!]
    $whereAdmin: CommunityAdminWhereInput
  ) {
    community(id: $id) {
      id
      objectId
      Owner {
        ...MemberFragment
      }
      Residents(after: $cursor, skip: $skip, order: $order, first: $first, where: $where) {
        ... on UserConnection {
          count
          edges {
            cursor
            node {
              ...MemberFragment
            }
          }
        }
      }
      Admins(where: $whereAdmin) {
        ... on CommunityAdminConnection {
          count
          edges {
            node {
              ...AdminFragment
            }
          }
        }
      }
    }
  }
  ${MemberFragment}
  ${AdminFragment}
`;

export const GetCommunityMembersRequestManager = gql`
  query community(
    $cursor: String
    $skip: Int
    $first: Int
    $where: UserWhereInput
    $id: ID!
    $order: [UserOrder!]
    $whereAdmin: CommunityAdminWhereInput
  ) {
    community(id: $id) {
      id
      objectId
      Owner {
        ...MemberWithNameFragment
      }
      Residents(after: $cursor, skip: $skip, order: $order, first: $first, where: $where) {
        ... on UserConnection {
          count
          edges {
            cursor
            node {
              ...MemberWithNameFragment
            }
          }
        }
      }
      Admins(where: $whereAdmin) {
        ... on CommunityAdminConnection {
          count
          edges {
            node {
              ...AdminFragment
            }
          }
        }
      }
    }
  }
  ${MemberWithNameFragment}
  ${AdminFragment}
`;

export type GetNewMembersReqType = {
  id: string;
};

export type NewMembersResponseT = Pick<Community, 'id' | 'objectId'> & {
  JoinRequests: Connection<memberJoinRequest>;
};
export type GetNewMembersResType = {
  community: NewMembersResponseT;
};

export const GetNewMembersRequest = gql`
  query community($cursor: String, $first: Int, $where: JoinRequestWhereInput, $id: ID!, $order: [JoinRequestOrder!]) {
    community(id: $id) {
      id
      objectId
      JoinRequests(after: $cursor, order: $order, first: $first, where: $where) {
        ... on JoinRequestConnection {
          count
          edges {
            cursor
            node {
              objectId
              User {
                ...MemberFragment
              }
            }
          }
        }
      }
    }
  }
  ${MemberFragment}
`;

export type GetUserResType = {
  user: User;
};

export type GetUserReqType = {
  id: string;
};

export const GetUserRequest = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...MemberFragment
      Location {
        ...LocationFragment
      }
    }
  }
  ${MemberFragment}
  ${LocationFragment}
`;

export type GetUsersAvatarResType = {
  getResidentsAvatar: {
    users: User[];
  };
};

export type GetUsersAvatarReqType = {
  communityId: string;
};

export const GetUsersAvatar = gql`
  mutation getResidentsAvatar($communityId: String!) {
    getResidentsAvatar(input: {communityId: $communityId}) {
      users
    }
  }
`;

export const GetUserRequestFull = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...MemberWithNameFragment
      Location {
        ...LocationFragment
      }
    }
  }
  ${MemberWithNameFragment}
  ${LocationFragment}
`;

export type setUserAdminReqType = {
  userId: string;
  communityId: string;
  label?: string;
  permissionList: Array<string>;
};

export type SetUserAdminResType = {
  communityAdminCreate: {
    success: boolean;
    communityAdminId: string;
  };
};

export const SetUserAdminRequest = gql`
  mutation makeUserAdmin($userId: ID!, $communityId: ID!, $label: String, $permissionList: [String]) {
    communityAdminCreate(
      input: {userId: $userId, communityId: $communityId, label: $label, permissionList: $permissionList}
    ) {
      success
      communityAdminId
    }
  }
`;

enum JoinRequestStatuses {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}

export type SetJoinRequestStatusReqType = {
  id: string;
  fields: {
    status: JoinRequestStatuses;
  };
};

export type SetJoinRequestStatusResType = {
  JoinRequest?: {
    status: JoinRequestStatuses;
  };
};

export const SetJoinRequestStatus = gql`
  mutation updateJoinRequest($id: ID!, $fields: UpdateJoinRequestFieldsInput) {
    updateJoinRequest(input: {id: $id, fields: $fields}) {
      joinRequest {
        status
      }
    }
  }
`;

export interface TUpdateCommunityAdminResponse {
  updateCommunityAdmin: {
    communityAdmin: CommunityAdmin;
  };
}

export interface TUpdateCommunityAdminRequest {
  id: string;
  fields: GQLUpdateCommunityAdminFieldsInput;
}

export const UpdateCommunityAdminQuery = gql`
  mutation updateCommunityAdmin($id: ID!, $fields: UpdateCommunityAdminFieldsInput) {
    updateCommunityAdmin(input: {id: $id, fields: $fields}) {
      communityAdmin {
        ...AdminFragment
      }
    }
  }
  ${AdminFragment}
`;

export interface EditCommunityAdminResponse {
  communityAdminEdit: boolean;
}

export interface EditCommunityAdminRequest {
  userId: string;
  communityId: string;
  label?: string;
  permissionList?: string[];
}

export const EditCommunityAdminQuery = gql`
  mutation communityAdminEdit($userId: ID!, $communityId: ID!, $label: String, $permissionList: [String]) {
    communityAdminEdit(
      input: {userId: $userId, communityId: $communityId, label: $label, permissionList: $permissionList}
    )
  }
`;

export interface TGetAdminsLabelsResponse {
  communityAdmins: Connection<{
    objectId: string;
    label?: string;
    User: {
      objectId: string;
    };
  }>;
}

export interface TGetAdminsLabelsRequest {
  communityId: string;
}

export const GetAdminsLabelsQuery = gql`
  query adminsLabels($communityId: ID!) {
    communityAdmins(where: {Community: {have: {objectId: {equalTo: $communityId}}}}) {
      edges {
        node {
          id
          objectId
          label
          User {
            objectId
          }
        }
      }
    }
  }
`;

export const GetCommunityUsersShort = gql`
  query community($cursor: String, $skip: Int, $first: Int, $where: UserWhereInput, $id: ID!, $order: [UserOrder!]) {
    community(id: $id) {
      Residents(after: $cursor, skip: $skip, order: $order, first: $first, where: $where) {
        ... on UserConnection {
          count
          edges {
            cursor
            node {
              objectId
              firstName
              lastName
              firstLetter
            }
          }
        }
      }
    }
  }
`;
