import React from 'react';
import {SetItemsSearchParams} from '../../containers/People/types';
import {useTranslation} from 'react-i18next';
import {MobileSearchBlock} from '../common/SearchBlock/MobileSearchBlock';
import {getPeopleOptions} from '../../helpers/people';
import {PeopleSearchType} from '../../types/people';
import {LabelValuePair} from '../../types/settings';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {CommunityStateType} from '../../states/community';

interface TProps {
  setSearchParams: SetItemsSearchParams;
  setPeopleType: React.Dispatch<React.SetStateAction<PeopleSearchType>>;
  invite: boolean;
  community?: CommunityStateType;
}

export const MobileSearch: React.FC<TProps> = ({setSearchParams, setPeopleType, community}) => {
  const {t} = useTranslation();
  const peopleOptions = getPeopleOptions(t, community);
  const {push} = useHistory();
  const {getLink} = useLinks();

  const onSearch = (searchText?: string, next?: Partial<LabelValuePair>) => {
    if (next?.value === t('search:people.myProfile')) push(getLink(route.pageUser.path));
    if (searchText)
      setSearchParams({
        firstName: {matchesRegex: searchText?.toLocaleLowerCase?.() ?? '', options: 'i'},
      });
    else {
      setSearchParams(undefined);
    }
    setPeopleType(next?.value ? (next.value as PeopleSearchType) : PeopleSearchType.Everyone);
  };

  return <MobileSearchBlock placeholder={t('search:labels.whoLooking')} options={peopleOptions} onSearch={onSearch} />;
};
