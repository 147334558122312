import React from 'react';
import {WrapperPageListings, WrapperSearch} from './styles';
import {CommunityStateType} from '../../states/community';
import {ListAmenitiesWithPreview} from './ListAmenitiesWithPreview';
import {TypeCommunity} from '../../types/auth';
import {SetAmenitiesSearchParams} from '../../containers/Amenities/types';
import {Search} from './Search';
import {MobileSearch} from './MobileSearch';
import {route} from '../../constants/routes';
import {EmptyState} from '../EmptyState/EmptyState';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {Amenity} from '../../queries/types/amenity';

interface IProps {
  community: CommunityStateType;
  ownAmenities?: Partial<Amenity>[];
  loading?: boolean;
  allAmenities?: Partial<Amenity>[];
  fetchDataAllAmenities: () => void;
  hasMoreAllAmenities: boolean;
  newAmenities?: Partial<Amenity>[];
  fetchDataNewAmenities: () => void;
  hasMoreNewAmenities: boolean;
  onDelete?: (id?: string) => Promise<boolean>;
  setSearchParams: SetAmenitiesSearchParams;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  onUnPublish: (id?: string) => () => Promise<void>;
  onPublish: (id?: string) => () => Promise<void>;
}

export const PageManager: React.FC<IProps> = ({
  allAmenities,
  hasMoreAllAmenities,
  fetchDataAllAmenities,
  newAmenities,
  community,
  loading,
  setSearchParams,
  userId,
  reportActions,
  getSignValue,
  onDelete,
  onUnPublish,
  onPublish,
}) => {
  const {getLink} = useLinks();
  return (
    <WrapperPageListings>
      <WrapperSearch>
        <Search setSearchParams={setSearchParams} />
        <MobileSearch setSearchParams={setSearchParams} />
      </WrapperSearch>
      {!allAmenities?.length && !newAmenities?.length && !loading ? (
        <EmptyState />
      ) : (
        <ListAmenitiesWithPreview
          communityId={community?.id}
          amenities={allAmenities}
          fetchDataAmenities={fetchDataAllAmenities}
          hasMoreAmenities={hasMoreAllAmenities}
          isPreview={false}
          typeUser={TypeCommunity.manager}
          featuredDivided={true}
          loading={loading}
          onDelete={onDelete}
          userId={userId}
          reportActions={reportActions}
          getSignValue={getSignValue}
          isHideLabel={true}
          onUnPublish={onUnPublish}
          onPublish={onPublish}
          canManage={true}
        />
      )}
      <AddButtonPortal link={getLink(route.createAmenity.path)} />
    </WrapperPageListings>
  );
};
