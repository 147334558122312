import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {fontPrimaryMedium, fontPrimaryReg} from '../styles';

export const ModalOverlay = styled.div<{$isWide?: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  width: 100%;
  cursor: initial;

  ${Media.down.s} {
    justify-content: center;
    align-items: flex-end;
  }
`;

export const ModalWrapper = styled.div<{$isWide?: boolean}>`
  width: 100%;
`;

export const PeopleModalWrapper = styled.div<{$isWide?: boolean}>`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

export const ModalTemplateWrapper = styled.div`
  border-radius: 12px;
  padding: 40px 31px 55px 31px;
  box-sizing: border-box;
  position: relative;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  width: 550px;
  margin: 0 auto;
  ${Media.down.m} {
    width: 370px;
    padding: 20px 21px 35px 21px;
  }
`;

export const ModalTemplateInviteWrapper = styled.div`
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  width: 340px;
  padding: 10px 16px;
  margin: 0 auto;
  margin-bottom: 45px;
  ${Media.down.xxs} {
    width: 320px;
    padding: 10px 9px;
  }
`;

export const Title = styled.div`
  ${fontPrimaryMedium};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 32px;
  ${Media.down.m} {
    font-size: 20px;
  }
`;

export const ModalTextWrapper = styled.div`
  margin-top: 20px;
  ${fontPrimaryReg};
  font-size: 18px;
  line-height: 24px;
  color: ${({theme}) => theme.palette.text.octonary};

  ${Media.down.m} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ModalButtonWrapper = styled.div`
  margin-top: 50px;
  ${Media.down.m} {
    margin-top: 30px;
  }
  width: 100%;
  & > * {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
  ${Media.down.m} {
    top: 10px;
    right: 6px;
  }
`;

export const ModalTemplateRow = styled.div`
  margin-bottom: 20px;
`;

export const PopupWrapper = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  ${Media.down.xxl} {
    max-height: 98%;
    overflow-y: scroll;
  }

  ${Media.down.s} {
    max-height: 75vh;
    overflow-y: auto;
  }
`;

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;

  ${Media.down.s} {
    justify-content: center;
    align-items: flex-end;
  }
`;

const InviteButtonStyles = css`
  border: none;
  outline: none;
  background: transparent;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const InvButtons = styled.div``;

export const InvButtonIcon = styled.div`
  width: 35px;
  height: 24px;
  display: flex;
  justify-content: flex-start;
`;
export const InvButtonText = styled.div`
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._6};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-right: 10px;
`;

export const Divider = styled.div`
  border-bottom: 0.5px solid ${({theme}) => theme.palette.grayscale._11};
  margin: 2px 0;
`;

export const InviteButtonItem = styled.button`
  ${InviteButtonStyles}
`;
export const InviteButtonLink = styled(Link)`
  ${InviteButtonStyles}
`;
export const InviteCodeText = styled.div`
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._13};
  padding: 8px 1px;
`;

export const RequestModalWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  position: relative;
  width: 100%;
  & > article {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
  }
  max-height: 89%;
  overflow: scroll;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const RequestCloseBtn = styled.div`
  position: absolute;
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 14px;
  top: 17px;
  background-color: ${({theme}) => theme.palette.background.primary};
  z-index: 100;
  border: none;
  outline: none;
  border-radius: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
`;

export const RoundModalWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  box-sizing: border-box;
  width: 380px;
  margin: 0 auto;
  border-radius: 10px;
  ${Media.down.m} {
    width: 100%;
    border-radius: 20px 20px 0 0;
    opacity: 0;
    animation: slide-up 0.4s cubic-bezier(0, 0, 0, 1) 1ms forwards;
  }
  @keyframes slide-up {
    0% {
      -webkit-transform: translateY(100%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(0);
      opacity: 1;
    }
  }
`;
