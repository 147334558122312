import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  ContactInfoLine,
  HeaderSideCard,
  HiddenButton,
  InnerBodyCard,
  InnerSideCard,
  SubInnerHeaderCard,
  SubTitleHeaderMsgList,
  TitleHeaderCard,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {MobTabMsgs, OrderHelpersT, TContact} from '../../types/messages';
import {Media} from '../../ui-kit/theme';
import {TResActiveOrder} from '../../hooks/message';
import {Order} from '../../queries/types/order';
import {RequestsContent} from './RequestsContent';
import {editReviewDataT, TDataInvoices, TResCreateReview, TResEditOrder, TResManageOrder} from '../../hooks/order';
import {TReview} from '../../types/reviews';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {MessagesRequestModal} from '../common/Modal/MessagesRequestModal';
import {BlockHint} from '../common/InfoHint';

type TProps = {
  count?: number;
  viewerId?: string;
  activeContact?: TContact | null;
  setActiveMobTab?: (tab: MobTabMsgs | null) => void;
  setIsActiveRequests?: (isActive: boolean) => void;
  dataActiveOrder: TResActiveOrder;
  dataOrders: {
    fetchData: () => void;
    hasMore: boolean;
    loading: boolean;
    invoiceLoading?: boolean;
    orders?: Order[] | null;
  };
  dataInvoice: TDataInvoices | null;
  dataManageOrder: TResManageOrder;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  showStripePopup: () => void;
  setCardErrorPopup: (v: string | null) => void;
  setActiveContact: (user: TContact | null) => void;
  separatePage?: boolean;
  separateLoading?: boolean;
  resetFromUrl?: () => void;
  hintShown?: boolean;
  hideHint?: () => void;
  redirectToProfile?: () => void;
};

export const MessagesRequests: React.FC<TProps> = ({
  count,
  activeContact,
  setActiveMobTab,
  setIsActiveRequests,
  dataActiveOrder,
  dataOrders,
  viewerId,
  dataInvoice,
  dataManageOrder,
  reviewOrderData,
  editOrderData,
  reviewsData,
  editReviewData,
  reviewsDataLoading,
  orderHelpers,
  showStripePopup,
  setCardErrorPopup,
  setActiveContact,
  separatePage,
  separateLoading,
  resetFromUrl,
  hideHint,
  hintShown,
  redirectToProfile,
}) => {
  const {t} = useTranslation();
  const {activeOrder, clearActiveOrder} = dataActiveOrder;
  const titleCard = activeOrder ? t('messages:column.bookingInfo') : t('messages:column.bookings');
  const subTitleCard = activeOrder
    ? t('common:datePP', {date: new Date(activeOrder?.createdAt)})
    : `${count || 0} ${t('messages:transactions')}`;

  const handleActiveMobTab = (tab: MobTabMsgs | null) => () => {
    if (!setActiveMobTab) return;
    setActiveMobTab(tab);
  };

  const handleIsActiveRequests = () => {
    if (!setIsActiveRequests) return;
    setIsActiveRequests(false);
  };

  const handleCloseOrderCard = () => {
    resetFromUrl?.();
    setActiveMobTab?.(MobTabMsgs.chat);
    return clearActiveOrder();
  };
  const itemName = activeOrder?.Item?.name || activeOrder?.Event?.name || activeOrder?.Amenity?.name;
  const showHead = (activeOrder && separatePage) || !separatePage;
  const isModalRequest = !!activeOrder?.objectId;
  return (
    <InnerSideCard>
      <HiddenElement mq={Media.up.m}>
        {hintShown && <BlockHint hintText={t('messages:hints.requests')} onClose={hideHint} offsets />}
      </HiddenElement>
      {showHead && !separatePage && (
        <HeaderSideCard>
          {!separatePage && (
            <SubInnerHeaderCard>
              <HiddenButton mq={Media.up.m}>
                <Button type={'button'} onClick={handleActiveMobTab(MobTabMsgs.chat)}>
                  <IconSvg type={'arrow-left'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
                </Button>
              </HiddenButton>
              <ContactInfoLine onClick={redirectToProfile}>
                {activeContact?.name && (
                  <TitleHeaderCard>
                    <Avatar src={activeContact?.avatar} size={'sm'} className={'preview-message_avatar'} />
                    <span>{activeContact?.name}</span>
                  </TitleHeaderCard>
                )}
              </ContactInfoLine>
              <div>
                <HiddenElement mq={Media.down.m}>
                  <TitleHeaderCard>{titleCard}</TitleHeaderCard>
                </HiddenElement>
                <HiddenElement mq={Media.down.m}>
                  <SubTitleHeaderMsgList>{subTitleCard}</SubTitleHeaderMsgList>
                </HiddenElement>
              </div>
            </SubInnerHeaderCard>
          )}
          {activeOrder && separatePage && (
            <>
              <SubInnerHeaderCard>
                <Button type={'button'} onClick={handleCloseOrderCard}>
                  <IconSvg type={'arrow-left'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
                </Button>
                <TitleHeaderCard>{itemName}</TitleHeaderCard>
              </SubInnerHeaderCard>
              <Button type={'button'} onClick={handleCloseOrderCard}>
                <IconSvg type={'close'} height={'20px'} width={'20px'} viewBox="0 0 20 20" stroke={'darkgray'} />
              </Button>
            </>
          )}
          {activeOrder && !separatePage && (
            <Button type={'button'} onClick={handleCloseOrderCard}>
              <IconSvg type={'close'} height={'20px'} width={'20px'} viewBox="0 0 20 20" stroke={'darkgray'} />
            </Button>
          )}
          {!activeOrder && !separatePage && (
            <HiddenButton mq={Media.down.m}>
              <Button type={'button'} onClick={handleIsActiveRequests}>
                <IconSvg type={'close'} height={'20px'} width={'20px'} viewBox="0 0 20 20" stroke={'darkgray'} />
              </Button>
            </HiddenButton>
          )}
          {!activeOrder && !separatePage && (
            <HiddenButton mq={Media.up.m}>
              <Button type={'button'} onClick={handleActiveMobTab(MobTabMsgs.messages)}>
                <IconSvg type={'chat'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
              </Button>
            </HiddenButton>
          )}
        </HeaderSideCard>
      )}

      <MessagesRequestModal isModalRequest={isModalRequest} onClose={handleCloseOrderCard}>
        <InnerBodyCard>
          <RequestsContent
            viewerId={viewerId}
            dataOrders={dataOrders}
            activeContact={activeContact}
            dataActiveOrder={dataActiveOrder}
            dataInvoice={dataInvoice}
            dataManageOrder={dataManageOrder}
            reviewOrderData={reviewOrderData}
            editOrderData={editOrderData}
            reviewsData={reviewsData}
            editReviewData={editReviewData}
            reviewsDataLoading={reviewsDataLoading}
            orderHelpers={orderHelpers}
            showStripePopup={showStripePopup}
            setCardErrorPopup={setCardErrorPopup}
            setActiveContact={setActiveContact}
            separateLoading={separateLoading}
            isModal={isModalRequest}
            onCloseOrder={handleCloseOrderCard}
          />
        </InnerBodyCard>
      </MessagesRequestModal>
    </InnerSideCard>
  );
};
