import {planOptions, PlanOptionsValues} from '../constants/plan';
import {PaymentPlan, TCreditCard, TFees, TGetCreditCard} from '../queries/types/payments';
import {TCommunity} from '../types/community';
import {User} from '../queries/types/user';
import {PaymentPeriodType, PayoutStatus} from '../types/payment';
import {StatusOrderType} from '../queries/types/order';
import {GQLOrderStatuses, GQLOrderWhereInput} from '../graphql.schema';
import {currency} from '../constants/common';
import {IGetCurrValue} from '../types/common';
import {useGetCurrencyValue} from '../hooks/currency';

export const convertCentToDollar = (value?: number) => {
  return value && Math.floor(value * 100) / 10000;
};

export const convertDollarToCent = (value?: number) => {
  return value && value * 100;
};

export const getPriceText = (value?: string | number) => {
  const {getSignValue} = useGetCurrencyValue();
  // return currency + (value ?? 0);
  return getSignValue(value || 0);
};

export const getPriceTextSplited = (value?: string | number) => {
  const {currency} = useGetCurrencyValue();

  // return currency + (value ?? 0);
  return [`${value}`, currency?.code];
};

export const getPlan = (plan?: PaymentPlan, period?: PaymentPeriodType) => {
  const planOpt = plan?.options.map((it) => it.value);
  const options = Object.keys(planOptions).map((it) => ({
    isSupport:
      it === PlanOptionsValues.membersApproval
        ? planOpt?.includes(PlanOptionsValues.privateCommunityAccount)
        : planOpt?.includes(it),
    text: it,
  }));
  const currentPriceMonth = period === PaymentPeriodType.month ? plan?.priceMonth : (plan?.priceYear || 0) / 12;
  const currentPriceYear = period === PaymentPeriodType.month ? (plan?.priceMonth || 0) * 12 : plan?.priceYear;
  return {
    id: plan?.id,
    objectId: plan?.objectId,
    name: plan?.name.toLowerCase(),
    descr: plan?.name.toLowerCase(),
    priceYear: convertCentToDollar(plan?.priceYear),
    priceMonth: convertCentToDollar(plan?.priceMonth),
    currentPriceMonth: currentPriceMonth ? convertCentToDollar(currentPriceMonth)?.toFixed(2) : 0,
    currentPriceYear: currentPriceYear ? convertCentToDollar(currentPriceYear)?.toFixed(2) : 0,
    communityFee: plan?.communityFee,
    options: options,
    serviceFees: plan?.serviceFees,
    rentFees: plan?.rentFees,
    salesFees: plan?.salesFees,
  };
};

export const toPointerCard = (data: TCreditCard) => {
  return {
    nameOnCard: data.lastName + ' ' + data.firstName,
    cardNumber: data.cardNumber,
    expiryDate: data.expiryDate,
    cvc: data.cvc,
    zipCode: data.zipCode,
    country: getShortCountryName(data.country as CardCountries),
  };
};

export const isViewerCards = (cards?: TCreditCard[] | null) => {
  return Boolean(cards?.length);
};

export const isPaymentPlanSelected = (community?: Partial<TCommunity> | null) => {
  return Boolean(community?.Subscr?.PaymentPlan.objectId) && Boolean(community?.Subscr?.paymentPeriod);
};

export const makeRounding = (val?: number) => {
  return val ? Math.round(val * 100) / 100 : 0;
};

export const getFormattingAmount = (value?: number) => {
  return makeRounding(convertCentToDollar(value)) || 0;
};

export enum CardCountriesShort {
  US = 'US',
  CA = 'CD',
  AU = 'AU',
}

export enum CardCountries {
  'United States' = 'US',
  Canada = 'CD',
  Australia = 'AU',
}

const cardCountry = {
  [CardCountries['United States']]: CardCountriesShort.US,
  [CardCountries.Canada]: CardCountriesShort.CA,
  [CardCountries.Australia]: CardCountriesShort.AU,
};

export const getShortCountryName = (country: CardCountries) => cardCountry[country];

export const toCreditCard = (cards: TGetCreditCard[], user?: User | null): TCreditCard[] => {
  return cards.map((c) => ({
    ...c,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    cardNumber: ``,
    expiryDate: `${c.expiryMonth}/${c.expiryYear}`,
    saveCard: false,
    cvc: '',
  }));
};

export const PayoutStatusMap = (status?: StatusOrderType) => {
  const statusMap = {
    [StatusOrderType.paymentPending]: PayoutStatus.pending,
    [StatusOrderType.accepted]: PayoutStatus.pending,
    [StatusOrderType.performing]: PayoutStatus.pending,
    [StatusOrderType.completed]: PayoutStatus.paid,
    [StatusOrderType.paid]: PayoutStatus.paid,
    [StatusOrderType.draft]: '-',
    [StatusOrderType.request]: '-',
    [StatusOrderType.rejected]: '-',
    [StatusOrderType.failed]: '-',
    [StatusOrderType.attending]: '-',
    [StatusOrderType.booked]: '-',
    [StatusOrderType.pending]: '-',
    [StatusOrderType.canceled]: '-',
  };
  if (status && statusMap[status] !== undefined) {
    return statusMap[status];
  }
  return '-';
};

type createPayoutsWhereOptions = {
  isManager?: boolean;
  communityId?: string;
  listerId?: string;
};
export const createPayoutsWhere = (options: createPayoutsWhereOptions) => {
  const passStatuses = [
    GQLOrderStatuses.accepted,
    GQLOrderStatuses.performing,
    GQLOrderStatuses.completed,
    GQLOrderStatuses.paid,
  ];

  const where: GQLOrderWhereInput = {
    AND: [
      {status: {in: passStatuses}},
      {...(!options?.isManager ? {Lister: {have: {objectId: {equalTo: options?.listerId}}}} : {})},
      {
        Community: {
          have: {
            objectId: {
              equalTo: options?.communityId,
            },
          },
        },
      },
    ],
  };
  return where;
};

type createIncomeOptions = {
  listerTotal?: number;
  cflister?: number;
  cfRequester?: number;
  isManager?: boolean;
  isLister?: boolean;
};

const convertIncome = (val?: number) => (val ? (val / 100).toFixed(2) : 0);

export const createIncome = (options: createIncomeOptions, getSignValue: IGetCurrValue) => {
  if (!options.isManager) {
    return convertIncome(options?.listerTotal);
  }

  const cfTotal = (options?.cflister || 0) + (options?.cfRequester || 0);

  if (options.isLister) {
    return `${currency}${convertIncome(options.listerTotal)}(+${currency}${convertIncome(cfTotal)})`;
  }

  return getSignValue(convertIncome(cfTotal));
};

export const fullCurrency = (value?: string | number) => {
  const {currency} = useGetCurrencyValue();

  return `${value} ${currency?.code}`;
};

export const getCFeePrc = (fees?: TFees) => {
  if (!fees) return 0;
  return (fees.cFee.cfListerPrc || 0) + (fees.cFee.cfRequesterPrc || 0);
};

export const getCFeeAbs = (fees?: TFees) => {
  if (!fees) return 0;
  return (fees.cFee.cfListerAbs || 0) + (fees.cFee.cfRequesterAbs || 0);
};

export const sortCards = (cards?: TCreditCard[]) => {
  const newCards = cards?.sort((c1, c2) => {
    const l = Number(c1.last4) || 0;
    const r = Number(c2.last4) || 0;
    if (l < r) {
      return -1;
    }
    if (l > r) {
      return 1;
    }
    return 0;
  });
  return newCards;
};
