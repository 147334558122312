import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AmenityFormAction} from '../../types/amenity';
import {
  DesktopView,
  EditButton,
  AmenityNameLine,
  InnerListingView,
  ListingViewTitle,
  MainContListingView,
  MobileView,
  WrapperListingView,
  MobAmenityPreviewHead,
  MobButtonEdit,
  AmenityNameLeftSide,
  AmenityDateSelector,
  AmenityDatePickerWrapper,
  ArrowBtn,
  AmenityDateButton,
} from './styles';
import {EditFormAside} from './EditFormAside';
import {route} from '../../constants/routes';
import {NavContainer} from '../../containers/Navigation';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {ListingNotFound} from '../Extras';
import {useLinks} from '../../hooks/common';
import {AddCreditCardPopup} from '../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {TDataCreator} from '../../hooks/amenity';
import {AmenitiesTable} from './AmenitiesTable';
import {LinkBack} from '../common/Buttons/LinkBack';
import {Link} from '../../ui-kit/Typography/Link';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Order} from '../../queries/types/order';
import {TResManageOrder} from '../../hooks/order';
import {amenityOrdersFilters} from '../../containers/Amenities/ViewEdit';
import {isToday} from 'date-fns';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {Popup} from '../../ui-kit/Popup';
import {DatePickerLanguageProvider} from '../../ui-kit/Form/Input/DatePickerLanguageProvider';
import ReactDatePicker from 'react-datepicker';
import {Amenity} from '../../queries/types/amenity';

interface IProps {
  amenity?: Partial<Amenity>;
  $loading?: boolean;
  action: AmenityFormAction;
  onPublish?: () => void;
  onUnlist?: () => void;
  viewerObjId?: string;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataCreator?: TDataCreator;
  onDelete?: () => void;
  onRenew?: () => void;
  isAvailable?: boolean;
  communityAlias?: string;
  communityId?: string;
  requestAccess?: boolean;
  refetch?: () => void;
  viewerIsNotOwner?: boolean;
  orders?: Order[];
  manageOrder?: TResManageOrder;
  ordersLoading?: boolean;
  ordersFilter: amenityOrdersFilters;
}

export const ViewAmenityCreator: React.FC<IProps> = ({
  amenity,
  $loading,
  onUnlist,
  onPublish,
  onDelete,
  communityId,
  requestAccess,
  refetch,
  orders,
  manageOrder,
  ordersLoading,
  ordersFilter,
}) => {
  const {t} = useTranslation();
  const textSeo = amenity?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const isAdmHidden = useMemo(
    () => Boolean(amenity?.AdmHidden?.filter((el) => el.objectId === communityId).length),
    [amenity],
  );
  const isPublished = useMemo(
    () => Boolean(amenity?.Published?.filter((el) => el.objectId === communityId).length),
    [amenity],
  );
  const imagesBlock = useRef<HTMLDivElement>(null);
  const [noRequestModal, setNoRequestModal] = useState<boolean>(false);
  const {getLink} = useLinks();

  const listingOg = useMemo(() => {
    return {
      title: amenity?.name,
      description: amenity?.descr,
      image: amenity?.images?.[0]?.file?.url,
    };
  }, [amenity]);

  const handleCloseAddCreditCardPopup = () => {
    setNoRequestModal(false);
    refetch?.();
  };
  const editPath = getLink(route.editAmenity.get({id: amenity?.objectId || ''}));
  // const allows = useGetMessagesAllows({directUserId: amenity?.Lister?.objectId});
  const handleBackNoReactRouter = (e?: React.MouseEvent) => {
    e?.preventDefault();
    window?.history.back();
  };

  return (
    <NavContainer>
      {!requestAccess && noRequestModal && <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />}
      <Seo title={textSeo} keywords={seoKeywords} og={listingOg} />
      {$loading ? (
        <ListingViewSkeleton />
      ) : amenity?.isDeleted ? (
        <ListingNotFound />
      ) : (
        <>
          <DesktopView>
            <MainContListingView ref={imagesBlock}>
              <WrapperListingView>
                <LinkBack type={'amenities'} />
                <InnerListingView>
                  <AmenityNameLine>
                    <ListingViewTitle>{amenity?.name}</ListingViewTitle>
                    <AmenityNameLeftSide>
                      <AmenityDatePicker ordersFilter={ordersFilter} />
                      <EditButton to={editPath}>{t('events:actions.buttons.edit')}</EditButton>
                    </AmenityNameLeftSide>
                  </AmenityNameLine>
                  <AmenitiesTable orders={orders} manageOrder={manageOrder} loading={ordersLoading} />
                  <EditFormAside
                    width={'300px'}
                    $isPublished={isPublished}
                    onUnlist={onUnlist}
                    onPublish={onPublish}
                    countViewers={amenity?.totalViews}
                    className={'listing-view__form-aside'}
                    editPath={editPath}
                    isAdmHidden={isAdmHidden}
                    onDelete={onDelete}
                    communityListPath={getLink(
                      route.configureListCommunitiesAmenity.get({id: amenity?.objectId || ''}),
                    )}
                    totalBooking={amenity?.totalBookings}
                    popularDay={amenity?.popularDay}
                    popularTime={amenity?.popularTime}
                  />
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
          </DesktopView>

          <MobileView>
            <MainContListingView>
              <WrapperListingView>
                <MobAmenityPreviewHead>
                  <InnerListingView $isLine={true}>
                    <LinkBack to={'#'} onClick={handleBackNoReactRouter} hideText={true} type={'amenities'} />
                    <ListingViewTitle>{amenity?.name}</ListingViewTitle>
                  </InnerListingView>
                  <MobButtonEdit>
                    <Link to={editPath}>
                      <IconSvg type={'pencil'} />
                    </Link>
                  </MobButtonEdit>
                </MobAmenityPreviewHead>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                <AmenityNameLine>
                  <ListingViewTitle>{t('amenities:table.bookings')}</ListingViewTitle>
                  <AmenityNameLeftSide>
                    <AmenityDatePicker ordersFilter={ordersFilter} />
                  </AmenityNameLeftSide>
                </AmenityNameLine>
                <AmenitiesTable orders={orders} loading={ordersLoading} />
                <EditFormAside
                  width={'300px'}
                  $isPublished={isPublished}
                  onUnlist={onUnlist}
                  onPublish={onPublish}
                  countViewers={amenity?.totalViews}
                  className={'listing-view__form-aside'}
                  editPath={getLink(route.editAmenity.get({id: amenity?.objectId || ''}))}
                  isAdmHidden={isAdmHidden}
                  onDelete={onDelete}
                  communityListPath={getLink(route.configureListCommunitiesAmenity.get({id: amenity?.objectId || ''}))}
                />
              </WrapperListingView>
            </MainContListingView>
          </MobileView>
        </>
      )}
    </NavContainer>
  );
};

export const AmenityDatePicker: React.FC<{ordersFilter: amenityOrdersFilters}> = ({ordersFilter}) => {
  const {t} = useTranslation();
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;
  const [open, setOpen] = useState(false);
  const today = isToday(ordersFilter.date.value);
  const date = today ? t('amenities:table.today') : format(ordersFilter.date.value, 'PP', getLanguage());

  const changeDay = (type: 'plus' | 'minus') => () => {
    const newDate = new Date(ordersFilter.date.value);
    if (type === 'plus') {
      newDate.setDate(newDate.getDate() + 1);
    }
    if (type === 'minus') {
      newDate.setDate(newDate.getDate() - 1);
    }
    ordersFilter.date.change(newDate);
  };
  return (
    <>
      <AmenityDateSelector>
        <ArrowBtn onClick={changeDay('minus')}>
          <IconSvg type={'chevrone-left'} />
        </ArrowBtn>
        <AmenityDateButton $short={!!today} onClick={() => setOpen(true)}>
          <IconSvg type={'calendar'} stroke={'gray'} />
          <div>{date}</div>
        </AmenityDateButton>
        <ArrowBtn onClick={changeDay('plus')}>
          <IconSvg type={'chevrone-right'} />
        </ArrowBtn>
      </AmenityDateSelector>
      <Popup isOpen={open} onClose={() => setOpen(false)} mobileCentered={true} centered={true}>
        <AmenityDatePickerWrapper>
          <DatePickerLanguageProvider>
            <ReactDatePicker
              selected={ordersFilter.date.value}
              onChange={(dateN: Date) => {
                ordersFilter.date.change(dateN);
                setOpen(false);
              }}
              inline
              locale={getLanguage()}
            />
          </DatePickerLanguageProvider>
        </AmenityDatePickerWrapper>
      </Popup>
    </>
  );
};
