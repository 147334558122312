import React from 'react';
import {useHistory} from 'react-router-dom';
import {ItemForm} from '../../components/Listings/Form';
import {useCreateItem, useItemFormData} from '../../hooks/item';
import {ListingType, RewardType} from '../../queries/types/item';
import {ItemFormAction} from '../../types/item';
import {route, SearchKey} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';

export const CreateItemContainer = () => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const textSeo = t('common:seo.listings.add');
  const params = useUrlSearchManager();
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {push} = useHistory();
  const formData = useItemFormData();
  const {currency} = useGetCurrencyValue();
  const paramType = formData?.listingTypeOptions?.find((el) => el?.value === params.get(SearchKey.type))
    ?.value as ListingType;
  const data = useCreateItem({
    initialState: {
      rewardType: formData?.rewardTypeOptions[0]?.value as RewardType,
      listingType: paramType || (formData?.listingTypeOptions[0]?.value as ListingType),
      category: formData?.categoriesOptions[0]?.value as string,
      images: formData.dataImages,
    },
    currency,
    onSuccess: async (item) => {
      push(getLink(route.listingCreated.get({id: item?.id ?? ''})));
    },
    // onSuccess: (item) => handlePublish(item),
  });

  const handleRedirect = () => push(getLink(route.generateItem.path));

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <ItemForm
        action={ItemFormAction.create}
        data={data}
        formData={formData}
        currSign={currency?.sign || ''}
        generateListing={handleRedirect}
      />
    </>
  );
};
