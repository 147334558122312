import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {MemoHeaderAuth} from './HeaderAuth';
import {CheckboxText, CheckBoxWrapper, ErrorMessage, ForgotPassBtn, InnerAuth, InnerForm} from './styles';
import {WrapperAuth} from './WrapperAuth';
import {correctTranslate, makeSeoKeywords, SendMessageToRNApp} from '../../helpers/common';
import {AuthField, AuthTab, LogInValues} from '../../types/auth';
import {Button} from '../../ui-kit/Button/Button';
import {Checkbox} from '../../ui-kit/Checkbox/Checkbox';
import {Input} from '../../ui-kit/Form/Input';
import {Text} from '../../ui-kit/Typography/Text';
import {Seo} from '../SEO';

import {IDataLogIn} from '../../hooks/auth';

type LogInField = keyof LogInValues;

interface IProps {
  dataLogIn: IDataLogIn;
  dataResetPass: {
    error: string;
    resetPass: (email?: string) => Promise<void>;
    clearError: () => void;
  };
  isOG?: boolean;
}

export const LogIn: React.FC<IProps> = ({dataLogIn, dataResetPass, isOG}) => {
  const {values, onChange, onSubmit, error: logInError, loading, setIsRemember, isRemember} = dataLogIn;
  const {resetPass, error: resetPassError, clearError} = dataResetPass;
  const [focused, setFocused] = useState(false);
  const error = logInError?.message || resetPassError;

  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const seoTitle = t('common:seoTitle', {text: t('auth:logIn.title')});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('auth:logIn.title')]);
  const errorMsg = correctTranslate({
    text: t(error || ''),
    defaultText: error,
  });

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const handleResetPass = () => {
    (formRef.current as HTMLFormElement).reportValidity() && resetPass(values.email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name === AuthField.username ? AuthField.email : e.target.name;
    onChange({name: name as LogInField, value: e.target.value});
  };

  const handleClickCheck = () => setIsRemember(!isRemember);
  const onFocus = () => setFocused(true);
  const credP = typeof window !== 'undefined' ? window?.credP : '';
  const credE = typeof window !== 'undefined' ? window?.credE : '';

  useEffect(() => {
    clearError();
  }, [values.email]);

  useEffect(() => {
    SendMessageToRNApp(JSON.stringify({checkAutofill: true}));
  }, []);
  useEffect(() => {
    if (credP && credE) {
      if (typeof window === 'undefined') return;
      onChange({name: AuthField.password as LogInField, value: credP});
      onChange({name: AuthField.email as LogInField, value: credE});
      setIsRemember(true);
      window.credP = '';
      window.credE = '';
      setFocused(!focused);
    }
  }, [credP, credE]);

  return (
    <WrapperAuth tab={AuthTab.logIn} isStart={true}>
      {!isOG && <Seo title={seoTitle} keywords={seoKeywords} />}
      <InnerAuth>
        <MemoHeaderAuth title={t('auth:logIn.title')} />
        <InnerForm onSubmit={handleSubmit} ref={formRef}>
          <Input
            $isError={Boolean(logInError.email)}
            name={AuthField.username}
            value={values.email || ''}
            onChange={handleChange}
            label={t('forms:emailAddress')}
            type={'email'}
            autoComplete="username"
            onFocus={onFocus}
          />
          <Input
            $isError={Boolean(logInError.password)}
            name={AuthField.password}
            value={values.password || ''}
            onChange={handleChange}
            label={t('forms:password')}
            type={'password'}
            autoComplete="current-password"
            onFocus={onFocus}
          />
          {/*<ErrorMessage open={!error?.message}>{'errorMsg'}</ErrorMessage>*/}
          <ErrorMessage open={!!error}>{errorMsg}</ErrorMessage>
          <CheckBoxWrapper>
            <Checkbox $isChecked={isRemember} onChange={handleClickCheck} />
            <CheckboxText>
              <Text variant={'tertiary'} size={14} color={'octonary'}>
                {t('forms:rememberMe')}
              </Text>
            </CheckboxText>
          </CheckBoxWrapper>
          <Button type={'submit'} variant={'primary'} loading={loading}>
            {t('auth:buttons.logIn')}
          </Button>
          <ForgotPassBtn onClick={handleResetPass}>
            <Text decoration={'underline'} variant={'tertiary'} size={14} color={'octonary'}>
              {t('auth:forgotYourPassword.button')}
            </Text>
          </ForgotPassBtn>
        </InnerForm>
        {/*<LinkForgotPswd to={''}>{t('auth:buttons.forgotPswd')}</LinkForgotPswd>*/}
      </InnerAuth>
    </WrapperAuth>
  );
};
