import styled from 'styled-components';
import {PrimaryButton} from '../../../ui-kit/Button/styles';
import {Media} from '../../../ui-kit/theme';
import {ToggleButton} from '../../common/Buttons/ToggleButton';
import {fontPrimaryMedium, fontPrimaryReg} from '../../common/styles';

export const NotificationSettingsItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 20px;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({theme}) => theme.palette.background.secondary};
  }

  ${Media.down.s} {
    grid-auto-flow: row;
    place-items: start;
  }
`;

export const CheckboxWrapper = styled.div<{viewOnly?: boolean}>`
  display: grid;
  grid-template-columns: repeat(3, 40px);
  gap: min(60px, 6vw);
  place-items: center;

  //& * {
  //  border: none;
  //  cursor: revert;
  //}

  ${({viewOnly}) => {
    if (viewOnly) {
      return `
        & * {
          border: none;
          cursor: revert;
        }
      `;
    }

    return '';
  }}
`;

export const CheckboxPairWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CheckboxWrapperMobile = styled(CheckboxWrapper)`
  gap: min(25px, 4vw);
  grid-template-columns: repeat(3, max-content);
`;

export const NotificationSettingsEntryWrapper = styled(NotificationSettingsItem)<{$noBorder?: boolean}>`
  padding: 15px 0 20px;
  ${({$noBorder}) => $noBorder && ` border: none; &::after {display: none};`}
`;

export const LangSettingsWrapper = styled.div`
  margin: 20px 0 25px;
`;

export const LangSettingsInfo = styled.div`
  display: flex;
  gap: 18px;
  padding: 16px 16px 8px;

  & > * {
    flex-basis: 240px;

    ${Media.down.m} {
      flex-basis: 100%;
    }
  }
`;

export const LangSettingsForm = styled.div`
  display: flex;
  gap: 18px;
  padding: 24px 0 8px;

  & > * {
    flex-basis: 240px;

    ${Media.down.m} {
      flex-basis: 100%;
    }
  }
`;

export const NotificationRow = styled.div`
  padding: 13px 0 24px 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._11};
`;

export const NotificationName = styled.div`
  ${fontPrimaryMedium};
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 14px;
`;

export const NotificationDescr = styled.div`
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 15px;
  margin-bottom: 14px;
`;

export const NotificationStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationAction = styled.button`
  ${fontPrimaryReg};
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.octonary};
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  border-radius: 9px;
  margin-right: 10px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  height: 26px;
  box-sizing: border-box;
`;

export const NotificationValues = styled.div`
  ${fontPrimaryReg};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.octonary};
  text-transform: capitalize;
`;

export const NotificationPopupContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  ${Media.down.m} {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
export const NotificationPopupWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  max-height: 440px;
  padding: 28px 31px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;
  margin-bottom: 4px;
  position: relative;
  ${Media.down.m} {
    max-width: 100%;
    padding: 20px 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const PopupTitle = styled.div`
  ${fontPrimaryReg};
  font-size: 20px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 9px;
`;
export const PopupDescr = styled.div`
  ${fontPrimaryReg};
  font-size: 15px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 26px;
`;
export const Toggle = styled(ToggleButton)`
  margin-bottom: 12px;
`;

export const CloseBtn = styled.span`
  position: absolute;
  right: 20px;
  top: 25px;
  cursor: pointer;
`;

export const UpgradeButton = styled(PrimaryButton)`
  margin-top: 22px;
  height: 46px;
  width: 240px;
  ${Media.down.m} {
    width: 100%;
  }
`;

export const PromptBlock = styled.div`
  width: 100%;
  padding: 8px 10px;
  background-color: ${({theme}) => theme.palette.common.yellowLight};
  font-weight: 400;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.octonary};
  ${fontPrimaryReg};
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  line-height: 150%;
`;

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
`;
