import React from 'react';
import {useTranslation} from 'react-i18next';

import {ChangeLangBtn, PostTranslatedFrom} from './styles';
import {TranslateState} from './types';
import {Text} from '../../../ui-kit/Typography/Text';

export const TranslatePanel: React.FC<TranslateState> = ({
  origLang,
  isTranslated,
  showTranslate,
  showOriginal,
  haveTranslates,
}) => {
  const {t, i18n} = useTranslation();

  if (i18n.language === origLang || !haveTranslates) return null;

  return (
    <PostTranslatedFrom>
      {isTranslated ? (
        <>
          <Text color={'duodecimal'} size={12}>
            {t('common:translates.from', {context: origLang})}
          </Text>
          <ChangeLangBtn onClick={showOriginal} as={'span'}>
            {t('common:translates.showOriginal')}
          </ChangeLangBtn>
        </>
      ) : (
        <ChangeLangBtn onClick={showTranslate} as={'span'}>
          {t('common:translates.showTranslate')}
        </ChangeLangBtn>
      )}
    </PostTranslatedFrom>
  );
};
