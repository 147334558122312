import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActionButton,
  ActionLink,
  AsideEdit,
  CardInsight,
  InnerActions,
  InnerEditAside,
  InnerInsights,
  SubTitle,
  TitleInsight,
  ValueInsight,
  WrapperEditFormAside,
  CommItem,
  SelectedIconCont,
  CommsList,
} from './styles';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Community} from '../../queries/types/community';
import {ListingType} from '../../queries/types/item';

type TProps = {
  totalCount?: number;
  completedCount?: number;
  amountIncome?: number;
  countViewers?: number;
  $isPublished?: boolean;
  width?: string;
  onPublish?: () => void;
  onUnlist?: () => void;
  onDelete?: () => void;
  onRenew?: () => void;
  onSold?: () => void;
  editPath?: string;
  className?: string;
  hasApprovalRequest?: boolean;
  isAdmHidden?: boolean;
  isSold?: boolean;
  communityListPath?: string;
  manyCommunities: boolean;
  commList?: {objectId?: string; name?: string}[];
  published?: Community[];
  previewLink?: string;
  listingType?: ListingType;
  getSignValue: (value: number) => string;
};

export const EditFormAside: React.FC<TProps> = ({
  totalCount,
  completedCount,
  amountIncome,
  countViewers,
  onDelete,
  width,
  editPath,
  className,
  isSold,
  onRenew,
  communityListPath,
  manyCommunities,
  onSold,
  $isPublished,
  hasApprovalRequest,
  onPublish,
  onUnlist,
  isAdmHidden,
  commList,
  published,
  listingType,
  getSignValue,
}) => {
  const {t} = useTranslation();

  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const isSell = listingType === ListingType.sell;
  const dataInsights = useMemo(() => {
    return [
      {
        title: t('listings:insights.views'),
        value: countViewers || 0,
      },
      {
        title: t('listings:insights.income'),
        value: getSignValue(amountIncome || 0),
      },
      {
        title: t('listings:insights.transactions'),
        value: completedCount || 0,
      },
      {
        title: t('listings:insights.requests'),
        value: totalCount || 0,
      },
    ];
  }, [totalCount, completedCount, amountIncome, countViewers]);

  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('listings:modals.delete.title'),
        okText: t('listings:modals.delete.okText'),
        cancelText: t('listings:modals.delete.cancelText'),
      },
    };
  }, []);
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  return (
    <>
      <InnerEditAside width={width} className={className}>
        <AsideEdit>
          <WrapperEditFormAside>
            <div className={'aside__actions-block'}>
              <SubTitle>{t('listings:actions.title')}</SubTitle>
              <InnerActions>
                {editPath && !isSold && (
                  <ActionLink to={editPath}>
                    {t('listings:actions.buttons.edit')}
                    <IconSvg type="pencil" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionLink>
                )}
                {$isPublished ? (
                  <ActionButton type="button" onClick={onUnlist}>
                    {t('listings:actions.buttons.unlist')}
                    <IconSvg type="eye-closed" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionButton>
                ) : (
                  !$isPublished &&
                  !hasApprovalRequest &&
                  !isAdmHidden && (
                    <ActionButton type="button" onClick={onPublish}>
                      {t('listings:actions.buttons.publish')}
                      <IconSvg type="eye-open" width="14px" height="14px" viewBox="0 0 14 14" />
                    </ActionButton>
                  )
                )}
                {manyCommunities && (
                  <ActionLink to={communityListPath || ''}>
                    {t('listings:actions.buttons.communityList')}
                    <IconSvg type="list" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionLink>
                )}
                {isSell && (
                  <>
                    {isSold ? (
                      <ActionButton type="button" onClick={onRenew}>
                        {t('listings:actions.buttons.renew')}
                      </ActionButton>
                    ) : (
                      <ActionButton type="button" onClick={onSold}>
                        {t('listings:actions.buttons.sold')}
                      </ActionButton>
                    )}
                  </>
                )}
                <ActionButton type="button" onClick={handleOpenDelete}>
                  {t('listings:actions.buttons.delete')}
                  <IconSvg type="delete-alt" width="14px" height="14px" viewBox="0 0 14 14" />
                </ActionButton>
              </InnerActions>
              <SubTitle>{t('listings:view.communities')}</SubTitle>
              <CommsList>
                {commList?.map((el) => (
                  <CommItem
                    to={communityListPath || ''}
                    $selected={published?.some((p) => p?.objectId === el?.objectId)}
                    key={el?.objectId}>
                    <SelectedIconCont>
                      {published?.some((p) => p?.objectId === el?.objectId) ? (
                        <IconSvg type="marked-circle" stroke="white" height="26px" />
                      ) : (
                        <IconSvg type="empty-circle" stroke="gray" height="22px" />
                      )}
                    </SelectedIconCont>
                    <span>{el?.name}</span>
                  </CommItem>
                ))}
              </CommsList>
            </div>
            <div>
              <SubTitle>{t('listings:insights.title')}</SubTitle>
              <InnerInsights>
                {dataInsights?.map((it) => (
                  <CardInsight key={it.title}>
                    <TitleInsight>{it.title}</TitleInsight>
                    <ValueInsight>{it.value}</ValueInsight>
                  </CardInsight>
                ))}
              </InnerInsights>
            </div>
          </WrapperEditFormAside>
        </AsideEdit>
      </InnerEditAside>
      {isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={onDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
    </>
  );
};
