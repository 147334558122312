import {gql} from '@apollo/client';

export const createReportRequest = gql`
  mutation createReport(
    $blockUserText: String
    $reportText: String
    $Item: ItemPointerInput
    $Group: GroupPointerInput
    $Event: EventPointerInput
    $Company: CompanyPointerInput
    $User: UserPointerInput
    $Post: PostPointerInput
    $Community: CommunityPointerInput!
    $Reporter: UserPointerInput
  ) {
    createReport(
      input: {
        fields: {
          blockUserText: $blockUserText
          reportText: $reportText
          Item: $Item
          Group: $Group
          Event: $Event
          Company: $Company
          Community: $Community
          Post: $Post
          User: $User
          Reporter: $Reporter
        }
      }
    ) {
      report {
        id
        Post {
          objectId
          text
          sentAs
          type
          Author {
            firstName
            lastName
            objectId
            email
          }
        }
      }
    }
  }
`;
