import {gql} from '@apollo/client';

export const residentDashboardFragment = gql`
  fragment residentDashboardFragment on ResidentDashboardPayload {
    countListings
    countItemValue
    countOwnTransactions
    countOthersTransactions
    countEarned
    countSpent
    averageRatingReceived
    averageRatingGiven
    #countInteractions
    countListingViews
  }
`;

export const managerDashboardFragment = gql`
  fragment managerDashboardFragment on ManagerDashboardPayload {
    countListings
    countMembers
    countTransactions
    countTransactionValue
    averageTransactionRating
    countListingValue
    countLoopPosts
    countLoopPostLikes
    incomeGenerated
    saleTransactions
    rentalTransactions
    serviceTransactions
    freeTransactions
    coffeeTransactions
    countLoopPostComments
    countInternalMessages
    countProfileViews
    totalInvitations
    countEventAttended
    countAmenityBooked
    completedInvitations
    countReports
    countEventViews
    countAmenityViews
    fee {
      cfListerAbs
      cfListerPrc
      cfRequesterAbs
      cfRequesterPrc
      message
    }
  }
`;
