import {Amenity} from '../queries/types/amenity';
import {FileInfo} from '../queries/types/parse';
import {User} from '../queries/types/user';
import {StatusOrderType} from '../queries/types/order';
import {TResManageOrder} from '../hooks/order';
import {PointerFile} from './common';

export enum AmenityFormAction {
  create = 'create',
  edit = 'edit',
  view = 'view',
}

export enum AmenityOpenStatus {
  open = 'open',
  closed = 'closed',
}

export enum AmenityOrderStatus {
  pending = 'pending',
  booking = 'booking',
  cancelled = 'cancelled',
}

export enum BookingTimingField {
  available = 'available',
  from = 'from',
  till = 'till',
}

export type BookingTimingRow = {
  [BookingTimingField.available]?: boolean;
  [BookingTimingField.from]?: string;
  [BookingTimingField.till]?: string;
};

export type BookingDayField = {[BookingTimingField.from]: string} | {[BookingTimingField.till]: string};

export type BookingTimingT = {
  mon?: BookingTimingRow;
  tue?: BookingTimingRow;
  wed?: BookingTimingRow;
  thu?: BookingTimingRow;
  fri?: BookingTimingRow;
  sat?: BookingTimingRow;
  sun?: BookingTimingRow;
};

export type bookingDay = keyof BookingTimingT;

export enum BookingPeriod {
  hour = 'hour',
  day = 'day',
  none = 'none',
}

export enum AmenityFields {
  name = 'name',
  objectId = 'objectId',
  isBookable = 'isBookable',
  rewardType = 'rewardType',
  maxResidentsPerBooking = 'maxResidentsPerBooking',
  maxBookingsPerDay = 'maxBookingsPerDay',
  autoBooking = 'autoBooking',
  bookingSlots = 'bookingSlots',
  maxBookingPerSlot = 'maxBookingPerSlot',
  bookingPeriod = 'bookingPeriod',
  allowGuests = 'allowGuests',
  descr = 'descr',
  images = 'images',
  totalBookings = 'totalBookings',
  popularDay = 'popularDay',
  bookingTiming = 'bookingTiming',
  Featured = 'Featured',
  Bookings = 'Bookings',
  popularTime = 'popularTime',
  Lister = 'Lister',
  openFrom = 'openFrom',
  openUntil = 'openUntil',
  isDeleted = 'isDeleted',
}

export type TImage = {
  id?: string;
  value?: File | string;
  order?: number;
  file?: FileInfo;
};

type NewAmenityFields = {
  images?: TImage[];
  serverImages?: Partial<PointerFile>[];
  openFrom?: string;
  openUntil?: string;
  eventName?: string;
};

type CreateParseFields = {
  images?: (Partial<PointerFile> | undefined)[];
  Lister: {link: string};
};

type EditParseFields = {
  images?: (Partial<PointerFile> | undefined)[];
};

export type AmenityFormValues = Omit<Pick<Amenity, AmenityFields>, keyof NewAmenityFields> & NewAmenityFields;
export type AmenityFormField = keyof AmenityFormValues;
export type AmenityFormValue = AmenityFormValues[AmenityFormField];
export type CreateAmenityFieldsInput = Omit<Partial<AmenityFormValues>, keyof CreateParseFields> & CreateParseFields;
export type EditAmenityFieldsInput = Omit<Partial<AmenityFormValues>, keyof EditParseFields> & EditParseFields;
export type TQueryAmenity = Amenity;

export type TAmenity = Omit<Amenity, keyof NewAmenityFields> & NewAmenityFields;

export type TableAmenityItem = {
  time?: Date;
  createdAt?: Date;
  unit?: number | string;
  resident?: Partial<User>;
  status?: StatusOrderType;
  updatedAt?: Date;
  duration?: string;
  objectId?: string;
  isManager?: boolean;
  isBooked?: boolean;
  manage?: TResManageOrder;
  orderId?: string;
};

export type PreAmenityOrderCalcDataT = {
  eventCost: number;
  sfRequesterAmt: number;
  ppRequesterAmt: number;
  requesterTotalAmt: number;
};

export type OverrideRowT = {
  objectId?: string;
  days?: string;
  blockEvery?: string;
  blockFor?: string;
};
