import React, {useEffect, useRef} from 'react';
import {getTypeUserByTCommunity} from '../../../../helpers/user';
import {useGetCurrencyValue} from '../../../../hooks/currency';
import {usePostComments} from '../../../../hooks/feed';
import {MemberT} from '../../../../types/people';
import {PostTypeT} from '../../../../types/post';
import {ContentLoader} from '../../../../ui-kit/Loader/ContentLoader';
import {Post} from '../Post';
import {LoaderWrapper, PostCommendWrapper, PostCommentsWrapper} from '../styles';
import {PostCommentsProps} from '../types';
import {PostCommentInput} from './PostCommentInput';

export const PostComments: React.FC<PostCommentsProps> = ({
  comments,
  viewerId,
  objectId,
  isComment,
  commentsClose,
  refetch,
  postActions,
  communityPeople,
  adminLabels,
  actions,
  menuActions,
  handleDelete,
  typeUser,
  editMode,
  Author,
  setEditing,
  toggleCreating,
}) => {
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const commentsItems = usePostComments(objectId);

  /*const handleRefetchComments = () => {
    commentsItems?.refetch();
  };*/

  const refetchOnCreate = () => {
    refetch?.();
    commentsItems?.refetch();
  };
  const refetchComments = async () => {
    commentsItems?.refetch();
  };
  const {getSignValue} = useGetCurrencyValue();

  useEffect(() => {
    const height = commentsContainerRef.current?.scrollHeight;
    height && commentsContainerRef.current?.scrollTo(0, height + 100);
  }, [commentsItems?.withLocal?.length]);
  if (!comments && isComment && !commentsItems?.withLocal?.length) return null;
  if (!comments && !isComment && !commentsItems?.withLocal?.length)
    return (
      <PostCommendWrapper>
        <PostCommentInput
          commentsClose={commentsClose}
          objectId={objectId}
          refetchComments={refetchOnCreate}
          Author={Author}
          addLocalPost={commentsItems?.addLocal}
          toggleCreating={toggleCreating}
        />
      </PostCommendWrapper>
    );

  return (
    <>
      {commentsItems?.loading ? (
        <LoaderWrapper>
          <ContentLoader />
        </LoaderWrapper>
      ) : (
        <PostCommentsWrapper ref={commentsContainerRef} $isEditing={editMode}>
          {commentsItems?.withLocal?.map((el) => {
            const author = {
              ...el.Author,
              adminLabel: el.Author?.objectId && adminLabels?.[el.Author.objectId],
              role: getTypeUserByTCommunity(el.Author?.objectId, communityPeople, true),
            } as MemberT;
            return (
              <Post
                {...el}
                isLocal={el?.isLocal}
                Author={author}
                key={el.objectId}
                isComment={true}
                viewerId={viewerId}
                refetchComments={refetchComments}
                postActions={postActions}
                actions={actions}
                getSignValue={getSignValue}
                adminLabels={adminLabels}
                type={PostTypeT.comment}
                menuActions={menuActions}
                handleDelete={handleDelete}
                typeUser={typeUser}
                setEditing={setEditing}
                isCommentsEditing={editMode}
              />
            );
          })}
        </PostCommentsWrapper>
      )}

      {!editMode && (
        <PostCommendWrapper>
          <PostCommentInput
            commentsClose={commentsClose}
            objectId={objectId}
            refetch={refetch}
            refetchComments={refetchOnCreate}
            Author={Author}
            addLocalPost={commentsItems?.addLocal}
            toggleCreating={toggleCreating}
          />
        </PostCommendWrapper>
      )}
    </>
  );
};
