import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';

export const LoadingWrapper = styled.div`
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 50px;
`;

export const FeedSelectWrapper = styled.div`
  max-width: 950px;
  margin: 16px auto 0;
  box-sizing: border-box;

  & > div > div > :last-child {
    margin-left: auto;
  }

  ${Media.down.xl} {
    margin: 10px auto 0;
    & > div > div > :last-child {
      margin-left: 0;
    }
  }

  ${Media.down.m} {
    margin: 4px auto 0;
  }

  ${Media.down.s} {
    margin: 4px auto 0;
    margin-right: -10px;
  }
`;
