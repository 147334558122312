import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {PlanOptionsValues} from '../../constants/plan';
import {hasPlanOption} from '../../helpers/community';
import {useGetActualCommunityList} from '../../hooks/community';
import {useGetCurrencyValue} from '../../hooks/currency';
import {GQLAmenityManageAction} from '../../graphql.schema';
import {Community} from '../../queries/types/community';
import {NavContainer} from '../Navigation';
import {useGetAmenity, useResidentActionsAmenity} from '../../hooks/amenity';
import {AmenityCreated} from '../../components/Amenities/AmenityCreated';

export const ConfigureListCommunitiesAmenity: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {data: amenity, refetch} = useGetAmenity({id});
  const [reviewMode, setReviewMode] = useState(false);
  const {onSubmit} = useResidentActionsAmenity({
    communityId: community?.objectId,
  });
  const {getSignValue} = useGetCurrencyValue();

  const needConfirmation =
    hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.listingsApproval) ?? false;

  const handleSubmit = (communityIdsPublish?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length
        ? [
            onSubmit({
              amenityId: amenity?.objectId,
              communityIds: communityIdsPublish,
              typeBtn: GQLAmenityManageAction.publish,
            }),
          ]
        : []),
    ]).then(() => {
      refetch();
      setReviewMode(true);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [community, communities],
  );

  const selectedCommunitiesIds = [
    ...((amenity?.Published || []) as Community[]),
    // ...((amenity?.ApproveReq || []) as Community[]),
  ].map((el) => {
    return el.objectId;
  });

  const [publishedList, setPublishedList] = useState<Array<string>>(selectedCommunitiesIds);

  useEffect(() => {
    if (!selectedCommunitiesIds?.length) return;
    setPublishedList(selectedCommunitiesIds);
  }, [selectedCommunitiesIds?.length]);

  return (
    <NavContainer>
      <AmenityCreated
        needConfirmation={needConfirmation}
        amenity={amenity}
        community={community}
        communities={communities}
        onSubmit={handleSubmit}
        reviewMode={reviewMode}
        publishedList={publishedList}
        setPublishedList={setPublishedList}
        allCommunitiesIds={allCommunitiesIds}
        initialListCommunity={selectedCommunitiesIds}
        isConfigureMode
        getSignValue={getSignValue}
      />
    </NavContainer>
  );
};
