import React from 'react';
import {StepsWrapper, StepLinkDesktop, StepLinkMobile, StepStroke, StepBorder, StyledStep} from './styles';
import {StepStylesProps} from './types';
import {useTranslation} from 'react-i18next';

type TProps = {
  dataSteps?: {[key: string]: {code: number; title: string; link: string}};
  step: string;
};

type StepProps = {
  link: string;
};

const Step: React.FC<StepStylesProps & StepProps> = ({children, $active, $isdone, link}) => {
  return (
    <>
      <StepLinkMobile to={link} $active={$active} $isdone={$isdone}>
        <StepStroke $active={$active} $isdone={$isdone} />
        <StepBorder $active={$active} $isdone={$isdone}>
          <StyledStep $active={$active} $isdone={$isdone} />
        </StepBorder>
      </StepLinkMobile>
      <StepLinkDesktop to={link} $isdone={$isdone} $active={$active}>
        {children}
      </StepLinkDesktop>
    </>
  );
};

export const Breadcrumbs: React.FC<TProps> = ({dataSteps = {}, step}) => {
  const idx = dataSteps[step]?.code || 0;
  const {t} = useTranslation();

  return (
    <StepsWrapper>
      {Object.values(dataSteps)?.map((step) => (
        <Step link={step.link} key={step.title} $isdone={idx > step.code} $active={idx === step.code}>
          {t(step.title)}
        </Step>
      ))}
    </StepsWrapper>
  );
};
