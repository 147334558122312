import {gql} from '@apollo/client';
import {CommunitySmallFragment} from './community';
import MemberFragment from './people';

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    objectId
    createdAt
    updatedAt
    name
    description
    category
    phone
    promoDescr
    promoTagline
    promotion
    revealCode
    visitedWebsite
    website
    isPublished
    totalClicks
    totalViews
    promoCTA
    Published {
      ... on Community {
        ...CommunitySmallFragment
      }
    }
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    interestedUsers {
      ... on User {
        ...MemberFragment
      }
    }
    Owner {
      ...MemberFragment
    }
    Featured {
      ... on Community {
        ...CommunitySmallFragment
      }
    }
    __typename
  }
  ${MemberFragment}
  ${CommunitySmallFragment}
`;

export const CompanyShortFragment = gql`
  fragment CompanyShortFragment on Company {
    id
    objectId
    name
    category
    promoTagline
    promotion
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
  }
`;
