import {Handler, Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';
import {isString} from '../validations';

export class ValidationHandler<T extends anyObject> extends Validation<T> {
  constructor(validator: Handler<T>, message?: Message) {
    super(message);
    this.$validators = [];
    this.$validators.push(Validator.of(validator, message));
  }

  validate(value: unknown, data: T): boolean {
    let result = true;

    this.$validators.forEach((validator) => {
      if (!result) return;
      result = validator.validate(value as T[keyof T], data);

      if (!result && isString(validator.message)) {
        this.$message = validator.message;
      }
    });

    return result;
  }
}
