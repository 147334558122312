import {isValid} from 'date-fns';
import {EMAIL_REGEX} from '../constants/common';

import {FileInfo} from '../queries/types/parse';

// eslint-disable-next-line @typescript-eslint/ban-types
type AnyFunction = Function;

export const validateEmail = (value: unknown): boolean => {
  return EMAIL_REGEX.test(value as string);
};

export const validateDate = (value: unknown): boolean => {
  return isValid(value);
};

export const isServer = (): boolean => {
  return typeof window === 'undefined';
};

export const isFile = (value: unknown): value is File => {
  return value instanceof File;
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isInt = (value: unknown): value is number => {
  return Number.isInteger(value);
};

export const isBoolean = (value: unknown): value is number => {
  return value === true || value === false;
};

export const isFunction = (value: unknown): value is AnyFunction => {
  return typeof value === 'function';
};

export const isFileInfo = (value: unknown): value is FileInfo => {
  return isString((value as FileInfo)?.url) && isString((value as FileInfo)?.name);
};

export const validateUrl = (value: unknown): value is string => {
  return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value as string);
};

export const StringValidation = {
  email: validateEmail,
  url: validateUrl,
  equal: (cnt: number, value: string): boolean => value.length === cnt,
  gt: (cnt: number, value: string): boolean => value.length > cnt,
  lt: (cnt: number, value: string): boolean => value.length < cnt,
  gte: (cnt: number, value: string): boolean => value.length >= cnt,
  lte: (cnt: number, value: string): boolean => value.length <= cnt,
};
