import React, {useMemo} from 'react';
import {NavContainer} from '../Navigation';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {PageManager} from './PageManager';
import {PageResident} from './PageResident';
import {EmptyTag, MobContainerProps} from '../MobileTabs';
import {CommunityStateType, currentCommunity, userPermissions} from '../../states/community';
import {PermissionsList} from '../../types/settings';

export type HelpDeskPageProps = {
  community: CommunityStateType;
};

export const HelpDeskContainer: React.FC<MobContainerProps> = ({hideNav}) => {
  const [type] = useRecoilState(typeUser);
  const [community] = useRecoilState(currentCommunity);
  const [pesrmissions] = useRecoilState(userPermissions);
  const isManager = useMemo(
    () =>
      type === TypeCommunity.manager ||
      (pesrmissions.includes(PermissionsList.manageHelpDesk) && type === TypeCommunity.admin),
    [pesrmissions, type],
  );

  const Wrapper = hideNav ? EmptyTag : NavContainer;

  return (
    <Wrapper>{isManager ? <PageManager community={community} /> : <PageResident community={community} />}</Wrapper>
  );
};
