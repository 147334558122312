import React from 'react';
import {HeaderTextAuth, InnerHeaderAuth, TitleAuth} from './styles';

interface IProps {
  title?: string;
  headerText?: string;
  linkTitle?: string;
  linkUrl?: string;
}

const HeaderAuth: React.FC<IProps> = ({title}) => {
  return (
    <InnerHeaderAuth>
      <TitleAuth>{title}</TitleAuth>
    </InnerHeaderAuth>
  );
};

const TitleLinkAuth: React.FC<Omit<IProps, 'title'>> = ({headerText}) => {
  return (
    <InnerHeaderAuth>
      <HeaderTextAuth>{headerText}</HeaderTextAuth>
      {/*{linkTitle && linkUrl && <LinkAuth to={linkUrl}>{linkTitle}</LinkAuth>}*/}
    </InnerHeaderAuth>
  );
};

const MemoHeaderAuth = React.memo(HeaderAuth);
const MemoTitleLinkAuth = React.memo(TitleLinkAuth);

export {MemoHeaderAuth, MemoTitleLinkAuth};
