import {useQuery} from '@apollo/client';
import {QueryOrder} from '../constants/common';
import {Location, LocationType} from '../queries/types/locations';
import {equalToQuery} from '../helpers/query';
import {GetLocations, LocationsResponse} from '../queries/locations';
import {useMemo} from 'react';
import {LabelValuePair} from '../types/settings';

export const useAllLocations = (params: {types: LocationType[]; order?: QueryOrder; skip?: boolean}) => {
  const {data, ...response} = useQuery<LocationsResponse>(GetLocations, {
    variables: {
      where: {
        OR: params.types.map((type) => equalToQuery('type', type)),
      },
      order: [params.order || QueryOrder.name_ASC],
    },
    skip: !!params.skip,
    ssr: true,
  });

  return {
    data: data?.locations.edges.map((edge) => edge.node).filter((el) => !el?.isDisabled) || [],
    ...response,
  };
};

export const useGetLocations = () => {
  const {data: cities} = useAllLocations({types: [LocationType.city]});
  const {data: states} = useAllLocations({types: [LocationType.state]});
  const {data: countries} = useAllLocations({
    types: [LocationType.country],
  });

  return {
    cities,
    states,
    countries,
  };
};

interface LocationFilter {
  country?: string;
  state?: string;
}

const toOptions = (source: Location) => ({
  label: source?.name,
  value: source?.id,
  code: source?.code,
});

export const useCountries = () => {
  const countriesSource = useQuery<LocationsResponse>(GetLocations, {
    variables: {
      where: {
        type: {
          equalTo: 'country',
        },
      },
    },
    fetchPolicy: 'cache-and-network',
    ssr: true,
  });
  return countriesSource.data?.locations.edges.map((e) => e.node).filter((el) => !el?.isDisabled);
};

export const useStates = (country?: string) => {
  const statesSource = useQuery<LocationsResponse>(GetLocations, {
    variables: {
      where: {
        type: {
          equalTo: 'state',
        },
        Country: {
          have: {
            OR: [{name: {equalTo: country}}, {id: {equalTo: country}}],
          },
        },
      },
    },
    fetchPolicy: 'cache-first',
    ssr: true,
  });

  return statesSource.data?.locations.edges.map((e) => e.node);
};

export const useCities = (state?: string) => {
  const citiesSource = useQuery<LocationsResponse>(GetLocations, {
    variables: {
      where: {
        type: {
          equalTo: 'city',
        },
        State: {
          have: {
            OR: [{name: {equalTo: state}}, {id: {equalTo: state}}],
          },
        },
      },
      first: 500,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ssr: true,
  });

  return citiesSource.data?.locations.edges.map((e) => e.node);
};

export interface Country {
  code: string | undefined;
  value: string | undefined;
  label: string | undefined;
}

export interface LocationOptionsType {
  countries?: Country[];
  states?: Partial<LabelValuePair>[];
  cities?: Partial<LabelValuePair>[];
}

export const useLocationOptions = (filter: LocationFilter): LocationOptionsType => {
  const countriesSource = useCountries();
  const statesSource = useStates(filter.country);
  const citiesSource = useCities(filter.state);

  const countries = useMemo(() => countriesSource?.map((c) => toOptions(c)), [countriesSource]);
  const states = useMemo(
    () =>
      statesSource
        ?.filter((s) => s.Country?.name === filter.country || s.Country?.id === filter.country)
        .map((s) => toOptions(s)),
    [filter.country, statesSource],
  );
  const cities = useMemo(
    () =>
      citiesSource
        ?.filter((c) => c.State?.name === filter.state || c.State?.id === filter.state)
        .map((c) => toOptions(c)),
    [filter.state, citiesSource],
  );
  return {countries, states, cities};
};
