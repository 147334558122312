export const AccessType = {
  public: 'Public',
  private: 'Private',
};

export const ListingApproval = {
  unmanaged: 'Unmanaged',
  managed: 'Managed ',
};

export enum CommunityUsers {
  Owner = 'Owner',
  Residents = 'Residents',
}

export const CommunityIdKeyInLS = 'currentCommunityId';

export const MaxCountOfCommunity = 30;
export const MaxCountOfCommunityForHangehEmployees = 5000;

export const MainPlans = ['pro (usa)', 'basic (usa)', 'starter (usa)', 'pro (can)', 'basic (can)', 'starter (can)'];

export const HangehEmployeesEmailPattern = /@hangeh\.(com)|(app)|(dev)/;
