import {GetNotificationSettingsResponse} from '../queries/notifications';
import {NotifyType, StateNotificationSettingsType} from '../types/notifications';
import {ListingType} from '../queries/types/item';
import {PaymentPlan} from '../queries/types/payments';
import {MenuItemType} from '../ui-kit/Menu/types';
import {translateFn} from '../types/common';

const imageFormats = ['jpg', 'png', 'jpeg', 'webp', 'svg', 'gif', 'bmp'];

export const isImage = (name?: string) => {
  for (const type of imageFormats) {
    if (name?.endsWith('.' + type)) return true;
  }
  return false;
};

export const toStateNotificationSettings = (data?: GetNotificationSettingsResponse): StateNotificationSettingsType => {
  const user = data?.user;

  return {
    notifyDigest: user?.notifyDigest?.map((e) => e.value),
    notifyJoinRequest: user?.notifyJoinRequest?.map((e) => e.value),
    notifyLikeOrComment: user?.notifyLikeOrComment?.map((e) => e.value),
    notifyListingsRequest: user?.notifyListingsRequest?.map((e) => e.value),
    notifyListingView: user?.notifyListingView?.map((e) => e.value),
    notifyLoopPost: user?.notifyLoopPost?.map((e) => e.value),
    notifyNewCommunities: user?.notifyNewCommunities?.map((e) => e.value),
    notifyManagerAdminPost: user?.notifyManagerAdminPost?.map((e) => e.value),
    notifyMarketingAndFeatures: user?.notifyMarketingAndFeatures?.map((e) => e.value),
    notifyMessage: user?.notifyMessage?.map((e) => e.value),
    notifyNewListings: user?.notifyNewListings?.map((e) => e.value),
    notifyNewMembers: user?.notifyNewMembers?.map((e) => e.value),
    notifyNewRequests: user?.notifyNewRequests?.map((e) => e.value),
    notifyNewResidentListings: user?.notifyNewResidentListings?.map((e) => e.value),
    notifyNewResidents: user?.notifyNewResidents?.map((e) => e.value),
    notifyNewTransaction: user?.notifyNewTransaction?.map((e) => e.value),
    notifyOrderRequest: user?.notifyOrderRequest?.map((e) => e.value),
    notifyProfileView: user?.notifyProfileView?.map((e) => e.value),
    //new
    notifyNewMember: user?.notifyNewMember?.map((e) => e.value),
    notifyAmenityReminder: user?.notifyAmenityReminder?.map((e) => e.value),
    notifyEventReminder: user?.notifyEventReminder?.map((e) => e.value),
    notifyHelpCentre: user?.notifyHelpCentre?.map((e) => e.value),
    notifyNewListing: user?.notifyNewListing?.map((e) => e.value),
    notifyEventAttendees: user?.notifyEventAttendees?.map((e) => e.value),
    notifyUnmanagedAmenities: user?.notifyUnmanagedAmenities?.map((e) => e.value),
    notifyManagedAmenities: user?.notifyManagedAmenities?.map((e) => e.value),
  };
};

const hasSettingsOptions = (options?: string[], option?: string): boolean => Boolean(options?.includes(option ?? ''));

export const hasPush = (options?: string[]) => hasSettingsOptions(options, NotifyType.Push);
export const hasEmail = (options?: string[]) => hasSettingsOptions(options, NotifyType.Email);
export const hasSMS = (options?: string[]) => hasSettingsOptions(options, NotifyType.SMS);

const addOption = (options?: string[], value?: string) => [...(options ?? []), value ?? ''];
const removeOption = (options?: string[], value?: string) => options?.filter((s) => s !== value);

export const handleModifyOptions = (action: 'add' | 'remove', options?: string[], value?: string): string[] =>
  action === 'add' ? addOption(options, value) ?? [] : removeOption(options, value) ?? [];

export const getPlanFeeString = (type: ListingType, plan?: PaymentPlan) => {
  switch (type) {
    case ListingType.rent: {
      const rentOptions = plan?.rentFees?.cFee;
      return `${rentOptions?.cfListerPrc}% + ${rentOptions?.cfListerAbs}c`;
    }
    case ListingType.sell: {
      const sellOptions = plan?.salesFees?.cFee;
      return `${sellOptions?.cfListerPrc}% + ${sellOptions?.cfListerAbs}c`;
    }
    case ListingType.service: {
      const serviceOptions = plan?.serviceFees?.cFee;
      return `${serviceOptions?.cfListerPrc}% + ${serviceOptions?.cfListerAbs}c`;
    }
  }
};

export interface GetMenuHelpDeskOptions {
  leaveCallBack: (id?: string) => void;
  t: translateFn;
  objectId?: string;
}

export const getCommMenu = (options: GetMenuHelpDeskOptions) => {
  const {t, leaveCallBack, objectId} = options;
  const addedOptions: MenuItemType[] = [];

  addedOptions.push({
    title: t('settings:community.actions.leave'),
    onClick: () => leaveCallBack(objectId),
    render: 'danger',
  });

  return addedOptions;
};

export const getMobileCommMenu = (options: GetMenuHelpDeskOptions) => {
  return getCommMenu(options)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};
