import React, {useState} from 'react';
import {CloseBtn, EventContainer, EventContent, EventDate, EventWrapper, PostReactionsWrapper} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {postActions, postMenuActionsList} from '../types';
import {useTranslation} from 'react-i18next';
import {formatDistanceToNowStrict, parseJSON, subDays} from 'date-fns';
import {Happy, Like, Wave} from '../../../ui-kit/Feed/labels/labels';
import {ReactionTypes} from '../../../queries/types/post';

type TProps = {
  date?: Date;
  hide?: postMenuActionsList['hide'];
  postId?: string;
  isLiked?: boolean;
  likes: number;
  isWaved?: boolean;
  waves: number;
  isSmiled?: boolean;
  smiles: number;
  postActions?: postActions;
  refetch?: () => void;
};

export const NotificationBar: React.FC<TProps> = ({
  children,
  date,
  hide,
  postId,
  likes,
  isLiked,
  waves,
  isWaved,
  isSmiled,
  smiles,
  postActions,
  refetch,
}) => {
  const [visible, setVisible] = useState(true);
  const {t} = useTranslation();
  const hidePost = () => {
    hide?.(postId);
    setVisible(false);
  };
  const [reactions, setReactions] = useState({
    like: {liked: isLiked || false, likes: likes},
    smile: {smiled: isSmiled || false, smiles: smiles},
    wave: {waved: isWaved || false, waves: waves},
  });

  const handleLike = () => {
    setReactions((prev) => ({
      ...prev,
      like: {liked: !prev.like.liked, likes: prev.like.liked ? prev.like.likes - 1 : prev.like.likes + 1},
    }));
    postActions && postActions.likePost?.(postId, refetch, true);
  };
  const handleWave = () => {
    if (!reactions.wave.waved) {
      setReactions((prev) => ({
        ...prev,
        wave: {waved: !prev.wave.waved, waves: waves + 1},
      }));
      postActions && postActions.reactPost?.(postId, refetch, ReactionTypes.wave);
    }
  };
  const handleSmile = () => {
    if (!reactions.smile.smiled) {
      setReactions((prev) => ({
        ...prev,
        smile: {smiled: !prev.smile.smiled, smiles: smiles + 1},
      }));
      postActions && postActions.reactPost?.(postId, refetch, ReactionTypes.smile);
    }
  };
  return (
    <>
      {visible && (
        <EventContainer>
          <EventWrapper>
            <EventContent>{children}</EventContent>
            <EventDate>
              {date &&
                t('common:datePPp', {
                  date: formatDistanceToNowStrict(subDays(new Date(parseJSON(date as Date)), 0), {addSuffix: true}),
                })}
            </EventDate>
            <EventDate showMob={true}>
              {date &&
                t('common:datePP', {
                  date: formatDistanceToNowStrict(subDays(new Date(parseJSON(date as Date)), 0), {addSuffix: true}),
                })}
            </EventDate>
            <CloseBtn onClick={hidePost}>
              <IconSvg type={'close'} viewBox={'0 0 20 18'} />
            </CloseBtn>
          </EventWrapper>
          <PostReactionsWrapper>
            <Like
              isReaction={true}
              active={reactions.like.liked}
              onClick={handleLike}
              label={t('common:likeReaction', {count: reactions.like.likes || 0})}
            />
            <Wave
              active={reactions.wave.waved}
              onClick={handleWave}
              label={t('common:wave', {count: reactions.wave.waves || 0})}
            />
            <Happy
              active={reactions.smile.smiled}
              onClick={handleSmile}
              label={t('common:smile', {count: reactions.smile.smiles || 0})}
            />
          </PostReactionsWrapper>
        </EventContainer>
      )}
    </>
  );
};
