import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getResize} from '../../../helpers/file';
import {Br} from '../../../ui-kit/Typography/Br';
import {Avatar, ErrorLine, TextAreaWrapper} from '../../Loop/PostCreate/styles';
import {PostActions} from './components/PostActions';
import {CommentInputContainer, CommentWrapper} from './styles';
import {InputsProps} from './types';
import {EmojiTab} from '../EmojiTab/EmojiTab';
import {handleEditorChangeForUserEvent, handleSetEvent, handleSetUser} from '../../../helpers/common';
import {RichTextEditor} from '../RichText/Editor';
import {isDesktop} from 'react-device-detect';
import {UserMenu} from '../../Account/Popups/HeaderMenu/UserMenu';
import {EventMenu} from '../../Account/Popups/HeaderMenu/EventMenu';
import {RTEdefault} from '../../../constants/common';

export const CommentInput: React.FC<InputsProps & {isAnnouncement?: boolean}> = ({
  handleSubmit,
  handleChange,
  values,
  typeUser,
  user,
  resetType,
  isEdit,
}) => {
  const {t} = useTranslation();
  const placeholder = t('community:loop.inputs.placeholderTextPost', {context: typeUser});
  const [errors, setError] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});

  const validate = () => {
    return !(!values?.text && !values?.Attachments?.length);
  };
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!validate()) {
      setError(t('error:postEmpty'));
      return;
    }
    !btnDisabled && handleSubmit?.();
    setBtnDisabled(true);
  };

  const handleChane = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    handleChange?.({name: next.name, value: next.value});
  };

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  const inputRef = useRef<any>();
  const setEditorReference = (ref: any) => {
    try {
      inputRef.current = ref;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    inputRef?.current?.focus();
  }, [!!inputRef?.current]);
  return (
    <CommentWrapper>
      <CommentInputContainer $isEdit={isEdit}>
        <Avatar src={getResize(user?.avatar, 'lg')} size={'sm'} />
        <TextAreaWrapper $isDesktop={isDesktop}>
          <RichTextEditor
            placeholder={placeholder}
            name={'text'}
            value={values?.text}
            onChange={(e, position) => handleChane(e, position, values?.text ? values?.text : RTEdefault)}
            rows={5}
            emoji={emoji}
            setEmoji={setEmoji}
            //inputRef={inputRef}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            eventName={eventName}
            setEventName={setEventName}
            inputRef={setEditorReference}
          />
          <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
          {userMenuOpen && (
            <UserMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetUser={handleSetUser}
              setUserProfile={setUserProfile}
              searchText={searchText}></UserMenu>
          )}
          {eventMenuOpen && (
            <EventMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetEvent={handleSetEvent}
              setEventName={setEventName}
              searchText={searchText}></EventMenu>
          )}
        </TextAreaWrapper>
      </CommentInputContainer>
      {errors && <ErrorLine>{errors}</ErrorLine>}
      <Br indent={14} />
      <PostActions resetType={resetType} handleSubmit={onSubmit} btnDisabled={btnDisabled} isEdit />
    </CommentWrapper>
  );
};
