import {gql} from '@apollo/client';

export default gql`
  fragment MemberFragment on User {
    id
    objectId
    stripeId
    createdAt
    username
    isVerified
    status
    firstName
    firstLetter
    email
    aptSuite
    bio
    listingCount
    onlineDate
    isOnline
    personInterests {
      ... on Element {
        value
      }
    }
    occupation
    recommendations
    Reviews {
      count
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
    isWaved
  }
`;

export const AdminFragment = gql`
  fragment AdminFragment on CommunityAdmin {
    id
    objectId
    User {
      id
      objectId
      stripeId
      username
      isVerified
      status
      firstName
      firstLetter
      aptSuite
      listingCount
      email
      isOnline
      personInterests {
        ... on Element {
          value
        }
      }
      recommendations
      Reviews {
        count
      }
      Avatar {
        objectId
        id
        file {
          name
          url
        }
      }
      occupation
    }
    Communities
    Members
    Listings
    Requests
    Transactions
    Promotions
    Configurations
    HelpCentre
    Settings
    label
  }
`;

export const ShortAdminFragment = gql`
  fragment ShortAdminFragment on CommunityAdmin {
    id
    objectId
    User {
      id
      objectId
      firstName
      firstLetter
    }
  }
`;

export const MemberWithNameFragment = gql`
  fragment MemberWithNameFragment on User {
    id
    objectId
    stripeId
    createdAt
    username
    isVerified
    status
    firstName
    listingCount
    firstLetter
    lastName
    aptSuite
    onlineDate
    isOnline
    bio
    occupation
    recommendations
    Reviews {
      count
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
  }
`;
