import React, {Suspense} from 'react';
import {EditorWrapper} from './styles';
import {Label} from '../../../ui-kit/Form/TextArea/styles';

//const EditorWithInitial = React.lazy(() => import('./EditorClientInitial'));
const Editor = React.lazy(() => import('./EditorClient'));

type TProps = {
  value?: string;
  onChange?: (next: {name: string; value: string; key: string}, pos: any) => void;
  name: string;
  rows?: number;
  $isError?: boolean;
  label?: string;
  disabled?: boolean;
  emoji?: string;
  setEmoji?: (str: string) => void;
  userProfile?: string;
  setUserProfile?: (str: string) => void;
  eventName?: string;
  setEventName?: (str: string) => void;
  placeholder?: string;
  inputRef?: any;
  disableAutoFocus?: boolean;
  noInitialText?: boolean;
  resetWatcher?: 'reset' | 'editing';
  onFocus?: () => void;
  onBlur?: () => void;
  refWrapper?: React.RefObject<HTMLDivElement> | null | undefined;
  onKeyDown?: (ev: any) => void;
  removeTags?: boolean;
  newState?: string;
};

export const RichTextEditor: React.FC<TProps> = ({$isError, label, refWrapper, onKeyDown, removeTags, ...props}) => {
  const withoutTags = props?.value ? props?.value?.replaceAll('</p>', '\n').replace('<p>', '\n') : props?.value;
  const text = removeTags ? withoutTags : props?.value;
  return (
    <EditorWrapper
      className={'editorWrapper'}
      $isError={$isError}
      $rows={props.rows}
      ref={refWrapper}
      onKeyDown={onKeyDown}>
      {label && <Label $isError={$isError}>{label}</Label>}
      <Suspense fallback={'...'}>{<Editor {...props} value={text} />}</Suspense>
    </EditorWrapper>
  );
};
