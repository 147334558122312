import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {SKIP_PARAMS_KEYS} from '../constants/common';
import {paths, SearchKey} from '../constants/routes';
import {currentCommunity, skipParams} from '../states/community';
import {prevPaths} from '../states/navState';
import {isModal} from '../states/themeMode';
import {SkipParamsOptions} from '../types/auth';
import {useUrlSearchManager} from './urlSearchManager';

export const useUnload = (fn: (e: any) => void) => {
  React.useEffect(() => {
    const onUnload = fn;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [fn]);
};

export const useLinks = () => {
  const [current] = useRecoilState(currentCommunity);

  const getLink = (route?: any, alias?: string) => {
    if (!route) return;

    const currentAlias = alias || current?.alias;

    return currentAlias ? `/${currentAlias}${route}` : route;
  };
  const getLinksForObj = (routes: Record<string, string>, alias?: string) => {
    const newRoutes: Record<string, string> = {};

    for (const key in routes) {
      newRoutes[key] = getLink(routes[key], alias);
    }
    return newRoutes;
  };

  return {getLink, getLinksForObj};
};

export const useGetSkipParams = () => {
  const {search} = useLocation();
  const setSkipParams = useSetRecoilState(skipParams);

  useEffect(() => {
    if (!search) return;
    const searchParams = new URLSearchParams(search);

    SKIP_PARAMS_KEYS.forEach((key) => {
      const value = searchParams.get(key);
      if (value === 'true') setSkipParams((prev) => ({...prev, [key]: true}));
      if (key === SkipParamsOptions.autoAddKey && value) setSkipParams((prev) => ({...prev, [key]: value}));
    });
  }, [search]);
};

export const useTagMenuCorrectPosition = (menuRef?: React.RefObject<HTMLDivElement>) => {
  const location = useLocation();
  useEffect(() => {
    if (menuRef?.current) {
      const windowHeight = window?.innerHeight;
      const blockHeight = menuRef.current?.clientHeight;
      const rect = menuRef.current?.getBoundingClientRect();
      const isMessages = location?.pathname?.includes(paths.messages);
      if (rect && windowHeight && blockHeight) {
        const rest = windowHeight - rect.top;
        if (rest <= blockHeight) {
          menuRef.current.style.top = 'unset';
          menuRef.current.style.bottom = isMessages ? '74px' : '34px';
        }
      }
    }
  }, [!!menuRef]);
};

export const usePageView = () => {
  const {pathname} = useLocation();
  const {push} = useHistory();
  const urlSearch = useUrlSearchManager();
  const view = urlSearch.get(SearchKey.view) || '';

  const setView = (value: string) => {
    if (urlSearch.pathname !== pathname) {
      let url = urlSearch.set(SearchKey.view, value).path;
      url = url.replace(urlSearch.pathname, pathname).replace('/ ', '/');
      push(url);
    } else {
      const url = urlSearch.set(SearchKey.view, value).path.replace('/ ', '/');
      push(url);
    }
  };
  return {view, setView};
};

export const useIsModal = () => {
  const set = useSetRecoilState(isModal);
  useEffect(() => {
    set(true);
    return () => {
      set(false);
    };
  }, []);
};

export const getScreenHeight = () => {
  if (typeof window === 'undefined') return 0;
  if (!window?.screen) return 0;
  return window.screen?.height;
};

export const useDetectKeyboardOpen = (minKeyboardHeight = 300): boolean => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (window?.visualViewport === null) return;
    const listener = () => {
      const vvHeigh = window.visualViewport?.height || 0;
      const newState = window.screen.height - minKeyboardHeight > vvHeigh;
      setIsKeyboardOpen(newState);
    };
    if (typeof visualViewport !== 'undefined') {
      window.visualViewport.addEventListener('resize', listener);
    }
    return () => {
      if (window?.visualViewport === null) return;
      if (typeof visualViewport !== 'undefined') {
        window.visualViewport.removeEventListener('resize', listener);
      }
    };
  }, []);

  return isKeyboardOpen;
};

export type useEditorRefOptionsT = {
  autoFocus?: boolean;
  autoBlur?: boolean;
};
export const useEditorRef = ({autoFocus, autoBlur}: useEditorRefOptionsT) => {
  const [textEditorRef, setTextEditorRef] = useState<{blur: () => void; focus: () => void}>();
  const setRef = (ref: any) => {
    if (!textEditorRef && ref) {
      setTextEditorRef(ref);
    }
  };
  useEffect(() => {
    if (textEditorRef) {
      if (autoBlur) textEditorRef.blur();
    }
  }, [autoFocus, autoBlur, !!textEditorRef]);
  return {textEditorRef, setRef};
};

export const usePrevPaths = () => {
  const {pathname} = useLocation();
  const setPrevPaths = useSetRecoilState(prevPaths);
  useEffect(() => {
    setPrevPaths((prev) => ({prev: prev.current, current: pathname}));
  }, [pathname]);
};
