import styled, {css, StyledProps} from 'styled-components';
import {StyledButtonProps} from './types';
import {Media, theme} from '../theme';
import arrow from './images/arrow-down.png';
import {Link} from 'react-router-dom';

const paddingLeft = (props: StyledButtonProps) => {
  const customPadding = props.leftAdtPadding || 0;
  return props.leftAdornment ? `${customPadding}px` : 0;
};

const justifyContent = (props: StyledButtonProps) => {
  return props.align === 'left' ? 'flex-start' : 'center';
};

const backgroundColor = (props: StyledProps<StyledButtonProps>) => {
  if (props.ghost) return 'transparent';
  if (props.disabled) return props.theme.palette.grayscale._11;
  if (props.color) return props.color;
  return props.variant ? props.theme.palette.button[props.variant] : props.theme.palette.button.primary;
};

const color = (props: StyledProps<StyledButtonProps>) => {
  return props.ghost && props.variant ? props.theme.palette.button[props.variant] : props.theme.palette.grayscale._1;
};

const width = (props: StyledButtonProps) => {
  return props.width ? props.width : '100%';
};

const height = (props: StyledButtonProps) => {
  return props.height ? props.height : props.variant === 'social' ? '54px' : '40px';
};

const border = (props: StyledProps<StyledButtonProps>) => {
  return props.ghost
    ? props.variant === 'select'
      ? `1px solid ${props.theme.palette.text.duodecimal}`
      : props.variant === 'social'
      ? `1px solid ${props.theme.palette.text.hexadecimal}`
      : `2px solid ${backgroundColor({
          ghost: false,
          color: props.color,
          variant: props.variant,
          theme: props.theme,
        })}`
    : 'none';
};

const opacity = (props: StyledButtonProps) => {
  return props?.loading ? 0.7 : 1;
};

const paddingByArrow = (props: StyledButtonProps) => {
  if (props.size === 'md') {
    return '19px 69px 19px 19px';
  }
  if (props.size === 'sm') {
    return '10px 41px 10px 15px';
  }
  return '25px 95px 25px 40px';
};

const rightByArrow = (props: StyledButtonProps) => {
  if (props.size === 'md') {
    return '25px';
  }
  if (props.size === 'sm') {
    return '12px';
  }
  return '35px';
};

const sizeByArrow = (props: StyledButtonProps) => {
  return props.size === 'lg' ? '50px' : '24px';
};

const fontSize = (props: StyledButtonProps) => {
  return props.fontSize;
};

const padding = (props: StyledButtonProps) => {
  return props.variant === 'social' && !props.label
    ? '5px 50px'
    : props.variant === 'social' && props.label
    ? '5px 15px 5px 50px'
    : '5px';
};

const styles = css`
  ${({theme}) => theme.font.primary.semiBold};
  box-sizing: border-box;
  align-items: center;
  background-color: ${backgroundColor};
  border: ${border};
  color: ${color};
  display: flex;
  justify-content: ${justifyContent};
  padding: ${padding};
  position: relative;
  width: ${width};
  height: ${height};
  cursor: pointer;
  text-decoration: none;
  opacity: ${opacity};
  ${Media.down.s} {
    ${({theme}) => theme.font.primary.regular};
  }
  &:hover {
    filter: brightness(85%);
    transition: all 0.3s ease-out;
  }
  transition: background-color 0.3s;
`;

const styleArrowBtn = css`
  width: ${width};
  height: auto;
  border: 2px solid ${({theme}) => theme.palette.grayscale._3};
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  padding: ${paddingByArrow};
  position: relative;
  overflow: initial;
  background-color: ${({mode}) => (mode === 'dark' ? theme.palette.grayscale._3 : theme.palette.grayscale._0)};
  &::after {
    content: '';
    position: absolute;
    background: center / contain no-repeat url('${arrow}');
    top: 50%;
    transform: translateY(-50%);
    right: ${rightByArrow};
    width: ${sizeByArrow};
    height: ${sizeByArrow};
  }
`;

export const PrimaryButton = styled.button<StyledButtonProps>`
  ${styles};
  border-radius: 12px;
`;

export const RoundedButton = styled.button<StyledButtonProps>`
  ${styles};
  border-radius: 25px;
`;

export const RoundedLinkButton = styled(Link)<StyledButtonProps>`
  ${styles};
  border-radius: 25px;
`;

export const PrimaryLinkButton = styled(Link)<StyledButtonProps>`
  ${styles};
  border-radius: 12px;
`;

export const PrimaryExternalLinkButton = styled.a<StyledButtonProps>`
  ${styles};
  border-radius: 12px;
`;

export const RoundedExternalLinkButton = styled.a<StyledButtonProps>`
  ${styles};
  border-radius: 25px;
`;

export const LeftAdornment = styled.div`
  height: 100%;
  left: 0;
  min-width: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  & > svg {
    display: block;
  }
`;

export const LabelWrapper = styled.div`
  margin-left: auto;
`;

export const Value = styled.div.attrs({className: 'button-value'})`
  font-size: ${fontSize || '14px'};
  cursor: pointer;
  pointer-events: none;
  margin-top: -1px;
  position: relative;
  padding-left: ${paddingLeft};
  ${Media.down.s} {
    font-size: ${fontSize || '16px'};
  }
`;

export const ButtonBaseRoot = styled.div`
  outline: none;
  // will-change: transform: Fix safari overflow hidden with border-radius
  // credits https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  will-change: transform;
`;

export const ArrowBtn = styled.button<StyledButtonProps>`
  ${styleArrowBtn};
`;
export const ArrowLinkBtn = styled(Link)<StyledButtonProps>`
  ${styleArrowBtn};
  display: inline-block;
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: right;
  padding: 0 8px 0;
`;

export const SpinnerWrapper = styled.div<{$active?: boolean} & StyledButtonProps>`
  display: flex;
  align-items: center;
  padding-right: ${({$active}) => ($active ? '8px' : '0px')};
  width: ${({$active}) => ($active ? '24px' : '0px')};
  transition: width 0.4s ease;

  & > svg {
    animation: 2s linear 0s normal none infinite rotate;

    path {
      stroke: ${color};
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;
