import React from 'react';
import {useRecoilState} from 'recoil';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import LoopManagerContainer from './Manager';
import {useViewer} from '../../hooks/user';
import {currentCommunity, userPermissions} from '../../states/community';
import LoopResidentContainer from './Resident';
import {MobContainerProps} from '../MobileTabs';

// import loadable, {LoadableComponent} from '@loadable/component';
// import {LoopResidentContainerProps} from './Resident';
// import {LoopManagerContainerProps} from './Manager';
// const LoopResidentContainer: LoadableComponent<LoopResidentContainerProps> = loadable(() => import('./Resident'));
// const LoopManagerContainer: LoadableComponent<LoopManagerContainerProps> = loadable(() => import('./Manager'));

export const LoopContainer: React.FC<MobContainerProps> = ({hideNav}) => {
  const viewer = useViewer();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const [permissions] = useRecoilState(userPermissions);
  const loading =
    !typeUser ||
    !viewer ||
    !community ||
    ((typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin) && permissions.length === 0);
  return (
    <>
      {(typeUser === TypeCommunity.resident || typeUser === TypeCommunity.admin) && (
        <LoopResidentContainer
          loading={loading}
          permissions={permissions}
          hideNav={hideNav}
          viewer={viewer}
          community={community}
          viewerId={viewer?.objectId}
          typeUser={typeUser}
        />
      )}
      {typeUser === TypeCommunity.manager && (
        <LoopManagerContainer
          loading={loading}
          permissions={permissions}
          hideNav={hideNav}
          viewer={viewer}
          community={community}
          viewerId={viewer?.objectId}
        />
      )}
    </>
  );
};
