export const colorOptions = <const>[
  'orange',
  'darkgray',
  'green',
  'aqua',
  'red',
  'yellow',
  'blue',
  'lightGray',
  'white',
  'gray',
  'gray-2',
  'grayLight',
  'grayLight-2',
  'none',
  'aqua-2',
  'darkBlue',
  'lightYellow',
  'blueLight',
  'black',
];
export type color = typeof colorOptions[number];

export const iconOptions = <const>[
  'sun',
  'sun-2',
  'credit-card',
  'credit-card-2',
  'circle-arrow-down',
  'feed',
  'hangeh_logo-aqua',
  'hangeh_logo-aqua-2',
  'home-alt',
  'message-warning',
  'person',
  'settings',
  'tag-alt',
  'Box',
  'chevrone-left',
  'chevrone-right',
  'chevrone-down',
  'vector-right',
  'vector-left',
  'polygon-down',
  'buttonHelp',
  'calendar',
  'calendar-2',
  'location',
  'circle-ok',
  'news',
  'news-2',
  'walking',
  'walking-2',
  'close',
  'people',
  'grid',
  'chat',
  'plus',
  'help',
  'copy',
  'chat-alt',
  'more',
  'close-2',
  'verified',
  'verified-2',
  'spam',
  'spam-2',
  'people2',
  'search',
  'star',
  'lock-alt',
  'support',
  'hangeh-logo',
  'checked-mark',
  'cancel-rounded',
  'bike',
  'bell',
  'arrow-left',
  'settings-2',
  'poll',
  'video',
  'happy-face',
  'controls-alt',
  'list-alt',
  'image',
  'send',
  'coffee-cup',
  'shield',
  'pencil',
  'checkmark',
  'checkmark-circle',
  'ellypsis',
  'info',
  'info-alt',
  'lightbulb',
  'camera',
  'list-view',
  'add',
  'person-add',
  'bug',
  'help-alt',
  'right',
  'dollar',
  'msg-sent-light',
  'no-messages-light',
  'no-requests-light',
  'shape',
  'minus',
  'clipboard',
  'empty-address',
  'sign-out',
  'search-filled',
  'message-send',
  'bored',
  'share',
  'rocket',
  'plane',
  'horn',
  'file',
  'download',
  'loading',
  'folder',
  'sticker',
  'pin',
  'hangeh-logo-beta',
  'search-2',
  'layers',
  'no-attendees',
  'no-bookings',
  'hamburger',
  'hamburger-light',
  'car-alt',
  'coffee',
  'tv',
  'sounds',
  'user',
  'sports',
  'food',
  'travel',
  'events',
  'movies',
  'arts',
  'fitness',
  'games',
  'music',
  'photo',
  'videoGames',
  'book',
  'biking',
  'dance',
  'others',
  'big-circle-ok',
  'groups',
  'animal',
  'duotone-check',
  'biz-box',
  'link',
  'phone',
  'upload',
  'cart',
  'umbrella',
  'close-3',
  'close-4',
  'star-2',
  'star-3',
  'wave',
  'new-message',
  'comment',
  'speak',
  'previous',
  'entrance',
  'arrow-down',
  'checked-circle',
  'not-checked-circle',
  'add-circle',
  'close-circle',
  'check-circle',
  'pencil-2',
  'like',
  'message',
  'list',
  'eye-open',
  'eye-closed',
  'delete-alt',
  'marked-circle',
  'empty-circle',
  'mail',
  'circled-arrow',
  'circled-plus',
  'circle-double',
  'edit',
  'combined-shape',
  'wave-hand',
  'wave-hand-active',
  'protection',
  'settings-3',
  'bell-2',
  'stars',
  'cube',
  'rounded-plus',
  'arrow-right',
  'earn',
  'reload',
  'logo-small',
  'facebook',
  'google',
  'apple',
  'lock',
];
export type icon = typeof iconOptions[number];

export interface StyledSvgIconProps {
  className?: string;
  type?: icon;
  width?: string;
  height?: string;
  fill?: color | 'none';
  fillChildren?: color | 'none';
  stroke?: color;
  strokeWidth?: number;
  viewBox?: string;
}
