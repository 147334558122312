import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {useGetActualCommunityList} from '../../hooks/community';
import {GQLAmenityManageAction} from '../../graphql.schema';
import {Community} from '../../queries/types/community';
import {NavContainer} from '../Navigation';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useGetAmenity, useResidentActionsAmenity} from '../../hooks/amenity';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {useLinks} from '../../hooks/common';
import {route} from '../../constants/routes';
import {AmenityCreated} from '../../components/Amenities/AmenityCreated';
import {isEqual} from 'date-fns';
import {commLoading} from '../../types/community';

export const AmenityCreatedContainer: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {data: amenity, refetch} = useGetAmenity({id});
  const [reviewMode, setReviewMode] = useState(true);
  const [type] = useRecoilState(typeUser);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [addCommLoading, setAddCommLoading] = useState<commLoading>(false);
  const setCommLoading = (t: commLoading) => setAddCommLoading(t);
  const {onSubmit} = useResidentActionsAmenity({
    communityId: community?.objectId,
    // onSuccess: () => setIsPublished(true),
  });
  const {getSignValue} = useGetCurrencyValue();

  // const needConfirmation =
  //   hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.listingsApproval) ?? false;
  const needConfirmation = false;
  const [hardUpdated, setHardUpdated] = useState<'created' | 'updated'>();

  const handleSubmit = (communityIdsPublish?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length
        ? [
            onSubmit({
              amenityId: amenity?.objectId,
              communityIds: communityIdsPublish,
              typeBtn: GQLAmenityManageAction.publish,
            }),
          ]
        : []),
    ]).then(() => {
      refetch();
      setReviewMode(true);
      setCommLoading(false);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [communities, community],
  );

  const [publishedList, setPublishedList] = useState<Array<string>>(allCommunitiesIds);

  const selectedCommunitiesIds = useMemo(() => {
    return [
      ...((amenity?.Published || []) as Community[]),
      // ...((amenity?.ApproveReq || []) as Community[]),
    ].map((el) => {
      return el.objectId;
    });
  }, [amenity]);

  useEffect(() => {
    setPublishedList(allCommunitiesIds);
  }, [communities?.length]);
  useEffect(() => {
    if (type && type !== TypeCommunity.manager && type !== TypeCommunity.admin) {
      push(getLink(route.amenities.get()));
    }
  }, [type]);
  useEffect(() => {
    const isUpdated = !isEqual(new Date(amenity?.createdAt ?? ''), new Date(amenity?.updatedAt ?? ''));
    if (!isUpdated) {
      setHardUpdated('created');
    }
  }, [amenity?.objectId]);

  const oneCommunitySubmit = () => {
    setCommLoading?.('one');
    handleSubmit(publishedList.filter((communityId) => !selectedCommunitiesIds?.includes(communityId)));
  };

  useEffect(() => {
    if (communities.length === 1 && amenity?.objectId) {
      setReviewMode(true);
      oneCommunitySubmit();
    } else setReviewMode(false);
  }, [amenity?.objectId, communities.length]);

  return (
    <NavContainer>
      <AmenityCreated
        needConfirmation={needConfirmation}
        amenity={amenity}
        community={community}
        createdType={hardUpdated}
        communities={communities}
        onSubmit={handleSubmit}
        reviewMode={reviewMode}
        publishedList={publishedList}
        setPublishedList={setPublishedList}
        allCommunitiesIds={allCommunitiesIds}
        initialListCommunity={selectedCommunitiesIds}
        getSignValue={getSignValue}
        setCommLoading={setCommLoading}
        addCommLoading={addCommLoading}
      />
    </NavContainer>
  );
};
