import {useQuery} from '@apollo/client';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Seo} from '../components/SEO';
import {makeSeoKeywords} from '../helpers/common';
import {CommunitiesResponseType, GetCommunitiesMicroQuery} from '../queries/community';

type OGCommunityInfoPropsT = {
  alias?: string;
  isJoin?: boolean;
};

export const OGCommunityInfo: React.FC<OGCommunityInfoPropsT> = ({alias, isJoin}) => {
  const {t} = useTranslation();
  const {data} = useQuery<CommunitiesResponseType>(GetCommunitiesMicroQuery, {
    variables: {
      where: {
        alias: {
          equalTo: alias,
        },
      },
    },
    skip: !alias,
    ssr: true,
  });
  const community = data?.communities?.edges?.[0]?.node;
  if (!community) return null;
  const join_seoTitle = t('common:seo.join', {text: community?.name});
  const join_seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.join', {text: community?.name})]);
  if (isJoin)
    return (
      <Seo
        title={join_seoTitle}
        description={community?.descr}
        keywords={join_seoKeywords}
        og={{
          title: join_seoTitle,
          description: community?.descr?.replace(/<\/?[^>]+(>|$)/g, ''),
          image: community?.Avatar?.file?.url,
          // imageWidth: '70',
          // imageHeight: '70',
        }}
      />
    );

  return (
    <Seo
      title={community?.name}
      description={community?.descr}
      og={{
        title: community?.name,
        description: community?.descr?.replace(/<\/?[^>]+(>|$)/g, ''),
        image: community?.Avatar?.file?.url,
        // imageWidth: '70',
        // imageHeight: '70',
      }}
    />
  );
};

type OGOrderInfoPropsT = {
  item?: any;
  orderId?: string | null;
};

export const OGOrderInfo: React.FC<OGOrderInfoPropsT> = ({item}) => {
  const name = item?.name;
  const descr = item?.descr;
  const avatar = item?.images?.[0];

  if (!item) return null;
  return (
    <Seo
      og={{
        title: name,
        description: descr?.replace(/<\/?[^>]+(>|$)/g, ''),
        image: avatar?.file?.url,
        // imageWidth: '300',
        // imageHeight: '200',
      }}
    />
  );
};

type OGEventInfoPropsT = {
  event?: any;
  orderId?: string | null;
};

export const OGEventInfo: React.FC<OGEventInfoPropsT> = ({event}) => {
  const name = event?.name;
  const descr = event?.descr;
  const avatar = event?.images?.[0];

  if (!event) return null;
  return (
    <Seo
      og={{
        title: name,
        description: descr?.replace(/<\/?[^>]+(>|$)/g, ''),
        image: avatar?.file?.url,
        // imageWidth: '300',
        // imageHeight: '200',
      }}
    />
  );
};
