import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EventFormAction, TEvent} from '../../types/event';
import {
  AttendeesList,
  DesktopView,
  EventContentRow,
  EventPayType,
  InnerListingView,
  ListingViewDescr,
  ListingViewSubTitle,
  ListingViewTitle,
  MainContListingView,
  MobileView,
  WrapperListingView,
} from './styles';
import {Slider} from './Slider';
import {RichText} from '../common/RichText/RichText';
import {NavContainer} from '../../containers/Navigation';
import {RequestAside} from './RequestAside';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {ListingNotFound} from '../Extras';
import {SendMessageStatus} from '../../ui-kit/RequestCard/types';
import {MobileBar} from './Slider/styles';
import {Button} from '../../ui-kit/Button/Button';
import {AddCreditCardPopup} from '../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {useGetMessagesAllows} from '../../hooks/community';
import {eventAvailableDataT, TDataCreator, TResCreateEventOrder} from '../../hooks/event';
import {LinkBack} from '../common/Buttons/LinkBack';
import {PersonCommonCard} from '../common/Person';
import {EmptySpace} from '../People/PeopleList';
import {TCommunity} from '../../types/community';
import {Linkify} from '../common/Buttons/Linkify';
import {TypeCommunity} from '../../types/auth';
import {formatUserLabel} from '../../helpers/people';
import {typeUser} from '../../states/typeUser';
import {useRecoilState} from 'recoil';
import {Gallery} from 'react-photoswipe-gallery';
import {isFromApp} from '../../ui-kit/Navigation/mobile/styles';
import {route} from '../../constants/routes';
import {Media} from '../../ui-kit/theme';
import {HaveAccountWrapper, LinkAuth, FooterTextAuth, TopLogoWrapper} from '../Auth/styles';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {Avatar, IconSvg} from '../Loop/PostCreate/styles';
import {useAuthPath} from '../../hooks/auth';
import {CustomAttendees} from '../../containers/Events/ViewPublicEvent';
import {getResize} from '../../helpers/file';
import {PeopleCard, CardWrapper, InfoWrapperSmall, PersonName, PersonLabels} from '../People/styles';
import {Listings} from '../../ui-kit/ProfileIntro/labels';
import {useLocation} from 'react-router-dom';
import {getAliasFromPath} from '../../helpers/community';
import {useLinks} from '../../hooks/common';

interface IProps {
  event?: Partial<TEvent>;
  $loading?: boolean;
  action: EventFormAction;
  onPublish?: () => void;
  onUnlist?: () => void;
  viewerObjId?: string;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataCreator?: TDataCreator;
  onDelete?: () => void;
  onRenew?: () => void;
  isAvailable?: eventAvailableDataT;
  communityAlias?: string;
  communityId?: string;
  requestAccess?: boolean;
  refetch?: () => void;
  dataOrder: TResCreateEventOrder;
  availableLoading?: boolean;
  comm?: Partial<TCommunity> | null;
  adminLabels: Record<string, string>;
  targetTypeUser?: TypeCommunity;
  attendees?: number;
  isAuthor: boolean;
  handleLike?: () => Promise<boolean>;
  isPreview?: boolean;
  isPublicView?: boolean;
  customAttendees?: CustomAttendees;
}
const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};
export const ViewEvent: React.FC<IProps> = ({
  event,
  $loading,
  dataCreateMsg,
  isAvailable,
  communityAlias,
  requestAccess,
  refetch,
  dataCreator,
  dataOrder,
  availableLoading,
  comm,
  adminLabels,
  targetTypeUser,
  attendees,
  isAuthor,
  handleLike,
  isPreview,
}) => {
  const {t} = useTranslation();
  const textSeo = event?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const descrBlock = useRef<HTMLDivElement>(null);
  const imagesBlock = useRef<HTMLDivElement>(null);
  const sendMessageRef = useRef<HTMLDivElement>(null);
  const requestCardRef = useRef<HTMLDivElement>(null);
  const changeCardStatusRef = useRef<(status: SendMessageStatus) => void>();
  const [showBottomMenu] = useState<boolean>(false);
  const [noRequestModal, setNoRequestModal] = useState<boolean>(false);
  const [showPropositionAddCardPopup, setShowProposition] = useState<boolean>(false);
  const showWarnPopup = () => setShowProposition(true);
  const showAddCardPopup = () => setNoRequestModal(true);
  const [userType] = useRecoilState(typeUser);

  const blocksHeight = (descrBlock?.current?.offsetHeight || 0) + (imagesBlock.current?.offsetHeight || 0) || undefined;
  const requestCardRange = blocksHeight ? blocksHeight + 60 : blocksHeight;

  const listingOg = useMemo(() => {
    return {
      title: event?.name,
      description: event?.descr,
      image: event?.images?.[0]?.file?.url,
    };
  }, [event]);
  const rewardType = t('events:reward.free');
  const handleWriteMsg = useCallback(() => {
    return changeCardStatusRef.current?.(SendMessageStatus.Send);
  }, [changeCardStatusRef.current]);

  const handleScrollMessage = () => {
    handleWriteMsg?.();
    sendMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const handleScrollRequest = () => {
    requestCardRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const handleCloseAddCreditCardPopup = () => {
    setNoRequestModal(false);
    refetch?.();
  };

  const closeProposition = () => {
    setShowProposition(false);
  };

  const handleAddCC = () => {
    closeProposition();
    showAddCardPopup();
  };

  useEffect(() => {
    // addEventListener('scroll', () => {
    //   if (!requestCardRef) return;
    //   const isShow =
    //     (requestCardRef.current?.getBoundingClientRect().bottom || 0) >
    //     window.pageYOffset + document.documentElement.clientHeight;
    //   setShowBottomMenu(isShow);
    // });
  }, []);
  const allows = useGetMessagesAllows({directUserId: event?.Lister?.objectId});
  const userLabel = formatUserLabel({t, userId: event?.Lister?.objectId, typeUser: targetTypeUser, adminLabels});

  return (
    <NavContainer>
      {!requestAccess && noRequestModal && <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />}
      <Seo title={textSeo} keywords={seoKeywords} og={listingOg} />
      {$loading ? (
        <ListingViewSkeleton />
      ) : event?.isDeleted ? (
        <ListingNotFound />
      ) : (
        <>
          <DesktopView>
            <MainContListingView ref={imagesBlock}>
              <WrapperListingView>
                <LinkBack type={'events'} />
                <InnerListingView>
                  <ListingViewTitle>
                    <Linkify>{event?.name}</Linkify>
                  </ListingViewTitle>
                  <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                    <Slider images={event?.images} isPreview={isPreview} />
                  </Gallery>
                  {event?.isPublished && (!isAuthor || isPreview) && (
                    <RequestAside
                      width={'300px'}
                      user={dataCreator}
                      descr={t('requests:descr.send')}
                      event={event}
                      dataCreateMsg={dataCreateMsg}
                      className={'listing-view__form-aside'}
                      bottomBoundary={requestCardRange}
                      isAvailable={isAvailable}
                      isDesktop
                      accessRequest={requestAccess}
                      showWarnPopup={showWarnPopup}
                      canSandMessage={allows.allow}
                      dataOrder={dataOrder}
                      availableLoading={availableLoading}
                      userLabel={userLabel}
                      attendees={attendees}
                      isPreview={isPreview}
                    />
                  )}
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView ref={descrBlock}>
              <WrapperListingView offsetTop={'25px'}>
                <InnerListingView>
                  <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                  <ListingViewDescr>
                    <RichText html={event?.descr} />
                  </ListingViewDescr>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            {event?.showAttendees && !isAuthor && (
              <MainContListingView>
                <WrapperListingView offsetTop={'25px'}>
                  <InnerListingView>
                    <ListingViewSubTitle>{t('events:insights.attendees')}</ListingViewSubTitle>
                    <AttendeesList>
                      {event?.Attendees?.map((el) => (
                        <PersonCommonCard {...el} key={el.objectId} comm={comm} typeUser={userType} />
                      ))}
                      <EmptySpace />
                    </AttendeesList>
                  </InnerListingView>
                </WrapperListingView>
              </MainContListingView>
            )}
          </DesktopView>

          <MobileView>
            <MainContListingView>
              <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                <Slider
                  images={event?.images}
                  communityAlias={communityAlias}
                  handleLike={handleLike}
                  isLike={event?.isLike}
                  isPreview={isPreview}
                />
              </Gallery>
              <EventContentRow>
                <ListingViewTitle>{event?.name}</ListingViewTitle>
                <EventPayType>{rewardType}</EventPayType>
              </EventContentRow>
            </MainContListingView>
            {/* <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                <InnerListingView>
                  <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                  <ListingViewDescr>
                    <RichText html={event?.descr} />
                  </ListingViewDescr>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView> */}
            <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                {event?.isPublished && (!isAuthor || isPreview) && (
                  <RequestAside
                    width={'300px'}
                    user={dataCreator}
                    descr={t('requests:descr.send')}
                    event={event}
                    dataCreateMsg={dataCreateMsg}
                    className={'listing-view__form-aside'}
                    changeCardStatusRef={changeCardStatusRef}
                    sendMessageRef={sendMessageRef}
                    requestCardRef={requestCardRef}
                    isAvailable={isAvailable}
                    accessRequest={requestAccess}
                    showWarnPopup={showWarnPopup}
                    dataOrder={dataOrder}
                    availableLoading={availableLoading}
                    userLabel={userLabel}
                    canSandMessage={allows.allow}
                    attendees={attendees}
                    description={event?.descr}
                    isPreview={isPreview}
                  />
                )}
              </WrapperListingView>
            </MainContListingView>
            {showBottomMenu && (
              <MobileBar>
                <Button variant={'secondary'} ghost={true} onClick={handleScrollMessage}>
                  {t('events:view.buttons.message')}
                </Button>
                <Button variant={'primary'} onClick={handleScrollRequest}>
                  {t('events:view.buttons.checkAvailability')}
                </Button>
              </MobileBar>
            )}
          </MobileView>
          {showPropositionAddCardPopup && (
            <Confirmation
              title={t('common:modals.addCC.title')}
              subTitle={t('common:modals.addCC.descr')}
              okText={t('common:modals.addCC.button')}
              onClose={closeProposition}
              onOk={handleAddCC}
            />
          )}
        </>
      )}
    </NavContainer>
  );
};

export const EventPublicView: React.FC<IProps> = ({
  event,
  dataCreateMsg,
  isAvailable,
  communityAlias,
  requestAccess,
  dataCreator,
  dataOrder,
  availableLoading,
  attendees,
  handleLike,
  isPreview,
  customAttendees,
}) => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const {getLink} = useLinks();
  const alias = getAliasFromPath(pathname);
  const {logInPath} = useAuthPath();
  const isApp = isFromApp();
  const descrBlock = useRef<HTMLDivElement>(null);
  const imagesBlock = useRef<HTMLDivElement>(null);
  const sendMessageRef = useRef<HTMLDivElement>(null);
  const requestCardRef = useRef<HTMLDivElement>(null);
  const changeCardStatusRef = useRef<(status: SendMessageStatus) => void>();
  const blocksHeight = (descrBlock?.current?.offsetHeight || 0) + (imagesBlock.current?.offsetHeight || 0) || undefined;
  const requestCardRange = blocksHeight ? blocksHeight + 60 : blocksHeight;
  const rewardType = t('events:reward.free');

  return (
    <>
      <>
        <TopLogoWrapper to={'/'} $isApp={isApp}>
          <IconSvg
            type={'hangeh-logo-beta'}
            stroke={'none'}
            fill={'aqua'}
            width={'132'}
            height={'55'}
            viewBox={'10 0 152 64'}
          />
        </TopLogoWrapper>
        <HaveAccountWrapper $isApp={isApp}>
          <HiddenElement mq={Media.up.m}>
            <LinkAuth $noBorder={true} to={getLink(route.home.path, alias)}>
              <IconSvg stroke={'gray'} type={'close-4'} height={'40px'} width={'40px'} />
            </LinkAuth>
          </HiddenElement>
          <HiddenElement mq={Media.down.m}>
            <FooterTextAuth>{t('auth:signUp.existAccount')}</FooterTextAuth>
            <LinkAuth to={logInPath}>{t('auth:auth.buttons.login')}</LinkAuth>
          </HiddenElement>
        </HaveAccountWrapper>
      </>
      <DesktopView>
        <MainContListingView ref={imagesBlock}>
          <WrapperListingView>
            <InnerListingView>
              <ListingViewTitle>
                <Linkify>{event?.name}</Linkify>
              </ListingViewTitle>
              <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                <Slider images={event?.images} isPreview={isPreview} />
              </Gallery>
              <RequestAside
                width={'300px'}
                user={dataCreator}
                descr={t('requests:descr.send')}
                event={event}
                dataCreateMsg={dataCreateMsg}
                className={'listing-view__form-aside'}
                bottomBoundary={requestCardRange}
                isAvailable={isAvailable}
                isDesktop
                accessRequest={requestAccess}
                canSandMessage={true}
                dataOrder={dataOrder}
                availableLoading={availableLoading}
                attendees={attendees}
                isPreview={isPreview}
                isPublicView={true}
              />
            </InnerListingView>
          </WrapperListingView>
        </MainContListingView>
        <MainContListingView ref={descrBlock}>
          <WrapperListingView offsetTop={'25px'}>
            <InnerListingView>
              <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
              <ListingViewDescr>
                <RichText html={event?.descr} />
              </ListingViewDescr>
            </InnerListingView>
          </WrapperListingView>
        </MainContListingView>
        {/* {event?.showAttendees && (
          <MainContListingView>
            <WrapperListingView offsetTop={'25px'}>
              <InnerListingView>
                <ListingViewSubTitle>{t('events:insights.attendees')}</ListingViewSubTitle>
                <AttendeesList>
                  {event?.Attendees?.map((el) => (
                    <PersonCommonCard {...el} key={el.objectId} comm={comm} />
                  ))}
                  <EmptySpace />
                </AttendeesList>
              </InnerListingView>
            </WrapperListingView>
          </MainContListingView>
        )} */}
        {!!customAttendees?.length && (
          <MainContListingView>
            <WrapperListingView offsetTop={'25px'}>
              <InnerListingView>
                <ListingViewSubTitle>{t('events:insights.attendees')}</ListingViewSubTitle>
                <AttendeesList>
                  {customAttendees?.map((el, i) => (
                    <PeopleCard to={getLink(logInPath, alias)} key={i}>
                      <CardWrapper>
                        <Avatar src={getResize(el?.avatar?.file?.url, 'md')} size={'sm'} />
                        <InfoWrapperSmall>
                          <PersonName>
                            <span>
                              {el?.firstName} {el?.firstLetter}
                            </span>
                          </PersonName>
                          <PersonLabels>
                            {/* <PersonType isAdmin={isAdmin} isManager={isManager} adminLabels={adminLabels} userId={objectId} /> */}
                            <Listings count={el?.totalListings || 0} label={t('search:labels.listings')} />
                          </PersonLabels>
                        </InfoWrapperSmall>
                      </CardWrapper>
                    </PeopleCard>
                  ))}
                  <EmptySpace />
                </AttendeesList>
              </InnerListingView>
            </WrapperListingView>
          </MainContListingView>
        )}
      </DesktopView>

      <MobileView>
        <MainContListingView>
          <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
            <Slider
              images={event?.images}
              communityAlias={communityAlias}
              handleLike={handleLike}
              isLike={event?.isLike}
              isPreview={isPreview}
              isPublicView={true}
            />
          </Gallery>
          <EventContentRow>
            <ListingViewTitle>{event?.name}</ListingViewTitle>
            <EventPayType>{rewardType}</EventPayType>
          </EventContentRow>
        </MainContListingView>
        <MainContListingView>
          <WrapperListingView offsetTop={'25px'}>
            <RequestAside
              width={'300px'}
              user={dataCreator}
              descr={t('requests:descr.send')}
              event={event}
              dataCreateMsg={dataCreateMsg}
              className={'listing-view__form-aside'}
              changeCardStatusRef={changeCardStatusRef}
              sendMessageRef={sendMessageRef}
              requestCardRef={requestCardRef}
              isAvailable={isAvailable}
              accessRequest={requestAccess}
              dataOrder={dataOrder}
              availableLoading={availableLoading}
              canSandMessage={true}
              attendees={attendees}
              description={event?.descr}
              isPreview={isPreview}
              isPublicView={true}
              customAttendees={customAttendees}
            />
          </WrapperListingView>
        </MainContListingView>
      </MobileView>
    </>
  );
};
