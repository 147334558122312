import React from 'react';
import {useCheckReferral, useFetchSession} from '../../hooks/auth';

type CurrentUserProps = {
  children: React.ReactNode;
  alias?: string | null;
};

const CurrentUser: React.FC<CurrentUserProps> = ({children, alias}) => {
  useFetchSession(alias);
  useCheckReferral();
  return children as React.ReactElement;
};

export default CurrentUser;
