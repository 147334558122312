import React from 'react';
import {EmptyBlock, PeopleContainer, Scroller} from './styles';
import {PersonCard} from './PersonCard/PersonCard';
import {PeopleListProps} from './types';
import {PersonCardSmall} from './PersonCard/PersonCardSmall';
import {PeopleSkeletonLoader, PeopleSkeletonSection} from '../common/Skeletons/PeopleSkeleton';

export const EmptySpace: React.FC = () => {
  return (
    <>
      <EmptyBlock /> <EmptyBlock />
    </>
  );
};

export const PeopleList: React.FC<PeopleListProps> = ({
  list,
  itemsType,
  actionsType,
  manageActions,
  communityId: comId,
  viewerId,
  reportActions,
  refetch,
  adminLabels,
  typeUser,
  loadMore,
  hasNextPage,
  //listingCount,
}) => {
  if (!list?.length) {
    if (!comId) return <PeopleSkeletonSection />;
  }
  const onLoadMore = () => {
    loadMore?.();
  };
  return (
    <PeopleContainer>
      {itemsType !== 'small' ? (
        <>
          <Scroller
            pageStart={0}
            loadMore={onLoadMore}
            loader={<PeopleSkeletonLoader />}
            useWindow={true}
            hasMore={!!hasNextPage}
            initialLoad={true}>
            {list?.map((p) => (
              <PersonCard
                {...p}
                viewerId={viewerId}
                actionsType={actionsType}
                manageActions={manageActions}
                key={p?.id}
                isOnline={p.isOnline}
                reportActions={reportActions}
                adminLabels={adminLabels}
                typeUser={typeUser}
                listingsCount={p?.listingCount}
                //listingCount={listingCount}
              />
            ))}
          </Scroller>
        </>
      ) : (
        <>
          {list?.map((p) => (
            <PersonCardSmall
              {...p}
              actionsType={actionsType}
              manageActions={manageActions}
              communityId={comId}
              viewerId={viewerId}
              key={p?.id}
              isOnline={p.isOnline}
              reportActions={reportActions}
              refetch={refetch}
              adminLabels={adminLabels}
              listingCount={p?.listingCount}
              typeUser={typeUser}
            />
          ))}
        </>
      )}
      <EmptySpace />
    </PeopleContainer>
  );
};
