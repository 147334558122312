import React, {useRef, useState} from 'react';
import {TypeSteps} from '../../../../types/resident';
import {Steps} from './Steps';
import {useSearchCommunities} from '../../../../hooks/community';
import {useViewer, useViewerId} from '../../../../hooks/user';
import {SearchCommunity} from '../../../../components/Account/SearchCommunity';
import {CommunitiesSearchParams} from './types';
import {useRecoilState} from 'recoil';
import {aliasPart} from '../../../../states/community';
import {Redirect, useHistory} from 'react-router-dom';
import {route} from '../../../../constants/routes';
import {useLinks} from '../../../../hooks/common';

export const SearchCommunityContainer: React.FC = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const viewerId = useViewerId();
  const viewer = useViewer();
  const viewerAddress = viewer?.address;
  const [searchParams, setSearchParams] = useState<CommunitiesSearchParams>();
  const previousCursor = useRef<string | null>(null);

  const redirectToPersonalSetting = () => push(getLink(route.personalSettings.path));
  const blocksToShow = 18;
  const variables = {
    where: {
      listingApproval: {exists: true},
      ...(searchParams ? searchParams : {}),
      Owner: {have: {id: {notEqualTo: viewerId}}},
      Location: {
        have: {
          Country: {
            have: {
              objectId: {
                equalTo: viewer?.Location?.Country?.objectId,
              },
            },
          },
        },
      },
    },
    userId: viewerId,
    first: blocksToShow,
  };

  const {data, refetch, loading, fetchMore, infoData} = useSearchCommunities({...variables});
  const fetch = async () => {
    const pageInfoItems = infoData?.communities?.pageInfo;
    if (!pageInfoItems || !viewerId) return;
    const {hasNextPage, endCursor} = pageInfoItems;

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.communities?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.communities.edges;
          const nextChunk = fetchMoreResult.communities.edges;

          return {
            communities: {
              ...fetchMoreResult.communities,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [alias] = useRecoilState(aliasPart);
  if (alias) {
    return <Redirect to={alias} />;
  }

  const communities = data.filter((el) => !viewer?.Blocked?.find((blocked) => blocked.objectId === el.objectId));

  return (
    <Steps step={TypeSteps.searchCommunity}>
      <SearchCommunity
        hasMorePosts={!!infoData?.communities?.pageInfo.hasNextPage}
        viewerId={viewerId}
        redirectToPersonalSetting={redirectToPersonalSetting}
        viewerAddress={viewerAddress}
        communities={communities}
        refetch={refetch}
        loading={!data?.length && loading}
        setSearchParams={setSearchParams}
        loadMore={fetch}
      />
    </Steps>
  );
};
