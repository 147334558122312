import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../../ui-kit/Button/Button';
import {InputActionsRightSide} from '../../../Loop/PostCreate/styles';
import {CancelWrapper, PrimarySubmitButton, SecondarySubmitButton} from '../styles';
import {inputPostType} from '../types';

type TProps = {
  resetType: () => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  btnDisabled?: boolean;
  isEdit?: boolean;
  inputType?: inputPostType;
};

export const PostActions: React.FC<TProps> = ({resetType, handleSubmit, btnDisabled, isEdit, inputType}) => {
  const {t} = useTranslation();
  const postBtn = isEdit
    ? t('community:loop.buttons.update')
    : inputType === inputPostType.request
    ? t('community:loop.buttons.request')
    : t('community:loop.buttons.post');
  const cancelBtn = t('community:loop.buttons.cancel');

  return (
    <InputActionsRightSide disabled={btnDisabled}>
      <CancelWrapper $isEdit={isEdit}>
        <Button onClick={resetType} variant={'secondary'} ghost={true} disabled={btnDisabled}>
          {cancelBtn}
        </Button>
      </CancelWrapper>
      <PrimarySubmitButton $isEdit={isEdit}>
        <Button rounded={true} onClick={handleSubmit} variant={'secondary'} ghost loading={btnDisabled}>
          {postBtn}
        </Button>
      </PrimarySubmitButton>
      <SecondarySubmitButton $isEdit={isEdit}>
        <Button
          rounded={true}
          onClick={handleSubmit}
          variant={'primary'}
          width={'154px'}
          height={'41px'}
          loading={btnDisabled}>
          {postBtn}
        </Button>
      </SecondarySubmitButton>
    </InputActionsRightSide>
  );
};
