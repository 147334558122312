import {Event} from './types/event';
import {CreateEventFieldsInput, EditEventFieldsInput, TQueryEvent} from '../types/event';
import {gql} from '@apollo/client';
import {EventFragment} from './fragments/event';
import {Connection} from './types/parse';
import {GQLEventManageAction} from '../graphql.schema';

export interface CreateEventResponseType {
  createEvent: {
    event: Event;
  };
}

export interface CreateEventRequestType {
  fields: CreateEventFieldsInput;
}

export const CreateEventQuery = gql`
  mutation createEvent($fields: CreateEventFieldsInput) {
    createEvent(input: {fields: $fields}) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragment}
`;

export interface UpdateEventResponseType {
  updateEvent: {
    event: Event;
  };
}

export interface UpdateEventRequestType {
  id: string;
  fields: EditEventFieldsInput;
}

export const UpdateEventQuery = gql`
  mutation updateEvent($id: ID!, $fields: UpdateEventFieldsInput) {
    updateEvent(input: {id: $id, fields: $fields}) {
      event {
        ...EventFragment
      }
    }
  }
  ${EventFragment}
`;

export type EventsResponseType = {
  events: Connection<TQueryEvent>;
};

export const GetEventsQuery = gql`
  query events($cursor: String, $first: Int, $skip: Int, $where: EventWhereInput, $order: [EventOrder!]) {
    events(after: $cursor, order: $order, first: $first, skip: $skip, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...EventFragment
        }
      }
    }
  }
  ${EventFragment}
`;

export const GetEventRequest = gql`
  query event($id: ID!) {
    event(id: $id) {
      ...EventFragment
    }
  }
  ${EventFragment}
`;

export type GetEventReqType = {
  id: string;
};

export type GetEventResType = {
  event: TQueryEvent;
};

export const eventManageQuery = gql`
  mutation eventManageButtons($eventId: String!, $communityIdList: [String]!, $action: EventManageAction!) {
    eventManageButtons(input: {eventId: $eventId, communityIdList: $communityIdList, action: $action})
  }
`;

export interface eventManageResponseType {
  eventManagerButtons: boolean;
}

export interface eventManageRequestType {
  eventId: string;
  communityIdList: string;
  type: GQLEventManageAction;
}

export const eventResidentButtonsQuery = gql`
  mutation eventManageButtons($eventId: String!, $communityIdList: [String]!, $action: EventManageAction!) {
    eventManageButtons(input: {eventId: $eventId, communityIdList: $communityIdList, action: $action})
  }
`;

export interface eventResidentButtonsResponseType {
  eventManagerButtons: boolean;
}

export interface eventResidentButtonsRequestType {
  eventId: string;
  communityIdList: string[];
  action: GQLEventManageAction;
}

export const HandleLikeEvent = gql`
  mutation likeHandlerEvent($id: String!) {
    likeHandlerEvent(input: {eventId: $id}) {
      success
    }
  }
`;

export interface GetPublicEventRequestType {
  eventId: string;
}
export interface GetPublicEventResType {
  getEventItem: {
    event: Event;
  };
}

export const getPublicEvent = gql`
  mutation getEventItem($eventId: String!) {
    getEventItem(input: {eventId: $eventId}) {
      event
    }
  }
`;
