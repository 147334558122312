import {Gallery, Item} from 'react-photoswipe-gallery';
import React, {useEffect, useMemo, useState} from 'react';
import {toStateItem} from '../../helpers/item';
import {
  getAmountLabel,
  getCreditInfo,
  getDataButtonLabel,
  getTextDescription,
  getTextsPaymentLabel,
  getTextsSecondaryLabel,
  timeDiffCalcInHours,
} from '../../helpers/order';
import {CustomerType, ReviewT, ReviewTypes, TagType} from '../../ui-kit/RequestCard/types';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {InnerRequestCard} from './styles';
import {useTranslation} from 'react-i18next';
import {Order, StatusOrderType} from '../../queries/types/order';
import {RequestCard as UiRequestCard} from '../../ui-kit/RequestCard/RequestCard';
import {editReviewDataT, TDataInvoices, TResCreateReview, TResEditOrder, TResManageOrder} from '../../hooks/order';
import {GQLOrderStatuses} from '../../graphql.schema';
import {SkeletonRequestCard} from './Sceleton/Card/SkeletonRequestCard';
import {TReview} from '../../types/reviews';
import {formatReadyReviewData} from '../../helpers/reviews';
import {OrderHelpersT} from '../../types/messages';
import {getResize} from '../../helpers/file';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {CloseButtonRound} from '../common/Modal/MessagesRequestModal';

type TProps = {
  activeOrder: Order;
  viewerId?: string;
  dataManageOrder: TResManageOrder;
  dataInvoice: TDataInvoices | null;
  invoiceLoading?: boolean;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  showStripePopup: () => void;
  stripeError?: boolean | null;
  isModal?: boolean;
  onClose?: () => void;
  handlePushToSupport: () => void;
  setIsCanceled?: (val: boolean) => void;
  isCanceled?: boolean;
  handlePushToListing?: () => void;
};

export const RequestCard: React.FC<TProps> = ({
  activeOrder: _activeOrder,
  viewerId,
  dataManageOrder,
  dataInvoice,
  invoiceLoading,
  reviewOrderData,
  editOrderData,
  reviewsData,
  editReviewData,
  reviewsDataLoading,
  orderHelpers,
  showStripePopup,
  stripeError,
  isModal,
  handlePushToSupport,
  onClose,
  setIsCanceled,
  isCanceled,
  handlePushToListing,
}) => {
  const {t} = useTranslation();
  const activeOrder = {..._activeOrder, ...(isCanceled ? {status: StatusOrderType.rejected} : {})};
  const {manageOrder, loading: manageOrderLoading} = dataManageOrder;
  const item = useMemo(() => toStateItem(activeOrder.Item), [activeOrder]);
  const typeUser = useMemo(
    () => (viewerId === item.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester),
    [viewerId, item],
  );
  const tagRewardType = getAmountLabel(item);
  const isRequester = typeUser === CustomerType.Requester;
  const params = useMemo(() => {
    return {
      rewardType: item.rewardType as RewardType,
      listingType: item.listingType as ListingType,
      typeUser,
      orderStatus: activeOrder.status,
      date: activeOrder?.startTime,
    };
  }, [activeOrder, item, typeUser]);
  const descr = getTextDescription(params);
  const paymentLabel = getTextsPaymentLabel(params);
  const secondaryLabel = getTextsSecondaryLabel(params);
  const {label: primaryBtnLabel, status: newStatus, isDisabled} = getDataButtonLabel(params);
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const [reviewType, setReviewType] = useState<ReviewTypes>(
    reviewsData?.length && !editReviewData.isEditing ? ReviewTypes.ReviewReady : ReviewTypes.Rating,
  );
  const [showCredit, setShowCredit] = useState(false);
  const toggleCredit = (f: boolean) => setShowCredit(f);
  const [reviewButton, setReviewButton] = useState('requests:buttons.rating');
  useEffect(() => {
    if (editReviewData.isEditing && reviewType === ReviewTypes.ReviewReady) {
      setReviewType(ReviewTypes.Rating);
      return;
    }
    if (reviewsData?.length) {
      if (!editReviewData.isEditing) setReviewType(ReviewTypes.ReviewReady);
    }
  }, [reviewsData]);
  const handleManageReview = () => {
    if (activeOrder.status !== StatusOrderType.completed) return;
    if (reviewType === ReviewTypes.Rating) {
      setReviewType(ReviewTypes.Review);
      setReviewButton('requests:buttons.review');
      return;
    }
    if (reviewType === ReviewTypes.Review) {
      setReviewType(ReviewTypes.ReviewReady);
      if (editReviewData.isEditing) {
        reviewOrderData.onUpdate();
        editReviewData.setEditReview(false);
      } else {
        reviewOrderData.onSubmit();
      }
      return;
    }
    if (reviewType === ReviewTypes.ReviewReady) {
      return;
    }
  };
  const handleReportOrder = () => handlePushToSupport();
  const handleManageOrder = () => {
    if ((stripeError === true || stripeError === null) && activeOrder?.Lister?.objectId === viewerId) {
      showStripePopup();
      return;
    }
    return manageOrder({orderId: activeOrder.objectId, status: newStatus});
  };

  const handleCancelOrder = async () => {
    await manageOrder({
      orderId: activeOrder.objectId,
      status: GQLOrderStatuses.rejected,
    });
    setIsCanceled?.(true);
    handleCloseDelete();
  };
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('events:modals.cancel.title'),
        okText: t('events:modals.cancel.okText'),
        cancelText: t('events:modals.cancel.cancelText'),
      },
    };
  }, []);
  const pickerLabels = useMemo(() => {
    return {
      labelTime: t('requests:labelTime'),
      labelPickUp: t('requests:pickUp'),
      labelDate: t('requests:labelDate'),
      labelDuration: t('requests:labelDuration'),
      labelHours: t('requests:labelHours'),
    };
  }, []);
  const pickerPlaceholders = useMemo(() => {
    return {
      addTime: t('requests:addTime'),
      addDate: t('requests:addDate'),
      addHours: t('requests:addHours'),
    };
  }, []);
  const listerInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Lister?.firstName || '',
      lastName: activeOrder?.Lister?.lastName || `${activeOrder?.Lister?.firstLetter}.`,
      avatar: getResize(activeOrder?.Lister?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const requesterInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Requester?.firstName || '',
      lastName: activeOrder?.Requester?.lastName || `${activeOrder?.Requester?.firstLetter}.`,
      avatar: getResize(activeOrder?.Requester?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const itemInfo = useMemo(() => {
    return {
      avatar: getResize(activeOrder?.Item?.images?.[0]?.file?.url, 'sm'),
      name: activeOrder?.Item.name,
    };
  }, [activeOrder]);
  const creditInfo = getCreditInfo({activeOrder, isRequester, t});
  const setEdit = () => editReviewData.setEditReview(true);
  const readyReviewsData: ReviewT[] | undefined = useMemo(
    () => reviewsData?.map((rev) => formatReadyReviewData(rev)),
    [reviewsData],
  );
  if (invoiceLoading) {
    return <SkeletonRequestCard />;
  }
  return (
    <InnerRequestCard>
      {isModal && <CloseButtonRound onClose={onClose} />}
      <UiRequestCard
        isDeleted={item?.isDeleted}
        Gallery={Gallery}
        Item={Item}
        paymentLabel={{
          title: t(paymentLabel.title, {value: dataInvoice?.total?.value}),
          descr: t(paymentLabel.descr),
        }}
        buttonLabel={{
          primary: {
            label: primaryBtnLabel ? t(primaryBtnLabel) : '',
            onClick: handleManageOrder,
            loading: manageOrderLoading,
          },
          secondary: {
            label: secondaryLabel ? t(secondaryLabel) : '',
            onClick:
              activeOrder?.status === StatusOrderType.performing && typeUser === 'requester'
                ? handleReportOrder
                : handleOpenDelete,
          },
          primaryReview: {label: reviewButton ? t(reviewButton) : '', onClick: handleManageReview},
          secondaryReview: {
            label:
              reviewType === ReviewTypes.ReviewReady ? t('requests:buttons.editRating') : t('requests:buttons.skip'),
            onClick: reviewType === ReviewTypes.ReviewReady ? setEdit : handleManageReview,
          },
          hideBase: activeOrder.status === StatusOrderType.completed,
          disabled: orderHelpers.orderLoading || isDisabled,
        }}
        dateLabel={{
          from: {title: t('requests:pickUp')},
          to: {title: t('requests:dropOff')},
          canEdit: false,
          dates: [new Date(activeOrder.startTime), new Date(activeOrder.endTime)],
          setDates: editOrderData?.onChange,
          placeholder: t('requests:addDate'),
          time: activeOrder.pricePeriod === PricePeriodType.hour ? new Date(activeOrder.startTime) : null,
          duration:
            activeOrder.pricePeriod === PricePeriodType.hour
              ? timeDiffCalcInHours(new Date(activeOrder.endTime), new Date(activeOrder.startTime))
              : null,
        }}
        editOrderData={editOrderData}
        handlePushToListing={handlePushToListing}
        listingType={activeOrder.listingType}
        pickerLabels={pickerLabels}
        pickerPlaceholders={pickerPlaceholders}
        pricePeriod={activeOrder.pricePeriod}
        smallPicker={true}
        payments={[{...dataInvoice}]}
        cancelled={{title: t('requests:buttons.cancelRequest')}}
        users={[listerInfo, requesterInfo]}
        reviewType={reviewType}
        descr={t(descr)}
        userType={viewerId === item?.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester}
        cardType={activeOrder.status === StatusOrderType.completed ? 'review' : 'regular'}
        maxPeriod={item?.maxPeriod}
        image={getResize(item?.images?.[0]?.file?.url, 'md') || ''}
        rewardType={item?.rewardType}
        title={item?.name}
        opponentInfo={isRequester ? listerInfo : requesterInfo}
        itemInfo={itemInfo}
        tags={[
          {label: t(`listings:listingsType.${item?.listingType}`), type: TagType.listingType},
          {
            label: t(tagRewardType.label, {value: tagRewardType?.opts}),
            type: tagRewardType.type,
          },
        ]}
        rating={{
          title: t('requests:card.rating.title'),
          descr: t('requests:card.rating.descr'),
          input: {
            user: {
              value: reviewOrderData.data.userRating,
              onChange: reviewOrderData.change.changeUserRating,
            },
            item: {
              value: reviewOrderData.data.itemRating,
              onChange: reviewOrderData.change.changeItemRating,
            },
          },
        }}
        review={{
          title: t('requests:card.review.title'),
          descr: t('requests:card.review.descr'),
          completedDescr: t('requests:card.completed'),
          noReview: t('requests:card.review.noReview'),
          input: {
            user: {
              placeholder: '',
              value: reviewOrderData.data.userReview,
              onChange: reviewOrderData.change.changeUserReview,
            },
            item: {
              placeholder: '',
              value: reviewOrderData.data.itemReview,
              onChange: reviewOrderData.change.changeItemReview,
            },
          },
        }}
        readyReviewsData={readyReviewsData}
        isRequester={isRequester}
        reviewLoading={reviewsDataLoading && !readyReviewsData}
        durationUnit={{
          single: t('requests:durationUnit.single'),
          many: t('requests:durationUnit.many'),
        }}
        captions={{
          date: t('requests:captions.date'),
          dateDuration: t('requests:captions.dateDuration'),
          time: t('requests:captions.time'),
          duration: t('requests:captions.duration'),
        }}
        buttonSelectText={t('requests:buttonSelectText')}
        showCredit={showCredit}
        toggleCredit={toggleCredit}
        creditInfo={creditInfo}
        textMode={true}
        dateInfoLabels={{
          duration: t('requests:dateLabels.duration'),
          from: t('requests:dateLabels.from'),
          until: t('requests:dateLabels.until'),
          hours: t('requests:dateLabels.hours'),
        }}
      />
      {!isMobile && isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={handleCancelOrder}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
      {isMobile && isShowModalDelete && (
        <BasePortal>
          <IsModalProvider>
            <Confirmation
              title={modalsText.delete.title}
              onClose={handleCloseDelete}
              okText={modalsText.delete.okText}
              onOk={handleCancelOrder}
              buttonType={'danger'}
              cancelText={modalsText.delete.cancelText}
            />
          </IsModalProvider>
        </BasePortal>
      )}
    </InnerRequestCard>
  );
};
