import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {route} from '../../../constants/routes';
import {getResize} from '../../../helpers/file';
import {useLinks} from '../../../hooks/common';
import {MemberT} from '../../../types/people';
import {RecommendationDataT, RecType} from '../../../types/post';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {StyledSvgIconProps} from '../../../ui-kit/Icon/Svg/types';
import {postActions} from '../types';
import {
  RecommendationWrapper,
  RecLike,
  RecAuthor,
  RecHeadLine,
  RecAuthorName,
  RecItems,
  RecItem,
  LikeBtn,
} from './styles';

type RecommendationPostPropsT = {
  Author?: MemberT & {recommendations?: RecommendationDataT};
  likes?: number;
  isLiked?: boolean;
  id?: string;
  postActions?: postActions;
  refetch?: () => void;
};

const iconsMap: Record<RecType, StyledSvgIconProps['type']> = {
  eatery: 'coffee',
  movies: 'tv',
  places: 'car-alt',
};

export const RecommendationPost: React.FC<RecommendationPostPropsT> = ({
  Author,
  isLiked,
  likes,
  postActions,
  id,
  refetch,
}) => {
  const {t} = useTranslation();
  const [countLikes, setCountLikes] = useState(0);
  const [liked, setLiked] = useState(isLiked || false);
  const recommendations = Author?.recommendations;
  const recs = Object.keys(recommendations || {});
  const isEmpty = !recs?.some((el) => recommendations?.[el as keyof RecommendationDataT]?.length);
  const onLike = async () => {
    setLiked((prev) => !prev);
    setCountLikes((prev) => (liked ? prev - 1 : prev + 1));
    await postActions?.likePost?.(id);
    refetch?.();
  };
  const {getLink} = useLinks();
  const profileLink = Author?.objectId ? getLink(route.profile.get({id: Author?.objectId})) : '#';
  useEffect(() => {
    setLiked(!!isLiked);
  }, [isLiked]);
  useEffect(() => {
    likes && setCountLikes(likes);
  }, [likes]);

  if (!recs?.length || !recommendations || isEmpty) return null;
  return (
    <RecommendationWrapper>
      <RecHeadLine>
        <RecAuthor to={profileLink}>
          <Avatar size={'sm'} src={getResize(Author?.Avatar?.file?.url, 'sm')} />
          <RecAuthorName>
            {Author?.firstName} {Author?.lastName || Author?.firstLetter + '.'}
          </RecAuthorName>
        </RecAuthor>
        <RecLike>
          <span>{t('common:like', {count: countLikes || 0})}</span>
          <LikeBtn onClick={onLike}>
            <IconSvg
              type="like"
              width="26px"
              height="26px"
              stroke={liked ? 'aqua' : 'gray'}
              fill={liked ? 'aqua' : 'none'}
            />
          </LikeBtn>
        </RecLike>
      </RecHeadLine>
      <RecItems>
        {recs?.map((el) => (
          <>
            {recommendations?.[el as keyof RecommendationDataT] ? (
              <RecItem key={el}>
                <IconSvg type={iconsMap?.[el as RecType]} />
                <span>{recommendations?.[el as keyof RecommendationDataT]}</span>
              </RecItem>
            ) : null}
          </>
        ))}
      </RecItems>
    </RecommendationWrapper>
  );
};
