import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Media} from '../../../ui-kit/theme';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {HiddenElement} from '../Hidden/HiddenElement';
import {PostActions} from './components/PostActions';
import {CloseButtonMobile} from './components/CloseButtonMobile';
import {PollOptionsEditor} from './components/PollOptionsEditor';
import {MobileMediaPanel} from './MobileMediaPanel';
import {InputsProps, pollIdType, PollOptions} from './types';
import {PostTypeT} from '../../../types/post';
import defaultAvatar from './assets/defaultAvatar.png';
import {
  Avatar,
  EditingContainer,
  ErrorLine,
  InputActionsLeftSide,
  InputActionsWrapper,
  InputContainer,
  PollInputMessageWrapper,
  InputTopWrapper,
  PollOptionsActions,
  TextBtn,
  // TextMessageInput,
  UsersMenuWrapper,
} from '../../Loop/PostCreate/styles';
import {ChangePostTypeIcon, InputsAvatarWrapper} from './styles';
import {EmojiTab} from '../../common/EmojiTab/EmojiTab';
import {RichTextEditor} from '../../common/RichText/Editor';
import {handleEditorChangeForUserEvent, handleSetUser, handleSetEvent} from '../../../helpers/common';
import {UserMenu} from '../../Account/Popups/HeaderMenu/UserMenu';
import {EventMenu} from '../../Account/Popups/HeaderMenu/EventMenu';
import {RTEdefault} from '../../../constants/common';

const maxOptionsCount = 8;

export const PollInput: React.FC<InputsProps> = ({
  user,
  resetType,
  handleChange,
  handleSubmit,
  error,
  handleChangePostType,
  inputType,
  values,
  menuOptions,
  isEdit,
  initialPollOptions,
  ManagerOrAdmin,
  isGroup,
}) => {
  const {t} = useTranslation();
  const placeholder = t('community:loop.inputs.placeholderPollInput');
  const addOptionBtnText = t('community:loop.buttons.addOption');
  const addAnotherOptionBtnText = t('community:loop.buttons.addAnotherOption');
  const allowMultiText = t('community:loop.buttons.allowMulti');
  const allowSingleText = t('community:loop.buttons.allowSingle');
  const [pollOptions, setPollOptions] = useState<PollOptions>(initialPollOptions || {});
  const pollCount = Object.keys(pollOptions).length;
  const pollLastKey = pollCount ? Number(Object.keys(pollOptions)[pollCount - 1]) + 1 : 0;
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const removeDisable = () => setBtnDisabled(false);
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});

  // const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   handleChange?.({name: 'text', value: e.target.value});
  //   removeDisable();
  // };
  const handleChane = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    handleChange?.({name: next.name, value: next.value});
    removeDisable();
  };
  const handleChangeMulti = () => {
    handleChange?.({name: 'isMulti', value: !isMulti});
    setIsMulti((p) => !p);
  };
  const handleAddOption = () => {
    if (pollCount < maxOptionsCount) setPollOptions((prev) => ({...prev, [pollLastKey]: {text: ''}}));
    removeDisable();
  };
  const handleEditTextOption = (id: pollIdType, text?: string) => {
    setPollOptions((prev) => ({...prev, [id]: {text: text}}));
    removeDisable();
  };

  const handleRemoveOption = (id: pollIdType) => {
    const copy = Object.assign({}, pollOptions);
    const deleted = delete copy[id];
    deleted && setPollOptions(copy);
    removeDisable();
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    !btnDisabled && handleSubmit?.();
    setBtnDisabled(true);
  };
  useEffect(() => {
    const options = Object.keys(pollOptions).map((key) => pollOptions[key]);
    handleChange?.({name: 'options', value: options});
    removeDisable();
  }, [pollOptions]);

  useEffect(() => {
    if (isEdit) return;
    handleChange?.({name: 'type', value: PostTypeT.poll});
    handleChange?.({name: 'text', value: ''});
    handleChange?.({name: 'sentAs', value: user?.postAs});
    removeDisable();
  }, []);

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };
  return (
    <InputContainer $isEdit={isEdit}>
      <InputTopWrapper>
        <InputsAvatarWrapper>
          <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
          {ManagerOrAdmin && (
            <ChangePostTypeIcon tabIndex={-1}>
              <UsersMenuWrapper>
                <MenuButton
                  item={{objectId: user?.postAs}}
                  menu={Menu}
                  options={menuOptions}
                  right={true}
                  prevent={true}
                  alwaysShowBtn={true}
                  customIcon={<IconSvg type={'chevrone-down'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />}
                />
              </UsersMenuWrapper>
            </ChangePostTypeIcon>
          )}
        </InputsAvatarWrapper>
        <EditingContainer>
          <PollInputMessageWrapper>
            <RichTextEditor
              placeholder={placeholder}
              name={'text'}
              value={values?.text}
              onChange={(e, position) => handleChane(e, position, values?.text ? values?.text : RTEdefault)}
              rows={8}
              emoji={emoji}
              setEmoji={setEmoji}
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              eventName={eventName}
              setEventName={setEventName}
            />
            <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
            {userMenuOpen && (
              <UserMenu
                top={positionSubMenu.top}
                left={positionSubMenu.left}
                handleSetUser={handleSetUser}
                setUserProfile={setUserProfile}
                searchText={searchText}></UserMenu>
            )}
            {eventMenuOpen && (
              <EventMenu
                top={positionSubMenu.top}
                left={positionSubMenu.left}
                handleSetEvent={handleSetEvent}
                setEventName={setEventName}
                searchText={searchText}></EventMenu>
            )}
            <IconSvg type={'poll'} className={'post-input_icon'} />
          </PollInputMessageWrapper>
          <PollOptionsEditor
            options={pollOptions}
            editText={handleEditTextOption}
            remove={handleRemoveOption}
            isEdit={isEdit}
            isMulti={isMulti}
          />
          {!isEdit && (
            <PollOptionsActions>
              <TextBtn onClick={handleAddOption} disabled={pollCount === maxOptionsCount}>
                {pollCount < maxOptionsCount ? (pollCount ? addAnotherOptionBtnText : addOptionBtnText) : ''}
              </TextBtn>
              <TextBtn onClick={handleChangeMulti}>{isMulti ? allowSingleText : allowMultiText}</TextBtn>
            </PollOptionsActions>
          )}
          {error?.message && <ErrorLine>{t(error?.message)}</ErrorLine>}
        </EditingContainer>
        <CloseButtonMobile resetType={resetType} postType={inputType} />
      </InputTopWrapper>
      <InputActionsWrapper>
        <MobileMediaPanel
          handleChangePostType={handleChangePostType}
          activeType={inputType}
          values={values}
          user={user}
          isGroup={isGroup}
        />
        <HiddenElement mq={Media.down.l}>
          <InputActionsLeftSide />
        </HiddenElement>
        <PostActions resetType={resetType} handleSubmit={onSubmit} btnDisabled={btnDisabled} isEdit={isEdit} />
      </InputActionsWrapper>
    </InputContainer>
  );
};
