import React from 'react';
import {SkeletonList, SkeletonItem} from './styles';
import Skeleton from 'react-loading-skeleton';

export const HelpDeskSkeleton = () => {
  return (
    <SkeletonList>
      <SkeletonItem>
        <Skeleton width={'50%'} />
        <Skeleton width={'30%'} />
      </SkeletonItem>
      <SkeletonItem>
        <Skeleton width={'50%'} />
        <Skeleton width={'30%'} />
      </SkeletonItem>
      <SkeletonItem>
        <Skeleton width={'50%'} />
        <Skeleton width={'30%'} />
      </SkeletonItem>
    </SkeletonList>
  );
};
