import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 14px;
  margin-left: 6px;
  ${Media.down.m} {
    margin-right: 0;
  }
`;

export const SecondarySelectWrapper = styled.div`
  position: relative;
  display: flex;
  ${Media.down.m} {
    margin-right: 0;
  }
`;

export const Select = styled.div`
  border: none;
  padding: 6px 8px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._3};
  color: ${({theme}) => theme.palette.grayscale._6};
  cursor: pointer;
  position: relative;
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
`;

export const SecondarySelect = styled.div`
  border: none;
  border-radius: 12px;
  background-color: transparent;
  padding-bottom: 6px;
  color: ${({theme}) => theme.palette.grayscale._6};
  cursor: pointer;
  position: relative;
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
`;

export const SelectBodySelected = styled.div`
  background-color: ${({theme}) => theme.palette.grayscale._3};
  font-size: 15px;
`;

export const SelectBody = styled.div<{$visibility?: boolean}>`
  background-color: ${({theme}) => theme.palette.grayscale._3};
  top: 98%;
  position: absolute;
  left: 0;
  width: 100%;
  visibility: ${({$visibility = true}) => ($visibility ? 'visible' : 'hidden')};
`;

export const SelectOption = styled.div<{$selected: boolean}>`
  background-color: ${({theme}) => theme.palette.grayscale._3};
  font-size: 13px;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  &:hover {
    ${({$selected}) => !$selected && ` filter: brightness(0.9);`};
  }
  ${({$selected}) => $selected && ` opacity: 0.5;`};
`;

export const SecondarySelectOption = styled.div<{$selected: boolean}>`
  font-size: 13px;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  &:hover {
    ${({$selected}) => !$selected && ` filter: brightness(0.9);`};
  }
  ${({$selected}) => $selected && ` opacity: 0.5;`};
`;

export const Arrow = styled(IconSvg).attrs({type: 'chevrone-left'})<{$isOpen?: boolean}>`
  transform: ${({$isOpen}) => ($isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
  position: absolute;
  right: 0;

  path {
    stroke-width: 1px;
  }
`;

export const Label = styled(Text).attrs({size: 16})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;
