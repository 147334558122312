import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';

import {Notifications} from '../../../components/Account/Notifications/Notifications';
import {communityCheckItem, AccountCheckListType} from '../../..//queries/types/checklist';
import {toStateCommunity} from '../../../helpers/community';
import {User} from '../../../queries/types/user';
import {currentCommunity} from '../../../states/community';
import {userState as userState} from '../../../states/session';
import {typeUser as StateTypeUser} from '../../../states/typeUser';
import {TCommunity} from '../../../types/community';
import {NotificationStatus} from '../../../types/notifications';

import {useCommunityUpdate} from '../../../hooks/community';
import {useNotifications, useUpdateNotification, useArchiveAllNotification} from '../../../hooks/notifications';
import {useRefetchUser, useUpdateUser, useViewerId} from '../../../hooks/user';

interface TProps {
  visible?: boolean;
  setVisible: (t: boolean) => void;
  newNotificationsRefetch?: () => void;
  setCleared: (b: boolean) => void;
  cleared: boolean;
  community?: Partial<TCommunity>;
}

export const NotificationsContainer: React.FC<TProps> = ({
  visible,
  setVisible,
  newNotificationsRefetch,
  cleared,
  setCleared,
  community,
}) => {
  const userId = useViewerId();
  const [currentComm, updateCurrentComm] = useRecoilState(currentCommunity);
  const [clearLoading, setClearLoading] = useState(false);
  const notificationsInfo = useNotifications(userId, currentComm?.objectId);
  const updateNotification = useUpdateNotification();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState(false);
  const [typeUser] = useRecoilState(StateTypeUser);
  const [user, updateUser] = useRecoilState(userState);
  const update = useUpdateUser();
  const {update: updateCommunity} = useCommunityUpdate();
  const {refetch: refetchUser} = useRefetchUser();
  const updateUserQuery = useUpdateUser();

  const onArchive = () => {
    notificationsInfo?.refetch();
    setClearLoading(false);
    setCleared(true);
  };
  const {archiveAll} = useArchiveAllNotification(() => setClearLoading(true), currentComm?.objectId, onArchive);

  useEffect(() => {
    if (!notificationsInfo.loading && visible) {
      notificationsInfo.refetch();
    }
    refetchUser();
  }, [visible]);

  useEffect(() => {
    if (user?.checkListSeen !== false || !userId) return;
    updateUser((prev) => ({...((prev || {}) as User), checkListSeen: true}));
    updateUserQuery({id: userId, fields: {checkListSeen: true}});
  }, [user?.checkListSeen, userId]);

  const setNotificationsSeen = async () => {
    if (notificationsInfo.data) {
      await Promise.all(
        notificationsInfo.data.map(async (n) => {
          if (n.status === NotificationStatus.New) {
            await updateNotification(n.id, {status: NotificationStatus.Seen});
          }
        }),
      );
      await notificationsInfo?.refetch?.();
      await newNotificationsRefetch?.();
    }
  };

  const setNotificationSeen = async (status: string, id: string) => {
    if (status === NotificationStatus.New) {
      await updateNotification(id, {status: NotificationStatus.Seen});
      await newNotificationsRefetch?.();
    }
  };
  const archiveNotification = async (id: string) => {
    await updateNotification(id, {status: NotificationStatus.Archived});
  };

  const hideCompletedCheck = (nameField: keyof AccountCheckListType) => async () => {
    if (nameField in communityCheckItem) {
      if (!currentComm?.objectId || !currentComm.checkList) return;

      const {data} = await updateCommunity({
        id: currentComm?.objectId,
        fields: {checkList: {...currentComm.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });

      data?.updateCommunity.community && updateCurrentComm(toStateCommunity(data.updateCommunity.community));
    } else {
      if (!userId || !user?.checkList) return;

      const {data} = await update({
        id: userId,
        fields: {checkList: {...user.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });
      data?.updateUser.user && updateUser(data?.updateUser.user);
    }
  };

  const fetchMore = async (skip?: number) => {
    if (!skip || fetching) return;
    setFetching(true);
    await notificationsInfo.fetchMore?.({
      variables: {
        skip,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (fetchMoreResult?.notifications?.edges?.length === 0 || !fetchMoreResult) {
          setHasMore(false);
          setFetching(false);
          return prev;
        }

        const prevEdges = prev?.notifications?.edges ?? [];
        const nextEdges = fetchMoreResult?.notifications?.edges ?? [];
        setFetching(false);
        return {
          notifications: {
            ...fetchMoreResult?.notifications,
            edges: [...prevEdges, ...nextEdges],
          },
        };
      },
    });
  };

  useEffect(() => {
    if (notificationsInfo.data?.length && notificationsInfo.data.length < 15 && hasMore) {
      setHasMore(false);
    }
  }, [notificationsInfo.data]);

  return (
    <Notifications
      notificationsInfo={notificationsInfo}
      visible={visible}
      setVisible={setVisible}
      setNotificationsSeen={setNotificationsSeen}
      archiveNotification={archiveNotification}
      fetchMore={fetchMore}
      hasMore={hasMore}
      setNotificationSeen={setNotificationSeen}
      typeUser={typeUser}
      hideCompletedCheck={hideCompletedCheck}
      archiveAll={archiveAll}
      clearLoading={clearLoading}
      checkList={{...(user?.checkList || {}), ...(currentComm?.checkList || {})} as AccountCheckListType}
      cleared={cleared}
      community={community}
    />
  );
};
