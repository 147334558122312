import styled, {css} from 'styled-components';
import {Media, theme} from '../../theme';
import {Text} from '../../Typography/Text';
import {OpType} from './DateTimePicker';

const scrollBarStyles = css`
  scrollbar-color: ${({theme}) => theme.palette.background.tertiary} ${({theme}) => theme.palette.background.denary};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.background.tertiary};
    border-radius: 9em;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.background.octonary};
  }
`;

const dateRangeArrowBtnStyles = css`
  background-color: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0;
  border: none;
`;
const dateRangeArrowStyles = css`
  width: 1px;
  height: 1px;
  border: solid ${theme.palette.text.quindecimal};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
`;

export const PickerContainerOne = styled.div`
  padding: 0 30px 30px 30px;
  overflow: hidden;
  display: flex;
  width: 320px;
  & > div {
    width: 100%;
    display: flex;
    position: relative;
  }
  ${Media.down.l} {
    width: 390px;
  }
  ${Media.down.s} {
    width: 335px;
  }

  & .react-datepicker {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: none;
    position: relative;
  }
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: ${({theme}) => theme.palette.grayscale._12};
    &.react-datepicker__day--disabled {
      color: ${({theme}) => theme.palette.grayscale._4};
    }
  }
  & .react-datepicker__week {
    justify-content: space-between;
  }
  & .react-datepicker__header {
    background: transparent;
    border: none;
  }
  & .react-datepicker__month {
    margin: 0;
  }
  & .react-datepicker__triangle {
    position: absolute;
  }

  & button.react-datepicker__navigation {
    position: absolute;
    top: 8px;

    &.react-datepicker__navigation--next {
      right: 15px;
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '>';
        ${dateRangeArrowStyles};
        transform: rotate(-45deg);
      }
      & span {
        display: none;
      }
    }
    &.react-datepicker__navigation--previous {
      left: 15px;
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '<';
        ${dateRangeArrowStyles};
        transform: rotate(135deg);
      }
      & span {
        display: none;
      }
    }
  }
  & .react-datepicker__month-container {
    width: 100%;
    box-sizing: border-box;
  }

  & .react-datepicker__current-month {
    text-align: center;
    color: ${({theme}) => theme.palette.grayscale._12};
    ${theme.font.primary.semiBold};
    ${Media.down.m} {
      font-size: 12px;
    }
  }

  & .react-datepicker__day-names {
    display: flex;
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: ${({theme}) => theme.palette.grayscale._5};
    ${theme.font.primary.semiBold};
    ${Media.down.m} {
      font-size: 10px;
    }
    & .react-datepicker__day-name {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .react-datepicker__week {
    display: flex;
    margin: 2px 0;
  }

  & .react-datepicker__day {
    width: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.166rem;
    margin: 0;
    ${theme.font.primary.medium}
    ${Media.down.m} {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }

  & .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: ${({theme}) => theme.palette.grayscale._12};
    &.react-datepicker__day--disabled {
      color: ${({theme}) => theme.palette.grayscale._4};
    }
  }

  & .react-datepicker__day.react-datepicker__day--in-range,
  & .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: ${theme.palette.text.quaternary};
    position: relative;
    box-sizing: border-box;
  }

  & .react-datepicker__day.react-datepicker__day--range-start {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--range-end {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--selected,
  & .react-datepicker__day.react-datepicker__day--range-end {
    background-color: ${({theme}) => theme.palette.grayscale._12};
    border-radius: 50%;
    color: ${({theme}) => theme.palette.grayscale._0};
  }

  & .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
  }
  & .react-datepicker__day {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    box-shadow: none !important;
    outline: none;
  }
`;

export const PickerContainerWithTime = styled.div<{$onlyTime?: boolean; $small?: boolean; $hideDisabled?: boolean}>`
  padding: 0 0px 20px 0px;
  overflow: hidden;
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  ${({$onlyTime}) => $onlyTime && ' width: 90px;'};
  ${({$small}) => $small && ' width: 190px !important; padding: 4px 6px;'};

  & > div {
    width: 100%;
    display: flex;
  }
  & .react-datepicker__day {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    box-shadow: none !important;
  }

  & .react-datepicker {
    width: 100%;
    justify-content: space-between;
    border: none;
    ${({$small}) => $small && ' flex-direction: column !important;'};
  }
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: ${({theme}) => theme.palette.grayscale._12};
    &.react-datepicker__day--disabled {
      color: ${({theme}) => theme.palette.grayscale._4};
    }
  }
  & ul.react-datepicker__time-list {
    display: flex;
    height: 100%;
    max-height: 100px;
    ${({$hideDisabled}) => $hideDisabled && ' grid-template-columns: repeat(3,auto) !important;'};
    ${scrollBarStyles};
  }
  & .react-datepicker__time-container {
    width: 100%;
    position: initial;
    border: 0;
    ${({$small}) => $small && ' width: 100%; border: none;'};

    .react-datepicker__time-box {
      ${({$small}) => $small && ' width: 100%; border: none;'};
    }
    .react-datepicker__time-list {
      ${({$small}) => $small && ' display: flex; flex-wrap: wrap;'};
    }
    .react-datepicker__time-list-item--disabled {
      ${({$hideDisabled}) => $hideDisabled && ' display: none;'};
    }
    .react-datepicker__time-list-item {
      ${({$onlyTime}) => $onlyTime && `margin: 0 auto;`};
      ${({$small}) => $small && ' padding: 4px !important;'};
    }
    .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
      background-color: ${({theme}) => theme.palette.grayscale._12} !important;
    }
  }
  & .react-datepicker__week {
    justify-content: space-between;
  }
  & .react-datepicker__header {
    background: transparent;
    border: none;
  }
  & .react-datepicker__month {
    margin: 0;
  }
  & .react-datepicker__triangle {
    position: absolute;
  }
  & button.react-datepicker__navigation {
    position: absolute;
    top: 8px;

    &.react-datepicker__navigation--next {
      right: 75px;
      ${({$small}) => $small && ' right: 15px;'};
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '>';
        ${dateRangeArrowStyles};
        transform: rotate(-45deg);
      }
      & span {
        display: none;
      }
    }
    &.react-datepicker__navigation--previous {
      left: 75px;
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '<';
        ${dateRangeArrowStyles};
        transform: rotate(135deg);
      }
      & span {
        display: none;
      }
    }
  }
  & .react-datepicker__month-container {
    width: 100%;
    box-sizing: border-box;
  }

  & .react-datepicker__current-month {
    text-align: center;
    color: ${({theme}) => theme.palette.grayscale._12};
    ${theme.font.primary.semiBold};
  }

  & .react-datepicker__day-names {
    display: flex;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 2px;
    color: ${({theme}) => theme.palette.grayscale._5};
    ${theme.font.primary.semiBold};
    justify-content: space-between;
    & .react-datepicker__day-name {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({$small}) => $small && ' width: 18px !important; height: 18px !important; font-size: 10px;'};
    }
  }

  & .react-datepicker__week {
    display: flex;
    margin: 2px 0;
  }

  & .react-datepicker__day {
    width: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.166rem;
    margin: 0;
    ${theme.font.primary.medium}
    ${({$small}) => $small && ' width: 22px !important; height: 22px !important; font-size: 10px;'};
  }

  & .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: ${({theme}) => theme.palette.grayscale._12};
    &.react-datepicker__day--disabled {
      color: ${({theme}) => theme.palette.grayscale._4};
    }
  }

  & .react-datepicker__day.react-datepicker__day--in-range,
  & .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: ${theme.palette.text.quaternary};
    position: relative;
    box-sizing: border-box;
  }

  & .react-datepicker__day.react-datepicker__day--range-start {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--range-end {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--selected,
  & .react-datepicker__day.react-datepicker__day--range-end {
    background-color: ${({theme}) => theme.palette.grayscale._12};
    border-radius: 50%;
    color: ${({theme}) => theme.palette.grayscale._0};
  }

  & .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
  }
  & .react-datepicker__time-container.react-datepicker__time.react-datepicker__time-box {
    width: initial !important;
    margin: 0 !important;
  }
  & .react-datepicker__time-list {
    display: grid !important;
    grid-template-columns: repeat(4, auto) !important;
    grid-row-gap: 10px;
  }
  & .react-datepicker__time-list-item {
    height: 24px !important;
    width: 77px !important;
    box-sizing: border-box;
    border-radius: 24px;
  }
  & .react-datepicker__time-list-item--selected {
    border-radius: 24px;
  }
  & .react-datepicker-time__header {
    text-align: left;
    font-size: 16px;
    ${({theme}) => theme.font.primary.bold}
  }
  & .react-datepicker__header--time {
    margin-bottom: 15px;
  }
  & .react-datepicker__time-box {
    width: 100% !important;
  }
  & .react-datepicker__day-name {
    color: ${({theme}) => theme.palette.text.septenary};
  }
  & .react-datepicker__header {
    padding-bottom: 0;
  }
  & .react-datepicker__day {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    box-shadow: none !important;
    outline: none;
  }
  .react-datepicker__header.react-datepicker__header--time .react-datepicker-time__header {
    ${Media.down.m} {
      font-size: 14px;
    }
  }
  .date-picker_onlyTime .react-datepicker__time-list {
    max-height: 325px;
  }
  .react-datepicker__time-list .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &.react-datepicker__time-list-item--disabled {
      display: none;
    }
    &.excluded {
      display: flex;
      opacity: 0.5;
    }
  }
`;

export const HourSelectWrapper = styled.div`
  & > div {
    position: absolute;
    z-index: 1008;
    padding: 0 30px;
    .react-dropdown-select {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 20px;
      font-size: 12px;
    }
  }
`;

export const DurationsWrapper = styled.div`
  margin: 10px 10px 0 10px;
`;

export const DurationsBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, auto);
  grid-row-gap: 10px;
  list-style: none;
  margin-bottom: 30px;
  max-height: 110px;
  justify-content: center;
  padding: 0;
`;

export const Duration = styled.li<{$isSelected?: boolean}>`
  color: ${({theme}) => theme.palette.text.quaternary} !important;
  span {
    color: ${({theme}) => theme.palette.text.quaternary} !important;
  }
  ${({$isSelected, theme}) =>
    $isSelected &&
    `
    background-color: ${theme.palette.grayscale._10} !important;
    border-radius: 24px;
    span {
      color: ${theme.palette.text.primary} !important;
    }
    `}
  width: 70px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 24px;
  }
`;

export const ButtonArea = styled.button`
  padding: 0;
  margin: 0 !important;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const CloseButton = styled(ButtonArea)`
  position: absolute;
  top: 19px;
  right: 18px;
  ${Media.down.m} {
    top: 8px;
    right: 9px;
  }
  z-index: 1;
`;

export const SelectWrapper = styled.div<{openType?: OpType}>`
  background-color: ${({theme}) => theme.palette.background.primary};
  padding: 14px 14px 40px 14px;
  position: relative;
  border-radius: 12px;
  overflow: scroll;
  max-height: 100%;
  max-width: 400px;
  box-sizing: border-box;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  button:last-child {
    margin: 0 auto;
  }

  ${Media.down.m} {
    display: block;
    position: fixed;
    max-width: 100%;
    max-height: 80vh;
    margin: auto auto 0 auto;
    top: auto;
    z-index: 1100;
    .react-datepicker__month-container {
      padding-right: 4px;
      padding-left: 4px;
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
      left: 62px !important;
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
      right: 62px !important;
    }
  }
  ${({openType}) => {
    if (openType === 'date')
      return `
    `;
    if (openType === 'time')
      return `
      .react-datepicker__month-container {
        display: none;
      }
      .react-datepicker__time-list {
        max-height: 300px !important;
      }
      .react-datepicker__navigation {
        display: none;
      }
    `;
    return '';
  }}
`;

export const Caption = styled(Text).attrs({
  size: 14,
  color: 'quaternary',
  weight: 'bold',
})`
  display: block;
`;

export const DateCaption = styled(Caption)`
  margin-left: 0px;
`;

export const UnitWrapper = styled.span`
  text-transform: lowercase;
`;
