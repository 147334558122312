import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ButtonContinueToPersonal, Link, Wrapper} from './styles';
import {useHistory} from 'react-router-dom';
import {EmptyState} from '../EmptyState';
import {TypeCommunity} from '../../../types/auth';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';

const SubText: React.FC = () => {
  const {getLink} = useLinks();

  return (
    <Trans i18nKey={'common:emptyState.search.community.subText'}>
      <Link to={getLink(route.signUp.get({user: TypeCommunity.manager}))} />
      <Link to={route.requestCommunity.path} />
    </Trans>
  );
};

export const EmptySearch: React.FC = () => {
  const {t} = useTranslation();
  const {push} = useHistory();
  const {getLink} = useLinks();

  const handleClick = () => push(getLink(route.pageUser.path));
  const mainText = t('common:emptyState.search.community.mainText');

  return (
    <Wrapper>
      <EmptyState mainText={mainText} ElemSubText={<SubText />} maxWidth={'625px'} />
      <ButtonContinueToPersonal link={true} onClick={handleClick}>
        {t('common:emptyState.search.community.buttonText')}
      </ButtonContinueToPersonal>
    </Wrapper>
  );
};
