import React from 'react';
import {useHistory} from 'react-router-dom';
import {CreateProfileSteps, TypeCommunity} from '../../../../types/auth';
import {useGenerateUsername, useProfile, useUpdateProfile, useViewer} from '../../../../hooks/user';
import {route} from '../../../../constants/routes';
import {Steps} from './Steps';
import {FormProfile} from '../../../../components/Account/Create/Forms/Profile';
import {useLinks} from '../../../../hooks/common';
import {WrapperAuth} from '../../../../components/Auth/WrapperAuth';

export const CreateProfileContainer: React.FC = () => {
  const viewer = useViewer();
  const {push} = useHistory();
  const profile = useProfile();
  const generateUsername = useGenerateUsername();
  const {getLink} = useLinks();
  const params = useUpdateProfile({
    initialState: profile || {},
    typeCommunity: TypeCommunity.manager,
    onSuccess: () => push(getLink(route.selectPlan.path)),
  });

  const onSkipProfile = () => {
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PhotoUpload})));
  };

  //const loadData = () => JSON.parse(JSON.stringify(jsonData));
  /*const optionsInterestedIn = getOptionsFromJSON(loadData()).map((it) => {
    const label = t(`resident:create.account.optionsInterestedIn.${it.value}`);
    return {label: label, value: it.value};
  });*/
  //const interests = useGetInterests();

  return (
    <Steps step={'profile'}>
      <WrapperAuth>
        <FormProfile
          //likes={interests.options}
          //interests={optionsInterestedIn}
          user={viewer || undefined}
          params={params}
          typeCommunity={TypeCommunity.manager}
          name={viewer?.firstName}
          generateUsername={generateUsername}
          onSkipProfile={onSkipProfile}
        />
      </WrapperAuth>
    </Steps>
  );
};
