import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Media} from '../../../ui-kit/theme';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {HiddenElement} from '../Hidden/HiddenElement';
import {inputPostType, InputsProps} from './types';
import {PostFields, PostFormValue, PostFormValues, PostTypeT} from '../../../types/post';
import defaultAvatar from './assets/defaultAvatar.png';
import {PostActions} from './components/PostActions';
import {CloseButtonMobile} from './components/CloseButtonMobile';
import {MobileMediaPanel} from './MobileMediaPanel';
import {FileInput} from './components/FileInput';
import {
  Avatar,
  CheckBoxWrapper,
  ErrorLine,
  FileLabel,
  FileRemove,
  InputActionsInnerWrapper,
  InputActionsLeftSide,
  InputActionsWrapperMessage,
  InputContainer,
  InputTopWrapper,
  MediumOptionsWrapper,
  PostInputWrapper,
  TextAreaWrapper,
  UploadedFilesWrapper,
  UsersMenuWrapper,
} from '../../Loop/PostCreate/styles';
import {ChangePostTypeIcon, InputsAvatarWrapper, LeftSideWrapper} from './styles';
import {TypeCommunity} from '../../../types/auth';
import {UserPostInfo} from '../../../types/community';
import {FormErrorFields} from '../../../hooks/error';
import {PermissionsList} from '../../../types/settings';
import {EmojiTab} from '../EmojiTab/EmojiTab';
import {RichTextEditor} from '../RichText/Editor';
import {isDesktop} from 'react-device-detect';
import {handleEditorChangeForUserEvent, handleSetEvent, handleSetUser} from '../../../helpers/common';
import {UserMenu} from '../../Account/Popups/HeaderMenu/UserMenu';
import {EventMenu} from '../../Account/Popups/HeaderMenu/EventMenu';
import {Checkbox} from '../../../ui-kit/Checkbox/Checkbox';
import {Text} from '../../../ui-kit/Typography/Text';
import {RTEdefault} from '../../../constants/common';
import {GenerateButton} from '../../../ui-kit/Button/GenerateButton';

const filesLimit = 2;

export const MessageInput: React.FC<InputsProps & {isAnnouncement?: boolean; isRequest?: boolean}> = ({
  user,
  resetType,
  handleSubmit,
  handleChange,
  values,
  isAnnouncement,
  handleChangePostType,
  inputType,
  menuOptions,
  isEdit,
  typeUser,
  ManagerOrAdmin,
  hideAttach,
  isRequest,
  loadingGenerate,
  generateData,
}) => {
  const {t} = useTranslation();
  const placeholder =
    inputType === inputPostType.request
      ? t('community:loop.inputs.placeholderRequestInput')
      : t('community:loop.inputs.placeholderTextPost', {context: typeUser});
  const postType = isAnnouncement ? PostTypeT.announcement : isRequest ? PostTypeT.request : PostTypeT.message;
  const [errors, setError] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const removeDisable = () => setBtnDisabled(false);
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});
  const [generatedData, setGeneratedData] = useState<{count: number; text: string; posted: boolean}>({
    count: 1,
    text: '',
    posted: !Boolean(generateData),
  });
  const validate = () => {
    if (values?.text) {
      return values?.text?.length > 8;
    }
  };
  const onSubmit = (e: React.SyntheticEvent) => {
    if (values?.medium && isAnnouncement && Object.values(values?.medium)?.every((item) => !item)) {
      setError(t('error:mediumEmpty'));
      return;
    }
    e.preventDefault();
    if (!validate()) {
      setError(t('error:postEmpty'));
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    setGeneratedData({...generatedData, posted: true});
    !btnDisabled && handleSubmit?.();
    setBtnDisabled(true);
  };

  const handleChane = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    handleChange?.({name: next.name, value: next.value});
  };

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  useEffect(() => {
    if (isEdit) return;
    handleChange?.({name: 'type', value: postType});
    handleChange?.({name: 'text', value: ''});
    handleChange?.({name: 'sentAs', value: user?.postAs});
    removeDisable();
  }, []);

  const handleRemoveFile = (id: number) => {
    const files = values?.Attachments?.length ? [...values?.Attachments?.filter((el, i) => i !== id)] : [];
    handleChange?.({name: PostFields.Attachments, value: files});
    removeDisable();
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid) return;
    if (values?.Attachments?.length && values?.Attachments?.length >= filesLimit) return;
    let fileInfo = event.target?.files?.[0];
    if (fileInfo && fileInfo?.name.length > 10) {
      const parseName = fileInfo?.name?.split('.');
      const ext = parseName?.[parseName?.length - 1] || '';
      fileInfo = new File([fileInfo], `${fileInfo?.name.slice(0, 5)}...${ext}`, {type: fileInfo?.type});
    }
    const newFile = {value: fileInfo};
    const files = values?.Attachments?.length ? [...values?.Attachments, newFile] : [newFile];
    handleChange?.({name: PostFields.Attachments, value: files});
    removeDisable();
  };
  const inputRef = useRef<any>();
  const setEditorReference = (ref: any) => {
    try {
      inputRef.current = ref;
    } catch (e) {
      console.log(e);
    }
  };
  const onMenuOpen = () => {
    inputRef?.current?.blur();
  };
  useEffect(() => {
    inputRef?.current?.focus();
  }, [!!inputRef?.current]);

  const handleGenerate = async () => {
    setBtnDisabled(true);
    if (loadingGenerate) return;
    const data = await generateData?.('post');
    setGeneratedData({text: data || '', count: generatedData?.count + 1, posted: generatedData?.posted});
    setBtnDisabled(false);
  };

  useEffect(() => {
    if (generatedData?.text) {
      handleChange?.({
        name: PostFields.text,
        value: generatedData?.text,
      });
    }
  }, [generatedData?.text]);

  return (
    <InputContainer $isEdit={isEdit}>
      <InputTopWrapper>
        <LeftSideWrapper>
          <InputsAvatarWrapper>
            <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
            {ManagerOrAdmin && (
              <ChangePostTypeIcon tabIndex={-1} onClick={onMenuOpen}>
                <UsersMenuWrapper>
                  <MenuButton
                    item={{objectId: user?.postAs}}
                    menu={Menu}
                    options={menuOptions}
                    right={true}
                    prevent={true}
                    alwaysShowBtn={true}
                    customIcon={<IconSvg type={'chevrone-down'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />}
                  />
                </UsersMenuWrapper>
              </ChangePostTypeIcon>
            )}
          </InputsAvatarWrapper>
          {generateData && !generatedData?.posted && generatedData?.count <= 3 && (
            <GenerateButton
              loading={loadingGenerate || false}
              initText={t('community:loop.inputs.generatePost')}
              className={'generate'}
              onClick={handleGenerate}
              count={generatedData?.count}
            />
          )}
        </LeftSideWrapper>
        <TextAreaWrapper $isDesktop={isDesktop} $isAnnouncement={postType === PostTypeT.announcement}>
          <RichTextEditor
            placeholder={placeholder}
            name={PostFields.text}
            value={values?.text}
            onChange={(e, position) => handleChane(e, position, values?.text ? values?.text : RTEdefault)}
            rows={8}
            emoji={emoji}
            setEmoji={setEmoji}
            //inputRef={inputRef}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            eventName={eventName}
            setEventName={setEventName}
            inputRef={setEditorReference}
            newState={generatedData?.text}
          />
          <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
          {userMenuOpen && (
            <UserMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetUser={handleSetUser}
              setUserProfile={setUserProfile}
              searchText={searchText}></UserMenu>
          )}
          {eventMenuOpen && (
            <EventMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetEvent={handleSetEvent}
              setEventName={setEventName}
              searchText={searchText}></EventMenu>
          )}
        </TextAreaWrapper>
        <CloseButtonMobile resetType={resetType} postType={inputType} />
      </InputTopWrapper>
      {errors && <ErrorLine>{errors}</ErrorLine>}
      <PostInputWrapper>
        {isAnnouncement && (
          <HiddenElement mq={Media.up.l}>
            <MediumOptionsWrapper>
              {values?.medium &&
                Object.keys(values?.medium).map((item) => (
                  <CheckBoxWrapper key={item}>
                    <Checkbox
                      size={18}
                      $isChecked={Boolean(values?.medium?.[item as keyof typeof values.medium])}
                      onChange={() =>
                        handleChange?.({
                          name: 'medium',
                          value: {...values?.medium, [item]: !values?.medium?.[item as keyof typeof values.medium]},
                        })
                      }
                    />
                    <Text size={12}>{t(`community:loop.medium.${item}`)}</Text>
                  </CheckBoxWrapper>
                ))}
            </MediumOptionsWrapper>
          </HiddenElement>
        )}
        <InputActionsWrapperMessage>
          <InputActionsInnerWrapper>
            {!isEdit && (
              <MobileMediaPanel
                handleFile={handleFile}
                handleChangePostType={handleChangePostType}
                activeType={inputType}
                values={values}
                filesLimit={filesLimit}
                user={user}
                typeUser={typeUser}
              />
            )}
            <UploadedFilesWrapper $isAnnouncement={Boolean(isAnnouncement)} $errors={Boolean(errors)}>
              {values?.Attachments?.map((el, ind) => (
                <FileLabel key={ind}>
                  <span>{typeof el?.value !== 'string' ? el?.value?.name || el.file?.name : el.file?.name}</span>
                  <FileRemove onClick={() => handleRemoveFile(ind)}>
                    <IconSvg type={'close'} viewBox={'0 0 20 20'} width={'20px'} height={'20px'} />
                  </FileRemove>
                </FileLabel>
              ))}
            </UploadedFilesWrapper>
            {!hideAttach ? (
              <>
                {isEdit ? (
                  <InputActionsLeftSide>
                    <FileInput
                      handleChange={handleFile}
                      btnDisabled={(values?.Attachments?.length && values?.Attachments?.length >= filesLimit) || false}
                    />
                  </InputActionsLeftSide>
                ) : (
                  <HiddenElement mq={Media.down.l}>
                    <InputActionsLeftSide>
                      <FileInput
                        handleChange={handleFile}
                        btnDisabled={
                          (values?.Attachments?.length && values?.Attachments?.length >= filesLimit) || false
                        }
                      />
                    </InputActionsLeftSide>
                  </HiddenElement>
                )}
              </>
            ) : (
              <span />
            )}
            {isAnnouncement && (
              <HiddenElement mq={Media.down.l}>
                <MediumOptionsWrapper>
                  {values?.medium &&
                    Object.keys(values?.medium).map((item) => (
                      <CheckBoxWrapper key={item}>
                        <Checkbox
                          size={18}
                          $isChecked={Boolean(values?.medium?.[item as keyof typeof values.medium])}
                          onChange={() =>
                            handleChange?.({
                              name: PostFields.medium,
                              value: {...values?.medium, [item]: !values?.medium?.[item as keyof typeof values.medium]},
                            })
                          }
                        />
                        <Text size={12}>{t(`community:loop.medium.${item}`)}</Text>
                      </CheckBoxWrapper>
                    ))}
                </MediumOptionsWrapper>
              </HiddenElement>
            )}
          </InputActionsInnerWrapper>
          <PostActions
            inputType={inputType}
            resetType={resetType}
            handleSubmit={onSubmit}
            btnDisabled={btnDisabled}
            isEdit={isEdit}
          />
        </InputActionsWrapperMessage>
      </PostInputWrapper>
    </InputContainer>
  );
};

type GroupMessageInputProps = {
  typeUser?: TypeCommunity;
  inputType?: inputPostType;
  resetType: () => void;
  user?: UserPostInfo;
  handleSubmit?: () => void;
  handleChange?: (next: {name: string; value?: PostFormValue}) => void;
  success?: string | null;
  values?: PostFormValues;
  refetchPosts?: () => void;
  error?: FormErrorFields<PostFormValues>;
  handleChangePostType?: (t: inputPostType) => void;
  isEdit?: boolean;
  permissions?: PermissionsList[];
};

export const GroupMessageInput: React.FC<GroupMessageInputProps> = ({
  user,
  resetType,
  handleSubmit,
  handleChange,
  values,
  inputType,
  isEdit,
  typeUser,
  handleChangePostType,
}) => {
  const {t} = useTranslation();
  const placeholder = t('community:loop.inputs.placeholderTextPost', {context: typeUser});
  const postType = PostTypeT.message;
  const [errors, setError] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const removeDisable = () => setBtnDisabled(false);
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});

  const validate = () => {
    if (values?.text) {
      return values?.text?.length > 8;
    }
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!validate()) {
      setError(t('error:postEmpty'));
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    !btnDisabled && handleSubmit?.();
    setBtnDisabled(true);
  };

  const handleChane = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    handleChange?.({name: next.name, value: next.value});
  };

  useEffect(() => {
    if (isEdit) return;
    handleChange?.({name: 'type', value: postType});
    handleChange?.({name: 'text', value: ''});
    handleChange?.({name: 'sentAs', value: user?.postAs});
    removeDisable();
  }, []);

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };
  return (
    <InputContainer $isEdit={isEdit}>
      <InputTopWrapper>
        <InputsAvatarWrapper>
          <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
        </InputsAvatarWrapper>
        <TextAreaWrapper $isDesktop={isDesktop}>
          <RichTextEditor
            placeholder={placeholder}
            name={'text'}
            value={values?.text}
            onChange={(e, position) => handleChane(e, position, values?.text ? values?.text : RTEdefault)}
            rows={8}
            emoji={emoji}
            setEmoji={setEmoji}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            eventName={eventName}
            setEventName={setEventName}
          />
          <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
          {userMenuOpen && (
            <UserMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetUser={handleSetUser}
              setUserProfile={setUserProfile}
              searchText={searchText}></UserMenu>
          )}
          {eventMenuOpen && (
            <EventMenu
              top={positionSubMenu.top}
              left={positionSubMenu.left}
              handleSetEvent={handleSetEvent}
              setEventName={setEventName}
              searchText={searchText}></EventMenu>
          )}
        </TextAreaWrapper>
        <CloseButtonMobile resetType={resetType} postType={inputType} />
      </InputTopWrapper>
      {errors && <ErrorLine>{errors}</ErrorLine>}
      <PostInputWrapper>
        <InputActionsWrapperMessage>
          <InputActionsInnerWrapper>
            {!isEdit && (
              <MobileMediaPanel
                handleChangePostType={handleChangePostType}
                activeType={inputType}
                values={values}
                user={user}
                typeUser={typeUser}
                isGroup
              />
            )}
          </InputActionsInnerWrapper>
          <PostActions resetType={resetType} handleSubmit={onSubmit} btnDisabled={btnDisabled} isEdit={isEdit} />
        </InputActionsWrapperMessage>
      </PostInputWrapper>
    </InputContainer>
  );
};
