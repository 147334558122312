import {gql} from '@apollo/client';
import MemberFragment from './people';
import {LocationFragment} from './locations';

//add order

export const ItemFragment = gql`
  fragment ItemFragment on Item {
    id
    objectId
    createdAt
    updatedAt
    name
    descr
    Lister {
      ...MemberFragment
    }
    category
    listingType
    rewardType
    price
    pricePeriod
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    originalPrice
    yearPurchased
    discount
    discountType
    isLike
    countLikes
    Featured {
      ... on Community {
        id
        objectId
      }
    }
    ApproveReq {
      ... on Community {
        id
        objectId
        name
        type
      }
    }
    Published {
      ... on Community {
        id
        objectId
        name
        type
      }
    }
    AdmHidden {
      ... on Community {
        id
        objectId
      }
    }
    Updated {
      ... on Community {
        id
        objectId
      }
    }
    Reviews {
      edges {
        node {
          id
          objectId
          stars
          text
          subject
          Community {
            id
          }
          Author {
            ...MemberFragment
          }
        }
      }
      count
    }
    Location {
      ...LocationFragment
    }
    maxPeriod
    minDuration
    isDeleted
    isPublished
    isSold
    rating
    Viewers {
      edges {
        node {
          ...MemberFragment
        }
      }
      count
    }
    income
    totalCompletedOrders
    totalViews
    totalRequests
  }
  ${MemberFragment}
  ${LocationFragment}
`;

export const ItemShortFragment = gql`
  fragment ItemShortFragment on Item {
    id
    objectId
    name
    rewardType
    isSold
    listingType
    price
    pricePeriod
    isPublished
    isDeleted
    category
    isLike
    countLikes
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Lister {
      ...MemberFragment
    }
    Featured {
      ... on Community {
        id
        objectId
      }
    }
    ApproveReq {
      ... on Community {
        id
        objectId
      }
    }
    Published {
      ... on Community {
        id
        objectId
      }
    }
    AdmHidden {
      ... on Community {
        id
        objectId
      }
    }
    Updated {
      ... on Community {
        id
        objectId
      }
    }
  }
  ${MemberFragment}
`;

export const ItemShortFragmentForListing = gql`
  fragment ItemShortFragmentForListing on Item {
    id
    objectId
    name
    rewardType
    isSold
    listingType
    price
    pricePeriod
    isPublished
    isDeleted
    category
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Lister {
      objectId
      firstName
      firstLetter
      isVerified
      Avatar {
        file {
          url
          name
        }
      }
      zip
      distance
      geo {
        latitude
        longitude
      }
    }
    isLike
    countLikes
    Featured {
      ... on Community {
        id
        objectId
      }
    }
    ApproveReq {
      ... on Community {
        id
        objectId
      }
    }
    Published {
      ... on Community {
        id
        objectId
      }
    }
    AdmHidden {
      ... on Community {
        id
        objectId
      }
    }
    Updated {
      ... on Community {
        id
        objectId
      }
    }
  }
`;
