import React, {useState} from 'react';

import {PhotoGrid} from './styles';
import {changeFileName, getSourceUrl} from '../../helpers/file';
import {ItemFields, ItemFormValue, TImage} from '../../types/item';
import {PhotoLoader} from '../common/PhotoCard/PhotoLoader';

type TProps = {
  cards: TImage[];
  onChange: (next: {name: string; value: ItemFormValue}) => void;
  error?: boolean;
};

export const PhotosList = ({cards: cards, onChange, error}: TProps) => {
  const [currentCard, setCurrentCard] = useState<TImage | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [underCard, setUnderCard] = useState<TImage | null>(null);
  const maxCount = 6;
  const dragstartHandler = (card: TImage) => () => {
    setCurrentCard(card);
    setIsDragging(true);
  };

  const dragEndHandler = () => {
    setUnderCard(null);
  };

  const dragOverHandler = (card: TImage) => (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setUnderCard(card);
  };

  const onDropHandler = (card: TImage) => (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (!card?.id || !cards?.length || !currentCard) return;
    const newCards = cards
      .map((c) => {
        if (c.id === card.id) {
          return {...c, order: currentCard.order};
        }

        if (c.id === currentCard.id) {
          return {...c, order: card.order};
        }
        return c;
      })
      .sort((a, b) => (a?.order as number) - (b?.order as number));

    setIsDragging(false);
    setUnderCard(null);
    onChange({name: ItemFields.images, value: newCards});
  };

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid || !cards?.length) return;
    const currentIndex = cards.findIndex((card) => card.id === event.target.dataset.id);
    const changeImages = event.target?.files
      ? Object.values(event?.target?.files).filter((_, i) => currentIndex + i < maxCount)
      : [];

    changeImages.forEach((file, i) => {
      cards[currentIndex + i] = {
        id: String(currentIndex + i),
        value: changeFileName(file),
        order: cards?.[currentIndex + i]?.order || 0,
      };
    });

    if (!changeImages.length) {
      cards[currentIndex] = {
        ...cards[currentIndex],
        value: event?.target?.files?.[0],
        order: cards?.[currentIndex]?.order || 0,
      };
    }
    onChange({name: event.target.name, value: cards});
  };
  const handleRemove = (id: number) => {
    const newCards = [...cards];
    newCards[id] = {...newCards[id], value: ''};
    onChange({name: 'images', value: newCards});
  };
  const shownCards = cards.slice(0, 3).every((c) => Boolean(c.value)) ? cards : cards.slice(0, 3);
  const cardsLoaded = cards.filter((el) => !!el.value).length;
  return (
    <div>
      <PhotoGrid isDragging={isDragging}>
        {shownCards?.map((card, i) => (
          <PhotoLoader
            typeCursor={'grab'}
            isUnderCard={card.id === underCard?.id}
            src={card?.value ? getSourceUrl(card?.value) : ''}
            key={String(card.id) + i}
            dataId={card.id || ''}
            numberId={i}
            name={ItemFields.images}
            handleChange={handleImage}
            aspectRatio={'3:2'}
            draggable={true}
            onDragStart={dragstartHandler(card)}
            onDragLeave={dragEndHandler}
            onDragEnd={dragEndHandler}
            onDragOver={dragOverHandler(card)}
            onDrop={onDropHandler(card)}
            multiple={true}
            showError={error && !cardsLoaded && i === 0}
            handleRemove={handleRemove}
          />
        ))}
      </PhotoGrid>
      {/* <InnerDesrPhotos>{t('listings:descr.images')}</InnerDesrPhotos> */}
    </div>
  );
};
