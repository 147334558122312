import React from 'react';
import {useTranslation} from 'react-i18next';

import {PlanCard} from './PlanCard';
import {IGetCurrValue} from '../../../../types/common';
import {PaymentPeriodType} from '../../../../types/payment';
import {Switch} from '../../../../ui-kit/Button/SwitchButton/PrimarySwitch';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  ContPlanCard,
  DiscountPeriodPlan,
  GreetingTitle,
  InnerPeriodPlan,
  PlanWrapper,
  SubscribeText,
  TitlePeriodPlan,
} from '../styles';

import {SelectPlanRes} from '../../../../hooks/community';

type PlansProps = {
  params: SelectPlanRes;
  getCodeValue: IGetCurrValue;
  getSignValue: IGetCurrValue;
  currency: string;
};

export const SelectPlanCommunity: React.FC<PlansProps> = ({params, getCodeValue, getSignValue, currency}) => {
  const {paymentPlans, values, loading, onChange} = params;
  const {t} = useTranslation();
  const handleChange = () => {
    onChange({
      name: 'billing',
      value: values.billing === PaymentPeriodType.year ? PaymentPeriodType.month : PaymentPeriodType.year,
    });
  };
  return (
    <PlanWrapper>
      <GreetingTitle>{t('community:plan.title.primary')}</GreetingTitle>
      <SubscribeText>{t('community:plan.descr.text')}</SubscribeText>
      <InnerPeriodPlan>
        <TitlePeriodPlan>{t('community:plan.descr.billing')}</TitlePeriodPlan>
        <Switch
          variant={'primary'}
          width={'207px'}
          checked={values.billing === PaymentPeriodType.year}
          textChecked={t('community:plan.button.switch.annually')}
          textUnchecked={t('community:plan.button.switch.monthly')}
          onChange={handleChange}
        />
        <DiscountPeriodPlan>{t('community:plan.descr.discountBilling')}</DiscountPeriodPlan>
      </InnerPeriodPlan>

      <ContPlanCard>
        {paymentPlans?.map((it) => (
          <PlanCard
            key={it.id}
            data={it}
            params={params}
            isChecked={values.plan === it.objectId}
            getCodeValue={getCodeValue}
            getSignValue={getSignValue}
            currency={currency}
          />
        ))}
      </ContPlanCard>
      <LottieLoader $isVisible={loading} allScreen={true} />
    </PlanWrapper>
  );
};
