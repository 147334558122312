import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AmenityFormAction, TAmenity} from '../../types/amenity';
import {
  DesktopView,
  InnerListingView,
  ListingViewDescr,
  ListingViewSubTitle,
  ListingViewTitle,
  MainContListingView,
  MobileView,
  WrapperListingView,
} from './styles';
import {Slider} from './Slider';
import {RichText} from '../common/RichText/RichText';
import {NavContainer} from '../../containers/Navigation';
import {RequestAside} from './RequestAside';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {ListingNotFound} from '../Extras';
import {SendMessageStatus} from '../../ui-kit/RequestCard/types';
import {AddCreditCardPopup} from '../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {useGetMessagesAllows} from '../../hooks/community';
import {amenityAvailableDataT, TDataCreator, TResCreateAmenityOrder} from '../../hooks/amenity';
import {LinkBack} from '../common/Buttons/LinkBack';
import {Linkify} from '../common/Buttons/Linkify';
import {formatUserLabel} from '../../helpers/people';
import {TypeCommunity} from '../../types/auth';
import {Gallery} from 'react-photoswipe-gallery';

interface IProps {
  amenity?: Partial<TAmenity>;
  $loading?: boolean;
  action: AmenityFormAction;
  onPublish?: () => void;
  onUnlist?: () => void;
  viewerObjId?: string;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataCreator?: TDataCreator;
  onDelete?: () => void;
  onRenew?: () => void;
  isAvailable?: amenityAvailableDataT;
  communityAlias?: string;
  communityId?: string;
  requestAccess?: boolean;
  refetch?: () => void;
  dataOrder?: TResCreateAmenityOrder;
  adminLabels: Record<string, string>;
  targetTypeUser?: TypeCommunity;
}
const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};

export const ViewAmenity: React.FC<IProps> = ({
  amenity,
  $loading,
  action,
  viewerObjId,
  dataCreateMsg,
  isAvailable,
  communityAlias,
  requestAccess,
  refetch,
  dataCreator,
  dataOrder,
  adminLabels,
  targetTypeUser,
}) => {
  const {t} = useTranslation();
  const textSeo = amenity?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const viewerIsNotOwner = action === AmenityFormAction.view && viewerObjId !== amenity?.Lister?.objectId;
  const descrBlock = useRef<HTMLDivElement>(null);
  const imagesBlock = useRef<HTMLDivElement>(null);
  const sendMessageRef = useRef<HTMLDivElement>(null);
  const requestCardRef = useRef<HTMLDivElement>(null);
  const changeCardStatusRef = useRef<(status: SendMessageStatus) => void>();
  const [noRequestModal, setNoRequestModal] = useState<boolean>(false);
  const [showPropositionAddCardPopup, setShowProposition] = useState<boolean>(false);
  const showWarnPopup = () => setShowProposition(true);
  const showAddCardPopup = () => setNoRequestModal(true);

  const blocksHeight = (descrBlock?.current?.offsetHeight || 0) + (imagesBlock.current?.offsetHeight || 0) || undefined;
  const requestCardRange = blocksHeight ? blocksHeight + 60 : blocksHeight;

  const listingOg = useMemo(() => {
    return {
      title: amenity?.name,
      description: amenity?.descr,
      image: amenity?.images?.[0]?.file?.url,
    };
  }, [amenity]);

  const handleCloseAddCreditCardPopup = () => {
    setNoRequestModal(false);
    refetch?.();
  };

  const closeProposition = () => {
    setShowProposition(false);
  };

  const handleAddCC = () => {
    closeProposition();
    showAddCardPopup();
  };

  const allows = useGetMessagesAllows({directUserId: amenity?.Lister?.objectId});
  const userLabel = formatUserLabel({t, userId: amenity?.Lister?.objectId, typeUser: targetTypeUser, adminLabels});
  return (
    <NavContainer>
      {!requestAccess && noRequestModal && <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />}
      <Seo title={textSeo} keywords={seoKeywords} og={listingOg} />
      {$loading ? (
        <ListingViewSkeleton />
      ) : amenity?.isDeleted ? (
        <ListingNotFound />
      ) : (
        <>
          <DesktopView>
            <MainContListingView ref={imagesBlock}>
              <WrapperListingView>
                <LinkBack type={'amenities'} />
                <InnerListingView>
                  <ListingViewTitle>
                    <Linkify>{amenity?.name}</Linkify>
                  </ListingViewTitle>
                  <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                    <Slider images={amenity?.images} />
                  </Gallery>
                  {viewerIsNotOwner && amenity?.isPublished && (
                    <RequestAside
                      width={'300px'}
                      user={dataCreator}
                      descr={t('requests:descr.send')}
                      amenity={amenity}
                      dataCreateMsg={dataCreateMsg}
                      className={'listing-view__form-aside'}
                      bottomBoundary={requestCardRange}
                      isAvailable={isAvailable}
                      isDesktop
                      accessRequest={requestAccess}
                      showWarnPopup={showWarnPopup}
                      canSandMessage={allows.allow}
                      dataOrder={dataOrder}
                      userLabel={userLabel}
                    />
                  )}
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView ref={descrBlock}>
              <WrapperListingView offsetTop={'25px'}>
                <InnerListingView>
                  <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                  <ListingViewDescr>
                    <RichText html={amenity?.descr} />
                  </ListingViewDescr>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
          </DesktopView>

          <MobileView>
            <MainContListingView>
              <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                <Slider images={amenity?.images} communityAlias={communityAlias} />
              </Gallery>
              <WrapperListingView>
                <InnerListingView>
                  <ListingViewTitle>{amenity?.name}</ListingViewTitle>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                <InnerListingView>
                  <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                  <ListingViewDescr>
                    <RichText html={amenity?.descr} />
                  </ListingViewDescr>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                {viewerIsNotOwner && amenity?.isPublished && (
                  <RequestAside
                    width={'300px'}
                    user={dataCreator}
                    descr={t('requests:descr.send')}
                    amenity={amenity}
                    dataCreateMsg={dataCreateMsg}
                    className={'listing-view__form-aside'}
                    changeCardStatusRef={changeCardStatusRef}
                    sendMessageRef={sendMessageRef}
                    requestCardRef={requestCardRef}
                    isAvailable={isAvailable}
                    accessRequest={requestAccess}
                    showWarnPopup={showWarnPopup}
                    dataOrder={dataOrder}
                    userLabel={userLabel}
                  />
                )}
              </WrapperListingView>
            </MainContListingView>
          </MobileView>
          {showPropositionAddCardPopup && (
            <Confirmation
              title={t('common:modals.addCC.title')}
              subTitle={t('common:modals.addCC.descr')}
              okText={t('common:modals.addCC.button')}
              onClose={closeProposition}
              onOk={handleAddCC}
            />
          )}
        </>
      )}
    </NavContainer>
  );
};
