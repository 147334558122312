import {TFunction} from 'react-i18next';
import {FileInfo} from '../queries/types/parse';

export interface SelectOption<T> {
  key: string;
  value: T | string | number;
  label: string;
}
export enum imageType {
  avatar = 'avatar',
  itemImage = 'itemImage',
  postAttachment = 'postAttachment',
}

export type PointerFile = {
  id: string;
  objectId: string;
  file: {
    name: string;
    url: string;
  };
  mime: string;
};

export type translateFn = TFunction<'translate'>;

export interface IGetCurrValue {
  (value: string | number): string;
}

export type TImage = {
  id?: string;
  value?: File | string;
  order?: number;
  file?: FileInfo;
};

export type EditorRefStateT = {
  blur: () => void;
  focus: () => void;
};

export type OGDataT = {
  item?: {
    name?: string;
    descr?: string;
    images?: any[];
  };
  event?: any;
};
