import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {DocumentsSkeletonSection} from '../common/Skeletons/DocumentSkeleton';
import {TDocument} from '../../types/document';
import {IActionsOnDocumentsData, FormMode, TModalData} from './types';
import {CardDocument} from './CardDocument';
import {ModalDocumentForm} from './ModalDocumentForm';
import {AddButton, HeaderWrapper, ItemsContainer, PageWrapper} from './styles';
import {isMobile} from 'react-device-detect';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {BasePortal} from '../../ui-kit/Popup';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

interface IProps {
  items?: Array<TDocument>;
  actionsData: IActionsOnDocumentsData;
  loading: boolean;
  canEdit: boolean;
}

export const PageDocuments: React.FC<IProps> = ({items, actionsData, loading, canEdit}) => {
  const {t} = useTranslation();

  const [docModal, setModal] = useState<TModalData | null>(null);

  const {clearValues} = actionsData;

  const closeModal = () => {
    setModal(null);
    clearValues();
  };
  const openAddDocModal = () => {
    setModal({mode: FormMode.Add});
  };
  const openEditDocModal = (doc: TDocument) => {
    setModal({mode: FormMode.Edit, selectedDoc: doc});
  };

  return (
    <>
      <PageWrapper>
        {!isMobile && (
          <HeaderWrapper>
            <Text size={26} color={'quaternary'} weight={'medium'} variant={'primary'}>
              {t('documents:pageTitle')}
            </Text>
            {canEdit ? <AddButton onClick={openAddDocModal}>{t('documents:buttons.add')}</AddButton> : null}
          </HeaderWrapper>
        )}
        <Br indent={32} />
        <ItemsContainer>
          {loading ? (
            <DocumentsSkeletonSection />
          ) : (
            items?.map((el) => (
              <CardDocument
                document={el}
                key={el.objectId}
                actionsData={actionsData}
                openEditDocModal={openEditDocModal}
                canEdit={canEdit}
              />
            ))
          )}
        </ItemsContainer>
        {canEdit ? <AddButtonPortal onClick={openAddDocModal} /> : null}
      </PageWrapper>
      {docModal && (
        <>
          {isMobile ? (
            <BasePortal>
              <IsModalProvider>
                <ModalDocumentForm {...docModal} close={closeModal} actionsData={actionsData} />
              </IsModalProvider>
            </BasePortal>
          ) : (
            <ModalDocumentForm {...docModal} close={closeModal} actionsData={actionsData} />
          )}
        </>
      )}
    </>
  );
};
