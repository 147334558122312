import {CommunityMembersResponseT, NewMembersResponseT} from '../queries/people';
import {CommunityMembersT, MemberT, PeopleSearchType} from '../types/people';
import {GQLUserWhereInput} from '../graphql.schema';
import {User} from '../queries/types/user';
import {TypeCommunity} from '../types/auth';
import {translateFn} from '../types/common';
import {status} from '../ui-kit/ProfileIntro/types';
import {CommunityStateType} from '../states/community';

export const toStatePeople = (
  community?: CommunityMembersResponseT,
  where?: GQLUserWhereInput,
  onlineUsers?: string[],
) => {
  const admins = community?.Admins?.edges?.map((edge) => edge?.node?.User?.objectId) || [];

  const allMembersBase =
    community?.Residents?.edges?.map((edge) => {
      return {
        ...edge.node,
        lastName: edge.node?.lastName || edge.node?.firstLetter,
        isAdmin: admins.includes(edge.node.objectId),
        isOnline: onlineUsers?.includes(edge.node?.objectId),
      };
    }) ?? [];
  const allMembers = community?.Owner
    ? checkOwnerIncludeName(community.Owner, where)
      ? [
          {
            ...community?.Owner,
            lastName: community?.Owner?.lastName || community?.Owner?.firstLetter,
            isManager: true,
            isOnline: onlineUsers?.includes(community?.Owner?.objectId),
          } as MemberT,
          ...allMembersBase,
        ]
      : allMembersBase
    : allMembersBase;

  return {
    id: community?.id,
    objectId: community?.objectId,
    countMembers:
      (community?.Residents?.edges?.length || 0) +
      (community?.Admins?.edges?.length || 0) +
      Number(!!community?.Owner?.objectId),
    AllMembers: allMembers,
    Residents: community?.Residents?.edges?.map((e) => ({
      ...e.node,
      lastName: e.node?.lastName || e?.node?.firstLetter,
      isOnline: onlineUsers?.includes(e.node?.objectId),
    })),
    Admins: community?.Admins?.edges?.map((e) => ({
      ...e.node.User,
      lastName: e.node.User?.lastName || e.node.User?.firstLetter,
      isAdmin: true,
      isOnline: onlineUsers?.includes(e.node.User?.objectId),
    })),
    Owner: {
      ...community?.Owner,
      lastName: community?.Owner?.lastName || community?.Owner?.firstLetter,
      isManager: true,
      isOnline: community?.Owner?.objectId && onlineUsers?.includes(community?.Owner?.objectId),
    } as MemberT,
    residentsCount: community?.Residents?.count,
  };
};

export const toStateNewMembers = (response?: NewMembersResponseT) => {
  const users = response?.JoinRequests?.edges?.map((e) => ({
    ...e.node.User,
    joinRequestId: e.node.objectId,
  }));
  return {
    id: response?.id,
    objectId: response?.objectId,
    newMembers: users,
  };
};

export const getPeopleByType = (type: PeopleSearchType, members: CommunityMembersT): MemberT[] => {
  if (!members) return [];
  if (type === PeopleSearchType.Managers) return members.Owner ? [members.Owner] : [];
  const membersByType = members[type];

  return membersByType ? membersByType : [];
};

export const checkOwnerIncludeName = (owner?: User, where?: GQLUserWhereInput) => {
  if (!where?.firstName?.matchesRegex) return true;
  return (
    owner?.firstName?.toLocaleLowerCase()?.includes(where?.firstName?.matchesRegex) ||
    owner?.lastName?.toLocaleLowerCase()?.includes(where?.firstName?.matchesRegex)
  );
};

export const toMembersWithListingsCount = (members: MemberT[], membersItemsCount: [string, number][]) => {
  return members.map((p) => {
    const listingsCount = membersItemsCount?.find(([id]) => id === p.id)?.[1];
    return {
      ...p,
      listingsCount: listingsCount ?? 0,
    };
  });
};

export const toMemberWithListingsCount = (member: MemberT, membersItemsCount: [string, number][]) => {
  const listingsCount = membersItemsCount?.find(([id]) => id === member.id)?.[1];
  return {
    ...member,
    listingsCount: listingsCount ?? 0,
  };
};

export const getPeopleWithListings = (members: MemberT[], membersItemsCount: [string, number][]) => {
  return toMembersWithListingsCount(members, membersItemsCount);
};

export const getPeopleOptions = (t: (key: string) => string, community?: CommunityStateType) => {
  const options = [
    {
      label: t('search:people.everyone'),
      value: PeopleSearchType.Everyone,
    },
  ];
  if ((community?.Admins?.length || 0) > 0)
    options.push({
      label: t('search:people.managers'),
      value: PeopleSearchType.Admins,
    });

  options.push({
    label: t('search:people.residents'),
    value: PeopleSearchType.Residents,
  });
  if (!community?.Admins?.length) return [];
  return options;
};

type formatUserLabelOptions = {
  typeUser?: TypeCommunity;
  t: translateFn;
  adminLabels: Record<string, string>;
  userId?: string;
};

export type UserLabelType = {status: status; name?: string};
export const formatUserLabel = (options: formatUserLabelOptions): {status: status; name?: string} => {
  const {t, adminLabels, typeUser, userId} = options;
  if (!typeUser || !userId) return {status: 'secondary', name: ''};

  const managerText = t('people:person.labels.admin');
  const adminText: string = adminLabels[userId] || t('people:person.labels.manager');

  if (typeUser === TypeCommunity.manager) {
    return {status: 'tertiary', name: managerText};
  }
  if (typeUser === TypeCommunity.admin) {
    return {status: 'tertiary', name: adminText};
  }
  return {status: 'secondary', name: ''};
};
