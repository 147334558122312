import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Copyrights, SettingsMenu, MenuContent, SettingsHeader, UserSettings} from './components';
import {CommunitiesDropdown} from './CommunitiesDropdown';
import {
  BtnText,
  DarkBackground,
  EntryLinkWrapper,
  MobileWrapper,
  PopupWrapper,
  SettingsBtn,
  SettingsWrapper,
  WrapperFlex,
  WrapperSettingsButtons,
} from './styles';
import {CustomCommunity, TLinks} from './types';
import {SettingsContainer} from '../../../../containers/Settings/Community/SettingsContainer';
import {getMaxCountOfCommunity} from '../../../../helpers/community';
import {getResize} from '../../../../helpers/file';
import {CommunityStateType} from '../../../../states/community';
import {TypeUserStateType} from '../../../../states/typeUser';
import {TypeCommunity} from '../../../../types/auth';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {useViewer} from '../../../../hooks/user';
import {IsModalProvider} from '../../../../containers/Navigation/IsModalProvider';

export interface TSettingsBaseProps {
  type?: TypeUserStateType;
  links: TLinks;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface TSettingsProps extends TSettingsBaseProps {
  community: CommunityStateType;
  setCurrentCommunity: (id: string) => void;
  listCommunities: Array<CustomCommunity>;
  hasListingFeed: boolean;
  haveManagerRank: boolean;
  haveAdminRank: boolean;
  openSearch: () => void;
  hasMoreItems?: boolean;
  loadingCommunities?: boolean;
  loadMore: () => Promise<void>;
  joinLoading?: boolean;
}

export function SettingsPopup({type, active, setActive, links}: TSettingsBaseProps) {
  const handleClose = () => setActive(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();
  return active ? (
    <DarkBackground onClick={handleClose}>
      <IsModalProvider>
        <PopupWrapper onClick={stop}>
          <SettingsContainer type={type} links={links} active={active} setActive={setActive} />
        </PopupWrapper>
      </IsModalProvider>
    </DarkBackground>
  ) : (
    <></>
  );
}

export const Settings: React.FC<TSettingsProps> = ({
  type,
  links,
  active,
  setActive,
  community,
  setCurrentCommunity,
  listCommunities,
  haveAdminRank,
  haveManagerRank,
  openSearch,
  loadMore,
  loadingCommunities,
  hasMoreItems,
  joinLoading,
}) => {
  const {t} = useTranslation();
  const user = useViewer();
  const [settingsActive, setSettingsActive] = useState(false);
  const handleClose = () => setActive(false);
  const [isOpen, setIsOpen] = useState(false);
  // const {communityCount} = useCheckJoined();
  const maxCountCommunities = getMaxCountOfCommunity(user?.email);
  const communityName = `${community?.name} (${community?.objectId})`;

  const handleSettingsActive = () => {
    setSettingsActive(!settingsActive);
  };
  if (!active) return null;

  return type === TypeCommunity.manager ? (
    <SettingsWrapper $isSettingsActive={settingsActive}>
      <SettingsHeader handleClose={handleClose} />
      <CommunitiesDropdown
        src={getResize(community?.avatar, 'sm')}
        title={community?.name}
        subtitle={t('settings:popup.community.subtitle')}
        to={links.community}
        communities={listCommunities}
        links={links}
        type={type}
        setCurrentCommunity={setCurrentCommunity}
        key={community?.objectId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        haveAdminRank={haveAdminRank}
        haveManagerRank={haveManagerRank}
        openSearch={openSearch}
        maxCountCommunities={maxCountCommunities}
        loadMore={loadMore}
        loadingCommunities={loadingCommunities}
        hasMoreItems={hasMoreItems}
        joinLoading={joinLoading}
      />
      {!isOpen && (
        <>
          <UserSettings
            handleClose={handleClose}
            src={getResize(user?.Avatar?.file?.url, 'lg')}
            title={`${user?.firstName} ${user?.lastName}`}
            subtitle={t('settings:popup.account.subtitle')}
            to={links.profile}
          />
          <MenuContent links={links} handleClose={handleClose} />
          <SettingsMenu
            links={links}
            active={settingsActive}
            handleActive={handleSettingsActive}
            handleClose={handleClose}
            communityName={communityName}
          />
          <EntryLinkWrapper as={'div'}>
            <WrapperSettingsButtons>
              <SettingsBtn to={links.invite}>
                <WrapperFlex>
                  <BtnText>{t('settings:popup.buttons.invite')}</BtnText>
                  <IconSvg type={'person-add'} />
                </WrapperFlex>
              </SettingsBtn>
            </WrapperSettingsButtons>
          </EntryLinkWrapper>
        </>
      )}
      <Copyrights />
    </SettingsWrapper>
  ) : (
    <SettingsWrapper $isSettingsActive={settingsActive}>
      <SettingsHeader handleClose={handleClose} />
      {(haveAdminRank || haveManagerRank || listCommunities?.length >= 1) && (
        <CommunitiesDropdown
          maxCountCommunities={maxCountCommunities}
          src={getResize(community?.avatar, 'sm')}
          title={community?.name}
          subtitle={t('settings:popup.community.residentSubtitle')}
          to={links.community}
          links={links}
          setCurrentCommunity={setCurrentCommunity}
          communities={listCommunities}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          type={type || undefined}
          haveAdminRank={haveAdminRank}
          haveManagerRank={haveManagerRank}
          openSearch={openSearch}
          loadMore={loadMore}
          loadingCommunities={loadingCommunities}
          hasMoreItems={hasMoreItems}
          joinLoading={joinLoading}
        />
      )}
      {!isOpen && (
        <>
          <UserSettings
            handleClose={handleClose}
            src={getResize(user?.Avatar?.file?.url, 'lg')}
            title={`${user?.firstName} ${user?.lastName}`}
            subtitle={t('settings:popup.account.subtitle')}
            to={links.profile}
          />
          <MenuContent links={links} handleClose={handleClose} />
          <SettingsMenu
            links={links}
            active={settingsActive}
            handleClose={handleClose}
            handleActive={handleSettingsActive}
            communityName={communityName}
          />
          <WrapperSettingsButtons>
            {!community && links.searchCommunity && (
              <MobileWrapper>
                <SettingsBtn to={'#'} onClick={openSearch}>
                  <WrapperFlex>
                    <BtnText>{t('common:menu.searchCommunities')}</BtnText>
                    <IconSvg type={'search-filled'} />
                  </WrapperFlex>
                </SettingsBtn>
              </MobileWrapper>
            )}
          </WrapperSettingsButtons>
        </>
      )}
      <Copyrights />
    </SettingsWrapper>
  );
};
