import React from 'react';
import {useTranslation} from 'react-i18next';
import {Label} from '../../ui-kit/Labels';
import {Text} from '../../ui-kit/Typography/Text';
import {InnerDataPreview, InnerLabelCard, InnerLabelItemTitle, ListingCardTitle} from './styles';

export type TLabelCard = {
  isPromotion: boolean;
  name?: string;
  category?: string;
  promotionTag?: string;
};

const LabelCard: React.FC<TLabelCard> = ({isPromotion, name, promotionTag, category}) => {
  const {t} = useTranslation();

  return (
    <InnerLabelCard>
      <InnerLabelItemTitle>
        <ListingCardTitle variantText={'quaternary'}>{name}</ListingCardTitle>
      </InnerLabelItemTitle>

      <InnerDataPreview>
        {isPromotion ? (
          <>
            <Label variant={'quaternary'} squared={true}>
              {t(`company:localBiz.labels.promotion`)}
            </Label>
            <Text color={'octonary'} size={14} weight={'medium'}>
              {promotionTag}
            </Text>
          </>
        ) : (
          <>
            <Label variant={'quinary'} squared={true}>
              {category}
            </Label>
          </>
        )}
      </InnerDataPreview>
    </InnerLabelCard>
  );
};

const MemoLabelCard = React.memo(LabelCard);
export {MemoLabelCard};
