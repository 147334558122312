import styled from 'styled-components';
import {fontPrimaryReg} from '../styles';
import {Media} from '../../../ui-kit/theme';
import {isFromApp} from '../../../ui-kit/Navigation/mobile/styles';

export const TabsWrapper = styled.div.attrs({className: 'topTabWrapper'})`
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 63px;
  padding: 5px 5px 0 21px;
  ${isFromApp() ? ` top: 52px;` : ''}
  background-color: ${({theme}) => theme.palette.grayscale._0};
  z-index: 301;
  border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._11};
  transition: 0.1s all;
  overflow: hidden;
  ${Media.up.l} {
    display: none;
  }
`;

export const TabWrapper = styled.div`
  margin-right: 20px;
`;

export const TabItem = styled.div<{$isActive: boolean}>`
  height: 32px;
  box-sizing: border-box;
  width: auto;
  display: inline-flex;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0;
    ${({$isActive, theme}) =>
      $isActive &&
      `
        width: 100%;
        height: 3px;
        background-color: ${theme.palette.grayscale._18};
    `};
    transition: 0.5s;
  }
`;

export const TabItemTitle = styled.div<{$padd?: boolean}>`
  font-size: 16px;
  color: ${({theme}) => theme.palette.grayscale._18};
  ${({$padd}) => $padd && `padding: 0px 4px;`};
  & > div {
    ${({$padd}) => $padd && `right: -8px`};
  }
  ${fontPrimaryReg};
`;

export const PageWrapper = styled.div<{$scrolled: boolean; $exclude?: boolean}>`
  padding-top: 0px;
  transition: 0.1s all;
`;

export const MobileMsgsWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.nonary};
  height: 100%;
  overflow-y: scroll;
`;
