import {MessageData, MsgStatus, TContact, UserChat} from '../types/messages';
import {differenceInSeconds, parseISO} from 'date-fns';
import {EChatStatus, Message, TypeMsg} from '../queries/types/message';
import {toPointer} from './parse';
import {getResize} from './file';
import {User} from '../queries/types/user';
import {getUserName} from './user';
import {TypeCommunity} from '../types/auth';
import {route} from '../constants/routes';
import {getTextMsgRequest} from './order';
import {PointerFile, translateFn} from '../types/common';

export const toPointerMessage = (
  msg: Omit<MessageData, 'Attachments'> & {
    Attachments?: (Partial<PointerFile> | undefined)[];
  },
) => {
  return {
    ...msg,
    ShowTo: [toPointer({__typename: '_User', objectId: msg?.ShowTo})],
    Author: {link: msg?.Author},
    Order: msg?.Order ? {link: msg?.Order} : undefined,
    Community: msg?.Community ? {link: msg?.Community} : undefined,
  };
};

export const isOnline = (date?: Date | string) => {
  if (!date) {
    return;
  }
  const correctDate = typeof date === 'string' ? parseISO(date) : date;
  return differenceInSeconds(new Date(), correctDate) <= 50;
};

export const getDataLastMessage = (contactId?: string, msgs?: Message[], viewerId?: string, t?: translateFn) => {
  const msg = (msgs ? [...msgs] : [])
    ?.sort((a, b) => (b.createdAt as Date).getTime() - (a.createdAt as Date).getTime())
    ?.filter((el) => el.type !== TypeMsg.internalNote)
    ?.find((msg) => {
      return msg?.Author?.objectId === contactId || msg?.ShowTo?.find((it) => it?.objectId === contactId);
    });
  let messageText = msg?.text || '';
  const isAttachment = !!msg?.Attachments?.length;
  const isEmptyText = !msg?.text
    ?.replaceAll('<p>', '')
    ?.replaceAll('</p>', '')
    ?.replaceAll('\n', '')
    ?.replaceAll('&nbsp;', '')
    ?.trim()?.length;

  if (msg?.type === TypeMsg.notification) {
    const dataText = getTextMsgRequest(msg?.Order, msg?.text, msg.Author, viewerId);
    messageText =
      t?.(dataText.label, {
        user: dataText.opts.user,
        item: dataText.opts.item,
        author: dataText.opts.author,
        date: '',
        time: '',
        interpolation: {
          escapeValue: false,
        },
      }) || '';
  }
  messageText = isAttachment && isEmptyText ? t?.('messages:sentPhoto') || '' : messageText;
  return {
    text: messageText,
    orderStatus: msg?.Order?.status || '',
    date: msg?.createdAt,
    haveUnseenMsg: !msg?.Seen?.find((c) => c?.objectId === viewerId) && msg?.Author?.objectId !== viewerId,
    isClosed: msg?.Chat?.status === EChatStatus.close,
    isArchived: Boolean(msg?.Chat?.isArchived),
    chatId: msg?.Chat?.objectId,
  };
};

export const getContactsList = (
  contacts?: UserChat[],
  viewerId?: string,
  msgs?: Message[],
  typeUser?: TypeCommunity | null,
  t?: translateFn,
  onlineUsers?: string[],
) => {
  const data = contacts
    ?.reduce((acc, c) => {
      const {chatId, isArchived, isClosed, ...dataMsg} = getDataLastMessage(c?.objectId, msgs, viewerId, t);

      if (c?.objectId !== viewerId) {
        acc.push({
          objectId: c?.objectId || '',
          avatar: getResize(c?.Avatar?.file?.url, 'lg') || '',
          isOnline: onlineUsers?.includes(c?.objectId),
          name: getUserName(typeUser, c?.firstName, c?.lastName || `${c?.firstLetter ? c?.firstLetter + '.' : ''}`),
          dataMsg,
          chatId,
          isArchived,
          isClosed,
          isSupport: Boolean(c.isSupport),
        });
      }
      return acc;
    }, [] as TContact[])
    .sort((a, b) => {
      const dateA = a.dataMsg.date || 0;
      const dateB = b.dataMsg.date || 0;

      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
  // console.log(
  //   'data1',
  //   data?.find((el) => el?.objectId === 'rk3UYmuwDn'),
  //   ' data2:',
  //   contacts,
  // );
  return data;
};

export const isSeen = (message: Message, objectId?: string): boolean => {
  return (message?.Seen || []).findIndex((el) => el?.objectId === objectId) >= 0;
};

export const getMesssages = (messages: Message[], contactObjectId?: string) => {
  if (contactObjectId) {
    return messages?.filter(
      (el) =>
        el.Author?.objectId === contactObjectId || el.ShowTo?.filter((it) => it?.objectId === contactObjectId)?.length,
    );
  }
};

export const getCountUnreadMsgs = (messages: Message[], objectId?: string) => {
  const data = messages?.reduce((acc, el) => {
    if (
      !isSeen(el, objectId) &&
      el?.Author?.objectId !== objectId &&
      el.ShowTo?.find((it) => it?.objectId === objectId)
    ) {
      // console.log(el);
      // if (!el?.Attachments && !el?.text && !el?.Order && el?.type === 'personal') return; //empty message
      acc['count'] = (acc['count'] || 0) + 1;
    }
    return acc;
  }, {} as any);

  return data?.count;
};

export const sortDescByDate = <T>(array?: T[] | null, field?: string) => {
  return (array ? [...array] : [])?.sort((a, b) => {
    const dateA = (a as any)[field || ''] as Date;
    const dateB = (b as any)[field || ''] as Date;

    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
};

export const getDataWithAvatars = (newData?: Array<UserChat>, oldData?: Array<TContact>) => {
  const data = [];

  for (let i = 0; i < Math.max(newData?.length || 0, oldData?.length || 0); i++) {
    const currentItem = oldData?.[i];
    const newCurrentItem = newData?.find((el) => el.objectId === currentItem?.objectId);

    const avatar = newCurrentItem?.Avatar?.file.url;
    data.push({
      ...currentItem,
      avatar: avatar || currentItem?.avatar,
    });
  }

  return data;
};

export const usetToTcontact = (user?: User, typeUser?: TypeCommunity | null): TContact | null => {
  if (!user) return null;
  return {
    objectId: user?.objectId,
    name: getUserName(typeUser, user?.firstName, user.lastName),
    avatar: user?.Avatar?.file?.url,
    isOnline: user.isOnline,
    dataMsg: {
      date: null,
      orderStatus: '',
      text: '',
      haveUnseenMsg: false,
    },
    isSupport: Boolean(user.isSupport),
  };
};

export const getActualNotifMessages = (messages?: Message[] | null) => {
  if (!messages) return {actualMessages: [], old: []};
  const notifications = messages.filter((el) => el?.type === TypeMsg.notification);
  const ordersParis: Record<string, Message[]> = {};
  notifications.forEach((el) => {
    const id = el?.Order?.objectId;
    if (id) {
      ordersParis[id] ? ordersParis[id]?.push(el) : (ordersParis[id] = [el]);
    }
  });
  const excludedIds = Object.keys(ordersParis)
    .filter((el) => Number(ordersParis[el]?.length) > 1)
    .map((el) => ordersParis[el]?.pop() && ordersParis[el]?.map((el) => el.objectId))
    .flat();
  return {actualMessages: messages?.filter((el) => !excludedIds.includes(el.objectId)), old: excludedIds};
};

export const formatOrderLink = (userId?: string, orderId?: string, isStripeId?: string | null) => {
  const order = orderId ? `?orderId=${orderId}${isStripeId ? `&stripeConnectId=${isStripeId}` : ''}` : '';
  return `${route.messages.get({contactId: userId})}${order}`;
};

export const checkIsDifferentDay = (created1?: Date, created2?: Date) => {
  if (!created1 || !created2) return;
  const date1 = new Date(created1);
  const date2 = new Date(created2);
  return !(
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDay() === date2.getDay()
  );
};
export const toCreateLocalMessage = (
  text: string,
  localRef: string,
  contact?: TContact | null,
  viewer?: User,
): Message => {
  if (!viewer || !contact) return {};
  const contactAvatar = getClearAvatar(contact.avatar) || '';
  return {
    objectId: localRef,
    localRef: localRef,
    isLocal: true,
    Author: {
      objectId: viewer?.objectId,
      firstName: viewer?.firstName,
      lastName: viewer?.lastName,
      firstLetter: viewer?.firstLetter,
    },
    ShowTo: [
      {
        objectId: viewer?.objectId,
        firstName: viewer?.firstName,
        lastName: viewer?.lastName,
        firstLetter: viewer?.firstLetter,
        isOnline: true,
      },
      {
        objectId: contact.objectId || '',
        firstName: contact?.name,
        Avatar: {
          file: {
            name: contactAvatar,
            url: contactAvatar,
          },
          objectId: contactAvatar,
          mime: '',
          id: '',
        },
        lastName: '',
        firstLetter: '',
        isOnline: contact?.isOnline || false,
      },
    ],
    text: text,
    type: TypeMsg.personal,
    localStatus: MsgStatus.loading,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};
export const checkIsEmptyMessage = (message?: string) => {
  const woTags = message?.replace(/(<([^>]+)>)/gi, '');
  const formatted = woTags?.replaceAll(' ', '')?.replaceAll('\n', '');
  return !formatted?.length;
};

export const formatSettinsStripeLink = (isStripeId?: string | null) => {
  const params = isStripeId ? `?stripeConnectId=${isStripeId}` : '';
  return `${route.paymentsSettings.path}${params}`;
};

export const getClearAvatar = (avatar?: string) =>
  avatar?.replaceAll('.lg', '')?.replaceAll('.sm', '')?.replaceAll('.md', '');

export const checkLastOrderIsReady = (message?: Message) => {
  if (!message?.Order?.objectId) return true; // its text type message
  return !!message?.Order?.Lister && (!!message?.Order?.Item || !!message?.Order?.Event || !!message?.Order?.Amenity);
};
