import {useMutation} from '@apollo/client';
import {useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {CommunityField} from '../components/Dashboard/types';
import {useHasPlanOptionCall} from '../helpers/community';
import {
  managerDashboard,
  ManagerDashboardResponceType,
  residentDashboard,
  ResidentDashboardResponceType,
} from '../queries/dashboard';
import {residentDashboardFragment} from '../queries/fragments/dashboard';
import {currentCommunity} from '../states/community';

interface DashboardParams {
  startDate?: string;
  endDate?: string;
  locationId?: string;
}

export const useGetResidentDashboard = (options?: DashboardParams) => {
  const [getDashboard, {data, client, loading}] = useMutation<ResidentDashboardResponceType>(residentDashboard);

  useEffect(() => {
    getDashboard({
      variables: {
        startDate: options?.startDate,
        endDate: options?.endDate,
        locationId: options?.locationId,
      },
    });
  }, []);

  useEffect(() => {
    if (!data) return;
    client.writeFragment({
      id: 'ResidentDashboard',
      fragment: residentDashboardFragment,
      data: data.residentDashboard,
    });
  }, [data]);

  const cachedData = client.readFragment({
    id: 'ResidentDashboard',
    fragment: residentDashboardFragment,
  });

  const Data = data?.residentDashboard || cachedData || null;

  return {data: Data, loading};
};

export const useGetManagerDashboard = (options?: DashboardParams) => {
  const community = useRecoilValue(currentCommunity);
  const [getDashboard, {data, loading}] = useMutation<ManagerDashboardResponceType>(managerDashboard);

  useEffect(() => {
    if (!community?.objectId) return;
    getDashboard({
      variables: {
        id: community.objectId,
        startDate: options?.startDate,
        endDate: options?.endDate,
        locationId: options?.locationId,
      },
    });
  }, [community?.objectId]);

  const Data = data?.managerDashboard || null;

  return {data: Data, loading};
};

export const useGetFilterCommunityFields = (fields: Array<CommunityField>): Array<CommunityField> => {
  const isHasPlanOption = useHasPlanOptionCall();

  return fields.filter((el) => {
    if (!el.plan) return true;
    return isHasPlanOption(el.plan);
  });
};
