import React from 'react';
import {DashboardElement, IDashboard, render, THelpers} from '../types';
import {residentDashboard} from '../../../queries/types/dashboard';
import {CardsWrapper, DisabledWrapper, PageHeader, PageWrapper, Title} from '../styles';
import {Card} from '../../../ui-kit/Cards';
import {useTranslation} from 'react-i18next';
import {SelectTime} from '../SelectTime';
import {convertCentToDollar} from '../../../helpers/payment';
import {route} from '../../../constants/routes';
import {DashboardSkeletonSection} from '../../common/Skeletons/DashboardSkeleton';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../SEO';

type Field = DashboardElement<residentDashboard>;

const fields: Field[] = [
  {
    dataIndex: 'countListings',
    link: {
      title: 'postYourFirstItem',
      url: route.createItem.path,
    },
  },
  {
    dataIndex: 'countItemValue',
    render: 'value',
  },
  {
    dataIndex: 'countOwnTransactions',
  },
  {
    dataIndex: 'countOthersTransactions',
  },
  {
    dataIndex: 'countEarned',
    render: 'value',
  },
  {
    dataIndex: 'countSpent',
    render: 'value',
    link: {
      title: 'borrowItems',
      url: route.listings.path,
    },
  },
  {
    dataIndex: 'averageRatingReceived',
    render: 'stars',
  },
  {
    dataIndex: 'averageRatingGiven',
    render: 'stars',
  },
  {
    dataIndex: 'countInteractions',
  },
  {
    dataIndex: 'countListingViews',
  },
];

const renderValue: render<residentDashboard> = (data, el, {t, getSignValue}) => {
  const {dataIndex} = el;
  return (
    <Card
      value={getSignValue(convertCentToDollar(data[dataIndex]) || 0)}
      title={t(`dashboard:resident.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:resident.${el.link.title}`),
        }
      }
    />
  );
};

const renderStars: render<residentDashboard> = (data, el, {t}) => {
  const {dataIndex} = el;
  const value = data[dataIndex];
  if (typeof value !== 'number') return;

  return (
    <Card
      value={t('dashboard:render.star', {stars: value.toFixed(1)}) as string}
      title={t(`dashboard:resident.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:resident.${el.link.title}`),
        }
      }
    />
  );
};

const getRender = (data: residentDashboard, el: Field, helpers: THelpers) => {
  const {dataIndex, render} = el;
  const {t} = helpers;

  if (render === 'value') return renderValue(data, el, helpers);
  if (render === 'stars') return renderStars(data, el, helpers);
  if (typeof render === 'function') return render(data, el, helpers);
  return (
    <Card
      value={data[dataIndex]}
      title={t(`dashboard:resident.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:resident.${el.link.title}`),
        }
      }
    />
  );
};

export const Dashboard: React.FC<
  IDashboard & {
    data: residentDashboard;
    loading?: boolean;
  }
> = ({data, loading, getSignValue}) => {
  const {t} = useTranslation();
  const textTitle = t('dashboard:dashboard');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textTitle]);

  if (!data || loading) {
    return (
      <PageWrapper>
        <Seo title={textTitle} keywords={seoKeywords} />
        <PageHeader>
          <Title>{t('dashboard:dashboard')}</Title>
          <DisabledWrapper>
            <SelectTime />
          </DisabledWrapper>
        </PageHeader>
        <DashboardSkeletonSection />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Title>{t('dashboard:dashboard')}</Title>
        <DisabledWrapper>
          <SelectTime />
        </DisabledWrapper>
      </PageHeader>
      <CardsWrapper>{fields.map((el) => getRender(data, el, {t, getSignValue}))}</CardsWrapper>
    </PageWrapper>
  );
};
