import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {GreatingSuperResident} from '../../components/Auth/NoCommunity/GreatingSuperResident';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {useMutation, useQuery} from '@apollo/client';
import {route} from '../../constants/routes';
import {toStateCommunity} from '../../helpers/community';
import {
  CommunitiesResponseType,
  CreateCommunityForNewUserQuery,
  CreateCommunityForNewUserRequest,
  GetCommunityInfoRequests,
} from '../../queries/community';
import {currentCommunity} from '../../states/community';
import {CreateProfileSteps} from '../../types/auth';
import {useTranslation} from 'react-i18next';

export const NoCommunityContainer: React.FC = () => {
  const {t} = useTranslation();
  const {push} = useHistory();
  const [create, {loading}] = useMutation<CreateCommunityForNewUserRequest>(CreateCommunityForNewUserQuery);
  const [progress, setProgress] = useState<number>(0);
  const [community] = useRecoilState(currentCommunity);
  const setCommunity = useSetRecoilState(currentCommunity);
  const {refetch: getCommunity} = useQuery<CommunitiesResponseType>(GetCommunityInfoRequests, {skip: true});
  const forwardToOnboard = () => {
    push(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests}));
  };
  const label_loading_1 = t('auth:foundCommunity.cardLoader.label1');
  const label_loading_2 = t('auth:foundCommunity.cardLoader.label2');
  const label_loading_3 = t('auth:foundCommunity.cardLoader.label3');
  const label_loading_4 = t('auth:foundCommunity.cardLoader.label4');
  const label_loading_5 = t('auth:foundCommunity.cardLoader.label5');
  const [label, setLabel] = useState<string>(label_loading_1);

  useEffect(() => {
    if (progress >= 10 && progress < 30 && label != label_loading_2) {
      setLabel(label_loading_2);
    }
    if (progress >= 30 && progress < 50 && label != label_loading_3) {
      setLabel(label_loading_3);
    }
    if (progress >= 70 && progress < 90 && label != label_loading_4) {
      setLabel(label_loading_4);
    }
    if (progress >= 90 && progress < 99 && label != label_loading_5) {
      setLabel(label_loading_5);
    }
    if (progress >= 99) setLabel(t('auth:foundCommunity.superResident.greating.label'));
  }, [progress]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    const createNew = async () => {
      id = setInterval(() => {
        setProgress((prevState) => prevState + 1);
      }, 500);
      try {
        if (!community) {
          const res = await create();
          if (!res?.data?.createCommunityForNewUser?.communityId) return;
          const newCommunity = await getCommunity({
            where: {objectId: {equalTo: res?.data?.createCommunityForNewUser?.communityId}},
          });
          if (!newCommunity?.data?.communities?.edges?.[0]?.node?.objectId) return;
          setCommunity(toStateCommunity(newCommunity?.data?.communities?.edges?.[0]?.node));
        }
      } catch (e) {
        console.log(e);
      } finally {
        clearInterval(id);
        setProgress(100);
      }
    };
    createNew();
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <WrapperAuth>
      <GreatingSuperResident onOk={forwardToOnboard} label={label} loading={loading} progress={progress} />
    </WrapperAuth>
  );
};
