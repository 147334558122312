import React, {useEffect, useState} from 'react';
import {
  SeparatedInputOption,
  SeparatedSelectLabel,
  SeparatedSelectOptionsWrapper,
  SeparatedSelectWrapper,
} from './styles';

export interface Options {
  label?: string;
  value?: string;
}

interface SeparatedSelectProps {
  options: Options[];
  value?: Options;
  onChange?: (next: Options) => void;
  label?: string;
  view?: string;
  setView?: (val: string) => void;
  loading?: boolean;
}

export const SeparatedSelect: React.FC<SeparatedSelectProps> = ({
  options,
  value,
  onChange,
  label,
  view,
  setView,
  loading,
}) => {
  const [initLoading, setInitLoading] = useState(true);
  const handleChange = (current: Options) => () => {
    if (loading && !initLoading) return;
    onChange?.(current);
    if (current?.value && view !== current?.value) setView?.(current?.value);
  };

  useEffect(() => {
    if (loading) setInitLoading(false);
  }, [loading]);

  useEffect(() => {
    if (view) {
      const findedOption = options?.find((el) => el?.value === view);
      findedOption && handleChange(findedOption)();
    }
  }, [!!view]);
  return (
    <SeparatedSelectWrapper $isLoading={loading}>
      {label && <SeparatedSelectLabel>{label}</SeparatedSelectLabel>}
      <SeparatedSelectOptionsWrapper>
        {options.map((o, i) => (
          <SeparatedInputOption
            key={String(o.value) + i}
            selected={o.label === value?.label && o.value === value?.value}
            onClick={handleChange(o)}>
            {o.label}
          </SeparatedInputOption>
        ))}
      </SeparatedSelectOptionsWrapper>
    </SeparatedSelectWrapper>
  );
};
