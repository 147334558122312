import {ParseObject, Connection} from './parse';
import {MsgStatus, TOrder, UserChat} from '../../types/messages';
import {User} from './user';
import {PointerFile} from '../../types/common';

export enum TypeMsg {
  hangeh = 'hangeh',
  personal = 'personal',
  notification = 'notification',
  internalNote = 'internalNote',
}

export interface Message extends ParseObject {
  Order?: TOrder;
  Author?: Partial<UserChat>;
  ShowTo?: Array<UserChat | undefined>;
  text?: string;
  type?: TypeMsg;
  Attachments?: Array<PointerFile | undefined>;
  Seen?: Array<UserChat | undefined>;
  Chat?: TChat;
  localRef?: string;
  isLocal?: boolean;
  localStatus?: MsgStatus;
}

export interface TChat extends ParseObject {
  status: EChatStatus;
  isArchived: boolean;
  Messages: Connection<Message>;
  Members: Connection<User>;
}

export enum EChatStatus {
  open = 'open',
  close = 'close',
}
