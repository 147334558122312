import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {VerifyBase} from './VerifyBase';

interface TProps {
  onSubmit: (code: string) => void;
  onCancel: () => void;
  resendCode: () => void;
  error?: boolean;
  loading?: boolean;
}

export const VerifyPhoneCode: React.FC<TProps> = ({onSubmit, resendCode, error, loading, onCancel}) => {
  const {t} = useTranslation();
  const [code, setCode] = useState('');
  const handleChange = (value: string) => setCode(value);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (code.length > 0) {
      onSubmit(code);
    }
  };
  const handleInputAction = () => resendCode();

  return (
    <VerifyBase
      title={t('auth:verification.code.title')}
      subtitle={t('auth:verification.code.subtitle')}
      inputLabel={t('auth:verification.code.label')}
      inputActionText={t('auth:verification.code.inputAction')}
      buttonText={t('auth:verification.code.button')}
      errorMessage={error ? t('auth:verification.code.error') : undefined}
      value={code}
      loading={loading}
      onChange={handleChange}
      onClick={handleClick}
      inputActionHandler={handleInputAction}
      onCancel={onCancel}
    />
  );
};
