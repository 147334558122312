import {ApolloClientOptions, InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {Connection} from '../queries/types/parse';
import {Post} from '../queries/types/post';

export const apolloClientCache: ApolloClientOptions<NormalizedCacheObject>['cache'] = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        posts: {
          keyArgs: ['where'],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          merge(existing: Connection<Post>, incoming: Connection<Post>, {args: {skip = 0}}): Connection<Post> {
            const merged = existing?.edges ? existing?.edges.slice(0) : [];
            for (let i = 0; i < incoming?.edges?.length; ++i) {
              if (merged) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                merged[skip + i] = incoming?.edges?.[i];
              }
            }
            return {...incoming, edges: merged};
          },
        },
        community: {
          merge: true,
        },
      },
    },
    Community: {
      merge: true,
      fields: {
        Residents: {
          keyArgs: ['where'],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          merge(existing: Connection<Post>, incoming: Connection<Post>, {args}): Connection<Post> {
            const skip = args?.skip || 0;
            const merged = existing?.edges ? existing?.edges.slice(0) : [];
            for (let i = 0; i < incoming?.edges?.length; ++i) {
              if (merged) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                merged[skip + i] = incoming?.edges?.[i];
              }
            }
            return {...incoming, edges: merged};
          },
        },
      },
    },
  },
});
