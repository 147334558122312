import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useGetInterests} from '../../../hooks/interests';
import {FormEditType, FormType, TInterestsRecmndsValues} from '../../../types/settings';
import {Input} from '../../../ui-kit/Form/Input';
import {Text} from '../../../ui-kit/Typography/Text';
import {Box} from '../../common/Box';
import {PressButton} from '../../../ui-kit/Button/PressButton';
import {ContentWrapper, SectionWrapper, SectionWrapperForm} from '../commonStyles';
import {ErrorMessage, FormButtons, SettingsEntry, SettingsSubsection} from '../components';
import {IconSvg} from '../../../ui-kit/ProfileIntro/styles';
import {TInterest, TRecommendantions} from '../../../types/interests';
import {icon} from '../../../ui-kit/Icon/Svg/types';

interface ISettingsInterests {
  edit: FormEditType;
  values: TInterestsRecmndsValues;
  form: FormType<TInterestsRecmndsValues>;
  canEdit: boolean;
}

export const SettingsInterests: React.FC<ISettingsInterests> = ({edit, values, form, canEdit}) => {
  const {isEditing, handleEditStart, handleEditEnd} = edit;
  return isEditing ? (
    <InterestsForm cancel={handleEditEnd} values={values} form={form} />
  ) : (
    <InterestsInfo edit={handleEditStart} values={values} canEdit={canEdit} />
  );
};

interface IInterestsInfo {
  edit: () => void;
  values: TInterestsRecmndsValues;
  canEdit: boolean;
}

const InterestsInfo: React.FC<IInterestsInfo> = ({values, edit, canEdit}) => {
  const {t} = useTranslation();
  const {options} = useGetInterests();

  const {personInterests, recommendations} = values;
  const {eatery, movies, places} = recommendations || {};

  const [clicked, setClicked] = useState<boolean>(false);

  const handleEdit = () => {
    setClicked(true);
    if (canEdit) edit();
    else setTimeout(() => setClicked(canEdit), 3000);
  };

  const selectedIntereses = useMemo(
    () => options.filter((el) => personInterests?.includes(el.value)),
    [options, personInterests],
  );

  return (
    <>
      <SectionWrapper>
        <SettingsSubsection
          label={t('settings:personal.interests.title')}
          value={t('settings:personal.interests.subtitle')}
          onClick={handleEdit}
        />
        <Box margin="10px 0">
          <Text size={14} color={'octonary'} weight={'medium'}>
            {t('settings:personal.interests.firstLabel')}
          </Text>
        </Box>
        <Box display="flex" margin="10px 0" gap="10px" wrap>
          {!!selectedIntereses.length ? (
            selectedIntereses.map((el) => <PressButton label={el.label} key={el.value} disabled />)
          ) : (
            <Text variant={'tertiary'} color={'quinary'} size={16}>
              {t('settings:personal.notSpecified')}
            </Text>
          )}
        </Box>
        <SettingsEntry label={t('auth:recmdsLabels.eatery')} onClick={handleEdit} value={eatery} />
        <SettingsEntry label={t('auth:recmdsLabels.movies')} onClick={handleEdit} value={movies} />
        <SettingsEntry label={t('auth:recmdsLabels.places')} onClick={handleEdit} value={places} />
        {!canEdit && clicked && <ErrorMessage error={'settings:personal.saveOrCancel'} />}
        <FormButtons variant={'edit'} onClick={handleEdit} />
      </SectionWrapper>
    </>
  );
};

interface IInterestsForm {
  cancel: () => void;
  values: TInterestsRecmndsValues;
  form: FormType<TInterestsRecmndsValues>;
}

const InterestsForm: React.FC<IInterestsForm> = ({form, cancel}) => {
  const {t} = useTranslation();
  const {options} = useGetInterests();

  const {onSubmit, values, onChange, error} = form;
  const {recommendations, personInterests} = values;
  const {eatery, movies, places} = recommendations || {};

  const handleSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    onSubmit();
  };
  const handleChange = (key: keyof TRecommendantions) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({name: 'recommendations', value: {...recommendations, [key]: e.target.value}});
  };
  const handleSelect = (value: TInterest) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      name: 'personInterests',
      value: e.target.checked
        ? [...(personInterests || []), value]
        : (personInterests || []).filter((el) => el !== value),
    });
  };

  return (
    <>
      <SectionWrapperForm onSubmit={handleSubmit}>
        <SettingsSubsection
          label={t('settings:personal.interests.title')}
          value={t('settings:personal.interests.subtitle')}
        />
        <Box margin="10px 0">
          <Text size={14} color={'octonary'} weight={'medium'}>
            {t('settings:personal.interests.firstLabel')}
          </Text>
        </Box>
        <Box display="flex" margin="10px 0" wrap gap="10px">
          {options.map((el) => (
            <PressButton
              label={el.label}
              key={el.value}
              isChecked={personInterests?.includes(el.value)}
              icon={el.value as icon}
              onChange={handleSelect(el.value)}
            />
          ))}
        </Box>
        <Box margin="0 0 18px 0">
          <Text size={14} color={'octonary'} weight={'medium'}>
            {t('settings:personal.interests.secondLabel')}
          </Text>
        </Box>
        <Box display="flex" gap="16px" direction="column">
          <Input
            label={t('auth:recmdsLabels.eatery')}
            icon={<IconSvg type="coffee" stroke="grayLight-2" />}
            onChange={handleChange('eatery')}
            value={eatery}
          />
          <Input
            label={t('auth:recmdsLabels.movies')}
            icon={<IconSvg type="tv" stroke="grayLight-2" />}
            onChange={handleChange('movies')}
            value={movies}
          />
          <Input
            label={t('auth:recmdsLabels.places')}
            icon={<IconSvg type="car-alt" stroke="grayLight-2" />}
            onChange={handleChange('places')}
            value={places}
          />
        </Box>
        <ContentWrapper>
          <ErrorMessage error={error.message} />
        </ContentWrapper>
        <FormButtons variant={'update'} disabled={form.loading} loading={form.loading} onCancel={cancel} />
      </SectionWrapperForm>
    </>
  );
};
