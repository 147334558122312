import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useViewer} from '../../hooks/user';
import {PostPage} from '../../components/Feed/PostPage';
import {usePinUnpinPost, useGetPost, useGetPostMenuActions, usePostActions, useGetPostPollInfo} from '../../hooks/feed';
import {useRecoilState} from 'recoil';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {currentCommunity} from '../../states/community';
import {useGetAdminsLabels} from '../../hooks/people';
import {GQLPollOptionOrder} from '../../graphql.schema';
import {toStatePosts} from '../../helpers/feed';
import {analyticsTrackFN} from '../../helpers/account';
import {entities, useReport} from '../../hooks/report';

export const ViewPost = () => {
  const {id} = useParams<{id?: string}>();
  const viewer = useViewer();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const {data, refetch, loading} = useGetPost({
    postId: id,
    userId: viewer?.objectId,
    optionsOrder: GQLPollOptionOrder.position_ASC,
  });
  const {getPollInformation} = useGetPostPollInfo({userId: viewer?.objectId});

  const getPollData = async (id: string) => {
    const result = await getPollInformation(id);
    return toStatePosts(result?.data?.posts)?.[0]?.Options?.map((item) => item.Voted);
  };

  useEffect(() => {
    if (data?.objectId)
      analyticsTrackFN('Post Viewed', {
        postId: data?.objectId,
        type: data?.type, // select from the options: poll, post, notice, request etc.
        content: data?.text,
        sentAs: data?.sentAs, // resident, manager, community
        viewedByName: `${viewer?.firstName}`,
        viewedByEmail: viewer?.email,
        viewedById: viewer?.objectId, // objectID or UID of user
        authorName: `${data?.Author?.firstName}`,
        authorEmail: data?.Author?.email,
        authorId: data?.Author?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle
      });
  }, [data?.objectId]);

  const menuActions = useGetPostMenuActions();
  const postActions = usePostActions();
  const reportItemActions = useReport(entities.listing);
  const pinUnpin = usePinUnpinPost({onSucces: refetch});
  const adminLabels = useGetAdminsLabels();
  return (
    <PostPage
      getPollData={getPollData}
      viewer={viewer}
      postData={data}
      refetch={refetch}
      typeUser={typeUser}
      community={community}
      postActions={postActions}
      menuActions={menuActions}
      loading={loading}
      pinUnpin={pinUnpin}
      adminLabels={adminLabels}
      reportItemAction={reportItemActions}
    />
  );
};
