import React, {ReactNode} from 'react';
import {Icon, MainText, SubText, Wrapper} from './styles';
import {useTranslation} from 'react-i18next';

type TProps = {
  mainText?: string;
  ElemSubText?: ReactNode;
  maxWidth?: string;
  showIcon?: boolean;
};

export const EmptyState: React.FC<TProps> = ({mainText, ElemSubText, maxWidth, showIcon = true}) => {
  const {t} = useTranslation();
  const _mainText = mainText ?? t('common:emptyState.default.mainText');
  const subtextText = ElemSubText ?? t('common:emptyState.default.subText');

  return (
    <Wrapper maxWidth={maxWidth}>
      {showIcon && <Icon />}
      <MainText>{_mainText}</MainText>
      <SubText>{subtextText}</SubText>
    </Wrapper>
  );
};
