import React, {useEffect, useMemo} from 'react';
import {useRecoilValue} from 'recoil';

import {PageDocuments} from '../../components/Documents/PageDocuments';
import {userPermissions} from '../../states/community';
import {typeUser as typeUserState} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {PermissionsList} from '../../types/settings';
import {EmptyTag, MobContainerProps} from '../MobileTabs';
import {NavContainer} from '../Navigation';

import {useActionOnDocument, useGetDocuments} from '../../hooks/document';

export const DocumentsContainer: React.FC<MobContainerProps> = ({hideNav}) => {
  const userType = useRecoilValue(typeUserState);
  const permissions = useRecoilValue(userPermissions);

  const {data, loading, refetch} = useGetDocuments();
  const actionsData = useActionOnDocument({
    onSuccess: refetch,
  });

  const canEdit = useMemo(
    () =>
      (permissions?.includes(PermissionsList.manageDocument) && userType === TypeCommunity.admin) ||
      userType === TypeCommunity.manager,
    [permissions, userType],
  );

  useEffect(() => {
    const interval = setInterval(() => refetch(), 5000);
    return () => clearInterval(interval);
  }, []);
  const Wrapper = hideNav ? EmptyTag : NavContainer;

  return (
    <Wrapper>
      <PageDocuments items={data} loading={loading} actionsData={actionsData} canEdit={canEdit} />
    </Wrapper>
  );
};
