import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {routes} from '../../../containers/Navigation/constans';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../../states/typeUser';
import {SettingsPortal} from './styles';
import {SettingsPopup} from '../../Account/Popups/Settings/Settings';
import {userPermissions} from '../../../states/community';
import {PermissionsList} from '../../../types/settings';
import {TypeCommunity} from '../../../types/auth';
import {useHasPlanOption} from '../../../helpers/community';
import {PlanOptionsValues} from '../../../constants/plan';
import {useGetDocuments} from '../../../hooks/document';
import {useLinks} from '../../../hooks/common';

export const SettingsMobileButton: React.FC<{
  handleSettingsClick: (e: React.SyntheticEvent) => void;
  isActive: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
}> = ({isActive, setActive}) => {
  const [type] = useRecoilState(typeUser);
  const {
    personalSettings,
    accountSettings,
    communitySettings,
    preferencesSettings,
    paymentsSettings,
    inviteCommunity,
    createCommunity,
    addListing,
    searchCommunity,
    events,
    localBiz,
    groups,
    documents,
    helpDesk,
    amenities,
    dashboard,
    referrals,
  } = routes;
  const {count: documentsCount} = useGetDocuments();
  const [permissions] = useRecoilState(userPermissions);
  const hasInsights = permissions?.includes(PermissionsList.dashboardAccess) || type === TypeCommunity.manager;
  const hasEvents = useHasPlanOption(PlanOptionsValues.events);
  const hasComapnies = useHasPlanOption(PlanOptionsValues.company);
  const hasGroups = useHasPlanOption(PlanOptionsValues.groups);
  const hasDocuments =
    useHasPlanOption(PlanOptionsValues.communityDocuments) &&
    !(documentsCount === 0 && type === TypeCommunity.resident);
  const hasHelpDesk = useHasPlanOption(PlanOptionsValues.helpDesk);
  const hasBookings = useHasPlanOption(PlanOptionsValues.amenities);
  const {getLink} = useLinks();

  const settingsLinks = useMemo(() => {
    return {
      referrals: getLink(referrals),
      event: hasEvents ? getLink(events) : '',
      localBiz: hasComapnies ? getLink(localBiz) : '',
      groups: hasGroups ? getLink(groups) : '',
      bookings: hasBookings ? getLink(amenities) : '',
      documents: hasDocuments ? getLink(documents) : '',
      insights: hasInsights ? getLink(dashboard) : '',
      helpDesk: hasHelpDesk ? getLink(helpDesk) : '',
      community: getLink(communitySettings),
      profile: getLink(personalSettings),
      payments: getLink(paymentsSettings),
      preferences: getLink(preferencesSettings),
      accountSettings: getLink(accountSettings),
      invite: getLink(inviteCommunity),
      addListing: getLink(addListing),
      searchCommunity: searchCommunity,
      createCommunity: createCommunity,
    };
  }, [hasEvents, hasComapnies, hasGroups, hasBookings, hasDocuments, hasInsights, hasHelpDesk]);
  return (
    <>
      <SettingsPortal $isOpened={isActive}>
        <SettingsPopup type={type} active={isActive} setActive={setActive} links={settingsLinks} />
      </SettingsPortal>
    </>
  );
};
