import {route} from '../../constants/routes';

export const routes = {
  loop: route.loop.get(),
  searchCommunity: route.searchCommunityResident.get(),
  profile: route.pageUser.get(),
  listings: route.listings.get(),
  people: route.people.get(),
  dashboard: route.dashboard.get(),
  messages: route.messages.get({contactId: ' '}),
  addListing: route.createItem.get(),
  personalSettings: route.personalSettings.get(),
  accountSettings: route.accountSettings.get(),
  communitySettings: route.communitySettings.get(),
  preferencesSettings: route.preferencesSettings.get(),
  paymentsSettings: route.paymentsSettings.get(),
  inviteCommunity: route.inviteCommunity.get(),
  createCommunity: route.selectPlan.get(),
  documents: route.documents.get(),
  events: route.events.get(),
  amenities: route.amenities.get(),
  helpDesk: route.helpDesk.get(),
  groups: route.groups.get(),
  localBiz: route.localBiz.get(),
  verifyUser: route.verifyUser.get(),
  referrals: route.referrals.get(),
};
