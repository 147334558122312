import React from 'react';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

import {
  Address,
  Description,
  FooterCard,
  InnerCard,
  InnerIcon,
  InnerTitle,
  JoinButton,
  JoinButtonNotLink,
  Members,
  Title,
} from './styles';
import {AccessType} from '../../../constants/community';
import {useTranslation} from 'react-i18next';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';
import {TypeCommunity} from '../../../types/auth';
import {setCurrentCommunity as setCurrentCommunityInLS} from '../../../helpers/community';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {currentCommunity} from '../../../states/community';
import {typeUser} from '../../../states/typeUser';
import {useHistory} from 'react-router-dom';
import {TCommunity} from '../../../types/community';
import {useViewer} from '../../../hooks/user';
import {RichText} from '../../common/RichText/RichText';

type TProps = {
  id?: string;
  userId?: string;
  name?: string;
  city?: string;
  state?: string;
  country?: string;
  descr?: string;
  accessType?: string;
  countMembers?: number;
  joinRequestStatus?: string;
  community: Partial<TCommunity>;
};

enum JoinRequestTypes {
  Approved = 'approved',
  Pending = 'pending',
}

export const SearchCard: React.FC<TProps> = ({
  id,
  name,
  city,
  country,
  descr,
  accessType,
  countMembers,
  joinRequestStatus,
  community,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {push} = useHistory();
  const isPrivate = accessType === AccessType.private.toLowerCase();
  const [currentCom, setCurrentCom] = useRecoilState(currentCommunity);
  const setTypeUser = useSetRecoilState(typeUser);
  const viewer = useViewer();

  const isCurrent = currentCom?.objectId === community.objectId;

  const goToCommunity = async () => {
    await setCurrentCom(community);
    setTypeUser(community?.Owner?.objectId === viewer?.objectId ? TypeCommunity.manager : TypeCommunity.resident);
    community?.objectId && setCurrentCommunityInLS(community.objectId);
    push(getLink(route.loop.get(), community?.alias));
  };
  return (
    <InnerCard to={getLink(route.joinCommunity.get({id: id || ''}))}>
      <InnerTitle $isPrivate={isPrivate}>
        <Title>{name}</Title>
        <InnerIcon $isPrivate={isPrivate}>
          <IconSvg type={'lock-alt'} />
        </InnerIcon>
      </InnerTitle>
      <Address>
        {city}, {country}
      </Address>
      <Description>
        <RichText html={descr} noLinkify={true} disableShowMore={true} />
      </Description>
      <FooterCard>
        <Members>
          {countMembers || 0} {t('community:search.label.member', {count: countMembers})}
        </Members>
        {joinRequestStatus === JoinRequestTypes.Approved ? (
          isCurrent ? (
            <JoinButton to={getLink(route.loop.path)}>{t('community:search.buttons.view')}</JoinButton>
          ) : (
            <JoinButtonNotLink onClick={goToCommunity}>{t('community:search.buttons.view')}</JoinButtonNotLink>
          )
        ) : joinRequestStatus === JoinRequestTypes.Pending ? (
          <JoinButton to={getLink(route.joinCommunity.get({id: id || ''}))} pending={true}>
            {t('community:search.buttons.pending')}
          </JoinButton>
        ) : isPrivate ? (
          <JoinButton to={getLink(route.joinCommunity.get({id: id || ''}))}>
            {t('community:search.buttons.request')}
          </JoinButton>
        ) : (
          <JoinButton to={getLink(route.joinCommunity.get({id: id || ''}))}>
            {t('community:search.buttons.join')}
          </JoinButton>
        )}
      </FooterCard>
    </InnerCard>
  );
};
