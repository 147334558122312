import React from 'react';
import Infinite from 'react-infinite-scroller';

type Props = {
  loadMore: () => void;
  hasMore: boolean;
  useWindow?: boolean;
  loader: React.ReactElement;
  pageStart?: number;
  initialLoad?: boolean;
  getScrollParent?(): HTMLDivElement | null;
  threshold?: number;
};

export const InfiniteScroll: React.FC<Props> = ({
  children,
  pageStart,
  initialLoad,
  hasMore,
  loadMore,
  useWindow,
  loader,
  getScrollParent,
  threshold = 2000,
  ...props
}) => {
  return (
    <Infinite
      initialLoad={initialLoad || false}
      pageStart={pageStart}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}
      useWindow={useWindow}
      getScrollParent={getScrollParent}
      threshold={threshold}
      {...props}>
      {children}
    </Infinite>
  );
};
