import React from 'react';
import {TermsLink} from './styles';
import {useTranslation} from 'react-i18next';
import {termsLinks} from '../../constants/links';

export const TermsLinks = () => {
  const {t} = useTranslation();
  return (
    <>
      <TermsLink href={termsLinks.Service}>{t('common:terms.links.service')}</TermsLink> {t('common:and')}
      <TermsLink href={termsLinks.PrivacyPolicy}>{t('common:terms.links.privacyPolicy')}</TermsLink>
    </>
  );
};
