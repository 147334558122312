import styled from 'styled-components';

import {Button} from '../../ui-kit/Button/Button';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {Media} from '../../ui-kit/theme';
import {fontPrimaryReg} from '../common/styles';

export const PageWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 15px;
  box-sizing: border-box;
  ${Media.down.l} {
    padding: 1px 10px 50px 10px;
  }
`;

export const HelpDeskWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  width: 100%;
  justify-content: space-between;
  min-height: 400px;
  ${Media.down.l} {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;
export const LeftSide = styled.div`
  width: 67%;
  box-sizing: border-box;
  ${Media.down.l} {
    width: 100%;
  }
`;
export const RightSide = styled.div`
  width: 30%;
  box-sizing: border-box;
  ${Media.down.l} {
    width: 100%;
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
`;

export const HelpItemWrapper = styled.div`
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  padding: 13px 50px 14px 18px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  margin-bottom: 16px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  color: ${({theme}) => theme.palette.grayscale._6};
  position: relative;
  width: 100%;
  ${fontPrimaryReg};
  ${Media.down.m} {
    margin-bottom: 8px;
  }
`;

export const HelpItemTitle = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HelpItemContent = styled.div<{$isOpened?: boolean}>`
  max-height: ${({$isOpened}) => ($isOpened ? 'unset' : '0px')};
  overflow: hidden;
  transition: 0.2s all;
`;

export const HelpItemContentText = styled.div`
  padding-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  p {
    padding: 4px 0;
    margin: 4px 0;
  }
`;

export const QuoteWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 19px 20px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  margin-bottom: 10px;
  position: relative;
  ${Media.down.l} {
    width: 100%;
  }
  ${fontPrimaryReg};
`;

export const QuoteTitle = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${Media.down.l} {
    font-size: 14px;
  }
`;

export const QuoteLabelWrapper = styled.div`
  & > span {
    margin-left: 0px;
  }
  margin-bottom: 10px;
`;

export const QuoteText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  font-style: italic;
  color: ${({theme}) => theme.palette.common.aqua};
  margin-top: 20px;
  ${Media.down.l} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const QuoteAuthor = styled.div`
  font-size: 12px;
  margin-top: 18px;
  color: ${({theme}) => theme.palette.grayscale._5};
`;

export const DropDownBtn = styled.button<{$isOpened?: boolean}>`
  display: flex;
  background: transparent;
  outline: none;
  border: none;
  width: 30px;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  transition: 0.2s all;
  transform: rotate(0deg);
  ${({$isOpened}) => $isOpened && 'transform: rotate(180deg);'};
`;

export const CloseMobBtn = styled.button`
  display: none;
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  ${Media.down.l} {
    display: flex;
  }
`;

export const HelpItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const DragBtn = styled.button`
  display: block;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  left: -35px;
  top: 15px;
`;

export const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.down.l} {
    display: none;
  }
`;

export const AddButton = styled(Button)`
  .button-value {
    display: flex;
    align-items: center;
  }
`;

export const FormWrapper = styled.div`
  padding: 28px 26px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
`;

export const InnerItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    width: 48%;
  }
`;

export const MobFocused = styled.div<{$isDesktop: boolean}>`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
  .rt-editor {
    padding-right: ${({$isDesktop}) => ($isDesktop ? '38px' : null)} !important;
  }
  ${Media.down.m} {
    & .rt-editor .DraftEditor-root {
      height: 100% !important;
      & .public-DraftEditor-content > div {
        height: 100%;
      }
    }
  }
`;

export const FormRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const FormActions = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 20px;
  }
`;

export const ExtLink = styled.a`
  width: auto;
  display: inline-block;
  height: auto;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  padding: 8px 19px 8px 19px;
  position: relative;
  overflow: initial;
  background-color: ${({theme}) => theme.palette.button.primary};
  color: ${({theme}) => theme.palette.grayscale._0};
  text-align: center;
`;

export const LinkBtn = styled(LinkButton)`
  padding: 8px 19px 8px 19px;
  text-align: center;
  display: inline-block;
  width: auto;
`;

export const PublishLabel = styled.div`
  right: 40px;
  top: 14px;
`;

export const LoaderBlock = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
`;

export const InnnerHelpDeskCardBtns = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:last-child {
    margin-left: 6px;
  }
  z-index: 100;
`;

export const MenuButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  pointer-events: all;

  & > div {
    & > div {
      max-width: 300px;
      min-width: 145px;
      z-index: 1000;
      margin: 0 auto;
      ${Media.down.s} {
        max-width: 100%;
        margin-top: 60px;
      }
    }
  }

  & button {
    padding: 0 0 0 14px;
  }
`;

export const FormEntity = styled.div``;

export const SkeletonList = styled.div``;

export const SkeletonItem = styled.div`
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  padding: 13px 50px 14px 18px;
  box-sizing: border-box;
  line-height: 26px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  margin-bottom: 16px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
