import {Link} from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import TextareaAutosize from 'react-textarea-autosize';
import styled, {css} from 'styled-components';

import {Media, theme} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Text';
import {fontPrimaryMedium, fontPrimaryReg} from '../../common/styles';

const postPaddingLeftStyles = css`
  padding-left: 96px;
`;

const scrollBarStyles = css`
  scrollbar-color: ${({theme}) => theme.palette.background.tertiary} ${({theme}) => theme.palette.background.denary};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.background.tertiary};
    border-radius: 9em;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.background.octonary};
  }
`;

const partsPaddingStyles = css`
  padding-left: 26px;
  padding-right: 39px;
  ${Media.down.s} {
    padding-left: 14px;
    padding-right: 4px;
  }
`;

const postContainerStyles = css`
  display: block;
  color: unset;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  user-select: text;
  ${theme.font.primary.regular};
  ${Media.down.m} {
    margin-top: 6px;
    margin-bottom: 10px;
  }
`;

export const PostContainer = styled.div<{
  $noBorder?: boolean;
  $isComment?: boolean;
  $isAnnouncement?: boolean;
  $isDeleted?: boolean;
  $isRequest?: boolean;
}>`
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border: ${({$noBorder, theme}) => ($noBorder ? `none` : `1px solid ${theme.palette.grayscale._11}`)};
  ${postContainerStyles};
  cursor: auto;

  ${({$isComment}) => $isComment && commentContainerStyles};
  ${({$isAnnouncement}) => $isAnnouncement && announcementContainerStyles};
  ${({$isDeleted}) => $isDeleted && deletedContainerStyles}
  ${({$isRequest}) => $isRequest && requestContainerStyles};
`;

export const PostContainerNoLink = styled.div<{
  $noBorder?: boolean;
  $isComment?: boolean;
  $isAnnouncement?: boolean;
  $isDeleted?: boolean;
  $isRequest?: boolean;
}>`
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border: ${({$noBorder, theme}) => ($noBorder ? `none` : `1px solid ${theme.palette.grayscale._11}`)};
  ${postContainerStyles};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
  }
  ${({$isComment}) => $isComment && commentContainerStyles};
  ${({$isAnnouncement}) => $isAnnouncement && announcementContainerStyles};
  ${({$isRequest}) => $isRequest && requestContainerStyles};
  ${({$isDeleted}) => $isDeleted && deletedContainerStyles}
`;

export const PostContainerLink = styled(Link)<{
  $noBorder?: boolean;
  $isComment?: boolean;
  $isAnnouncement?: boolean;
  $isDeleted?: boolean;
}>`
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border: ${({$noBorder, theme}) => ($noBorder ? `none` : `1px solid ${theme.palette.grayscale._11}`)};
  ${postContainerStyles};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
  }
  ${({$isComment}) => $isComment && commentContainerStyles};
  ${({$isAnnouncement}) => $isAnnouncement && announcementContainerStyles};
  ${({$isDeleted}) => $isDeleted && deletedContainerStyles}
`;

const deletedContainerStyles = css`
  display: none;
`;

const announcementContainerStyles = css`
  background-color: ${({theme}) => theme.palette.common.yellowLight};
`;
const requestContainerStyles = css`
  background-color: ${({theme}) => theme.palette.common.blueLight};
`;

const commentContainerStyles = css`
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${({theme}) => theme.palette.grayscale._3};
  border-radius: 0;
  border: none;
  border-bottom: ${({theme}) => `1px solid ${theme.palette.grayscale._11}`};
  &:first-of-type {
    border-top: ${({theme}) => `1px solid ${theme.palette.grayscale._11}`};
  }
  & .post__wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  & .post__top-part {
    margin-left: -30px;
    ${Media.down.m} {
      margin-left: 0px;
    }
  }
  & .post__actions-line {
    margin-top: 6px;
    padding-left: 144px;
    ${Media.down.m} {
      ${partsPaddingStyles};
      padding-right: 25px;
    }
  }
`;

export const PostWrapper = styled.div.attrs({className: 'post__wrapper'})`
  padding-top: 26px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  ${Media.down.s} {
    padding-top: 14px;
    padding-bottom: 14px;
  }
`;

export const PostTopPart = styled.div.attrs({className: 'post__top-part'})`
  display: flex;
  position: relative;
  ${partsPaddingStyles};
  ${Media.down.m} {
    padding-right: 25px;
  }
`;

export const PostBottomPart = styled.div``;

export const PostActions = styled.div.attrs({className: 'post__actions-line'})`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px 0;
  margin-top: 17px;
  align-items: center;

  ${postPaddingLeftStyles};

  ${Media.down.m} {
    ${partsPaddingStyles};
    padding-right: 2px;
    margin-top: 6px;
    gap: 8px 0;
  }

  & > div:first-child {
    //flex-basis: 100%;
    flex-basis: unset;
  }
`;

export const PostTranslatedFrom = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 0;
  margin-top: 17px;

  ${postPaddingLeftStyles};

  ${Media.down.m} {
    ${partsPaddingStyles};
    padding-right: 2px;
    margin-top: 6px;
    gap: 8px 0;
  }

  & > span:first-child {
    //flex-basis: 100%;
    flex-basis: unset;
    margin-right: 10px;
  }
`;

export const PostActionsLeft = styled.div`
  display: flex;
  & > div {
    margin-right: 19px;
  }
`;
export const PostActionsRight = styled.div`
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;

  & :first-child {
    padding-right: 0;
  }
`;

export const PostFeed = styled.div<{$isRecs?: boolean}>`
  width: auto;
  max-width: 950px;
  margin: 0 auto;
  ${({$isRecs}) =>
    $isRecs &&
    `
     & > div {
      display: grid;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 15px;
      margin-top: 15px;
      ${Media.down.l} {
        grid-template-columns: repeat(2, 1fr);
      }
      ${Media.down.m} {
        grid-template-columns: repeat(1, 1fr);
      }
     }
    `}
`;

export const PostLeftSide = styled.div<{$isComment?: boolean; $nonClickable?: boolean}>`
  display: ${({$isComment}) => (!$isComment ? `block` : 'none')};
  width: 60px;
  margin-right: 20px;
  ${Media.down.m} {
    display: none;
    width: 0;
    margin-right: 0;
  }
  & > a {
    cursor: ${({$nonClickable}) => ($nonClickable ? 'auto' : 'pointer')};
  }
`;

export const PostRightSide = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

export const PostHeadLine = styled.div<{isComment?: boolean}>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  ${({isComment}) =>
    isComment &&
    `
  padding-left: 102px;
  ${Media.down.m} {
    padding-left: 0;
  }
  `};
`;

export const PostBodyWrapper = styled.div<{isComment?: boolean}>`
  margin-top: 13px;
  ${({isComment}) =>
    isComment &&
    `
    margin-top: 2px;
    padding-left: 152px;
    ${Media.down.m} {
      padding-left: 0;
    }
  `};
  ${Media.down.s} {
    & iframe {
      width: 100%;
      height: 205px;
    }
  }
`;

export const PostText = styled.div.attrs({className: 'post__text'})`
  font-size: 14px;
  line-height: 16px;
  white-space: pre-wrap;
  cursor: auto;

  ${Media.down.m} {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

export const ShowMore = styled(ShowMoreText)`
  .showMore {
    white-space: nowrap;
    font-weight: 500;
    color: inherit;
  }
`;

const avatarCommentStyles = css`
  display: block;
  margin-right: 14px;
  transform: translateY(20%);
  ${Media.down.m} {
    transform: translateY(0);
  }
`;

export const PostAvatar = styled.div<{showMob?: boolean; isComment?: boolean}>`
  display: ${({showMob}) => (showMob ? `none` : `block`)};
  ${Media.down.m} {
    display: ${({showMob}) => (showMob ? `block` : `none`)};
    margin-right: 10px;
  }
  ${({isComment}) => isComment && avatarCommentStyles};
`;

export const PostUserLine = styled(Link)<{$isComment?: boolean; $nonClickable?: boolean}>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  gap: 10px;
  position: relative;

  & > * {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;

    & > * {
      width: max-content;
    }
  }

  & > *:first-child {
    gap: 5px;
  }

  & svg {
    width: 18px;
    height: 18px;
    margin: 0;
  }

  ${Media.up.l} {
    flex-direction: row;
    & > * {
      width: max-content;
    }
  }

  ${Media.down.s} {
    gap: 5px;
  }
  cursor: ${({$nonClickable}) => ($nonClickable ? 'auto' : 'pointer')};
`;

export const WhiteLabel = styled.div`
  & > span {
    background-color: ${({theme}) => theme.palette.background.primary};
  }

  ${Media.down.s} {
    order: -1;
  }
`;

export const ListingsLabelWrapper = styled.div<{listingsCount: number}>`
  display: ${({listingsCount}) => (listingsCount != 0 ? 'inline-block' : 'none')};

  & span {
    font-size: 12px;
  }

  & svg {
    margin-right: 3px;
  }

  ${Media.down.m} {
    display: inline-block;
  }
`;

export const PostUserName = styled.div`
  ${theme.font.primary.medium};
  color: ${({theme}) => theme.palette.grayscale._10};
  font-size: 15px;
  white-space: nowrap;
`;

export const PostUserInfo = styled.div`
  display: flex;
  & > div {
    margin-right: 10px;
    svg {
      margin-right: 6px;
      width: 14px;
      height: 14px;
    }
  }
`;

export const PostDate = styled.div<{showMob?: boolean}>`
  margin-left: auto;
  margin-right: 0;
  font-size: 10px;
  color: ${({theme}) => theme.palette.grayscale._5};
  display: ${({showMob}) => (showMob ? `none` : `block`)};
  grid-column: 4;

  ${Media.down.m} {
    display: ${({showMob}) => (showMob ? `block` : `none`)};
    font-size: 12px;
  }

  ${Media.down.s} {
    grid-column: 2;
  }
`;

export const ButtonMore = styled.div<{$isDisabled?: boolean}>`
  position: absolute;
  right: 5px;
  top: 14px;

  & > button {
    margin: 0;
    padding: 0;
  }

  ${Media.down.m} {
    top: 13px;
  }
  ${({$isDisabled}) =>
    $isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const PostCommentsWrapper = styled.div<{$isEditing?: boolean}>`
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 350px;
  overflow-y: ${({$isEditing}) => ($isEditing ? 'visible' : 'scroll')};
  ${scrollBarStyles};
  ${Media.down.m} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &::-webkit-scrollbar {
    background-color: ${({theme}) => theme.palette.grayscale._3};
  }
`;

export const PostCommendWrapper = styled.div`
  padding-left: 96px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  ${Media.down.m} {
    ${partsPaddingStyles};
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 4px;
  }
`;

export const CommentInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & svg,
  & img {
    flex-grow: 1;
    min-width: 36px;
  }
  padding-right: 6px;
`;

export const CommentInput = styled(TextareaAutosize)`
  max-height: 100px;
  min-height: 30px;
  border-radius: 25px;
  padding: 7px 18px;
  box-sizing: border-box;
  border: none;
  outline: none;
  flex-grow: 1;
  display: block;
  height: 100%;
  width: 98%;
  padding-right: 65px;
  background-color: ${({theme}) => theme.palette.background.septenary};
  font-size: 14px;
  resize: none;
  overflow: hidden;
  &::placeholder {
    font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    font-size: 14px;
    color: ${({theme}) => theme.palette.text.septenary};
    ${Media.down.xs} {
      font-size: 12px;
    }
    ${Media.down.l} {
      font-size: 14px;
    }
  }
  ${Media.down.l} {
    margin: 0 14px 0 10px;
    padding: 7px 12px;
    padding-right: 45px;
    font-size: 14px;
  }
`;

export const CloseText = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 11px;
  color: ${({theme}) => theme.palette.grayscale._13};
  text-decoration: underline;
  ${theme.font.primary.semiBold};
  margin-left: 10px;
  cursor: pointer;
  ${Media.down.s} {
    margin-left: 2px;
    font-size: 9px;
    min-width: 75px;
  }
`;

export const PostListingWrapper = styled.div`
  width: 100%;
`;

export const PostListingLabel = styled.div`
  ${theme.font.primary.regular};
  font-size: 14px;
  margin-bottom: 14px;
`;

export const SceletonPostHead = styled.div`
  display: flex;
  justify-content: space-between;
  & p {
    width: 100%;
    margin: 0;
    &:last-of-type {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

export const AttachmentsLine = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    border-radius: 10px;
    margin-right: 12px;
    object-fit: cover;
    width: 125px;
    height: 88px;
  }
    .pswp__button--zoom {
      //display: none;
    }
    .pswp__button--close {
      //display: none;
    }
  }
`;

export const AttachmentItem = styled.div<{$bordered?: boolean}>`
  height: 88px;
  width: 125px;
  margin-right: 10px;
  border-radius: 12px;
  border: ${({$bordered, theme}) => ($bordered ? `1px solid ${theme.palette.grayscale._14}` : `none`)};
  overflow: hidden;
  transition: 0.2s all;
  &:hover {
    box-shadow: ${({$bordered}) => ($bordered ? '1px 1px 12px rgba(0, 0, 0, 0.14)' : 'unset')};
  }
`;

export const AttachmentImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const AttachmentFile = styled.a`
  width: 100%;
  height: 100%;
  ${theme.font.primary.regular};
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  color: ${({theme}) => theme.palette.grayscale._6};
  & > span {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const CommentInputWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  margin-left: 15px;
  max-width: 90%;
  ${Media.down.m} {
    max-width: 86%;
    margin-left: 6px;
  }
  .ModalOverlay {
    position: relative;
    top: 20px;
    left: -10px;
  }

  .editorWrapper {
    height: auto;
    max-height: 100px;
    min-height: 36px;
    border: none;
    border-radius: 25px;
    background: #f2f2f2;
  }

  .editorWrapper .rt-wrapper {
    height: auto;
    max-height: 100px;
    min-height: 36px;
  }

  .editorWrapper .rdw-editor-main {
    position: relative;
    height: auto;
    max-height: 100px;
    min-height: 30px;
    padding: 8px 76px 5px 18px;
    ${Media.down.m} {
      padding: 8px 40px 5px 18px;
    }
  }

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 50%;
    transform: translateY(-50%);
    right: 28px;
    cursor: pointer;
  }

  .ModalWindow {
    top: 20px;
    left: -34px;
  }

  > button {
    top: 51%;
    right: -2px;
  }
`;

export const CommentSend = styled.button<{isDisabled?: boolean}>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  right: 10px;
  transform: translateY(-50%);
  position: absolute;
  ${Media.down.m} {
    right: -8px;
  }
  ${({isDisabled}) => isDisabled && `opacity: 0.5; pointer-events: none;`};
`;

export const ScrollBottomLine = styled.div`
  height: 0;
  width: 0;
  overflow: hidden;
`;

export const BottomContentWrapper = styled.div`
  cursor: auto;
`;

export const AdditionalLabel = styled.div`
  position: absolute;
  top: 12px;
  right: 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  ${Media.down.m} {
    right: 30px;
  }
`;

export const ChangeLangBtn = styled(Text).attrs({
  color: 'duodecimal',
  size: 12,
  decoration: 'underline',
})`
  cursor: pointer;
`;

export const RecommendationWrapper = styled.div`
  padding: 10px 12px;
  background: ${({theme}) => theme.palette.grayscale._0};
  border: 0.5px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  box-sizing: border-box;
`;

export const RecHeadLine = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
  margin-bottom: 2px;
`;
export const RecAuthor = styled(Link)`
  display: flex;
  align-items: center;
  & > div {
    width: 19px;
    height: 19px;
    margin-right: 10px;
  }
`;
export const RecAuthorName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.grayscale._19};
  ${fontPrimaryReg};
`;
export const RecLike = styled.div`
  display: flex;
  padding-top: 5px;
  & > span {
    padding-right: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    ${fontPrimaryReg};
    color: ${({theme}) => theme.palette.grayscale._13};
  }
`;

export const LikeBtn = styled.button`
  display: inline;
  height: 24px;
  width: 24px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-top: 1px;
`;
export const RecItems = styled.div`
  padding-left: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
`;
export const RecItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 25px;
  color: ${({theme}) => theme.palette.grayscale._19};
  ${fontPrimaryReg};
  & > span {
    max-width: 80%;
  }
  & > svg {
    margin-right: 8px;
  }
`;

export const PostRetryWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: -20px;
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-radius: 4px;
  transition: 0.1s all;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const PostRetryButton = styled.span`
  cursor: pointer;
  color: ${({theme}) => theme.palette.grayscale._6};
  font-size: 14px;
  ${fontPrimaryMedium}
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

export const SliderWrapper = styled.div``;
export const MobileListingsBlock = styled.div`
  display: none;
  ${Media.down.m} {
    display: block;
  }
  & > div {
    margin-bottom: 4px;
  }
`;
export const ShowMoreButton = styled.div`
  ${fontPrimaryReg};
  font-size: 12px;
  font-weight: 600;
  color: ${({theme}) => theme.palette.grayscale._19};
  margin-top: 8px;
  text-decoration: underline;
  padding: 0 4px;
`;

export const AvatarWrapper = styled.div``;
