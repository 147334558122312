import React, {useEffect, useRef} from 'react';

export const TouchScrollableBlock: React.FC = ({children, ...props}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const isolateTouch = (ev: TouchEvent) => {
      ev.stopPropagation();
    };

    scrollRef?.current?.addEventListener('touchstart', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchmove', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchend', isolateTouch, {passive: true});
    return () => {
      // eslint-disable-next-line
            // @ts-ignore
      scrollRef?.current?.removeEventListener('touchstart', isolateTouch, {passive: true});
      // eslint-disable-next-line
            // @ts-ignore
      scrollRef?.current?.removeEventListener('touchmove', isolateTouch, {passive: true});
      // eslint-disable-next-line
            // @ts-ignore
      scrollRef?.current?.removeEventListener('touchend', isolateTouch, {passive: true});
    };
  }, [scrollRef]);
  return (
    <div {...props} ref={scrollRef}>
      {children}
    </div>
  );
};
