import React from 'react';
import {VerifyEmailWrapper} from './styles';
import SingImg from './assets/sing.png';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../ui-kit/Typography/Text';
import {Button} from '../../../ui-kit/Button/Button';
import {Link} from '../../../ui-kit/Typography/Link';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';

export const VerifyEmail: React.FC = () => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  return (
    <VerifyEmailWrapper>
      <img src={SingImg} alt={'singing'} />
      <Text variant={'primary'} size={20} weight={'medium'} color={'quaternary'}>
        {t('common:verifyEmail.title')}
      </Text>
      <Text variant={'primary'} size={16} color={'septenary'}>
        {t('common:verifyEmail.subtitle')}
      </Text>
      <Link to={getLink(route.pageUser.path)}>
        <Button variant={'secondary'} ghost={true} rounded={true}>
          {t('common:verifyEmail.button')}
        </Button>
      </Link>
    </VerifyEmailWrapper>
  );
};
