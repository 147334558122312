import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Sticky from 'react-stickynode';
import {useRecoilState} from 'recoil';

import {EventRequestCard} from './EventRequestCard';
import {
  AsideRequests,
  InnerEditAside,
  InnerRequestCard,
  WrapperEditFormAside,
  MobileView,
  DesktopView,
  EventInfoRow,
  FullBlock,
  ListingViewDescr,
  InnerEventView,
  EventInfoTitle,
  AttendeeAvatar,
  EventViewAttendees,
} from './styles';
import {route} from '../../constants/routes';
import {correctTranslate} from '../../helpers/common';
import {getActualEventDate, getEventLabels} from '../../helpers/event';
import {UserLabelType} from '../../helpers/people';
import {getUserName} from '../../helpers/user';
import {EventRepeatType, RewardType} from '../../queries/types/event';
import {typeUser} from '../../states/typeUser';
import {TEvent} from '../../types/event';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {MessageRequestCard} from '../../ui-kit/RequestCard/MessageRequestCard';
import {MessageCardUserType, SendMessageStatus} from '../../ui-kit/RequestCard/types';
import {LottieLoader} from '../Loader/LottieLoader';

import {useLinks} from '../../hooks/common';
import {eventAvailableDataT, SubmitEventOrderOptions, TResCreateEventOrder} from '../../hooks/event';
import {TInvoice} from '../../hooks/order';
import {RichText} from '../common/RichText/RichText';
import {EventInfoDescr} from './EventRequestCard/styles';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {useHistory, useLocation} from 'react-router-dom';
import {getAliasFromPath} from '../../helpers/community';
import {TypeCommunity} from '../../types/auth';
import {CustomAttendees} from '../../containers/Events/ViewPublicEvent';

type TProps = {
  className?: string;
  width?: string;
  user?: MessageCardUserType;
  descr: string;
  event?: Partial<TEvent>;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataOrder?: TResCreateEventOrder;
  dataInvoice?: TInvoice;
  isDesktop?: boolean;
  bottomBoundary?: number;
  changeCardStatusRef?: React.MutableRefObject<((status: SendMessageStatus) => void) | undefined>;
  sendMessageRef?: React.RefObject<HTMLDivElement>;
  requestCardRef?: React.RefObject<HTMLDivElement>;
  isAvailable?: eventAvailableDataT;
  showWarnPopup?: () => void;
  accessRequest?: boolean;
  busyDates?: Date[];
  canSandMessage?: boolean;
  availableLoading?: boolean;
  userLabel?: UserLabelType;
  attendees?: number;
  description?: string;
  isPreview?: boolean;
  isPublicView?: boolean;
  customAttendees?: CustomAttendees;
};

const getTextData = (item?: Partial<TEvent>, typeText?: 'buttons' | 'descr') => {
  if (item?.rewardType === RewardType.free) {
    return `events:requests.${typeText}.request.free`;
  }
  return '';
};

export const RequestAside: React.FC<TProps> = ({
  width,
  user,
  event,
  dataCreateMsg,
  dataOrder,
  className,
  bottomBoundary,
  isDesktop,
  changeCardStatusRef,
  sendMessageRef,
  requestCardRef,
  isAvailable,
  accessRequest,
  showWarnPopup,
  canSandMessage,
  userLabel,
  attendees,
  description,
  isPreview,
  isPublicView,
  customAttendees,
}) => {
  const [cardStatus, setCardStatus] = useState<SendMessageStatus>(SendMessageStatus.Write);
  const [userType] = useRecoilState(typeUser);

  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {push} = useHistory();
  const {pathname} = useLocation();
  const alias = getAliasFromPath(pathname);
  useEffect(() => {
    if (changeCardStatusRef) {
      changeCardStatusRef.current = setCardStatus;
    }
  }, []);

  useEffect(() => {
    if (dataCreateMsg?.success) {
      setCardStatus(SendMessageStatus.Done);
    }
  }, [dataCreateMsg?.success]);

  const titleBtn = t(getTextData(event, 'buttons'));
  const titleDescr = t(getTextData(event, 'descr'));
  // const tagRewardType = getAmountLabelEvent(event);

  const errorMsg = correctTranslate({
    text: t(dataOrder?.error?.message || ''),
    defaultText: dataOrder?.error?.message,
  });
  const successMsg = dataOrder?.success ? t(dataOrder.success) : dataOrder?.success;

  const handleWriteMsg = () => {
    setCardStatus(SendMessageStatus.Send);
  };

  const handleSendMsg = () => {
    if (!dataCreateMsg?.onSubmit) return;
    dataCreateMsg.onSubmit();
  };

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!dataCreateMsg?.onChange) return;
    dataCreateMsg?.onChange({name: CreateMsgFields.text, value: target.value});
  };
  const handleCreateOrder = (subOptions?: SubmitEventOrderOptions) => {
    if (!accessRequest) return showWarnPopup?.();
    if (!dataOrder?.onSubmit) return;
    dataOrder.onSubmit(subOptions);
  };
  const labels = getEventLabels(t, event);
  const orderId = dataOrder?.orderInfo ? `?orderId=${dataOrder?.orderInfo}` : '';
  const messageLink = `${getLink(route.messages.get({contactId: event?.Lister?.objectId}))}${orderId}`;
  const notAvailableText = isAvailable?.text && t(`events:requests.notAvailable.${isAvailable.text}`);
  const userName = getUserName(userType, user?.firstName, user?.lastName);
  const actualDate = useMemo(() => getActualEventDate(event?.startTime, event?.eventType), [!!event?.startTime]);
  const getLang = useGetLanguage();
  const {interpolation} = optionsI18n;
  const f = interpolation.format;
  const timePart1 =
    actualDate && event?.endTime
      ? f(new Date(actualDate), 'iii', getLang()) + ', ' + f(new Date(actualDate), 'PP', getLang())
      : '';

  const timePart2 =
    actualDate && event?.endTime
      ? f(new Date(actualDate), 'p', getLang()) + ' to ' + f(new Date(event?.endTime), 'p', getLang())
      : '';
  const repeatLabel = event?.eventType
    ? event.eventType !== EventRepeatType.one
      ? `(${t(`events:repeatEventType.${event?.eventType}`)})`
      : ''
    : '';
  const goSignUp = () => push(getLink(route.signUp.get({user: TypeCommunity.resident}), alias));
  return (
    <InnerEditAside width={width} className={className}>
      <AsideRequests>
        <WrapperEditFormAside>
          <MobileView>
            <FullBlock ref={sendMessageRef}>
              <MessageRequestCard
                hideReviews={true}
                type={cardStatus}
                user={user}
                descr={t('events:messages.sendDescr')}
                buttonTitle={t('requests:buttons.send')}
                linkTitle={t('requests:buttons.view')}
                itemTitle={event?.name}
                handleWriteMsg={handleWriteMsg}
                handleSendMsg={handleSendMsg}
                pathLink={getLink(route.messages.get({contactId: event?.Lister?.objectId}))}
                messageSent={t('requests:successSend')}
                message={{
                  placeholder: t('requests:placeholder'),
                  value: dataCreateMsg?.values?.text,
                  onChange: handleChange,
                  loading: dataCreateMsg?.loading,
                }}
                canSend={canSandMessage || isPublicView}
                profileLink={event?.Lister?.objectId && getLink(route.profile.get({id: event?.Lister?.objectId}))}
                userLabel={userLabel}
                hideVerified={true}
                userName={userName}
                isPreview={isPreview}
                goSignUp={goSignUp}
                isPublicView={isPublicView}
              />
            </FullBlock>
            {description && (
              <InnerEventView>
                <EventInfoTitle>{t('common:descr')}</EventInfoTitle>
                <ListingViewDescr>
                  <RichText html={description} />
                </ListingViewDescr>
              </InnerEventView>
            )}
            <InnerEventView>
              <EventInfoTitle>{t('events:requests.dateAndTime')}</EventInfoTitle>
              <EventInfoDescr>
                {timePart1} {repeatLabel}
              </EventInfoDescr>
              <EventInfoDescr>{timePart2}</EventInfoDescr>
            </InnerEventView>
            {event?.locationDetails && (
              <InnerEventView>
                <EventInfoTitle>{t('events:requests.location')}</EventInfoTitle>
                <ListingViewDescr>{event.locationDetails}</ListingViewDescr>
              </InnerEventView>
            )}
            {event?.Attendees && (
              <InnerEventView>
                <EventInfoTitle>
                  {t('events:requests.label.attendees', {count: event?.Attendees?.length || 0})}
                </EventInfoTitle>
                <EventViewAttendees>
                  {event?.Attendees?.slice(0, 40)?.map((el, i) => (
                    <AttendeeAvatar src={el?.Avatar?.file?.url} key={i} />
                  ))}
                </EventViewAttendees>
              </InnerEventView>
            )}
            {customAttendees?.length && (
              <InnerEventView>
                <EventInfoTitle>
                  {t('events:requests.label.attendees', {count: customAttendees?.length || 0})}
                </EventInfoTitle>
                <EventViewAttendees onClick={goSignUp}>
                  {customAttendees?.slice(0, 40)?.map((el, i) => (
                    <AttendeeAvatar src={el?.avatar?.file?.url} key={i} />
                  ))}
                </EventViewAttendees>
              </InnerEventView>
            )}
            <InnerEventView>
              <EventInfoTitle>{t('events:requests.label.book')}</EventInfoTitle>
            </InnerEventView>
          </MobileView>
          <InnerRequestCard id={'requestCard'} ref={requestCardRef}>
            <Sticky enabled={!!isDesktop} top={60} bottomBoundary={bottomBoundary}>
              <EventRequestCard
                event={event}
                buttonLabel={{label: titleBtn, onClick: handleCreateOrder, loading: dataOrder?.loading}}
                descr={titleDescr}
                tags={labels}
                message={{error: errorMsg, success: successMsg}}
                successOrder={dataOrder?.success}
                requestSent={t('requests:requestSend')}
                pathLink={messageLink}
                linkTitle={t('requests:buttons.viewConfirmation')}
                isAvailable={isAvailable?.check}
                notAvailableText={notAvailableText}
                orderChangeData={dataOrder?.changeData}
                attendees={attendees}
                isDisabled={isPreview}
                isPublicView={isPublicView}
                goSignUp={goSignUp}
                actualDate={actualDate}
              />
              <EventInfoRow />
              <DesktopView>
                <FullBlock>
                  <MessageRequestCard
                    hideReviews={true}
                    type={cardStatus}
                    user={user}
                    descr={t('events:messages.sendDescr')}
                    buttonTitle={t('requests:buttons.send')}
                    linkTitle={t('requests:buttons.view')}
                    itemTitle={event?.name}
                    handleWriteMsg={handleWriteMsg}
                    handleSendMsg={handleSendMsg}
                    pathLink={getLink(route.messages.get({contactId: event?.Lister?.objectId}))}
                    messageSent={t('requests:successSend')}
                    message={{
                      placeholder: t('requests:placeholder'),
                      value: dataCreateMsg?.values?.text,
                      onChange: handleChange,
                      loading: dataCreateMsg?.loading,
                    }}
                    canSend={canSandMessage || isPublicView}
                    profileLink={event?.Lister?.objectId && getLink(route.profile.get({id: event?.Lister?.objectId}))}
                    userLabel={userLabel}
                    hideVerified={true}
                    userName={userName}
                    isPublicView={isPublicView}
                    goSignUp={goSignUp}
                  />
                </FullBlock>
              </DesktopView>
            </Sticky>
          </InnerRequestCard>
          <LottieLoader $isVisible={dataOrder?.loading} allScreen={true} />
        </WrapperEditFormAside>
      </AsideRequests>
    </InnerEditAside>
  );
};
