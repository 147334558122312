import React from 'react';
import {PaymentLabelProps, RewardType} from './types';
import {
  CardPaymentTypeContent,
  CardPaymentTypeIcon,
  CardPaymentTypeText,
  CardPaymentTypeTitle,
  CardPaymentTypeWrapper,
} from './styles';
import {IconSvg} from '../Icon/Svg';

export const PaymentLabel: React.FC<PaymentLabelProps> = ({rewardType, title, descr, onClick}) => {
  return (
    <CardPaymentTypeWrapper onClick={onClick}>
      <CardPaymentTypeIcon>
        {rewardType === RewardType.free && <IconSvg type={'sun-2'} stroke={'yellow'} />}
        {rewardType === RewardType.coffee && <IconSvg type={'coffee-cup'} stroke={'blue'} />}
        {rewardType === RewardType.fee && <IconSvg type={'credit-card'} stroke={'red'} />}
      </CardPaymentTypeIcon>
      <CardPaymentTypeContent>
        <CardPaymentTypeTitle>{title}</CardPaymentTypeTitle>
        <CardPaymentTypeText>{descr}</CardPaymentTypeText>
      </CardPaymentTypeContent>
    </CardPaymentTypeWrapper>
  );
};
