import React, {useState} from 'react';
import {Post} from '../Posts/Post';
import {MemberT} from '../../../types/people';
import {useMembersListingsCount} from '../../../hooks/people';
import {formatPostAuthor} from '../../../helpers/feed';
import {TypeCommunity} from '../../../types/auth';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {useTranslation} from 'react-i18next';
import {PostPageProps} from '../types';
import {NavContainer} from '../../../containers/Navigation';
import {NavLineText, PostPageContainer, PostPageNavLine, PostPageNavLink, PostPageWrapper} from '../../Loop/styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {route} from '../../../constants/routes';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../SEO';
import {FeedSkeleton} from '../../common/Skeletons/FeedSkeleton';
import {useLinks} from '../../../hooks/common';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {MemoLoadingSkeleton} from '../../common/Skeletons/LoadingSkeleton';
import {useSetRecoilState} from 'recoil';
import {isModal} from '../../../states/themeMode';

export const PostPage: React.FC<PostPageProps> = ({
  postData,
  refetch,
  community,
  viewer,
  typeUser,
  menuActions,
  postActions,
  loading,
  pinUnpin,
  adminLabels,
  getPollData,
  reportItemAction,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const setIsModal = useSetRecoilState(isModal);
  const textTitle = t('community:loop.postTitle');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textTitle]);
  const btnHide = t('community:loop.post.menuMore.hide');
  const btnDelete = t('community:loop.post.menuMore.delete');
  const btnBack = postData?.Group?.objectId ? t('groups:groupPost.backLink') : t('community:loop.buttons.back');
  const postAuthors = [postData?.Author as MemberT];
  const membersListingsCount = useMembersListingsCount(postAuthors, community?.objectId, community?.__typename);
  const postAuthor = formatPostAuthor({postData: postData ? [postData] : [], membersListingsCount, community});
  const isPinned = !!postData?.Pin?.length;
  const {getSignValue} = useGetCurrencyValue();
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [focusPostId, setFocusPostId] = useState<string | undefined>('');
  const handleOpenDelete = (id?: string) => {
    setFocusPostId(id);
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setFocusPostId('');
    setShowModalDelete(false);
  };

  const optionsManagerBase = (id?: string): MenuItemType[] => [
    ...(!isPinned
      ? [
          {
            title: btnHide,
            onClick: () => menuActions?.hide?.(id, refetch),
          },
        ]
      : []),
    {
      title: t(`community:loop.post.actions.${isPinned ? 'unpin' : 'pin'}`),
      onClick: () => id && pinUnpin(id),
    } as MenuItemType,
    {
      render: 'line',
    },
    {
      title: btnDelete,
      onClick: () => {
        handleOpenDelete();
      },
      render: 'danger',
    },
  ];
  const createMenuOptions =
    typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin
      ? (id?: string): MenuItemType[] => {
          return optionsManagerBase(id);
        }
      : (id?: string): MenuItemType[] => [
          {
            title: btnHide,
            onClick: () => menuActions?.hide?.(id, refetch),
          },
        ];
  const modalsText = {
    delete: {
      title: t('common:modals.delete.title'),
      okText: t('common:modals.delete.button'),
      cancelText: t('common:modals.delete.close'),
    },
  };
  const handleDelete = async () => {
    await menuActions?.deletePost?.(focusPostId || postData?.objectId, refetch);
    handleCloseDelete();
  };
  return (
    <NavContainer>
      <Seo title={textTitle} keywords={seoKeywords} />
      <PostPageWrapper>
        <PostPageNavLine>
          {loading ? (
            <MemoLoadingSkeleton width={'20%'} mobileWidth={'40%'} />
          ) : (
            <PostPageNavLink
              to={getLink(
                postData?.Group?.objectId ? route.groupView.get({id: postData?.Group?.objectId}) : route.loop.path,
              )}>
              <IconSvg type={'arrow-left'} />
              <NavLineText>{btnBack}</NavLineText>
            </PostPageNavLink>
          )}
        </PostPageNavLine>
        {loading ? (
          <FeedSkeleton />
        ) : (
          <PostPageContainer>
            {postData?.objectId ? (
              <Post
                {...postData}
                typeUser={typeUser || TypeCommunity.resident}
                key={postData?.objectId}
                Author={postAuthor}
                createMenuOptions={createMenuOptions}
                postActions={postActions}
                viewerId={viewer?.objectId || ''}
                refetch={refetch}
                hidePost={menuActions?.hide}
                communityId={community?.id}
                listingsCount={postAuthor?.listingCount}
                viewer={viewer}
                isDetail={true}
                getSignValue={getSignValue}
                adminLabels={adminLabels}
                menuActions={menuActions}
                handleDelete={handleOpenDelete}
                getPollData={getPollData}
                reportItemActions={reportItemAction}
                setIsModal={setIsModal}
              />
            ) : (
              <div />
            )}
          </PostPageContainer>
        )}
        {isShowModalDelete && (
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={handleDelete}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        )}
      </PostPageWrapper>
    </NavContainer>
  );
};
