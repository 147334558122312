import {anyObject} from '../../types/helpers';
import {Message, IValidation, IValidator} from './types';
import {isString} from '../validations';

export class Validation<T extends anyObject> implements IValidation<T> {
  public isValidation: true;
  protected $isNullable: boolean;
  protected $validators: IValidator<T>[];
  protected $message: string | undefined;

  get message() {
    return this.$message as string;
  }

  get isNullable() {
    this.$isNullable = true;
    return this;
  }

  constructor(message?: Message) {
    this.$message = message;
    this.isValidation = true;
    this.$validators = [];
    this.$isNullable = false;
  }

  validate(value: unknown, data: T): boolean {
    if (this.$validateNullable(value)) {
      return this.$isNullable;
    }

    let result = true;

    this.$validators.forEach((validator) => {
      if (!result) return;
      result = validator.validate(value as T[keyof T], data);

      if (!result && isString(validator.message)) {
        this.$message = validator.message;
      }
    });

    return result;
  }

  protected $validateNullable(value: unknown): boolean {
    return value === null || value === undefined || value === '';
  }
}
