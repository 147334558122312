import {ItemFormValues, TItem, TQueryItem, TQueryPostItem} from '../types/item';
import {Item, ListingType, PricePeriodType} from '../queries/types/item';
import {convertCentToDollar, convertDollarToCent} from './payment';
import {Connection} from '../queries/types/parse';
import {Community} from '../queries/types/community';
import {Review} from '../queries/types/review';
import {TPostItem} from '../queries/types/post';
import {TYPE_NAME} from '../constants/common';
import {getAvarageReviewsListingStars} from './reviews';
import {PointerFile} from '../types/common';

export const getDiscountType = (
  listingType?: ListingType,
  discount?: number | null,
  pricePeriod?: PricePeriodType | null,
) => {
  if (!discount) return null;
  if (listingType === ListingType.sell) {
    return PricePeriodType.fixed;
  }
  return pricePeriod;
};

export const toPointerCreateItem = (
  item: Partial<ItemFormValues>,
  viewerId: string,
  parseImages?: (Partial<PointerFile> | undefined)[],
) => {
  return {
    ...item,
    images: parseImages,
    price: convertDollarToCent(item?.price),
    originalPrice: convertDollarToCent(item?.originalPrice),
    pricePeriod: item.listingType === ListingType.sell ? PricePeriodType.fixed : item.pricePeriod,
    discountType: getDiscountType(item.listingType, item.discount, item.pricePeriod),
    Lister: {
      link: viewerId || '',
    },
  };
};

type TEditItem = Omit<Partial<ItemFormValues>, 'images'> & {
  images?: (Partial<PointerFile> | undefined)[];
};
export const toPointerEditItem = (item: Partial<TEditItem>) => {
  return {
    category: item.category,
    descr: item.descr,
    discount: item.discount,
    price: convertDollarToCent(item?.price),
    originalPrice: convertDollarToCent(item?.originalPrice),
    discountType: getDiscountType(item.listingType, item.discount, item.pricePeriod),
    listingType: item.listingType,
    minDuration: item.minDuration,
    name: item.name,
    pricePeriod: item.listingType === ListingType.sell ? PricePeriodType.fixed : item.pricePeriod,
    rewardType: item.rewardType,
    yearPurchased: item.yearPurchased,
    images: item.images,
  };
};

export const toStateItem = (item?: TQueryItem): Partial<TItem> => {
  const images = item?.images;

  const Reviews = {
    ...item?.Reviews,
    edges: item?.Reviews?.edges.map((el) => ({
      ...el,
      node: {
        ...el.node,
        Author: {
          ...el.node.Author,
          isOnline: el.node?.Author?.isOnline,
        },
      },
    })),
  } as Connection<Review> | undefined;

  return {
    ...item,
    images: images,
    serverImages: images,
    rating: getAvarageReviewsListingStars(item?.Reviews?.edges),
    price: convertCentToDollar(item?.price),
    originalPrice: convertCentToDollar(item?.originalPrice),
    ...(Reviews ? {Reviews} : {}),
  };
};

export const toStatePostItem = (item?: TQueryPostItem): TPostItem => {
  const images = item?.images;

  let Reviews: Connection<Review> | undefined;
  let name: string | undefined;
  let rewardType: string | undefined;
  let pricePeriod: string | number | undefined;

  if (item?.__typename === TYPE_NAME.Item) {
    const _item = item as any as Item;

    name = item.listingName;
    rewardType = item.listingRewardType;
    pricePeriod = item.listingPricePeriod;

    Reviews =
      _item.Reviews &&
      ({
        ..._item.Reviews,
        edges: _item?.Reviews?.edges.map((el) => ({
          ...el,
          node: {
            ...el.node,
            Author: {
              ...el.node.Author,
              isOnline: el.node?.Author?.isOnline,
            },
          },
        })),
      } as Connection<Review> | undefined);
  }

  if (item?.__typename === TYPE_NAME.Document) {
    name = item.documentName;
  }
  if (item?.__typename === TYPE_NAME.Event) {
    name = item.eventName;
    rewardType = item.eventRewardType;
    pricePeriod = item.eventPricePeriod;
  }
  if (item?.__typename === TYPE_NAME.Group) {
    name = item.groupName;
  }

  const price = convertCentToDollar(item?.price);
  const originalPrice = convertCentToDollar(item?.originalPrice);

  return {
    ...item,
    ...(name ? {name} : {}),
    ...(Reviews ? {Reviews} : {}),
    ...(images ? {images} : {}),
    ...(images ? {serverImages: images} : {}),
    ...(price ? {price} : {}),
    ...(originalPrice ? {originalPrice} : {}),
    ...(rewardType ? {rewardType} : {}),
    ...(pricePeriod ? {pricePeriod} : {}),
  } as TPostItem;
};

export const getIsIncludesCmnt = (data?: Partial<Community>[], communityId?: string) => {
  return Number(data?.findIndex((it) => it.id === communityId || it.objectId === communityId)) >= 0;
};

export const getIsPublishedCmnt = (data?: Partial<Community>[], communityId?: string) => {
  return Number(data?.findIndex((it) => it.id === communityId || it.objectId === communityId)) >= 0;
};

export const getIsUnlist = (item?: Partial<TItem>, communityId?: string) => {
  return (
    Number(item?.Published?.findIndex((it) => it.id === communityId || it.objectId === communityId)) === -1 &&
    Number(item?.AdmHidden?.findIndex((it) => it.id === communityId || it.objectId === communityId)) === -1 &&
    Number(item?.ApproveReq?.findIndex((it) => it.id === communityId || it.objectId === communityId)) === -1
  );
};

export const checkDeletedItems = (deleted: string[], items?: Partial<TItem>[]) => {
  return deleted?.length
    ? items?.filter((el) => !!el.id && !!el.objectId && !(deleted?.includes(el.objectId) || deleted?.includes(el.id)))
    : items;
};
