import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EventFormAction, TEvent} from '../../types/event';
import {
  DesktopView,
  EditButton,
  EventNameLine,
  InnerListingView,
  ListingViewTitle,
  MainContListingView,
  MobileView,
  WrapperListingView,
  MobEventPreviewHead,
  EditButtonsWrapper,
  InnerEventView,
  EventItemSubtitle,
  EventItemTitle,
  WrapperEventView,
  TableTitle,
  DatesDDvalue,
  DatesDropdown,
  DatesDropdownWrapper,
} from './styles';
import {EditFormAside} from './EditFormAside';
import {route} from '../../constants/routes';
import {NavContainer} from '../../containers/Navigation';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {ListingNotFound} from '../Extras';
import {useLinks} from '../../hooks/common';
import {AddCreditCardPopup} from '../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {TDataCreator} from '../../hooks/event';
import {EventsTable} from './EventsTable';
import {LinkBack} from '../common/Buttons/LinkBack';
import {MemoLabelCard} from './LabelCard';
import {Order} from '../../queries/types/order';
import {TResManageOrder} from '../../hooks/order';
import {Gallery} from 'react-photoswipe-gallery';
import {Slider} from './Slider';
import {
  AmenityDateButton,
  AmenityDateSelector,
  AmenityNameLeftSide,
  AmenityNameLine,
  ArrowBtn,
} from '../Amenities/styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {isToday} from 'date-fns';
import {EventRepeatType} from '../../queries/types/event';
import {filterOrdersByDate, getEventDatesWithActual} from '../../helpers/event';

interface IProps {
  event?: Partial<TEvent>;
  $loading?: boolean;
  action: EventFormAction;
  onPublish?: () => void;
  onUnlist?: () => void;
  viewerObjId?: string;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataCreator?: TDataCreator;
  onDelete?: () => void;
  onRenew?: () => void;
  isAvailable?: boolean;
  communityAlias?: string;
  communityId?: string;
  requestAccess?: boolean;
  refetch?: () => void;
  viewerIsNotOwner?: boolean;
  orders?: Order[];
  manageOrder?: TResManageOrder;
  ordersLoading?: boolean;
  canEditCommunities?: boolean;
  handleLike?: () => Promise<boolean>;
  commList?: {objectId?: string; name?: string}[];
  eventOrders?: Order[] | undefined;
}

const itemsCount = 8;
const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};
export const ViewEventCreator: React.FC<IProps> = ({
  event,
  $loading,
  onUnlist,
  onPublish,
  onDelete,
  communityId,
  requestAccess,
  refetch,
  orders,
  manageOrder,
  ordersLoading,
  canEditCommunities,
  handleLike,
  commList,
  eventOrders,
}) => {
  const {t} = useTranslation();
  const textSeo = event?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const isAdmHidden = useMemo(
    () => Boolean(event?.AdmHidden?.filter((el) => el.objectId === communityId).length),
    [event, communityId],
  );
  const isPublished = useMemo(
    () => Boolean(event?.Published?.filter((el) => el.objectId === communityId).length),
    [event, communityId],
  );
  const imagesBlock = useRef<HTMLDivElement>(null);
  const [noRequestModal, setNoRequestModal] = useState<boolean>(false);
  const {getLink} = useLinks();
  const [itemsToShow, setItemsToShow] = useState(6);
  const [orderDate, setOrderDate] = useState<Date | undefined>();
  const listingOg = useMemo(() => {
    return {
      title: event?.name,
      description: event?.descr,
      image: event?.images?.[0]?.file?.url,
    };
  }, [event]);

  const handleCloseAddCreditCardPopup = () => {
    setNoRequestModal(false);
    refetch?.();
  };
  const editPath = useMemo(
    () => (event?.objectId ? getLink(route.editEvent.get({id: event?.objectId || ''})) : null),
    [event],
  );
  const commListPath = useMemo(
    () => (event?.objectId ? getLink(route.configureListCommunitiesEvent.get({id: event?.objectId || ''})) : null),
    [event],
  );
  // const allows = useGetMessagesAllows({directUserId: event?.Lister?.objectId});
  const previewLink = event?.objectId && `${getLink(route.eventView.get({id: event?.objectId || ''}))}?preview=true`;
  const onViewAll = () => setItemsToShow(itemsCount);
  const isReccuring = event?.eventType && event?.eventType !== EventRepeatType.one;
  const filterTime = orderDate && new Date(orderDate).getTime();
  const filteredOrders = useMemo(() => {
    return filterOrdersByDate(orderDate, event?.eventType, event?.startTime, orders);
  }, [filterTime, orders?.length]);
  return (
    <NavContainer>
      {!requestAccess && noRequestModal && <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />}
      <Seo title={textSeo} keywords={seoKeywords} og={listingOg} />
      {$loading ? (
        <ListingViewSkeleton />
      ) : event?.isDeleted ? (
        <ListingNotFound />
      ) : (
        <>
          <DesktopView>
            <MainContListingView ref={imagesBlock}>
              <WrapperListingView>
                <LinkBack type={'events'} />
                <InnerListingView>
                  <EventNameLine>
                    <ListingViewTitle>
                      {event?.name}
                      <MemoLabelCard rewardType={event?.rewardType} withOutName={true} />
                    </ListingViewTitle>
                    <EditButtonsWrapper>
                      {canEditCommunities && (
                        <EditButton to={commListPath}>{t('events:actions.buttons.communityList')}</EditButton>
                      )}
                      <EditButton to={editPath}>{t('events:actions.buttons.edit')}</EditButton>
                    </EditButtonsWrapper>
                  </EventNameLine>
                  <AmenityNameLine>
                    <TableTitle>{t('events:insights.attendees')}</TableTitle>
                    <AmenityNameLeftSide>
                      {isReccuring && (
                        <EventDatePicker
                          orderDate={orderDate}
                          setDate={setOrderDate}
                          eventType={event?.eventType}
                          startTime={event?.startTime}
                          eventOrders={eventOrders}
                        />
                      )}
                    </AmenityNameLeftSide>
                  </AmenityNameLine>
                  <EventsTable orders={filteredOrders} manageOrder={manageOrder} loading={ordersLoading} />
                  <EditFormAside
                    width={'300px'}
                    $isPublished={isPublished}
                    orders={orders}
                    onUnlist={onUnlist}
                    onPublish={onPublish}
                    countViewers={event?.totalViews}
                    className={'listing-view__form-aside'}
                    editPath={editPath}
                    location={event?.locationDetails}
                    endTime={event?.endTime}
                    dateAndTime={event?.startTime}
                    isAdmHidden={isAdmHidden}
                    onDelete={onDelete}
                    manyCommunities={canEditCommunities}
                    countLikes={event?.countLikes}
                    communityListPath={getLink(route.configureListCommunitiesEvent.get({id: event?.objectId || ''}))}
                  />
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
          </DesktopView>

          <MobileView>
            <MainContListingView>
              <WrapperEventView>
                <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                  <Slider
                    images={event?.images}
                    isEdit={true}
                    previewLink={previewLink}
                    editLink={getLink(route.editEvent.get({id: event?.objectId || ''}))}
                    handleLike={handleLike}
                    isLike={event?.isLike}
                    // analyticSend={analyticSend}
                  />
                </Gallery>
                <MobEventPreviewHead>
                  <InnerEventView>
                    {/* <LinkBack to={'#'} onClick={handleBackNoReactRouter} hideText={true} type={'events'} /> */}
                    <EventItemTitle>{event?.name}</EventItemTitle>
                    <EventItemSubtitle>{event?.rewardType}</EventItemSubtitle>
                  </InnerEventView>
                </MobEventPreviewHead>
              </WrapperEventView>
            </MainContListingView>
            <MainContListingView>
              <WrapperListingView offsetTop={'25px'}>
                <EditFormAside
                  width={'300px'}
                  $isPublished={isPublished}
                  onUnlist={onUnlist}
                  onPublish={onPublish}
                  countViewers={event?.totalViews}
                  className={'listing-view__form-aside'}
                  editPath={getLink(route.editEvent.get({id: event?.objectId || ''}))}
                  location={event?.locationDetails}
                  endTime={event?.endTime}
                  dateAndTime={event?.startTime}
                  attendeesCount={event?.Attendees?.length}
                  isAdmHidden={isAdmHidden}
                  onDelete={onDelete}
                  communityListPath={getLink(route.configureListCommunitiesEvent.get({id: event?.objectId || ''}))}
                  manyCommunities={canEditCommunities}
                  countLikes={event?.countLikes}
                  commList={commList}
                  published={event?.Published}
                />
                <AmenityNameLine>
                  <TableTitle>{t('events:insights.attendees')}</TableTitle>
                  <AmenityNameLeftSide>
                    {isReccuring && (
                      <EventDatePicker
                        orderDate={orderDate}
                        setDate={setOrderDate}
                        eventType={event?.eventType}
                        startTime={event?.startTime}
                        eventOrders={eventOrders}
                      />
                    )}
                  </AmenityNameLeftSide>
                </AmenityNameLine>
                <EventsTable
                  limit={itemsToShow}
                  onViewALl={onViewAll}
                  orders={filteredOrders}
                  manageOrder={manageOrder}
                  loading={ordersLoading}
                />
              </WrapperListingView>
            </MainContListingView>
          </MobileView>
        </>
      )}
    </NavContainer>
  );
};

export const EventDatePicker: React.FC<{
  orderDate?: Date;
  setDate: (value?: Date) => void;
  startTime?: Date;
  eventType?: EventRepeatType;
  eventOrders?: Order[] | undefined;
}> = ({orderDate, setDate, eventType, startTime, eventOrders}) => {
  const {range, actualIndex} = useMemo(() => getEventDatesWithActual(startTime, eventType), []);
  const {t} = useTranslation();
  const [datesIndex, setIndex] = useState(0);
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;
  const [open, setOpen] = useState(false);
  const today = orderDate && isToday(orderDate);
  const date = today ? t('amenities:table.today') : format(orderDate, 'PP', getLanguage());
  const onClose = () => setOpen(false);
  const [ordersList, setOrdersList] = useState<{date?: string; count?: number}[]>([]);

  const changeDay = (type: 'plus' | 'minus') => () => {
    if (type === 'plus') {
      const nextInd = datesIndex + 1;
      const nextDate = range?.[nextInd];
      if (nextInd > Number(range?.length)) return;
      setIndex((prev) => prev + 1);
      nextDate && setDate?.(nextDate);
    }
    if (type === 'minus') {
      const nextInd = datesIndex - 1;
      const nextDate = range?.[nextInd];
      if (nextInd < 0) return;
      setIndex((prev) => prev - 1);
      nextDate && setDate?.(nextDate);
    }
  };

  useEffect(() => {
    if (eventType === EventRepeatType.one || !eventType) {
      startTime && setDate?.(startTime);
      return;
    }
    const actual = range?.[actualIndex];
    if (actual) {
      setDate?.(actual);
      setIndex(actualIndex);
    }
  }, [actualIndex]);

  useEffect(() => {
    if (eventOrders?.length) {
      const actualDates = [...(range || [])].slice(actualIndex)?.map((el) => format(new Date(el), 'P'));
      const datesObj: Record<string, number> = {};
      actualDates?.forEach((el) => (datesObj[el] = 0));

      eventOrders?.forEach((order) => {
        const bookedDate = order?.startTime && format(new Date(order?.startTime), 'P');
        if (!order?.recurringEvent && actualDates.includes(bookedDate)) {
          datesObj[bookedDate] = (datesObj?.[bookedDate] || 0) + 1;
          return;
        }
        if (order?.recurringEvent) {
          const from = actualDates?.findIndex((el) => el === bookedDate) || 0;
          if (from >= 0) actualDates.slice(from)?.forEach((el) => (datesObj[el] = (datesObj?.[el] || 0) + 1));
        }
      }, []);
      setOrdersList(Object.keys(datesObj)?.map((key) => ({date: key, count: datesObj[key]})));
    }
  }, [!!eventOrders?.length]);
  return (
    <>
      <AmenityDateSelector>
        <ArrowBtn onClick={changeDay('minus')}>
          <IconSvg type={'chevrone-left'} />
        </ArrowBtn>
        <AmenityDateButton $short={!!today} onClick={() => setOpen(true)}>
          <IconSvg type={'calendar'} stroke={'gray'} />
          <div>{date}</div>
          {open && <OrdersDropdown onClose={onClose} opened={open} ordersList={ordersList} />}
        </AmenityDateButton>
        <ArrowBtn onClick={changeDay('plus')}>
          <IconSvg type={'chevrone-right'} />
        </ArrowBtn>
      </AmenityDateSelector>
    </>
  );
};

export const OrdersDropdown: React.FC<{
  onClose?: any;
  opened?: boolean;
  ordersList: {date?: string; count?: number}[];
}> = ({onClose, opened, ordersList}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (opened && ref.current && !ref.current.contains(e.target)) {
        onClose?.();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [opened]);
  return (
    <DatesDropdown ref={ref}>
      <DatesDropdownWrapper>
        {ordersList?.map((el) => (
          <DatesDDvalue key={el.date}>
            <span>{el?.date}</span>
            <span>{el?.count}</span>
          </DatesDDvalue>
        ))}
      </DatesDropdownWrapper>
    </DatesDropdown>
  );
};
