import React from 'react';
import {SubTitle, WrapperContent} from './styles';
import {EmptyStateListingNotAv, EmptyStateUser} from '../EmptyState/User';
import {route} from '../../constants/routes';
import {ListCardsGrid} from '../Listings/styles';
import {ListingCard} from '../Listings/ListingCard';
import {TItem} from '../../types/item';
import {useTranslation} from 'react-i18next';
import {ItemsSkeletonSection} from '../common/Skeletons/ItemsSkeleton';
import {TypeUserStateType} from '../../states/typeUser';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {getResize} from '../../helpers/file';
import {IGetCurrValue} from '../../types/common';

type TProps = {
  items?: Partial<TItem>[];
  loading?: boolean;
  typeUser?: TypeUserStateType;
  communityId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  listingsAvailable?: boolean;
  isSupport?: boolean;
};

export const Content: React.FC<TProps> = ({
  items,
  loading,
  communityId,
  reportActions,
  getSignValue,
  listingsAvailable,
  isSupport,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  if ((isSupport && !items?.length && !loading) || (!listingsAvailable && isSupport)) return null;

  if (!listingsAvailable) {
    return <EmptyStateListingNotAv />;
  }
  if (!items?.length && !loading) {
    return <EmptyStateUser path={getLink(route.createItem.path)} />;
  }

  return (
    <WrapperContent>
      <SubTitle offsetBottom={14}>{t(`people:profile.titles.listings`)}</SubTitle>
      <ListCardsGrid minWidth={300}>
        {loading && !items?.length ? (
          <ItemsSkeletonSection />
        ) : (
          items?.map((item) => (
            <ListingCard
              src={getResize(item?.images?.[0]?.file?.url, 'md')}
              to={getLink(route.listingViewEdit.get({id: item?.id || ''}))}
              communityId={communityId}
              isOwner={true}
              key={item.id}
              item={item}
              reportActions={reportActions}
              aspectRatio={'300:200'}
              getSignValue={getSignValue}
              editPath={getLink(
                route.listingViewEdit.get({
                  id: item.objectId || '',
                }),
              )}
              likeDisabled={true}
            />
          ))
        )}
      </ListCardsGrid>
    </WrapperContent>
  );
};
