import React from 'react';
import {SuperResidentInfo} from '../../components/Auth/NoCommunity/SuperResidentInfo';
import {useGetSupportData} from '../../hooks/helpDesk';
import {useNotifyAboutSuperResident} from '../../hooks/notifications';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';
import {route} from '../../constants/routes';
import {useHistory, useParams} from 'react-router-dom';
import {useLinks} from '../../hooks/common';
import {useRecoilState} from 'recoil';
import {aliasCommunity, skipParams} from '../../states/community';
import {useAutoAddToCommunity} from '../../hooks/auth';
import {userState} from '../../states/session';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {typeUser as StateTypeUser} from '../../states/typeUser';

interface IProps {
  skip: () => void;
  hasLocation: boolean;
}

const SuperResidentInfoContainer: React.FC<IProps & {linkOnChat: string}> = ({linkOnChat, hasLocation, skip}) => {
  useNotifyAboutSuperResident(!hasLocation);
  const {getLink} = useLinks();
  const {push} = useHistory();
  const [typeUser] = useRecoilState(StateTypeUser);

  const redirectToRequest = () => {
    push(getLink(route.communityRequest.get({user: typeUser as TypeCommunity})));
  };

  return (
    <SuperResidentInfo
      feedBackLink={linkOnChat}
      hasLocation={hasLocation}
      skip={skip}
      redirectToRequest={redirectToRequest}
    />
  );
};

export const CommunitySuperResident: React.FC = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const {user} = useParams<{user: TypeCommunity}>();
  const {addByKey} = useAutoAddToCommunity();
  const [{autoAddKey}] = useRecoilState(skipParams);
  const {linkOnChat} = useGetSupportData();
  const [userRecoil] = useRecoilState(userState);
  const isHasLocation = Boolean(userRecoil?.Location);

  const successPath =
    user === TypeCommunity.manager
      ? getLink(route.profileCreateManager.path)
      : getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests}));

  const onSuccess = () => {
    if (aliasedComm?.objectId === autoAddKey) addByKey(autoAddKey);
    push(successPath);
  };

  return (
    <>
      <WrapperAuth>
        <SuperResidentInfoContainer skip={onSuccess} linkOnChat={linkOnChat} hasLocation={isHasLocation} />
      </WrapperAuth>
    </>
  );
};
