import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CreateProfileSteps, TypeCommunity} from '../../../../types/auth';
import {LivingWithType, TypeSteps} from '../../../../types/resident';
import {
  useGenerateUserData,
  useGenerateUsername,
  useProfile,
  useUpdateProfile,
  useUpdateProfilePhoto,
  useViewer,
} from '../../../../hooks/user';
import {getOptions} from '../../../../helpers/common';
import {route} from '../../../../constants/routes';
import {MISSING_TRANSLATION_KEY} from '../../../../constants/common';
import {Steps} from './Steps';
import {FormProfile} from '../../../../components/Account/Create/Forms/Profile';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {aliasCommunity, currentCommunity, skipParams, userCommunities} from '../../../../states/community';
import {useLinks} from '../../../../hooks/common';
import {
  PersonInterests,
  PersonRecomendations,
  TPersonInterestsData,
} from '../../../../components/Account/Create/Forms/PersonInterests';
import {useGetInterests} from '../../../../hooks/interests';
import {WrapperAuth} from '../../../../components/Auth/WrapperAuth';
import {
  useGetListCommunities,
  useGetUserPendingCommunities,
  useSetAutoAddedCommunity,
} from '../../../../hooks/community';
import {useGetMembersAvatar} from '../../../../hooks/people';
import {PhotoUpload} from '../../../../components/Account/Create/Forms/PhotoUpload';
import {GENDER_API} from '../../../../constants/env';
import domtoimage from 'dom-to-image';
import {FirstPost} from '../../../../components/Account/Create/Forms/FirstPost';
import {useCreatePost} from '../../../../hooks/feed';
import {PostTypeT} from '../../../../types/post';
import {useGetCurrencyValue} from '../../../../hooks/currency';
import {FirstListing} from '../../../../components/Account/Create/Forms/FirstListing';
import {ListingCategory} from '../../../../components/Account/Create/Forms/ListingCategory';
import {User as UserType, User} from '../../../../queries/types/user';
import {ListingCreated} from '../../../../components/Account/Create/Forms/ListingCreated';
import {onboardingServices, useCreateMultiplyListings} from '../../../../hooks/item';
import {userState} from '../../../../states';
import {OnboardingCategories, ServiceCategories, ServiceItem} from '../../../../types/item';
import {analyticsTrackFN} from '../../../../helpers/account';
import {TRecommendantions} from '../../../../types/interests';
import {convertDollarToCent} from '../../../../helpers/payment';
import {CommunityOptionsFields} from '../../../../types/community';
import {CommunityTypes} from '../../../../helpers/community';
import {useAutoAddToCommunity} from '../../../../hooks/auth';

const stepsMap = {
  //[CreateProfileSteps.Interests]: 1,
  [CreateProfileSteps.PersonInterests]: 4,
  [CreateProfileSteps.Profile]: 5,
  [CreateProfileSteps.PhotoUpload]: 6,
  [CreateProfileSteps.ListingCategory]: 7,
  [CreateProfileSteps.FirstListing]: 7,
  [CreateProfileSteps.ListingCreated]: 8,
  [CreateProfileSteps.Recommendations]: 9,
  [CreateProfileSteps.FirstPost]: 10,
};
export const CreateProfileResidentContainer: React.FC = () => {
  //common
  const viewer = useViewer();
  const profile = useProfile();
  const {t} = useTranslation();
  const {steps} = useParams<{steps: CreateProfileSteps}>();
  const [community, setCommunity] = useRecoilState(currentCommunity);
  const {data: communities} = useGetListCommunities(viewer?.id, 15, false);
  const setCommunities = useSetRecoilState(userCommunities);
  const {push} = useHistory();
  const {currency} = useGetCurrencyValue();
  const [{skipProfile, skipInterests, autoAddKey, skipAddress}] = useRecoilState(skipParams);
  const {addByKey} = useAutoAddToCommunity();
  const setUser = useSetRecoilState(userState);
  const birthDate = !profile?.birthDate ? null : new Date(profile.birthDate);
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const {getLink} = useLinks();
  const {setAutoAddedComm, loaded} = useSetAutoAddedCommunity(aliasedComm?.objectId, autoAddKey);
  const {data: pendingCommunity} = useGetUserPendingCommunities();
  const [members, setMembers] = useState<User[]>([]);
  const {getMembersAvatars} = useGetMembersAvatar();
  const canSkipServices = aliasedComm?.options?.[CommunityOptionsFields.requireListing] === false;
  const {loading: loadingGenerate, onSubmit: generateData} = useGenerateUserData({
    firstName: viewer?.firstName || '',
    city: viewer?.Location?.name || '',
    foodRecommendations: viewer?.recommendations?.eatery || '',
    moviesRecommendation: viewer?.recommendations?.movies || '',
    travelRecommendation: viewer?.recommendations?.places || '',
    interests: viewer?.personInterests?.reduce((acc, item) => acc + `${item?.value}, `, ''),
    language: viewer?.languages,
    occupation: viewer?.occupation,
  });
  const communityIds = [
    community?.objectId || '',
    ...(pendingCommunity
      ?.filter((item) => item?.objectId !== community?.objectId)
      ?.map((comm) => comm?.objectId || '') || []),
    ...communities
      ?.filter(
        (item) =>
          item?.objectId !== community?.objectId && !pendingCommunity.some((comm) => comm?.objectId === item?.objectId),
      )
      ?.map((comm) => comm?.objectId || ''),
  ]?.filter((item) => Boolean(item?.length));
  useEffect(() => {
    if (communities) {
      setCommunities(communities);
      if (communities?.length >= 2) {
        const user = {
          ...viewer,
          Communities: {
            count: communities?.length || viewer?.Communities?.count,
          },
        } as UserType;
        setUser(user);
      }
    }
    if (!community?.objectId) {
      setCommunity(communities?.find((item) => item?.type === CommunityTypes.circle) || communities?.[0]);
    }
  }, [communities?.length, community?.objectId]);

  useEffect(() => {
    if (community?.objectId && autoAddKey && skipAddress) {
      addByKey(autoAddKey);
    }
  }, [community?.objectId]);

  const getNavigationSteps = (next: boolean) => {
    let func = undefined;
    switch (steps) {
      case CreateProfileSteps.PersonInterests:
        func = next ? onSkipPersonInterests : community?.objectId ? undefined : onBackToAddress;
        break;
      case CreateProfileSteps.Profile:
        func = next ? onSkipProfile : onSkipInterests;
        break;
      case CreateProfileSteps.PhotoUpload:
        func = next ? undefined : onSkipPersonInterests;
        break;
      case CreateProfileSteps.FirstListing:
        func = next ? undefined : onSkipPhotoUpload;
        break;
      case CreateProfileSteps.Recommendations:
        func = next ? onSkipPersonRecommendation : undefined;
        break;
      case CreateProfileSteps.FirstPost:
        func = next ? onSkipFirstPost : onSkipListing;
        break;
      default:
        func = undefined;
        break;
    }
    return func;
  };

  useEffect(() => {
    if (
      (steps === CreateProfileSteps.ListingCreated || steps === CreateProfileSteps.FirstListing) &&
      viewer?.checkList?.addListing?.isChecked
    ) {
      return push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCreated})));
    }
    if (steps === CreateProfileSteps.FirstPost && viewer?.checkList?.addPost?.isChecked) {
      return goSuccessPath();
    }
  }, [steps]);

  useEffect(() => {
    if (skipProfile && skipInterests) goSuccessPath();
    if (skipProfile) push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PhotoUpload})));
    if (skipInterests) push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Profile})));
  }, []);

  useEffect(() => {
    loaded && setAutoAddedComm();
  }, [loaded]);
  //Route
  const goSuccessPath = () => {
    if (pendingCommunity?.[0]?.objectId) {
      push(
        getLink(
          route.joinByInvite.get({id: pendingCommunity?.[0]?.objectId || ''}),
          pendingCommunity?.[0]?.alias || '',
        ),
      );
      return;
    } else push(getLink(route.loop.path), community?.alias || '');
  };
  const onSkipInterests = () => {
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})));
  };

  const onBackToAddress = () => {
    push(getLink(route.address.get({user: TypeCommunity.resident})));
  };

  const onSkipPersonInterests = () => {
    analyticsTrackFN('Hobbies Skipped');
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Profile})));
  };

  const onSkipProfile = () => {
    analyticsTrackFN('Profile Skipped');
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PhotoUpload})));
  };

  const onSkipPersonRecommendation = () => {
    analyticsTrackFN('Recommendations Skipped');
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.FirstPost})));
  };

  const onSkipPhotoUpload = () => {
    if (!community?.objectId && !pendingCommunity?.[0]?.objectId) {
      goSuccessPath();
      return;
    }
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCategory})));
  };

  const onSkipFirstPost = () => {
    analyticsTrackFN('Introduction Post Skipped');
    goSuccessPath();
  };

  const onSkipListing = () => {
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Recommendations})));
  };

  //Interests
  const interests = useGetInterests();
  const optionsLivingWith = getOptions(LivingWithType).map((it) => {
    let label = t(`resident:create.account.livingWith.${it.value}`);
    if (label.includes(MISSING_TRANSLATION_KEY)) {
      label = it.label || '';
    }
    return {...it, label: label};
  });
  /*const loadData = () => JSON.parse(JSON.stringify(jsonData));
  const optionsInterestedIn = getOptionsFromJSON(loadData()).map((it) => {
    const label = t(`resident:create.account.optionsInterestedIn.${it.value}`);
    return {label: label, value: it.value};
  });*/
  //Profile
  const params = useUpdateProfile({
    initialState:
      {
        ...profile,
        livingWith: optionsLivingWith[0]?.label,
        birthDate: birthDate,
      } || {},
    typeCommunity: TypeCommunity.resident,
    /* steps === CreateProfileSteps.Interests
        ? push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})))
        :*/
    onSuccess: () =>
      skipInterests
        ? goSuccessPath()
        : steps === CreateProfileSteps.PersonInterests
        ? push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Profile})))
        : steps === CreateProfileSteps.Profile
        ? push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PhotoUpload})))
        : steps === CreateProfileSteps.Recommendations
        ? onSubmitRecommendation()
        : steps === CreateProfileSteps.PhotoUpload && (community?.objectId || pendingCommunity?.[0]?.objectId)
        ? push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCategory})))
        : goSuccessPath(),
  });

  const onSubmitRecommendation = () => {
    const count = Object?.keys(interestsData?.values?.recmnds || {})?.reduce((acc, item) => {
      if (interestsData?.values?.recmnds?.[item as keyof TRecommendantions]?.length) return acc + 1;
      else return acc;
    }, 0);
    analyticsTrackFN('Recommendations Provided', {
      recommendationsCount: count, // count based on recommendation input values
      placesToEatAndDrink: interestsData?.values?.recmnds?.places,
      MoviesOrShowsToWatch: interestsData?.values?.recmnds?.movies,
      PlacesOrCitiesToVisit: interestsData?.values?.recmnds?.eatery,
    });
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.FirstPost})));
  };

  const generateUsername = useGenerateUsername();
  const {values, onChange, loading, onSubmit} = params;
  const interestsData: TPersonInterestsData = {
    options: interests.options,
    submit: onSubmit as () => void,
    skipInterests: onSkipPersonInterests,
    skipRecs: onSkipPersonRecommendation,
    change: onChange,
    values: {
      interests: values.personInterests,
      recmnds: values.recommendations,
    },
    loading: loading || false,
  };

  useEffect(() => {
    const getMembers = async () => {
      if (!pendingCommunity?.[0]?.objectId && !community?.objectId) return;
      const result = await getMembersAvatars({
        variables: {
          communityId: pendingCommunity?.[0]?.objectId || community?.objectId || '',
        },
      });
      setMembers(result?.data?.getResidentsAvatar?.users || []);
    };
    getMembers();
  }, [pendingCommunity?.[0]?.objectId, community?.objectId]);

  //Photo
  const [gender, setGender] = useState<'man' | 'woman' | ''>('');
  const [loadingFile, setLoadingFile] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {call: updateAvatar, loading: loadingAvatar} = useUpdateProfilePhoto();
  const handleUpdate = async (photo: File | undefined) => {
    if (viewer?.objectId) await updateAvatar(photo, viewer?.objectId);
  };
  const getFileFromDom = async () => {
    try {
      setLoadingFile(true);
      const scale = 1;
      const node = ref.current;
      if (node) {
        const blob = await domtoimage.toBlob(node, {
          height: node.offsetHeight * scale,
          style: {
            transform: `scale(${scale})`,
            'border-radius': 0,
          },
          width: node.offsetWidth * scale,
          cacheBust: true,
        });
        const file = new File([blob], 'image.png');
        handleUpdate(file);
      }
    } catch (e) {
      console.log(e);
      onSkipPhotoUpload();
    } finally {
      setLoadingFile(false);
    }
  };
  useEffect(() => {
    const getPersonGender = async () => {
      const response = await fetch(
        `https://gender-api.com/get?name=${viewer?.firstName}&country=${viewer?.Location?.Country?.name}&key=${GENDER_API}`,
      );
      if (response.ok) {
        const data = await response.json();
        const gender = data.gender !== 'unknown' ? (data.gender === 'male' ? 'man' : 'woman') : '';
        setGender(gender);
      }
    };
    getPersonGender();
  }, [viewer?.firstName, viewer?.Location?.Country?.name]);
  //Post
  const {
    onChange: onChangePost,
    onSubmit: onPostCreate,
    values: valuesPost,
    error: errorPost,
    loading: loadingPost,
  } = useCreatePost({
    initialState: {text: '', isMulti: false, type: PostTypeT.message, sentAs: undefined},
  });
  const onSubmitPost = async () => {
    let promises = [];
    if (pendingCommunity?.[0]?.type === CommunityTypes.circle || community?.type === CommunityTypes.circle) {
      promises = [
        ...pendingCommunity
          ?.filter((comm) => comm?.type !== CommunityTypes.neighbourhood && comm?.objectId !== community?.objectId)
          ?.map((comm) => onPostCreate(comm?.objectId)),
        ...communities
          ?.filter(
            (item) =>
              item?.type !== CommunityTypes.neighbourhood &&
              item?.objectId !== community?.objectId &&
              !pendingCommunity.some((comm) => comm?.objectId === item?.objectId),
          )
          ?.map((comm) => onPostCreate(comm?.objectId)),
      ];
      if (community?.objectId && community?.type !== CommunityTypes.neighbourhood)
        promises.push(onPostCreate(community?.objectId));
    } else {
      promises = communityIds?.map((id) => onPostCreate(id));
    }
    await Promise.all(promises);
    goSuccessPath();
  };
  //Listings
  const forwardListingCreate = () => {
    let analyticSelected = {};
    selectedCategory.forEach((item) => {
      analyticSelected = {...analyticSelected, [item]: true};
    });
    Object.keys(onboardingServices).forEach((item) => {
      if (!selectedCategory.includes(item)) {
        analyticSelected = {...analyticSelected, [item]: false};
      }
    });
    analyticsTrackFN('Marketplace Categories Selected', {
      categoriesCount: selectedCategory?.length || 0,
      ...analyticSelected,
    });
    if (!selectedCategory?.includes('others')) setSelectedCategory([...selectedCategory, 'others']);
    push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.FirstListing})));
  };
  const {
    onSubmit: onCreateListing,
    listings,
    setListings,
    loading: loadingCreateListing,
    setSelectedCategory,
    selectedCategory,
    items,
  } = useCreateMultiplyListings({
    communityIds: communityIds,
    onSuccess: (
      listId?: string[],
      init?: {[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>},
    ) => {
      analyticsTrackFN('Marketplace Services Added', {
        servicesCount: items?.length,
        listerName: `${viewer?.firstName}`,
        listerEmail: viewer?.email,
        listerId: viewer?.objectId,
        communityName: pendingCommunity?.[0]?.name || community?.name,
        communityId: pendingCommunity?.[0]?.objectId || community?.objectId,
        communityType: pendingCommunity?.[0]?.type || community?.type, // building, neighbourhood, circle
        services: items?.map((item, index) => {
          let initial: ServiceItem = {} as ServiceItem;
          Object.keys(init || {})?.forEach((el) => {
            const category = init?.[el as keyof typeof init];
            return Object?.keys(category || {})?.find((cat) => {
              if (category?.[cat as keyof typeof category]?.description === item?.description) {
                initial = category?.[cat as keyof typeof category];
              }
            });
          });
          return {
            listingId: listId?.[index],
            title: item?.title,
            rate: item?.price,
            currency: currency?.code,
            bookingType: item?.period,
            category: item?.description,
            defaultRate: item?.price === convertDollarToCent(initial?.price),
            customService: item?.description !== initial?.description,
          };
        }),
      });
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCreated})));
    },
  });

  useEffect(() => {
    if (steps === CreateProfileSteps.FirstListing && !listings) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCategory})));
    }
  }, [!!listings, steps]);

  return (
    <Steps step={TypeSteps.profile}>
      <WrapperAuth step={stepsMap[steps]} previousPage={getNavigationSteps(false)} nextPage={getNavigationSteps(true)}>
        {/*steps === CreateProfileSteps.Interests && (
          <SelectInterests params={params} options={optionsInterestedIn} onSkip={onSkipInterests} />
        )*/}
        {steps === CreateProfileSteps.PersonInterests && <PersonInterests data={interestsData} />}
        {steps === CreateProfileSteps.Profile && (
          <FormProfile
            user={viewer || undefined}
            members={members}
            params={params}
            typeCommunity={TypeCommunity.resident}
            name={viewer?.firstName}
            generateUsername={generateUsername}
            generateData={generateData}
            optionsLivingWith={optionsLivingWith}
            loadingGenerate={loadingGenerate}
            onSkipProfile={onSkipProfile}
          />
        )}
        {steps === CreateProfileSteps.Recommendations && <PersonRecomendations data={interestsData} />}
        {steps === CreateProfileSteps.PhotoUpload && (
          <PhotoUpload
            avatarRef={ref}
            getFileFromDom={getFileFromDom}
            gender={gender}
            photoSrc={viewer?.Avatar?.file?.url || ''}
            upload={handleUpdate}
            loadingAvatar={loadingAvatar || loadingFile}
            submit={onSkipPhotoUpload}
          />
        )}
        {steps === CreateProfileSteps.FirstPost && (
          <FirstPost
            firstName={viewer?.firstName}
            bio={viewer?.bio}
            recommendations={viewer?.recommendations}
            generateData={generateData}
            loadingGenerate={loadingGenerate}
            skip={onSkipFirstPost}
            values={valuesPost}
            loading={loadingPost || false}
            handleSubmit={onSubmitPost}
            handleChange={onChangePost}
            error={errorPost}
          />
        )}
        {steps === CreateProfileSteps.ListingCategory && (
          <ListingCategory
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            nextStep={forwardListingCreate}
            onSkipListing={onSkipListing}
            canSkipServices={canSkipServices}
          />
        )}
        {steps === CreateProfileSteps.FirstListing && (
          <FirstListing
            currSign={currency?.sign || ''}
            onSubmit={onCreateListing}
            listings={listings as {[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>}}
            setListings={setListings}
            loading={loadingCreateListing}
            onSkipListing={onSkipListing}
            canSkipServices={canSkipServices}
          />
        )}
        {steps === CreateProfileSteps.ListingCreated && (
          <ListingCreated onNext={onSkipListing} items={items} sign={currency?.sign || ''} />
        )}
      </WrapperAuth>
    </Steps>
  );
};
