import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {PageCreateCommunity} from '../../../../components/Account/Create/PageCreateCommunity';
import {route} from '../../../../constants/routes';
import {useLinks} from '../../../../hooks/common';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {CommunityStateType, currentCommunity, userCommunities} from '../../../../states/community';
import {TypeCommunity} from '../../../../types/auth';
import {getCurrentCommunityFromLS} from '../../../../helpers/community';
import {useViewer} from '../../../../hooks/user';
import {typeUser} from '../../../../states/typeUser';

export type TypeStep = 'profile' | 'plan' | 'paymentInfo' | 'community';

type PageProps = {
  step: TypeStep;
};

export const Steps: React.FC<PageProps> = ({children, step}) => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [community, setCommunity] = useRecoilState(currentCommunity);
  const [communities] = useRecoilState(userCommunities);
  const viewer = useViewer();
  const setTypeUser = useSetRecoilState(typeUser);

  const dataSteps = useMemo(() => {
    return {
      ...(!!community
        ? {}
        : {
            profile: {
              code: 0,
              title: 'profile:steps.manager.profile',
              link: getLink(route.profileCreateManager.path),
            },
          }),
      plan: {
        code: 1,
        title: 'profile:steps.manager.plan',
        link: getLink(route.selectPlan.path),
      },
      paymentInfo: {
        code: 2,
        title: 'profile:steps.manager.paymentInfo',
        link: getLink(route.paymentCommunity.path),
      },
      community: {
        code: 3,
        title: 'profile:steps.manager.community',
        link: getLink(route.createCommunity.path),
      },
    };
  }, [community]);

  const handleBack = () => {
    if (!!community) {
      const returnCommunity = getCurrentCommunityFromLS(communities as any);
      setCommunity(returnCommunity as CommunityStateType);
      setTypeUser(
        returnCommunity?.Owner?.objectId === viewer?.objectId ? TypeCommunity.manager : TypeCommunity.resident,
      );
      return push(getLink(route.loop.get()));
    }
    if (!step || step === 'profile') return;
    const prevEl = Object.values(dataSteps).find((it) => it.code === dataSteps[step].code - 1);
    push(prevEl?.link || dataSteps[step].link);
  };

  return (
    <PageCreateCommunity
      dataSteps={dataSteps}
      step={step}
      backPrevPage={handleBack}
      isVisibleButton={!!communities?.length}
      showSteps={step !== 'profile'}>
      {children}
    </PageCreateCommunity>
  );
};
