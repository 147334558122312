import {Company} from '../queries/types/company';
import {TCompany, TCompanyFormValues, TPromoCTA} from '../types/company';
import {FormValidation} from './FormValidation';

export const CompanySchema = FormValidation.schema<Partial<TCompanyFormValues>>({
  images: FormValidation.handler((value, data) => {
    const addedImage = data.images?.filter((el) => !!el.value);
    if (!addedImage?.length) return false;
    return true;
  }, 'error:allRequired'),
  name: FormValidation.string('error:allRequired'),
  description: FormValidation.string('error:allRequired').length({gte: 9}),
  category: FormValidation.string('error:allRequired'),
  website: FormValidation.string('error:allRequired'),
  isPromotion: FormValidation.boolean('error:allRequired'),
  promoTag: FormValidation.handler((v, d) => {
    if (!d.isPromotion) return true;
    return Boolean(v);
  }, 'error:allRequired'),
  promoDescr: FormValidation.handler((v, d) => {
    if (!d.isPromotion) return true;
    return Boolean(v);
  }, 'error:allRequired'),
  promoCTA: FormValidation.handler((v, d) => {
    if (!d.isPromotion) return true;
    return Boolean(v);
  }, 'error:allRequired'),
  valueCTA: FormValidation.handler((v, d) => {
    if (!d.isPromotion || d.promoCTA === TPromoCTA.none || d.promoCTA === TPromoCTA.interested) return true;
    return Boolean(v);
  }, 'error:allRequired'),
});

interface IToStateCompany {
  (params: {item: Company; communityId?: string}): TCompany;
}

export const toStateCompany: IToStateCompany = ({item, communityId}) => {
  return {
    ...item,
    images: item.images || [],
    isFeatured: communityId ? item.Featured?.some((el) => el.id === communityId) : false,
    countClicks: item.totalClicks,
    countViews: item.totalViews,
  } as TCompany;
};

interface IToFormValuesCompany {
  (item: TCompany): TCompanyFormValues;
}

export const toFormValuesCompany: IToFormValuesCompany = (item) => {
  const {
    name,
    description,
    images,
    category,
    website,
    phone,
    promoDescr,
    promoTagline,
    promotion,
    visitedWebsite,
    revealCode,
    promoCTA,
  } = item;

  return {
    images: Array.from({length: 6}, (v, k) => ({
      id: images?.[k]?.objectId || String(k),
      value: images?.[k]?.file?.url || '',
      order: k,
    })),
    name,
    description,
    category,
    website,
    phone,
    promoTag: promoTagline,
    promoDescr,
    isPromotion: promotion,
    serverImages: images,
    valueCTA: visitedWebsite || revealCode,
    promoCTA,
  };
};
