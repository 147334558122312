import React from 'react';
import {
  receivingVariants,
  SettingNotificationT,
  SettingNotificationValuesT,
  StateNotificationSettingsType,
} from '../../../types/notifications';
import {FormType} from '../../../types/settings';
import {
  NotificationRow,
  NotificationAction,
  NotificationDescr,
  NotificationName,
  NotificationStatus,
  NotificationValues,
  NotificationPopupWrapper,
  PopupDescr,
  PopupTitle,
  NotificationPopupContainer,
  CloseBtn,
  Toggle,
  UpgradeButton,
  PromptBlock,
  ModalOverlay,
} from './styles';
import {Popup} from '../../../ui-kit/Popup';
import {useTranslation} from 'react-i18next';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {getNotificationValues} from '../../../helpers/notifications';

interface SettingsFormProps {
  notificationsList: SettingNotificationT[];
  form: FormType<StateNotificationSettingsType>;
  onEdit: (type: string) => void;
  editing?: string;
  onClosePopup: () => void;
  onUpdate: () => void;
  selected?: SettingNotificationT;
  toggleValue: (key: receivingVariants, value: boolean) => void;
  updateLoading: boolean;
}
export const SettingsForm: React.FC<SettingsFormProps> = ({
  notificationsList,
  form,
  onEdit,
  onClosePopup,
  toggleValue,
  editing,
  selected,
  onUpdate,
  updateLoading,
}) => {
  const {t} = useTranslation();
  const updateButtonTitle = t('settings:notifications.update');
  const editButtonTitle = t('settings:notifications.edit');
  const requiredText = t('settings:notifications.required');
  const onText = `${t('settings:notifications.on')}: `;
  const receive = {
    push: t('settings:notifications.push'),
    sms: t('settings:notifications.sms'),
    email: t('settings:notifications.email'),
  };

  return (
    <>
      {notificationsList.map((el) => (
        <NotificationItem
          key={el.type}
          {...el}
          onEdit={onEdit}
          values={getNotificationValues(el, form.values)}
          buttonTitle={editButtonTitle}
          onText={onText}
          receive={receive}
        />
      ))}
      <Popup isOpen={!!editing} onClose={onClosePopup}>
        <ModalOverlay onClick={onClosePopup}>
          <NotificationPopupContent
            selected={selected}
            onClose={onClosePopup}
            buttonTitle={updateButtonTitle}
            toggleValue={toggleValue}
            values={getNotificationValues(selected, form.values)}
            onUpdate={onUpdate}
            requiredText={requiredText}
            updateLoading={updateLoading}
            receive={receive}
          />
        </ModalOverlay>
      </Popup>
    </>
  );
};

type NotifItemProps = {
  name: string;
  descr: string;
  type: string;
  onEdit: (type: string) => void;
  values?: SettingNotificationValuesT;
  buttonTitle: string;
  onText: string;
  receive: {
    push: string;
    sms: string;
    email: string;
  };
};

const NotificationItem: React.FC<NotifItemProps> = ({
  name,
  descr,
  type,
  onEdit,
  buttonTitle,
  values,
  onText,
  receive,
}) => {
  const handleEdit = () => onEdit(type);
  const enabled = Object.keys(values || {})
    .map((el) => (values?.[el as receivingVariants] ? receive?.[el as receivingVariants] || el : null))
    .filter((e) => !!e);
  return (
    <NotificationRow>
      <NotificationName>{name}</NotificationName>
      <NotificationDescr>{descr}</NotificationDescr>
      <NotificationStatus>
        <NotificationAction onClick={handleEdit}>{buttonTitle}</NotificationAction>
        <NotificationValues>
          {!!enabled?.length && onText}
          {enabled.join(', ')}
        </NotificationValues>
      </NotificationStatus>
    </NotificationRow>
  );
};

type NotifPopupProps = {
  selected?: SettingNotificationT;
  onClose: () => void;
  buttonTitle: string;
  toggleValue: (key: receivingVariants, value: boolean) => void;
  values?: SettingNotificationValuesT;
  onUpdate: () => void;
  requiredText?: string;
  updateLoading: boolean;
  receive: {
    push: string;
    sms: string;
    email: string;
  };
};

const NotificationPopupContent: React.FC<NotifPopupProps> = ({
  selected,
  onClose,
  buttonTitle,
  toggleValue,
  values,
  onUpdate,
  requiredText,
  updateLoading,
  receive,
}) => {
  const toggle = (key: receivingVariants) => (value: boolean) => toggleValue(key, value);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();
  return (
    <NotificationPopupContainer>
      <NotificationPopupWrapper onClick={stop}>
        <CloseBtn onClick={onClose}>
          <IconSvg type="close" />
        </CloseBtn>
        <PopupTitle>{selected?.name}</PopupTitle>
        <PopupDescr>{selected?.descr}</PopupDescr>
        <Toggle onChange={toggle('push')} checked={values?.push} label={receive.push} />
        <Toggle onChange={toggle('email')} checked={values?.email} label={receive.email} />
        <Toggle onChange={toggle('sms')} checked={values?.sms} label={receive.sms} />
        <UpgradeButton onClick={onUpdate} loading={updateLoading}>
          {buttonTitle}
        </UpgradeButton>
        {selected?.required && <PromptBlock>{requiredText}</PromptBlock>}
      </NotificationPopupWrapper>
    </NotificationPopupContainer>
  );
};
