import {gql} from '@apollo/client';
import {ReferralFragment} from './fragments/referral';
import {Connection} from './types/parse';
import {Referral} from './types/referral';

export const getUserByReferral = gql`
  mutation getUserByReferral($referralCode: String!) {
    getUserByReferral(input: {referralCode: $referralCode}) {
      user
    }
  }
`;

export interface CreateUserReferralParams {
  userId: string;
  referralCode: string;
}

export const createUserReferral = gql`
  mutation createUserReferral($userId: String!, $referralCode: String!) {
    createUserReferral(input: {userId: $userId, referralCode: $referralCode})
  }
`;

export interface GetReferralsRes {
  referrals: Connection<Referral>;
}

export const getReferrals = gql`
  query referrals($where: ReferralWhereInput, $order: [ReferralOrder!], $skip: Int, $after: String, $first: Int) {
    referrals(where: $where, order: $order, skip: $skip, after: $after, first: $first) {
      edges {
        node {
          ...ReferralFragment
        }
      }
    }
  }

  ${ReferralFragment}
`;
