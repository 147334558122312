import React, {useEffect, useRef} from 'react';
import {User} from '../../queries/types/user';
import {TypeCommunity} from '../../types/auth';
import {TCommunity} from '../../types/community';
import {PermissionsList} from '../../types/settings';
import {SeparatedSelect} from '../../ui-kit/Form/Select/SeparatedSelect';
import {Feed} from '../../components/Feed';
import {PostCreate} from '../../components/Loop/PostCreate';
import {FeedSelectWrapper} from '../../components/Feed/styles';
import {useLinks, usePageView} from '../../hooks/common';
import {useFeed} from '../../hooks/feed';
import {entities, useReport} from '../../hooks/report';
import {useGenerateUserData} from '../../hooks/user';
import {route} from '../../constants/routes';
import {useHistory} from 'react-router-dom';

type TProps = {
  user: User | null;
  typeUser: TypeCommunity;
  viewerId?: string;
  currentComm: Partial<TCommunity>;
  permissions?: PermissionsList[];
  toggleCreating: (v: boolean) => void;
};

export const CommunityFeed = ({typeUser, user, viewerId, currentComm, permissions, toggleCreating}: TProps) => {
  const {getLink} = useLinks();
  const {push} = useHistory();
  const reportItemActions = useReport(entities.listing);
  const {loading: loadingGenerate, onSubmit: generateData} = useGenerateUserData({
    firstName: user?.firstName || '',
    city: user?.Location?.name || '',
    foodRecommendations: user?.recommendations?.eatery || '',
    moviesRecommendation: user?.recommendations?.movies || '',
    travelRecommendation: user?.recommendations?.places || '',
    interests: user?.personInterests?.reduce((acc, item) => acc + `${item?.value}, `, ''),
    language: user?.languages,
    occupation: user?.occupation,
  });
  const {view, setView} = usePageView();
  const {
    menuActions,
    postActions,
    hasMorePosts,
    showOptions,
    getPollData,
    handleRefetch,
    handleSelect,
    actions,
    handleFetchMore,
    adminLabels,
    loading,
    refetch,
    postType,
    data,
    postOptions,
    addLocalPost,
    localDeleted,
    onLocalDelete,
    onCreateRetry,
    setLocalError,
    itemsLiked,
    onLikeItem,
    hasPost,
  } = useFeed({typeUser, viewerId, currentComm});

  const handleRedirect = () => push(getLink(route.generateItem.path));

  if (!viewerId) return null;
  return (
    <>
      <PostCreate
        user={user}
        community={currentComm}
        refetchPosts={refetch}
        typeUser={typeUser}
        permissions={permissions}
        toggleCreating={toggleCreating}
        addLocalPost={addLocalPost}
        setLocalError={setLocalError}
        loadingGenerate={loadingGenerate}
        generateData={hasPost ? undefined : generateData}
      />
      {showOptions && (
        <FeedSelect>
          <SeparatedSelect
            options={postOptions}
            value={postType}
            onChange={handleSelect}
            view={view}
            setView={setView}
            loading={loading}
          />
        </FeedSelect>
      )}
      <Feed
        posts={data}
        typeUser={typeUser}
        menuActions={menuActions}
        viewerId={viewerId}
        postActions={postActions}
        loading={loading}
        refetch={handleRefetch}
        community={currentComm}
        loadMore={handleFetchMore}
        hasMorePosts={hasMorePosts}
        viewer={user}
        actions={actions}
        adminLabels={adminLabels}
        getPollData={getPollData}
        postType={postType?.value}
        localDeleted={localDeleted}
        onLocalDelete={onLocalDelete}
        onCreateRetry={onCreateRetry}
        itemsLiked={itemsLiked}
        onLikeItem={onLikeItem}
        reportActions={reportItemActions}
        toggleCreating={toggleCreating}
        generateListing={handleRedirect}
      />
    </>
  );
};

const FeedSelect: React.FC = ({children}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const isolateTouch = (ev: TouchEvent) => {
      ev.stopPropagation();
    };

    scrollRef?.current?.addEventListener('touchstart', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchmove', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchend', isolateTouch, {passive: true});
    return () => {
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchstart', isolateTouch, {passive: true});
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchmove', isolateTouch, {passive: true});
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchend', isolateTouch, {passive: true});
    };
  }, [scrollRef]);
  return <FeedSelectWrapper ref={scrollRef}>{children}</FeedSelectWrapper>;
};
