import {gql} from '@apollo/client';

export const CurrencyFragment = gql`
  fragment CurrencyFragment on Currency {
    id
    objectId
    updatedAt
    createdAt
    name
    code
    sign
  }
`;
