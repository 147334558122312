import React from 'react';
import {MemoHeaderAuth} from './HeaderAuth';
import {InnerAuth, NoteWrapper} from './styles';
import {AuthTab, TypeCommunity} from '../../types/auth';
import {SignUpForm} from './SignUpForm';
import {useTranslation} from 'react-i18next';
import {Seo} from '../SEO';
import {ISignUpRes} from '../../hooks/auth';
import {makeSeoKeywords} from '../../helpers/common';
import {MemoHelpCentreButton} from '../Account/Popups/HelpCentreButton/HelpCentreButton';
import {WrapperAuth} from './WrapperAuth';
import {Note} from '../../ui-kit/Note';

interface IProps {
  linkUrl: string;
  typeCommunity: TypeCommunity;
  dataAuth: ISignUpRes;
}

export const SignUp: React.FC<IProps> = ({typeCommunity, dataAuth}) => {
  const {t} = useTranslation();
  const seoTitle = t('common:seoTitle', {text: t('auth:signUp.title')});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('auth:signUp.title')]);

  const isSighUpForm = typeCommunity === TypeCommunity.resident || typeCommunity === TypeCommunity.manager;
  return (
    <WrapperAuth tab={AuthTab.signUp} isStart={true}>
      <Seo title={seoTitle} keywords={seoKeywords} />
      <InnerAuth>
        <MemoHeaderAuth
          title={t(`auth:titleAuth.${typeCommunity}`, {
            typeAuth: t('auth:signUp.title'),
          })}
        />
        <NoteWrapper>
          <Note iconType={'lock'} text={t('auth:signUp.note')} />
        </NoteWrapper>
        {/*{isAuthButtonsResident && <AuthButtonsResident typeAuth={AuthTab.signUp} handleIsEmail={handleIsEmail} />}*/}
        {isSighUpForm && <SignUpForm typeCommunity={typeCommunity} dataAuth={dataAuth} />}
        <MemoHelpCentreButton isShown={!isSighUpForm} />
      </InnerAuth>
    </WrapperAuth>
  );
};
