import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {Seo} from '../../../components/SEO';
import {PageManagerContainer} from './Manager';
import {PageResidentContainer} from './Resident';
import {makeSeoKeywords} from '../../../helpers/common';
import {currentCommunity, userPermissions} from '../../../states/community';
import {typeUser as StateTypeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {PermissionsList} from '../../../types/settings';
import {EmptyTag, MobContainerProps} from '../../MobileTabs';
import {NavContainer} from '../../Navigation';

import {useViewerId} from '../../../hooks/user';
import {prevLocation} from '../../../states/navState';
import {route} from '../../../constants/routes';

export const ListingsPage: React.FC<MobContainerProps> = ({hideNav}) => {
  const [typeUser] = useRecoilState(StateTypeUser);
  const viewerId = useViewerId('objectId');
  const [community] = useRecoilState(currentCommunity);
  const setCurrentLocation = useSetRecoilState(prevLocation);
  const [loading, setLoading] = useState(false);
  const [permissions] = useRecoilState(userPermissions);
  const {t} = useTranslation();
  const textSeo = t('common:seo.listings.listings', {
    text: community?.name || '',
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const canManage = useMemo(
    () => permissions.includes(PermissionsList.manageListing) && typeUser === TypeCommunity.admin,
    [permissions, typeUser],
  );
  const Wrapper = hideNav ? EmptyTag : NavContainer;

  useEffect(() => {
    if (!typeUser || !community) {
      return setLoading(true);
    }
    setLoading(false);
  }, [typeUser, community]);
  useEffect(() => {
    setCurrentLocation(route.listings.path);
  }, []);
  if (loading || !typeUser || !viewerId || !community) {
    return <LottieLoader allScreen={true} $isVisible={true} />;
  }

  return (
    <Wrapper>
      <Seo title={textSeo} keywords={seoKeywords} />
      {(typeUser === TypeCommunity.manager || canManage) && (
        <PageManagerContainer viewerId={viewerId} community={community} />
      )}
      {(typeUser === TypeCommunity.resident || typeUser === TypeCommunity.admin) && !canManage && (
        <PageResidentContainer viewerId={viewerId} community={community} />
      )}
    </Wrapper>
  );
};
