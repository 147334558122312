import React from 'react';
import {CustomImage, CustomLabelPhoto, IconPhoto, InnerIconPhoto, InnerImagePhoto} from './styles';
import {PhotoLoaderProps} from './PhotoLoader';
import {Responsive} from '../../../ui-kit/Responsive';

export const PhotoCard: React.FC<PhotoLoaderProps> = ({
  children,
  width,
  height,
  isUnderCard,
  aspectRatio,
  src,
  fallbackSrc,
  showError,
  CoverElement,
  isListing,
  bordered,
  ...props
}) => {
  return (
    <CustomLabelPhoto
      width={width}
      height={height}
      $isUndercard={isUnderCard}
      $bordered={bordered}
      $isListing={isListing}
      {...props}>
      <Responsive aspectRatio={aspectRatio}>
        {src ? (
          <InnerImagePhoto>
            <CustomImage src={src as string} fallbackSrc={fallbackSrc} alt={'item photo'} $isListing={isListing} />
          </InnerImagePhoto>
        ) : (
          <InnerIconPhoto $showError={showError}>
            {CoverElement || (
              <IconPhoto width={'49'} height={'52'} viewBox="0 0 49 52" type={'camera'} stroke={'gray'} />
            )}
          </InnerIconPhoto>
        )}
        {children}
      </Responsive>
    </CustomLabelPhoto>
  );
};
