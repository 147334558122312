import React, {useState} from 'react';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {LabelCard} from './LabelCard';
import {
  ContainerListingCard,
  HiddenMenu,
  IconStar,
  InnerListingCard,
  InnnerListingCardBtns,
  MenuButtonWrapper,
} from './styles';
import {PhotoLoaderProps} from '../common/PhotoCard/PhotoLoader';
import {useTranslation} from 'react-i18next';
import {getIsIncludesCmnt} from '../../helpers/item';

import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Media} from '../../ui-kit/theme';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {Link} from '../../ui-kit/Typography/Link';
import {IGetCurrValue} from '../../types/common';
import {isAmenityInCommunity} from '../../hooks/amenity';
import {
  getMenuAmenities,
  GetMenuAmenitiesFuncOptions,
  GetMenuAmenitiesOptions,
  getMobileMenuAmenities,
} from '../../helpers/amenity';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {PopupView} from '../common/Modal/PopupView';
import {getResize} from '../../helpers/file';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {Amenity} from '../../queries/types/amenity';

type AmenityCardT = {
  to: string;
  editPath?: string | null;
  amenity: Partial<Amenity>;
  src?: string;
  fallbackSrc?: string;
  communityId?: string;
  onDelete?: (id?: string) => Promise<boolean>;

  isHideLabel?: boolean;
  isManager?: boolean;
  isOwner?: boolean;
  reportActions?: reportActions;
  isCardInPost?: boolean;
  getSignValue: IGetCurrValue;
  onUnPublish?: (id?: string) => () => Promise<void>;
  onPublish?: (id?: string) => () => Promise<void>;
};

export const AmenityCard: React.FC<PhotoLoaderProps & AmenityCardT> = ({
  to,
  src,
  amenity,
  aspectRatio,
  communityId,
  isOwner,
  isCardInPost,
  onDelete,
  onUnPublish,
  isManager,
  onPublish,
}) => {
  const {t} = useTranslation();
  const {name, rewardType} = amenity;
  const isPublished = isAmenityInCommunity({amenity, commId: communityId});
  const isFeatured = getIsIncludesCmnt(amenity?.Featured, communityId);
  // const isLabel = (!isPublished || isUpdated || isAdmHidden) && !isHideLabel;
  const isOpacity = !isPublished;
  const {getLink} = useLinks();
  const pramenityRedirect = (e: React.SyntheticEvent) => e.preventDefault();
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const unPublish = onUnPublish?.(amenity.objectId);
  const publish = onPublish?.(amenity.objectId);
  const del = () => onDelete?.(amenity.objectId);

  const getMenuAmenitiesOptions: GetMenuAmenitiesOptions = {
    objectId: amenity?.objectId,
    editLink: getLink(route.editAmenity.get({id: amenity.id || '/'})),
    previewLink: getLink(route.amenityView.get({id: amenity.id || '/'})),
    manageLink: getLink(route.amenityViewEdit.get({id: amenity.id || '/'})),
    isPublished,
    isOwner: isOwner || isManager,
  };
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  const getMenuItemsFunctions: GetMenuAmenitiesFuncOptions = {
    t,
    deleteCallBack: handleOpenDelete,
    unPublishCallBack: unPublish,
    publishCallBack: publish,
  };

  const menuItems: MenuItemType[] =
    isOwner || isManager ? [...getMenuAmenities(getMenuAmenitiesOptions, getMenuItemsFunctions)] : [];
  const mobileMenuItems: MobileMenuEntry[] =
    isOwner || isManager ? [...getMobileMenuAmenities(getMenuAmenitiesOptions, getMenuItemsFunctions)] : [];
  const modalsText = {
    delete: {
      title: t('common:modals.delete.title'),
      okText: t('common:modals.delete.button'),
      cancelText: t('common:modals.delete.close'),
    },
  };
  return (
    <>
      <ContainerListingCard>
        <Link to={to}>
          <InnerListingCard isOpacity={isOpacity}>
            <PhotoCard aspectRatio={aspectRatio} src={getResize(src, 'md')} fallbackSrc={src} />
            {isFeatured && <IconStar type={'star'} fill={'orange'} stroke={'white'} />}
            <LabelCard rewardType={rewardType} name={name} bookingTiming={amenity.bookingTiming} />
          </InnerListingCard>
          <InnnerListingCardBtns>
            <MenuButtonWrapper onClick={pramenityRedirect}>
              {!!menuItems.length && !isCardInPost && (
                <HiddenMenu mq={Media.down.s}>
                  <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
                </HiddenMenu>
              )}
              {!!mobileMenuItems.length && !isCardInPost && (
                <HiddenMenu mq={Media.up.m}>
                  <MobileMenuButton entries={mobileMenuItems} isPortal={true} portalProvider={IsModalProvider} />
                </HiddenMenu>
              )}
            </MenuButtonWrapper>
          </InnnerListingCardBtns>
        </Link>
      </ContainerListingCard>
      {isShowModalDelete && (
        <PopupView>
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={del}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        </PopupView>
      )}
    </>
  );
};
