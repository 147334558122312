import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ConfigureAdminsSectionWrapper, ConfirmBtn, PermissionsWrapper, WrapperWithOffset, TopWrapper} from './styles';
import {getPermissionsList} from '../../../../helpers/community';
import {LabelValuePair, TAdminsPermissions, TUserData} from '../../../../types/settings';
import {Input} from '../../../../ui-kit/Form/Input';
import {Select} from '../../../../ui-kit/Form/Select';
import {Br} from '../../../../ui-kit/Typography/Br';
import {Text} from '../../../../ui-kit/Typography/Text';
import {CheckItem} from '../../../common/CheckItem';
import {TLoadingStates} from '../../../Feed/types';
import {Button} from '../../../../ui-kit/Button/Button';

import {useGetUserPermissions} from '../../../../hooks/user';

interface IConfigureAdminsForm {
  userId?: string;
  communityId?: string;
  handleUserChange: (data: Partial<TUserData>) => void;
  residents?: Array<{label: string; value: string}>;
  adminsId: Array<string>;
  handleMakeAdmin: () => Promise<void>;
  handleEditAdmin: () => Promise<void>;
  user?: Partial<TUserData>;
  editMode?: boolean;
  setAdminAdded: React.Dispatch<
    React.SetStateAction<{user: string; label: string; permissions: Array<keyof TAdminsPermissions>} | null>
  >;
  adminAdded: {user: string; label: string} | null;
  loadingStates: TLoadingStates;
}

export const ConfigureAdminSection: React.FC<IConfigureAdminsForm> = ({
  handleUserChange,
  residents,
  handleMakeAdmin,
  handleEditAdmin,
  user,
  editMode,
  adminsId,
  adminAdded,
  setAdminAdded,
  loadingStates,
  communityId,
  userId,
}) => {
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const adminPermissions = useGetUserPermissions({userId: userId || '', communityId: communityId || ''});
  const {t} = useTranslation();

  const {makeAdminLoading, editAdminLoading} = loadingStates;

  const handleSelectUser = (values: LabelValuePair[]) => {
    handleUserChange(values?.[0] || {});
  };
  const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUserChange({userLabel: event.target.value});
  };

  const getOnChange = (keyData: keyof TAdminsPermissions) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      return handleUserChange({...user, permissions: user?.permissions?.filter((item) => item !== keyData) || []});
    }
    return handleUserChange({...user, permissions: [...(user?.permissions || []), keyData]});
  };

  const confirmMakeAdmin = () => {
    handleMakeAdmin().then(() =>
      setAdminAdded({
        label: user?.userLabel || t('people:person.labels.manager'),
        user: user?.label || '',
        permissions: user?.permissions || [],
      }),
    );
  };

  const confirmEditAdmin = () => {
    handleEditAdmin();
  };

  const confirm = () => {
    editMode ? confirmEditAdmin() : confirmMakeAdmin();
  };

  const addAnother = () => {
    setAdminAdded(null);
  };

  useEffect(() => {
    handleUserChange({...user, permissions: adminPermissions});
  }, [adminPermissions]);

  const permissionsList = getPermissionsList();

  const changeAllPermissions = () => {
    if (isSelectAll) {
      return handleUserChange({
        ...user,
        permissions: user?.permissions?.filter((item) => !permissionsList.some((el) => el.keyData === item)) || [],
      });
    }

    const newPermissionsList = permissionsList.filter((el) => !user?.permissions?.includes(el.keyData));

    return handleUserChange({
      ...user,
      permissions: [...(user?.permissions || []), ...newPermissionsList.map((el) => el.keyData)],
    });
  };

  useEffect(() => {
    const allSelect = permissionsList.every((el) => user?.permissions?.includes(el.keyData));
    setIsSelectAll(allSelect);
  }, [user?.permissions]);

  return (
    <ConfigureAdminsSectionWrapper>
      <WrapperWithOffset>
        <Text color={'octonary'} size={16} variant={'tertiary'}>
          {t(editMode ? 'settings:community.admins.editAdmins' : 'settings:community.admins.addAdmins')}
        </Text>
      </WrapperWithOffset>
      <Br indent={15} />
      {!adminAdded ? (
        <>
          <Select
            values={user ? [user] : []}
            options={residents?.filter((el) => !adminsId.includes(el.value))}
            onChange={handleSelectUser}
            label={t('settings:community.admins.labels.selectUser')}
            name={'residents'}
            disabled={editMode}
          />
          {user && (
            <>
              <Br indent={15} />
              <Input
                label={t('settings:community.admins.labels.userLabel')}
                value={user?.userLabel}
                onChange={handleChangeLabel}
              />
              <Br indent={15} />
              {user?.value && (
                <>
                  <TopWrapper>
                    <Text color={'octonary'} size={16} variant={'tertiary'}>
                      {t('settings:community.admins.permissions')}
                    </Text>
                    <Button
                      variant={'select'}
                      width={'73px'}
                      height={'21px'}
                      ghost
                      fontSize={'10px'}
                      onClick={changeAllPermissions}>
                      {!isSelectAll ? t('buttons:common.selectAll') : t('buttons:common.unselectAll')}
                    </Button>
                  </TopWrapper>
                  <Br indent={15} />
                  <PermissionsWrapper>
                    {permissionsList.map(({keyData, defaultValue, label, hintText}) => {
                      return (
                        <CheckItem
                          $isChecked={user?.permissions?.includes(keyData) || defaultValue}
                          onChange={getOnChange(keyData)}
                          hintText={t(hintText)}
                          key={keyData}>
                          {t(label)}
                        </CheckItem>
                      );
                    })}
                  </PermissionsWrapper>
                </>
              )}
              <Br indent={30} />
              <ConfirmBtn
                variant={'primary'}
                onClick={confirm}
                loading={editMode ? editAdminLoading : makeAdminLoading}>
                {t('settings:community.admins.confirm')}
              </ConfirmBtn>
            </>
          )}
        </>
      ) : (
        <>
          <Text color={'octonary'} size={16} variant={'tertiary'}>
            {t('settings:community.admins.adminAdded', adminAdded)}
          </Text>
          <Br indent={30} />
          <ConfirmBtn variant={'secondary'} onClick={addAnother}>
            {t('settings:community.admins.addAnother')}
          </ConfirmBtn>
        </>
      )}
    </ConfigureAdminsSectionWrapper>
  );
};
