import {User} from '../queries/types/user';
import {Profile} from '../types/profile';
import {TypeCommunity} from '../types/auth';
import {Community} from '../queries/types/community';
import {TCommunity} from '../types/community';
import {getArrayElement} from './parse';
import {ttSteps} from '../components/common/NavigationToolTips';
import {LocalStorageItems} from '../constants/common';
import {format} from 'date-fns';

export const getUserProfileFields = (user: User): Profile => {
  return {
    username: user.username,
    bio: user.bio,
    languages: user.languages,
    study: user.study,
    work: user.work,
    howDidYouHear: user.howDidYouHear,
    birthDate: user.birthDate,
    gender: user.gender,
    personInterests: user.personInterests?.map(getArrayElement),
    recommendations: user.recommendations,
    occupation: user.occupation,
  };
};

export const isProfileCreated = (viewer?: User | null): boolean => {
  return Boolean(viewer?.username);
};

export const getTypeUser = (viewerId?: string, community?: Partial<Community> | null) => {
  if (!community) {
    return TypeCommunity.resident;
  }
  if (viewerId === community?.Owner?.objectId) return TypeCommunity.manager;
  if (viewerId === community?.Admins?.edges?.find((el) => el.node?.User?.objectId === viewerId)?.node?.User?.objectId)
    return TypeCommunity.admin;

  return TypeCommunity.resident;
};

export const getTypeUserByTCommunity = (
  viewerId?: string,
  community?: Partial<TCommunity> | null,
  forLabel?: boolean,
) => {
  if (!community) {
    return TypeCommunity.resident;
  }
  if (viewerId === community?.Owner?.objectId) return !forLabel ? TypeCommunity.manager : TypeCommunity.admin;
  if (viewerId === community?.Admins?.find((el) => el?.User?.objectId === viewerId)?.User?.objectId)
    return !forLabel ? TypeCommunity.admin : TypeCommunity.manager;
  return TypeCommunity.resident;
};

export const getUserName = (type?: TypeCommunity | null, first?: string, last?: string, showFull?: boolean) => {
  const base = `${first} ${last || ''}`;
  if (base.toLowerCase().trim().includes('hangeh support')) return base;
  if (type === TypeCommunity.admin || type === TypeCommunity.manager || showFull) return base;
  const lastName = last?.trim() ? last?.[0] + '.' : '';
  return `${first} ${lastName}`;
};

export const ttStepsMap = {
  [ttSteps.start]: 0,
  [ttSteps.home]: 1,
  [ttSteps.people]: 2,
  [ttSteps.explore]: 3,
  [ttSteps.messages]: 4,
  [ttSteps.helpDesk]: 5,
  [ttSteps.profile]: 6,
  [ttSteps.switch]: 7,
  [ttSteps.end]: 8,
  [ttSteps.none]: 9,
};

export const ttStepsMapRev: Record<string, ttSteps> = {
  '0': ttSteps.start,
  '1': ttSteps.home,
  '2': ttSteps.people,
  '3': ttSteps.explore,
  '4': ttSteps.messages,
  '5': ttSteps.helpDesk,
  '6': ttSteps.profile,
  '7': ttSteps.switch,
  '8': ttSteps.end,
  '9': ttSteps.none,
};

export const getInboxHints = () => {
  if (typeof window === 'undefined') return;
  const item = window?.localStorage.getItem(LocalStorageItems.inboxHints);
  return item ? JSON.parse(item) : {};
};

export const markInboxHints = (type: 'requests' | 'chat') => {
  if (typeof window === 'undefined') return;
  const hints = getInboxHints();
  window?.localStorage?.setItem(
    LocalStorageItems.inboxHints,
    JSON.stringify({
      ...hints,
      ...(type === 'requests' ? {requests: false} : {chat: false}),
      date: format(new Date(), 'P'),
    }),
  );
};
