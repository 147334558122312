import React from 'react';
import {
  ButtonWrapper,
  CardContentWrapper,
  CardTitle,
  CardWrapper,
  DateWrapperCard,
  ErrorMessage,
  MessageSuccessWrapper,
  NotAvailable,
  SuccessMessage,
  TextContainer,
  TextContainerEntry,
  UserCardIcon,
  GrayLabel,
  RequestButton,
} from './styles';
import {ListingCardProps, ListingCardType, ListingType, PickDateTypes, PricePeriodType} from './types';
import {CardTags} from './CardTags';
import {DateLabel} from './DateBlock/DateLabel';
import {PaymentInfo} from './PaymentInfo';
import {DateTimePicker} from './DateBlock/DateTimePicker';
import {IconSvg} from '../Icon/Svg';
import {LinkButton} from '../Button/LinkButton';
import {RowInfoLabel} from '../Labels';

export const ListingRequestCard: React.FC<ListingCardProps> = ({
  title,
  descr,
  tags,
  buttonLabel,
  dateLabel,
  payments,
  type,
  canEdit,
  message,
  pickerLabels,
  listingType,
  pricePeriod,
  pickerPlaceholders,
  datePickerData,
  successOrder,
  requestSent,
  pathLink,
  linkTitle,
  durationUnit,
  captions,
  buttonSelectText,
  isAvailable,
  notAvailableText,
  disabled,
  busyDates,
  accessRequest,
  showWarnPopup,
  isCustom,
  bookingLabel,
  isNotSelected,
  rewardType,
}) => {
  const requestClick = () => {
    if (!accessRequest) return showWarnPopup?.();
    buttonLabel?.onClick?.();
  };
  return (
    <CardWrapper padding={3} $custom={isCustom}>
      <CardContentWrapper>
        <DateWrapperCard $isAvailable={isAvailable} $disable={disabled}>
          {!isCustom && (
            <>
              <CardTitle>{title}</CardTitle>
              <CardTags tags={tags} />
            </>
          )}
          {successOrder ? (
            <>
              <MessageSuccessWrapper>
                <TextContainer>
                  <UserCardIcon width={22}>
                    <IconSvg type={'checkmark'} stroke={'green'} />
                  </UserCardIcon>
                  <TextContainerEntry variant={'quaternary'}>{requestSent}</TextContainerEntry>
                </TextContainer>
              </MessageSuccessWrapper>
              {pathLink && (
                <LinkButton to={pathLink} ghost={true} variant={'primary'}>
                  {linkTitle}
                </LinkButton>
              )}
              <br />
            </>
          ) : (
            <>
              {bookingLabel && <GrayLabel>{bookingLabel}</GrayLabel>}
              {listingType === ListingType.sell ? (
                <DateTimePicker
                  date={datePickerData?.startdate?.value}
                  pickType={PickDateTypes.dateTime}
                  setDate={datePickerData?.startdate?.set}
                  canEdit={canEdit}
                  pickerLabels={pickerLabels}
                  pickerPlaceholders={pickerPlaceholders}
                  durationUnit={durationUnit}
                  captions={captions}
                  buttonSelectText={buttonSelectText}
                  busyDates={busyDates}
                  setTime={datePickerData?.time?.set}
                  time={datePickerData?.time?.value}
                />
              ) : (
                <>
                  {pricePeriod === PricePeriodType.hour ? (
                    <DateTimePicker
                      date={datePickerData?.startdate?.value}
                      setDate={datePickerData?.startdate?.set}
                      time={datePickerData?.time?.value}
                      pickType={PickDateTypes.TimeAndDuration}
                      setTime={datePickerData?.time?.set}
                      canEdit={canEdit}
                      pickerLabels={pickerLabels}
                      pickerPlaceholders={pickerPlaceholders}
                      duration={datePickerData?.duration?.value}
                      setDuration={datePickerData?.duration?.set}
                      durationUnit={durationUnit}
                      captions={captions}
                      buttonSelectText={buttonSelectText}
                      busyDates={busyDates}
                    />
                  ) : (
                    <DateLabel
                      accessRequest={accessRequest}
                      showWarnPopup={showWarnPopup}
                      from={dateLabel.from}
                      to={dateLabel.to}
                      dates={dateLabel.dates}
                      setDates={dateLabel?.setDates}
                      canEdit={canEdit}
                      placeholder={dateLabel.placeholder}
                      durationUnit={durationUnit}
                      captions={captions}
                      buttonSelectText={buttonSelectText}
                      busyDates={busyDates}
                      pricePeriod={pricePeriod}
                    />
                  )}
                </>
              )}

              <SuccessMessage open={!!message?.success}>{message?.success}</SuccessMessage>
              <ErrorMessage open={!!message?.error}>{message?.error}</ErrorMessage>
              {!successOrder && type === ListingCardType.Request && (
                <PaymentInfo payments={payments} rewardType={rewardType} />
              )}
              <ButtonWrapper pad={10}>
                <RequestButton
                  $notSelected={isNotSelected}
                  variant={'primary'}
                  onClick={isAvailable && !isNotSelected ? requestClick : undefined}
                  loading={buttonLabel.loading}>
                  {buttonLabel.label}
                </RequestButton>
              </ButtonWrapper>
            </>
          )}
          <RowInfoLabel text={descr} />
          {/* <CardInfoText>{descr}</CardInfoText> */}
        </DateWrapperCard>
        {!isAvailable && <NotAvailable>{notAvailableText}</NotAvailable>}
      </CardContentWrapper>
    </CardWrapper>
  );
};
