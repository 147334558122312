import {gql} from '@apollo/client';

export default gql`
  fragment NotificationFragment on Notification {
    objectId
    id
    event
    data
    status
    createdAt
    updatedAt
    to {
      ...UserPartData
    }
    from {
      ...UserPartData
    }
    Community {
      id
      Avatar {
        file {
          name
          url
        }
      }
    }
  }

  fragment UserPartData on User {
    id
    objectId
    Avatar {
      file {
        name
        url
      }
    }
  }
`;

export const NotificationSettingsFragment = gql`
  fragment NotificationSettingsFragment on User {
    notifyDigest {
      ... on Element {
        value
      }
    }
    notifyHelpCentre {
      ... on Element {
        value
      }
    }
    notifyJoinRequest {
      ... on Element {
        value
      }
    }
    notifyLikeOrComment {
      ... on Element {
        value
      }
    }
    notifyListingView {
      ... on Element {
        value
      }
    }
    notifyListingsRequest {
      ... on Element {
        value
      }
    }
    notifyLoopPost {
      ... on Element {
        value
      }
    }
    notifyManagerAdminPost {
      ... on Element {
        value
      }
    }
    notifyMarketingAndFeatures {
      ... on Element {
        value
      }
    }
    notifyMessage {
      ... on Element {
        value
      }
    }
    notifyNewCommunities {
      ... on Element {
        value
      }
    }
    notifyNewListings {
      ... on Element {
        value
      }
    }
    notifyNewMembers {
      ... on Element {
        value
      }
    }
    notifyNewRequests {
      ... on Element {
        value
      }
    }
    notifyNewResidentListings {
      ... on Element {
        value
      }
    }
    notifyNewResidents {
      ... on Element {
        value
      }
    }
    notifyNewTransaction {
      ... on Element {
        value
      }
    }
    notifyOrderRequest {
      ... on Element {
        value
      }
    }
    notifyProfileView {
      ... on Element {
        value
      }
    }
    notifyNewMember {
      ... on Element {
        value
      }
    }
    notifyAmenityReminder {
      ... on Element {
        value
      }
    }
    notifyEventReminder {
      ... on Element {
        value
      }
    }
    notifyHelpCentre {
      ... on Element {
        value
      }
    }
    notifyNewListing {
      ... on Element {
        value
      }
    }
    notifyEventAttendees {
      ... on Element {
        value
      }
    }
    notifyUnmanagedAmenities {
      ... on Element {
        value
      }
    }
    notifyManagedAmenities {
      ... on Element {
        value
      }
    }
  }
`;
