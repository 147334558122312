import React, {useCallback, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {CSSTransitionProps} from 'react-transition-group/CSSTransition';
import styled from 'styled-components';

type ExcludedProps = 'appear' | 'classNames' | 'unmountOnExit' | 'style';
// @types mistake
const endListener = () => {
  return;
};

const timeout = 300;

const StyledFade = styled(CSSTransition)`
  opacity: 0;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
    transition: opacity 500ms ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
    transition: opacity 1000ms ease;
  }

  &.fade-enter-active.default-expanded {
    transition: unset;
  }
`;

export const StyledCollapse = styled(CSSTransition)`
  transition: all ${({timeout}) => timeout}ms ease-in-out;
  overflow: hidden;

  &.collapse-enter,
  &.collapse-appear {
    max-height: 0;
  }

  &.collapse-enter-active,
  &.collapse-appear-active {
    max-height: ${({style}) => style.height}px;
  }

  &.collapse-exit {
    max-height: ${({style}) => style.height}px;
  }

  &.collapse-exit-active {
    max-height: 0;
  }

  &.default-expanded {
    transition: unset;
  }
`;

type CollapseProps = Omit<CSSTransitionProps, ExcludedProps> & {in: boolean; defaultExpanded?: boolean};

export const Collapse: React.FC<CollapseProps> = ({children, defaultExpanded, ...props}) => {
  const [showContent, setShowContent] = useState(false);
  const [height, setHeight] = useState(0);
  const [className, setClassName] = useState(defaultExpanded ? 'default-expanded' : '');
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const setContainerHeight = useCallback(() => {
    if (!container) return;
    setHeight(container.getBoundingClientRect().height);
  }, [container]);

  useEffect(() => {
    if (!container) return;

    setHeight(container.getBoundingClientRect().height);
    return () => {
      setHeight(0);
    };
  }, [container]);

  useEffect(() => {
    if (!container) return;

    const observer = new MutationObserver(setContainerHeight);
    observer.observe(container, {childList: true, subtree: true});
    return () => {
      observer.disconnect();
    };
  }, [container, setContainerHeight]);

  return (
    <StyledCollapse
      style={{height}}
      unmountOnExit
      appear
      className={className}
      classNames={'collapse'}
      timeout={props.timeout ?? timeout}
      addEndListener={endListener}
      onEntering={() => setShowContent(true)}
      onExiting={() => setShowContent(false)}
      onEntered={() => setClassName('')}
      {...props}>
      <div>
        <StyledFade {...props} in={showContent} timeout={100} classNames="fade" addEndListener={endListener}>
          <div ref={setContainer}>{children}</div>
        </StyledFade>
      </div>
    </StyledCollapse>
  );
};
