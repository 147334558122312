import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TYPE_NAME} from '../../../../constants/common';
import {route} from '../../../../constants/routes';
import {getFileFormatByMime, getNameForDoc, getUrlForDoc} from '../../../../helpers/documents';
import {getResize} from '../../../../helpers/file';
import {groupToState} from '../../../../helpers/group';
import {getIsIncludesCmnt, getIsPublishedCmnt} from '../../../../helpers/item';
import {useLinks} from '../../../../hooks/common';
import {Document} from '../../../../queries/types/document';
import {Group} from '../../../../queries/types/group';
import {TPostItem} from '../../../../queries/types/post';
import {IGetCurrValue} from '../../../../types/common';
import {TDocumentFormat} from '../../../../types/document';
import {TEvent} from '../../../../types/event';
import {TGroup} from '../../../../types/group';
import {TItem} from '../../../../types/item';
import {MemoSimpleCardDocument} from '../../../Documents/SimpleCardDocument';
import {TDocumentMime} from '../../../Documents/types';
import {MemoSimpleEventCard} from '../../../Events/SimpleEventCard';
import {SimpleCardGroup} from '../../../Groups/SimpleCardGroup';
import {ListingCard} from '../../../Listings/ListingCard';
import {MobileListingsBlock, PostListingLabel, PostListingWrapper, ShowMoreButton, SliderWrapper} from '../styles';
import {ListingSlider} from './ListingSlider';
import {TCompany} from '../../../../types/company';
import {MemoSimpleCompanyCard} from '../../../LocalBiz/SimpleCompanyCard';
import {TAmenity} from '../../../../types/amenity';
import {MemoSimpleAmenityCard} from '../../../Amenities/SimpleAmenityCard';
import {LikeState} from '../../../../hooks/item';
import {reportActions} from '../../../../hooks/report';

type TProps = {
  items?: Partial<TPostItem>[];
  loading?: boolean;
  communityId?: string;
  getSignValue: IGetCurrValue;
  type?: string;
  isManager: boolean;
  preventRedirect?: (e: React.SyntheticEvent) => void;
  itemsLiked?: LikeState;
  onLikeItem?: (id: string) => void;
  reportItemActions?: reportActions;
};

export const PostItem: React.FC<TProps> = ({
  items,
  communityId,
  getSignValue,
  type,
  isManager,
  preventRedirect,
  itemsLiked,
  onLikeItem,
  reportItemActions,
}) => {
  const [showAll, setShowAll] = useState(false);
  const {t} = useTranslation();
  const {getLink} = useLinks();

  if (!items?.length) return null;
  const viewAllText = t('community:loop.buttons.viewAll');
  const postedItems = t('community:loop.post.labels.postedItems', {
    context: type,
    count: items.length,
  });
  const isListings = items?.some((postItem) => postItem.__typename === TYPE_NAME.Item);
  const onShowAll = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll(true);
  };

  const listingItems = showAll ? items : items?.slice(0, 3);
  return (
    <PostListingWrapper>
      <PostListingLabel>{postedItems}</PostListingLabel>
      {isListings && (
        <MobileListingsBlock onClick={preventRedirect}>
          {listingItems?.map((postItem) => {
            if (postItem.__typename === TYPE_NAME.Item) {
              const item = postItem as unknown as TItem;
              return item.isPublished &&
                !getIsIncludesCmnt(item?.AdmHidden, communityId) &&
                !item?.isDeleted &&
                getIsPublishedCmnt(item?.Published, communityId) ? (
                <ListingCard
                  src={item?.images?.[0]?.file?.url}
                  to={getLink(route.listingView.get({id: item?.id || ''}))}
                  key={item.id}
                  item={item}
                  aspectRatio={'300:200'}
                  className={'post-listing__item'}
                  communityId={communityId}
                  isCardInPost
                  getSignValue={getSignValue}
                  liked={itemsLiked}
                  onLike={onLikeItem}
                  isPost={true}
                  reportActions={reportItemActions}
                />
              ) : null;
            }
          })}
          {items?.length > 3 && !showAll && <ShowMoreButton onClick={onShowAll}>{viewAllText}</ShowMoreButton>}
        </MobileListingsBlock>
      )}
      <SliderWrapper onClick={preventRedirect}>
        <ListingSlider isListings={isListings}>
          {items?.map((postItem) => {
            if (postItem.__typename === TYPE_NAME.Item) {
              const item = postItem as unknown as TItem;
              return item.isPublished &&
                !getIsIncludesCmnt(item?.AdmHidden, communityId) &&
                !item?.isDeleted &&
                getIsPublishedCmnt(item?.Published, communityId) ? (
                <ListingCard
                  src={item?.images?.[0]?.file?.url}
                  to={getLink(route.listingView.get({id: item?.id || ''}))}
                  key={item.id}
                  item={item}
                  aspectRatio={'300:200'}
                  className={'post-listing__item'}
                  communityId={communityId}
                  isCardInPost
                  getSignValue={getSignValue}
                  liked={itemsLiked}
                  onLike={onLikeItem}
                  isPost={true}
                  reportActions={reportItemActions}
                />
              ) : null;
            }

            if (postItem.__typename === TYPE_NAME.Document) {
              const item = postItem as unknown as Document;

              const coverUrl = item.Cover?.file?.url || getUrlForDoc(item.alias as string);

              return (
                <MemoSimpleCardDocument
                  key={item.id}
                  name={getNameForDoc({
                    text: item.name,
                    t,
                  })}
                  createdAt={item.createdAt as Date}
                  objectId={item.objectId as string}
                  coverUrl={coverUrl}
                  format={getFileFormatByMime(item.DocFile?.mime as TDocumentMime) as TDocumentFormat}
                />
              );
            }

            if (postItem.__typename === TYPE_NAME.Group) {
              const item = postItem as unknown as Group;

              return <SimpleCardGroup key={item.id} group={groupToState(item, t) as TGroup} />;
            }

            if (postItem.__typename === TYPE_NAME.Event) {
              const item = postItem as unknown as TEvent;
              const to = isManager
                ? getLink(route.eventViewEdit.get({id: item?.objectId || ''}))
                : getLink(route.eventView.get({id: item?.objectId || ''}));

              return (
                <MemoSimpleEventCard
                  key={item.id}
                  event={item}
                  src={getResize(item?.images?.[0]?.file?.url, 'md')}
                  to={to}
                />
              );
            }

            if (postItem.__typename === TYPE_NAME.Company) {
              const item = postItem as unknown as TCompany;
              const to = isManager
                ? getLink(route.bizViewEdit.get({id: postItem?.objectId || ''}))
                : getLink(route.bizView.get({id: postItem?.objectId || ''}));

              return (
                <MemoSimpleCompanyCard
                  key={item.id}
                  company={item}
                  src={getResize(item?.images?.[0]?.file?.url, 'md')}
                  to={to}
                />
              );
            }

            if (postItem.__typename === TYPE_NAME.Amenity) {
              const item = postItem as unknown as TAmenity;
              const to = isManager
                ? getLink(route.amenityViewEdit.get({id: postItem?.objectId || ''}))
                : getLink(route.amenityView.get({id: postItem?.objectId || ''}));
              return (
                <MemoSimpleAmenityCard
                  key={item.id}
                  eventName={item?.eventName}
                  bookingTiming={item?.bookingTiming}
                  rewardType={item?.rewardType}
                  src={getResize(item?.images?.[0]?.file?.url, 'md')}
                  to={to}
                />
              );
            }
          })}
        </ListingSlider>
      </SliderWrapper>
    </PostListingWrapper>
  );
};
