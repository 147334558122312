import React from 'react';
import {ProfileSection} from '../ProfileSection';
import {useTranslation} from 'react-i18next';
import {ProfileContentProps} from '../types';
import {EmptyState} from '../../EmptyState/EmptyState';
import {Listings} from './Listings';
import {Reviews} from '../../Profile/content/Reviews';

export const ProfileContent: React.FC<ProfileContentProps> = ({
  listingItems,
  listingLoading,
  reviewsLoading,
  typeUser,
  communityId,
  reviewsItems,
  reportActions,
  isOwner,
  getSignValue,
  reviewsContainerRef,
  liked,
  onLike,
}) => {
  const {t} = useTranslation();
  const tListings = t('people:profile.titles.listings');
  const tReviews = t('people:profile.titles.reviews');
  const isEmptyState =
    !listingItems?.length &&
    !listingLoading &&
    !reviewsItems?.received?.length &&
    !reviewsItems?.given?.length &&
    !reviewsLoading;

  if (isEmptyState) {
    return (
      <EmptyState
        mainText={t('people:profile.emptyState.title')}
        ElemSubText={t('people:profile.emptyState.subtitle')}
      />
    );
  }

  return (
    <>
      <ProfileSection bordered={false} title={`${tListings}`}>
        {!listingItems?.length && !listingLoading ? (
          <EmptyState mainText={''} ElemSubText={t('people:profile.emptyState.listings')} />
        ) : (
          <Listings
            typeUser={typeUser}
            items={listingItems}
            isOwner={isOwner}
            communityId={communityId}
            loading={listingLoading}
            reportActions={reportActions}
            getSignValue={getSignValue}
            onLike={onLike}
            liked={liked}
          />
        )}
      </ProfileSection>
      <div ref={reviewsContainerRef}>
        {!!reviewsItems?.received?.length && !!reviewsItems?.given?.length && (
          <ProfileSection title={`${tReviews}`} className={'profile-reviews__tabs'}>
            {!reviewsItems?.received?.length && !reviewsItems?.given?.length ? (
              <EmptyState mainText={''} ElemSubText={t('people:profile.emptyState.reviews')} />
            ) : (
              <Reviews items={reviewsItems} />
            )}
          </ProfileSection>
        )}
      </div>
    </>
  );
};
