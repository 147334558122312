import {CardsGrid} from './styles';
import {SceletonCard} from './SceletonCard';
import React from 'react';

interface IProps {
  count?: number | null;
}

const SceletonLoader: React.FC<IProps> = ({count}) => {
  const sceletonArray = new Array(count || 12).fill('');
  return (
    <CardsGrid>
      {sceletonArray.map((it, index) => (
        <SceletonCard key={index} />
      ))}
    </CardsGrid>
  );
};

const MemoSceletonLoader = React.memo(SceletonLoader);
export {MemoSceletonLoader};
