import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {formatInviteLink, handleSharing} from '../../../helpers/common';
import {useIsModal} from '../../../hooks/common';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Label} from '../../../ui-kit/Labels';
import {
  DarkBackground,
  InviteButtonItem,
  InvButtons,
  InvButtonIcon,
  InvButtonText,
  Divider,
  InviteCodeText,
  InviteButtonLink,
  ModalTemplateInviteWrapper,
  PeopleModalWrapper,
} from './styles';

export type InvitePeopleModalT = {
  onClose: () => void;
  inviteLink: string;
  isManager?: boolean;
  commPassCode?: string;
  communityName?: string;
};

export const InvitePeopleModal: React.FC<InvitePeopleModalT> = ({
  isManager,
  inviteLink,
  commPassCode,
  communityName,
  onClose,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const {t} = useTranslation();
  const inviteUrl = commPassCode ? formatInviteLink(commPassCode) : '';
  useIsModal();
  const copyText = t('people:modals.copy');
  const invEmailText = t('people:modals.invEmail');
  const shareText = t('people:modals.share');
  const copiedText = t('people:modals.copied');
  const inviteCodeText = t('people:modals.inviteCode');
  const sendShareText = t('people:modals.sendShareText', {code: commPassCode});
  const sendShareTitle = t('people:modals.sendShareTitle', {name: communityName || ''});
  const handleCopy = () => {
    navigator?.clipboard?.writeText(inviteUrl);
    setIsCopied(true);
  };
  const onShare = () => {
    if (!inviteUrl) return;
    handleSharing({url: inviteUrl, text: sendShareText, title: sendShareTitle});
  };
  const stopEvent = (e: React.SyntheticEvent) => e.stopPropagation();
  return (
    <DarkBackground onClick={onClose}>
      <PeopleModalWrapper onClick={stopEvent}>
        <ModalTemplateInviteWrapper>
          <InvButtons>
            {isManager && (
              <>
                <InviteButtonLink to={inviteLink}>
                  <InvButtonIcon>
                    <IconSvg type="mail" />
                  </InvButtonIcon>
                  <InvButtonText>{invEmailText}</InvButtonText>
                </InviteButtonLink>
                <Divider />
              </>
            )}
            <InviteButtonItem onClick={handleCopy}>
              <InvButtonIcon>
                <IconSvg type="copy" />
              </InvButtonIcon>
              <InvButtonText>{copyText}</InvButtonText>
              {isCopied && <Label variant="quaternary">{copiedText}</Label>}
            </InviteButtonItem>
            <Divider />
            <InviteButtonItem onClick={onShare}>
              <InvButtonIcon>
                <IconSvg type="share" />
              </InvButtonIcon>
              <InvButtonText>{shareText}</InvButtonText>
            </InviteButtonItem>
            <Divider />
            <InviteCodeText>
              {inviteCodeText}: {commPassCode}
            </InviteCodeText>
          </InvButtons>
        </ModalTemplateInviteWrapper>
      </PeopleModalWrapper>
    </DarkBackground>
  );
};
