import React from 'react';
import {IconSvg, LabelText, LabelWrapper} from './styles';

type TLabel = {label?: string; onClick?: () => void; className?: string; disabled?: boolean};

export const Like: React.FC<{active?: boolean; isReaction?: boolean} & TLabel> = ({
  label,
  active,
  onClick,
  className,
  disabled,
}) => {
  const fill = active ? 'red' : 'none';
  const stroke = active ? 'red' : 'gray';
  const handleClick = disabled ? undefined : onClick;
  return (
    <LabelWrapper className={className} onClick={handleClick} $disabled={disabled}>
      <IconSvg type="shape" fill={fill} stroke={stroke} viewBox={'0 -2 14 18'} />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
  );
};

export const Wave: React.FC<{active?: boolean} & TLabel> = ({label, active, onClick, className}) => {
  const fill = active ? 'green' : 'none';
  const stroke = active ? 'gray' : 'gray';
  return (
    <LabelWrapper className={className} onClick={onClick}>
      <IconSvg type="wave" fill={fill} stroke={stroke} viewBox={'0 0 20 20'} />
      <LabelText>
        <LabelText>{label}</LabelText>
      </LabelText>
    </LabelWrapper>
  );
};

export const Happy: React.FC<{active?: boolean} & TLabel> = ({label, active, onClick, className}) => {
  const fill = active ? 'yellow' : 'none';
  const stroke = active ? 'gray' : 'gray';
  return (
    <LabelWrapper className={className} onClick={onClick}>
      <IconSvg type="happy-face" fill={fill} stroke={stroke} viewBox={'0 0 20 20'} />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
  );
};

export const Comment: React.FC<{active?: boolean} & TLabel> = ({label, onClick, className, disabled}) => {
  const handleClick = disabled ? undefined : onClick;
  return (
    <LabelWrapper className={className} onClick={handleClick} $disabled={disabled}>
      <IconSvg type="comment" stroke={'gray'} viewBox={'0 0 20 20'} />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
  );
};

export const Stars: React.FC<{count?: number; active?: boolean} & TLabel> = ({
  label,
  count,
  active,
  onClick,
  className,
}) => {
  const fill = active ? 'yellow' : 'none';
  const stroke = active ? 'yellow' : 'gray';
  const labelText = label ? label : count ? (count === 1 ? 'star' : 'stars') : 'Give star';

  return (
    <LabelWrapper className={className} onClick={onClick}>
      <IconSvg type="star" fill={fill} stroke={stroke} width={'20px'} height={'20px'} viewBox={'0 2 24 24'} />
      <LabelText>
        {count !== 0 && count && count} {labelText}
      </LabelText>
    </LabelWrapper>
  );
};
