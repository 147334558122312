import {Item, PricePeriodType} from '../queries/types/item';
import {FileInfo, Pointer} from '../queries/types/parse';
import {TPostItemRaw} from '../queries/types/post';
import {PointerFile} from './common';

export enum ItemFormAction {
  create = 'create',
  edit = 'edit',
  view = 'view',
}

export interface ServiceItem {
  title: string;
  description: string;
  images: string;
  isChecked: boolean;
  isEdit: boolean;
  price: number;
  period: string;
  category: string;
}

export enum ServiceCategories {
  plant = 'plant',
  move = 'move',
  pet = 'pet',
  childcare = 'childcare',
  errands = 'errands',
  cleaning = 'cleaning',
  troubleshoot = 'troubleshoot',
  grocery = 'grocery',
  emergency = 'emergency',
  language = 'language',
  music = 'music',
  traditional = 'traditional',
  sport = 'sport',
  dance = 'dance',
  meal = 'meal',
  nutrition = 'nutrition',
  strength = 'strength',
  yoga = 'yoga',
  gym = 'gym',
  gift = 'gift',
  song = 'song',
  painting = 'painting',
  organizing = 'organizing',
  event = 'event',
  trip = 'trip',
  photography = 'photography',
  boardgames = 'boardgames',
}

export type OnboardingCategories =
  | 'provideAssistance'
  | 'teachLessons'
  | 'healthWellness'
  | 'hobbiesInterests'
  | 'others';

export interface CreateMultiplyListingType {
  price: number;
  period: PricePeriodType;
  title: string;
  description: string;
}

export enum ItemFields {
  objectId = 'objectId',
  name = 'name',
  descr = 'descr',
  category = 'category',
  listingType = 'listingType',
  rewardType = 'rewardType',
  originalPrice = 'originalPrice',
  yearPurchased = 'yearPurchased',
  price = 'price',
  pricePeriod = 'pricePeriod',
  discount = 'discount',
  discountType = 'discountType',
  minDuration = 'minDuration',
  images = 'images',
  Viewers = 'Viewers',
  ApproveReq = 'ApproveReq',
  Published = 'Published',
  isPublished = 'isPublished',
  isDeleted = 'isDeleted',
  isSold = 'isSold',
}

export type TImage = {
  id?: string;
  value?: File | string;
  order?: number;
  file?: FileInfo;
};

type NewItemFields = {
  images?: TImage[];
  serverImages?: PointerFile[];
};

type CreateParseFields = {
  Lister?: {
    link: string;
  };
  images?: (Partial<PointerFile> | undefined)[];
};

type EditParseFields = {
  ApproveReq?: Pointer;
  images?: (Partial<PointerFile> | undefined)[];
};

export type ItemFormValues = Omit<Pick<Item, ItemFields>, keyof NewItemFields> & NewItemFields;
export type ItemFormField = keyof ItemFormValues;
export type ItemFormValue = ItemFormValues[ItemFormField];
export type CreateItemFieldsInput = Omit<Partial<ItemFormValues>, keyof CreateParseFields> & CreateParseFields;
export type EditItemFieldsInput = Omit<Partial<ItemFormValues>, keyof EditParseFields> & EditParseFields;
export type TQueryItem = Item;
export type TQueryPostItem = TPostItemRaw;
export type TItem = Omit<Item, keyof NewItemFields> & NewItemFields;

export type PreOrderCalcDataT = {
  itemCost: number;
  sfRequesterAmt: number;
  ppRequesterAmt: number;
  requesterTotalAmt: number;
};
