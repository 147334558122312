import styled from 'styled-components';
import {Link} from '../../../../ui-kit/Typography/Link';
import {Media} from '../../../../ui-kit/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;
  width: 200px;
  height: max-content;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

  ${Media.down.s} {
    padding-bottom: 30px;
    width: 100vw;
  }

  div:nth-child(2) {
    margin-bottom: -5px;
    justify-self: center;
    position: relative;
    width: calc(100% - 40px);
  }
`;

export const BlockWrapper = styled.div<{borderBottom?: boolean}>`
  width: 100%;
  border-bottom: ${({borderBottom, theme}) =>
    borderBottom ? `1px solid ${theme.palette.background.secondary}` : 'none'};
  padding: 8px 0 0 0;
`;

export const CipyrightWrapper = styled.div`
  width: 90%;
`;

export const ContentWrapper = styled.div`
  width: 90%;
  padding: 6px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${Media.down.s} {
    padding-left: 30px;
  }
`;

export const TextMenuBlock = styled.div`
  height: max-content;
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  margin-left: 28px;

  p {
    margin-top: 5px;
  }
`;

export const Title = styled.p<{$overflow?: boolean}>`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
  margin: 0 0 5px;
  cursor: pointer;
  text-decoration: none;
  ${({$overflow}) =>
    $overflow &&
    `
    max-width: 156px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};
  ${Media.down.m} {
    max-width: unset;
  }
`;

export const EntryLinkWrapper = styled(Link)`
  display: block;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
`;

export const EntryOutsideLinkWrapper = styled.a`
  display: block;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
`;

export const HeaderWrapper = styled(ContentWrapper).attrs({
  borderBottom: true,
})`
  padding: 12px 10px;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -8px;
  cursor: pointer;

  &:first-child {
    margin-right: 8px;
  }
`;

export const TitleButton = styled.button`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.octonary};
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
`;

export const Header = styled(Title)`
  font-size: 20px;
  cursor: text;
`;

export const Text = styled(Title)`
  font-size: 11px;
`;

export const TextBottom = styled(Text)`
  color: ${({theme}) => theme.palette.text.duodecimal};
  cursor: text;
  margin: 10px 0;
`;
