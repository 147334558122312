import React, {useState} from 'react';
import {Props} from './types';
import {
  CancelButton,
  CloseButton,
  DarkBackground,
  ModalWrapper,
  OkButton,
  Title,
  TextArea,
  ButtonWrapper,
} from './styles';
import {Text} from '../../Typography/Text';
import {Br} from '../../Typography/Br';
import {IconSvg} from '../../Icon/Svg';

export const Confirmation: React.FC<Props> = ({
  title,
  subTitle,
  okText,
  onOk,
  buttonType,
  cancelText,
  onCancel,
  onClose,
  isHaveTextArea,
  textAreaLabel,
}) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onOk?.(text);
    setLoading(false);
  };

  return (
    <DarkBackground>
      <ModalWrapper>
        <Title size={20} weight={'medium'} color={'octonary'} mb={'14px'}>
          {title}
        </Title>

        <div>
          <Text size={12} color={'octonary'}>
            {subTitle}
          </Text>
        </div>

        {isHaveTextArea && (
          <>
            <Br indent={13} mobileIndent={24} />
            <TextArea onChange={onChange} value={text} label={textAreaLabel} />
          </>
        )}
        <ButtonWrapper>
          {okText && (
            <OkButton variant={buttonType} onClick={handleSubmit} loading={loading} width={'240px'}>
              {okText}
            </OkButton>
          )}
          {cancelText && <CancelButton onClick={onCancel || onClose}>{cancelText}</CancelButton>}
        </ButtonWrapper>

        <CloseButton onClick={onClose}>
          <IconSvg type={'close'} viewBox={'0 0 20 20'} />
        </CloseButton>
      </ModalWrapper>
    </DarkBackground>
  );
};
