import React, {useState} from 'react';
import {parseJSON} from 'date-fns';
import {useTranslation} from 'react-i18next';

import {PostTypeT} from '../../../../../types/post';
import {Like} from '../../../../../ui-kit/Feed/labels/labels';
import {PostActions, PostActionsRight, PostActionsLeft, PostDate} from '../../styles';
import {TranslatePanel} from '../../TranslatePanel';
import {PostBottomContentProps} from '../../types';

type TProps = {
  showCommentsShow: () => void;
  showCommentsClose: () => void;
  commentsShow?: boolean;
};

export const CommentBottom: React.FC<PostBottomContentProps & TProps> = ({
  likes,
  postActions,
  objectId,
  isLiked,
  refetchComments,
  translateState,
  type,
  createdAt,
  isLocal,
}) => {
  const {t} = useTranslation();
  const [like, setLike] = useState<{liked: boolean; likes: number}>({liked: isLiked || false, likes: likes || 0});
  const handleLike = () => {
    setLike((prev) => ({liked: !prev.liked, likes: prev.liked ? prev.likes - 1 : prev.likes + 1}));
    postActions?.likePost?.(objectId, refetchComments);
  };

  return (
    <PostActions>
      <PostActionsLeft>
        {type !== PostTypeT.items && type !== PostTypeT.video && <TranslatePanel {...translateState} />}
        <Like
          active={like.liked}
          onClick={handleLike}
          label={t('common:like', {count: like.likes || 0})}
          disabled={isLocal}
        />
      </PostActionsLeft>
      <PostActionsRight>
        <PostDate>{createdAt && t('common:datePPp', {date: parseJSON(createdAt)})}</PostDate>
        <PostDate showMob={true}>{createdAt && t('common:datePP', {date: new Date(createdAt)})}</PostDate>
      </PostActionsRight>
    </PostActions>
  );
};
