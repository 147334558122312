import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {Text} from '../../ui-kit/Typography/Text';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {fontPrimaryReg} from '../common/styles';
import {StyledText} from '../../ui-kit/Typography/styles';

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
`;

export const Title = styled(Text).attrs({variant: 'primary', weight: 'medium', color: 'octonary'})`
  display: inline-block;
  font-size: 20px;
  padding: 20px 15px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CheckWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 56px;
  position: relative;
  cursor: pointer;
  & > *:nth-child(2) {
    margin-right: 60px;
  }
  & > *:last-child {
    position: absolute;
    right: 19px;
  }
  // &::after {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 1px;
  //   background-color: ${({theme}) => theme.palette.background.secondary};
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }
`;

export const IsCompleteIcon = styled(IconSvg).attrs({
  width: '25px',
  height: '25px',
  viewBox: '0 0 20 20',
  type: 'circle-ok',
})<{$isComplete?: boolean}>`
  position: absolute;
  left: 14px;
  & path {
    ${({theme, $isComplete}) => $isComplete && `stroke: ${theme.palette.common.aqua};`}

    &:first-child {
      ${({$isComplete}) => !$isComplete && 'display: none;'}
    }
  }
`;

export const CheckContent = styled(Text).attrs({
  size: 12,
  color: 'octonary',
})`
  ${Media.down.m} {
    font-size: 14px;
    width: 80%;
  }
`;

export const ActionIcon = styled(IconSvg).attrs({
  viewBox: '0 0 20 20',
})`
  transform: rotate(180deg);

  & path {
    stroke: ${({theme}) => theme.palette.background.tertiary};
    stroke-width: 1;
  }
`;

export const Button = styled.span`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ChecklistContainer = styled.div`
  background: #fff3da;
  border: none;
  position: absolute;
  left: 280px;
  top: 60px;
  z-index: 1010;
  box-sizing: border-box;
  max-width: 331px;
  word-break: break-word;
  width: 100vw;
  padding: 9px;
  border-radius: 12px;
  & > * {
    box-sizing: border-box;
  }
`;

export const AccountCheckWrapper = styled.div`
  margin-top: 12px;
`;

export const ChecklistMobileWrapper = styled.div`
  padding: 2px 2px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 16px;
  color: ${({theme}) => theme.palette.grayscale._6};
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fontPrimaryReg};
  margin-top: 2px;
  ${Media.up.s} {
    display: none;
  }
`;

export const ChecklistStepsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const PercentText = styled.span`
  font-size: 14px;
  position: relative;
  left: -35px;
  top: 4px;
`;

export const RealText = styled(StyledText)`
  max-width: 250px;
  ${Media.down.xxs} {
    max-width: 230px;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const DropDownBtnMobile = styled.button<{$isOpened?: boolean}>`
  padding-right: 40px;
  background: transparent;
  outline: none;
  border: none;
  width: 30px;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  transition: 0.2s all;
  transform: rotate(0deg);
  ${({$isOpened}) => $isOpened && 'transform: rotate(180deg);'};
`;
