import {Community} from './types/community';
import {gql} from '@apollo/client';

export type GetResidentsResponseType = {
  community: Community;
};

export const GetCommunityMembersQuery = gql`
  query residents($cursor: String, $first: Int, $where: UserWhereInput, $order: [UserOrder!], $id: ID!) {
    community(id: $id) {
      Residents(after: $cursor, order: $order, first: $first, where: $where) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        count
        edges {
          cursor
          node {
            objectId
            Avatar {
              file {
                url
                name
              }
            }
            firstName
            firstLetter
            lastName
          }
        }
      }
      Owner {
        objectId
        Avatar {
          file {
            url
            name
          }
        }
        firstName
        firstLetter
        lastName
      }
    }
  }
`;
