import React from 'react';
import {PollOptionsWrapper, TextBtn, TextMessageInput, PollOptionRow, Circle} from '../../../Loop/PostCreate/styles';
import {pollIdType, PollOptions} from '../types';
import {useTranslation} from 'react-i18next';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type TProps = {
  remove: (id: pollIdType) => void;
  options: PollOptions;
  editText: (id: pollIdType, text?: string) => void;
  isEdit?: boolean;
  isMulti?: boolean;
};

export const PollOptionsEditor: React.FC<TProps> = ({remove, options, editText, isEdit, isMulti}) => {
  const {t} = useTranslation();
  const removeBtn = t('community:loop.buttons.remove');
  const placeholder = t('community:loop.inputs.placeholderPollOption');

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, id: pollIdType) => {
    editText(id, e.target.value);
  };

  const handleRemove = (id: string) => () => remove(id);

  return (
    <PollOptionsWrapper>
      {Object.keys(options).map((i) => (
        <PollOptionRow key={i}>
          <Circle $isMulti={!!isMulti} />
          <TextMessageInput
            onChange={(e: inputEvent) => handleChangeText(e, i)}
            value={options[i]?.text}
            placeholder={placeholder}
            mobileFontSize={14}
          />
          {!isEdit && (
            <TextBtn onClick={handleRemove(i)} className={'poll-option__btn--remove'}>
              {removeBtn}
            </TextBtn>
          )}
        </PollOptionRow>
      ))}
    </PollOptionsWrapper>
  );
};
