import {useTranslation} from 'react-i18next';
import {InterestsGroup, InterestsKey} from '../constants/interests';
import {TInterest} from '../types/interests';

export const useGetInterests = () => {
  const {t} = useTranslation();

  return {
    options: InterestsKey.map((el) => {
      return {
        value: el,
        label: t('resident:create.account.interes', {context: el}),
        group: InterestsGroup?.[el] || '',
      };
    }) as Array<{value: TInterest; label: string}>,
    values: InterestsKey.reduce((acc, el) => {
      return {...acc, [el]: t('resident:create.account.interes', {context: el})};
    }, {}) as Record<TInterest, string>,
  };
};
