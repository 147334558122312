import React from 'react';
import {
  MessagePreviewCardWrapper,
  //MessageUserName,
  PreviewMessageCardBody,
  PreviewMessageText,
} from '../../../../ui-kit/Chat/Messages/styles';
import Skeleton from 'react-loading-skeleton';

export const SkeletonContactCard = () => {
  return (
    <MessagePreviewCardWrapper bordered={true} fullWidth={true}>
      <Skeleton circle={true} width={'42px'} height={'42px'} />
      <PreviewMessageCardBody fullWidth={true}>
        <PreviewMessageText>
          <Skeleton width={'80%'} />
        </PreviewMessageText>
        <PreviewMessageText>
          <Skeleton width={'60%'} />
        </PreviewMessageText>
      </PreviewMessageCardBody>
    </MessagePreviewCardWrapper>
  );
};
