import React from 'react';
import {GroupCardLabelJoinBtn, GroupRightColumn, MembersColumnHead, MembersColumnTitle, MembersList} from './styles';
import {useTranslation} from 'react-i18next';
import {User} from '../../queries/types/user';
import {CommunityStateType} from '../../states/community';
import {PersonCommonCard} from '../common/Person';
import {MemberSkeleton} from '../common/Skeletons/PeopleSkeleton';

type GroupMembersProps = {
  members?: User[];
  community?: CommunityStateType;
  loading?: boolean;
  onJoin?: () => void;
  isJoined?: boolean;
};

export const GroupMembersColumn: React.FC<GroupMembersProps> = ({members, community, loading, onJoin, isJoined}) => {
  const {t} = useTranslation();
  const membersTitle = t('groups:members.title');
  const joinBtn = t('groups:card.join');
  return (
    <GroupRightColumn>
      <MembersColumnHead>
        <MembersColumnTitle>{membersTitle}</MembersColumnTitle>
        {!isJoined && <GroupCardLabelJoinBtn onClick={onJoin}>{joinBtn}</GroupCardLabelJoinBtn>}
      </MembersColumnHead>
      <MembersList>
        {loading && !members ? (
          <>
            <MemberSkeleton />
            <MemberSkeleton />
            <MemberSkeleton />
          </>
        ) : (
          members?.map((el) => <PersonCommonCard {...el} key={el?.objectId} comm={community} />)
        )}
      </MembersList>
    </GroupRightColumn>
  );
};
