import React from 'react';
import {CounterAction, CounterInputActions, CounterInputLabel, CounterInputWrapper, CounterValue} from './styles';

type CounterInputProps = {
  onChange?: (v: number) => void;
  value?: number;
  label?: string;
  placeholder?: string;
  min: number;
  max: number;
};

export const CounterInput: React.FC<CounterInputProps> = ({onChange, value = 0, label, max, min, placeholder}) => {
  const increase = () => value + 1 <= max && onChange?.(value + 1);
  const decrease = () => value - 1 >= min && onChange?.(value - 1);
  return (
    <CounterInputWrapper>
      <CounterInputLabel>{value ? label : placeholder}</CounterInputLabel>
      <CounterInputActions>
        <CounterAction onClick={decrease}>-</CounterAction>
        <CounterValue $active={!!value}>{value}</CounterValue>
        <CounterAction onClick={increase}>+</CounterAction>
      </CounterInputActions>
    </CounterInputWrapper>
  );
};
