import {useTranslation} from 'react-i18next';
import {GQLHelpDeskWhereInput, GQLUpdateHelpDeskFieldsInput} from '../graphql.schema';
import {HelpDeskItemType} from '../queries/types/helpDesk';
import {CommunityStateType} from '../states/community';
import {SelectOption, translateFn} from '../types/common';
import {baseHelpDeskItem, helpDeskButtonType, HelpDeskFormValues, HelpDeskItemT} from '../types/helpDesk';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {MenuItemType} from '../ui-kit/Menu/types';

export const getMainPositions = (list?: HelpDeskItemT[]) => {
  const base = [baseHelpDeskItem.haveAppExpIdeas, baseHelpDeskItem.haveAppIssues];
  return {
    custom:
      list?.filter((el) => !base.includes(el.descr as baseHelpDeskItem) && el.type !== HelpDeskItemType.dailyQuote) ||
      [],
    main:
      list?.filter((el) => base.includes(el.descr as baseHelpDeskItem) && el.type !== HelpDeskItemType.dailyQuote) ||
      [],
    quotes: list?.filter((el) => el.type === HelpDeskItemType.dailyQuote) || [],
  };
};

export enum HelpDeskCreateTypes {
  message = 'message',
  link = 'link',
  faq = 'faq',
}

export const getHelpDeskTypeOptions = (): SelectOption<string>[] => {
  const {t} = useTranslation();

  return [
    {value: HelpDeskCreateTypes.message, label: t(`helpDesk:form.type.${HelpDeskCreateTypes.message}`), key: '1'},
    {value: HelpDeskCreateTypes.link, label: t(`helpDesk:form.type.${HelpDeskCreateTypes.link}`), key: '2'},
    {value: HelpDeskCreateTypes.faq, label: t(`helpDesk:form.type.${HelpDeskCreateTypes.faq}`), key: '3'},
  ];
};

export const getAdminsOptions = (community: CommunityStateType): SelectOption<string>[] => {
  return (
    community?.Admins?.map((el, i) => ({
      value: el.User.objectId,
      label: el.User.firstName + ' ' + el.User.firstLetter + '.',
      key: el.id || String(i),
    })) || []
  );
};

export const buttonsTypes = [
  helpDeskButtonType.bugReport,
  helpDeskButtonType.shareFeedback,
  helpDeskButtonType.faqView,
];

export const correctButtonTranslate = (t: translateFn, buttonTitle?: string) => {
  if (buttonsTypes.includes(buttonTitle as helpDeskButtonType)) {
    return t(`helpDesk:card.buttons.${buttonTitle}`);
  }
  return buttonTitle;
};

export const helpDeskWhereResident: GQLHelpDeskWhereInput = {isDeleted: {equalTo: false}, isPublished: {equalTo: true}};
export const helpDeskWhereManager: GQLHelpDeskWhereInput = {isDeleted: {equalTo: false}};

export const formatLink = (link?: string) => {
  if (!link?.includes('http')) return `http://${link}`;
  return link;
};

export interface GetMenuHelpDeskOptions {
  isPublished?: boolean;
  objectId?: string;
  isView?: boolean;
}

export interface GetMenuHelpDeskFuncOptions {
  unPublishCallBack: (id?: string) => void;
  publishCallBack: (id?: string) => void;
  deleteCallBack: (id?: string) => void;
  viewCallBack: (id?: string) => void;
  editCallBack?: (id?: string) => void;
  t: (key: string) => string;
}

export const getMenuHelpDesk = (
  options: GetMenuHelpDeskOptions,
  functions: GetMenuHelpDeskFuncOptions,
): MenuItemType[] => {
  const {isPublished, objectId, isView} = options;
  const {t, unPublishCallBack, deleteCallBack, viewCallBack, publishCallBack, editCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  addedOptions.push({
    title: isView ? t('helpDesk:card.menu.close') : t('helpDesk:card.menu.view'),
    onClick: () => viewCallBack(objectId),
  });
  if (!isPublished) {
    addedOptions.push({
      title: t('helpDesk:card.menu.publish'),
      onClick: () => publishCallBack(objectId),
    });
  }

  addedOptions.push({
    title: t('helpDesk:card.menu.edit'),
    onClick: () => editCallBack?.(objectId),
  });

  addedOptions.push({
    render: 'line',
  });

  if (isPublished) {
    addedOptions.push({
      title: t('helpDesk:card.menu.unpublish'),
      onClick: () => unPublishCallBack(objectId),
      render: 'danger',
    });
  }

  if (deleteCallBack) {
    addedOptions.push({
      title: t('helpDesk:card.menu.delete'),
      onClick: () => deleteCallBack(objectId),
      render: 'danger',
    });
  }

  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuHelpDesk = (
  options: GetMenuHelpDeskOptions,
  functions: GetMenuHelpDeskFuncOptions,
): MobileMenuEntry[] => {
  return getMenuHelpDesk(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export interface GetMenuQuoteOptions {
  isPublished?: boolean;
  objectId?: string;
}

export interface GetMenuQuoteFuncOptions {
  unPublishCallBack: (id?: string) => void;
  publishCallBack: (id?: string) => void;
  t: (key: string) => string;
}

export const getMenuQuote = (options: GetMenuQuoteOptions, functions: GetMenuQuoteFuncOptions): MenuItemType[] => {
  const {isPublished, objectId} = options;
  const {t, unPublishCallBack, publishCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (!isPublished) {
    addedOptions.push({
      title: t('helpDesk:card.menu.publish'),
      onClick: () => publishCallBack(objectId),
    });
  } else {
    addedOptions.push({
      title: t('helpDesk:card.menu.unpublish'),
      onClick: () => unPublishCallBack(objectId),
      render: 'danger',
    });
  }
  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuQuote = (
  options: GetMenuQuoteOptions,
  functions: GetMenuQuoteFuncOptions,
): MobileMenuEntry[] => {
  return getMenuQuote(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const helpDeskToEditState = (item: HelpDeskItemT): Partial<HelpDeskFormValues> => {
  const newType = item.type as string as HelpDeskCreateTypes;
  return {
    objectId: item.objectId,
    type: newType,
    descr: item.descr,
    title: item.title,
    buttonTitle: item.buttonTitle,
    Assignee: item.Assignee?.objectId,
    assignTo: item.assignTo,
  };
};

export const toPointerEditHelpDesk = (values: Partial<HelpDeskFormValues>): GQLUpdateHelpDeskFieldsInput => {
  return {
    descr: values.descr,
    type: values.type,
    title: values.title,
    buttonTitle: values.buttonTitle,
    Assignee: values.Assignee,
    assignTo: values.assignTo,
  };
};
