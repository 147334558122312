import styled from 'styled-components';
import {Media, theme} from '../../../ui-kit/theme';
import {Link} from '../../../ui-kit/Typography/Link';
import AIBackgroung from './assets/AI_background.png';
export const EventContainer = styled.div<{noBorder?: boolean}>`
  background-color: ${({theme}) => theme.palette.common.blueLight};
  border: ${({noBorder, theme}) => (noBorder ? `none` : `1px solid ${theme.palette.grayscale._11}`)};
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  ${theme.font.primary.regular};
`;

export const AIPromotionWrapper = styled.div`
  margin-top: 8px;
  max-height: 85px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 6px;
  background-image: ${`url(${AIBackgroung})`};
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  flex-direction: row;
  cursor: pointer;
`;

export const FirstLineWrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const LogoWrapper = styled.span`
  background-color: ${theme.palette.common.yellowLight3};
  border-radius: 4px;
  width: fit-content;
  padding: 2px 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  white-space: nowrap;
`;

export const AIIconWrapper = styled.span`
  background-color: ${theme.palette.common.yellowLight3};
  border-radius: 20px;
  width: fit-content;
  padding: 3px 7px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  white-space: nowrap;
  cursor: pointer;
  gap: 4px;
`;

export const TextWrapperPromotion = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 4px;
`;

export const EventWrapper = styled.div<{noBorder?: boolean}>`
  padding: 19px 74px 19px 26px;
  position: relative;
  display: flex;
  align-items: center;
  ${Media.down.s} {
    padding: 15px 34px 15px 14px;
  }
`;
export const PostReactionsWrapper = styled.div`
  padding: 0 17px 19px 26px;
  display: flex;
  & > div {
    margin-right: 19px;
  }
  ${Media.down.s} {
    padding: 0px 34px 15px 14px;
  }
`;

export const CloseBtn = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  ${Media.down.s} {
    right: 0;
  }
`;

export const EventDate = styled.div<{showMob?: boolean}>`
  font-size: 12px;
  margin-left: auto;
  margin-right: 0;
  word-wrap: break-word;
  ${Media.down.s} {
    display: none;
  }
  ${({showMob}) =>
    showMob &&
    `display: none;
    ${Media.down.s} {
      display: block;
    }`};
`;

export const EventContent = styled.div`
  font-size: 14px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectedText = styled.span`
  ${theme.font.primary.medium};
  text-decoration: underline;
  margin-right: 6px;
  font-size: 14px;
`;

export const SelectedLink = styled(Link)`
  ${theme.font.primary.medium};
  text-decoration: underline;
  margin-right: 6px;
  color: ${({theme}) => theme.palette.grayscale._12};
  font-size: 14px;
`;
