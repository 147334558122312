import {gql} from '@apollo/client';
import {CommunitySmallFragment} from './community';

export const GroupFragment = gql`
  fragment GroupFragment on Group {
    id
    objectId
    createdAt
    updatedAt
    isDeleted
    name
    description
    alias
    type
    Members {
      ... on UserConnection {
        count
      }
    }
    Community {
      ...CommunitySmallFragment
    }
    Avatar {
      file {
        name
        url
      }
    }
    Featured {
      ... on Community {
        ...CommunitySmallFragment
      }
    }
  }
  ${CommunitySmallFragment}
`;

export const GroupShortFragment = gql`
  fragment GroupShortFragment on Group {
    id
    objectId
    name
    description
    type
    alias
    Members {
      ... on UserConnection {
        count
      }
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
    Featured {
      ... on Community {
        ...CommunitySmallFragment
      }
    }
  }
  ${CommunitySmallFragment}
`;
