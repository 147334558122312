import React from 'react';
import FacebookLogin from 'react-facebook-login-typed';
import AppleLogin from 'react-apple-login';

import {CLIENT_FACEBOOK_ID, CLIENT_APPLE_ID, SITE_URL} from '../../constants/env';
import {SocialButton} from './SocialButton';
import {SocialProps} from '../../types/auth';
import {ErrorMessage, GoogleButtonWrapper, GoogleLoginStyled} from './styles';

export const SocialButtonsComponent: React.FC<SocialProps> = ({
  reqWIthGoogle,
  reqWIthFacebook,
  reqWIthApple,
  error,
  connectSocialInfo,
  disconnectAccount,
  loadingButton,
  onGoogleLoginClick,
  googleReady,
  fbReady,
  onFBClick,
}) => {
  const disabledButtons = loadingButton?.facebook || loadingButton?.google || loadingButton?.apple;
  return (
    <>
      <FacebookLogin
        appId={CLIENT_FACEBOOK_ID as string}
        fields="name, email, picture.width(720)"
        callback={reqWIthFacebook}
        disableMobileRedirect={true}
        onClick={onFBClick}
        render={(renderProps) => (
          <SocialButton
            type={'facebook'}
            connectSocialInfo={connectSocialInfo}
            connectInfo={connectSocialInfo?.isFacebook}
            disconnectAccount={disconnectAccount}
            onClick={!fbReady ? onFBClick : (renderProps.onClick as () => void)}
            loading={loadingButton?.facebook}
            disabled={disabledButtons || renderProps?.isDisabled}
          />
        )}
      />

      <GoogleButtonWrapper isConnect={connectSocialInfo?.isGoogle} onClick={onGoogleLoginClick}>
        {googleReady && <GoogleLoginStyled onSuccess={reqWIthGoogle} />}
        <SocialButton
          type={'google'}
          connectSocialInfo={connectSocialInfo}
          connectInfo={connectSocialInfo?.isGoogle}
          disconnectAccount={disconnectAccount}
          onClick={googleReady ? reqWIthGoogle : undefined}
          loading={loadingButton?.google}
          disabled={googleReady ? disabledButtons : false}
        />
      </GoogleButtonWrapper>

      <AppleLogin
        clientId={CLIENT_APPLE_ID as string}
        redirectURI={SITE_URL as string}
        usePopup={true}
        scope={'name email'}
        responseMode={'form_post'}
        callback={reqWIthApple}
        render={(renderProps) => (
          <SocialButton
            type={'apple'}
            connectSocialInfo={connectSocialInfo}
            connectInfo={connectSocialInfo?.isApple}
            disconnectAccount={disconnectAccount}
            onClick={renderProps.onClick}
            loading={loadingButton?.apple}
            disabled={disabledButtons}
          />
        )}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
