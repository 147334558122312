import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MobileSearchWrapper, SearchLineWrapper, WrapperPage, WrapperPeoplePage} from './styles';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {ManageActions, ManageActionsList, PeoplePropsT} from './types';
import {PeopleBlock} from './PeopleBlock';
import {Search} from './Search';
import {MobileSearch} from './MobileSearch';
import {getPeopleByType} from '../../helpers/people';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {EmptyTag} from '../../containers/MobileTabs';
import {InvitePeopleModal} from '../common/Modal/InvitePeople';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

export const ManagePeople: React.FC<PeoplePropsT> = ({
  members,
  newMembers,
  actionsList,
  setSearchParams,
  user,
  loading,
  peopleType,
  setPeopleType,
  //membersItemsCount,
  community,
  fetchMore,
  hasMore,
  reportActions,
  refetch,
  adminLabels,
  canInvite,
  hasNextPage,
  loadMore,
}) => {
  const {t} = useTranslation();
  const textSeo = t('common:seo.people', {
    text: community?.name || '',
  });

  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const ListTitle1 = t('people:peopleBlock.newMembers.title');
  const ListTitle2 = t('people:peopleBlock.allMembers.title');
  const emptyText1 = t('people:peopleBlock.newMembers.empty');
  const {getLink} = useLinks();
  const [focusPerson, setFocusPerson] = useState<string>('');
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [isShowModalInvite, setShowModalInvite] = useState<boolean>(false);
  const handleOpenInvite = () => setShowModalInvite(true);
  const handleCloseInvite = () => setShowModalInvite(false);
  const handleOpenDelete = (id: string) => {
    setFocusPerson(id);
    setShowModalDelete(true);
  };
  const handleCloseDelete = () => {
    setFocusPerson('');
    setShowModalDelete(false);
  };
  const newActionList: ManageActionsList | undefined = {
    ...actionsList,
    personRemove: (id: string) => handleOpenDelete(id),
  };
  const handleRemove = async () => {
    await actionsList?.personRemove?.(focusPerson);
    handleCloseDelete();
  };
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('people:modals.delete.title'),
        okText: t('people:modals.delete.button'),
        cancelText: t('people:modals.delete.close'),
      },
    };
  }, []);
  const PortalWrapper = isMobile ? BasePortal : EmptyTag;
  const invLink = getLink(route.inviteCommunity.path);
  const peopleList = useMemo(() => {
    if (members) return getPeopleByType(peopleType, members);
    return [];
  }, [peopleType, members?.countMembers]);
  return (
    <WrapperPage>
      <Seo title={textSeo} keywords={seoKeywords} description={community?.descr} og={{image: community?.avatar}} />
      <WrapperPeoplePage className={'people__page'}>
        <SearchLineWrapper>
          <Search
            setSearchParams={setSearchParams}
            community={community}
            setPeopleType={setPeopleType}
            invite={!!canInvite}
          />
        </SearchLineWrapper>
        <MobileSearchWrapper>
          <MobileSearch
            setSearchParams={setSearchParams}
            community={community}
            setPeopleType={setPeopleType}
            invite={!!canInvite}
          />
        </MobileSearchWrapper>
        {newMembers?.newMembers?.[0] && (
          <PeopleBlock
            listTitle={ListTitle1}
            communityId={members?.objectId}
            list={newMembers?.newMembers}
            actionsType={ManageActions.MANAGE_NEW}
            manageActions={newActionList}
            emptyText={emptyText1}
            loading={loading}
            reportActions={reportActions}
            refetch={refetch}
            adminLabels={adminLabels}
          />
        )}

        <PeopleBlock
          listTitle={ListTitle2}
          communityId={members?.objectId}
          list={peopleList}
          actionsType={ManageActions.MANAGE_ALL}
          manageActions={newActionList}
          viewerId={user?.objectId}
          loading={loading}
          fetchMore={fetchMore}
          hasMore={hasMore}
          reportActions={reportActions}
          refetch={refetch}
          adminLabels={adminLabels}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          //listingCount={listingCount}
        />
      </WrapperPeoplePage>
      <AddButtonPortal
        onClick={handleOpenInvite}
        iconType={'person-add'}
        animated
        animatedText={t('common:addButtons.invite')}
      />
      {isShowModalDelete && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              title={modalsText.delete.title}
              onClose={handleCloseDelete}
              okText={modalsText.delete.okText}
              onOk={handleRemove}
              buttonType={'danger'}
              cancelText={modalsText.delete.cancelText}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
      {isShowModalInvite && (
        <PortalWrapper>
          <InvitePeopleModal
            onClose={handleCloseInvite}
            inviteLink={invLink}
            isManager={canInvite}
            commPassCode={community?.passCode}
            communityName={community?.name}
          />
        </PortalWrapper>
      )}
    </WrapperPage>
  );
};
