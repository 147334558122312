import {gql} from '@apollo/client';
import CommunityFragment, {
  CommunityMicroFragment,
  CommunityShortFragment,
  CommunitySmallFragment,
} from './fragments/community';
import {LocationFragment} from './fragments/locations';
import PaymentPlanFragment from './fragments/paymentPlan';
import {AdminFragment, ShortAdminFragment} from './fragments/people';
import UserFragment from './fragments/user';
import {Community, CommunityAdmin, TypeCommunityRequest} from './types/community';
import {Connection} from './types/parse';
import {User} from './types/user';

export type CommunitiesResponseType = {
  communities: Connection<Community>;
};

export const GetCommunitiesQuery = gql`
  query communities(
    $skip: Int
    $cursor: String
    $first: Int
    $where: CommunityWhereInput
    $userId: ID
    $order: [CommunityOrder!]
  ) {
    communities(skip: $skip, after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...CommunityShortFragment
          JoinRequests(where: {User: {have: {id: {equalTo: $userId}}}}) {
            edges {
              node {
                status
                request
                answer
              }
            }
          }
        }
      }
    }
  }
  ${CommunityShortFragment}
`;

export const GetCommunitiesMicroQuery = gql`
  query communities($skip: Int, $cursor: String, $first: Int, $where: CommunityWhereInput, $order: [CommunityOrder!]) {
    communities(skip: $skip, after: $cursor, order: $order, first: $first, where: $where) {
      count
      edges {
        cursor
        node {
          ...CommunityMicroFragment
        }
      }
    }
  }
  ${CommunityMicroFragment}
`;

export interface CreateCommunityResponseType {
  createCommunity: {
    community: Community;
  };
}

export interface CommunityRequestType {
  fields: TypeCommunityRequest;
}

export const CreateCommunityQuery = gql`
  mutation createCommunity($fields: CreateCommunityFieldsInput) {
    createCommunity(input: {fields: $fields}) {
      community {
        ...CommunityFragment
      }
    }
  }
  ${CommunityFragment}
`;

export interface CreateCommunityForNewUserRequest {
  createCommunityForNewUser: {
    communityId: string;
  };
}

export const CreateCommunityForNewUserQuery = gql`
  mutation createCommunityForNewUser {
    createCommunityForNewUser {
      communityId
    }
  }
`;

export interface AddAddressItem {
  unit: string;
  zip: string;
  address: string;
  id: string;
}

interface AddAddressProps {
  address_add: (AddAddressItem[] | AddAddressItem | undefined)[];
}
export interface UpdateCommunityRequestType {
  id?: string;
  fields: TypeCommunityRequest | AddAddressProps;
}

export interface UpdateCommunityResponseType {
  updateCommunity: {
    community: Community;
  };
}

export const UpdateCommunityQuery = gql`
  mutation updateCommunity($id: ID!, $fields: UpdateCommunityFieldsInput) {
    updateCommunity(input: {id: $id, fields: $fields}) {
      community {
        ...CommunityFragment
      }
    }
  }
  ${CommunityFragment}
`;

export interface AddMoreAddressResponseType {
  addMoreAddress: {
    addAddress: AddAddressProps;
  };
}

export interface createJoinRequestType {
  fields: {
    User: {
      link: string;
    };
    Community: {
      link: string;
    };
    Invitation?: {
      link?: string;
    };
  };
}

export interface createJoinResponseType {
  createJoinRequest: {
    joinRequest: {
      Community: Community;
      User: User;
      // User: User;
      status: string;
    };
  };
}

export const createJoinRequest = gql`
  mutation createJoinRequest($fields: CreateJoinRequestFieldsInput) {
    createJoinRequest(input: {fields: $fields}) {
      joinRequest {
        User {
          ...UserFragment
        }
        Community {
          ...CommunityFragment
        }
        status
        date
      }
    }
  }
  ${CommunityFragment}
  ${UserFragment}
`;

export type GetCommunityReqType = {
  id: string;
};

export type GetCommunityResType = {
  community: Community;
};

export const GetCommunityRequest = gql`
  query community($id: ID!) {
    community(id: $id) {
      ...CommunityFragment
    }
  }
  ${CommunityFragment}
`;

export const GetCommunityShortRequest = gql`
  query community($id: ID!) {
    community(id: $id) {
      ...CommunityShortFragment
    }
  }
  ${CommunityShortFragment}
`;

export const GetCommunitySmallRequest = gql`
  query community($id: ID!) {
    community(id: $id) {
      id
      objectId
      Location {
        ...LocationFragment
      }
      zip
      address
      name
      Avatar {
        file {
          name
          url
        }
      }
      alias
      accessType
    }
  }
  ${LocationFragment}
`;

export const GetCommunityAlias = gql`
  query community($id: ID!) {
    community(id: $id) {
      alias
    }
  }
`;

export interface RemoveCommunityAdminRequest {
  id: string;
}

export interface RemoveCommunityAdminResponse {
  deleteCommunityAdmin: {
    communityAdmin: CommunityAdmin;
  };
}

export const RemoveCommunityAdmin = gql`
  mutation communityAdminRemove($id: ID!) {
    deleteCommunityAdmin(input: {id: $id}) {
      communityAdmin {
        ...AdminFragment
      }
    }
  }

  ${AdminFragment}
`;

export interface AccountTransferRequest {
  communityId: string;
  adminId: string;
}

export interface AccountTransferResponse {
  accountTransfer: {
    success: boolean;
  };
}

export const AccountTransfer = gql`
  mutation accountTransfer($adminId: ID!, $communityId: ID!) {
    accountTransfer(input: {adminId: $adminId, communityId: $communityId}) {
      success
    }
  }
`;

export interface GetCommunityAdminRequest {
  id: string;
}

export interface GetCommunityAdminResponse {
  communityAdmins: Connection<CommunityAdmin>;
}

export const GetCommunityAdmin = gql`
  query getAdmins($id: ID!) {
    communityAdmins(where: {User: {have: {objectId: {equalTo: $id}}}}) {
      edges {
        node {
          ...AdminFragment
        }
      }
    }
  }

  ${AdminFragment}
`;

export type GetPaymentPlanReqType = {
  id: string;
};

export type GetPaymentPlanResType = {
  community: Community;
};

export const GetPaymentPlanRequest = gql`
  query community($id: ID!) {
    community(id: $id) {
      objectId
      Subscr {
        PaymentPlan {
          ...PaymentPlanFragment
          serviceFees
          salesFees
          rentFees
        }
      }
    }
  }
  ${PaymentPlanFragment}
`;

export const GetCommunityInfo = gql`
  query communities($where: CommunityWhereInput) {
    communities(where: $where) {
      edges {
        node {
          ...CommunityShortFragment
        }
      }
    }
  }
  ${CommunityShortFragment}
`;

export const GetShortCommunityInfo = gql`
  query communities($where: CommunityWhereInput) {
    communities(where: $where) {
      edges {
        node {
          id
          objectId
          createdAt
          updatedAt
          Avatar {
            file {
              name
              url
            }
          }
          name
          descr
          alias
          address
          zip
          type
          units
          buildings
          accessType
          listingApproval
          invitation
          isVerified
          countMembers
          options
          Location {
            ...LocationFragment
          }
          Subscr {
            id
            objectId
            PaymentPlan {
              ...PaymentPlanFragment
            }
            paymentPeriod
          }
        }
      }
    }
  }
  ${PaymentPlanFragment}
  ${LocationFragment}
`;

export const GetCommunityInfoRequests = gql`
  query communitiesRequests($where: CommunityWhereInput, $requestsWhere: JoinRequestWhereInput) {
    communities(where: $where) {
      edges {
        node {
          ...CommunityShortFragment
          JoinRequests(where: $requestsWhere) {
            edges {
              node {
                status
                request
                answer
                User {
                  id
                  objectId
                }
              }
            }
          }
        }
      }
    }
  }
  ${CommunityShortFragment}
`;

export interface SendManagerInviteResponse {
  notifyManager: {
    ok: boolean | null;
  };
}

export interface SendManagerInviteRequest {
  email: string;
  text: string;
}

export interface SendInviteRequest {
  email: string;
  text: string;
  community: {
    link: string;
  };
}

export const SendManagerInvite = gql`
  mutation sendInvitation($email: String!, $text: String!) {
    notifyManager(input: {email: $email, emailText: $text}) {
      ok
    }
  }
`;

export interface SendInviteResponse {
  createInvitation: {
    invitation: {
      id: string;
      objectId: string;
    };
  };
}

export const SendInvite = gql`
  mutation createInvite($email: String, $text: String, $community: CommunityPointerInput) {
    createInvitation(input: {fields: {email: $email, emailText: $text, Community: $community}}) {
      invitation {
        id
        objectId
      }
    }
  }
`;

export const GetCountJoinRequests = gql`
  query joinRequest($where: JoinRequestWhereInput) {
    joinRequests(where: $where) {
      count
      edges {
        node {
          Community {
            ...CommunityMicroFragment
            Owner {
              objectId
            }
          }
          id
          objectId
          status
          updatedAt
          User {
            id
            objectId
          }
        }
      }
    }
  }
  ${CommunityMicroFragment}
`;

export const GetInvitationRequests = gql`
  query invitation($where: InvitationWhereInput) {
    invitations(where: $where) {
      count
      edges {
        node {
          Community {
            ...CommunityMicroFragment
            Owner {
              objectId
            }
          }
          invLink
        }
      }
    }
  }
  ${CommunityMicroFragment}
`;

export const RemoveResidentFromCommunityRequest = gql`
  mutation removeFromCommunity($userId: String!, $communityId: String!) {
    managerRemoveResident(input: {residentId: $userId, communityId: $communityId})
  }
`;

export const GetShortCommunitiesQuery = gql`
  query communities($cursor: String, $first: Int, $where: CommunityWhereInput, $order: [CommunityOrder!]) {
    communities(after: $cursor, order: $order, first: $first, where: $where) {
      count
      edges {
        cursor
        node {
          ...CommunitySmallFragment
        }
      }
    }
  }
  ${CommunitySmallFragment}
`;

export const GetCommunitiesCount = gql`
  query communities($cursor: String, $first: Int, $where: CommunityWhereInput) {
    communities(after: $cursor, first: $first, where: $where) {
      count
    }
  }
`;

export interface GetManagersListRequestType {
  communityId: string;
}

export type AdminInfoT = {
  id: string;
  fullName: string;
  email: string;
  label: string;
};

export type GetManagersListResponseType = {getManagersList: AdminInfoT[]};

export const GetManagersList = gql`
  mutation getManagersList($communityId: String!) {
    getManagersList(input: {communityId: $communityId}) {
      id
      fullName
      email
      label
    }
  }
`;

export const GetCommunityList = gql`
  query communities($cursor: String, $first: Int, $where: CommunityWhereInput, $order: [CommunityOrder!]) {
    communities(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        node {
          ...CommunityMicroFragment
          Owner {
            objectId
          }
        }
      }
    }
  }
  ${CommunityMicroFragment}
`;

export const GetCurrentCommunityInfo = gql`
  query communities($cursor: String, $first: Int, $where: CommunityWhereInput, $order: [CommunityOrder!]) {
    communities(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        node {
          id
          objectId
          createdAt
          updatedAt
          Avatar {
            file {
              name
              url
            }
          }
          checkList
          name
          descr
          alias
          address
          identityData
          zip
          accessType
          listingApproval
          invitation
          isVerified
          passCode
          type
          countMembers
          Subscr {
            id
            objectId
            PaymentPlan {
              ...PaymentPlanFragment
            }
            paymentPeriod
          }
          Location {
            ...LocationFragment
          }
          allowChat
          allowEvents
          allowPosts
          Owner {
            id
            objectId
          }
          Admins {
            ... on CommunityAdminConnection {
              count
              edges {
                node {
                  ...ShortAdminFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${LocationFragment}
  ${ShortAdminFragment}
  ${PaymentPlanFragment}
`;

export interface leaveCommunityRes {
  success?: boolean;
}

export interface leaveCommunityParams {
  communityId: string;
}

export const leaveCommunity = gql`
  mutation leaveCommunity($communityId: ID!) {
    leaveCommunity(input: {communityId: $communityId})
  }
`;
