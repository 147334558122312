import React from 'react';
import styled from 'styled-components';
import {CheckboxProps} from '../../ui-kit/Checkbox/types';
import {Checkbox} from '../../ui-kit/Checkbox/Checkbox';
import {fontPrimaryMedium} from './styles';
import {MemoInfoHint} from './InfoHint';

const CheckItemContainer = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  color: ${({theme}) => theme.palette.text.octonary};
  ${fontPrimaryMedium}

  & > span {
    line-height: 1.2em;
    margin-left: 15px;
  }
`;

const StyledInfoHint = styled(MemoInfoHint)`
  position: relative;
  bottom: -4px;
  margin-left: 7px;
`;

export const CheckItem: React.FC<CheckboxProps> = (props) => {
  const {children, hintText} = props;

  return (
    <CheckItemContainer>
      <Checkbox size={16} {...props} />
      <span>{children}</span>
      <StyledInfoHint
        top={90}
        width={'23'}
        height={'23'}
        fillChildren={'grayLight-2'}
        stroke={'grayLight-2'}
        hintText={hintText}
        zIndex={1}
      />
    </CheckItemContainer>
  );
};
