import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {useTranslation} from 'react-i18next';
import {User} from '../../queries/types/user';
import {ProfileCard, WrapperPage, WrapperProfileCard, ReviewsWrapper, EmptyOffset} from './styles';
import {status} from '../../ui-kit/ProfileIntro/types';
import {TypeCommunity} from '../../types/auth';
import {Seo} from '../SEO';
import {makeSeoKeywords} from '../../helpers/common';
import {Content} from './Content';
import {MemoHelpCentreButton} from '../Account/Popups/HelpCentreButton/HelpCentreButton';
import {TItem} from '../../types/item';
import {NavContainer} from '../../containers/Navigation';
import {TypeUserStateType} from '../../states/typeUser';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {isImage} from '../../helpers/settings';
import {ProfileIntroSkeleton} from '../common/Skeletons/ProfileIntroSkeleton';
import {Reviews} from '../Profile/content/Reviews';
import {ReviewsPair} from '../../types/reviews';
import {userSection} from '../../containers/HomePageUser/types';
import {useLinks} from '../../hooks/common';
import {ContentLoader} from '../../ui-kit/Loader/ContentLoader';
import {reportActions} from '../../hooks/report';
import {getResize} from '../../helpers/file';
import {IGetCurrValue} from '../../types/common';
import {RichText} from '../common/RichText/RichText';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {Popup} from '../../ui-kit/Popup';
import {AddMenu} from '../Account/Popups/HeaderMenu/AddMenu';
import {ListEventsWithPreview} from '../Events/ListEventsWithPreview';
import {useViewerId} from '../../hooks/user';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {useEventPageContent} from '../../hooks/event';
import {GQLEventWhereInput} from '../../graphql.schema';
import {getDateHourBorder} from '../../helpers/event';
import {SubTitle, WrapperContent} from './styles';
import {PermissionsList} from '../../types/settings';
import ChecklistMobileContainer from '../../containers/Checklist/ChecklistMobileContainer';
import {useGetCheckListInfo} from '../../hooks/checklist';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {ProfileSection} from '../Profile/ProfileSection';
import {getAvarageReviewsStars} from '../../helpers/reviews';
import {isMobile} from 'react-device-detect';
import {isModal} from '../../states/themeMode';
import {TInterest} from '../../types/interests';
import {useGetInterests} from '../../hooks/interests';

type TProps = {
  user: User | null;
  typeUser?: TypeUserStateType;
  reqUserType?: TypeCommunity;
  communityId?: string;
  items?: Partial<TItem>[];
  loading?: boolean;
  updateAvatar?: (photo: File | undefined, id: string) => Promise<User | undefined>;
  reviews?: ReviewsPair;
  showPopup?: () => void;
  section?: string;
  resetSection: () => void;
  reviewsLoading?: boolean;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  adminsLabels: Record<string, string>;
  listingsAvailable?: boolean;
  isSupport?: boolean;
  loadingAvatar: boolean;
  permissions?: PermissionsList[];
  goBack?: () => void;
  interestsLink?: {interest: TInterest; link: string | undefined}[];
  goToGroup: (link?: string) => void;
};

export const HomePageUser: React.FC<TProps> = ({
  user,
  typeUser,
  items,
  loading,
  updateAvatar,
  communityId,
  reviews,
  reqUserType,
  showPopup,
  section,
  resetSection,
  reviewsLoading,
  reportActions,
  getSignValue,
  adminsLabels,
  listingsAvailable,
  isSupport,
  loadingAvatar,
  permissions,
  goBack,
  interestsLink,
  goToGroup,
}) => {
  const {t} = useTranslation();
  const [error, setError] = useState<string>('');
  const {push} = useHistory();
  const userId = useViewerId('objectId');
  const [community] = useRecoilState(currentCommunity);
  const baseText = t('common:seo.profile');
  const textTitle = `${user?.firstName || baseText} ${user?.lastName || ''}`;
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textTitle]);
  const revRef = useRef<HTMLDivElement>(null);
  const {getLink} = useLinks();
  const rating = getAvarageReviewsStars(reviews);
  const reviewsContainerRef = useRef<HTMLDivElement>(null);
  const setModal = useSetRecoilState(isModal);
  const {values: interestsValues} = useGetInterests();
  useEffect(() => {
    if (section === userSection.reviews && revRef.current) {
      revRef?.current?.scrollIntoView({block: 'end'});
      resetSection();
    }
  }, [section, revRef]);

  const onScrollToReviews = () => {
    reviewsContainerRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const statusType =
    reqUserType === TypeCommunity.manager || reqUserType === TypeCommunity.admin ? 'tertiary' : 'secondary';

  const statusLabel =
    (user?.objectId && adminsLabels[user.objectId]) ||
    t('common:typeUser', {context: reqUserType || TypeCommunity.resident});

  const tReviews = t('people:profile.titles.reviews');
  const today = getDateHourBorder();
  const city = user?.Location?.name;
  const state = user?.Location?.State?.name;
  const correctState =
    state && state?.split(' ').length > 1
      ? state
          ?.split(' ')
          .map((w) => w[0]?.[0])
          .join('')
      : state;
  const country = user?.Location?.Country?.name;

  const searchParams: GQLEventWhereInput[] = useMemo(() => {
    return [
      {
        endTime: {
          greaterThan: today,
        },
      },
      {
        Lister: {
          have: {
            objectId: {
              equalTo: userId,
            },
          },
        },
      },
    ];
  }, [userId, today]);
  const {hasMoreAllEvents, fetchDataAllEvents, communityEvents} = useEventPageContent(searchParams, community);

  const handleRedirect = () => push(getLink(route.personalSettings.path));
  const handleUpdatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setError('');
    if (file && file?.size / 1024 / 1024 > 10) {
      setError(t('error:invalidSize'));
    } else if (!isImage(file?.name)) {
      setError(t('error:invalidFormat'));
    } else {
      updateAvatar?.(file, user?.id ?? '')
        .then(() => (e.target.value = ''))
        .catch(() => {
          setError(t('error:invalidCharacters'));
          e.target.value = '';
        });
    }
  };
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const openAddMenuHandler = () => setAddMenuOpen(true);
  const closeAddMenuHandler = () => setAddMenuOpen(false);
  const {completedCheckList} = useGetCheckListInfo();
  return (
    <NavContainer hideHeader={true}>
      <WrapperPage>
        <Seo title={textTitle} keywords={seoKeywords} />
        <WrapperProfileCard>
          {completedCheckList && <ChecklistMobileContainer />}
          {Boolean(user) ? (
            <ProfileCard
              goBack={goBack}
              buttonText={t('common:pageUser.home.button.edit')}
              buttonOnClick={handleRedirect}
              canUpdatePhoto={false}
              onUpdatePhoto={handleUpdatePhoto}
              updatePhotoError={error}
              loadingAvatar={loadingAvatar}
              title={`${user?.firstName} ${user?.lastName}`}
              avatar={getResize(user?.Avatar?.file?.url, 'lg')}
              originalAvatar={user?.Avatar?.file?.url}
              description={user?.bio}
              work={user?.work}
              education={user?.study}
              languages={user?.languages}
              isVerified={user?.emailVerified || user?.phoneVerified}
              isOnline={true}
              isVerifiedClick={!user?.emailVerified && !user?.phoneVerified ? showPopup : undefined}
              countReviews={reviews?.received?.length || undefined}
              rating={rating}
              status={{
                statusType: statusType as status,
                statusName: statusLabel as string,
              }}
              joined={String(user?.createdAt)}
              labels={{
                work: t('common:labels.work'),
                study: t('common:labels.study'),
                joined: t('common:labels.joined'),
                verified: t('common:labels.verified'),
                unverified: t('common:labels.unverified'),
                languages: t('common:labels.languages'),
                reviews: t('common:labels.reviews'),
                reviewsProfile: [city, correctState, country],
              }}
              interestsTitle={t('common:pageUser.interestsTitle')}
              recommendsTitle={t('common:pageUser.recommendationsTitle')}
              interests={user?.personInterests}
              interestsLink={interestsLink}
              interestsValues={interestsValues}
              goToGroup={goToGroup}
              recommends={user?.recommendations || {}}
              RichText={RichText}
              onScrollToReviews={onScrollToReviews}
              isOwner={true}
              Gallery={Gallery}
              Item={Item}
              setIsModal={setModal}
              isMobile={isMobile}
            />
          ) : (
            <ProfileIntroSkeleton />
          )}
        </WrapperProfileCard>

        <Content
          items={items}
          loading={loading}
          typeUser={typeUser}
          communityId={communityId}
          reportActions={reportActions}
          getSignValue={getSignValue}
          listingsAvailable={listingsAvailable}
          isSupport={isSupport}
        />

        {Boolean(communityEvents?.length) && communityEvents.length > 0 && (
          <WrapperContent>
            <SubTitle offsetBottom={12}>
              {t(`listings:page.myEvents`)} {`(${communityEvents?.length || 0})`}
            </SubTitle>
            <ListEventsWithPreview
              communityId={community?.id}
              events={communityEvents}
              fetchDataEvents={fetchDataAllEvents}
              hasMoreEvents={hasMoreAllEvents}
              isPreview={false}
              typeUser={typeUser}
              loading={loading}
              userId={userId}
              reportActions={reportActions}
              getSignValue={getSignValue}
              isHideLabel={true}
            />
          </WrapperContent>
        )}

        <ReviewsWrapper ref={reviewsContainerRef}>
          {!reviews?.received?.length && !reviews?.given?.length && reviewsLoading && <ContentLoader />}
          {!reviews?.received?.length && !reviews?.given?.length ? (
            <EmptyOffset ref={revRef} />
          ) : (
            <ProfileSection title={`${tReviews}`} className={'profile-reviews__tabs'}>
              <Reviews items={reviews} />
            </ProfileSection>
          )}
        </ReviewsWrapper>

        <MemoHelpCentreButton />
        {community?.objectId ? (
          addMenuOpen ? (
            <AddButtonPortal onClick={openAddMenuHandler} iconType={'close-3'} />
          ) : (
            <AddButtonPortal onClick={openAddMenuHandler} iconType={'plus'} />
          )
        ) : null}
        {addMenuOpen && (
          <Popup isOpen={addMenuOpen} onClose={closeAddMenuHandler}>
            <IsModalProvider>
              <AddMenu onClose={closeAddMenuHandler} typeUser={typeUser} permissions={permissions} />
            </IsModalProvider>
          </Popup>
        )}
      </WrapperPage>
    </NavContainer>
  );
};
