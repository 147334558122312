import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {preventDefault} from '../../helpers/common';
import {entities, IReport} from '../../hooks/report';
import {TCompany} from '../../types/company';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Menu} from '../../ui-kit/Menu';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {Media} from '../../ui-kit/theme';
import {Link} from '../../ui-kit/Typography/Link';
import {PopupView} from '../common/Modal/PopupView';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {useGetMenuItems} from './hooks';
import {MemoLabelCard} from './LabelCard';
import {ContainerItemCard, HiddenMenu, IconStar, InnerItemsCard, InnnerItemCardBtns, MenuButtonWrapper} from './styles';
import {TActionsOnItems} from './types';
import {getIsIncludesCmnt} from '../../helpers/item';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {PointerFile} from '../../types/common';

type IProps = {
  item: TCompany;
  to: string;
  editPath?: string | null;
  src?: File | string | PointerFile;
  communityId?: string;
  isManager: boolean;
  isFeature?: boolean;
  isOwner?: boolean;
  actions?: TActionsOnItems;
  onReport?: IReport;
  userId?: string;
};

export const ItemCard: React.FC<IProps> = ({
  to,
  src,
  isManager,
  item,
  actions,
  editPath,
  onReport,
  communityId,
  isOwner,
  userId,
}) => {
  const {t} = useTranslation();
  const {push} = useHistory();
  const {objectId, name, promotion: isPromotion, promoTagline, category, isPublished} = item;
  const isFeatured = getIsIncludesCmnt(item?.Featured, communityId);
  const {onDelete} = actions || {};

  const [modalStates, setModalStates] = useState<{
    remove: boolean;
    report: boolean;
    info: boolean;
  }>({
    remove: false,
    report: false,
    info: false,
  });

  const handle = {
    open: {
      remove() {
        setModalStates({remove: true, report: false, info: false});
      },
      report() {
        setModalStates({remove: false, report: true, info: false});
      },
      info() {
        setModalStates({remove: false, report: false, info: true});
      },
    },
    close: {
      remove() {
        setModalStates({remove: false, report: false, info: false});
      },
      report() {
        setModalStates({remove: false, report: false, info: false});
      },
      info() {
        setModalStates({remove: false, report: false, info: false});
      },
    },
  };

  const handleEdit = () => {
    push(editPath as string);
  };
  const handleDelete = () => {
    return onDelete?.(objectId as string);
  };
  const handleReport = async (text: string) => {
    await onReport?.({id: item.id as string, text});
    handle.close.report();
    handle.open.info();
  };

  const {mobileItems, descItems} = useGetMenuItems({
    mainActions: actions as TActionsOnItems,
    otherActions: {
      onDelete: handle.open.remove,
      onEdit: handleEdit,
      onReport: handle.open.report,
    },
    options: {
      isFeatured,
      itemId: item?.objectId as string,
      isManager,
      isCommOwner: isOwner || false,
      isItemOwner: item?.Owner?.objectId === userId,
    },
  });
  const modalsText = useMemo(() => {
    return {
      remove: {
        title: t('common:modals.delete.title'),
        okText: t('common:modals.delete.button'),
        cancelText: t('common:modals.delete.close'),
      },
      report: {
        title: t('common:report.title', {entity: t(`common:entities.${entities.company}`)}),
        subtitle: t('common:report.subtitle'),
        okText: t('common:report.confirmReport'),
      },
    };
  }, []);
  const infoParams = useMemo(() => {
    return {
      title: t('common:report.success.title', {entity: t(`common:entities.${entities.company}`)}),
      subtitle: t('common:report.success.subtitle'),
      close: () => {
        handle.close.info();
      },
    };
  }, []);
  return (
    <>
      <ContainerItemCard>
        {isFeatured && <IconStar type={'star'} fill={'orange'} stroke={'white'} />}

        <Link to={to}>
          <InnerItemsCard $isOpacity={isPublished === false}>
            <PhotoCard aspectRatio={'300:200'} src={src} />
            <MemoLabelCard name={name} isPromotion={isPromotion} promotionTag={promoTagline} category={category} />
          </InnerItemsCard>
          <InnnerItemCardBtns>
            <MenuButtonWrapper onClick={preventDefault}>
              {!!descItems.length && (
                <HiddenMenu mq={Media.down.s}>
                  <MenuButton menu={Menu} item={{objectId: 'null'}} options={descItems} />
                </HiddenMenu>
              )}
              {!!mobileItems.length && (
                <HiddenMenu mq={Media.up.m}>
                  <MobileMenuButton entries={mobileItems} isPortal={true} portalProvider={IsModalProvider} />
                </HiddenMenu>
              )}
            </MenuButtonWrapper>
          </InnnerItemCardBtns>
        </Link>
      </ContainerItemCard>

      {modalStates.remove && (
        <PopupView>
          <Confirmation
            title={modalsText.remove.title}
            cancelText={modalsText.remove.cancelText}
            okText={modalsText.remove.okText}
            onOk={handleDelete}
            onClose={handle.close.remove}
            buttonType={'danger'}
          />
        </PopupView>
      )}
      {modalStates.report && (
        <PopupView>
          <Confirmation
            isHaveTextArea
            title={modalsText.report.title}
            subTitle={modalsText.report.subtitle}
            okText={modalsText.report.okText}
            onOk={handleReport}
            onClose={handle.close.report}
            buttonType={'danger'}
          />
        </PopupView>
      )}
      {modalStates.info && (
        <PopupView>
          <Confirmation
            title={infoParams.title}
            subTitle={infoParams.subtitle}
            buttonType={'primary'}
            onClose={infoParams.close}
          />
        </PopupView>
      )}
    </>
  );
};
