import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {SelectPlanCommunity} from '../../../components/Account/Create/SelectPlan/ChangePlan';
import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {route} from '../../../constants/routes';
import {getRouteStepCreate} from '../../../helpers/account';
import {isProfileCreated} from '../../../helpers/user';
import {TypeCommunity} from '../../../types/auth';
import {PaymentPeriodType} from '../../../types/payment';
import {NavContainer} from '../../Navigation';

import {useLinks} from '../../../hooks/common';
import {useChangePlan, useCommunity} from '../../../hooks/community';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {useViewer} from '../../../hooks/user';

export const PlansAndPricing: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const {push} = useHistory();
  const viewer = useViewer();
  const {data: community, loading: loadingCmnt} = useCommunity(viewer?.id, TypeCommunity.manager);
  const isProfile = isProfileCreated(viewer);
  const {getLink} = useLinks();
  const {getCodeValue, currency, getSignValue} = useGetCurrencyValue();

  const params = useChangePlan({
    initialState: {
      plan: community?.Subscr?.PaymentPlan.objectId,
      billing: community?.Subscr?.paymentPeriod || PaymentPeriodType.year,
    },
    communityId: community?.id,
    onSuccess: () => push(getLink(route.paymentsSettings.path)),
  });

  useEffect(() => {
    if (!viewer || loadingCmnt) {
      return setLoading(true);
    }
    return setLoading(false);
  }, [viewer, loadingCmnt]);

  useEffect(() => {
    if (!viewer || !community) return;
    const successPath = getLink(
      getRouteStepCreate({
        isProfile,
      }),
    );
    successPath && push(successPath);
  }, [viewer, community]);

  if (loading && !community) return <LottieLoader allScreen={true} $isVisible={loading} />;

  return (
    <NavContainer>
      <SelectPlanCommunity
        params={params}
        getCodeValue={getCodeValue}
        getSignValue={getSignValue}
        currency={currency?.code || ''}
      />
    </NavContainer>
  );
};
