import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {TLoadingStates} from '../../../components/Feed/types';
import {Seo} from '../../../components/SEO';
import {CommunitySettings} from '../../../components/Settings/Community/CommunitySettings';
import {CommunityAdminSchema, CommunityProfileSchema} from '../../../components/Settings/schemas';
import {route} from '../../../constants/routes';
import {makeSeoKeywords} from '../../../helpers/common';
import {isImage} from '../../../helpers/settings';
import {currentCommunity, userPermissions} from '../../../states/community';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {
  CommunityAdminValues,
  CommunityProfileValues,
  CommunityType,
  CommunityValidation,
  PermissionsList,
} from '../../../types/settings';
import {NavContainer} from '../../Navigation';

import {useLinks} from '../../../hooks/common';
import {
  useAccountTransfer,
  useGetCommunity,
  useGetCommunityAdmin,
  useGetCommunityUsers,
  useRemoveCommunityAddAddress,
  useRemoveCommunityAdmin,
  useUpdateCommunityAvatar,
} from '../../../hooks/community';
import {useLocationOptions} from '../../../hooks/locations';
import {useEditAdmin, useMakeUserAdmin} from '../../../hooks/people';
import {useChangeLocation, useFormEdit, useUpdateCommunityForm} from '../../../hooks/settings';
import {useViewer} from '../../../hooks/user';
import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {LoaderItemWrapper} from '../../../ui-kit/Loader/styles';
import {CommunityOptionsFields} from '../../../types/community';

export const Community: React.FC = () => {
  const [typeCommunity] = useRecoilState(typeUser);
  const [stateCommunity] = useRecoilState(currentCommunity);
  const [permissions] = useRecoilState(userPermissions);
  const {data: updatedCommunity, loading} = useGetCommunity({id: stateCommunity?.id});
  const {getLink} = useLinks();
  const community = updatedCommunity || stateCommunity;

  const canEdit = useMemo(
    () => permissions.includes(PermissionsList.editCommunity) || typeCommunity === TypeCommunity.manager,
    [permissions, typeCommunity],
  );
  if (!canEdit) {
    return <Redirect to={getLink(route.pageUser.get({user: typeCommunity}))} />;
  }
  if (loading)
    return (
      <NavContainer>
        <LoaderItemWrapper>
          <LottieLoader $isVisible={true} loading={loading} />
        </LoaderItemWrapper>
      </NavContainer>
    );
  return (
    <NavContainer>
      <CommunitySettingsPage community={community} />
    </NavContainer>
  );
};

interface TProps {
  community?: Partial<CommunityType> | null;
}

export const CommunitySettingsPage: React.FC<TProps> = ({community}) => {
  const editProfile = useFormEdit();
  const editAdmin = useFormEdit();
  const changeLocationProfile = useChangeLocation(community?.Location);
  const locationOptions = useLocationOptions({
    country: changeLocationProfile.country.label,
    state: changeLocationProfile.state.label,
  });
  const viewer = useViewer();
  const {remove: removeAddAddress} = useRemoveCommunityAddAddress();
  const {remove: removeAdmin} = useRemoveCommunityAdmin();
  const {makeOwner} = useAccountTransfer();
  const canTransfer = community?.Owner?.objectId === viewer?.objectId;
  const {makeAdmin, loading: makeAdminLoading} = useMakeUserAdmin();
  const getAdmin = useGetCommunityAdmin();
  const {editAdmin: editAdminRequest, loading: editAdminLoading} = useEditAdmin();
  const {t} = useTranslation();
  const textSeo = t('common:seo.settings.community');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const [photoError, setPhotoError] = useState('');
  const {users: residents, refetch: refetchUsers} = useGetCommunityUsers(community?.objectId, community?.countMembers);
  const profileInit: Partial<CommunityProfileValues> = {
    zip: community?.zip,
    units: community?.units,
    buildings: community?.buildings,
    address: community?.address,
    name: community?.name,
    id: community?.id,
    alias: community?.alias,
    descr: community?.descr,
    type: community?.type,
    Location: {link: community?.Location?.objectId},
  };
  const formProfile = useUpdateCommunityForm<CommunityProfileValues, CommunityValidation>({
    validationSchema: CommunityProfileSchema,
    initialState: profileInit,
    onSuccess: () => editProfile.handleEditEnd(),
  });
  const adminInit: Partial<CommunityAdminValues> = {
    id: community?.id,
    accessType: community?.accessType,
    listingApproval: community?.listingApproval,
    allowChat: community?.allowChat || false,
    allowEvents: community?.allowEvents,
    options: {
      [CommunityOptionsFields.passCode]: community?.options?.passCode ?? true,
      [CommunityOptionsFields.requireAddress]: community?.options?.requireAddress ?? true,
      [CommunityOptionsFields.requirePhone]: community?.options?.requirePhone ?? true,
      [CommunityOptionsFields.requireListing]: community?.options?.requireListing ?? true,
    },
  };
  const formAdmin = useUpdateCommunityForm<CommunityAdminValues, CommunityValidation>({
    validationSchema: CommunityAdminSchema,
    initialState: adminInit,
    onSuccess: async () => {
      await editAdmin.handleEditEnd();
      await refetchUsers();
    },
  });
  const loadingStates: TLoadingStates = {
    makeAdminLoading,
    editAdminLoading,
  };
  const {call: updateAvatar, loading: loadingAvatar} = useUpdateCommunityAvatar();
  const handleUpdatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setPhotoError('');
    if (file && file?.size / 1024 / 1024 > 10) {
      setPhotoError(t('error:invalidSize'));
    } else if (!isImage(file?.name)) {
      setPhotoError(t('error:invalidFormat'));
    } else {
      updateAvatar?.(file, community?.id ?? '')
        .then(() => (e.target.value = ''))
        .catch(() => {
          e.target.value = '';
        });
    }
  };
  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <CommunitySettings
        community={community}
        editProfile={editProfile}
        editAdmin={editAdmin}
        formProfile={formProfile}
        formAdmin={formAdmin}
        changeLocationProfile={changeLocationProfile}
        locationOptions={locationOptions}
        removeAdmin={removeAdmin}
        removeAddAddress={removeAddAddress}
        makeOwner={canTransfer ? makeOwner : undefined}
        makeAdmin={makeAdmin}
        getAdmin={getAdmin}
        updateAvatar={handleUpdatePhoto}
        updatePhotoError={photoError}
        updateAdmin={editAdminRequest}
        loadingStates={loadingStates}
        loadingAvatar={loadingAvatar}
        residents={residents}
      />
    </>
  );
};
