import React, {useEffect, useState} from 'react';
import {isDesktop} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {PhotosList} from './PhotosList';
import {
  CheckBoxPart,
  InnerItems,
  InnerItemsGrid,
  ListingForm,
  MobFocused,
  SelectPart,
  SelectWithCheckWrapper,
  SimpleText,
  SubTitle,
  Title,
  WrapperForm,
  LeftClosedLabel,
  RightClosedLabel,
} from './styles';
import {NavContainer} from '../../containers/Navigation';
import {
  checkSelectedTimeFrom,
  checkSelectedTimeTill,
  createTimeFromTillOptions,
  resetBaseBookableOptions,
  setBaseDaysOptions,
} from '../../helpers/amenity';
import {correctTranslate, DaysList} from '../../helpers/common';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {
  AmenityFields,
  AmenityFormAction,
  BookingDayField,
  BookingPeriod,
  BookingTimingField,
} from '../../types/amenity';
import {SelectOption} from '../../types/common';
import {Button} from '../../ui-kit/Button/Button';
import {Checkbox} from '../../ui-kit/Checkbox/Checkbox';
import {Input} from '../../ui-kit/Form/Input';
import {NumberFormatInput} from '../../ui-kit/Form/Input/NumberFormatInput';
import {PropsSelect, Select} from '../../ui-kit/Form/Select';
import {ErrorMessage} from '../Auth/styles';
import {EmojiTab} from '../common/EmojiTab/EmojiTab';
import {MemoInfoHint} from '../common/InfoHint';
import {HintWrapperInput} from '../common/InfoHint/styles';
import {RichTextEditor} from '../common/RichText/Editor';
import {LottieLoader} from '../Loader/LottieLoader';

import {TAmenityFormData, TResCreateAmenity, TResEditAmenity} from '../../hooks/amenity';
import {useEditorRef} from '../../hooks/common';

type TOnChange = (next: {name: string; value: string}) => void;

type TProps = {
  action: AmenityFormAction;
  data: TResEditAmenity | TResCreateAmenity;
  formData: TAmenityFormData;
  currSign: string;
  communityId?: string;
  onPublish?: () => void;
  onDelete?: () => void;
};

export const AmenityForm: React.FC<TProps> = ({action, data, formData}) => {
  const {t} = useTranslation();
  const {values, onChange, onSubmit, error, loading} = data;
  const [emoji, setEmoji] = useState<string>('');
  const {setRef} = useEditorRef({autoBlur: true});
  const {
    rewardTypeOptions,
    bookingSlotsHour,
    bookingSlotsDay,
    bookingPerDay,
    bookByOptions,
    toggleOptions,
    autoBookingOptions,
    baseTimeOptions,
  } = formData;
  const openFromOptions = createTimeFromTillOptions(
    BookingTimingField.from,
    values?.openFrom,
    values?.openUntil,
  ) as SelectOption<{
    [BookingTimingField.from]: string;
  }>[];
  const openTillOptions = createTimeFromTillOptions(
    BookingTimingField.till,
    values?.openFrom,
    values?.openUntil,
  ) as SelectOption<{
    [BookingTimingField.till]: string;
  }>[];
  const [avOptions, setAvOptions] = useState({
    [DaysList.mon]: true,
    [DaysList.tue]: true,
    [DaysList.wed]: true,
    [DaysList.thu]: true,
    [DaysList.fri]: true,
    [DaysList.sat]: false,
    [DaysList.sun]: false,
  });
  const title = t(`amenities:titlesForm.${action}`);
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  const avChange = (day: DaysList) => (b: boolean) => {
    setAvOptions((prev) => ({...prev, [day]: b}));
    const disabledData = {[BookingTimingField.available]: b};
    onChange({
      name: AmenityFields.bookingTiming,
      value: {
        ...values.bookingTiming,
        [day]: !b ? disabledData : {...values?.bookingTiming?.[day], [BookingTimingField.available]: b},
      },
    });
  };

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleSelect =
    (name: string) => (data: SelectOption<string | Date | ListingType | RewardType | PricePeriodType>[]) => {
      const value = data[0]?.value;
      onChange({name: name, value: value});

      name === AmenityFields.openFrom &&
        setBaseDaysOptions({from: String(value), values: values.bookingTiming, onChange});
      name === AmenityFields.openUntil &&
        setBaseDaysOptions({till: String(value), values: values.bookingTiming, onChange});
      name === AmenityFields.isBookable && !value && resetBaseBookableOptions({onChange});
    };

  const handleDayOptionSelect =
    (day: DaysList, type: BookingTimingField.from | BookingTimingField.till) =>
    (data: SelectOption<BookingDayField>[]) => {
      const value = data[0]?.value;
      onChange({
        name: AmenityFields.bookingTiming,
        value: {...values.bookingTiming, [day]: {...values?.bookingTiming?.[day], [type]: value}},
      });
    };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };
  const bookingSlots = values.bookingPeriod === BookingPeriod.day ? bookingSlotsDay : bookingSlotsHour;
  useEffect(() => {
    setAvOptions({
      [DaysList.mon]:
        values?.bookingTiming?.mon?.available !== undefined ? values?.bookingTiming?.mon?.available : true,
      [DaysList.tue]:
        values?.bookingTiming?.tue?.available !== undefined ? values?.bookingTiming?.tue?.available : true,
      [DaysList.wed]:
        values?.bookingTiming?.wed?.available !== undefined ? values?.bookingTiming?.wed?.available : true,
      [DaysList.thu]:
        values?.bookingTiming?.thu?.available !== undefined ? values?.bookingTiming?.thu?.available : true,
      [DaysList.fri]:
        values?.bookingTiming?.fri?.available !== undefined ? values?.bookingTiming?.fri?.available : true,
      [DaysList.sat]:
        values?.bookingTiming?.sat?.available !== undefined ? values?.bookingTiming?.sat?.available : false,
      [DaysList.sun]:
        values?.bookingTiming?.sun?.available !== undefined ? values?.bookingTiming?.sun?.available : false,
    });
  }, [values.objectId]);

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  return (
    <NavContainer>
      <WrapperForm>
        <Title>{title}</Title>
        <ListingForm onSubmit={handleSubmit}>
          <SubTitle>{t(`amenities:titlesForm.photos.${action}`)}</SubTitle>
          <PhotosList cards={values.images || []} onChange={onChange} error={!!error?.message} />
          <SubTitle>{t(`amenities:titlesForm.amenityDetails`)}</SubTitle>
          <InnerItems>
            <Input
              $isError={Boolean(error.name)}
              name={AmenityFields.name}
              value={values.name}
              onChange={handleChange}
              label={t('forms:name')}
            />
            <MobFocused $isDesktop={isDesktop}>
              <RichTextEditor
                $isError={Boolean(error.descr)}
                label={t('forms:descr')}
                name={AmenityFields.descr}
                value={values?.descr}
                onChange={onChange as TOnChange}
                rows={8}
                emoji={emoji}
                setEmoji={setEmoji}
                inputRef={setRef}
              />
              <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.descr} />
            </MobFocused>
            <Select
              $isError={Boolean(error.rewardType)}
              width={'100%'}
              name={AmenityFields.rewardType}
              label={t('forms:listFor')}
              values={rewardTypeOptions.filter((it) => it.value === values.rewardType)}
              options={rewardTypeOptions}
              onChange={handleSelect(AmenityFields.rewardType)}
              searchable={false}
            />
            <HintWrapperInput $isSelect={true}>
              <Select
                $isError={Boolean(error.isBookable)}
                width={'100%'}
                name={AmenityFields.isBookable}
                label={t('amenities:form.bookable')}
                values={toggleOptions.filter((it) => it.value === values.isBookable)}
                options={toggleOptions}
                onChange={handleSelect(AmenityFields.isBookable)}
                searchable={false}
              />
              <MemoInfoHint hintText={t('amenities:hints.bookable')} />
            </HintWrapperInput>
          </InnerItems>
          {values.isBookable && (
            <>
              <SubTitle>{t(`amenities:form.bookingOptions`)}</SubTitle>
              <InnerItems>
                <HintWrapperInput $isSelect={true}>
                  <Select
                    $isError={Boolean(error.autoBooking)}
                    width={'100%'}
                    name={AmenityFields.autoBooking}
                    label={t('amenities:form.bookingType')}
                    values={autoBookingOptions.filter((it) => it.value === values.autoBooking)}
                    options={autoBookingOptions}
                    onChange={handleSelect(AmenityFields.autoBooking)}
                    searchable={false}
                  />
                  <MemoInfoHint hintText={t('amenities:hints.bookingType')} />
                </HintWrapperInput>
              </InnerItems>
              <InnerItemsGrid>
                <Select
                  $isError={Boolean(error.bookingPeriod)}
                  width={'100%'}
                  name={AmenityFields.bookingPeriod}
                  label={t('amenities:form.bookBy')}
                  values={bookByOptions.filter((it) => it.value === values.bookingPeriod)}
                  options={bookByOptions}
                  onChange={handleSelect(AmenityFields.bookingPeriod)}
                  searchable={false}
                />
                <Select
                  $isError={Boolean(error.bookingSlots)}
                  width={'100%'}
                  name={AmenityFields.bookingSlots}
                  label={t('amenities:form.bookingSlots')}
                  values={bookingSlots.filter((it) => it.value === values.bookingSlots)}
                  options={bookingSlots}
                  onChange={handleSelect(AmenityFields.bookingSlots)}
                  searchable={false}
                />
                <NumberFormatInput
                  $isError={Boolean(error.maxBookingPerSlot)}
                  name={AmenityFields.maxBookingPerSlot}
                  value={values.maxBookingPerSlot}
                  onChange={handleChange}
                  label={t('amenities:form.maxBookPerSlot')}
                />
                <HintWrapperInput>
                  <NumberFormatInput
                    $isError={Boolean(error.maxResidentsPerBooking)}
                    name={AmenityFields.maxResidentsPerBooking}
                    value={values.maxResidentsPerBooking}
                    onChange={handleChange}
                    min={1}
                    max={values.maxBookingPerSlot || 1}
                    label={t('amenities:form.maxResPerBook')}
                  />
                  <MemoInfoHint hintText={t('amenities:hints.maxResidentsPerBooking')} />
                </HintWrapperInput>
                <Select
                  $isError={Boolean(error.allowGuests)}
                  width={'100%'}
                  name={AmenityFields.allowGuests}
                  label={t('amenities:form.allowGuests')}
                  values={toggleOptions.filter((it) => it.value === values.allowGuests)}
                  options={toggleOptions}
                  onChange={handleSelect(AmenityFields.allowGuests)}
                  searchable={false}
                />
                <HintWrapperInput $isSelect={true}>
                  <Select
                    $isError={Boolean(error.maxBookingsPerDay)}
                    width={'100%'}
                    name={AmenityFields.maxBookingsPerDay}
                    label={t('amenities:form.allowedBookPerDay')}
                    values={bookingPerDay.filter((it) => it.value === values.maxBookingsPerDay)}
                    options={bookingPerDay}
                    onChange={handleSelect(AmenityFields.maxBookingsPerDay)}
                    searchable={false}
                  />
                  <MemoInfoHint hintText={t('amenities:hints.maxBooking')} />
                </HintWrapperInput>
              </InnerItemsGrid>
            </>
          )}
          <>
            <SubTitle>{t(`amenities:form.bookingAvailabilty`)}</SubTitle>
            <InnerItemsGrid>
              <Select
                $isError={Boolean(error.openFrom)}
                width={'100%'}
                name={AmenityFields.openFrom}
                label={t('amenities:form.openFrom')}
                values={baseTimeOptions.filter((el) => el.value === values?.openFrom)}
                options={baseTimeOptions}
                onChange={handleSelect(AmenityFields.openFrom)}
                searchable={false}
              />
              <Select
                $isError={Boolean(error.openUntil)}
                width={'100%'}
                name={AmenityFields.openUntil}
                label={t('amenities:form.openUntil')}
                values={baseTimeOptions.filter((el) => el.value === values?.openUntil)}
                options={baseTimeOptions}
                onChange={handleSelect(AmenityFields.openUntil)}
                searchable={false}
              />
            </InnerItemsGrid>
            <InnerItems>
              <SelectWithCheck
                checkLabel={t(`common:days.monday`)}
                isChecked={avOptions[DaysList.mon]}
                onChangeCheck={avChange(DaysList.mon)}
                select1={{
                  name: DaysList.mon + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.mon?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.mon, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.mon + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.mon?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.mon, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.tuesday`)}
                isChecked={avOptions[DaysList.tue]}
                onChangeCheck={avChange(DaysList.tue)}
                select1={{
                  name: DaysList.tue + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.tue?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.tue, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.mon + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.tue?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.tue, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.wednesday`)}
                isChecked={avOptions[DaysList.wed]}
                onChangeCheck={avChange(DaysList.wed)}
                select1={{
                  name: DaysList.wed + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.wed?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.wed, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.wed + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.wed?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.wed, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.thursday`)}
                isChecked={avOptions[DaysList.thu]}
                onChangeCheck={avChange(DaysList.thu)}
                select1={{
                  name: DaysList.thu + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.thu?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.thu, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.thu + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.thu?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.thu, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.friday`)}
                isChecked={avOptions[DaysList.fri]}
                onChangeCheck={avChange(DaysList.fri)}
                select1={{
                  name: DaysList.fri + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.fri?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.fri, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.fri + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.fri?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.fri, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.saturday`)}
                isChecked={avOptions[DaysList.sat]}
                onChangeCheck={avChange(DaysList.sat)}
                select1={{
                  name: DaysList.sat + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.sat?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.sat, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.sat + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.sat?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.sat, BookingTimingField.till),
                }}
              />
              <SelectWithCheck
                checkLabel={t(`common:days.sunday`)}
                isChecked={avOptions[DaysList.sun]}
                onChangeCheck={avChange(DaysList.sun)}
                select1={{
                  name: DaysList.sun + 'from',
                  label: t('amenities:form.openFrom'),
                  values: checkSelectedTimeFrom(openFromOptions, values.bookingTiming?.sun?.[BookingTimingField.from]),
                  options: openFromOptions,
                  onChange: handleDayOptionSelect(DaysList.sun, BookingTimingField.from),
                }}
                select2={{
                  name: DaysList.sun + 'until',
                  label: t('amenities:form.openUntil'),
                  values: checkSelectedTimeTill(openTillOptions, values.bookingTiming?.sun?.[BookingTimingField.till]),
                  options: openTillOptions,
                  onChange: handleDayOptionSelect(DaysList.sun, BookingTimingField.till),
                }}
              />
            </InnerItems>
          </>
          {/*<OverridePicker />*/}
          <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
          <Button type={'submit'} loading={loading} disabled={loading}>
            {t(`amenities:buttons.form.${action}`)}
          </Button>
        </ListingForm>
        <LottieLoader $isVisible={loading} allScreen={true} />
      </WrapperForm>
    </NavContainer>
  );
};

interface SelectWithCheckProps {
  checkLabel: string;
  onChangeCheck: (b: boolean) => void;
  isChecked: boolean;
  select1: PropsSelect;
  select2: PropsSelect;
}

const SelectWithCheck: React.FC<SelectWithCheckProps> = ({isChecked, select1, select2, checkLabel, onChangeCheck}) => {
  const {t} = useTranslation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => onChangeCheck(e.target.checked);
  return (
    <SelectWithCheckWrapper>
      <CheckBoxPart>
        <Checkbox $isChecked={isChecked} onChange={onChange} />
        <SimpleText>{checkLabel}</SimpleText>
      </CheckBoxPart>
      <SelectPart>
        {!isChecked && <LeftClosedLabel>{t('amenities:options.closed')}</LeftClosedLabel>}
        <Select {...select1} searchable={false} disabled={!isChecked} />
        <SimpleText>{t('requests:credit.to')}</SimpleText>
        <Select {...select2} searchable={false} disabled={!isChecked} />
        {!isChecked && <RightClosedLabel>{t('amenities:options.closed')}</RightClosedLabel>}
      </SelectPart>
    </SelectWithCheckWrapper>
  );
};
