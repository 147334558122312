import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import {useRecoilValue} from 'recoil';
import {useTranslation} from 'react-i18next';
import {localeType} from '../libs/i18nextUtils';
import {setLocale} from '../libs/i18next';
import {userState} from '../states/session';
import {
  GetLanguageByCodeParams,
  GetLanguageByCodeQuery,
  GetLanguageByCodeRes,
  GetLanguages,
  GetLanguagesRes,
} from '../queries/language';
import {UpdateProfileRequestType, UpdateProfileResponseType, UpdateProfileShort} from '../queries/user';

export const useGetLangByCode = () => {
  const client = useApolloClient();

  const getLang = async (code: localeType) => {
    const res = await client.query<GetLanguageByCodeRes, GetLanguageByCodeParams>({
      query: GetLanguageByCodeQuery,
      variables: {
        langCode: code,
      },
    });
    return res?.data.languages.edges.map((el) => el.node)[0];
  };

  return {
    getLang,
  };
};

export const useSetUserLang = () => {
  const {i18n} = useTranslation();
  const user = useRecoilValue(userState);
  const [updateProfile] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(UpdateProfileShort);
  const {getLang} = useGetLangByCode();

  const setLang = async (lang: localeType) => {
    try {
      if (!user?.objectId) return;
      const langData = await getLang(lang);
      if (!langData) return;

      await updateProfile({
        variables: {
          id: user.objectId,
          fields: {
            Language: {
              link: langData.objectId,
            },
          },
        },
      });
      setLocale(lang as localeType);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const syncLang = async (userId: string, userLang: localeType) => {
    const lang = i18n.language;

    try {
      if (!userId) return;
      if (lang === userLang) return;
      const langData = await getLang(lang as localeType);
      if (!langData) return;

      await updateProfile({
        variables: {
          id: userId,
          fields: {
            Language: {
              link: langData.objectId,
            },
          },
        },
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  return {
    setLang,
    syncLang,
  };
};

export const useGetLanguages = () => {
  const res = useQuery<GetLanguagesRes>(GetLanguages);
  const data = res?.data?.languages.edges.map((el) => el.node).filter((el) => !el?.isDisabled);
  return {...res, data};
};
