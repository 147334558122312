import React from 'react';
import styled from 'styled-components';
import {Text as UiText} from '../../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';
import {managerDashboard} from '../../../queries/types/dashboard';
import {CommunityStateType} from '../../../states/community';
import {convertCentToDollar} from '../../../helpers/payment';
import {IGetCurrValue} from '../../../types/common';

const CardWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 5;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.quinary};
  padding: 24px 21px;
  position: relative;
`;

const CardItem = styled.div<{marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

const Line = styled.div<{marginBottom?: number}>`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.palette.text.duodecimal};
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

const Text = styled(UiText).attrs({weight: 'semi-bold', color: 'primary'})<{marginTop?: number}>`
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 33px;
  right: 22px;
  * {
    color: ${({theme}) => theme.palette.common.aqua};
  }
`;

export const IncomeGeneratedCard: React.FC<{
  data: managerDashboard;
  community?: CommunityStateType;
  getSignValue: IGetCurrValue;
}> = ({data, getSignValue}) => {
  const {t} = useTranslation();

  const prcValue = (data.fee?.cfListerPrc || 0) + (data.fee?.cfRequesterPrc || 0);
  const absValue = (data.fee?.cfListerAbs || 0) + (data.fee?.cfRequesterAbs || 0);

  if (!prcValue && !absValue) return null;

  const fee = data.fee?.message ? data.fee?.message : `${prcValue.toFixed(2)}% + ${getSignValue(absValue.toFixed(2))}`;
  const signSymbol = getSignValue('');
  return (
    <CardWrapper>
      <CardItem marginBottom={3}>
        <UiText weight={'semi-bold'} color={'primary'}>
          {t('dashboard:manager.incomeGenerated')}
        </UiText>
      </CardItem>
      <CardItem marginBottom={19}>
        <UiText weight={'medium'} size={26} color={'primary'}>
          {data.incomeGenerated
            ? getSignValue(convertCentToDollar(data.incomeGenerated)?.toFixed(2) || 0)
            : getSignValue(0)}
        </UiText>
      </CardItem>
      <Line marginBottom={22} />
      <CardItem marginBottom={12}>
        <Text>{t('dashboard:manager.saleTransactions')}</Text>
        <Text size={18}>{data.saleTransactions}</Text>
      </CardItem>
      <CardItem marginBottom={12}>
        <Text>{t('dashboard:manager.rentalTransactions')}</Text>
        <Text size={18}>{data.rentalTransactions}</Text>
      </CardItem>
      <CardItem marginBottom={12}>
        <Text>{t('dashboard:manager.serviceTransactions')}</Text>
        <Text size={18}>{data.serviceTransactions}</Text>
      </CardItem>
      <CardItem marginBottom={22}>
        <Text>{t('dashboard:manager.freeCoffeeTransactions')}</Text>
        <Text size={18}>{(data.freeTransactions || 0) + (data.coffeeTransactions || 0)}</Text>
      </CardItem>
      <Line marginBottom={12} />
      <CardItem>
        <UiText size={10} color={'tertiary'}>
          {t('dashboard:manager.description')}
        </UiText>
      </CardItem>
      <CardItem>
        <Text size={16} marginTop={8}>
          {fee}
        </Text>
      </CardItem>
      <IconWrapper>
        <UiText size={24} color={'tertiary'}>
          {signSymbol}
        </UiText>
      </IconWrapper>
    </CardWrapper>
  );
};
