import {User} from '../queries/types/user';
import {StatusOrderType, typeOrder} from '../queries/types/order';
import {Message} from '../queries/types/message';
import {PointerFile} from './common';

export interface UserChat {
  objectId: string;
  Avatar?: PointerFile;
  firstName: string;
  lastName: string;
  firstLetter: string;
  isOnline?: boolean;
  isSupport?: boolean;
}

export enum MessageFields {
  objectId = 'objectId',
  Order = 'Order',
  Author = 'Author',
  ShowTo = 'ShowTo',
  text = 'text',
  type = 'type',
  Attachments = 'Attachments',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  Seen = 'Seen',
  localRef = 'localRef',
}

export enum UserFields {
  objectId = 'objectId',
  Avatar = 'Avatar',
  firstName = 'firstName',
  lastName = 'lastName',
  firstLetter = 'firstLetter',
  isSupport = 'isSupport',
  isOnline = 'isOnline',
}

export enum AvatarFields {
  objectId = 'objectId',
  file = 'file',
}
export enum CreateMsgFields {
  text = 'text',
  Attachments = 'Attachments',
}

export enum AppFileFields {
  objectId = 'objectId',
  file = 'file',
}

export interface MessageData {
  Order?: string;
  Author?: string;
  ShowTo: string;
  text: string;
  type?: string;
  // Attachments?: Array<AppFile | undefined>;
  Attachments?: (File | undefined)[] | Partial<PointerFile[]>[];
  Seen?: Array<User | undefined>;
  Community?: string;
}

export type TContact = {
  objectId: string;
  avatar: string;
  isOnline?: boolean;
  name: string;
  dataMsg: {text: string; orderStatus: string; date?: Date | null; haveUnseenMsg?: boolean};
  isClosed?: boolean;
  isArchived?: boolean;
  chatId?: string;
  isSupport: boolean;
};

export enum MobTabMsgs {
  messages = 'messages',
  chat = 'chat',
  requests = 'requests',
}

export interface IDataHookMessage {
  values: Omit<MessageData, 'ShowTo'> & {
    ShowTo?: string;
  };
  onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
  deleteImage: (index: number) => void;
  onSubmit: (text?: string) => void;
  loading: boolean;
  LoadingCreateMsg: boolean;
  updateSeenMsg: (params: {seen: string[]; msg: Message}) => void;
}

export enum MsgStatus {
  seen = 'seen',
  delivered = 'delivered',
  loading = 'loading',
}

export enum OrderFields {
  Lister = 'Lister',
  Requester = 'Requester',
  Item = 'Item',
  Amenity = 'Amenity',
  Event = 'Event',
  objectId = 'objectId',
  status = 'status',
  orderType = 'orderType',
  startTime = 'startTime',
}

export type TOrder = {
  Lister?: UserChat;
  Requester?: UserChat;
  Item?: OrderItem;
  Amenity?: OrderItem;
  Event?: OrderItem;
  objectId: string;
  status: StatusOrderType;
  orderType: typeOrder;
  startTime: Date;
};

export enum ItemFields {
  name = 'name',
  objectId = 'objectId',
}

export type OrderItem = {
  name: string;
  objectId: string;
};

export enum LQOrderField {
  objectId = 'objectId',
  status = 'status',
  startTime = 'startTime',
}

export enum ChatFields {
  objectId = 'objectId',
  status = 'status',
  isArchived = 'isArchived',
}

export interface ILQOrder {
  objectId: string;
  status: StatusOrderType;
  startTime?: Date;
}

export type OrderHelpersT = {
  refetchMsgs?: () => void;
  orderLoading?: boolean;
};

export enum TMessagesNotifications {
  closed = 'closed',
}
