import React from 'react';
import {LinkfyLink} from './styles';
import LinkifyComponent, {Props} from 'react-linkify';

export const Linkify: React.FC<Props> = ({children, ...props}) => {
  return (
    <LinkifyComponent
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <LinkfyLink target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </LinkfyLink>
      )}
      {...props}>
      {children}
    </LinkifyComponent>
  );
};
