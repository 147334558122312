import React, {useMemo, useState} from 'react';
import {isDesktop} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {NumberFormatValues} from 'react-number-format';

import {PhotosList} from './PhotosList';
import {
  InnerItems,
  InnerItemsForm,
  InnerItemsGrid,
  LabelHint,
  ListingForm,
  MobFocused,
  SelectLabel,
  SubTitle,
  Title,
  WrapperForm,
} from './styles';
import {NavContainer} from '../../containers/Navigation';
import {correctTranslate} from '../../helpers/common';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {SelectOption} from '../../types/common';
import {ItemFields, ItemFormAction} from '../../types/item';
import {Button} from '../../ui-kit/Button/Button';
import {Input} from '../../ui-kit/Form/Input';
import {NumberFormatInput} from '../../ui-kit/Form/Input/NumberFormatInput';
import {Select} from '../../ui-kit/Form/Select';
import {SeparatedSelect} from '../../ui-kit/Form/Select/SeparatedSelect';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {ErrorMessage} from '../Auth/styles';
import {EmojiTab} from '../common/EmojiTab/EmojiTab';
import {RichTextEditor} from '../common/RichText/Editor';
import {Text} from '../common/styles';
import {LottieLoader} from '../Loader/LottieLoader';

import {TGetInsightsItem, TItemFormData, TResCreateItem, TResEditItem} from '../../hooks/item';
import {useEditorRef} from '../../hooks/common';
import {BlockHint} from '../common/InfoHint';
import {GoBack} from '../common/Buttons/GoBack';
import {AIIcon} from '../Feed/Notification/AIPromotion';

type TOnChange = (next: {name: string; value: string}) => void;
type SelectOptions = {label?: string; value?: string};

type TProps = {
  action: ItemFormAction;
  data: TResEditItem | TResCreateItem;
  formData: TItemFormData;
  dataInsights?: TGetInsightsItem;
  currSign: string;
  communityId?: string;
  onPublish?: () => void;
  onDelete?: () => void;
  generateListing?: () => void;
};

export const ItemForm: React.FC<TProps> = ({action, data, formData, currSign, generateListing}) => {
  const {t} = useTranslation();
  const [descrHint, setDescrHint] = useState({focus: false, visible: true});
  const {values, onChange, onSubmit, error, loading} = data;
  const isFree = values.rewardType === RewardType.free;
  const isCoffee = values.rewardType === RewardType.coffee;
  const rateValue = isFree ? t('listings:options.free') : t('listings:options.coffee');
  const showDescrHint = () => setDescrHint((prev) => ({...prev, focus: true}));
  const hideDescrHint = () => {
    setTimeout(() => {
      setDescrHint((prev) => ({...prev, focus: false}));
    }, 10);
  };
  const onCloseDescrHint = () => setDescrHint((prev) => ({...prev, visible: false}));
  const {
    categoriesOptions,
    categoriesServiceOptions,
    listingTypeOptions,
    rewardTypeOptions,
    pricePeriodOptions,
    pricePeriodOptionsService,
  } = formData;

  const pricePeriodOpts = values.listingType === ListingType.service ? pricePeriodOptionsService : pricePeriodOptions;
  const categories = values.listingType === ListingType.service ? categoriesServiceOptions : categoriesOptions;
  const isSelling = values.listingType === ListingType.sell;
  const isDisabled = values.rewardType === RewardType.free || values.rewardType === RewardType.coffee;
  const title = t(`listings:titlesForm.${action}`);
  const [emoji, setEmoji] = useState<string>('');
  const {setRef} = useEditorRef({autoBlur: true});
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  const isEdit = action === ItemFormAction.edit;
  const isService = values.listingType === ListingType.service;
  const listingOptions = useMemo(
    () =>
      listingTypeOptions.map((v) => ({
        label: v.label,
        value: v.value.toString(),
      })),
    [listingTypeOptions],
  );
  const rewardOptions = useMemo(
    () =>
      rewardTypeOptions.map((v) => ({
        label: v.label,
        value: v.value.toString(),
      })),
    [rewardTypeOptions],
  );

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleNumberChange = (name: ItemFields) => (values: NumberFormatValues) => {
    return onChange({name: name, value: values.floatValue});
  };

  const handleSelect =
    (name: string) => (data: SelectOption<string | ListingType | RewardType | PricePeriodType>[]) => {
      const value = data[0]?.value;
      onChange({name: name, value: value || ''});
    };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const handleSelectType = (name: string) => (next: SelectOptions) => {
    onChange({name, value: next.value});
  };

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  const goBack = () => window?.history.back();
  return (
    <NavContainer>
      <WrapperForm>
        <Title>{title}</Title>
        <GoBack goBack={goBack} title={title} />
        <ListingForm onSubmit={handleSubmit}>
          <InnerItems>
            <SelectLabel>
              {t('forms:listingType')}
              <LabelHint hintText={t(`listings:hints.listingType`)} />
            </SelectLabel>
            <SeparatedSelect
              options={listingOptions}
              value={listingOptions.filter((o) => o.value === values.listingType)[0]}
              onChange={handleSelectType(ItemFields.listingType)}
            />
            <SelectLabel>
              {t('forms:listFor')}
              <LabelHint hintText={t(`listings:hints.listFor`)} />
            </SelectLabel>
            <SeparatedSelect
              options={rewardOptions}
              value={rewardOptions.filter((o) => o.value === values.rewardType)[0]}
              onChange={handleSelectType(ItemFields.rewardType)}
            />
          </InnerItems>
          <SelectLabel>
            {t(`listings:titlesForm.photos.${action}`)}
            <LabelHint hintText={t(`listings:hints.addPhotos`)} />
          </SelectLabel>
          {/* <SubTitle>{t(`listings:titlesForm.photos.${action}`)}</SubTitle> */}
          <PhotosList
            cards={values.images || []}
            onChange={onChange}
            error={!isService ? !!error?.message : undefined}
          />
          <InnerItems>
            <SubTitle>{t('listings:titlesForm.listingDetails')}</SubTitle>
            <Input
              $isError={Boolean(error.name)}
              name={ItemFields.name}
              value={values.name || ''}
              onChange={handleChange}
              label={t('forms:itemName')}
              autoFocus={true}
              icon={
                generateListing &&
                values.listingType === ListingType.service && <AIIcon isShow={true} redirect={generateListing} />
              }
            />
            {descrHint.focus && descrHint.visible && !isEdit && (
              <BlockHint onClose={onCloseDescrHint} hintText={t('listings:hints.description')} />
            )}
            <MobFocused $isDesktop={isDesktop}>
              <RichTextEditor
                $isError={Boolean(error.descr)}
                label={t('forms:descr')}
                name={ItemFields.descr}
                value={values?.descr}
                onChange={onChange as TOnChange}
                rows={6}
                emoji={emoji}
                setEmoji={setEmoji}
                disableAutoFocus={true}
                inputRef={setRef}
                onFocus={showDescrHint}
                onBlur={hideDescrHint}
              />
              <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.descr} />
            </MobFocused>
            <Select
              $isError={Boolean(error.category)}
              width={'100%'}
              name={ItemFields.category}
              label={t('forms:category')}
              values={categories?.filter((it) => it.value === values.category)}
              options={categories}
              onChange={handleSelect(ItemFields.category)}
              searchable={false}
            />
          </InnerItems>
          <InnerItems>
            {!isSelling && (
              <InnerItemsGrid $smallDD={true}>
                <Select
                  $isError={Boolean(error.pricePeriod)}
                  width={'100%'}
                  name={ItemFields.pricePeriod}
                  label={t('forms:per')}
                  values={pricePeriodOpts?.filter((it) => it.value === values.pricePeriod)}
                  options={pricePeriodOpts}
                  onChange={handleSelect(ItemFields.pricePeriod)}
                />
                {isFree || isCoffee ? (
                  <Input label={t('forms:rate')} disabled={true} value={rateValue} />
                ) : (
                  <NumberFormatInput
                    $isError={Boolean(error.price)}
                    name={ItemFields.price}
                    value={values.price}
                    onValueChange={handleNumberChange(ItemFields.price)}
                    label={t('forms:rate')}
                    libPrefix={currSign}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator={'.'}
                    thousandSeparator={','}
                    disabled={isDisabled}
                  />
                )}
              </InnerItemsGrid>
            )}
            {isSelling && (
              <InnerItemsForm>
                <div>
                  <NumberFormatInput
                    $isError={Boolean(error.price)}
                    name={ItemFields.price}
                    value={values.price}
                    onValueChange={handleNumberChange(ItemFields.price)}
                    label={t('forms:sellPrice')}
                    libPrefix={currSign}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator={'.'}
                    thousandSeparator={','}
                    disabled={isDisabled}
                  />
                  <Text offsetLeft={10} offsetTop={5} variant={'primary'} as={'div'}>
                    {t('listings:descr.input.sell.price')}
                  </Text>
                </div>
                <div>
                  <NumberFormatInput
                    $isError={Boolean(error.discount)}
                    name={ItemFields.discount}
                    value={values.discount}
                    onValueChange={handleNumberChange(ItemFields.discount)}
                    label={t('forms:discount')}
                    icon={
                      <IconSvg width="16" height="16" viewBox="0 0 16 16" type={'info'} fillChildren={'darkgray'} />
                    }
                    allowNegative={false}
                    disabled={isDisabled}
                    decimalScale={2}
                    libPrefix={currSign}
                    decimalSeparator={'.'}
                    thousandSeparator={','}
                  />
                  <Text offsetLeft={10} offsetTop={5} variant={'primary'} as={'div'}>
                    {t('listings:descr.input.sell.discount')}
                  </Text>
                </div>
              </InnerItemsForm>
            )}
          </InnerItems>
          <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
          <Button type={'submit'} loading={loading} disabled={loading}>
            {t(`listings:buttons.form.${action}`)}
          </Button>
        </ListingForm>
        <LottieLoader $isVisible={loading} allScreen={true} />
      </WrapperForm>
    </NavContainer>
  );
};
