import {TCommunity} from '../../types/community';
import {User} from './user';
import {Connection, ParseObject} from './parse';
import {Item} from './item';
// import {TDocument} from "../../types/document";
import {Group} from './group';
import {Event} from './event';
import {Document} from './document';
import {TQueryPostItem} from '../../types/item';
import {PointerFile} from '../../types/common';

// import {TEvent} from "../../types/event";

export enum PostTypeT {
  items = 'items',
  notification = 'notification',
}

export enum ReactionTypes {
  smile = 'smile',
  wave = 'wave',
}

export type MediumTypes = 'loop' | 'email' | 'push' | 'sms' | 'message';

export interface Post extends ParseObject {
  Community?: TCommunity;
  type?: PostTypeT;
  text?: string;
  sentAs?: string;
  Author?: User;
  Attachments?: PointerFile[];
  Items?: TQueryPostItem[];
  Hide?: Connection<User>;
  Pin?: any;
  Seen?: any;
  isDeleted?: boolean;
  // likes?: Connection<User>;
  // waves?: Connection<User>;
  // smiles?: Connection<User>;
  comments?: Connection<Post>;
  countComments?: number;
  Options?: Connection<PollOption>;
  data?: NotificationPostData;
  // isLiked?: Connection<User>;
  // isWaved?: Connection<User>;
  // isSmiled?: Connection<User>;
  isHidden?: boolean;
  Translate?: PostTranslate;
  isMulti?: boolean;
  countLikes?: number;
  isLike?: boolean;
  countSmiles?: number;
  isSmile?: boolean;
  countWaves?: number;
  isWave?: boolean;
  medium?: MediumTypes[];
  notShow?: boolean;
}

export interface PollOption extends ParseObject {
  id?: string;
  objectId?: string;
  Voted?: Connection<User[]>;
  Post?: Post;
  countVotes?: number;
  text?: string;
  position?: number;
  isVoted?: boolean;
  Translate?: PostTranslate;
}

export enum NotificationEventType {
  newResident = 'newResident',
  approvedJoinRequest = 'approvedJoinRequest',
}

export type NotificationPostData = {
  personName?: string;
  objectId?: string;
  event?: NotificationEventType;
};

export interface PostTranslate extends ParseObject {
  en?: string;
  ru?: string;
  fr?: string;
  originalLang: string;
}

export type TPostItemBase<T> = Partial<
  Omit<Item & Document & Group & Event, 'name' | 'rewardType' | 'pricePeriod'> & T
> & {
  id: string;
  objectId: string;
  __typename: string;
};

export type TPostItemRaw = TPostItemBase<{
  listingName: string;
  groupName: string;
  eventName: string;
  documentName: string;
  eventRewardType: string;
  eventPricePeriod: number;
  listingRewardType: string;
  listingPricePeriod: string;
}>;

export type TPostItem = TPostItemBase<{
  name: string;
  rewardType: string;
  pricePeriod: string | number;
}>;
