import React, {useEffect, useRef, useState} from 'react';
import {ModalOverlayFullScreen, ModalWindow, ModalWrapper} from './styles';
type PropsT = {
  dark?: boolean;
  active: boolean;
  setActive: (v: boolean) => void;
};

export const ModalEmoji: React.FC<PropsT> = ({children, setActive, active}) => {
  const handleClose = () => setActive(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();
  const ref = useRef<any>();
  const below = -310;
  const above = 0;
  const [offset, setOffset] = useState(above);

  const position = () => {
    const pos = ref?.current?.getBoundingClientRect();
    if (pos?.y && pos.y < 100) {
      setOffset(above);
    } else if (pos?.y && pos.y - window?.innerHeight > -400) {
      setOffset(below);
    }
  };

  useEffect(() => {
    function watchScroll() {
      window?.addEventListener('scroll', position);
    }
    watchScroll();
    return () => {
      window?.removeEventListener('scroll', position);
    };
  }, []);

  useEffect(() => {
    position();
  }, [active]);

  if (!active) return <></>;
  return (
    <>
      <ModalOverlayFullScreen onClick={handleClose} />
      <ModalWindow className="ModalWindow" onClick={handleClose} offset={offset}>
        <ModalWrapper className="ModalOverlay" ref={ref} onClick={stop}>
          {children}
        </ModalWrapper>
      </ModalWindow>
    </>
  );
};
