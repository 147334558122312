import React from 'react';
import {RecoilRoot} from 'recoil';
import {useQuery} from '@apollo/client';
import {ViewerQuery, ViewerResponseType} from '../queries/auth';
import {session} from '../states/session';
import {currentCommunity, skipParams} from '../states/community';
import {typeUser} from '../states/typeUser';
import {CommunitiesResponseType, GetShortCommunitiesQuery} from '../queries/community';
import {TypeCommunity} from '../types/auth';
import {defaultGetCommunityWhereOption, toStateCommunity} from '../helpers/community';
import {getTypeUser} from '../helpers/user';
import {toStateSkipParams} from '../helpers/auth';
import {useLocation} from 'react-router-dom';
import {useCheckIsSupport} from '../hooks/user';

const RecoilProvider: React.FC<{
  alias?: string | null;
  skipSignUpParams?: URLSearchParams;
  currentComm?: string | null;
}> = ({children, currentComm, alias}) => {
  const {data: sessionData} = useQuery<ViewerResponseType>(ViewerQuery, {
    fetchPolicy: 'cache-only',
    ssr: true,
  });
  const {search} = useLocation();

  const viewer = sessionData?.viewer;
  const lastVisitedId = viewer?.user?.lastVisitedCommunity;
  const where = defaultGetCommunityWhereOption({
    viewerId: viewer?.user?.objectId,
    alias: alias || '',
    lastVisitedId: lastVisitedId || '',
  });
  const {data: CommunitiesData} = useQuery<CommunitiesResponseType>(GetShortCommunitiesQuery, {
    fetchPolicy: 'cache-only',
    ssr: true,
    variables: {
      where: where,
    },
  });
  const communities = CommunitiesData?.communities?.edges?.map((el) => el.node);
  const community = alias
    ? communities?.find((el) => el.alias === alias) || communities?.[0]
    : currentComm
    ? communities?.find((el) => el.objectId === currentComm) || communities?.[0]
    : lastVisitedId
    ? communities?.find((el) => el.objectId === lastVisitedId) || communities?.[0]
    : communities?.[0];
  const skipSignUp = toStateSkipParams(new URLSearchParams(search));
  const isSupport = useCheckIsSupport({email: viewer?.user?.email});
  return (
    <RecoilRoot
      initializeState={({set}) => {
        set(skipParams, skipSignUp);
        if (!viewer) return;
        set(session, viewer);
        if (!community) return set(typeUser, TypeCommunity.resident);
        if (isSupport) return set(typeUser, TypeCommunity.resident);
        set(currentCommunity, toStateCommunity(community));
        set(typeUser, getTypeUser(viewer.user.objectId, community));
      }}>
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;
