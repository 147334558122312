import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useEffect, useState} from 'react';
import {
  AddCardRequest,
  ConnectStripe,
  ConnectStripeRequest,
  ConnectStripeResponse,
  DeleteCardRequest,
  GetCardsRequest,
  GetPaymentPlansRequest,
  PaymentPlansResponse,
  SetDefaultCard,
  SetDefaultCardRequestType,
  TAddCardRequest,
  TAddCardResponse,
  TDeleteCardRequest,
  TDeleteCardResponse,
  TGetCardsRequest,
  TGetCardsResponse,
} from '../queries/payments';
import {CurrenciesResponse, GetCurrenciesRequest} from '../queries/currency';
import {
  GQLCurrencyOrder,
  GQLCurrencyWhereInput,
  GQLPaymentPlanOrder,
  GQLPaymentPlanWhereInput,
} from '../graphql.schema';

import {FormValidation} from '../helpers/FormValidation';
import {FormErrorFields, useFormError} from './error';
import {isFunction, isString} from '../helpers/validations';
import {TCreditCard} from '../queries/types/payments';
import {toCreditCard, toPointerCard} from '../helpers/payment';
import useMutableState from './useMutableState';
import {useViewer} from './user';
import {analyticsTrackFN} from '../helpers/account';
import {SearchKey} from '../constants/routes';

export const useCurrencies = (options?: {
  where?: GQLCurrencyWhereInput;
  order?: GQLCurrencyOrder;
  skip?: boolean;
  first?: number;
}) => {
  const {data, ...response} = useQuery<CurrenciesResponse>(GetCurrenciesRequest, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
    },
    ssr: true,
  });

  const items = data?.currencies?.edges?.map((edge) => ({
    ...edge.node,
  }));

  return {data: items || [], ...response};
};

export const usePaymentPlans = (options?: {
  where?: GQLPaymentPlanWhereInput;
  order?: GQLPaymentPlanOrder;
  skip?: boolean;
  first?: number;
}) => {
  const {data, ...response} = useQuery<PaymentPlansResponse>(GetPaymentPlansRequest, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
    },
    ssr: true,
  });

  const items = data?.paymentPlans?.edges?.map((edge) => ({
    ...edge.node,
  }));

  return {data: items, ...response};
};

export type TAddCardReturn = {
  values: Partial<TCreditCard>;
  error: FormErrorFields<TCreditCard>;
  loading: boolean;
  onSubmit: () => void;
  onChange: (next: {name: keyof TCreditCard; value: TCreditCard[keyof TCreditCard]}) => void;
};

const CardSchema = FormValidation.schema<Partial<TCreditCard>>({
  firstName: FormValidation.string().length({gte: 1}, 'error:paymentCard'),
  lastName: FormValidation.string().length({gte: 1}, 'error:paymentCard'),
  cardNumber: FormValidation.string().length({equal: 16}, 'error:paymentCard'),
  expiryDate: FormValidation.string().length({equal: 5}, 'error:paymentCard'),
  cvc: FormValidation.string().length({equal: 3}, 'error:paymentCard'),
  country: FormValidation.string('error:paymentCard'),
  zipCode: FormValidation.string().length({gte: 1}, 'error:paymentCard'),
  saveCard: FormValidation.boolean().isNullable,
});

type TAddCardParams = {
  onSuccess?: () => void;
  onError?: () => void;
  initialState: Partial<TCreditCard>;
};

export const useAddCreditCard = (params: TAddCardParams): TAddCardReturn => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const {onError, onSuccess} = params || {};
  const [addCard, {loading}] = useMutation<TAddCardResponse, TAddCardRequest>(AddCardRequest);
  const [values, setValues] = useState<Partial<TCreditCard>>(params.initialState);
  const [error, setError] = useFormError<Partial<TCreditCard>>();
  const viewer = useViewer();
  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(params.initialState?.country)) return;
    setValues(params.initialState);
    setState({hasSetInitialValues: true});
  }, [params.initialState]);

  const onChange = (next: {name: keyof TCreditCard; value: TCreditCard[keyof TCreditCard]}) => {
    setError(null);
    return setValues((prev) => ({
      ...prev,
      [next.name]: next.value,
    }));
  };

  const onSubmit = async () => {
    try {
      if (!CardSchema.validate<TCreditCard>(values)) return;

      const response = await addCard({
        variables: {
          fields: toPointerCard(values),
        },
      });

      if (!response?.data?.paymentAddCard) throw new Error('Payment denied. Please check your CC info.');
      analyticsTrackFN('Credit Card Added', {
        name: `${viewer?.firstName} ${viewer?.lastName}`,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
      });
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (e) {
      if (isFunction(onError)) onError();
      setError(e);
    }
  };

  return {
    onChange,
    onSubmit,
    values,
    error,
    loading,
  };
};

export const useViewerCards = (viewerId?: string) => {
  const viewer = useViewer();
  const [cards, setCards] = useState<TCreditCard[] | undefined>();
  const [activeCard, setActiveCard] = useState<string | undefined>('');
  const [getCards, {...response}] = useMutation<TGetCardsResponse, TGetCardsRequest>(GetCardsRequest);
  const handleGetCards = useCallback(async () => {
    if (!viewerId) return;

    try {
      const {data} = await getCards({
        variables: {
          id: viewerId,
        },
      });
      const res = data?.paymentGetUserCards.cards;
      setActiveCard(data?.paymentGetUserCards.defaultCardId);
      if (!res) {
        throw new Error();
      }

      const mappedCards = toCreditCard(res, viewer);

      setCards(mappedCards);
    } catch (e) {
      console.log(e);
    }
  }, [viewerId]);

  useEffect(() => {
    handleGetCards();
  }, []);

  return {cards, activeCard, refetch: handleGetCards, ...response};
};

export const useDeleteCard = (params: {onSuccess?: () => void; onError?: () => void; cardId?: string}) => {
  const {onError, onSuccess, cardId} = params;
  const [deleteCard] = useMutation<TDeleteCardResponse, TDeleteCardRequest>(DeleteCardRequest);

  return useCallback(async () => {
    if (!cardId) return;

    try {
      await deleteCard({
        variables: {
          cardId: cardId,
        },
      });
      onSuccess?.();
    } catch (e) {
      onError?.();
      console.log(e);
    }
  }, [cardId]);
};

export const useConnectStripe = () => {
  const [mutate] = useMutation<ConnectStripeResponse, ConnectStripeRequest>(ConnectStripe);

  return async (returnUrl?: string) => {
    const {data} = await mutate({variables: {returnUrl}});
    const params = new URLSearchParams(returnUrl?.split('?')[1]);
    const stripeConnectId = params.get(SearchKey.stripeConnectId);

    if (stripeConnectId) {
      localStorage.setItem(SearchKey.stripeConnectId, stripeConnectId);
    }
    return data?.connectStripeAccount;
  };
};

export type useSetDefaultCardT = (options: {id: string; onSucces?: () => void; after?: () => void}) => Promise<boolean>;

export const useSetDefaultCard = (): useSetDefaultCardT => {
  const [mutate] = useMutation<undefined, SetDefaultCardRequestType>(SetDefaultCard);

  return async (options: {id: string; onSucces?: () => void; after?: () => void}) => {
    try {
      const {data} = await mutate({variables: {cardId: options.id}});
      options.after?.();
      if (data) options.onSucces?.();
      return true;
    } catch (e) {
      return false;
    }
  };
};
