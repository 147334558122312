import {CommunityMembersT, MemberT, newCommunityMembersT, PeopleSearchType} from '../../types/people';
import {User} from '../../queries/types/user';
import {TypeCommunity} from '../../types/auth';
import {SetItemsSearchParams} from '../../containers/People/types';
import React from 'react';
import {CommunityStateType} from '../../states/community';
import {reportActions} from '../../hooks/report';

export type PersonCardT = MemberT & {
  isAdmin?: boolean;
  isManager?: boolean;
  actionsType?: ManageActions;
  manageActions?: ManageActionsList;
  viewerId?: string;
  reportActions?: reportActions;
  adminLabels: Record<string, string>;
  typeUser?: TypeCommunity;
  //listingCount?: [string, number][];
  listingsCount?: number;
};

export type PersonCardSmallT = Pick<
  MemberT,
  | 'Avatar'
  | 'isVerified'
  | 'id'
  | 'objectId'
  | 'status'
  | 'firstName'
  | 'lastName'
  | 'aptSuite'
  | 'isOnline'
  //| 'listingsCount'
  | 'listingCount'
  | 'Reviews'
> & {
  isManager?: boolean;
  isAdmin?: boolean;
  actionsType?: ManageActions;
  manageActions?: ManageActionsList;
  communityId?: string;
  viewerId?: string;
  reportActions?: reportActions;
  refetch?: () => void;
  adminLabels: Record<string, string>;
  typeUser?: TypeCommunity;
};

export enum ManageActions {
  NONE = 'NONE',
  MANAGE_NEW = 'MANAGE_NEW',
  MANAGE_ALL = 'MANAGE_ALL',
}

export type PeopleListProps = {
  list?: MemberT[];
  itemsType?: 'default' | 'small';
  actionsType?: ManageActions;
  manageActions?: ManageActionsList;
  communityId?: string;
  emptyText?: string;
  viewerId?: string;
  hasMore?: boolean;
  fetchMore?: () => void;
  refetch?: () => void;
  reportActions?: reportActions;
  adminLabels: Record<string, string>;
  typeUser?: TypeCommunity;
  loadMore?: () => void;
  hasNextPage?: boolean;
  //listingCount?: [string, number][];
};

export type ManageActionsList = {
  personDeny?: (id: string) => void;
  personApprove?: (id: string) => void;
  personView?: (id: string) => void;
  personRemove?: (id: string) => void;
  personMakeAdmin?: (id: string, communityId: string) => void;
  personRemoveAdmin?: (id: string, communityId: string) => void;
};

export type PeoplePropsT = {
  user?: User | null;
  members?: CommunityMembersT | null;
  typeUser?: TypeCommunity;
  actionsList?: ManageActionsList;
  newMembers?: newCommunityMembersT | null;
  loading?: boolean;
  peopleType: PeopleSearchType;
  setSearchParams: SetItemsSearchParams;
  setPeopleType: React.Dispatch<React.SetStateAction<PeopleSearchType>>;
  //membersItemsCount: [string, number][];
  community?: CommunityStateType;
  hasMore?: boolean;
  fetchMore?: () => void;
  refetch?: () => void;
  reportActions?: reportActions;
  adminLabels: Record<string, string>;
  isAdmin?: boolean;
  canInvite?: boolean;
  canManage?: boolean;
  loadMore?: () => void;
  hasNextPage?: boolean;
};
