import React from 'react';
import {
  CardCheckEntry,
  CardContentWrapper,
  CardInfoText,
  CardLink,
  CardLinkWrapper,
  CardTitle,
  CardWrapper,
  ContainerBorderTop,
  ContentWrapperFlex,
  TextContainer,
  TextContainerEntry,
  TopCardMessageWrapper,
} from '../styles';
import {CardCheckProps} from '../types';
import {PaymentInfo} from '../PaymentInfo';
import {CardTags} from '../CardTags';

export const CheckInfo: React.FC<{info: string; date: string}> = ({info, date}) => {
  return (
    <TopCardMessageWrapper>
      <TextContainer>
        <TextContainerEntry bold={true}>{info}</TextContainerEntry>
        <TextContainerEntry>{date}</TextContainerEntry>
      </TextContainer>
    </TopCardMessageWrapper>
  );
};

export const Check: React.FC<CardCheckProps> = ({title, checkInfo, descr, payments, tags}) => {
  const entries = checkInfo?.entries?.map((e) => (
    <CardCheckEntry key={e.label}>{`${e.label}: ${e.value}`}</CardCheckEntry>
  ));
  // const {viewCard, paymentType} = checkInfo?.buttons;

  return (
    <CardWrapper>
      <CardContentWrapper padTop={25}>
        <CheckInfo info={`${checkInfo?.type} #${checkInfo?.id}`} date={checkInfo?.date || ''} />
        <CardTitle>{title}</CardTitle>
        <CardTags tags={tags} />
        <TopCardMessageWrapper>
          <ContentWrapperFlex direction={'column'}>{entries}</ContentWrapperFlex>
        </TopCardMessageWrapper>
        <PaymentInfo payments={payments} />
        <ContainerBorderTop>
          <CardInfoText>{descr}</CardInfoText>
        </ContainerBorderTop>
        <CardLinkWrapper spaceBetween={true}>
          <CardLink onClick={checkInfo?.buttons?.viewCard.onClick}>{checkInfo?.buttons?.viewCard.label}</CardLink>
          <CardLink onClick={checkInfo?.buttons?.paymentType.onClick}>{checkInfo?.buttons?.paymentType.label}</CardLink>
        </CardLinkWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};
