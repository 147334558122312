import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';

export const TableWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 12px;
  text-align: left;
  max-width: unset;

  & > table > tr:first-child > td:nth-child(6) {
    padding-right: 16px;
  }

  ${Media.down.l} {
    max-width: unset;
    overflow-x: scroll;
  }
  ${Media.down.xl} {
    & > table td span {
      font-size: 12px;
    }
  }

  & > table {
    width: 100%;
    ${Media.down.l} {
      width: max-content;
    }
  }

  & > table > :first-child:is(tr) {
    background-color: ${({theme}) => theme.palette.background.septenary};
  }
  & > table > tr:not(:first-child) > td:first-child {
    padding-left: 17px;
  }

  ${Media.down.m} {
    & > :last-child {
      width: 90%;

      & > :first-child {
        margin-left: 15px;
      }
    }
  }
`;

export const PayoutsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: min(25px, 3vw);
  margin: 10px 0;

  & > :first-child {
    margin: 0;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > div,
  & > a {
    height: 62px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 32%;
  }
`;
