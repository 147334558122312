import styled, {css} from 'styled-components';
import {Text} from '../Typography/Text';
import {Media} from '../theme';
import {TagType} from './types';
import {Message} from '../Form/Message';
import {Button} from '../Button/Button';
import {OpType} from './DateBlock/DateTimePicker';

export const ContentWrapper = styled.div<{offsetTop?: number}>`
  width: 100%;
  height: max-content;
  ${({offsetTop}) => offsetTop && ` margin-top: ${offsetTop}px;`};
`;

export const CardWrapper = styled.section<{padding?: number; $custom?: boolean; $isEvent?: boolean}>`
  width: 100%;
  max-width: 100%;
  height: fit-content;
  background: ${({theme}) => theme.palette.background.primary};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  border-radius: 12px;
  padding: ${({padding}) => padding}px 0;
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  position: relative;
  ${Media.down.m} {
    ${({$custom}) => $custom && `border: none;`};
    background: ${({theme}) => theme.palette.background.primary};
  }
  ${Media.down.m} {
    ${({$isEvent}) =>
      $isEvent &&
      `
        max-width: 100%;
        background: transparent;
        border: none;
    `}
  }

  .pswp_image {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  .pswp__img {
    object-fit: contain;
    cursor: pointer;
  }
`;

export const EmptyWhiteSpace = styled.div`
  height: 150px;
  background-color: ${({theme}) => theme.palette.background.primary};
`;

export const CardImage = styled.div<{imageUrl: string; $isDeleted?: boolean}>`
  height: 213px;
  background-image: url(${({imageUrl}) => imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;

  ${Media.down.xxs} {
    height: 192px;
  }

  &::before {
    ${({$isDeleted}) => $isDeleted && 'content: "";'}
    background-color: #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const CardTagsWrapper = styled.ul`
  width: max-content;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 4px 6px 10px 0;
`;

export const CardTag = styled.li<{type: TagType}>`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 15px;
  height: 16px;
  display: flex;
  align-items: center;
  color: ${({theme, type}) => {
    switch (type) {
      case TagType.listingType:
      case TagType.plain:
      case TagType.coffee:
      case TagType.free:
        return theme.palette.text.septenary;

      case TagType.sell:
      case TagType.purchased:
        return theme.palette.text.primary;

      default:
        return theme.palette.text.septenary;
    }
  }};
  margin-right: 6px;
  padding: ${({type}) => (type === TagType.plain ? '0' : '2px 10px')};
  border-radius: 6px;
  background-color: ${({theme, type}) => {
    switch (type) {
      case TagType.plain:
        return 'transparent';

      case TagType.listingType:
        return theme.palette.grayscale._3;

      case TagType.sell:
        return theme.palette.common.red;

      case TagType.purchased:
        return theme.palette.common.aqua;

      case TagType.coffee:
        return theme.palette.common.blueLight;

      case TagType.free:
        return theme.palette.common.yellowLight;

      default:
        return 'transparent';
    }
  }};
`;

export const CardContentWrapper = styled.div<{padTop?: number; $isAvailable?: boolean; $noMargin?: boolean}>`
  margin: 23px 31px;
  height: max-content;
  padding-top: ${({padTop}) => (padTop ? padTop + 20 : 0)}px;

  ${Media.down.m} {
    padding-top: ${({padTop}) => (padTop ? padTop + 30 : 0)}px;
    margin: ${({$noMargin}) => ($noMargin ? '8px 0 0 0' : '8px 12px 0 12px')};
  }
`;

export const ListingInfo = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const MessageCardContentWrapper = styled.div<{
  padTop?: number;
  $isAvailable?: boolean;
  $isMessaging?: boolean;
  $isPreview?: boolean;
  $isDone?: boolean;
}>`
  margin: 8px 15px;
  height: max-content;
  padding-top: ${({padTop}) => (padTop ? padTop : 0)}px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({$isMessaging}) => ($isMessaging ? 'column' : 'row')};
  ${({$isDone}) => $isDone && ` flex-direction: column;`}
  ${({$isPreview}) => $isPreview && ` pointer-events: none;`}
  & > div {
    flex-direction: column;
    & > a {
      max-width: 100%;
    }
  }
  ${Media.down.l} {
    flex-direction: ${({$isMessaging}) => ($isMessaging ? 'column' : 'row')};
    ${({$isDone}) => $isDone && ` flex-direction: column;`}
  }
  ${Media.down.xxs} {
    margin: 11px 15px;
    & > div {
      flex-direction: column;
    }
  }
`;

export const DateWrapperCard = styled.div<{$isAvailable?: boolean; $disable?: boolean}>`
  ${({$isAvailable}) => !$isAvailable && ` opacity: 0.4; pointer-events: none;`}
  ${({$disable}) => $disable && ` pointer-events: none;`}
`;

export const NotAvailable = styled.div`
  text-align: center;
  width: 100%;
  margin: 16px 0;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const CardTitle = styled.span`
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

const cardDatesWrapperStyles = css`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: none;
  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

export const CardDatesWrapper = styled(ContentWrapper)<{offsetBottom?: number; zIndex?: number}>`
  ${cardDatesWrapperStyles};
  ${({offsetBottom}) => offsetBottom && ` margin-bottom: ${offsetBottom}px;`};
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    border-left: 1px solid ${({theme}) => theme.palette.background.secondary};
    height: 100%;
  }
  outline: none;
  * {
    -webkit-tap-highlight-color: transparent;
  }
  z-index: ${({zIndex}) => (zIndex ? zIndex : '200')};
`;
export const CardDatesWrapperGhost = styled(ContentWrapper)<{offsetBottom?: number; zIndex?: number}>`
  display: flex;
  justify-content: space-between;
  ${({offsetBottom}) => offsetBottom && ` margin-bottom: ${offsetBottom}px;`};
  outline: none;
  * {
    -webkit-tap-highlight-color: transparent;
  }
  z-index: ${({zIndex}) => (zIndex ? zIndex : '200')};
`;

export const CardDatesWrapperFull = styled(ContentWrapper)<{offsetBottom?: number; zIndex?: number}>`
  ${cardDatesWrapperStyles}
  ${({offsetBottom}) => offsetBottom && ` margin-bottom: ${offsetBottom}px;`};
  z-index: ${({zIndex}) => (zIndex ? zIndex : '200')};
`;

const cardDateWrapperStyles = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  padding: 9px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({theme}) => theme.palette.grayscale._20};
  border-radius: 8px;
`;

export const CardDateWrapper = styled.div<{
  padRight?: number;
  $noOverflow?: boolean;
  $independent?: boolean;
  $ml?: boolean;
}>`
  ${cardDateWrapperStyles};
  ${({$independent, theme}) =>
    $independent &&
    `
    border: 1px solid ${theme.palette.background.secondary};
    width: 49%;
    padding: 12px 13px;
  `};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 999;
  & * {
    outline: none;
    will-change: transform;
  }
  ${({$ml}) =>
    $ml &&
    `
    margin-left: 8px;
  `};
  ${({$noOverflow}) => $noOverflow && `   overflow: visible;`}
`;

export const CardDateWrapperFull = styled.div<{padRight?: number}>`
  ${cardDateWrapperStyles};
  padding: 12px 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const CardDateTitle = styled(Text).attrs({case: 'uppercase'})`
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 13px;
  color: ${({theme}) => theme.palette.text.septenary};
  text-transform: none;
  ${Media.down.xxs} {
    font-size: 10px;
  }
`;

export const CardDate = styled.span`
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.quaternary};

  ${Media.down.xxs} {
    font-size: 12px;
  }
`;

export const CardDatesEdit = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 4px;
  position: absolute;
  right: -7px;
  top: -7px;
  background: ${({theme}) => theme.palette.grayscale.white};
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
`;

export const CardPaymentTypeWrapper = styled(CardDatesWrapper)`
  padding: 12px;
  margin: 9px 0;
  justify-content: flex-start;
  cursor: pointer;
  &:after {
    display: none;
  }
`;

export const CardPaymentTypeIcon = styled.div`
  width: max-content;
  height: max-content;
`;

export const CardPaymentTypeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 11px;
`;

export const CardPaymentTypeTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.octonary};
  margin: 0;
`;

export const CardPaymentTypeText = styled.p`
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.septenary};
  margin: 0;
`;

export const CardInfoText = styled.p`
  margin: 16px 0;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 12px;
  line-height: 13px;
  color: ${({theme}) => theme.palette.text.octonary};
  background-color: ${({theme}) => theme.palette.common.yellowLight};
  padding: 12px 14px;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const CardLinkWrapper = styled.div<{spaceBetween?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: ${({spaceBetween}) => (spaceBetween ? 'space-between' : 'center')};
  align-items: center;
`;

export const CardLink = styled.a`
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.septenary};
  margin-bottom: 21px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const CardPaymentInfoWrapper = styled(ContentWrapper)`
  margin: 4px 0 15px 0;
`;

export const TextContainer = styled.div`
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainerEntry = styled.span<{underline?: boolean; bold?: boolean; variant?: string}>`
  font-size: 14px;
  text-decoration-line: ${({underline}) => (underline ? 'none' : 'none')};
  color: ${({theme, variant}) => (variant ? theme.palette.text[variant] : theme.palette.text.octonary)};
`;

export const TextContainerTotal = styled(TextContainer)`
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
  padding-top: 10px;
  margin-top: 8px;
`;

export const ContentWrapperFlex = styled(ContentWrapper)<{direction?: 'column' | 'row'}>`
  display: flex;
  flex-direction: ${({direction}) => direction};
`;

export const CardRatingWrapper = styled(ContentWrapperFlex)<{direction?: 'column' | 'row'}>`
  margin: 30px 0;
  justify-content: center;
  align-items: center;
`;

export const RatingContent = styled(ContentWrapperFlex).attrs({direction: 'column'})`
  margin-left: 12px;

  & > * {
    margin-bottom: 7px;
  }
`;

export const AvatarWrapper = styled.div`
  width: max-content;
  flex-shrink: 0;
  justify-self: flex-start;
  align-self: flex-start;
`;

export const StarsWrapper = styled.div<{width?: number}>`
  & > * {
    margin-right: 5px;
    cursor: pointer;
    width: ${({width}) => (width ? width + 'px' : 'auto')};
  }
`;

export const ReviewContentWrapper = styled(ContentWrapperFlex).attrs({direction: 'column'})`
  margin: 9px 0;

  & > * {
    margin-bottom: 6px;
  }
`;

export const UserDataReviewWrapper = styled(ContentWrapperFlex).attrs({direction: 'row'})`
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 6px;
  }
`;

export const ReviewTextAreaWrapper = styled.div`
  textarea {
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    font-size: 14px;
  }

  textarea::placeholder {
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    font-size: 14px;
    color: ${({theme}) => theme.palette.text.undecimal};
  }
`;

export const ReviewInputWrapper = styled.div`
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.quindecimal};
  padding: 15px 20px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-radius: 8px;
`;

export const ReviewStarsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > span {
    max-width: 50%;
  }
`;

export const TopCardMessageWrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
`;

export const TopCardMessageText = styled.span`
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const RedText = styled.span`
  color: ${({theme}) => theme.palette.common.red};
`;

export const CardCheckEntry = styled(TextContainerEntry)`
  margin: 3px 0;
  color: ${({theme}) => theme.palette.text.septenary};
`;

export const ContainerBorderTop = styled.div`
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
  padding-top: 11px;
  margin: 15px 0;
`;

export const ButtonWrapper = styled.div<{pad: number}>`
  margin: ${({pad}) => pad}px 0;
`;

export const RequestButton = styled(Button)<{$notSelected?: boolean}>`
  ${({$notSelected, theme}) =>
    $notSelected &&
    `
    pointer-events: none;
    background: ${theme.palette.grayscale._11};
    border-radius: 12px;
    color: ${theme.palette.grayscale._0};
  `};
`;

export const CardUserInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  & > * {
    margin-right: 5px;
  }
`;

export const UserCardAvatarWrapper = styled(AvatarWrapper)<{isOnline?: boolean}>`
  margin: 0 12px 6px 0;
  position: relative;

  &::after {
    content: '';
    display: ${({isOnline}) => (isOnline ? 'block' : 'none')};
    width: 11px;
    height: 11px;
    bottom: 1px;
    right: -1px;
    position: absolute;
    background: ${({theme}) => theme.palette.common.aqua};
    border: 1px solid ${({theme}) => theme.palette.background.primary};
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

export const UserCardUsername = styled(TextContainerEntry)`
  font-size: 18px;
`;

export const UserCardIcon = styled(StarsWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0;
  }
`;

export const OutlinedButton = styled.button`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 14px;
  border: none;
  padding: 8px 13px;
  color: ${({theme}) => theme.palette.grayscale._6};
  background-color: ${({theme}) => theme.palette.grayscale._3};
  cursor: pointer;
  & svg {
    margin-left: 6px;
  }
  ${Media.down.m} {
    font-size: 12px;
  }
`;

export const InputsWrapper = styled(ContentWrapperFlex).attrs({direction: 'column'})`
  & > * {
    margin-bottom: 10px;
  }

  * {
    border-color: ${({theme}) => theme.palette.background.secondary};
  }
`;

export const MessageSuccessWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin-right: 5px;
  }

  ${Media.up.m} {
    flex-direction: column;
    gap: 10px;
    a {
      max-width: 120px;
      margin: auto;
    }
  }
`;

export const UserCardWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.primary};
`;

export const DatePickerWrapper = styled.div<{zIndex?: number; openType?: OpType}>`
  position: absolute;
  bottom: 10px;
  z-index: ${({zIndex}) => (zIndex ? zIndex : '990')};

  & * {
    outline: none;
    will-change: transform;
  }

  ${Media.down.m} {
    & > div {
      left: -30px;
      transform: translateX(0);
    }

    & .react-datepicker__current-month {
      font-size: 10px;
    }
  }
  ${({openType}) => {
    if (openType === 'date')
      return `
    `;
    if (openType === 'time')
      return `
      .react-datepicker__time-list {
        max-height: 300px;
      }
    `;
    return '';
  }}
`;

export const ErrorMessage = styled(Message).attrs({variant: 'error'})`
  margin: 15px 0;
`;

export const SuccessMessage = styled(Message).attrs({variant: 'success'})`
  margin: 15px 0;
`;

export const GrayLabel = styled.div`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 14px;
  color: ${({theme}) => theme.palette.text.duodecimal};
  padding-bottom: 7px;
`;

export const DurationInput = styled.input`
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._10};
  background: transparent;
  outline: none;
  max-width: 80px;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 14px;
  color: ${({theme}) => theme.palette.grayscale._10};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const MarkDeleted = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  width: 60px;
  height: 20px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.common.red};
  text-align: center;
  z-index: 1;
`;

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;

  ${Media.down.s} {
    align-items: flex-end;
  }
`;

export const CreditBlockWrapper = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._11};
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: -4px;
  top: 4px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const CreditRow = styled.div`
  color: ${({theme}) => theme.palette.grayscale._5};
  font-size: 14px;
  line-height: 22px;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

export const DatesInfoWrapper = styled.div`
  padding: 13px 0;
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
`;

export const DateInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
`;

export const ReqInfoLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-transform: capitalize;
  ${Media.down.xxl} {
    font-size: 10px;
  }
  ${Media.down.m} {
    font-size: 14px;
  }
  color: ${({theme}) => theme.palette.text.duodecimal};
`;

export const ReqInfoValue = styled.div`
  font-weight: 500;
  line-height: 150%;
  text-align: right;
  font-size: 14px;
  ${Media.down.xxl} {
    font-size: 10px;
  }
  ${Media.down.m} {
    font-size: 14px;
  }
  color: ${({theme}) => theme.palette.text.octonary};
`;
