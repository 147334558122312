import React from 'react';
import {
  ButtonLabelType,
  CancelledType,
  CustomerType,
  ItemType,
  PaymentLabelType,
  RatingInfo,
  ReviewInfo,
  ReviewT,
  ReviewTypes,
  RewardType,
  UserType,
} from '../../../ui-kit/RequestCard/types';
import {variant} from '../../../ui-kit/Labels/types';
import {
  CardContentWrapper,
  CardImage,
  CardInfoText,
  CardTitle,
  CardWrapper,
  MarkDeleted,
} from '../../../ui-kit/RequestCard/styles';
import {Text} from '../../../ui-kit/Typography/Text';

import {CardButton} from '../../../ui-kit/RequestCard/CardButton';
import {Label} from '../../../ui-kit/Labels';
import {LabelsWrapper} from './styles';
import {TEvent} from '../../../types/event';
import {useTranslation} from 'react-i18next';
import {DatesInfoWrapper, ReqInfoLabel, ReqInfoValue, DateInfoRow} from '../../Amenities/AmenityRequestCard/styles';
import {useGetLanguage} from '../../../ui-kit/utils/language';
import {optionsI18n} from '../../../libs/i18nextUtils';
import {SuccessEventMessage} from './index';

type EventMessageRequestCardProps = {
  title?: string;
  tags: {type: variant; label: string}[];
  image: string;
  descr: string;
  cardType: 'regular' | 'review';
  userType: CustomerType;
  rewardType?: RewardType;
  reviewType?: ReviewTypes;
  users: [UserType, UserType];
  paymentLabel: PaymentLabelType;
  buttonLabel: ButtonLabelType;
  rating?: RatingInfo;
  cancelled: CancelledType;
  review?: ReviewInfo;
  opponentInfo: UserType;
  eventInfo: ItemType;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  isDeleted?: boolean;
  buttonSelectText?: string;
  event?: Partial<TEvent>;
  guests?: number;
  startDate?: Date;
  booked?: boolean;
  repeat?: string;
};

export const EventMessageRequestCard: React.FC<EventMessageRequestCardProps> = ({
  title,
  image,
  tags,
  buttonLabel,
  descr,
  isDeleted,
  guests,
  startDate,
  booked,
  repeat,
}) => {
  const {t} = useTranslation();
  const attendeesLabel = t('requests:labelTickets');
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;
  return (
    <CardWrapper>
      {isDeleted && (
        <MarkDeleted>
          <Text size={12} color={'primary'}>
            Deleted
          </Text>
        </MarkDeleted>
      )}
      <CardImage imageUrl={image} $isDeleted={isDeleted} />
      <CardContentWrapper>
        <CardTitle>{title}</CardTitle>
        <LabelsWrapper>
          {tags.map((t) => (
            <Label variant={t.type} key={t.label}>
              {t.label}
            </Label>
          ))}
        </LabelsWrapper>

        <DatesInfoWrapper>
          <DateInfoRow>
            <ReqInfoLabel>{attendeesLabel}</ReqInfoLabel>
            <ReqInfoValue>{guests}</ReqInfoValue>
          </DateInfoRow>
          <DateInfoRow>
            <ReqInfoLabel>{t('requests:labelDate')}</ReqInfoLabel>
            <ReqInfoValue>{startDate && format(new Date(startDate), 'P', getLanguage())}</ReqInfoValue>
          </DateInfoRow>
          <DateInfoRow>
            <ReqInfoLabel>{t('requests:labelTime')}</ReqInfoLabel>
            <ReqInfoValue>{startDate && format(new Date(startDate), 'p', getLanguage())}</ReqInfoValue>
          </DateInfoRow>
          {repeat && (
            <DateInfoRow>
              <ReqInfoLabel>{t('requests:labelRepeats')}</ReqInfoLabel>
              <ReqInfoValue>{repeat}</ReqInfoValue>
            </DateInfoRow>
          )}
        </DatesInfoWrapper>

        {!isDeleted && (
          <>
            <CardButton
              primary={buttonLabel.primary}
              secondary={buttonLabel.secondary}
              type={'primary'}
              disabled={buttonLabel?.disabled}
            />
            {!booked ? <CardInfoText>{descr}</CardInfoText> : <SuccessEventMessage bordered={true} text={descr} />}
            <CardButton primary={buttonLabel.primary} secondary={buttonLabel.secondary} type={'secondary'} />
          </>
        )}
      </CardContentWrapper>
    </CardWrapper>
  );
};
