import {User} from './types/user';
import userFragment from './fragments/user';
import {CommunitySmallFragment} from './fragments/community';
import {gql} from '@apollo/client';
import {Profile} from '../types/profile';
import {Connection} from './types/parse';
import {AppFileFragment} from './fragments/file';
import {Community} from './types/community';
import {communityCheckItem, AccountCheckListType} from './types/checklist';

export interface UpdateProfileResponseType {
  updateUser: {
    user: User;
  };
}

export interface UpdateProfileRequestType {
  id: string;
  fields?: Partial<Profile> & {checkList?: Omit<AccountCheckListType, communityCheckItem>};
}

export const UpdateProfile = gql`
  mutation updateUser($id: ID!, $fields: UpdateUserFieldsInput) {
    updateUser(input: {id: $id, fields: $fields}) {
      user {
        ...UserFragment
      }
    }
  }
  ${userFragment}
`;

export interface OpenAIGenerateResponse {
  generateAiOpenApiText: {
    gptResponseText: string;
  };
}

export interface OpenAIGenerateRequest {
  textType: 'bio' | 'post' | 'listingDescription';
  promptData: [
    {
      firstName?: string;
      city?: string;
      interests?: string;
      canSpeak?: string;
      occupation?: string;
      foodRecommendations?: string;
      moviesRecommendations?: string;
      travelRecommendations?: string;
      serviceTitle?: string;
    },
  ];
}

export const openAIGenerate = gql`
  mutation generateAiOpenApiText($textType: String!, $promptData: [Object]) {
    generateAiOpenApiText(input: {textType: $textType, promptData: $promptData}) {
      gptResponseText
    }
  }
`;

export const UpdateProfileShort = gql`
  mutation updateUser($id: ID!, $fields: UpdateUserFieldsInput) {
    updateUser(input: {id: $id, fields: $fields}) {
      user {
        objectId
      }
    }
  }
`;

export const SetOnline = gql`
  mutation setOnline {
    setOnline
  }
`;

export interface RemoveUserRequest {
  email: string;
  token: string;
}

export const RemoveUser = gql`
  mutation RemoveUser($email: String!, $token: String!) {
    removeUser(input: {email: $email, token: $token})
  }
`;

export const СonfirmUserRemoval = gql`
  mutation confirmUserRemoval {
    confirmUserRemoval
  }
`;

export const DeactivateUser = gql`
  mutation deactivateUser {
    deactivateUser
  }
`;

export interface GenerateUsernameRequest {
  name: string;
}

export interface GenerateUsernameResponse {
  generateUsername: {
    name: string;
  };
}

export const GenerateUsername = gql`
  mutation checkUsername($name: String!) {
    generateUsername(input: {name: $name}) {
      name
    }
  }
`;

export type UsersResponseType = {
  users: Connection<User>;
};

export const GetUsersQuery = gql`
  query users($cursor: String, $first: Int, $where: UserWhereInput, $order: [UserOrder!]) {
    users(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          objectId
          firstName
          lastName
          isOnline
          onlineDate
          Avatar {
            ...AppFileFragment
          }
        }
      }
    }
  }
  ${AppFileFragment}
`;

export interface GetUsersCommunitiesResponse {
  users: Connection<{
    id?: string;
    objectId?: string;
    Communities: Connection<Community>;
  }>;
}

export const GetUsersCommunities = gql`
  query getCommunities($where: UserWhereInput, $first: Int, $usersFirst: Int) {
    users(where: $where, first: $usersFirst) {
      edges {
        node {
          id
          objectId
          firstName
          Communities(first: $first) {
            edges {
              node {
                ...CommunitySmallFragment
              }
            }
          }
        }
      }
    }
  }
  ${CommunitySmallFragment}
`;

export type updatePushNotificationRequest = {
  userId: string;
  token: string;
};

export const updatePushNotification = gql`
  mutation updatePushNotification($userId: ID!, $token: String!) {
    updatePushNotification(input: {userId: $userId, token: $token})
  }
`;

export type rmUserPushNotificationT = {
  token: string;
};

export const rmUserPushNotification = gql`
  mutation rmUserPushNotification($token: String!) {
    rmUserPushNotification(input: {token: $token})
  }
`;

export interface SendVerifyEmailParams {
  email: string;
}
export interface SendVerifyEmailRes {
  sendVerificationEmail: {
    ok: boolean;
  };
}

export const SendVerifyEmailQuery = gql`
  mutation verifyEmail($email: String!) {
    sendVerificationEmail(input: {email: $email}) {
      ok
    }
  }
`;

export interface GetUsersPermissionsParams {
  communityId: string;
  userId: string;
}
export interface GetUsersPermissionsRes {
  getUserPermissionsList: {
    permissionsList: string[];
    error: string;
  };
}

export const GetUsersPermissionsQuery = gql`
  mutation getUserPermissionsList($communityId: String!, $userId: String!) {
    getUserPermissionsList(input: {communityId: $communityId, userId: $userId}) {
      permissionsList
      error
    }
  }
`;
