import React, {useState} from 'react';
import {
  ButtonWrapper,
  HangehLogo,
  ImgWrapperWide,
  LandingImage,
  SpaceLine,
  TextWrapper,
  TextWrapperWide,
  WraparoundContainer,
  WrapperDownload,
  WrapperPage,
} from './styles';
import {IconImage} from '../../ui-kit/Icon/Image';
// import {Carousel} from 'react-responsive-carousel';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Button} from '../../ui-kit/Button/Button';
import {APP_STORE_URL, GOOGLE_PLAY_URL} from '../../constants/env';
import {useResetInstallation} from '../../hooks/auth';
import ladingImage from '../../files/images/Landing_image.png';
//import {AuthContainer} from '../../containers/Auth';

interface PropTypes {
  forwardLogin: () => void;
  forwardJoinIn: () => void;
}

const PageHome: React.FC<PropTypes> = ({forwardLogin, forwardJoinIn}) => {
  const {t} = useTranslation();
  const [text] = useState<{title: string; description: string}>({
    title: t('auth:auth.landingTitle'),
    description: t('auth:auth.landingDescription'),
  });

  // const customIndicator = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean) => {
  //   return <Indicator $isSelected={isSelected} />;
  // };

  // const onChange = (index: number) => {
  //   switch (index) {
  //     case 0:
  //       setText({
  //         title: t('auth:auth.titleFirst'),
  //         description: t('auth:auth.descriptionFirst'),
  //       });
  //       break;
  //     case 1:
  //       setText({
  //         title: t('auth:auth.titleSecond'),
  //         description: t('auth:auth.descriptionSecond'),
  //       });
  //       break;
  //     case 2:
  //       setText({
  //         title: t('auth:auth.titleThird'),
  //         description: t('auth:auth.descriptionThird'),
  //       });
  //       break;
  //   }
  // };
  useResetInstallation(); //reset user from installation
  //return <AuthContainer />;
  return (
    <WrapperPage $noPadding>
      <HangehLogo
        type={'hangeh-logo-beta'}
        stroke={'none'}
        fill={'aqua'}
        width={'300'}
        height={'60'}
        viewBox={'15 0 120 63'}
      />
      <WraparoundContainer>
        {/* <Carousel
          onChange={onChange}
          renderIndicator={customIndicator}
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          stopOnHover={false}>
          <ImgWrapper>
            <IconImage type={'hello'} />
          </ImgWrapper>
          <ImgWrapper>
            <IconImage type={'socialDistancing'} />
          </ImgWrapper>
          <ImgWrapper>
            <IconImage type={'videoCall'} />
          </ImgWrapper>
        </Carousel> */}
        <ImgWrapperWide>
          <LandingImage src={ladingImage} />
        </ImgWrapperWide>
        <TextWrapperWide>
          <TextWrapper>
            <Text weight={'semi-bold'} size={24}>
              {text?.title}
            </Text>
            <SpaceLine />
            <Text weight={'normal'} size={18}>
              {text?.description}
            </Text>
            <SpaceLine />
          </TextWrapper>
          <ButtonWrapper>
            <Button onClick={forwardJoinIn} height={'52px'} rounded={true}>
              {t('auth:auth.getStarted')}
            </Button>
            <Button onClick={forwardLogin} ghost={true} height={'52px'} rounded={true} variant={'custom'}>
              {t('auth:auth.buttons.login')}
            </Button>
          </ButtonWrapper>
        </TextWrapperWide>
      </WraparoundContainer>

      <WrapperDownload>
        <Text size={14} weight={'medium'}>
          {t('auth:auth.download')}
        </Text>
        <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
          <IconImage type={'appStore'} />
        </a>
        <a href={GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
          <IconImage type={'googlePlay'} />
        </a>
      </WrapperDownload>
    </WrapperPage>
  );
};

export default PageHome;
