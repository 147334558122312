import React from 'react';
import {ThemeProvider as Provider} from 'styled-components';
import {themeVariants} from '../ui-kit/theme';
import {useRecoilState} from 'recoil';
import {modeState} from '../states/themeMode';

export const ThemeProvider: React.FC = ({children}) => {
  const [mode] = useRecoilState(modeState);

  if (!mode) {
    return null;
  }

  return <Provider theme={themeVariants[mode]}>{children}</Provider>;
};
