import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MobileSearchWrapper, SearchLineWrapper, WrapperPage, WrapperPeoplePage} from './styles';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../SEO';
import {PeoplePropsT} from './types';
import {PeopleBlock} from './PeopleBlock';
import {Search} from './Search';
import {MobileSearch} from './MobileSearch';
import {getPeopleByType} from '../../helpers/people';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {EmptyTag} from '../../containers/MobileTabs';
import {InvitePeopleModal} from '../common/Modal/InvitePeople';
import {CommunityOptionsFields} from '../../types/community';

export const CommunityPeople: React.FC<PeoplePropsT> = ({
  members,
  setSearchParams,
  user,
  loading,
  peopleType,
  setPeopleType,
  //membersItemsCount,
  community,
  fetchMore,
  hasMore,
  reportActions,
  refetch,
  adminLabels,
  typeUser,
  canInvite,
  loadMore,
  hasNextPage,
}) => {
  const {t} = useTranslation();
  const textSeo = t('common:seo.people', {text: community?.name || ''});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {getLink} = useLinks();
  const [isShowModalInvite, setShowModalInvite] = useState<boolean>(false);
  const invLink = getLink(route.inviteCommunity.path);
  const PortalWrapper = isMobile ? BasePortal : EmptyTag;
  const showInviteCTA = canInvite || community?.options?.[CommunityOptionsFields.passCode] !== false;
  const handleOpenInvite = () => setShowModalInvite(true);
  const handleCloseInvite = () => setShowModalInvite(false);

  const peopleList = useMemo(() => {
    if (members) return getPeopleByType(peopleType, members);
    return [];
  }, [peopleType, members?.countMembers]);
  //const listingCount = membersItemsCount;

  return (
    <WrapperPage>
      <Seo
        title={textSeo}
        keywords={seoKeywords}
        description={community?.descr}
        og={{image: community?.avatar || ''}}
      />
      <WrapperPeoplePage className={'people__page'}>
        <SearchLineWrapper>
          <Search
            setSearchParams={setSearchParams}
            community={community}
            setPeopleType={setPeopleType}
            invite={showInviteCTA}
          />
        </SearchLineWrapper>
        <MobileSearchWrapper>
          <MobileSearch
            setSearchParams={setSearchParams}
            community={community}
            setPeopleType={setPeopleType}
            invite={showInviteCTA}
          />
        </MobileSearchWrapper>
        <PeopleBlock
          list={peopleList}
          communityId={members?.objectId}
          viewerId={user?.objectId}
          loading={loading}
          fetchMore={fetchMore}
          hasMore={hasMore}
          reportActions={reportActions}
          refetch={refetch}
          adminLabels={adminLabels}
          typeUser={typeUser}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          //listingCount={listingCount}
        />
      </WrapperPeoplePage>
      {showInviteCTA && (
        <AddButtonPortal
          onClick={handleOpenInvite}
          iconType={'person-add'}
          animated
          animatedText={t('common:addButtons.invite')}
        />
      )}
      {isShowModalInvite && (
        <PortalWrapper>
          <InvitePeopleModal
            onClose={handleCloseInvite}
            inviteLink={invLink}
            isManager={canInvite}
            commPassCode={community?.passCode}
            communityName={community?.name}
          />
        </PortalWrapper>
      )}
    </WrapperPage>
  );
};
