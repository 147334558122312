import React, {useState} from 'react';
import {FeedProps, TCommunityPeople} from './types';
import {PostFeed} from './Posts/styles';
import {Post} from './Posts/Post';
import {TypeCommunity} from '../../types/auth';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {useTranslation} from 'react-i18next';
import {MemoEmptyStateCommunityLoop} from '../EmptyState/CommunityLoop';
import {FeedSkeleton, FeedSkeletonSection} from '../common/Skeletons/FeedSkeleton';
import {MemberT} from '../../types/people';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {EmptyState} from '../EmptyState/EmptyState';
import {getTypeUserByTCommunity} from '../../helpers/user';
import {useGetCurrencyValue} from '../../hooks/currency';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {PostTypeT} from '../../types/post';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {useSetRecoilState} from 'recoil';
import {isModal} from '../../states/themeMode';
import defaultCommunityAvatar from '../../ui-kit/ProfileIntro/images/avatar-default-community.png';

export type cbDeleteT = () => Promise<void>;
export const Feed: React.FC<FeedProps> = ({
  posts,
  typeUser,
  menuActions,
  viewerId,
  postActions,
  loading,
  refetch,
  community,
  hasMorePosts,
  loadMore,
  viewer,
  actions,
  adminLabels,
  hideAttach,
  isGroup,
  getPollData,
  postType,
  onLocalDelete,
  localDeleted,
  onCreateRetry,
  onLikeItem,
  itemsLiked,
  reportActions,
  toggleCreating,
  //generateListing,
}) => {
  const {t} = useTranslation();
  const btnHide = t('community:loop.post.menuMore.hide');
  const btnDelete = t('community:loop.post.menuMore.delete');
  const setIsModal = useSetRecoilState(isModal);
  const [focusPostId, setFocusPostId] = useState<string | undefined>('');
  const [deleteCB, setDeleteCB] = useState<cbDeleteT | undefined>();
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  /*const postAuthors = useMemo(
    () =>
      uniqBy(
        posts?.map((p) => p.Author as MemberT),
        'objectId',
      ),
    [posts?.length],
  );*/
  //const membersListingsCount = useMembersListingsCount(postAuthors ?? [], community?.objectId, community?.__typename);
  const {getSignValue} = useGetCurrencyValue();
  const handleOpenDelete = (id?: string, cb?: cbDeleteT) => {
    setFocusPostId(id);
    setShowModalDelete(true);
    setDeleteCB(() => cb);
  };

  const handleCloseDelete = () => {
    setFocusPostId('');
    setShowModalDelete(false);
    setDeleteCB(undefined);
  };
  const optionsManagerBase = (id?: string, isPinned?: boolean): MenuItemType[] => [
    {
      title: btnHide,
      onClick: () => {
        id && onLocalDelete?.(id);
        menuActions?.hide?.(id, refetch);
      },
    },
    ...(actions?.pinUnpin
      ? [
          {
            title: t(`community:loop.post.actions.${isPinned ? 'unpin' : 'pin'}`),
            onClick: () => id && actions?.pinUnpin?.(id),
          } as MenuItemType,
        ]
      : []),
    {
      render: 'line',
    },
    {
      title: btnDelete,
      onClick: () => {
        handleOpenDelete(id);
      },
      render: 'danger',
    },
  ];
  const modalsText = {
    delete: {
      title: t('common:modals.delete.title'),
      okText: t('common:modals.delete.button'),
      cancelText: t('common:modals.delete.close'),
    },
  };
  const handleDelete = async () => {
    focusPostId && onLocalDelete?.(focusPostId);
    menuActions?.deletePost?.(focusPostId, refetch);
    //refetch comments
    if (typeof deleteCB === 'function') await deleteCB?.();
    handleCloseDelete();
  };

  const createMenuOptions =
    typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin
      ? (id?: string, isPinned?: boolean): MenuItemType[] => {
          return optionsManagerBase(id, isPinned);
        }
      : (id?: string): MenuItemType[] => [
          {
            title: btnHide,
            onClick: () => menuActions?.hide?.(id, refetch),
          },
        ];

  if (!posts?.length && !loading) {
    if (typeUser === TypeCommunity.manager)
      return <MemoEmptyStateCommunityLoop isGroup={isGroup} linkInvite={''} linkVerify={''} />;
    return <EmptyState />;
  }

  if (loading && !posts?.length)
    return (
      <PostFeed>
        <FeedSkeletonSection />
      </PostFeed>
    );

  const handleFetchMore = () => loadMore(posts?.length);

  return (
    <PostFeed $isRecs={postType === PostTypeT.recommendation}>
      {/*generateListing && !isDesktop && (
        <AiPromotion isShow={postType === PostTypeT.request || postType === PostTypeT.all} redirect={generateListing} />
      )*/}
      <InfiniteScroll
        pageStart={0}
        loadMore={handleFetchMore}
        loader={<FeedSkeleton key={0} />}
        initialLoad={false}
        useWindow={true}
        hasMore={hasMorePosts}>
        {posts?.map((el, i) => {
          const autogeneratedAvatar = defaultCommunityAvatar;
          const postAuthor =
            el.sentAs !== 'community'
              ? ({
                  ...el.Author,
                  listingsCount: el?.Author?.listingCount,
                  adminLabel: el?.Author?.objectId && adminLabels?.[el.Author.objectId],
                  role: getTypeUserByTCommunity(el?.Author?.objectId, community, true),
                } as MemberT)
              : ({
                  ...el.Author,
                  Avatar: {
                    file: {
                      url: community?.avatar
                        ? community?.avatar
                        : community?.identityData?.autogenerated
                        ? autogeneratedAvatar
                        : '',
                    },
                  },
                  firstName: community?.name,
                  lastName: '',
                  isVerified: community?.isVerified,
                  listingsCount: el?.Author?.listingCount,
                  role: TypeCommunity.community,
                  Reviews: {count: 0},
                  adminLabel: el?.Author?.objectId && adminLabels?.[el.Author.objectId],
                } as unknown as MemberT);
          return (
            <Post
              {...el}
              isLiked={el.isLiked}
              key={el?.objectId || i}
              Author={postAuthor}
              createMenuOptions={createMenuOptions}
              postActions={postActions}
              viewerId={viewerId}
              refetch={refetch}
              typeUser={typeUser}
              hidePost={menuActions?.hide}
              communityId={community?.id}
              listingsCount={postAuthor?.listingCount}
              viewer={viewer}
              actions={actions}
              communityPeople={
                {
                  Owner: community?.Owner,
                  Residents: community?.Residents,
                  Admins: community?.Admins,
                } as TCommunityPeople
              }
              getSignValue={getSignValue}
              adminLabels={adminLabels}
              menuActions={menuActions}
              handleDelete={handleOpenDelete}
              hideAttach={hideAttach}
              getPollData={getPollData}
              setIsModal={setIsModal}
              isLocal={el?.isLocal}
              localDeleted={localDeleted}
              onCreateRetry={onCreateRetry}
              itemsLiked={itemsLiked}
              onLikeItem={onLikeItem}
              reportItemActions={reportActions}
              toggleCreating={toggleCreating}
            />
          );
        })}
      </InfiniteScroll>
      {isShowModalDelete && !isMobile && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={handleDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
      {isShowModalDelete && isMobile && (
        <BasePortal zIndex={1003}>
          <IsModalProvider>
            <Confirmation
              title={modalsText.delete.title}
              onClose={handleCloseDelete}
              okText={modalsText.delete.okText}
              onOk={handleDelete}
              buttonType={'danger'}
              cancelText={modalsText.delete.cancelText}
            />
          </IsModalProvider>
        </BasePortal>
      )}
    </PostFeed>
  );
};
