import {isServer, isString} from './validations';

type ImageSize = 'sm' | 'md' | 'lg';

export class Image {
  static withSize(fileInfo: {name?: string; url?: string}, size: ImageSize): string {
    if (!isString(fileInfo.url)) {
      console.error('Image.url: no base url');
      return '';
    }

    if (/^blob:/.test(fileInfo.url)) {
      console.log("Image.url: doesn't work with local files");
      return fileInfo.url;
    }

    const urlParts = fileInfo.url.split('.');

    if (urlParts.length < 2) {
      console.error("Image.url: base url doesn't have file extension");
      return fileInfo.url;
    }

    const ext = urlParts.pop();
    const withSize = [...urlParts, size, ext];

    return withSize.join('.');
  }

  static createName(ext?: string): string {
    const hash = `xxxxxxxxxxxxxxxxxxxx`.replace(/[x]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    return hash + '.' + ext || '';
  }

  static isImage(file: unknown): boolean {
    if (isServer()) {
      console.log('Image: Operation is not allowed on server');
      return false;
    }

    if (!(file instanceof File)) return false;

    return file.type.includes('image');
  }

  static getSize(file: File): number {
    return file.size;
  }

  static changeName(file: File): File {
    if (isServer()) {
      console.log('Image.changeName: Operation is not allowed on server');
      return file;
    }

    const ext = file.name.split('.')?.reverse()?.[0];
    if (!ext) {
      console.error("File doesn't have extension");
      return file;
    }

    return new File([file], Image.createName(ext), {type: file.type});
  }
}
