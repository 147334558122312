import React from 'react';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../../ui-kit/Popup';
import {EmptyTag} from '../../../containers/MobileTabs';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';

interface IProps {
  isPortal?: boolean;
}

export const PopupView: React.FC<IProps> = ({children, isPortal}) => {
  const Wrapper = isMobile || isPortal ? BasePortal : EmptyTag;
  return (
    <Wrapper>
      <IsModalProvider>{children}</IsModalProvider>
    </Wrapper>
  );
};
