import {useEffect, useMemo, useState} from 'react';
import {useUrlSearchManager} from './urlSearchManager';
import {UserPartsT} from '../types/people';
import {SearchKey} from '../constants/routes';
import {useMutation, useQuery} from '@apollo/client';
import {User} from '../queries/types/user';
import {GetReferralsRes, getReferrals, getUserByReferral} from '../queries/referral';
import {useViewer} from './user';
import {referralToState, referralsToState} from '../helpers/referral';
import {GQLReferralOrder} from '../graphql.schema';
import {convertCentToDollar} from '../helpers/payment';

export const useGetReferral = () => {
  const params = useUrlSearchManager();
  const referralFromUrl = params.get(SearchKey.ref);
  const [user, setUser] = useState<UserPartsT>();
  const [loading, setLoading] = useState(false);
  const [exec] = useMutation<{getUserByReferral: {user: User}}>(getUserByReferral);
  const getData = async () => {
    if (!referralFromUrl) return;
    setLoading(true);
    try {
      const res = await exec({variables: {referralCode: referralFromUrl}});
      setLoading(false);
      if (res.data?.getUserByReferral?.user) setUser(res.data?.getUserByReferral?.user);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (referralFromUrl) getData();
  }, [referralFromUrl]);
  return {code: referralFromUrl, referralLoading: loading, referralUser: user};
};

export const useGetUserReferrals = () => {
  const viewer = useViewer();
  const {data, loading} = useQuery<GetReferralsRes>(getReferrals, {
    variables: {
      where: {
        OR: [{ReferralBy: {have: {objectId: {equalTo: viewer?.objectId}}}}],
      },
      order: [GQLReferralOrder.userMadeTransaction_DESC, GQLReferralOrder.updatedAt_DESC],
    },
    skip: !viewer?.objectId,
  });
  const referrals = useMemo(() => referralsToState(data?.referrals, viewer?.objectId), [!!data]);

  return {referrals, loading};
};

export const useGetUserCredits = () => {
  const viewer = useViewer();
  const {data, loading} = useQuery<GetReferralsRes>(getReferrals, {
    variables: {
      where: {
        OR: [{User: {have: {objectId: {equalTo: viewer?.objectId}}}}],
      },
      order: [GQLReferralOrder.userMadeTransaction_DESC, GQLReferralOrder.updatedAt_DESC],
    },
    skip: !viewer?.objectId,
  });
  const referralOwner = useMemo(() => referralToState(data?.referrals?.edges?.[0]?.node), [!!data]);

  const totalCredits = convertCentToDollar(referralOwner?.accumulatedDiscount);
  const totalReferrals = referralOwner?.ReferralsCount;

  return {
    referralOwner,
    loading,
    totalCredits,
    totalReferrals,
    totalCreditsInCents: referralOwner?.accumulatedDiscount,
  };
};
