import styled from 'styled-components';
import {Text} from '../../../ui-kit/Typography/Text';
import {Media} from '../../../ui-kit/theme';

export const Wrapper = styled.div<{contentWidth?: number; topPadding?: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({topPadding}) => (topPadding ? `${topPadding}px` : '65px')};

  & > * {
    ${({contentWidth}) => contentWidth && `max-width: ${contentWidth}px;`}
  }

  ${Media.down.l} {
    padding-top: 0;
  }
`;

export const IconWrapper = styled.div`
  width: 330px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Text).attrs({weight: 'medium', size: 20, color: 'quaternary'})``;

export const Subtitle = styled(Text).attrs({size: 16, color: 'septenary'})``;

export const CloseButton = styled.div`
  position: fixed;
  left: 30px;
  top: 30px;
  display: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: ${({theme}) => theme.palette.background.primary};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  ${Media.down.m} {
    display: flex;
  }
`;
