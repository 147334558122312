import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  & > * {
    margin-bottom: 10px;
  }
`;

export const SkeletonRequestCard = () => {
  return (
    <Inner>
      <Skeleton width={'100%'} height={'200px'} />
      <Skeleton width={'90%'} height={30} />
      <Skeleton width={'60%'} height={30} />
      <Skeleton width={'90%'} height={64} />
      <Skeleton width={'90%'} height={64} />
    </Inner>
  );
};
