import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

export const NotInWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
  ${Media.down.xl} {
    padding-top: 90px;
  }
  ${Media.down.m} {
    padding-top: 40px;
  }
`;

export const NotInText = styled.div`
  font-size: 20px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  color: ${({theme}) => theme.palette.text.quaternary};
  margin-top: 16px;
  margin-bottom: 20px;
  ${Media.down.s} {
    font-size: 14px;
    margin-bottom: 14px;
    margin-top: 10px;
  }
`;

export const Icon = styled(IconSvg).attrs({
  type: 'sign-out',
  viewBox: '0 0  330 330',
})`
  width: 183px;
  height: 183px;
  ${Media.down.s} {
    width: 137px;
    height: 137px;
  }
`;
