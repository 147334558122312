import React, {useEffect, useMemo} from 'react';
import {useGetTargetTypeUser, useViewer, useViewerId} from '../../hooks/user';
import {useNewViewer} from '../../hooks/notifications';
import {TDataCreator, useCheckEventForAvailability, useCreateEventOrder, useEventLike} from '../../hooks/event';
import {useCreateMessage} from '../../hooks/message';
import {ViewEvent} from '../../components/Events/View';
import {EventFormAction, TEvent} from '../../types/event';
import {CommunityStateType, currentCommunity} from '../../states/community';
import {useRecoilState} from 'recoil';
import {useGetAdminsLabels} from '../../hooks/people';
import {GQLOrderWhereInput} from '../../graphql.schema';
import {typeOrder} from '../../queries/types/order';
import {useOrdersList} from '../../hooks/order';
import {SearchKey} from '../../constants/routes';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';
import {format} from 'date-fns';
import {analyticsTrackFN} from '../../helpers/account';

type TProps = {
  id?: string;
  community: CommunityStateType;
  dataCreator: TDataCreator;
  loading?: boolean;
  event: Partial<TEvent>;
};

export const ViewEventItem: React.FC<TProps> = ({community, dataCreator, id, loading, event}) => {
  const viewer = useViewer();
  const viewerObjId = viewer?.objectId;
  const createViewer = useNewViewer();
  const [comm] = useRecoilState(currentCommunity);
  const viewerId = useViewerId('objectId');
  const {handleLike} = useEventLike(id);
  const available = useCheckEventForAvailability({
    event: event,
    strictCheck: true,
    userId: viewerObjId,
  });
  const urlSearch = useUrlSearchManager();
  const isPreview = urlSearch.get(SearchKey.preview) === 'true';
  const dataCreateMsg = useCreateMessage({
    idActiveContact: event?.Lister?.objectId,
    communityId: community?.objectId,
    initialState: {
      Author: viewerObjId,
      text: '',
    },
    onSuccess: () => {
      analyticsTrackFN('Lister Messaged', {
        eventId: event?.objectId,
        eventType: event?.eventType, // service, rental, buy/sell
        listedFor: event?.rewardType, // coffee, free, money
        photoCount: event?.images?.length,
        title: event?.name,
        description: event?.descr,
        listerName: `${event?.Lister?.firstName}`,
        listerEmail: event?.Lister?.email,
        listerId: event?.Lister?.objectId,
        requesterName: `${viewer?.firstName}`,
        requesterEmail: viewer?.email,
        requesterId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
    },
  });

  useEffect(() => {
    if (event?.id && event?.Lister?.objectId !== viewerObjId) {
      createViewer(event.id, 'Event');
      analyticsTrackFN('Event Viewed', {
        eventId: event?.id,
        eventType: event?.eventType,
        rewardType: event?.rewardType,
        photoCount: event?.images?.length,
        title: event?.name,
        description: event?.descr,
        startTime: event?.startTime && format(new Date(event?.startTime), 'p'),
        endTime: event?.endTime && format(new Date(event?.endTime), 'p'),
        eventDate: event?.eventDate && format(new Date(event?.eventDate), 'P'),
        creatorName: `${event?.Lister?.firstName}`,
        creatorEmail: event?.Lister?.email,
        creatorId: event?.Lister?.objectId,
        requesterName: `${viewer?.firstName}`,
        requesterEmail: viewer?.email,
        requesterId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
    }
  }, [id, event?.Lister?.objectId, viewerObjId]);
  const dataOrder = useCreateEventOrder({
    communityId: community?.objectId,
    eventId: event?.objectId,
    rewardType: event.rewardType,
    startTime: event.startTime,
    eventType: event.eventType,
    onSuccess: (bookingDate?: Date) => {
      const date = new Date();
      analyticsTrackFN('Event Attending', {
        eventId: event?.objectId,
        eventType: event?.eventType,
        rewardType: event?.rewardType,
        photoCount: event?.images?.length,
        title: event?.name,
        description: event?.descr,
        listerName: `${event?.Lister?.firstName}`,
        listerEmail: event?.Lister?.email,
        listerId: event?.Lister?.objectId,
        requesterName: `${viewer?.firstName}`,
        requesterEmail: viewer?.email,
        requesterId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
        requestDate: format(new Date(date), 'P'),
        requestTime: format(new Date(date), 'p'),
        eventDate: bookingDate && format(new Date(bookingDate), 'P'),
        startTime: bookingDate && format(new Date(bookingDate), 'p'),
        guests: dataOrder?.changeData?.guests,
      });
    },
  });
  const requestAccess = true;
  const adminLabels = useGetAdminsLabels();
  const targetTypeUser = useGetTargetTypeUser(event?.Lister?.objectId);
  const whereOrders: GQLOrderWhereInput = useMemo(() => {
    return {
      orderType: {equalTo: typeOrder.eventOrder},
      Event: {have: {objectId: {equalTo: event?.objectId}}},
    };
  }, [event]);
  const orders = useOrdersList({where: whereOrders});
  const attendees = useMemo(
    () =>
      orders?.data?.reduce((acc, item) => {
        if (item && item.attendeeGuests) return acc + item.attendeeGuests;
        else return acc;
      }, 0) || 0,
    [orders],
  );
  const isAuthor = event?.Lister?.objectId === viewerId;

  return (
    <ViewEvent
      isAuthor={isAuthor}
      communityAlias={community?.alias}
      dataCreateMsg={dataCreateMsg}
      viewerObjId={viewerObjId}
      action={EventFormAction.view}
      event={event}
      $loading={loading}
      dataCreator={dataCreator}
      isAvailable={available.availableData}
      requestAccess={requestAccess}
      dataOrder={dataOrder}
      comm={comm}
      adminLabels={adminLabels}
      targetTypeUser={targetTypeUser}
      attendees={attendees}
      handleLike={handleLike}
      isPreview={isPreview}
    />
  );
};
