import React from 'react';
import {useRecoilState} from 'recoil';
import {Checklist} from '../../components/Checklist';
import {useUpdateUser, useViewerId} from '../../hooks/user';
import {AccountCheckListType, communityCheckItem} from '../../queries/types/checklist';
import {userState} from '../../states';
import {currentCommunity} from '../../states/community';
import {toStateCommunity} from '../../helpers/community';
import {useCommunityUpdate} from '../../hooks/community';

interface TProps {
  onClose?: () => void;
}

export const ChecklistContainer: React.FC<TProps> = ({onClose}) => {
  const userId = useViewerId();
  const [currentComm, updateCurrentComm] = useRecoilState(currentCommunity);
  const [user, updateUser] = useRecoilState(userState);
  const update = useUpdateUser();
  const {update: updateCommunity} = useCommunityUpdate();

  const hideCompletedCheck = (nameField: keyof AccountCheckListType) => async () => {
    if (nameField in communityCheckItem) {
      if (!currentComm?.objectId || !currentComm.checkList) return;

      const {data} = await updateCommunity({
        id: currentComm?.objectId,
        fields: {checkList: {...currentComm.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });

      data?.updateCommunity.community && updateCurrentComm(toStateCommunity(data.updateCommunity.community));
    } else {
      if (!userId || !user?.checkList) return;

      const {data} = await update({
        id: userId,
        fields: {checkList: {...user.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });
      data?.updateUser.user && updateUser(data?.updateUser.user);
    }
  };

  return (
    <Checklist
      onClose={onClose}
      hideCompletedCheck={hideCompletedCheck}
      checkList={{...(user?.checkList || {}), ...(currentComm?.checkList || {})} as AccountCheckListType}
    />
  );
};
