import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {Br} from '../../ui-kit/Typography/Br';
import {Text} from '../../ui-kit/Typography/Text';
import {useLinks} from '../../hooks/common';
import {MemoHeaderAuth} from './HeaderAuth';
import {InnerAuth} from './styles';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {WrapperAuth} from './WrapperAuth';

export const CheckYourEmail = () => {
  const {t} = useTranslation();
  const {state} = useLocation();
  const {getLink} = useLinks();

  if (!state) return <Redirect to={getLink(route.home)} />;

  return (
    <WrapperAuth>
      <InnerAuth>
        <MemoHeaderAuth title={t('auth:forgotYourPassword.title')} />
        <Br indent={30} />
        <Text size={16} color={'octonary'} variant={'tertiary'}>
          {t('auth:forgotYourPassword.descr', {email: state})}
        </Text>
        <Br indent={30} />
        <LinkButton to={getLink(route.auth.get())} variant={'primary'}>
          {t('auth:forgotYourPassword.back')}
        </LinkButton>
      </InnerAuth>
    </WrapperAuth>
  );
};
