import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

import {ErrorMessage, FormButtons, SettingsEntry, SettingsHeader, SettingsSubsection} from '../components';
import {User} from '../../../queries/types/user';
import {TypeCommunity} from '../../../types/auth';
import {TCommunity} from '../../../types/community';
import {FormType, AccountFormValues} from '../../../types/settings';
import {Input} from '../../../ui-kit/Form/Input';
import {StyledInput} from '../../../ui-kit/Form/Input/styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {Text} from '../../../ui-kit/Typography/Text';
import {
  PageWrapper,
  SectionWrapper,
  SectionWrapperForm,
  SettingsWrapper,
  SettingsInfoWrapper,
  EditPhoneInputWrapper,
  LinkButton,
  ButtonsWrapper,
} from '../commonStyles';
import {SettingsJoinedCommunities} from '../Personal/SettingsContacts';
import {GoBack} from '../../common/Buttons/GoBack';
import {AccountConnections} from '../../../containers/Settings/Account/AccountConnections';

interface TProps {
  typeUser?: TypeCommunity | null;
  user: User | null;
  form: FormType<AccountFormValues>;
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  generateUsername: (name: string) => Promise<string | undefined>;
  deleteUserConfirm: () => void;
  deactiveteUserConfirm: () => void;
  communities: Partial<TCommunity>[];
  pendingCommunities: Partial<TCommunity>[];
  onLeave: (id?: string) => void;
  setCurrentCommunity: (id: string) => void;
  loading?: boolean;
}

export const AccountSettings: React.FC<TProps> = ({
  onLeave,
  communities,
  typeUser,
  user,
  isEditing,
  onEdit,
  form,
  generateUsername,
  onCancel,
  deleteUserConfirm,
  deactiveteUserConfirm,
  setCurrentCommunity,
  pendingCommunities,
  loading,
}) => {
  const {t} = useTranslation();
  const [type, setType] = useState<'delete' | 'deactivate' | 'confirmed' | null>(null);

  const handleOpenModalDelete = () => {
    setType('delete');
  };

  const handleOpenModalDiactivate = () => {
    setType('deactivate');
  };

  const handleDelete = async () => {
    await deleteUserConfirm();
    setType('confirmed');
  };

  const handleDiactivate = async () => {
    await deactiveteUserConfirm();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setType(null);
  };

  const modalsText = useMemo(() => {
    return {
      title:
        type === 'delete'
          ? t('settings:manageAccount.modalTitleDelete')
          : t('settings:manageAccount.modalTitleDeactivate'),
      description:
        type === 'delete'
          ? t('settings:manageAccount.modalDescriptionDelete')
          : t('settings:manageAccount.modalDescriptionDeactivate'),
      okText:
        type === 'delete' ? t('settings:manageAccount.confirmDelete') : t('settings:manageAccount.confirmDeactivate'),
      cancelText: t('settings:manageAccount.cancel'),
      confirmedTitle: t('settings:manageAccount.confirmedTitle'),
      confirmedText: t('settings:manageAccount.confirmedText'),
    };
  }, []);
  const goBack = () => window?.history.back();

  return (
    <PageWrapper>
      <GoBack title={t('settings:account.title')} goBack={goBack} />
      <SettingsWrapper>
        <SettingsHeader title={t('settings:account.title')} hideMob={true} subtitle={t('settings:account.subtitle')} />
      </SettingsWrapper>
      {!isEditing ? (
        <AccountInfo typeUser={typeUser} user={user} onEdit={onEdit} />
      ) : (
        <AccountForm
          user={user}
          typeUser={typeUser}
          form={form}
          generateUsername={generateUsername}
          onCancel={onCancel}
        />
      )}

      <AccountConnections />

      <SettingsJoinedCommunities
        pendingCommunities={pendingCommunities}
        setCurrentCommunity={setCurrentCommunity}
        communities={communities}
        onLeave={onLeave}
        loading={loading}
      />

      <SettingsWrapper>
        <SettingsSubsection label={t('settings:manageAccount.title')} value={t('settings:manageAccount.subtitle')} />
      </SettingsWrapper>

      <ButtonsWrapper>
        <LinkButton onClick={handleOpenModalDiactivate}>Deactivate account</LinkButton>
        <LinkButton onClick={handleOpenModalDelete}>Delete account</LinkButton>
      </ButtonsWrapper>
      {type && (
        <Confirmation
          title={type === 'confirmed' ? modalsText.confirmedTitle : modalsText.title}
          onClose={handleCloseModal}
          subTitle={type === 'confirmed' ? modalsText.confirmedText : undefined}
          okText={type === 'confirmed' ? undefined : modalsText.okText}
          onOk={type === 'confirmed' ? undefined : type === 'deactivate' ? handleDiactivate : handleDelete}
          buttonType={'danger'}
          cancelText={type === 'confirmed' ? undefined : modalsText.cancelText}
        />
      )}
    </PageWrapper>
  );
};

interface InfoProps {
  typeUser?: TypeCommunity | null;
  user: User | null;
  onEdit: () => void;
  mobpt?: string;
}

const AccountInfo: React.FC<InfoProps> = ({user, onEdit, mobpt}) => {
  const {t} = useTranslation();

  return (
    <SectionWrapper $noBorder={true} mobpt={mobpt}>
      <Text color={'octonary'} size={18}>
        {t('settings:account.access')}
      </Text>
      <SettingsInfoWrapper noGap={true}>
        <SettingsEntry label={t('settings:account.username')} onClick={onEdit} value={user?.username} />
        <SettingsEntry label={t('settings:account.email')} onClick={onEdit} value={user?.email} />
        <SettingsEntry label={t('settings:account.phone')} onClick={onEdit} value={user?.phone} />
      </SettingsInfoWrapper>
      <FormButtons variant={'edit'} onClick={onEdit} />
    </SectionWrapper>
  );
};

interface FormProps {
  typeUser?: TypeCommunity | null;
  user: User | null;
  form: FormType<AccountFormValues>;
  generateUsername: (name: string) => Promise<string | undefined>;
  onCancel: () => void;
}

const AccountForm: React.FC<FormProps> = ({user, form, generateUsername, onCancel}) => {
  const {t} = useTranslation();
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true);
  const [suggestedUsername, setSuggestedUsername] = useState<string>('');

  const handleChangeUsername = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    form.onChange({name, value});

    const response = await generateUsername(value);
    setUsernameAvailable(response === value);
    setSuggestedUsername(response || value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    form.values.username = suggestedUsername || form.values.username;
    form.onSubmit();
  };

  const phoneOnChange = async (value: string) => {
    form.onChange({name: 'phone', value: value});
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => phoneOnChange(e.target.value);

  return (
    <SectionWrapperForm noBorder={true} onSubmit={handleSubmit}>
      <Text color={'octonary'} size={18}>
        {t('settings:account.access')}
      </Text>
      <SettingsInfoWrapper>
        <Input
          $isError={form.error.username}
          type={'text'}
          name={'username'}
          label={t('settings:account.username')}
          value={form.values.username}
          onChange={handleChangeUsername}
          icon={
            usernameAvailable ? (
              <IconSvg type={'circle-ok'} stroke={'green'} />
            ) : (
              <IconSvg type={'spam-2'} stroke={'red'} />
            )
          }
        />
        <Input
          $isError={form.error.email}
          type={'email'}
          name={'email'}
          inputMode={'email'}
          label={t('settings:account.email')}
          value={form.values.email}
          disabled={true}
          icon={
            user?.emailVerified ? (
              <IconSvg type={'circle-ok'} stroke={'green'} />
            ) : (
              <IconSvg type={'spam-2'} stroke={'red'} />
            )
          }
        />
        <EditPhoneInputWrapper $noPadd={true}>
          <PhoneInput
            value={form.values.phone || ''}
            onChange={phoneOnChange}
            placeholder={t('settings:personal.contacts.phone')}
            international={true}
            countryCallingCodeEditable={false}
            defaultCountry={'CA'}
            inputComponent={StyledInput}
          />
          <Input
            $isError={form.error.phone}
            value={form.values.phone}
            onChange={handleChangeCode}
            label={t('settings:personal.contacts.phone')}
            type={'text'}
          />
        </EditPhoneInputWrapper>
      </SettingsInfoWrapper>
      <ErrorMessage error={form.error.message} />
      <FormButtons variant={'update'} disabled={form.loading} onCancel={onCancel} />
    </SectionWrapperForm>
  );
};
