import React from 'react';
import {SkeletonWrapper} from './styles';
import Skeleton from 'react-loading-skeleton';

interface LoadingSkeletonProps {
  width?: number | string;
  height?: number | string;
  mobileWidth?: number | string;
  mobileHeight?: number | string;
  circle?: boolean;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({circle, width, height, mobileHeight, mobileWidth}) => {
  return (
    <SkeletonWrapper
      width={width}
      height={height}
      circle={circle}
      mobileHeight={mobileHeight}
      mobileWidth={mobileWidth}>
      <Skeleton circle={circle} height={height} />
    </SkeletonWrapper>
  );
};

const MemoLoadingSkeleton = React.memo(LoadingSkeleton);
export {MemoLoadingSkeleton};
