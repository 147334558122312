import {useMutation, useQuery} from '@apollo/client';
import {
  ArchiveNotifications,
  ArchiveNotificationsRequest,
  CreateNoficationQuery,
  GetNotificationResponse,
  GetNotifications,
  GetNotificationSettings,
  GetNotificationSettingsRequest,
  GetNotificationSettingsResponse,
  NewViewer,
  NewViewerRequest,
  NewViewerResponse,
  TCreateNoficationRequest,
  TCreateNoficationResponse,
  UpdateNotification,
  UpdateNotificationRequest,
  UpdateNotificationResponse,
} from '../queries/notifications';
import {useTranslation} from 'react-i18next';
import {Notification, NotificationsEvents} from '../queries/types/notifications';
import {
  NotificationFields,
  NotificationsEventTypes,
  NotificationStatus,
  NotificationsType,
  UserPartData,
  UserPartFields,
} from '../types/notifications';
import {toPOJO, useLiveQuery} from './parse';
import {useCallback, useEffect, useState} from 'react';
import {useIsSupport, useViewer, useViewerId} from './user';
import {AppFileFields} from '../types/messages';
import Parse from 'parse';
import {toStateNotificationSettings} from '../helpers/settings';
import {GQLNotificationOrder, GQLNotificationWhereInput} from '../graphql.schema';
import {formatAdditionalTranslate} from '../helpers/notifications';
import {TypeCommunity} from '../types/auth';
import {getUserName} from '../helpers/user';
import {useRecoilValue} from 'recoil';
import {currentCommunity} from '../states/community';
import {analyticsTrackFN} from '../helpers/account';

const toStateNotifications = (notifications?: Notification[]): NotificationsType[] | undefined => {
  return notifications?.map((n) => ({
    ...n,
    Community: {avatar: n?.Community.Avatar?.file.url},
    event: n.event as NotificationsEventTypes,
    createdAt: new Date(n.createdAt ?? ''),
    updatedAt: new Date(n.updatedAt ?? ''),
  }));
};

export const useLiveNotifications = (params: {commId?: string; notificationsOpened: boolean}) => {
  const {commId, notificationsOpened} = params;
  const isSupport = useIsSupport();
  const objectId = useViewerId('objectId');
  const [queryData, setQueryData] = useState<Parse.Query>();

  const memoMap = useCallback(
    (object: Parse.Object): NotificationsType => {
      return toPOJO<NotificationsType>(Object.values(NotificationFields), object, {
        from: (item: Parse.Object | undefined) => {
          if (!item) return;
          return toPOJO<UserPartData>(Object.values(UserPartFields), item, {
            Avatar: (item: Parse.Object | undefined) => {
              if (!item) return '';
              return toPOJO<UserPartData['Avatar']>(Object.values(AppFileFields), item, {
                file: (item: Parse.File): UserPartData['Avatar']['file'] => {
                  return {
                    url: item.url(),
                    name: item.name(),
                  };
                },
              });
            },
          });
        },
      });
    },
    [queryData],
  );

  const {data, loading, refetch, unsubscribe} = useLiveQuery<NotificationsType>({
    query: queryData as Parse.Query,
    map: memoMap,
  });

  useEffect(() => {
    if (!objectId) return;
    const user = new Parse.User();
    user.id = objectId;
    if (isSupport) {
      return;
      // const query = new Parse.Query('Notification')
      //   .equalTo('to', user)
      //   .equalTo('status', NotificationStatus.New)
      //   .notEqualTo('event', NotificationsEventTypes.newMessage)
      //   .include(['from.Avatar', 'from'])
      //   .addAscending('createdAt');
      // setQueryData(query);
    } else {
      if (!commId) return;
      const community = new Parse.Object('Community');
      community.id = commId;
      const query = new Parse.Query('Notification')
        .equalTo('to', user)
        .equalTo('status', NotificationStatus.New)
        .equalTo('Community', community)
        .notEqualTo('event', NotificationsEventTypes.newMessage)
        .include(['from.Avatar', 'from'])
        .addAscending('createdAt');
      setQueryData(query);
    }
  }, [commId, notificationsOpened]);

  useEffect(() => {
    if (!objectId) unsubscribe();
  }, [objectId]);
  return {data: Object.values(data ?? {}), loading, fetchMore: refetch};
};

export const useNotifications = (userId?: string, commId?: string) => {
  const isSupport = useIsSupport();
  const {refetch, data, loading, fetchMore} = useQuery<GetNotificationResponse>(GetNotifications, {
    skip: isSupport,
    variables: {
      order: GQLNotificationOrder.createdAt_DESC,
      first: 15,
      where: {
        to: {
          have: {
            id: {
              equalTo: userId,
            },
          },
        },
        sendAt: {
          exists: false,
        },
        status: {
          notIn: [NotificationStatus.Archived, NotificationStatus.Hidden],
        },
        event: {
          notEqualTo: NotificationsEventTypes.newMessage,
        },
        ...(isSupport
          ? {}
          : {
              Community: {
                have: {
                  objectId: {
                    equalTo: commId,
                  },
                },
              },
            }),
      } as GQLNotificationWhereInput,
    },
  });
  const returnData = toStateNotifications(data?.notifications.edges.map((e) => e.node));

  return {data: returnData, loading, fetchMore, refetch};
};

export const useNotificationTranslation = (userType?: TypeCommunity | null) => {
  const {t} = useTranslation();
  return (notification: NotificationsType) => {
    const userName = getUserName(userType, notification.data.personFirstName, notification.data.personLastName);
    const event = formatAdditionalTranslate(notification.event, notification.data?.toManager);
    return t(`notifications:${event}`, {
      personName: userName,
      listingName: notification.data.listingName,
      communityName: notification.data.communityName,
      totalDays: notification.data.totalDays,
      reportedUserName: notification.data.reportedUserName,
      eventName: notification.data.eventName || '',
      amenityName: notification.data.amenityName || '',
      text: notification.data.text || '',
      groupName: notification.data.groupName || '',
      companyName: notification.data.companyName || '',
      documentName: notification.data.documentName || '',
      requestStatus: notification.data.requestStatus || '',
      bookingTime: notification.data.bookingTime || '',
      time: notification.data.time || '',
      numberOfListings: notification.data.numberOfListings || '',
      numberOfItems: notification.data.numberOfItems || '',
      interpolation: {
        escapeValue: false,
      },
    });
  };
};

export const useHasNotifications = (userId?: string) => {
  const notifications = useNotifications(userId).data ?? [];
  for (const n of notifications) {
    if (n.status === NotificationStatus.New) return true;
  }

  return false;
};

export const useUpdateNotification = () => {
  const [update] = useMutation<UpdateNotificationResponse, UpdateNotificationRequest>(UpdateNotification);

  return async (id?: string, fields?: Partial<Notification>) => {
    return (
      await update({
        variables: {
          input: {
            id: id ?? '',
            fields: fields ?? {},
          },
        },
      })
    ).data;
  };
};

export const useArchiveAllNotification = (setLoading?: () => void, currentCommId?: string, onSuccess?: () => void) => {
  const [archive] = useMutation<undefined, ArchiveNotificationsRequest>(ArchiveNotifications);

  const archiveAll = async () => {
    if (!currentCommId) return false;
    setLoading?.();
    const res = await archive({variables: {input: {communityId: currentCommId}}});
    if (res) onSuccess?.();
    return true;
  };
  return {archiveAll};
};

export const useNewViewer = (skip?: boolean) => {
  const [create] = useMutation<NewViewerResponse, NewViewerRequest>(NewViewer);

  return async (id: string, type: 'User' | 'Item' | 'Event' | 'Amenity'): Promise<boolean | undefined> => {
    if (skip) return;
    try {
      const res = await create({
        variables: {
          input: {
            id: id,
            objectType: type,
          },
        },
      });
      return res.data?.newViewer?.success;
    } catch (e) {
      return undefined;
    }
  };
};

export const useNotificationSettings = (objectId: string) => {
  const {data, loading, ...other} = useQuery<GetNotificationSettingsResponse, GetNotificationSettingsRequest>(
    GetNotificationSettings,
    {
      variables: {
        id: objectId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const mappedData = toStateNotificationSettings(data);
  return {data: mappedData, loading, ...other};
};

export const useNotifyAboutSuperResident = (skip?: boolean) => {
  const id = useViewerId('objectId');
  const [call] = useMutation<TCreateNoficationResponse, TCreateNoficationRequest>(CreateNoficationQuery);

  useEffect(() => {
    if (skip) return;
    call({
      variables: {
        fields: {
          event: NotificationsEvents.newRequest,
          from: {
            link: id,
          },
        },
      },
    });
  }, []);
};

export type onNotifUpdateT = (params: {
  type: string;
  options: {sms?: boolean; push?: boolean; email?: boolean};
}) => void;

export const useNotificationsAnalytics = () => {
  const community = useRecoilValue(currentCommunity);
  const viewer = useViewer();
  const onNotifUpdate: onNotifUpdateT = (params: {
    type: string;
    options: {sms?: boolean; push?: boolean; email?: boolean};
  }) => {
    const {type, options} = params;
    const obj = {
      firstName: viewer?.firstName,
      lastName: viewer?.lastName,
      userEmail: viewer?.email,
      userId: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type,
      notificationType: type,
      email: options?.email,
      push: options?.push,
      sms: options?.sms,
    };
    return analyticsTrackFN('Notifications Updated', obj);
  };
  return onNotifUpdate;
};
