import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Media} from '../../../ui-kit/theme';
import {icon} from '../../../ui-kit/Icon/Svg/types';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {UserPostInfo} from '../../../types/community';
import defaultAvatar from '../../common/PostInputs/assets/defaultAvatar.png';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {inputPostType} from '../../common/PostInputs/types';
import {
  Avatar,
  AvatarWrapper,
  ChangePostTypeIcon,
  IconSvg,
  InnerMediaBtn,
  InputBaseContainer,
  InputLayer,
  InputTopWrapper,
  LoopInput,
  TextMediaBtn,
  UsersMenuWrapper,
  WrapperMediaBtns,
} from './styles';
import {MobileMenuButton} from '../../../ui-kit/Button/MobileMenuButton';
import {MobileMenuEntry} from '../../../ui-kit/Menu/MobileMenu';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';

type TButton = {
  iconType: string;
  text: string;
  callback?: () => void;
};

type TButtonData = {
  iconType: string;
  text: string;
  postType: inputPostType;
};

const dataButtons: TButtonData[] = [
  {
    iconType: 'clipboard',
    text: 'community:loop.buttons.notice',
    postType: inputPostType.announcement,
  },
  {
    iconType: 'previous',
    text: 'community:loop.buttons.request',
    postType: inputPostType.request,
  },
  {
    iconType: 'poll',
    text: 'community:loop.buttons.poll',
    postType: inputPostType.poll,
  },
  /*{
    iconType: 'video',
    text: 'community:loop.buttons.video',
    postType: inputPostType.video,
  },*/
  // {
  //   iconType: 'happy-face',
  //   text: 'community:loop.buttons.emote',
  //   postType: inputPostType.none,
  // },
];

const MediaButton: React.FC<TButton> = ({iconType, text, callback}) => {
  return (
    <InnerMediaBtn onClick={callback}>
      <IconSvg type={iconType as icon} stroke={'darkgray'} />
      <TextMediaBtn>{text}</TextMediaBtn>
    </InnerMediaBtn>
  );
};

type TProps = {
  user: UserPostInfo;
  handleChangePostType: (t: inputPostType) => void;
  ManagerOrAdmin: boolean;
  menuOptions: MenuItemType[];
  canPostAsAnnouncement?: boolean;
};

export const BaseInput: React.FC<TProps> = ({
  user,
  handleChangePostType,
  menuOptions,
  ManagerOrAdmin,
  canPostAsAnnouncement,
}) => {
  const {t} = useTranslation();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleChangePostType(inputPostType.message);
  };
  const handleOpenPostInput = (type: inputPostType) => () => handleChangePostType(type);
  const formattedDataButtons = useMemo(
    () => dataButtons.filter((el) => (canPostAsAnnouncement ? true : el.postType !== inputPostType.announcement)),
    [dataButtons, canPostAsAnnouncement],
  );
  const mobMenuOptions: MobileMenuEntry[] = menuOptions.map((o) => ({
    title: o.title ?? '',
    onClick: () => o.onClick?.(''),
    type: o.render as 'regular' | 'danger',
  }));
  return (
    <InputBaseContainer>
      <InputTopWrapper base={true}>
        <AvatarWrapper>
          <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
          {ManagerOrAdmin && (
            <HiddenElement mq={Media.down.l}>
              <ChangePostTypeIcon>
                <UsersMenuWrapper>
                  <HiddenElement mq={Media.down.s}>
                    <MenuButton
                      item={{objectId: user.postAs}}
                      menu={Menu}
                      options={menuOptions}
                      right={false}
                      alwaysShowBtn={true}
                      customIcon={<IconSvg type={'chevrone-down'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />}
                    />
                  </HiddenElement>
                  <HiddenElement mq={Media.up.m}>
                    <MobileMenuButton
                      isPortal={true}
                      entries={mobMenuOptions}
                      portalProvider={IsModalProvider}
                      customIcon={<IconSvg type={'chevrone-down'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />}
                    />
                  </HiddenElement>
                </UsersMenuWrapper>
              </ChangePostTypeIcon>
            </HiddenElement>
          )}
        </AvatarWrapper>
        <InputLayer>
          <LoopInput
            placeholder={t('community:loop.placeholderInput')}
            onChange={handleInput}
            onClick={handleOpenPostInput(inputPostType.message)}
            value={''}
          />
          <IconSvg type="pencil-2" stroke="gray" />
        </InputLayer>
        <WrapperMediaBtns>
          {formattedDataButtons?.map((it) => (
            <MediaButton
              key={it.iconType}
              iconType={it.iconType}
              text={t(it.text)}
              callback={handleOpenPostInput(it.postType)}
            />
          ))}
        </WrapperMediaBtns>
      </InputTopWrapper>
    </InputBaseContainer>
  );
};

type TGroupProps = {
  user: UserPostInfo;
  handleChangePostType: (t: inputPostType) => void;
};

export const BaseGroupInput: React.FC<TGroupProps> = ({user, handleChangePostType}) => {
  const {t} = useTranslation();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleChangePostType(inputPostType.message);
  };
  const handleOpenPostInput = (type: inputPostType) => () => handleChangePostType(type);

  return (
    <InputBaseContainer>
      <InputTopWrapper base={true}>
        <AvatarWrapper>
          <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
        </AvatarWrapper>
        <InputLayer>
          <LoopInput
            placeholder={t('community:loop.placeholderInput')}
            onChange={handleInput}
            onClick={handleOpenPostInput(inputPostType.message)}
            value={''}
          />
          <IconSvg type="pencil-2" stroke="gray" />
        </InputLayer>
        <WrapperMediaBtns>
          <MediaButton
            iconType={'poll'}
            text={t('community:loop.buttons.poll')}
            callback={handleOpenPostInput(inputPostType.poll)}
          />
        </WrapperMediaBtns>
      </InputTopWrapper>
    </InputBaseContainer>
  );
};
