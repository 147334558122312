import {gql} from '@apollo/client';
import {GQLAppFile, GQLAppFileWhereInput, GQLUpdateAppFileFieldsInput} from '../graphql.schema';
import {Connection, FileInfo} from './types/parse';
import {User} from './types/user';
import {AppFileFragment} from './fragments/file';

export const CreateAppFile = gql`
  mutation createAppFile($fields: CreateAppFileFieldsInput) {
    createAppFile(input: {fields: $fields}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface GetFilesRequestType {
  where: GQLAppFileWhereInput;
}

export interface GetFilesResponseType {
  appFiles: Connection<GQLAppFile>;
}

export const getAppFile = gql`
  query getAppFile($where: AppFileWhereInput) {
    appFiles(where: $where) {
      edges {
        node {
          ...AppFileFragment
        }
      }
    }
  }
  ${AppFileFragment}
`;

export interface CreateFileResponseType {
  createAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string; id: string; __typename: string};
  };
}

export const UpdateAppFile = gql`
  mutation updateAppFile($id: ID!, $fields: UpdateAppFileFieldsInput) {
    updateAppFile(input: {id: $id, fields: $fields}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface UpdateFileResponseType {
  updateAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string; __typename: string};
  };
}

export interface UpdateFileRequestType {
  id: string;
  fields: GQLUpdateAppFileFieldsInput;
}

export const DeleteAppFile = gql`
  mutation deleteAppFile($id: ID!) {
    deleteAppFile(input: {id: $id}) {
      appFile {
        ...AppFileFragment
      }
    }
  }
  ${AppFileFragment}
`;

export interface DeleteFileResponseType {
  deleteAppFile: {
    appFile: {file: FileInfo; Owner: User; objectId: string};
  };
}

export interface DeleteFileRequestType {
  id: string;
}
