import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  CommItem,
  CommItemAvatar,
  CommItemDescr,
  CommItemName,
  CommItemRightPart,
  ErrorMessage,
  FieldWrapper,
  InnerAuth,
  StyledTextArea,
  SubtitleWrapper,
  TextButton,
  TitleAuth,
  CommItemLeftPart,
  JoinButton,
  SubtitleAuth,
} from './styles';
import {validateEmail} from '../../helpers/validations';
import {TCommunity} from '../../types/community';
import defaultAvatar from '../../ui-kit/Avatar/assets/avatar-default.png';
import {Button} from '../../ui-kit/Button/Button';
import {SimpleTagInput} from '../../ui-kit/Form';

import {sendEmailType} from '../../hooks/community';

type FoundedCommunityProps = {
  communities?: Partial<TCommunity>[];
  onJoin: (id: string) => void;
  onClickSecond?: () => void;
};

export const FoundedCommunity: React.FC<FoundedCommunityProps> = ({communities, onJoin, onClickSecond}) => {
  const {t} = useTranslation();
  const title = t('auth:foundCommunity.founded.title');
  const subTitleOneCom = t('auth:foundCommunity.founded.subTitleOneCom');
  const subTitleMoreThenOneCom = t('auth:foundCommunity.founded.subTitleMoreThenOneCom');
  const btn = t('auth:foundCommunity.founded.join');
  const btnSecond = t('auth:foundCommunity.founded.joinRequest');
  const btnText = t('auth:foundCommunity.founded.secondButton');
  return (
    <InnerAuth>
      <TitleAuth>{title}</TitleAuth>
      <SubtitleAuth>{communities && communities?.length > 1 ? subTitleMoreThenOneCom : subTitleOneCom}</SubtitleAuth>
      {communities?.map((el) => (
        <CommItem key={el?.objectId}>
          <CommItemLeftPart>
            <CommItemAvatar src={el?.avatar || defaultAvatar} width="57px" height="57px" />
            <CommItemRightPart>
              <CommItemName>{el?.address}</CommItemName>
              <CommItemDescr>
                {el?.city?.name}, {el?.zip}
              </CommItemDescr>
            </CommItemRightPart>
          </CommItemLeftPart>
          <JoinButton type="button" onClick={() => onJoin(el.id || '')}>
            {el.accessType === 'public' ? btn : btnSecond}
          </JoinButton>
        </CommItem>
      ))}
      <TextButton onClick={onClickSecond}>{btnText}</TextButton>
    </InnerAuth>
  );
};

type NotFoundedProps = {
  onClickSecond?: () => void;
  sendEmail: sendEmailType;
  personFirstName: string;
};

export const NotFounded: React.FC<NotFoundedProps> = ({onClickSecond, sendEmail, personFirstName}) => {
  const {t} = useTranslation();
  const title = t('auth:foundCommunity.notFound.title');
  const subTitle = t('auth:foundCommunity.notFound.subTitle');
  const continueTo = t('auth:foundCommunity.notFound.continue');
  const requestField = t('auth:foundCommunity.notFound.requestField');
  const emailField = t('auth:foundCommunity.notFound.emailField');
  const defaultLetter = t('auth:foundCommunity.notFound.defaultLetter', {personFirstName});
  const [invitation, setInvitation] = useState(defaultLetter);
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState<boolean | undefined>();

  const handleInvitationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setInvitation(e.target.value);
  const handleEmailChange = ([email]: Array<string>) => {
    setEmail(email || '');
  };
  const handleSendInvitation = () => {
    if (email.trim() && invitation.trim() && !hasError) {
      sendEmail(email, invitation).then((data) => setSendSuccess(data));
    } else {
      setSendSuccess(false);
    }
  };

  const buttonLabel =
    sendSuccess === undefined
      ? t('community:request.buttonSend')
      : sendSuccess
      ? t('community:request.sendSuccess')
      : t('community:request.sendFail');

  return (
    <InnerAuth>
      <TitleAuth>{title}</TitleAuth>
      <SubtitleWrapper>{subTitle}</SubtitleWrapper>
      <FieldWrapper>
        <StyledTextArea value={invitation} onChange={handleInvitationChange} label={requestField} />
      </FieldWrapper>
      <FieldWrapper>
        <SimpleTagInput
          values={[email].filter(Boolean)}
          onChange={handleEmailChange}
          validator={validateEmail}
          label={emailField}
          disabled={Boolean(email)}
          setHasError={setHasError}
        />
      </FieldWrapper>
      {hasError && <ErrorMessage>{t('error:email')}</ErrorMessage>}
      <Button onClick={handleSendInvitation}>{buttonLabel}</Button>
      <TextButton onClick={onClickSecond}>{continueTo}</TextButton>
    </InnerAuth>
  );
};
