import styled, {css} from 'styled-components';

interface TTagInputContainer {
  notValidItems?: Array<number>;
}

const getSelectorNotValidItems = ({notValidItems}: Required<TTagInputContainer>) =>
  notValidItems.map((el) => `&:nth-child(${el})`).join(', ');

const errorTagStyles = ({notValidItems}: any) =>
  notValidItems?.length &&
  css`
    ${getSelectorNotValidItems} {
      background-color: ${({theme}) => theme.palette.common.redLight2};
    }
  `;

export const TagInputContainer = styled.div<TTagInputContainer>`
  width: 100%;

  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .ReactTags__tagInput {
    flex-grow: 1;
  }
  .ReactTags__tagInputField {
    border: 0;
    outline: none;
    font-size: 14px;
    width: 100%;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.octonary};
    background-color: initial !important;
    ${({theme}) => theme.font.quaternary.regular};
  }
  .ReactTags__remove {
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    font-size: 18px;
    color: ${({theme}) => theme.palette.text.octonary};
    background-color: transparent;
    cursor: pointer;
  }
  .ReactTags__tag {
    position: relative;
    display: inline-flex;
    height: 27px;
    align-items: center;
    padding: 0 24px 0 8px;
    border-radius: 12px;
    background-color: ${({theme}) => theme.palette.background.septenary};

    font-size: 14px;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.octonary};
    ${({theme}) => theme.font.quaternary.regular};

    ${errorTagStyles}
  }

  label.fieldset-box__label {
    z-index: 1;
  }
`;
