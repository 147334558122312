import {useEffect, useRef, useState} from 'react';
import {dequal} from 'dequal';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {AddAddressProps} from './../components/Settings/Community/CommunitySettings';
import {useMutation, useQuery} from '@apollo/client';
import {useCommunityUpdate, useGetCommunity} from './community';
import {useFormError} from './error';
import {useGetLanguages, useSetUserLang} from './language';
import {Country} from './locations';
import {useViewerId} from './user';
import {langLabel} from '../constants/lang';
import {toStateCommunity} from '../helpers/community';
import {localeType} from '../libs/i18nextUtils';
import {ViewerQuery, ViewerResponseType} from '../queries/auth';
import {
  GetManagersList,
  GetManagersListRequestType,
  GetManagersListResponseType,
  UpdateCommunityQuery,
  UpdateCommunityRequestType,
  UpdateCommunityResponseType,
} from '../queries/community';
import {Location} from '../queries/types/locations';
import {UpdateProfile, UpdateProfileRequestType, UpdateProfileResponseType} from '../queries/user';
import {currentCommunity} from '../states/community';
import {userState} from '../states/session';
import {addAddressContainer} from '../types/community';
import {AdditionalAddressFields, FormEditType, FormOptions, FormType, LabelValuePair} from '../types/settings';

export const useUpdateProfileForm = <TValues extends Partial<TValidation>, TValidation>(
  options?: FormOptions<TValues>,
): FormType<TValues> => {
  const updateUser = useSetRecoilState(userState);
  const [values, setValues] = useState<Partial<TValues>>(options?.initialState ?? {});
  const [error, setError] = useFormError<TValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const loadingRef = useRef<boolean>(Boolean(options?.loading));

  useEffect(() => {
    if (loadingRef.current && !dequal(values, options?.initialState)) {
      setValues(options?.initialState ?? {});
      loadingRef.current = false;
    }
  }, [options?.initialState, options?.loading]);

  const userId = useViewerId();
  const [ChangeProfileRequest, {loading}] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(
    UpdateProfile,
  );

  const onChange = (next: {name: string; value: NonNullable<TValues[keyof TValues]>}) => {
    setError(null);
    setSuccess(null);
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const onSubmit = async (id?: string, skipValidation?: boolean): Promise<boolean> => {
    if (!userId && !id) return false;

    try {
      if (options?.validationSchema && !skipValidation) {
        if (
          !options.validationSchema.validate<TValidation>({
            ...values,
          })
        ) {
          console.log('fail', values);
          return false;
        }
      }
      const _values = {
        ...values,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        firstName: values?.firstName?.trim(),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastName: values?.lastName?.trim(),
      };
      const fields = {..._values};

      // this need to exclude writing email field, because after rewriting, parse require verify it again

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (fields?.email && fields.email === options?.initialState?.email) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete fields.email;
      }

      const response = await ChangeProfileRequest({
        variables: {id: userId || id || '', fields},
      });

      const updatedUser = response?.data?.updateUser?.user;
      if (!updatedUser) throw new Error();
      updateUser(updatedUser);
      setSuccess('Profile saved successfully');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {values, onSubmit, success, loading, onChange, error, setError};
};

const getAllZip = (data: AddAddressProps[]) => {
  const allZip = [];
  for (const el of data) {
    allZip.push(el.zip?.trim());
  }
  return allZip;
};

interface WithId {
  id: string;
}

export const useUpdateAddressCommForm = (
  options: FormOptions<AdditionalAddressFields>,
): FormType<AdditionalAddressFields, NonNullable<AdditionalAddressFields[keyof AdditionalAddressFields]>> => {
  const [values, setValues] = useState<Partial<AdditionalAddressFields>>(options.initialState || {});
  const [error, setError] = useFormError<AdditionalAddressFields>();
  const [success, setSuccess] = useState<string | null>(null);
  const [, setCommunity] = useRecoilState(currentCommunity);
  const {data: stateCommunity} = useGetCommunity({id: options.initialState?.id});

  const {update} = useCommunityUpdate();
  const handleChange = (next: {
    name: string;
    value: NonNullable<AdditionalAddressFields[keyof AdditionalAddressFields]>;
  }) => {
    setError(null);
    setSuccess(null);
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  useEffect(() => {
    if (!options.initialState?.id) return;
    setValues(options.initialState);
  }, [options.initialState?.id]);

  const onSubmit = async (): Promise<boolean> => {
    try {
      if (options?.validationSchema) {
        if (!options.validationSchema.validate<Partial<AdditionalAddressFields>>(values)) {
          return false;
        }
      }
      const {id, zip, unit, address, idCard, itemId} = values;

      if (!id || !zip || !unit || !address || !idCard) {
        throw new Error('error:id');
      }
      const addresses = stateCommunity?.address_add?.map((item: addAddressContainer) => item?.value) || [];
      if (itemId === undefined) {
        const allZip = getAllZip(addresses);

        const response = await update({
          id,
          fields: {
            address_add: [...addresses, {id: idCard, zip: zip?.trim(), unit, address}],
            zip_add: [...allZip, zip?.trim()],
          },
        });
        const data = response?.data?.updateCommunity?.community;
        if (!data) throw new Error();
        setCommunity(toStateCommunity(data));
      }
      if (itemId) {
        const findInd = addresses?.findIndex((item: AddAddressProps) => item.id === String(itemId));
        const editItem = {id: String(itemId), zip, unit, address};
        addresses[findInd] = editItem;
        const allZip = getAllZip(addresses);

        const response = await update({
          id,
          fields: {
            address_add: [...addresses],
            zip_add: [...allZip, zip],
          },
        });
        const data = response?.data?.updateCommunity?.community;
        if (!data) throw new Error();
        setCommunity(toStateCommunity(data));
      }

      setSuccess('success:createCommunity');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    // loading,
    onChange: handleChange,
    error,
    setError,
  };
};

export const useUpdateCommunityForm = <TValues extends WithId, TValidation extends Partial<TValues>>(
  options: FormOptions<TValues>,
): FormType<TValues, NonNullable<TValues[keyof TValues]>> => {
  const [values, setValues] = useState<Partial<TValues & {options?: Record<string, boolean>}>>(
    options.initialState ?? {},
  );
  const [error, setError] = useFormError<TValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const setCommunity = useSetRecoilState(currentCommunity);
  const [ChangeCmntRequest, {loading}] = useMutation<UpdateCommunityResponseType, UpdateCommunityRequestType>(
    UpdateCommunityQuery,
  );
  const handleChange = (next: {name: string; value: NonNullable<TValues[keyof TValues]>; isOptions?: boolean}) => {
    setError(null);
    setSuccess(null);
    if (next?.isOptions)
      return setValues((prev) => ({...prev, options: {...(prev?.options || {}), [next.name]: next.value}}));
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  useEffect(() => {
    if (!options.initialState?.id) return;
    setValues(options.initialState);
  }, [options.initialState?.id]);

  const onSubmit = async (): Promise<boolean> => {
    try {
      if (options?.validationSchema) {
        if (!options.validationSchema.validate<TValidation>(values)) {
          return false;
        }
      }

      if (!values.id) {
        throw new Error('error:id');
      }
      const {id, ...fields} = values;
      if (!id) throw new Error();
      const response = await ChangeCmntRequest({
        variables: {id: id, fields},
      });

      const data = response?.data?.updateCommunity?.community;
      if (!data) throw new Error();
      setCommunity(toStateCommunity(data));

      setSuccess('success:createCommunity');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
    setError,
  };
};

export const useFormEdit = (initialState?: boolean): FormEditType => {
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(initialState));
  const handleEditStart = () => setIsEditing(true);
  const handleEditEnd = () => setIsEditing(false);

  return {isEditing, handleEditStart, handleEditEnd};
};

export const toOptions = (source?: Location): Partial<LabelValuePair> => ({label: source?.name, value: source?.id});

export interface ChangeLocationType {
  country: Partial<LabelValuePair>;
  state: Partial<LabelValuePair>;
  city: Partial<LabelValuePair>;
  handleChangeDropdown: (name: 'city' | 'state' | 'country') => (values: LabelValuePair[]) => void;
  setDefoultCountry: (value: Country) => void;
}

export const useChangeLocation = (initialState?: Location): ChangeLocationType => {
  const [city, setCity] = useState<Partial<LabelValuePair>>({});
  const [state, setState] = useState<Partial<LabelValuePair>>({});
  const [country, setCountry] = useState<Partial<LabelValuePair>>({});
  const handleChangeDropdown = (name: 'city' | 'state' | 'country') => (values: LabelValuePair[]) => {
    const value = values[0] ?? {value: '', label: ''};
    if (name === 'country' && country?.value === value?.value) return;
    if (name === 'state' && state?.value === value?.value) return;
    if (name === 'city' && city?.value === value?.value) return;
    switch (name) {
      case 'country':
        setCountry(value);
        setState({value: '', label: ''});
        setCity({value: '', label: ''});
        break;

      case 'state':
        setState(value);
        setCity({value: '', label: ''});
        break;

      case 'city':
        setCity(value);
        break;
    }
  };

  const setDefoultCountry = (value: Country) => {
    setCountry(value);
  };

  useEffect(() => {
    if (!initialState) return;
    const {Country, State} = initialState;
    setCountry(toOptions(Country));
    setState(toOptions(State));
    setCity(toOptions(initialState));
  }, [!!initialState]);

  return {city, state, country, handleChangeDropdown, setDefoultCountry};
};

export type OptionObj = {
  label: string;
  value: string;
};

type LangAndCurrencySettings = {
  lang?: OptionObj;
  currency?: OptionObj;
};

export interface ISettingLangAndCurrency {
  values: LangAndCurrencySettings;
  submit: () => void;
  change: ({key, value}: {key: keyof LangAndCurrencySettings; value: OptionObj}) => void;
  langOptions: Array<OptionObj>;
  setToInitial: () => void;
}

export const useSettingLangAndCurrency = (): ISettingLangAndCurrency => {
  const {i18n} = useTranslation();
  const {setLang} = useSetUserLang();
  const languages = useGetLanguages();

  const [values, setValues] = useState<LangAndCurrencySettings>({
    lang: {
      label: langLabel[i18n.language as localeType],
      value: i18n.language,
    },
  });

  /*const defaultOptions: Array<OptionObj> = langOptions.map((el) => ({
    label: langLabel[el],
    value: el,
  }));*/
  const [options, setOptions] = useState<OptionObj[]>([]);

  const submit = () => {
    setLang(values.lang?.value as localeType);
  };

  const change = ({key, value}: {key: keyof LangAndCurrencySettings; value: OptionObj}) => {
    setValues((prev) => ({...prev, [key]: value}));
  };

  const setToInitial = () => {
    setValues({
      lang: {
        label: langLabel[i18n.language as localeType],
        value: i18n.language,
      },
    });
  };
  useEffect(() => {
    const newOptions = languages?.data?.map((el) => ({
      label: langLabel?.[el.code as localeType] || '',
      value: el.code,
    }));
    setOptions(newOptions || []);
  }, [languages.data?.length]);

  useEffect(() => {
    languages.refetch();
  }, []);

  return {values, submit, change, langOptions: options, setToInitial};
};

export type OptionBoolObj = {
  label: string;
  value: boolean;
};

type ProfileViewOffValues = {enabled: OptionBoolObj};

export interface ISettingProfileViewOff {
  values: ProfileViewOffValues;
  submit: () => void;
  change: ({key, value}: {key: keyof ProfileViewOffValues; value: OptionBoolObj}) => void;
  enabledOptions: Array<OptionBoolObj>;
}

export enum ProfileViewSwitcher {
  enabled = 'enabled',
  disabled = 'disabled',
}

export const profileViewOption = (b: boolean) => (b ? ProfileViewSwitcher.disabled : ProfileViewSwitcher.enabled);

export const useSettingProfileView = (): ISettingProfileViewOff => {
  const {t} = useTranslation();
  const {refetch} = useQuery<ViewerResponseType>(ViewerQuery, {fetchPolicy: 'network-only', skip: true});
  const {update} = useSetProfileViews();
  const [values, setValues] = useState<{enabled: OptionBoolObj}>({
    enabled: {
      label: t(`settings:preference.profileView.disabled`),
      value: true,
    },
  });
  const options = [
    {label: t('settings:preference.profileView.disabled'), value: true},
    {
      label: t('settings:preference.profileView.enabled'),
      value: false,
    },
  ];

  const submit = () => {
    update(values.enabled.value);
  };

  const change = ({key, value}: {key: keyof ProfileViewOffValues; value: OptionBoolObj}) => {
    setValues((prev) => ({...prev, [key]: value}));
  };

  useEffect(() => {
    const getData = async () => {
      const res = await refetch();
      if (!res?.data?.viewer?.user?.objectId) return;
      setValues({
        enabled: {
          label: t(`settings:preference.profileView.${profileViewOption(!!res?.data?.viewer?.user?.profileViewOff)}`),
          value: !!res?.data?.viewer?.user?.profileViewOff,
        },
      });
    };
    getData();
  }, []);

  return {values, submit, change, enabledOptions: options};
};

export const useSetProfileViews = () => {
  const user = useRecoilValue(userState);
  const [updateProfile] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(UpdateProfile);

  const update = async (value: boolean) => {
    try {
      if (!user?.objectId) return;

      await updateProfile({
        variables: {
          id: user.objectId,
          fields: {
            profileViewOff: value,
          },
        },
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  return {
    update,
  };
};

export const useGetManagersInfo = () => {
  const [getInfo] = useMutation<GetManagersListResponseType, GetManagersListRequestType>(GetManagersList);

  return async (commId?: string) => {
    if (!commId) return [];
    const res = await getInfo({variables: {communityId: commId}});
    return res.data?.getManagersList;
  };
};
