import React from 'react';
import {DatePickerInput, DateRangePickerWrapper} from '../styles';
import {RangeDatePickerValues} from '../types';
import {useGetLanguage} from '../../utils/language';
import {PricePeriodType} from '../../RequestCard/types';

type DateRangePickerProps = {
  values: RangeDatePickerValues[];
  setValues: (t: RangeDatePickerValues[]) => void;
  closeDropdown?: (e?: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'default' | undefined | false;
  excludeDates?: Date[];
  pricePeriod?: PricePeriodType | null;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  values = [null, null],
  setValues,
  size,
  excludeDates,
  pricePeriod,
}) => {
  const getLanguage = useGetLanguage();

  const [startDate, endDate] = values;
  const onChange = (update: [Date, Date]) => {
    if (update[0] && update[0] === update[1]) {
      setValues([update[0], null]);
    } else {
      setValues(update);
    }
  };
  const getMinDateAfterRefDate = (dateArray: Date[] | undefined, refDate: Date) => {
    let minDate: Date | null = null;
    if (dateArray !== undefined && dateArray.length > 0) {
      dateArray.forEach((date) => {
        if ((minDate === null || minDate > date) && date > refDate) {
          minDate = date;
        }
      });
    }
    return minDate;
  };
  const getAbsoluteDays = (date: RangeDatePickerValues) => {
    return date === undefined || date === null ? -1 : Math.trunc(date?.getTime() / (1000 * 60 * 60 * 24));
  };
  const selectableDates = (
    date: Date,
    selectedStartDate: RangeDatePickerValues | undefined,
    selectedEndDate: RangeDatePickerValues | undefined,
    preSelectedDates: Date[] | undefined,
  ) => {
    if (
      selectedStartDate === undefined ||
      selectedStartDate === null ||
      (selectedStartDate !== null && selectedEndDate !== null) ||
      date.getTime() < selectedStartDate?.getTime()
    ) {
      return true;
    }
    const maxSelectedDate = getMinDateAfterRefDate(preSelectedDates, selectedStartDate);
    const absDate = getAbsoluteDays(date);
    const absStartDate = getAbsoluteDays(selectedStartDate);
    const absMaxSelectedDate = maxSelectedDate === null ? -1 : getAbsoluteDays(maxSelectedDate);
    if (absMaxSelectedDate !== -1 && absMaxSelectedDate < absDate) {
      return false;
    } else if (pricePeriod === PricePeriodType.week) {
      return (absDate - absStartDate) % 7 === 6;
    } else if (pricePeriod === PricePeriodType.month) {
      return (absDate - absStartDate) % 30 === 0;
    }
    return true;
  };
  return (
    <DateRangePickerWrapper size={size}>
      <DatePickerInput
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        selectsRange
        inline
        filterDate={(date) => selectableDates(date, startDate, endDate, excludeDates)}
        excludeDates={excludeDates}
        minDate={new Date()}
        dateFormat={getLanguage()}
      />
    </DateRangePickerWrapper>
  );
};
