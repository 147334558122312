import React, {useMemo, useState} from 'react';
import {
  ButtonWrapper,
  CardContentWrapper,
  CardInfoText,
  CardTitle,
  CardWrapper,
  DateWrapperCard,
  ErrorMessage,
  NotAvailable,
} from '../../../ui-kit/RequestCard/styles';
import {TEvent} from '../../../types/event';
import {ButtonLabelEntry, PickDateTypes} from '../../../ui-kit/RequestCard/types';
import {EventRepeatType, RewardType} from '../../../queries/types/event';
import {
  AttendingLabel,
  CardTitleWrapper,
  EventDatePickerRow,
  EventInfoDescr,
  LabelsWrapper,
  RecurringModalWrapper,
  ReqButton,
  RMButton,
  RMButtons,
  RMDescr,
  RMTitle,
} from './styles';
// import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {CounterInput} from '../../common/CounterInput';
import {useTranslation} from 'react-i18next';
import {variant} from '../../../ui-kit/Labels/types';
import {orderEventChangeData} from '../../../hooks/event';
import {
  BottomLinks,
  ButtonLink,
  EventInfoTitle,
  InnerEventView,
  ListingViewDescr,
  SuccessEventMessageWrapper,
  SuccessEventText,
} from '../styles';
import {DateTimePicker} from '../../../ui-kit/RequestCard/DateBlock/DateTimePicker';
import {getRecurringDates} from '../../../helpers/event';
import {RoundModal} from '../../common/Modal/RoundModal';
import {useGetLanguage} from '../../../ui-kit/utils/language';
import {optionsI18n} from '../../../libs/i18nextUtils';
import {Label} from '../../../ui-kit/Labels';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {Media} from '../../../ui-kit/theme/breakpoints';

type EventCardProps = {
  event?: Partial<TEvent>;
  tags: {type: variant; label: string}[];
  successOrder?: string | null;
  requestSent?: string;
  pathLink?: string;
  linkTitle?: string;
  isAvailable?: boolean;
  message?: {
    error?: string | null;
    success?: string | null;
  };
  buttonLabel?: ButtonLabelEntry;
  descr?: string;
  notAvailableText?: string;
  orderChangeData?: orderEventChangeData;
  attendees?: number;
  isDisabled?: boolean;
  isPublicView?: boolean;
  goSignUp?: () => void;
  actualDate?: Date;
};

export const EventRequestCard: React.FC<EventCardProps> = ({
  isAvailable,
  event,
  pathLink,
  successOrder,
  linkTitle,
  message,
  buttonLabel,
  descr,
  notAvailableText,
  orderChangeData,
  isDisabled,
  isPublicView,
  goSignUp,
  tags,
  actualDate,
}) => {
  const {t} = useTranslation();
  const eventType = event?.rewardType;
  const isRecurring = event?.eventType ? event.eventType !== EventRepeatType.one : false;
  const reqDisabled = isPublicView
    ? false
    : (event?.allowGuests && event?.maxGuestsPerAttendee ? (orderChangeData?.guests || 0) < 1 : false) ||
      (isRecurring && !orderChangeData?.date);
  const pickerPlaceholders = {addDate: t('requests:addDate')};
  const pickerLabels = {labelDate: t('requests:labelDate')};
  const availableDates = useMemo(() => getRecurringDates(event?.eventType, event?.startTime), [event?.eventType]);
  const [modalActive, setModalActive] = useState(false);
  const onRequest = () => {
    if (!isRecurring && isAvailable) {
      return buttonLabel?.onClick?.();
    }
    if (isRecurring && isAvailable) {
      setModalActive(true);
    }
  };
  const closeModal = () => setModalActive(false);
  const submitNotRecurringOrder = () => {
    buttonLabel?.onClick?.();
    closeModal();
  };
  const submitRecurringOrder = () => {
    buttonLabel?.onClick?.({isRecurring: true});
    closeModal();
  };
  const onReqClick = (e: React.SyntheticEvent) => {
    if (isPublicView) {
      e.preventDefault();
      e.stopPropagation();
      goSignUp?.();
    }
  };
  const getLang = useGetLanguage();
  const {interpolation} = optionsI18n;
  const f = interpolation.format;
  const timePart1 =
    actualDate && event?.endTime
      ? f(new Date(actualDate), 'iii', getLang()) + ', ' + f(new Date(actualDate), 'PP', getLang())
      : '';

  const timePart2 =
    actualDate && event?.endTime
      ? f(new Date(actualDate), 'p', getLang()) + ' to ' + f(new Date(event?.endTime), 'p', getLang())
      : '';
  const repeatLabel = event?.eventType
    ? event.eventType !== EventRepeatType.one
      ? `(${t(`events:repeatEventType.${event?.eventType}`)})`
      : ''
    : '';
  const attendeesCount = event?.totalAttendees;
  return (
    <CardWrapper padding={10} $isEvent>
      <CardContentWrapper onClick={onReqClick} $noMargin={true}>
        <DateWrapperCard $isAvailable={isDisabled ? false : isAvailable}>
          <HiddenElement mq={Media.down.m}>
            <CardTitleWrapper>
              <CardTitle>{event?.name}</CardTitle>
            </CardTitleWrapper>
            <LabelsWrapper>
              {tags.map((t) => (
                <Label variant={t.type} key={t.label}>
                  {t.label}
                </Label>
              ))}
              <AttendingLabel>{t('events:requests.label.attending', {count: attendeesCount || 0})}</AttendingLabel>
            </LabelsWrapper>
          </HiddenElement>

          {/* <EventInfoRow>
            <EventInfoTitle>{t('events:requests.dateAndTime')}</EventInfoTitle>
            <EventInfoDescr>{timePart1}</EventInfoDescr>
            <EventInfoDescr>{timePart2}</EventInfoDescr>
          </EventInfoRow>

          <EventInfoRow>
            <EventInfoTitle>{t('events:requests.location')}</EventInfoTitle>
            <EventInfoDescr>{event?.locationDetails}</EventInfoDescr>
          </EventInfoRow> */}
          <HiddenElement mq={Media.down.m}>
            <InnerEventView>
              <EventInfoTitle>{t('events:requests.dateAndTime')}</EventInfoTitle>
              <EventInfoDescr>
                {timePart1} {repeatLabel}
              </EventInfoDescr>
              <EventInfoDescr>{timePart2}</EventInfoDescr>
            </InnerEventView>
            {event?.locationDetails && (
              <InnerEventView>
                <EventInfoTitle>{t('events:requests.location')}</EventInfoTitle>
                <ListingViewDescr>{event.locationDetails}</ListingViewDescr>
              </InnerEventView>
            )}
            <InnerEventView />
          </HiddenElement>
          {!successOrder && (
            <>
              {isRecurring && (
                <EventDatePickerRow>
                  <DateTimePicker
                    pickerPlaceholders={pickerPlaceholders}
                    pickerLabels={pickerLabels}
                    pickType={PickDateTypes.fullDate}
                    buttonSelectText={t('requests:buttonSelectText')}
                    date={orderChangeData?.date}
                    setDate={orderChangeData?.setDate}
                    canEdit={isDisabled ? false : true}
                    offsetBottom={10}
                    includeDates={availableDates}
                  />
                </EventDatePickerRow>
              )}
              {eventType === RewardType.free && event?.allowGuests && event?.maxGuestsPerAttendee && (
                <CounterInput
                  min={0}
                  max={event?.maxGuestsPerAttendee}
                  onChange={orderChangeData?.onChangeGuests}
                  value={orderChangeData?.guests}
                  label={t('events:requests.tickets')}
                  placeholder={t('events:requests.selectTickets')}
                />
              )}
            </>
          )}
          {!!message?.success && <SuccessEventMessage text={message.success} />}
          <ErrorMessage open={!!message?.error}>{message?.error}</ErrorMessage>
          {!successOrder && (
            <ButtonWrapper pad={10}>
              <ReqButton
                $isDisabled={reqDisabled}
                disabled={reqDisabled}
                variant={'primary'}
                onClick={onRequest}
                loading={buttonLabel?.loading}>
                {buttonLabel?.label}
              </ReqButton>
            </ButtonWrapper>
          )}
          {!successOrder && <CardInfoText>{descr}</CardInfoText>}
          {successOrder && <BottomLinks>{pathLink && <ButtonLink to={pathLink}>{linkTitle}</ButtonLink>}</BottomLinks>}
          {/*{!successOrder && type === ListingCardType.Request && <PaymentInfo payments={payments} />}*/}
        </DateWrapperCard>
        {!isAvailable && <NotAvailable>{notAvailableText}</NotAvailable>}
      </CardContentWrapper>
      <RoundModal active={modalActive} setActive={closeModal} isPortal={true}>
        <RecurringModalWrapper>
          <RMTitle>{t('events:modals.recurring.title')}</RMTitle>
          <RMDescr>{t('events:modals.recurring.descr')}</RMDescr>
          <RMButtons>
            <RMButton onClick={submitRecurringOrder}>{t('events:modals.recurring.all')}</RMButton>
            <RMButton onClick={submitNotRecurringOrder}>{t('events:modals.recurring.one')}</RMButton>
          </RMButtons>
        </RecurringModalWrapper>
      </RoundModal>
    </CardWrapper>
  );
};

export const SuccessEventMessage: React.FC<{text?: string; bordered?: boolean}> = ({text, bordered = true}) => {
  return (
    <SuccessEventMessageWrapper $bordered={!!bordered}>
      {/* <IconSvg type={'checked-circle'} width={'25px'} height={'25px'} viewBox={'0 0 30 30'} stroke={'aqua'} /> */}
      <SuccessEventText>{text}</SuccessEventText>
    </SuccessEventMessageWrapper>
  );
};
