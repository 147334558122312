import {Link as ReactLink} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {IconSvg} from '../../ui-kit/Icon/Svg';

const stylesText = css`
  font-size: 16px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  color: ${({theme}) => theme.palette.text.septenary};
  ${Media.down.s} {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div<{maxWidth?: string}>`
  box-sizing: border-box;
  padding: 100px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '500px')};
  text-align: center;
  margin: 0 auto;
  ${Media.down.m} {
    padding: 30px 40px;
  }
`;

export const MainText = styled.div`
  font-size: 20px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  color: ${({theme}) => theme.palette.text.quaternary};
  margin-top: 16px;
  margin-bottom: 20px;
  ${Media.down.s} {
    font-size: 18px;
    margin-bottom: 14px;
    margin-top: 10px;
  }
`;

export const SubText = styled.div`
  ${stylesText};
  ${Media.down.s} {
    font-size: 15px;
  }
  & > a {
    ${Media.down.s} {
      font-size: 15px;
    }
  }
`;

export const Link = styled(ReactLink)`
  ${stylesText};
  text-decoration: underline;
  ${Media.down.s} {
    font-size: 15px;
  }
`;

export const Icon = styled(IconSvg).attrs({
  type: 'sign-out',
  viewBox: '0 0  330 330',
})`
  width: 183px;
  height: 183px;
  ${Media.down.s} {
    width: 137px;
    height: 137px;
  }
`;
