import React, {useMemo} from 'react';
import {PostPollProps} from '../../types';
import {PollContainer, PollRow, VotedInfo, VotedInfoPercent, VotedInfoCount} from './styles';
import {CheckInput, RadioInput} from '../../../../../ui-kit/Radio/RadioInput';
import {calcVotesPercent, declOfNum} from '../../../../../helpers/feed';
import {useTranslation} from 'react-i18next';
import {usePollVote} from '../../../../../hooks/feed';

type VoteBlockProps = {
  votesCount?: number;
  percent?: number;
};
const VotedBlock: React.FC<VoteBlockProps> = ({votesCount = 0, percent}) => {
  const {t} = useTranslation();
  const Tvotes = t('community:loop.poll.votes');
  const Tvote = t('community:loop.poll.vote');
  const votes = declOfNum(votesCount, [Tvote, Tvotes, Tvotes]);

  return (
    <VotedInfo>
      <VotedInfoCount>
        {votesCount} {votes}
      </VotedInfoCount>
      <i>|</i>
      <VotedInfoPercent>{percent}%</VotedInfoPercent>
    </VotedInfo>
  );
};

const MemoVotedBlock = React.memo(VotedBlock);

export const PostPoll: React.FC<PostPollProps> = ({
  Options,
  objectId,
  refetch,
  votedIds,
  handleVote,
  isMulti,
  disabled,
}) => {
  if (!Options?.length) return null;

  const onSuccess = () => {
    refetch?.();
  };
  const {votePoll} = usePollVote();
  const handleSetVote = (e: React.ChangeEvent<HTMLInputElement>, optionId?: string) => {
    if (e.target.checked) {
      handleVote(optionId);
      votePoll(objectId, optionId, onSuccess);
    }
  };
  const totalVotes = useMemo(() => Options.reduce((prev, option) => prev + (option?.countVotes || 0), 0), [Options]);
  const isVoted = Boolean(Options.find((op) => op.isVoted)?.objectId);
  const setVoteChange = (id?: string) => (e: React.ChangeEvent<HTMLInputElement>) => handleSetVote(e, id);
  return (
    <PollContainer $isVoted={isVoted} $disabled={disabled}>
      {Options.map((option, i) => (
        <PollRow key={option.objectId || i}>
          {isMulti ? (
            <CheckInput
              onChange={setVoteChange(option.objectId)}
              id={option.objectId || String(i)}
              text={option.text}
              checked={votedIds?.includes(option?.objectId || '')}
            />
          ) : (
            <RadioInput
              onChange={setVoteChange(option.objectId)}
              name={`poll${objectId}`}
              id={option.objectId || String(i)}
              text={option.text}
              checked={votedIds?.includes(option?.objectId || '')}
            />
          )}
          <MemoVotedBlock
            votesCount={Number(option?.countVotes || 0) > 0 ? option.countVotes : 0}
            percent={calcVotesPercent(totalVotes, option?.countVotes)}
          />
        </PollRow>
      ))}
    </PollContainer>
  );
};
