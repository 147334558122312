import React from 'react';
import {TGroup} from '../../types/group';
import {Seo} from '../SEO';
import {WrapperGroupView, GroupContentWrapper} from './styles';
import {LinkBack} from '../common/Buttons/LinkBack';
import {GroupFeedColumn} from './GroupFeedColumn';
import {GroupMembers} from '../../containers/Groups/GroupMembers';

type ViewGroupProps = {
  group?: TGroup;
  isJoined?: boolean;
  onJoin?: () => void;
};

export const ViewGroup: React.FC<ViewGroupProps> = ({group, isJoined, onJoin}) => {
  return (
    <>
      <Seo title={group?.name} />
      <WrapperGroupView>
        <LinkBack type={'groups'} />
        <GroupContentWrapper>
          <GroupFeedColumn group={group} isJoined={isJoined} onJoin={onJoin} />
          <GroupMembers objectId={group?.objectId} isJoined={isJoined} onJoin={onJoin} />
        </GroupContentWrapper>
      </WrapperGroupView>
    </>
  );
};
