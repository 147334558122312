import React from 'react';
import {useTranslation} from 'react-i18next';
import {RewardType} from '../../queries/types/amenity';
import {Label} from '../../ui-kit/Labels';
import {ListingCardTitle, InnerDataPreview, InnerRewardData, InnerLabelItemTitle, InnerLabelCard} from './styles';
import {BookingTimingT} from '../../types/amenity';
import {getOpenedStatus} from '../../helpers/amenity';

export type TLabelCard = {
  name?: string;
  price?: number;
  rewardType?: RewardType;
  withOutName?: boolean;
  bookingTiming?: BookingTimingT;
};

const colorOpenedType: {[key: string]: 'quaternary' | 'quinary'} = {
  open: 'quaternary',
  closed: 'quinary',
};

export const LabelCard: React.FC<TLabelCard> = ({name, price, rewardType, withOutName, bookingTiming}) => {
  const {t} = useTranslation();
  const openedStatus = getOpenedStatus(bookingTiming);
  const bookingStatus = rewardType === RewardType.free ? t('amenities:rewardStatus.free') : '';
  if (!name && !withOutName) return null;

  return (
    <InnerLabelCard>
      {!withOutName && (
        <InnerLabelItemTitle>
          <ListingCardTitle variantText={'quaternary'}>{name}</ListingCardTitle>
        </InnerLabelItemTitle>
      )}

      <InnerDataPreview>
        {openedStatus && (
          <Label variant={colorOpenedType[openedStatus]} squared={true}>
            {t(`amenities:status.${openedStatus}`)}
          </Label>
        )}
        {bookingStatus && <InnerRewardData>{bookingStatus}</InnerRewardData>}

        {Boolean(price) && (
          <>
            {/*<InnerPrice>{getSignValue(price || 0)}</InnerPrice>*/}
            {/*{pricePeriod && pricePeriod !== PricePeriodType.month && (*/}
            {/*  <InnerRewardData>{t(`events:pricePeriod.${pricePeriod}`)}</InnerRewardData>*/}
            {/*)}*/}
          </>
        )}
      </InnerDataPreview>
    </InnerLabelCard>
  );
};
