import {gql} from '@apollo/client';
import {
  GQLCompanyManageAction,
  GQLCompanyOrder,
  GQLCompanyWhereInput,
  GQLCreateCompanyFieldsInput,
  GQLUpdateCompanyFieldsInput,
} from '../graphql.schema';
import {CompanyFragment, CompanyShortFragment} from './fragments/company';
import {Company} from './types/company';
import {Connection} from './types/parse';

export interface TGetLocalBizResponse {
  companies: Connection<Company>;
}

export interface TGetLocalBizRequest {
  where: GQLCompanyWhereInput;
  first?: number;
  last?: number;
  skip?: number;
  after?: string;
  before?: string;
  order?: GQLCompanyOrder;
}

export const GetLocalBizQuery = gql`
  query getCompanies(
    $where: CompanyWhereInput
    $first: Int
    $last: Int
    $skip: Int
    $after: String
    $before: String
    $order: [CompanyOrder!]
  ) {
    companies(where: $where, first: $first, last: $last, skip: $skip, after: $after, before: $before, order: $order) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CompanyShortFragment
        }
      }
    }
  }
  ${CompanyShortFragment}
`;

export interface TCompanyManageRequest {
  companyId: string;
  action: GQLCompanyManageAction;
  communityIdList: Array<string>;
}

export const CompanyManageQuery = gql`
  mutation companyManage($companyId: String!, $action: CompanyManageAction!, $communityIdList: [String]!) {
    companyManageButtons(input: {companyId: $companyId, action: $action, communityIdList: $communityIdList})
  }
`;

export interface TDeleteCompanyRequest {
  id: string;
}

export const DeleteCompanyQuery = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(input: {id: $id}) {
      __typename
    }
  }
`;

export interface TCreateCompanyRequest {
  fields: GQLCreateCompanyFieldsInput;
}

export interface TCreateCompanyResponse {
  createCompany: {
    company: Company;
  };
}

export const CreateCompanyQuery = gql`
  mutation createCompany($fields: CreateCompanyFieldsInput) {
    createCompany(input: {fields: $fields}) {
      company {
        ...CompanyFragment
      }
    }
  }
  ${CompanyFragment}
`;

export interface TUpdateCompanyRequest {
  fields: GQLUpdateCompanyFieldsInput;
  id: string;
}

export interface TUpdateCompanyResponse {
  updateCompany: {
    company: Company;
  };
}

export const UpdateCompanyQuery = gql`
  mutation updateCompany($id: ID!, $fields: UpdateCompanyFieldsInput) {
    updateCompany(input: {id: $id, fields: $fields}) {
      company {
        ...CompanyFragment
      }
    }
  }
  ${CompanyFragment}
`;

export interface IGetCompanyResponse {
  company: Company;
}

export interface IGetCompanyRequest {
  id: string;
}

export const GetCompanyQuery = gql`
  query company($id: ID!) {
    company(id: $id) {
      ...CompanyFragment
    }
  }
  ${CompanyFragment}
`;

export interface TCountCompanyClickRequest {
  id: string;
}

export const CountCompanyClickQuery = gql`
  mutation ($id: String!) {
    countTotalClicks(input: {companyId: $id})
  }
`;
