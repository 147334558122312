import React from 'react';
import {ModalTemplateWrapper, Title, ModalButtonWrapper, ModalTextWrapper, CloseBtn} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

type PropsT = {
  title: string;
  descr?: string | React.ReactNode;
  onClose?: () => void;
  Button?: React.ReactNode;
};

export const ModalTemplate: React.FC<PropsT> = ({title, descr, Button, onClose}) => {
  return (
    <ModalTemplateWrapper>
      <CloseBtn onClick={onClose}>
        <IconSvg type={'close'} width={'40px'} height={'40px'} />
      </CloseBtn>
      <Title>{title}</Title>
      <ModalTextWrapper>{descr}</ModalTextWrapper>
      <ModalButtonWrapper>{Button}</ModalButtonWrapper>
    </ModalTemplateWrapper>
  );
};
