import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  GreetingTitle,
  InnerBoxCards,
  InnerBoxPlan,
  InnerPaymentButton,
  InnerPaymentInfo,
  PaymentChangeButton,
  SubscribeText,
  WrapperForm,
} from '../styles';
import {TOptions} from '../../../../types/community';
import {route} from '../../../../constants/routes';
import {SelectedTPlan} from '../../../../hooks/community';
import {HeaderPlan} from '../SelectPlan/HeaderPlanCard';
import {TAddCardReturn} from '../../../../hooks/payments';
import {TCreditCard} from '../../../../queries/types/payments';
import {PaymentCardContainer as PaymentCard} from '../../../../containers/Account/Payment/Card';
import {AddCreditCard} from './AddCreditCard';
import {PaymentPeriodType} from '../../../../types/payment';
import {useLinks} from '../../../../hooks/common';
import {useHistory} from 'react-router-dom';
import {Button} from '../../../../ui-kit/Button/Button';
import {sortCards} from '../../../../helpers/payment';
import {IGetCurrValue} from '../../../../types/common';

interface IProps {
  options?: TOptions[];
  paymentPlan?: SelectedTPlan;
  cardData: TAddCardReturn;
  cards?: TCreditCard[];
  refetchCards: () => void;
  paymentPeriod?: string;
  activeCardId?: string;
  currency: string;
  getCodeValue: IGetCurrValue;
  getSignValue: IGetCurrValue;
}

export const PaymentDetails: React.FC<IProps> = ({
  options,
  paymentPlan,
  paymentPeriod,
  cardData,
  cards,
  refetchCards,
  activeCardId,
  getCodeValue,
  currency,
  getSignValue,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {push} = useHistory();

  const packageName = t('community:plan.package.community');
  const totalAmount = paymentPeriod === PaymentPeriodType.month ? paymentPlan?.priceMonth : paymentPlan?.priceYear;
  const annualOrMonthly =
    paymentPeriod === PaymentPeriodType.month ? t('community:plan.button.switch.monthly') : t('community:plan.annual');
  const yearlyOrMonthly =
    paymentPeriod === PaymentPeriodType.month ? t('community:plan.monthly') : t('community:plan.yearly');

  const skipCardAdd = () => push(getLink(route.createCommunity.path));

  return (
    <WrapperForm paddingX={'30px'}>
      <GreetingTitle>{t('community:payment.title')}</GreetingTitle>
      <SubscribeText>
        {t('community:payment.descr.text', {
          annualOrMonthly,
          totalAmount,
          yearlyOrMonthly,
          packageName,
          currencyCode: currency,
        })}
      </SubscribeText>

      <InnerBoxPlan>
        <InnerPaymentInfo>
          <HeaderPlan
            name={paymentPlan?.name}
            priceYear={paymentPlan?.currentPriceYear}
            priceMonth={paymentPlan?.currentPriceMonth}
            getCodeValue={getCodeValue}
            currency={currency}
            getSignValue={getSignValue}
          />
        </InnerPaymentInfo>
        <InnerPaymentButton>
          <PaymentChangeButton to={getLink(route.selectPlan.path)}>
            {t('community:payment.button.changePlan')}
          </PaymentChangeButton>
        </InnerPaymentButton>
      </InnerBoxPlan>
      {Boolean(cards?.length) && (
        <InnerBoxCards>
          {sortCards(cards)?.map((it) => (
            <PaymentCard refetchCards={refetchCards} isActive={activeCardId === it.id} key={it.id} card={it} />
          ))}
        </InnerBoxCards>
      )}
      {!cards?.length ? (
        <AddCreditCard cardData={cardData} options={options} />
      ) : (
        <Button onClick={skipCardAdd}>{t('community:payment.button.continue')}</Button>
      )}
    </WrapperForm>
  );
};
