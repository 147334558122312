import React, {useMemo, useState} from 'react';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {LabelCard} from './LabelCard';
import {
  ContainerListingCard,
  HiddenMenu,
  InnerListingCard,
  InnnerListingCardBtns,
  LikeButton,
  MenuButtonWrapper,
  ShapeWrapper,
  UnPublishedLabel,
} from './styles';
import {PhotoLoaderProps} from '../common/PhotoCard/PhotoLoader';
import {useTranslation} from 'react-i18next';
import {TItem} from '../../types/item';
import {getIsIncludesCmnt} from '../../helpers/item';
import {useHasPlanOption} from '../../helpers/community';
import {PlanOptionsValues} from '../../constants/plan';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Media} from '../../ui-kit/theme';
import {getMenuItems, GetMenuItemsFuncOptions, GetMenuItemsOptions, getMobileMenuItems} from '../../helpers/listings';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {entities, reportActions} from '../../hooks/report';
import {Link} from '../../ui-kit/Typography/Link';
import {IGetCurrValue} from '../../types/common';
import {PopupView} from '../common/Modal/PopupView';
import {getResize} from '../../helpers/file';
import {useLocation} from 'react-router-dom';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {User} from '../../queries/types/user';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {LikeState} from '../../hooks/item';

type ListingCard = {
  to: string;
  editPath?: string | null;
  item: Partial<TItem>;
  src?: string;
  communityId?: string;
  hideCallBack?: (itemId?: string) => () => void;
  featureCallBack?: (itemId?: string) => () => void;
  removeFeatureCallBack?: (itemId?: string) => () => void;
  approveCallBack?: (itemId?: string) => () => void;
  unListCallBack?: (itemId?: string) => () => void;
  onPublishCallBack?: (itemId?: string) => () => void;
  isHideLabel?: boolean;
  isManager?: boolean;
  isOwner?: boolean;
  reportActions?: reportActions;
  isCardInPost?: boolean;
  getSignValue: IGetCurrValue;
  lister?: User;
  onLike?: (id: string) => void;
  liked?: LikeState;
  likeDisabled?: boolean;
  avatarRequired?: boolean;
  isPost?: boolean;
};

interface Titles {
  isPublished?: boolean;
  isUpdated?: boolean;
  isAdmHidden?: boolean;
  hasApprovalRequest?: boolean;
  isSold?: boolean;
}

const getTitleLabel = ({isPublished = true, isUpdated, isAdmHidden, hasApprovalRequest, isSold}: Titles) => {
  if (isAdmHidden) {
    return 'listings:card.labels.adminUnPublished';
  }

  if (hasApprovalRequest) {
    return 'listings:card.labels.waitingApproval';
  }

  if (!isPublished) {
    return 'listings:card.labels.unPublished';
  }

  if (isUpdated) {
    return 'listings:card.labels.updated';
  }

  if (isSold) {
    return 'listings:card.labels.sold';
  }

  return '';
};

export const ListingCard: React.FC<PhotoLoaderProps & ListingCard> = ({
  to,
  src,
  item,
  aspectRatio,
  unListCallBack,
  hideCallBack,
  featureCallBack,
  approveCallBack,
  communityId,
  isHideLabel,
  onPublishCallBack,
  removeFeatureCallBack,
  isManager,
  isOwner,
  reportActions,
  getSignValue,
  lister,
  liked,
  onLike,
  likeDisabled,
  avatarRequired,
  isPost,
  isCardInPost,
}) => {
  const {t} = useTranslation();
  const {price, pricePeriod, listingType, name, rewardType, isSold, category} = item;
  const isPublished = item.isPublished;
  const hasApprovalRequest = getIsIncludesCmnt(item?.ApproveReq, communityId);
  const isUpdated = getIsIncludesCmnt(item?.Updated, communityId);
  const isAdmHidden = getIsIncludesCmnt(item?.AdmHidden, communityId);
  const isFeatured = getIsIncludesCmnt(item?.Featured, communityId);
  const canFeature = useHasPlanOption(PlanOptionsValues.featureListings);
  const isLabel = (!isPublished || isUpdated || isAdmHidden || hasApprovalRequest) && !isHideLabel;
  const isOpacity = (!isPublished || isAdmHidden || isSold || hasApprovalRequest) && !isHideLabel;
  const {getLink} = useLinks();
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const preventRedirect = (e: React.SyntheticEvent) => e.preventDefault();
  const {pathname} = useLocation();
  const report = () => {
    setIsShowReportModal(true);
  };

  const getMenuItemsOptions: GetMenuItemsOptions = useMemo(() => {
    return {
      objectId: item?.objectId,
      to: getLink(route.listingViewEdit.get({id: item.id || '/'})),
      isPublished,
      hasApprovalRequest,
      canFeature,
      isAdmHidden,
      isFeatured,
      isOwner: isOwner,
    };
  }, [isOwner, item?.objectId]);

  const getMenuItemsFunctions: GetMenuItemsFuncOptions = {
    t,
    approveCallBack,
    featureCallBack,
    hideCallBack,
    onPublishCallBack,
    removeFeatureCallBack,
    unListCallBack,
  };

  const menuItems: MenuItemType[] =
    isOwner && !(hasApprovalRequest && isManager)
      ? []
      : [
          ...getMenuItems(getMenuItemsOptions, getMenuItemsFunctions),

          ...(hasApprovalRequest
            ? []
            : ([
                {
                  title: t('people:actions.report'),
                  onClick: report,
                  render: 'danger',
                },
              ] as MenuItemType[])),
        ];

  const mobileMenuItems: MobileMenuEntry[] =
    isOwner && !(hasApprovalRequest && isManager)
      ? []
      : [
          ...getMobileMenuItems(getMenuItemsOptions, getMenuItemsFunctions),
          ...(hasApprovalRequest
            ? []
            : ([
                {
                  title: t('people:actions.report'),
                  onClick: report,
                  type: 'danger',
                },
              ] as MobileMenuEntry[])),
        ];

  const reportParams = {
    title: t('common:report.title', {entity: t(`common:entities.${entities.listing}`)}),
    subtitle: t('common:report.subtitle'),
    okButton: t('common:report.confirmReport'),
    textAreaLabel: t('common:report.textAreaLabel'),
    close: () => {
      setIsShowReportModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowReportModal(false);
        setIsShowInfoModal(true);
      };

      return reportActions?.report?.({id: item?.objectId, onSuccess, text});
    },
  };

  const infoParams = useMemo(() => {
    return {
      title: t('common:report.success.title', {entity: t(`common:entities.${entities.listing}`)}),
      subtitle: t('common:report.success.subtitle'),
      close: () => {
        setIsShowInfoModal(false);
      },
    };
  }, []);

  const authorName = lister?.firstName ? lister?.firstName + ' ' + (lister?.firstLetter || '') : '';
  const distance =
    lister?.distance && Number(lister?.distance || 0) < 500
      ? lister?.distance === '0.0'
        ? ` ${t('listings:card.labels.distance', {distance: 0})}`
        : `${t('listings:card.labels.distance', {distance: lister?.distance})}`
      : '';

  const handleLike = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    item?.objectId && onLike?.(item.objectId);
  };

  const isLiked = item?.objectId ? liked?.[item?.objectId] : false;
  const photoSRC = avatarRequired ? (!src ? lister?.Avatar?.file?.url || src : src) : src;
  return (
    <>
      <ContainerListingCard>
        <Link to={{pathname: to, state: pathname?.split('/')?.[2]}}>
          {isLabel && (
            <UnPublishedLabel>
              {t(getTitleLabel({isPublished, isUpdated, isAdmHidden, hasApprovalRequest}))}
            </UnPublishedLabel>
          )}
          <InnerListingCard isOpacity={isOpacity}>
            {photoSRC && (
              <PhotoCard
                aspectRatio={aspectRatio}
                src={getResize(photoSRC, 'md')}
                fallbackSrc={photoSRC}
                isListing={true}
              />
            )}
            {/* {isFeatured && <IconStar type={'star'} fill={'orange'} stroke={'white'} />} */}
            <LabelCard
              rewardType={rewardType}
              price={price}
              pricePeriod={pricePeriod}
              listingType={listingType}
              name={name}
              getSignValue={getSignValue}
              category={category}
              isFeatured={isFeatured}
              authorName={authorName}
              verified={lister?.isVerified}
              isSold={isSold}
              isPost={isPost}
              noPhoto={!photoSRC}
              distance={distance}
            />
          </InnerListingCard>
          <InnnerListingCardBtns>
            <MenuButtonWrapper onClick={preventRedirect} $isCardInPost={Boolean(isCardInPost)}>
              {!!menuItems.length && (
                <HiddenMenu mq={Media.down.m}>
                  <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
                </HiddenMenu>
              )}
              {!!mobileMenuItems.length && (
                <HiddenMenu mq={Media.up.m}>
                  <MobileMenuButton entries={mobileMenuItems} isPortal={true} portalProvider={IsModalProvider} />
                </HiddenMenu>
              )}
            </MenuButtonWrapper>
          </InnnerListingCardBtns>
          {!likeDisabled && isPublished && (
            <LikeButton onClick={handleLike}>
              <ShapeWrapper>
                <IconSvg type="shape" fill={isLiked ? 'red' : 'none'} stroke={isLiked ? 'none' : 'grayLight'} />
              </ShapeWrapper>
            </LikeButton>
          )}
        </Link>
        {isShowReportModal && (
          <PopupView isPortal={true}>
            <Confirmation
              isHaveTextArea
              title={reportParams.title}
              subTitle={reportParams.subtitle}
              buttonType={'danger'}
              textAreaLabel={reportParams.textAreaLabel}
              okText={reportParams.okButton}
              onClose={reportParams.close}
              onOk={reportParams.onOk}
            />
          </PopupView>
        )}
        {isShowInfoModal && (
          <PopupView isPortal={true}>
            <Confirmation
              title={infoParams.title}
              subTitle={infoParams.subtitle}
              buttonType={'primary'}
              onClose={infoParams.close}
            />
          </PopupView>
        )}
      </ContainerListingCard>
    </>
  );
};
