import React from 'react';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {IReport} from '../../hooks/report';
import {CommunityStateType} from '../../states/community';
import {TCompany} from '../../types/company';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {EmptyState} from '../EmptyState/EmptyState';
import {ListCompanies} from './ListCompanies';
import {MobileSearch} from './MobileSearch';
import {Search} from './Search';
import {WrapperPageLocalBiz, WrapperSearch} from './styles';
import {SetCompanySearchParams, TActionsOnItems} from './types';

interface IProps {
  community: CommunityStateType;
  userId: string;
  loading: boolean;
  hasMore: boolean;
  companies?: Array<TCompany>;
  setSearchParams: SetCompanySearchParams;
  fetchData: () => void;
  actionsOnItems: TActionsOnItems;
  onReport: IReport;
}

export const PageManager: React.FC<IProps> = ({
  community,
  loading,
  userId,
  companies,
  hasMore,
  setSearchParams,
  actionsOnItems,
  fetchData,
  onReport,
}) => {
  const {getLink} = useLinks();
  return (
    <WrapperPageLocalBiz>
      <WrapperSearch>
        <Search setSearchParams={setSearchParams} />
        <MobileSearch setSearchParams={setSearchParams} />
      </WrapperSearch>

      {!companies?.length && !loading ? (
        <EmptyState />
      ) : (
        <ListCompanies
          communityId={community?.id}
          items={companies}
          fetchData={fetchData}
          hasMore={hasMore}
          loading={loading}
          userId={userId}
          actionsOnItems={actionsOnItems}
          isManager
          isOwner={community?.Owner?.objectId === userId}
          onReport={onReport}
        />
      )}
      <AddButtonPortal link={getLink(route.createBiz.get())} />
    </WrapperPageLocalBiz>
  );
};
