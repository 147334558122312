import {gql} from '@apollo/client';
import MemberFragment from './people';
import {CommunitySmallFragment} from './community';
import {AppFileFragment} from './file';

export const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    objectId
    createdAt
    updatedAt
    name
    type
    alias
    User {
      ...MemberFragment
    }
    Community {
      ...CommunitySmallFragment
    }
    isDeleted
    isPublished
    DocFile {
      ...AppFileFragment
    }
    Cover {
      ...AppFileFragment
    }
  }
  ${MemberFragment}
  ${CommunitySmallFragment}
  ${AppFileFragment}
`;
