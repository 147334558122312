import React, {useMemo, useState} from 'react';
import {
  getEventDataButtonLabel,
  getTextEventDescription,
  getTextsEventPaymentLabel,
  getTextsEventSecondaryLabel,
} from '../../helpers/order';
import {CustomerType} from '../../ui-kit/RequestCard/types';
import {RewardType} from '../../queries/types/item';
import {InnerRequestCard} from './styles';
import {useTranslation} from 'react-i18next';
import {Order, StatusOrderType} from '../../queries/types/order';
import {editReviewDataT, TDataInvoices, TResCreateReview, TResEditOrder, TResManageOrder} from '../../hooks/order';
import {GQLOrderStatuses} from '../../graphql.schema';
import {SkeletonRequestCard} from './Sceleton/Card/SkeletonRequestCard';
import {TReview} from '../../types/reviews';
import {OrderHelpersT} from '../../types/messages';
import {getResize} from '../../helpers/file';
import {getEventLabels, toStateEvent} from '../../helpers/event';
import {EventMessageRequestCard} from '../Events/EventRequestCard/EventMessageRequestCard';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {CloseButtonRound} from '../common/Modal/MessagesRequestModal';
import {EventRepeatType} from '../../queries/types/event';
import {RoundModal} from '../common/Modal/RoundModal';
import {RecurringModalWrapper, RMButton, RMButtons, RMDescr, RMTitle} from '../Events/EventRequestCard/styles';

type TProps = {
  activeOrder: Order;
  viewerId?: string;
  dataManageOrder: TResManageOrder;
  dataInvoice: TDataInvoices | null;
  invoiceLoading?: boolean;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  showStripePopup: () => void;
  stripeError?: boolean | null;
  isModal?: boolean;
  onClose?: () => void;
  setIsCanceled?: (val: boolean) => void;
  isCanceled?: boolean;
};

export const RequestCardEvent: React.FC<TProps> = ({
  activeOrder: _activeOrder,
  viewerId,
  dataManageOrder,
  dataInvoice,
  invoiceLoading,
  orderHelpers,
  isModal,
  onClose,
  setIsCanceled,
  isCanceled,
}) => {
  const {t} = useTranslation();
  const activeOrder = {..._activeOrder, ...(isCanceled ? {status: StatusOrderType.rejected} : {})};
  const {manageOrder, loading: manageOrderLoading} = dataManageOrder;
  const event = useMemo(() => toStateEvent(activeOrder.Event), [activeOrder]);
  const typeUser = useMemo(
    () => (viewerId === event.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester),
    [viewerId, event],
  );
  // const tagRewardType = getAmountLabel(event);
  const isRequester = typeUser === CustomerType.Requester;
  const params = useMemo(() => {
    return {
      rewardType: event.rewardType as RewardType,
      typeUser,
      orderStatus: activeOrder.status,
    };
  }, [typeUser, activeOrder, event]);
  const descr = getTextEventDescription(params);
  const paymentLabel = getTextsEventPaymentLabel(params);
  const secondaryLabel = getTextsEventSecondaryLabel(params);
  const {label: primaryBtnLabel, status: newStatus} = getEventDataButtonLabel(params);
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [recurringModal, setRecurringCancelModal] = useState<boolean>(false);
  const [cancelType, setCancelType] = useState<'all' | 'one'>();

  const handleManageOrder = () => {
    // if (stripeError === true || stripeError === null) {
    //   showStripePopup();
    //   return;
    // }
    return manageOrder({orderId: activeOrder.objectId, status: newStatus});
  };
  const repeatType =
    activeOrder?.recurringEvent && event?.eventType && event.eventType !== EventRepeatType.one
      ? t(`events:repeatShort.${event.eventType}`)
      : '';
  const handleCancelOrder = async () => {
    await manageOrder({
      orderId: activeOrder.objectId,
      status: GQLOrderStatuses.canceled,
      recurring: cancelType,
    });
    setIsCanceled?.(true);
    handleCloseDelete();
  };
  const handleOpenDelete = () => {
    if (activeOrder?.recurringEvent) {
      setRecurringCancelModal(true);
      return;
    }
    setShowModalDelete(true);
  };
  const handleCloseRecurringModal = () => {
    setRecurringCancelModal(false);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  const cancelOne = () => {
    setCancelType('one');
    setShowModalDelete(true);
    setRecurringCancelModal(false);
  };

  const cancelAll = () => {
    setCancelType('all');
    setShowModalDelete(true);
    setRecurringCancelModal(false);
  };
  const listerInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Lister?.firstName || '',
      lastName: activeOrder?.Lister?.lastName,
      avatar: getResize(activeOrder?.Lister?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const requesterInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Requester?.firstName || '',
      lastName: activeOrder?.Requester?.lastName,
      avatar: getResize(activeOrder?.Requester?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const eventInfo = useMemo(() => {
    return {
      avatar: getResize(activeOrder?.Event?.images[0]?.file?.url, 'sm'),
      name: activeOrder?.Event.name,
    };
  }, [activeOrder]);
  // const creditInfo = getCreditInfo({activeOrder, isRequester, t});
  const tags = getEventLabels(t, event);
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('events:modals.cancel.title'),
        okText: t('events:modals.cancel.okText'),
        cancelText: t('events:modals.cancel.cancelText'),
      },
    };
  }, []);
  if (invoiceLoading) {
    return <SkeletonRequestCard />;
  }
  return (
    <InnerRequestCard>
      {isModal && <CloseButtonRound onClose={onClose} />}
      <EventMessageRequestCard
        isDeleted={event?.isDeleted}
        paymentLabel={{
          title: t(paymentLabel.title, {value: dataInvoice?.total?.value}),
          descr: t(paymentLabel.descr),
        }}
        buttonLabel={{
          primary: {
            label: primaryBtnLabel ? t(primaryBtnLabel) : '',
            onClick: handleManageOrder,
            loading: manageOrderLoading,
          },
          secondary: {label: secondaryLabel ? t(secondaryLabel) : '', onClick: handleOpenDelete},
          hideBase: activeOrder.status === StatusOrderType.attending,
          disabled: orderHelpers.orderLoading,
        }}
        cancelled={{title: t('requests:buttons.cancelRequest')}}
        users={[listerInfo, requesterInfo]}
        descr={t(descr)}
        userType={viewerId === event?.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester}
        cardType={activeOrder.status === StatusOrderType.completed ? 'review' : 'regular'}
        image={getResize(event?.images?.[0]?.file?.url, 'md') || ''}
        title={event?.name}
        opponentInfo={isRequester ? listerInfo : requesterInfo}
        eventInfo={eventInfo}
        tags={tags}
        startDate={activeOrder?.startTime}
        repeat={repeatType}
        // rating={{
        //     title: t('requests:card.rating.title'),
        //     descr: t('requests:card.rating.descr'),
        //     input: {
        //         user: {
        //             value: reviewOrderData.data.userRating,
        //             onChange: reviewOrderData.change.changeUserRating,
        //         },
        //         event: {
        //             value: reviewOrderData.data.eventRating,
        //             onChange: reviewOrderData.change.changeItemRating,
        //         },
        //     },
        // }}
        // review={{
        //     title: t('requests:card.review.title'),
        //     descr: t('requests:card.review.descr'),
        //     completedDescr: t('requests:card.completed'),
        //     noReview: t('requests:card.review.noReview'),
        //     input: {
        //         user: {
        //             placeholder: '',
        //             value: reviewOrderData.data.userReview,
        //             onChange: reviewOrderData.change.changeUserReview,
        //         },
        //         event: {
        //             placeholder: '',
        //             value: reviewOrderData.data.eventReview,
        //             onChange: reviewOrderData.change.changeItemReview,
        //         },
        //     },
        // }}
        // readyReviewsData={readyReviewsData}
        isRequester={isRequester}
        // reviewLoading={reviewsDataLoading && !readyReviewsData}
        buttonSelectText={t('requests:buttonSelectText')}
        guests={activeOrder?.attendeeGuests}
        booked={activeOrder.status === StatusOrderType.attending}
      />
      <RoundModal active={recurringModal} setActive={handleCloseRecurringModal} isPortal={true}>
        <RecurringModalWrapper>
          <RMTitle>{t('events:modals.recurring.title')}</RMTitle>
          <RMDescr>{t('events:modals.recurring.descr')}</RMDescr>
          <RMButtons>
            <RMButton onClick={cancelAll} variant={'danger'}>
              {t('events:modals.recurring.all')}
            </RMButton>
            <RMButton onClick={cancelOne} variant={'danger'}>
              {t('events:modals.recurring.one')}
            </RMButton>
          </RMButtons>
        </RecurringModalWrapper>
      </RoundModal>
      {!isMobile && isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={handleCancelOrder}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
      {isMobile && isShowModalDelete && (
        <BasePortal>
          <IsModalProvider>
            <Confirmation
              title={modalsText.delete.title}
              onClose={handleCloseDelete}
              okText={modalsText.delete.okText}
              onOk={handleCancelOrder}
              buttonType={'danger'}
              cancelText={modalsText.delete.cancelText}
            />
          </IsModalProvider>
        </BasePortal>
      )}
    </InnerRequestCard>
  );
};
