import styled from 'styled-components';
import {Media, theme} from '../theme';
import {mixins} from '../theme/mixins';

export const CircleUnfilled = styled.circle<{
  radius: number;
  innerRadius: number;
  innerDiameter: number;
  strokeWidth: number;
  value: number;
}>`
  cx: ${(props) => props.radius};
  cy: ${(props) => props.radius};
  r: ${(props) => props.innerRadius};
  stroke-width: ${(props) => props.strokeWidth};
  stroke: ${theme.palette.common.white};
  stroke-dasharray: ${(props) => props.innerDiameter * Math.PI};
  transitionduration: '0.5s';
  fill: none;
`;

export const CircleFilled = styled.circle<{
  radius: number;
  innerRadius: number;
  innerDiameter: number;
  strokeWidth: number;
  value: number;
}>`
  stroke-width: ${(props) => props.strokeWidth};
  stroke: ${theme.palette.common.aqua};
  stroke-dasharray: ${(props) => `
          ${props.innerDiameter * Math.PI * props.value},
          ${props.innerDiameter * Math.PI}`};
  transitionduration: '0.5s';
  fill: ${theme.palette.grayscale._16};
  transform: rotateZ(-90deg);
  transform-origin: 50% 50%;
  display: flex;
`;

export const CircleText = styled.text<{
  radius: number;
}>`
  font-family: ${mixins.font.primary.regular};
  font-size: 12px;
  font-weight: 500;
  fill: ${theme.palette.common.quaternary};
  text-anchor: middle;
`;

export const StyledSvg = styled.svg`
  ${Media.down.m} {
    display: flex;
    padding: 6px;
  }
`;
