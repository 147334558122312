import {AuthTab, LogInValues, SignUpValues, SkipParams, SkipParamsOptions, TypeCommunity} from '../types/auth';
import {route} from '../constants/routes';
import {Location} from '../queries/types/locations';
import {CommunityStateType} from '../states/community';

export const getAuthPathUser = (tab: string, user: TypeCommunity) => {
  return tab === AuthTab.signUp ? route.signUp.get({user: user}) : route.auth.get();
};

export const toParseSignUpUser = async (data: SignUpValues): Promise<SignUpValues> => {
  return {
    username: data?.username,
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data.email,
    password: data.password,
    work: data?.work,
    status: data?.status,
  };
};

export const toParseLogInUser = (data: LogInValues) => {
  const email: string = data !== undefined && data.email !== undefined ? data.email.toLowerCase() : data?.email;
  return {
    username: email,
    password: data.password,
  };
};

export const toStateSkipParams = (params: URLSearchParams): SkipParams => {
  return {
    [SkipParamsOptions.skipPhone]: Boolean(params.get(SkipParamsOptions.skipPhone)),
    [SkipParamsOptions.skipAddress]: Boolean(params.get(SkipParamsOptions.skipAddress)),
    [SkipParamsOptions.autoAddress]: Boolean(params.get(SkipParamsOptions.autoAddress)),
    [SkipParamsOptions.skipProfile]: Boolean(params.get(SkipParamsOptions.skipProfile)),
    [SkipParamsOptions.skipInterests]: Boolean(params.get(SkipParamsOptions.skipInterests)),
  };
};

export const getCommunityLocation = (community?: CommunityStateType): Partial<Location> => {
  return {
    name: community?.city?.name,
    State: {
      name: community?.state?.name,
    },
    Country: {
      name: community?.country?.name,
    },
  };
};
