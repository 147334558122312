import {SkipParamsOptions} from '../types/auth';

export const MISSING_TRANSLATION_KEY = 'missing_translation_key';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum QueryOrder {
  createdAt_ASC = 'CreatedAt_ASC',
  createdAt_DESC = 'CreatedAt_DESC',
  updatedAt_ASC = 'UpdatedAt_ASC',
  updatedAt_DESC = 'UpdatedAt_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
}

export const DefaultOption = {label: '', value: '', type: ''};

export const LocalStorageItems = {
  communityId: 'communityId',
  deviceToken: 'deviceToken',
  pushAlias: 'pushAlias',
  inboxHints: 'inboxHints',
};
/*
* export const CATEGORIES: Record<string, string> = {
  electronics: 'common:categories.electronics',
  outdoors: 'common:categories.outdoors',
  furniture: 'common:categories.furniture',
  partyEvents: 'common:categories.partyEvents',
  sports: 'common:categories.sports',
  home: 'common:categories.home',
  music: 'common:categories.music',
  cooking: 'common:categories.cooking',
  toys: 'common:categories.toys',
  clothing: 'common:categories.clothing',
  other: 'common:categories.other',
};

export const CATEGORIES_SERVICE: Record<string, string> = {
  food: 'common:categories.food',
  coaching: 'common:categories.coaching',
  business: 'common:categories.business',
  babysitting: 'common:categories.babysitting',
  household: 'common:categories.household',
  other: 'common:categories.other',
};*/

export const currency = 'C$';

export const TEXTAREA_MAX_LENGTH = 500;

export const SUPPORT_EMAIL_MASK = 'support@hangeh.';

export const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};

export const SKIP_PARAMS_KEYS = Object.values(SkipParamsOptions);

export enum TYPE_NAME {
  Company = 'Company',
  Event = 'Event',
  Group = 'Group',
  Document = 'Document',
  Item = 'Item',
  Amenity = 'Amenity',
}

export const RTEdefault = '<p></p>\n';

export const SupportName = 'Hangeh Support';

export const referralReward = 10;
