import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue} from 'recoil';

import {ApolloClient} from '@apollo/client';
import {getOptions, insertChar} from './common';
import {
  AccessType,
  CommunityIdKeyInLS,
  HangehEmployeesEmailPattern,
  ListingApproval,
  MaxCountOfCommunity,
  MaxCountOfCommunityForHangehEmployees,
} from '../constants/community';
import {PlanOptionsValues} from '../constants/plan';
import {GQLCommunityWhereInput} from '../graphql.schema';
import {CommunitiesResponseType, GetCurrentCommunityInfo, GetShortCommunitiesQuery} from '../queries/community';
import {Community} from '../queries/types/community';
import {User} from '../queries/types/user';
import {currentCommunity} from '../states/community';
import {typeUser as typeUserState} from '../states/typeUser';
import {TypeCommunity} from '../types/auth';
import {
  CommunityAccessType,
  CommunityFormValues,
  CommunityOptionsFields,
  TCommunity,
  TOptions,
} from '../types/community';
import {TAdminsPermissions} from '../types/settings';
import {translateFn} from '../types/common';
import {paths} from '../constants/routes';

export const toPointerCommunity = (community: Partial<CommunityFormValues>) => {
  const isCircle = community?.type === CommunityTypes.circle;
  return {
    name: community.name,
    descr: community.descr,
    alias: community.alias,
    zip: isCircle ? '' : community.zip,
    address: isCircle ? '' : community.address,
    Location: {
      link: community.city || '',
    },
    accessType: community.accessType,
    listingApproval: community.listingApproval,
    type: community?.type || '',
    passCode: community?.passCode || '',
    neighbourhood: community?.neighbourhood || '',
  };
};

export const isCommunityCreate = (community?: Partial<TCommunity> | null) => {
  return Boolean(community?.alias);
};

export const toStateCommunity = (community?: Community | null) => {
  return {
    id: community?.id,
    objectId: community?.objectId,
    Owner: community?.Owner,
    name: community?.name,
    descr: community?.descr,
    alias: community?.alias,
    zip: community?.zip,
    type: community?.type,
    address_add: community?.address_add,
    zip_add: community?.zip_add,
    units: community?.units,
    buildings: community?.buildings,
    identityData: community?.identityData,
    address: community?.address,
    Location: community?.Location,
    city: community?.Location,
    passCode: community?.passCode,
    state: community?.Location?.State,
    country: community?.Location?.Country,
    accessType: community?.accessType,
    allowChat: community?.allowChat,
    allowEvents: community?.allowEvents,
    allowPosts: community?.allowPosts,
    listingApproval: community?.listingApproval,
    Residents: community?.Residents?.edges?.map((edge) => edge.node) || [],
    Admins: community?.Admins?.edges?.map((edge) => edge.node) || [],
    avatar: community?.Avatar?.file?.url,
    Subscr: community?.Subscr,
    countMembers: community?.countMembers,
    invitation: community?.invitation,
    isVerified: community?.isVerified,
    createdAt: community?.createdAt,
    Items: community?.Items?.edges?.map((edge) => edge.node) || [],
    JoinRequests: community?.JoinRequests?.edges?.map((edge) => edge.node) || [],
    checkList: community?.checkList,
    options: community?.options,
    __typename: community?.__typename,
  };
};

export const getIsResident = (residents?: User[], viewerId?: string) => {
  return Number(residents?.findIndex((it) => it.id === viewerId)) >= 0;
};

export const getCurrentCommunity = async (
  objectId: string,
  client: ApolloClient<any>,
  isSupport: boolean,
  lastVisited?: string,
  alias?: string | null,
) => {
  const {data} = await client.query<CommunitiesResponseType>({
    query: GetShortCommunitiesQuery,
    variables: {
      skip: !objectId || isSupport,
      where: defaultGetCommunityWhereOption({viewerId: objectId}),
      first: DEFAULT_GET_COMMUNITIES_LIMIT,
    },
  });
  const AliasWhere = defaultGetCommunityWhereOption({
    viewerId: objectId,
    alias: alias || '',
    lastVisitedId: lastVisited || '',
  });
  const LVWhere = defaultGetCommunityWhereOption({
    viewerId: objectId,
    alias: '',
    lastVisitedId: lastVisited || '',
  });
  const {data: lastVisitedCommunities} = await client.query<CommunitiesResponseType>({
    query: GetCurrentCommunityInfo,
    variables: {
      skip: !objectId || isSupport,
      where: LVWhere,
    },
  });
  const {data: current} = await client.query<CommunitiesResponseType>({
    query: GetCurrentCommunityInfo,
    variables: {
      skip: !objectId || isSupport,
      where: AliasWhere,
    },
  });
  const communities = data?.communities?.edges?.map((el) => el.node);
  const lastVisitedCommunity = lastVisitedCommunities?.communities?.edges?.map((el) => el.node)?.[0];
  const aliasedComm = current?.communities?.edges?.filter((el) => el.node?.alias === alias)?.[0]?.node;

  return {community: aliasedComm?.id ? aliasedComm : lastVisitedCommunity, communities};
};

interface PaymentOptionType {
  __typename?: string;
  value?: string;
}

export const hasPlanOption = (options?: PaymentOptionType[], value?: PlanOptionsValues) => {
  return options?.map((o) => o.value).includes(value);
};

export const useHasPlanOption = (value: PlanOptionsValues) => {
  const [community] = useRecoilState(currentCommunity);

  return hasPlanOption(community?.Subscr?.PaymentPlan.options, value);
};

export const useHasPlanOptionCall = () => {
  const [community] = useRecoilState(currentCommunity);

  return (value: PlanOptionsValues) => hasPlanOption(community?.Subscr?.PaymentPlan.options, value);
};

export const useGetListingsApprovalOptions = (options?: PaymentOptionType[]): TOptions[] => {
  const {t} = useTranslation();

  const listingsApproval = getOptions(ListingApproval);

  if (!hasPlanOption(options, PlanOptionsValues.listingsApproval)) {
    return listingsApproval
      .filter((it) => it.type === ListingApproval.unmanaged)
      .map((el) => ({...el, label: t('community:listingApprovalType', {context: el.value})}));
  }

  return listingsApproval.map((el) => ({...el, label: t('community:listingApprovalType', {context: el.value})}));
};

export const useGetAccTypes = (options?: PaymentOptionType[]): TOptions[] => {
  const {t} = useTranslation();

  const accTypes = getOptions(AccessType);

  if (!hasPlanOption(options, PlanOptionsValues.privateCommunityAccount)) {
    return accTypes
      .filter((it) => it.type === AccessType.public)
      .map((el) => ({...el, label: t('community:accessType', {context: el.value})}));
  }

  return accTypes.map((el) => ({...el, label: t('community:accessType', {context: el.value})}));
};

export enum searchCommunityOptions {
  redirect = 'redirect',
}

export const getSearchCommunityOptions = (t: (key: string) => string) => [
  {
    label: t('search:communities.showAll'),
    value: '',
  },
  {
    label: t('search:communities.public'),
    value: CommunityAccessType.Public,
  },
  {
    label: t('search:communities.private'),
    value: CommunityAccessType.Private,
  },
  {
    label: t('search:communities.myPage'),
    value: searchCommunityOptions.redirect,
  },
];

export const getCurrentCommunityFromLS = (communities: Array<Community>, lastVisited?: string) => {
  if (!communities.length) return null;

  const communityId = localStorage?.getItem(CommunityIdKeyInLS);
  const community = communities.find((el) => el.objectId === communityId) || null;
  if (community) return community;

  const visitedCommunity = communities.find((el) => el.objectId === lastVisited) || null;
  if (visitedCommunity) return visitedCommunity;

  const defaultCommunity = communities[0];
  const defaultCommunityId = defaultCommunity?.objectId;
  if (!defaultCommunityId) return null;

  setCurrentCommunity(defaultCommunityId);
  return defaultCommunity;
};

export const setCurrentCommunity = (communityId: string) => {
  localStorage.setItem(CommunityIdKeyInLS, communityId);
};

export const getAliasFromPath = (path: string) => {
  const parts = path.split('/');
  let alias: string | undefined = '';
  if (parts.length > 2) {
    if (parts[2]) {
      alias = parts[1];
    }
  }
  return alias;
};

export const useResolveRoute = (value?: PlanOptionsValues, roles?: Array<TypeCommunity>) => {
  const community = useRecoilValue(currentCommunity);
  const userType = useRecoilValue(typeUserState);

  let planAccess = false;
  let roleAccess = false;

  if (!value) planAccess = true;
  if (!planAccess) planAccess = !!hasPlanOption(community?.Subscr?.PaymentPlan.options, value);

  if (!roles?.length) roleAccess = true;
  if (!roleAccess && userType) roleAccess = !!roles?.includes(userType as TypeCommunity);

  return planAccess && roleAccess;
};

type TPermissionsItem = {keyData: keyof TAdminsPermissions; defaultValue: boolean; label: string; hintText: string};
export type TPermissionsList = TPermissionsItem[];

export const getPermissionsList = () => {
  const community = useRecoilValue(currentCommunity);
  const planOptions = community?.Subscr?.PaymentPlan?.options;
  const manageAmenities: TPermissionsList = !!hasPlanOption(planOptions, PlanOptionsValues.amenities)
    ? [
        {
          keyData: 'manageAmenity',
          defaultValue: false,
          label: 'settings:community.admins.permissionsOptions.manageAmenity',
          hintText: 'settings:community.admins.permissionsTooltip.manageAmenity',
        },
      ]
    : [];

  const manageEvents: TPermissionsList = !!hasPlanOption(planOptions, PlanOptionsValues.events)
    ? [
        {
          keyData: 'manageEvent',
          defaultValue: false,
          label: 'settings:community.admins.permissionsOptions.manageEvent',
          hintText: 'settings:community.admins.permissionsTooltip.manageEvent',
        },
      ]
    : [];

  const manageListings: TPermissionsList = !!hasPlanOption(planOptions, PlanOptionsValues.listingsFeed)
    ? [
        {
          keyData: 'manageListing',
          defaultValue: false,
          label: 'settings:community.admins.permissionsOptions.manageListings',
          hintText: 'settings:community.admins.permissionsTooltip.manageListings',
        },
      ]
    : [];

  const manageCompany: TPermissionsList = !!hasPlanOption(planOptions, PlanOptionsValues.company)
    ? [
        {
          keyData: 'manageCompany',
          defaultValue: false,
          label: 'settings:community.admins.permissionsOptions.manageCompany',
          hintText: 'settings:community.admins.permissionsTooltip.manageCompany',
        },
      ]
    : [];

  const manageHelpDesk: TPermissionsList = !!hasPlanOption(planOptions, PlanOptionsValues.helpDesk)
    ? [
        {
          keyData: 'manageHelpDesk',
          defaultValue: false,
          label: 'settings:community.admins.permissionsOptions.manageHelpDesk',
          hintText: 'settings:community.admins.permissionsTooltip.manageHelpDesk',
        },
      ]
    : [];

  const permissionsList: TPermissionsList = [
    {
      keyData: 'postAsCommunity',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.postAsCommunity',
      hintText: 'settings:community.admins.permissionsTooltip.postAsCommunity',
    },
    {
      keyData: 'postNotice',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.postNotice',
      hintText: 'settings:community.admins.permissionsTooltip.postNotice',
    },
    {
      keyData: 'editCommunity',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.editCommunity',
      hintText: 'settings:community.admins.permissionsTooltip.editCommunity',
    },
    {
      keyData: 'manageAdmin',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.manageAdmin',
      hintText: 'settings:community.admins.permissionsTooltip.manageAdmin',
    },
    {
      keyData: 'managePeople',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.managePeople',
      hintText: 'settings:community.admins.permissionsTooltip.managePeople',
    },
    {
      keyData: 'manageInvitation',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.manageInvitation',
      hintText: 'settings:community.admins.permissionsTooltip.manageInvitation',
    },
    ...manageListings,
    ...manageCompany,
    ...manageEvents,
    ...manageAmenities,
    ...manageHelpDesk,
    {
      keyData: 'manageReport',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.manageReport',
      hintText: 'settings:community.admins.permissionsTooltip.manageReport',
    },
    {
      keyData: 'manageDocument',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.manageDocument',
      hintText: 'settings:community.admins.permissionsTooltip.manageDocument',
    },
    {
      keyData: 'dashboardAccess',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.dashboardAccess',
      hintText: 'settings:community.admins.permissionsTooltip.dashboardAccess',
    },
    {
      keyData: 'manageGroups',
      defaultValue: false,
      label: 'settings:community.admins.permissionsOptions.manageGroups',
      hintText: 'settings:community.admins.permissionsTooltip.manageGroups',
    },
  ];
  return permissionsList;
};

export const getMaxCountOfCommunity = (email = '') => {
  return HangehEmployeesEmailPattern.test(email) ? MaxCountOfCommunityForHangehEmployees : MaxCountOfCommunity;
};

export const DEFAULT_GET_COMMUNITIES_LIMIT = 2;

export type defGetCommunityWhereOptionProps = {viewerId?: string; alias?: string; lastVisitedId?: string};

export const defaultGetCommunityWhereOption = ({
  viewerId,
  lastVisitedId,
  alias,
}: defGetCommunityWhereOptionProps): GQLCommunityWhereInput => {
  if (alias)
    return {
      OR: [
        {Owner: {have: {objectId: {equalTo: viewerId}}}, alias: {equalTo: alias}},
        {Residents: {have: {objectId: {equalTo: viewerId}}}, alias: {equalTo: alias}},
      ],
    };
  if (lastVisitedId)
    return {
      OR: [
        {Owner: {have: {objectId: {equalTo: viewerId}}}, objectId: {equalTo: lastVisitedId}},
        {Residents: {have: {objectId: {equalTo: viewerId}}}, objectId: {equalTo: lastVisitedId}},
      ],
    };
  return {
    OR: [{Owner: {have: {objectId: {equalTo: viewerId}}}}, {Residents: {have: {objectId: {equalTo: viewerId}}}}],
  };
};

const addZipSpaces = (zip: string) => {
  let newZip = zip;
  if (zip.length < 3) return null;
  if (zip.length > 3) newZip = insertChar(zip, ' ', 3);
  if (zip.length > 6) newZip = insertChar(newZip, ' ', 7);
  return newZip.trim();
};

export const formatSearchZipAdd = (zip?: string) => {
  if (!zip) return null;
  const zipUpper = zip.toUpperCase();
  const zipLower = zip.toLowerCase();

  const variants = {
    1: zip,

    2: zipLower,
    3: zipUpper,

    4: zipLower.replaceAll(' ', ''),
    5: zipUpper.replaceAll(' ', ''),

    6: addZipSpaces(zipLower),
    7: addZipSpaces(zipUpper),
  };
  const values = Object.values(variants).filter((val) => !!val);

  return {zip_add_array: values.map((v) => ({zip_add: {contains: [v]}})), string_array: values};
};

export enum CommunityTypes {
  building = 'building',
  neighbourhood = 'neighbourhood',
  circle = 'circle',
}
export const getCommunityTypesOptions = (t: translateFn, user: User | null) => {
  const types = [{label: t('community:types.building'), value: CommunityTypes.building}];
  if (user?.email?.includes('@hangeh.com'))
    types.push({label: t('community:types.neighbourhood'), value: CommunityTypes.neighbourhood});
  types.push({label: t('community:types.circle'), value: CommunityTypes.circle});
  return types;
};

export const formatCommOptions = (values: any) => {
  const options = {
    [CommunityOptionsFields.passCode]: values?.passCode ?? true,
    [CommunityOptionsFields.requireAddress]: values?.requireAddress ?? true,
    [CommunityOptionsFields.requirePhone]: values?.requirePhone ?? true,
    [CommunityOptionsFields.requireListing]: values?.requireListing ?? true,
  };
  return options;
};

export const getSwitchPath = (pathname: string) => {
  if (pathname.includes(paths.group)) return paths.loop;
  const path = pathname.split('/')?.pop() || '';
  const currentPath = path?.trim() ? '/' + path : '/' + paths.messages;
  return currentPath;
};
