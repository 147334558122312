import React, {SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import defaultAvatar from './assets/defaultAvatar.png';
import {
  ButtonsWrapper,
  DescrJoin,
  ErrorMessageJoin,
  InnerAllWidth,
  InnerOptionIcon,
  InnerOptionsJoin,
  ItemsPairContainer,
  MobileTopLine,
  OptionJoin,
  TextOptionJoin,
  TitleJoin,
  JoinWrapper,
  MemberWrapper,
  MembersText,
} from './styles';
import {AccessType} from '../../../constants/community';
import {route} from '../../../constants/routes';
import {correctTranslate, invidKey, makeSeoKeywords} from '../../../helpers/common';
import {getResize} from '../../../helpers/file';
import {aliasPart, inviteToCommunityState, skipParams} from '../../../states/community';
import {AuthTab} from '../../../types/auth';
import {MemberT} from '../../../types/people';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {Button} from '../../../ui-kit/Button/Button';
import {LinkButton} from '../../../ui-kit/Button/LinkButton';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {icon} from '../../../ui-kit/Icon/Svg/types';
import {Br} from '../../../ui-kit/Typography/Br';
import {Link} from '../../../ui-kit/Typography/Link';
import {HeaderLogo} from '../../Auth/HeaderLogo';
import {WrapperAuth} from '../../Auth/WrapperAuth';
import {LottieLoader} from '../../Loader/LottieLoader';
import {Seo} from '../../SEO';
import {MemoHelpCentreButton} from '../Popups/HelpCentreButton/HelpCentreButton';

import {useIsAuthenticated} from '../../../hooks/auth';
import {useLinks} from '../../../hooks/common';
import {getCommunityByAlias, TJoinReturn, useGetListCommunities} from '../../../hooks/community';
import {useViewer} from '../../../hooks/user';

enum JoinRequestTypes {
  Approved = 'approved',
  Pending = 'pending',
}

type JoinButtonsProps = {
  isCurrentCommunity?: boolean;
  reqSend?: boolean;
  isPrivate?: boolean;
  joinRequestStatus?: string;
  isAuthorized?: boolean;
  signUpPath?: string;
  handleJoinPrivate: (e: React.SyntheticEvent) => void;
  joinOnSubmit?: () => void;
  goToCommunity?: () => void;
  isInvited?: boolean;
  autoAddKey?: string;
};
const JoinButtons: React.FC<JoinButtonsProps> = ({
  isCurrentCommunity,
  reqSend,
  isPrivate,
  joinRequestStatus,
  isAuthorized,
  signUpPath,
  joinOnSubmit,
  handleJoinPrivate,
  goToCommunity,
  isInvited,
  autoAddKey,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const joinT = t('community:join.button.join');
  const reqJoinT = t('community:search.buttons.request');
  const viewT = t('community:search.buttons.view');
  const pendingT = t('community:search.buttons.pending');
  const continueToPersonal = t('community:search.buttons.continueToPersonal');

  if (isCurrentCommunity && !reqSend)
    if ((isPrivate && joinRequestStatus === JoinRequestTypes.Approved) || !isPrivate)
      return (
        <LinkButton to={getLink(route.loop.path)} variant={'primary'} width={'117px'}>
          {viewT}
        </LinkButton>
      );
  const joinText = autoAddKey ? joinT : isPrivate ? (isInvited ? joinT : reqJoinT) : joinT;
  return (
    <>
      {joinRequestStatus === JoinRequestTypes.Approved ? (
        <Button onClick={goToCommunity}>{viewT}</Button>
      ) : reqSend ? (
        <ItemsPairContainer>
          <LinkButton to={getLink(route.pageUser.path)} variant={'secondary'}>
            {pendingT}
          </LinkButton>
          <Link
            to={getLink(route.pageUser.path)}
            size={14}
            decoration={'underline'}
            weight={'semi-bold'}
            color={'octonary'}>
            {continueToPersonal}
          </Link>
        </ItemsPairContainer>
      ) : !isAuthorized ? (
        <>
          <LinkButton to={signUpPath || '#'} variant={'primary'} width={'240px'}>
            {joinText}
          </LinkButton>
          <Br indent={20} />
        </>
      ) : (
        <>
          {isPrivate ? (
            <Button width={'240px'} type={'button'} onClick={handleJoinPrivate}>
              {joinText}
            </Button>
          ) : (
            <Button width={'240px'} type={'button'} onClick={joinOnSubmit}>
              {joinText}
            </Button>
          )}
          <Br indent={20} />
        </>
      )}
    </>
  );
};

type TProps = {
  name?: string;
  avatar?: string;
  dataJoin?: TJoinReturn;
  options: {icon: string; text: string; viewBox?: string; width?: string}[];
  signUpPath?: string;
  accessType?: string;
  userId?: string;
  communityId?: string;
  joinRequestStatus?: string;
  refetchRequests?: () => void;
  isCurrentCommunity?: boolean;
  joinFromLink?: boolean;
  goToCommunity?: () => void;
  reqSend?: boolean;
  setReqSend?: React.Dispatch<SetStateAction<boolean>>;
  isInvited?: boolean;
  descr?: string;
  members?: MemberT[];
  addByKey: (autoAddKey?: string) => Promise<boolean>;
  loadingAddByKey: boolean;
};

export const JoinToCommunity: React.FC<TProps> = ({
  name,
  avatar,
  dataJoin,
  options,
  accessType,
  joinRequestStatus,
  isCurrentCommunity,
  joinFromLink,
  goToCommunity,
  setReqSend,
  reqSend,
  descr,
  members,
  addByKey,
  loadingAddByKey,
  signUpPath,
}) => {
  const viewer = useViewer();
  const {t} = useTranslation();
  const errorMsg = correctTranslate({
    text: t(dataJoin?.error?.message || ''),
    defaultText: dataJoin?.error?.message,
  });
  const seoTitle = t('common:seo.join', {text: name});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.join', {text: name})]);
  const isAuthorized = useIsAuthenticated();
  const {push} = useHistory();
  const isPrivate = accessType === AccessType.private.toLowerCase();
  const {getLink} = useLinks();
  const [isInvited] = useRecoilState(inviteToCommunityState);
  const [{autoAddKey}] = useRecoilState(skipParams);
  const [alias] = useRecoilState(aliasPart);
  const dataComm = getCommunityByAlias(alias);
  const {pathname} = useLocation();
  const {data: listOfJoinedCommunities} = useGetListCommunities(viewer?.id);
  const sCommunity = listOfJoinedCommunities?.filter((el) => el.objectId === dataComm.data?.objectId)?.[0];
  const isInv = pathname.includes(invidKey);
  useEffect(() => {
    if (joinRequestStatus === JoinRequestTypes.Pending) {
      setReqSend?.(true);
    }
  }, [joinRequestStatus, sCommunity?.id]);

  const handleBack = () => push(getLink(route.searchCommunityResident.path));
  useEffect(() => {
    const join = async () => await dataJoin?.onSubmit();
    if (isAuthorized && !!isInvited?.communityId) join();
  }, [!!isInvited?.communityId, isAuthorized]);

  /*useEffect(() => {
    if (sCommunity?.objectId) {
      const hasLoop = !!hasPlanOption(currComm?.Subscr?.PaymentPlan.options, PlanOptionsValues.communityFeed);
      push(hasLoop ? getLink(route.loop.path) : getLink(route.pageUser.path));
    }
  }, [sCommunity?.objectId, currComm?.objectId]);*/

  const handleJoinPrivate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (autoAddKey) {
      const res = await addByKey(autoAddKey);
      res && getLink(route.loop.path);
    } else {
      dataJoin?.onSubmit();
      setReqSend?.(true);
    }
  };

  const onJoin = async () => {
    if (autoAddKey) {
      const res = await addByKey(autoAddKey);
      res && getLink(route.loop.path, alias);
    } else await dataJoin?.onSubmit();
  };

  return (
    <WrapperAuth tab={AuthTab.signUp} joinPage={true}>
      <JoinWrapper>
        {!isInv && (
          <Seo
            title={seoTitle}
            keywords={seoKeywords}
            description={descr}
            og={{image: getResize(avatar, 'md'), imageWidth: '70', imageHeight: '70'}}
          />
        )}
        {!joinFromLink && (
          <MobileTopLine>
            <HeaderLogo icon={'back'} onClick={handleBack} />
          </MobileTopLine>
        )}
        <Avatar src={avatar || defaultAvatar} size={'xl'} />
        <TitleJoin>{name}</TitleJoin>
        <DescrJoin>{t('community:join.descr')}</DescrJoin>
        <InnerOptionsJoin>
          {options?.map((it) => (
            <OptionJoin key={it.icon}>
              <InnerOptionIcon>
                <IconSvg
                  width={it.width || '34'}
                  height={it.width || '34'}
                  viewBox={it.viewBox || '0 0 34 34'}
                  type={it.icon as icon}
                  stroke={'yellow'}
                />
              </InnerOptionIcon>
              <TextOptionJoin>{t(it.text)}</TextOptionJoin>
            </OptionJoin>
          ))}
        </InnerOptionsJoin>
        {members && members.length >= 3 && (
          <>
            <MemberWrapper>
              {members?.map((mb, index) => (
                <Avatar
                  className={'avatar'}
                  key={mb?.objectId || index}
                  size={'sm'}
                  src={getResize(mb?.Avatar?.file?.url, 'lg')}
                />
              ))}
            </MemberWrapper>
            <MembersText>
              {t('community:join.members', {
                name1: members?.[0]?.firstName,
                name2: members?.[1]?.firstName,
                name3: members?.[2]?.firstName,
              })}
            </MembersText>
          </>
        )}
        <InnerAllWidth>
          <ErrorMessageJoin open={!!dataJoin?.error?.message}>{errorMsg}</ErrorMessageJoin>
        </InnerAllWidth>
        <ButtonsWrapper>
          <JoinButtons
            isCurrentCommunity={isCurrentCommunity}
            reqSend={reqSend}
            isPrivate={isPrivate}
            joinRequestStatus={joinRequestStatus}
            isAuthorized={isAuthorized}
            signUpPath={signUpPath}
            joinOnSubmit={onJoin}
            handleJoinPrivate={handleJoinPrivate}
            goToCommunity={goToCommunity}
            isInvited={!!isInvited?.communityId}
            autoAddKey={autoAddKey}
          />
        </ButtonsWrapper>
      </JoinWrapper>
      <LottieLoader $isVisible={dataJoin?.loading || loadingAddByKey} allScreen={true} />
      <MemoHelpCentreButton isShown={!joinFromLink} />
    </WrapperAuth>
  );
};
