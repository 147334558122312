import React from 'react';

import {PostContainerNoLink, PostContainer} from './styles';
import {route} from '../../../constants/routes';

import {useLinks} from '../../../hooks/common';

type PostContainerSwitchT = {
  $isComment?: boolean;
  $isAnnouncement?: boolean;
  $isRequest?: boolean;
  isDetail?: boolean;
  id?: string;
};

export const PostContainerSwitch: React.FC<PostContainerSwitchT> = ({id, isDetail, children, ...props}) => {
  const {getLink} = useLinks();

  const postRoute = id && !props.$isComment && !isDetail ? getLink(route.post.get({id})) : '';
  if (postRoute) {
    return <PostContainerNoLink {...props}>{children}</PostContainerNoLink>;
  }

  return <PostContainer {...props}>{children}</PostContainer>;
};
