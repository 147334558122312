import React from 'react';
import {AccountCheckListType} from '../../queries/types/checklist';
import {Br} from '../../ui-kit/Typography/Br';
import {useLinks} from '../../hooks/common';
import {useTranslation} from 'react-i18next';
import {AccountCheck} from './AccountCheck';
import {useGetCheckListInfo} from '../../hooks/checklist';
import {IconSvg} from '../../ui-kit/Icon/Svg';

import {
  AccountCheckWrapper,
  ChecklistMobileWrapper,
  RealText,
  ChecklistStepsWrapper,
  DropDownBtnMobile,
  DropDownContainer,
} from './styles';
import CircleProgressBar from '../../ui-kit/CircleProgressBar';

interface TProps {
  checkList?: AccountCheckListType;
  hideCompletedCheck?: (nameField: keyof AccountCheckListType) => () => void;
  onClose?: () => void;
  isOpen: boolean;
}

export const ChecklistMobile: React.FC<TProps> = ({checkList, hideCompletedCheck, onClose, isOpen}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {actualChecklist, completedCheckList, checklistCompletion} = useGetCheckListInfo();

  return (
    <ChecklistMobileWrapper onClick={onClose}>
      <ChecklistStepsWrapper>
        <CircleProgressBar value={checklistCompletion} size={'medium'} text={`${checklistCompletion}%`} />
        <DropDownContainer>
          <div>
            <RealText weight={'medium'} size={14}>
              {t('checklist.communityProgress')}
            </RealText>
          </div>
          <DropDownBtnMobile $isOpened={isOpen}>
            <IconSvg type={'chevrone-down'} />
          </DropDownBtnMobile>
        </DropDownContainer>
      </ChecklistStepsWrapper>

      {isOpen && (
        <AccountCheckWrapper>
          {!!actualChecklist?.length && completedCheckList && (
            <>
              {actualChecklist.map((el) => {
                return (
                  <AccountCheck
                    onClose={onClose}
                    key={el.dataKey}
                    to={getLink(el.to)}
                    name={t(`notifications:checklist.${el.dataKey}`)}
                    isComplete={checkList?.[el.dataKey]?.isChecked}
                    onClick={hideCompletedCheck && hideCompletedCheck(el.dataKey)}
                    isActiveState={checkList ? !el.isInactiveState?.(checkList) : true}
                  />
                );
              })}
              <Br indent={1} />
            </>
          )}
        </AccountCheckWrapper>
      )}
    </ChecklistMobileWrapper>
  );
};
