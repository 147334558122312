import React, {useEffect, useState} from 'react';
import {
  CardTitle,
  CardValue,
  Cards,
  CopiedLabel,
  CreditsCard,
  EarnDescription,
  EarnTitle,
  EmptyImage,
  InviteLink,
  InviteLinkWrapper,
  LinkButton,
  LinkLabel,
  LinkValue,
  PageWrapper,
  SectionTitle,
  UserInfo,
  UserName,
  UsersCardWrapper,
  UsersList,
} from './styles';
import {useHistory} from 'react-router-dom';
import {GoBack} from '../common/Buttons/GoBack';
import {useTranslation} from 'react-i18next';
import {MemoInfoHint} from '../common/InfoHint';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {handleSharing} from '../../helpers/common';
import {ReferralT} from '../../types/referral';
import {getResize} from '../../helpers/file';
import {Label} from '../../ui-kit/Labels';
import {referralReward} from '../../constants/common';
import {Title} from '../Listings/styles';
import Landscape from '../../files/images/Landscape-lg.png';

type ReferralsSettingsPropsT = {
  inviterName: string;
  referralLink: string;
  referrals?: Partial<ReferralT>[];
  loading?: boolean;
  totalCredits?: number;
  totalReferrals?: number;
};

export const ReferralsSettings: React.FC<ReferralsSettingsPropsT> = ({
  inviterName,
  referralLink,
  referrals,
  totalCredits,
  totalReferrals,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const {t} = useTranslation();
  const {goBack} = useHistory();
  const sendShareText = t('settings:referrals.inviteLink.shareText');
  const sendShareTitle = t('settings:referrals.inviteLink.shareTitle', {userName: inviterName});
  const copiedText = t('people:modals.copied');

  const onShare = () => {
    handleSharing({url: referralLink, text: sendShareText, title: sendShareTitle});
  };
  const handleCopy = () => {
    navigator?.clipboard?.writeText(referralLink);
    setIsCopied(true);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      isCopied && setIsCopied(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [isCopied]);
  return (
    <>
      <PageWrapper>
        <GoBack title={t('common:menu.referrals')} goBack={goBack} />
        <Title $hideOnMob>{t('common:menu.referrals')}</Title>
        <EarnTitle>{t('settings:referrals.inviteTitle')}</EarnTitle>
        <EarnDescription>{t('settings:referrals.inviteDescription')}</EarnDescription>
        <InviteLinkWrapper>
          <LinkLabel>{t('settings:referrals.inviteLink.label')}</LinkLabel>
          <InviteLink>
            {isCopied && <CopiedLabel>{copiedText}</CopiedLabel>}
            <LinkValue onClick={handleCopy}>{referralLink}</LinkValue>
            <LinkButton onClick={handleCopy} $active={isCopied}>
              {isCopied ? copiedText : t('settings:referrals.inviteLink.copy')}
            </LinkButton>
            <LinkButton $mobileOnly onClick={onShare}>
              {t('settings:referrals.inviteLink.share')}
            </LinkButton>
          </InviteLink>
        </InviteLinkWrapper>
        <SectionTitle>
          {t('settings:referrals.creditsTitle')}
          <MemoInfoHint hintText={t('settings:referrals.hints.credits')} />
        </SectionTitle>
        <ReferralCards referrals={totalReferrals} credits={totalCredits} />
        {!!referrals?.length && (
          <>
            <SectionTitle>
              {t('settings:referrals.referralsTitle')}
              <MemoInfoHint hintText={t('settings:referrals.hints.referrals')} />
            </SectionTitle>
            <UsersList>
              {referrals?.map((el, i) => (
                <UserCard
                  avatar={el?.User?.Avatar?.file?.url}
                  firstName={el?.User?.firstName}
                  firstLetter={el?.User?.firstLetter}
                  userMadeTransaction={el?.userMadeTransaction}
                  key={el?.objectId || i}
                />
              ))}
            </UsersList>
          </>
        )}
      </PageWrapper>
      {!referrals?.length && <EmptyImage src={Landscape} />}
    </>
  );
};

export const ReferralCards: React.FC<{referrals?: number; credits?: number}> = ({referrals, credits}) => {
  const {t} = useTranslation();
  return (
    <Cards>
      <CreditsCard>
        <CardTitle>{t('settings:referrals.referrals')}</CardTitle>
        <CardValue>{referrals || 0}</CardValue>
      </CreditsCard>
      <CreditsCard>
        <CardTitle>{t('settings:referrals.credits')}</CardTitle>
        <CardValue>{`$${credits || 0}`}</CardValue>
      </CreditsCard>
    </Cards>
  );
};

const UserCard: React.FC<{avatar?: string; firstName?: string; firstLetter?: string; userMadeTransaction?: boolean}> =
  ({avatar, firstName, firstLetter, userMadeTransaction}) => {
    const {t} = useTranslation();
    const label = userMadeTransaction ? `$${referralReward}` : t('settings:referrals.pending');
    return (
      <UsersCardWrapper>
        <UserInfo>
          <Avatar src={getResize(avatar, 'md')} size={'xxs'} />
          <UserName>
            {firstName} {firstLetter}
          </UserName>
        </UserInfo>
        <Label variant={userMadeTransaction ? 'quaternary' : 'quinary'} squared>
          {label}
        </Label>
      </UsersCardWrapper>
    );
  };
