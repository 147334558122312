import React from 'react';
import {managerDashboard} from '../../../queries/types/dashboard';
import {useTranslation} from 'react-i18next';
import {CardsWrapper, DisabledWrapper, PageHeader, PageWrapper, Title} from '../styles';
import {SelectTime} from '../SelectTime';
import {Card} from '../../../ui-kit/Cards';
import {IDashboard, render, THelpers, CommunityField} from '../types';
import {IncomeGeneratedCard} from './IncomeGenerated';
import {convertCentToDollar} from '../../../helpers/payment';
import {DashboardSkeletonSection} from '../../common/Skeletons/DashboardSkeleton';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../SEO';

const renderValue: render<managerDashboard> = (data, el, {t, getSignValue}) => {
  const {dataIndex} = el;
  const value = data[dataIndex];
  if (typeof value !== 'number') return null;
  return (
    <Card
      value={getSignValue(convertCentToDollar(value) || 0)}
      title={t(`dashboard:manager.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:manager.${el.link.title}`),
        }
      }
    />
  );
};

const renderStars: render<managerDashboard> = (data, el, {t}) => {
  const {dataIndex} = el;
  const value = data[dataIndex];
  if (typeof value !== 'number') return;

  return (
    <Card
      value={t('dashboard:render.star', {stars: value.toFixed(1)}) as string}
      title={t(`dashboard:manager.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:manager.${el.link.title}`),
        }
      }
    />
  );
};

const getRender = (data: managerDashboard, el: CommunityField, helpers: THelpers) => {
  const {dataIndex, render} = el;
  const {t} = helpers;

  if (render === 'value') return renderValue(data, el, helpers);
  if (render === 'stars') return renderStars(data, el, helpers);
  if (typeof render === 'function') return render(data, el, helpers);

  const value = data[dataIndex] || 0;
  if (typeof value === 'object') return null;

  return (
    <Card
      value={value}
      title={t(`dashboard:manager.${dataIndex}`)}
      link={
        el?.link && {
          url: el.link.url,
          title: t(`dashboard:manager.${el.link.title}`),
        }
      }
    />
  );
};

export const Dashboard: React.FC<
  IDashboard & {
    data: managerDashboard | null;
    loading?: boolean;
    fields: Array<CommunityField>;
  }
> = ({data, loading, getSignValue, fields}) => {
  const {t} = useTranslation();
  const textTitle = t('dashboard:dashboard');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textTitle]);

  if (data && !loading) {
    return (
      <PageWrapper>
        <Seo title={textTitle} keywords={seoKeywords} />
        <PageHeader>
          <Title>{t('dashboard:dashboard')}</Title>
          <DisabledWrapper>
            <SelectTime />
          </DisabledWrapper>
        </PageHeader>
        <CardsWrapper>
          <IncomeGeneratedCard data={data} getSignValue={getSignValue} />
          {fields.map((el) => getRender(data, el, {t, getSignValue}))}
        </CardsWrapper>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageHeader>
        <Title>{t('dashboard:dashboard')}</Title>
        <DisabledWrapper>
          <SelectTime />
        </DisabledWrapper>
      </PageHeader>
      <DashboardSkeletonSection />
    </PageWrapper>
  );
};
