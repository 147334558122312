import React, {useState} from 'react';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {
  HintBlock,
  HintBlockWrapper,
  HintWrapper,
  IconWrapper,
  HintBlockClose,
  HintBlockText,
  HintBlockIcon,
} from './styles';
import {color} from '../../../ui-kit/Icon/Svg/types';

type HintProps = {
  hintText?: string;
  width?: string;
  height?: string;
  stroke?: color;
  fillChildren?: color;
  top?: number;
  zIndex?: number;
  onClose?: () => void;
  offsets?: boolean;
  float?: 'left' | 'right';
};

const InfoHint: React.FC<HintProps> = ({hintText, top, zIndex, float, ...props}) => {
  const [textVisible, setTextVisible] = useState(false);
  const show = () => setTextVisible(true);
  const hide = () => setTextVisible(false);
  return (
    <HintWrapper $active={textVisible} zIndex={zIndex}>
      <IconWrapper onMouseOver={show} onMouseOut={hide} onFocus={show} onBlur={hide} tabIndex={-1}>
        <IconSvg {...props} type={'info'} fillChildren={'darkgray'} />
      </IconWrapper>
      {textVisible && (
        <HintBlock top={top} float={float}>
          {hintText}
        </HintBlock>
      )}
    </HintWrapper>
  );
};

const BlockHint: React.FC<HintProps> = ({hintText, onClose, offsets, ...props}) => {
  const [visible, setVisible] = useState(true);
  const hide = () => {
    setVisible(false);
    onClose?.();
  };
  if (!visible) return null;
  return (
    <HintBlockWrapper $offsets={offsets} {...props}>
      <HintBlockIcon>
        <IconSvg type={'protection'} stroke={'aqua'} />
      </HintBlockIcon>
      <HintBlockText>{hintText}</HintBlockText>
      <HintBlockClose onClick={hide}>
        <IconSvg type={'close'} />
      </HintBlockClose>
    </HintBlockWrapper>
  );
};

const MemoInfoHint = React.memo(InfoHint);
export {MemoInfoHint, BlockHint};
