import React from 'react';
import {useTranslation} from 'react-i18next';
import {Breadcrumbs} from '../../common/Breadcrumbs';
import {BtnBackDeskTop, BtnBackMobile, HeaderPage, PageWrapper} from './styles';
import {IconImage} from '../../../ui-kit/Icon/Image';
import {Seo} from '../../SEO';
import {makeSeoKeywords} from '../../../helpers/common';
import {TypeStep} from '../../../containers/Account/Create/Manager/Steps';

type PageProps = {
  step: TypeStep;
  backPrevPage: () => void;
  dataSteps: {[key: string]: {code: number; title: string; link: string}};
  isVisibleButton?: boolean;
  showSteps?: boolean;
};

export const PageCreateCommunity: React.FC<PageProps> = ({
  children,
  dataSteps,
  backPrevPage,
  step,
  isVisibleButton,
  showSteps,
}) => {
  const {t} = useTranslation();
  const seoTitle = t('common:seoTitle', {
    text: t('common:seo.create.community'),
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.create.community')]);

  const isVisible = step !== 'profile' && isVisibleButton;

  return (
    <PageWrapper>
      <Seo title={seoTitle} keywords={seoKeywords} />
      {showSteps && (
        <HeaderPage>
          <BtnBackDeskTop isVisible={isVisible} onClick={backPrevPage} height={'22px'} width={'74px'}>
            {t('profile:button.back')}
          </BtnBackDeskTop>
          <BtnBackMobile isVisible={isVisible} onClick={backPrevPage}>
            <IconImage type={'back'} width={'25px'} height={'25px'} />
          </BtnBackMobile>
          <Breadcrumbs dataSteps={dataSteps} step={step} />
        </HeaderPage>
      )}
      {children}
    </PageWrapper>
  );
};
