import React from 'react';
import {Confirmation} from '../../../../ui-kit/Modals/Confirmation';
import {useTranslation} from 'react-i18next';

interface Props {
  onOk?: () => Promise<boolean> | void;
  onClose?: () => void;
  isUpgrade?: boolean;
}

export const ConfirmChanging: React.FC<Props> = ({onOk, isUpgrade, onClose}) => {
  const {t} = useTranslation();
  const title = t(`community:plan.changingPlan.confirmation.title.${isUpgrade ? 'upgrade' : 'downgrade'}`);
  const subTitle = t('community:plan.changingPlan.confirmation.description');
  const okButton = t('community:plan.changingPlan.confirmation.confirm');
  const cancelButton = t('common:commonButtons.cancel');

  return (
    <Confirmation
      onOk={onOk}
      title={title}
      subTitle={subTitle}
      okText={okButton}
      buttonType={'primary'}
      onClose={onClose}
      cancelText={cancelButton}
    />
  );
};
