import React, {useState} from 'react';
import {ActionsWrapper} from '../styles';
import {Button} from '../../../ui-kit/Button/Button';
import {ManageActions, ManageActionsList} from '../types';
import {useTranslation} from 'react-i18next';

type PersonActionsProps = {
  actionsType?: ManageActions;
  actions?: ManageActionsList;
  joinRequestId?: string;
};

export const PersonActions: React.FC<PersonActionsProps> = ({actionsType, actions, joinRequestId}) => {
  const {t} = useTranslation();
  const [denyLoading, setDenyLoading] = useState(false);
  const [aprLoading, setAprLoading] = useState(false);

  const buttonDenyText = t('people:manage.buttons.deny');
  const buttonApproveText = t('people:manage.buttons.approve');

  const handleDeny = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (denyLoading) return;
    setDenyLoading(true);
    joinRequestId && actions?.personDeny?.(joinRequestId);
  };
  const handleApprove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (aprLoading) return;
    setAprLoading(true);
    joinRequestId && actions?.personApprove?.(joinRequestId);
  };

  if (actionsType === ManageActions.MANAGE_NEW)
    return (
      <ActionsWrapper>
        <Button onClick={handleDeny} ghost={true} variant={'secondary'} loading={denyLoading}>
          {buttonDenyText}
        </Button>
        <Button onClick={handleApprove} loading={aprLoading}>
          {buttonApproveText}
        </Button>
      </ActionsWrapper>
    );

  return null;
};
