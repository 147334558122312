import {anyObject} from '../../types/helpers';
import {Handler, Message, SchemaType, IValidation} from './types';
import {Schema} from './Schema';
// validations
import {ValidationHandler} from './VHandler';
import {ValidationString} from './VString';
import {ValidationBoolean} from './VBoolean';
import {ValidationImage} from './VImage';
import {ValidationNumber} from './VNumber';
import {ValidationDate} from './VDate';
import {ValidationUnion} from './VUnion';

export abstract class FormValidation {
  static schema<TSchema extends anyObject>(schema: SchemaType<TSchema>) {
    return new Schema(schema);
  }

  static combine<TSchema extends anyObject>(schemas: Schema<TSchema>[]) {
    return new Schema<TSchema>(
      schemas.reduce((schemas, current) => ({...schemas, ...current.schema}), {}) as SchemaType<TSchema>,
    );
  }

  // Validations

  static handler<T extends anyObject>(handler: Handler<T>, message?: Message) {
    return new ValidationHandler<T>(handler, message);
  }

  static string<T extends anyObject>(message?: Message) {
    return new ValidationString<T>(message);
  }

  static date<T extends anyObject>(message?: Message) {
    return new ValidationDate<T>(message);
  }

  static number<T extends anyObject>(message?: Message) {
    return new ValidationNumber<T>(message);
  }

  static boolean<T extends anyObject>(message?: Message) {
    return new ValidationBoolean<T>(message);
  }

  static image<T extends anyObject>(message?: Message) {
    return new ValidationImage<T>(message);
  }

  static OR<T extends anyObject>(validations: IValidation<T>[], message?: Message) {
    return new ValidationUnion<T>(validations, message);
  }
}
