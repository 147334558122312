import React, {useEffect, useMemo, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {EmptyTag} from '../../containers/MobileTabs';
import {declOfNum} from '../../helpers/common';
import {useLinks} from '../../hooks/common';
import {useGetMenuItemsForGroup} from '../../hooks/groups';
import {entities, reportActions} from '../../hooks/report';
import {TGroup} from '../../types/group';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Menu} from '../../ui-kit/Menu';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {BasePortal} from '../../ui-kit/Popup';
import {Media} from '../../ui-kit/theme';
import {Br} from '../../ui-kit/Typography/Br';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {MenuBtnWrapper} from '../Documents/styles';
import {GroupLabel} from './GroupLabel';
import {InnerGroupCard} from './styles';
import {IActionsOnGroupsData} from './types';
import {getIsIncludesCmnt} from '../../helpers/item';
import {IconStar} from '../Amenities/styles';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

interface IProps {
  group: TGroup;
  actionsData: IActionsOnGroupsData;
  openEditGroupModal: (group: TGroup) => void;
  canEdit: boolean;
  isJoined: boolean;
  report: reportActions;
}

export const CardGroup: React.FC<IProps> = ({group, actionsData, canEdit, openEditGroupModal, isJoined, report}) => {
  const [joinLoading, setJoinLoading] = useState(false);
  const [community] = useRecoilState(currentCommunity);
  const {objectId, Avatar} = group || {};
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const {getLink} = useLinks();
  const {t} = useTranslation();
  const isFeatured = getIsIncludesCmnt(group?.Featured, community?.objectId);
  const joinText = t('groups:card.join');
  const joinedText = t('groups:card.joined');
  const getMembersKey = declOfNum({count: group?.MembersCount || 0, wordOne: 'member', wordMany: 'members'});
  const membersCount = t(`groups:card.${getMembersKey}`, {count: group?.MembersCount || 0});
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('common:modals.delete.title'),
        okText: t('common:modals.delete.button'),
        cancelText: t('common:modals.delete.close'),
      },
    };
  }, []);
  const handleDelete = async () => {
    await actionsData?.deleteGroup?.(objectId);
    setShowModalDelete(false);
  };
  const onJoin = (e?: React.SyntheticEvent) => {
    setJoinLoading(true);
    e?.preventDefault();
    actionsData?.joinGroup?.(group?.objectId);
  };
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  const editGroup = () => {
    openEditGroupModal(group);
  };
  const onReport = () => {
    setIsShowReportModal(true);
  };
  const {menuItems, mobileMenuItems} = useGetMenuItemsForGroup({
    data: {...actionsData, onDelete: handleOpenDelete, onUpdate: editGroup, onReport},
    group,
    canEdit,
    isJoined,
    isFeatured,
  });
  useEffect(() => {
    if (isJoined) setJoinLoading(false);
  }, [isJoined]);

  const reportParams = {
    title: t('common:report.title', {entity: t(`common:entities.${entities.group}`)}),
    subtitle: t('common:report.subtitle'),
    okButton: t('common:report.confirmReport'),
    textAreaLabel: t('common:report.textAreaLabel'),
    close: () => {
      setIsShowReportModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowReportModal(false);
      };
      return report?.report?.({id: objectId, onSuccess, text});
    },
  };

  const ModalWrapper = isMobile ? BasePortal : EmptyTag;
  return (
    <>
      <InnerGroupCard>
        <Link to={getLink(route.groupView.get({id: objectId || ''}))}>
          <PhotoCard aspectRatio={'300:200'} src={Avatar?.file?.url} />
          {isFeatured && <IconStar type={'star'} fill={'orange'} stroke={'white'} />}
          <Br indent={8} />
          <GroupLabel
            group={group}
            membersCount={membersCount}
            onJoin={onJoin}
            joinBtn={joinText}
            joinedText={joinedText}
            isJoined={isJoined}
            joinLoading={joinLoading}
          />
        </Link>
        <MenuBtnWrapper>
          {!!menuItems.length && (
            <HiddenElement mq={Media.down.s}>
              <MenuButton menu={Menu} item={{objectId}} options={menuItems} />
            </HiddenElement>
          )}
          {!!mobileMenuItems.length && (
            <HiddenElement mq={Media.up.m}>
              <MobileMenuButton entries={mobileMenuItems} isPortal={true} portalProvider={IsModalProvider} />
            </HiddenElement>
          )}
        </MenuBtnWrapper>
      </InnerGroupCard>
      {isShowModalDelete && (
        <ModalWrapper>
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={handleDelete}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        </ModalWrapper>
      )}
      {isShowReportModal && (
        <ModalWrapper>
          <Confirmation
            isHaveTextArea
            title={reportParams.title}
            subTitle={reportParams.subtitle}
            buttonType={'danger'}
            textAreaLabel={reportParams.textAreaLabel}
            okText={reportParams.okButton}
            onClose={reportParams.close}
            onOk={reportParams.onOk}
          />
        </ModalWrapper>
      )}
    </>
  );
};
