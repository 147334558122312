import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {declOfNum} from '../../helpers/common';
import {useLinks} from '../../hooks/common';
import {TGroup} from '../../types/group';
import {Br} from '../../ui-kit/Typography/Br';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {GroupLabel} from './GroupLabel';
import {InnerGroupCard} from './styles';

interface IProps {
  group: TGroup;
}

export const SimpleCardGroup: React.FC<IProps> = ({group}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const {objectId, Avatar} = group;

  const joinText = t('groups:card.join');
  const joinedText = t('groups:card.joined');
  const getMembersKey = declOfNum({count: group?.MembersCount || 0, wordOne: 'member', wordMany: 'members'});
  const membersCount = t(`groups:card.${getMembersKey}`, {count: group?.MembersCount || 0});

  return (
    <InnerGroupCard>
      <Link to={getLink(route.groupView.get({id: objectId || ''}))}>
        <PhotoCard aspectRatio={'300:200'} src={Avatar?.file?.url} />
        <Br indent={8} />
        <GroupLabel group={group} membersCount={membersCount} joinBtn={joinText} joinedText={joinedText} />
      </Link>
    </InnerGroupCard>
  );
};
