import React, {useEffect, useState} from 'react';
import {TabsProps} from './types';
import {TabsWrapper, PageWrapper} from './styles';
import {Tab} from './Tab';
import throttle from 'lodash.throttle';

export const Tabs: React.FC<TabsProps> = ({index, onChange, tabsArray, redDotIndex}) => {
  const haveRedDot = redDotIndex !== null && redDotIndex !== undefined;
  return (
    <TabsWrapper>
      {tabsArray.map((tab) => (
        <Tab
          onClick={() => onChange(tab.index)}
          title={tab.title}
          key={tab.index}
          isActive={tab.index === index}
          redDot={haveRedDot ? redDotIndex === tab.index : false}
        />
      ))}
    </TabsWrapper>
  );
};

export const TabsPageWrapper: React.FC<{exclude?: boolean}> = ({children, exclude}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (exclude) return;
    const threshold = 100;
    let lastScrollY = window?.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window?.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      scrollY > lastScrollY ? setScrolled(true) : setScrolled(false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = throttle(() => {
      if (!ticking) {
        window?.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    }, 1000);
    window?.addEventListener('scroll', onScroll);

    return () => window?.removeEventListener('scroll', onScroll);
  }, [exclude]);

  return (
    <PageWrapper $scrolled={exclude ? false : scrolled} $exclude={exclude}>
      {children}
    </PageWrapper>
  );
};
