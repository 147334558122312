import {FileInfo, ParseObject} from '../queries/types/parse';
import {TypeCommunity} from './auth';

export type MemberT = {
  id: string;
  objectId: string;
  username: string;
  firstName: string;
  lastName: string;
  firstLetter?: string;
  isVerified: boolean;
  isManager?: boolean;
  isAdmin?: boolean;
  status: string;
  aptSuite?: string | number;
  isOnline?: boolean;
  email?: string;
  Avatar: {
    file: FileInfo;
  };
  Reviews: {
    count: number;
  };
  joinRequestId?: string;
  //listingsCount?: number;
  listingCount?: number;
  role?: TypeCommunity;
  adminLabel?: string;
  recommendations?: any;
};

export type CommunityMembersT = {
  id?: string;
  objectId?: string;
  countMembers?: number;
  AllMembers: MemberT[];
  Residents?: MemberT[];
  Admins?: MemberT[];
  Owner?: MemberT;
};

export type newCommunityMembersT = {
  id?: string;
  objectId?: string;
  newMembers?: MemberT[];
};

export interface memberJoinRequest extends ParseObject {
  status: string;
  objectId: string;
  User: MemberT;
}

export enum JoinRequestStatuses {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}

export enum JoinRequestManageAction {
  approve = 'approve',
  deny = 'deny',
}

export enum PeopleSearchType {
  Everyone = 'AllMembers',
  Managers = 'Owner',
  Admins = 'Admins',
  Residents = 'Residents',
  // MyProfile = 'MyProfile',
}

export type UserPartsT = {
  firstName?: string;
  firstLetter?: string;
  avatar?: string;
  objectId?: string;
};
