import React from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {MemoNotInCommunity} from '../../components/common/NotInCommunity';
import {PageLoaderWrapper} from '../../components/Events/styles';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {ViewEventItem} from './ViewEventItem';
import {currentCommunity} from '../../states/community';
import {NavContainer} from '../Navigation';

import {isEventInCommunity, useDataCreator, useGetEvent} from '../../hooks/event';

export const ViewEventContainer = () => {
  const {id} = useParams<{id?: string}>();
  const [community] = useRecoilState(currentCommunity);
  const {data: event, loading} = useGetEvent({id});
  const dataCreator = useDataCreator(event?.Lister);
  const exist = isEventInCommunity({event, commId: community?.objectId, default: true});

  if (loading && !event?.objectId) {
    return (
      <NavContainer>
        <PageLoaderWrapper>
          <LottieLoader allScreen={true} $isVisible={true} />
        </PageLoaderWrapper>
      </NavContainer>
    );
  }

  if (!exist && event?.objectId) {
    return <MemoNotInCommunity type={'listing'} />;
  }

  return <ViewEventItem event={event} id={id} dataCreator={dataCreator} community={community} loading={loading} />;
};
