import React, {SetStateAction, useEffect, useRef} from 'react';
import {
  BtnText,
  CommunitiesListWrapper,
  DarkBackground,
  DesktopWrapper,
  DropdownArrow,
  DropdownArrowWrapper,
  MobileWrapper,
  RelativeWrapper,
  SelectCommunityPopupWrapper,
  SelectCommunityWrapper,
  SettingsBtn,
  WrapperFlex,
} from './styles';
import {CommunityElement, UserCommunitySettings} from './components';
import {CustomCommunity, TLinks, UserCommunitySettingsProps} from './types';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {useTranslation} from 'react-i18next';
import {Br} from '../../../../ui-kit/Typography/Br';
import {useSetRecoilState} from 'recoil';
import {aliasPart} from '../../../../states/community';
import {useViewer} from '../../../../hooks/user';
import {useGetCommunityManagingInfo} from '../../../../hooks/community';
import {IsModalProvider} from '../../../../containers/Navigation/IsModalProvider';
import InfiniteScroll from 'react-infinite-scroller';
import {CommunitiesMenuSkeleton} from '../../../common/Skeletons/CommunitiesMenuSkeleton';

interface Props {
  communities: CustomCommunity[];
  links: TLinks;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  setCurrentCommunity: (id: string) => void;
  haveManagerRank: boolean;
  haveAdminRank: boolean;
  openSearch: () => void;
  maxCountCommunities: number;
  hasMoreItems?: boolean;
  loadingCommunities?: boolean;
  loadMore: () => Promise<void>;
  joinLoading?: boolean;
}

export type TProps = UserCommunitySettingsProps & Props;

export const CommunitiesDropdown: React.FC<TProps> = ({
  communities,
  links,
  setCurrentCommunity,
  setIsOpen,
  isOpen,
  openSearch,
  maxCountCommunities,
  hasMoreItems,
  loadMore,
  loadingCommunities,
  joinLoading,
  ...props
}) => {
  const {t} = useTranslation();
  const setAlias = useSetRecoilState(aliasPart);
  const viewer = useViewer();
  const communityCount = viewer?.Communities?.count || 0;
  const {owner: listedOwner, admin: listedAdmin} = useGetCommunityManagingInfo(viewer?.id);
  const onlyResident = !listedOwner && !listedAdmin;
  const showSearchCommunity = communityCount === 0 || (!onlyResident && Number(communityCount) < maxCountCommunities);
  const showCreateCommunity = Number(listedOwner || 0) >= 1 && Number(listedOwner || 0) < maxCountCommunities;
  const openDropdown = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const closeDropdown = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();
  useEffect(() => {
    setAlias(undefined);
  }, []);
  const ref = useRef<HTMLDivElement>(null);
  const handleGetScrollParent = () => {
    return ref.current;
  };

  return (
    <SelectCommunityWrapper>
      <RelativeWrapper>
        <UserCommunitySettings {...props} onClick={isOpen ? closeDropdown : openDropdown} />
        <DropdownArrowWrapper onClick={isOpen ? closeDropdown : openDropdown}>
          <DropdownArrow $isOpen={isOpen} />
        </DropdownArrowWrapper>
      </RelativeWrapper>
      {isOpen && (
        <DesktopWrapper>
          <CommunitiesListWrapper>
            <InfiniteScroll
              useWindow={false}
              getScrollParent={handleGetScrollParent}
              loadMore={loadMore}
              hasMore={(hasMoreItems && loadingCommunities) || !communities?.length ? true : hasMoreItems}
              loader={<CommunitiesMenuSkeleton />}>
              {communities.map((el) => (
                <CommunityElement
                  community={el}
                  key={el.objectId}
                  setCurrentCommunity={setCurrentCommunity}
                  isPending={!!el.pendingToJoin}
                  isInvited={!!el?.invited}
                  joinLoading={joinLoading}
                />
              ))}
            </InfiniteScroll>
          </CommunitiesListWrapper>
          <Br indent={20} />
          {showSearchCommunity && links.searchCommunity && (
            <SettingsBtn to={'#'} onClick={openSearch}>
              <WrapperFlex>
                <BtnText>{t('common:menu.searchCommunities')}</BtnText>
                <IconSvg type={'search-filled'} />
              </WrapperFlex>
            </SettingsBtn>
          )}
          {showCreateCommunity && links.createCommunity && (
            <SettingsBtn to={links.createCommunity}>
              <WrapperFlex>
                <BtnText>{t('settings:popup.buttons.createCommunity')}</BtnText>
                <IconSvg type={'add'} />
              </WrapperFlex>
            </SettingsBtn>
          )}
          <Br indent={12} />
        </DesktopWrapper>
      )}
      {isOpen && (
        <MobileWrapper>
          <IsModalProvider>
            <DarkBackground onClick={closeDropdown}>
              <SelectCommunityPopupWrapper onClick={stopPropagation} className={'Scroller'} ref={ref}>
                <InfiniteScroll
                  useWindow={false}
                  getScrollParent={handleGetScrollParent}
                  loadMore={loadMore}
                  hasMore={(hasMoreItems && loadingCommunities) || !communities?.length ? true : hasMoreItems}
                  loader={<CommunitiesMenuSkeleton />}>
                  {communities.map((el) => (
                    <CommunityElement
                      community={el}
                      key={el.objectId}
                      setCurrentCommunity={setCurrentCommunity}
                      isPending={!!el.pendingToJoin}
                      isInvited={!!el?.invited}
                      joinLoading={joinLoading}
                    />
                  ))}
                </InfiniteScroll>

                <Br indent={20} />

                {showSearchCommunity && links.searchCommunity && (
                  <SettingsBtn to={'#'} onClick={openSearch}>
                    <WrapperFlex>
                      <BtnText>{t('common:menu.searchCommunities')}</BtnText>
                      <IconSvg type={'search-filled'} />
                    </WrapperFlex>
                  </SettingsBtn>
                )}

                {showSearchCommunity && links.createCommunity && (
                  <SettingsBtn to={links.createCommunity}>
                    <WrapperFlex>
                      <BtnText>{t('settings:popup.buttons.createCommunity')}</BtnText>
                      <IconSvg type={'add'} />
                    </WrapperFlex>
                  </SettingsBtn>
                )}
                <Br indent={32} />
              </SelectCommunityPopupWrapper>
            </DarkBackground>
          </IsModalProvider>
        </MobileWrapper>
      )}
    </SelectCommunityWrapper>
  );
};
