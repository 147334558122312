import styled, {css} from 'styled-components';
import {theme} from '../theme';

export const RadioInputContainer = styled.label`
  display: inline-flex;
  position: relative;
  align-items: center;
  ${theme.font.primary.regular};
  cursor: pointer;
  width: auto;

  &:hover {
    & .custom-radio__icon i {
      background-color: ${({theme}) => theme.palette.grayscale._4};
    }
  }
`;

export const Icon = styled.svg`
  stroke-width: 1px;
`;

const checkedBackground = css`
  background-color: ${({theme}) => theme.palette.common.aqua} !important;
`;

export const HiddenRadio = styled.input.attrs({type: 'radio'})`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  &:checked {
    & ~ .custom-radio__icon {
      & i {
        ${checkedBackground};
      }
    }
  }
`;

export const HiddenCheck = styled.input.attrs({type: 'checkbox'})`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  &:checked {
    & ~ .custom-radio__icon {
      & i {
        ${checkedBackground};
      }
    }
  }
`;

export const StyledRadio = styled.div<{$isChecked?: boolean}>`
  width: 18px;
  height: 18px;
  aspect-ratio: 1 / 1;
  border: 1px solid ${({theme}) => theme.palette.grayscale._13};
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
`;

export const StyledCheck = styled.div<{$isChecked?: boolean}>`
  width: 18px;
  height: 18px;
  aspect-ratio: 1 / 1;
  border: 1px solid ${({theme}) => theme.palette.grayscale._13};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
`;

export const FilledCircle = styled.i`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  transition: 0.1s all;
`;

export const FilledSquare = styled.i`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  transition: 0.1s all;
`;

export const RadioText = styled.span`
  font-size: 14px;
  width: auto;
  max-width: 100%;
  color: ${({theme}) => theme.palette.grayscale._6};
`;
