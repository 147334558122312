import {Event} from '../queries/types/event';
import {FileInfo} from '../queries/types/parse';
import {User} from '../queries/types/user';
import {StatusOrderType} from '../queries/types/order';
import {TResManageOrder} from '../hooks/order';
import {PointerFile} from './common';

export enum EventFormAction {
  create = 'create',
  edit = 'edit',
  view = 'view',
}

export enum EventOrderStatus {
  attending = 'attending',
  cancelled = 'cancelled',
}

export enum EventFields {
  objectId = 'objectId',
  name = 'name',
  rewardType = 'rewardType',
  eventDate = 'eventDate',
  descr = 'descr',
  endTime = 'endTime',
  startTime = 'startTime',
  locationDetails = 'locationDetails',
  showAttendees = 'showAttendees',
  images = 'images',
  pricePeriod = 'pricePeriod',
  isPublished = 'isPublished',
  isDeleted = 'isDeleted',
  rating = 'rating',
  totalViews = 'totalViews',
  maxAttendees = 'maxAttendees',
  allowGuests = 'allowGuests',
  maxGuestsPerAttendee = 'maxGuestsPerAttendee',
  Featured = 'Featured',
  AdmHidden = 'AdmHidden',
  Published = 'Published',
  Updated = 'Updated',
  Reviews = 'Reviews',
  Location = 'Location',
  totalAttendees = 'totalAttendees',
  Attendees = 'Attendees',
  Lister = 'Lister',
  openTo = 'openTo',
  eventType = 'eventType',
  expiresDate = 'expiresDate',
}

export type TImage = {
  id?: string;
  value?: File | string;
  order?: number;
  file?: FileInfo;
};

type NewEventFields = {
  images?: TImage[];
  serverImages?: Partial<PointerFile>[];
  Attendees?: User[];
};

type CreateParseFields = {
  images?: (Partial<PointerFile> | undefined)[];
  Lister: {link: string};
};

type EditParseFields = {
  images?: (Partial<PointerFile> | undefined)[];
};

export type EventFormValues = Omit<Pick<Event, EventFields>, keyof NewEventFields> & NewEventFields;
export type EventFormField = keyof EventFormValues;
export type EventFormValue = EventFormValues[EventFormField];
export type CreateEventFieldsInput = Omit<Partial<EventFormValues>, keyof CreateParseFields> & CreateParseFields;
export type EditEventFieldsInput = Omit<Partial<EventFormValues>, keyof EditParseFields> & EditParseFields;
export type TQueryEvent = Event;

export type TEvent = Omit<Event, keyof NewEventFields> & NewEventFields;

export type TableEventItem = {
  Attendee?: Partial<User>;
  unit?: number | string;
  guests?: number;
  status?: StatusOrderType;
  updatedAt?: Date;
  objectId?: string;
  isManager?: boolean;
  manage?: TResManageOrder;
  orderId?: string;
  isBooked?: boolean;
  menu?: string;
};

export enum EventCardType {
  request = 'request',
}

export type PreEventOrderCalcDataT = {
  eventCost: number;
  sfRequesterAmt: number;
  ppRequesterAmt: number;
  requesterTotalAmt: number;
};
