import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Media} from '../../../ui-kit/theme';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {PostTypeT} from '../../../types/post';
import {InputsProps} from './types';
import defaultAvatar from './assets/defaultAvatar.png';
import {HiddenElement} from '../Hidden/HiddenElement';
import {PostActions} from './components/PostActions';
import {CloseButtonMobile} from './components/CloseButtonMobile';
import {MobileMediaPanel} from './MobileMediaPanel';
import {
  Avatar,
  ErrorLine,
  InputActionsLeftSide,
  InputActionsWrapper,
  InputContainer,
  InputMessageWrapper,
  InputTopWrapper,
  TextMessageInput,
  UsersMenuWrapper,
} from '../../Loop/PostCreate/styles';
import {ChangePostTypeIcon, InputsAvatarWrapper} from './styles';

export const VideoInput: React.FC<InputsProps> = ({
  user,
  resetType,
  handleChange,
  handleSubmit,
  values,
  error,
  handleChangePostType,
  inputType,
  menuOptions,
  isEdit,
  ManagerOrAdmin,
}) => {
  const {t} = useTranslation();
  const placeholder = t('community:loop.inputs.placeholderVideoInput');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const removeDisable = () => setBtnDisabled(false);
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    !btnDisabled && handleSubmit?.();
    setBtnDisabled(true);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange?.({name: 'text', value: e.target.value});
    removeDisable();
  };

  useEffect(() => {
    if (isEdit) return;
    handleChange?.({name: 'type', value: PostTypeT.video});
    handleChange?.({name: 'text', value: ''});
    handleChange?.({name: 'sentAs', value: user?.postAs});
    removeDisable();
  }, []);
  return (
    <InputContainer $isEdit={isEdit}>
      <InputTopWrapper>
        <InputsAvatarWrapper>
          <Avatar src={user?.avatar || defaultAvatar} size={'md'} />
          {ManagerOrAdmin && (
            <ChangePostTypeIcon tabIndex={-1}>
              <UsersMenuWrapper>
                <MenuButton
                  item={{objectId: user?.postAs}}
                  menu={Menu}
                  options={menuOptions}
                  right={true}
                  prevent={true}
                  alwaysShowBtn={true}
                  customIcon={<IconSvg type={'chevrone-down'} width={'20'} height={'20'} viewBox={'0 0 20 20'} />}
                />
              </UsersMenuWrapper>
            </ChangePostTypeIcon>
          )}
        </InputsAvatarWrapper>
        <InputMessageWrapper>
          <TextMessageInput placeholder={placeholder} onChange={onChange} value={values?.text} />
          <IconSvg type={'video'} className={'post-input_icon'} />
        </InputMessageWrapper>
        <CloseButtonMobile resetType={resetType} postType={inputType} />
      </InputTopWrapper>
      {error?.message && <ErrorLine>{t(error.message)}</ErrorLine>}
      <InputActionsWrapper>
        <MobileMediaPanel
          handleChangePostType={handleChangePostType}
          activeType={inputType}
          values={values}
          user={user}
        />
        <HiddenElement mq={Media.down.l}>
          <InputActionsLeftSide />
        </HiddenElement>
        <PostActions resetType={resetType} handleSubmit={onSubmit} btnDisabled={btnDisabled} isEdit={isEdit} />
      </InputActionsWrapper>
    </InputContainer>
  );
};
