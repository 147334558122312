import React, {useState} from 'react';
import {MouseEventHandler} from 'react';
import {IconSvg} from '../Icon/Svg';
import {TransparentButton} from './styles';
import {MobileMenu, MobileMenuProps} from '../Menu/MobileMenu';
import {BasePortal} from '../Popup';

const EmptyProvider: React.FC = ({children}) => <>{children}</>;

export const MobileMenuButton: React.FC<Omit<MobileMenuProps, 'closeMenu'>> = ({
  entries,
  isPortal,
  toggleCallback,
  portalProps,
  customIcon,
  portalProvider,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu: MouseEventHandler = (event) => {
    event.preventDefault();
    setShowMenu(true);
    toggleCallback?.(true);
  };

  const closeMenu = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowMenu(false);
    toggleCallback?.(false);
  };
  const PortalProvider = portalProvider ? portalProvider : EmptyProvider;
  return (
    <>
      {showMenu ? (
        isPortal ? (
          <BasePortal {...portalProps}>
            <PortalProvider>
              <MobileMenu entries={entries} closeMenu={closeMenu} />
            </PortalProvider>
          </BasePortal>
        ) : (
          <MobileMenu entries={entries} closeMenu={closeMenu} />
        )
      ) : (
        <TransparentButton onClick={openMenu}>
          {customIcon ? (
            customIcon
          ) : (
            <IconSvg type="more" width="6px" height="21px" viewBox="0 0 6 21" stroke="none" fill={'grayLight'} />
          )}
        </TransparentButton>
      )}
    </>
  );
};
