import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {MemoLabelCard} from './LabelCard';
import {
  ContainerListingCard,
  HiddenMenu,
  IconStar,
  InnerListingCard,
  InnnerListingCardBtns,
  MenuButtonWrapper,
} from './styles';
import {route} from '../../constants/routes';
import {
  getActualEventDate,
  getMenuEvents,
  GetMenuEventsFuncOptions,
  GetMenuEventsOptions,
  getMobileMenuEvents,
} from '../../helpers/event';
import {getIsIncludesCmnt} from '../../helpers/item';
import {IGetCurrValue} from '../../types/common';
import {TEvent} from '../../types/event';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {Media} from '../../ui-kit/theme';
import {Link} from '../../ui-kit/Typography/Link';
import {PopupView} from '../common/Modal/PopupView';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {PhotoLoaderProps} from '../common/PhotoCard/PhotoLoader';
import {useLinks} from '../../hooks/common';
import {isEventInCommunity} from '../../hooks/event';
import {entities, reportActions} from '../../hooks/report';
import {getResize} from '../../helpers/file';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {LikeState} from '../../hooks/item';
import {LikeButton, ShapeWrapper} from '../Listings/styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {EventRepeatType} from '../../queries/types/event';

type EventCard = {
  to: string;
  editPath?: string | null;
  event: Partial<TEvent>;
  src?: string;
  communityId?: string;
  onDelete?: (id?: string) => Promise<boolean>;
  viewerId?: string;
  isHideLabel?: boolean;
  isManager?: boolean;
  isOwner?: boolean;
  reportActions?: reportActions;
  isCardInPost?: boolean;
  getSignValue: IGetCurrValue;
  onUnPublish?: (id?: string) => () => Promise<void>;
  onPublish?: (id?: string) => () => Promise<void>;
  onLike?: (id: string) => void;
  liked?: LikeState;
  likeDisabled?: boolean;
  eventType?: EventRepeatType;
  isReview?: boolean;
};

export const EventCard: React.FC<PhotoLoaderProps & EventCard> = ({
  to,
  src,
  event,
  aspectRatio,
  communityId,
  isOwner,
  isCardInPost,
  onDelete,
  onUnPublish,
  onPublish,
  reportActions,
  isManager,
  viewerId,
  liked,
  onLike,
  likeDisabled,
  eventType,
  isReview,
}) => {
  const {t} = useTranslation();
  const {pricePeriod, name, rewardType} = event;
  const isPublished = isReview || isEventInCommunity({event, commId: communityId});
  const isFeatured = getIsIncludesCmnt(event?.Featured, communityId);
  const isOpacity = !isPublished;
  const {getLink} = useLinks();
  const preventRedirect = (e: React.SyntheticEvent) => e.preventDefault();
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);

  const unPublish = onUnPublish?.(event.objectId);
  const publish = onPublish?.(event.objectId);
  const handleLike = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    event?.objectId && onLike?.(event.objectId);
  };
  const isLiked = event?.objectId ? liked?.[event?.objectId] : false;
  const reportParams = useMemo(() => {
    return {
      title: t('common:report.title', {entity: t(`common:entities.${entities.event}`)}),
      subtitle: t('common:report.subtitle'),
      okButton: t('common:report.confirmReport'),
      textAreaLabel: t('common:report.textAreaLabel'),
      close: () => {
        setIsShowReportModal(false);
      },
      onOk: (text: string) => {
        const onSuccess = () => {
          setIsShowReportModal(false);
          setIsShowInfoModal(true);
        };

        return reportActions?.report?.({id: event?.objectId, onSuccess, text});
      },
    };
  }, [reportActions?.report, event?.objectId]);

  const infoParams = useMemo(() => {
    return {
      title: t('common:report.success.title', {entity: t(`common:entities.${entities.event}`)}),
      subtitle: t('common:report.success.subtitle'),
      close: () => {
        setIsShowInfoModal(false);
      },
    };
  }, []);

  const report = () => {
    setIsShowReportModal(true);
  };

  const del = async () => {
    await onDelete?.(event.objectId);
    setShowModalDelete(false);
  };

  const getMenuEventsOptions: GetMenuEventsOptions = useMemo(() => {
    return {
      objectId: event?.objectId,
      editLink: getLink(route.editEvent.get({id: event.id || '/'})),
      previewLink: getLink(route.eventView.get({id: event.id || '/'})),
      guestLink: getLink(route.eventViewEdit.get({id: event.id || '/'})),
      isPublished,
      isOwner: isOwner || isManager || event.Lister?.objectId === viewerId,
      isEventOwner: event.Lister?.objectId === viewerId,
      isCommOwner: isOwner || false,
    };
  }, [event, isOwner, isManager]);
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  const getMenuItemsFunctions: GetMenuEventsFuncOptions = {
    t,
    deleteCallBack: handleOpenDelete,
    unPublishCallBack: unPublish,
    publishCallBack: publish,
    reportCallBack: report,
  };

  const menuItems: MenuItemType[] = [...getMenuEvents(getMenuEventsOptions, getMenuItemsFunctions)];
  const mobileMenuItems: MobileMenuEntry[] = [...getMobileMenuEvents(getMenuEventsOptions, getMenuItemsFunctions)];

  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('common:modals.delete.title'),
        okText: t('common:modals.delete.button'),
        cancelText: t('common:modals.delete.close'),
      },
    };
  }, []);
  const actualDate = useMemo(() => getActualEventDate(event?.startTime, eventType), [!!event?.startTime]);
  const lister = event?.Lister;
  const authorName = lister?.firstName ? lister?.firstName + ' ' + (lister?.firstLetter || '') : '';
  return (
    <>
      <ContainerListingCard>
        <Link to={to}>
          <InnerListingCard $isOpacity={isOpacity} $isReview={isReview}>
            <PhotoCard aspectRatio={aspectRatio} src={getResize(src, 'md')} fallbackSrc={src} isListing={isReview} />
            {isFeatured && <IconStar type={'star'} fill={'orange'} stroke={'white'} />}
            <MemoLabelCard
              rewardType={rewardType}
              pricePeriod={pricePeriod}
              name={name}
              showDate={true}
              date={actualDate}
              eventType={eventType}
              isReview={isReview}
              authorName={authorName}
              verified={lister?.isVerified}
            />
          </InnerListingCard>
          {!isReview && (
            <InnnerListingCardBtns>
              <MenuButtonWrapper onClick={preventRedirect}>
                {((!!menuItems.length && !isCardInPost) || event.Lister?.objectId === viewerId) && (
                  <HiddenMenu mq={Media.down.s}>
                    <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
                  </HiddenMenu>
                )}
                {((!!mobileMenuItems.length && !isCardInPost) || event.Lister?.objectId === viewerId) && (
                  <HiddenMenu mq={Media.up.m}>
                    <MobileMenuButton entries={mobileMenuItems} isPortal={true} portalProvider={IsModalProvider} />
                  </HiddenMenu>
                )}
              </MenuButtonWrapper>
            </InnnerListingCardBtns>
          )}
          {!likeDisabled && isPublished && !isReview && (
            <LikeButton onClick={handleLike}>
              <ShapeWrapper>
                <IconSvg type="shape" fill={isLiked ? 'red' : 'none'} stroke={isLiked ? 'none' : 'grayLight'} />
              </ShapeWrapper>
            </LikeButton>
          )}
        </Link>
      </ContainerListingCard>
      {isShowModalDelete && (
        <PopupView>
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={del}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        </PopupView>
      )}
      {isShowReportModal && (
        <PopupView>
          <Confirmation
            isHaveTextArea
            title={reportParams.title}
            subTitle={reportParams.subtitle}
            buttonType={'danger'}
            textAreaLabel={reportParams.textAreaLabel}
            okText={reportParams.okButton}
            onClose={reportParams.close}
            onOk={reportParams.onOk}
          />
        </PopupView>
      )}
      {isShowInfoModal && (
        <PopupView>
          <Confirmation
            title={infoParams.title}
            subTitle={infoParams.subtitle}
            buttonType={'primary'}
            onClose={infoParams.close}
          />
        </PopupView>
      )}
    </>
  );
};
