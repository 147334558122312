import {TCommunity} from './community';
import {MemberT} from './people';
import {localeType} from '../libs/i18nextUtils';
import {Group} from '../queries/types/group';
import {FileInfo, ParseObject} from '../queries/types/parse';
import {MediumTypes, NotificationPostData, Post} from '../queries/types/post';
import {User} from '../queries/types/user';

import {reportActions} from '../hooks/report';
import {PointerFile} from './common';

export enum PostTypeT {
  all = 'all',
  items = 'items',
  notification = 'notification',
  announcement = 'announcement',
  message = 'post',
  request = 'request',
  poll = 'poll',
  video = 'video',
  comment = 'comment',
  event = 'event',
  amenity = 'amenity',
  group = 'group',
  company = 'company',
  document = 'document',
  recommendation = 'recommendation',
  myPosts = 'myPosts',
}

export interface TPost extends ParseObject {
  Community?: TCommunity;
  type?: PostTypeT;
  text?: string;
  sentAs?: string;
  Author?: MemberT;
  Attachments?: PointerFile[];
  Items?: Partial<TPostItem>[];
  Hide?: any;
  Pin?: any;
  Seen?: any;
  isDeleted?: boolean;
  likes?: number;
  waves?: number;
  smiles?: number;
  comments?: number;
  Options?: TPollOption[];
  data?: NotificationPostData;
  isLiked?: boolean;
  isWaved?: boolean;
  isSmiled?: boolean;
  isHidden?: boolean;
  translates?: Record<localeType, string>;
  originalLang?: localeType;
  Group?: Partial<Group>;
  isMulti?: boolean;
  medium?: MediumTypes[];
  notShow?: boolean;
  localRef?: string;
  isLocal?: boolean;
  localCreationData?: any;
  isError?: boolean;
}

export interface TPollOption extends ParseObject {
  id?: string;
  objectId?: string;
  Voted?: User[];
  Post?: string;
  countVotes?: number;
  text?: string;
  position?: number;
  isVoted?: boolean;
  translates?: Record<localeType, string>;
  originalLang?: localeType;
}

export enum PostFields {
  text = 'text',
  Attachments = 'Attachments',
  type = 'type',
  isMulti = 'isMulti',
  medium = 'medium',
}

export type TAttachment = {
  id?: string;
  value?: File | string;
  file?: FileInfo | Partial<PointerFile['file']>;
};

export type NewPostOptions =
  | {
      text?: string;
    }
  | undefined;

type NewPostFields = {
  text?: string;
  type?: PostTypeT;
  isMulti?: boolean;
  sentAs?: 'resident' | 'manager';
  Attachments?: TAttachment[];
  serverAttachments?: Partial<PointerFile>[];
  options?: NewPostOptions[];
  Parent?: {
    link: string;
  };
  medium?: {
    loop?: boolean;
    email?: boolean;
    push?: boolean;
    sms?: boolean;
    message?: boolean;
  };
};

type CreateParseFields = {
  Author?: {
    link: string;
  };
  Community?: {
    link: string;
  };
  Parent?: {
    link: string;
  };
  Group?: {
    link: string;
  };
  Attachments?: (Partial<PointerFile> | undefined)[];
  medium?: MediumTypes[];
};

export type PostFormValues = Omit<Pick<Post, PostFields>, keyof NewPostFields> & NewPostFields;
export type PostFormField = keyof PostFormValues;
export type PostFormValue = PostFormValues[PostFormField];
export type CreatePostFieldsInput = Omit<Partial<PostFormValues>, keyof CreateParseFields> & CreateParseFields;
export type TQueryPost = Post;
export type TPostItem = Omit<Post, keyof NewPostFields> & NewPostFields;
export interface PostActions extends reportActions {
  pinUnpin?: (postId: string) => void;
}

export type RecommendationDataT = {eatery: string; movies: string; places: string};
export type RecType = 'eatery' | 'movies' | 'places';
