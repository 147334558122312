import {gql} from '@apollo/client';
import MemberFragment from './people';

export const AmenityFragment = gql`
  fragment AmenityFragment on Amenity {
    id
    objectId
    createdAt
    updatedAt
    name
    descr
    rewardType
    allowGuests
    isBookable
    rewardType
    maxResidentsPerBooking
    maxBookingsPerDay
    autoBooking
    bookingSlots
    maxBookingPerSlot
    bookingPeriod
    bookingTiming
    popularTime
    popularDay
    isPublished
    isDeleted
    totalBookings
    totalViews
    Published {
      ... on Community {
        id
        objectId
      }
    }
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Lister {
      ...MemberFragment
    }
  }
  ${MemberFragment}
`;
