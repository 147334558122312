import {ApolloArrayElement, ParseObject} from './parse';
import {Community} from './community';

interface UserPartData {
  id: string;
  objectId: string;
  Avatar: {
    file: {
      name: string;
      url: string;
    };
  };
}

export interface Notification extends ParseObject {
  event: string;
  data: {
    personFirstName?: string;
    personLastName?: string;
    communityName?: string;
    communityId?: string;
    post?: string;
    comment?: string;
    listingName?: string;
    totalDays?: number;
    orderType?: string;
  };
  status: string;
  to: UserPartData[];
  from: UserPartData;
  Community: Community;
}

export interface NotificationSettingsType {
  notifyDigest: ApolloArrayElement<string>[];
  notifyJoinRequest: ApolloArrayElement<string>[];
  notifyLikeOrComment: ApolloArrayElement<string>[];
  notifyListingView: ApolloArrayElement<string>[];
  notifyListingsRequest: ApolloArrayElement<string>[];
  notifyLoopPost: ApolloArrayElement<string>[];
  notifyManagerAdminPost: ApolloArrayElement<string>[];
  notifyMarketingAndFeatures: ApolloArrayElement<string>[];
  notifyMessage: ApolloArrayElement<string>[];
  notifyNewCommunities: ApolloArrayElement<string>[];
  notifyNewListings: ApolloArrayElement<string>[];
  notifyNewMembers: ApolloArrayElement<string>[];
  notifyNewRequests: ApolloArrayElement<string>[];
  notifyNewResidentListings: ApolloArrayElement<string>[];
  notifyNewResidents: ApolloArrayElement<string>[];
  notifyNewTransaction: ApolloArrayElement<string>[];
  notifyOrderRequest: ApolloArrayElement<string>[];
  notifyProfileView: ApolloArrayElement<string>[];
  //new
  notifyNewMember: ApolloArrayElement<string>[];
  notifyAmenityReminder: ApolloArrayElement<string>[];
  notifyEventReminder: ApolloArrayElement<string>[];
  notifyHelpCentre: ApolloArrayElement<string>[];
  notifyNewListing: ApolloArrayElement<string>[];
  notifyEventAttendees: ApolloArrayElement<string>[];
  notifyUnmanagedAmenities: ApolloArrayElement<string>[];
  notifyManagedAmenities: ApolloArrayElement<string>[];
}

export enum NotificationsEvents {
  newRequest = 'newRequest',
}
