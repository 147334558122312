import React from 'react';
import {IReport} from '../../hooks/report';
import {CommunityStateType} from '../../states/community';
import {TCompany} from '../../types/company';
import {EmptyState} from '../EmptyState/EmptyState';
import {ListCompanies} from './ListCompanies';
import {MobileSearch} from './MobileSearch';
import {Search} from './Search';
import {WrapperPageLocalBiz, WrapperSearch} from './styles';
import {SetCompanySearchParams} from './types';

interface IProps {
  community: CommunityStateType;
  userId?: string;
  loading?: boolean;
  companies?: Array<TCompany>;
  fetchData: () => void;
  hasMore: boolean;
  setSearchParams: SetCompanySearchParams;
  onReport: IReport;
}

export const PageResident: React.FC<IProps> = ({
  companies,
  hasMore,
  fetchData,
  community,
  loading,
  setSearchParams,
  userId,
  onReport,
}) => {
  return (
    <>
      <WrapperPageLocalBiz>
        <WrapperSearch>
          <Search setSearchParams={setSearchParams} cantAdd />
          <MobileSearch setSearchParams={setSearchParams} />
        </WrapperSearch>
        {!companies?.length && !loading ? (
          <EmptyState />
        ) : (
          <ListCompanies
            communityId={community?.id}
            items={companies}
            fetchData={fetchData}
            hasMore={hasMore}
            loading={loading}
            userId={userId}
            onReport={onReport}
          />
        )}
      </WrapperPageLocalBiz>
    </>
  );
};
