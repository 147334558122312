import React, {useEffect, useRef} from 'react';
import {MobTabMsgs, TContact} from '../../types/messages';
import {EmptyCard} from '../../ui-kit/Chat/EmptyCard';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {InnerContacts, InnerEmptyCard} from './styles';
import {useTranslation} from 'react-i18next';
import {MessagePreviewCard} from '../../ui-kit/Chat/Messages/MessagePreviewCard';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {SkeletonLoader} from './Sceleton/Loader';
import {SkeletonContactCard} from './Sceleton/Card/ContactCard';
import {useLinks} from '../../hooks/common';
import {getResize} from '../../helpers/file';
import linkifyHtml from 'linkifyjs/html';
import {getClearAvatar} from '../../helpers/message';

type TProps = {
  contactsList?: TContact[] | null;
  setActiveContact: (user: TContact | null) => void;
  setActiveMobTab?: (tab: MobTabMsgs | null) => void;
  contactsLoading: boolean;
  activeContact?: TContact | null;
  setScrolling?: (val: boolean) => void;
};

export const ContactListContent: React.FC<TProps> = ({
  contactsList,
  contactsLoading,
  setActiveMobTab,
  activeContact,
  setScrolling,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleActiveTab = () => {
    if (setActiveMobTab) {
      setActiveMobTab(MobTabMsgs.chat);
    }
  };
  useEffect(() => {
    let currentPos = 0;
    const interval: NodeJS.Timeout = setInterval(() => {
      const currentPosition = scrollRef?.current?.getBoundingClientRect().top || 0;
      if (currentPosition !== currentPos) {
        setScrolling?.(true);
        currentPos = currentPosition;
      } else {
        setScrolling?.(false);
        currentPos = currentPosition;
      }
    }, 200);
    return () => clearInterval(interval);
  }, [!!scrollRef.current]);
  if (!contactsList?.length && contactsLoading) {
    return <SkeletonLoader card={SkeletonContactCard} />;
  }

  if (!contactsList?.length && !contactsLoading) {
    return (
      <InnerEmptyCard>
        <EmptyCard
          title={t('messages:emptyState.messaging.text1')}
          description={t('messages:emptyState.messaging.text2')}
          imageSvg={
            <IconSvg stroke={'none'} type={'msg-sent-light'} width={'200px'} height={'200px'} viewBox="0 0 200 200" />
          }
        />
      </InnerEmptyCard>
    );
  }

  return (
    <InnerContacts>
      {contactsList?.map((it) => {
        let formattedText = it.dataMsg?.text?.replace(/<\/?[^>]+(>|$)/g, '');
        formattedText = '<p>' + formattedText + '</p>';
        const clearedAvatar = getClearAvatar(it.avatar);
        return (
          <Link key={it.objectId} to={getLink(route.messages.get({contactId: it.objectId}))} onClick={handleActiveTab}>
            <MessagePreviewCard
              messageDate={it.dataMsg?.date}
              userName={it.name}
              bordered={true}
              fullWidth={true}
              userAvatar={getResize(clearedAvatar, 'md')}
              onlineStatus={it.isOnline}
              messageText={formattedText}
              requestStatus={it.dataMsg?.orderStatus && t('messages:reqStatus', {context: it.dataMsg.orderStatus})}
              unSeenedMsg={it.dataMsg?.haveUnseenMsg}
              isActive={it.objectId === activeContact?.objectId}
              linkifyHtml={linkifyHtml}
            />
          </Link>
        );
      })}
      <div ref={scrollRef} />
    </InnerContacts>
  );
};
