import {useMutation} from '@apollo/client';
import {
  CodeVerificationRequest,
  CodeVerificationResponse,
  PhoneVerificationRequest,
  PhoneVerificationResponse,
  VerifyCode,
  VerifyPhone,
} from '../queries/auth';
import {analyticsTrackFN} from '../helpers/account';

interface VerificationOptions {
  onSuccess: () => void;
  onError: (error?: any) => void;
}

export const useVerifyPhone = (options: VerificationOptions) => {
  const [mutate, otherData] = useMutation<PhoneVerificationResponse, PhoneVerificationRequest>(VerifyPhone);

  const sendCode = async (phone: string) => {
    try {
      const response = await mutate({variables: {phone}});

      if (response.data?.verifyPhone.ok) {
        options.onSuccess();
        return true;
      } else {
        options.onError();
        return false;
      }
    } catch (err) {
      options.onError();
      return false;
    }
  };

  return {sendCode, otherData};
};

export const useVerifyCode = (options: VerificationOptions) => {
  const [mutate, otherData] = useMutation<CodeVerificationResponse, CodeVerificationRequest>(VerifyCode);

  const verifyCode = async (phone: string, code: string) => {
    try {
      const response = await mutate({variables: {input: {phone, code}}});

      if (response.data?.verifyCode.ok) {
        options.onSuccess();
        analyticsTrackFN('Phone Verified');
        return true;
      } else {
        options.onError();
        return false;
      }
    } catch (err) {
      options.onError();
      return false;
    }
  };

  return {verifyCode, otherData};
};

export const useVerification = (optionsPhone: VerificationOptions, optionsCode: VerificationOptions) => {
  const {sendCode, otherData: sendCodeData} = useVerifyPhone(optionsPhone);
  const {verifyCode, otherData: verifyCodeData} = useVerifyCode(optionsCode);

  return {sendCode, verifyCode, sendCodeData, verifyCodeData};
};
