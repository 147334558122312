import React from 'react';
import {ToggleLabel, ToggleText, ToggleWrapper} from './styles';

type ToggleButtonPropsT = {
  checked?: boolean;
  onChange: (value: boolean) => void;
  label?: string;
};

export const ToggleButton: React.FC<ToggleButtonPropsT> = ({onChange, checked, label, ...props}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);
  return (
    <ToggleWrapper {...props}>
      <ToggleLabel $isChecked={checked}>
        <input type="checkbox" onChange={handleChange} checked={checked} />
        <div></div>
      </ToggleLabel>
      {label && <ToggleText>{label}</ToggleText>}
    </ToggleWrapper>
  );
};
