import React from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {PageLoaderWrapper} from '../../components/Amenities/styles';
import {MemoNotInCommunity} from '../../components/common/NotInCommunity';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {ViewAmenityItem} from './ViewAmenityItem';
import {currentCommunity} from '../../states/community';
import {NavContainer} from '../Navigation';

import {isAmenityInCommunity, useDataCreator, useGetAmenity} from '../../hooks/amenity';

export const ViewAmenityContainer = () => {
  const {id} = useParams<{id?: string}>();
  const [community] = useRecoilState(currentCommunity);
  const {data: amenity, loading} = useGetAmenity({id});
  const dataCreator = useDataCreator(amenity?.Lister);
  const exist = isAmenityInCommunity({amenity, commId: community?.objectId, default: true});

  if (loading && !amenity?.objectId) {
    return (
      <NavContainer>
        <PageLoaderWrapper>
          <LottieLoader $isVisible={true} allScreen={true} />
        </PageLoaderWrapper>
      </NavContainer>
    );
  }

  if (!exist && amenity?.objectId) {
    return <MemoNotInCommunity type={'listing'} />;
  }

  return (
    <ViewAmenityItem amenity={amenity} id={id} dataCreator={dataCreator} community={community} loading={loading} />
  );
};
