import React from 'react';
import {ActionIcon, Button, CheckContent, CheckWrapper, IsCompleteIcon} from './styles';
import {useHistory} from 'react-router-dom';
import {Link} from '../../ui-kit/Typography/Link';
import {routes} from '../../containers/Navigation/constans';

interface Props {
  name?: string;
  isComplete?: boolean;
  to?: any;
  onClick?: () => void;
  onClose?: () => void;
  isActiveState?: boolean;
}

const AdaptiveButton: React.FC<Props> = ({isComplete, onClick: OnClick, to, children, isActiveState, onClose}) => {
  const history = useHistory();

  const handleRedirectOnCurrentPage = () => {
    if (to) history.push(to);
    onClose?.();
  };

  const isLinkOnCurrentPage =
    history.location.pathname === to?.split('?')?.[0] && to?.split('?')?.[1] !== routes.verifyUser;
  const onClick = isLinkOnCurrentPage && !isComplete ? handleRedirectOnCurrentPage : OnClick;
  return (isComplete || isLinkOnCurrentPage) && isActiveState ? (
    <Button onClick={onClick}>{children}</Button>
  ) : to && isActiveState ? (
    <Link to={to}>{children}</Link>
  ) : (
    <>{children}</>
  );
};

export const AccountCheck: React.FC<Props> = (props) => {
  const {isComplete, name} = props;
  return (
    <AdaptiveButton {...props}>
      <CheckWrapper>
        <IsCompleteIcon $isComplete={isComplete} />
        <CheckContent>{name}</CheckContent>
        <ActionIcon type={isComplete ? 'close' : 'arrow-left'} />
      </CheckWrapper>
    </AdaptiveButton>
  );
};
