import React from 'react';
import {AccountCheckListType} from '../../queries/types/checklist';
import {Br} from '../../ui-kit/Typography/Br';
import {useLinks} from '../../hooks/common';
import {useTranslation} from 'react-i18next';
import {AccountCheck} from './AccountCheck';
import {ChecklistContainer} from './styles';
import {useGetCheckListInfo} from '../../hooks/checklist';

interface TProps {
  checkList?: AccountCheckListType;
  hideCompletedCheck?: (nameField: keyof AccountCheckListType) => () => void;
  onClose?: () => void;
}

export const Checklist: React.FC<TProps> = ({checkList, hideCompletedCheck, onClose}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {actualChecklist, completedCheckList} = useGetCheckListInfo();
  return (
    <ChecklistContainer>
      {!!actualChecklist?.length && completedCheckList && (
        <>
          {actualChecklist.map((el) => {
            return (
              <AccountCheck
                onClose={onClose}
                key={el.dataKey}
                to={getLink(el.to)}
                name={t(`notifications:checklist.${el.dataKey}`)}
                isComplete={checkList?.[el.dataKey]?.isChecked}
                onClick={hideCompletedCheck && hideCompletedCheck(el.dataKey)}
                isActiveState={checkList ? !el.isInactiveState?.(checkList) : true}
              />
            );
          })}
          <Br indent={1} />
        </>
      )}
    </ChecklistContainer>
  );
};
