import React, {useEffect, useMemo} from 'react';
import {
  ButtonWrapper,
  CardContentWrapper,
  CardInfoText,
  CardWrapper,
  CardTitle,
  DateWrapperCard,
  ErrorMessage,
  NotAvailable,
} from '../../../ui-kit/RequestCard/styles';
import {bookingDay, TAmenity} from '../../../types/amenity';
import {ButtonLabelEntry, PickDateTypes} from '../../../ui-kit/RequestCard/types';
import {DatepickerLine, LabelsWrapper} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Button} from '../../../ui-kit/Button/Button';
import {CounterInput} from '../../common/CounterInput';
import {useTranslation} from 'react-i18next';
import {variant} from '../../../ui-kit/Labels/types';
import {Label} from '../../../ui-kit/Labels';
import {orderAmenityChangeData} from '../../../hooks/amenity';
import {SuccessAmenityMessageWrapper, SuccessAmenityText} from './styles';
import {LinkButton} from '../../../ui-kit/Button/LinkButton';
import {DateTimePicker} from '../../../ui-kit/RequestCard/DateBlock/DateTimePicker';
import {format} from 'date-fns';
import {checkSlots, filterAmenityTime, getTime12to24} from '../../../helpers/amenity';
import {CardTitleWrapper} from '../../Events/EventRequestCard/styles';

type AmenityCardProps = {
  amenity?: Partial<TAmenity>;
  tags: {type: variant; label: string}[];
  successOrder?: string | null;
  requestSent?: string;
  pathLink?: string;
  linkTitle?: string;
  isAvailable?: boolean;
  message?: {
    error?: string | null;
    success?: string | null;
  };
  buttonLabel?: ButtonLabelEntry;
  descr?: string;
  notAvailableText?: string;
  orderChangeData?: orderAmenityChangeData;
  busyDates?: Date[];
};

export const AmenityRequestCard: React.FC<AmenityCardProps> = ({
  isAvailable,
  amenity,
  pathLink,
  successOrder,
  tags,
  linkTitle,
  message,
  buttonLabel,
  descr,
  notAvailableText,
  orderChangeData,
  busyDates,
}) => {
  const {t} = useTranslation();
  const pickerPlaceholders = useMemo(() => {
    return {
      addTime: t('requests:addTime'),
      addDate: t('requests:addDate'),
      addHours: t('requests:addHours'),
    };
  }, []);
  const pickerLabels = useMemo(() => {
    return {
      labelTime: t('requests:labelTime'),
      labelPickUp: t('requests:pickUp'),
      labelDate: t('requests:labelDate'),
      labelDuration: t('requests:labelDuration'),
      labelHours: t('requests:labelHours'),
    };
  }, []);
  const filterDate = (date: Date) => {
    const day = format(date, 'iii').toLowerCase() as bookingDay;
    return !!amenity?.bookingTiming?.[day]?.available;
  };
  const filterTime = (date: Date) => {
    const day = format(date, 'iii').toLowerCase() as bookingDay;
    const from = amenity?.bookingTiming?.[day]?.from;
    const till = amenity?.bookingTiming?.[day]?.till;
    return filterAmenityTime({from, till, date});
  };
  useEffect(() => {
    if (orderChangeData?.startDate.value) {
      const day = format(orderChangeData?.startDate.value, 'iii').toLowerCase() as bookingDay;
      const from = amenity?.bookingTiming?.[day]?.from;
      const till = amenity?.bookingTiming?.[day]?.till;

      if (!filterAmenityTime({from, till, date: orderChangeData?.startDate.value})) {
        const {hours: fHours, minutes: fMinutes} = getTime12to24(from);
        const newTime = new Date(orderChangeData?.startDate.value);
        newTime.setHours(fHours);
        newTime.setMinutes(Number(fMinutes));
        newTime.setSeconds(0);
        newTime.setMilliseconds(0);
        orderChangeData?.startDate.set(newTime);
      }
    }
  }, [orderChangeData?.startDate.value]);
  const maxGuests = Number(amenity?.maxResidentsPerBooking) - 1 < 0 ? 0 : Number(amenity?.maxResidentsPerBooking) - 1;
  const slotsByTime = checkSlots(amenity?.bookingSlots);
  return (
    <CardWrapper padding={10}>
      <CardContentWrapper>
        <DateWrapperCard $isAvailable={isAvailable}>
          <CardTitleWrapper>
            <CardTitle>{amenity?.name}</CardTitle>
          </CardTitleWrapper>
          <LabelsWrapper>
            {tags.map((t) => (
              <Label variant={t.type} key={t.label}>
                {t.label}
              </Label>
            ))}
          </LabelsWrapper>
          {!successOrder && (
            <DatepickerLine>
              <DateTimePicker
                date={orderChangeData?.startDate?.value}
                pickType={PickDateTypes.dateTime}
                setDate={orderChangeData?.startDate?.set}
                time={orderChangeData?.startDate?.value}
                setTime={orderChangeData?.startDate?.set}
                canEdit={true}
                pickerLabels={pickerLabels}
                pickerPlaceholders={pickerPlaceholders}
                captions={{
                  date: t('requests:captions.date'),
                  dateDuration: t('requests:captions.dateDuration'),
                  time: t('requests:captions.time'),
                  duration: t('requests:captions.duration'),
                }}
                durationUnit={{
                  single: t('requests:durationUnit.single'),
                  many: t('requests:durationUnit.many'),
                }}
                buttonSelectText={t('requests:buttonSelectText')}
                filterDate={filterDate}
                filterTime={filterTime}
                timeIntervals={slotsByTime}
                hidePastTimes={true}
                // busyDates={busyDates}
                busyTimes={busyDates}
              />
            </DatepickerLine>
          )}
          {!successOrder && (
            <>
              {amenity?.allowGuests && (
                <CounterInput
                  min={0}
                  max={maxGuests}
                  onChange={orderChangeData?.onChangeGuests}
                  value={orderChangeData?.guests}
                  label={t('amenities:requests.guests')}
                />
              )}
            </>
          )}
          {!!message?.success && <SuccessAmenityMessage text={message.success} />}
          <ErrorMessage open={!!message?.error}>{message?.error}</ErrorMessage>
          {!successOrder && (
            <ButtonWrapper pad={10}>
              <Button
                variant={'primary'}
                onClick={isAvailable ? buttonLabel?.onClick : undefined}
                loading={buttonLabel?.loading}>
                {buttonLabel?.label}
              </Button>
            </ButtonWrapper>
          )}
          {successOrder && pathLink && (
            <LinkButton to={pathLink} ghost={true} variant={'primary'}>
              {linkTitle}
            </LinkButton>
          )}
          {amenity?.allowGuests && <CardInfoText>{descr}</CardInfoText>}
          {/*{!successOrder && type === ListingCardType.Request && <PaymentInfo payments={payments} />}*/}
        </DateWrapperCard>
        {!isAvailable && <NotAvailable>{notAvailableText}</NotAvailable>}
      </CardContentWrapper>
    </CardWrapper>
  );
};

export const SuccessAmenityMessage: React.FC<{text?: string}> = ({text}) => {
  return (
    <SuccessAmenityMessageWrapper>
      <IconSvg type={'checked-mark'} stroke={'aqua'} />
      <SuccessAmenityText>{text}</SuccessAmenityText>
    </SuccessAmenityMessageWrapper>
  );
};
