import React from 'react';

import {useFacebookAppFix, useGoogleIosAppFix, useSocialConnect} from '../../../hooks/social';

import {SettingsAccountConnections} from '../../../components/Settings/Personal/SettingsAccountConnections';

export const AccountConnections = () => {
  const {reqWIthGoogle, reqWIthFacebook, reqWIthApple, connectSocialInfo, disconnectAccount, loadingButton, error} =
    useSocialConnect();
  const {googleReady, onGoogleLoginClick} = useGoogleIosAppFix();
  const {fbReady, onFBClick} = useFacebookAppFix();
  return (
    <SettingsAccountConnections
      reqWIthGoogle={reqWIthGoogle}
      reqWIthFacebook={reqWIthFacebook}
      reqWIthApple={reqWIthApple}
      connectSocialInfo={connectSocialInfo}
      disconnectAccount={disconnectAccount}
      loadingButton={loadingButton}
      error={error}
      fbReady={fbReady}
      googleReady={googleReady}
      onGoogleLoginClick={onGoogleLoginClick}
      onFBClick={onFBClick}
    />
  );
};
