import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConnectionButton, ConnectionButtonText} from './styles';
import {Button} from '../../ui-kit/Button/Button';
import {SocialButtonPropsT} from '../../types/auth';
import {IconSvg} from '../../ui-kit/Icon/Svg';

export const SocialButton: React.FC<SocialButtonPropsT> = ({
  type,
  connectSocialInfo,
  connectInfo,
  disconnectAccount,
  onClick,
  loading,
  disabled,
}) => {
  const {t} = useTranslation();

  const onDisconnect = async () => {
    await disconnectAccount?.(type);
  };

  return (
    <Button
      onClick={!connectInfo ? onClick : onDisconnect}
      icon={
        <IconSvg
          type={type}
          stroke="none"
          fill={type === 'apple' ? 'black' : undefined}
          width="24"
          height="24"
          viewBox="0 0 24 24"
        />
      }
      label={connectSocialInfo && <ConnectionLabel isConnect={connectInfo} />}
      variant={'social'}
      loading={loading}
      disabled={disabled}
      ghost>
      {t(`auth:social.buttons.${[type]}`)}
    </Button>
  );
};

const ConnectionLabel = ({isConnect}: {isConnect?: boolean}) => {
  const {t} = useTranslation();

  return (
    <ConnectionButton isConnect={isConnect}>
      <ConnectionButtonText>
        {isConnect ? t('auth:social.buttons.disconnect') : t('auth:social.buttons.connect')}
      </ConnectionButtonText>
    </ConnectionButton>
  );
};
