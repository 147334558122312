import styled from 'styled-components';
import {Media} from '../../../../ui-kit/theme';

export const HelpButtonWrapper = styled.div<{$isShown: boolean}>`
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 35px;
  height: 35px;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 50%;
  display: ${({$isShown}) => ($isShown ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  ${Media.down.l} {
    display: flex;
  }
  ${Media.down.s} {
    display: none;
  }
`;

export const PopupWrapper = styled.div`
  position: fixed;
  right: 50px;
  bottom: 60px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  ${Media.down.s} {
    right: 0;
    bottom: 0;
  }
`;

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
`;
