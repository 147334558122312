import React, {useState} from 'react';
import {SectionWrapper} from '../commonStyles';
import {SettingsSubsection} from '../components';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../ui-kit/Button/Button';
import {useHistory} from 'react-router-dom';
import {route} from '../../../constants/routes';
import {StripeInfo} from '../../../queries/payments';
import {useLinks} from '../../../hooks/common';
import {isFromApp} from '../../../ui-kit/Navigation/mobile/styles';

export interface SettingsPayoutsProps {
  stripeInfo?: StripeInfo;
}

export const SettingsPayoutsSection: React.FC<SettingsPayoutsProps> = ({stripeInfo}) => {
  const {t} = useTranslation();
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [loading, setLoading] = useState(false);
  const hasStripe = stripeInfo?.accountConnected;
  const stripeLink = stripeInfo?.accountLink ?? '';
  const btnDisabled = !hasStripe && !stripeLink;

  const handleClickBtn = () => {
    if (hasStripe) {
      push(getLink(route.payoutsSettings.path));
    } else {
      isFromApp() && setLoading(true);
      window?.open(stripeLink, '_blank');
    }
  };

  return (
    <SectionWrapper>
      <SettingsSubsection
        label={t('settings:payments.managePayouts.title')}
        value={t('settings:payments.managePayouts.subtitle')}
      />
      <Button
        variant={hasStripe ? 'primary' : 'tertiary'}
        width={'240px'}
        link={true}
        disabled={btnDisabled}
        onClick={handleClickBtn}
        loading={loading}>
        {hasStripe ? t('settings:payments.managePayouts.buttonManage') : t('settings:payments.managePayouts.buttonAdd')}
      </Button>
    </SectionWrapper>
  );
};
