import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {
  AdminFormEntry,
  ErrorMessage,
  FormButtons,
  SettingsEntry,
  SettingsEntryAdmin,
  SettingsSubsection,
} from '../components';
import {ConfigureAdminSection} from './components/ConfigureAdminSection';
import {ContentWrapper, InviteSectionWrapper, StyledSelect} from './styles';
import {PlanOptionsValues} from '../../../constants/plan';
import {YesNotoBoolReverse} from '../../../helpers/common';
import {useGetAccTypes, useGetListingsApprovalOptions, useHasPlanOption} from '../../../helpers/community';
import {AdminInfoT} from '../../../queries/community';
import {SetUserAdminResType} from '../../../queries/people';
import {CommunityAdmin} from '../../../queries/types/community';
import {User} from '../../../queries/types/user';
import {currentCommunity, userPermissions} from '../../../states/community';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {
  CommunityAdminValues,
  CommunityType,
  FormEditType,
  FormType,
  LabelValuePair,
  LabelValuePairBolean,
  PermissionsList,
  TAdminsPermissions,
  TUserData,
} from '../../../types/settings';
import {TLoadingStates} from '../../Feed/types';
import {
  AdditionalInfo,
  AdminsWrapper,
  InfoPairWrapper,
  SectionWrapper,
  SectionWrapperForm,
  SettingsInfoWrapper,
} from '../commonStyles';

import {editAdminT} from '../../../hooks/people';
import {useGetManagersInfo} from '../../../hooks/settings';
import {CommunityOptionsFields} from '../../../types/community';

interface TProps {
  community?: Partial<CommunityType> | null;
  edit: FormEditType;
  form: FormType<CommunityAdminValues>;
  canEdit: boolean;
  removeAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  makeOwner: ((adminId: string, communityId: string) => Promise<boolean>) | undefined;
  makeAdmin: (options: {
    id: string;
    communityId: string;
    label?: string;
    onSuccess?: (data?: SetUserAdminResType | null) => void;
  }) => Promise<boolean>;
  updateAdmin: editAdminT;
  getAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  loadingStates: TLoadingStates;
  residents?: User[];
}

export const SettingsAdmin: React.FC<TProps> = ({
  edit,
  community,
  form,
  canEdit,
  removeAdmin,
  makeAdmin,
  getAdmin,
  updateAdmin,
  loadingStates,
  makeOwner,
  residents,
}) => {
  const [adminsInfo, setAdminsInfo] = useState<AdminInfoT[]>([]);
  const admins = [{User: community?.Owner} as CommunityAdmin, ...(community?.Admins ?? [])];
  const getAdminsInfo = useGetManagersInfo();

  const [permissions] = useRecoilState(userPermissions);
  const [userType] = useRecoilState(typeUser);
  const canManageAdmins = useMemo(
    () =>
      (permissions.includes(PermissionsList.manageAdmin) && userType === TypeCommunity.admin) ||
      userType === TypeCommunity.manager,
    [userType, permissions],
  );

  useEffect(() => {
    (async () => {
      const res = await getAdminsInfo(community?.objectId);
      if (res) {
        setAdminsInfo(res);
      }
    })();
  }, [community?.objectId]);
  return !edit.isEditing ? (
    <AdminInfo
      community={community}
      onEdit={edit.handleEditStart}
      admins={admins}
      canEdit={canEdit}
      canManageAdmins={canManageAdmins}
      adminsInfo={adminsInfo}
    />
  ) : (
    <AdminForm
      form={form}
      community={community}
      admins={admins}
      onCancel={edit.handleEditEnd}
      makeOwner={makeOwner}
      removeAdmin={removeAdmin}
      makeAdmin={makeAdmin}
      getAdmin={getAdmin}
      updateAdmin={updateAdmin}
      loadingStates={loadingStates}
      adminsInfo={adminsInfo}
      residents={residents}
    />
  );
};

interface InfoProps {
  community?: Partial<CommunityType> | null;
  admins: CommunityAdmin[];
  onEdit: () => void;
  canEdit: boolean;
  canManageAdmins?: boolean;
  adminsInfo?: AdminInfoT[];
}

const AdminInfo: React.FC<InfoProps> = ({community, onEdit, admins, canEdit, canManageAdmins, adminsInfo}) => {
  const {t} = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const handleEdit = () => {
    setClicked(true);
    if (canEdit) {
      onEdit();
    }
    setTimeout(() => setClicked(canEdit), 3000);
  };
  return (
    <SectionWrapper>
      <SettingsSubsection
        label={t('settings:community.settings.title')}
        value={t('settings:community.settings.subtitle')}
        onClick={onEdit}
      />
      <InfoPairWrapper>
        <div>
          <SettingsEntryAdmin
            label={t('settings:community.settings.accessType')}
            value={t(`search:communities.${community?.accessType?.toLowerCase()}`)}
            onClick={onEdit}
          />
        </div>
        <SettingsEntryAdmin
          label={t('settings:community.settings.listingApproval')}
          value={t(`settings:community.settings.${community?.listingApproval?.toLowerCase()}`)}
          onClick={onEdit}
        />
      </InfoPairWrapper>
      <InfoPairWrapper>
        <SettingsEntryAdmin
          label={t('settings:community.settings.allowChat')}
          value={t(`common:commonButtons.${YesNotoBoolReverse(community?.allowChat)}`)}
          onClick={onEdit}
        />
        <SettingsEntryAdmin
          label={t('settings:community.settings.allowEvents')}
          value={t(`common:commonButtons.${YesNotoBoolReverse(community?.allowEvents)}`)}
          onClick={onEdit}
        />

        {/* <SettingsEntryAdmin
          label={t('settings:community.settings.listingApproval')}
          value={t(`settings:community.settings.${community?.listingApproval?.toLowerCase()}`)}
          onClick={onEdit}
        /> */}
      </InfoPairWrapper>
      <InfoPairWrapper>
        <SettingsEntryAdmin
          label={t('settings:community.settings.passCode')}
          value={t(
            `common:commonButtons.${YesNotoBoolReverse(community?.options?.[CommunityOptionsFields.passCode] ?? true)}`,
          )}
          onClick={onEdit}
        />
        <SettingsEntryAdmin
          label={t('settings:community.settings.requireAddress')}
          value={t(
            `common:commonButtons.${YesNotoBoolReverse(
              community?.options?.[CommunityOptionsFields.requireAddress] ?? true,
            )}`,
          )}
          onClick={onEdit}
        />
      </InfoPairWrapper>
      <InfoPairWrapper>
        <SettingsEntryAdmin
          label={t('settings:community.settings.requirePhone')}
          value={t(
            `common:commonButtons.${YesNotoBoolReverse(
              community?.options?.[CommunityOptionsFields.requirePhone] ?? true,
            )}`,
          )}
          onClick={onEdit}
        />
        <SettingsEntryAdmin
          label={t('settings:community.settings.requireListing')}
          value={t(
            `common:commonButtons.${YesNotoBoolReverse(
              community?.options?.[CommunityOptionsFields.requireListing] ?? true,
            )}`,
          )}
          onClick={onEdit}
        />
      </InfoPairWrapper>

      {canManageAdmins && (
        <>
          <SettingsSubsection label={t('settings:community.admins.title')} />
          <SettingsInfoWrapper noGap={true}>
            {admins.map((a) => (
              <SettingsEntry
                label={`${a.User?.firstName} ${a.User?.lastName || a?.User?.firstLetter}`}
                value={a.User?.email || adminsInfo?.find((el) => a.User?.objectId === el?.id)?.email}
                key={a.id}
                onClick={onEdit}
              />
            ))}
          </SettingsInfoWrapper>
        </>
      )}
      {clicked && !canEdit && <ErrorMessage error={'settings:personal.saveOrCancel'} />}
      <FormButtons variant={'edit'} onClick={handleEdit} />
    </SectionWrapper>
  );
};

interface FormProps {
  form: FormType<CommunityAdminValues>;
  admins: CommunityAdmin[];
  community?: Partial<CommunityType> | null;
  onCancel: () => void;
  removeAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  makeOwner: ((adminId: string, communityId: string) => Promise<boolean>) | undefined;
  makeAdmin: (options: {
    id: string;
    communityId: string;
    label?: string;
    permissionList: Array<keyof TAdminsPermissions>;
    onSuccess?: (data?: SetUserAdminResType | null) => void;
  }) => Promise<boolean>;
  updateAdmin: editAdminT;
  getAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  loadingStates: TLoadingStates;
  adminsInfo?: AdminInfoT[];
  residents?: User[];
}

const toOptions = (user?: User) =>
  user
    ? {
        //user.last name
        label: `${user.firstName} ${user.firstLetter}`,
        value: user.objectId,
      }
    : {label: '', value: ''};

const adminToOption = (admin?: CommunityAdmin): Partial<TUserData> => {
  return {
    ...toOptions(admin?.User),
    userLabel: admin?.label,
    permissions: [],
    adminId: admin?.objectId,
  };
};

export const AdminForm: React.FC<FormProps> = ({
  form,
  community,
  admins,
  onCancel,
  removeAdmin,
  makeAdmin,
  getAdmin,
  updateAdmin,
  loadingStates,
  adminsInfo,
  makeOwner,
  residents,
}) => {
  const {t} = useTranslation();
  const [permissions] = useRecoilState(userPermissions);
  const [userType] = useRecoilState(typeUser);
  const [user, setUser] = useState<Partial<TUserData> | undefined>();
  const [adminAdded, setAdminAdded] = useState<{
    user: string;
    label: string;
    permissions: Array<keyof TAdminsPermissions>;
  } | null>(null);
  const setCommunity = useSetRecoilState(currentCommunity);
  const residentsOptions = residents?.map((r) => toOptions(r));
  const hasMultipleAdmins = useHasPlanOption(PlanOptionsValues.multipleAdmins);
  // Add people to admin
  const handleMakeAdmin = async () => {
    if (user?.value && community?.objectId) {
      await makeAdmin({
        id: user.value,
        communityId: community.objectId,
        label: user.userLabel || t('people:person.labels.manager'),
        permissionList: user?.permissions || [],
        onSuccess: () => {
          getAdmin(user.value ?? '')
            .then((response) => {
              if (response) {
                setCommunity({
                  ...community,
                  Admins: [...(community?.Admins ?? []), response],
                });
              }
              setUser(undefined);
            })
            .catch((err) => console.log(err));
        },
      });
    }
  };

  // Add owner
  const handleMakeOwner = async (id: string) => {
    if (community?.objectId && makeOwner) {
      return await makeOwner(id, community.objectId);
    }
    return false;
  };

  // Add admin
  const handleEditAdmin = async () => {
    if (!user?.adminId || !user?.value) return;
    return updateAdmin({
      userId: user?.value,
      label: user?.userLabel,
      communityId: community?.objectId,
      permissionList: user?.permissions || [],
    })
      .then(() => setUser(undefined))
      .catch((e) => console.log(e));
  };

  const getHandleEditAdmin = (admin: CommunityAdmin) => async () => {
    setUser(adminToOption(admin));
    setAdminAdded(null);
  };

  const handleChange = (name: string) => (values: LabelValuePair[]) => {
    const value = values?.[0];
    form.onChange({name, value: value?.value ?? ''});
  };

  const handleChangeBoolean = (name: string, options?: boolean) => (values: LabelValuePairBolean[]) => {
    const value = values?.[0];
    form.onChange({name, value: value?.value || false, isOptions: options});
  };
  // TODO: Add Event
  const handleChangeBooleanEvent = (name: string) => (values: LabelValuePairBolean[]) => {
    const value = values?.[0];
    form.onChange({name, value: value?.value ?? true});
  };

  const handleUserChange = (data: Partial<TUserData>) => {
    setUser((prev) => ({...prev, ...data}));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.onSubmit();
  };

  const optionsType: Partial<LabelValuePair>[] = useGetAccTypes(community?.Subscr?.PaymentPlan.options);
  const optionsApproval: Partial<LabelValuePair>[] = useGetListingsApprovalOptions(
    community?.Subscr?.PaymentPlan.options,
  );
  const YesNoOptions: Partial<LabelValuePairBolean>[] = [
    {label: t('common:commonButtons.no'), value: false},
    {label: t('common:commonButtons.yes'), value: true},
  ];
  // const optionsPost: Partial<LabelValuePair>[] = [
  //   {label: t('common:commonButtons.Yes'), value: 'true'},
  //   {label: t('common:commonButtons.No'), value: 'false'},
  // ];

  const typeValue: LabelValuePair = {
    label: form.values.accessType ? t('community:accessType', {context: form.values.accessType}) : '',
    value: form.values.accessType ?? '',
  };
  const typeApproval: LabelValuePair = {
    label: form.values.listingApproval
      ? t('community:listingApprovalType', {context: form.values.listingApproval})
      : '',
    value: form.values.listingApproval ?? '',
  };
  const editAdmin = admins.some((el) => el.User?.objectId === user?.value && !!user?.value);
  const canEditAdmins = permissions.includes(PermissionsList.manageAdmin) || userType === TypeCommunity.manager;
  return (
    <SectionWrapperForm onSubmit={handleSubmit}>
      <SettingsSubsection
        label={t('settings:community.settings.title')}
        value={t('settings:community.settings.subtitle')}
      />
      <InfoPairWrapper>
        <div>
          <StyledSelect
            $isError={form.error.accessType}
            values={[typeValue]}
            options={optionsType}
            onChange={handleChange('accessType')}
            name={'accessType'}
            label={t('settings:community.settings.accessType')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.typeDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
        <div>
          <StyledSelect
            $isError={form.error.listingApproval}
            values={[typeApproval]}
            options={optionsApproval}
            onChange={handleChange('listingApproval')}
            name={'accessType'}
            label={t('settings:community.settings.listingApproval')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.approvalDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
      </InfoPairWrapper>
      <InfoPairWrapper>
        <div>
          <StyledSelect
            $isError={form.error.accessType}
            values={YesNoOptions.filter((el) => el.value === form.values.allowChat)}
            options={YesNoOptions}
            onChange={handleChangeBoolean('allowChat')}
            name={'allowChat'}
            label={t('settings:community.settings.allowChat')}
          />

          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.allowChatDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>

        <div>
          <StyledSelect
            $isError={form.error.accessType}
            values={YesNoOptions.filter((el) => el.value === form.values.allowEvents)}
            options={YesNoOptions}
            onChange={handleChangeBooleanEvent('allowEvents')}
            name={'allowEvents'}
            label={t('settings:community.settings.allowEvents')}
          />

          <AdditionalInfo>
            <ContentWrapper>
              <ContentWrapper>{t('settings:community.settings.allowEventsDescr')}</ContentWrapper>
            </ContentWrapper>
          </AdditionalInfo>
        </div>
      </InfoPairWrapper>
      <InfoPairWrapper>
        <div>
          <StyledSelect
            values={YesNoOptions.filter((el) => el.value === form.values?.options?.passCode)}
            options={YesNoOptions}
            onChange={handleChangeBoolean('passCode', true)}
            name={'passCode'}
            label={t('settings:community.settings.passCode')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.passCodeDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
        <div>
          <StyledSelect
            values={YesNoOptions.filter((el) => el.value === form.values?.options?.requireAddress)}
            options={YesNoOptions}
            onChange={handleChangeBoolean('requireAddress', true)}
            name={'requireAddress'}
            label={t('settings:community.settings.requireAddress')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.requireAddressDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
      </InfoPairWrapper>
      <InfoPairWrapper>
        <div>
          <StyledSelect
            values={YesNoOptions.filter((el) => el.value === form.values?.options?.requirePhone)}
            options={YesNoOptions}
            onChange={handleChangeBoolean('requirePhone', true)}
            name={'requirePhone'}
            label={t('settings:community.settings.requirePhone')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.requirePhoneDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
        <div>
          <StyledSelect
            values={YesNoOptions.filter((el) => el.value === form.values?.options?.requireListing)}
            options={YesNoOptions}
            onChange={handleChangeBoolean('requireListing', true)}
            name={'requireListing'}
            label={t('settings:community.settings.requireListing')}
          />
          <AdditionalInfo>
            <ContentWrapper>{t('settings:community.settings.requireListingDescr')}</ContentWrapper>
          </AdditionalInfo>
        </div>
      </InfoPairWrapper>
      {/*<div>*/}
      {/*  <StyledSelect*/}
      {/*    $isError={form.error.listingApproval}*/}
      {/*    values={[optionsPost[0]]}*/}
      {/*    options={optionsPost}*/}
      {/*    onChange={onChange}*/}
      {/*    name={'allowPosts'}*/}
      {/*    label={t('settings:community.settings.allowPosts')}*/}
      {/*  />*/}
      {/*  <AdditionalInfo>*/}
      {/*    <ContentWrapper>{t('settings:community.settings.allowPostsDescr')}</ContentWrapper>*/}
      {/*  </AdditionalInfo>*/}
      {/*</div>*/}
      {canEditAdmins && (
        <>
          <SettingsSubsection label={t('settings:community.admins.title')} />
          <AdminsWrapper>
            {admins?.map((a) =>
              user?.value === a.User?.objectId ? (
                <ConfigureAdminSection
                  communityId={community?.objectId || ''}
                  handleMakeAdmin={handleMakeAdmin}
                  handleEditAdmin={handleEditAdmin}
                  handleUserChange={handleUserChange}
                  user={user}
                  userId={a.User?.objectId}
                  residents={residentsOptions}
                  adminsId={admins.map((el) => el.User?.objectId).filter((el) => el)}
                  key={user?.value}
                  editMode
                  adminAdded={adminAdded}
                  setAdminAdded={setAdminAdded}
                  loadingStates={loadingStates}
                />
              ) : (
                <AdminFormEntry
                  admin={a}
                  isOwner={a?.User?.id === community?.Owner?.id}
                  removeAdmin={removeAdmin}
                  key={a?.id}
                  makeOwner={makeOwner ? handleMakeOwner : undefined}
                  editAdmin={getHandleEditAdmin(a)}
                  adminsInfo={adminsInfo}
                />
              ),
            )}
          </AdminsWrapper>
        </>
      )}
      {hasMultipleAdmins && canEditAdmins && (
        <InviteSectionWrapper>
          <ConfigureAdminSection
            handleMakeAdmin={handleMakeAdmin}
            handleEditAdmin={handleEditAdmin}
            handleUserChange={handleUserChange}
            user={editAdmin ? undefined : user}
            residents={residentsOptions}
            adminsId={admins.map((el) => el?.User?.objectId).filter((el) => el)}
            key={editAdmin ? '' : user?.value}
            adminAdded={adminAdded}
            setAdminAdded={setAdminAdded}
            loadingStates={loadingStates}
          />
        </InviteSectionWrapper>
      )}
      <ErrorMessage error={form.error.message} />
      <FormButtons variant={'update'} disabled={form.loading} loading={form.loading} onCancel={onCancel} />
    </SectionWrapperForm>
  );
};
