import React, {useCallback, useState} from 'react';
import {Text} from '../../../ui-kit/Typography/Text';
import {MinimalSearchInput} from '../../../ui-kit/Form/Input/MinimalSearchInput';
import {HeaderPageWrapper, InnerSearchWrapper, SelectWrapper, TopBlock} from './styles';
import {SeparatedSelect} from '../../../ui-kit/Form/Select/SeparatedSelect';
import {searchCommunityOptions} from '../../../helpers/community';
import {useHistory} from 'react-router-dom';
import {route} from '../../../constants/routes';
import {useLinks, usePageView} from '../../../hooks/common';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface SearchBlockProps {
  title?: string;
  placeholder?: string;
  options?: SelectOptions[];
  moveLast?: boolean;
  onSearch: (searchText?: string, params?: SelectOptions) => void;
  buttons?: React.ReactNode;
  cantSearch?: boolean;
}

export const SearchBlock: React.FC<SearchBlockProps> = ({
  title,
  placeholder,
  options,
  onSearch,
  moveLast,
  buttons,
  cantSearch,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<SelectOptions | undefined>(options?.[0]);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {view, setView} = usePageView();

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);
  const handleSelect = useCallback(
    (next: SelectOptions) => {
      if (next.value === searchCommunityOptions.redirect) {
        push(getLink(route.pageUser.path));
      }
      if (next.value !== searchCommunityOptions.redirect) {
        setSelectOptions(next);
        onSearch(searchText, next);
      }
    },
    [searchText],
  );
  return (
    <InnerSearchWrapper>
      <TopBlock>
        <HeaderPageWrapper>
          {title ? (
            <Text size={26} color={'quaternary'} weight={'medium'} variant={'primary'}>
              {title}
            </Text>
          ) : (
            <span />
          )}
          {buttons || null}
        </HeaderPageWrapper>

        {!cantSearch && (
          <MinimalSearchInput
            value={searchText}
            onChange={handleChangeSearchText}
            onSearch={onSearch}
            placeholder={placeholder}
          />
        )}
      </TopBlock>

      {options && (
        <SelectWrapper moveLast={moveLast}>
          <SeparatedSelect
            options={options}
            value={selectOptions}
            onChange={handleSelect}
            view={view}
            setView={setView}
          />
        </SelectWrapper>
      )}
    </InnerSearchWrapper>
  );
};
