import styled from 'styled-components';
import {Link as ReactLink} from 'react-router-dom';
import {Media, theme} from '../../../ui-kit/theme';
import {Text, Text as UiText} from '../../../ui-kit/Typography/Text';
import {LinkButton} from '../../../ui-kit/Button/LinkButton';
import {Button} from '../../../ui-kit/Button/Button';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Message} from '../../../ui-kit/Form/Message';
import {fontPrimaryMedium, fontPrimaryReg, fontPrimarySemiBold} from '../../common/styles';
import Avatar from 'react-nice-avatar';
import {Box} from '../../common/Box';
import {NumberFormatInput} from '../../../ui-kit/Form/Input/NumberFormatInput';
import {Input} from '../../../ui-kit/Form/Input';

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  max-width: 1440px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 auto;
  background-color: #f8fafa;
`;

export const HeaderPage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 34px 118px 34px 118px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  ${Media.down.s} {
    padding: 50px 55px 20px 55px;
  }
`;

export const BtnBackDeskTop = styled(Button).attrs({
  ghost: true,
  variant: 'secondary',
})`
  display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
  position: absolute;
  left: 34px;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
  & .button-value {
    font-size: 12px;
    position: static;
    transform: none;
  }
  ${Media.down.l} {
    display: none;
  }
`;

export const BtnBackMobile = styled.button<{isVisible?: boolean}>`
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  position: absolute;
  right: 5px;
  top: 35px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
  & .button-value {
    font-size: 12px;
  }

  ${Media.up.l} {
    display: none;
    right: 15px;
  }

  ${Media.up.s} {
    right: 15px;
  }
`;

//start form
export const WrapperForm = styled.div<{paddingX?: string; $isGhost?: boolean}>`
  max-width: min(600px, 100%);
  min-width: 600px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  margin: 0 20px;
  ${Media.down.xl} {
    min-width: 500px;
  }
  ${Media.down.l} {
    min-width: auto;
  }
  ${Media.up.xs} {
    padding: ${({paddingX}) => (paddingX ? `20px ${paddingX}` : '25px 0')};
  }
  ${Media.down.xs} {
    padding: 20px 20px;
  }
  ${({$isGhost}) =>
    $isGhost &&
    `
    background: transparent;
    border: none;
    box-shadow: none;
  `};
`;

export const GreetingTitle = styled(UiText).attrs({variant: 'secondary'})`
  font-size: 38px;
  display: block;
  margin-bottom: 16px;
  color: ${({theme}) => theme.palette.text.quaternary};
  text-align: left;
  ${Media.down.s} {
    font-size: 26px;
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;

export const CommTypeHint = styled.div`
  padding: 12px 10px;
  background: ${({theme}) => theme.palette.common.yellowLight};
  border-radius: 10px;
  ${fontPrimaryReg};
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    width: 10%;
  }
  & > span {
    width: 80%;
  }
  & > button {
    width: 10%;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
`;

export const CommunityTip = styled(UiText).attrs({variant: 'secondary'})`
  font-size: 14px;
  display: block;
  color: ${({theme}) => theme.palette.text.quaternary};
  text-align: left;
`;

export const MemberWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .avatar {
    margin-left: -5px;
    height: 24px;
    width: 24px;
  }
  span {
    margin-left: 6px;
  }
`;

export const SubscribeText = styled(UiText).attrs({variant: 'primary'})`
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 24px;
  ${Media.down.s} {
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const ProfileForm = styled.form`
  & > * {
    margin-bottom: 16px;
  }
`;

export const TextAreaWrapper = styled.form`
  position: relative;
  .generate {
    z-index: 1;
    position: absolute;
    top: -13px;
    right: 10px;
  }
`;

export const TextUI = styled(UiText).attrs({
  variant: 'tertiary',
  color: 'quinary',
})<{
  offsetTop?: number;
  offsetBottom?: number;
}>`
  line-height: 1.5;
  font-size: 13px;
  margin-top: ${({offsetTop}) => offsetTop || 0}px;
  margin-bottom: ${({offsetBottom}) => offsetBottom || 0}px;
`;

export const Link = styled(ReactLink)`
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 14px;
  line-height: 150%;
  text-decoration: underline;
  width: max-content;
  margin: 0 auto;
`;

export const InnerText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const CounterInner = styled(InnerText)`
  justify-content: flex-end;
  margin-top: 5px;
`;

export const SubTitle = styled.h2`
  ${fontPrimaryReg};
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const InnerItemsForm = styled.div`
  display: flex;
  position: relative;
  & > *:first-child {
    margin-right: 20px;
  }
  & > * {
    width: 50%;
  }
  ${Media.down.s} {
    & > *:first-child {
      margin-right: 7px;
    }
  }
`;

export const WrapItem = styled.div`
  max-width: 100%;
`;

export const TextSelect = styled.div`
  ${fontPrimaryReg};
  margin-top: 3px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

//end form

export const InnerBoxPlan = styled.div`
  border: ${({theme}) => `1px solid ${theme.palette.background.secondary}`};
  display: flex;
  padding: 22px 30px;
  box-sizing: border-box;
  margin-top: 41px;
  margin-bottom: 25px;
  border-radius: 12px;
`;

export const InnerBoxCards = styled(InnerBoxPlan)`
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const InnerBoxCard = styled(InnerBoxPlan)`
  margin-top: 40px;
  margin-bottom: 0;
  display: block;
  background-color: ${({theme}) => theme.palette.background.primary};
`;

export const InnerPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const PaymentPlanType = styled.p`
  color: ${({theme}) => theme.palette.text.octonary};
  ${fontPrimarySemiBold};
  font-size: 12px;
  margin: 0;
  line-height: 150%;
`;

export const PaymentPlanPrice = styled.span`
  color: ${({theme}) => theme.palette.text.octonary};
  ${fontPrimaryMedium};
  font-size: 26px;
  line-height: 150%;
`;

export const PaymentPlanMonth = styled.span`
  color: ${({theme}) => theme.palette.text.septenary};
  ${fontPrimaryReg};
  font-size: 12px;
  line-height: 150%;
  margin-left: 5px;
`;

export const PaymentPlanAnually = styled.p`
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.septenary};
  font-size: 12px;
  margin: 0;
  line-height: 150%;
`;

export const PaymentChangeButton = styled(LinkButton).attrs({
  ghost: true,
  variant: 'secondary',
  width: '100px',
  height: '22px',
})`
  width: fit-content;
  & .button-value {
    font-size: 12px;
  }
`;

export const InnerPaymentButton = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TextApply = styled(UiText).attrs({
  color: 'octonary',
})`
  ${fontPrimaryReg};
  font-size: 12px;
`;

export const BoxCardTitle = styled.div`
  ${fontPrimaryMedium};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 20px;
  line-height: 150%;
`;

export const BoxCardDescr = styled(BoxCardTitle)`
  ${fontPrimaryReg};
  font-size: 12px;
  margin-bottom: 25px;
`;

export const InnerCardsImage = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
  margin-bottom: 25px;
`;

//start plan

export const ContPlanCard = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 24px;
  }
  ${Media.down.l} {
    flex-direction: column-reverse;
    & > *:first-child {
      margin-right: 0;
    }
    & > *:not(:first-child) {
      margin-right: 0;
      margin-bottom: 33px;
    }
  }
`;

export const WrapperPlanCard = styled.div<{isChecked?: boolean}>`
  position: relative;
  min-width: 300px;
  max-width: 400px;
  padding: 28px;
  box-sizing: border-box;
  border: ${({theme, isChecked}) =>
    !isChecked
      ? `1px solid ${theme.palette.background.secondary}`
      : `2px solid ${theme.palette.background.quaternary}`};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const LabelCard = styled.div`
  color: ${({theme}) => theme.palette.text.primary};
  font-size: 12px;
  ${fontPrimarySemiBold};
  position: absolute;
  right: 0;
  top: 0;
  width: 135px;
  padding: 13px 23px;
  box-sizing: border-box;
  border-radius: 0 4px;
  background: ${({theme}) => theme.palette.background.quaternary};
`;

export const PlanCardDescr = styled.div`
  ${fontPrimaryReg};
  margin-top: 16px;
  margin-bottom: 25px;
  color: ${({theme}) => theme.palette.text.septenary};
  font-size: 16px;
  ${Media.down.s} {
    margin-bottom: 0;
  }
`;

export const TitleSubscr = styled.div`
  ${fontPrimarySemiBold};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 15px;
  margin-top: 24px;
`;

// export const InnerItemSubscr = styled.div`
//
// `

export const ItemSubscr = styled.div`
  position: relative;
  ${fontPrimaryReg};
  font-size: 12px;
  line-height: 18px;
  color: ${({theme}) => theme.palette.text.octonary};
  padding-left: 30px;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

export const IconSubscr = styled(IconSvg)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const PlanButton = styled(Button)`
  margin: 17px 0;
  ${Media.down.l} {
    margin: 15px 0;
  }
`;

export const PlanWrapper = styled(WrapperForm)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChangePlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 120px;
  ${Media.down.s} {
    padding-bottom: 60px;
  }
`;

export const InnerPeriodPlan = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  ${Media.down.s} {
    flex-direction: column;
    margin-bottom: 36px;
  }
`;

export const TitlePeriodPlan = styled(UiText).attrs({
  weight: 'medium',
  variant: 'primary',
})`
  font-size: 14px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 20px);
  width: max-content;
  ${Media.down.s} {
    display: none;
  }
`;

export const DiscountPeriodPlan = styled(UiText).attrs({
  weight: 'regular',
  variant: 'primary',
})`
  position: absolute;
  font-size: 12px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 20px);
  width: max-content;
  ${Media.down.s} {
    position: static;
    top: auto;
    transform: none;
    margin-top: 10px;
  }
`;

export const ViewOptions = styled.button`
  ${fontPrimarySemiBold};
  text-decoration: underline;
  color: ${({theme}) => theme.palette.text.octonary};
  border: none;
  background-color: transparent;
  font-size: 10px;
  line-height: 150%;
  margin-top: 15px;
  padding: 0;
`;

export const Hidden = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const ErrorMessage = styled(Message).attrs({variant: 'error'})`
  margin: 15px 0;
  padding: 10px 20px;
  box-sizing: border-box;
  ${fontPrimarySemiBold};
  font-size: 14px;
`;

export const UiCopyButton = styled.button`
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  position: relative;
`;

export const InnerAlias = styled.div`
  position: relative;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
`;

export const UnderlinedText = styled(UiText).attrs({
  color: 'octonary',
  size: 10,
  weight: 'semi-bold',
})`
  text-decoration: underline;
  display: block;
  text-align: center;
`;
export const PlanOptionsContainer = styled.div`
  ${Media.down.l} {
    margin-top: 24px;
    & > div > div > div:first-of-type {
      margin-top: 0;
    }
  }
`;

export const MainWrapper = styled.div`
  max-width: 580px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: min(25px, 18px);
  text-align: left;
  padding: 24px 24px;
  box-sizing: border-box;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  & :last-child:is(span) {
    cursor: pointer;
  }
  position: relative;
  .button-value {
    font-size: 16px;
  }
  ${Media.down.m} {
    gap: min(25px, 14px);
    width: auto;
    padding: 20px 20px;
    margin: 0 20px;
  }
`;

export const MobFocused = styled.div<{$isDesktop: boolean}>`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 20px;
    right: 12px;
    cursor: pointer;
  }
  .rt-editor {
    padding-right: ${({$isDesktop}) => ($isDesktop ? '38px' : null)} !important;
  }
  .generate {
    z-index: 1;
    position: absolute;
    top: -13px;
    right: 10px;
  }
}
${Media.down.m} {
  & .rt-editor .DraftEditor-root {
    height: 100% !important;
    & .public-DraftEditor-content > div {
      height: 100%;
    }
  }
}
`;

export const SelectInterestsOptionsList = styled.div`
  width: 100%;
  //display: grid;
  //grid-auto-flow: row;
  //gap: min(20px, 3vw);
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;

  ${Media.down.l} {
    grid-template-columns: 1fr;
  }
`;

export const Subtitle = styled(TextUI).attrs({
  variant: 'tertiary',
  color: 'quinary',
  size: 16,
})`
  display: inline-block;
  padding-left: 16px;
`;

export const SuccessMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  min-height: 400px;
  box-sizing: border-box;
  text-align: center;

  & > * {
    max-width: 250px;
  }

  ${Media.down.l} {
    min-height: initial;
    padding: 0;
  }
`;

export const SuccessIcon = styled(IconSvg).attrs({
  type: 'message-send',
  width: '200px',
  height: '200px',
  viewBox: '0 0 200 200',
})`
  & path {
    stroke-width: 1;
  }
`;

export const ButtonArea = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
`;

// Earn component

export const PrimaryEarnContainer = styled.div`
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  padding: 8px 16px;
  height: 70px;
`;

export const SecondaryEarnContainer = styled(PrimaryEarnContainer)`
  background-color: ${({theme}) => theme.palette.common.blueLight};
`;

export const InfoIcon = styled(IconSvg).attrs({
  type: 'info',
  width: '16.67',
  height: '16.67',
  viewBox: '0 0 20 20',
})`
  position: absolute;
  top: 8.6px;
  right: 8.6px;
`;

export const LabelContainer = styled.div<{type: 'current' | 'recommended'}>`
  position: absolute;
  top: -1px;
  right: -1px;
  border-radius: 0 4px;
  width: 135px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({type, theme}) =>
    type === 'current' ? theme.palette.background.quinary : theme.palette.background.quaternary};
`;

export const EmptySpace = styled.div`
  flex-grow: 1;
`;
export const AvaratLg = styled(Avatar)`
  height: 150px;
  width: 150px;
`;

export const AvatarBox = styled(Box)`
  .avatar {
    width: 150px;
    height: 150px;
  }
`;

export const UpdateWrapper = styled.div`
  position: relative;
`;

export const FormTopTitle = styled.div<{$noOffset?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: unset;
    & > a {
      text-decoration: unset;
    }
  }
  & > span {
    margin-top: 0;
    margin-bottom: 0;
  }
  margin-bottom: ${({$noOffset}) => ($noOffset ? '0px' : '26px')};
  ${Media.down.m} {
    margin-bottom: ${({$noOffset}) => ($noOffset ? '0px' : '16px')};
    & > span {
      font-size: 26px;
    }
  }
`;

export const SkipBtn = styled.span<{$isRow?: boolean}>`
  cursor: pointer;
  & > * {
    ${fontPrimaryReg};
  }
  & > a {
    text-decoration: unset;
  }
  ${({$isRow}) =>
    $isRow &&
    `display: flex;
     padding-top: 10px;
     padding-bottom: 10px;
     width: 150x;
     margin: 0 auto;
     justify-content: center;
    `};
  ${Media.down.m} {
    position: absolute;
    top: -56px;
    right: 15px;
    ${({$isRow}) => $isRow && ` position: relative; top: unset; right: unset;`};
  }
`;

export const CategoryGrid = styled.div`
  margin-top: 18px;
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CategoryElement = styled.div<{$isSelected?: boolean}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 10px 15px 10px 10px;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  height: auto;
  background-color: ${({theme, $isSelected}) =>
    $isSelected ? theme.palette.common.blueLight : theme.palette.common.white};
  border: 1px solid ${({theme, $isSelected}) => ($isSelected ? theme.palette.common.aqua : theme.palette.grayscale._11)};
  border-radius: 12px;
  align-items: center;
  span {
    color: ${theme.palette.grayscale._19};
  }
`;

export const InnerItems = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
    ${Media.down.m} {
      margin-bottom: 18px;
    }
  }
`;

export const CopyIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`;

export const ListingForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
  ${Media.down.l} {
    padding-right: 0;
  }
  ${Media.down.xs} {
    width: 100%;
  }
`;

export const PriceWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: right;
  span {
    white-space: nowrap;
  }
  * {
    box-sizing: border-box;
  }

  .menu__items {
    top: 0;
    transform: translate(-6px, 25px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
`;

export const PriceInput = styled(NumberFormatInput)`
  ${fontPrimaryReg};
  text-decoration: underline;
  font-size: 16px;
  line-height: 16px;
  color: ${({theme}) => theme.palette.grayscale._6};
  border: none;
  outline: none;
  padding: 0;
  input {
    text-align: right;
  }
`;

export const ServiceWrapper = styled.div<{$isChecked?: boolean}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 10px;
  width: 100%;
  min-height: 49px;
  border: 0.5px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 10px;
  cursor: pointer;
  ${({$isChecked, theme}) =>
    $isChecked &&
    `
    border: 0.5px solid ${theme.palette.common.aqua};
    background: ${theme.palette.common.blueLight};
    .fieldset-box__inner-input {
      background: ${theme.palette.common.blueLight};
    }
    .fieldset-box__label {
      display: none;
    }
    .fieldset-box__root {
      background: transparent;
    }
  `}
`;

export const ServiceIconWrapper = styled.div`
  flex: 0 0 auto;
`;

export const ServiceHeader = styled(ServiceWrapper)`
  background-color: ${({theme}) => theme.palette.grayscale._2};
  justify-content: center;
`;
export const CheckButton = styled.div<{width?: string}>`
  position: relative;
  width: ${({width}) => (width ? width : '100%')};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  & > svg {
    min-width: 25px;
  }
`;

export const CreateYourOwnWrapper = styled.div<{$isActive?: boolean}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  min-height: 75px;
  border: 0.5px solid
    ${({theme, $isActive}) => ($isActive ? theme.palette.common.redDark : theme.palette.grayscale._11)};
  border-radius: 10px;
`;

export const InputCreateName = styled.input`
  ${fontPrimaryReg};
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  width: 100%;
  color: ${({theme}) => theme.palette.grayscale._6};
  border: none;
  outline: none;
  font-style: italic;
  padding: 0;
  height: 100%;
`;

export const InputCreateDescription = styled.textarea`
  ${fontPrimaryReg};
  text-decoration: none;
  width: 100%;
  color: ${({theme}) => theme.palette.grayscale._13};
  border: none;
  outline: none;
  font-style: italic;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
  resize: none;
  height: 100%;
`;

export const CreateOwnBtnWrapper = styled.div<{$enabled?: boolean}>`
  cursor: ${({$enabled}) => ($enabled ? 'pointer' : 'none')};
  pointer-events: ${({$enabled}) => ($enabled ? 'auto' : 'none')};
`;

export const CreateOwnText = styled(Text).attrs({
  color: 'quaternary',
  size: 14,
})`
  cursor: pointer;
  font-style: italic;
`;

export const NeighInput = styled(Input)`
  height: 53px;
`;
