import styled, {css} from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {Message} from '../../../ui-kit/Form/Message';
import {Text} from '../../../ui-kit/Typography/Text';

const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

const fontPrimarySemiBold = css`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
`;

const fontSecondaryReg = css`
  font-family: ${({theme}) => theme.font.secondary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.secondary.regular.fontWeight};
`;

export const InnerJoin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 490px;
  min-height: 100vh;
  position: relative;
  padding: 30px 10px 108px 10px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const TitleJoin = styled.div`
  ${fontSecondaryReg};
  font-size: 42px;
  line-height: 100%;
  color: ${({theme}) => theme.palette.text.quaternary};
  margin-top: 23px;
  margin-bottom: 18px;
  &:first-letter {
    text-transform: uppercase;
  }
  word-break: break-word;
  ${Media.down.s} {
    font-size: 32px;
    margin-bottom: 16px;
  }
`;
export const DescrJoin = styled.div`
  ${fontPrimaryReg};
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 25px;
  ${Media.down.s} {
    font-size: 18px;
  }
`;

export const InnerOptionsJoin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  gap: 15px;
  margin-bottom: 30px;

  ${Media.down.s} {
    grid-template-columns: 1fr;
    padding: 0px 16px;
  }
`;

export const MemberWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 8px;
  border-top: ${({theme}) => `1px solid ${theme.palette.background.secondary}`};

  .avatar {
    height: 24px;
    width: 24px;
    margin-left: -5px;
  }
`;

export const OptionJoin = styled.div`
  position: relative;
  padding-left: 49px;
  box-sizing: border-box;
`;

export const InnerOptionIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
`;

export const TextOptionJoin = styled.div`
  ${fontPrimaryReg};
  font-size: 12px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.quaternary};
  ${Media.down.s} {
    font-size: 14px;
  }
`;

export const InnerLogo = styled.div`
  position: absolute;
  ${fontPrimaryReg};
  font-size: 10px;
  line-height: 150%;
  text-align: center;
  color: ${({theme}) => theme.palette.text.octonary};
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 36px;
`;

export const ErrorMessageJoin = styled(Message).attrs({variant: 'error'})`
  margin: 15px 0;
  padding: 15px 20px;
  box-sizing: border-box;
  ${fontPrimarySemiBold};
  font-size: 14px;
  text-align: center;
  justify-content: center;
`;

export const MembersText = styled(Text).attrs({variant: 'primary', size: 14})`
  margin-bottom: 22px;
  width: 200px;
  text-align: center;
`;

export const InnerAllWidth = styled.div`
  width: 100%;
  position: relative;
`;

export const MobileTopLine = styled.div`
  display: none;
  padding: 10px 6px 20px 6px;
  width: 100%;
  ${Media.down.m} {
    display: flex;
    padding: 10px 6px;
    margin-top: -20px;
  }
  & > div {
    position: static;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  & button {
    min-width: 160px;
    width: auto;
    padding: 0px 6px;
  }
`;

export const ItemsPairContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 240px;

  & :last-child:is(a) {
    margin-top: 20px;
  }
`;

export const JoinWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 28px 28px;
  max-width: 623px;
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.down.m} {
    width: auto;
    padding: 20px 20px;
    margin: 0 20px;
  }
`;
