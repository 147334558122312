import React from 'react';
import {ProfileIntroSkeletonContent, ProfileIntroSkeletonWrapper} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const ProfileIntroSkeleton: React.FC<{hideOnMob?: boolean}> = ({hideOnMob}) => {
  return (
    <ProfileIntroSkeletonWrapper $hideOnMob={hideOnMob}>
      <MemoLoadingSkeleton width={128} height={128} circle={true} />
      <ProfileIntroSkeletonContent>
        <MemoLoadingSkeleton width={331} height={32} />
        <MemoLoadingSkeleton width={207} height={18} />
        <MemoLoadingSkeleton width={753} height={18} />
        <MemoLoadingSkeleton width={303} height={18} />
      </ProfileIntroSkeletonContent>
    </ProfileIntroSkeletonWrapper>
  );
};
