import React from 'react';
import {useTranslation} from 'react-i18next';

import {AddListingBtn, Hidden, PlusIcon} from './styles';
import {route} from '../../constants/routes';
import {SetEventsSearchParams} from '../../containers/Events/types';
import {
  getEventTypeParams,
  getSearchEventOptions,
  getShowEventsParams,
  isAdditionalEventOption,
} from '../../helpers/event';
import {SearchBlock} from '../common/SearchBlock/SearchBlock';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface Props {
  setSearchParams?: SetEventsSearchParams;
  searchButtonText?: string;
  cantAdd?: boolean;
}

export const Search: React.FC<Props> = ({setSearchParams, cantAdd}) => {
  const {t} = useTranslation();
  const listingOptions = getSearchEventOptions(t);

  const onSearch = (searchText?: string, time?: SelectOptions) => {
    const searchParams = isAdditionalEventOption(time?.value) ? getShowEventsParams() : getEventTypeParams(time?.value);

    setSearchParams?.([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        ...searchParams,
      },
    ]);
  };

  return (
    <Hidden>
      <SearchBlock
        title={t('search:labels.events')}
        placeholder={t('search:labels.whatLooking')}
        options={listingOptions}
        onSearch={onSearch}
        moveLast
        buttons={
          !cantAdd ? (
            <AddListingBtn to={route.createEvent.get()} rounded width={'154px'}>
              <PlusIcon type={'plus'} stroke={'white'} width={'10px'} height={'10px'} />
              <span>{t('events:buttons.form.create')}</span>
            </AddListingBtn>
          ) : undefined
        }
      />
    </Hidden>
  );
};
