import React, {useEffect, useState} from 'react';
import {HelpDeskManager} from '../../components/HelpDesk/HelpDesk';
import {
  useChangeOrder,
  useCreateHelpDesk,
  useEditHelpDesk,
  useGetHelpDeskData,
  useHelpDeskManageActions,
} from '../../hooks/helpDesk';
import {HelpDeskItemT} from '../../types/helpDesk';
import {helpDeskWhereManager} from '../../helpers/helpDesk';
import {HelpDeskPageProps} from './HelpDeskContainer';
import {useViewerId} from '../../hooks/user';
import quotesJson from '../../files/quotes.json';

export const PageManager: React.FC<HelpDeskPageProps> = ({community}) => {
  const viewer = useViewerId('objectId');
  const deskData = useGetHelpDeskData({
    where: {
      ...helpDeskWhereManager,
      OR: [{Community: {have: {objectId: {equalTo: community?.objectId}}}}, {Community: {exists: false}}],
    },
  });
  const {custom, main: mainList} = deskData.lists;
  const [selectedDesk, setSelectedDesk] = useState<string | undefined>();
  const [customList, setCustomList] = useState<HelpDeskItemT[]>([]);

  const createData = useCreateHelpDesk({initialState: {}, onSuccess: deskData.refetch});
  const editData = useEditHelpDesk({
    initialState: customList.filter((el) => el.objectId === selectedDesk)?.[0],
    onSuccess: deskData.refetch,
  });
  const actions = useHelpDeskManageActions({onSuccess: deskData.refetch});
  const {handleChangeOrder} = useChangeOrder();

  const handleSetSelected = (id?: string) => setSelectedDesk(id);
  const handleSetList = (list: HelpDeskItemT[]) => setCustomList(list);
  const quotesList = JSON.parse(JSON.stringify(quotesJson));

  const loading = deskData.loading && !mainList.length && !customList.length && !quotesList.length;
  const customEdited = custom.map((el) => el?.updatedAt).join('');
  useEffect(() => {
    setCustomList(custom);
  }, [customEdited]);
  return (
    <HelpDeskManager
      customList={customList}
      mainList={mainList}
      setList={handleSetList}
      quotes={quotesList}
      createData={createData}
      refetch={deskData.refetch}
      loading={loading}
      changeOrder={handleChangeOrder}
      setSelectedDesk={handleSetSelected}
      editData={editData}
      isEditing={!!selectedDesk}
      viewer={viewer}
      selectedDesk={selectedDesk}
      {...actions}
    />
  );
};
