import React from 'react';
import {PeopleListProps} from './types';
import {PeopleListWrapper} from './styles';
import {PeopleList} from './PeopleList';
import {PeopleSkeletonSection} from '../common/Skeletons/PeopleSkeleton';
import {EmptyState} from '../EmptyState/EmptyState';
import {reportActions} from '../../hooks/report';
import {TypeCommunity} from '../../types/auth';
type PropsT = PeopleListProps & {
  listTitle?: string;
  loading?: boolean;
  reportActions?: reportActions;
  refetch?: () => void;
  adminLabels: Record<string, string>;
  typeUser?: TypeCommunity;
  loadMore?: () => void;
  hasNextPage?: boolean;
  listingCount?: [string, number][];
};

export const PeopleBlock: React.FC<PropsT> = ({
  list,
  itemsType,
  manageActions,
  actionsType,
  communityId,
  emptyText,
  viewerId,
  loading,
  fetchMore,
  hasMore,
  reportActions,
  refetch,
  adminLabels,
  typeUser,
  loadMore,
  hasNextPage,
  //listingCount,
}) => {
  if (!loading && !list?.length) return <EmptyState />;
  // const sortedList = useMemo(() => {
  //   const noAvatar = list?.filter((item, index) => !item.Avatar?.file?.url && index !== 0);
  //   const haveAvatar = list?.filter((item, index) => item.Avatar?.file?.url && index !== 0);
  //   return list?.[0] ? [list?.[0], ...(haveAvatar || []), ...(noAvatar || [])] : [];
  // }, [list]);
  return (
    <>
      <PeopleListWrapper>
        {loading && !list?.length ? (
          <PeopleSkeletonSection />
        ) : (
          <PeopleList
            communityId={communityId}
            list={list}
            itemsType={itemsType}
            actionsType={actionsType}
            manageActions={manageActions}
            emptyText={emptyText}
            viewerId={viewerId}
            fetchMore={fetchMore}
            hasMore={hasMore}
            reportActions={reportActions}
            refetch={refetch}
            adminLabels={adminLabels}
            typeUser={typeUser}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
            //listingCount={listingCount}
          />
        )}
      </PeopleListWrapper>
    </>
  );
};
