import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {User} from '../../../queries/types/user';
import {WrapperMsgInput} from './styles';
import {BaseGroupInput, BaseInput} from './BaseInput';
import {Inputs} from '../../common/PostInputs';
import {inputPostType} from '../../common/PostInputs/types';
import {useCreatePost} from '../../../hooks/feed';
import {TypeCommunity} from '../../../types/auth';
import {UserPostInfo} from '../../../types/community';
import {CommunityStateType} from '../../../states/community';
import {getResize} from '../../../helpers/file';
import {PermissionsList} from '../../../types/settings';
import {useLocation} from 'react-router-dom';
import {useUpdateUser, useViewer} from '../../../hooks/user';
import {useSetRecoilState} from 'recoil';
import {userState} from '../../../states';
import {isMobile} from 'react-device-detect';
import {TPost} from '../../../types/post';
import {GroupMessageInput} from '../../common/PostInputs/MessageInput';
import {PollInput} from '../../common/PostInputs/PollInput';

type TMsgInput = {
  user: User | null;
  community: CommunityStateType;
  refetchPosts?: () => void;
  typeUser?: TypeCommunity;
  permissions?: PermissionsList[];
  toggleCreating?: (v: boolean) => void;
  addLocalPost?: (local: TPost) => void;
  setLocalError?: (localRef: string) => void;
  loadingGenerate?: boolean;
  generateData?: (type: 'bio' | 'post') => Promise<string>;
};

export const PostCreate: React.FC<TMsgInput> = ({
  user,
  community,
  typeUser,
  permissions,
  toggleCreating,
  addLocalPost,
  setLocalError,
  generateData,
  loadingGenerate,
}) => {
  const {t} = useTranslation();
  const [postType, setPostType] = useState<inputPostType>(inputPostType.none);
  const canPostAsComm = !!permissions?.includes(PermissionsList.postAsCommunity);
  const PostAsAnnouncement = !!permissions?.includes(PermissionsList.postNotice) || typeUser === TypeCommunity.manager;
  const ManagerOrAdmin = typeUser === TypeCommunity.manager || (typeUser === TypeCommunity.admin && canPostAsComm);
  const [userPostInfo, setUserPostInfo] = useState<UserPostInfo>({
    avatar: getResize(user?.Avatar?.file?.url, 'lg'),
    postAs: '',
    isManager: typeUser === TypeCommunity.manager,
  });
  const handleChangePostType = (type: inputPostType) => {
    setPostType(type);
  };
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const type = searchParams.get('type');
  const handleResetType = () => setPostType(inputPostType.none);
  const onSuccess = () => {
    // refetchPosts?.();
  };
  const {onChange, onSubmit, success, values, error} = useCreatePost({
    initialState: {
      text: '',
      isMulti: false,
      medium: {
        email: false,
        loop: false,
        sms: false,
        push: false,
        message: false,
      },
    },
    addLocalPost,
    onSuccess: onSuccess,
    onLocalSuccess: handleResetType,
    setLocalError,
  });
  const viewer = useViewer();
  const setUser = useSetRecoilState(userState);
  const userUpdate = useUpdateUser();
  const handleSetUser = (user: UserPostInfo) => setUserPostInfo(user);

  const userOptions: UserPostInfo[] = useMemo(() => {
    return [
      {
        fullName: `${user?.firstName} ${user?.lastName || user?.firstLetter}`,
        avatar: getResize(user?.Avatar?.file?.url, 'md'),
        postAs: '',
        isManager: typeUser === TypeCommunity.manager,
      },
      {
        fullName: community?.name,
        avatar: getResize(community?.avatar, 'md'),
        postAs: 'community',
        isManager: true,
      },
    ];
  }, [user, community]);
  const [userOptionsInfo, communityOptionsInfo] = userOptions;
  const menuOptions: MenuItemType[] = [
    {
      title: t('community:loop.post.postAs'),
    },
    {
      render: 'line',
    },
    {
      title: userOptionsInfo?.fullName,
      onClick: () => handleSetUser(userOptionsInfo ?? {}),
    },
    {
      title: communityOptionsInfo?.fullName,
      onClick: () => handleSetUser(communityOptionsInfo ?? {}),
    },
  ];

  useEffect(() => {
    onChange({name: 'sentAs', value: userPostInfo.postAs});
  }, [userPostInfo]);

  const pollRef = useRef<any>();

  useEffect(() => {
    if (type) {
      window?.scrollTo(0, 0);
      setPostType(type as inputPostType);
    }
  }, [type]);
  useEffect(() => {
    toggleCreating?.(postType !== inputPostType.none);
    if (postType === inputPostType.poll) {
      pollRef.current?.focus();
    }
  }, [postType]);
  const handleSubmit = async () => {
    await onSubmit?.();
    if (viewer?.id && !viewer.checkList?.addPost.isChecked) {
      const newUserState = await userUpdate({id: viewer?.id});
      newUserState.data?.updateUser.user && setUser(newUserState.data?.updateUser.user);
    }
  };
  return (
    <WrapperMsgInput>
      {postType === inputPostType.none ? (
        <>
          {isMobile ? null : (
            <BaseInput
              user={userPostInfo}
              handleChangePostType={handleChangePostType}
              ManagerOrAdmin={ManagerOrAdmin}
              menuOptions={menuOptions}
              canPostAsAnnouncement={PostAsAnnouncement}
            />
          )}
        </>
      ) : (
        <Inputs
          inputType={postType}
          handleChangePostType={handleChangePostType}
          resetType={handleResetType}
          user={userPostInfo}
          handleChange={onChange}
          handleSubmit={handleSubmit}
          success={success}
          values={values}
          typeUser={typeUser}
          error={error}
          isManager={typeUser === TypeCommunity.manager}
          menuOptions={menuOptions}
          ManagerOrAdmin={ManagerOrAdmin}
          pollRef={pollRef}
          loadingGenerate={loadingGenerate}
          generateData={generateData}
        />
      )}
    </WrapperMsgInput>
  );
};

export type GroupPostCreateProps = {
  user: User | null;
  refetchPosts?: () => void;
  typeUser?: TypeCommunity;
  permissions?: PermissionsList[];
  groupId?: string;
};

export const GroupPostCreate: React.FC<GroupPostCreateProps> = ({refetchPosts, user, typeUser, groupId}) => {
  const [postType, setPostType] = useState<inputPostType>(inputPostType.none);
  const pollRef = useRef<any>();
  const {onChange, onSubmit, success, values, error} = useCreatePost({
    initialState: {text: ''},
    onSuccess: () => {
      refetchPosts?.();
      setPostType(inputPostType.none);
    },
    groupId: groupId,
  });
  const [userPostInfo] = useState<UserPostInfo>({
    avatar: getResize(user?.Avatar?.file?.url, 'lg'),
    postAs: '',
    isManager: typeUser === TypeCommunity.manager,
  });
  const ManagerOrAdmin = typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin;
  const handleChangePostType = (type: inputPostType) => {
    setPostType(type);
  };
  const handleResetType = () => setPostType(inputPostType.none);

  return (
    <WrapperMsgInput>
      {postType === inputPostType.none ? (
        <BaseGroupInput user={userPostInfo} handleChangePostType={handleChangePostType} />
      ) : postType === inputPostType.poll ? (
        <PollInput
          resetType={handleResetType}
          user={userPostInfo}
          handleSubmit={onSubmit}
          handleChange={onChange}
          success={success}
          values={values}
          typeUser={typeUser}
          error={error}
          handleChangePostType={handleChangePostType}
          inputType={inputPostType.message}
          key={inputPostType.message}
          menuOptions={[]}
          ManagerOrAdmin={ManagerOrAdmin}
          pollRef={pollRef}
          isManager={ManagerOrAdmin}
          isGroup={true}
        />
      ) : (
        <GroupMessageInput
          resetType={handleResetType}
          user={userPostInfo}
          handleSubmit={onSubmit}
          handleChange={onChange}
          success={success}
          values={values}
          typeUser={typeUser}
          error={error}
          handleChangePostType={handleChangePostType}
          inputType={inputPostType.message}
          key={inputPostType.message}
        />
      )}
    </WrapperMsgInput>
  );
};
