import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';
import {PageManager} from '../../components/LocalBiz/PageManager';
import {ISimpleAction, TActionsOnItems} from '../../components/LocalBiz/types';
import {GQLCompanyWhereInput} from '../../graphql.schema';
import {
  useDeleteCompany,
  useGetActionsOnCompanyInCommunity,
  useGetLocalBiz,
  useListingCompany,
} from '../../hooks/company';
import {entities, useReport} from '../../hooks/report';
import {useViewerId} from '../../hooks/user';
import {currentCommunity} from '../../states/community';

export const PageManagerContainer: React.FC = () => {
  const {report} = useReport(entities.company);

  const [searchParams, setSearchParams] = useState<GQLCompanyWhereInput | null>(null);
  const {data, loading, refetch, hasMore} = useGetLocalBiz(
    [searchParams].filter(Boolean) as Array<GQLCompanyWhereInput>,
  );

  const {unfeatureItem, featureItem} = useGetActionsOnCompanyInCommunity();
  const {listItem, unlistItem} = useListingCompany();
  const deleteItem = useDeleteCompany();

  const community = useRecoilValue(currentCommunity);
  const viewerId = useViewerId('objectId') as string;

  const handleActionOnItem = (fn: ISimpleAction): ISimpleAction => {
    return (...params) => {
      return fn(...params).then(() => refetch()) as Promise<void>;
    };
  };

  const actions: TActionsOnItems = {
    onPublish: handleActionOnItem(listItem),
    onUnPublish: handleActionOnItem(unlistItem),
    onFeature: handleActionOnItem(featureItem),
    onUnFeature: handleActionOnItem(unfeatureItem),
    onDelete: handleActionOnItem(deleteItem),
  };

  return (
    <PageManager
      community={community}
      loading={loading}
      companies={data}
      fetchData={refetch}
      hasMore={hasMore}
      setSearchParams={setSearchParams}
      userId={viewerId}
      actionsOnItems={actions}
      onReport={report}
    />
  );
};
