import React from 'react';
import {RichTextDefault, RichTextDescription, RichTextLinkifyExpanded} from './styles';
import {ShowMore, linkifyHtmlCustom} from '../ShowMore';

type TProps = {
  html?: string;
  noLinkify?: boolean;
  disableShowMore?: boolean;
  isForProfile?: boolean;
  isForDescription?: boolean;
};

export const RichText: React.FC<TProps> = ({html, noLinkify, isForDescription, disableShowMore, isForProfile}) => {
  if (!disableShowMore) {
    return (
      <RichTextLinkifyExpanded $isForProfile={isForProfile}>
        <ShowMore isLinkify={!noLinkify} html={html} />
      </RichTextLinkifyExpanded>
    );
  }

  if (!noLinkify && disableShowMore)
    return (
      <RichTextLinkifyExpanded
        $isForProfile={isForProfile}
        dangerouslySetInnerHTML={{__html: linkifyHtmlCustom(html)}}
      />
    );

  if (isForDescription) {
    return <RichTextDescription dangerouslySetInnerHTML={{__html: linkifyHtmlCustom(html)}} />;
  }

  return <RichTextDefault dangerouslySetInnerHTML={{__html: html || ''}} />;
};
