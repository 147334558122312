import React from 'react';
import {ListingViewAsideWrapper, ListingViewContentWrapper, ListingViewWrapper} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const ListingViewSkeleton: React.FC = () => {
  return (
    <ListingViewWrapper>
      <MemoLoadingSkeleton width={350} height={20} />
      <MemoLoadingSkeleton width={200} height={20} />
      <ListingViewContentWrapper>
        <MemoLoadingSkeleton width={600} height={400} />
        <ListingViewAsideWrapper>
          <MemoLoadingSkeleton width={300} height={200} />
          <MemoLoadingSkeleton width={170} height={20} />
          <MemoLoadingSkeleton width={100} height={15} />
        </ListingViewAsideWrapper>
      </ListingViewContentWrapper>
    </ListingViewWrapper>
  );
};
