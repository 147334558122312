import {TGroup} from '../../types/group';
import {AddGroupData} from '../../hooks/groups';

export enum FormMode {
  Add = 'add',
  Edit = 'edit',
}

export type TModalData = {
  mode: FormMode;
  selectedGroup?: TGroup;
};

export type TFormDataType = {
  name?: string;
  description?: string;
  Avatar?: File | null;
  isDeleted?: string;
};

export interface IActionsOnGroupsData {
  addGroup: (cb?: () => void) => Promise<void>;
  clearValues: () => void;
  setValue: (params: {key: keyof AddGroupData; value: any}) => void;
  setFile: (params: {key: keyof AddGroupData; value?: File | null}) => void;
  values: TFormDataType;
  error?: string;
  loading: boolean;
  deleteGroup: (id?: string) => Promise<boolean>;
  joinGroup: (id?: string) => void;
  leaveGroup: (id?: string) => void;
  onFeatured: (id?: string) => void;
  unFeatured: (id?: string) => void;
  edit?: () => void;
  updateGroup: (doc: TGroup, cb?: () => void) => Promise<void>;
}
