import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {getArrByCondition} from '../../helpers/common';
import {TDocument} from '../../types/document';
import {IActionsOnDocumentsData} from './types';

type IActionsOnDocumentsDataOverwrite = IActionsOnDocumentsData & {
  onDelete: () => void;
};

export const useGetMenuItemsForDocument = (params: {
  document: TDocument;
  data: IActionsOnDocumentsDataOverwrite;
  canEdit: boolean;
}) => {
  const {t} = useTranslation();

  const {document, data, canEdit} = params;
  const {objectId, isPublished, viewerIsOwner} = document;
  const {onDelete, unPublish, publish, edit} = data;

  const itemDelete: MenuItemType = {
    title: t('documents:dropdownOptions.delete'),
    render: 'danger',
    onClick: onDelete,
  };

  const itemEdit: MenuItemType = {
    title: t('documents:dropdownOptions.edit'),
    onClick: edit,
  };

  const itemUnPublish: MenuItemType = {
    title: t('documents:dropdownOptions.unPublish'),
    onClick: unPublish,
  };

  const itemPublish: MenuItemType = {
    title: t('documents:dropdownOptions.publish'),
    onClick: publish,
  };

  const menuItems = useMemo<Array<MenuItemType>>(() => {
    if (!(canEdit || viewerIsOwner)) return [] as Array<MenuItemType>;
    if (!canEdit) return [itemEdit, itemDelete];
    return [...getArrByCondition(isPublished, itemUnPublish, itemPublish), itemEdit, itemDelete];
  }, [document, canEdit, viewerIsOwner]);

  const mobileMenuItems: Array<MobileMenuEntry> = menuItems.map((el) => ({
    title: el.title || '',
    to: objectId && el.to?.(objectId),
    type:
      (el.render as string) === 'regular' || (el.render as string) === 'danger'
        ? (el.render as 'regular' | 'danger')
        : undefined,
    onClick: () => objectId && el.onClick?.(objectId),
  }));

  return {menuItems, mobileMenuItems};
};
