import React from 'react';

import {PostAttachments} from './PostAttachments';
import {PostHead} from './PostHead';
import {PostItem} from './PostItem';
import {MemoPostTextContent} from './PostTextContent';
import {getResize} from '../../../../helpers/file';
import {TPostItem} from '../../../../queries/types/post';
import {TypeCommunity} from '../../../../types/auth';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {Link} from '../../../../ui-kit/Typography/Link';
import {
  PostAvatar,
  PostBodyWrapper,
  PostLeftSide,
  PostRetryButton,
  PostRetryWrapper,
  PostRightSide,
  PostTopPart,
} from '../styles';
import {PostTopContentProps} from '../types';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';

export const PostTopContent: React.FC<PostTopContentProps> = ({
  Author,
  text,
  createdAt,
  objectId,
  Items,
  isComment,
  type,
  Attachments,
  communityId,
  listingsCount,
  getSignValue,
  profileLink,
  typeUser,
  onClick,
  forwardProfile,
  setIsModal,
  isLocal,
  isLocalEdited,
  onCreateRetry,
  isError,
  onLikeItem,
  itemsLiked,
  reportItemActions,
}) => {
  const onRetry = () => {
    objectId && onCreateRetry?.(objectId);
  };
  const preventRedirect = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };
  return (
    <PostTopPart>
      <PostLeftSide $isComment={isComment} $nonClickable={profileLink === '#'}>
        <Link to={profileLink || '#'}>
          <PostAvatar>
            <Avatar size={'md'} src={getResize(Author?.Avatar?.file?.url, 'md')} $isOnline={Author?.isOnline} />
          </PostAvatar>
        </Link>
      </PostLeftSide>
      {isError && (
        <PostRetryWrapper>
          <PostRetryButton onClick={onRetry}>
            <IconSvg type={'info'} fillChildren={'red'} stroke={'red'} strokeWidth={1.5} viewBox={'0 0 21 21'} /> Retry
          </PostRetryButton>
        </PostRetryWrapper>
      )}
      <PostRightSide onClick={onClick}>
        <PostHead
          forwardProfile={forwardProfile}
          Author={Author}
          createdAt={createdAt}
          objectId={objectId}
          isComment={isComment}
          type={type}
          listingsCount={listingsCount}
          profileLink={profileLink}
          typeUser={typeUser}
          preventRedirect={preventRedirect}
        />
        <PostBodyWrapper isComment={isComment}>
          <MemoPostTextContent text={text} type={type} />
          <PostAttachments Attachments={Attachments} setIsModal={setIsModal} isLocal={isLocal || isLocalEdited} />
          <PostItem
            items={Items as Partial<TPostItem>[]}
            communityId={communityId}
            getSignValue={getSignValue}
            type={type}
            isManager={typeUser === TypeCommunity.manager}
            preventRedirect={preventRedirect}
            itemsLiked={itemsLiked}
            onLikeItem={onLikeItem}
            reportItemActions={reportItemActions}
          />
        </PostBodyWrapper>
      </PostRightSide>
    </PostTopPart>
  );
};
