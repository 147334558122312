import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {route} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useAmenityFormData, useEditAmenity, useGetAmenity} from '../../hooks/amenity';
import {AmenityForm} from '../../components/Amenities/Form';
import {AmenityFormAction, BookingTimingField} from '../../types/amenity';
import {getTimesDivider} from '../../helpers/amenity';

export const EditAmenityContainer = () => {
  const [community] = useRecoilState(currentCommunity);
  const {id} = useParams<{id?: string}>();
  const {push} = useHistory();
  const {data: amenity, loading: loadingGetItem} = useGetAmenity({id});
  const formData = useAmenityFormData({images: amenity?.images});
  const {getLink} = useLinks();
  const {currency} = useGetCurrencyValue();

  const {t} = useTranslation();
  const textSeo = t('common:seo.amenities.edit', {text: amenity?.name});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  const {loading: loadingEditItem, ...data} = useEditAmenity({
    initialState: {
      ...amenity,
      openFrom: getTimesDivider(BookingTimingField.from, amenity?.bookingTiming),
      openUntil: getTimesDivider(BookingTimingField.till, amenity?.bookingTiming),
      images: formData.dataImages,
    },
    onSuccess: () => push(getLink(route.amenityView.get({id: id ?? ''}))),
  });

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <AmenityForm
        communityId={community?.id}
        action={AmenityFormAction.edit}
        data={{...data, loading: loadingGetItem || loadingEditItem}}
        formData={formData}
        currSign={currency?.sign || ''}
      />
    </>
  );
};
