import React from 'react';
import {useTranslation} from 'react-i18next';
import {InnerAuth, OkIcon, SubtitleWrapper, TextButton, TitleAuth} from './styles';
import {FormTopTitle, SkipBtn} from '../Account/Create/styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';
import {PrimaryExternalLinkButton} from '../../ui-kit/Button/styles';

type CommunityRequestedProps = {
  onClickSecond?: () => void;
};

export const CommunityRequested: React.FC<CommunityRequestedProps> = ({onClickSecond}) => {
  const {t} = useTranslation();
  const title = t('auth:foundCommunity.requested.title');
  const descr = t('auth:foundCommunity.requested.descr');
  const continueTo = t('auth:foundCommunity.notFound.continue');
  const button = t('auth:foundCommunity.requested.button');

  return (
    <InnerAuth>
      <FormTopTitle>
        <OkIcon>
          <IconSvg type={'big-circle-ok'} width="103" height="103" viewBox="0 0 103 103" stroke={'aqua'} />
        </OkIcon>
        <SkipBtn onClick={onClickSecond}>
          <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
            {t('auth:foundCommunity.requested.continue')}
          </Text>
        </SkipBtn>
      </FormTopTitle>
      <TitleAuth>{title}</TitleAuth>
      <SubtitleWrapper>{descr}</SubtitleWrapper>
      <PrimaryExternalLinkButton target={'_blank'} href={'https://hangeh.com/super-resident'}>
        {button}
      </PrimaryExternalLinkButton>
      <TextButton onClick={onClickSecond}>{continueTo}</TextButton>
    </InnerAuth>
  );
};
