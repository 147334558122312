import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {TextArea} from '../../ui-kit/Form/TextArea/TextArea';

export const Wrapper = styled.div`
  max-width: 500px;
  height: max-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 45px;

  & > * {
    margin-bottom: 25px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 42px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: max-content;
  position: relative;

  ${Media.down.s} {
    width: 90%;
  }
`;

export const CopyIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export const StyledTextArea = styled(TextArea)`
  min-height: 15rem;
`;

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
