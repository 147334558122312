import {Connection} from './types/parse';
import {gql} from '@apollo/client';
import {OrderFragment, ShortOrderFragment} from './fragments/order';
import {Order, TCalcOrderData} from './types/order';
import {GQLOrderStatuses, GQLOrderType} from '../graphql.schema';
import {PreOrderCalcDataT} from '../types/item';

export type OrdersResponseType = {
  orders: Connection<Order>;
};

export const GetOrdersQuery = gql`
  query orders($cursor: String, $first: Int, $where: OrderWhereInput, $order: [OrderOrder!], $skip: Int) {
    orders(after: $cursor, order: $order, first: $first, where: $where, skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
  }
  ${OrderFragment}
`;

export interface MakeOrderResponseType {
  makeOrder: {
    order: string;
  };
}

export interface MakeOrderRequestType {
  communityId: string;
  orderType: GQLOrderType;
  objectId: string;
  startTime: string | Date;
  endTime?: string;
  guests?: number;
  recurringEvent?: boolean;
}

export const MakeOrder = gql`
  mutation makeOrder(
    $communityId: ID!
    $objectId: ID!
    $orderType: OrderType!
    $startTime: String!
    $endTime: String
    $guests: Int
    $recurringEvent: Boolean
  ) {
    makeOrder(
      input: {
        communityId: $communityId
        objectId: $objectId
        orderType: $orderType
        startTime: $startTime
        guests: $guests
        endTime: $endTime
        recurringEvent: $recurringEvent
      }
    ) {
      order
    }
  }
`;

export interface ManageOrderResponseType {
  manageOrder: {
    order: string;
  };
}

export interface ManageOrderRequestType {
  orderId: string;
  status: GQLOrderStatuses;
}

export const ManageOrder = gql`
  mutation manageOrder($orderId: ID!, $status: OrderStatuses!) {
    manageOrder(input: {orderId: $orderId, status: $status}) {
      order
    }
  }
`;

export interface CalcOrderDataResType {
  calcOrderData: TCalcOrderData;
}

export interface CalcOrderDataReqType {
  orderId: string;
}

export const CalcOrderData = gql`
  mutation calcOrderData($orderId: ID!) {
    calcOrderData(input: {orderId: $orderId}) {
      period
      price
      pricePeriod
      discount
      hfListerPrc
      hfListerAbs
      hfListerAmt
      hfRequesterPrc
      hfRequesterAbs
      hfRequesterAmt
      cfListerPrc
      cfListerAbs
      cfListerAmt
      sfListerAmt
      cfRequesterPrc
      cfRequesterAbs
      cfRequesterAmt
      sfRequesterAmt
      ppListerPrc
      ppListerAbs
      ppListerAmt
      ppRequesterPrc
      ppRequesterAbs
      ppRequesterAmt
      listerTotalAmt
      requesterTotalAmt
    }
  }
`;

export interface EditOrderResType {
  objectId: string;
}

export interface EditOrderReqType {
  id: string;
  fields: {
    startTime: string;
    endTime: string;
  };
}

export const EditOrder = gql`
  mutation updateOrder($id: ID!, $fields: UpdateOrderFieldsInput) {
    updateOrder(input: {id: $id, fields: $fields}) {
      order {
        objectId
      }
    }
  }
`;

export interface PreOrderCalcDataResponseType {
  preOrderCalcData: PreOrderCalcDataT;
}

export interface PreOrderCalcDataRequestType {
  communityId: string;
  orderType: GQLOrderType;
  objectId: string;
  startTime: string;
  endTime?: string;
  guests?: number;
}

export const preOrderCalcData = gql`
  mutation preOrderCalcData(
    $communityId: ID!
    $objectId: ID!
    $orderType: OrderType!
    $startTime: String!
    $endTime: String
    $guests: Int
  ) {
    preOrderCalcData(
      input: {
        communityId: $communityId
        objectId: $objectId
        orderType: $orderType
        startTime: $startTime
        guests: $guests
        endTime: $endTime
      }
    ) {
      itemCost
      sfRequesterAmt
      ppRequesterAmt
      requesterTotalAmt
    }
  }
`;

export interface GetSeoOrderItemDataResponseType {
  data: {
    getSeoOrderItemData: {
      item: {
        name?: string;
        descr?: string;
        images?: any[];
      };
    };
  };
}

export interface GetSeoOrderItemDataRequestType {
  orderId: string;
}

export const getSeoOrderItemData = gql`
  mutation getSeoOrderItemData($orderId: ID!) {
    getSeoOrderItemData(input: {orderId: $orderId}) {
      item
    }
  }
`;

export const GetShortOrdersQuery = gql`
  query orders($cursor: String, $first: Int, $where: OrderWhereInput, $order: [OrderOrder!], $skip: Int) {
    orders(after: $cursor, order: $order, first: $first, where: $where, skip: $skip) {
      count
      edges {
        node {
          ...ShortOrderFragment
        }
      }
    }
  }
  ${ShortOrderFragment}
`;
