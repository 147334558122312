import React, {useMemo, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {
  AddGroupQuery,
  AddGroupsParams,
  AddGroupsRes,
  GetGroupsParams,
  GetGroupsQuery,
  GetGroupsRes,
  UpdateGroupParams,
  UpdateGroupQuery,
  UpdateGroupRes,
  GetGroupParams,
  GetGroupQuery,
  GetGroupRes,
  GetJoinedGroupsQuery,
  DeleteGroupQuery,
  DeleteGroupRes,
  DeleteGroupParams,
  LeaveGroupQuery,
  LeaveGroupRes,
  LeaveGroupParams,
  JoinGroupQuery,
  JoinGroupParams,
  JoinGroupRes,
  GetGroupMembersQuery,
  GetGroupMembersParams,
  ActionsManagerQueryGroup,
  GroupActionsManagerResponseType,
  GroupActionsManagerRequestType,
  GroupManageAction,
  GetGroupLinksQuery,
  GetGroupsLinkRes,
} from '../queries/group';
import {TGroup} from '../types/group';
import {useRecoilValue} from 'recoil';
import {CommunityStateType, currentCommunity} from '../states/community';
import {getArrByCondition, getObjByCondition} from '../helpers/common';
import {
  CreateAppFile,
  CreateFileResponseType,
  DeleteAppFile,
  DeleteFileRequestType,
  DeleteFileResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {groupsToState, groupToState} from '../helpers/group';
import {changeFileName} from '../helpers/file';
import {useParams} from 'react-router-dom';
import {
  GQLGroupWhereInput,
  GQLPollOptionOrder,
  GQLPostOrder,
  GQLPostWhereInput,
  GQLUserWhereInput,
} from '../graphql.schema';
import {useGetCommunityPosts} from './feed';
import {useTranslation} from 'react-i18next';
import {MenuItemType} from '../ui-kit/Menu/types';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {IActionsOnGroupsData} from '../components/Groups/types';
import {InterestsGroup} from '../constants/interests';
import {route} from '../constants/routes';
import {TInterest} from '../types/interests';

export type AddGroupData = {
  name?: string;
  description?: string;
  Avatar?: File | null;
};

export interface ISetValue {
  ({key, value}: {key: keyof AddGroupData; value: any}): void;
}

export const useAddGroup = (params: {
  onSuccess?: () => void;
  values: AddGroupData;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const {onSuccess, values, setError} = params;

  const [add, addData] = useMutation<AddGroupsRes, AddGroupsParams>(AddGroupQuery);
  const community = useRecoilValue(currentCommunity);
  const _values = {
    ...values,
    name: values.name?.trim(),
  };

  const addGroup = async (cb?: () => void) => {
    const {name, description, Avatar} = _values;
    try {
      if (!name) throw new Error('error:documents.name');

      await add({
        variables: {
          fields: {
            name,
            description,
            ...getObjByCondition(!!Avatar, {
              Avatar: {
                createAndLink: {
                  file: {
                    upload: Avatar,
                  },
                },
              },
            }),
            Community: {
              link: community?.objectId,
            },
          },
        },
      });

      onSuccess?.();
      cb?.();
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    addData,
    addGroup,
  };
};

export const useUpdateGroup = (params: {
  onSuccess?: () => void;
  values: AddGroupData;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const {onSuccess, values, setError} = params;

  const [update, updateData] = useMutation<UpdateGroupRes, UpdateGroupParams>(UpdateGroupQuery);
  const [updateFile, {loading: updateLoading}] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(
    UpdateAppFile,
  );
  const [createFile, {loading: createLoading}] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [deleteFile, {loading: deleteLoading}] = useMutation<DeleteFileResponseType, DeleteFileRequestType>(
    DeleteAppFile,
  );
  const _values = {
    ...values,
    name: values.name?.trim(),
  };

  const updateGroup = async (group: TGroup, cb?: () => void) => {
    const {Avatar: initialAvatar, objectId: id} = group;
    const {name, description, Avatar} = _values;
    try {
      if (!id) throw new Error('error:id');
      if (name?.trim() === '') throw new Error('error:documents.name');

      let coverObjectId: string | undefined | null;
      if (Avatar && initialAvatar) {
        const {data} = await updateFile({
          variables: {
            id: initialAvatar?.objectId as string,
            fields: {
              file: {
                upload: Avatar,
              },
            },
          },
        });

        coverObjectId = data?.updateAppFile.appFile.objectId;
      } else if (Avatar && !initialAvatar) {
        const {data} = await createFile({
          variables: {
            fields: {
              file: {
                upload: Avatar,
              },
            },
          },
        });

        coverObjectId = data?.createAppFile.appFile.objectId;
      } else if ('Avatar' in values && !Avatar && initialAvatar) {
        await deleteFile({
          variables: {
            id: initialAvatar.objectId,
          },
        });

        coverObjectId = null;
      }

      await update({
        variables: {
          id,
          fields: {
            ...getObjByCondition(!!name, {
              name: name,
            }),
            ...getObjByCondition(!!description, {
              description: description,
            }),
            ...getObjByCondition(coverObjectId !== undefined, {
              Avatar: {
                link: coverObjectId,
              },
            }),
          },
        },
      });

      onSuccess?.();
      cb?.();
    } catch (err) {
      setError(err.message);
    }
  };
  return {
    update: updateGroup,
    updateData: {...updateData, loading: createLoading || updateLoading || deleteLoading || updateData.loading},
  };
};

export const useGetGroups = () => {
  const {t} = useTranslation();
  const community = useRecoilValue(currentCommunity);

  const {data, ...otherData} = useQuery<GetGroupsRes, GetGroupsParams>(GetGroupsQuery, {
    variables: {
      where: {
        AND: [
          {
            Community: {
              have: {
                objectId: {
                  equalTo: community?.objectId,
                },
              },
            },
          },
          {
            isDeleted: {
              equalTo: false,
            },
          },
        ],
      },
    },
  });

  return {
    data: groupsToState(data?.groups, t),
    count: data?.groups.count,
    ...otherData,
  };
};

export const useActionOnGroup = (params: {onSuccess?: () => void; community?: CommunityStateType}) => {
  const {onSuccess, community} = params;

  const [values, setValues] = useState<AddGroupData>({});
  const [error, setError] = useState<string>();

  const {addGroup, addData} = useAddGroup({
    onSuccess,
    values,
    setError,
  });
  const {updateData, update} = useUpdateGroup({
    onSuccess,
    values,
    setError,
  });
  const deleteGroup = useDeleteGroup(onSuccess);
  const joinGroup = useJoinLeaveGroup(onSuccess).join;
  const leaveGroup = useJoinLeaveGroup(onSuccess).leave;

  const {onSubmit: onActionsItemManager, loading: loadActionManager} = useManagerActionsGroup({
    communityIdList: [community?.objectId || ''],
    onSuccess,
  });

  const onFeatured = (id?: string) => {
    onActionsItemManager({
      typeBtn: GroupManageAction.feature,
      groupId: id,
    });
  };

  const unFeatured = (id?: string) => {
    onActionsItemManager({
      typeBtn: GroupManageAction.unfeature,
      groupId: id,
    });
  };

  const setValue = ({key, value}: {key: keyof AddGroupData; value: any}) => {
    setError(undefined);
    setValues((prev) => ({...prev, [key]: value}));
  };
  const setFile = ({key, value}: {key: keyof AddGroupData; value?: File | null}) => {
    setError(undefined);
    if (!value) return setValue({key: key, value: value});
    if (key === 'Avatar') {
      if (!value?.type.startsWith('image/')) return setError('error:documents.fileNotSupported');
    }
    setValue({key: key, value: changeFileName(value as File)});
  };

  const clearValues = () => {
    setError(undefined);
    setValues({});
  };

  return {
    deleteGroup,
    setValue,
    clearValues,
    setFile,
    addGroup,
    updateGroup: update,
    joinGroup,
    leaveGroup,
    onFeatured,
    unFeatured,
    loading: addData.loading || updateData.loading || loadActionManager,
    error,
    values,
  };
};

export const useGetGroup = () => {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const {data, ...otherData} = useQuery<GetGroupRes, GetGroupParams>(GetGroupQuery, {
    variables: {
      id: id,
    },
    skip: !id,
  });

  return {
    data: data && groupToState(data.group, t),
    ...otherData,
  };
};

export const useGetGroupLinks = (communityId?: string) => {
  const {data} = useQuery<GetGroupsLinkRes>(GetGroupLinksQuery, {
    variables: {
      where: {
        Community: {
          have: {
            objectId: {
              equalTo: communityId,
            },
          },
        },
        type: {
          equalTo: 'default',
        },
      } as GQLGroupWhereInput,
    },
    skip: !communityId,
  });

  const result = data?.groups?.edges?.map((edge) => edge?.node);
  const info = Object.keys(InterestsGroup)
    ?.filter((item) =>
      result?.find(
        (group) => group?.alias?.toLowerCase() === InterestsGroup[item as keyof typeof InterestsGroup]?.toLowerCase(),
      ),
    )
    .map((res) => {
      const objectId = result?.find(
        (group) => InterestsGroup[res as keyof typeof InterestsGroup]?.toLowerCase() === group?.alias?.toLowerCase(),
      )?.objectId;
      return {
        interest: res,
        link: route.groupView?.get({id: objectId || ''}),
      };
    }) as Array<{interest: TInterest; link: string}>;
  return {
    data: info,
  };
};

type useCommunityFeedProps = {
  where?: GQLPostWhereInput;
  communityId?: string;
  groupId?: string;
  userId?: string;
  first?: number;
  isManager?: boolean;
};

export const useGroupFeed = (options: useCommunityFeedProps) => {
  const query = {
    Community: {
      have: {
        objectId: {
          equalTo: options?.communityId,
        },
      },
    },
    Hide: {
      haveNot: {
        objectId: {
          equalTo: options?.userId,
        },
      },
    },
    Parent: {
      exists: false,
    },
    Group: {
      have: {
        objectId: {
          equalTo: options?.groupId,
        },
      },
    },
    ...(options?.where ?? {}),
  };

  const fetchOptions = {
    skip: false,
    where: query,
    order: [GQLPostOrder.Pin_DESC, GQLPostOrder.createdAt_DESC],
    optionsOrder: GQLPollOptionOrder.position_ASC,
    userId: options?.userId,
    first: options?.first || 8,
    isManager: options?.isManager,
  };

  const result = useGetCommunityPosts(fetchOptions);

  return {...result, data: result?.data || null};
};

export const useDeleteGroup = (onSuccess?: () => void) => {
  const [del] = useMutation<DeleteGroupRes, DeleteGroupParams>(DeleteGroupQuery);
  return async (id?: string) => {
    if (!id) return false;

    try {
      const res = await del({
        variables: {
          id: id,
        },
      });
      if (res) onSuccess?.();
      return true;
    } catch {
      return false;
    }
  };
};

export const useManagerActionsGroup = (params: {
  communityIdList?: string[];
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const {onSuccess, onError, communityIdList} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionRequest, {loading}] = useMutation<GroupActionsManagerResponseType, GroupActionsManagerRequestType>(
    ActionsManagerQueryGroup,
  );

  const onSubmit = async (params: {groupId?: string; typeBtn: GroupManageAction}) => {
    if (!params?.groupId || !communityIdList) return;

    try {
      const response = await ActionRequest({
        variables: {
          groupId: params?.groupId,
          communityIdList: communityIdList,
          action: params.typeBtn,
        },
      });

      const data = response?.data?.groupManageButtons;
      if (!data) throw new Error();

      setError(null);
      onSuccess?.();
    } catch (error) {
      setError(error);
      onError?.();
    }
  };
  return {onSubmit, error, loading};
};

export const useJoinLeaveGroup = (onSuccess?: () => void) => {
  const [leaveGroup] = useMutation<LeaveGroupRes, LeaveGroupParams>(LeaveGroupQuery);
  const [joinGroup] = useMutation<JoinGroupRes, JoinGroupParams>(JoinGroupQuery);

  const join = async (id?: string) => {
    if (!id) return;
    try {
      const res = await joinGroup({variables: {groupId: id}});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  const leave = async (id?: string) => {
    if (!id) return;
    try {
      const res = await leaveGroup({variables: {groupId: id}});
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };
  return {join, leave};
};

type IActionsOnGroupsDataOverride = IActionsOnGroupsData & {
  onUpdate: () => void;
  onDelete: () => void;
  onReport: () => void;
};

export const useGetMenuItemsForGroup = (params: {
  group: TGroup;
  data: IActionsOnGroupsDataOverride;
  canEdit: boolean;
  isJoined?: boolean;
  isFeatured?: boolean;
}) => {
  const {t} = useTranslation();

  const {data, canEdit, group, isJoined, isFeatured} = params;
  const {onUpdate, leaveGroup, onDelete, onReport, onFeatured, unFeatured} = data;
  const objectId = group?.objectId;

  const itemDelete: MenuItemType = {
    title: t('groups:buttons.delete'),
    render: 'danger',
    onClick: () => onDelete?.(),
  };

  const itemEdit: MenuItemType = {
    title: t('groups:buttons.edit'),
    onClick: () => onUpdate?.(),
  };

  const itemFeatured: MenuItemType = {
    title: t('groups:buttons.featured'),
    onClick: () => onFeatured?.(objectId),
  };
  const itemUnFeatured: MenuItemType = {
    title: t('groups:buttons.removeFeature'),
    onClick: () => unFeatured?.(objectId),
  };

  const itemLeave: MenuItemType = {
    title: t('groups:buttons.leave'),
    onClick: () => leaveGroup?.(objectId),
  };

  const itemReport: MenuItemType = {
    title: t('groups:buttons.report'),
    onClick: () => onReport?.(),
  };
  const FeatureOpt = isFeatured ? itemUnFeatured : itemFeatured;
  const menuItems = useMemo<Array<MenuItemType>>(() => {
    if (canEdit)
      return [...getArrByCondition(!!isJoined, itemLeave), FeatureOpt, itemEdit, itemDelete] as Array<MenuItemType>;
    return [...getArrByCondition(!!isJoined, itemLeave), itemReport];
  }, [group, canEdit, isJoined]);
  const mobileMenuItems: Array<MobileMenuEntry> = menuItems.map((el) => ({
    title: el.title || '',
    to: objectId && el.to?.(objectId),
    type:
      (el.render as string) === 'regular' || (el.render as string) === 'danger'
        ? (el.render as 'regular' | 'danger')
        : undefined,
    onClick: () => objectId && el.onClick?.(objectId),
  }));

  return {menuItems, mobileMenuItems};
};

export type JoinedGroupsList = {objectId?: string; name?: string}[];

export const useGetJoinedGroups = (userId?: string) => {
  const {data, ...rest} = useQuery<GetGroupsRes, GetGroupsParams>(GetJoinedGroupsQuery, {
    variables: {
      where: {
        Members: {
          have: {
            objectId: {
              equalTo: userId,
            },
          },
        },
      },
    },
  });

  return {
    joinedList: data?.groups?.edges?.map((el) => ({
      objectId: el?.node?.objectId,
      name: el?.node?.name,
    })) as JoinedGroupsList,
    count: data?.groups?.count,
    ...rest,
  };
};

type useGetGroupMembersOptions = {
  first?: number;
  groupId?: string;
  membersWhere?: GQLUserWhereInput;
};

export const useGetGroupMembers = ({first, groupId, membersWhere}: useGetGroupMembersOptions) => {
  const {data, ...rest} = useQuery<GetGroupsRes, GetGroupMembersParams>(GetGroupMembersQuery, {
    variables: {
      ...(first ? {first} : {}),
      ...(membersWhere ? {membersWhere} : {}),
      where: {objectId: {equalTo: groupId}},
    },
    skip: !groupId,
  });
  return {data: data?.groups?.edges?.[0]?.node?.Members?.edges?.map((el) => el.node), ...rest};
};
