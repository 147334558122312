import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {Seo} from '../../../components/SEO';
import {PageManagerContainer} from './Manager';
import {PageResidentContainer} from './Resident';
import {makeSeoKeywords} from '../../../helpers/common';
import {currentCommunity, userPermissions} from '../../../states/community';
import {typeUser as StateTypeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {PermissionsList} from '../../../types/settings';
import {EmptyTag, MobContainerProps} from '../../MobileTabs';
import {NavContainer} from '../../Navigation';

import {useViewerId} from '../../../hooks/user';

export const EventsPage: React.FC<MobContainerProps> = ({hideNav}) => {
  const [typeUser] = useRecoilState(StateTypeUser);
  const viewerId = useViewerId('objectId');
  const [community] = useRecoilState(currentCommunity);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const textSeo = t('common:seo.events.events', {
    text: community?.name || '',
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const [permissions] = useRecoilState(userPermissions);

  useEffect(() => {
    if (!typeUser || !community) {
      return setLoading(true);
    }
    setLoading(false);
  }, [typeUser, community]);

  if (loading) {
    //  ??????
    <LottieLoader $isVisible={true} allScreen={true} />;
  }
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  const canManage = useMemo(
    () => permissions.includes(PermissionsList.manageEvent) && typeUser === TypeCommunity.admin,
    [permissions, typeUser],
  );
  return (
    <Wrapper>
      <Seo title={textSeo} keywords={seoKeywords} />
      {(typeUser === TypeCommunity.manager || canManage) && (
        <PageManagerContainer viewerId={viewerId} community={community} />
      )}
      {(typeUser === TypeCommunity.resident || typeUser === TypeCommunity.admin) && !canManage && (
        <PageResidentContainer viewerId={viewerId} community={community} />
      )}
    </Wrapper>
  );
};
