import {TEXTAREA_MAX_LENGTH} from '../../constants/common';
import {FormValidation} from '../../helpers/FormValidation';
import {
  AccountFormValues,
  AdditionalAddressFields,
  CommunityAdminValues,
  CommunityProfileValues,
  ContactsFormValues,
  ProfileFormValues,
} from '../../types/settings';
import {AddressFormData} from '../Account/Create/Forms/AddressForm';
import {CommunityTypes} from '../../helpers/community';

export const ProfileFormSchema = FormValidation.schema<Partial<ProfileFormValues>>({
  firstName: FormValidation.string('error:firstName'),
  lastName: FormValidation.string('error:lastName'),
  bio: FormValidation.string('error:bio').length({lte: TEXTAREA_MAX_LENGTH}),
  study: FormValidation.string('error:study').isNullable,
  languages: FormValidation.string('error:languages').isNullable,
  work: FormValidation.string('error:work').isNullable,
  gender: FormValidation.string('error:gender').isNullable,
  username: FormValidation.string('error:username'),
});

export const ContactsFormSchema = FormValidation.schema<Partial<ContactsFormValues>>({
  email: FormValidation.string().email('error:email'),
  phone: FormValidation.handler((value) => {
    if (value && typeof value === 'string') return value.length >= 12 && value !== '+';
    else return false;
  }, 'error:phone'),
  address: FormValidation.string('error:address'),
  zip: FormValidation.string('error:zip'),
  // aptSuite: FormValidation.string('error:aptSuite'),
  Location: FormValidation.handler((value) => {
    return Boolean(value?.link);
  }, 'error:location'),
});

export const AccountFormSchema = FormValidation.schema<Partial<AccountFormValues>>({
  email: FormValidation.string().email('error:email'),
  phone: FormValidation.handler((value) => {
    if (value) return value.length >= 12 && value !== '+';
    else return false;
  }, 'error:phone'),
  username: FormValidation.string('error:username'),
});

export const AddressFormSchema = FormValidation.schema<Partial<AddressFormData>>({
  address: FormValidation.string('error:address'),
  zip: FormValidation.string('error:zip'),
  // aptSuite: FormValidation.string('error:aptSuite'),
  Location: FormValidation.handler((value) => {
    return Boolean(value?.link);
  }, 'error:location'),
});

export const CommunityProfileSchema = FormValidation.schema<Partial<CommunityProfileValues>>({
  name: FormValidation.string('error:name'),
  units: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:units'),
  buildings: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:buildings'),
  zip: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:zip'),
  address: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:address'),
  descr: FormValidation.string().length({lte: TEXTAREA_MAX_LENGTH}, 'error:descr'),
  Location: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return Boolean(value?.link);
  }, 'error:location'),
});

export const AddAdressProfileSchema = FormValidation.schema<Partial<AdditionalAddressFields>>({
  unit: FormValidation.string('error:unit'),
  zip: FormValidation.string('error:zip'),
  address: FormValidation.string('error:address'),
});

export const CommunityAdminSchema = FormValidation.schema<Partial<CommunityAdminValues>>({
  accessType: FormValidation.string('error:accessType'),
  listingApproval: FormValidation.string('error:listingApproval'),
});
