import React, {useState} from 'react';
import {InputDropdown} from '../../ui-kit/Form/Input/InputDropdown';
import {DropdownStyledOption} from '../../ui-kit/Form/Input/styles';
import {optionDropdown} from '../../ui-kit/Form/Input/types';
import {useTranslation} from 'react-i18next';

const options: optionDropdown[] = [
  {
    value: 'All Time',
    label: 'allTime',
  },
  {
    value: 'Today',
    label: 'today',
  },
  {
    value: 'Last 7 days',
    label: 'last7Day',
  },
  {
    value: 'Last 4 weeks',
    label: 'last4Weeks',
  },
  {
    value: 'Last 3 months',
    label: 'last3Month',
  },
  {
    value: 'Last 12 months',
    label: 'last12Month',
  },
];

export const SelectTime: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState(options[0]?.value);
  const {t} = useTranslation();

  const handleChose = (value: string) => {
    return () => {
      setSelectedValue(value);
    };
  };
  return (
    <InputDropdown
      dropdownPosition={'center'}
      variant={'primary'}
      options={options}
      icon="calendar"
      label={t(`dashboard:selectTimeOptions.${options.find((el) => el.value === selectedValue)?.label || 'choseOne'}`)}>
      {options.map((option, ind) => (
        <DropdownStyledOption key={ind} onClick={handleChose(option.value)}>
          {t(`dashboard:selectTimeOptions.${option.label}`)}
        </DropdownStyledOption>
      ))}
    </InputDropdown>
  );
};
