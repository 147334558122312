import React from 'react';
import {useTranslation} from 'react-i18next';
import {MobileSearchBlock} from '../common/SearchBlock/MobileSearchBlock';
import {SetCompanySearchParams} from './types';

interface TProps {
  setSearchParams?: SetCompanySearchParams;
}

export const MobileSearch: React.FC<TProps> = ({setSearchParams}) => {
  const {t} = useTranslation();

  const onSearch = (searchText?: string) => {
    setSearchParams?.({
      name: {
        matchesRegex: searchText?.toLocaleLowerCase() ?? '',
        options: 'i',
      },
    });
  };

  return <MobileSearchBlock placeholder={t('search:labels.whatLooking')} onSearch={onSearch} />;
};
