import {gql} from '@apollo/client';
import {AppFileFragment} from './file';
import {OrderFragment} from './order';

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    objectId
    createdAt
    updatedAt
    Order {
      ...OrderFragment
    }
    Author {
      objectId
      firstName
      lastName
      onlineDate
      Avatar {
        ...AppFileFragment
      }
    }
    ShowTo {
      ... on Element {
        value
      }
    }
    text
    type
    Attachments {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    Seen {
      ... on Element {
        value
      }
    }
  }
  ${AppFileFragment}
  ${OrderFragment}
`;
