import {CommunityPostsResponse} from '../queries/feed';
import {NewPostOptions, PostFormValues, PostTypeT, TPollOption, TPost} from '../types/post';
import {PollOption} from '../queries/types/post';
import {toStatePostItem} from './item';
import {MemberT} from '../types/people';
import {CommunityStateType} from '../states/community';
import {localeType} from '../libs/i18nextUtils';
import {SearchKey} from '../constants/routes';
import {LabelValuePair} from '../types/settings';
import {GQLPostWhereInput} from '../graphql.schema';
import {PointerFile} from '../types/common';

export const toStatePosts = (posts?: CommunityPostsResponse['posts'], onlineIds?: string[]): TPost[] | undefined => {
  return posts?.edges?.map((edge) => ({
    ...edge.node,
    Items: edge?.node?.Items?.map((edge) => toStatePostItem(edge)),
    likes: edge?.node?.countLikes,
    isLiked: !!edge?.node?.isLike,
    smiles: edge?.node?.countSmiles,
    isSmiled: !!edge?.node?.isSmile,
    waves: edge?.node?.countWaves,
    isWaved: !!edge?.node?.isWave,
    isHidden: !!edge?.node?.isHidden,
    comments: edge?.node?.countComments,
    Options: edge?.node?.Options?.edges.map((edge) => toStatePollOption(edge.node)),
    translates: {
      [localeType.en]: edge?.node?.Translate?.en,
      [localeType.ru]: edge?.node?.Translate?.ru,
    } as Record<localeType, string>,
    originalLang: (edge?.node?.Translate?.originalLang as localeType) || localeType.en,
    ...(edge?.node?.Author
      ? {Author: {...(edge?.node?.Author || {}), isOnline: onlineIds?.includes(edge?.node?.Author?.objectId)}}
      : {}),
  }));
};
export const toStatePostComments = (posts?: CommunityPostsResponse['posts']): TPost[] | undefined => {
  const comments = posts?.edges[0]?.node?.comments?.edges?.map((edge) => {
    return {
      ...edge.node,
      Items: edge?.node?.Items?.map((edge) => toStatePostItem(edge)),
      likes: edge?.node?.countLikes,
      isSmiled: edge?.node?.isSmile,
      isLiked: edge?.node?.isLike,
      isWaved: edge?.node?.isWave,
      // isLiked: !!edge?.node?.countLikes,
      smiles: edge?.node?.countSmiles,
      // isSmiled: !!edge?.node?.isSmile,
      waves: edge?.node?.countWaves,
      // isWaved: !!edge?.node?.isWave,
      isHidden: !!edge?.node?.isHidden,
      comments: 0,
      Options: edge?.node?.Options?.edges.map((edge) => toStatePollOption(edge.node)),
      translates: {
        [localeType.en]: edge?.node?.Translate?.en,
        [localeType.ru]: edge?.node?.Translate?.ru,
      } as Record<localeType, string>,
      originalLang: (edge?.node?.Translate?.originalLang as localeType) || localeType.en,
    };
  });

  return comments;
};

export const toStatePollOption = (option: PollOption): TPollOption => {
  return {
    id: option.id,
    objectId: option.objectId,
    Voted: option.Voted?.edges?.map((item) => item?.node).flat(),
    Post: option.Post?.objectId,
    countVotes: option.countVotes,
    text: option.text,
    position: option.position,
    isVoted: Boolean(option.isVoted),
    translates: {
      [localeType.en]: option.Translate?.en,
      [localeType.ru]: option.Translate?.ru,
    } as Record<localeType, string>,
    originalLang: (option.Translate?.originalLang as localeType) || localeType.en,
  };
};

export const toPointerCreatePost = (
  post: Partial<PostFormValues>,
  viewerId: string,
  communityId?: string,
  parseAttachments?: (Partial<PointerFile> | undefined)[],
  parentId?: string,
  groupId?: string,
  pollOptions?: NewPostOptions[],
  localRef?: string,
) => {
  const {options, ...postValues} = post;
  return {
    ...postValues,
    localRef,
    Attachments: parseAttachments,
    Author: {
      link: viewerId || '',
    },
    Community: {
      link: communityId || '',
    },
    ...(parentId
      ? {
          Parent: {
            link: parentId,
          },
        }
      : {}),
    ...(groupId
      ? {
          Group: {
            link: groupId,
          },
        }
      : {}),
    ...(options ? {} : {}),
    ...(pollOptions
      ? {
          pollOptions: pollOptions.map((item) => item?.text),
        }
      : {}),
    ...(post?.medium
      ? {
          medium: Object.keys(post?.medium)?.filter((item) => {
            if (post?.medium?.[item as keyof typeof post.medium]) {
              return item;
            }
          }, []),
        }
      : {}),
  };
};

export const checkIsImage = (name: string) => {
  const postImagesExt = ['png', 'jpg', 'webp', 'jpeg', 'gif'];
  const ext = name?.split('.').pop();
  if (ext) return postImagesExt.includes(ext);
  return false;
};

export const getClearName = (name: string) => {
  return name.substring(name.indexOf('_') + 1, name.length);
};

export const declOfNum = (count?: number, titles?: string[]) => {
  if ((!count && count !== 0) || !titles?.length) return null;
  const cases = [2, 0, 1, 1, 1, 2];
  const index = count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5];
  if (index || index === 0) return titles?.[index];
  return null;
};

export const calcVotesPercent = (total: number, count = 0) => {
  const res = Number(((count * 100) / total).toFixed(1)) || 0;
  return res > 0 ? res : 0;
};

export const getIdForEmbedYoutube = (url?: string) => {
  if (!url) return null;
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2]?.length === 11) {
    return match[2];
  } else {
    return null;
  }
};

export const getPostOptions = (t: (key: string) => string) => {
  return [
    {
      label: t('search:posts.showAll'),
      value: 'all',
    },
    {
      label: t('search:posts.recommendations'),
      value: PostTypeT.recommendation,
    },
    {
      label: t('search:posts.requests'),
      value: PostTypeT.request,
    },
    {
      label: t('search:posts.polls'),
      value: PostTypeT.poll,
    },
    {
      label: t('search:posts.notices'),
      value: PostTypeT.announcement,
    },
    {
      label: t('search:posts.posts'),
      value: 'post',
    },
    {
      label: t('search:posts.myPosts'),
      value: PostTypeT.myPosts,
    },
  ];
};

type formatPostAuthorProps = {
  postData?: TPost[] | [];
  community?: CommunityStateType;
  membersListingsCount: [string, number][];
};
export const formatPostAuthor = (options: formatPostAuthorProps) => {
  const postsWithListings = options?.postData?.map((p) => {
    const listingsCount = options.membersListingsCount?.find(([id]) => id === p?.Author?.id)?.[1];
    return {
      ...p,
      Author: {
        ...p?.Author,
        listingsCount: listingsCount ?? 0,
      },
    };
  });
  const postsWithListing = postsWithListings?.[0];

  if (postsWithListing?.sentAs === 'community') {
    return {
      ...postsWithListing?.Author,
      Avatar: {
        file: {
          url: options?.community?.avatar,
        },
      },
      firstName: options?.community?.name,
      lastName: '',
      isVerified: options?.community?.isVerified,
      listingsCount: 0,
      Reviews: {count: 0},
    } as unknown as MemberT;
  } else {
    return postsWithListing?.Author as MemberT;
  }
};

export const createLoopLink = (path?: string, option?: PostTypeT) => {
  if (option) return path + `?${SearchKey.view}=${option}`;
  return path;
};

export const removeLocal = (
  remove: (id: string) => void,
  local?: (TPost | undefined)[],
  data?: TPost[],
  isComments?: boolean,
) => {
  const localRefs = local?.map((el) => el?.localRef);
  if (data?.length && data?.length > 10) {
    if (isComments) {
      for (let i = data.length - 11; i < data?.length; i++) {
        const dataRef = data?.[i]?.localRef;
        if (dataRef && localRefs?.includes(dataRef)) {
          remove(dataRef);
        }
      }
    } else {
      for (let i = 0; i < 10; i++) {
        const dataRef = data?.[i]?.localRef;
        if (dataRef && localRefs?.includes(dataRef)) {
          remove(dataRef);
        }
      }
    }
  } else {
    data?.forEach((el) => {
      if (el?.localRef && localRefs?.includes(el?.localRef)) {
        remove(el?.localRef);
      }
    });
  }
};

export const formatLocalFeedArray = (localFeedArray: (TPost | undefined)[], postType?: string) => {
  if (postType === 'all' || postType === PostTypeT.myPosts)
    return (localFeedArray.filter((el) => !!el) || []) as TPost[];
  return (localFeedArray.filter((el) => !!el && el?.type === postType) || []) as TPost[];
};

export const createSearchParams = (
  next: Partial<LabelValuePair>,
  setSearchParams: (value: React.SetStateAction<GQLPostWhereInput>) => void,
  viewerId?: string,
) => {
  const value = next?.value === 'all' ? '' : next?.value;
  if (next.value !== PostTypeT.recommendation) {
    if (next.value === 'myPosts') {
      setSearchParams({
        AND: [
          {
            Author: {
              have: {
                id: {
                  equalTo: viewerId,
                },
              },
            },
          },
          {
            type: {notEqualTo: PostTypeT.recommendation},
          },
        ],
      });
    } else {
      setSearchParams(
        value
          ? {
              AND: [
                {
                  type: {equalTo: next?.value?.toLowerCase()},
                },
                {
                  type: {notEqualTo: PostTypeT.recommendation},
                },
              ],
            }
          : {
              type: {notEqualTo: PostTypeT.recommendation},
            },
      );
    }
  } else {
    setSearchParams(
      value
        ? {
            type: {equalTo: next?.value?.toLowerCase()},
          }
        : {},
    );
  }
};
