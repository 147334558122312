import {Community} from './community';
import {Currency} from './currency';
import {Location} from './locations';
import {Item, ListingType, PricePeriodType, RewardType} from './item';
import {User} from './user';
import {ParseObject} from './parse';
import {Amenity} from './amenity';
import {Event} from './event';

export enum StatusOrderType {
  draft = 'draft',
  request = 'request',
  accepted = 'accepted',
  paid = 'paid',
  performing = 'performing',
  completed = 'completed',
  rejected = 'rejected',
  failed = 'failed',
  paymentPending = 'paymentPending',
  attending = 'attending',
  booked = 'booked',
  canceled = 'canceled',
  pending = 'pending',
}

export enum OrderPayMethod {
  cash = 'cash',
  card = 'card',
}

export enum typeOrder {
  eventOrder = 'eventOrder',
  itemOrder = 'itemOrder',
  amenityOrder = 'amenityOrder',
}

export interface Order extends ParseObject {
  Community: Community;
  Currency: Currency;
  Item: Item;
  Event: Event;
  Amenity: Amenity;
  Lister: User;
  Location: Location;
  Requester: User;
  cfListerAbs: number;
  cfListerAmt: number;
  cfListerPrc: number;
  cfRequesterAbs: number;
  cfRequesterAmt: number;
  cfRequesterPrc: number;
  createdAt: Date;
  discount: number;
  discountAmount: number;
  discountType: string;
  endTime: Date;
  hfListerAbs: number;
  hfListerAmt: number;
  hfListerPrc: number;
  hfRequesterAbs: number;
  hfRequesterAmt: number;
  hfRequesterPrc: number;
  itemAmount: number;
  listerTotalAmt: number;
  listingType: ListingType;
  period: number;
  ppListerAbs: number;
  ppListerAmt: number;
  ppListerPrc: number;
  ppRequesterAbs: number;
  ppRequesterAmt: number;
  ppRequesterPrc: number;
  price: number;
  pricePeriod: PricePeriodType;
  requesterTotalAmt: number;
  rewardType: RewardType;
  startTime: Date;
  status: StatusOrderType;
  updatedAt: Date;
  payMethod: OrderPayMethod;
  orderType: typeOrder;
  attendeeGuests?: number;
  recurringEvent?: boolean;
  hangehDiscount: number;
}

export type TCalcOrderData = {
  listingType: string;
  rewardType: string;
  price: number;
  pricePeriod: string;
  startTime: string;
  endTime: string;
  period: number;
  discount: number;
  discountType: string;
  hfListerPrc: number;
  hfListerAbs: number;
  hfListerAmt: number;
  sfListerAmt: number;
  hfRequesterPrc: number;
  hfRequesterAbs: number;
  hfRequesterAmt: number;
  sfRequesterAmt: number;
  cfListerPrc: number;
  cfListerAbs: number;
  cfListerAmt: number;
  cfRequesterPrc: number;
  cfRequesterAbs: number;
  cfRequesterAmt: number;
  ppListerPrc: number;
  ppListerAbs: number;
  ppListerAmt: number;
  ppRequesterPrc: number;
  ppRequesterAbs: number;
  ppRequesterAmt: number;
  listerTotalAmt: number;
  requesterTotalAmt: number;
};
