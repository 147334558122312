import styled from 'styled-components';
import {Media} from '../../../../../ui-kit/theme';

export const PostListingSlider = styled.div<{$isListings?: boolean}>`
  height: ${({$isListings}) => ($isListings ? '100px' : '280px')};
  display: flex;
  width: 100%;
  ${Media.down.m} {
    ${({$isListings}) => $isListings && ' display: none;'};
  }
`;

export const PostListingSliderWrapper = styled.div<{$isListings?: boolean}>`
  width: 100%;
  z-index: 0;
  & {
    .slick-list {
      margin: 0 -5px;
      ${Media.down.xs} {
        margin: 0;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      max-width: 310px;
      max-height: 220px;
      min-width: ${({$isListings}) => ($isListings ? '280px' : 'unset')};
      & > div {
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > div > a {
        max-height: 220px;
        max-width: 300px;
      }
    }
    .slider_wrapper {
      max-height: 230px;
      background-color: #fff;
    }

    .slick-slider {
      position: relative;
      &.listing-slider__mobile {
        display: none;
      }
      ${Media.down.m} {
        display: none;
        &.listing-slider__mobile {
          display: block;
        }
      }
    }

    .slick-arrow {
      height: 100%;
      width: 20px;
      background-color: rgba(255, 255, 255, 0.781);
      display: block;
      position: absolute;
      z-index: 1009;
      border: none;
      transition: 0.2s all;
      cursor: pointer;
      top: 0;
      & svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .slick-arrow.slick-disabled {
      visibility: hidden;
      opacity: 0;
    }

    .slick-prev {
      left: 0;
      box-shadow: 6px -1px 20px 16px rgba(255, 255, 255, 0.781);
      & svg {
        left: 0;
      }
    }

    .slick-next {
      right: 0;
      box-shadow: -4px 0px 20px 16px rgba(255, 255, 255, 0.781);
      & svg {
        transform: rotate(180deg);
        right: 5px;
      }
    }

    .slick-prev::after {
      content: '';
      display: block;
    }

    .slick-next::after {
      content: '';
      display: block;
    }
  }
`;
