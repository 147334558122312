import React from 'react';
import {useRecoilState} from 'recoil';
import {currentCommunity, userCommunities} from '../../states/community';
import {userState} from '../../states/session';
import {NavigationToolTips} from '../../components/common/NavigationToolTips';

export const ToolTips = () => {
  const [user] = useRecoilState(userState);
  const [comm] = useRecoilState(currentCommunity);
  const [allComm] = useRecoilState(userCommunities);
  if (!comm?.objectId || !user) return null;
  if (user?.tips?.welcomeTipsViewed && user?.tips?.multiplyCommunityTip) return null;
  return <NavigationToolTips comm={comm} user={user} allComm={allComm} />;
};
