import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getResize} from '../../../helpers/file';
import {PhotoCard} from '../../common/PhotoCard/PhotoCard';
import {
  FooterSlider,
  InnerImagesBtns,
  InnerMobileBtns,
  InnerPhoto,
  MobileBtn,
  SliderButton,
  SliderWrapper,
  ViewBtn,
} from './styles';
import {TImage} from '../../../types/item';
import {Media} from '../../../ui-kit/theme';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Item, useGallery} from 'react-photoswipe-gallery';
import {isMobile} from 'react-device-detect';
import {PhotoSlider} from '../../common/PhotoSlider';

interface IProps {
  images?: TImage[];
  communityAlias?: string;
}

export const Slider: React.FC<IProps> = ({images}) => {
  const {t} = useTranslation();
  //const {location} = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [activeCardId, setActiveCardId] = useState<string | undefined>(images?.[0]?.id);
  const [index, setIndex] = useState<number>(0);
  const titleButton = !isShow ? t('listings:view.buttons.viewAllPhotos') : t(`listings:view.buttons.hidePhotos`);
  const isAllBtn = Number(images?.length) > 3;
  const {open} = useGallery();

  const handleClick = (id?: string) => () => {
    setActiveCardId(id);
  };

  const handleSetActive = (id: string, index: number) => () => {
    setIndex(index);
    setActiveCardId(id);
  };

  const viewAll = () => {
    setIsShow((prev) => !prev);
  };
  const handleOpenPreview = () => open(index);
  const handleOpenPreviewMob = (ind: number) => () => open(ind);

  const handleBackNoReactRouter = () => window?.history.back();

  /*const handleCopyUrl = () => {
    const fullUrl = SITE_URL + location.pathname;
    const viewUrl = fullUrl.includes('-edit') ? fullUrl.split('-edit').join('') : fullUrl;
    navigator?.clipboard?.writeText(viewUrl);
  };*/

  return (
    <>
      <SliderWrapper>
        <InnerPhoto>
          {!isMobile && (
            <PhotoCard
              aspectRatio={'623:445'}
              src={getResize(images?.find((it) => it.id === activeCardId)?.file?.url, 'lg')}
              onClick={handleOpenPreview}
            />
          )}

          {isMobile && (
            <PhotoSlider>
              {images?.map((img, ind) => (
                <PhotoCard
                  aspectRatio={'623:445'}
                  src={getResize(img?.file?.url, 'lg')}
                  onClick={handleOpenPreviewMob(ind)}
                  key={ind}
                />
              ))}
            </PhotoSlider>
          )}
          <HiddenElement mq={Media.up.m}>
            <InnerMobileBtns>
              {images?.map((img) => (
                <SliderButton key={img.id} isActive={img.id === activeCardId} onClick={handleClick(img.id)} />
              ))}
            </InnerMobileBtns>
          </HiddenElement>
        </InnerPhoto>
        <HiddenElement mq={Media.down.m}>
          <FooterSlider>
            <InnerImagesBtns>
              {images?.map((item, index) => (
                <span key={index}>
                  <Item original={getResize(item?.file?.url, 'lg')} width={'1024'} height={'768'}>
                    {({ref}) => (
                      <img
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={handleSetActive(item?.id || '', index)}
                        src={getResize(item?.file?.url, 'sm')}
                        alt={''}
                      />
                    )}
                  </Item>
                </span>
              ))}
            </InnerImagesBtns>
            {isAllBtn && (
              <ViewBtn type={'button'} onClick={viewAll}>
                {titleButton}
              </ViewBtn>
            )}
          </FooterSlider>
        </HiddenElement>
        <HiddenElement mq={Media.up.m}>
          <MobileBtn $align={'left'} onClick={handleBackNoReactRouter}>
            <IconSvg type={'close'} width={'32'} height={'32'} viewBox={'0.5 -2.5 18 26'} />
          </MobileBtn>
          {/*}<MobileBtn $align={'right'} onClick={handleCopyUrl}>
            <IconSvg type={'share'} width={'28'} height={'28'} viewBox={'0 0 22 24'} />
          </MobileBtn>*/}
        </HiddenElement>
        {/*
        <PreviewImage
          previewImg={popupImg}
          images={images?.map((i) => i.file?.url ?? '') ?? []}
          handleClose={handleClosePreview}
          closeOutside={true}
        />*/}
      </SliderWrapper>
    </>
  );
};
