import React from 'react';
import {ListingCard} from '../../Listings/ListingCard';
import {route} from '../../../constants/routes';
import {ListCardsGrid} from '../../Listings/styles';
import {ContentListingsProps} from '../types';
import {ItemsSkeletonSection} from '../../common/Skeletons/ItemsSkeleton';
import {useLinks} from '../../../hooks/common';
import {getResize} from '../../../helpers/file';

export const Listings: React.FC<ContentListingsProps> = ({
  items,
  communityId,
  loading,
  reportActions,
  getSignValue,
  liked,
  onLike,
}) => {
  const {getLink} = useLinks();

  return (
    <ListCardsGrid minWidth={300}>
      {loading && !items?.length ? (
        <ItemsSkeletonSection />
      ) : (
        items?.map((item) => (
          <ListingCard
            src={getResize(item?.images?.[0]?.file?.url, 'md')}
            key={item.id}
            to={getLink(route.listingView.get({id: item?.id || ''}))}
            item={item}
            aspectRatio={'300:200'}
            communityId={communityId}
            reportActions={reportActions}
            getSignValue={getSignValue}
            isHideLabel={true}
            liked={liked}
            onLike={onLike}
          />
        ))
      )}
    </ListCardsGrid>
  );
};
