import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {CheckYourEmail} from './components/Auth/CheckYourEmail';
import {PlanOptionsValues} from './constants/plan';
import {route} from './constants/routes';
import SelectPlan from './containers/Account//Create/Manager/SelectPlan';
import CreateCommunityContainer from './containers/Account/Create/Manager/Community';
import {InviteCommunityContainer} from './containers/Account/Create/Manager/Invite';
import CreatePaymentDetails from './containers/Account/Create/Manager/PaymentDetails';
import {CreateProfileContainer as CreateProfileManager} from './containers/Account/Create/Manager/Profile';
import {JoinToCommunityContainer} from './containers/Account/Create/Resident/JoinToCommunity';
import {CreateProfileResidentContainer as CreateProfileResident} from './containers/Account/Create/Resident/Profile';
import {SearchCommunityContainer} from './containers/Account/Create/Resident/SearchComminity';
import {Dashboard} from './containers/Account/Dashboard';
import {DeleteAccountContainer} from './containers/Account/Delete';
import {StepRequestCommunityContainer} from './containers/Account/RequestCommunity/RequestCommunityContainer';
import {VerifyEmailContainer} from './containers/Account/VerifyEmail/VerifyEmailContainer';
import {AmenityCreatedContainer} from './containers/Amenities/AmenityCreatedContainer';
import {ConfigureListCommunitiesAmenity} from './containers/Amenities/ConfigureListCommunitiesAmenity';
import {CreateAmenityContainer} from './containers/Amenities/CreateAmenityContainer';
import {EditAmenityContainer} from './containers/Amenities/EditAmenityContainer';
import {AmenitiesPage} from './containers/Amenities/Page';
import {ViewAmenityContainer} from './containers/Amenities/View';
import {ViewEditAmenityContainer} from './containers/Amenities/ViewEdit';
import {AddressFormPage} from './containers/Auth/AddressFormPage';
import {CommunityRequestContainer} from './containers/Auth/CommunityRequestContainer';
import {CommunitySuperResident} from './containers/Auth/CommunitySuperResident';
import {FoundCommunity} from './containers/Auth/FoundCommunity';
import {LogInContainer} from './containers/Auth/LogIn';
import {NoCommunityContainer} from './containers/Auth/NoCommunityContainer';
import {SignUpContainer} from './containers/Auth/SignUp';
import {VerifyPhonePage} from './containers/Auth/VerifyPhone';
import {ViewPost} from './containers/CommunityFeed/View';
import {DocumentsContainer} from './containers/Documents/DocumentsContainer';
import {ViewDocumentContainer} from './containers/Documents/ViewDocumentContainer';
import {ConfigureListCommunitiesEvent} from './containers/Events/ConfigureListCommunitiesEvent';
import {CreateEventContainer} from './containers/Events/CreateEventContainer';
import {EditEventContainer} from './containers/Events/EditEventContainer';
import {EventCreatedContainer} from './containers/Events/EventCreatedContainer';
import {EventsPage} from './containers/Events/Page';
import {ViewEventContainer} from './containers/Events/View';
import {ViewEditEventContainer} from './containers/Events/ViewEdit';
import {Groups} from './containers/Groups';
import {GroupView} from './containers/Groups/View';
import {HelpDeskContainer} from './containers/HelpDesk/HelpDeskContainer';
import Home from './containers/Home/PageHome';
import {HomePageUserContainer} from './containers/HomePageUser';
import {ConfigureListCommunities} from './containers/Listings/ConfigureListCommunities';
import {CreateItemContainer} from './containers/Listings/CreateItemContainer';
import {EditItemContainer} from './containers/Listings/EditItemContainer';
import {ListingCreatedContainer} from './containers/Listings/ListingCreatedContainer';
import {ListingsPage} from './containers/Listings/Page';
import {ViewListingContainer} from './containers/Listings/View';
import {ViewEditListingContainer} from './containers/Listings/ViewEdit';
import {
  CreateCompanyContainer,
  EditCompanyContainer,
  LocalBizPageContainer,
  ManageCompanyPublishingContainer,
  ViewCompanyContainer,
  ViewEditCompanyContainer,
} from './containers/LocalBiz';
import {LoopContainer} from './containers/Loop';
import {MessagesContainer} from './containers/Messages';
import {NewPasswordContainer} from './containers/NewPassword/NewPasswordContainer';
import {People} from './containers/People/';
import {PrivateRoute} from './containers/PrivateRoute';
import {Profile} from './containers/Profile';
import {Account} from './containers/Settings/Account';
import {Community} from './containers/Settings/Community';
import {PlansAndPricing} from './containers/Settings/Community/PlansAndPricing';
import {Preferences} from './containers/Settings/Notifications';
import {Payments} from './containers/Settings/Payments';
import {Payouts} from './containers/Settings/Payouts';
import {Personal} from './containers/Settings/Personal';
import {getRouteWithAlias} from './helpers/common';
import {TypeCommunity} from './types/auth';
import {JoinInContainer} from './containers/Auth/JoinInContainer';
import AllowPushContainer from './containers/Auth/AllowPushContainer';
import {getToken} from './libs/auth';
import {ViewPublicEvent} from './containers/Events/ViewPublicEvent';
import GenerateListingContainer from './containers/Listings/GenerateListingContainer';
import {Referrals} from './containers/Referrals';
import {SocialContainer} from './containers/Auth/Social';
import {CommunityCreationConfirm} from './containers/Auth/CommunityCreationConfirm';

const PublicRoutes = [
  <Route key="home" path={route.home.path} exact component={Home} />,
  <Route key="auth" path={route.auth.path} exact component={LogInContainer} />,
  <Route key="signUp" path={route.signUp.path} exact component={SignUpContainer} />,
  <Route key="joinIn" path={route.joinIn.path} exact component={JoinInContainer} />,
  <Route key="social" path={route.social.path} exact component={SocialContainer} />,
  <Route key="forgot-password" path={route.forgotPassword.path} component={CheckYourEmail} />,
  <Route key="change-password" path={route.changePassword.path} component={NewPasswordContainer} />,
  <Route key="confirm-delete" path={route.confirmDeleteUser.path} component={DeleteAccountContainer} />,
];
const PublicForOG = [
  ...PublicRoutes,
  <Route key="public-event" path={'*/event-view/:id'} component={ViewPublicEvent} />,
  <Route key="og-request" path={'*'} component={LogInContainer} />,
];

const PrivateRoutes = [
  //create account
  <PrivateRoute key="profile-phone-number" path={route.verifyPhone.path} exact component={VerifyPhonePage} />,
  <PrivateRoute key="allow-push" path={route.allowPush.path} exact component={AllowPushContainer} />,
  <PrivateRoute key="profile-address" path={route.address.path} exact component={AddressFormPage} />,
  <PrivateRoute key="community-found" path={route.communityFound.path} exact component={FoundCommunity} />,
  <PrivateRoute key="community-not-found" path={route.communityNotFound.path} exact component={NoCommunityContainer} />,
  <PrivateRoute
    key="community-creation-confirm"
    path={route.communityCreationConfirm.path}
    exact
    component={CommunityCreationConfirm}
  />,
  <PrivateRoute
    key="community-super-resident"
    path={route.communitySuperResident.path}
    exact
    component={CommunitySuperResident}
  />,

  <PrivateRoute
    key="community-request"
    path={route.communityRequest.path}
    exact
    component={CommunityRequestContainer}
  />,
  //manager
  <PrivateRoute
    key="create-profile"
    path={getRouteWithAlias(route.profileCreateManager.path)}
    component={CreateProfileManager}
  />,
  <PrivateRoute key="select-plan" path={getRouteWithAlias(route.selectPlan.path)} component={SelectPlan} />,
  <PrivateRoute
    key="create-community"
    path={getRouteWithAlias(route.createCommunity.path)}
    component={CreateCommunityContainer}
  />,
  <PrivateRoute
    key="create-payment"
    path={getRouteWithAlias(route.paymentCommunity.path)}
    component={CreatePaymentDetails}
  />,
  <PrivateRoute
    key="invite-community"
    path={getRouteWithAlias(route.inviteCommunity.path)}
    component={InviteCommunityContainer}
  />,
  //resident
  <PrivateRoute
    key="create-resident"
    path={getRouteWithAlias(route.profileCreateResident.path)}
    component={CreateProfileResident}
  />,
  <PrivateRoute
    key="search-community-resident"
    path={getRouteWithAlias(route.searchCommunityResident.path)}
    component={SearchCommunityContainer}
  />,
  <PrivateRoute
    key="join-community"
    path={getRouteWithAlias(route.joinCommunity.path)}
    component={JoinToCommunityContainer}
  />,
  <PrivateRoute
    key="request-community"
    path={getRouteWithAlias(route.requestCommunity.path)}
    component={StepRequestCommunityContainer}
  />,
  //pages
  <PrivateRoute key="page-user" path={getRouteWithAlias(route.pageUser.path)} component={HomePageUserContainer} />,
  <PrivateRoute
    key="loop-manager"
    path={getRouteWithAlias(route.loop.path)}
    planOption={PlanOptionsValues.communityFeed}
    component={LoopContainer}
  />,
  <PrivateRoute key="post" path={getRouteWithAlias(route.post.path)} component={ViewPost} />,
  <PrivateRoute
    key="dashboard"
    path={getRouteWithAlias(route.dashboard.path)}
    planOption={PlanOptionsValues.reportingDashboard}
    roles={[TypeCommunity.manager, TypeCommunity.admin]}
    component={Dashboard}
  />,
  <PrivateRoute
    key="create-item"
    path={getRouteWithAlias(route.createItem.path)}
    planOption={PlanOptionsValues.featureListings}
    component={CreateItemContainer}
  />,
  <PrivateRoute
    key="generate-item"
    path={getRouteWithAlias(route.generateItem.path)}
    planOption={PlanOptionsValues.featureListings}
    component={GenerateListingContainer}
  />,
  <PrivateRoute
    key="edit-item"
    path={getRouteWithAlias(route.editItem.path)}
    planOption={PlanOptionsValues.featureListings}
    component={EditItemContainer}
  />,
  <PrivateRoute
    key="listings"
    path={getRouteWithAlias(route.listings.path)}
    planOption={PlanOptionsValues.listingsFeed}
    component={ListingsPage}
  />,
  <PrivateRoute
    key="listings-view"
    path={getRouteWithAlias(route.listingView.path)}
    planOption={PlanOptionsValues.featureListings}
    component={ViewListingContainer}
  />,
  <PrivateRoute
    key="listings-view-edit"
    path={getRouteWithAlias(route.listingViewEdit.path)}
    planOption={PlanOptionsValues.featureListings}
    component={ViewEditListingContainer}
  />,
  <PrivateRoute
    key="listing-created"
    path={getRouteWithAlias(route.listingCreated.path)}
    planOption={PlanOptionsValues.featureListings}
    component={ListingCreatedContainer}
  />,
  <PrivateRoute
    key="configure-list-communities"
    path={getRouteWithAlias(route.configureListCommunities.path)}
    planOption={PlanOptionsValues.featureListings}
    component={ConfigureListCommunities}
  />,
  <PrivateRoute
    key="people"
    path={getRouteWithAlias(route.people.path)}
    planOption={PlanOptionsValues.peoplesFeed}
    component={People}
  />,
  <PrivateRoute
    key="/user/:id"
    path={getRouteWithAlias(route.profile.path)}
    planOption={PlanOptionsValues.peoplesFeed}
    component={Profile}
  />,
  <PrivateRoute key="settings-personal" path={getRouteWithAlias(route.personalSettings.path)} component={Personal} />,
  <PrivateRoute key="settings-account" path={getRouteWithAlias(route.accountSettings.path)} component={Account} />,
  <PrivateRoute
    key="settings-community"
    path={getRouteWithAlias(route.communitySettings.path)}
    component={Community}
  />,
  <PrivateRoute
    key="settings-preferences"
    path={getRouteWithAlias(route.preferencesSettings.path)}
    component={Preferences}
  />,
  <PrivateRoute
    key="settings-payments"
    path={getRouteWithAlias(route.paymentsSettings.path)}
    exact
    component={Payments}
  />,
  <PrivateRoute
    key="settings-plans-and-pricing"
    path={getRouteWithAlias(route.plansAndPricing.path)}
    component={PlansAndPricing}
  />,
  <PrivateRoute key="settings-payouts" path={getRouteWithAlias(route.payoutsSettings.path)} component={Payouts} />,
  <PrivateRoute key="messages" path={getRouteWithAlias(route.messages.path)} component={MessagesContainer} />,
  <PrivateRoute key="verify-email" path={getRouteWithAlias(route.verifyEmail.path)} component={VerifyEmailContainer} />,
  <PrivateRoute
    key="documents"
    path={getRouteWithAlias(route.documents.path)}
    planOption={PlanOptionsValues.communityDocuments}
    component={DocumentsContainer}
  />,
  <PrivateRoute
    key="view-document"
    path={getRouteWithAlias(route.documentView.path)}
    planOption={PlanOptionsValues.communityDocuments}
    component={ViewDocumentContainer}
  />,
  <PrivateRoute
    key="events"
    path={getRouteWithAlias(route.events.path)}
    planOption={PlanOptionsValues.events}
    component={EventsPage}
  />,
  <PrivateRoute
    key="create-event"
    path={getRouteWithAlias(route.createEvent.path)}
    planOption={PlanOptionsValues.events}
    component={CreateEventContainer}
  />,
  <PrivateRoute
    key="edit-event"
    path={getRouteWithAlias(route.editEvent.path)}
    planOption={PlanOptionsValues.events}
    component={EditEventContainer}
  />,
  <PrivateRoute
    key="events-view"
    path={getRouteWithAlias(route.eventView.path)}
    planOption={PlanOptionsValues.events}
    component={ViewEventContainer}
  />,
  <PrivateRoute
    key="events-view-edit"
    path={getRouteWithAlias(route.eventViewEdit.path)}
    planOption={PlanOptionsValues.events}
    component={ViewEditEventContainer}
  />,
  <PrivateRoute
    key="events-created"
    path={getRouteWithAlias(route.eventCreated.path)}
    planOption={PlanOptionsValues.events}
    component={EventCreatedContainer}
  />,
  <PrivateRoute
    key="configure-list-communities-event"
    path={getRouteWithAlias(route.configureListCommunitiesEvent.path)}
    planOption={PlanOptionsValues.events}
    component={ConfigureListCommunitiesEvent}
  />,
  <PrivateRoute
    key="help-desk"
    path={getRouteWithAlias(route.helpDesk.path)}
    component={HelpDeskContainer}
    planOption={PlanOptionsValues.helpDesk}
  />,
  <PrivateRoute
    key="amenities"
    path={getRouteWithAlias(route.amenities.path)}
    planOption={PlanOptionsValues.amenities}
    component={AmenitiesPage}
  />,
  <PrivateRoute
    key="create-amenity"
    path={getRouteWithAlias(route.createAmenity.path)}
    planOption={PlanOptionsValues.amenities}
    component={CreateAmenityContainer}
  />,
  <PrivateRoute
    key="edit-amenity"
    path={getRouteWithAlias(route.editAmenity.path)}
    planOption={PlanOptionsValues.amenities}
    component={EditAmenityContainer}
  />,
  <PrivateRoute
    key="amenities-view"
    path={getRouteWithAlias(route.amenityView.path)}
    planOption={PlanOptionsValues.amenities}
    component={ViewAmenityContainer}
  />,
  <PrivateRoute
    key="amenities-view-edit"
    path={getRouteWithAlias(route.amenityViewEdit.path)}
    planOption={PlanOptionsValues.amenities}
    component={ViewEditAmenityContainer}
  />,
  <PrivateRoute
    key="amenities-created"
    path={getRouteWithAlias(route.amenityCreated.path)}
    planOption={PlanOptionsValues.amenities}
    component={AmenityCreatedContainer}
  />,
  <PrivateRoute
    key="configure-list-communities-amenity"
    path={getRouteWithAlias(route.configureListCommunitiesAmenity.path)}
    planOption={PlanOptionsValues.amenities}
    component={ConfigureListCommunitiesAmenity}
  />,
  <PrivateRoute
    key="groups"
    path={getRouteWithAlias(route.groups.path)}
    planOption={PlanOptionsValues.groups}
    component={Groups}
  />,
  <PrivateRoute
    key="group-view"
    path={getRouteWithAlias(route.groupView.path)}
    planOption={PlanOptionsValues.groups}
    component={GroupView}
  />,
  <PrivateRoute
    key="amenities"
    path={getRouteWithAlias(route.amenities.path)}
    planOption={PlanOptionsValues.amenities}
    component={AmenitiesPage}
  />,
  <PrivateRoute
    key="create-amenity"
    path={getRouteWithAlias(route.createAmenity.path)}
    planOption={PlanOptionsValues.amenities}
    component={CreateAmenityContainer}
  />,
  <PrivateRoute
    key="edit-amenity"
    path={getRouteWithAlias(route.editAmenity.path)}
    planOption={PlanOptionsValues.amenities}
    component={EditAmenityContainer}
  />,
  <PrivateRoute
    key="amenities-view"
    path={getRouteWithAlias(route.amenityView.path)}
    planOption={PlanOptionsValues.amenities}
    component={ViewAmenityContainer}
  />,

  <PrivateRoute
    key="local-biz"
    path={getRouteWithAlias(route.localBiz.path)}
    planOption={PlanOptionsValues.company}
    component={LocalBizPageContainer}
  />,
  <PrivateRoute
    key="create-biz"
    path={getRouteWithAlias(route.createBiz.path)}
    planOption={PlanOptionsValues.company}
    component={CreateCompanyContainer}
  />,
  <PrivateRoute
    key="edit-biz"
    path={getRouteWithAlias(route.editBiz.path)}
    planOption={PlanOptionsValues.company}
    component={EditCompanyContainer}
  />,
  <PrivateRoute
    key="biz-view"
    path={getRouteWithAlias(route.bizView.path)}
    planOption={PlanOptionsValues.company}
    component={ViewCompanyContainer}
  />,
  <PrivateRoute
    key="biz-view-edit"
    path={getRouteWithAlias(route.bizViewEdit.path)}
    planOption={PlanOptionsValues.company}
    component={ViewEditCompanyContainer}
  />,
  <PrivateRoute
    key="biz-created"
    path={getRouteWithAlias(route.bizCreated.path)}
    planOption={PlanOptionsValues.company}
    component={ManageCompanyPublishingContainer}
  />,
  <PrivateRoute
    key="manage-biz-publishing"
    path={getRouteWithAlias(route.configureBizPublishing.path)}
    planOption={PlanOptionsValues.company}
    component={ManageCompanyPublishingContainer}
  />,
  <PrivateRoute key="referrals" path={getRouteWithAlias(route.referrals.path)} exact component={Referrals} />,
];

const OtherRoutes = [<Route key="alias" path={[route.joinByInvite.path, '/']} component={Home} />];
export const ogURLS = ['/messages/', '/people', '/loop', '/event-view'];
const Routes: React.FC = () => {
  const token = getToken();
  const {pathname} = useLocation();
  // const community = useRecoilValue(currentCommunityState);
  // const viewer = useViewer();
  // const [aliasedComm] = useRecoilState(aliasCommunity);
  // const [lottie, setLottieLoaded] = useState(false);
  // const loading = !!(community === undefined && viewer?.Communities?.count && !aliasedComm);
  // const lottieLoaded = viewer?.objectId ? lottie : true;
  // // if ((loading || !lottieLoaded) && !aliasedComm?.objectId)
  // //   return (
  // //     <LoaderItemWrapper>
  // //       <LottieLoader $isVisible={true} hideFromApp={isFromApp()} loading={loading} setLoaded={setLottieLoaded} />
  // //     </LoaderItemWrapper>
  // //   );
  if (ogURLS.some((e) => pathname.includes(e)) && !token) {
    return <Switch>{PublicForOG}</Switch>;
  }

  return (
    <Switch>
      {PublicRoutes}
      {PrivateRoutes}
      {OtherRoutes}
    </Switch>
  );
};

export default Routes;
