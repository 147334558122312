import styled, {css} from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Button} from '../../ui-kit/Button/Button';

const stylesText = css`
  font-size: 16px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  color: ${({theme}) => theme.palette.text.septenary};
`;

export const Wrapper = styled.div<{maxWidth?: string}>`
  box-sizing: border-box;
  padding: 100px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '500px')};
  text-align: center;
  margin: 0 auto;
  ${Media.down.m} {
    padding: 30px 40px;
  }
`;

export const MainText = styled.div`
  font-size: 20px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  color: ${({theme}) => theme.palette.text.quaternary};
  margin-top: 16px;
  margin-bottom: 20px;
  ${Media.down.s} {
    margin-bottom: 14px;
    margin-top: 10px;
  }
`;

export const SubText = styled.div`
  ${stylesText};
`;

export const Icon = styled(IconSvg).attrs({
  type: 'empty-address',
  viewBox: '0 0 200 200',
})`
  width: 200px;
  height: 200px;
  ${Media.down.s} {
    width: 137px;
    height: 137px;
  }
`;

export const ButtonContinueToPersonal = styled(Button).attrs({
  ghost: false,
  variant: 'primary',
  rounded: true,
  width: '154px',
})`
  margin-top: 24px;

  & .button-value {
    margin: 0;
    font-size: 14px;
    font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
    color: ${({theme}) => theme.palette.grayscale.white};
  }
`;
