import styled, {css} from 'styled-components';
import {Media, theme} from '../../ui-kit/theme';
import {Link} from '../../ui-kit/Typography/Link';
import {InfiniteScroll} from '../common/InfiniteScroll';

export const WrapperPeoplePage = styled.div`
  max-width: min(960px, 100%);
  width: 960px;
  margin: 0 auto;
  padding: 30px 15px 30px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;

  ${Media.down.l} {
    padding-top: 28px;
    min-height: 700px;
  }

  ${Media.down.xs} {
    padding-top: 0px;
  }
`;

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PeopleListWrapper = styled.div`
  max-width: 1040px;
  //margin: 0 auto 0px;
`;

export const PeopleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Media.down.xl} {
    justify-content: center;
  }
`;

const cardStyles = css`
  width: 100%;
  max-width: 300px;
  min-width: 250px;

  ${Media.down.xl} {
    margin: 0 24px 21px 24px;
  }

  ${Media.down.s} {
    max-width: unset;
    margin: 0 0 10px;
  }
`;

export const cardBodyStyles = css`
  ${cardStyles};
  padding: 12px 11px;
  margin-bottom: 15px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  cursor: pointer;
  transition: 0.15s all;
  width: 100%;

  &:hover {
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.08);
  }
`;

export const PeopleCard = styled(Link)`
  ${cardBodyStyles}
`;

export const EmptyBlock = styled.div`
  ${cardStyles};
  height: 0;
  display: flex;
  opacity: 0;
  margin-bottom: 0;
`;

export const InfoWrapper = styled.div`
  margin-left: 12px;
  width: 100%;

  & > span:first-of-type {
    ${Media.down.xl} {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
      display: block;
    }
  }
`;

export const InfoWrapperSmall = styled.div`
  margin-left: 12px;
  width: 100%;

  & > span:first-of-type {
    max-width: 200px;

    ${Media.down.xl} {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
      display: flex;
      align-items: flex-start;
    }
  }
`;

export const PersonName = styled.span<{$isCommunity?: boolean}>`
  ${theme.font.primary.medium};
  margin-bottom: 4px;
  display: ${({$isCommunity}) => ($isCommunity ? 'block' : 'flex')};
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  color: ${({theme}) => theme.palette.grayscale._6};
  padding-left: 2px;
  max-width: ${({$isCommunity}) => ($isCommunity ? '80% !important' : '250px')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 20px;
  ${Media.down.xxl} {
    max-width: ${({$isCommunity}) => ($isCommunity ? '85% !important' : '250px')};
  }

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
  }
`;

export const PersonLabels = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;

  & > div {
    width: auto;
  }

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  & span {
    margin-right: 10px;
    font-size: 12px;
    color: ${({theme}) => theme.palette.grayscale._5};
    ${theme.font.primary.regular};
  }

  & > :first-child {
    top: 0;
    right: 10px;
  }
`;

export const PersonTypeWrapper = styled.div`
  width: auto;
  display: flex;

  & span {
    text-transform: capitalize;
    ${theme.font.primary.regular};
    color: ${({theme}) => theme.palette.grayscale._6};
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 90px;
    padding-bottom: 20px;
    white-space: nowrap;
  }
`;

export const LastColumn = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  position: relative;

  &.menu-btn__wrapper > div {
    right: 2px;
    top: 10px;
  }

  &.menu-btn__wrapper > button {
    padding-right: 5px;
    max-width: 12px;
  }

  &.menu-btn__wrapper > div > button {
    padding: 0 4px;
  }
`;

export const MenuBtn = styled.button`
  background-color: transparent;
  width: 6px;
  outline: none;
  cursor: pointer;
  border: none;
`;

export const ListTitle = styled.span`
  display: block;
  color: ${({theme}) => theme.palette.grayscale._10};
  ${theme.font.primary.medium};
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 36px;
`;

export const CardWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  padding-left: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  outline: none;

  & button {
    padding: 6px 16px;
    box-sizing: border-box;
    height: 25px;
    width: auto;
    margin-right: 10px;
    border-width: 1px;
  }

  & .button-value {
    font-size: 10px;
  }
`;

export const EmptyLabel = styled.span`
  color: ${({theme}) => theme.palette.grayscale._7};
  ${theme.font.primary.medium};
  font-size: 16px;
  display: block;
  margin-top: -10px;
  text-align: center;
  width: 100%;
`;

export const SearchLineWrapper = styled.div`
  max-width: 65rem;
  width: 100%;
  margin: 0 auto 10px;
  box-sizing: border-box;
  z-index: 100;

  & > div {
    width: 100%;
  }

  & input {
    ${theme.font.primary.medium};
  }

  ${Media.down.s} {
    display: none;
  }
`;

export const MobileSearchWrapper = styled.div`
  width: 100%;
  margin: 15px 0 0px 0;
  padding: 0;
  box-sizing: border-box;

  ${Media.up.m} {
    display: none;
  }
  ${Media.down.m} {
    margin: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: calc(50% - 11px);
`;

export const AddPeoplBtn = styled(Link)`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.palette.common.aqua};
  display: none;
  position: fixed;
  right: 12px;
  bottom: 100px;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 1px 0 9px rgba(0, 0, 0, 0.5);

  ${Media.down.s} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Scroller = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
  ${Media.down.xl} {
    gap: 0px;
    justify-content: center;
  }
`;
