import React from 'react';
import {DashboardSkeletonAreaWrapper, DashboardSkeletonWrapper} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const DashboardSkeleton: React.FC = () => {
  return (
    <DashboardSkeletonWrapper>
      <MemoLoadingSkeleton width={96} height={10} />
      <MemoLoadingSkeleton width={170} height={23} />
    </DashboardSkeletonWrapper>
  );
};

export const DashboardSkeletonSection: React.FC = () => {
  return (
    <DashboardSkeletonAreaWrapper>
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
      <DashboardSkeleton />
    </DashboardSkeletonAreaWrapper>
  );
};
