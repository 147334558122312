import {gql} from '@apollo/client';
import ReviewFragment from './fragments/review';
import {Review, ReviewSubject} from './types/review';
import {Connection} from './types/parse';

export interface CreateOrderReviewResType {
  objectId: string;
}

export interface CreateOrderReviewReqType {
  fields: {
    Author: {
      link: string;
    };
    Community: {
      link: string;
    };
    Order: {
      link: string;
    };
    Item?: {
      link?: string;
    };
    User?: {
      link?: string;
    };
    text?: string;
    stars?: number;
    subject?: ReviewSubject;
  };
}

export const CreateOrderReview = gql`
  mutation createReview($fields: CreateReviewFieldsInput) {
    createReview(input: {fields: $fields}) {
      review {
        objectId
      }
    }
  }
`;

export interface UpdateOrderReviewResType {
  objectId: string;
}

export interface UpdateOrderReviewReqType {
  id: string;
  fields: {
    Author: {
      link: string;
    };
    Community: {
      link: string;
    };
    Order: {
      link: string;
    };
    Item?: {
      link?: string;
    };
    User?: {
      link?: string;
    };
    text?: string;
    stars?: number;
    subject?: ReviewSubject;
  };
}

export const UpdateOrderReview = gql`
  mutation updateReview($id: ID!, $fields: UpdateReviewFieldsInput) {
    updateReview(input: {id: $id, fields: $fields}) {
      review {
        objectId
      }
    }
  }
`;

export interface GetOrderReviewResType {
  reviews: Connection<Review>;
}

export const getOrderReviews = gql`
  query reviews($where: ReviewWhereInput) {
    reviews(where: $where) {
      edges {
        node {
          ...ReviewFragment
        }
      }
    }
  }
  ${ReviewFragment}
`;
