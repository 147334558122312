import React from 'react';
import {ButtonProps} from './types';
import {ButtonBase} from './ButtonBase';
import {IconSvg} from '../Icon/Svg';
import {LeftAdornment, PrimaryButton, RoundedButton, SpinnerWrapper, Value, IconWrapper, LabelWrapper} from './styles';

export const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  label,
  leftAdornment,
  rounded,
  type,
  loading,
  ...props
}) => {
  const Component = rounded ? RoundedButton : PrimaryButton;

  return (
    <ButtonBase
      element={Component}
      loading={loading}
      leftAdornment={leftAdornment}
      label={label}
      type={type || 'button'}
      {...props}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <SpinnerWrapper variant={props.variant} ghost={props.ghost} $active={loading}>
        <IconSvg type={'loading'} width={'1.5em'} height={'1.5em'} viewBox={'-3 -3 19 19'} />
      </SpinnerWrapper>
      <Value leftAdornment={leftAdornment} {...props}>
        {children}
        {leftAdornment && <LeftAdornment>{leftAdornment}</LeftAdornment>}
      </Value>
      {label && <LabelWrapper>{label}</LabelWrapper>}
    </ButtonBase>
  );
};
