import React from 'react';
import styled from 'styled-components';
import {Checkbox} from '../Checkbox/Checkbox';
import {Text} from '../Typography/Text';
import {icon, iconOptions} from '../Icon/Svg/types';
import {IconSvg} from '../Icon/Svg';

const SelectWordWrapper = styled.div<{$isActive?: boolean; $disabled: boolean}>`
  position: relative;
  display: inline-flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid
    ${({theme, $isActive, $disabled}) =>
      $disabled ? theme.palette.grayscale._11 : $isActive ? theme.palette.grayscale._14 : theme.palette.grayscale._17};
  background-color: ${({theme, $isActive, $disabled}) =>
    $disabled ? theme.palette.background.septenary : $isActive ? theme.palette.common.blueLight : 'transparent'};
  & > div:first-of-type {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    & > input {
      width: 100%;
      height: 100%;
      position: absolute !important;
      left: 0 !important;
    }
  }
  & > svg {
    margin-right: 6px;
  }
`;

interface IProps {
  isChecked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  icon?: icon;
}

export const PressButton: React.FC<IProps> = ({label, isChecked = false, onChange, disabled = false, icon}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    onChange?.(e);
  };

  return (
    <SelectWordWrapper $isActive={isChecked} $disabled={disabled}>
      {icon && iconOptions.includes(icon) && <IconSvg type={icon} />}
      <Checkbox $isChecked={isChecked} onChange={handleChange} />
      <Text variant={'primary'} color={'octonary'} size={14}>
        {label}
      </Text>
    </SelectWordWrapper>
  );
};
