import React, {useEffect, useState} from 'react';
import {ToolTipsSteps} from './Steps';
import {ttStepsMap, ttStepsMapRev} from '../../../helpers/user';
import {TCommunity} from '../../../types/community';
import {User} from '../../../queries/types/user';
import {hasPlanOption} from '../../../helpers/community';
import {PlanOptionsValues} from '../../../constants/plan';
import {highlightsExists} from './Highlights';
import {useSetUserTooltips} from '../../../hooks/user';
import {isMobile} from 'react-device-detect';
import {analyticsTrackFN} from '../../../helpers/account';

export enum ttSteps {
  start = 'start',
  home = 'home',
  people = 'people',
  explore = 'explore',
  messages = 'messages',
  helpDesk = 'helpDesk',
  profile = 'profile',
  switch = 'switch',
  end = 'end',
  none = 'none',
}

export const NavigationToolTips: React.FC<{
  comm: Partial<TCommunity>;
  user: User;
  allComm: Array<Partial<TCommunity>>;
}> = ({comm, user, allComm}) => {
  const [step, setStep] = useState<ttSteps>(ttSteps.none);
  const [close, setClose] = useState(false);
  const {acceptTips} = useSetUserTooltips();

  useEffect(() => {
    if (!user?.tips?.welcomeTipsViewed && !user?.tips?.multiplyCommunityTip) analyticsTrackFN('Walkthrough Begin');
  }, []);

  useEffect(() => {
    if (!user?.tips?.welcomeTipsViewed) {
      setClose(false);
      setStep(ttSteps.start);
    } else if (!user?.tips?.multiplyCommunityTip && user?.tips?.welcomeTipsViewed && allComm?.length >= 2) {
      setClose(false);
      setStep(ttSteps.switch);
    } else {
      setStep(ttSteps.none);
      setClose(true);
    }
  }, [comm?.objectId, allComm?.length]);

  const onClose = async () => {
    if (!user?.tips?.multiplyCommunityTip && allComm?.length >= 2 && step !== ttSteps.switch) {
      setStep(ttSteps.switch);
      return;
    }
    setClose(true);
    analyticsTrackFN('Walkthrough Completed');
    await acceptTips(true, hasMultiplyCommunity);
  };

  const goToEnd = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    analyticsTrackFN('Walkthrough Skipped');
    setStep(ttSteps.end);
  };

  const hasHelpDeskAndMore = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.helpDesk) || isMobile;
  const hasEvents = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.events);
  const hasListings = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.listingsFeed);
  const hasAmenities = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.amenities);
  const hasPeople = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.peoplesFeed);
  const hasGroups = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.groups);
  const hasLocalBiz = hasPlanOption(comm?.Subscr?.PaymentPlan?.options, PlanOptionsValues.company);
  const hasMultiplyCommunity = Boolean(allComm?.length >= 2);
  const exList: highlightsExists = {
    helpDeskAndMore: hasHelpDeskAndMore,
    events: hasEvents,
    listings: hasListings,
    amenities: hasAmenities,
    people: hasPeople,
    groups: hasGroups,
    localBiz: hasLocalBiz,
    hasMultiplyCommunity: hasMultiplyCommunity,
  };
  const handleNextStep = () => {
    setStep((prev) => {
      /*if (prev === ttSteps.switch && !user?.tips?.multiplyCommunityTip && user?.tips?.welcomeTipsViewed) {
        onClose();
        return ttSteps.none;
      }*/
      const nextIndex = String(ttStepsMap[prev] + 1);
      const checkSkip = (nextIndex: string): string => {
        return (!hasHelpDeskAndMore && ttStepsMapRev?.[nextIndex] === ttSteps.helpDesk) ||
          (!hasListings && !hasEvents && ttStepsMapRev?.[nextIndex] === ttSteps.explore) ||
          (!hasPeople && ttStepsMapRev?.[nextIndex] === ttSteps.people) ||
          ttStepsMapRev?.[nextIndex] === ttSteps.switch
          ? checkSkip(String(Number(nextIndex) + 1))
          : nextIndex;
      };
      const checkedIndex = checkSkip(nextIndex);
      return ttStepsMapRev?.[checkedIndex] || prev;
    });
  };
  if (close) return null;

  return (
    <ToolTipsSteps
      close={onClose}
      step={step}
      nextStep={handleNextStep}
      comm={comm}
      user={user}
      exList={exList}
      goToEnd={goToEnd}
    />
  );
};
