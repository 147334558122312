import React from 'react';
import {useParams} from 'react-router-dom';

import {Social} from '../../components/Auth/Social';
import {SocialAuth} from '../../types/auth';
import {useSocialAuth} from '../../hooks/auth';
import {useFacebookAppFix, useGoogleIosAppFix} from '../../hooks/social';

export const SocialContainer = () => {
  const {tab} = useParams<{tab?: SocialAuth}>();
  const {reqWIthGoogle, reqWIthFacebook, reqWIthApple, error, loading} = useSocialAuth();
  const {fbReady, onFBClick} = useFacebookAppFix();
  const {googleReady, onGoogleLoginClick} = useGoogleIosAppFix();

  return (
    <Social
      tab={tab}
      reqWIthGoogle={reqWIthGoogle}
      reqWIthFacebook={reqWIthFacebook}
      reqWIthApple={reqWIthApple}
      error={error}
      loading={loading}
      fbReady={fbReady}
      googleReady={googleReady}
      onGoogleLoginClick={onGoogleLoginClick}
      onFBClick={onFBClick}
    />
  );
};
