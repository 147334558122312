import React, {useMemo} from 'react';
import {PageGroups} from '../../components/Groups/PageGroups';
import {EmptyTag, MobContainerProps} from '../MobileTabs';
import {NavContainer} from '../Navigation';
import {useActionOnGroup, useGetGroups, useGetJoinedGroups} from '../../hooks/groups';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {useViewerId} from '../../hooks/user';
import {currentCommunity, userPermissions} from '../../states/community';
import {PermissionsList} from '../../types/settings';
import {entities, useReport} from '../../hooks/report';

export const Groups: React.FC<MobContainerProps> = ({hideNav}) => {
  const viewerId = useViewerId('objectId');
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  const [userType] = useRecoilState(typeUser);
  const [permissions] = useRecoilState(userPermissions);
  const {data, loading, refetch} = useGetGroups();
  const joinedGroups = useGetJoinedGroups(viewerId);
  const [community] = useRecoilState(currentCommunity);
  const actionsData = useActionOnGroup({
    community: community,
    onSuccess: () => {
      refetch();
      joinedGroups?.refetch?.();
    },
  });
  const canEdit = useMemo(
    () =>
      userType === TypeCommunity.manager ||
      (permissions.includes(PermissionsList.manageGroups) && userType === TypeCommunity.admin),
    [permissions, userType],
  );

  const report = useReport(entities.group);
  return (
    <Wrapper>
      <PageGroups
        loading={loading}
        actionsData={actionsData}
        items={data}
        canEdit={canEdit}
        joinedGroups={joinedGroups.joinedList}
        report={report}
      />
    </Wrapper>
  );
};
