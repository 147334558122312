import React, {useEffect, useState} from 'react';
import {useViewerId} from '../../../hooks/user';
import {useNotificationsAnalytics, useNotificationSettings} from '../../../hooks/notifications';
import {
  useFormEdit,
  useSettingLangAndCurrency,
  useSettingProfileView,
  useUpdateProfileForm,
} from '../../../hooks/settings';
import {
  receivingVariants,
  SettingNotificationT,
  SettingNotificationTypes,
  StateNotificationSettingsType,
} from '../../../types/notifications';
import {PreferencesSettings} from '../../../components/Settings/Preferences/PreferencesSettings';
import {NavContainer} from '../../Navigation';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../../components/SEO';
import {FormEditType, FormType} from '../../../types/settings';
import {SectionWrapper} from '../../../components/Settings/commonStyles';
import {Subtitle} from '../../../components/Settings/Preferences/components';
import {SettingsForm} from '../../../components/Settings/Preferences/SettingsNotifications';
import {changeNotificationState, getNotificationValues, getUpdatedValues} from '../../../helpers/notifications';
import {isModal} from '../../../states/themeMode';

export const Preferences: React.FC = () => {
  const userId = useViewerId('objectId');
  const notificationSettings = useNotificationSettings(userId ?? '');
  const formEdit = useFormEdit();
  const [typeCommunity] = useRecoilState(typeUser);
  const isManager = typeCommunity === TypeCommunity.manager;
  const {t} = useTranslation();
  const textSeo = t('common:seo.settings.preference');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  const notificationsForm = useUpdateProfileForm<StateNotificationSettingsType, StateNotificationSettingsType>({
    initialState: notificationSettings.data,
    loading: notificationSettings.loading,
    onSuccess: () => {
      formEdit.handleEditEnd();
      notificationSettings.refetch();
    },
  });

  const langData = useSettingLangAndCurrency();
  const profileViewsData = useSettingProfileView();
  return (
    <NavContainer>
      <Seo title={textSeo} keywords={seoKeywords} />
      <PreferencesSettings
        notificationSettings={notificationSettings.data}
        isManager={isManager}
        editNotifications={formEdit}
        formNotifications={notificationsForm}
        langData={langData}
        profileViewsData={profileViewsData}
        userId={userId}
      />
    </NavContainer>
  );
};

interface SettingsNotificationsProps {
  notificationSettings: StateNotificationSettingsType;
  isManager: boolean;
  edit: FormEditType;
  form: FormType<StateNotificationSettingsType>;
  notificationsList: SettingNotificationT[];
  userId?: string;
}

export const SettingsNotifications: React.FC<SettingsNotificationsProps> = ({notificationsList, form, userId}) => {
  const [editing, setEditing] = useState('');
  const [loading, setLoading] = useState(false);
  const [oldValues, setOldValues] = useState<Partial<StateNotificationSettingsType>>();
  const [prevValues, setPrevValues] = useState<{name: string; values: string[]}[]>();
  const selected = notificationsList.find((el) => el.type === editing);
  const setModal = useSetRecoilState(isModal);
  const sendUpdate = useNotificationsAnalytics();
  const toggleValue = (key: receivingVariants, value: boolean) => {
    //check for required
    if (
      value === false &&
      (selected?.type === SettingNotificationTypes.bookingRequests ||
        selected?.type === SettingNotificationTypes.personalInteractions)
    ) {
      const {push, email} = getNotificationValues(selected, form.values);
      if (!push && key === 'email') return;
      if (!email && key === 'push') return;
    }
    const toChange = changeNotificationState(key, value, selected, form?.values);
    toChange?.forEach((changed) => {
      form.onChange({name: changed?.notifKey, value: changed?.value});
    });
  };
  const onUpdate = async () => {
    setLoading(true);
    const updated = getUpdatedValues(form.values, oldValues);
    setOldValues(form?.values);
    const res = await form.onSubmit(userId);
    if (res) {
      updated.forEach((item) => {
        sendUpdate(item);
      });
    }
    setEditing('');
    setPrevValues([]);
    setModal(false);
    setLoading(false);
  };
  const onEdit = (type: string) => {
    if (!Object.values(form.values)?.some((el) => !!el)) return;
    setModal(true);
    setEditing(type);
    const editingNotif = notificationsList.find((el) => el.type === type);
    setPrevValues(
      editingNotif?.notifications?.map((el) => ({
        name: el,
        values: form?.values[el as keyof StateNotificationSettingsType] || [],
      })),
    );
  };
  const closePopup = () => {
    setModal(false);
    setEditing('');
    prevValues?.forEach((changed) => {
      form.onChange({name: changed?.name, value: changed?.values});
    });
  };
  const loaded = form?.values?.notifyManagerAdminPost !== undefined;
  useEffect(() => {
    if (loaded) setOldValues(form?.values);
  }, [loaded]);
  return (
    <SectionWrapper $topBorder $noBorder>
      <Subtitle />
      <SettingsForm
        notificationsList={notificationsList}
        form={form}
        onEdit={onEdit}
        onClosePopup={closePopup}
        selected={selected}
        editing={editing}
        toggleValue={toggleValue}
        onUpdate={onUpdate}
        updateLoading={loading}
      />
    </SectionWrapper>
  );
};
