import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';
import {PageResident} from '../../components/LocalBiz/PageResident';
import {GQLCompanyWhereInput} from '../../graphql.schema';
import {useGetLocalBiz} from '../../hooks/company';
import {entities, useReport} from '../../hooks/report';
import {useViewerId} from '../../hooks/user';
import {currentCommunity} from '../../states/community';

export const PageResidentContainer: React.FC = () => {
  const {report} = useReport(entities.company);

  const [searchParams, setSearchParams] = useState<GQLCompanyWhereInput | null>(null);
  const {data, loading, refetch, hasMore} = useGetLocalBiz(
    [{isPublished: {equalTo: true}}, searchParams].filter(Boolean) as Array<GQLCompanyWhereInput>,
  );

  const community = useRecoilValue(currentCommunity);
  const viewerId = useViewerId('objectId') as string;

  return (
    <PageResident
      community={community}
      loading={loading}
      companies={data}
      fetchData={refetch}
      hasMore={hasMore}
      setSearchParams={setSearchParams}
      userId={viewerId}
      onReport={report}
    />
  );
};
