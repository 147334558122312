import React, {useMemo, useState} from 'react';
import {SectionWrapper} from '../commonStyles';
import {SettingsSubsection} from '../components';
import {useTranslation} from 'react-i18next';
import {Tabs} from '../../../ui-kit/Tabs/Tabs';
import {Tab} from '../../../ui-kit/Tabs/Tab';
import {
  ErorrResultBlock,
  TabsWrapper,
  TransactionBox,
  TransactionInfoEntry,
  TransactionInfoWrapper,
  TransactionsGrid,
  ViewButton,
} from './styles';
import {SubscrPayment, SubscrPaymentStatus, Transaction} from '../../../queries/types/transactions';
import {Text} from '../../../ui-kit/Typography/Text';
import {useSubscrPayDataT} from '../../../hooks/transactions';
import {Button} from '../../../ui-kit/Button/Button';
import {parseJSON} from 'date-fns';
import {IGetCurrValue} from '../../../types/common';
import {RoundModal} from '../../common/Modal/RoundModal';
import {useCalcOrderData, useDataInvoiceForMsgs} from '../../../hooks/order';
import {SpinnerLoader} from '../../../ui-kit/Loader/ContentLoader';
import loadable, {LoadableComponent} from '@loadable/component';
import {TransactionModalContentPropsT} from './TransactionModalContent';
const TransactionModalContent: LoadableComponent<TransactionModalContentPropsT> = loadable(
  () => import('./TransactionModalContent'),
  {ssr: false},
);

interface SettingsInvoicesProps {
  payments?: Transaction[];
  subscriptions?: SubscrPayment[];
  promos?: Transaction[];
  isManager?: boolean;
  subscrPayData?: useSubscrPayDataT;
  refetchSubscr?: () => void;
  getAmount: IGetCurrValue;
  paymentsLoading?: boolean;
}

export const TransactionEntry: React.FC<{
  transaction?: Transaction | SubscrPayment;
  getAmount: IGetCurrValue;
  onView?: (id: string) => void;
}> = ({transaction, getAmount, onView}) => {
  const date = transaction?.date || transaction?.createdAt;
  const {t} = useTranslation();
  const viewClick = () => {
    transaction?.objectId && onView?.(transaction?.objectId);
  };
  const transact = transaction as Transaction;
  const type = !!transact?.Order?.listingType && t(`listings:listingsType.${transact?.Order?.listingType}`);
  return (
    <TransactionBox>
      <TransactionInfoWrapper>
        <TransactionInfoEntry>{date && t('common:datePP', {date: parseJSON(date)})}</TransactionInfoEntry>
        <TransactionInfoEntry>
          {type ? type : t('community:transactions.type', {context: transaction?.type})}
        </TransactionInfoEntry>
        <TransactionInfoEntry>{getAmount(transaction?.amount ? transaction.amount / 100 : 0)}</TransactionInfoEntry>
      </TransactionInfoWrapper>
      <ViewButton onClick={viewClick}>
        <Text variant={'primary'} weight={'semi-bold'} size={12} color={'octonary'}>
          {t('settings:payments.invoices.view')}
        </Text>
      </ViewButton>
    </TransactionBox>
  );
};

export const SubscrEntry: React.FC<{
  transaction?: SubscrPayment;
  subscrPayData?: useSubscrPayDataT;
  refetchSubscr?: () => void;
  getAmount: IGetCurrValue;
}> = ({transaction, subscrPayData, refetchSubscr, getAmount}) => {
  const [inActive, setInActive] = useState(false);
  const date = transaction?.date || transaction?.createdAt;
  const type = transaction?.Subscr?.PaymentPlan.name;
  const isError = useMemo(
    () =>
      transaction?.data
        ? transaction.data.toLowerCase().includes('error') && transaction.status === SubscrPaymentStatus.failed
        : false,
    [transaction],
  );
  const {t} = useTranslation();
  const rePay = () => {
    if (!transaction?.objectId || !isError) return;
    setInActive(true);
    subscrPayData?.paySubscr(transaction?.objectId, () => setInActive(false), refetchSubscr);
  };
  const status =
    transaction?.status === SubscrPaymentStatus.failed || transaction?.status === SubscrPaymentStatus.succeeded
      ? t(`settings:payments.statuses.${transaction.status}`)
      : '';
  return (
    <TransactionBox>
      <TransactionInfoWrapper>
        <TransactionInfoEntry>{date && t('common:datePP', {date: parseJSON(date)})}</TransactionInfoEntry>
        <TransactionInfoEntry>{type}</TransactionInfoEntry>
        <TransactionInfoEntry>{getAmount(transaction?.amount ? transaction.amount / 100 : 0)}</TransactionInfoEntry>
        {status && <TransactionInfoEntry>{status}</TransactionInfoEntry>}
        {isError && (
          <TransactionInfoEntry $isDisabled={inActive}>
            <Button onClick={rePay}>{t('settings:payments.invoices.pay')}</Button>
          </TransactionInfoEntry>
        )}
      </TransactionInfoWrapper>
    </TransactionBox>
  );
};

export const SettingsInvoices: React.FC<SettingsInvoicesProps> = ({
  payments,
  subscriptions,
  isManager,
  subscrPayData,
  refetchSubscr,
  getAmount,
  paymentsLoading,
}) => {
  const {t} = useTranslation();
  const [activeTransaction, setActiveTransaction] = useState<Transaction | null>(null);
  const order = activeTransaction?.Order ? activeTransaction?.Order : null;
  const {calcDataOrder, loading: invoiceLoading} = useCalcOrderData({orderId: order?.objectId});
  const dataInvoice = useDataInvoiceForMsgs(calcDataOrder, order);
  const tabPayments = `${t('settings:payments.invoices.payments')} (${payments?.length ?? 0})`;
  const tabSubscriptions = `${t('settings:payments.invoices.subscription')} (${subscriptions?.length ?? 0})`;
  // const tabPromos = `${t('settings:payments.invoices.promo')} (${promos?.length ?? 0})`;
  const onView = (id: string) => {
    const transaction = payments?.find((el) => el?.objectId === id);
    setActiveTransaction(transaction || null);
  };
  const closeModal = (val: boolean) => {
    if (val === false) setActiveTransaction(null);
  };
  return (
    <SectionWrapper $noBorder={true}>
      <SettingsSubsection label={t('settings:payments.invoices.title')} />
      <TabsWrapper>
        <Tabs variant={'primary'} defaultActiveTab={tabPayments}>
          <Tab label={tabPayments}>
            <TransactionsGrid>
              {paymentsLoading && !payments?.length && <SpinnerLoader />}
              {payments?.map((t) => (
                <TransactionEntry key={t.id} transaction={t} getAmount={getAmount} onView={onView} />
              ))}
            </TransactionsGrid>
          </Tab>
          {isManager ? (
            <Tab label={tabSubscriptions}>
              <TransactionsGrid>
                {subscriptions?.map((t) => (
                  <SubscrEntry
                    key={t.id}
                    transaction={t}
                    subscrPayData={subscrPayData}
                    refetchSubscr={refetchSubscr}
                    getAmount={getAmount}
                  />
                ))}
              </TransactionsGrid>
              <ErorrResultBlock>{!subscrPayData?.success && subscrPayData?.error}</ErorrResultBlock>
            </Tab>
          ) : (
            <></>
            // <Tab label={tabPromos}>
            //   <TransactionsGrid>
            //     {promos?.map((t) => (
            //       <TransactionEntry key={t.id} transaction={t} />
            //     ))}
            //   </TransactionsGrid>
            // </Tab>
          )}
        </Tabs>
      </TabsWrapper>
      <RoundModal active={!!activeTransaction?.objectId} setActive={closeModal}>
        <TransactionModalContent
          activeTransaction={activeTransaction}
          setActive={closeModal}
          invoiceLoading={invoiceLoading}
          dataInvoice={dataInvoice}
        />
      </RoundModal>
    </SectionWrapper>
  );
};
