import React from 'react';
import {Table as BaseTable} from '../../../../ui-kit/Table';
import {Earned, getDate, getPrice, getStatus, getType, Listing, Plan} from './columns';
import {Columns} from '../../../../ui-kit/Table/types';
import {useTranslation} from 'react-i18next';
import {PayoutsItem} from '../../../../types/settings';
import {IGetCurrValue, translateFn} from '../../../../types/common';

const getColumns = (t: translateFn, getSignValue: IGetCurrValue): Columns<PayoutsItem> => [
  {
    title: t('settings:payouts.table.date'),
    width: '110px',
    padLeft: '17px',
    render: getDate(t),
    dataIndex: 'createdAt',
  },
  {
    title: t('settings:payouts.table.for'),
    width: '200px',
    render: Listing,
  },
  {
    title: t('settings:payouts.table.type'),
    width: '80px',
    render: getType(t),
  },
  {
    title: t('settings:payouts.table.plan'),
    width: '120px',
    render: Plan,
  },
  {
    title: t('settings:payouts.table.total'),
    width: '80px',
    padRight: '40px',
    render: getPrice(getSignValue),
  },
  {
    title: t('settings:payouts.table.earning'),
    width: '80px',
    align: 'left',
    render: Earned,
  },
  {
    title: t('settings:payouts.table.status'),
    width: '120px',
    render: getStatus(t),
  },
];

export const PayoutsTable: React.FC<{items?: PayoutsItem[]; getSignValue: IGetCurrValue}> = ({items, getSignValue}) => {
  const {t} = useTranslation();
  const columns = getColumns(t, getSignValue);
  return <BaseTable<Partial<PayoutsItem>> variant="primary" items={items || []} columns={columns} height={'45px'} />;
};
