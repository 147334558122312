import React from 'react';
import {CircleFilled, CircleUnfilled, StyledSvg, CircleText} from './styles';

interface IProps {
  size: 'small' | 'medium' | 'large';
  text: string;
  value: number;
}

const CircleProgressBarMemo: React.FC<IProps> = ({value, size, text}) => {
  let radius = 0;
  let strokeWidth = 0;
  switch (size) {
    case 'small': {
      radius = 14;
      strokeWidth = 1;
      break;
    }
    case 'medium': {
      radius = 18;
      strokeWidth = 2;
      break;
    }
    case 'large': {
      radius = 22;
      strokeWidth = 2;
      break;
    }
    default: {
      radius = 18;
      strokeWidth = 2;
    }
  }
  const localValue = value / 100;
  const innerRadius = radius - 0.5 * strokeWidth;
  const innerDiameter = 2 * innerRadius;
  return (
    <StyledSvg width={radius * 2} height={radius * 2}>
      <g>
        <CircleUnfilled
          innerDiameter={innerDiameter}
          innerRadius={innerRadius}
          radius={radius}
          strokeWidth={5}
          value={localValue}
        />
        <CircleFilled
          value={localValue}
          innerRadius={innerRadius}
          innerDiameter={innerDiameter}
          strokeWidth={strokeWidth}
          radius={radius}
          cx={innerRadius}
          cy={innerRadius}
          r={radius - 2}
        />
        {text && (
          <CircleText x={'50%'} y={'60%'} radius={radius}>
            {text}
          </CircleText>
        )}
      </g>
    </StyledSvg>
  );
};

const CircleProgressBar = React.memo(CircleProgressBarMemo);
export default CircleProgressBar;
