import {
  GQLOrderOrder,
  GQLOrderStatuses,
  GQLOrderType,
  GQLOrderWhereInput,
  GQLReviewOrder,
  GQLTransactionWhereInput,
} from '../graphql.schema';
import {useMutation, useQuery} from '@apollo/client';
import {
  CalcOrderData,
  CalcOrderDataReqType,
  CalcOrderDataResType,
  EditOrder,
  EditOrderReqType,
  EditOrderResType,
  GetOrdersQuery,
  GetShortOrdersQuery,
  MakeOrder,
  MakeOrderRequestType,
  MakeOrderResponseType,
  ManageOrder,
  ManageOrderRequestType,
  ManageOrderResponseType,
  OrdersResponseType,
  preOrderCalcData,
  PreOrderCalcDataRequestType,
  PreOrderCalcDataResponseType,
} from '../queries/order';
import {
  CreateOrderReview,
  CreateOrderReviewReqType,
  CreateOrderReviewResType,
  GetOrderReviewResType,
  getOrderReviews,
  UpdateOrderReview,
  UpdateOrderReviewReqType,
  UpdateOrderReviewResType,
} from '../queries/reviews';
import {useCallback, useEffect, useRef, useState} from 'react';
import {User} from '../queries/types/user';
import {useTranslation} from 'react-i18next';
import {useGetContactsForChat} from './message';
import {PaymentPlan} from '../queries/types/payments';
import {PreOrderCalcDataT, TItem} from '../types/item';
import {ListingType, PricePeriodType} from '../queries/types/item';
import {calcWithDiscount, checkExternal, checkStatusHierarchy, getTimeData, preCalcInitial} from '../helpers/order';
import {convertCentToDollar, getFormattingAmount, makeRounding} from '../helpers/payment';
import {Order, TCalcOrderData} from '../queries/types/order';
import {useViewerId} from './user';
import Parse from 'parse';
import {ILQOrder, LQOrderField} from '../types/messages';
import {toPOJO, useLiveQuery} from './parse';
import {sortDescByDate} from '../helpers/message';
import {datePickerDataT} from '../ui-kit/RequestCard/types';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../states/community';
import {reviewsToState} from '../helpers/reviews';
import {ReviewSubject} from '../queries/types/review';
import {TReview} from '../types/reviews';
import {GetTransactions, GetTransactionsRequest, GetTransactionsResponse} from '../queries/transactions';
import {getDatesArray} from '../helpers/dates';
import {getResize} from '../helpers/file';
import {SubscrPaymentStatus} from '../queries/types/transactions';
import {useGetCurrencyValue} from './currency';
import {checkIsNotSelected} from '../helpers/listings';
import {EventRepeatType} from '../queries/types/event';
import {getNextEventDate} from '../helpers/event';
import {format} from 'date-fns';

export const useOrders = (options?: {
  where?: GQLOrderWhereInput;
  order?: GQLOrderOrder;
  skip?: number;
  first?: number;
}) => {
  const {data, ...response} = useQuery<OrdersResponseType>(GetOrdersQuery, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
      ...(options?.skip ? {skip: options?.skip} : {}),
    },
    ssr: true,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.orders?.edges?.map((edge) => edge.node),
    count: data?.orders?.count,
    ...response,
  };
};

type OrdersParams = {
  where?: GQLOrderWhereInput;
  order?: GQLOrderOrder[];
  cursor?: string;
  first?: number;
  id?: string;
};

export const useOrdersList = (params: {
  where?: GQLOrderWhereInput;
  order?: GQLOrderOrder;
  skip?: boolean;
  first?: number;
}) => {
  const previousCursor = useRef<string | null>(null);

  const variables = {
    ...(params?.where ? {where: params.where} : {}),
    ...(params?.order ? {order: [params.order]} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<OrdersResponseType, OrdersParams>(GetOrdersQuery, {
    variables,
    notifyOnNetworkStatusChange: true,
    ssr: true,
    skip: params?.skip,
  });

  const fetch = async () => {
    const {hasNextPage, endCursor} = data?.orders?.pageInfo || {};

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.orders?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.orders.edges;
          const nextChunk = fetchMoreResult.orders.edges;

          return {
            orders: {...fetchMoreResult.orders, edges: prevChunk.concat(nextChunk)},
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: data?.orders?.edges?.map((edge) => edge.node) || [],
    loading,
    total: data?.orders?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.orders?.pageInfo?.hasNextPage),
    refetch,
  };
};

export type TDateValues = Date | null;

export type TResCreateOrder = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  dates: TDateValues[];
  success?: string | null;
  onChange: (dates: TDateValues[]) => void;
  datePickerData: datePickerDataT;
  preCalcData: PreOrderCalcDataT;
  orderInfo?: string;
  startEndTime: {start: string; end: string};
  isNotSelected?: boolean;
};

type TOptsCreateOrderType = (options: {
  communityId?: string;
  itemId?: string;
  pricePeriod?: PricePeriodType | null;
  listingType?: ListingType;
  onSuccess?: () => void;
  onError?: () => void;
  busyDates?: Date[];
}) => TResCreateOrder;

export const useCreateOrder: TOptsCreateOrderType = (options) => {
  const [dates, setDates] = useState<TDateValues[]>([null, null]);
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [time, setTime] = useState<Date | null>(null);
  const [duration, setDuration] = useState<number | '' | undefined>();
  const [startdate, setStartdate] = useState<Date | null>(null);
  const [preCalcData, setPreCalcData] = useState<PreOrderCalcDataT>(preCalcInitial);
  const [orderId, setOrderId] = useState<string>('');

  const handleSetTime = (t: Date) => setTime(t);
  const handleSetDuration = (t: number | '') => setDuration(t);
  const handleSetStartdate = (t: Date) => setStartdate(t);
  const [startEndTime, setStartEndTime] = useState<{start: string; end: string}>({end: '', start: ''});

  const [CreateOrderRequest, {loading}] = useMutation<MakeOrderResponseType, MakeOrderRequestType>(MakeOrder);
  const [getCalcOrderRequest] = useMutation<PreOrderCalcDataResponseType, PreOrderCalcDataRequestType>(
    preOrderCalcData,
  );
  const handleChange = (value: TDateValues[]) => {
    setError(null);
    setSuccess(null);
    return setDates(value);
  };
  useEffect(() => {
    if (options.pricePeriod === PricePeriodType.hour) {
      if (startdate && duration) {
        const startDate = new Date(startdate);
        let endDate = new Date(new Date(startdate).setHours(startDate.getHours() + duration));
        endDate = new Date(new Date(endDate).setMinutes(startDate?.getMinutes()));
        // setTime(startdate);
        handleChange([startDate, endDate]);
      }
    }
    if (options.listingType === ListingType.sell) {
      if (startdate) {
        handleChange([startdate, startdate]);
      }
    }
  }, [startdate, time, duration]);
  const preCalc = async (): Promise<boolean> => {
    try {
      if (!options?.communityId || !options?.itemId) {
        return false;
      }
      if (!startdate && dates.includes(null)) {
        return false;
      }
      let startTime = (dates[0] && (dates[0] as Date).toISOString()) || '';
      let endTime = (dates[1] && (dates[1] as Date).toISOString()) || '';

      if (options.listingType === ListingType.sell) {
        if (!startdate) {
          return false;
        }
        startTime = startdate?.toISOString();
        endTime = startdate?.toISOString();
      }
      setStartEndTime({start: startTime, end: endTime});
      const response = await getCalcOrderRequest({
        variables: {
          communityId: options.communityId,
          objectId: options.itemId,
          orderType: GQLOrderType.item,
          startTime: startTime || '',
          endTime: endTime || '',
        },
      });
      const data = response.data?.preOrderCalcData;
      if (data) {
        setPreCalcData(data);
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  const onSubmit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!options?.communityId || !options?.itemId) {
        return false;
      }
      if (options.pricePeriod !== PricePeriodType.hour && options.listingType !== ListingType.sell) {
        if (dates.includes(null)) {
          throw new Error('error:datesRequests');
        }
      }

      let startTime = dates[0] && (dates[0] as Date).toISOString();
      let endTime = dates[1] && (dates[1] as Date).toISOString();

      if (options.listingType !== ListingType.sell) {
        const pickedDates = getDatesArray(dates?.[0] as Date, dates?.[1] as Date);
        pickedDates?.forEach((date1) => {
          options?.busyDates?.forEach((date2) => {
            if (date1.getTime() === date2.getTime()) {
              throw new Error('error:availableDatesRequests');
            }
          });
        });
      }

      if (options.pricePeriod === PricePeriodType.hour) {
        if (!time || !startdate || !duration) {
          throw new Error('error:datesRequests');
        }
      }

      if (options.listingType === ListingType.sell) {
        if (!startdate) {
          throw new Error('error:datesRequests');
        }
        startTime = startdate.toISOString();
        endTime = startdate.toISOString();
      }

      const response = await CreateOrderRequest({
        variables: {
          communityId: options.communityId,
          objectId: options.itemId,
          orderType: GQLOrderType.item,
          startTime: startTime || '',
          endTime: endTime || '',
        },
      });

      const data = response?.data?.makeOrder?.order;
      if (!data) throw new Error();
      setOrderId(data);
      setSuccess('success:createOrder');
      setError(null);
      options?.onSuccess?.();
      setDates([null, null]);
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };
  const datePickerData: datePickerDataT = {
    time: {
      value: time,
      set: handleSetTime,
    },
    duration: {
      value: duration,
      set: handleSetDuration,
    },
    startdate: {
      value: startdate,
      set: handleSetStartdate,
    },
  };

  const isNotSelected = checkIsNotSelected(datePickerData, options?.listingType, options?.pricePeriod, dates);
  useEffect(() => {
    if (isNotSelected) return;
    preCalc();
  }, [dates, startdate, isNotSelected]);

  return {
    preCalcData,
    dates,
    datePickerData,
    onSubmit,
    success,
    loading: isLoading,
    onChange: handleChange,
    error,
    startEndTime,
    orderInfo: orderId,
    isNotSelected,
  };
};

export type TDataLister = {
  verified: string;
  reviews: string;
  isOnline?: boolean;
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  objectId: string;
};

export const useDataLister = (lister?: User): TDataLister => {
  const {t} = useTranslation();
  const {contacts} = useGetContactsForChat({
    initUsers: lister ? [lister] : null,
    msgs: [],
    contactsIds: lister ? [lister.objectId] : undefined,
  });

  const verified = lister?.isVerified ? t('requests:verified') : '';
  const reviews = t('requests:reviews', {count: lister?.Reviews?.count || 0});
  return {
    email: lister?.email || '',
    objectId: lister?.objectId || '',
    verified,
    reviews,
    isOnline: contacts?.[0]?.isOnline,
    avatar: getResize(lister?.Avatar?.file?.url, 'lg') || '',
    firstName: lister?.firstName || '',
    lastName: lister?.lastName || '',
  };
};

export type TInvoice = {
  price: {label: string; value: number | string};
  serviceFee: {label: string; value: number | string};
  paymentProcessing: {label: string; value: number | string};
  tax: {label: string; value: number | string};
  total: {label: string; value: number | string};
  discount: {label: string; value: number | string};
};

export const useDataInvoice = (opts: {
  plan: PaymentPlan | null;
  item?: Partial<TItem>;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  const {t} = useTranslation();
  const {plan, item, startDate, endDate} = opts;
  const [values, setValues] = useState<TInvoice>({
    price: {label: '', value: 0},
    serviceFee: {label: '', value: 0},
    tax: {label: '', value: 0},
    total: {label: '', value: 0},
    paymentProcessing: {label: '', value: 0},
    discount: {label: '', value: 0},
  });
  const isExternal = checkExternal({
    type: item?.listingType,
    externalRent: plan?.rentalExternal,
    externalSale: plan?.salesExternal,
    externalService: plan?.serviceExternal,
  });

  const calc = (amount?: number, val?: number) => {
    return amount ? makeRounding(val) : 0;
  };

  useEffect(() => {
    if (!plan || !item?.objectId) return;

    const dataFees = {
      rent: plan.rentFees,
      sell: plan.salesFees,
      service: plan.serviceFees,
    };
    const fees = dataFees[item.listingType as ListingType];
    const dataInterval = getTimeData({pricePeriod: item.pricePeriod, startDate, endDate});
    const amount = (item.price || 0) * dataInterval.value;
    const serviceFee = calc(amount, fees.hFee.hfRequesterAbs + (amount / 100) * fees.hFee.hfRequesterPrc);
    const tax = calc(amount, fees.cFee.cfRequesterAbs + (amount / 100) * fees.cFee.cfRequesterPrc);
    const total = isExternal ? amount : calc(amount, amount + serviceFee + tax);
    return setValues({
      price: {label: t(dataInterval.label, {value: dataInterval.value}), value: amount},
      serviceFee: {label: t('requests:label.serviceFee'), value: isExternal ? 0 : serviceFee},
      tax: {label: t('requests:label.tax'), value: isExternal ? 0 : tax},
      paymentProcessing: {label: t('requests:label.paymentProcessing'), value: 0},
      total: {label: t('requests:label.total'), value: total},
      discount: {label: t('requests:label.discount'), value: total},
    });
  }, [plan, item?.objectId, startDate, endDate]);

  return {values};
};

type formatDataInvoiceOptions = {
  data: PreOrderCalcDataT;
  pricePeriod: PricePeriodType | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  hangehDiscount: number;
};

export const formatDataInvoice = (options: formatDataInvoiceOptions) => {
  const {t} = useTranslation();
  const {getSignValue} = useGetCurrencyValue();

  if (!options?.startDate || !options?.endDate) return;
  const dataInterval = getTimeData({
    pricePeriod: options.pricePeriod,
    startDate: options?.startDate,
    endDate: options?.endDate,
  });
  const itemCost = options.data.itemCost || 0;
  const discount = calcWithDiscount(itemCost, options.hangehDiscount);

  return {
    price: {
      label: t(dataInterval.label, {value: dataInterval.value}),
      value: getSignValue(convertCentToDollar(options.data.itemCost) || 0),
    },
    serviceFee: {
      label: t('requests:label.serviceFee'),
      value: getSignValue(convertCentToDollar(options.data.sfRequesterAmt) || 0),
    },
    paymentProcessing: {
      label: t('requests:label.paymentProcessing'),
      value: getSignValue(convertCentToDollar(options.data.ppRequesterAmt) || 0),
    },
    tax: {label: t('requests:label.tax'), value: 0},
    discount: {
      label: t('requests:label.discount'),
      value: discount ? `-${getSignValue(convertCentToDollar(discount) || 0)}` : 0,
    },
    total: {
      label: t('requests:label.total'),
      value: getSignValue(convertCentToDollar(options.data.requesterTotalAmt - discount) || 0),
    },
  };
};

export type TResManageOrder = {
  manageOrder: (params: {
    orderId?: string;
    status?: GQLOrderStatuses | null;
    recurring?: 'one' | 'all';
    onManageSuccess?: () => void;
  }) => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  success?: string | null;
};

type TOptsManageOrderType = (options: {
  onSuccess?: (status?: GQLOrderStatuses) => void;
  onError?: () => void;
  eventStartTime?: Date;
  eventEndTime?: Date;
  eventType?: EventRepeatType;
  orderStartTime?: Date;
}) => TResManageOrder;

export const useManageOrder: TOptsManageOrderType = (options) => {
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ManageOrderRequest, {loading}] = useMutation<ManageOrderResponseType, ManageOrderRequestType>(ManageOrder);
  const [EditOrderRequest, {loading: orderLoading}] = useMutation<EditOrderResType, EditOrderReqType>(EditOrder);
  const manageOrder = async (params: {
    orderId?: string;
    status?: GQLOrderStatuses | null;
    onManageSuccess?: () => void;
    recurring?: 'one' | 'all';
  }): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!params?.orderId || !params?.status) {
        return false;
      }
      let nextDate: Date | null = null;
      if (params?.recurring === 'one') nextDate = getNextEventDate(options?.orderStartTime, options?.eventType);
      if (params?.recurring === 'one' && nextDate && options?.eventStartTime && options?.eventEndTime) {
        const oldStartTime = new Date(options.eventStartTime);
        const startTime = new Date(nextDate);
        startTime.setHours(oldStartTime.getHours());
        startTime.setMinutes(oldStartTime.getMinutes());

        const oldEndTime = new Date(options.eventEndTime);
        const endTime = new Date(nextDate);
        endTime.setHours(oldEndTime.getHours());
        endTime.setMinutes(oldEndTime.getMinutes());
        await EditOrderRequest({
          variables: {
            id: params.orderId,
            fields: {
              startTime: startTime.toISOString(),
              endTime: endTime.toISOString(),
            },
          },
        });
      } else {
        const response = await ManageOrderRequest({
          variables: {
            orderId: params.orderId,
            status: params.status,
          },
        });
        const data = response?.data?.manageOrder?.order;
        if (!data) throw new Error();
      }

      setSuccess('success:common');
      setError(null);
      options?.onSuccess?.(params.status);
      params?.onManageSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    } finally {
      setIsLoading(loading || orderLoading);
    }

    return true;
  };

  return {
    manageOrder,
    success,
    loading: isLoading,
    error,
  };
};

export type TResCalcDataOrder = {
  calcDataOrder: TCalcOrderData | null;
  Submit: () => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  success?: string | null;
};

type TOptsCalcDataOrderType = (options: {
  orderId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResCalcDataOrder;

export const useCalcOrderData: TOptsCalcDataOrderType = (options) => {
  const [calcDataOrder, setCalcDataOrder] = useState<TCalcOrderData | null>(null);
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [CalcOrderRequest, {loading}] = useMutation<CalcOrderDataResType, CalcOrderDataReqType>(CalcOrderData);

  const Submit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!options?.orderId) {
        return false;
      }

      const response = await CalcOrderRequest({
        variables: {
          orderId: options.orderId,
        },
      });

      const data = response?.data?.calcOrderData;
      if (!data) throw new Error();
      setCalcDataOrder(data);
      setSuccess('success:common');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  useEffect(() => {
    if (!options?.orderId) return;
    Submit();
  }, [options?.orderId]);

  return {
    calcDataOrder,
    Submit,
    success,
    loading: isLoading,
    error,
  };
};

type TFieldInvoice = {
  label: string;
  value: string;
};

export type TDataInvoices = {
  price: TFieldInvoice;
  serviceFee: TFieldInvoice;
  paymentProcessing: TFieldInvoice;
  tax: TFieldInvoice;
  total: TFieldInvoice;
  discount: TFieldInvoice;
};

export const useDataInvoiceForMsgs = (calcDataOrder: TCalcOrderData | null, activeOrder: Order | null) => {
  const [state, setState] = useState<TDataInvoices | null>(null);
  const {t} = useTranslation();
  const {getSignValue} = useGetCurrencyValue();

  const viewerId = useViewerId('objectId');
  const list = {
    lister: {
      serviceFee: calcDataOrder?.sfListerAmt,
      paymentProcessing: calcDataOrder?.ppListerAmt,
      tax: calcDataOrder?.cfListerAmt,
      total: calcDataOrder?.listerTotalAmt,
    },
    requester: {
      serviceFee: calcDataOrder?.sfRequesterAmt,
      paymentProcessing: calcDataOrder?.ppRequesterAmt,
      tax: calcDataOrder?.cfRequesterAmt,
      total: calcDataOrder?.requesterTotalAmt,
    },
  };

  useEffect(() => {
    if (!calcDataOrder || !activeOrder || !viewerId) return;
    if (activeOrder?.Event?.objectId) return;
    if (activeOrder?.Amenity?.objectId) return;
    const typeUser = viewerId === activeOrder.Item?.Lister?.objectId ? 'lister' : 'requester';
    const isRequester = typeUser === 'requester';
    const itemCost = calcDataOrder.price * calcDataOrder.period || 0;
    const discount = isRequester ? calcWithDiscount(itemCost, activeOrder?.hangehDiscount || 0) : 0;
    setState({
      price: {
        label: t(`requests:label.price.${calcDataOrder.pricePeriod}`, {value: calcDataOrder.period}),
        value: getSignValue(getFormattingAmount(itemCost)),
      },
      serviceFee: {
        label: t('requests:label.serviceFee'),
        value: `${isRequester ? '' : '-'} ${getSignValue(getFormattingAmount(list[typeUser].serviceFee))}`,
      },
      paymentProcessing: {
        label: t('requests:label.paymentProcessing'),
        value: `${isRequester ? '' : '-'} ${getSignValue(getFormattingAmount(list[typeUser].paymentProcessing))}`,
      },
      tax: {label: t('requests:label.tax'), value: ''},
      discount: {
        label: t('requests:label.discount'),
        value: isRequester ? `-${getSignValue(getFormattingAmount(discount))}` : '',
      },
      total: {
        label: t(`requests:label.${isRequester ? 'total' : 'payout'}`),
        value: getSignValue(getFormattingAmount((list[typeUser].total || 0) - discount)),
      },
    });
  }, [calcDataOrder, activeOrder, viewerId]);

  return state;
};

export const mapOrdersParseObject = (object: Parse.Object): ILQOrder => {
  return toPOJO<ILQOrder>(Object.values(LQOrderField), object);
};

export const useLQOrders = (params: {orders?: Order[] | null; loading?: boolean; ordersIds?: string[]}) => {
  const [query, setQuery] = useState<Parse.Query>();
  const [orders, setOrders] = useState<Order[] | null | undefined>(params?.orders);

  useEffect(() => {
    if (!params?.orders?.length) return;
    setOrders(sortDescByDate(params?.orders, 'updatedAt'));
  }, [params?.orders?.length]);

  useEffect(() => {
    if (!params?.ordersIds?.length) return;
    const query = new Parse.Query('Order').include(['objectId', 'status']).containedIn('objectId', params?.ordersIds);
    setQuery(query);
  }, [params?.ordersIds?.length]);

  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapOrdersParseObject(object);
    },
    [query],
  );
  const {
    data,
    refetch,
    loading: loadingLQ,
  } = useLiveQuery<ILQOrder>({
    query: query as Parse.Query,
    map: memoMap,
  });
  useEffect(() => {
    const dataLQ = Object.values(data || {});
    dataLQ?.forEach((itLQ) => {
      orders?.forEach((it, index) => {
        if (itLQ.objectId !== it.objectId) return;
        const isListing = !!it?.Item?.objectId;
        const isChangeStatus =
          it.status !== itLQ.status && checkStatusHierarchy({next: itLQ.status, current: it.status, isListing});
        const isChangeDate = itLQ?.startTime
          ? format(new Date(it.startTime), 'P') !== format(new Date(itLQ.startTime), 'P')
          : false;
        if (!isChangeStatus && !isChangeDate) return;
        const copyOrders = [...orders];
        copyOrders[index] = {...it, status: itLQ.status, ...(itLQ.startTime ? {startTime: itLQ.startTime} : {})};
        setOrders(copyOrders);
      });
    });
  }, [data, orders]);

  return {orders, refetch, loading: params.loading || loadingLQ};
};

export type TResEditOrder = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  dates: TDateValues[];
  success?: string | null;
  onChange: (dates: TDateValues[]) => void;
  datePickerData: datePickerDataT;
};

type UseEditOrderType = (options: {
  orderId?: string;
  pricePeriod?: PricePeriodType | null;
  listingType?: ListingType;
  dates: TDateValues[];
  onSuccess?: () => void;
  onError?: () => void;
}) => TResEditOrder;

export const useEditOrder: UseEditOrderType = (options) => {
  const [dates, setDates] = useState<TDateValues[]>(options.dates || [null, null]);
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startdate, setStartdate] = useState<Date | null>(options.dates[0] || null);

  const handleSetStartdate = (t: Date) => setStartdate(t);

  const [EditOrderRequest, {loading}] = useMutation<EditOrderResType, EditOrderReqType>(EditOrder);

  const handleChange = (value: TDateValues[]) => {
    setError(null);
    setSuccess(null);
    return setDates(value);
  };
  const onSubmit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!options?.orderId) {
        return false;
      }
      if (options.pricePeriod !== PricePeriodType.hour && options.listingType !== ListingType.sell) {
        if (dates.includes(null)) {
          throw new Error('error:datesRequests');
        }
      }

      let startTime = dates[0] && (dates[0] as Date).toISOString();
      let endTime = dates[1] && (dates[1] as Date).toISOString();

      if (options.listingType === ListingType.sell) {
        if (!startdate) {
          throw new Error('error:datesRequests');
        }
        startTime = startdate.toISOString();
        endTime = startdate.toISOString();
      }

      const response = await EditOrderRequest({
        variables: {
          id: options.orderId,
          fields: {
            startTime: startTime || '',
            endTime: endTime || '',
          },
        },
      });

      const data = response?.data?.objectId;
      if (!data) throw new Error();

      setSuccess('success:createOrder');
      setError(null);
      options?.onSuccess?.();
      setDates([null, null]);
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };
  const datePickerData: datePickerDataT = {
    startdate: {
      value: startdate,
      set: handleSetStartdate,
    },
  };

  return {
    dates,
    datePickerData,
    onSubmit,
    success,
    loading: isLoading,
    onChange: handleChange,
    error,
  };
};
export type editReviewDataT = {
  isEditing: boolean;
  setEditReview: (t: boolean) => void;
};

export type TResCreateReview = {
  onSubmit: () => Promise<boolean>;
  onUpdate: () => Promise<boolean>;
  error?: {message?: string} | null;
  success?: string | null;
  data: {
    userRating?: number;
    userReview?: string;
    itemRating?: number;
    itemReview?: string;
  };
  change: {
    changeUserRating: (t: number) => void;
    changeUserReview: (value: string) => void;
    changeItemRating: (t: number) => void;
    changeItemReview: (value: string) => void;
  };
};

type UseCreateReviewType = (options: {
  orderId?: string;
  itemId?: string;
  eventId?: string;
  amenityId?: string;
  opponentId?: string;
  isRequester?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  reviewData?: TReview[] | null;
}) => TResCreateReview;

export const useCreateOrderReview: UseCreateReviewType = (options) => {
  const authorId = useViewerId('id');
  const [community] = useRecoilState(currentCommunity);
  const readyItem = options.reviewData?.find((el) => el.subject === ReviewSubject.item);
  const readyUser = options.reviewData?.find((el) => el.subject === ReviewSubject.user);
  const [userRating, setUserRating] = useState<number>(0);
  const [userReview, setUserReview] = useState<string>('');
  const [itemRating, setItemRating] = useState<number>(0);
  const [itemReview, setItemReview] = useState<string>('');

  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);

  const [CreateReviewRequest] = useMutation<CreateOrderReviewResType, CreateOrderReviewReqType>(CreateOrderReview);
  const [UpdateReviewRequest] = useMutation<UpdateOrderReviewResType, UpdateOrderReviewReqType>(UpdateOrderReview);

  const changeUserRating = (value: number) => {
    return setUserRating(value);
  };
  const changeUserReview = (value: string) => {
    return setUserReview(value);
  };
  const changeItemRating = (value: number) => {
    return setItemRating(value);
  };
  const changeItemReview = (value: string) => {
    return setItemReview(value);
  };
  const onSubmit = async (): Promise<boolean> => {
    try {
      if (!options?.orderId || !authorId || !community?.id) {
        return false;
      }
      if (!userRating && !userReview && !itemRating && !itemReview) {
        return false;
      }
      const responseUser = await CreateReviewRequest({
        variables: {
          fields: {
            Author: {
              link: authorId,
            },
            Order: {
              link: options.orderId,
            },
            Community: {
              link: community?.id,
            },
            User: {
              link: options?.opponentId,
            },
            text: userReview,
            stars: userRating,
            subject: ReviewSubject.user,
          },
        },
      });
      const responseItem = options.isRequester
        ? await CreateReviewRequest({
            variables: {
              fields: {
                Author: {
                  link: authorId,
                },
                Order: {
                  link: options.orderId,
                },
                Community: {
                  link: community?.id,
                },
                Item: {
                  link: options?.itemId,
                },
                text: itemReview,
                stars: itemRating,
                subject: ReviewSubject.item,
              },
            },
          })
        : null;
      const dataUser = responseUser?.data?.objectId;
      const dataItem = options.isRequester ? responseItem?.data?.objectId : true;
      options?.onSuccess?.();
      if (!dataUser || !dataItem) throw new Error();
      setSuccess('success:createOrder');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };
  const userReviewId = readyUser?.id || '';
  const itemReviewId = readyItem?.id || '';
  const onUpdate = async (): Promise<boolean> => {
    try {
      if (!options?.orderId || !authorId || !community?.id) {
        return false;
      }
      if (!userRating && !userReview && !itemRating && !itemReview) {
        return false;
      }
      const responseUser =
        userReviewId &&
        (await UpdateReviewRequest({
          variables: {
            id: userReviewId,
            fields: {
              Author: {
                link: authorId,
              },
              Order: {
                link: options.orderId,
              },
              Community: {
                link: community?.id,
              },
              User: {
                link: options?.opponentId,
              },
              text: userReview,
              stars: userRating,
              subject: ReviewSubject.user,
            },
          },
        }));
      const responseItem =
        itemReviewId && options.isRequester
          ? await UpdateReviewRequest({
              variables: {
                id: itemReviewId,
                fields: {
                  Author: {
                    link: authorId,
                  },
                  Order: {
                    link: options.orderId,
                  },
                  Community: {
                    link: community?.id,
                  },
                  Item: {
                    link: options?.itemId,
                  },
                  text: itemReview,
                  stars: itemRating,
                  subject: ReviewSubject.item,
                },
              },
            })
          : null;

      const dataUser = responseUser && responseUser?.data?.objectId;
      const dataItem = options.isRequester ? responseItem && responseItem?.data?.objectId : true;
      options?.onSuccess?.();
      if (!dataUser || !dataItem) throw new Error();
      setSuccess('success:createOrder');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    data: {
      userRating,
      userReview,
      itemRating,
      itemReview,
    },
    change: {
      changeUserRating,
      changeUserReview,
      changeItemRating,
      changeItemReview,
    },
    onSubmit,
    onUpdate,
    success,
    error,
  };
};

type UseGetOrderReviewsType = {orderId?: string | null; onSuccess?: () => void; onError?: () => void};

export const useGetOrderReviews = (options: UseGetOrderReviewsType) => {
  const authorId = useViewerId('id');
  const {data, ...rest} = useQuery<GetOrderReviewResType>(getOrderReviews, {
    variables: {
      order: GQLReviewOrder.createdAt_DESC,
      where: {
        AND: [
          {
            Author: {
              have: {
                id: {
                  equalTo: authorId,
                },
              },
            },
          },
          {
            Order: {
              have: {
                id: {
                  equalTo: options.orderId,
                },
              },
            },
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const reviewsData = reviewsToState(data);
  return {data: reviewsData || null, ...rest};
};

export const useFetchOrder = () => {
  const {refetch} = useQuery<OrdersResponseType, OrdersParams>(GetOrdersQuery, {
    notifyOnNetworkStatusChange: true,
    ssr: true,
    skip: true,
  });
  const viewerId = useViewerId();

  return async (id: string) => {
    const res = await refetch({
      where: {
        AND: [
          {
            OR: [
              {
                Lister: {
                  have: {
                    id: {
                      equalTo: viewerId,
                    },
                  },
                },
              },
              {
                Requester: {
                  have: {
                    id: {
                      equalTo: viewerId,
                    },
                  },
                },
              },
            ],
          },
          {
            objectId: {
              equalTo: id,
            },
          },
        ],
      },
    });

    return res?.data?.orders?.edges?.map((o) => o.node)?.[0];
  };
};

type useGetOrderErrorT = {
  objectId?: string;
};

export const whereTransactionOrder = (objectId?: string) => {
  return {
    AND: [
      {
        type: {
          equalTo: 'order',
        },
      },
      {Order: {have: {objectId: {equalTo: objectId || ''}}}},
    ],
  } as GQLTransactionWhereInput;
};

export const useGetOrderError = (options: useGetOrderErrorT) => {
  const {data, refetch} = useQuery<GetTransactionsResponse, GetTransactionsRequest>(GetTransactions, {
    variables: {
      where: {
        AND: [
          {
            type: {
              equalTo: 'order',
            },
          },
          {Order: {have: {objectId: {equalTo: options?.objectId || ''}}}},
        ],
      },
    },
  });
  const solved = data?.transactions.edges.filter((e) => e.node.status === SubscrPaymentStatus.succeeded).length;
  const error = solved ? null : data?.transactions.edges.map((e) => e.node.data)[0] || null;
  return {error, refetch};
};

export const useCheckUserMakeOrders = (options: {userId?: string; commId?: string}) => {
  const where: GQLOrderWhereInput = {
    OR: [
      {Requester: {have: {objectId: {equalTo: options.userId}}}},
      {Lister: {have: {objectId: {equalTo: options.userId}}}},
    ],
    Community: {have: {objectId: {equalTo: options.commId}}},
  };

  const {data} = useQuery<OrdersResponseType, OrdersParams>(GetOrdersQuery, {
    variables: {
      where,
      first: 1,
    },
    ssr: true,
  });
  return !!data?.orders.count;
};

export const getEventOrders = (eventId?: string) => {
  const {data, refetch} = useQuery<OrdersResponseType>(GetShortOrdersQuery, {
    variables: {
      where: {
        AND: [
          {Event: {have: {id: {equalTo: eventId}}}},
          {status: {notIn: [GQLOrderStatuses.canceled, GQLOrderStatuses.rejected, GQLOrderStatuses.request]}},
        ],
      },
    },
    skip: !eventId,
  });

  return {data: data?.orders?.edges?.map((el) => el?.node), refetch};
};
