import React, {useMemo} from 'react';
import {MenuContainer, MenuLink, BorderLine, MenuLinkButton} from './styles';
import {useTranslation} from 'react-i18next';
import {useLinks} from '../../../../hooks/common';
import {route} from '../../../../constants/routes';
import {useSignOut} from '../../../../hooks/auth';

export const ProfileMenu = () => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {signOut} = useSignOut();
  const links = useMemo(() => {
    return [
      {label: t('common:menu.profile'), link: getLink(route.pageUser.path)},
      {label: t('common:menu.payments'), link: getLink(route.paymentsSettings.path)},
      {label: t('common:menu.otherSettings.preferences'), link: getLink(route.preferencesSettings.path)},
      {label: t('common:menu.settings'), link: getLink(route.accountSettings.path)},
    ];
  }, [getLink]);

  const logoutLabel = t('common:menu.logOut');
  const onSignOut = () => signOut();

  return (
    <MenuContainer>
      {links.map((el) => (
        <MenuLink to={el.link} key={el.link}>
          {el.label}
        </MenuLink>
      ))}
      <BorderLine />
      <MenuLinkButton onClick={onSignOut}>{logoutLabel}</MenuLinkButton>
    </MenuContainer>
  );
};
