import styled, {css, StyledProps} from 'styled-components';
import {Link as ReactLink} from 'react-router-dom';
import {fontPrimaryMedium, fontPrimaryReg, fontPrimarySemiBold, fontTertiaryReg} from '../common/styles';
import {Media, theme} from '../../ui-kit/theme';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {GridProps} from '../Account/SearchCommunity/styles';
import {MemoInfoHint} from '../common/InfoHint';
import {Label} from '../../ui-kit/Labels';
import EarnPromotion from './images/EarnPromotion.png';

const cardStyles = css`
  width: 100%;
  max-width: 100%;
  min-width: 250px;
`;

export const cardBodyStyles = css`
  ${cardStyles};
  padding: 12px 11px;
  margin-bottom: 15px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  cursor: pointer;
  transition: 0.15s all;
  width: 100%;

  &:hover {
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.08);
  }
`;

const stylesListingBtn = css`
  color: ${({theme}) => theme.palette.text.octonary};
  background: ${({theme}) => theme.palette.background.nonary};
  border: none;
  font-size: 12px;
  outline: none;
  ${fontPrimaryReg};
  text-align: center;
  padding: 6px 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  cursor: pointer;
`;

export const WrapperForm = styled.div`
  max-width: 1184px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 124px 100px 108px;
  box-sizing: border-box;
  ${Media.down.xl} {
    padding: 30px 20px 100px 20px;
    max-width: 870px;
  }
  ${Media.down.l} {
    padding: 30px 50px 60px 50px;
  }
  ${Media.down.s} {
    padding: 30px 20px 60px 20px;
  }
`;

export const ListingForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  box-sizing: border-box;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  ${Media.down.l} {
    padding-right: 0;
  }
  ${Media.down.xs} {
    width: 100%;
  }
`;

export const InnerItems = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const InnerItemsGenerate = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .earn > path:nth-child(1) {
    stroke: white;
    fill: white;
  }
  .earn > path:nth-child(2) {
    stroke: ${theme.palette.common.greenLight3};
    fill: ${theme.palette.common.greenLight3};
    stroke-width: 0;
  }

  .react-dropdown-select-no-data {
    display: none;
  }

  .react-dropdown-select-dropdown-add-new {
    display: none;
  }

  .stars {
    margin-left: -20px;
  }
`;

export const CloseButton = styled.div`
  position: fixed;
  width: 34px;
  height: 34px;
  right: 24px;
  cursor: pointer;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  ${Media.up.s} {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  ${Media.down.m} {
    height: 100%;
    max-height: 210px;
    .image,
    img {
      object-fit: cover;
      height: 100%;
      max-height: 210px;
    }
  }
  .loading {
    animation: loading 1.5s infinite linear;
  }

  @keyframes loading {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const DeleteImageButton = styled(CloseButton)`
  position: absolute;
  width: 28px;
  height: 28px;
  left: 14px;
  top: 10px;
  z-index: 50;
  ${Media.up.s} {
    display: flex;
  }
`;

export const NextImageButton = styled(DeleteImageButton)`
  right: 14px;
  left: revert;
`;

export const AIPromotionWrapper = styled.div`
  margin-top: 8px;
  max-height: 85px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 6px;
  background: linear-gradient(180deg, #0097b8 0%, #06dbc1 100%);
  background-image: ${`url(${EarnPromotion})`};
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  flex-direction: row;
  cursor: pointer;
`;

export const TextWrapperPromotion = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 4px;
`;

export const getTextColor = ({theme, variantText}: StyledProps<{variantText?: string}>) => {
  return variantText && theme.palette.text[variantText];
};

export const Title = styled.h1.attrs({textColor: 'quaternary'})<{textColor: string; $hideOnMob?: boolean}>`
  ${fontPrimaryReg};
  font-size: 26px;
  line-height: 150%;
  color: ${getTextColor};
  margin: 0 0 15px 0;
  ${Media.down.xs} {
    font-size: 16px;
  }
  ${Media.down.m} {
    ${({$hideOnMob}) => $hideOnMob && ` opacity: 0;`};
  }
`;

export const SubTitle = styled(Title).attrs({textColor: 'octonary'})<{
  variantText?: string;
  offsetBottom?: number;
}>`
  font-size: 18px;
  color: ${({theme}) => theme.palette.text.black};
  margin-bottom: ${({offsetBottom}) => `${offsetBottom || 0}px`};
  ${Media.down.m} {
    font-size: 18px;
  }
`;
export const ListingCardTitle = styled(SubTitle)<{$isPost?: boolean; $unsetMaxWidth?: boolean}>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  max-width: ${({$isPost}) => ($isPost ? '150px' : '175px')};
  font-weight: 500;
  ${Media.down.m} {
    max-width: ${({$isPost}) => ($isPost ? '230px' : '290px')};
    font-size: 16px;
  }
  ${Media.down.xs} {
    max-width: ${({$isPost}) => ($isPost ? '180px' : '236px')};
  }
  @media screen and (max-width: 380px) {
    max-width: ${({$isPost}) => ($isPost ? '189px' : '170px')};
  }
  ${Media.down.xxs} {
    max-width: 140px;
    font-size: 15px;
  }
  ${({$unsetMaxWidth}) => $unsetMaxWidth && `max-width: 230px!important;`};
  ${Media.down.m} {
    ${({$unsetMaxWidth}) => $unsetMaxWidth && `max-width: 320px!important;`};
  }
  ${Media.down.xs} {
    ${({$unsetMaxWidth}) => $unsetMaxWidth && `max-width: 260px!important;`};
  }
  ${Media.down.xxs} {
    ${({$unsetMaxWidth}) => $unsetMaxWidth && `max-width: 220px!important;`};
  }
`;

export const InnerItemsForm = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: 20px;
  }
  & > * {
    width: 50%;
  }
  ${Media.down.s} {
    & > *:first-child {
      margin-right: 7px;
    }
  }
`;

export const InnerItemsGrid = styled.div<{$smallDD?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 26px;
  ${Media.down.l} {
    ${({$smallDD}) =>
      $smallDD &&
      `
    .react-dropdown-select-dropdown {
      max-height: 110px;
    }
    `}
  }
`;

export const InnerAside = styled.div`
  z-index: ${({theme}) => theme.zIndex._1};
  position: absolute;
  top: 0;
  right: 0;
  width: 456px;
  height: 100%;
  ${Media.down.xl} {
    width: 420px;
  }
`;

export const InnerEditAside = styled(InnerAside)<{width?: string; $inFlow?: boolean}>`
  width: ${({width}) => width || '50%'};
  &.listing-view__form-aside {
    ${Media.down.l} {
      margin-top: 10px;
      width: 100%;
      position: static;
    }
  }
  &.listing-view__form-aside .form-aside__wrapper {
    ${Media.down.l} {
      width: 100%;
      position: static;
      & > div {
        display: flex;
        max-width: 100%;
        h1 {
          font-size: 15px;
        }
      }
    }
    ${Media.down.s} {
      & > div {
        flex-direction: column;
      }
    }
  }
  &.listing-view__form-aside .aside__actions-block {
    ${Media.down.l} {
      min-width: 200px;
    }
  }
  ${({$inFlow}) => $inFlow && ' position: static;'};
`;

export const Aside = styled.aside.attrs({className: 'form-aside__wrapper'})`
  background: ${({theme}) => theme.palette.background.primary};
  position: fixed;
  width: 455px;
  max-width: inherit;
  ${Media.down.xl} {
    width: 420px;
  }
`;

export const AsideEdit = styled(Aside)`
  width: 350px;
  background-color: transparent;
`;

export const AsideRequests = styled(AsideEdit)<{$inFlow?: boolean}>`
  position: ${({$inFlow}) => ($inFlow ? 'static' : 'absolute')};
`;

export const InnerTips = styled.div`
  box-sizing: border-box;
  margin-top: 18px;
  margin-bottom: 19px;
  padding: 21px 30px 21px 70px;
  position: relative;
  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  border-radius: 12px;
`;

export const IconTips = styled(IconSvg)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`;

export const TipsTitle = styled.div`
  margin: 0;
  padding: 0;
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 12px;
  line-height: 150%;
`;

export const InnerDesrPhotos = styled(TipsTitle)`
  ${fontPrimaryReg};
  margin-top: 5px;
  text-align: right;
`;

export const TipsDescr = styled(TipsTitle)`
  ${fontPrimaryReg};
  margin-top: 5px;
`;

export const InnerPreview = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  & > *:first-child {
    margin-right: 20px;
  }
`;

export const InnerLabelPreview = styled.div`
  width: calc(100% - 158px);
`;

export const InnerPhotoCardPreview = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const InnerDescrPreview = styled.div`
  box-sizing: border-box;
  padding: 16px 20px 20px 20px;
  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  border-radius: 12px;
  max-width: 138px;
`;

export const LabelPhoto = styled.label<{
  isUndercard?: boolean;
  typeCursor?: string;
  width?: string;
  height?: string;
  bordered?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || 'auto'};
  cursor: ${({typeCursor}) => typeCursor || 'inherit'};
  opacity: ${({isUndercard}) => (isUndercard ? 0.7 : 1)};
  background-color: ${({isUndercard, theme}) =>
    isUndercard ? theme.palette.background.secondary : theme.palette.background.primary};
`;

export const InnerDataPreview = styled.div`
  margin-top: 2px;
  position: relative;
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export const InnerRewardData = styled(TipsTitle)`
  font-size: 12px;
  font-weight: 400;
`;

export const InnerPrice = styled(TipsTitle)`
  font-size: 12px;
  margin: 0 3px;
`;

export const CenterInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const minWidth = ({minWidth}: GridProps) => (minWidth ?? 138) + 'px';
// const maxWidth = ({maxWidth}: GridProps) => (maxWidth ?? 138) + 'px' || '1fr';
const columnGap = ({columnGap}: GridProps) => (columnGap ? columnGap : 14) + 'px';
const rowGap = ({rowGap}: GridProps) => (rowGap ? rowGap : 22) + 'px';

export const PhotoGrid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-row-gap: ${rowGap};
  grid-column-gap: ${columnGap};
  grid-template-columns: auto auto auto;
  ${Media.down.m} {
    grid-row-gap: 10px;
  }
`;

export const InnerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;

export const ListCardsGrid = styled(PhotoGrid)`
  grid-template-columns: repeat(auto-fill, minmax(${minWidth}, 1fr));
  ${Media.down.xl} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  & > * {
    max-width: 400px;
  }
  ${Media.down.m} {
    grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
    & > div {
      max-width: 100%;
    }
  }
  ${Media.down.xxs} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    & > * {
      max-width: 100%;
    }
  }
`;

export const Link = styled(ReactLink)`
  font-size: 12px;
  line-height: 150%;
  ${fontTertiaryReg};
  color: ${({theme}) => theme.palette.text.quinary};
  text-decoration: underline;
`;

export const ViewAllBtn = styled.button`
  font-size: 12px;
  line-height: 150%;
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const InnerAllListings = styled.div``;

export const WrapperMyListings = styled.div`
  overflow-x: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  scrollbar-color: ${({theme}) => theme.palette.background.tertiary} ${({theme}) => theme.palette.background.denary};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 24px;
    height: 8px;
    background-color: ${({theme}) => theme.palette.background.denary};
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.background.tertiary};
    border-radius: 9em;
    box-shadow: inset 1px 1px 10px #f3faf7;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.background.octonary};
  }

  &::-webkit-scrollbar-button:horizontal:start:decrement {
    background: linear-gradient(30deg, ${({theme}) => theme.palette.background.octonary} 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(150deg, ${({theme}) => theme.palette.background.octonary} 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(270deg, ${({theme}) => theme.palette.background.octonary} 30%, rgba(0, 0, 0, 0) 31%);
    background-color: ${({theme}) => theme.palette.background.secondary};
  }

  &::-webkit-scrollbar-button:horizontal:end:increment {
    background: linear-gradient(210deg, ${({theme}) => theme.palette.background.octonary} 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(330deg, ${({theme}) => theme.palette.background.octonary} 40%, rgba(0, 0, 0, 0) 41%),
      linear-gradient(90deg, ${({theme}) => theme.palette.background.octonary} 30%, rgba(0, 0, 0, 0) 31%);
    background-color: ${({theme}) => theme.palette.background.secondary};
  }
`;

export const InnerMyListings = styled.div`
  width: 100%;
`;

export const ContainerListingCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
`;

export const InnerListingCard = styled.div<{isOpacity?: boolean}>`
  position: relative;
  opacity: ${({isOpacity}) => (isOpacity ? 0.9 : 1)};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  padding: 9px 11px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 77px;
`;

export const UnPublishedLabel = styled.div`
  position: absolute;
  top: 3px;
  right: 6px;
  text-align: center;
  background: ${({theme}) => theme.palette.background.octonary};
  border: 1px solid ${({theme}) => theme.palette.background.octonary};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  padding: 2px 8px;
  color: ${({theme}) => theme.palette.text.octodecimal};
  ${fontPrimaryReg};
  z-index: 1;
  opacity: 1;
`;

export const SoldLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 14px;
  text-align: center;
  background: ${({theme}) => theme.palette.common.blueDark2};
  border: 1px solid ${({theme}) => theme.palette.grayscale._10};
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 12px;
  padding: 2px 14px;
  color: ${({theme}) => theme.palette.text.octodecimal};
  ${fontPrimaryReg};
  z-index: 1;
  text-transform: uppercase;
`;

export const IconStar = styled(IconSvg)`
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 100;
`;

export const IconCoffee = styled(IconSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

export const InnerLabelCard = styled.div`
  margin-top: 4px;
  margin-left: 8px;
`;

export const ListerInfo = styled.div`
  display: flex;
  align-items: center;
  span {
    white-space: pre;
    font-size: 11px;
    color: ${({theme}) => theme.palette.text.octonary};
  }
  svg {
    margin-right: 1px;
    margin-left: 3px;
  }
`;

export const InnerLabelItemTitle = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-right: 29px;
`;

export const InnnerListingCardBtns = styled.div`
  position: absolute;
  bottom: 2px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    transform: rotate(90deg);
  }
  & > *:last-child {
    margin-left: 6px;
  }
`;

export const LikeButton = styled.button`
  display: flex;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;
export const ShapeWrapper = styled.div`
  display: block;
  width: 18px;
  height: 18px;
`;

export const ListingCardLink = styled(ReactLink)`
  ${stylesListingBtn}
`;

export const ListingCardBtn = styled.button`
  ${stylesListingBtn}
`;

export const WrapperEditFormAside = styled.div`
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 70px;

  ${Media.down.m} {
    margin-top: 0;

    & .aside__actions-block {
      order: 1;
    }
  }
`;

export const InnerRequestCard = styled.div<{$isPreview?: boolean}>`
  margin-top: 15px;
  width: 100%;
  ${({$isPreview}) => $isPreview && ` pointer-events: none;`}
`;

export const InnerInsights = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  & > *:nth-child(2n) {
    margin-left: 10px;
  }
`;

export const CardInsight = styled.div`
  box-sizing: border-box;
  padding: 12px 7px 12px 20px;
  margin-bottom: 10px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  width: 47%;
  flex-grow: 1;
  height: 100%;
  background: ${({theme}) => theme.palette.background.primary};
`;

export const TitleInsight = styled.div`
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 12px;
  line-height: 150%;
  ${fontPrimarySemiBold};
`;

export const ValueInsight = styled.div`
  font-size: 20px;
  ${fontPrimaryReg};
`;

export const InnerActions = styled(InnerInsights)`
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const ActionBtnStyles = css`
  padding: 8px 20px;
  box-sizing: border-box;
  background: ${({theme}) => theme.palette.grayscale._3};
  font-size: 14px;
  line-height: 150%;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.octonary};
  border-radius: 34px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
  border: none;
  display: flex;
  align-items: center;
  & svg {
    margin-left: 6px;
  }
`;

export const ActionButton = styled.button`
  ${ActionBtnStyles};
`;

export const ActionLink = styled(ReactLink)`
  ${ActionBtnStyles};
`;

export const WrapperPageListings = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  padding: 30px 15px 40px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > :first-child {
    padding-bottom: 10px;
  }
  ${Media.down.l} {
    padding-top: 36px;
  }
`;

export const WrapperSearch = styled.div`
  width: 100%;

  & > * {
    z-index: 200;
  }

  ${Media.down.s} {
    display: block;
    margin: -35px 0 -15px;
  }
`;

//view
export const ListCardsFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 14px;
  }
  & > * {
    min-width: 300px;
    width: calc(100% / 3 - 28px);
  }

  ${Media.down.xs} {
    & > * {
      min-width: 290px;
    }
  }

  & > div > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MainContListingView = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const MobileListingWrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 35px;
`;

export const WrapperListingView = styled.div<{offsetTop?: string; $isReviews?: boolean}>`
  max-width: 953px;
  width: 100%;
  margin: 0 auto;
  padding: ${({offsetTop}) => offsetTop || '8px'} 20px 8px 20px;
  box-sizing: border-box;
  ${Media.down.m} {
    padding: ${({offsetTop}) => offsetTop || '8px'} 10px 8px 10px;
    ${({$isReviews, offsetTop}) => $isReviews && `padding: ${offsetTop || '8px'} 20px 8px 20px`};
  }
`;

export const ListingViewTitle = styled.div`
  ${fontPrimaryMedium};
  font-size: 26px;
  line-height: 122%;
  color: ${({theme}) => theme.palette.text.quaternary};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListingViewSubTitle = styled(ListingViewTitle)`
  font-size: 20px;
  ${Media.down.s} {
    font-size: 14px;
    padding-bottom: 4px;
    color: ${({theme}) => theme.palette.text.duodecimal};
  }
`;

export const InnerTitleReviewers = styled.div`
  padding-bottom: 30px;
  box-sizing: border-box;
`;

export const ListingViewDescr = styled(ListingViewTitle)`
  word-break: break-all;
  font-size: 16px;
  line-height: 24px;
  ${fontPrimaryReg};

  & > div {
    display: flex;
    flex-direction: column;
  }

  & p {
    margin: 0;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const InnerListingView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 330px;
  box-sizing: border-box;

  ${Media.down.l} {
    padding-right: 0;
  }

  ${Media.down.xs} {
    width: 100%;
  }
`;

export const InnerInfoTags = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoListing = styled.span`
  color: ${({theme}) => theme.palette.text.septenary};
  ${fontPrimarySemiBold};
  font-size: 12px;
  line-height: 150%;
`;

export const InnerWithDelimiter = styled(InfoListing)`
  position: relative;
  display: inline-block;
  padding-right: 17px;
  &::after {
    position: absolute;
    display: inline-block;
    content: '.';
    top: 30%;
    right: 6px;
    transform: translateY(-50%);
    font-size: 16px;
  }
`;

export const InnerInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 10px 0;
`;

//ReviewerCard

export const InnerReviewerCard = styled.div`
  position: relative;
  padding: 40px 0 30px 110px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  &:last-child {
    border-bottom: none;
  }
`;

export const InnerReviewerAvatar = styled.div`
  position: absolute;
  padding-top: 40px;
  box-sizing: border-box;
  left: 0;
  top: 0;
`;

export const ReviewerName = styled.div`
  ${fontPrimaryReg};
  font-size: 18px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.quindecimal};
  margin-top: 10px;
`;

export const ReviewerDescr = styled(ReviewerName)`
  font-size: 16px;
  margin-top: 5px;
  color: ${({theme}) => theme.palette.text.quaternary};
`;

export const ItemsDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: unset;
  background-color: ${({theme}) => theme.palette.background.secondary};
`;

export const InnerSearchWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
`;

export const ListingTypeSelectWrapper = styled.div`
  width: 100%;

  ${Media.up.xl} {
    & > div > div > div:last-child {
      margin-left: auto;
    }
  }
`;

// Mobile search

export const Hidden = styled.div`
  ${Media.down.s} {
    display: none;
  }
`;

export const MobileSearchWrapper = styled.div`
  position: fixed;
  inset: 0;
  height: max-content;
  z-index: 301;

  & * {
    box-sizing: border-box;
  }

  ${Media.up.s} {
    display: none;
  }
`;

export const InnerMobileSearchWrapper = styled.div`
  & * {
    box-sizing: border-box;
  }

  ${Media.up.s} {
    display: none;
  }
`;

export const SearchHeaderWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  width: 100%;
  min-height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  padding: 13px 18px;
`;

export const MobileSearchContent = styled.div`
  background-image: ${({theme}) =>
    `linear-gradient(180deg, ${theme.palette.background.primary} 0%, ${theme.palette.background.primary} 30%, transparent 100%)`};
  height: calc(100vh - 86px);
  z-index: 10;
`;

export const MobileSearchInputs = styled.div`
  display: grid;
  grid-auto-flow: row;
  max-height: 120px;
  grid-row-gap: 6px;
  padding: 10px 20px;
`;

export const MobileSelectWrapper = styled(ListingTypeSelectWrapper)`
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;

  & > div > div {
    flex-wrap: nowrap;
  }
`;

export const SearchHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddPlusBtn = styled(Link)`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.palette.common.aqua};
  display: none;
  position: fixed;
  right: 12px;
  bottom: 100px;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 1px 0 9px rgba(0, 0, 0, 0.5);

  &::after {
    content: '';
    display: block;
    height: 0;
    width: 24px;
    border-top: 1px solid ${({theme}) => theme.palette.grayscale._0};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    content: '';
    display: block;
    height: 24px;
    width: 0;
    border-right: 1px solid ${({theme}) => theme.palette.grayscale._0};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${Media.down.s} {
    display: flex;
  }
`;

// listing created

export const ListingCreatedWrapper = styled.div`
  width: 100%;
  padding: min(55px, 5vw) min(110px, 8vw);
  box-sizing: border-box;
  padding-top: 65px;
`;

export const HeaderMobileWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 300;
`;

export const ListingCreatedContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  box-sizing: border-box;
  ${Media.down.m} {
    gap: 12px;
  }
`;

export const ListingCreatedTitle = styled(Text).attrs({
  variant: 'primary',
  color: 'quaternary',
  weight: 'semiBold',
})`
  font-size: 36px;
  ${Media.down.m} {
    display: none;
  }
`;

export const ListingCreatedSubtitle = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
})`
  font-weight: 400;
  font-size: 14px;
`;

export const ListingCreatedButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ListingCreatedCardWrapper = styled.div`
  width: 100%;
`;

export const CommunityCardLink = styled(ReactLink)<{$isCheckboxMode?: boolean}>`
  ${cardBodyStyles};

  margin: 0;
  position: relative;
  ${({$isCheckboxMode}) => $isCheckboxMode && 'pointer-events: none;'}

  & div > div > div {
    color: ${({theme}) => theme.palette.text.duodecimal};
  }
`;

export const CommunityPendingLabelWrapper = styled.div`
  //position: absolute;
  //right: 0;
  //top: 0;
  display: inline-block;
  margin-right: 5px;
`;

export const MenuButtonWrapper = styled.div<{$isCardInPost: boolean}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  pointer-events: all;

  & > div {
    top: 10px;
  }

  & button {
    padding: 0 10px;
  }

  .menu__items {
    top: ${({$isCardInPost}) => ($isCardInPost ? '-5px' : 'inherit')};
  }
`;

export const HiddenMenu = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const SoldItemInfoWrapper = styled.div`
  margin-top: 15px;
  padding: 25px 31px 27px;
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
`;

export const SmallSoldLabel = styled.div`
  text-align: center;
  background: ${({theme}) => theme.palette.grayscale._10};
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 10px;
  line-height: 15px;
  padding: 1px 6px;
  color: ${({theme}) => theme.palette.text.primary};
  ${fontPrimaryReg};
  display: inline-block;
`;

export const SoldItemLabelsWrapper = styled.div`
  margin-top: 4px;
  & > *:first-child {
    margin-right: 9px;
  }
`;

export const DesktopView = styled.div`
  display: block;
  ${Media.down.m} {
    display: none;
  }

  .pswp__img {
    object-fit: contain !important;
  }
`;

export const MobileView = styled.div`
  display: none;
  ${Media.down.m} {
    display: block;
  }

  .pswp__img {
    object-fit: contain !important;
  }
`;

export const MobFocused = styled.div<{$isDesktop: boolean}>`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
  .rt-editor {
    padding-right: ${({$isDesktop}) => ($isDesktop ? '38px' : null)} !important;
  }

  ${Media.down.m} {
    & .rt-editor .DraftEditor-root {
      height: 100% !important;
      & .public-DraftEditor-content > div {
        height: 100%;
      }
    }
  }
`;

export const PageLoaderWrapper = styled.div`
  display: block;
  position: relative;
  & .page-loader__overlay {
    position: absolute;
  }
`;

export const PlusIcon = styled(IconSvg)`
  margin-right: 4px;
`;

export const AddListingBtn = styled(LinkButton)`
  .button-value {
    display: flex;
    align-items: center;
  }
`;

export const FullBlock = styled.div`
  width: 100%;
  & > div {
    padding: 2px 0;
  }
`;

export const CommsList = styled.div`
  margin-top: 6px;
  display: none;
  ${Media.down.l} {
    display: block;
  }
`;
export const CommItem = styled(ReactLink)<{$selected?: boolean}>`
  display: flex;
  align-items: center;
  margin-left: 0 !important;
  span {
    ${fontPrimaryReg};
    color: ${({theme, $selected}) => ($selected ? theme.palette.grayscale._6 : theme.palette.grayscale._13)};
  }
  & > div {
    ${({$selected}) => !$selected && ` padding-left: 2px;`};
  }
`;
export const SelectedIconCont = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  jusstify-content: center;
  margin-right: 6px;
  box-sizing: border-box;
`;

export const SelectLabel = styled.div`
  display: flex;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 15px;
  cursor: text;
  ${({theme}) => theme.font.primary.medium};
  position: relative;
  margin-bottom: 2px !important;
`;
export const LabelHint = styled(MemoInfoHint)`
  margin-left: 10px;
`;

export const TopWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FeatureLabel = styled(Label)`
  font-size: 9px !important;
  padding: 1px 7px !important;
  height: 14px !important;
  margin-left: 3px;
`;
