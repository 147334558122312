import React, {useEffect, useState} from 'react';
import {
  ContentWrapper,
  ErrorMessage,
  InputActionText,
  InputActionWrapper,
  InputWrapper,
  PhoneInputWrapper,
  Title,
  Wrapper,
} from './styles';
import {Text} from '../../../ui-kit/Typography/Text';
import {Input} from '../../../ui-kit/Form/Input';
import {Button} from '../../../ui-kit/Button/Button';
import PhoneInput from 'react-phone-number-input';
import {StyledInput} from '../../../ui-kit/Form/Input/styles';
import './style.css';

interface TProps {
  title: string;
  subtitle: string;
  inputLabel: string;
  inputActionText: string;
  phone?: boolean;
  errorMessage?: string;
  value: string;
  onChange: (value: string) => void;
  buttonText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  inputActionHandler: (e?: React.SyntheticEvent) => void;
  loading?: boolean;
  onCancel?: () => void;
}

export const VerifyBase: React.FC<TProps> = ({
  title,
  subtitle,
  phone,
  value,
  onChange,
  onClick,
  inputLabel,
  inputActionText,
  errorMessage,
  buttonText,
  children,
  loading,
  //onCancel,
}) => {
  const [baseValue, setBaseValue] = useState<string>('');
  const [country, setCountry] = useState<string | undefined>('');
  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  const onCountryChange = (s?: string) => {
    setCountry(s);
  };
  const onReset = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    onChange(baseValue);
  };
  useEffect(() => {
    value === '+1' ? setBaseValue('') : setBaseValue(value);
  }, [country]);

  const showReset = Number(value?.length) > Number(baseValue?.length);
  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{title}</Title>
      </ContentWrapper>
      <ContentWrapper>
        <Text size={16} variant={'primary'} color={'octonary'}>
          {subtitle}
        </Text>
      </ContentWrapper>
      {/*onCancel && (
        <BackIconWrapper onClick={onCancel}>
          <IconSvg type={'right'} width={'24'} height={'24'} />
        </BackIconWrapper>
      )*/}
      <form>
        <InputWrapper>
          <InputWrapper>
            {phone && (
              <PhoneInputWrapper>
                <PhoneInput
                  value={value}
                  onChange={onChange}
                  placeholder={inputLabel}
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry={'CA'}
                  inputComponent={StyledInput}
                  onCountryChange={onCountryChange}
                />
              </PhoneInputWrapper>
            )}
            <Input value={value} onChange={handleChangeCode} label={inputLabel} type={'text'} />
            {showReset && (
              <InputActionWrapper onClick={onReset}>
                <InputActionText>{inputActionText}</InputActionText>
              </InputActionWrapper>
            )}
          </InputWrapper>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </InputWrapper>
        <ContentWrapper>
          <Button variant={'primary'} type={'submit'} onClick={onClick} loading={loading}>
            {buttonText}
          </Button>
        </ContentWrapper>
      </form>
      {children}
    </Wrapper>
  );
};
