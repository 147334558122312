import React from 'react';
import {CommunityStateType} from '../../states/community';
import {User} from '../../queries/types/user';
import {TypeCommunity} from '../../types/auth';
import {useGetDocuments} from '../../hooks/document';
import {LoopCommunity} from '../../components/Loop';
import {NavContainer} from '../Navigation';
import {EmptyTag} from '../MobileTabs';
import {PermissionsList} from '../../types/settings';

export interface LoopResidentContainerProps {
  viewer: User | null;
  community?: CommunityStateType;
  loading: boolean;
  viewerId?: string;
  typeUser: TypeCommunity;
  hideNav?: boolean;
  permissions?: PermissionsList[];
}

const LoopResidentContainer: React.FC<LoopResidentContainerProps> = ({
  viewer,
  community,
  loading,
  viewerId,
  typeUser,
  hideNav,
  permissions,
}) => {
  const {count: documentsCount} = useGetDocuments();
  const Wrapper = hideNav ? EmptyTag : NavContainer;

  return (
    <Wrapper>
      <LoopCommunity
        typeUser={typeUser}
        user={viewer}
        community={community ?? {}}
        loading={loading}
        viewerId={viewerId}
        documentsCount={documentsCount}
        permissions={permissions}
      />
    </Wrapper>
  );
};

export default LoopResidentContainer;
