import {gql} from '@apollo/client';
import {LocationFragment} from './locations';
import PaymentPlanFragment from './paymentPlan';
import MemberFragment from './people';
import {AdminFragment} from './people';

export default gql`
  fragment CommunityFragment on Community {
    id
    objectId
    createdAt
    updatedAt
    __typename
    allowChat
    allowEvents
    allowPosts
    Avatar {
      file {
        name
        url
      }
    }
    Owner {
      ...MemberFragment
    }
    name
    descr
    identityData
    passCode
    alias
    address
    address_add {
      ... on Element {
        value
      }
    }
    zip_add {
      ... on Element {
        value
      }
    }
    units
    buildings
    Location {
      ...LocationFragment
    }
    zip
    accessType
    type
    listingApproval
    invitation
    isVerified
    Subscr {
      id
      objectId
      PaymentPlan {
        ...PaymentPlanFragment
      }
      paymentPeriod
    }
    countMembers
    Residents {
      ... on UserConnection {
        edges {
          node {
            ...MemberFragment
          }
        }
      }
    }
    Admins {
      ... on CommunityAdminConnection {
        count
        edges {
          node {
            ...AdminFragment
          }
        }
      }
    }
    checkList
    options
  }
  ${MemberFragment}
  ${PaymentPlanFragment}
  ${LocationFragment}
  ${AdminFragment}
`;

export const CommunityShortFragment = gql`
  fragment CommunityShortFragment on Community {
    id
    objectId
    createdAt
    updatedAt
    __typename
    Avatar {
      file {
        name
        url
      }
    }
    Location {
      ...LocationFragment
    }
    Owner {
      id
      objectId
    }
    name
    descr
    alias
    passCode
    address
    identityData
    type
    address_add {
      ... on Element {
        value
      }
    }
    zip_add {
      ... on Element {
        value
      }
    }

    units
    buildings
    zip
    accessType
    listingApproval
    invitation
    isVerified
    countMembers
    checkList
    allowPosts
    allowChat
    allowEvents
    options
    Subscr {
      id
      objectId
      PaymentPlan {
        ...PaymentPlanFragment
      }
      paymentPeriod
    }
    Residents {
      ... on UserConnection {
        edges {
          node {
            id
            objectId
            firstName
            firstLetter
            email
            phone
          }
        }
      }
    }
    Admins {
      ... on CommunityAdminConnection {
        count
        edges {
          node {
            id
            objectId
            User {
              id
              objectId
              firstName
              firstLetter
              email
              phone
            }
          }
        }
      }
    }
  }
  ${PaymentPlanFragment}
  ${LocationFragment}
`;

export const CommunitySmallFragment = gql`
  fragment CommunitySmallFragment on Community {
    id
    objectId
    alias
    type
    name
    descr
    passCode
    countMembers
    Subscr {
      id
      objectId
      PaymentPlan {
        ...PaymentPlanFragment
      }
      paymentPeriod
    }
    Avatar {
      file {
        name
        url
      }
    }
    Owner {
      id
      objectId
    }
    Admins {
      edges {
        node {
          User {
            objectId
            id
          }
          id
          objectId
        }
      }
    }
  }
  ${PaymentPlanFragment}
`;
export const CommunityMicroFragment = gql`
  fragment CommunityMicroFragment on Community {
    id
    objectId
    alias
    type
    name
    descr
    address
    zip
    countMembers
    Location {
      ...LocationFragment
    }
    options
    Avatar {
      file {
        name
        url
      }
    }
    identityData
  }
  ${LocationFragment}
`;
