import {
  AmenityFields,
  AmenityFormValue,
  AmenityFormValues,
  AmenityOpenStatus,
  BookingPeriod,
  BookingTimingField,
  BookingTimingT,
  TableAmenityItem,
  TAmenity,
} from '../types/amenity';
import {optionsI18n} from '../libs/i18nextUtils';
import {useGetLanguage} from '../ui-kit/utils/language';
import {Amenity} from '../queries/types/amenity';
import {TagType} from '../ui-kit/RequestCard/types';
import {PointerFile, SelectOption} from '../types/common';
import {MenuItemType} from '../ui-kit/Menu/types';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {variant} from '../ui-kit/Labels/types';
import {format} from 'date-fns';
import {Namespace, TFunction} from 'react-i18next';
import {RewardType} from '../queries/types/event';
import {Order, StatusOrderType} from '../queries/types/order';
import {ItemType} from '../ui-kit/Table/types';
import {TResManageOrder} from '../hooks/order';

export const toPointerCreateAmenity = (
  amenity: Partial<AmenityFormValues>,
  viewerId: string,
  parseImages?: (Partial<PointerFile> | undefined)[],
) => {
  const {...amenityData} = amenity;

  return {
    name: amenityData.name,
    descr: amenityData.descr,
    isBookable: amenityData.isBookable,
    rewardType: amenityData.rewardType,
    maxResidentsPerBooking: amenityData.maxResidentsPerBooking ? Number(amenityData.maxResidentsPerBooking) : 0,
    maxBookingsPerDay: amenityData.maxBookingsPerDay ? Number(amenityData.maxBookingsPerDay) : 0,
    autoBooking: amenityData.autoBooking,
    maxBookingPerSlot: amenityData.maxBookingPerSlot ? Number(amenityData.maxBookingPerSlot) : 0,
    allowGuests: amenityData.allowGuests,
    bookingTiming: amenityData.bookingTiming,
    bookingPeriod: amenityData.bookingPeriod || BookingPeriod.none,
    bookingSlots: amenityData.bookingSlots || '0',
    images: parseImages,
    Lister: {link: viewerId},
  };
};

type TEditAmenity = Omit<Partial<AmenityFormValues>, 'images'> & {
  images?: (Partial<PointerFile> | undefined)[];
};
export const toPointerEditAmenity = (amenity: Partial<TEditAmenity>) => {
  return {
    name: amenity.name,
    descr: amenity.descr,
    isBookable: amenity.isBookable,
    rewardType: amenity.rewardType,
    maxResidentsPerBooking: amenity.maxResidentsPerBooking ? Number(amenity.maxResidentsPerBooking) : 0,
    maxBookingsPerDay: amenity.maxBookingsPerDay ? Number(amenity.maxBookingsPerDay) : 0,
    autoBooking: amenity.autoBooking,
    maxBookingPerSlot: amenity.maxBookingPerSlot ? Number(amenity.maxBookingPerSlot) : 0,
    allowGuests: amenity.allowGuests,
    bookingTiming: amenity.bookingTiming,
    bookingPeriod: amenity.bookingPeriod || BookingPeriod.none,
    bookingSlots: amenity.bookingSlots || '0',
    images: amenity.images,
  };
};

// export type timeOptionValueT = {hours: number, minutes: number}

export const createTimeOptions = () => {
  const getLang = useGetLanguage();

  const {interpolation} = optionsI18n;
  const timePointsArray = [];
  for (let i = 1; i <= 24; i++) {
    const date1 = new Date();
    const date2 = new Date();
    date1.setHours(i);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);

    date2.setHours(i);
    date2.setMinutes(30);
    date2.setSeconds(0);
    date2.setMilliseconds(0);

    timePointsArray.push({
      value: interpolation.format(date1, 'p'),
      label: interpolation.format(date1, 'p', getLang()),
      key: String(date1.getTime()),
    });

    timePointsArray.push({
      value: interpolation.format(date2, 'p'),
      label: interpolation.format(date2, 'p', getLang()),
      key: String(date2.getTime()),
    });
  }
  return timePointsArray;
};

export const createTimeFromTillOptions = (type: BookingTimingField, openFrom?: string, openTill?: string) => {
  const getLang = useGetLanguage();
  const {interpolation} = optionsI18n;
  const timePointsArray = [];
  const {hours: baseFrom, minutes: fMinutes} = getTimeParts(openFrom);
  const {hours: till, minutes: tMinutes} = getTimeParts(openTill);

  const baseTill = till !== undefined ? till + 1 : till;
  let startFromMinutes = fMinutes;
  const startTillMinutes = tMinutes;
  const start = baseFrom || 1;
  const end = baseTill || 24;

  for (let i = start; i <= (baseTill || 24); i++) {
    const date1 = new Date();
    const date2 = new Date();
    date1.setHours(i);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);

    date2.setHours(i);
    date2.setMinutes(30);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    if (!startFromMinutes) {
      if (i === end && startTillMinutes) {
      } else
        timePointsArray.push({
          value: interpolation.format(date1, 'p'),
          label: interpolation.format(date1, 'p', getLang()),
          key: String(date1.getTime()),
        });
    } else {
      startFromMinutes = 0;
    }
    if (i === end && startTillMinutes) {
    } else
      timePointsArray.push({
        value: interpolation.format(date2, 'p'),
        label: interpolation.format(date2, 'p', getLang()),
        key: String(date2.getTime()),
      });
  }
  return timePointsArray;
};

export const getTimeParts = (inputDate?: string) => {
  // const multiplier = inputDate?.toLowerCase().includes('am') ? 0 : 12;
  // const date = inputDate?.toLowerCase().replace('am', '').trim().split(':');
  //
  // const hours = Number(date?.[0]) + multiplier;
  // const minutes = Number(date?.[1]);
  // return {hours, minutes};
  return getTime12to24(inputDate);
};

export const AmenityOptions = {
  upcoming: 'upcoming',
  past: 'past',
  myAmenities: 'myAmenities',
  allAmenities: 'allAmenities',
};

export const getSearchAmenityOptions = () => [
  // {
  //   label: t('amenities:options.upcoming'),
  //   value: AmenityOptions.upcoming,
  // },
  // {
  //   label: t('amenities:options.past'),
  //   value: AmenityOptions.past,
  // },
  // {
  //   label: t('amenities:options.allAmenities'),
  //   value: AmenityOptions.allAmenities,
  // },
];

export const isAdditionalAmenityOption = (value?: string) =>
  value === AmenityOptions.myAmenities || value === AmenityOptions.allAmenities;

export const getShowAmenitiesParams = () => {
  return {};
};

export const getAmenityTypeParams = () => {
  return {};
};

export const getAmountLabelAmenity = (amenity?: Partial<TAmenity> | Amenity) => {
  return {
    label: `amenities:card.rewardType.${amenity?.rewardType}`,
    type: TagType[amenity?.rewardType as 'coffee' | 'free'],
  };
};

export const dataToEditAmenityFields = (values: Partial<TAmenity>): Partial<AmenityFormValues> => {
  return {
    ...values,
  };
};

export const checkSelectedTimeFrom = (options: SelectOption<{[BookingTimingField.from]: string}>[], time?: string) => {
  return options.filter((el) => el.value === time);
};

export const checkSelectedTimeTill = (options: SelectOption<{[BookingTimingField.till]: string}>[], time?: string) => {
  return options.filter((el) => el.value === time);
};

export interface GetMenuAmenitiesOptions {
  isPublished?: boolean;
  isOwner?: boolean;
  objectId?: string;
  editLink?: string;
  previewLink?: string;
  manageLink?: string;
}

export interface GetMenuAmenitiesFuncOptions {
  unPublishCallBack?: () => void;
  publishCallBack?: () => void;
  deleteCallBack?: () => void;
  t: (key: string) => string;
}

export const getMenuAmenities = (
  options: GetMenuAmenitiesOptions,
  functions: GetMenuAmenitiesFuncOptions,
): MenuItemType[] => {
  const {isOwner, isPublished, editLink, previewLink, manageLink} = options;
  const {t, unPublishCallBack, deleteCallBack, publishCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (isOwner && isPublished) {
    addedOptions.push({
      title: t('amenities:card.buttons.edit'),
      to: () => editLink || '/',
    });

    addedOptions.push({
      title: t('amenities:card.buttons.preview'),
      to: () => previewLink || '/',
    });

    addedOptions.push({
      title: t('amenities:card.buttons.bookings'),
      to: () => manageLink || '/',
    });

    addedOptions.push({
      render: 'line',
    });

    if (unPublishCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.unpublish'),
        onClick: () => unPublishCallBack(),
        render: 'danger',
      });
    }
    if (deleteCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.delete'),
        onClick: () => deleteCallBack(),
        render: 'danger',
      });
    }
  }
  if (isOwner && !isPublished) {
    if (publishCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.publish'),
        onClick: () => publishCallBack(),
      });
    }
    addedOptions.push({
      render: 'line',
    });
    if (deleteCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.delete'),
        onClick: () => deleteCallBack(),
        render: 'danger',
      });
    }
  }

  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuAmenities = (
  options: GetMenuAmenitiesOptions,
  functions: GetMenuAmenitiesFuncOptions,
): MobileMenuEntry[] => {
  return getMenuAmenities(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const bookingSlotsMap = {
  30: '30m',
  45: '45m',
  60: '60m',
  90: '90m',
  120: '120m',
  1: '1d',
  0.5: '0.5d',
};

export type bsMapKeyOf = keyof typeof bookingSlotsMap;

export type bookingSlotsMapkeys = '30m' | '45m' | '60m' | '90m' | '120m' | '1d' | '0.5d';
export const bookingSlotsMapReverse = {
  '30m': 30,
  '45m': 45,
  '60m': 60,
  '90m': 90,
  '120m': 120,
  '1d': 1,
  '0.5d': 0.5,
};

export const bookingSlotsMapReverseValues = Object.values(bookingSlotsMapReverse);

export const checkSlots = (slots?: string) => {
  if (slots === bookingSlotsMap['1'] || slots === bookingSlotsMap['0.5'] || !slots) return undefined;
  return bookingSlotsMapReverse[slots as bookingSlotsMapkeys];
};

export type bsMapReverseKeyOf = keyof typeof bookingSlotsMap;

export const bsDaysMap = {
  [bookingSlotsMapReverse['0.5d']]: 'halfDay',
  [bookingSlotsMapReverse['1d']]: 'fullDay',
};

export const disabledDayData = {
  [BookingTimingField.from]: undefined,
  [BookingTimingField.till]: undefined,
};

export const setBaseDaysOptions = (options: {
  from?: string;
  till?: string;
  values?: BookingTimingT;
  onChange: (next: {name: string; value?: AmenityFormValue}) => void;
}) => {
  const tillFromValue = options.from ? {from: options.from} : options.till ? {till: options.till} : {};
  const newDaysSet: BookingTimingT = {
    mon: {...options?.values?.mon, ...(options?.values?.mon?.available ? tillFromValue : {})},
    tue: {...options?.values?.tue, ...(options?.values?.tue?.available ? tillFromValue : {})},
    wed: {...options?.values?.wed, ...(options?.values?.wed?.available ? tillFromValue : {})},
    thu: {...options?.values?.thu, ...(options?.values?.thu?.available ? tillFromValue : {})},
    fri: {...options?.values?.fri, ...(options?.values?.fri?.available ? tillFromValue : {})},
    sat: {...options?.values?.sat, ...(options?.values?.sat?.available ? tillFromValue : {})},
    sun: {...options?.values?.sun, ...(options?.values?.sun?.available ? tillFromValue : {})},
  };
  options.onChange({name: AmenityFields.bookingTiming, value: newDaysSet});
};

export const resetBaseBookableOptions = (options: {
  onChange: (next: {name: string; value?: AmenityFormValue}) => void;
}) => {
  const newDaysSet: Partial<AmenityFormValues> = {
    autoBooking: undefined,
    bookingPeriod: undefined,
    bookingSlots: undefined,
    maxBookingPerSlot: undefined,
    maxResidentsPerBooking: undefined,
    allowGuests: undefined,
    maxBookingsPerDay: undefined,
  };
  options.onChange({name: AmenityFields.autoBooking, value: newDaysSet.autoBooking});
  options.onChange({name: AmenityFields.bookingPeriod, value: newDaysSet.bookingPeriod});
  options.onChange({name: AmenityFields.bookingSlots, value: newDaysSet.bookingSlots});
  options.onChange({name: AmenityFields.maxBookingPerSlot, value: newDaysSet.maxBookingPerSlot});
  options.onChange({name: AmenityFields.maxResidentsPerBooking, value: newDaysSet.maxResidentsPerBooking});
  options.onChange({name: AmenityFields.allowGuests, value: newDaysSet.allowGuests});
  options.onChange({name: AmenityFields.maxBookingsPerDay, value: newDaysSet.maxBookingsPerDay});
};

export interface GetMenuTableAmenitiesOptions {
  isManager?: boolean;
  isBooked?: boolean;
  objectId?: string;
  messageLink?: string;
  requestLink?: string;
  profileLink?: string;
}

export interface GetMenuTableAmenitiesFuncOptions {
  acceptCallBack?: (id?: string) => void;
  denyCallBack?: (id?: string) => void;
  cancelCallBack?: (id?: string) => void;
  t: (key: string) => string;
}

export const getMenuTableAmenities = (
  options: GetMenuTableAmenitiesOptions,
  functions: GetMenuTableAmenitiesFuncOptions,
): MenuItemType[] => {
  const {isManager, isBooked, objectId, messageLink, requestLink, profileLink} = options;
  const {t, acceptCallBack, denyCallBack, cancelCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (isManager && !isBooked) {
    addedOptions.push({
      title: t('amenities:table.buttons.accept'),
      onClick: () => acceptCallBack?.(objectId),
    });
  }

  addedOptions.push({
    title: t('amenities:table.buttons.message'),
    to: () => messageLink || '/',
  });

  addedOptions.push({
    title: t('amenities:table.buttons.request'),
    to: () => requestLink || '/',
  });
  addedOptions.push({
    title: t('amenities:table.buttons.profile'),
    to: () => profileLink || '/',
  });
  addedOptions.push({
    render: 'line',
  });

  if (isManager && isBooked) {
    addedOptions.push({
      title: t('amenities:table.buttons.deny'),
      onClick: () => denyCallBack?.(objectId),

      render: 'danger',
    });
  }
  if (isBooked) {
    addedOptions.push({
      title: t('amenities:table.buttons.cancel'),
      onClick: () => cancelCallBack?.(objectId),
      render: 'danger',
    });
  }

  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileTableMenuAmenities = (
  options: GetMenuTableAmenitiesOptions,
  functions: GetMenuTableAmenitiesFuncOptions,
): MobileMenuEntry[] => {
  return getMenuTableAmenities(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const statusesColor: Record<StatusOrderType, variant> = {
  [StatusOrderType.booked]: 'quaternary' as variant,
  [StatusOrderType.pending]: 'senary' as variant,
  [StatusOrderType.canceled]: 'quinary' as variant,
  [StatusOrderType.draft]: 'quinary' as variant,
  [StatusOrderType.accepted]: 'quinary' as variant,
  [StatusOrderType.rejected]: 'quinary' as variant,
  [StatusOrderType.paymentPending]: 'quinary' as variant,
  [StatusOrderType.paid]: 'quinary' as variant,
  [StatusOrderType.failed]: 'quinary' as variant,
  [StatusOrderType.performing]: 'quinary' as variant,
  [StatusOrderType.completed]: 'quinary' as variant,
  [StatusOrderType.request]: 'quinary' as variant,
  [StatusOrderType.attending]: 'quinary' as variant,
};

export const getTimesDivider = (field: BookingTimingField.from | BookingTimingField.till, timing?: BookingTimingT) => {
  if (!timing) return undefined;
  const times = Object.values(timing)
    .filter((el) => !!el[field])
    .map((el) => el[field]);
  let upperTime = times?.[0];

  for (let i = 0; i < times.length; i++) {
    const current = times?.[i];
    if (current) {
      const {hours, minutes} = getTimeParts(current);
      const {hours: uHours, minutes: uMinutes} = getTimeParts(upperTime);

      if (field === BookingTimingField.from)
        if (hours * 60 + minutes <= (uHours || 0) * 60 + (uMinutes || 0)) {
          upperTime = current;
        }

      if (field === BookingTimingField.till)
        if (hours * 60 + minutes >= (uHours || 0) * 60 + (uMinutes || 0)) {
          upperTime = current;
        }
    }
  }
  return upperTime;
};

export const getOpenedStatus = (timing?: BookingTimingT): AmenityOpenStatus => {
  const today = new Date();
  const dayName = format(today, 'iii').toLowerCase() as keyof BookingTimingT;
  const {hours: fHours, minutes: fMinutes} = getTimeParts(timing?.[dayName]?.from);
  const {hours: tHours, minutes: tMinutes} = getTimeParts(timing?.[dayName]?.till);

  const fromBorder = fHours * 60 + fMinutes;
  const tillBorder = tHours * 60 + tMinutes;
  const isOpen =
    fromBorder <= today.getHours() * 60 + today.getMinutes() && tillBorder > today.getHours() * 60 + today.getMinutes();
  if (timing?.[dayName]?.available && isOpen) return AmenityOpenStatus.open;

  return AmenityOpenStatus.closed;
};

export const getAmenityLabels = (t: TFunction<Namespace>, amenity?: Partial<TAmenity | Amenity>) => {
  const labels = [{type: 'quinary' as variant, label: t('amenities:amenity')}];

  if (amenity?.rewardType === RewardType.free)
    labels.push({type: 'quaternary' as variant, label: t('amenities:card.rewardType.free')});

  return labels;
};

export const getTime12to24 = (time12h?: string) => {
  const time = time12h?.split(' ')?.[0];
  const modifier = time12h?.split(' ')?.[1];
  const hours = time?.split(':')?.[0];
  const minutes = Number(time?.split(':')?.[1]);
  let newHours = Number(hours);
  if (hours === '12') {
    newHours = 0;
  }
  if (modifier?.toLowerCase() === 'pm') {
    newHours = parseInt(hours || '', 10) + 12;
  }

  return {hours: newHours, minutes};
};

export const filterAmenityTime = (options: {from?: string; till?: string; date: Date}) => {
  const {from, till, date} = options;
  const {hours: fHours, minutes: fMinutes} = getTime12to24(from);
  const {hours: tHours, minutes: tMinutes} = getTime12to24(till);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const calcCurrent = hours * 60 + Number(minutes);
  const calcFrom = fHours * 60 + Number(fMinutes) || 0;
  const calcTill = tHours * 60 + Number(tMinutes) || 0;

  return calcCurrent >= calcFrom && calcTill >= calcCurrent;
};

export const ordersToAmenityItems = (orders?: Order[], manage?: TResManageOrder) => {
  const items: ItemType<TableAmenityItem>[] | undefined = orders?.map((el) => ({
    time: new Date(el.startTime),
    createdAt: new Date(el.createdAt),
    unit: el.Requester.aptSuite,
    resident: el.Requester,
    status: el.status,
    updatedAt: new Date(el.updatedAt),
    duration: el.Amenity.bookingSlots,
    objectId: el.objectId || '',
    isManager: true,
    isBooked: el?.status === StatusOrderType.booked,
    manage: manage,
    orderId: el?.objectId,
  }));
  return items || [];
};

export const timeBorderFrom = (inpDate: Date) => {
  const date = new Date(inpDate);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const timeBorderTill = (inpDate: Date) => {
  const date = new Date(inpDate);
  date.setHours(24);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export interface GetMenuOverrideOptions {
  objectId?: string;
}

export interface GetMenuOverrideFuncOptions {
  editCallBack: (id?: string) => void;
  deleteCallBack: (id?: string) => void;
  t: (key: string) => string;
}

export const getMenuOverride = (
  options: GetMenuOverrideOptions,
  functions: GetMenuOverrideFuncOptions,
): MenuItemType[] => {
  const {objectId} = options;
  const {t, editCallBack, deleteCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (objectId) {
    addedOptions.push({
      title: t('amenities:override.form.menu.edit'),
      onClick: () => editCallBack(objectId),
    });

    addedOptions.push({
      title: t('amenities:override.form.menu.delete'),
      onClick: () => deleteCallBack(objectId),
      render: 'danger',
    });
  }
  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuOverride = (
  options: GetMenuOverrideOptions,
  functions: GetMenuOverrideFuncOptions,
): MobileMenuEntry[] => {
  return getMenuOverride(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};
