import React from 'react';
import {GroupPostsFeed} from '../../components/Groups/GroupPostsFeed';
import {useViewer} from '../../hooks/user';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {useGroupFeed} from '../../hooks/groups';
import {PostActions} from '../../types/post';
import {useGetPostPollInfo, useGetPostsCount, usePinUnpinPost} from '../../hooks/feed';
import {entities, useReport} from '../../hooks/report';
import {useGetAdminsLabels} from '../../hooks/people';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {toStatePosts} from '../../helpers/feed';

export const GroupFeed: React.FC<{objectId?: string}> = ({objectId}) => {
  const viewer = useViewer();
  const [userType] = useRecoilState(typeUser);
  const [comm] = useRecoilState(currentCommunity);
  const isManagerOrAdm = userType === TypeCommunity.admin || userType === TypeCommunity.manager;
  const {data, refetch, fetchMore, loading, otherData} = useGroupFeed({
    userId: viewer?.objectId,
    groupId: objectId,
    communityId: comm?.objectId,
    isManager: isManagerOrAdm,
  });
  const totalPostsCount = useGetPostsCount(comm?.objectId, objectId);

  const handleRefetch = (num?: number) => {
    refetch({first: num || data?.length > 8 ? data?.length : 8});
  };
  const handleFetchMore = (skip?: number) => {
    if (!skip) return;
    try {
      fetchMore({
        variables: {
          skip: skip,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const {getPollInformation} = useGetPostPollInfo({userId: viewer?.objectId});

  const getPollData = async (id: string) => {
    const result = await getPollInformation(id);
    return toStatePosts(result?.data?.posts)?.[0]?.Options?.map((item) => item.Voted);
  };

  const reportActions = useReport(entities.post);
  const pinUnpin = usePinUnpinPost({onSucces: refetch});
  const actions: PostActions = {...reportActions, pinUnpin};
  const hasMorePosts = otherData?.posts?.pageInfo?.hasNextPage ?? true;
  const adminLabels = useGetAdminsLabels();
  const reportItemActions = useReport(entities.listing);
  if (!userType) return null;
  return (
    <GroupPostsFeed
      posts={data}
      viewer={viewer}
      groupId={objectId}
      community={comm}
      fetchMore={handleFetchMore}
      refetchPosts={handleRefetch}
      postsLoading={loading}
      hasMorePosts={hasMorePosts}
      actions={actions}
      adminLabels={adminLabels}
      totalPostsCount={totalPostsCount}
      typeUser={userType}
      getPollData={getPollData}
      reportActions={reportItemActions}
    />
  );
};
