import React, {useEffect, useState} from 'react';
import {TCommunity} from '../../../types/community';
import {CardsList} from './CardsList';
import {useTranslation} from 'react-i18next';
import {Seo} from '../../SEO';
import {makeSeoKeywords} from '../../../helpers/common';
import {EmptySearch} from '../../EmptyState/EmptySearch/EmptySearch';
import {SetCommunitiesSearchParams} from '../../../containers/Account/Create/Resident/types';
import {SearchBlock} from '../../common/SearchBlock/SearchBlock';
import {getSearchCommunityOptions} from '../../../helpers/community';
import {LabelValuePair} from '../../../types/settings';
import {BtnCannotFind, ContentWrapper, WrapperSearch, HangehLogoWrapper} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {MemoSceletonLoader} from './SceletonLoader';
import {InfiniteScroll} from '../../common/InfiniteScroll';
import {isDesktop} from 'react-device-detect';

type TProps = {
  communities?: Partial<TCommunity>[] | null;
  refetch: () => void;
  viewerId?: string;
  viewerAddress?: string;
  loading?: boolean;
  setSearchParams: SetCommunitiesSearchParams;
  redirectToPersonalSetting: () => void;
  hasMorePosts: boolean;
  loadMore: (c?: number) => void;
};

export const SearchCommunity: React.FC<TProps> = ({
  communities,
  loading,
  viewerId,
  viewerAddress,
  setSearchParams,
  redirectToPersonalSetting,
  hasMorePosts,
  loadMore,
}) => {
  const {t} = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const seoTitle = t('common:seoTitle', {text: t('common:seo.search')});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.search')]);

  useEffect(() => {
    document.documentElement.scrollTo({top: 0});
  }, []);

  const handleActive = () => {
    setIsActive(true);
  };

  const onSearch = (searchText?: string, postType?: Partial<LabelValuePair>) => {
    setSearchParams({
      OR: [
        {
          name: {matchesRegex: searchText?.toLocaleLowerCase() ?? '', options: 'i'},
          accessType: {matchesRegex: postType?.value ?? '', options: 'i'},
        },
        {
          zip: {
            matchesRegex:
              searchText
                ?.split('')
                .map((item) => {
                  return item + '\\s*';
                })
                .join('')
                .toLocaleLowerCase()
                .trim()
                .replaceAll(' ', '') ?? '',
            options: 'i',
          },
          accessType: {matchesRegex: postType?.value ?? '', options: 'i'},
        },
        {
          address: {matchesRegex: searchText?.toLocaleLowerCase() ?? '', options: 'i'},
          accessType: {matchesRegex: postType?.value ?? '', options: 'i'},
        },
      ],
    });
  };

  const searchCommunityOptions = getSearchCommunityOptions(t);
  return (
    <WrapperSearch>
      <Seo title={seoTitle} keywords={seoKeywords} />
      <HangehLogoWrapper>
        <IconSvg
          type={'hangeh-logo-beta'}
          stroke={'none'}
          fill={'aqua'}
          width={'95'}
          height={'40'}
          viewBox={'10 0 152 64'}
        />
      </HangehLogoWrapper>
      <SearchBlock
        title={t('search:labels.communities')}
        placeholder={t('community:search.placeholders.search')}
        onSearch={onSearch}
        options={searchCommunityOptions}
      />
      {!isActive && (
        <ContentWrapper>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadMore(communities?.length)}
            loader={<MemoSceletonLoader count={!isDesktop ? 3 : null} />}
            initialLoad={true}
            useWindow={true}
            hasMore={hasMorePosts}>
            <CardsList
              communities={communities}
              address={!!viewerAddress}
              loading={loading}
              viewerId={viewerId}
              viewerAddress={viewerAddress}
              redirectToPersonalSetting={redirectToPersonalSetting}
            />
          </InfiniteScroll>
        </ContentWrapper>
      )}
      {!isActive && viewerAddress && (
        <BtnCannotFind onClick={handleActive}>{t('community:search.buttons.cannotFind')}</BtnCannotFind>
      )}
      {isActive && <EmptySearch />}
    </WrapperSearch>
  );
};
