import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';
import {route} from '../../constants/routes';
import {useRecoilState} from 'recoil';
import {skipParams} from '../../states/community';
// import {RNMessages, SendMessageToRNApp} from '../../helpers/common';
// import {analyticsTrackFN} from '../../helpers/account';

const AllowPushContainer: React.FC = () => {
  const {push} = useHistory();
  const {user} = useParams<{user: TypeCommunity}>();
  const [{skipAddress}] = useRecoilState(skipParams);

  const successPath =
    user === TypeCommunity.manager
      ? route.profileCreateManager.path
      : route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests});

  const onSkip = () => (skipAddress ? push(successPath) : push(route.address.get({user: user})));

  // const onAllow = () => {
  //   // SendMessageToRNApp(RNMessages?.openPushAllow);
  //   // analyticsTrackFN('Push Enabled');
  //   onSkip();
  // };
  useEffect(() => {
    // SendMessageToRNApp(RNMessages?.authorized);
    onSkip();
  }, []);

  return null;
};

export default AllowPushContainer;
