import {gql} from '@apollo/client';
import MemberFragment from './people';

export const HelpDeskFragment = gql`
  fragment HelpDeskFragment on HelpDesk {
    id
    objectId
    createdAt
    updatedAt
    descr
    layOutOrder
    isPublished
    buttonTitle
    Community {
      id
      objectId
    }
    Assignee {
      ...MemberFragment
    }
    assignTo
    dailyQuoteAuthor
    isDeleted
    title
    type
  }

  ${MemberFragment}
`;
