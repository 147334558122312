import {useTranslation} from 'react-i18next';

export enum Locales {
  en = 'en',
  ru = 'ru',
}

export function useGetLanguage() {
  const {i18n} = useTranslation();

  const getLanguage = () => {
    switch (i18n.language) {
      case Locales.ru:
        return Locales.ru;
      default:
        return;
    }
  };

  return getLanguage;
}
