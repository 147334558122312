import React, {useState} from 'react';
import {InputDropdownProps} from './types';
import {InputStyledDropdownWrapper, InputWithDropdownChosen, InputWithDropdownWrapper} from './styles';
import {Dropdown} from '../../Dropdown/Dropdown';
import {IconSvg} from '../../Icon/Svg';

export const InputDropdown: React.FC<InputDropdownProps> = ({
  icon,
  label,
  children,
  dropdownPosition = 'left',
  variant = 'primary',
}) => {
  const [active, setActive] = useState(false);

  const dropdownHandleOpen = () => setActive(true);
  const dropdownHandleClose = () => setActive(false);

  return (
    <InputWithDropdownWrapper
      variant={variant}
      onFocusCapture={dropdownHandleOpen}
      onBlurCapture={dropdownHandleClose}
      tabIndex={-1}>
      {icon && <IconSvg type={icon} />}
      <InputWithDropdownChosen variant={variant}>{label}</InputWithDropdownChosen>
      <Dropdown position={dropdownPosition} $isOpen={active}>
        <InputStyledDropdownWrapper>{children}</InputStyledDropdownWrapper>
      </Dropdown>
    </InputWithDropdownWrapper>
  );
};
