import React from 'react';
import {useTranslation} from 'react-i18next';
import {SetAmenitiesSearchParams} from '../../containers/Amenities/types';
import {AddListingBtn, Hidden, PlusIcon} from './styles';

import {SearchBlock} from '../common/SearchBlock/SearchBlock';
import {route} from '../../constants/routes';
import {
  getAmenityTypeParams,
  getShowAmenitiesParams,
  isAdditionalAmenityOption,
  getSearchAmenityOptions,
} from '../../helpers/amenity';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface Props {
  setSearchParams: SetAmenitiesSearchParams;
  searchButtonText?: string;
  cantAdd?: boolean;
}

export const Search: React.FC<Props> = ({setSearchParams, cantAdd}) => {
  const {t} = useTranslation();
  const listingOptions = getSearchAmenityOptions();

  const onSearch = (searchText?: string, time?: SelectOptions) => {
    const searchParams = isAdditionalAmenityOption(time?.value) ? getShowAmenitiesParams() : getAmenityTypeParams();

    setSearchParams([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        ...searchParams,
      },
    ]);
  };

  return (
    <Hidden>
      <SearchBlock
        title={t('search:labels.amenities')}
        placeholder={t('search:labels.whatLooking')}
        options={listingOptions}
        onSearch={onSearch}
        moveLast
        buttons={
          !cantAdd ? (
            <AddListingBtn to={route.createAmenity.get()} rounded width={'154px'}>
              <PlusIcon type={'plus'} stroke={'white'} width={'10px'} height={'10px'} />
              <span>{t('amenities:buttons.form.create')}</span>
            </AddListingBtn>
          ) : undefined
        }
      />
    </Hidden>
  );
};
