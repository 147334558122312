import React from 'react';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {CoverWrapper, EmptyCoverContainer, EmptyCoverFormat, EmptyCoverWrapper} from './styles';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';

interface IProps {
  src?: string;
  format?: string;
  isHaveLabel: boolean;
}

const FileCover: React.FC<IProps> = ({src, format, isHaveLabel}) => {
  return (
    <CoverWrapper $isHaveLabel={isHaveLabel}>
      {src ? (
        <PhotoCard src={src} aspectRatio={'300:200'} />
      ) : (
        <EmptyCoverContainer>
          <EmptyCoverWrapper>
            <IconSvg type={'file'} viewBox={'0 0 50 50'} width={'105px'} height={'105px'} stroke={'gray-2'} />
            <EmptyCoverFormat case={'uppercase'}>{format}</EmptyCoverFormat>
          </EmptyCoverWrapper>
        </EmptyCoverContainer>
      )}
    </CoverWrapper>
  );
};

const MemoFileCover = React.memo(FileCover);
export {MemoFileCover};
