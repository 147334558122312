import React from 'react';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';

import {SearchBlock} from '../common/SearchBlock/SearchBlock';
import {AddListingBtn, Hidden} from './styles';
import {SetCompanySearchParams} from './types';

interface Props {
  setSearchParams?: SetCompanySearchParams;
  searchButtonText?: string;
  cantAdd?: boolean;
}

export const Search: React.FC<Props> = ({setSearchParams, cantAdd}) => {
  const {t} = useTranslation();

  const onSearch = (searchText?: string) => {
    setSearchParams?.({
      name: {
        matchesRegex: searchText?.toLocaleLowerCase() ?? '',
        options: 'i',
      },
    });
  };

  return (
    <Hidden>
      <SearchBlock
        title={t('search:labels.localBiz')}
        placeholder={t('search:labels.whatLooking')}
        onSearch={onSearch}
        moveLast
        buttons={
          !cantAdd ? (
            <AddListingBtn to={route.createBiz.get()} rounded width={'154px'}>
              <span>{t('company:localBiz.buttons.create')}</span>
            </AddListingBtn>
          ) : undefined
        }
      />
    </Hidden>
  );
};
