import {DefaultOption} from '../constants/common';
import {Location} from '../queries/types/locations';
import {TOptions} from '../types/community';

export const getOptionsLocation = (items?: Location[]): TOptions[] => {
  if (!items?.length) return [DefaultOption];
  return items?.map((it) => ({label: it.name, value: it.id, type: it.type}));
};

export const getLocationValue = (location?: Location): Array<{label: string; value: string}> => {
  const {objectId, name} = location || {};

  return objectId && name
    ? [
        {
          label: name,
          value: objectId,
        },
      ]
    : [];
};
