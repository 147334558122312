import React, {useEffect, useState} from 'react';
import {ShowMoreWrapper, ShowMoreContainer} from './styles';
import {useTranslation} from 'react-i18next';
import linkifyHtml from 'linkifyjs/html';
import {closeTags} from '../../../helpers/common';

type ShowMoreProps = {
  isExpanded?: boolean;
  onExpand?: () => void;
  html?: string;
  isLinkify?: boolean;
};

const limit = 500;

export const linkifyHtmlCustom = (htmlStr: string | undefined) => {
  const linkHtml = linkifyHtml(htmlStr || '', {
    target: '_blank',
    validate: (url: string) => {
      if (url.includes('hangeh.app') || url.includes('hangeh.dev')) {
        return false;
      }
      return true;
    },
  });
  return linkifyHtml(linkHtml || '', {target: '_self'});
};

export const ShowMore: React.FC<ShowMoreProps> = ({onExpand, html, isLinkify}) => {
  const {t} = useTranslation();
  const btnShowMore = t('seeMore');
  const [isEnabled, setIsEnabled] = useState(false);
  const onExpandClick = (e: React.SyntheticEvent) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (!e.target?.getAttribute('href')) e.preventDefault();
    setIsEnabled(false);
    onExpand?.();
  };

  useEffect(() => {
    Number(html?.length) > limit && setIsEnabled(true);
  }, [html]);

  const fixedHtml = isEnabled ? closeTags(html?.slice(0, 500)) : html;
  const haveTags = fixedHtml?.includes('</p>');
  return (
    <>
      {isEnabled ? (
        <ShowMoreContainer>
          <ShowMoreWrapper
            onClick={onExpandClick}
            $isEnabled={isEnabled}
            text={`... ${btnShowMore}`}
            $haveTags={haveTags}
            dangerouslySetInnerHTML={{
              __html: (isLinkify ? linkifyHtmlCustom(fixedHtml) : fixedHtml) || '',
            }}
          />
          {/*<ExpandContainer onClick={prevent}>*/}
          {/*  <ExpandButton onClick={onExpandClick}>{btnShowMore}</ExpandButton>*/}
          {/*  <ExpandShadow />*/}
          {/*</ExpandContainer>*/}
        </ShowMoreContainer>
      ) : (
        <div dangerouslySetInnerHTML={{__html: (isLinkify ? linkifyHtmlCustom(fixedHtml) : html) || ''}} />
      )}
    </>
  );
};
