import React, {useState} from 'react';
import {DarkBackground, HelpButtonWrapper, PopupWrapper} from './styles';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {HelpCentre} from '../HelpCentre/HelpCentre';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../../../states/community';
import {SLACK_BOT_ACCESS_TOKEN, SLACK_CHANNEL} from '../../../../constants/env';

// eslint-disable-next-line
// @ts-ignore
import loadable from '@loadable/component';
import {IsModalProvider} from '../../../../containers/Navigation/IsModalProvider';

// eslint-disable-next-line
// @ts-ignore
const FeedbackComponent: any = loadable(() => import('simple-screenshot-feedback'), {ssr: false});

const HelpCentreButton: React.FC<{isShown?: boolean}> = ({isShown = true}) => {
  const [active, setActive] = useState(false);
  const [community] = useRecoilState(currentCommunity);
  const handleActive = () => setActive(!active);
  const handleClose = () => setActive(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();
  const communityName = `${community?.name} (${community?.objectId})`;

  return (
    <>
      <HelpButtonWrapper onClick={handleActive} $isShown={isShown}>
        <IconSvg type={'help-alt'} viewBox={'0 0 32 32'} width={'32px'} height={'32px'} />
      </HelpButtonWrapper>
      {active && (
        <DarkBackground onClick={handleClose}>
          <IsModalProvider>
            <PopupWrapper onClick={stop}>
              <HelpCentre active={active} nameCommunity={communityName} close={handleClose} />
            </PopupWrapper>
          </IsModalProvider>
        </DarkBackground>
      )}
      <FeedbackComponent
        slackToken={SLACK_BOT_ACCESS_TOKEN}
        slackChannel={SLACK_CHANNEL}
        location={'top-middle'}
        handleSubmitError={(error: any) => console.log('Feedback', error)}
      />
    </>
  );
};

const MemoHelpCentreButton = React.memo(HelpCentreButton);
export {MemoHelpCentreButton};
