import React from 'react';
import styled from 'styled-components';

const A = styled.a`
  text-decoration: none;
  color: initial;
  display: block;
`;

interface IProps {
  to: string;
  openInNewTab?: boolean;
}

export const BlankExternalLink: React.FC<IProps> = ({to, openInNewTab = true, children}) => {
  return (
    <A href={to} referrerPolicy="no-referrer" target={openInNewTab ? '_blank' : undefined}>
      {children}
    </A>
  );
};
