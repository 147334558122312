import React from 'react';
import {LinkProps} from 'react-router-dom';
import {ButtonProps} from './types';
import {
  PrimaryExternalLinkButton,
  RoundedExternalLinkButton,
  PrimaryLinkButton,
  RoundedLinkButton,
  Value,
  LeftAdornment,
  IconWrapper,
} from './styles';
import {ButtonBase} from './ButtonBase';

const components = {
  external: {
    primary: PrimaryExternalLinkButton,
    rounded: RoundedExternalLinkButton,
  },
  internal: {
    primary: PrimaryLinkButton,
    rounded: RoundedLinkButton,
  },
};

type Props = {
  external?: boolean;
};

export const LinkButton: React.FC<ButtonProps & LinkProps & Props> = ({
  children,
  leftAdornment,
  rounded,
  external,
  icon,
  to,
  ...props
}) => {
  const type = rounded ? 'rounded' : 'primary';
  const Component = external ? components.external[type] : components.internal[type];

  return (
    <ButtonBase element={Component} {...props} {...{[external ? 'href' : 'to']: to}}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Value leftAdornment={leftAdornment} leftAdtPadding={props.leftAdtPadding}>
        {children}
        {leftAdornment && <LeftAdornment>{leftAdornment}</LeftAdornment>}
      </Value>
    </ButtonBase>
  );
};
