import React from 'react';
import {useLocation} from 'react-router-dom';

import {LogIn} from '../../components/Auth/Login';
import {LottieLoader} from '../../components/Loader/LottieLoader';

import {useLogIn, useResetPass} from '../../hooks/auth';
import {useViewer} from '../../hooks/user';
import {ogURLS} from '../../Routes';
import {isFromApp} from '../../ui-kit/Navigation/mobile/styles';

export const LogInContainer = () => {
  const viewer = useViewer();
  const resetPassData = useResetPass();
  const {pathname} = useLocation();
  const {...dataLogIn} = useLogIn();

  const isOG = !viewer?.objectId && ogURLS.some((u) => pathname.includes(u));
  if (dataLogIn.loading && !isFromApp()) return <LottieLoader $isVisible={true} allScreen={true} />;
  return <LogIn dataLogIn={dataLogIn} dataResetPass={resetPassData} isOG={isOG} />;
};
