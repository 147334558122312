import React from 'react';
import {TEvent} from '../../types/event';
import {Link} from '../../ui-kit/Typography/Link';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {MemoLabelCard} from './LabelCard';
import {ContainerListingCard, InnerListingCard} from './styles';

interface IProps {
  event: Partial<TEvent>;
  to: string;
  src: string;
}

const SimpleEventCard: React.FC<IProps> = ({event, to, src}) => {
  const {pricePeriod, name, rewardType, startTime} = event;
  return (
    <ContainerListingCard>
      <Link to={to}>
        <InnerListingCard>
          <PhotoCard aspectRatio={'300:200'} src={src} />
          <MemoLabelCard
            rewardType={rewardType}
            pricePeriod={pricePeriod}
            name={name}
            showDate={true}
            date={startTime}
          />
        </InnerListingCard>
      </Link>
    </ContainerListingCard>
  );
};

const MemoSimpleEventCard = React.memo(SimpleEventCard);
export {MemoSimpleEventCard};
