import React, {useRef} from 'react';
import {
  DarkBackground,
  NoNotificationsText,
  NotificationsBlockWrapper,
  NotificationsWrapper,
  PopupWrapper,
  SkeletonWrapper,
  // Title,
  CloseNotificationsBtn,
  NotificationLabel,
  NotificationsClear,
} from './styles';
import {useTranslation} from 'react-i18next';
import {NotificationsType} from '../../../types/notifications';
import Skeleton from 'react-loading-skeleton';
import {Notification} from './Notification';
import {InfiniteScroll} from '../../common/InfiniteScroll';
// import {Br} from '../../../ui-kit/Typography/Br';
// import {AccountCheck} from './AccountCheck';
// import {AccountCheckList, listingsCheckListKeys} from './accountCheckList';
// import {TypeCommunity} from '../../../types/auth';
import {TypeUserStateType} from '../../../states/typeUser';
import {AccountCheckListType} from '../../../queries/types/checklist';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
// import {useLinks} from '../../../hooks/common';
// import {hasPlanOption} from '../../../helpers/community';
// import {PlanOptionsValues} from '../../../constants/plan';
import {TCommunity} from '../../../types/community';

const NotificationSkeleton: React.FC = () => {
  return (
    <SkeletonWrapper>
      <Skeleton circle={true} width={30} height={30} />
      <div>
        <Skeleton />
        <Skeleton width={75} />
      </div>
    </SkeletonWrapper>
  );
};

interface TProps {
  notificationsInfo: {
    data?: NotificationsType[];
    loading: boolean;
  };
  visible?: boolean;
  setVisible: (t: boolean) => void;
  setNotificationsSeen: () => Promise<void>;
  archiveNotification: (id: string) => Promise<void>;
  fetchMore: (skip?: number) => Promise<void>;
  hasMore: boolean;
  setNotificationSeen: (s: string, id: string) => void;
  typeUser?: TypeUserStateType;
  checkList?: AccountCheckListType;
  hideCompletedCheck: (nameField: keyof AccountCheckListType) => () => void;
  archiveAll?: () => void;
  clearLoading?: boolean;
  cleared: boolean;
  community?: Partial<TCommunity>;
}

export const Notifications: React.FC<TProps> = ({
  notificationsInfo,
  visible,
  setVisible,
  setNotificationsSeen,
  archiveNotification,
  fetchMore,
  hasMore,
  setNotificationSeen,
  // typeUser,
  // checkList,
  // hideCompletedCheck,
  archiveAll,
  clearLoading,
  cleared,
  // community,
}) => {
  const {t} = useTranslation();
  // const {getLink} = useLinks();
  const scrollParentRef = useRef<HTMLDivElement>(null);

  if (!visible) return null;

  const hide = async () => {
    setVisible?.(false);
    await setNotificationsSeen();
  };
  const stopEvent = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleFetchMore = async () => await fetchMore(notificationsInfo.data?.length);

  const handleGetScrollParent = () => {
    return scrollParentRef.current;
  };
  const handleClose = () => setVisible(false);
  // const listingsAvailable = hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.featureListings);
  // const actualChecklist =
  //   checkList &&
  //   AccountCheckList.filter((el) => {
  //     if (!listingsAvailable) {
  //       if (listingsCheckListKeys.includes(el.dataKey)) return false;
  //     }
  //
  //     return !!checkList[el.dataKey] && !checkList[el.dataKey].isHide && el.roles.includes(typeUser as TypeCommunity);
  //   });
  // const completedCheckList = !actualChecklist?.filter((el) => checkList?.[el?.dataKey]?.isChecked === false).length;
  return (
    <DarkBackground onClick={hide}>
      <PopupWrapper onClick={stopEvent}>
        <CloseNotificationsBtn onClick={() => setVisible?.(false)}>
          <IconSvg type={'close'} width={'24'} height={'24'} />
        </CloseNotificationsBtn>
        <NotificationsWrapper>
          <NotificationsBlockWrapper ref={scrollParentRef}>
            {notificationsInfo.loading && !notificationsInfo.data?.length ? (
              <NotificationSkeleton />
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={handleFetchMore}
                hasMore={hasMore}
                loader={notificationsInfo.loading ? <NotificationSkeleton /> : <div />}
                useWindow={false}
                getScrollParent={handleGetScrollParent}>
                {/*{!!actualChecklist?.length && !completedCheckList && (*/}
                {/*  <>*/}
                {/*    <Title>{t('notifications:titleChecklist')}</Title>*/}
                {/*    {actualChecklist.map((el) => {*/}
                {/*      return (*/}
                {/*        <AccountCheck*/}
                {/*          key={el.dataKey}*/}
                {/*          to={getLink(el.to)}*/}
                {/*          name={t(`notifications:checklist.${el.dataKey}`)}*/}
                {/*          isComplete={checkList?.[el.dataKey]?.isChecked}*/}
                {/*          onClick={hideCompletedCheck(el.dataKey)}*/}
                {/*          onClose={handleClose}*/}
                {/*          isActiveState={checkList ? !el.isInactiveState?.(checkList) : true}*/}
                {/*        />*/}
                {/*      );*/}
                {/*    })}*/}
                {/*    <Br indent={7} />*/}
                {/*  </>*/}
                {/*)}*/}
                <NotificationLabel>
                  {t('notifications:titleNotifications')}
                  {!!notificationsInfo.data?.length && (
                    <NotificationsClear onClick={archiveAll} $loading={clearLoading}>
                      {t('common:commonButtons.clearAll')}
                    </NotificationsClear>
                  )}
                </NotificationLabel>
                {notificationsInfo.data?.length === 0 && (
                  <NoNotificationsText>{t('notifications:noNotifications')}</NoNotificationsText>
                )}
                {!cleared &&
                  notificationsInfo.data?.map((n) => (
                    <Notification
                      notification={n}
                      archiveNotification={archiveNotification}
                      close={handleClose}
                      key={n.id}
                      setNotificationSeen={setNotificationSeen}
                    />
                  ))}
              </InfiniteScroll>
            )}
          </NotificationsBlockWrapper>
        </NotificationsWrapper>
      </PopupWrapper>
    </DarkBackground>
  );
};
