import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {paths} from '../../constants/routes';
import {getMobPath, pathIndexMap, PathsList} from '../../helpers/mobNavigation';
import {Message} from '../../ui-kit/Form/Message';
import {Media} from '../../ui-kit/theme';
import {fontPrimaryReg} from '../common/styles';

const Wrapper = styled.div<{$offset?: boolean; $appendTop?: boolean}>`
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #fff3da;
  gap: 0.5em;
  ${({$offset}) => $offset && `margin-top: 110px;`};

  ${Media.down.l} {
    height: 36px;
    font-size: 12px;
    ${({$appendTop}) =>
      $appendTop &&
      css`
        position: absolute;
        inset: 0 0 auto 0;
      `};
    ${({$offset}) => $offset && `margin-top: 10px;`};
  }

  ${fontPrimaryReg};
`;

const TextBox = styled.span`
  text-align: center;
`;

const TextBtn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

interface IProps {
  send: () => void;
  appendTop?: boolean;
}

export const VerifyEmailMessage: React.FC<IProps> = ({send, appendTop}) => {
  const {t} = useTranslation();
  const [visibleMsg, setVisible] = useState(false);
  const {pathname} = useLocation();
  const path = getMobPath(pathname as string);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentIndex = pathIndexMap[path as PathsList];
  const noOffset = pathname.includes(paths.messages);
  const showTabs = isMobile && currentIndex !== undefined && !noOffset;
  const handleSubmit = () => {
    if (visibleMsg) return;

    send();
    setVisible(true);
    setTimeout(() => setVisible(false), 5000);
  };

  return (
    <>
      <Wrapper $offset={showTabs} $appendTop={appendTop}>
        <TextBox>
          {t('common:verifyEmailMessage.text')}
          <TextBtn onClick={handleSubmit}>{t('common:verifyEmailMessage.button')}</TextBtn>
        </TextBox>
      </Wrapper>
      <Message open={visibleMsg} variant={'success'} centred>
        {t('common:verifyEmailMessage.sent')}
      </Message>
    </>
  );
};
