import React from 'react';
import {
  GroupAvatar,
  GroupHeader,
  GroupHeaderInfo,
  GroupHeaderTitle,
  GroupMainColumn,
  GroupHeaderDescr,
  GroupHeaderMembers,
  JoinRow,
  JoinRowText,
  JoinRowButton,
} from './styles';
import {TGroup} from '../../types/group';
import DefaultAvatar from '../../ui-kit/Avatar/assets/avatar-default.png';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {useTranslation} from 'react-i18next';
import {GroupFeed} from '../../containers/Groups/GroupFeed';
import {declOfNum} from '../../helpers/common';
import {getUrlForGroupAvatar} from '../../helpers/group';

type GroupFeedColumnProps = {
  group?: TGroup;
  isJoined?: boolean;
  onJoin?: () => void;
};

export const GroupFeedColumn: React.FC<GroupFeedColumnProps> = ({group, isJoined, onJoin}) => {
  return (
    <GroupMainColumn>
      <GroupHeadInfo group={group} />
      {isJoined ? <GroupFeed objectId={group?.objectId} /> : <JoinBlock onJoin={onJoin} />}
    </GroupMainColumn>
  );
};

const GroupHeadInfo: React.FC<{group?: TGroup}> = ({group}) => {
  const {t} = useTranslation();
  const getMembersKey = declOfNum({count: group?.MembersCount || 0, wordOne: 'member', wordMany: 'members'});
  const membersCount = t(`groups:card.${getMembersKey}`, {count: group?.MembersCount || 0});
  const avatarSrc = getUrlForGroupAvatar(group?.alias as string);

  return (
    <GroupHeader>
      <GroupAvatar src={group?.Avatar?.file?.url || avatarSrc || DefaultAvatar} />
      <GroupHeaderInfo>
        <GroupHeaderTitle>{group?.name}</GroupHeaderTitle>
        <GroupHeaderDescr disableShowMore={true} html={group?.description} />
        <GroupHeaderMembers>
          <IconSvg type={'people'} />
          <span>{membersCount}</span>
        </GroupHeaderMembers>
      </GroupHeaderInfo>
    </GroupHeader>
  );
};

type JoinBlockProps = {
  onJoin?: () => void;
};

export const JoinBlock: React.FC<JoinBlockProps> = ({onJoin}) => {
  const {t} = useTranslation();
  const joinBtn = t('groups:card.join');
  const joinText = t('groups:card.joinText');
  return (
    <JoinRow>
      <JoinRowText>
        <JoinRowButton onClick={onJoin}>{joinBtn}</JoinRowButton>
        {joinText}
      </JoinRowText>
    </JoinRow>
  );
};
