import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {IGetCurrValue} from '../../types/common';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Label} from '../../ui-kit/Labels';
import {
  ListingCardTitle,
  InnerDataPreview,
  InnerPrice,
  InnerRewardData,
  InnerLabelItemTitle,
  InnerLabelCard,
  ListerInfo,
  FeatureLabel,
} from './styles';

export type TLabelCard = {
  listingType?: ListingType;
  name?: string;
  price?: number;
  pricePeriod?: PricePeriodType | null;
  rewardType?: RewardType;
  getSignValue: IGetCurrValue;
  category?: string;
  isFeatured?: boolean;
  authorName?: string;
  verified?: boolean;
  isSold?: boolean;
  isPost?: boolean;
  noPhoto?: boolean;
  distance?: string;
};

// const colorRewardType: {[key: string]: 'quaternary' | 'senary'} = {
//   free: 'quaternary',
//   coffee: 'senary',
// };

export const LabelCard: React.FC<TLabelCard> = ({
  name,
  price,
  pricePeriod,
  rewardType,
  getSignValue,
  category,
  verified,
  authorName,
  isFeatured,
  isSold,
  isPost,
  noPhoto,
  distance,
}) => {
  const {t} = useTranslation();
  if (!name && !category) return null;

  return (
    <InnerLabelCard>
      <InnerLabelItemTitle>
        {authorName && (
          <ListerInfo>
            <span>{authorName}</span>
            {verified && <IconSvg type="verified" stroke="green" width="11px" height="11px" viewBox="0 0 16 16" />}
            {/*isFeatured && <IconSvg type="star" stroke="orange" width="11px" height="11px" viewBox="0 2 22 22" />*/}
            {isFeatured && (
              <FeatureLabel variant={'quaternary'} squared={true}>
                {t(`listings:card.labels.featured`)}
              </FeatureLabel>
            )}
            <span> {distance}</span>
          </ListerInfo>
        )}
        <ListingCardTitle variantText={'quaternary'} $isPost={isPost} $unsetMaxWidth={noPhoto}>
          {name || category}
        </ListingCardTitle>
        {/* {rewardType === RewardType.coffee && <IconCoffee type={'coffee-cup'} stroke={'blue'} />} */}
      </InnerLabelItemTitle>

      <InnerDataPreview>
        {isSold ? (
          <Label variant={'septenary'} squared={true}>
            {t(`listings:card.labels.sold`)}
          </Label>
        ) : null}
        {/*  <Label variant={'quinary'} squared={true}>
            {t(`listings:card.listingsType.${listingType}`)}
           </Label>
        )} */}
        {Boolean(price) && (
          <>
            <InnerPrice>{getSignValue(price || 0)}</InnerPrice>
            {Boolean(price) && pricePeriod && (
              <InnerRewardData>{t(`listings:pricePeriod.${pricePeriod}`)}</InnerRewardData>
            )}
          </>
        )}
        {rewardType && rewardType !== RewardType.fee && (
          <InnerRewardData>{t(`listings:card.rewardType.${rewardType}`)}</InnerRewardData>
        )}
      </InnerDataPreview>
    </InnerLabelCard>
  );
};
