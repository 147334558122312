import styled, {css, keyframes} from 'styled-components';
import {Avatar as UiAvatar} from '../../../ui-kit/Avatar/Avatar';
import {Media, theme} from '../../../ui-kit/theme';
import {IconSvg as UiIconSvg} from '../../../ui-kit/Icon/Svg';
import {TextArea} from '../../../ui-kit/Form/TextArea/TextArea';

const fadeIn = keyframes`
 0% { opacity: 0; }
 30% { opacity: 0.3 }
 60% { opacity: 0.6; }
 100% { opacity: 1; }
`;

const fontPrimaryReg = css`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
`;

export const Avatar = styled(UiAvatar)`
  flex-shrink: 0;
  ${Media.down.l} {
    width: 34px;
    height: 34px;
  }
`;

export const IconSvg = styled(UiIconSvg)`
  ${Media.down.xs} {
    width: 16px;
    height: 16px;
  }
`;

export const WrapperMsgInput = styled.div`
  margin: 30px auto 0 auto;
  padding: 16px 26px 16px 26px;
  box-sizing: border-box;
  max-width: 950px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.primary};
  ${Media.down.l} {
    margin: 4px auto 10px auto;
    padding: 13px 6px;
    &:empty {
      padding: 0;
      border: none;
    }
  }
`;

export const LoopInput = styled.input`
  max-height: 40px;
  border-radius: 25px;
  padding: 10px 18px;
  box-sizing: border-box;
  margin: 0 40px 0 14px;
  border: none;
  outline: none;
  flex-grow: 1;
  display: block;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.septenary};
  font-size: 16px;
  &::placeholder {
    ${fontPrimaryReg};
    font-size: 16px;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.septenary};
    ${Media.down.xs} {
      font-size: 14px;
    }
  }
  ${Media.down.l} {
    margin: 0 0 0 0;
    padding: 10px 18px;
  }
`;

export const InnerMediaBtn = styled.div`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const WrapperMediaBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 30px;
  }
  ${Media.down.l} {
    display: none;
  }
`;

export const TextMediaBtn = styled.div`
  font-size: 10px;
  line-height: 150%;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.octonary};
  ${Media.down.l} {
    display: none;
  }
`;

export const InputBaseContainer = styled.div`
  width: 100%;
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
`;

export const InputContainer = styled.form<{$isEdit?: boolean}>`
  width: 100%;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  ${({$isEdit}) => $isEdit && 'margin-top: 30px;'}
`;

const topWrapperBaseStyles = css`
  align-items: center;
`;

const topWrapperNotBaseStyles = css`
  & > div:first-of-type {
    margin-right: 24px;
    ${Media.down.l} {
      margin-right: 14px;
    }
    ${Media.down.m} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  ${Media.down.m} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const InputTopWrapper = styled.div<{base?: boolean}>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: ${({base}) => (base ? `center` : `flex-start`)};
  ${({base}) => (base ? topWrapperBaseStyles : topWrapperNotBaseStyles)};
`;

export const PostInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;

  ${Media.down.l} {
    flex-direction: column;
  }
`;

export const UploadedFilesWrapper = styled.div<{$isAnnouncement: boolean; $errors: boolean}>`
  //padding-top: 20px;
  //padding-left: 77px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  ${Media.down.l} {
    padding-left: 10px;
    position: absolute;
    transform: ${({$isAnnouncement, $errors}) =>
      $isAnnouncement
        ? $errors
          ? 'translateY(-108px)'
          : 'translateY(-78px)'
        : $errors
        ? 'translateY(-80px)'
        : 'translateY(-50px)'};
  }

  ${Media.down.m} {
    padding-left: 10px;
  }
`;

export const MediumOptionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  border-left: solid ${theme.palette.background.grey} 0.5px;
  padding-left: 14px;
  margin-left: 10px;
  gap: 12px;

  ${Media.down.xxl} {
    padding-left: 6;
    gap: 4px;
    margin-left: 6px;
  }

  ${Media.down.l} {
    padding-left: 67px;
    margin-left: 0;
    border-left: none;
    margin-bottom: 10px;
  }

  ${Media.down.m} {
    padding-left: 0;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: end;
  gap: 7px;

  span {
    margin-bottom: 2px;
  }
`;

export const InputActionsWrapperMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 97px;

  ${Media.down.l} {
    padding-left: 67px;
  }

  ${Media.down.m} {
    padding-left: 0;
  }
`;

export const InputActionsInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const InputActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 77px;

  ${Media.down.l} {
    padding-left: 57px;
  }

  ${Media.down.m} {
    padding-left: 0;
  }
`;

export const InputActionsLeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputActionsRightSide = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;

  & > div > button {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({disabled}) => disabled && `opacity: 0.5; pointer-events: none;`};
`;

export const InputMessageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;

  & .post-input_icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    ${Media.down.s} {
      right: 4px;
    }
  }
`;

export const PollInputMessageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;

  .editorWrapper {
    height: auto;
    max-height: 100px;
    min-height: 44px;
    border: none;
    border-radius: 12px;
    background: #f2f2f2;
  }
  .editorWrapper .rt-wrapper {
    height: auto;
    max-height: 100px;
    min-height: 44px;
  }
  .editorWrapper .rdw-editor-main {
    position: relative;
    height: auto;
    max-height: 100px;
    min-height: 44px;
    padding: 12px 65px 10px 18px;
  }
  .EmojiAction {
    position: absolute;
    top: 60% !important;
    transform: translateY(-50%);
    right: 38px !important;
    cursor: pointer;
    z-index: revert !important;
  }
  > button {
    top: 50%;
    right: -5px;
  }
  & .post-input_icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    ${Media.down.s} {
      right: 4px;
    }
  }
`;

export const EditingContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  .EmojiAction {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
  }

  .post-input_icon {
    top: calc(100% - 20px);
    right: 15px;
  }
`;

export const TextMessageInput = styled.input<{fontSize?: number; mobileFontSize?: number}>`
  height: 44px;
  border-radius: 12px;
  padding: 7px 33px 7px 18px;
  box-sizing: border-box;
  border: none;
  outline: none;
  flex-grow: 1;
  display: block;
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.septenary};
  ${theme.font.primary.regular};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}px;`}
  &::placeholder {
    ${fontPrimaryReg};
    font-size: 14px;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.septenary};
    ${({fontSize}) => fontSize && `font-size: ${fontSize}px;`}
    ${Media.down.s} {
      font-size: 11px;
    }
    ${Media.down.l} {
      ${({mobileFontSize}) => mobileFontSize && `font-size: ${mobileFontSize}px;`}
    }
  }
  ${Media.down.s} {
    font-size: 10px;
  }
  ${Media.down.l} {
    padding: 7px 30px 7px 12px;
    ${({mobileFontSize}) => mobileFontSize && `font-size: ${mobileFontSize}px;`}
  }
`;

export const TextAreaWrapper = styled.div<{$isDesktop: boolean; $isAnnouncement?: boolean}>`
  position: relative;
  width: 100%;
  margin-top: 10px;
  background-color: ${({theme, $isAnnouncement}) =>
    $isAnnouncement ? theme.palette.common.yellowLight : theme.palette.background.septenary};
  .editorWrapper {
    background-color: ${({theme, $isAnnouncement}) =>
      $isAnnouncement ? theme.palette.common.yellowLight : theme.palette.background.septenary};
    border: none;
  }
  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
  .rt-editor {
    padding-right: ${({$isDesktop}) => ($isDesktop ? '38px' : null)} !important;
  }
  ${Media.down.m} {
    & .rt-editor .DraftEditor-root {
      height: 100% !important;
      font-size: 14px;
      & .public-DraftEditor-content > div {
        height: 100%;
      }
    }
  }
  ${Media.down.l} {
    padding-bottom: 40px;
  }
`;
export const MobFocused = styled.div`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }

  ${Media.up.m} {
    & .rt-editor {
      padding-right: 38px;
    }
  }
`;

export const TextMessageTextArea = styled(TextArea)<{isAnno?: boolean}>`
  border: none;
  background-color: ${({theme}) => theme.palette.background.septenary};
  outline: none;
  flex-grow: 1;
  background-color: ${({theme, isAnno}) =>
    !isAnno ? theme.palette.background.septenary : theme.palette.common.yellowLight};
  ${theme.font.primary.regular};

  textarea {
    font-size: 14px;
    padding-right: 30px;
  }

  textarea::placeholder {
    ${fontPrimaryReg};
    font-size: 14px;
    color: ${({theme}) => theme.palette.text.septenary};
  }

  ${Media.down.l} {
    padding: 7px 12px;

    textarea {
      font-size: 16px;
    }

    textarea::placeholder {
      font-size: 16px;
    }
  }
`;

export const CloseBtnMob = styled.span`
  position: absolute;
  right: 0;
  top: 7px;
  align-items: center;

  display: none;
  ${Media.down.m} {
    display: inline-flex;
    gap: 6px;
  }
`;

export const TextBtn = styled.span<{disabled?: boolean}>`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-size: 12px;
  ${theme.font.primary.semiBold};
  color: ${({theme}) => theme.palette.grayscale._12};
  ${({disabled}) => (disabled ? `opacity: 0.5` : 'opacity: 1')};
`;

export const PollOptionsWrapper = styled.div`
  position: relative;
  margin: 15px 0;
  width: 100%;
`;

export const PollOptionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  & > label {
    margin-right: 6px;
  }
  & .poll-option__btn--remove {
    margin-left: 20px;
    margin-right: 14px;
  }
  & > input {
    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const Circle = styled.span<{$isMulti?: boolean}>`
  min-width: 26px;
  height: 26px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._4};
  border-radius: ${({$isMulti}) => ($isMulti ? '3px' : '50%')};
  flex-grow: 1;
  margin-right: 8px;
`;

export const AttachFileInput = styled.input`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  ${theme.font.primary.semiBold};
  color: ${({theme}) => theme.palette.grayscale._12};
  ${({disabled}) => (disabled ? `opacity: 0.5` : 'opacity: 1')};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 10;
`;

export const AttachFileLabel = styled.div<{disabled?: boolean}>`
  position: relative;
  ${({disabled}) => disabled && `point-events: none; opacity: 0.5; display: none;`};
  & span {
    z-index: 1;
  }
  & input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export const FileLabel = styled.div`
  display: flex;
  font-size: 10px;
  ${theme.font.primary.regular};
  color: ${({theme}) => theme.palette.grayscale._6};
  border: 1px solid ${({theme}) => theme.palette.grayscale._14};
  border-radius: 14px;
  position: relative;
  min-width: fit-content;
  max-width: 30%;
  margin-right: 10px;
  height: 24px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 3px;
  align-items: center;
  justify-content: space-between;

  > span {
    white-space: nowrap;
  }

  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

export const FileRemove = styled.span`
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
`;

export const ErrorLine = styled.div`
  text-align: center;
  width: 100%;
  padding: 8px 0;
  font-size: 13px;
  color: ${({theme}) => theme.palette.common.red};
  ${theme.font.primary.regular};
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
  ${Media.down.l} {
    display: none;
  }
`;

export const InputLayer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  svg {
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
    ${Media.down.l} {
      right: 10px;
    }
  }
`;

export const ChangePostTypeIcon = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: calc(100% + 3px);
  bottom: 0;

  & > div > div {
    //transform: translateY(-10%) translateX(104%);
  }
`;

export const UsersMenuWrapper = styled.div`
  z-index: 299;
  & > div > button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    max-width: 370px;
    & span {
      white-space: nowrap;
      display: block;
    }
  }
`;

export const MediaPanelWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding-left: 4px;
`;

export const FileInputWrapper = styled.div`
  position: relative;
  height: 22px;
  width: 22px;
  overflow: hidden;
  & > div {
    opacity: 0;
    z-index: 1;
  }
  & > svg {
    position: absolute;
    inset: 0;
  }
`;

export const PollOptionsActions = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 12px;
  }
`;
