import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {VerifyBase} from './VerifyBase';

interface TProps {
  onSubmit: (phone: string) => void;
  error?: boolean;
  loading?: boolean;
}

export const VerifyPhone: React.FC<TProps> = ({onSubmit, error, loading}) => {
  const {t} = useTranslation();
  const [phone, setPhone] = useState('');
  const handleChange = (value: string) => setPhone(value);
  const handleInputAction = () => setPhone('');
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const phoneFormatted = phone.split(' ').join('');
    if (phoneFormatted.length > 0) {
      onSubmit(phoneFormatted);
    }
  };

  return (
    <VerifyBase
      title={t('auth:verification.phone.title')}
      subtitle={t('auth:verification.phone.subtitle')}
      inputLabel={t('auth:verification.phone.label')}
      inputActionText={t('auth:verification.phone.inputAction')}
      buttonText={t('auth:verification.phone.button')}
      errorMessage={error ? t('auth:verification.phone.error') : undefined}
      phone={true}
      value={phone}
      onChange={handleChange}
      onClick={handleSubmit}
      inputActionHandler={handleInputAction}
      loading={loading}
    />
  );
};
