import {communityCheckItem, AccountCheckListType} from '../queries/types/checklist';
import {CommunityAdmin, JoinRequest, Subscription} from '../queries/types/community';
import {Item} from '../queries/types/item';
import {Location} from '../queries/types/locations';
import {ParseObject, PointerInput} from '../queries/types/parse';
import {User} from '../queries/types/user';

export type TOptions = {label?: string; value?: string; type?: string};

export enum CommunityField {
  id = 'id',
  name = 'name',
  descr = 'descr',
  alias = 'alias',
  address = 'address',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  accessType = 'accessType',
  listingApproval = 'listingApproval',
  invitation = 'invitation',
  type = 'type',
  neighbourhood = 'neighbourhood',
}

export interface addAddressProps {
  id: string;
  zip: string;
  unit: string;
  address: string;
}

export interface addAddressContainer {
  value: addAddressProps;
  __typename?: string;
}

export interface TCommunity extends ParseObject {
  id: string;
  createdAt: Date;
  objectId: string;
  name: string;
  descr: string;
  Owner: User;
  alias: string;
  units: string;
  address_add?: addAddressContainer[];
  zip_add?: string[];
  buildings: string;
  zip: string;
  passCode?: string;
  accessType: string;
  listingApproval: string;
  invitation: string;
  address: string;
  identityData: {autogenerated: boolean};
  city: Location;
  state: Location;
  country: Location;
  Residents: User[];
  avatar: string;
  countMembers: number;
  Subscr: Subscription;
  isVerified: boolean;
  Items: Item[];
  JoinRequests: JoinRequest[];
  Admins: CommunityAdmin[];
  checkList: Pick<AccountCheckListType, communityCheckItem>;
  allowChat?: boolean;
  allowEvents?: boolean;
  type?: string;
  options?: Record<string, boolean>;
}

export type CommunityFormValues = {
  id: string;
  objectId: string;
  name: string;
  descr: string;
  Owner: PointerInput;
  alias: string;
  Location: Location;
  units: string;
  buildings: string;
  zip: string;
  accessType: string;
  listingApproval: string;
  invitation: string;
  address: string;
  passCode: string;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  Subscr?: Subscription;
  allowEvents?: boolean;
  type?: string;
  neighbourhood?: string;
};

export type CommunityFormField = keyof CommunityFormValues;
export type CommunityFormValue = CommunityFormValues[CommunityFormField];

export enum SearchStatus {
  request = 'request',
  join = 'join',
  joined = 'joined',
  pending = 'pending',
}

export interface UserPostInfo {
  avatar?: string;
  fullName?: string;
  isManager?: boolean;
  postAs?: '' | 'community';
}

export enum CommunityAccessType {
  Public = 'public',
  Private = 'private',
}

export type commLoading = 'all' | 'one' | boolean;

export interface PageInfo {
  hasMore: boolean;
  endCursor: string | null;
}

export enum CommunityOptionsFields {
  passCode = 'passCode',
  requireAddress = 'requireAddress',
  requirePhone = 'requirePhone',
  requireListing = 'requireListing',
}
