import React, {useEffect} from 'react';
import {RatingStarsProps, StarProps} from './types';
import {StarsContainer, StarIcon} from './styles';
import {IconSvg} from '../Icon/Svg';

const Star: React.FC<StarProps> = ({marked, starId, starSize, onClick, onMouseOver, isStarsSelected}) => {
  const width = starSize === 'small' ? '12px' : '18px';
  const height = starSize === 'small' ? '12px' : '18px';
  return (
    <StarIcon data-star-id={starId} size={starSize} onClick={onClick} onMouseOver={onMouseOver}>
      {marked ? (
        <IconSvg width={width} height={height} type={'star'} stroke={'orange'} fill={'orange'} />
      ) : (
        <IconSvg
          width={width}
          height={height}
          type={'star'}
          stroke={isStarsSelected ? 'orange' : 'gray'}
          fill={'none'}
        />
      )}
    </StarIcon>
  );
};

export const RatingStars: React.FC<RatingStarsProps> = ({value, maxStars, className, notEditable, onChange, small}) => {
  const [rating, setRating] = React.useState(Number(value) || 0);
  const [selection, setSelection] = React.useState(0);
  const chosen = rating > 0;
  useEffect(() => {
    setRating(value || 0);
  }, [value]);
  const hoverOver = (e: React.SyntheticEvent<HTMLSpanElement>, out?: boolean) => {
    if (notEditable) return;
    if (out) {
      setSelection(0);
      return;
    }
    let val = 0;
    if (e?.currentTarget.dataset['starId']) {
      val = Number(e?.currentTarget.dataset['starId']);
      setSelection(val);
    }
  };
  const setRatingHandle = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    if (notEditable) return;
    const val = Number(e?.currentTarget?.dataset['starId']);
    setRating(val || rating);
    onChange?.(val);
  };
  return (
    <StarsContainer
      onMouseOut={(e: React.SyntheticEvent<HTMLSpanElement>) => hoverOver(e, true)}
      className={className}
      notChosen={!chosen}
      notEditable={notEditable}>
      {Array.from({length: maxStars || 5}, (v, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1}`}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
          onClick={(e) => setRatingHandle(e)}
          onMouseOver={hoverOver}
          isStarsSelected={chosen}
          starSize={small ? 'small' : 'big'}
        />
      ))}
    </StarsContainer>
  );
};
