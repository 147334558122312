import {GQLUserOrder, GQLUserWhereInput} from '../graphql.schema';
import {useRef} from 'react';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../states/community';
import {useQuery} from '@apollo/client';
import {GetCommunityMembersQuery, GetResidentsResponseType} from '../queries/residents';
import {MemberT} from '../types/people';

type ResidentsParams = {
  where?: GQLUserWhereInput;
  order?: GQLUserOrder[];
  cursor?: string;
  first?: number;
  id?: string;
};

export const useGetResidents = (params: {
  where?: GQLUserWhereInput;
  order?: GQLUserOrder;
  skip?: boolean;
  first?: number;
}) => {
  const previousCursor = useRef<string | null>(null);
  const [community] = useRecoilState(currentCommunity);

  const variables = {
    id: community?.id,
    ...(params?.where ? {where: params.where} : {}),
    ...(params?.order ? {order: [params.order]} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<GetResidentsResponseType, ResidentsParams>(
    GetCommunityMembersQuery,
    {
      variables,
      skip: !community?.id,
      notifyOnNetworkStatusChange: true,
      ssr: true,
    },
  );

  const fetch = async () => {
    const pageInfoItems = data?.community?.Residents?.pageInfo;
    if (!pageInfoItems || !community?.id) return;
    const {hasNextPage, endCursor} = pageInfoItems;

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult: GetResidentsResponseType, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.community?.Residents?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.community.Residents.edges;
          const nextChunk = fetchMoreResult.community.Residents.edges;

          return {
            community: {
              ...fetchMoreResult.community,
              Residents: {
                ...fetchMoreResult.community.Residents,
                edges: prevChunk.concat(nextChunk),
              },
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: [data?.community.Owner, ...(data?.community.Residents?.edges?.map((edge) => edge.node) || [])] as MemberT[],
    loading,
    total: data?.community?.Residents?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.community?.Residents?.pageInfo?.hasNextPage),
    refetch,
  };
};
