import React from 'react';
import {useHistory} from 'react-router-dom';
import {EventForm} from '../../components/Events/Form';
import {RewardType} from '../../queries/types/item';
import {route} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useCreateEvent, useEventFormData} from '../../hooks/event';
import {EventFormAction} from '../../types/event';
import {EventRepeatType, OpenToType} from '../../queries/types/event';

export const CreateEventContainer = () => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const textSeo = t('common:seo.events.add');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {push} = useHistory();
  const formData = useEventFormData();
  const {currency} = useGetCurrencyValue();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const data = useCreateEvent({
    initialState: {
      rewardType: formData?.rewardTypeOptions[0]?.value as RewardType,
      eventDate: tomorrow,
      images: formData.dataImages,
      allowGuests: true,
      openTo: OpenToType.community,
      eventType: EventRepeatType.one,
    },
    onSuccess: (item) => push(getLink(route.eventCreated.get({id: item.id ?? ''}))),
    // onSuccess: (item) => handlePublish(item),
  });
  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <EventForm action={EventFormAction.create} data={data} formData={formData} currSign={currency?.sign || ''} />
    </>
  );
};
