import {anyObject} from '../../types/helpers';
import {keys} from '../object';
import {ValidationError} from './ValidationError';
import {SchemaType} from './types';

export class Schema<T extends anyObject> {
  constructor(public readonly schema: SchemaType<T>) {}

  public validate<R extends T>(data: T, onError?: (field: keyof T, data: T[keyof T]) => void): data is R {
    const fields = keys(this.schema);

    fields.forEach((field) => {
      const Validation = this.schema[field];

      if (!Validation.isValidation) {
        throw new ReferenceError(`Schema field ${field} has invalid validation handler`);
      }

      if (!Validation.validate(data[field], data)) {
        onError?.(field, data[field]);
        throw new ValidationError(Validation.message, field as string);
      }
    });

    return true;
  }
}
