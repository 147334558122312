import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  InnerAllListings,
  InnerMyListings,
  InnerTitle,
  ListCardsFlex,
  ListCardsGrid,
  ListingDivider,
  SubTitle,
  ViewAllBtn,
  WrapperMyListings,
} from './styles';
import {AmenityCard} from './AmenityCard';
import {route} from '../../constants/routes';
import {TypeCommunity} from '../../types/auth';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {ItemsSkeletonLoaderSection, ItemsSkeletonSection} from '../common/Skeletons/ItemsSkeleton';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {TouchScrollableBlock} from '../common/Tabs/TouchScrollableBlock';
import {Amenity} from '../../queries/types/amenity';
type TListAmenitiesWithPreview = {
  isPreview: boolean;
  typeUser?: TypeCommunity | null;
  amenities?: Partial<Amenity>[];
  fetchDataAmenities: () => void;
  hasMoreAmenities: boolean;
  communityId?: string;
  title?: string;
  isEdit?: boolean;
  isHideLabel?: boolean;
  featuredDivided?: boolean;
  loading?: boolean;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  onDelete?: (id?: string) => Promise<boolean>;
  onUnPublish?: (id?: string) => () => Promise<void>;
  onPublish?: (id?: string) => () => Promise<void>;
  canManage?: boolean;
};

export const ListAmenitiesWithPreview: React.FC<TListAmenitiesWithPreview> = ({
  isPreview,
  amenities,
  communityId,
  fetchDataAmenities,
  hasMoreAmenities,
  isEdit,
  onUnPublish,
  title,
  isHideLabel,
  featuredDivided,
  loading,
  typeUser,
  reportActions,
  getSignValue,
  onDelete,
  onPublish,
  canManage,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [isShow, setIsShow] = useState(isPreview);
  const titleButton = isShow ? t('amenities:page.viewAll') : t(`amenities:page.hide`);
  const isAllBtn = Number(amenities?.length) > 3;
  const isManager = typeUser === TypeCommunity.manager || canManage;

  const viewAll = () => {
    setIsShow((prev) => !prev);
  };
  const endFeatured = featuredDivided ? amenities?.findIndex((el) => !Boolean(el?.Featured)) : null;

  return (
    <InnerAllListings>
      {title && (
        <InnerTitle>
          <SubTitle>{title}</SubTitle>
          {isAllBtn && isPreview && (
            <ViewAllBtn type={'button'} onClick={viewAll}>
              {titleButton}
            </ViewAllBtn>
          )}
        </InnerTitle>
      )}
      {isShow && isPreview && (
        <WrapperMyListings>
          <InnerMyListings>
            <TouchScrollableBlock>
              <ListCardsFlex>
                {loading && !amenities?.length ? (
                  <ItemsSkeletonSection />
                ) : (
                  amenities?.map((item) => (
                    <AmenityCard
                      key={item.id}
                      to={
                        isManager
                          ? getLink(route.amenityViewEdit.get({id: item?.objectId || ''}))
                          : getLink(route.amenityView.get({id: item?.objectId || ''}))
                      }
                      communityId={communityId}
                      aspectRatio={'300:200'}
                      amenity={item}
                      src={item?.images?.[0]?.file?.url}
                      editPath={
                        isEdit || isManager
                          ? getLink(
                              route.amenityViewEdit.get({
                                id: item?.objectId || '',
                              }),
                            )
                          : null
                      }
                      onUnPublish={onUnPublish}
                      onPublish={onPublish}
                      onDelete={onDelete}
                      isHideLabel={isHideLabel}
                      isManager={isManager}
                      isOwner={isManager}
                      reportActions={reportActions}
                      getSignValue={getSignValue}
                    />
                  ))
                )}
              </ListCardsFlex>
            </TouchScrollableBlock>
          </InnerMyListings>
        </WrapperMyListings>
      )}

      {!isShow && (
        <InfiniteScroll
          loadMore={fetchDataAmenities}
          hasMore={!isShow ? hasMoreAmenities : false}
          pageStart={-50}
          loader={<ItemsSkeletonLoaderSection />}>
          <ListCardsGrid minWidth={280}>
            {loading && !amenities?.length ? (
              <ItemsSkeletonSection />
            ) : (
              amenities?.map((amenity, i) => (
                <>
                  {endFeatured && i === endFeatured ? <ListingDivider /> : <></>}
                  <AmenityCard
                    key={amenity.id}
                    to={
                      isManager
                        ? getLink(route.amenityViewEdit.get({id: amenity?.objectId || ''}))
                        : getLink(route.amenityView.get({id: amenity?.objectId || ''}))
                    }
                    communityId={communityId}
                    aspectRatio={'300:200'}
                    amenity={amenity}
                    src={amenity?.images?.[0]?.file?.url}
                    editPath={
                      isEdit || isManager ? getLink(route.amenityViewEdit.get({id: amenity.objectId || ''})) : null
                    }
                    onUnPublish={onUnPublish}
                    onPublish={onPublish}
                    isHideLabel={isHideLabel}
                    isManager={isManager}
                    isOwner={isManager}
                    onDelete={onDelete}
                    reportActions={reportActions}
                    getSignValue={getSignValue}
                  />
                </>
              ))
            )}
          </ListCardsGrid>
        </InfiniteScroll>
      )}
    </InnerAllListings>
  );
};
