import {translateFn} from '../types/common';
import {tabArrayItem} from '../components/common/Tabs/types';
import {useHasPlanOption} from './community';
import {PlanOptionsValues} from '../constants/plan';
import {hideNavPaths, paths, visibleNavPaths} from '../constants/routes';
import {PermissionsList} from '../types/settings';
import {getArrByCondition} from './common';

export enum PathsList {
  loop = 'loop',
  documents = 'documents',
  insights = 'insights',
  people = 'people',
  groups = 'groups',
  listings = 'listings',
  events = 'events',
  amenities = 'amenities',
  messages = 'messages',
  bookings = 'bookings',
  localBiz = 'local-biz',
}

export const pathIndexMap = {
  //home
  [PathsList.loop]: -1, //0
  [PathsList.documents]: -1, //1
  [PathsList.insights]: -1, //2
  //people
  [PathsList.people]: -1,
  [PathsList.groups]: -1,
  //explore
  [PathsList.listings]: -1,
  [PathsList.events]: -1,
  [PathsList.localBiz]: -1,
  [PathsList.amenities]: -1,
  //inbox
  [PathsList.messages]: 0,
  [PathsList.bookings]: 1,
};

export type homePathsT = PathsList.loop | PathsList.documents | PathsList.insights;
export type peoplePathsT = PathsList.people | PathsList.groups;
export type explorePathsT = PathsList.listings | PathsList.events | PathsList.amenities | PathsList.localBiz;
export type inboxPathsT = PathsList.messages | PathsList.bookings;

export const homePaths: homePathsT[] = [PathsList.loop, PathsList.documents, PathsList.insights];
export const peoplePaths: peoplePathsT[] = [PathsList.people, PathsList.groups];
export const explorePaths: explorePathsT[] = [
  PathsList.listings,
  PathsList.events,
  PathsList.amenities,
  PathsList.localBiz,
];
export const inboxPaths: inboxPathsT[] = [PathsList.messages, PathsList.bookings];

export type getMobileTabsRes = {paths: tabArrayItem[]; root: string} | undefined;

type getMobileTabsOptions = {
  t: translateFn;
  path?: string;
  isManager?: boolean;
  documentsCount?: number;
  permissions?: PermissionsList[];
};

export const getMobileTabs = ({
  path,
  t,
  documentsCount,
  isManager,
  permissions,
}: getMobileTabsOptions): getMobileTabsRes => {
  const hasReportingDashboard = useHasPlanOption(PlanOptionsValues.reportingDashboard);
  const hasDocuments = useHasPlanOption(PlanOptionsValues.communityDocuments);
  const hasGroups = useHasPlanOption(PlanOptionsValues.groups);
  const hasListings = useHasPlanOption(PlanOptionsValues.listingsFeed);
  const hasAmenities = useHasPlanOption(PlanOptionsValues.amenities);
  const hasEvents = useHasPlanOption(PlanOptionsValues.events);
  const hasCompanies = useHasPlanOption(PlanOptionsValues.company);

  if (homePaths.includes(path as homePathsT)) {
    const loop = [{title: t('common:menu.loop'), index: pathIndexMap[PathsList.loop], path: PathsList.loop}];
    const docs =
      hasDocuments && documentsCount
        ? [{title: t('common:menu.docs'), index: pathIndexMap[PathsList.documents], path: PathsList.documents}]
        : [];
    const insights =
      hasReportingDashboard && (isManager || permissions?.includes(PermissionsList.dashboardAccess))
        ? [{title: t('common:menu.insights'), index: pathIndexMap[PathsList.insights], path: PathsList.insights}]
        : [];
    return {
      paths: [...loop, ...docs, ...insights],
      root: 'home',
    };
  }
  if (peoplePaths.includes(path as peoplePathsT))
    return {
      paths: [
        {title: t('common:menu.members'), index: pathIndexMap[PathsList.people], path: PathsList.people},
        ...(hasGroups
          ? [{title: t('common:menu.groups'), index: pathIndexMap[PathsList.groups], path: PathsList.groups}]
          : []),
      ],
      root: 'people',
    };

  if (explorePaths.includes(path as explorePathsT))
    return {
      paths: [
        ...getArrByCondition(Boolean(hasListings), {
          title: t('common:menu.listings'),
          index: pathIndexMap[PathsList.listings],
          path: PathsList.listings,
        }),
        ...getArrByCondition(Boolean(hasEvents), {
          title: t('common:menu.events'),
          index: pathIndexMap[PathsList.events],
          path: PathsList.events,
        }),
        ...getArrByCondition(Boolean(hasCompanies), {
          title: t('common:menu.localBiz'),
          index: pathIndexMap[PathsList.localBiz],
          path: PathsList.localBiz,
        }),
        ...getArrByCondition(Boolean(hasAmenities), {
          title: t('common:menu.amenities'),
          index: pathIndexMap[PathsList.amenities],
          path: PathsList.amenities,
        }),
      ],
      root: 'explore',
    };

  if (inboxPaths.includes(path as inboxPathsT))
    return {
      paths: [
        {title: t('common:menu.chat'), index: pathIndexMap[PathsList.messages], path: PathsList.messages},
        {title: t('common:menu.bookings'), index: pathIndexMap[PathsList.bookings], path: PathsList.bookings},
      ],
      root: 'inbox',
    };

  return undefined;
};

export const getMobPath = (location: string) => {
  const pathString = location.trim();
  const formatedPath =
    pathString?.[pathString.length - 1] === '/' ? pathString.substring(0, pathString.length - 1) : pathString;
  const path = formatedPath.split('/').pop();
  const paramIndex = path?.indexOf('?') || -1;
  return paramIndex >= 0 ? path?.substring(0, paramIndex) : path;
};

export const hideMobNav = (pathName: string) =>
  !!hideNavPaths.filter((el) => pathName.toLowerCase().includes(el.toLowerCase())).length;

export const getNavTitles = (pathName: string) => {
  if ([PathsList.listings, PathsList.amenities, PathsList.events].filter((el) => pathName.includes(el)).length)
    return 'explore';
  return null;
};

export const showGoBack = (path?: string) => {
  return path?.includes(paths.profile);
};

export const visibleMobNav = (pathName: string) =>
  !!visibleNavPaths.filter((el) => pathName.toLowerCase().includes(el.toLowerCase())).length;

export const excludePrevPaths = (path: string) => !['/', 'listing-view', '/auth'].some((el) => path.includes(el));
