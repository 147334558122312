import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Seo} from '../../../components/SEO';
import {PersonalSettings} from '../../../components/Settings/Personal/PersonalSettings';
import {ProfileWithCredentials, TVerifyPhoneData} from '../../../components/Settings/Personal/SettingsContacts';
import {ContactsFormSchema, ProfileFormSchema} from '../../../components/Settings/schemas';
import {makeSeoKeywords} from '../../../helpers/common';
import {isImage} from '../../../helpers/settings';
import {Profile} from '../../../types/profile';
import {ContactsFormValues, ProfileFormValues, TInterestsRecmndsValues} from '../../../types/settings';
import {NavContainer} from '../../Navigation';

import {useGetActualCommunityList} from '../../../hooks/community';
import {useVerification} from '../../../hooks/phone';
import {useFormEdit, useUpdateProfileForm} from '../../../hooks/settings';
import {useGenerateUsername, useProfile, useUpdateProfilePhoto, useUpdateUser, useViewer} from '../../../hooks/user';
import {GENDER_API} from '../../../constants/env';
import {useRecoilValue} from 'recoil';
import {currentCommunity} from '../../../states/community';

export const Personal: React.FC = () => {
  const user = useViewer();
  const profile = useProfile();
  const editContacts = useFormEdit();
  const editProfile = useFormEdit();
  const editInterests = useFormEdit();
  const generateUsername = useGenerateUsername();
  const {t} = useTranslation();
  const textSeo = t('common:seo.settings.personal');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const communities = useGetActualCommunityList();
  const community = useRecoilValue(currentCommunity);
  const [error, setError] = useState('');
  const [photoError, setPhotoError] = useState('');
  const [showModalVerify, setShowVerify] = useState(false);
  const updateUser = useUpdateUser();
  const [gender, setGender] = useState<'man' | 'woman' | ''>('');

  useEffect(() => {
    const getPersonGender = async () => {
      const response = await fetch(
        `https://gender-api.com/get?name=${user?.firstName}&country=${user?.Location?.Country?.name}&key=${GENDER_API}`,
      );
      if (response.ok) {
        const data = await response.json();
        const gender = data.gender !== 'unknown' ? (data.gender === 'male' ? 'man' : 'woman') : '';
        setGender(gender);
      }
    };
    getPersonGender();
  }, [user?.firstName, user?.Location?.Country?.name]);

  const profileInit: Partial<ProfileFormValues> = {
    gender: profile?.gender,
    username: profile?.username,
    bio: profile?.bio,
    study: profile?.study,
    work: profile?.work,
    languages: profile?.languages,
    birthDate: user?.birthDate ? new Date(user?.birthDate) : undefined,
    firstName: user?.firstName,
    lastName: user?.lastName,
    occupation: user?.occupation,
  };

  const profileWithCredentials: ProfileWithCredentials = {
    email: user?.email,
    phone: user?.phone,
    aptSuite: user?.aptSuite,
    address: user?.address,
    zip: user?.zip,
    Location: profile?.Location,
  };

  const profileInterests: TInterestsRecmndsValues = {
    personInterests: profile?.personInterests,
    recommendations: profile?.recommendations,
  };

  const formContacts = useUpdateProfileForm<ContactsFormValues, Profile>({
    initialState: profileWithCredentials,
    onSuccess: () => editContacts.handleEditEnd(),
    validationSchema: ContactsFormSchema,
  });

  const formProfile = useUpdateProfileForm<ProfileFormValues, Profile>({
    onSuccess: () => editProfile.handleEditEnd(),
    initialState: profileInit,
    validationSchema: ProfileFormSchema,
  });

  const formInterests = useUpdateProfileForm<TInterestsRecmndsValues, Profile>({
    onSuccess: () => editInterests.handleEditEnd(),
    initialState: profileInterests,
  });

  const {onSubmit, values} = formContacts;

  const phoneData = useVerification(
    {
      onError: (err) => setError(typeof err === 'object' ? err?.message : err),
      onSuccess: () => {
        setError('');
        return setShowVerify(true);
      },
    },
    {
      onError: (err) => setError(typeof err === 'object' ? err?.message : err),
      onSuccess: () => {
        setError('');
        setShowVerify(false);
        onSubmit();
      },
    },
  );

  const {sendCode, verifyCode, sendCodeData, verifyCodeData} = phoneData;

  const checkFile = (file: File) => {
    setPhotoError('');
    if (file && file?.size / 1024 / 1024 > 10) {
      setPhotoError(t('error:invalidSize'));
      return false;
    } else if (!isImage(file?.name)) {
      setPhotoError(t('error:invalidFormat'));
      return false;
    }
    return true;
  };

  const {call: updateAvatar, loading: loadingAvatar} = useUpdateProfilePhoto();

  const handleUpdatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && checkFile(file))
      updateAvatar?.(file, user?.id ?? '')
        .then(() => (e.target.value = ''))
        .catch(() => {
          e.target.value = '';
        });
  };
  const handleUpdatePhotoAsRandom = async (file: File) => {
    if (file && checkFile(file)) {
      const res = await updateAvatar?.(file, user?.id ?? '');
      return !!res;
    }
    return false;
  };
  const isCanEditCountry =
    !communities?.length || !user?.Location?.Country?.id || community?.Owner?.objectId === user?.objectId;

  const handleSubmitContacts = () => {
    if (!values.phone) {
      setError('error:phone');
      return Promise.resolve(false);
    }
    if (profileWithCredentials.phone === values.phone) return onSubmit();
    if (user?.phone !== values.phone) {
      return sendCode(values.phone).then((res) => {
        if (!res) setError('auth:verification.phone.error');
        return res;
      });
    }
  };

  const closeModalVerify = () => {
    setShowVerify(false);
  };

  const handleCancelContacts = () => {
    if (profileWithCredentials.phone !== values.phone) {
      updateUser({
        id: user?.objectId as string,
        fields: {
          phone: profileWithCredentials.phone,
        },
      });
    }

    return editContacts.handleEditEnd();
  };

  const verifyPhoneData: TVerifyPhoneData = {
    error: error,
    closeModal: closeModalVerify,
    showModal: showModalVerify,
    verifyCode: (code: string) => {
      return verifyCode(values.phone as string, code).then((res) => {
        if (!res) setError('auth:verification.code.error');
        return res;
      });
    },
    resendCode: () => {
      return sendCode(values.phone as string);
    },
    loading: sendCodeData.loading || verifyCodeData.loading,
  };
  return (
    <NavContainer>
      <Seo title={textSeo} keywords={seoKeywords} />
      <PersonalSettings
        handleUpdatePhotoAsRandom={handleUpdatePhotoAsRandom}
        gender={gender}
        profile={profileInit}
        profileWithCredentials={profileWithCredentials}
        profileInterests={profileInterests}
        user={user}
        formProfile={formProfile}
        formContacts={{...formContacts, onSubmit: handleSubmitContacts}}
        formInterests={formInterests}
        editInterests={editInterests}
        editProfile={editProfile}
        editContacts={{...editContacts, handleEditEnd: handleCancelContacts}}
        generateUsername={generateUsername}
        updateAvatar={handleUpdatePhoto}
        updatePhotoError={photoError}
        loadingAvatar={loadingAvatar}
        isCanEditCountry={isCanEditCountry}
        verifyPhoneData={verifyPhoneData}
      />
    </NavContainer>
  );
};
