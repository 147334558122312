import React from 'react';
import {AIIconWrapper, AIPromotionWrapper, FirstLineWrapper, LogoWrapper, TextWrapperPromotion} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Text} from '../../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';

export const BetaLogo: React.FC = () => {
  const {t} = useTranslation();
  return (
    <LogoWrapper>
      <Text size={12}> {t('listings:promotion.loop.logo')}</Text>
    </LogoWrapper>
  );
};

export const AiPromotion: React.FC<{isShow: boolean; redirect: () => void}> = ({redirect, isShow}) => {
  const {t} = useTranslation();
  if (!isShow) return null;
  return (
    <AIPromotionWrapper onClick={redirect}>
      <IconSvg type={'stars'} fill={'lightYellow'} stroke={'lightYellow'} width={'16px'} viewBox={'0 2 18 18'} />
      <TextWrapperPromotion>
        <FirstLineWrapper>
          <Text size={16} color={'denary'}>
            {t('listings:promotion.loop.title')}
          </Text>
          <BetaLogo />
        </FirstLineWrapper>
        <Text color={'tertiary'} size={12}>
          {t('listings:promotion.loop.description')}
        </Text>
      </TextWrapperPromotion>
      <IconSvg width={'16px'} stroke={'white'} type={'arrow-right'} viewBox={'2 1 12 12'} />
    </AIPromotionWrapper>
  );
};

export const AIIcon: React.FC<{isShow: boolean; redirect: () => void}> = ({isShow, redirect}) => {
  const {t} = useTranslation();

  if (!isShow) return null;
  return (
    <AIIconWrapper onClick={redirect}>
      <Text size={12}>{t('listings:promotion.create.try')}</Text>
      <IconSvg type={'stars'} width={'16px'} viewBox={'0 1 18 18'} />
    </AIIconWrapper>
  );
};
