import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {Settings, TSettingsBaseProps} from '../../../components/Account/Popups/Settings/Settings';
import {CustomCommunity} from '../../../components/Account/Popups/Settings/types';
import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {PlanOptionsValues} from '../../../constants/plan';
import {route} from '../../../constants/routes';
import {
  CommunityTypes,
  setCurrentCommunity as setCurrentCommunityInLS,
  useHasPlanOption,
} from '../../../helpers/community';
import {aliasPart, CommunityStateType, currentCommunity} from '../../../states/community';

import {useIsModal, useLinks} from '../../../hooks/common';
import {useGetMenuCommunities, useGetUserPendingCommunities, useJoinToCommunity} from '../../../hooks/community';
import {useMarkCommunitySwitched, useViewer} from '../../../hooks/user';
import {getInviteData} from '../../../helpers/common';
import {useResetPreload} from '../../../hooks/preload';
import {useAutoAddToCommunity} from '../../../hooks/auth';

export const SettingsContainer: React.FC<TSettingsBaseProps> = (props) => {
  const {setActive} = props;

  const viewer = useViewer();
  const {
    data: listOfJoinedCommunities,
    hasMore: hasMoreItems,
    loading: loadingCommunities,
    loadMore,
  } = useGetMenuCommunities([], viewer?.id, 11, viewer?.Communities?.count);
  const [community, setCommunity] = useRecoilState(currentCommunity);
  const [loading, setLoading] = useState(false);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const hasListingFeed = useHasPlanOption(PlanOptionsValues.listingsFeed);
  const setAlias = useSetRecoilState(aliasPart);
  const {data: listPendingCommunities} = useGetUserPendingCommunities(listOfJoinedCommunities);
  const {onSubmit: join, loading: joinLoading} = useJoinToCommunity();
  useIsModal();
  const reset = useResetPreload();
  const communitiesWithoutCurrent = useMemo(
    () => [...listPendingCommunities, ...listOfJoinedCommunities]?.filter((el) => el.objectId !== community?.objectId),
    [listOfJoinedCommunities, listPendingCommunities, community?.objectId],
  ) as CustomCommunity[];
  const markSwitched = useMarkCommunitySwitched();
  const {addByKey, loading: loadingAddByKey} = useAutoAddToCommunity();

  const setCurrentCommunity = async (id: string) => {
    const newCommunity = communitiesWithoutCurrent.find((el) => el.objectId === id);
    if (!newCommunity) return;
    if (newCommunity.pendingToJoin) {
      push(getLink(route.joinCommunity.get({id: id || ''})));
      return;
    }
    if (newCommunity.invited && newCommunity.objectId) {
      if (joinLoading || loadingAddByKey) return;
      if (newCommunity.invited === CommunityTypes.neighbourhood) {
        await addByKey(newCommunity.objectId);
        return;
      }
      await join({
        localCommId: newCommunity?.objectId,
        localUserId: viewer?.objectId,
        email: viewer?.email,
        invite: {communityId: newCommunity.objectId, ...getInviteData(newCommunity.invited)},
      });
      return;
    }
    setCommunity(newCommunity as CommunityStateType);
    reset();
    markSwitched();
    setLoading(true);
    newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
    setActive(false);
    setLoading(false);
    push(getLink(route.loop.get(), newCommunity.alias));
  };

  if (loading) return <LottieLoader allScreen={true} $isVisible={loading} />;

  const haveAdminRank = useMemo(
    () => listOfJoinedCommunities.some((cmt) => cmt.Admins?.some((usr) => usr.User?.objectId === viewer?.objectId)),
    [listOfJoinedCommunities, viewer],
  );
  const haveManagerRank = useMemo(
    () => listOfJoinedCommunities.some((cmt) => cmt.Owner?.objectId == viewer?.objectId),
    [listOfJoinedCommunities, viewer],
  );

  const openSearch = () => {
    setAlias(undefined);
    push(getLink(route.searchCommunityResident.path));
  };

  return (
    <Settings
      {...props}
      community={community}
      setCurrentCommunity={setCurrentCommunity}
      listCommunities={communitiesWithoutCurrent}
      hasListingFeed={!!hasListingFeed}
      haveAdminRank={haveAdminRank}
      haveManagerRank={haveManagerRank}
      openSearch={openSearch}
      loadMore={loadMore}
      loadingCommunities={loadingCommunities}
      hasMoreItems={hasMoreItems}
      joinLoading={joinLoading}
    />
  );
};
