import {AccountCheckListType} from '../queries/types/checklist';
import {AccountCheckList, listingsCheckListKeys} from '../components/Checklist/accountCheckList';
import {TypeCommunity} from '../types/auth';
import {useRecoilValue} from 'recoil';
import {currentCommunity} from '../states/community';
import {userState} from '../states';
import {hasPlanOption} from '../helpers/community';
import {PlanOptionsValues} from '../constants/plan';
import {typeUser as StateTypeUser} from '../states/typeUser';

export const useGetCheckListInfo = () => {
  const community = useRecoilValue(currentCommunity);
  const user = useRecoilValue(userState);
  const typeUser = useRecoilValue(StateTypeUser);
  const listingsAvailable = hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.featureListings);
  const checkList = {...(user?.checkList || {}), ...(community?.checkList || {})} as AccountCheckListType;
  const actualChecklist =
    community?.Owner && checkList
      ? AccountCheckList.filter((el) => {
          if (!listingsAvailable) {
            if (listingsCheckListKeys.includes(el.dataKey)) return false;
          }
          return (
            !!checkList[el.dataKey] && !checkList[el.dataKey].isHide && el.roles.includes(typeUser as TypeCommunity)
          );
        })
      : [];

  const userCheckList =
    community?.Owner && checkList
      ? AccountCheckList.filter((el) => {
          if (!listingsAvailable) {
            if (listingsCheckListKeys.includes(el.dataKey)) return false;
          }
          return !!checkList[el.dataKey] && el.roles.includes(typeUser as TypeCommunity);
        })
      : [];
  const completedCheckList = !!actualChecklist?.filter((el) => checkList?.[el?.dataKey]?.isChecked === false).length;

  let checklistCompletion =
    ((userCheckList.length - actualChecklist?.filter((el) => checkList?.[el?.dataKey]?.isChecked === false).length) /
      userCheckList.length) *
    100;
  checklistCompletion = Math.round(checklistCompletion / 10) * 10;
  return {completedCheckList, checklistCompletion, actualChecklist};
};
