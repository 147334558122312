import {atom, selector} from 'recoil';

import {Currency} from '../queries/types/currency';
import {SkipParams, UserInfoT} from '../types/auth';
import {TCommunity} from '../types/community';
import {PermissionsList} from '../types/settings';
import {UserPartsT} from '../types/people';

export type CommunityItemStateType = Partial<TCommunity>;
export type CommunityStateType = Partial<TCommunity> | null | undefined;
export type UserInfoStateType = UserInfoT;

export const currentCommunity = atom<CommunityStateType>({
  key: 'currentCommunity',
  default: null,
});

export const aliasPart = atom<string | undefined>({
  key: 'aliasPart',
  default: undefined,
});

export const aliasCommunity = atom<CommunityStateType>({
  key: 'aliasCommunity',
  default: undefined,
});

export const skipParams = atom<SkipParams>({
  key: 'skipParams',
  default: {},
});

export const isApartment = atom<boolean>({
  key: 'isApartment',
  default: true,
});

export const userCommunities = atom<Array<CommunityItemStateType>>({
  key: 'communities',
  default: [],
});

const defaultUserInfo = {
  hasMessages: undefined,
  isAuth: false,
};

export const userInfo = atom<UserInfoStateType>({
  key: 'userInfo',
  default: defaultUserInfo,
});

export interface InviteState {
  communityId: string;
  inviteId: string;
  inviteEmail: string;
}

export const inviteToCommunityState = atom<InviteState | undefined>({
  key: 'invite',
  default: undefined,
});

export type TCurrencyState = Currency | undefined | null;

export const currencyState = selector<TCurrencyState>({
  key: 'currency',
  get: ({get}) => {
    const s = get(currentCommunity);
    if (!s) {
      return null;
    }
    return s.Subscr?.PaymentPlan.Currency;
  },
  set: ({set, get}, newValue) => {
    const s = get(currentCommunity);
    if (!s) {
      return null;
    }
    const subscr = {...s.Subscr, PaymentPlan: {...s.Subscr?.PaymentPlan, Currency: newValue}} as TCommunity['Subscr'];

    set(currentCommunity, {...s, Subscr: subscr});
  },
});

export const userPermissions = atom<PermissionsList[]>({
  key: 'userPermissions',
  default: [],
});

export const enableSwipeableLoop = atom<boolean>({
  key: 'enableSwipeableLoop',
  default: false,
});

export interface passCodeToCommunityState {
  passCode: string;
}

export const passCodeToCommunity = atom<passCodeToCommunityState | null>({
  key: 'passCodeToCommunity',
  default: null,
});

export const inviteReferralState = atom<{code: string; user: UserPartsT} | null>({
  key: 'inviteReferralState',
  default: null,
});

export const onlineUsers = atom<string[]>({
  key: 'onlineUsers',
  default: [],
});
