import React from 'react';
import {CardRatingProps} from '../types';
import {RequestCardReviewBase} from './Base';
import {CardTags} from '../CardTags';
import {PaymentLabel} from '../PaymentLabel';
import {CardRatingSection} from '../CardRatingSection';
import {CardButton} from '../CardButton';
import {CardInfoText} from '../styles';
import {CreditBlock, DatesTextInfo} from '../Card';

export const Rating: React.FC<CardRatingProps> = ({
  descr,
  opponentInfo,
  itemInfo,
  rating,
  rewardType,
  dateLabel,
  buttonLabel,
  tags,
  paymentLabel,
  pricePeriod,
  isRequester,
  showCredit,
  toggleCredit,
  creditInfo,
  dateInfoLabels,
}) => {
  return (
    <RequestCardReviewBase title={rating.title} buttonLabel={buttonLabel}>
      <CardTags tags={tags} />
      {!showCredit && <DatesTextInfo dateLabel={dateLabel} pricePeriod={pricePeriod} dateInfoLabels={dateInfoLabels} />}
      {!showCredit ? (
        <PaymentLabel
          rewardType={rewardType}
          descr={paymentLabel.descr}
          title={paymentLabel.title}
          onClick={() => toggleCredit?.(true)}
        />
      ) : (
        <CreditBlock close={() => toggleCredit?.(false)} creditInfo={creditInfo} />
      )}

      <CardRatingSection rating={rating} info={[opponentInfo, itemInfo]} isRequester={isRequester} />
      {buttonLabel.primaryReview && buttonLabel.secondaryReview && (
        <CardButton primary={buttonLabel.primaryReview} secondary={buttonLabel.secondaryReview} type={'primary'} />
      )}
      <CardInfoText>{descr}</CardInfoText>
      {buttonLabel.primaryReview && buttonLabel.secondaryReview && (
        <CardButton primary={buttonLabel.primaryReview} secondary={buttonLabel.secondaryReview} type={'secondary'} />
      )}
    </RequestCardReviewBase>
  );
};
