import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {SelectPlanCommunity} from '../../../../components/Account/Create/SelectPlan/SelectPlan';
import {LottieLoader} from '../../../../components/Loader/LottieLoader';
import {Steps} from './Steps';
import {route} from '../../../../constants/routes';
import {getRouteStepCreate} from '../../../../helpers/account';
import {isProfileCreated} from '../../../../helpers/user';
import {CommunityStateType, currentCommunity} from '../../../../states/community';
import {TypeCommunity} from '../../../../types/auth';
import {PaymentPeriodType} from '../../../../types/payment';

import {useLinks} from '../../../../hooks/common';
import {useCommunity, useSelectPlan} from '../../../../hooks/community';
import {useGetCurrencyValue} from '../../../../hooks/currency';
import {useViewer} from '../../../../hooks/user';

const SelectPlanContainer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const {push} = useHistory();
  const viewer = useViewer();
  const {data: firstCommunity, loading: loadingCmnt} = useCommunity(viewer?.id, TypeCommunity.manager);
  const isProfile = isProfileCreated(viewer);
  const {getLink} = useLinks();
  const [currComm] = useRecoilState(currentCommunity);
  const {getCodeValue, currency, getSignValue} = useGetCurrencyValue();

  const community = !!currComm ? ({} as CommunityStateType) : firstCommunity;

  const params = useSelectPlan({
    initialState: {
      // plan: community?.Subscr?.PaymentPlan.objectId,
      billing: PaymentPeriodType.year,
    },
    communityId: community?.id,
    onSuccess: () => push(getLink(route.paymentCommunity.path)),
  });

  useEffect(() => {
    if (!viewer || loadingCmnt) {
      return setLoading(true);
    }
    return setLoading(false);
  }, [viewer, loadingCmnt]);

  useEffect(() => {
    if (!viewer || !community) return;
    const successPath = getLink(
      getRouteStepCreate({
        isProfile,
      }),
    );
    successPath && push(successPath);
  }, [viewer, community]);

  if (loading && (!community || !viewer)) return <LottieLoader $isVisible={loading} allScreen={true} />;
  return (
    <Steps step={'plan'}>
      <SelectPlanCommunity
        params={params}
        getCodeValue={getCodeValue}
        getSignValue={getSignValue}
        currency={currency?.code || ''}
      />
    </Steps>
  );
};

export default SelectPlanContainer;
