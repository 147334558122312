import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useItemPageContent, useResidentActionsItem} from '../../../hooks/item';
import {GQLButtonsResidentTypes, GQLItemWhereInput} from '../../../graphql.schema';
import {PageResident} from '../../../components/Listings/PageResident';
import {CommunityStateType} from '../../../states/community';
import {entities, useReport} from '../../../hooks/report';
import {useGetCurrencyValue} from '../../../hooks/currency';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
};
export const PageResidentContainer: React.FC<TProps> = ({viewerId, community}) => {
  const [searchParams, setSearchParams] = useState<GQLItemWhereInput[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const location = useLocation();

  const {
    loadingAllItems,
    refetchAllItems,
    refetchAppAndFeat,
    hasMoreAllItems,
    fetchDataAllItems,
    communityItems,
    onLike,
    liked,
    featureLoading,
    featured,
  } = useItemPageContent(searchParams, community);
  const {getSignValue} = useGetCurrencyValue();

  const actions = useReport(entities.listing);

  const onSuccess = () => {
    refetchAppAndFeat();
    refetchAllItems();
  };
  const {onSubmit: onActionsItemResident, loading: loadActionResident} = useResidentActionsItem({
    communityId: community?.objectId,
    onSuccess,
  });

  useEffect(() => {
    if (!firstLoad) {
      refetchAllItems();
      setFirstLoad(false);
    }
    refetchAppAndFeat();
  }, [location]);

  const unlistItem = (itemId?: string) => () => {
    onActionsItemResident({itemId, typeBtn: GQLButtonsResidentTypes.unlist});
  };

  const onPublishItem = (itemId?: string) => () => {
    onActionsItemResident({itemId, typeBtn: GQLButtonsResidentTypes.publish});
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityItems.length && refetchFeaturedItems({first: communityItems.length});
  //     communityItems.length && refetchAllItems({first: communityItems.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityItems.length]);
  const loading =
    !communityItems?.length || !featured?.length ? loadingAllItems || loadActionResident || featureLoading : false;
  return (
    <PageResident
      community={community}
      loading={loading}
      allItems={communityItems}
      fetchDataAllItems={fetchDataAllItems}
      hasMoreAllItems={hasMoreAllItems}
      onUnlist={unlistItem}
      onPublish={onPublishItem}
      setSearchParams={setSearchParams}
      userId={viewerId}
      reportActions={actions}
      getSignValue={getSignValue}
      onLike={onLike}
      liked={liked}
    />
  );
};
