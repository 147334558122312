import {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {useMutation, useQuery} from '@apollo/client';
import {useSignOut} from './auth';
import {getCommunityAlias, getCommunityByAlias, useGetListCommunities, useIsInAliasedCommunity} from './community';
import {FormErrorFields, useFormError} from './error';
import {SUPPORT_EMAIL_MASK} from '../constants/common';
import {GQLReviewOrder, GQLUserOrder, GQLUserWhereInput} from '../graphql.schema';
import {changeFileName} from '../helpers/file';
import {FormValidation} from '../helpers/FormValidation';
import {getTypeUserByTCommunity, getUserProfileFields} from '../helpers/user';
import {ViewerQuery, ViewerResponseType} from '../queries/auth';
import {GetOrderReviewResType, getOrderReviews} from '../queries/reviews';
import {User} from '../queries/types/user';
import {
  DeactivateUser,
  GenerateUsername,
  GenerateUsernameRequest,
  GenerateUsernameResponse,
  GetUsersCommunities,
  GetUsersCommunitiesResponse,
  GetUsersPermissionsParams,
  GetUsersPermissionsQuery,
  GetUsersPermissionsRes,
  GetUsersQuery,
  openAIGenerate,
  OpenAIGenerateRequest,
  OpenAIGenerateResponse,
  RemoveUser,
  RemoveUserRequest,
  rmUserPushNotification,
  rmUserPushNotificationT,
  SendVerifyEmailParams,
  SendVerifyEmailQuery,
  SendVerifyEmailRes,
  UpdateProfile,
  UpdateProfileRequestType,
  UpdateProfileResponseType,
  updatePushNotification,
  updatePushNotificationRequest,
  UsersResponseType,
  СonfirmUserRemoval,
} from '../queries/user';
import {
  aliasCommunity,
  aliasPart,
  currentCommunity,
  inviteToCommunityState,
  userPermissions,
} from '../states/community';
import {userState} from '../states/session';
import {SocialAuth, TypeCommunity} from '../types/auth';
import {imageType} from '../types/common';
import {Profile} from '../types/profile';
import {PermissionsList} from '../types/settings';
import {analyticsTrackFN} from '../helpers/account';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {route} from '../constants/routes';
import {getInviteData} from '../helpers/common';
import {useGetSkipParams, useLinks} from './common';
import {useGetReferral} from './referral';
import {PlanOptionsValues} from '../constants/plan';
import {hasPlanOption} from '../helpers/community';
import {TCommunity} from '../types/community';

export const useViewer = () => {
  const user = useRecoilState(userState);

  return user[0];
};

export const useViewerId = (field?: 'id' | 'objectId'): string | undefined => {
  return useViewer()?.[field || 'id'];
};

export const useProfile = (): Profile | null => {
  const user = useViewer();
  if (!user) return null;
  return getUserProfileFields(user);
};

const ProfileSchema = FormValidation.schema<Partial<Profile & {typeCommunity?: TypeCommunity}>>({
  username: FormValidation.string('error:username'),
  bio: FormValidation.string().isNullable,
  languages: FormValidation.string().isNullable,
  // study: FormValidation.string().isNullable,
  // work: FormValidation.string().isNullable,
  // howDidYouHear: FormValidation.handler((value, data) => {
  //   if (data.typeCommunity === TypeCommunity.manager) return true;
  //   return Boolean(value);
  // }, 'error:howDidYouHear'),
  // birthDate: FormValidation.handler((value, data) => {
  //   if (data.typeCommunity === TypeCommunity.manager) return true;
  //   return Boolean(value);
  // }, 'error:birthDate'),
});

type FormValue = NonNullable<Profile[keyof Profile]>;

export type ResProfileType = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<Profile>;
  values: Partial<Profile>;
  success: string | null;
  onChange: (next: {name: string; value: FormValue}) => void;
};

type UseUpdateProfileType = (options?: {
  initialState?: Partial<Profile>;
  typeCommunity: TypeCommunity;
  onSuccess?: () => void;
  onError?: () => void;
}) => ResProfileType;

export const useUpdateProfile: UseUpdateProfileType = (options) => {
  const updateUser = useSetRecoilState(userState);
  const [values, setValues] = useState<Partial<Profile>>(options?.initialState || {});
  const [error, setError] = useFormError<Profile>();
  const [success, setSuccess] = useState<string | null>(null);

  const userId = useViewerId();
  const [ChangeProfileRequest, {loading}] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(
    UpdateProfile,
  );

  const handleChange = (next: {name: string; value: FormValue}) => {
    setError(null);
    setSuccess(null);
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const updateProfile = async (): Promise<boolean> => {
    if (!userId) return false;

    try {
      if (
        !ProfileSchema.validate<Profile & {typeCommunity?: TypeCommunity}>({
          ...values,
          typeCommunity: options?.typeCommunity,
        })
      ) {
        return false;
      }

      // remove when this fields will be implemented on backend
      delete values.livingWith;

      const response = await ChangeProfileRequest({
        variables: {id: userId, fields: values},
      });

      const updatedUser = response?.data?.updateUser?.user;
      if (!updatedUser) throw new Error();
      updateUser(updatedUser);
      setSuccess('Profile saved successfully');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit: updateProfile,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

type GenerateProps = (options?: {
  firstName?: string;
  city?: string;
  interests?: string;
  language?: string;
  occupation?: string;
  foodRecommendations?: string;
  moviesRecommendation?: string;
  travelRecommendation?: string;
}) => {
  onSubmit: (type: 'bio' | 'post', additional?: {language: string; occupation: string}) => Promise<string>;
  loading: boolean;
};

export const useGenerateUserData: GenerateProps = (options) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [generate] = useMutation<OpenAIGenerateResponse, OpenAIGenerateRequest>(openAIGenerate);
  const onSubmit = async (type: 'bio' | 'post', additional?: {language: string; occupation: string}) => {
    try {
      setLoading(true);
      const result = await generate({
        variables: {
          textType: type,
          promptData: [
            {
              firstName: options?.firstName,
              moviesRecommendations: options?.moviesRecommendation,
              foodRecommendations: options?.foodRecommendations,
              travelRecommendations: options?.travelRecommendation,
              city: options?.city,
              interests: options?.interests,
              occupation: additional?.occupation || options?.occupation,
              canSpeak: additional?.language || options?.language,
            },
          ],
        },
      });
      return result?.data?.generateAiOpenApiText?.gptResponseText || '';
    } finally {
      setLoading(false);
    }
  };
  return {onSubmit, loading};
};

export const useGetTypeUser = (): TypeCommunity | undefined => {
  const viewer = useViewer();
  const [community] = useRecoilState(currentCommunity);

  return getTypeUserByTCommunity(viewer?.objectId, community);
};

export const useUpdateUser = () => {
  const [mutate] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(UpdateProfile);

  const update = (variables: UpdateProfileRequestType) => {
    return mutate({variables});
  };

  return update;
};

export const useGenerateUsername = () => {
  const [mutate] = useMutation<GenerateUsernameResponse, GenerateUsernameRequest>(GenerateUsername, {
    fetchPolicy: 'no-cache',
  });

  return async (name: string) => {
    const {data} = await mutate({variables: {name}});
    return data?.generateUsername.name;
  };
};

export const useUpdateProfilePhoto = () => {
  const [update, otherData] = useMutation<UpdateProfileResponseType>(UpdateProfile);
  const setUser = useSetRecoilState(userState);

  const call = async (photo: File | undefined, id: string) => {
    const file = photo ? changeFileName(photo) : photo;
    const variables = {
      id,
      fields: {
        Avatar: {
          createAndLink: {
            Owner: {
              link: id,
            },
            file: {
              upload: file,
            },
            type: imageType.avatar,
          },
        },
      },
    };
    const response = await update({variables});
    if (response.data?.updateUser.user) {
      setUser(response.data.updateUser.user);
    }
    return response.data?.updateUser.user;
  };

  return {
    call,
    ...otherData,
  };
};

type UsersParams = {
  where?: GQLUserWhereInput;
  order?: GQLUserOrder[];
  cursor?: string;
  first?: number;
  id?: string;
};

export const useGetUsers = (params: {
  where?: GQLUserWhereInput;
  order?: GQLUserOrder;
  skip?: boolean;
  first?: number;
}) => {
  const previousCursor = useRef<string | null>(null);

  const variables = {
    ...(params?.where ? {where: params.where} : {}),
    ...(params?.order ? {order: [params.order]} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<UsersResponseType, UsersParams>(GetUsersQuery, {
    variables,
    notifyOnNetworkStatusChange: true,
    ssr: true,
  });

  const fetch = async () => {
    const {hasNextPage, endCursor} = data?.users?.pageInfo || {};

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.users?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.users.edges;
          const nextChunk = fetchMoreResult.users.edges;

          return {
            users: {...fetchMoreResult.users, edges: prevChunk.concat(nextChunk)},
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: data?.users?.edges?.map((edge) => edge.node) || [],
    loading,
    total: data?.users?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.users?.pageInfo?.hasNextPage),
    refetch,
  };
};

export const useUserReviews = (objectId?: string) => {
  const {data, loading} = useQuery<GetOrderReviewResType>(getOrderReviews, {
    variables: {
      where: {
        subject: {
          equalTo: 'user',
        },
        OR: [
          {
            Author: {
              have: {
                id: {
                  equalTo: objectId,
                },
              },
            },
          },
          {
            User: {
              have: {
                id: {
                  equalTo: objectId,
                },
              },
            },
          },
        ],
      },
      order: GQLReviewOrder.createdAt_DESC,
    },
    skip: !objectId,
    fetchPolicy: 'no-cache',
  });

  return {data, loading};
};

export const useUserCommunities = (userId?: string) => {
  const {data} = useQuery<GetUsersCommunitiesResponse>(GetUsersCommunities, {
    variables: {
      where: {
        id: {
          equalTo: userId,
        },
      },
      first: 5000,
    },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  return data?.users?.edges?.map((e) => e?.node.Communities.edges.map((e) => e.node)).flat();
};

export const useDeleteUser = () => {
  const [deleteUsr] = useMutation<boolean, RemoveUserRequest>(RemoveUser);
  const [confirm] = useMutation<boolean>(СonfirmUserRemoval);
  const {signOut} = useSignOut();
  const viewer = useViewer();

  const deleteUser = async (token: string, email: string) => {
    try {
      if (!email || !token) return;
      await deleteUsr({variables: {email: email, token: token}});
      analyticsTrackFN('Account Deleted', {
        name: viewer?.firstName,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
        zipCode: viewer?.zip,
      });
      await signOut();
    } catch (e) {
      console.log(e);
    }
  };

  const confirmRemoval = async () => {
    try {
      await confirm();
    } catch (e) {
      console.log(e);
    }
  };

  return {deleteUser, confirmRemoval};
};

export const useDeactivateUser = () => {
  const [deactivateUser] = useMutation<boolean>(DeactivateUser);
  const {signOut} = useSignOut();
  const viewer = useViewer();

  const deactivate = async () => {
    try {
      await deactivateUser();
      analyticsTrackFN('Account Deactivated', {
        name: viewer?.firstName,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
        zipCode: viewer?.zip,
      });
      await signOut();
    } catch (e) {
      console.log(e);
    }
  };

  return {deactivate};
};

export const useUserJoinedCommunity = (userId?: string, communityId?: string) => {
  const userCommunities = useUserCommunities(userId);
  return userCommunities?.some((c) => c?.id === communityId || c?.objectId === communityId);
};

export const useRefetchUser = () => {
  const {data, refetch} = useQuery<ViewerResponseType>(ViewerQuery);
  const updateUser = useSetRecoilState(userState);

  useEffect(() => {
    data?.viewer.user && updateUser(data?.viewer.user);
  }, [data]);

  return {refetch};
};

export type useSubscrUser = {
  userId?: string;
  token?: string | null;
};

export type useRemoveSubscrUser = {
  token?: string | null;
};

export const useSetUserPushNotifications = () => {
  const [subscribeUserRequest] = useMutation<undefined, updatePushNotificationRequest>(updatePushNotification);
  const [removeUserRequest] = useMutation<undefined, rmUserPushNotificationT>(rmUserPushNotification);

  const subscribeUserForPush = async (options: useSubscrUser) => {
    if (!options.userId || !options.token) return false;
    try {
      await subscribeUserRequest({variables: {token: options.token, userId: options.userId}});
    } catch (e) {
      return false;
    }
    return true;
  };

  const removeUserForPush = async (options: useRemoveSubscrUser) => {
    if (!options.token) return false;
    try {
      await removeUserRequest({variables: {token: options.token}});
    } catch (e) {
      return false;
    }
    return true;
  };

  return {subscribeUserForPush, removeUserForPush};
};

export const useSendVerifyEmail = () => {
  const user = useRecoilValue(userState);

  const [sendEmail] = useMutation<SendVerifyEmailRes, SendVerifyEmailParams>(SendVerifyEmailQuery, {
    variables: {
      email: user?.email as string,
    },
  });

  return {
    sendEmail,
  };
};

export const isManagerOrAdmin = () => {
  const id = useViewerId('id');

  const {data: listOfJoinedCommunities} = useGetListCommunities(id);
  if (!id) return false;
  return !!listOfJoinedCommunities.filter(
    (el) => el?.Owner?.id === id || el.Admins.map((el) => el?.User?.id || '').includes(id),
  ).length;
};

export const useCheckJoined = () => {
  const id = useViewerId('id');

  const {data: listOfJoinedCommunities} = useGetListCommunities(id, 2);
  if (!id) return {communityCount: 0};
  const listedOwner = !!listOfJoinedCommunities.filter((el) => el?.Owner?.id === id).length;
  const listedAdmin = !!listOfJoinedCommunities.filter((el) => el.Admins.map((el) => el?.User?.id || '').includes(id))
    .length;

  return {listedOwner, listedAdmin, communityCount: Number(listOfJoinedCommunities.length || 0)};
};

export const useIsSupport = () => {
  const viewer = useViewer();
  return viewer?.email?.toLocaleLowerCase()?.includes(SUPPORT_EMAIL_MASK);
};

export const useCheckIsSupport = (options?: {viewer?: User | null; email?: string}) => {
  if (options?.viewer?.email) return options.viewer.email.toLocaleLowerCase().includes(SUPPORT_EMAIL_MASK);
  if (options?.email) return options.email.toLocaleLowerCase().includes(SUPPORT_EMAIL_MASK);
  return false;
};

export const useGetTargetTypeUser = (objectId?: string): TypeCommunity | undefined => {
  const [community] = useRecoilState(currentCommunity);

  return getTypeUserByTCommunity(objectId, community);
};

export const useGetUserPermissions = ({userId, communityId}: {userId?: string; communityId?: string}) => {
  const [data, setData] = useState<any[] | undefined>([]);
  const [getPermission, {called}] = useMutation<GetUsersPermissionsRes, GetUsersPermissionsParams>(
    GetUsersPermissionsQuery,
    {
      variables: {
        userId: userId || '',
        communityId: communityId || '',
      },
    },
  );
  if (data?.length === 0 && !called) {
    if (!userId || !communityId) return;
    getPermission()
      .then((res) => setData(res.data?.getUserPermissionsList.permissionsList || []))
      .catch((e) => console.log('permissions err:', e));
  }
  return data;
};

export const useGetUserPermissionsClient = () => {
  const [getPermission] = useMutation<GetUsersPermissionsRes, GetUsersPermissionsParams>(GetUsersPermissionsQuery);

  return async ({userId, communityId, skip}: {userId?: string; communityId?: string; skip?: boolean}) => {
    if (skip || !communityId) return [];
    const {data} = await getPermission({
      variables: {
        userId: userId || '',
        communityId: communityId || '',
      },
    });
    return data?.getUserPermissionsList.permissionsList as PermissionsList[];
  };
};

export const useRefetchPermissions = () => {
  const [getPermission, {data}] = useMutation<GetUsersPermissionsRes, GetUsersPermissionsParams>(
    GetUsersPermissionsQuery,
  );
  const setPermissions = useSetRecoilState(userPermissions);
  const community = useRecoilValue(currentCommunity);
  const viewer = useViewer();

  useEffect(() => {
    if (!data) return;
    setPermissions(data?.getUserPermissionsList.permissionsList as PermissionsList[]);
  }, [data]);

  const refetchPermissions = async () => {
    getPermission({
      variables: {
        userId: viewer?.objectId || '',
        communityId: community?.objectId || '',
      },
    });
  };

  return {refetchPermissions};
};

export const useSetUserTooltips = () => {
  const updateUser = useSetRecoilState(userState);
  const [ChangeProfileRequest] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(UpdateProfile);
  const user = useViewer();

  const update = async (welcomeTipsViewed: boolean, multiplyCommunityTip: boolean) => {
    if (!user) return false;
    const response = await ChangeProfileRequest({
      variables: {
        id: user?.objectId,
        fields: {
          tips: {
            welcomeTipsViewed: welcomeTipsViewed,
            multiplyCommunityTip: multiplyCommunityTip,
          },
        },
      },
    });

    const updatedUser = response?.data?.updateUser?.user;
    if (!updatedUser) return false;
    updateUser(updatedUser);
    return true;
  };
  return {acceptTips: update};
};

export const useMarkCommunitySwitched = () => {
  const [user, updateUser] = useRecoilState(userState);
  const [ChangeProfileRequest] = useMutation<UpdateProfileResponseType, UpdateProfileRequestType>(UpdateProfile);

  const update = async () => {
    if (!user || user?.tips?.communitySwitched) return false;
    const tips = {
      welcomeTipsViewed: user?.tips?.welcomeTipsViewed || false,
      multiplyCommunityTip: user?.tips?.multiplyCommunityTip || false,
      communitySwitched: true,
    };
    const response = await ChangeProfileRequest({
      variables: {
        id: user?.objectId,
        fields: {
          tips: tips,
        },
      },
    });

    const updatedUser = response?.data?.updateUser?.user;
    if (!updatedUser) return false;
    updateUser(updatedUser);
    return true;
  };
  return update;
};

export const useHomePage = () => {
  //location
  useGetSkipParams();
  const {getLink} = useLinks();
  const location = useLocation();
  const {push} = useHistory();
  const viewer = useViewer();
  const {id: urlInviteId} = useParams<{id?: string}>();

  //states
  const [currentComm] = useRecoilState(currentCommunity);
  const [aliasComm, setCommunity] = useRecoilState(aliasCommunity);
  const [invState] = useRecoilState(inviteToCommunityState);

  //alias
  const [alias] = useRecoilState(aliasPart);
  const urlAlias = location?.pathname?.split('/')?.[1] || '';
  const localAlias = alias || urlAlias;

  //queries
  const {referralLoading, code, referralUser} = useGetReferral();
  const aliasedComm = getCommunityByAlias(localAlias);
  const isInCommunity = useIsInAliasedCommunity(viewer?.objectId, urlAlias);
  const lastVisited = getCommunityAlias(viewer?.lastVisitedCommunity);

  //consts
  const signUpPath = route.social.get({tab: SocialAuth.signUp});
  const isAuth = !!viewer?.id;
  const isInvite = urlInviteId && getInviteData(urlInviteId || '').inviteEmail === viewer?.email;
  const isWrongInvite = urlInviteId && getInviteData(urlInviteId || '').inviteEmail !== viewer?.email;
  const inAliasedCondition = isInCommunity.loading && !isInCommunity.data?.communities?.count;
  const noInvite = !isInvite && !invState;
  const aliasedId = aliasedComm?.data?.objectId;
  const haveCommunityAndNoInvite = !!(isWrongInvite && lastVisited.alias && viewer && !invState);
  const zeroCommunityAndNoInvite = !!(isWrongInvite && !viewer?.Communities?.count && viewer && !invState);
  const defaultHomePage = !!(!aliasedId && isAuth && noInvite);
  const aliasedIsCurrent = aliasedId === currentComm?.objectId && !!currentComm?.objectId && isAuth && noInvite;
  const withoutAliasAndNotAuthorized = (!aliasedId || !urlAlias) && !isAuth && noInvite;

  //links
  const forwardLogin = () => push(route.social.get({tab: SocialAuth.logIn}));
  const forwardSignUp = () => push(route.social.get({tab: SocialAuth.signUp}));
  const forwardJoinIn = () => push(route.joinIn.path);

  //loading
  const l1 = !currentComm?.objectId && !localAlias && !!viewer?.Communities?.count && !isInvite;
  const l2 = !!viewer?.Communities?.count && !isInvite && !!inAliasedCondition;
  const l3 = lastVisited.loading;
  const l4 = !!code && !!referralLoading;
  const pageLoading = l1 || l2 || l3 || l4;

  //hooks
  useEffect(() => {
    if (aliasComm) return;
    setCommunity(aliasedComm.data as unknown as TCommunity);
  }, [aliasedId]);

  const loopEnabled = useMemo(() => {
    if (aliasedIsCurrent)
      return !!hasPlanOption(aliasedComm?.data?.Subscr?.PaymentPlan.options, PlanOptionsValues.communityFeed);
    if (currentComm?.objectId)
      return !!hasPlanOption(currentComm?.Subscr?.PaymentPlan.options, PlanOptionsValues.communityFeed);
  }, [currentComm, aliasedIsCurrent]);

  return {
    getLink,
    push,
    currentComm,
    pageLoading,
    invState,
    referralUser,
    signUpPath,
    isAuth,
    isInvite,
    isWrongInvite,
    lastVisited,
    isInCommunity,
    loopEnabled,
    forwardLogin,
    forwardSignUp,
    forwardJoinIn,
    viewer,
    code,
    aliasedComm,
    urlAlias,
    haveCommunityAndNoInvite,
    zeroCommunityAndNoInvite,
    defaultHomePage,
    aliasedIsCurrent,
    withoutAliasAndNotAuthorized,
  };
};
