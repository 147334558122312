import React from 'react';
import {ProfilePageProps} from './types';
import {useTranslation} from 'react-i18next';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {makeSeoKeywords} from '../../helpers/common';
import {WrapperPage} from './styles';
import {Seo} from '../SEO';
import {ProfileIntro} from '../../ui-kit/ProfileIntro';
import {ProfileSection} from './ProfileSection';
import {ProfileContent} from './content/ProfileContent';
import {status} from '../../ui-kit/ProfileIntro/types';
import {TypeCommunity} from '../../types/auth';
import {ProfileIntroSkeleton} from '../common/Skeletons/ProfileIntroSkeleton';
import {getResize} from '../../helpers/file';
import {getUserName} from '../../helpers/user';
import {RichText} from '../common/RichText/RichText';
import {getAvarageReviewsStars} from '../../helpers/reviews';
import {isModal} from '../../states/themeMode';
import {isMobile} from 'react-device-detect';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useGetInterests} from '../../hooks/interests';
import {onlineUsers} from '../../states/community';

export const ProfilePage: React.FC<ProfilePageProps> = ({
  community,
  user,
  typeUser,
  isOwner,
  reqUserType,
  listingItems,
  listingLoading,
  openMessages,
  reportActions,
  getSignValue,
  communityAllows,
  adminLabels,
  goBack,
  reviewsItems,
  reviewsLoading,
  onScrollToReviews,
  reviewsContainerRef,
  handleWave,
  isWaved,
  liked,
  onLike,
  interestsLink,
  goToGroup,
}) => {
  const {t} = useTranslation();
  const setModal = useSetRecoilState(isModal);
  const online = useRecoilValue(onlineUsers);
  const userName = getUserName(typeUser, user?.firstName, user?.lastName || user?.firstLetter);
  const baseText = t('common:seo.profile');
  const textSeo = `${userName || baseText}`;
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {values: interestsValues} = useGetInterests();

  const statusType =
    reqUserType === TypeCommunity.manager || reqUserType === TypeCommunity.admin ? 'tertiary' : 'secondary';
  const statusLabel =
    (user?.objectId && adminLabels[user.objectId]) ||
    t('common:typeUser', {context: reqUserType || TypeCommunity.resident});

  const buttonAllow = !communityAllows.loading && communityAllows.allow ? t('people:profile.buttons.chat') : undefined;
  const buttonText = isOwner ? t('people:profile.buttons.edit') : buttonAllow;
  const secondButtonText = isOwner
    ? undefined
    : isWaved
    ? t('people:profile.buttons.waved')
    : t('people:profile.buttons.wave');
  const rating = getAvarageReviewsStars(reviewsItems);
  const city = user?.Location?.name;
  const state = user?.Location?.State?.name;
  const correctState =
    state && state?.split(' ').length > 1
      ? state
          ?.split(' ')
          .map((w) => w[0]?.[0])
          .join('')
      : state;
  const country = user?.Location?.Country?.name;

  const handleClick = () => {
    openMessages(user?.objectId);
  };

  return (
    <WrapperPage>
      <Seo title={textSeo} keywords={seoKeywords} />
      <ProfileSection bordered={true} another={true}>
        {Boolean(user) ? (
          <ProfileIntro
            isOnline={!!user?.objectId && online?.includes(user?.objectId)}
            className={'profile__card'}
            isOwner={isOwner}
            isWaved={isWaved}
            goBack={goBack}
            buttonText={buttonText}
            buttonOnClick={handleClick}
            secondButtonText={secondButtonText}
            secondButtonOnClick={handleWave}
            title={userName}
            avatar={getResize(user?.Avatar?.file?.url, 'lg')}
            originalAvatar={user?.Avatar?.file?.url}
            description={user?.bio}
            work={user?.work}
            education={user?.study}
            languages={user?.languages}
            isVerified={user?.emailVerified || user?.phoneVerified || user?.isVerified}
            status={{
              statusType: statusType as status,
              statusName: statusLabel as string,
            }}
            joined={String(user?.createdAt)}
            labels={{
              work: t('common:labels.work'),
              study: t('common:labels.study'),
              joined: t('common:labels.joined'),
              verified: t('common:labels.verified'),
              unverified: t('common:labels.unverified'),
              languages: t('common:labels.languages'),
              reviews: t('common:labels.reviews'),
              reviewsProfile: [city, correctState, country],
            }}
            interestsTitle={t('common:pageUser.interestsTitle')}
            recommendsTitle={t('common:pageUser.recommendationsTitle')}
            interests={user?.personInterests}
            recommends={user?.recommendations || {}}
            RichText={RichText}
            countReviews={reviewsItems?.received?.length || undefined}
            rating={rating}
            onScrollToReviews={onScrollToReviews}
            Gallery={Gallery}
            Item={Item}
            setIsModal={setModal}
            isMobile={isMobile}
            interestsLink={interestsLink}
            interestsValues={interestsValues}
            goToGroup={goToGroup}
          />
        ) : (
          <ProfileIntroSkeleton />
        )}
      </ProfileSection>
      <ProfileContent
        typeUser={typeUser}
        listingItems={listingItems}
        listingLoading={listingLoading}
        communityId={community.objectId}
        reportActions={reportActions}
        getSignValue={getSignValue}
        reviewsItems={reviewsItems}
        reviewsLoading={reviewsLoading}
        reviewsContainerRef={reviewsContainerRef}
        liked={liked}
        onLike={onLike}
      />
    </WrapperPage>
  );
};
