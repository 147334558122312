import {gql} from '@apollo/client';
import {HelpDeskFragment} from './fragments/helpDesk';
import {Connection} from './types/parse';
import {GQLHelpDeskOrder, GQLHelpDeskWhereInput, GQLUpdateHelpDeskFieldsInput} from '../graphql.schema';
import {HelpDeskItem} from './types/helpDesk';
import {CreateHelpDeskFieldsInput} from '../types/helpDesk';
import MemberFragment from './fragments/people';

export const getHelpDesk = gql`
  query GetDocuments($where: HelpDeskWhereInput, $order: [HelpDeskOrder!]) {
    helpDesks(where: $where, order: $order) {
      count
      edges {
        node {
          ...HelpDeskFragment
        }
      }
    }
  }
  ${HelpDeskFragment}
`;

export interface GetHelpDesksRes {
  helpDesks: Connection<HelpDeskItem>;
}

export interface GetHelpDesksParams {
  where?: GQLHelpDeskWhereInput;
  order?: Array<GQLHelpDeskOrder>;
}

export interface CreateHelpDeskResponseType {
  createHelpDesk: {
    helpDesk: HelpDeskItem;
  };
}

export interface CreateHelpDeskRequestType {
  fields: CreateHelpDeskFieldsInput;
}

export const CreateHelpDeskQuery = gql`
  mutation createHelpDesk($fields: CreateHelpDeskFieldsInput) {
    createHelpDesk(input: {fields: $fields}) {
      helpDesk {
        ...HelpDeskFragment
      }
    }
  }
  ${HelpDeskFragment}
`;

export interface UpdateHelpDeskRes {
  updateHelpDesk: {
    helpDesk: HelpDeskItem;
  };
}

export interface UpdateHelpDeskParams {
  id: string;
  fields: GQLUpdateHelpDeskFieldsInput;
}

export const UpdateHelpDeskQuery = gql`
  mutation updateHelpDesk($id: ID!, $fields: UpdateHelpDeskFieldsInput) {
    updateHelpDesk(input: {id: $id, fields: $fields}) {
      helpDesk {
        ...HelpDeskFragment
      }
    }
  }
  ${HelpDeskFragment}
`;

export interface UpdateHelpDeskParams {
  id: string;
  fields: GQLUpdateHelpDeskFieldsInput;
}

export interface helpDeskListOrderParams {
  input: {orderList: string[]};
}

export const UpdateHelpDeskListOrder = gql`
  mutation helpDeskListOrder($input: HelpDeskListOrderInp!) {
    helpDeskListOrder(input: $input)
  }
`;

export const GetSupportUserQuery = gql`
  query supportUser {
    users(where: {isSupport: {equalTo: true}}, first: 1) {
      edges {
        node {
          ...MemberFragment
        }
      }
    }
  }
  ${MemberFragment}
`;
