import React from 'react';
import {useTranslation} from 'react-i18next';

import {SettingsHeader} from '../components';
import {ProfileWithCredentials, SettingsContacts, TVerifyPhoneData} from './SettingsContacts';
import {SettingsInterests} from './SettingsInterests';
import {SettingsProfile} from './SettingsProfile';
import {User} from '../../../queries/types/user';
import {
  ContactsFormValues,
  ProfileFormValues,
  FormType,
  FormEditType,
  TInterestsRecmndsValues,
} from '../../../types/settings';
import {PageWrapper, SettingsWrapper} from '../commonStyles';
import {GoBack} from '../../common/Buttons/GoBack';

interface TProps {
  profileWithCredentials: ProfileWithCredentials;
  profile: Partial<ProfileFormValues>;
  profileInterests: TInterestsRecmndsValues;
  user: User | null;
  formContacts: FormType<ContactsFormValues>;
  formProfile: FormType<ProfileFormValues>;
  formInterests: FormType<TInterestsRecmndsValues>;
  editContacts: FormEditType;
  editProfile: FormEditType;
  editInterests: FormEditType;
  generateUsername: (name: string) => Promise<string | undefined>;
  updateAvatar?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  isCanEditCountry: boolean;
  verifyPhoneData: TVerifyPhoneData;
  loadingAvatar: boolean;
  gender?: string;
  handleUpdatePhotoAsRandom?: (file: File) => Promise<boolean>;
}

export const PersonalSettings: React.FC<TProps> = ({
  profile,
  user,
  formProfile,
  formContacts,
  formInterests,
  editProfile,
  editContacts,
  profileWithCredentials,
  generateUsername,
  updateAvatar,
  updatePhotoError,
  isCanEditCountry,
  verifyPhoneData,
  profileInterests,
  editInterests,
  loadingAvatar,
  gender,
  handleUpdatePhotoAsRandom,
}) => {
  const {t} = useTranslation();
  const goBack = () => window?.history.back();
  return (
    <PageWrapper>
      <GoBack title={t('settings:personal.title')} goBack={goBack} />
      <SettingsWrapper>
        <SettingsHeader
          title={t('settings:personal.title')}
          hideMob
          mobpt="10px"
          subtitle={t('settings:personal.subtitle')}
        />
        <SettingsProfile
          handleUpdatePhotoAsRandom={handleUpdatePhotoAsRandom}
          gender={gender}
          profile={profile}
          form={formProfile}
          edit={editProfile}
          generateUsername={generateUsername}
          canEdit={!editContacts.isEditing && !editInterests.isEditing}
          updateAvatar={updateAvatar}
          updatePhotoError={updatePhotoError}
          avatar={user?.Avatar?.file?.url}
          loadingAvatar={loadingAvatar}
        />
        <SettingsInterests
          values={profileInterests}
          form={formInterests}
          canEdit={!editContacts.isEditing && !editProfile.isEditing}
          edit={editInterests}
        />
        <SettingsContacts
          profile={profileWithCredentials}
          location={user?.Location}
          form={formContacts}
          edit={editContacts}
          canEdit={!editProfile.isEditing && !editInterests.isEditing}
          isCanEditCountry={isCanEditCountry}
          verifyPhoneData={verifyPhoneData}
        />
      </SettingsWrapper>
    </PageWrapper>
  );
};
