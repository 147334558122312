import React, {useState} from 'react';
import {TCreditCard} from '../../../queries/types/payments';
import {PaymentCard} from '../../../components/common/PaymentCard';
import {useDeleteCard, useSetDefaultCard} from '../../../hooks/payments';

type TProps = {
  width?: string;
  card: TCreditCard;
  refetchCards: () => void;
  isActive?: boolean;
};

export const PaymentCardContainer: React.FC<TProps> = ({width, card, isActive, refetchCards}) => {
  const switchCard = useSetDefaultCard();
  const [isSwitching, setIsSwitching] = useState(false);
  const deleteCard = useDeleteCard({
    cardId: card.id,
    onSuccess: () => refetchCards(),
  });

  const handleSwitch = () => {
    setIsSwitching(true);
    switchCard({
      id: card.id,
      onSucces: () => {
        refetchCards();
        setIsSwitching(false);
      },
    });
  };

  const handleDelete = () => {
    deleteCard();
  };

  return (
    <PaymentCard
      width={width}
      card={card}
      isActive={isActive}
      onRemove={handleDelete}
      switchCard={handleSwitch}
      isSwitching={isSwitching}
    />
  );
};
