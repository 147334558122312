export enum PaymentField {
  firstName = 'firstName',
  lastName = 'lastName',
  cardNumber = 'cardNumber',
  expiryDate = 'expiryDate',
  cvc = 'cvc',
  zipCode = 'zipCode',
  country = 'country',
}

export enum PaymentPeriodType {
  month = 'month',
  year = 'year',
}

export enum CurrencyCode {
  USD = 'USD',
  CAD = 'CAD',
}

export enum PayoutStatus {
  pending = 'pending',
  paid = 'paid',
  external = 'external',
}
