import React from 'react';
import {ListingSkeletonWrapper, ProfileIntroSkeletonContent} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const DocumentSkeleton: React.FC = () => {
  return (
    <ListingSkeletonWrapper>
      <MemoLoadingSkeleton width={300} mobileWidth={'100%'} mobileHeight={230} height={200} />
      <ProfileIntroSkeletonContent>
        <MemoLoadingSkeleton width={168} height={19} />
        <MemoLoadingSkeleton width={94} height={16} />
      </ProfileIntroSkeletonContent>
    </ListingSkeletonWrapper>
  );
};

export const DocumentsSkeletonSection: React.FC = () => {
  return (
    <>
      <DocumentSkeleton />
      <DocumentSkeleton />
      <DocumentSkeleton />
    </>
  );
};
