import React, {ChangeEvent} from 'react';
import {CardWrapper, CheckboxWrapper, InfoWrapper, PersonName} from '../People/styles';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {Label} from '../../ui-kit/Labels';
import {CommunityStateType} from '../../states/community';
import {useTranslation} from 'react-i18next';
import {CommunityCardLink, CommunityPendingLabelWrapper} from './styles';
import {Checkbox} from '../../ui-kit/Checkbox/Checkbox';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';

interface CommunityLabelProps {
  community: CommunityStateType;
  icon: JSX.Element;
  count?: number;
  needConfirmation?: boolean;
  isUnmanaged?: boolean;
  isCheckboxMode?: boolean;
  isChecked?: boolean;
  isUserManager?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isPublished?: boolean;
}

export const CommunityLabel: React.FC<CommunityLabelProps> = (props) => {
  const {community, isUnmanaged, isChecked, onChange, isCheckboxMode, isUserManager, isPublished} = props;
  const {t} = useTranslation();
  const {getLink} = useLinks();

  return (
    <CommunityCardLink to={isCheckboxMode ? '#' : getLink(route.loop.path, community?.alias)}>
      <CardWrapper>
        <Avatar src={community?.avatar} size={'sm'} $isOnline={false} />
        <InfoWrapper>
          <PersonName>{community?.name}</PersonName>
          {/*<PersonLabels>*/}
          {/*  {icon}*/}
          {/*  {count}*/}
          {/*</PersonLabels>*/}
          {isCheckboxMode && (
            <CommunityPendingLabelWrapper>
              <Label squared={true} variant={isUserManager ? 'quaternary' : 'quinary'}>
                {isUserManager ? t('common:typeCommunity.admin') : t('common:typeCommunity.resident')}
              </Label>
            </CommunityPendingLabelWrapper>
          )}
          {isPublished && (
            <CommunityPendingLabelWrapper>
              <Label squared={true} variant={'quinary'}>
                {isUnmanaged ? t('listings:created.published') : t('listings:created.pending')}
              </Label>
            </CommunityPendingLabelWrapper>
          )}
        </InfoWrapper>
      </CardWrapper>
      {isCheckboxMode && onChange && (
        <CheckboxWrapper>
          <Checkbox $isChecked={!!isChecked} onChange={onChange} />
        </CheckboxWrapper>
      )}
    </CommunityCardLink>
  );
};
