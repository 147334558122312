import {TFunction} from 'react-i18next';
import {DocumentFormatsByMime, TDocumentMime} from '../components/Documents/types';
import {GetDocumentsRes} from '../queries/documents';
import {Document, TTypeOfDocument} from '../queries/types/document';
import {TDocument, TDocumentFormat} from '../types/document';
import {correctTranslate} from './common';
import {ResidentGuide, CommunityValues} from '../files';
import {PointerFile} from '../types/common';

const defaultCovers: Record<string, string> = {
  communityValues: ResidentGuide,
  residentGuide: CommunityValues,
};

export const getFileFormatByMime = (mime: TDocumentMime): TDocumentFormat | undefined => {
  return DocumentFormatsByMime[mime];
};

interface IGetNameForDoc {
  (params: {text?: string; t: TFunction<'translation'>}): string;
}

export const getNameForDoc: IGetNameForDoc = ({text, t}) => {
  return correctTranslate({
    text: t('documents:defaultDocs.name', {context: text}),
    defaultText: text || '',
  }) as string;
};

export const getUrlForDoc = (alias: string) => {
  return defaultCovers[alias] || '';
};

interface IDocumentToState {
  (params: {doc: Document; viewerId?: string; t: TFunction<'translation'>}): TDocument;
}

export const documentToState: IDocumentToState = ({doc, t, viewerId}): TDocument => {
  const name = getNameForDoc({
    text: doc.name,
    t,
  });

  const defaultCover = getUrlForDoc(doc.alias || '');

  const cover: {Cover?: PointerFile} = {};

  if (!doc.Cover?.file.url && doc.type === TTypeOfDocument.default && defaultCover) {
    cover.Cover = {
      file: {
        name: '',
        url: defaultCover,
      },
    } as PointerFile;
  }

  return {
    ...doc,
    ...cover,
    viewerIsOwner: doc?.User?.objectId === viewerId,
    format: getFileFormatByMime(doc.DocFile.mime as TDocumentMime),
    name,
  } as TDocument;
};

export const documentsToState = (
  docs: GetDocumentsRes['documents'],
  t: TFunction<'translation'>,
  viewerId?: string,
): Array<TDocument> => {
  return docs.edges.map((el) =>
    documentToState({
      doc: el.node,
      viewerId,
      t,
    }),
  );
};
