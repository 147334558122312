import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  // ActionButton,
  // ActionLink,
  AsideEdit,
  CardInsight,
  // CardWideInsight,
  InnerActions,
  InnerEditAside,
  InnerInsights,
  SubTitle,
  TitleInsight,
  ValueInsight,
  // ValueInsightWide,
  WrapperEditFormAside,
} from './styles';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
// import {optionsI18n} from '../../libs/i18nextUtils';
// import {useGetLanguage} from '../../ui-kit/utils/language';
import {Order} from '../../queries/types/order';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {CommItem, CommsList, SelectedIconCont, ActionButton, ActionLink} from '../Listings/styles';
import {Community} from '../../queries/types/community';

type TProps = {
  attendeesCount?: number;
  countViewers?: number;
  $isPublished?: boolean;
  dateAndTime?: Date;
  endTime?: Date;
  location?: string;
  width?: string;
  onPublish?: () => void;
  onUnlist?: () => void;
  onDelete?: () => void;
  editPath?: string;
  className?: string;
  isAdmHidden?: boolean;
  orders?: Order[];
  communityListPath?: string;
  manyCommunities?: boolean;
  commList?: {objectId?: string; name?: string}[];
  published?: Community[];
  countLikes?: number;
};

export const EditFormAside: React.FC<TProps> = ({
  countViewers,
  onDelete,
  width,
  className,
  orders,
  editPath,
  // $isPublished,
  // isAdmHidden,
  // onPublish,
  // onUnlist,
  communityListPath,
  commList,
  manyCommunities,
  published,
  countLikes,
}) => {
  const {t} = useTranslation();
  // const getLanguage = useGetLanguage();
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const dataInsights = useMemo(() => {
    return [
      {
        title: t('events:insights.views'),
        value: countViewers || 0,
      },
      {
        title: t('events:insights.revenue'),
        value: 0,
      },
      {
        title: t('events:insights.totalGuests'),
        value:
          orders?.reduce((acc, item) => {
            if (item && item.attendeeGuests) return acc + item.attendeeGuests;
            else return acc + 0;
          }, 0) || 0,
      },
      {
        title: t('events:insights.likes'),
        value: countLikes || 0,
      },
    ];
  }, [orders, countViewers]);
  // const timePart1 =
  //   dateAndTime && endTime
  //     ? f(new Date(dateAndTime), 'iii', getLanguage()) + ', ' + f(new Date(dateAndTime), 'PP', getLanguage())
  //     : '';

  // const timePart2 =
  //   dateAndTime && endTime
  //     ? f(new Date(dateAndTime), 'p', getLanguage()) + ' to ' + f(new Date(endTime), 'p', getLanguage())
  //     : '';

  // const infoInsights = [
  //   {
  //     title: t('events:insights.location'),
  //     value: location || '',
  //   },
  // ];
  // const dateInsight = {
  //   title: t('events:insights.dateAndTime'),
  //   value1: timePart1,
  //   value2: timePart2,
  // };

  const modalsText = {
    delete: {
      title: t('events:modals.delete.title'),
      okText: t('events:modals.delete.okText'),
      cancelText: t('events:modals.delete.cancelText'),
    },
  };
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  return (
    <>
      <InnerEditAside width={width} className={className}>
        <AsideEdit>
          <WrapperEditFormAside>
            <div className={'aside__actions-block'}>
              <SubTitle>{t('listings:actions.title')}</SubTitle>
              <InnerActions>
                {editPath && (
                  <ActionLink to={editPath}>
                    {t('listings:actions.buttons.edit')}
                    <IconSvg type="pencil" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionLink>
                )}
                {manyCommunities && (
                  <ActionLink to={communityListPath || ''}>
                    {t('listings:actions.buttons.communityList')}
                    <IconSvg type="list" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionLink>
                )}
                {/* {$isPublished ? (
                  <ActionButton type="button" onClick={onUnlist}>
                    {t('listings:actions.buttons.unlist')}
                    <IconSvg type="eye-closed" width="14px" height="14px" viewBox="0 0 14 14" />
                  </ActionButton>
                ) : (
                  !$isPublished &&
                  // !hasApprovalRequest &&
                  !isAdmHidden && (
                    <ActionButton type="button" onClick={onPublish}>
                      {t('listings:actions.buttons.publish')}
                      <IconSvg type="eye-open" width="14px" height="14px" viewBox="0 0 14 14" />
                    </ActionButton>
                  )
                )} */}
                <ActionButton type="button" onClick={handleOpenDelete}>
                  {t('listings:actions.buttons.delete')}
                  <IconSvg type="delete-alt" width="14px" height="14px" viewBox="0 0 14 14" />
                </ActionButton>
              </InnerActions>
              <SubTitle>{t('listings:view.communities')}</SubTitle>
              <CommsList>
                {commList?.map((el) => (
                  <CommItem
                    to={communityListPath || ''}
                    $selected={published?.some((p) => p?.objectId === el?.objectId)}
                    key={el?.objectId}>
                    <SelectedIconCont>
                      {published?.some((p) => p?.objectId === el?.objectId) ? (
                        <IconSvg type="marked-circle" stroke="white" height="26px" />
                      ) : (
                        <IconSvg type="empty-circle" stroke="gray" height="22px" />
                      )}
                    </SelectedIconCont>
                    <span>{el?.name}</span>
                  </CommItem>
                ))}
              </CommsList>
            </div>
            <InnerInsights>
              {dataInsights?.map((it) => (
                <CardInsight key={it.title}>
                  <TitleInsight>{it.title}</TitleInsight>
                  <ValueInsight>{it.value}</ValueInsight>
                </CardInsight>
              ))}
              {/* <CardWideInsight>
                  <TitleInsight>{dateInsight.title}</TitleInsight>
                  <ValueInsightWide>{dateInsight.value1}</ValueInsightWide>
                  <ValueInsightWide>{dateInsight.value2}</ValueInsightWide>
                </CardWideInsight>
                {infoInsights?.map((it) => (
                  <CardWideInsight key={it.title}>
                    <TitleInsight>{it.title}</TitleInsight>
                    <ValueInsightWide>{it.value}</ValueInsightWide>
                  </CardWideInsight>
                ))} */}
            </InnerInsights>
          </WrapperEditFormAside>
        </AsideEdit>
      </InnerEditAside>
      {isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={onDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
    </>
  );
};
