import {Connection, ParseObject} from './parse';
import {User} from './user';
import {Community} from './community';
import {Review} from './review';
import {PointerFile} from '../../types/common';

export enum RewardType {
  free = 'free',
  coffee = 'coffee',
  fee = 'fee',
  paid = 'paid',
}

export enum OpenToType {
  community = 'community',
  public = 'public',
}

export enum EventRepeatType {
  one = 'one',
  daily = 'daily',
  onceWeek = 'onceWeek',
  everyTwoWeeks = 'everyTwoWeeks',
  month = 'month',
  quarterly = 'quarterly',
}

export enum PricePeriodType {
  month = 'month',
  hour = 'hour',
  day = 'day',
  fixed = 'fixed',
}

export interface Event extends ParseObject {
  name: string;
  rewardType: RewardType;
  descr: string;
  eventDate: Date;
  endTime: Date;
  startTime: Date;
  locationDetails?: string;
  showAttendees: boolean;
  images: PointerFile[];
  pricePeriod?: PricePeriodType | null;
  isPublished: boolean;
  isDeleted: boolean;
  rating?: number;
  totalViews?: number;
  maxAttendees?: number;
  allowGuests?: boolean;
  maxGuestsPerAttendee?: number;
  Featured?: Community[];
  AdmHidden?: Community[];
  Published?: Community[];
  Updated?: Community[];
  Reviews?: Connection<Review>;
  Location?: Location;
  totalAttendees?: number;
  Attendees?: Connection<User>;
  Lister?: User;
  openTo?: OpenToType;
  eventType?: EventRepeatType;
  isLike?: boolean;
  countLikes?: number;
  expiresDate?: Date;
}
