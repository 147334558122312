import React, {useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ItemFormAction} from '../../types/item';
import {
  useGetInsightsItem,
  useGetItemNoCache,
  useResidentActionsItem,
  useSetItemRenewOrSold,
  useSetItemStatusDeleted,
} from '../../hooks/item';
import {useRecoilState} from 'recoil';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {ViewListing} from '../../components/Listings/View';
import {currentCommunity} from '../../states/community';
import {GQLButtonsResidentTypes} from '../../graphql.schema';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {useGetActualCommunityList} from '../../hooks/community';

export const ViewEditListingContainer = () => {
  const {push} = useHistory();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {id} = useParams<{id?: string}>();
  const {data: item, loading} = useGetItemNoCache({id});
  const dataInsights = useGetInsightsItem(id);
  const {getLink} = useLinks();
  const isManyCommunities = communities?.length > 1;
  const onSuccess = () => {
    if (!typeUser) return;
    push(getLink(route.listings.path));
  };
  const commList = useMemo(
    () => communities?.map((el) => ({objectId: el?.objectId, name: el?.name})),
    [communities?.length],
  );
  const previewLink = item?.objectId && `${getLink(route.listingView.get({id: item?.objectId || ''}))}?preview=true`;
  const {onDelete} = useSetItemStatusDeleted({id, onSuccess});
  const {onRenew, onSold} = useSetItemRenewOrSold({id, onSuccess});

  const {onSubmit, loading: actionLoading} = useResidentActionsItem({
    communityId: community?.objectId,
    onSuccess: onSuccess,
  });

  const handlePublish = () => {
    onSubmit({
      itemId: item?.objectId,
      typeBtn: GQLButtonsResidentTypes.publish,
    });
  };

  const handleUnlist = () => {
    onSubmit({
      itemId: item?.objectId,
      typeBtn: GQLButtonsResidentTypes.unlist,
    });
  };
  return (
    <ViewListing
      community={community}
      action={ItemFormAction.edit}
      item={item}
      onPublish={handlePublish}
      onUnlist={handleUnlist}
      $loading={loading || actionLoading}
      dataInsights={dataInsights}
      onDelete={onDelete}
      onRenew={onRenew}
      onSold={onSold}
      manyCommunities={isManyCommunities}
      commList={commList}
      previewLink={previewLink}
    />
  );
};
