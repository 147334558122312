import React from 'react';
import {ReviewProps, ReviewTypes} from './types';
import {Check} from './Review/Check';
import {Rating} from './Review/Rating';
import {ReviewCard} from './Review/ReviewCard';
import {Cancelled} from './Review/Cancelled';

export const Review: React.FC<ReviewProps> = ({
  title,
  descr,
  rewardType,
  tags,
  payments,
  paymentLabel,
  buttonLabel,
  dateLabel,
  check,
  review,
  rating,
  reviewType,
  cancelled,
  opponentInfo,
  itemInfo,
  listingType,
  pricePeriod,
  pickerLabels,
  pickerPlaceholders,
  smallPicker,
  readyReviewsData,
  isRequester,
  reviewLoading,
  toggleCredit,
  showCredit,
  creditInfo,
  dateInfoLabels,
}) => {
  switch (reviewType) {
    case ReviewTypes.Check:
      return (
        <Check
          title={title}
          descr={descr}
          checkInfo={check}
          payments={payments}
          buttonLabel={buttonLabel}
          tags={tags}
        />
      );

    case ReviewTypes.Rating:
      return (
        <Rating
          descr={rating.descr}
          tags={tags}
          rating={rating}
          rewardType={rewardType}
          dateLabel={dateLabel}
          paymentLabel={paymentLabel}
          opponentInfo={opponentInfo}
          itemInfo={itemInfo}
          buttonLabel={buttonLabel}
          listingType={listingType}
          pricePeriod={pricePeriod}
          pickerPlaceholders={pickerPlaceholders}
          pickerLabels={pickerLabels}
          smallPicker={smallPicker}
          isRequester={isRequester}
          reviewLoading={reviewLoading}
          showCredit={showCredit}
          toggleCredit={toggleCredit}
          creditInfo={creditInfo}
          dateInfoLabels={dateInfoLabels}
        />
      );
    case ReviewTypes.Review:
      return (
        <ReviewCard
          tags={tags}
          descr={rating.descr}
          rewardType={rewardType}
          reviewType={reviewType}
          opponentInfo={opponentInfo}
          itemInfo={itemInfo}
          dateLabel={dateLabel}
          paymentLabel={paymentLabel}
          review={review}
          buttonLabel={buttonLabel}
          listingType={listingType}
          pricePeriod={pricePeriod}
          pickerPlaceholders={pickerPlaceholders}
          pickerLabels={pickerLabels}
          smallPicker={smallPicker}
          rating={rating}
          isRequester={isRequester}
          reviewLoading={reviewLoading}
          showCredit={showCredit}
          toggleCredit={toggleCredit}
          creditInfo={creditInfo}
          dateInfoLabels={dateInfoLabels}
        />
      );
    case ReviewTypes.ReviewReady:
      return (
        <ReviewCard
          tags={tags}
          descr={review.completedDescr}
          rewardType={rewardType}
          reviewType={reviewType}
          opponentInfo={opponentInfo}
          itemInfo={itemInfo}
          dateLabel={dateLabel}
          paymentLabel={paymentLabel}
          review={review}
          title={title}
          buttonLabel={buttonLabel}
          listingType={listingType}
          pricePeriod={pricePeriod}
          pickerPlaceholders={pickerPlaceholders}
          pickerLabels={pickerLabels}
          smallPicker={smallPicker}
          rating={rating}
          readyReviewsData={readyReviewsData}
          isRequester={isRequester}
          reviewLoading={reviewLoading}
          showCredit={showCredit}
          toggleCredit={toggleCredit}
          creditInfo={creditInfo}
          dateInfoLabels={dateInfoLabels}
        />
      );

    case ReviewTypes.Cancelled:
      return (
        <Cancelled
          descr={descr}
          tags={tags}
          rewardType={rewardType}
          payments={payments}
          dateLabel={dateLabel}
          paymentLabel={paymentLabel}
          cancelled={cancelled}
          buttonLabel={buttonLabel}
          listingType={listingType}
          pricePeriod={pricePeriod}
          pickerPlaceholders={pickerPlaceholders}
          pickerLabels={pickerLabels}
          smallPicker={smallPicker}
          showCredit={showCredit}
          toggleCredit={toggleCredit}
          creditInfo={creditInfo}
          dateInfoLabels={dateInfoLabels}
        />
      );
  }
  return null;
};
