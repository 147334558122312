import React from 'react';
import {isMobile} from 'react-device-detect';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {BasePortal} from '../../../ui-kit/Popup';
import {DarkBackground, RequestCloseBtn, RequestModalWrapper} from './styles';

export const MessagesRequestModal: React.FC<{isModalRequest: boolean; onClose?: () => void}> = ({
  children,
  isModalRequest,
  onClose,
}) => {
  const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();
  if (!isModalRequest || !isMobile) return <>{children}</>;
  return (
    <BasePortal>
      <DarkBackground onClick={onClose}>
        <RequestModalWrapper onClick={stopPropagation}>{children}</RequestModalWrapper>
      </DarkBackground>
    </BasePortal>
  );
};

export const CloseButtonRound: React.FC<{onClose?: () => void}> = ({onClose}) => {
  return (
    <RequestCloseBtn onClick={onClose}>
      <IconSvg type={'close'} height={'20px'} width={'20px'} viewBox="0 0 20 20" stroke={'darkgray'} />
    </RequestCloseBtn>
  );
};
