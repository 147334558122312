import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {ListingTypeSelectWrapper} from '../../Listings/styles';

export const InnerSearchWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

export const TopBlock = styled.div`
  ${Media.down.s} {
    padding: 0 15px;
  }
  & > div > span {
    margin-bottom: 15px;
    display: block;
  }
`;

export const SelectWrapper = styled.div<{moveLast?: boolean}>`
  width: 100%;
  overflow-x: auto;

  ${Media.up.xl} {
    & > div > div > div:last-child {
      margin-left: ${({moveLast}) => (moveLast ? 'auto' : '0')};
    }
  }
`;

export const InnerMobileSearchWrapper = styled.div`
  margin-right: -15px;

  & * {
    box-sizing: border-box;
  }

  ${Media.up.s} {
    display: none;
  }
`;

export const MobileSearchInputs = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 120px;
  gap: 6px;
  box-sizing: border-box;
`;

export const MobileSelectWrapper = styled(ListingTypeSelectWrapper)`
  //width: 100%;
`;

export const HeaderPageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
