import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';

import {TEXTAREA_MAX_LENGTH} from '../../../../constants/common';
import {AccessType, ListingApproval} from '../../../../constants/community';
import {correctTranslate, getSiteAlias} from '../../../../helpers/common';
import {getLocationValue} from '../../../../helpers/locations';
import {userState} from '../../../../states/session';
import {CommunityField, TOptions} from '../../../../types/community';
import {Button} from '../../../../ui-kit/Button/Button';
import {CleaveInput, Input} from '../../../../ui-kit/Form/Input';
import {Select} from '../../../../ui-kit/Form/Select';
import {TextArea} from '../../../../ui-kit/Form/TextArea/TextArea';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {ErrorMessage} from '../../../Auth/styles';
import {MemoSymbolsCounter} from '../../../common/SymbolsCounter';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  CounterInner,
  GreetingTitle,
  InnerItemsForm,
  ProfileForm,
  SubscribeText,
  SubTitle,
  TextSelect,
  WrapItem,
  WrapperForm,
  CommTypeHint,
  NeighInput,
} from '../styles';

import {ResCmntType} from '../../../../hooks/community';
import {useLocationOptions} from '../../../../hooks/locations';
import {useChangeLocation} from '../../../../hooks/settings';
import {CommunityTypes, getCommunityTypesOptions} from '../../../../helpers/community';

interface IProps {
  params: ResCmntType;
}

export const FormCommunity: React.FC<IProps> = ({params}) => {
  const {
    values,
    onChange,
    onSubmit,
    error,
    loading,
    options: {accTypes, listingsApproval},
  } = params;
  const {t} = useTranslation();
  const manager = useRecoilValue(userState);
  const typesOptions = getCommunityTypesOptions(t, manager);
  const [showHint, setShowHint] = useState<string>(CommunityTypes.building as string);
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  const {country, state, city, handleChangeDropdown} = useChangeLocation(manager?.Location);
  const {cities, states, countries} = useLocationOptions({
    country: country.value || '',
    state: state.value || '',
  });

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleSelect = (name: string) => (data: TOptions[]) => {
    onChange({name: name, value: data[0]?.value});
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    values.country = country.value;
    values.state = state.value;
    values.city = city.value;
    onSubmit();
  };

  const addressInfo = React.useMemo(() => {
    return {
      [CommunityField.country]: getLocationValue(manager?.Location?.Country),
      [CommunityField.state]: getLocationValue(manager?.Location?.State),
      [CommunityField.city]: getLocationValue(manager?.Location),
      [CommunityField.zip]: manager?.zip,
      [CommunityField.address]: manager?.address,
    };
  }, [manager, getLocationValue]);

  useEffect(() => {
    Object.entries(addressInfo).map(([key, _value]) => {
      const value = typeof _value === 'string' ? _value : _value?.[0]?.value;

      onChange({name: key, value});
    });
  }, [manager]);
  const isBuilding = values?.type === CommunityTypes.building;
  const isNeighbourhood = values?.type === CommunityTypes.neighbourhood;
  const isCircle = values?.type === CommunityTypes.circle;
  const closeHint = () => setShowHint('');
  useEffect(() => {
    values?.type && setShowHint(values?.type);
  }, [values?.type]);
  useEffect(() => {
    if (isCircle && city?.value && city?.label) {
      handleChangeDropdown('city')([{value: city.value, label: city.label}]);
    }
  }, [isCircle]);
  return (
    <WrapperForm paddingX={'20px'} $isGhost>
      <GreetingTitle>{t('community:create.title')}</GreetingTitle>
      <SubscribeText>{t('community:create.descr.text')}</SubscribeText>
      <ProfileForm name={'form-community'} onSubmit={handleSubmit}>
        <div>
          <Select
            $isError={Boolean(error.type)}
            name={CommunityField.type}
            label={t('forms:communityType')}
            dropdownHandle={true}
            values={typesOptions.filter((el) => el?.value === values?.type)}
            options={typesOptions}
            onChange={handleSelect(CommunityField.type)}
          />
        </div>
        {showHint && (
          <CommTypeHint>
            <IconSvg type="info-alt" />
            <span>{t(`community:hints.${showHint}`)}</span>
            <button onClick={closeHint}>
              <IconSvg type="close" />
            </button>
          </CommTypeHint>
        )}

        <div>
          <Input
            $isError={Boolean(error.name)}
            name={CommunityField.name}
            value={values.name}
            onChange={handleChange}
            label={t('forms:communityName')}
          />
        </div>

        <div>
          <TextArea
            $isError={Boolean(error.descr)}
            name={CommunityField.descr}
            value={values.descr}
            onChange={handleChange}
            label={t('forms:descr')}
            maxLength={TEXTAREA_MAX_LENGTH}
          />
          <CounterInner>
            <MemoSymbolsCounter value={values?.descr?.length} limit={TEXTAREA_MAX_LENGTH} />
          </CounterInner>
        </div>
        <Input
          $isError={Boolean(error.alias)}
          name={CommunityField.alias}
          value={values.alias}
          onChange={handleChange}
          suffix={`.${getSiteAlias()}`}
          label={t('forms:communityPage')}
          icon={<IconSvg width={'24px'} height={'24px'} type={'circle-ok'} stroke={'green'} />}
        />

        {!isCircle && (
          <>
            <SubTitle>{t('community:create.descr.form.address')}</SubTitle>
            <InnerItemsForm>
              <Select
                $isError={Boolean(error.country)}
                name={CommunityField.country}
                label={t('forms:country')}
                dropdownHandle={true}
                values={country ? [country] : []}
                options={countries}
                onChange={handleChangeDropdown('country')}
              />
              <Select
                $isError={Boolean(error.state)}
                name={CommunityField.state}
                label={t('forms:state')}
                dropdownHandle={false}
                values={state ? [state] : []}
                options={states}
                onChange={handleChangeDropdown('state')}
              />
            </InnerItemsForm>
            <InnerItemsForm>
              <WrapItem>
                <Select
                  $isError={Boolean(error.city)}
                  name={CommunityField.city}
                  label={t('forms:city')}
                  dropdownHandle={false}
                  values={city ? [city] : []}
                  options={cities}
                  onChange={handleChangeDropdown('city')}
                />
              </WrapItem>
              {isBuilding && (
                <WrapItem>
                  <CleaveInput
                    options={{
                      blocks: [3, 3],
                      uppercase: true,
                    }}
                    $isError={Boolean(error.zip)}
                    name={CommunityField.zip}
                    value={values?.zip}
                    onChange={handleChange}
                    label={t('forms:zip')}
                  />
                </WrapItem>
              )}
              {isNeighbourhood && (
                <WrapItem>
                  <NeighInput
                    $isError={Boolean(error.neighbourhood)}
                    name={CommunityField.neighbourhood}
                    value={values?.neighbourhood}
                    onChange={handleChange}
                    label={t('forms:neighbourhood')}
                  />
                </WrapItem>
              )}
            </InnerItemsForm>
            {isBuilding && (
              <Input
                $isError={Boolean(error.address)}
                name={CommunityField.address}
                value={values?.address}
                onChange={handleChange}
                label={t('forms:address')}
              />
            )}
          </>
        )}
        <SubTitle>{t('community:create.descr.form.settings')}</SubTitle>
        <InnerItemsForm>
          <WrapItem>
            <Select
              $isError={Boolean(error.accessType)}
              name={CommunityField.accessType}
              label={t('forms:accountType')}
              options={accTypes}
              values={accTypes?.filter((it) => it.value === values.accessType)}
              onChange={handleSelect(CommunityField.accessType)}
            />
            {values.accessType === Object.keys(AccessType)[1] && (
              <TextSelect>{t('community:create.descr.form.accType')}</TextSelect>
            )}
          </WrapItem>
          <WrapItem>
            <Select
              $isError={Boolean(error.listingApproval)}
              name={CommunityField.listingApproval}
              label={t('forms:listingApproval')}
              values={listingsApproval?.filter((it) => it.value === values.listingApproval)}
              options={listingsApproval}
              onChange={handleSelect(CommunityField.listingApproval)}
            />
            {values.listingApproval === Object.keys(ListingApproval)[1] && (
              <TextSelect>{t('community:create.descr.form.listingApproval')}</TextSelect>
            )}
          </WrapItem>
        </InnerItemsForm>
        <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
        <Button type={'submit'} loading={loading}>
          {t('community:create.button.create')}
        </Button>
      </ProfileForm>
      <LottieLoader $isVisible={loading} allScreen={true} />
    </WrapperForm>
  );
};
