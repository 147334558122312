import React, {useMemo} from 'react';

type TProps = {
  card: React.FC;
};

export const SkeletonLoader: React.FC<TProps> = ({card}) => {
  const Card = card;
  const skeletonArray = useMemo(() => new Array(7).fill(''), []);
  return (
    <>
      {skeletonArray.map((it, index) => (
        <Card key={index} />
      ))}
    </>
  );
};
