import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSetRecoilState} from 'recoil';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {PlanOptionsValues} from '../../constants/plan';
import {route} from '../../constants/routes';
import {currentCommunity} from '../../states/community';
import {makeSeoKeywords} from '../../helpers/common';
import {getResize} from '../../helpers/file';
import {isImage} from '../../helpers/settings';
import {useHasPlanOption} from '../../helpers/community';
import {User} from '../../queries/types/user';
import {TCommunity} from '../../types/community';
import {TypeCommunity} from '../../types/auth';
import {useLinks} from '../../hooks/common';
import {status} from '../../ui-kit/ProfileIntro/types';
import {ProfileIntroSkeleton} from '../common/Skeletons/ProfileIntroSkeleton';
import {CommunityFeed} from '../../containers/CommunityFeed';
import {VerifyPhonePage} from '../../containers/Auth/VerifyPhone';
import {Seo} from '../SEO';
import {WrapperPage} from '../HomePageUser/styles';
import {WrapperCommunityCard, ProfileCard} from './styles';
import {PermissionsList} from '../../types/settings';
import {RichText} from '../common/RichText/RichText';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {Popup} from '../../ui-kit/Popup';
import {AddMenu} from '../Account/Popups/HeaderMenu/AddMenu';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

type TProps = {
  user: User | null;
  community: Partial<TCommunity>;
  typeUser: TypeCommunity;
  updateAvatar?: (photo: File | undefined, id: string) => Promise<void>;
  loading?: boolean;
  viewerId?: string;
  documentsCount?: number;
  permissions?: PermissionsList[];
  loadingAvatar?: boolean;
};

export const LoopCommunity: React.FC<TProps> = ({
  community,
  user,
  typeUser,
  updateAvatar,
  loading,
  viewerId,
  permissions,
  loadingAvatar,
}) => {
  const {push} = useHistory();
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const textSeo = t('common:seo.loop', {
    text: community.name || '',
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const [error, setError] = useState<string>('');
  const city = community?.city?.name;
  const country = community?.country?.name;
  const address = city + ', ' + country;

  const [showVerifyPopup, setShowPopupMode] = useState<boolean>(false);
  const PopupWrapper = useRef<HTMLDivElement>(null);
  const setCommunity = useSetRecoilState(currentCommunity);
  const canEdit = useMemo(
    () =>
      typeUser === TypeCommunity.manager ||
      (permissions?.includes(PermissionsList.editCommunity) && typeUser === TypeCommunity.admin),
    [typeUser, permissions],
  );

  // const hasDocuments = useHasPlanOption(PlanOptionsValues.communityDocuments);
  const hasHelpDesk = useHasPlanOption(PlanOptionsValues.helpDesk);
  // const secondButtonText =
  //   hasDocents && !(typeUser === TypeCommunity.resident && documentsCount === 0)
  //     ? t('common:menu.documents')
  //     : undefined;
  const secondButtonText = undefined;
  const handleRedirect = () => {
    if (typeUser === TypeCommunity.manager || canEdit) return push(getLink(route.communitySettings.path));
    if (hasHelpDesk) return push(getLink(route.helpDesk.get()));
    push(getLink(route.messages.get({contactId: community.Owner?.objectId})));
  };
  const goToDocs = () => {
    push(route.documents.get());
  };
  const handleUpdatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setError('');

    if (!isImage(file?.name)) {
      setError(t('error:invalidFormat'));
    } else {
      updateAvatar?.(file, community.id ?? '')
        .then(() => (e.target.value = ''))
        .catch(() => {
          setError(t('error:invalidCharacters'));
          e.target.value = '';
        });
    }
  };

  const showPopup = () => {
    setShowPopupMode(true);
  };

  const closePopup = () => {
    setShowPopupMode(false);
  };

  const onSubmit = () => {
    closePopup();
    setCommunity((currVal) => currVal && {...currVal, isVerified: true});
  };

  useEffect(() => {
    if (!PopupWrapper.current) return;
    PopupWrapper.current?.addEventListener('click', (e) => {
      if (e.target === PopupWrapper.current) {
        closePopup();
      }
    });
  }, [PopupWrapper.current, showVerifyPopup]);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const openAddMenuHandler = () => setAddMenuOpen(true);
  const closeAddMenuHandler = () => setAddMenuOpen(false);
  const toggleCreating = (val: boolean) => setIsCreating(val);
  return (
    <>
      <WrapperPage>
        <Seo title={textSeo} keywords={seoKeywords} description={community?.descr} og={{image: community?.avatar}} />
        <WrapperCommunityCard>
          {/* {completedCheckList && <ChecklistMobileContainer />} */}
          {loading ? (
            <ProfileIntroSkeleton hideOnMob={true} />
          ) : (
            <ProfileCard
              buttonText={
                typeUser === TypeCommunity.manager || canEdit
                  ? t('common:pageUser.home.button.edit')
                  : t('people:profile.buttons.message', {context: hasHelpDesk && 'hasHelpDesk'})
              }
              buttonOnClick={handleRedirect}
              updatePhotoError={error}
              canUpdatePhoto={false}
              onUpdatePhoto={handleUpdatePhoto}
              title={community?.name}
              avatar={getResize(community?.avatar, 'lg')}
              originalAvatar={community?.avatar}
              description={community?.descr}
              address={address}
              isVerified={community?.isVerified}
              status={{
                statusType: 'primary' as status,
                statusName: t(`common:typeCommunity.community`),
              }}
              countMembers={community?.countMembers || 0}
              labels={{
                members: {text: t('common:labels.members'), to: route.people.get()},
                joined: t('common:labels.joined'),
                verified: t('common:labels.verified'),
                unverified: t('common:labels.unverified'),
              }}
              isVerifiedClick={!user?.isVerified ? showPopup : undefined}
              className={'loop_profile-intro'}
              secondButtonText={secondButtonText}
              secondButtonOnClick={goToDocs}
              interestsTitle={t('common:pageUser.interestsTitle')}
              recommendsTitle={t('common:pageUser.recommendationsTitle')}
              loadingAvatar={loadingAvatar}
              RichText={RichText}
              isOwner={typeUser === TypeCommunity.manager || canEdit}
              Gallery={Gallery}
              Item={Item}
              autogenerated={community?.identityData?.autogenerated}
            />
          )}
          <CommunityFeed
            typeUser={typeUser}
            user={user}
            viewerId={viewerId}
            currentComm={community}
            permissions={permissions}
            toggleCreating={toggleCreating}
          />
        </WrapperCommunityCard>
        {!isCreating && (
          <>
            {addMenuOpen ? (
              <AddButtonPortal onClick={openAddMenuHandler} iconType={'close-3'} />
            ) : (
              <AddButtonPortal
                onClick={openAddMenuHandler}
                iconType={'rounded-plus'}
                viewBox={'2 2 30 31'}
                animated
                animatedText={t('community:loop.buttons.post')}
              />
            )}
          </>
        )}
        {addMenuOpen && (
          <Popup isOpen={addMenuOpen} onClose={closeAddMenuHandler}>
            <IsModalProvider>
              <AddMenu onClose={closeAddMenuHandler} permissions={permissions} typeUser={typeUser} />
            </IsModalProvider>
          </Popup>
        )}
      </WrapperPage>
      {showVerifyPopup && <VerifyPhonePage onSubmit={onSubmit} refOnPopupWrapper={PopupWrapper} />}
    </>
  );
};
