import styled from 'styled-components';

interface IBox {
  position?: 'relative' | 'fixed' | 'absolute';
  display?: 'flex' | 'inline-flex' | 'block' | 'iniline' | 'iniline-block' | 'none';
  gap?: string;
  inset?: string;
  padding?: string;
  margin?: string;
  direction?: 'column' | 'row';
  items?: 'flex-start' | 'flex-end' | 'center';
  justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
  textAlign?: 'center' | 'left' | 'right';
  wrap?: boolean;
  width?: string;
  height?: string;
}

export const Box = styled.div<IBox>`
  ${({position}) => position && `position: ${position};`}
  ${({display}) => display && `display: ${display};`}
  ${({inset}) => inset && `inset: ${inset};`}
  ${({padding}) => padding && `padding: ${padding};`}
  ${({margin}) => margin && `margin: ${margin};`}
  ${({gap}) => gap && `gap: ${gap};`}
  ${({direction}) => direction && `flex-direction: ${direction};`}
  ${({items}) => items && `align-items: ${items};`}
  ${({justify}) => justify && `justify-content: ${justify};`}
  ${({textAlign}) => textAlign && `text-align: ${textAlign};`}
  ${({wrap}) => wrap && `flex-wrap: wrap;`}
  ${({width}) => width && `width: ${width};`}
  ${({height}) => height && `height: ${height};`}
`;
