import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {Br} from '../../ui-kit/Typography/Br';
import {Text} from '../../ui-kit/Typography/Text';
import {MemoHeaderAuth} from './HeaderAuth';
import {DisabledInput, ErrorMessage, InnerAuth} from './styles';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {Button} from '../../ui-kit/Button/Button';
import {Input} from '../../ui-kit/Form/Input';
import {TChangePassValues} from '../../hooks/auth';
import {correctTranslate} from '../../helpers/common';
import {useLinks} from '../../hooks/common';
import {WrapperAuth} from './WrapperAuth';

interface IProps {
  changePass: (params: {id: string; token: string}) => Promise<void>;
  error: string;
  changeValue: (key: keyof TChangePassValues, value: string) => void;
  email: string;
  token: string;
  id: string;
  loading: boolean;
  showSuccess: boolean;
  values: TChangePassValues;
}

export const NewPassword: React.FC<IProps> = ({
  changePass,
  changeValue,
  error,
  email,
  loading,
  showSuccess,
  id,
  token,
  values,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const errorMsg = correctTranslate({
    text: t(error || ''),
    defaultText: error,
  });

  const getOnChange = (key: keyof TChangePassValues) => (e: ChangeEvent<HTMLInputElement>) => {
    changeValue(key, e.target.value);
  };

  const handleSubmit = () => {
    changePass({id, token});
  };

  return (
    <WrapperAuth>
      <InnerAuth>
        {!showSuccess ? (
          <>
            <MemoHeaderAuth title={t('auth:newPassword.primaryTitle')} />
            <Br indent={30} />
            <DisabledInput label={t('auth:newPassword.labels.email')} disabled value={email} />
            <Br indent={20} />
            <Input
              label={t('auth:newPassword.labels.password')}
              type={'password'}
              onChange={getOnChange('password')}
              value={values.password}
            />
            <Br indent={20} />
            <Input
              label={t('auth:newPassword.labels.confirmPassword')}
              type={'password'}
              onChange={getOnChange('confirmPassword')}
              value={values.confirmPassword}
            />
            <Br indent={20} />
            <ErrorMessage open={!!error}>{errorMsg}</ErrorMessage>
            <Button loading={loading} variant={'primary'} onClick={handleSubmit}>
              {t('auth:newPassword.button')}
            </Button>
          </>
        ) : (
          <>
            <MemoHeaderAuth title={t('auth:newPassword.secondaryTitle')} />
            <Br indent={30} />
            <Text size={16} color={'octonary'} variant={'tertiary'}>
              {t('auth:newPassword.successText', {email: email || ''})}
            </Text>
            <Br indent={30} />
            <LinkButton to={getLink(route.auth.get())} variant={'primary'}>
              {t('auth:newPassword.back')}
            </LinkButton>
          </>
        )}
      </InnerAuth>
    </WrapperAuth>
  );
};
