export enum AuthTab {
  logIn = 'logIn',
  signUp = 'signUp',
  joinIn = 'joinIn',
}

export enum SocialAuth {
  logIn = 'logIn',
  signUp = 'signUp',
}

export enum TypeCommunity {
  manager = 'manager',
  resident = 'resident',
  community = 'community',
  admin = 'admin',
}

export enum PhoneVerifySteps {
  number = 'number',
  verification = 'verification',
}

export type TData = {
  [key: string]: {
    title: string;
    headerText: string;
    footerText: string;
    buttonsData: Array<{type: TypeCommunity; title: string; descr?: string}>;
    linkTitle: string;
  };
};

export enum AuthField {
  username = 'username',
  firstName = 'firstName',
  lastName = 'lastName',
  password = 'password',
  confirmPassword = 'confirmPassword',
  email = 'email',
  work = 'work',
  status = 'status',
}

export interface SignUpValues {
  [AuthField.username]: string;
  [AuthField.firstName]: string;
  [AuthField.lastName]: string;
  [AuthField.email]: string;
  [AuthField.password]: string;
  [AuthField.confirmPassword]?: string;
  [AuthField.work]?: string;
  [AuthField.status]?: string;
  typeCommunity?: TypeCommunity;
}

export interface LogInValues {
  [AuthField.email]: string;
  [AuthField.password]: string;
}

export enum CreateProfileSteps {
  Profile = 'profile',
  //Interests = 'profile-interests',
  PersonInterests = 'profile-likes',
  Recommendations = 'profile-recommendations',
  PhotoUpload = 'upload-photo',
  FirstPost = 'first-post',
  ListingCategory = 'listing-category',
  FirstListing = 'first-listing',
  ListingCreated = 'listing-created',
}

export enum SkipParamsOptions {
  skipPhone = 'skipPhone',
  skipAddress = 'skipAddress',
  autoAddress = 'autoAddress',
  skipProfile = 'skipProfile',
  skipInterests = 'skipInterests',
  autoAddKey = 'autoAddKey',
}

export interface SkipParams {
  skipPhone?: boolean;
  skipAddress?: boolean;
  autoAddress?: boolean;
  skipProfile?: boolean;
  skipInterests?: boolean;
  autoAddKey?: string;
}

export type UserInfoT = {
  hasMessages?: hasMessagesRow;
  isAuth?: boolean;
};

type hasMessagesRow = Record<string, boolean>;

export type GoogleParamsType = {
  credential: string;
  clientId: string;
};

export type FBparamsType = {
  id: string;
  accessToken: string;
  data_access_expiration_time: number;
  email: string;
  name: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
};

export type AppleParamsType = {
  authorization: {
    state: string;
    code: string;
    id_token: string;
  };
  user: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

export type AuthDataTypeGoogle = {
  google: {
    id: string;
    id_token: string;
    access_token: string;
  };
};

export type AuthDataTypeFB = {
  facebook: {
    id: string;
    access_token: string;
    expiration_date: string;
  };
};

export type AuthDataTypeApple = {
  apple: {
    id: string;
    token: string;
  };
};

export type UserSocialDataT = {
  firstName: string;
  lastName: string;
  imageUrl?: string;
};

export type ConnectSocialInfoT = {
  isFacebook: boolean;
  isGoogle: boolean;
  isApple: boolean;
};

export type SocialLoadingButtonT = {
  facebook: boolean;
  google: boolean;
  apple: boolean;
};

export interface SocialProps {
  tab?: SocialAuth;
  error?: string | null;
  reqWIthGoogle: () => void;
  reqWIthFacebook: () => void;
  reqWIthApple: () => void;
  loading?: boolean;
  connectSocialInfo?: ConnectSocialInfoT;
  disconnectAccount?: (provider: string) => void;
  loadingButton?: SocialLoadingButtonT;
  fbReady?: boolean;
  googleReady?: boolean;
  onGoogleLoginClick: () => void;
  onFBClick: () => void;
}

export interface SocialButtonPropsT {
  type: 'facebook' | 'google' | 'apple';
  connectSocialInfo?: ConnectSocialInfoT;
  connectInfo?: boolean;
  disconnectAccount?: (type: string) => void;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}
