import Avatar from 'react-nice-avatar';
import styled, {css} from 'styled-components';

import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Media} from '../../ui-kit/theme';
import {Text} from '../../ui-kit/Typography/Text';
import {fontPrimaryReg} from '../common/styles';

export const buttonText = css`
  font-size: 14px;
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
`;

export const PageWrapper = styled.section`
  width: 100%;
  height: max-content;
  padding: 1vw 6vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  box-sizing: border-box;
  & .page-loader__overlay {
    position: absolute;
  }
  ${Media.down.m} {
    padding: 6px 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

export const SettingsWrapper = styled.div<{max?: number}>`
  max-width: ${({max}) => (max ? max : 500)}px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div<{$isClickable?: boolean}>`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-after-overflow: ellipsis;
  ${({$isClickable}) => $isClickable && ` cursor: pointer;`}
`;

export const UpdatePhotoPersonal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const UpdatePhotoBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${Media.down.xs} {
    & button {
      width: 165px;
      white-space: nowrap;
    }
  }
`;

export const AvaratLg = styled(Avatar)`
  height: 126px;
  width: 126px;
`;

export const UpdatePhotoWrapper = styled.div`
  position: relative;
`;

export const UpdatePhotoIcon = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 7%;
  bottom: 2%;

  ${Media.down.s} {
    right: 0;
    bottom: 0;
  }
`;

export const UpdateBtnWrapper = styled.div`
  position: relative;
`;

export const UpdatePhotoInput = styled.input.attrs({type: 'file', id: 'update-photo'})`
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    cursor: pointer;
  }

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export const LinkButton = styled(Button).attrs({
  variant: 'danger',
  type: 'button',
})`
  width: fit-content;
  height: fit-content;
  text-underline-offset: 4px;
  text-decoration: underline;
  background-color: transparent;
  color: ${({theme}) => theme.palette.common.red};
  padding-left: 0;
  padding-right: 0;
`;

export const ButtonWrapper = styled(ContentWrapper)`
  padding: 15px 0;
  max-width: 500px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
`;

export const TextWrapper = styled(ContentWrapper)<{noPadding?: boolean}>`
  padding: ${({noPadding}) => (noPadding ? '0' : '10px')};
  & > * {
    &:first-child {
      margin-bottom: 10px;
    }
  }
  padding-left: 0;
`;

export const SectionWrapper = styled.section<{
  $noBorder?: boolean;
  $topBorder?: boolean;
  $hideMob?: boolean;
  mobpt?: string;
}>`
  padding: 20px 0;
  display: grid;
  grid-auto-flow: row;
  position: relative;
  border-bottom: ${({$noBorder, theme}) => ($noBorder ? 'none' : `1px solid ${theme.palette.background.secondary}`)};
  border-top: ${({$topBorder, theme}) => (!$topBorder ? 'none' : `1px solid ${theme.palette.background.secondary}`)};
  width: 100%;
  ${Media.down.m} {
    padding: 20px 0 10px 0;
    ${({$hideMob}) => $hideMob && ` padding-top: 50px; & > div {display: none}; border: none;`}
    ${({mobpt}) => mobpt && ` padding-top: ${mobpt};`}
  }
`;

export const SectionWrapperForm = styled.form<{noBorder?: boolean; $loading?: boolean}>`
  padding: 20px 0 40px 0;
  display: flex;
  flex-direction: column;
  border-top: ${({noBorder, theme}) => (noBorder ? 'none' : `1px solid ${theme.palette.background.secondary}`)};
  width: 100%;
  max-width: 500px;
  transition: 0.25s all;
  ${({$loading}) => $loading && ` opacity: 0.5; pointer-events: none;`}
`;

export const TextValueWrapper = styled.div`
  padding: 7px 6px 0 0;
  & > span {
    display: block;
    word-break: break-all;
  }
`;

export const TextLabel = styled.span`
  color: ${({theme}) => theme.palette.text.heptadecimal};
  font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
  font-size: 12px;
`;

export const EditButton = styled(Button).attrs({
  ghost: true,
  variant: 'secondary',
})<{$isDisabled?: boolean}>`
  & .button-value {
    ${buttonText};
    color: ${({theme, $isDisabled}) => ($isDisabled ? theme.palette.text.undecimal : theme.palette.text.octonary)};
  }
  ${({theme, $isDisabled}) => $isDisabled && `border-color: ${theme.palette.text.undecimal};`};
`;

export const UpdateButton = styled(Button).attrs({
  variant: 'primary',
  type: 'submit',
})`
  & .button-value {
    ${buttonText};
    color: ${({theme}) => theme.palette.text.secondary};
  }
`;

export const CancelButton = styled(Button).attrs({
  variant: 'danger',
  type: 'button',
})`
  & .button-value {
    ${buttonText};
    color: ${({theme}) => theme.palette.text.primary};
  }
`;

export const VerificationButton = styled(Button).attrs({
  ghost: true,
  variant: 'primary',
})<{isVerified?: boolean}>`
  border-color: ${({theme, isVerified}) => (isVerified ? theme.palette.common.aqua : theme.palette.common.red)};

  & .button-value {
    ${buttonText};
    color: ${({theme, isVerified}) => (isVerified ? theme.palette.common.aqua : theme.palette.common.red)};
  }
`;

export const SettingsInfoWrapper = styled.div<{noGap?: boolean}>`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${({noGap}) => (!noGap ? '20px' : '0')};
  margin: 10px;
`;

export const RowEntry = styled.div`
  display: flex;
  gap: 50px;
`;

export const SettingsInfoFormWrapper = styled(SettingsInfoWrapper)`
  margin: 20px 0 0;
  grid-row-gap: 20px;
`;

export const SettingsInfoFormWrapperAddAdress = styled(SettingsInfoWrapper)`
  grid-row-gap: 0px;
  margin: 20px 0 0;
`;

export const InfoPartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  ${Media.down.m} {
    grid-template-rows: 1fr 1fr;
  }
`;

export const InfoPairWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 10px 0;
`;

export const InfoPartFormWrapper = styled(InfoPartWrapper)<{$columnMob?: boolean}>`
  margin-top: 20px;
  grid-gap: 20px;
  ${Media.down.m} {
    ${({$columnMob}) => $columnMob && ` grid-template-columns: 1fr;`}
  }
`;

export const EditPhoneInputWrapper = styled.div<{$noPadd?: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({$noPadd}) => ($noPadd ? '0px' : '0 8px')};
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;
  position: relative;
  & > :first-child {
    width: 100%;
    position: absolute;
    z-index: 5;
    left: 15px;
  }
  .fieldset-box__root {
    padding: 26px 16px;
  }
  .fieldset-box__input {
    display: none;
  }
  .fieldset-box__label {
    left: 7px;
    top: -12px;
    transition: 0s all;
    font-size: 12px;
    transform: none;
  }
  ${Media.down.m} {
    padding: 0px;
  }
`;

export const MobFocused = styled.div`
  position: relative;

  .EmojiAction {
    position: absolute;
    z-index: 200;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }

  ${Media.up.m} {
    & .editor {
      textarea {
        padding-right: 38px;
      }
    }
  }
`;

export const EmojiAction = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0 6px;
  outline: none;
`;

export const AdditionalInfo = styled(Text).attrs({
  size: 12,
  variant: 'tertiary',
  color: 'quinary',
})`
  justify-self: flex-end;
  align-self: flex-end;
  margin: 5px 15px;
  box-sizing: border-box;
`;

export const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

export const AdminWrapper = styled.div`
  width: 100%;
  height: max-content;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 12px;
  border: ${({theme}) => `1px solid ${theme.palette.text.hexadecimal}`};
  background: ${({theme}) => theme.palette.background.undecimal};
  position: relative;
`;

export const AdminWrapperAddAddress = styled(AdminWrapper)`
  background: ${({theme}) => theme.palette.background.primary};
`;

export const AdminsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 20px;
`;

export const StyledSvg = styled(IconSvg)`
  cursor: pointer;
`;

export const UserLabel = styled.div`
  margin-right: 35px;

  & span {
    background-color: ${({theme}) => theme.palette.background.primary} !important;
    padding: 2px 14px;
  }
`;

export const UserLabelAddAddress = styled(UserLabel)`
  & span {
    background-color: ${({theme}) => theme.palette.background.septenary} !important;
  }
`;

export const InviteButton = styled(UserLabel).attrs({as: 'button', type: 'button', role: 'button'})`
  background-color: ${({theme}) => theme.palette.background.quinary};
  border: 1px solid ${({theme}) => theme.palette.background.quinary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 35%;
  right: 10px;
  cursor: pointer;
`;

export const WrapperRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  right: 6px;

  &,
  * {
    line-height: 0 !important;
  }
`;

export const MenuIconWrapper = styled.div`
  position: relative;
`;

export const LoadingText = styled.span`
  color: ${({theme}) => theme.palette.text.primary};
  ${buttonText};
`;

export const AvatarWithButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

export const SettingsInfoList = styled.div`
  & > * {
    cursor: pointer;
  }
`;

export const CommunityCards = styled.div`
  margin-top: 25px;
`;

export const Card = styled.div`
  padding: 12px;
  display: flex;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  background: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;
  align-items: center;
  ${fontPrimaryReg};
  margin-bottom: 10px;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;

export const CommCardContent = styled.div`
  display: flex;
  align-items: center;
`;

export const CommAvatar = styled.img`
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 50%;
`;
export const CommInfo = styled.div`
  margin-left: 16px;
`;

export const CommName = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.palette.grayscale._6};
  margin-bottom: 6px;
`;
export const CommMembers = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 6px;
  color: ${({theme}) => theme.palette.grayscale._5};
  & > svg {
    width: 18px;
    height: 18px;
  }
`;

export const SectionContent = styled.div`
  padding: 12px 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  .lds-spinner {
    margin: 0 auto;
    display: block;
  }
`;
