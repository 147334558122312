import React from 'react';
import {useTranslation} from 'react-i18next';
import {EventRepeatType, PricePeriodType, RewardType} from '../../queries/types/event';
import {Label} from '../../ui-kit/Labels';
import {ListingCardTitle, InnerDataPreview, InnerLabelItemTitle, InnerLabelCard, InnerRewardData} from './styles';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {ListerInfo} from '../Listings/styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';

export type TLabelCard = {
  name?: string;
  price?: number;
  pricePeriod?: PricePeriodType | null;
  rewardType?: RewardType;
  date?: Date;
  showDate?: boolean;
  withOutName?: boolean;
  eventType?: EventRepeatType;
  isReview?: boolean;
  authorName?: string;
  verified?: boolean;
};

const colorRewardType: {[key: string]: 'quaternary' | 'senary'} = {
  free: 'quaternary',
};

const LabelCard: React.FC<TLabelCard> = ({
  name,
  price,
  rewardType,
  date,
  showDate,
  withOutName,
  eventType,
  isReview,
  authorName,
  verified,
}) => {
  const {t} = useTranslation();
  const getLang = useGetLanguage();
  const {interpolation: i} = optionsI18n;
  if (!name && !withOutName) return null;
  const repeat = eventType && eventType !== EventRepeatType.one ? `(${t(`events:repeatShort.${eventType}`)})` : '';
  return (
    <InnerLabelCard $isReview={isReview}>
      {isReview && authorName && (
        <ListerInfo>
          <span>{authorName}</span>
          {verified && <IconSvg type="verified" stroke="green" width="11px" height="11px" viewBox="0 0 16 16" />}
        </ListerInfo>
      )}
      {!withOutName && (
        <InnerLabelItemTitle>
          <ListingCardTitle variantText={'quaternary'} $isReview={isReview}>
            {name}
          </ListingCardTitle>
        </InnerLabelItemTitle>
      )}

      <InnerDataPreview>
        {rewardType && rewardType !== RewardType.fee && (
          <Label variant={colorRewardType[rewardType]} squared={true}>
            {t(`events:card.rewardType.${rewardType}`)}
          </Label>
        )}
        {showDate && date && (
          <InnerRewardData>
            {i.format(new Date(date), 'ccc, LLL dd - p', getLang())} {repeat}
          </InnerRewardData>
        )}
        {Boolean(price) && (
          <>
            {/*<InnerPrice>{getSignValue(price || 0)}</InnerPrice>*/}
            {/*{pricePeriod && pricePeriod !== PricePeriodType.month && (*/}
            {/*  <InnerRewardData>{t(`events:pricePeriod.${pricePeriod}`)}</InnerRewardData>*/}
            {/*)}*/}
          </>
        )}
      </InnerDataPreview>
    </InnerLabelCard>
  );
};

const MemoLabelCard = React.memo(LabelCard);
export {MemoLabelCard};
