import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme/breakpoints';
import {fontPrimaryReg} from '../styles';

export const CounterInputWrapper = styled.div`
  width: 100%;
  padding: 14px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  ${fontPrimaryReg};
  box-sizing: border-box;
  background: ${({theme}) => theme.palette.grayscale._20};
`;

export const CounterInputLabel = styled.div`
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 13px;
  color: ${({theme}) => theme.palette.text.septenary};
  text-transform: none;
  ${Media.down.xxs} {
    font-size: 10px;
  }
`;

export const CounterInputActions = styled.div`
  display: flex;
  align-items: center;
`;

export const CounterAction = styled.button`
  font-size: 18px;
  color: ${({theme}) => theme.palette.grayscale._10};
  cursor: pointer;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`;

export const CounterValue = styled.div<{$active?: boolean}>`
  font-size: 14px;
  color: ${({theme, $active}) => ($active ? theme.palette.text.nonary : theme.palette.grayscale._13)};
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;

export const LineOffset = styled.div`
  margin-bottom: 20px;
`;
