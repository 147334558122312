import React from 'react';
import {SetItemsSearchParams} from '../../containers/Listings/types';
import {useTranslation} from 'react-i18next';
import {
  getListingOptions,
  getListingTypeParams,
  getShowListingsParams,
  isAdditionalListingOption,
} from '../../helpers/listings';
import {MobileSearchBlock} from '../common/SearchBlock/MobileSearchBlock';
import {useViewerId} from '../../hooks/user';

interface TProps {
  setSearchParams: SetItemsSearchParams;
}

interface SelectOptions {
  label?: string;
  value?: string;
}

export const MobileSearch: React.FC<TProps> = ({setSearchParams}) => {
  const {t} = useTranslation();
  const listingOptions = getListingOptions(t);
  const viewerId = useViewerId();

  const onSearch = (searchText?: string, listingType?: SelectOptions) => {
    const searchParams = isAdditionalListingOption(listingType?.value)
      ? getShowListingsParams(listingType?.value, viewerId)
      : getListingTypeParams(listingType);

    setSearchParams([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        ...searchParams,
      },
    ]);
  };

  return (
    <MobileSearchBlock placeholder={t('search:labels.whatLooking')} options={listingOptions} onSearch={onSearch} />
  );
};
