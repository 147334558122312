import React from 'react';
import {
  ButtonLabelType,
  CancelledType,
  CustomerType,
  ItemType,
  PaymentLabelType,
  RatingInfo,
  ReviewInfo,
  ReviewT,
  ReviewTypes,
  RewardType,
  UserType,
} from '../../../ui-kit/RequestCard/types';
import {variant} from '../../../ui-kit/Labels/types';
import {
  CardContentWrapper,
  CardImage,
  CardInfoText,
  CardTitle,
  CardWrapper,
  MarkDeleted,
} from '../../../ui-kit/RequestCard/styles';
import {Text} from '../../../ui-kit/Typography/Text';

import {CardButton} from '../../../ui-kit/RequestCard/CardButton';
import {Label} from '../../../ui-kit/Labels';
import {LabelsWrapper, DateInfoRow, DatesInfoWrapper, ReqInfoLabel, ReqInfoValue} from './styles';
import {TEvent} from '../../../types/event';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';

type EventMessageRequestCardProps = {
  title?: string;
  tags: {type: variant; label: string}[];
  image: string;
  descr: string;
  cardType: 'regular' | 'review';
  userType: CustomerType;
  rewardType?: RewardType;
  reviewType?: ReviewTypes;
  users: [UserType, UserType];
  paymentLabel: PaymentLabelType;
  buttonLabel: ButtonLabelType;
  rating?: RatingInfo;
  cancelled: CancelledType;
  review?: ReviewInfo;
  opponentInfo: UserType;
  amenityInfo: ItemType;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  isDeleted?: boolean;
  buttonSelectText?: string;
  event?: Partial<TEvent>;
  guests?: number;
  startDate?: Date;
};

export const AmenityMessageRequestCard: React.FC<EventMessageRequestCardProps> = ({
  title,
  image,
  tags,
  buttonLabel,
  descr,
  isDeleted,
  guests,
  startDate,
}) => {
  const {t} = useTranslation();
  const startTime = startDate ? new Date(startDate) : undefined;
  return (
    <CardWrapper>
      {isDeleted && (
        <MarkDeleted>
          <Text size={12} color={'primary'}>
            Deleted
          </Text>
        </MarkDeleted>
      )}
      <CardImage imageUrl={image} $isDeleted={isDeleted} />
      <CardContentWrapper>
        <CardTitle>{title}</CardTitle>
        <LabelsWrapper>
          {tags.map((t) => (
            <Label variant={t.type} key={t.label}>
              {t.label}
            </Label>
          ))}
        </LabelsWrapper>

        <DatesInfoWrapper>
          <DateInfoRow>
            <ReqInfoLabel>{t('requests:labelDate')}</ReqInfoLabel>
            <ReqInfoValue>{startTime && format(new Date(startTime), 'P')}</ReqInfoValue>
          </DateInfoRow>
          <DateInfoRow>
            <ReqInfoLabel>{t('requests:labelTime')}</ReqInfoLabel>
            <ReqInfoValue>{startTime && format(new Date(startTime), 'p')}</ReqInfoValue>
          </DateInfoRow>
          <DateInfoRow>
            <ReqInfoLabel>{t('events:requests.guests')}</ReqInfoLabel>
            <ReqInfoValue>{guests}</ReqInfoValue>
          </DateInfoRow>
        </DatesInfoWrapper>
        {!isDeleted && (
          <>
            <CardButton
              primary={buttonLabel.primary}
              secondary={buttonLabel.secondary}
              type={'primary'}
              disabled={buttonLabel?.disabled}
            />
            <CardInfoText>{descr}</CardInfoText>
            <CardButton primary={buttonLabel.primary} secondary={buttonLabel.secondary} type={'secondary'} />
          </>
        )}
      </CardContentWrapper>
    </CardWrapper>
  );
};
