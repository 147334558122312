import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {PageLoaderWrapper} from '../../components/Events/styles';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {ViewCompany} from '../../components/LocalBiz/View';
import {route} from '../../constants/routes';
import {communitiesState, currentCommunityState, userState} from '../../states';
import {NavContainer} from '../Navigation';

import {useDeleteCompany, useGetCompany, useListingCompany} from '../../hooks/company';

export const ViewEditCompanyContainer = () => {
  const {push} = useHistory();
  const {id} = useParams<{id: string}>();
  const community = useRecoilValue(currentCommunityState);
  const communities = useRecoilValue(communitiesState);
  const {id: userId} = useRecoilValue(userState) || {};
  const {data: company, loading} = useGetCompany(id);
  const deleteItem = useDeleteCompany();
  const {listItem, unlistItem} = useListingCompany();

  if (loading && !company?.objectId) {
    return (
      <NavContainer>
        <PageLoaderWrapper>
          <LottieLoader allScreen={true} $isVisible={true} />
        </PageLoaderWrapper>
      </NavContainer>
    );
  }

  const handleDeleteItem = () => {
    return deleteItem?.(company?.objectId as string).then(() => {
      push(route.localBiz.get());
    });
  };

  return (
    <ViewCompany
      item={company}
      community={community}
      loading={loading}
      isManager={true}
      viewerId={userId as string}
      onDelete={handleDeleteItem}
      onPublish={listItem.bind(null, company?.objectId as string)}
      onUnpublish={unlistItem.bind(null, company?.objectId as string)}
      managersCommunities={communities.length}
    />
  );
};
