import React, {useEffect, useState} from 'react';
import {HelpDesk} from '../../components/HelpDesk/HelpDesk';
import {useGetButtonActions, useGetHelpDeskData} from '../../hooks/helpDesk';
import {HelpDeskItemT} from '../../types/helpDesk';
import {helpDeskWhereResident} from '../../helpers/helpDesk';
import {HelpDeskPageProps} from './HelpDeskContainer';
import {useViewer} from '../../hooks/user';
import quotesJson from '../../files/quotes.json';
import {analyticsTrackFN} from '../../helpers/account';

export const PageResident: React.FC<HelpDeskPageProps> = ({community}) => {
  const viewer = useViewer();
  const [mainList, setMainList] = useState<HelpDeskItemT[]>([]);
  const [customList, setCustomList] = useState<HelpDeskItemT[]>([]);
  const deskData = useGetHelpDeskData({
    where: {
      ...helpDeskWhereResident,
      OR: [{Community: {have: {objectId: {equalTo: community?.objectId}}}}, {Community: {exists: false}}],
    },
  });
  const buttonActions = useGetButtonActions();

  useEffect(() => {
    const {custom, main} = deskData.lists;
    setCustomList(custom);
    setMainList(main);
  }, [deskData.data?.length]);

  const quotesList = JSON.parse(JSON.stringify(quotesJson));
  const loading = deskData.loading && !mainList.length && !customList.length && !quotesList.length;

  const analyticReport = (key: string) => {
    analyticsTrackFN(key, {
      name: viewer?.firstName,
      userEmail: viewer?.email,
      userId: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type,
    });
  };

  return (
    <HelpDesk
      mainList={mainList}
      quotes={quotesList}
      customList={customList}
      buttonActions={buttonActions}
      loading={loading}
      viewerId={viewer?.objectId}
      analyticReport={analyticReport}
    />
  );
};
