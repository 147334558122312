import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {Button} from '../../ui-kit/Button/Button';
import {fontPrimaryReg} from '../common/styles';
import {Title} from '../Events/styles';
import {RichText} from '../common/RichText/RichText';

export const PageWrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
  padding: 54px 4px;
  box-sizing: border-box;
  ${Media.down.s} {
    padding: 24px 10px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled(Button).attrs({
  rounded: true,
  variant: 'primary',
})`
  width: initial;
  padding: 10px 25px;

  & > div {
    padding: 0;
  }
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 32px 25px;

  ${Media.down.m} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${Media.down.xs} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.palette.background.primary};
  padding: 18px 21px 12px;
  min-width: 376px;
  border-radius: 4px;
  box-sizing: border-box;

  ${Media.down.xs} {
    width: 100%;
  }
`;

export const LoadersWrapper = styled.div`
  display: flex;
  gap: 35px;
`;

export const FileInputWrapper = styled.div`
  position: relative;
  & > label {
    height: 190px;
    ${Media.down.m} {
      height: 180px;
    }
  }
`;

export const DeleteBtnWrapper = styled.div`
  position: absolute;
  inset: 6px 6px auto auto;
  cursor: pointer;
  z-index: 1;
  line-height: 0;
`;

export const FileLoaderWrapper = styled.div`
  width: 100%;

  span {
    white-space: nowrap;
  }
`;

export const RightAlign = styled.div`
  text-align: right;
`;

export const CardWrapper = styled.div`
  position: relative;
`;

export const FormatLabel = styled.div`
  height: 18px;
  padding: 0 6px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.septenary};
`;

export const MenuBtnWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 8px;
`;

export const StateLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  text-align: center;
  background: ${({theme}) => theme.palette.background.octonary};
  border: 1px solid ${({theme}) => theme.palette.background.octonary};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 14px;
  color: ${({theme}) => theme.palette.text.octodecimal};
  ${fontPrimaryReg};
  z-index: 1;
  opacity: 1;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const InnerGroupCard = styled.div<{isOpacity?: boolean}>`
  position: relative;
  opacity: ${({isOpacity}) => (isOpacity ? 0.4 : 1)};
  width: 100%;
`;

export const GroupCardLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GroupCardLabelLeft = styled.div`
  overflow: hidden;
`;

export const GroupCardLabelMembers = styled.div`
  ${fontPrimaryReg};
  font-size: 14px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.grayscale._6};
`;

export const GroupCardLabelJoinBtn = styled.button<{$loading?: boolean}>`
  ${fontPrimaryReg};
  border-radius: 25px;
  border: none;
  background-color: ${({theme}) => theme.palette.grayscale._3};
  font-size: 12px;
  color: ${({theme}) => theme.palette.grayscale._6};
  box-sizing: border-box;
  padding: 5px 25px;
  max-height: 30px;
  cursor: pointer;
  transition: 0.15s all;
  &:hover {
    background-color: ${({theme, $loading}) => ($loading ? theme.palette.grayscale._3 : theme.palette.grayscale._4)};
  }
  opacity: ${({$loading}) => ($loading ? 0.9 : 1)};
`;

export const GroupCardLabelJoined = styled.span`
  ${fontPrimaryReg};
  font-size: 12px;
  color: ${({theme}) => theme.palette.grayscale._4};
  padding: 8px 25px;
`;

export const SubTitle = styled(Title).attrs({textColor: 'octonary'})<{
  variantText?: string;
  offsetBottom?: number;
}>`
  font-size: 18px;
  color: ${({theme}) => theme.palette.grayscale._18};
  margin-bottom: ${({offsetBottom}) => `${offsetBottom || 0}px`};
  ${Media.down.xs} {
    font-size: 14px;
  }
`;

export const GroupCardLabelName = styled(SubTitle)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 8px;
`;

export const WrapperGroupView = styled.div<{offsetTop?: string}>`
  max-width: 953px;
  width: 100%;
  margin: 0 auto;
  padding: ${({offsetTop}) => offsetTop || '54px'} 30px 18px 20px;
  box-sizing: border-box;
  ${Media.down.m} {
    padding: 24px 15px 18px 15px;
  }
`;

export const GroupContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 31px;
  ${Media.down.m} {
    margin-top: 15px;
  }
`;

export const GroupMainColumn = styled.div`
  width: 75%;
  ${Media.down.l} {
    width: 100%;
  }
`;

export const GroupRightColumn = styled.div`
  width: 23%;
  ${Media.down.l} {
    display: none;
  }
`;

export const MembersColumnHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MembersColumnTitle = styled.div`
  font-size: 20px;
  color: ${({theme}) => theme.palette.grayscale._10};
  ${fontPrimaryReg};
`;

export const MembersList = styled.div`
  margin-top: 20px;
  & > a {
    margin-bottom: 10px;
    display: block;
    margin-left: 0;
    min-width: unset;
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: flex-start;
  ${Media.down.m} {
    flex-direction: column;
  }
`;

export const GroupAvatar = styled.img`
  min-width: 126px;
  min-height: 126px;
  object-fit: cover;
  border-radius: 100%;
  max-width: 126px;
  max-height: 126px;
  ${Media.down.m} {
    min-width: 73px;
    min-height: 73px;
    max-width: 73px;
    max-height: 73px;
    display: block;
    margin-bottom: 12px;
  }
`;

export const GroupHeaderInfo = styled.div`
  margin-left: 24px;
  ${Media.down.m} {
    margin-left: 0;
  }
`;

export const GroupHeaderTitle = styled.h2`
  font-size: 26px;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 4px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._10};
  max-width: 100%;
  word-break: break-all;
`;

export const GroupHeaderDescr = styled(RichText).attrs({className: 'group'})`
  font-size: 16px;
  padding: 0;
  margin-bottom: 12px;
  font-weight: 400;
  line-height: 150%;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._10};
  max-width: 100%;
  word-break: break-all;
`;

export const GroupHeaderMembers = styled.div`
  display: flex;
  & > svg {
    margin-right: 10px;
  }
  align-items: center;
  font-size: 14px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._6};
`;

export const GroupFeedContainer = styled.div`
  margin-top: 31px;
`;

export const JoinRow = styled.div`
  margin-top: 80px;
  text-align: center;
  ${Media.down.m} {
    margin-top: 50px;
  }
`;
export const JoinRowButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._6};
  text-decoration: underline;
  padding: 0 4px 0 0;
  display: inline-block;
  cursor: pointer;
`;
export const JoinRowText = styled.div`
  font-size: 14px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._7};
`;

export const EmptyGroupsRow = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${fontPrimaryReg};
  & > svg {
    ${Media.down.m} {
      width: 180px;
      height: 180px;
    }
  }
`;

export const EmptyGroupsRowTitle = styled.div`
  font-size: 20px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._10};
`;

export const EmptyGroupsRowDescr = styled.div`
  margin-top: 10px;
  font-size: 15px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._7};
`;

export const LabelWrapper = styled.div<{indent?: number}>`
  margin-bottom: ${({indent}) => (indent ? `${indent}px` : '16px')};
`;

export const CounterBlock = styled.div<{$danger?: boolean}>`
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  width: 80px;
  margin-left: auto;
  & > span {
    color: ${({theme, $danger}) => ($danger ? theme.palette.common.red : theme.palette.grayscale._7)};
  }
`;
export const CounterItem = styled.span`
  font-size: 15px;
  ${fontPrimaryReg};
`;
