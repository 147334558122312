import styled, {css} from 'styled-components';
import {Media} from '../../../ui-kit/theme/breakpoints';
import {fontPrimaryReg} from '../../common/styles';

const fontSecondaryReg = css`
  font-family: ${({theme}) => theme.font.secondary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.secondary.regular.fontWeight};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  & button {
    min-width: 160px;
    width: auto;
    padding: 0px 6px;
  }
`;
export const TitleJoin = styled.div`
  ${fontSecondaryReg};
  font-size: 26px;
  line-height: 100%;
  color: ${({theme}) => theme.palette.text.quaternary};
  margin-top: 23px;
  margin-bottom: 18px;
  text-align: center;
  &:first-letter {
    text-transform: uppercase;
  }
  word-break: break-word;
  ${Media.down.s} {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 16px;
  }
`;
export const DescrJoin = styled.div`
  ${fontPrimaryReg};
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 25px;
  ${Media.down.s} {
    font-size: 14px;
  }
`;

export const JoinWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 28px 28px;
  max-width: 623px;
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.down.m} {
    width: auto;
    padding: 20px 20px;
    margin: 0 20px;
  }
`;
export const ExternalLink = styled.a`
  ${fontPrimaryReg};
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 25px;
  ${Media.down.s} {
    font-size: 18px;
  }
  text-decoration: underline;
  display: block;
  margin-top: 30px;
`;

export const BottomImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  ${Media.up.m} {
    display: none;
  }
`;
