import {atom} from 'recoil';
import {Message} from '../queries/types/message';
import {TItem} from '../types/item';
import {TContact} from '../types/messages';

export const preloadMessages = atom<Array<Message>>({
  key: 'preloadMessages',
  default: [],
});

export const preloadContactIdsState = atom<Array<string>>({
  key: 'preloadContactIds',
  default: [],
});

export const preloadContactsState = atom<TContact[] | null | undefined>({
  key: 'preloadContacts',
  default: [],
});

export const messageHints = atom<{chat: boolean; requests: boolean}>({
  key: 'messageHints',
  default: {chat: true, requests: false},
});

export const preloadListings = atom<Partial<TItem>[]>({
  key: 'preloadListings',
  default: [],
});
export const preloadFeatured = atom<Partial<TItem>[]>({
  key: 'preloadFeatured',
  default: [],
});

export const deletedState = atom<{items: string[]}>({
  key: 'deletedState',
  default: {items: []},
});
