import React from 'react';
import {useTranslation} from 'react-i18next';

import {PostBottomContent} from './parts/PostBottomContent';
import {PostTopContent} from './parts/PostTopContent';
import {PostContainerSwitch} from './PostContainerSwitch';
import {ButtonMore, AdditionalLabel, PostWrapper} from './styles';
import {PostProps} from './types';
import {route} from '../../../constants/routes';
import {EditPostContainer} from '../../../containers/CommunityFeed/EditPostContainer';
import {getUserName} from '../../../helpers/user';
import {TItem} from '../../../types/item';
import {PostTypeT} from '../../../types/post';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {MobileMenuButton} from '../../../ui-kit/Button/MobileMenuButton';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Menu} from '../../../ui-kit/Menu';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {Media} from '../../../ui-kit/theme';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {PopupView} from '../../common/Modal/PopupView';
import {inputPostType} from '../../common/PostInputs/types';
import {NotificationBar} from '../Notification/NotificationBar';
import {MemoNotificationText} from '../Notification/NotificationText';

import {useLinks} from '../../../hooks/common';
import {useHistory} from 'react-router-dom';
import {
  InteractionWrapper,
  PopupWrapper,
  OptionsWrapper,
  UserOption,
  ProfileLink,
  MessageLink,
  CloseWrapper,
  DarkBackground,
} from '../../Loop/styles';
import {ContentLoader} from '../../../ui-kit/Loader/ContentLoader';
import {Text} from '../../../ui-kit/Typography/Text';
import {Box} from '../../common/Box';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {Button} from '../../../ui-kit/Button/Button';
import {RecommendationPost} from './Recommendation';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';
import {usePostMenu, usePostStates, usePostTranlation} from '../../../hooks/post';
import {createProfileLink} from '../../../helpers/post';
import {getIsIncludesCmnt, getIsPublishedCmnt} from '../../../helpers/item';

const inputTypes: Partial<Record<PostTypeT, inputPostType>> = {
  [PostTypeT.message]: inputPostType.message,
  [PostTypeT.announcement]: inputPostType.announcement,
  [PostTypeT.poll]: inputPostType.poll,
  [PostTypeT.video]: inputPostType.video,
  [PostTypeT.comment]: inputPostType.comment,
  [PostTypeT.request]: inputPostType.request,
};

export const Post: React.FC<PostProps> = (props) => {
  const {
    text: originalText,
    Author,
    createdAt,
    id,
    objectId,
    isComment,
    comments,
    type,
    data,
    Items,
    createMenuOptions,
    viewerId,
    postActions,
    likes,
    isLiked,
    isWaved,
    waves,
    isSmiled,
    smiles,
    refetch,
    Attachments: OriginalAttachments,
    refetchComments,
    Options: originalOptions,
    hidePost,
    communityId,
    listingsCount: originalListingCount,
    isDetail,
    actions,
    Pin,
    communityPeople,
    translates: allTranslates,
    originalLang,
    getSignValue,
    adminLabels,
    sentAs,
    typeUser,
    menuActions,
    handleDelete,
    setEditing: setEditingUpper,
    hideAttach,
    isMulti,
    getPollData,
    isCommentsEditing,
    setIsModal,
    isLocal,
    localDeleted,
    isError,
    onCreateRetry,
    itemsLiked,
    onLikeItem,
    reportItemActions,
    toggleCreating,
  } = props;
  const {t, i18n} = useTranslation();
  const {getLink} = useLinks();
  const {push} = useHistory();
  const inputType = type && inputTypes[type];
  const isOwner = viewerId === Author?.objectId;
  const isPinned = !!Pin?.length;

  const {
    isShowReportModal,
    isShowInfoModal,
    isShowInteractions,
    setIsShowInfoModal,
    loading,
    pollData,
    editMode,
    isEditing,
    setIsEditing,
    report,
    enableEditMode,
    disableEditMode,
    closeInteractions,
    openInteractions,
    setIsShowReportModal,
    editedState,
    addToLocalEdited,
    resetLocalEdited,
    listingsCount,
  } = usePostStates({getPollData, objectId, setEditingUpper, originalListingCount});
  const Attachments = editedState?.Attachments || OriginalAttachments;
  const {showOriginal, showTranslate, text, haveTranslates, votedTotalCount, Options, isTranslated} = usePostTranlation(
    {
      i18n,
      originalOptions: editedState?.Options || originalOptions,
      allTranslates,
      originalText: editedState?.text || originalText,
    },
  );

  const {menuOptions, infoParams, mobileMenuOptions, reportParams} = usePostMenu({
    isOwner,
    enableEditMode,
    type,
    objectId,
    inputType,
    getPollData,
    votedTotalCount,
    t,
    createMenuOptions,
    openInteractions,
    isPinned,
    report,
    typeUser,
    handleDelete,
    id,
    refetchComments,
    isComment,
    setIsShowInfoModal,
    setIsShowReportModal,
    actions,
  });

  const setEditing = (b: boolean) => setIsEditing(b);
  const profileLink = createProfileLink(getLink, Author, type, sentAs, typeUser);

  const forwardDetailPage = () => {
    if (id && !isComment && !isDetail && !isLocal) {
      push(getLink(route.post.get({id: id})));
    }
  };

  const forwardCreateListing = () => {
    push(getLink(route.createItem.path));
  };

  const forwardPageUser = () => {
    push(profileLink);
  };
  const stopPropagation = (event: React.SyntheticEvent<HTMLDivElement>) => event.stopPropagation();
  if (objectId && localDeleted?.includes(objectId)) return null;
  if (
    type === PostTypeT.items &&
    (Items as Array<TItem>)?.every(
      (i) =>
        !i.isPublished ||
        getIsIncludesCmnt(i?.AdmHidden, communityId) ||
        i.isDeleted ||
        !getIsPublishedCmnt(i?.Published, communityId),
    )
  )
    return null;
  if (type === PostTypeT.recommendation) {
    return (
      <RecommendationPost
        Author={Author}
        isLiked={isLiked}
        likes={likes || 0}
        id={objectId}
        postActions={postActions}
        refetch={refetch}
      />
    );
  }
  if (type == PostTypeT.notification) {
    const personName = getUserName(typeUser, data?.personName?.split(' ')?.[0], data?.personName?.split(' ')?.[1]);
    return (
      <NotificationBar
        postActions={postActions}
        date={createdAt}
        hide={hidePost}
        postId={objectId}
        likes={likes || 0}
        waves={waves || 0}
        smiles={smiles || 0}
        isSmiled={isSmiled}
        isWaved={isWaved}
        isLiked={isLiked}>
        {data && <MemoNotificationText personName={personName} event={data?.event} objectId={data.objectId} />}
      </NotificationBar>
    );
  }
  if (!editMode && isCommentsEditing) return null;
  if (editMode && inputType)
    return (
      <EditPostContainer
        {...props}
        disableEditMode={disableEditMode}
        inputType={inputType}
        hideAttach={hideAttach}
        refetch={inputType === inputPostType.comment ? refetchComments : refetch}
        onLocalEdit={addToLocalEdited}
        resetLocalEdited={resetLocalEdited}
      />
    );
  if (!originalText?.trim()?.length && !Attachments?.length && !Items?.length) return null;
  return (
    <>
      <PostContainerSwitch
        $isComment={isComment}
        $isAnnouncement={type === PostTypeT.announcement}
        $isRequest={type === PostTypeT.request}
        isDetail={isDetail}
        id={id}>
        <PostWrapper>
          <PostTopContent
            forwardProfile={forwardPageUser}
            onClick={forwardDetailPage}
            text={text}
            Author={Author}
            createdAt={createdAt}
            objectId={objectId}
            isComment={isComment}
            Items={Items}
            type={type}
            Attachments={Attachments}
            communityId={communityId}
            listingsCount={listingsCount}
            postId={id}
            getSignValue={getSignValue}
            profileLink={profileLink}
            typeUser={typeUser}
            setIsModal={setIsModal}
            isLocal={isLocal}
            isLocalEdited={!!editedState}
            isError={isError}
            onCreateRetry={onCreateRetry}
            itemsLiked={itemsLiked}
            onLikeItem={onLikeItem}
            reportItemActions={reportItemActions}
          />
          <PostBottomContent
            isComment={isComment}
            comments={comments}
            actions={actions}
            likes={likes}
            isLiked={isLiked}
            viewerId={viewerId}
            postActions={postActions}
            objectId={objectId}
            refetch={refetch}
            refetchComments={refetchComments}
            type={type}
            Options={Options}
            createdAt={createdAt}
            communityPeople={communityPeople}
            translateState={{
              isTranslated,
              showTranslate,
              showOriginal,
              haveTranslates,
              origLang: originalLang,
            }}
            adminLabels={adminLabels}
            menuActions={menuActions}
            handleDelete={handleDelete}
            typeUser={typeUser}
            editMode={isEditing}
            setEditing={setEditing}
            isMulti={isMulti}
            isLocal={isLocal}
            Author={Author}
            toggleCreating={toggleCreating}
          />
          {(isPinned || (type === PostTypeT.request && Author?.id !== viewerId)) && (
            <AdditionalLabel>
              {type === PostTypeT.request && Author?.objectId !== viewerId && (
                <Button
                  width={'90px'}
                  height={'24px'}
                  variant={'primary'}
                  type={'button'}
                  ghost={false}
                  onClick={forwardCreateListing}>
                  <Text size={10}>{t('community:loop.buttons.offer')}</Text>
                </Button>
              )}
              {isPinned && <IconSvg type={'horn'} />}
            </AdditionalLabel>
          )}
          <ButtonMore $isDisabled={isLocal}>
            {!!menuOptions?.length && (
              <HiddenElement mq={Media.down.s}>
                <MenuButton item={objectId} menu={Menu} options={menuOptions} prevent={true} />
              </HiddenElement>
            )}
            {!!mobileMenuOptions?.length && (
              <HiddenElement mq={Media.up.s}>
                <MobileMenuButton isPortal={true} entries={mobileMenuOptions} portalProvider={IsModalProvider} />
              </HiddenElement>
            )}
          </ButtonMore>
        </PostWrapper>
      </PostContainerSwitch>

      {isShowReportModal && (
        <PopupView>
          <Confirmation
            isHaveTextArea
            title={reportParams.title}
            subTitle={reportParams.subtitle}
            buttonType={'danger'}
            textAreaLabel={reportParams.textAreaLabel}
            okText={reportParams.okButton}
            onClose={reportParams.close}
            onOk={reportParams.onOk}
          />
        </PopupView>
      )}

      {isShowInfoModal && (
        <PopupView>
          <Confirmation
            title={infoParams.title}
            subTitle={infoParams.subtitle}
            buttonType={'primary'}
            onClose={infoParams.close}
          />
        </PopupView>
      )}
      {isShowInteractions && (
        <PopupView>
          <DarkBackground onClick={closeInteractions}>
            <PopupWrapper>
              <InteractionWrapper>
                {loading ? (
                  <ContentLoader />
                ) : (
                  <OptionsWrapper onClick={stopPropagation}>
                    {Options.map((item, index) => {
                      if (!item?.countVotes) return null;
                      return (
                        <Box direction={'column'} display={'flex'} gap={'4px'} key={item.id}>
                          <Text variant={'primary'} color={'quaternary'} size={14}>
                            {item.text}
                          </Text>
                          <Text variant={'primary'} color={'quaternary'} size={12}>
                            {t('community:loop.post.interactions.voteCount', {
                              number: item.countVotes,
                              percent: ((item.countVotes / votedTotalCount) * 100).toFixed(1),
                            })}
                          </Text>
                          {pollData?.[index] &&
                            pollData?.[index]?.map((user) => {
                              const messageLink =
                                user?.objectId === Author?.objectId
                                  ? '#'
                                  : getLink(route.messages.get({contactId: user?.objectId}));
                              return (
                                <UserOption key={user?.objectId}>
                                  <ProfileLink to={getLink(route.profile.get({id: user?.objectId}))}>
                                    <Avatar size={'xxs'} src={user?.Avatar?.file?.url} />
                                    <Text variant={'primary'} color={'quaternary'} size={12}>
                                      {user?.firstName} {user?.lastName || user?.firstLetter}
                                    </Text>
                                  </ProfileLink>
                                  <MessageLink to={messageLink}>
                                    <Text variant={'primary'} color={'quaternary'} size={10}>
                                      {t('people:profile.buttons.message')}
                                    </Text>
                                  </MessageLink>
                                </UserOption>
                              );
                            })}
                        </Box>
                      );
                    })}
                  </OptionsWrapper>
                )}
                <CloseWrapper onClick={closeInteractions}>
                  <IconSvg type={'close'} stroke={'gray'} />
                </CloseWrapper>
              </InteractionWrapper>
            </PopupWrapper>
          </DarkBackground>
        </PopupView>
      )}
    </>
  );
};
