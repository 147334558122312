import React from 'react';
import {RequestCardReviewBaseProps} from '../types';
import {CardContentWrapper, CardTitle, CardWrapper} from '../styles';
import {CardButton} from '../CardButton';

export const RequestCardReviewBase: React.FC<RequestCardReviewBaseProps> = ({
  title,
  cardTopMessage,
  buttonLabel,
  children,
}) => {
  return (
    <CardWrapper>
      <CardContentWrapper padTop={25}>
        {cardTopMessage}
        <CardTitle>{title}</CardTitle>
        {children}
        {!buttonLabel?.hideBase && (
          <CardButton primary={buttonLabel.primary} secondary={buttonLabel.secondary} type={'secondary'} />
        )}
      </CardContentWrapper>
    </CardWrapper>
  );
};
