import styled, {StyledProps} from 'styled-components';
import {ProfileIntro} from '../../ui-kit/ProfileIntro';
import {Media} from '../../ui-kit/theme';
import {fontPrimaryReg} from '../common/styles';

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const WrapperProfileCard = styled.div`
  padding: 30px 123px 44px 111px;
  box-sizing: border-box;

  ${Media.down.xl} {
    padding: 54px 65px 44px 55px;
  }

  ${Media.down.l} {
    padding: 18px 22px 30px 22px;
  }

  ${Media.down.m} {
    padding: 0 12px 6px 12px;
  }
`;

export const ProfileCard = styled(ProfileIntro)`
  max-width: 950px;
  margin: 0 auto;
  & > div > div {
    word-break: break-word;
  }
  & .intro__footer-labels {
    span {
      word-break: break-all;
      font-size: 16px;
    }
    ${Media.down.m} {
      & > *:first-child {
        display: none;
      }
    }
    ${Media.down.xl} {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
    ${Media.down.s} {
      grid-column-gap: 16px;
      grid-row-gap: 6px;
      span {
        max-width: 100%;
      }
    }
    ${Media.down.xxs} {
      grid-template-columns: repeat(2, max-content);
      span {
        font-size: 16px;
      }
    }
  }
`;

export const WrapperContent = styled.div`
  max-width: 975px;
  width: 100%;
  padding: 6px 12px 18px 12px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const getTextColor = ({theme, variantText}: StyledProps<{variantText?: string}>) => {
  return variantText && theme.palette.text[variantText];
};

export const Title = styled.h1.attrs({textColor: 'quaternary'})<{
  textColor: string;
}>`
  ${fontPrimaryReg};
  font-size: 26px;
  line-height: 150%;
  color: ${getTextColor};
  margin: 0 0 5px 0;
  ${Media.down.xs} {
    font-size: 16px;
  }
`;

export const SubTitle = styled(Title).attrs({textColor: 'octonary'})<{
  variantText?: string;
  offsetBottom?: number;
}>`
  font-weight: 500;
  font-size: 14px;
  color: ${getTextColor};
  margin-bottom: ${({offsetBottom}) => `${offsetBottom || 0}px`};
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const ReviewsWrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 min(3vw, 15px) 10vh;
  box-sizing: border-box;
  padding-bottom: 40px;
  position: relative;
  ${Media.down.m} {
    padding: 0 12px;
  }
`;

export const ReviewsTitleWrapper = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  box-sizing: border-box;
  padding: 0px 20px;
`;

export const EmptyOffset = styled.div`
  height: 1px;
  opacity: 0;
  background-color: transparent;
  position: absolute;
  top: 400px;
`;
