import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {AddressForm} from '../../components/Account/Create/Forms/AddressForm';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {AccessType} from '../../constants/community';
import {route} from '../../constants/routes';
import {aliasCommunity, skipParams} from '../../states/community';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';

import {useAutoAddToCommunity} from '../../hooks/auth';
import {useLinks} from '../../hooks/common';
import {useJoinToCommunity} from '../../hooks/community';
import {useViewer} from '../../hooks/user';
import {analyticsTrackFN} from '../../helpers/account';
import {CommunityTypes} from '../../helpers/community';

export const AddressFormPage: React.FC = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {user} = useParams<{user: TypeCommunity}>();
  const {addByKey} = useAutoAddToCommunity();
  const [community] = useRecoilState(aliasCommunity);
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const [{autoAddress, autoAddKey}] = useRecoilState(skipParams);
  const viewer = useViewer();
  const {onSubmit} = useJoinToCommunity({
    initialState: {communityId: aliasedComm?.objectId, userId: viewer?.objectId},
    isPrivate: aliasedComm?.accessType === AccessType.private.toLowerCase(),
  });
  const [loading, setLoading] = useState(false);

  const successPath =
    user === TypeCommunity.manager
      ? getLink(route.profileCreateManager.path)
      : getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests}));

  const onSuccess = () => {
    if (aliasedComm?.objectId === autoAddKey) addByKey(autoAddKey);
    if (aliasedComm?.type === CommunityTypes.neighbourhood || aliasedComm?.type === CommunityTypes.circle) {
      push(getLink(route.communityFound.get({user: user})));
      return;
    }
    push(successPath);
  };

  const skipFound = aliasedComm?.objectId || user === TypeCommunity.manager;

  const handleAddressSubmit = async () => {
    if (aliasedComm && !autoAddKey) {
      setLoading(true);
      await onSubmit({localUserId: viewer?.objectId});
      setLoading(false);
    }
    analyticsTrackFN('Address Provided');
    return skipFound ? onSuccess() : push(getLink(route.communityFound.get({user: user})));
  };

  /*const handleCancelAddress = () =>
    skipPhone
      ? push(getLink(route.signUp.get({user: user})))
      : push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.number, user: user})));
  */
  return (
    <WrapperAuth step={3}>
      <AddressForm
        successLoading={loading}
        onSubmit={handleAddressSubmit}
        //onCancel={handleCancelAddress}
        autoAddress={autoAddress}
        communityAddress={community?.address}
        communityZip={community?.zip}
        communityLocation={community?.city}
      />
    </WrapperAuth>
  );
};
