import React from 'react';
import {CarouselWrapper, SlickCarousel} from './styles';

export const PhotoSlider: React.FC = ({children}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <CarouselWrapper>
      <SlickCarousel {...settings}>{children}</SlickCarousel>
    </CarouselWrapper>
  );
};
