import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {currentCommunity, userPermissions} from '../../states/community';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {AmenityFormAction} from '../../types/amenity';
import {useGetAmenityNoCache, useSetAmenityStatusDeleted} from '../../hooks/amenity';
import {ViewAmenityCreator} from '../../components/Amenities/ViewCreator';
import {useManageOrder, useOrdersList} from '../../hooks/order';
import {GQLOrderWhereInput} from '../../graphql.schema';
import {typeOrder} from '../../queries/types/order';
import {useViewerId} from '../../hooks/user';
import {timeBorderFrom, timeBorderTill} from '../../helpers/amenity';
import {PermissionsList} from '../../types/settings';
import {TypeCommunity} from '../../types/auth';

export type amenityOrdersFilters = {
  date: {value: Date; change: (d: Date) => void};
};

export const ViewEditAmenityContainer = () => {
  const viewerId = useViewerId('objectId');
  const {push} = useHistory();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const {id} = useParams<{id?: string}>();
  const {data: amenity, loading} = useGetAmenityNoCache({id});
  const {getLink} = useLinks();
  const [ordersDate, setOrderDate] = useState(new Date());
  const [permissions] = useRecoilState(userPermissions);

  const ordersFilter = {
    date: {
      value: ordersDate,
      change: (d: Date) => setOrderDate(d),
    },
  };
  const onSuccess = () => {
    if (!typeUser) return;
    push(getLink(route.amenities.path));
  };
  const {onDelete} = useSetAmenityStatusDeleted({id, onSuccess});

  // const {onSubmit, loading: actionLoading} = useResidentActionsItem({
  //   communityId: community?.objectId,
  //   onSuccess: onSuccess,
  // });

  const handlePublish = () => {
    // onSubmit({
    //   itemId: item?.objectId,
    //   typeBtn: GQLButtonsResidentTypes.publish,
    // });
  };

  const handleUnlist = () => {
    // onSubmit({
    //   itemId: item?.objectId,
    //   typeBtn: GQLButtonsResidentTypes.unlist,
    // });
  };

  const whereOrders: GQLOrderWhereInput = {
    orderType: {equalTo: typeOrder.amenityOrder},
    Amenity: {have: {objectId: {equalTo: amenity?.objectId}}},
    AND: [
      {startTime: {greaterThanOrEqualTo: timeBorderFrom(ordersFilter.date.value)}},
      {startTime: {lessThanOrEqualTo: timeBorderTill(ordersFilter.date.value)}},
    ],
  };
  const orders = useOrdersList({where: whereOrders});
  const manageOrder = useManageOrder({onSuccess: () => orders.refetch()});
  useEffect(() => {
    if (amenity?.objectId) {
      const pass =
        permissions.includes(PermissionsList.manageAmenity) ||
        typeUser === TypeCommunity.manager ||
        amenity.Lister?.objectId === viewerId;
      if (!pass) push(getLink(route.amenities.path));
    }
  }, [amenity?.objectId]);
  return (
    <ViewAmenityCreator
      communityAlias={community?.alias}
      communityId={community?.objectId}
      action={AmenityFormAction.edit}
      amenity={amenity}
      onPublish={handlePublish}
      onUnlist={handleUnlist}
      $loading={loading}
      onDelete={onDelete}
      viewerIsNotOwner={false}
      orders={orders.data}
      ordersLoading={orders.loading}
      manageOrder={manageOrder}
      ordersFilter={ordersFilter}
    />
  );
};
