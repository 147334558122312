import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {getTextMsgRequest} from '../../helpers/order';
import {useLinks} from '../../hooks/common';
import {Message, TypeMsg} from '../../queries/types/message';
import {MobTabMsgs, MsgStatus, TMessagesNotifications, UserChat} from '../../types/messages';
import {MessageBox as UiMessageBox} from '../../ui-kit/Chat/Messages/MessageBox';
import {RequestBox} from '../../ui-kit/Chat/Messages/RequestBox';
// import {Linkify} from '../common/Buttons/Linkify';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {RequestMessageBoxWrapper, UiMessageBoxWrapper, DateLine} from './styles';
import linkifyHtml from 'linkifyjs/html';
import {leftOnlyLinkTag} from '../../helpers/common';
import {isMobile} from 'react-device-detect';

type MessageProps = {
  message?: Message;
  isSeen?: boolean;
  isOwner?: boolean;
  avatar?: string;
  name?: string;
  onSeen: () => void;
  setRef: ((el: HTMLDivElement) => void) | null;
  setActiveOrder: (id?: string) => void;
  setActiveMobTab?: (tab: MobTabMsgs | null) => void;
  authorId?: string;
  imagePreviewToggle?: (f: {previewImg?: string; images: string[]}) => void;
  author?: Partial<UserChat>;
  viewerId?: string;
  isNote: boolean;
  isEndOfBlock?: boolean;
  isDifferentDay?: boolean;
  setIsModal?: (val: boolean) => void;
  localStatus?: MsgStatus;
  showStatus?: boolean;
};

export const removeSpaces = (text?: string): string => {
  if (text?.includes('<br><br>')) return removeSpaces(text?.replaceAll('<br><br>', '<br>'));
  return text?.replace('<p><br>', '<p>') || '';
};

export const MessageBox: React.FC<MessageProps> = ({
  avatar,
  name,
  message,
  isSeen,
  isOwner,
  onSeen,
  setRef: $setRef,
  setActiveOrder,
  setActiveMobTab,
  authorId,
  author,
  viewerId,
  isNote,
  isEndOfBlock,
  isDifferentDay,
  setIsModal,
  localStatus,
  showStatus,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref) return;

    const handleObserve = function (entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.target === ref && entry.isIntersecting) {
          observer.disconnect();
          onSeen();
        }
      });
    };

    const observer = new IntersectionObserver(handleObserve, {
      root: null,
      threshold: [0.1],
    });

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (!ref || !$setRef) return;

    $setRef(ref);
  }, [ref, $setRef]);

  if (isNote) return null;

  const status =
    localStatus === MsgStatus.loading ? MsgStatus.delivered : isSeen ? MsgStatus.seen : MsgStatus.delivered;

  const handleActiveOrder = () => {
    setActiveOrder(message?.Order?.objectId);
    setActiveMobTab && setActiveMobTab(MobTabMsgs.requests);
  };

  const callLinkify = (str: string, ...args: any[]): string => {
    return linkifyHtml(str, args);
  };
  const messageDate = message?.createdAt ? format(new Date(message?.createdAt), 'cccc, LLL d') : '';
  const messageText = leftOnlyLinkTag(message?.text);
  const formattedText = removeSpaces(messageText);
  if (message?.type === TypeMsg.notification) {
    if (message?.text === TMessagesNotifications.closed) return null;

    const dataText = getTextMsgRequest(message?.Order, message?.text, author, viewerId);
    const startDate = message?.Order?.startTime ? format(new Date(message.Order.startTime), 'PP') : '';
    const startTime = message?.Order?.startTime ? format(new Date(message.Order.startTime), 'p') : '';
    return (
      <RequestMessageBoxWrapper ref={setRef}>
        {isDifferentDay && <DateLine>{messageDate}</DateLine>}
        <RequestBox
          requestText={t(dataText.label, {
            user: dataText.opts.user,
            item: dataText.opts.item,
            author: dataText.opts.author,
            date: startDate,
            time: startTime,
            interpolation: {
              escapeValue: false,
            },
          })}
          onRequestClick={handleActiveOrder}
          isEnd={isEndOfBlock}
          messageTime={message?.createdAt}
          isOwner={isOwner}
        />
      </RequestMessageBoxWrapper>
    );
  }

  return (
    <UiMessageBoxWrapper ref={setRef}>
      {isDifferentDay && <DateLine>{messageDate}</DateLine>}
      <UiMessageBox
        time={message?.createdAt}
        userAvatar={avatar}
        userName={name || ''}
        profileLink={authorId && getLink(route.profile.get({id: authorId}))}
        messageText={formattedText}
        messageStatus={isOwner ? (status ? t(`messages:status.${status}`) : '') : ''}
        images={message?.Attachments}
        //imagePreviewToggle={imagePreviewToggle}
        linkify={callLinkify}
        Item={Item}
        Gallery={Gallery}
        isOwner={isOwner}
        isEnd={isEndOfBlock}
        setIsModal={setIsModal}
        isMobile={isMobile}
        showStatus={showStatus}
      />
    </UiMessageBoxWrapper>
  );
};
