import {gql} from '@apollo/client';
import UserFragment from './user';
import {ItemShortFragment} from './item';
import MemberFragment from './people';

export default gql`
  fragment ReviewFragment on Review {
    id
    objectId
    Item {
      ...ItemShortFragment
    }
    User {
      ...UserFragment
    }
    Author {
      ...MemberFragment
    }
    Order {
      id
      objectId
      status
    }
    text
    stars
    subject
    createdAt
    updatedAt
  }

  ${UserFragment}
  ${MemberFragment}
  ${ItemShortFragment}
`;
