import {Amenity} from './types/amenity';
import {CreateAmenityFieldsInput, EditAmenityFieldsInput} from '../types/amenity';
import {gql} from '@apollo/client';
import {AmenityFragment} from './fragments/amenity';
import {Connection} from './types/parse';
import {GQLAmenityManageAction} from '../graphql.schema';

export interface CreateAmenityResponseType {
  createAmenity: {
    amenity: Amenity;
  };
}

export interface CreateAmenityRequestType {
  fields: CreateAmenityFieldsInput;
}

export const CreateAmenityQuery = gql`
  mutation createAmenity($fields: CreateAmenityFieldsInput) {
    createAmenity(input: {fields: $fields}) {
      amenity {
        ...AmenityFragment
      }
    }
  }
  ${AmenityFragment}
`;

export interface UpdateAmenityResponseType {
  updateAmenity: {
    amenity: Amenity;
  };
}

export interface UpdateAmenityRequestType {
  id: string;
  fields: EditAmenityFieldsInput;
}

export const UpdateAmenityQuery = gql`
  mutation updateAmenity($id: ID!, $fields: UpdateAmenityFieldsInput) {
    updateAmenity(input: {id: $id, fields: $fields}) {
      amenity {
        ...AmenityFragment
      }
    }
  }
  ${AmenityFragment}
`;

export type AmenitiesResponseType = {
  amenities: Connection<Amenity>;
};

export const GetAmenitiesQuery = gql`
  query amenities($cursor: String, $first: Int, $skip: Int, $where: AmenityWhereInput, $order: [AmenityOrder!]) {
    amenities(after: $cursor, order: $order, first: $first, skip: $skip, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...AmenityFragment
        }
      }
    }
  }
  ${AmenityFragment}
`;

export const GetAmenityRequest = gql`
  query amenity($id: ID!) {
    amenity(id: $id) {
      ...AmenityFragment
    }
  }
  ${AmenityFragment}
`;

export type GetAmenityReqType = {
  id: string;
};

export type GetAmenityResType = {
  amenity: Amenity;
};

export const amenityManageQuery = gql`
  mutation amenityManageButtons($amenityId: String!, $communityIdList: [String]!, $action: AmenityManageAction!) {
    amenityManageButtons(input: {amenityId: $amenityId, communityIdList: $communityIdList, action: $action})
  }
`;

export interface amenityManageResponseType {
  amenityManagerButtons: boolean;
}

export interface amenityManageRequestType {
  amenityId: string;
  communityIdList: string;
  type: GQLAmenityManageAction;
}

export const amenityResidentButtonsQuery = gql`
  mutation amenityManageButtons($amenityId: String!, $communityIdList: [String]!, $action: AmenityManageAction!) {
    amenityManageButtons(input: {amenityId: $amenityId, communityIdList: $communityIdList, action: $action})
  }
`;

export interface amenityResidentButtonsResponseType {
  amenityManagerButtons: boolean;
}

export interface amenityResidentButtonsRequestType {
  amenityId: string;
  communityIdList: string[];
  action: GQLAmenityManageAction;
}
