import React from 'react';
import {TPollOption, PostTypeT} from '../../../../../types/post';
import {CloseText} from '../../styles';
import {useTranslation} from 'react-i18next';
import {usePollVote} from '../../../../../hooks/feed';

type TProps = {
  refetch?: () => void;
  type?: PostTypeT;
  objectId?: string;
  Options?: TPollOption[];
  handleVote: (id?: string) => void;
  optionId?: string;
};

export const VoteRemove: React.FC<TProps> = ({type, objectId, optionId, refetch, handleVote}) => {
  if (type !== PostTypeT.poll) return null;
  if (!optionId) return null;
  const {t} = useTranslation();
  const removeVoteText = t('community:loop.poll.removeVote');
  const {removeVote} = usePollVote();
  const handleClick = () => {
    handleVote(optionId);
    removeVote(objectId, optionId, refetch);
  };
  return <CloseText onClick={handleClick}>{removeVoteText}</CloseText>;
};
