import React from 'react';
import {EmptyState} from './EmptyState';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from './styles';

type TProps = {path: string};

const EmptyRes: React.FC<TProps> = ({path}) => (
  <Trans i18nKey={'common:emptyState.home.user.subText'}>{path ? <Link to={path} /> : <Link as={'span'} />}</Trans>
);

export const EmptyStateUser: React.FC<TProps> = ({path}) => {
  const {t} = useTranslation();
  const mainText = t('common:emptyState.home.user.mainText');
  return <EmptyState mainText={mainText} ElemSubText={<EmptyRes path={path} />} showIcon={false} />;
};

const EmptyResCheck = () => <Trans i18nKey={'common:emptyState.home.user.checkFeed'} />;

export const EmptyStateListingNotAv = () => {
  return <EmptyState mainText={''} ElemSubText={<EmptyResCheck />} showIcon={false} />;
};
