import React, {useState} from 'react';
import {CredentialsWrapper} from './styles';
import {
  ContentWrapper,
  SectionWrapper,
  SectionWrapperForm,
  InfoPartFormWrapper,
  InfoPartWrapper,
  EditPhoneInputWrapper,
  Card,
  CommunityCards,
  CommAvatar,
  CommInfo,
  CommName,
  CommMembers,
  CommCardContent,
} from '../commonStyles';
import {useTranslation} from 'react-i18next';
import {FormButtons, SettingsSubsection, SettingsEntry, ErrorMessage} from '../components';
import {CleaveInput, Input} from '../../../ui-kit/Form/Input';
import {Select} from '../../../ui-kit/Form/Select';
import {useLocationOptions} from '../../../hooks/locations';
import {Location} from '../../../queries/types/locations';
import {Profile} from '../../../types/profile';
import {ContactsFormValues, FormEditType, FormType} from '../../../types/settings';
import {useChangeLocation} from '../../../hooks/settings';
import {VerifyPhoneCode} from '../../Auth/PhoneVerification/VerifyPhoneCode';
import {DarkBackgroundWrapper} from '../../common/DarkBackground';
import PhoneInput from 'react-phone-number-input';
import {StyledInput} from '../../../ui-kit/Form/Input/styles';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../../ui-kit/Popup';
import {EmptyTag} from '../../../containers/MobileTabs';
import {TCommunity} from '../../../types/community';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {getResize} from '../../../helpers/file';
import defaultAvatar from '../../../ui-kit/Avatar/assets/avatar-default.png';
import defaultAvatarCommunity from '../../../ui-kit/ProfileIntro/images/avatar-default-community.png';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {getCommMenu, getMobileCommMenu} from '../../../helpers/settings';
import {translateFn} from '../../../types/common';
import {HiddenMenu} from '../../Amenities/styles';
import {Media} from '../../../ui-kit/theme';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {Menu} from '../../../ui-kit/Menu';
import {MobileMenuButton} from '../../../ui-kit/Button/MobileMenuButton';
import {MenuButtonWrapper} from '../../HelpDesk/styles';
import {MobileMenuEntry} from '../../../ui-kit/Menu/MobileMenu';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';
import {Label} from '../../../ui-kit/Labels';

export type ProfileWithCredentials = Partial<Profile & {email: string; phone: string}>;

export interface TVerifyPhoneData {
  error: string;
  showModal: boolean;
  loading: boolean;
  closeModal: () => void;
  resendCode: () => Promise<boolean>;
  verifyCode: (code: string) => Promise<boolean>;
}

interface TProps {
  profile: ProfileWithCredentials;
  location?: Location;
  form: FormType<ContactsFormValues>;
  edit: FormEditType;
  canEdit: boolean;
  isCanEditCountry: boolean;
  verifyPhoneData: TVerifyPhoneData;
}

export const SettingsContacts: React.FC<TProps> = ({
  profile,
  location,
  form,
  edit,
  canEdit,
  isCanEditCountry,
  verifyPhoneData,
}) => {
  return edit.isEditing ? (
    <ContactsForm
      form={form}
      location={location}
      onCancel={edit.handleEditEnd}
      isCanEditCountry={isCanEditCountry}
      verifyPhoneData={verifyPhoneData}
    />
  ) : (
    <ContactsInfo profile={profile} onEdit={edit.handleEditStart} location={location} canEdit={canEdit} />
  );
};

interface InfoProps {
  profile: ProfileWithCredentials;
  location?: Location;
  onEdit: () => void;
  canEdit: boolean;
}

const ContactsInfo: React.FC<InfoProps> = ({profile, onEdit, location, canEdit}) => {
  const {t} = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);

  const handleEdit = () => {
    setClicked(true);
    if (canEdit) {
      onEdit();
    }
    setTimeout(() => setClicked(canEdit), 3000);
  };

  return (
    <SectionWrapper>
      <SettingsSubsection
        label={t('settings:personal.contacts.title')}
        value={t('settings:personal.contacts.subtitle')}
        onClick={onEdit}
      />
      <CredentialsWrapper>
        <SettingsEntry label={t('settings:personal.contacts.email')} onClick={onEdit} value={profile?.email} />
        <SettingsEntry label={t('settings:personal.contacts.phone')} onClick={onEdit} value={profile?.phone} />
      </CredentialsWrapper>
      <SettingsEntry label={t('settings:personal.contacts.apt')} onClick={onEdit} value={profile?.aptSuite} />
      <SettingsEntry label={t('settings:personal.contacts.address')} onClick={onEdit} value={profile?.address} />
      <InfoPartWrapper>
        <SettingsEntry label={t('settings:personal.contacts.city')} onClick={onEdit} value={location?.name} />
        <SettingsEntry label={t('settings:personal.contacts.state')} onClick={onEdit} value={location?.State?.name} />
        <SettingsEntry label={t('settings:personal.contacts.zip')} onClick={onEdit} value={profile?.zip} />
        <SettingsEntry
          label={t('settings:personal.contacts.country')}
          onClick={onEdit}
          value={location?.Country?.name}
        />
      </InfoPartWrapper>
      {clicked && !canEdit && <ErrorMessage error={'settings:personal.saveOrCancel'} />}
      <FormButtons variant={'edit'} onClick={handleEdit} />
    </SectionWrapper>
  );
};

interface FormProps {
  location?: Location;
  form: FormType<ContactsFormValues>;
  onCancel: () => void;
  isCanEditCountry: boolean;
  verifyPhoneData: TVerifyPhoneData;
}

const ContactsForm: React.FC<FormProps> = ({location, form, onCancel, isCanEditCountry, verifyPhoneData}) => {
  const {t} = useTranslation();
  const {country, state, city, handleChangeDropdown} = useChangeLocation(location);
  const {countries, states, cities} = useLocationOptions({
    country: country.label,
    state: state.label,
  });
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.onChange({name: e.target.name, value: e.target.value});
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    form.values.Location = {link: city.value};
    form.onSubmit();
  };
  const addressOpen = {
    city: !!country.label && !!state.label,
    other: !!city.label,
  };
  const {closeModal, error, showModal, loading, verifyCode, resendCode} = verifyPhoneData;
  const phoneOnChange = async (value: string) => {
    form.onChange({name: 'phone', value: value});
  };
  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => phoneOnChange(e.target.value);
  return (
    <>
      <SectionWrapperForm onSubmit={handleSubmit}>
        <SettingsSubsection
          label={t('settings:personal.contacts.title')}
          value={t('settings:personal.contacts.subtitle')}
        />
        <InfoPartFormWrapper $columnMob={true}>
          <Input
            $isError={Boolean(form.error.email)}
            type={'email'}
            inputMode={'email'}
            name={'email'}
            label={t('settings:personal.contacts.email')}
            value={form.values.email}
            disabled={true}
          />
          <EditPhoneInputWrapper>
            <PhoneInput
              value={form.values.phone || ''}
              onChange={phoneOnChange}
              placeholder={t('settings:personal.contacts.phone')}
              international={true}
              countryCallingCodeEditable={false}
              defaultCountry={'CA'}
              inputComponent={StyledInput}
            />
            <Input
              $isError={form.error.phone}
              value={form.values.phone}
              onChange={handleChangeCode}
              label={t('settings:personal.contacts.phone')}
              type={'text'}
            />
          </EditPhoneInputWrapper>
        </InfoPartFormWrapper>
        <InfoPartFormWrapper>
          <Select
            values={country ? [country] : []}
            options={countries}
            onChange={handleChangeDropdown('country')}
            name={'country'}
            label={t('settings:personal.contacts.country')}
            disabled={!isCanEditCountry}
          />
          <Select
            values={state ? [state] : []}
            options={states}
            onChange={handleChangeDropdown('state')}
            name={'state'}
            label={t('settings:personal.contacts.state')}
            dropdownHandle={false}
          />
          {addressOpen.city && (
            <Select
              $isError={Boolean(form.error.Location)}
              values={city ? [city] : []}
              options={cities}
              onChange={handleChangeDropdown('city')}
              name={'city'}
              label={t('settings:personal.contacts.city')}
              dropdownHandle={false}
            />
          )}
          {addressOpen.other && (
            <CleaveInput
              options={{
                blocks: [3, 3],
                uppercase: true,
              }}
              $isError={Boolean(form.error.zip)}
              type={'text'}
              name={'zip'}
              label={t('settings:personal.contacts.zip')}
              value={form.values.zip}
              onChange={handleChange}
            />
          )}
        </InfoPartFormWrapper>
        <ContentWrapper>
          {addressOpen.other && (
            <Input
              $isError={Boolean(form.error.address)}
              type={'text'}
              name={'address'}
              label={t('settings:personal.contacts.address')}
              value={form.values.address}
              onChange={handleChange}
            />
          )}
        </ContentWrapper>
        <ContentWrapper>
          {addressOpen.other && (
            <Input
              $isError={Boolean(form.error.aptSuite)}
              type={'text'}
              inputMode={'numeric'}
              name={'aptSuite'}
              label={t('settings:personal.contacts.apt')}
              value={form.values.aptSuite}
              onChange={handleChange}
            />
          )}
        </ContentWrapper>
        <ContentWrapper>
          <ErrorMessage error={form.error.message || error} />
        </ContentWrapper>
        <FormButtons variant={'update'} disabled={form.loading} loading={form.loading} onCancel={onCancel} />
      </SectionWrapperForm>
      {showModal && (
        <DarkBackgroundWrapper>
          <VerifyPhoneCode
            onCancel={closeModal}
            error={Boolean(error)}
            loading={loading}
            resendCode={resendCode}
            onSubmit={verifyCode}
          />
        </DarkBackgroundWrapper>
      )}
    </>
  );
};

type JoinedCommsSettingsProps = {
  communities: Partial<TCommunity>[];
  pendingCommunities: Partial<TCommunity>[];
  onLeave: (id?: string) => void;
  setCurrentCommunity: (id: string) => void;
  loading?: boolean;
};

export const SettingsJoinedCommunities: React.FC<JoinedCommsSettingsProps> = ({
  communities,
  onLeave,
  setCurrentCommunity,
  pendingCommunities,
  loading,
}) => {
  const {t} = useTranslation();
  const [focus, setFocus] = useState<string>('');
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const handleOpenDelete = (id?: string) => {
    setFocus(id || '');
    setShowModalDelete(true);
  };
  const handleCloseDelete = () => {
    setFocus('');
    setShowModalDelete(false);
  };
  const handleRemove = async () => {
    focus && (await onLeave(focus));
    handleCloseDelete();
  };
  const modalsText = {
    delete: {
      title: t('settings:modals.leave.title'),
      okText: t('settings:modals.leave.button'),
      cancelText: t('settings:modals.leave.close'),
    },
  };
  const handleSubmit = (e: React.SyntheticEvent) => e.preventDefault();
  const PortalWrapper = isMobile ? BasePortal : EmptyTag;

  return (
    <SectionWrapperForm onSubmit={handleSubmit} $loading={loading}>
      <SettingsSubsection
        label={t('settings:personal.community.title')}
        value={t('settings:personal.community.subtitle')}
      />
      <CommunityCards>
        {communities?.map((el) => (
          <SettingCommCard
            setCurrentCommunity={setCurrentCommunity}
            onDelete={handleOpenDelete}
            name={el?.name}
            members={el?.countMembers}
            avatar={el?.avatar}
            key={el?.objectId}
            objectId={el?.objectId}
            t={t}
            autogenerated={el?.identityData?.autogenerated}
          />
        ))}
        {pendingCommunities?.map((el) => (
          <SettingCommCard
            setCurrentCommunity={setCurrentCommunity}
            name={el?.name}
            members={el?.countMembers}
            avatar={el?.avatar}
            key={el?.objectId}
            objectId={el?.objectId}
            t={t}
            isPending
            autogenerated={el?.identityData?.autogenerated}
          />
        ))}
      </CommunityCards>
      {isShowModalDelete && (
        <PortalWrapper>
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={handleRemove}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        </PortalWrapper>
      )}
    </SectionWrapperForm>
  );
};

type CommCardPropsT = {
  onDelete?: (id?: string) => void;
  members?: number;
  name?: string;
  objectId?: string;
  avatar?: string;
  t: translateFn;
  setCurrentCommunity?: (id: string) => void;
  isPending?: boolean;
  autogenerated?: boolean;
};

export const SettingCommCard: React.FC<CommCardPropsT> = ({
  avatar,
  name,
  members,
  objectId,
  onDelete,
  t,
  setCurrentCommunity,
  isPending,
  autogenerated,
}) => {
  let menuItems: MenuItemType[] = [];
  let mobMenuItems: MobileMenuEntry[] = [];
  if (onDelete && objectId && t) {
    const options = {
      objectId,
      leaveCallBack: onDelete,
      t,
    };
    menuItems = getCommMenu(options);
    mobMenuItems = getMobileCommMenu(options);
  }
  const prevent = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSetCommunity = () => {
    objectId && setCurrentCommunity && setCurrentCommunity(objectId);
  };

  return (
    <Card onClick={handleSetCommunity}>
      <CommCardContent>
        <CommAvatar src={getResize(avatar, 'md') || (autogenerated ? defaultAvatarCommunity : defaultAvatar)} />
        <CommInfo>
          <CommName>{name}</CommName>
          <CommMembers>
            <IconSvg type={'people'} />
            <span>
              {members} {t('common:labels.members')}
            </span>
            {isPending && (
              <Label squared variant={'septenary'}>
                {t('common:typeCommunity.pending')}
              </Label>
            )}
          </CommMembers>
        </CommInfo>
      </CommCardContent>
      <MenuButtonWrapper onClick={prevent}>
        {!!menuItems.length && (
          <HiddenMenu mq={Media.down.s}>
            <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
          </HiddenMenu>
        )}
        {!!mobMenuItems.length && (
          <HiddenMenu mq={Media.up.m}>
            <MobileMenuButton isPortal={true} entries={mobMenuItems} portalProvider={IsModalProvider} />
          </HiddenMenu>
        )}
      </MenuButtonWrapper>
    </Card>
  );
};
