import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {HelpDeskWrapper, PageWrapper, LeftSide, RightSide, TopLine, AddButton, LoaderBlock, FormEntity} from './styles';
import {QuoteBlock, QuoteBlockManager} from './QuoteBlock';
import {HelpDeskPropsT, HelpDeskManagerPropsT} from './types';
import {HelpList} from './HelpList';
import {HelpListManager} from './HelpListManager';
import {PlusIcon} from '../Listings/styles';
import {Form} from './Form';
import {ContentLoader} from '../../ui-kit/Loader/ContentLoader';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {HelpDeskSkeleton} from './HelpDeskSkeleton';

export const HelpDesk: React.FC<HelpDeskPropsT> = ({
  mainList,
  quotes,
  customList,
  buttonActions,
  loading,
  viewerId,
  analyticReport,
}) => {
  const {t} = useTranslation();
  const [showQuote, setShowQuote] = useState(true);
  const closeQuote = () => setShowQuote(false);

  if (loading) return <HelpDeskLoader />;
  return (
    <PageWrapper>
      <TopLine>
        <Text size={26} width={'medium'} color={'quaternary'}>
          {t('common:menu.helpDesk')}
        </Text>
      </TopLine>
      <HelpDeskWrapper>
        <LeftSide>
          {mainList?.length || customList?.length ? (
            <HelpList
              mainList={mainList}
              customList={customList}
              buttonActions={buttonActions}
              viewerId={viewerId}
              analyticReport={analyticReport}
            />
          ) : (
            <HelpDeskSkeleton />
          )}
        </LeftSide>
        {showQuote && quotes?.length && (
          <RightSide>
            <QuoteBlock handleClose={closeQuote} quotes={quotes} />
          </RightSide>
        )}
      </HelpDeskWrapper>
    </PageWrapper>
  );
};

export const HelpDeskManager: React.FC<HelpDeskManagerPropsT> = ({
  mainList,
  customList,
  setList,
  quotes,
  createData,
  refetch,
  loading,
  handlePublish,
  handleDelete,
  changeOrder,
  isEditing,
  setSelectedDesk,
  editData,
  viewer,
  selectedDesk,
}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const [addForm, setAddForm] = useState(false);
  const {t} = useTranslation();
  const scrollToForm = () => formRef?.current?.scrollIntoView({behavior: 'smooth'});

  const reseting = () => {
    createData.reset();
    editData.reset();
    setSelectedDesk(undefined);
  };
  const openForm = () => {
    if (isEditing) return;
    setAddForm(true);
    reseting();
    setTimeout(() => scrollToForm(), 100);
  };

  const closeForm = () => {
    setAddForm(false);
    reseting();
  };

  const onEdit = (id?: string) => {
    if (addForm) return;
    reseting();
    setSelectedDesk(id);
  };

  if (loading) return <HelpDeskLoader />;
  return (
    <>
      <PageWrapper>
        <TopLine>
          <Text size={26} width={'medium'} color={'quaternary'}>
            {t('common:menu.helpDesk')}
          </Text>
          <AddButton onClick={openForm} rounded width={'154px'}>
            <PlusIcon type={'plus'} stroke={'white'} width={'10px'} height={'10px'} />
            <span>{t('helpDesk:form.buttons.add')}</span>
          </AddButton>
        </TopLine>
        <HelpDeskWrapper>
          <LeftSide>
            {mainList?.length || customList?.length ? (
              <HelpListManager
                mainList={mainList}
                customList={customList}
                setList={setList}
                dragDisabled={addForm || isEditing}
                handleEdit={onEdit}
                handlePublish={handlePublish}
                handleDelete={handleDelete}
                changeOrder={changeOrder}
                editingOrOpened={!!isEditing || addForm}
                viewer={viewer}
                selectedDesk={selectedDesk}
                form={
                  <Form
                    createData={createData}
                    close={closeForm}
                    editData={editData}
                    isEditing={isEditing}
                    refetch={refetch}
                  />
                }
              />
            ) : (
              <HelpDeskSkeleton />
            )}
            {addForm && (
              <Form
                createData={createData}
                close={closeForm}
                editData={editData}
                isEditing={isEditing}
                refetch={refetch}
              />
            )}
          </LeftSide>
          <RightSide>{quotes?.length && <QuoteBlockManager quotes={quotes} handlePublish={handlePublish} />}</RightSide>
        </HelpDeskWrapper>
      </PageWrapper>
      <AddButtonPortal onClick={openForm} />
      <FormEntity ref={formRef} />
    </>
  );
};

export const HelpDeskLoader = () => {
  const {t} = useTranslation();
  return (
    <PageWrapper>
      <TopLine>
        <Text size={26} width={'medium'} color={'quaternary'}>
          {t('common:menu.helpDesk')}
        </Text>
      </TopLine>
      <LoaderBlock>
        <ContentLoader />
      </LoaderBlock>
    </PageWrapper>
  );
};
