import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormButtons, SettingsSubsection, SettingsEntry, ErrorMessage} from '../components';
import {
  ContentWrapper,
  SectionWrapper,
  SectionWrapperForm,
  InfoPartFormWrapper,
  InfoPartWrapper,
  AdditionalInfo,
  AvatarWithButtonWrapper,
  MobFocused,
  UpdatePhotoPersonal,
  UpdatePhotoBtnWrapper,
  UpdateBtnWrapper,
  UpdatePhotoWrapper,
  UpdatePhotoInput,
  AvaratLg,
  UpdatePhotoIcon,
} from '../commonStyles';
import {Profile, ProfileField} from '../../../types/profile';
import {CleaveInput, Input} from '../../../ui-kit/Form/Input';
import {Select} from '../../../ui-kit/Form/Select';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {addYears, subYears} from 'date-fns';
import {DatePickerInput} from '../../../ui-kit/Form/Input/DateInput';
import {ProfileFormValues, FormEditType, FormType} from '../../../types/settings';
import {MemoSymbolsCounter} from '../../common/SymbolsCounter';
import {getSiteAlias} from '../../../helpers/common';
import {UpdatePhotoError} from '../../../ui-kit/ProfileIntro/styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {DatePickerLanguageProvider} from '../../../ui-kit/Form/Input/DatePickerLanguageProvider';
import {useGetLanguage} from '../../../ui-kit/utils/language';
import {RTEdefault, TEXTAREA_MAX_LENGTH} from '../../../constants/common';
import {EmojiTab} from '../../common/EmojiTab/EmojiTab';
import {RichTextEditor} from '../../common/RichText/Editor';
import {handleEditorChangeForUserEvent, handleSetUser, handleSetEvent} from '../../../helpers/common';
import {UserMenu} from '../../Account/Popups/HeaderMenu/UserMenu';
import {EventMenu} from '../../Account/Popups/HeaderMenu/EventMenu';
import {Button} from '../../../ui-kit/Button/Button';
import {AvatarFullConfig, genConfig, Sex} from 'react-nice-avatar';
import domtoimage from 'dom-to-image';

interface TProps {
  profile: Partial<Profile> | null;
  form: FormType<ProfileFormValues>;
  edit: FormEditType;
  generateUsername: (name: string) => Promise<string | undefined>;
  canEdit: boolean;
  updateAvatar?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  avatar?: string;
  loadingAvatar: boolean;
  gender?: string;
  handleUpdatePhotoAsRandom?: (file: File) => Promise<boolean>;
}

export const SettingsProfile: React.FC<TProps> = ({
  profile,
  form,
  edit,
  generateUsername,
  canEdit,
  updateAvatar,
  updatePhotoError,
  avatar,
  loadingAvatar,
  gender,
  handleUpdatePhotoAsRandom,
}) => {
  return !edit.isEditing ? (
    <ProfileInfo
      profile={profile}
      onEdit={edit.handleEditStart}
      canEdit={canEdit}
      updateAvatar={updateAvatar}
      updatePhotoError={updatePhotoError}
      avatar={avatar}
      loadingAvatar={loadingAvatar}
    />
  ) : (
    <ProfileForm
      handleUpdatePhotoAsRandom={handleUpdatePhotoAsRandom}
      form={form}
      generateUsername={generateUsername}
      onCancel={edit.handleEditEnd}
      updateAvatar={updateAvatar}
      updatePhotoError={updatePhotoError}
      avatar={avatar}
      loadingAvatar={loadingAvatar}
      gender={gender}
    />
  );
};

interface ProfileInfoProps {
  profile?: Partial<Profile> | null;
  onEdit: () => void;
  canEdit: boolean;
  updateAvatar?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  avatar?: string;
  loadingAvatar: boolean;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  profile,
  onEdit,
  canEdit,
  updatePhotoError,
  avatar,
  loadingAvatar,
  updateAvatar,
}) => {
  const {t} = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const handleEdit = () => {
    setClicked(true);
    if (canEdit) {
      onEdit();
    }
    setTimeout(() => setClicked(canEdit), 3000);
  };
  return (
    <SectionWrapper>
      <SettingsSubsection
        onClick={onEdit}
        //label={t('settings:personal.profile.title')}
        //value={t('settings:personal.profile.subtitle')}
      />
      <ContentWrapper>
        <AvatarWithButtonWrapper>
          <UpdatePhotoWrapper>
            <Avatar src={avatar} size={'xl'} loading={loadingAvatar} />
            <UpdatePhotoIcon>
              {updateAvatar && (
                <UpdatePhotoInput onChange={updateAvatar} accept={'.jpg, .png, .jpeg, .webp, .svg, .gif, .bmp'} />
              )}
              <IconSvg type={'camera'} width={'16'} height={'16'} viewBox={'0 0 46 46'} />
            </UpdatePhotoIcon>
          </UpdatePhotoWrapper>
          {updatePhotoError && <UpdatePhotoError>{updatePhotoError}</UpdatePhotoError>}
        </AvatarWithButtonWrapper>
        <InfoPartWrapper>
          <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.firstName')} value={profile?.firstName} />
          <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.lastName')} value={profile?.lastName} />
          <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.gender')} value={profile?.gender} />
          <SettingsEntry
            onClick={onEdit}
            label={t('settings:personal.profile.birthDate')}
            value={
              profile?.birthDate
                ? t('settings:personal.date', {
                    date: new Date(profile?.birthDate),
                  })
                : undefined
            }
          />
        </InfoPartWrapper>
        <SettingsEntry
          onClick={onEdit}
          label={t('settings:personal.profile.username')}
          value={`${getSiteAlias()}/${profile?.username}`}
        />
        <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.bio')} value={profile?.bio} />
        <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.languages')} value={profile?.languages} />
        <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.study')} value={profile?.study} />
        <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.occupation')} value={profile?.occupation} />
        <SettingsEntry onClick={onEdit} label={t('settings:personal.profile.work')} value={profile?.work} />
      </ContentWrapper>
      {clicked && !canEdit && <ErrorMessage error={'settings:personal.saveOrCancel'} />}
      <FormButtons variant={'edit'} onClick={handleEdit} />
    </SectionWrapper>
  );
};

interface ProfileFormProps {
  form: FormType<ProfileFormValues>;
  generateUsername: (name: string) => Promise<string | undefined>;
  onCancel: () => void;
  updateAvatar?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  avatar?: string;
  loadingAvatar: boolean;
  gender?: string;
  handleUpdatePhotoAsRandom?: (file: File) => Promise<boolean>;
}

interface Options {
  label: string;
  value: string;
}

const getUsername = (source?: string): string | undefined => {
  if (source?.startsWith(getSiteAlias() + '/')) {
    return source?.split('/')[1];
  }
  return source;
};

const ProfileForm: React.FC<ProfileFormProps> = ({
  form,
  generateUsername,
  onCancel,
  updateAvatar,
  updatePhotoError,
  avatar,
  loadingAvatar,
  gender,
  handleUpdatePhotoAsRandom,
}) => {
  const {t} = useTranslation();
  const getLang = useGetLanguage();
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true);
  const [suggestedUsername, setSuggestedUsername] = useState<string>('');
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});
  const avatarRef = useRef<HTMLDivElement>(null);
  const [config, setConfig] = useState<AvatarFullConfig | null>();
  const [localLoading, setLocalLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.onChange({name: e.target.name, value: e.target.value});
  };
  const handleChangeUsername = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    form.onChange({name, value});

    const username = getUsername(value) ?? '';
    const response = await generateUsername(username);
    setUsernameAvailable(response === username);
    setSuggestedUsername(response || username);
  };
  const handleChangeGender = (values: Options[]) => {
    const value = values?.[0];
    form.onChange({name: 'gender', value: value?.value || ''});
  };
  const handleChangeDate = (value: Date) => {
    form.onChange({name: ProfileField.birthDate, value});
  };
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLocalLoading(true);
    if (config) {
      const scale = 1;
      const node = avatarRef.current;
      if (node) {
        const blob = await domtoimage.toBlob(node, {
          height: node.offsetHeight * scale,
          style: {
            transform: `scale(${scale})`,
            'border-radius': 0,
          },
          width: node.offsetWidth * scale,
        });
        const file = new File([blob], 'image.png');
        await handleUpdatePhotoAsRandom?.(file);
      }
    }
    setLocalLoading(false);
    form.values.username = suggestedUsername || form.values.username;
    form.onSubmit();
  };
  const handleChangeBio = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    if (next.value.length > TEXTAREA_MAX_LENGTH) {
      form.setError(new Error('error:bio'));
    } else {
      form.onChange({name: next.name, value: next.value});
    }
  };

  const genderOptions: Options[] = [
    {label: t('settings:personal.male'), value: t('settings:personal.male')},
    {
      label: t('settings:personal.female'),
      value: t('settings:personal.female'),
    },
    {
      label: t('settings:personal.notSpecified'),
      value: t('settings:personal.notSpecified'),
    },
  ];

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  const handleRandomAvatar = () => {
    setConfig(gender ? genConfig({sex: gender as Sex}) : genConfig());
  };

  const handleClearConfig = () => setConfig(null);

  return (
    <SectionWrapperForm onSubmit={handleSubmit}>
      <SettingsSubsection
      //label={t('settings:personal.profile.title')}
      //value={t('settings:personal.profile.subtitle')}
      />
      <AvatarWithButtonWrapper>
        <UpdatePhotoPersonal>
          {!config ? (
            <Avatar src={avatar} size={'xl'} loading={loadingAvatar} />
          ) : (
            <div ref={avatarRef}>
              <AvaratLg id={'avatar'} className={'avatar'} {...config} />
            </div>
          )}
          <UpdatePhotoBtnWrapper>
            <UpdateBtnWrapper>
              <Button
                ghost={true}
                height={'25px'}
                width={'165px'}
                loading={loadingAvatar}
                type={'button'}
                variant={'primary'}>
                {t('settings:personal.updatePhoto')}
              </Button>
              <UpdatePhotoInput onClick={handleClearConfig} onChange={updateAvatar} accept={'image/*'} />
            </UpdateBtnWrapper>
            <Button
              height={'25px'}
              width={'195px'}
              disabled={loadingAvatar}
              ghost={true}
              type={'button'}
              variant={'secondary'}
              onClick={handleRandomAvatar}>
              {t('settings:personal.randomPhoto')}
            </Button>
          </UpdatePhotoBtnWrapper>
          {/*<UpdatePhotoIcon>
            {updateAvatar && <UpdatePhotoInput onChange={updateAvatar} accept={'image/*'} />}
            <IconSvg type={'camera'} width={'16'} height={'16'} viewBox={'0 0 46 46'} />
          </UpdatePhotoIcon>*/}
        </UpdatePhotoPersonal>
        {updatePhotoError && <UpdatePhotoError>{updatePhotoError}</UpdatePhotoError>}
      </AvatarWithButtonWrapper>
      <InfoPartFormWrapper>
        <Input
          $isError={Boolean(form.error.firstName)}
          type={'text'}
          name={'firstName'}
          label={t('settings:personal.profile.firstName')}
          value={form.values.firstName || ''}
          onChange={handleChange}
        />
        <Input
          $isError={Boolean(form.error.lastName)}
          type={'text'}
          name={'lastName'}
          label={t('settings:personal.profile.lastName')}
          value={form.values.lastName || ''}
          onChange={handleChange}
        />
        <Select
          values={[
            form.values.gender
              ? {label: form.values.gender, value: form.values.gender}
              : {
                  label: t('settings:personal.notSpecified'),
                  value: t('settings:personal.notSpecified'),
                },
          ]}
          options={genderOptions}
          onChange={handleChangeGender}
          name={'gender'}
          label={t('settings:personal.profile.gender')}
        />
        <DatePickerLanguageProvider>
          <DatePickerInput
            icon={<IconSvg type={'calendar'} />}
            $isError={Boolean(form.error?.birthDate)}
            label={t('settings:personal.profile.birthDate')}
            selected={form.values?.birthDate}
            dateFormat="PP"
            onChange={handleChangeDate}
            name={ProfileField.birthDate}
            peekNextMonth
            minDate={subYears(new Date(), 100)}
            maxDate={addYears(new Date(), -11)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            yearDropdownItemNumber={10}
            scrollableYearDropdown
            locale={getLang()}
          />
        </DatePickerLanguageProvider>
      </InfoPartFormWrapper>
      <AdditionalInfo>{t('settings:personal.profile.descr')}</AdditionalInfo>
      <ContentWrapper>
        <CleaveInput
          options={{
            prefix: getSiteAlias() + '/',
            noImmediatePrefix: true,
            rawValueTrimPrefix: true,
          }}
          $isError={Boolean(form.error.username)}
          type={'text'}
          name={'username'}
          label={t('settings:personal.profile.username')}
          value={form.values.username}
          onChange={handleChangeUsername}
          icon={
            usernameAvailable ? (
              <IconSvg type={'circle-ok'} stroke={'green'} />
            ) : (
              <IconSvg type={'spam-2'} stroke={'red'} />
            )
          }
        />
        <AdditionalInfo>
          {usernameAvailable
            ? t('settings:personal.profile.usernameAvailable')
            : `${t('settings:personal.profile.suggestedUsername')}: ${suggestedUsername}`}
        </AdditionalInfo>
      </ContentWrapper>
      <MobFocused>
        <RichTextEditor
          $isError={Boolean(form.error.bio)}
          label={t('settings:personal.profile.bio')}
          name={'bio'}
          value={form.values.bio}
          onChange={(e, position) => handleChangeBio(e, position, form?.values?.bio ? form?.values?.bio : RTEdefault)}
          rows={5}
          emoji={emoji}
          setEmoji={setEmoji}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          eventName={eventName}
          setEventName={setEventName}
        />
        <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={form.values.bio} />
        {userMenuOpen && (
          <UserMenu
            top={positionSubMenu.top}
            left={positionSubMenu.left}
            handleSetUser={handleSetUser}
            setUserProfile={setUserProfile}
            searchText={searchText}></UserMenu>
        )}
        {eventMenuOpen && (
          <EventMenu
            top={positionSubMenu.top}
            left={positionSubMenu.left}
            handleSetEvent={handleSetEvent}
            setEventName={setEventName}
            searchText={searchText}></EventMenu>
        )}
      </MobFocused>
      <AdditionalInfo>
        <MemoSymbolsCounter limit={TEXTAREA_MAX_LENGTH} value={form.values.bio?.length} />
      </AdditionalInfo>
      <ContentWrapper>
        <Input
          $isError={Boolean(form.error.languages)}
          type={'text'}
          name={'languages'}
          label={t('settings:personal.profile.languages')}
          value={form.values.languages || ''}
          onChange={handleChange}
          icon={<IconSvg type={'speak'} width={'24px'} height={'24px'} />}
        />
      </ContentWrapper>
      <ContentWrapper>
        <Input
          $isError={Boolean(form.error.study)}
          type={'text'}
          name={'study'}
          label={t('settings:personal.profile.study')}
          value={form.values.study || ''}
          onChange={handleChange}
          icon={<IconSvg type={'news'} width={'24px'} height={'24px'} />}
        />
      </ContentWrapper>
      <ContentWrapper>
        <Input
          $isError={Boolean(form.error.work)}
          type={'text'}
          name={'work'}
          label={t('settings:personal.profile.work')}
          value={form.values.work || ''}
          onChange={handleChange}
          icon={<IconSvg type={'walking'} width={'24px'} height={'24px'} />}
        />
      </ContentWrapper>
      <ContentWrapper>
        <Input
          $isError={Boolean(form.error.occupation)}
          type={'text'}
          name={'occupation'}
          label={t('settings:personal.profile.occupation')}
          value={form.values.occupation || ''}
          onChange={handleChange}
          icon={<IconSvg type={'user'} width={'24px'} height={'24px'} />}
        />
      </ContentWrapper>
      <ErrorMessage error={form.error.message} />
      <FormButtons
        variant={'update'}
        disabled={form.loading || localLoading}
        loading={form.loading || localLoading}
        onCancel={onCancel}
      />
    </SectionWrapperForm>
  );
};
