import React from 'react';
import {useTranslation} from 'react-i18next';
import {MainWrapper, ServiceWrapper, ServiceIconWrapper} from '../styles';
import {IconImage} from '../../../../ui-kit/Icon/Image';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Button} from '../../../../ui-kit/Button/Button';
import {Box} from '../../../common/Box';
import {CreateMultiplyListingType} from '../../../../types/item';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {convertCentToDollar} from '../../../../helpers/payment';

interface IProps {
  onNext?: () => void;
  items?: CreateMultiplyListingType[];
  sign: string;
}

export const ListingCreated: React.FC<IProps> = ({onNext, items, sign}) => {
  const {t} = useTranslation();
  return (
    <MainWrapper>
      <Box
        direction="column"
        display="flex"
        gap="16px"
        textAlign="left"
        justify={'center'}
        items={'flex-start'}
        padding={'0 24px'}>
        <IconImage type={'music'} height={'225px'} width={'225px'} />
        <Text variant={'secondary'} color={'quaternary'} size={26} weight={'bold'}>
          {t('auth:firstListing.thirdTitle')}
        </Text>
        <Text variant={'primary'} color={'octonary'} size={16}>
          {t('auth:firstListing.thirdSubtitle')}
        </Text>
        {items?.map((item, index) => {
          return (
            <ServiceWrapper key={index}>
              <Box direction={'column'} display={'flex'} height={'100%'}>
                <Text variant={'secondary'} color={'quaternary'} size={14}>
                  {item?.title}
                </Text>
                <Text variant={'primary'} color={'octonary'} size={12}>
                  {`${sign}${convertCentToDollar(item?.price)} ${item?.period}`}
                </Text>
              </Box>
              <ServiceIconWrapper>
                <IconSvg
                  type={'checked-circle'}
                  stroke={'orange'}
                  width={'25px'}
                  height={'25px'}
                  viewBox={'0 0 30 30'}
                />
              </ServiceIconWrapper>
            </ServiceWrapper>
          );
        })}
        <Button width={'100%'} height={'46px'} onClick={onNext}>
          {t(`auth:firstListing.buttons.continue`)}
        </Button>
      </Box>
    </MainWrapper>
  );
};
