import {EventFormValues, TableEventItem, TEvent, TQueryEvent} from '../types/event';
import {optionsI18n} from '../libs/i18nextUtils';
import {useGetLanguage} from '../ui-kit/utils/language';
import {Event, EventRepeatType, RewardType} from '../queries/types/event';
import {TagType} from '../ui-kit/RequestCard/types';
import {PointerFile, SelectOption} from '../types/common';
import {MenuItemType} from '../ui-kit/Menu/types';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {variant} from '../ui-kit/Labels/types';
import {Namespace, TFunction} from 'react-i18next';
import {Order, StatusOrderType} from '../queries/types/order';
import {ItemType} from '../ui-kit/Table/types';
import {TResManageOrder} from '../hooks/order';
import {eachDayOfInterval, format, getDate, getDay, getMonth, getWeekOfMonth} from 'date-fns';

export const toPointerCreateEvent = (
  event: Partial<EventFormValues>,
  viewerId: string,
  parseImages?: (Partial<PointerFile> | undefined)[],
) => {
  const {eventDate, startTime, endTime, ...eventData} = event;
  const {startDate, endDate} = getStartEndDate({eventDate, startTime, endTime});

  return {
    ...eventData,
    startTime: startDate,
    endTime: endDate,
    maxAttendees: Number(eventData.maxAttendees),
    maxGuestsPerAttendee: Number(eventData.maxGuestsPerAttendee),
    images: parseImages,
    Lister: {link: viewerId},
  };
};

type TEditEvent = Omit<Partial<EventFormValues>, 'images'> & {
  images?: (Partial<PointerFile> | undefined)[];
};

export const toPointerEditEvent = (event: Partial<TEditEvent>) => {
  const {eventDate, startTime, endTime, ...eventData} = event;
  const {startDate, endDate} = getStartEndDate({eventDate, startTime, endTime});

  return {
    name: event?.name,
    rewardType: event?.rewardType,
    descr: event?.descr,
    locationDetails: event?.locationDetails,
    showAttendees: event?.showAttendees,
    allowGuests: event?.allowGuests,
    startTime: startDate,
    endTime: endDate,
    maxAttendees: Number(eventData.maxAttendees),
    maxGuestsPerAttendee: Number(eventData.maxGuestsPerAttendee),
    images: event.images,
    eventType: event?.eventType,
    openTo: event?.openTo,
    expiresDate: event?.expiresDate,
  };
};

export const toStateEvent = (event?: TQueryEvent): Partial<TEvent> => {
  const images = event?.images;
  const Atendees = event?.Attendees?.edges?.map((el) => el.node);

  return {
    ...event,
    Attendees: Atendees,
    images: images,
    serverImages: images,
  };
};

// export type timeOptionValueT = {hours: number, minutes: number}

export const createTimeOptions = () => {
  const getLang = useGetLanguage();

  const {interpolation} = optionsI18n;
  const timePointsArray = [];
  for (let i = 1; i < 24; i++) {
    const date1 = new Date();
    const date2 = new Date();
    date1.setHours(i);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);

    date2.setHours(i);
    date2.setMinutes(30);
    date2.setSeconds(0);
    date2.setMilliseconds(0);

    timePointsArray.push({
      value: date1,
      label: interpolation.format(date1, 'p', getLang()),
      key: `${i}:00`,
    });

    timePointsArray.push({
      value: date2,
      label: interpolation.format(date2, 'p', getLang()),
      key: `${i}:30`,
    });
  }
  return timePointsArray;
};

export const getTimeParts = (inputDate: Date | string | number) => {
  const date = new Date(inputDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return {hours, minutes};
};

export const getStartEndDate = (props: {eventDate?: Date; startTime?: Date; endTime?: Date}) => {
  if (!props.eventDate || !props.endTime || !props.startTime) return {startDate: undefined, endDate: undefined};
  const startParts = getTimeParts(props.startTime);
  const endParts = getTimeParts(props.endTime);
  const startCalc = startParts.hours * 60 + startParts.minutes;
  const endCalc = endParts.hours * 60 + endParts.minutes;

  const startDate = new Date(props.eventDate);
  startDate.setHours(startParts.hours);
  startDate.setMinutes(startParts.minutes);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(props.eventDate);
  if (startCalc >= endCalc) {
    endDate.setDate(endDate.getDate() + 1);
  }
  endDate.setHours(endParts.hours);
  endDate.setMinutes(endParts.minutes);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);

  return {startDate, endDate};
};

export const EventOptions = {
  upcoming: 'upcoming',
  past: 'past',
  myEvents: 'myEvents',
  allEvents: 'allEvents',
};

export const getSearchEventOptions = (t: (key: string, option?: {lng: string}) => string) => [
  {
    label: t('events:options.upcoming'),
    value: EventOptions.upcoming,
  },
  {
    label: t('events:options.past'),
    value: EventOptions.past,
  },
  {
    label: t('events:options.allEvents'),
    value: EventOptions.allEvents,
  },
];

export const isAdditionalEventOption = (value?: string) =>
  value !== EventOptions.past && value !== EventOptions.upcoming;

export const getShowEventsParams = () => {
  return {};
};

export const getEventTypeParams = (type?: string | null) => {
  if (!type) return {};
  const today = getDateHourBorder();
  if (type === EventOptions.past) {
    return {
      OR: [
        {
          AND: [
            {
              endTime: {
                lessThan: today,
              },
            },
            {OR: [{eventType: {equalTo: EventRepeatType.one}}, {eventType: {exists: false}}]},
          ],
        },
        {
          AND: [
            {
              expiresDate: {
                lessThan: today,
              },
            },
            {eventType: {notEqualTo: EventRepeatType.one}},
            {eventType: {exists: true}},
          ],
        },
      ],
    };
  }
  if (type === EventOptions.upcoming) {
    return {
      OR: [
        {
          AND: [
            {
              endTime: {
                greaterThan: today,
              },
            },
            {OR: [{eventType: {equalTo: EventRepeatType.one}}, {eventType: {exists: false}}]},
          ],
        },
        {
          AND: [
            {
              expiresDate: {
                greaterThan: today,
              },
            },
            {eventType: {notEqualTo: EventRepeatType.one}},
            {eventType: {exists: true}},
          ],
        },
      ],
    };
  }
  if (type === EventOptions.allEvents)
    return {
      OR: [
        {
          endTime: {
            lessThan: today,
          },
        },
        {
          endTime: {
            greaterThan: today,
          },
        },
      ],
    };
  return {};
};

export const getAmountLabelEvent = (event?: Partial<TEvent> | Event) => {
  return {
    label: `events:card.rewardType.${event?.rewardType}`,
    type: TagType[event?.rewardType as 'coffee' | 'free'],
  };
};

export const dataToEditEventFields = (values: Partial<TEvent>): Partial<EventFormValues> => {
  const startTime = values.startTime ? new Date(values.startTime) : undefined;
  const endTime = values.endTime ? new Date(values.endTime) : undefined;
  startTime?.setSeconds(0);
  startTime?.setMilliseconds(0);
  endTime?.setSeconds(0);
  endTime?.setMilliseconds(0);

  return {
    ...values,
    eventDate: values.startTime ? new Date(values.startTime) : new Date(),
    endTime,
    startTime,
  };
};

export const checkSelectedTime = (options: SelectOption<Date>[], time?: Date) => {
  return options.filter((el) => {
    if (!time) return false;
    const option = getTimeParts(el.value);
    const current = getTimeParts(time);
    return option.hours === current.hours && option.minutes === current.minutes;
  });
};

export interface GetMenuEventsOptions {
  isPublished?: boolean;
  isOwner?: boolean;
  isEventOwner: boolean;
  isCommOwner: boolean;
  objectId?: string;
  editLink?: string;
  previewLink?: string;
  guestLink?: string;
}

export interface GetMenuEventsFuncOptions {
  unPublishCallBack?: () => void;
  deleteCallBack?: () => void;
  publishCallBack?: () => void;
  reportCallBack?: () => void;
  t: (key: string) => string;
}

export const getMenuEvents = (options: GetMenuEventsOptions, functions: GetMenuEventsFuncOptions): MenuItemType[] => {
  const {isOwner, isPublished, editLink, previewLink, guestLink, isEventOwner, isCommOwner} = options;
  const {t, unPublishCallBack, deleteCallBack, publishCallBack, reportCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (isOwner && isPublished) {
    addedOptions.push({
      title: t('events:card.buttons.edit'),
      to: () => editLink || '/',
    });

    addedOptions.push({
      title: t('events:card.buttons.preview'),
      to: () => previewLink || '/',
    });

    addedOptions.push({
      title: t('events:card.buttons.guest'),
      to: () => guestLink || '/',
    });

    addedOptions.push({
      render: 'line',
    });

    if (unPublishCallBack) {
      addedOptions.push({
        title: t('events:card.buttons.unpublish'),
        onClick: () => unPublishCallBack(),
        render: 'danger',
      });
    }
    if (deleteCallBack) {
      addedOptions.push({
        title: t('events:card.buttons.delete'),
        onClick: () => deleteCallBack(),
        render: 'danger',
      });
    }
  }
  if (isOwner && !isPublished) {
    if (publishCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.publish'),
        onClick: () => publishCallBack(),
      });
    }
    addedOptions.push({
      render: 'line',
    });
    if (deleteCallBack) {
      addedOptions.push({
        title: t('amenities:card.buttons.delete'),
        onClick: () => deleteCallBack(),
        render: 'danger',
      });
    }
  }
  if (!isEventOwner && !isCommOwner && reportCallBack) {
    addedOptions.push({
      title: t('people:actions.report'),
      onClick: () => reportCallBack(),
      render: 'danger',
    });
  }
  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuEvents = (
  options: GetMenuEventsOptions,
  functions: GetMenuEventsFuncOptions,
): MobileMenuEntry[] => {
  return getMenuEvents(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const getEventLabels = (t: TFunction<Namespace>, event?: Partial<TEvent>) => {
  const labels = [{type: 'quinary' as variant, label: t('events:event')}];

  if (event?.rewardType === RewardType.free)
    labels.push({type: 'quaternary' as variant, label: t('events:card.rewardType.free')});

  return labels;
};

export const ordersToEventItems = (orders?: Order[], manage?: TResManageOrder) => {
  const items: ItemType<TableEventItem>[] | undefined = orders?.map((el) => ({
    Attendee: el.Requester,
    unit: el.Requester.aptSuite,
    guests: el.attendeeGuests,
    status: el.status,
    updatedAt: new Date(el.updatedAt),
    objectId: el?.objectId || '',
    isManager: true,
    isBooked: el.status === StatusOrderType.attending,
    manage: manage,
    orderId: el?.objectId,
  }));
  return items || [];
};

export interface GetMenuTableEventsOptions {
  isManager?: boolean;
  isBooked?: boolean;
  objectId?: string;
  requestLink?: string;
  profileLink?: string;
}

export interface GetMenuTableEventsFuncOptions {
  cancelCallBack?: (id?: string) => void;
  acceptCallBack?: (id?: string) => void;
  t: (key: string) => string;
}

export const getMenuTableEvents = (
  options: GetMenuTableEventsOptions,
  functions: GetMenuTableEventsFuncOptions,
): MenuItemType[] => {
  const {isManager, isBooked, objectId, requestLink, profileLink} = options;
  const {t, acceptCallBack, cancelCallBack} = functions;
  const addedOptions: MenuItemType[] = [];

  if (isManager && !isBooked) {
    addedOptions.push({
      title: t('events:table.buttons.accept'),
      onClick: () => acceptCallBack?.(objectId),
    });
  }

  addedOptions.push({
    title: t('events:table.buttons.booking'),
    to: () => requestLink || '/',
  });
  addedOptions.push({
    title: t('events:table.buttons.profile'),
    to: () => profileLink || '/',
  });
  addedOptions.push({
    render: 'line',
  });

  if (isBooked) {
    addedOptions.push({
      title: t('events:table.buttons.cancel'),
      onClick: () => cancelCallBack?.(objectId),
      render: 'danger',
    });
  }

  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileTableMenuEvents = (
  options: GetMenuTableEventsOptions,
  functions: GetMenuTableEventsFuncOptions,
): MobileMenuEntry[] => {
  return getMenuTableEvents(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const getDateHourBorder = () => {
  const today = new Date();
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
};

export const getRecurringDates = (type?: EventRepeatType, _eventDate?: Date) => {
  if (type === EventRepeatType.one || !_eventDate) return undefined;
  const eventDate = new Date(_eventDate);

  const expired = new Date(eventDate);
  expired.setFullYear(new Date(eventDate).getFullYear() + 1); //event date + 1 year
  const range = eachDayOfInterval({
    start: eventDate,
    end: new Date(expired),
  });
  if (type === EventRepeatType.onceWeek) return getOnceAWeekInterval(range, eventDate);
  if (type === EventRepeatType.everyTwoWeeks) return getEveryTwoWeekInterval(range, eventDate);
  if (type === EventRepeatType.quarterly) return getQuarterInterval(range, eventDate);
  if (type === EventRepeatType.month) return getEveryMonthInterval(range, eventDate);

  return range;
};

const getOnceAWeekInterval = (range: Date[], eventDate: Date) => {
  const day = getDay(eventDate);
  return range.filter((el) => el?.getDay() === day);
};

const getEveryTwoWeekInterval = (range: Date[], eventDate: Date) => {
  const day = getDay(eventDate);
  const startWeek = getWeekOfMonth(eventDate);

  const includeStart = new Date().getTime() < eventDate.getTime() ? [eventDate] : []; //check if today < event date
  const checkWeek = (w: number) => (startWeek % 2 ? !(w % 2) : !!(w % 2));

  const filtered = range.filter((el) => getDay(el) === day && checkWeek(getWeekOfMonth(el)));

  return [...includeStart, ...filtered];
};

const getEveryMonthInterval = (range: Date[], eventDate: Date) => {
  const date = getDate(eventDate);

  return range.filter((el) => {
    return getDate(el) === date;
  });
};

const getQuarterInterval = (range: Date[], eventDate: Date) => {
  const date = getDate(eventDate);
  const month = getMonth(eventDate);
  const possibleMonths = [0, 1, 2, 3].map((el) => {
    const nextMonth = month + 4 * el;
    if (nextMonth > 12) return nextMonth - 12;
    return nextMonth;
  });

  return range.filter((el) => {
    return date === getDate(el) && possibleMonths.includes(getMonth(el));
  });
};

export const getNextEventDate = (startTime?: Date, type?: EventRepeatType): Date | null => {
  if (!startTime) return null;
  const date = format(new Date(startTime), 'P');
  const range = getRecurringDates(type, startTime);
  const index = range?.findIndex((el) => format(el, 'P') === date);
  if (index === 0) return range?.[1] || null;
  if (!index || index === -1) return null;

  return range?.[index + 1] || null;
};

export const getActualEventDate = (startTime?: Date, type?: EventRepeatType): Date | undefined => {
  if (type === EventRepeatType.one || !type) return startTime;
  if (!startTime) return;
  const time = new Date().getTime();
  const range = getRecurringDates(type, startTime);
  const upcoming = range?.find((el) => el.getTime() > time);
  if (upcoming) {
    const start_min = new Date(startTime).getMinutes();
    const start_hours = new Date(startTime).getHours();
    upcoming.setHours(start_hours);
    upcoming.setMinutes(start_min);
  }

  return upcoming;
};

export const getEventDatesWithActual = (startTime?: Date, type?: EventRepeatType) => {
  if (type === EventRepeatType.one || !type || !startTime) return {range: [], actualIndex: 0};
  const time = new Date().getTime();
  const range = getRecurringDates(type, startTime);
  const upcoming = range?.findIndex((el) => el.getTime() > time) || 0;

  return {range, actualIndex: upcoming < 0 ? 0 : upcoming};
};

export const filterOrdersByDate = (filterDate?: Date, type?: EventRepeatType, startTime?: Date, orders?: Order[]) => {
  if (type === EventRepeatType.one || !type || !startTime || !filterDate) return orders;
  const fdate = format(new Date(filterDate), 'P');
  const range = getRecurringDates(type, startTime)?.map((el) => format(new Date(el), 'P'));
  const f_index = range?.findIndex((el) => el === fdate) || 0;
  return orders?.filter((order) => {
    if (!order?.recurringEvent && format(new Date(order?.startTime), 'P') === fdate) {
      return true;
    }
    if (order?.recurringEvent && f_index >= 0 && range?.slice(f_index).some((el) => el === fdate)) {
      return true;
    }
    return false;
  });
};
