import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {AddMenu} from '../../components/Account/Popups/HeaderMenu/AddMenu';
import {CommunityMenu} from '../../components/Account/Popups/HeaderMenu/CommunityMenu';
import {ProfileMenu} from '../../components/Account/Popups/HeaderMenu/ProfileMenu';
import {MemoHelpCentreButton} from '../../components/Account/Popups/HelpCentreButton/HelpCentreButton';
import {PlusIcon} from '../../components/Amenities/styles';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {routes} from './constans';
import {ToolTips} from './ToolTips';
import {paths, route} from '../../constants/routes';
import {getResize} from '../../helpers/file';
import {hideMobNav, showGoBack, visibleMobNav} from '../../helpers/mobNavigation';
import {currentCommunity, userInfo, userPermissions} from '../../states/community';
import {navState} from '../../states/navState';
import {isResidentState, typeUser as StateTypeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {TRoutes} from '../../types/navigation';
import CircleProgressBar from '../../ui-kit/CircleProgressBar';
import {Navigation} from '../../ui-kit/Navigation';
import {goBack, navOffsetsT, viewMode} from '../../ui-kit/Navigation/types';
import {NotificationsContainer} from '../Account/Notifications';
import {ChecklistContainer} from '../Checklist/ChecklistContainer';
import {VerifyEmailMessageContainer} from '../Messages/VerifyEmailMessageContainer';
import {PermissionsList} from '../../types/settings';

import {useRedirectOnSignUp, useSignOut} from '../../hooks/auth';
import {useGetCheckListInfo} from '../../hooks/checklist';
import {useLinks} from '../../hooks/common';
import {useGetCommunityAdmin, useSetCommunityIfNotSetted} from '../../hooks/community';
import {useGetDocuments} from '../../hooks/document';
import {useExistsUserMessages, useUnreadMessages} from '../../hooks/message';
import {useMobCommunitiesDropdown, useNavigation} from '../../hooks/navigation';
import {useLiveNotifications} from '../../hooks/notifications';
import {useCheckIsSupport, useViewer} from '../../hooks/user';
import {MobileCommunitiesDropdown} from '../../components/common/Modal/MobileCommunitiesDropdown';

export const NavContainer: React.FC<{
  viewMode?: viewMode;
  navOffsets?: navOffsetsT;
  verifyEmailMsgAppendTop?: boolean;
  hideHeader?: boolean;
}> = ({children, viewMode, navOffsets, verifyEmailMsgAppendTop, hideHeader}) => {
  const [loading, setLoading] = useState(false);
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [isResident, setIsResident] = useRecoilState(isResidentState);
  const [navOpen, setNavOpen] = useRecoilState(navState);
  const [community] = useRecoilState(currentCommunity);
  const [typeUser] = useRecoilState(StateTypeUser);
  const [permissions] = useRecoilState(userPermissions);
  const [userInf, setUserInfo] = useRecoilState(userInfo);
  useRedirectOnSignUp();
  const {t} = useTranslation();
  const viewer = useViewer();
  const hasCommunitySwitched = !!viewer?.tips?.communitySwitched;
  const notifications = useLiveNotifications({
    notificationsOpened,
    commId: community?.objectId,
  });
  const hasNotifications = notifications.data?.length > 0 || !viewer?.checkListSeen;
  const {count} = useUnreadMessages(community?.objectId);
  const getAdmin = useGetCommunityAdmin();
  const {location, goBack, push} = useHistory();
  const isSupport = useCheckIsSupport({email: viewer?.email});
  const [notifCleared, setNotifCleared] = useState(false);
  const handleSetState = (value: boolean) => setNavOpen(value);
  const {getLink, getLinksForObj} = useLinks();
  const {signOut} = useSignOut({
    onSuccess: () => {
      setLoading(false);
    },
  });
  const {
    active: cdActive,
    setActive: cdSetActive,
    links: cdLinks,
    openCD,
    cdShowArrow,
  } = useMobCommunitiesDropdown(typeUser, viewer?.Communities?.count);
  useSetCommunityIfNotSetted(viewer?.objectId, community?.id, community === null);
  const {count: documentsCount} = useGetDocuments();
  const messagesExists = useExistsUserMessages({commId: community?.objectId, objectId: viewer?.objectId});
  //const showCommMenu = Number(viewer?.Communities?.count) >= 1;
  const {headMenu, menuItemsTop, menuItemsBottom, menuItemsMobile} = useNavigation({
    routes: getLinksForObj(routes) as TRoutes,
    nameCommunity: community?.name,
    typeUser,
    msgsNotices: count,
    messagesExists: true,
    isJoinedCommunity: !!community?.id,
    isResident,
    documentsCount,
    isSupport,
    permissions,
  });
  useEffect(() => {
    getAdmin(viewer?.objectId ?? '').then((data) => {
      const nextValue = !data?.objectId && typeUser === TypeCommunity.resident;

      if (isResident !== nextValue) {
        setIsResident(nextValue);
      }
    });
  }, []);
  const openNotifications = () => setNotificationsOpened(true);
  const dashBoardLink = useMemo(() => menuItemsTop.filter((el) => el.path === 'dashboard')[0], [menuItemsTop]);
  const setVisible = (visible: boolean) => setNotificationsOpened(visible);
  const showHelpButton = !location.pathname.includes(paths.messages);
  const hideMobNavigation = hideMobNav(location.pathname);
  const onSignOut = () => {
    signOut();
    setLoading(true);
  };
  useEffect(() => {
    notifications.data?.length > 0 && setNotifCleared(false);
  }, [notifications.data?.length]);
  useEffect(() => {
    messagesExists && setUserInfo({hasMessages: {...userInf.hasMessages, [community?.objectId as string]: true}});
  }, [messagesExists, community?.objectId]);
  const setCleared = (b: boolean) => setNotifCleared(b);
  const goBackData: goBack = {text: t('common:goBack'), onClick: () => goBack(), show: showGoBack(location.pathname)};
  const [isVerified, setIsVerified] = useState<boolean | undefined>(viewer?.emailVerified || viewer?.phoneVerified);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const closeAddMenuHandler = () => setAddMenuOpen(false);
  const openAddMenuHandler = () => setAddMenuOpen(true);

  const [progressMenuOpen, setProgressMenuOpen] = useState(false);
  const closeProgressMenuHandler = () => setProgressMenuOpen(false);
  const openProgressMenuHandler = () => setProgressMenuOpen(true);
  if (loading) return <LottieLoader $isVisible={loading} />;
  const {completedCheckList, checklistCompletion} = useGetCheckListInfo();
  const checklistButtonName = t('checklist.communityProgress');
  const communityLoading = community === undefined && !!viewer?.Communities?.count;
  const onOpenSetting = () => push(getLink(route.communitySettings.path));
  const isOwnerCommunity = typeUser === TypeCommunity.manager;
  const isVisibleSettings =
    visibleMobNav(location.pathname) && (permissions.includes(PermissionsList.editCommunity) || isOwnerCommunity);
  const countCommunity = typeUser === TypeCommunity.resident ? viewer?.Communities?.count : 2;

  return (
    <>
      <Navigation
        checklistButtonName={checklistButtonName}
        activeStepsComp={
          <>
            <CircleProgressBar value={checklistCompletion} size={'medium'} text={`${checklistCompletion}%`} />
          </>
        }
        isChecklistCompleted={completedCheckList}
        plusIcon={<PlusIcon type={'plus'} stroke={'white'} width={'14px'} height={'12px'} />}
        $isOpenNav={navOpen}
        setStateNav={handleSetState}
        logout={onSignOut}
        menuItemsTop={menuItemsTop}
        variant={'web'}
        mode="light"
        head={headMenu}
        onClickNotifications={openNotifications}
        $isNotices={hasNotifications}
        userinfo={{
          name: `${viewer?.firstName} ${viewer?.lastName}`,
          avatar: getResize(viewer?.Avatar?.file?.url, 'lg'),
        }}
        menuItemsBottom={menuItemsBottom}
        menuItemsMobile={menuItemsMobile}
        homePathUser={getLink(route.pageUser.path)}
        dashBoardLink={dashBoardLink}
        logoutText={t('common:menu.logOut')}
        viewMode={viewMode}
        hideMobNavigation={hideMobNavigation}
        alias={community?.alias}
        navOffsets={navOffsets}
        profMenuComponent={<ProfileMenu />}
        addMenuComponent={<AddMenu onClose={closeAddMenuHandler} permissions={permissions} typeUser={typeUser} />}
        closeAddMenuHandler={closeAddMenuHandler}
        openAddMenuHandler={openAddMenuHandler}
        addMenuOpen={addMenuOpen}
        progressMenuComponent={<ChecklistContainer onClose={closeProgressMenuHandler} />}
        closeProgressMenuHandler={closeProgressMenuHandler}
        openProgressMenuHandler={openProgressMenuHandler}
        progressMenuOpen={progressMenuOpen}
        commMenuComponent={<CommunityMenu />}
        communityCount={countCommunity}
        goBack={goBackData}
        communityLoading={communityLoading}
        communityName={community?.name}
        openCD={openCD}
        showCommDropdown={cdShowArrow}
        onOpenSetting={onOpenSetting}
        isVisibleSettings={isVisibleSettings}
        hideHeader={hideHeader}
        hasCommunitySwitched={hasCommunitySwitched}
        switchText={t('common:switch')}>
        {!isVerified && (
          <VerifyEmailMessageContainer setIsVerified={setIsVerified} appendTop={verifyEmailMsgAppendTop} />
        )}
        {children}
      </Navigation>
      <MemoHelpCentreButton isShown={showHelpButton} />
      {notificationsOpened && (
        <NotificationsContainer
          visible={notificationsOpened}
          newNotificationsRefetch={notifications.fetchMore}
          setVisible={setVisible}
          setCleared={setCleared}
          cleared={notifCleared}
          community={community || undefined}
        />
      )}
      {community?.id && <ToolTips />}
      <MobileCommunitiesDropdown active={cdActive} setActive={cdSetActive} links={cdLinks} />
    </>
  );
};
