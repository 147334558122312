import {gql} from '@apollo/client';

export const LanguageFragment = gql`
  fragment LanguageFragment on Language {
    id
    objectId
    createdAt
    updatedAt
    code
    name
    isDisabled
  }
`;
