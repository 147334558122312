import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import {useHistory, useLocation} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {PlanOptionsValues} from '../../../constants/plan';
import {route} from '../../../constants/routes';
import {
  getMaxCountOfCommunity,
  useHasPlanOption,
  setCurrentCommunity as setCurrentCommunityInLS,
  getSwitchPath,
  toStateCommunity,
  CommunityTypes,
} from '../../../helpers/community';
import {getTypeUserByTCommunity} from '../../../helpers/user';
import {useLinks, useIsModal} from '../../../hooks/common';
import {
  useGetCommunity,
  useGetCommunityManagingInfo,
  useGetMenuCommunities,
  useGetUserPendingCommunities,
  useJoinToCommunity,
} from '../../../hooks/community';
import {useMarkCommunitySwitched, useViewer} from '../../../hooks/user';
import {aliasPart, CommunityStateType, currentCommunity} from '../../../states/community';
import {typeUser} from '../../../states/typeUser';
import {CommunityElement} from '../../Account/Popups/Settings/components';
import {
  BtnText,
  MobileWrapper,
  SelectCommunityPopupWrapper,
  SelectCommunityWrapper,
  SettingsBtn,
  WrapperFlex,
} from '../../Account/Popups/Settings/styles';
import {CustomCommunity, TLinks} from '../../Account/Popups/Settings/types';
import {LottieLoader} from '../../Loader/LottieLoader';
import {IconSvg} from '../../Loop/PostCreate/styles';
import {Br} from '../../Settings/Community/styles';
import {CommunitiesMenuSkeleton} from '../Skeletons/CommunitiesMenuSkeleton';
import {DarkBackground, PopupWrapper} from './styles';
import {getInviteData} from '../../../helpers/common';
import {analyticsTrackFN} from '../../../helpers/account';
import {useResetPreload} from '../../../hooks/preload';
import {useAutoAddToCommunity} from '../../../hooks/auth';

type ActiveProps = {
  active?: boolean;
  setActive?: (v: boolean) => void;
  links: TLinks;
};

export const MobileCommunitiesDropdown: React.FC<ActiveProps> = ({active, setActive, links}) => {
  const handleClose = () => setActive?.(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();

  return active ? (
    <DarkBackground onClick={handleClose}>
      <PopupWrapper onClick={stop}>
        <CommunitiesDropdownContainer active={active} setActive={setActive} links={links} />
      </PopupWrapper>
    </DarkBackground>
  ) : (
    <></>
  );
};

const CommunitiesDropdownContainer: React.FC<ActiveProps> = ({...props}) => {
  const viewer = useViewer();
  const {
    data: listOfJoinedCommunities,
    hasMore: hasMoreItems,
    loading: loadingCommunities,
    loadMore,
  } = useGetMenuCommunities([], viewer?.id, 11, viewer?.Communities?.count);
  const [community, setCommunity] = useRecoilState(currentCommunity);
  const [loading, setLoading] = useState(false);
  const setTypeUser = useSetRecoilState(typeUser);
  const {push} = useHistory();
  const {pathname} = useLocation();
  const {getLink} = useLinks();
  const hasListingFeed = useHasPlanOption(PlanOptionsValues.listingsFeed);
  const setAlias = useSetRecoilState(aliasPart);
  const {data: listPendingCommunities} = useGetUserPendingCommunities(listOfJoinedCommunities);
  const {onSubmit: join, loading: joinLoading} = useJoinToCommunity();
  const reset = useResetPreload();
  const markSwitched = useMarkCommunitySwitched();
  const {refetch: getCommunity} = useGetCommunity({id: undefined, short: false, small: false});
  const {addByKey, loading: loadingAddByKey} = useAutoAddToCommunity();

  useIsModal();
  const communitiesWithCurrent = useMemo(
    () => [
      ...listPendingCommunities,
      ...[community],
      ...listOfJoinedCommunities?.filter((el) => el.objectId !== community?.objectId),
    ],
    [listPendingCommunities, listOfJoinedCommunities, community?.objectId],
  ) as Array<CustomCommunity>;
  const setCurrentCommunity = async (id: string) => {
    if (id === community?.objectId) {
      props?.setActive?.(false);
      return;
    }
    const newCommunity = communitiesWithCurrent.find((el) => el.objectId === id);
    if (!newCommunity) return;
    if (newCommunity.pendingToJoin) {
      push(getLink(route.joinCommunity.get({id: id || ''})));
      return;
    }
    if (newCommunity.invited && newCommunity.objectId) {
      if (joinLoading || loadingAddByKey) return;
      if (newCommunity.invited === CommunityTypes.neighbourhood) {
        await addByKey(newCommunity.objectId);
        return;
      }
      await join({
        localCommId: newCommunity?.objectId,
        localUserId: viewer?.objectId,
        email: viewer?.email,
        invite: {communityId: newCommunity.objectId, ...getInviteData(newCommunity.invited)},
      });
      return;
    }
    const fetchCommunity = await getCommunity({id: newCommunity?.objectId});
    const communityNew = fetchCommunity?.data?.community;
    setCommunity(toStateCommunity(communityNew));
    markSwitched();
    reset();
    setTypeUser(getTypeUserByTCommunity(viewer?.objectId, newCommunity));
    setLoading(true);
    newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
    props?.setActive?.(false);
    setLoading(false);
    analyticsTrackFN('Community Switched', {
      userName: viewer?.username,
      userEmail: viewer?.email,
      userId: viewer?.objectId, // objectID or UID of user
      communityName: newCommunity?.name,
      communityId: newCommunity?.objectId, // objectID or ID of community
      communityType: newCommunity?.type,
      previousCommunityName: community?.name,
      previousCommunityId: community?.objectId, // objectID or ID of community
      previousCommunityType: community?.type,
      page: pathname?.split('/')?.[2] || '', // home, explore, inbox etc.
    });
    const path = getSwitchPath(pathname);
    push(getLink(path, newCommunity.alias));
  };

  const haveAdminRank = useMemo(
    () => listOfJoinedCommunities.some((cmt) => cmt.Admins?.some((usr) => usr.User?.objectId === viewer?.objectId)),
    [listOfJoinedCommunities, viewer?.objectId],
  );
  const haveManagerRank = useMemo(
    () => listOfJoinedCommunities.some((cmt) => cmt.Owner?.objectId == viewer?.objectId),
    [listOfJoinedCommunities, viewer?.objectId],
  );

  const openSearch = () => {
    setAlias(undefined);
    push(getLink(route.searchCommunityResident.path));
  };
  if (loading) return <LottieLoader allScreen={true} $isVisible={loading} />;

  return (
    <Dropdown
      {...props}
      community={community}
      setCurrentCommunity={setCurrentCommunity}
      listCommunities={communitiesWithCurrent}
      hasListingFeed={!!hasListingFeed}
      haveAdminRank={haveAdminRank}
      haveManagerRank={haveManagerRank}
      openSearch={openSearch}
      loadMore={loadMore}
      loadingCommunities={loadingCommunities}
      hasMoreItems={hasMoreItems}
      joinLoading={joinLoading}
    />
  );
};

type CommDDPropsT = ActiveProps & {
  community: CommunityStateType;
  setCurrentCommunity: (id: string) => void;
  listCommunities: Array<CustomCommunity>;
  hasListingFeed: boolean;
  haveManagerRank: boolean;
  haveAdminRank: boolean;
  openSearch: () => void;
  hasMoreItems?: boolean;
  loadingCommunities?: boolean;
  loadMore: () => Promise<void>;
  joinLoading?: boolean;
};

export const Dropdown: React.FC<CommDDPropsT> = ({
  setActive,
  listCommunities,
  openSearch,
  links,
  setCurrentCommunity,
  community,
  hasMoreItems,
  loadingCommunities,
  loadMore,
  joinLoading,
}) => {
  const {t} = useTranslation();
  const user = useViewer();
  const maxCountCommunities = getMaxCountOfCommunity(user?.email);
  const setAlias = useSetRecoilState(aliasPart);
  const communityCount = user?.Communities?.count || 0;

  const {owner: listedOwner, admin: listedAdmin} = useGetCommunityManagingInfo(user?.id);
  const onlyResident = !listedOwner && !listedAdmin;
  const showSearchCommunity =
    (communityCount === 0 && !community?.id) || (!onlyResident && Number(communityCount) < maxCountCommunities);
  const showCreateCommunity = Number(listedOwner || 0) > 1 && Number(listedOwner || 0) < maxCountCommunities;
  const closeDropdown = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setActive?.(false);
  };

  const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();
  useEffect(() => {
    setAlias(undefined);
  }, []);
  const ref = useRef<HTMLDivElement>(null);
  const handleGetScrollParent = () => {
    return ref.current;
  };

  return (
    <SelectCommunityWrapper>
      <MobileWrapper>
        <DarkBackground onClick={closeDropdown}>
          <SelectCommunityPopupWrapper onClick={stopPropagation} className={'Scroller'} ref={ref}>
            <InfiniteScroll
              useWindow={false}
              getScrollParent={handleGetScrollParent}
              loadMore={loadMore}
              hasMore={(hasMoreItems && loadingCommunities) || !listCommunities?.length ? true : hasMoreItems}
              loader={<CommunitiesMenuSkeleton />}>
              {listCommunities.map((el) => (
                <CommunityElement
                  community={el}
                  key={el.objectId}
                  setCurrentCommunity={setCurrentCommunity}
                  isPending={!!el.pendingToJoin}
                  isInvited={!!el?.invited}
                  joinLoading={joinLoading}
                />
              ))}
            </InfiniteScroll>
            <Br indent={20} />

            {showSearchCommunity && links.searchCommunity && (
              <SettingsBtn to={'#'} onClick={openSearch}>
                <WrapperFlex>
                  <BtnText>{t('common:menu.searchCommunities')}</BtnText>
                  <IconSvg type={'search-filled'} />
                </WrapperFlex>
              </SettingsBtn>
            )}

            {showCreateCommunity && links.createCommunity && (
              <SettingsBtn to={links.createCommunity}>
                <WrapperFlex>
                  <BtnText>{t('settings:popup.buttons.createCommunity')}</BtnText>
                  <IconSvg type={'add'} />
                </WrapperFlex>
              </SettingsBtn>
            )}
            <Br indent={32} />
          </SelectCommunityPopupWrapper>
        </DarkBackground>
      </MobileWrapper>
    </SelectCommunityWrapper>
  );
};
