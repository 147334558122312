import {TItem} from '../../types/item';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  InnerAllListings,
  InnerMyListings,
  InnerTitle,
  ListCardsFlex,
  ListCardsGrid,
  SubTitle,
  ViewAllBtn,
  WrapperMyListings,
  // ItemsDivider,
} from './styles';
import {ListingCard} from './ListingCard';
import {route} from '../../constants/routes';
import {TypeCommunity} from '../../types/auth';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {ItemsSkeletonLoaderSection} from '../common/Skeletons/ItemsSkeleton';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {TouchScrollableBlock} from '../common/Tabs/TouchScrollableBlock';
import {useRecoilState} from 'recoil';
import {userPermissions} from '../../states/community';
import {PermissionsList} from '../../types/settings';
import {convertCentToDollar} from '../../helpers/payment';
import {LikeState} from '../../hooks/item';

type TListItemsWithPreview = {
  isPreview: boolean;
  typeUser: TypeCommunity;
  items?: Partial<TItem>[];
  fetchDataItems?: () => void;
  hasMoreItems?: boolean;
  communityId?: string;
  title?: string;
  isEdit?: boolean;
  isHideLabel?: boolean;
  featuredDivided?: boolean;
  loading?: boolean;
  hideItem?: (itemId?: string) => () => void;
  featureItem?: (itemId?: string) => () => void;
  unFeatureItem?: (itemId?: string) => () => void;
  approveItem?: (itemId?: string) => () => void;
  onUnlist?: (itemId?: string) => () => void;
  onPublish?: (itemId?: string) => () => void;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  featured?: Partial<TItem>[];
  onLike?: (id: string) => void;
  liked?: LikeState;
};

export const ListItemsWithPreview: React.FC<TListItemsWithPreview> = ({
  isPreview,
  items,
  communityId,
  fetchDataItems,
  hasMoreItems,
  isEdit,
  hideItem,
  featureItem,
  approveItem,
  title,
  onUnlist,
  onPublish,
  isHideLabel,
  loading,
  typeUser,
  userId,
  unFeatureItem,
  reportActions,
  getSignValue,
  // featuredDivided,
  liked,
  onLike,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [isShow, setIsShow] = useState(isPreview);
  const titleButton = isShow ? t('listings:page.viewAll') : t(`listings:page.hide`);
  const isAllBtn = Number(items?.length) > 3;
  const [permissions] = useRecoilState(userPermissions);

  const viewAll = () => {
    setIsShow((prev) => !prev);
  };

  const isManager = useMemo(() => {
    return (
      typeUser === TypeCommunity.manager ||
      (permissions.includes(PermissionsList.manageListing) && typeUser === TypeCommunity.admin)
    );
  }, [typeUser, permissions]);

  const priceConverted = items?.map((item) => {
    return {...item, price: convertCentToDollar(item?.price)};
  });
  // const itemsFeatured = items?.filter((item) => item?.Featured?.some((el) => el?.objectId === communityId));
  // const itemsNotFeatured = items?.filter((item) => !item?.Featured?.some((el) => el?.objectId === communityId));
  // const allItems = featuredDivided ? itemsNotFeatured : items;
  return (
    <InnerAllListings>
      {title && (
        <InnerTitle>
          <SubTitle>{title}</SubTitle>
          {isAllBtn && isPreview && (
            <ViewAllBtn type={'button'} onClick={viewAll}>
              {titleButton}
            </ViewAllBtn>
          )}
        </InnerTitle>
      )}
      {isShow && isPreview && (
        <WrapperMyListings>
          <InnerMyListings>
            <TouchScrollableBlock>
              <ListCardsFlex>
                {loading && !priceConverted?.length ? (
                  <ItemsSkeletonLoaderSection />
                ) : (
                  priceConverted?.map((item) => (
                    <ListingCard
                      key={item.id}
                      to={
                        item.Lister?.objectId === userId
                          ? getLink(route.listingViewEdit.get({id: item?.objectId || ''}))
                          : getLink(route.listingView.get({id: item?.objectId || ''}))
                      }
                      communityId={communityId}
                      aspectRatio={'300:200'}
                      item={item}
                      src={item?.images?.[0]?.file?.url}
                      editPath={
                        isEdit || item.Lister?.objectId === userId
                          ? getLink(
                              route.listingViewEdit.get({
                                id: item?.objectId || '',
                              }),
                            )
                          : null
                      }
                      hideCallBack={hideItem}
                      featureCallBack={featureItem}
                      approveCallBack={approveItem}
                      unListCallBack={onUnlist}
                      onPublishCallBack={onPublish}
                      isHideLabel={isHideLabel}
                      isManager={isManager}
                      isOwner={item.Lister?.objectId === userId}
                      reportActions={reportActions}
                      getSignValue={getSignValue}
                    />
                  ))
                )}
              </ListCardsFlex>
            </TouchScrollableBlock>
          </InnerMyListings>
        </WrapperMyListings>
      )}

      {!isShow && fetchDataItems && (
        <InfiniteScroll
          loadMore={fetchDataItems}
          hasMore={!isShow ? (items?.length && hasMoreItems) || false : false}
          pageStart={0}
          initialLoad={true}
          loader={<ItemsSkeletonLoaderSection />}>
          <>
            {loading ? (
              <ItemsSkeletonLoaderSection />
            ) : (
              <>
                {/* <>
                  {featuredDivided && (
                    <>
                      <ListCardsGrid minWidth={280}>
                        {!!itemsFeatured?.length &&
                          itemsFeatured.map((item) => (
                            <ListingCard
                              key={item.id}
                              to={
                                item.Lister?.objectId === userId
                                  ? getLink(route.listingViewEdit.get({id: item?.objectId || ''}))
                                  : getLink(route.listingView.get({id: item?.objectId || ''}))
                              }
                              communityId={communityId}
                              aspectRatio={'300:200'}
                              item={item}
                              src={item?.images?.[0]?.file?.url}
                              editPath={
                                isEdit || item.Lister?.objectId === userId
                                  ? getLink(
                                      route.listingViewEdit.get({
                                        id: item?.objectId || '',
                                      }),
                                    )
                                  : null
                              }
                              hideCallBack={hideItem}
                              featureCallBack={featureItem}
                              approveCallBack={approveItem}
                              unListCallBack={onUnlist}
                              onPublishCallBack={onPublish}
                              isHideLabel={isHideLabel}
                              isManager={isManager}
                              isOwner={item.Lister?.objectId === userId}
                              reportActions={reportActions}
                              getSignValue={getSignValue}
                              removeFeatureCallBack={unFeatureItem}
                            />
                          ))}
                      </ListCardsGrid>
                      <ItemsDivider />
                    </>
                  )}
                </> */}
                <ListCardsGrid minWidth={280}>
                  {items?.map((item) => (
                    <ListingCard
                      key={item.id}
                      to={
                        item.Lister?.objectId === userId
                          ? getLink(route.listingViewEdit.get({id: item?.objectId || ''}))
                          : getLink(route.listingView.get({id: item?.objectId || ''}))
                      }
                      communityId={communityId}
                      aspectRatio={'300:200'}
                      item={item}
                      src={item?.images?.[0]?.file?.url}
                      editPath={
                        isEdit || item.Lister?.objectId === userId
                          ? getLink(route.listingViewEdit.get({id: item.objectId || ''}))
                          : null
                      }
                      hideCallBack={hideItem}
                      featureCallBack={featureItem}
                      removeFeatureCallBack={unFeatureItem}
                      approveCallBack={approveItem}
                      unListCallBack={onUnlist}
                      onPublishCallBack={onPublish}
                      isHideLabel={isHideLabel}
                      isManager={isManager}
                      isOwner={item.Lister?.objectId === userId}
                      reportActions={reportActions}
                      getSignValue={getSignValue}
                      lister={item.Lister}
                      liked={liked}
                      onLike={onLike}
                      avatarRequired={true}
                    />
                  ))}
                </ListCardsGrid>
              </>
            )}
          </>
        </InfiniteScroll>
      )}
    </InnerAllListings>
  );
};
