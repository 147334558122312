import {Item, ListingType, PricePeriodType, RewardType} from '../queries/types/item';
import {differenceInDays, differenceInHours, differenceInWeeks, format, parseISO} from 'date-fns';
import {PreOrderCalcDataT, TItem} from '../types/item';
import {TagType} from '../ui-kit/RequestCard/types';
import {TOrder, UserChat} from '../types/messages';
import {Order, StatusOrderType, typeOrder} from '../queries/types/order';
import {GQLOrderStatuses} from '../graphql.schema';
import {Message} from '../queries/types/message';
import {sortDescByDate} from './message';
import {Namespace, TFunction} from 'react-i18next';
import {getFormattingAmount} from './payment';
import {getDatesArray} from './dates';
import {optionsI18n} from '../libs/i18nextUtils';
import {IGetCurrValue} from '../types/common';
import {getUserName} from './user';

const getAbsoluteDays = (date: Date) => {
  return Math.trunc(date?.getTime() / (1000 * 60 * 60 * 24));
};

const getNumMonths = (endDate: Date, startDate: Date) => {
  const absStartDate = getAbsoluteDays(startDate);
  const absEndDate = getAbsoluteDays(endDate);
  return (absEndDate - absStartDate) / 30;
};

export const getTimeData = (opts: {
  pricePeriod?: PricePeriodType | null;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  const {pricePeriod, startDate, endDate} = opts;

  if ((!startDate || !endDate) && pricePeriod === PricePeriodType.month) {
    return {label: 'requests:label.price.fixed', value: 1};
  }
  if (!startDate || !endDate || !pricePeriod) {
    return {label: 'requests:label.price.fixed', value: 0};
  }
  if (pricePeriod === PricePeriodType.day) {
    return {label: 'requests:label.price.day', value: differenceInDays(endDate, startDate) + 1};
  }
  if (pricePeriod === PricePeriodType.hour) {
    return {label: 'requests:label.price.hour', value: differenceInHours(endDate, startDate)};
  }
  if (pricePeriod === PricePeriodType.week) {
    return {label: 'requests:label.price.week', value: differenceInWeeks(endDate, startDate) + 1};
  }
  if (pricePeriod === PricePeriodType.month) {
    return {label: 'requests:label.price.month', value: getNumMonths(endDate, startDate)};
  }
  return {label: 'requests:label.price.fixed', value: 1};
};

export const getAmountLabel = (item?: Partial<TItem> | Item) => {
  if (item?.rewardType !== RewardType.fee) {
    return {
      label: `listings:card.rewardType.${item?.rewardType}`,
      type: TagType[item?.rewardType as 'coffee' | 'free'],
    };
  }

  return {
    label: `requests:label.tagPrice.${item?.pricePeriod ?? 'fixed'}`,
    type: TagType.plain,
    opts: item?.price,
  };
};

export enum messagesNotificationStatuses {
  itemReviewed = 'itemReviewed',
}

const orderTypesTran = {
  [typeOrder.itemOrder]: '',
  [typeOrder.eventOrder]: 'event.',
  [typeOrder.amenityOrder]: 'amenity.',
};

export const getTextMsgRequest = (order?: TOrder, text?: string, author?: Partial<UserChat>, viewerId?: string) => {
  const typeT = order?.orderType ? orderTypesTran[order?.orderType] : '';
  let typeUser: 'Lister' | 'Requester' = 'Lister';
  const isAmenityRequester = viewerId === order?.Requester?.objectId;

  if (
    text === StatusOrderType.request ||
    text === StatusOrderType.pending ||
    text === messagesNotificationStatuses.itemReviewed
  ) {
    typeUser = 'Requester';
  }

  let label = `messages:labels.requests.${typeT}${order?.status}`;
  if (text) label = `messages:labels.requests.${typeT}${text}`;

  const amRequester =
    !isAmenityRequester && order?.status === StatusOrderType.booked && order?.Amenity?.name
      ? order.Requester?.firstName
      : '';
  const userName = amRequester || (typeUser ? order?.[typeUser]?.firstName : '');

  const rejectAuthor =
    order?.status === StatusOrderType.rejected || order?.status === StatusOrderType.canceled ? author?.firstName : '';

  const itemName = order?.Item?.name || order?.Event?.name || order?.Amenity?.name;

  if (order?.Amenity?.name && isAmenityRequester) {
    label = `messages:labels.requests.${typeT}requester.${text}`;
  }
  if (order?.Event?.name && isAmenityRequester) {
    label = `messages:labels.requests.${typeT}requester.${text}`;
  }

  return {
    label,
    opts: {
      user: rejectAuthor || userName || '',
      item: itemName || '',
      author: getUserName(null, author?.firstName, author?.lastName, true),
    },
  };
};

export const getTextsPaymentLabel = (params: {
  rewardType: RewardType;
  listingType: ListingType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {rewardType, listingType, typeUser, orderStatus} = params;

  if (rewardType !== RewardType.fee) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.subtitle`,
    };
  }
  if (orderStatus === StatusOrderType.request || orderStatus === StatusOrderType.rejected) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.willPay.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.willPay.subtitle`,
    };
  }
  if (listingType === ListingType.rent) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.paid.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.paid.subtitle`,
    };
  }

  if (orderStatus === StatusOrderType.accepted) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.willPay2.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.willPay2.subtitle`,
    };
  }
  return {
    title: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.paid.title`,
    descr: `requests:card.${typeUser}.${rewardType}.labelInfo.${listingType}.paid.subtitle`,
  };
};

export const getTextsSecondaryLabel = (params: {
  rewardType: RewardType;
  listingType: ListingType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {orderStatus, typeUser, listingType} = params;
  if (orderStatus === StatusOrderType.rejected) {
    return '';
  }
  if (orderStatus === StatusOrderType.performing && typeUser === 'requester' && listingType === ListingType.service) {
    return 'requests:buttons.reportService';
  }
  return 'requests:buttons.cancelRequest';
};

export const getTextsEventSecondaryLabel = (params: {
  rewardType: RewardType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {orderStatus} = params;

  if (orderStatus === StatusOrderType.canceled) {
    return '';
  }
  if (orderStatus === StatusOrderType.rejected) {
    return '';
  }
  return 'requests:buttons.cancelRequest';
};

export const getTextsAmenitySecondaryLabel = (params: {
  rewardType: RewardType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {orderStatus} = params;

  if (orderStatus === StatusOrderType.canceled) {
    return '';
  }
  if (orderStatus === StatusOrderType.rejected) {
    return '';
  }
  return 'requests:buttons.cancelRequest';
};

export const getDataButtonLabel = (params: {
  rewardType: RewardType;
  listingType: ListingType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
  date?: Date;
}) => {
  const {rewardType, listingType, orderStatus, typeUser, date} = params;
  const isLister = typeUser === 'lister';
  const isRequester = typeUser === 'requester';

  if (orderStatus === StatusOrderType.request && isLister) {
    return {label: 'requests:buttons.accept', status: GQLOrderStatuses.accepted};
  }
  if ((orderStatus === StatusOrderType.paymentPending && isLister) || orderStatus === StatusOrderType.failed) {
    return {label: '', status: null};
  }
  if (orderStatus === StatusOrderType.accepted && listingType === ListingType.sell && isLister) {
    return {label: 'requests:buttons.sold', status: GQLOrderStatuses.performing};
  }
  if (orderStatus === StatusOrderType.performing && listingType === ListingType.sell && isRequester) {
    return {label: 'requests:buttons.confirmSold', status: GQLOrderStatuses.completed};
  }
  if (orderStatus === StatusOrderType.accepted && listingType === ListingType.service && isLister) {
    const isDisabled = date ? differenceInDays(parseISO(String(date)), new Date().getTime()) > 0 : false;
    return {label: 'requests:buttons.serviceProvided', status: GQLOrderStatuses.performing, isDisabled};
  }
  if (orderStatus === StatusOrderType.performing && listingType === ListingType.service && isRequester) {
    return {label: 'requests:buttons.confirmServiceProvided', status: GQLOrderStatuses.completed};
  }
  // if (orderStatus === StatusOrderType.completed && listingType === ListingType.sell && isLister) {
  //   return {label: 'requests:buttons.serviceReceived', status: GQLOrderStatuses.paid};
  // }
  if (
    (orderStatus === StatusOrderType.paid || orderStatus === StatusOrderType.accepted) && //delete accepted after stripe integration with backend
    listingType === ListingType.rent &&
    isLister
  ) {
    const isDisabled = date ? differenceInDays(parseISO(String(date)), new Date().getTime()) > 0 : false;
    return {label: 'requests:buttons.lended', status: GQLOrderStatuses.performing, isDisabled};
  }
  if (orderStatus === StatusOrderType.performing && listingType === ListingType.rent && isLister) {
    return {label: 'requests:buttons.returned', status: GQLOrderStatuses.completed};
  }
  if (orderStatus === StatusOrderType.accepted && rewardType !== RewardType.fee && isLister) {
    return {label: 'requests:buttons.lended', status: GQLOrderStatuses.performing};
  }

  return {label: '', status: null};
};

export const getEventDataButtonLabel = (params: {
  rewardType: RewardType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
}) => {
  const {orderStatus, typeUser} = params;
  const isLister = typeUser === 'lister';
  const isRequester = typeUser === 'requester';

  if (orderStatus === StatusOrderType.pending && isLister) {
    return {label: 'requests:buttons.acceptBooking', status: GQLOrderStatuses.attending};
  }
  if (orderStatus === StatusOrderType.pending && isRequester) {
    return {label: '', status: null};
  }

  return {label: '', status: null};
};

export const getAmenityDataButtonLabel = (params: {
  rewardType: RewardType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
}) => {
  const {orderStatus, typeUser} = params;
  const isLister = typeUser === 'lister';
  const isRequester = typeUser === 'requester';
  if (orderStatus === StatusOrderType.pending && isLister) {
    return {label: 'requests:buttons.accept', status: GQLOrderStatuses.booked};
  }
  if (orderStatus === StatusOrderType.pending && isRequester) {
    return {label: '', status: null};
  }

  return {label: '', status: null};
};

export const getTextDescription = (params: {
  listingType: ListingType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
  rewardType: RewardType;
}) => {
  const {listingType, orderStatus, typeUser, rewardType} = params;
  return `requests:card.${typeUser}.descr.${listingType}.${orderStatus}${
    orderStatus === StatusOrderType.request && rewardType === RewardType.free ? '-free' : ''
  }`;
};

export const getTextEventDescription = (params: {
  rewardType: RewardType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
}) => {
  const {orderStatus, rewardType, typeUser} = params;
  return `events:requests.card.${typeUser}.${rewardType}.descr.${orderStatus}`;
};

export const getTextAmenityDescription = (params: {
  rewardType: RewardType;
  orderStatus: StatusOrderType;
  typeUser: 'lister' | 'requester';
}) => {
  const {orderStatus, rewardType, typeUser} = params;
  return `amenities:requests.card.${typeUser}.${rewardType}.descr.${orderStatus}`;
};

export const getTextsEventPaymentLabel = (params: {
  rewardType: RewardType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {rewardType, typeUser, orderStatus} = params;

  if (rewardType !== RewardType.free && orderStatus) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.subtitle`,
    };
  }
  return {
    title: `requests:card.${typeUser}.${rewardType}.labelInfo.title`,
    descr: `requests:card.${typeUser}.${rewardType}.labelInfo.subtitle`,
  };
};

export const getTextsAmenityPaymentLabel = (params: {
  rewardType: RewardType;
  typeUser: 'lister' | 'requester';
  orderStatus: StatusOrderType;
}) => {
  const {rewardType, typeUser, orderStatus} = params;

  if (rewardType !== RewardType.free && orderStatus) {
    return {
      title: `requests:card.${typeUser}.${rewardType}.labelInfo.title`,
      descr: `requests:card.${typeUser}.${rewardType}.labelInfo.subtitle`,
    };
  }
  return {
    title: `requests:card.${typeUser}.${rewardType}.labelInfo.title`,
    descr: `requests:card.${typeUser}.${rewardType}.labelInfo.subtitle`,
  };
};

export const getOrdersIds = (msgs?: Message[]) => {
  return (
    sortDescByDate(msgs, 'createdAt')?.reduce((acc, it) => {
      const objectId = it?.Order?.objectId;
      if (objectId && !acc.includes(objectId)) {
        acc.push(objectId);
      }
      return acc;
    }, [] as any[]) || []
  );
};

export const getTextLabelPreviewCardOrder = (
  order: Order | null,
  t: TFunction<Namespace>,
  getSignValue: IGetCurrValue,
) => {
  const days = t('requests:label.previewCard.day', {value: order?.period});
  const date = order
    ? order?.period > 1
      ? t('requests:label.previewCard.date', {
          from: format(new Date(order.startTime), 'MMM d'),
          to: format(new Date(order.endTime), 'MMM d'),
        })
      : format(new Date(order.startTime), 'MMM d')
    : '';

  const payment =
    order?.rewardType === RewardType.fee
      ? getSignValue(getFormattingAmount(order?.period * order?.price))
      : t(`requests:label.previewCard.${order?.rewardType}`);
  return [days, date, payment];
};

type checkExternalOptions = {
  type?: ListingType;
  externalRent?: boolean;
  externalSale?: boolean;
  externalService?: boolean;
};
export const checkExternal = (options: checkExternalOptions) => {
  if (options.type === ListingType.rent && options.externalRent) return true;
  if (options.type === ListingType.sell && options.externalSale) return true;
  if (options.type === ListingType.service && options.externalService) return true;

  return false;
};

export const timeDiffCalcInHours = (dateFuture: Date, dateNow: Date) => {
  const diffInMilliSeconds = Math.abs(dateFuture.getTime() - dateNow.getTime()) / 1000;
  return Math.floor(diffInMilliSeconds / 3600);
};

type getCreditInfoOptions = {
  activeOrder: Order;
  isRequester?: boolean;
  t: TFunction<Namespace>;
};

export const getCreditInfo = (options: getCreditInfoOptions) => {
  const {activeOrder, isRequester, t} = options;
  const {format} = optionsI18n.interpolation;
  const isSell = activeOrder.listingType === ListingType.sell;
  const requesterName = `${activeOrder.Requester.firstName} ${activeOrder.Requester.lastName}`;
  const listerName = `${activeOrder.Lister.firstName} ${activeOrder.Lister.lastName}`;

  const duration =
    activeOrder.pricePeriod === PricePeriodType.hour
      ? timeDiffCalcInHours(new Date(activeOrder.endTime), new Date(activeOrder.startTime)) +
        ` ${t('requests:credit.hours')}`
      : activeOrder.pricePeriod === PricePeriodType.day
      ? getDatesArray(new Date(activeOrder.startTime), new Date(activeOrder.endTime)).length +
        ` ${t('requests:credit.days')}`
      : ` ${t('requests:credit.NotApplicable')}`;
  const dates =
    !isSell && activeOrder.pricePeriod !== PricePeriodType.hour
      ? format(new Date(activeOrder.startTime), 'P') +
        ` ${t('requests:credit.to')} ` +
        format(new Date(activeOrder.endTime), 'P')
      : '';
  const dateAndTime =
    isSell || activeOrder.pricePeriod === PricePeriodType.hour
      ? format(new Date(activeOrder.startTime), `P '${t('requests:credit.at')}' p`)
      : '';
  const requestedBy = !isRequester && activeOrder.status !== StatusOrderType.completed ? requesterName : '';
  const listingBy = isRequester && activeOrder.status !== StatusOrderType.completed ? listerName : '';
  const soldBy = isRequester && activeOrder.status === StatusOrderType.completed && isSell ? listerName : '';
  const offeredBy = isRequester && activeOrder.status === StatusOrderType.completed && !isSell ? listerName : '';
  const receivedBy = !isRequester && activeOrder.status === StatusOrderType.completed ? requesterName : '';

  return {
    duration: {label: t('requests:credit.duration'), value: duration},
    dates: {label: t('requests:credit.dates'), value: dates},
    DateAndTime: {label: t('requests:credit.DateAndTime'), value: dateAndTime},
    requestedBy: {label: t('requests:credit.requestedBy'), value: requestedBy},
    listingBy: {label: t('requests:credit.listingBy'), value: listingBy},
    soldBy: {label: t('requests:credit.soldBy'), value: soldBy},
    receivedBy: {label: t('requests:credit.receivedBy'), value: receivedBy},
    offeredBy: {label: t('requests:credit.offeredBy'), value: offeredBy},
  };
};

export const preCalcInitial: PreOrderCalcDataT = {
  itemCost: 0,
  sfRequesterAmt: 0,
  ppRequesterAmt: 0,
  requesterTotalAmt: 0,
};

export const calcWithDiscount = (itemCost: number, hangehDiscount: number) => {
  const withDiscount = itemCost > 0;
  const discountCover = withDiscount && itemCost - hangehDiscount < 0;
  const discount = withDiscount ? (discountCover ? itemCost : hangehDiscount) : 0;
  return discount;
};
type CheckStatusHierarchyOptions = {
  isEvent?: boolean;
  isListing?: boolean;
  isAmenity?: boolean;
  current?: string;
  next?: string;
};

const ListingStatusHierarchy = {
  [StatusOrderType.draft]: 0,
  [StatusOrderType.request]: 1,
  [StatusOrderType.accepted]: 2,
  [StatusOrderType.paid]: 3,
  [StatusOrderType.performing]: 4,
  [StatusOrderType.completed]: 5,
  [StatusOrderType.paymentPending]: 98,
  [StatusOrderType.rejected]: 99,
  [StatusOrderType.failed]: 100,
};
type HierarchyKey = keyof typeof ListingStatusHierarchy;

export const checkStatusHierarchy = ({isListing, current, next}: CheckStatusHierarchyOptions) => {
  if (isListing && next && current) {
    const nextStatus = ListingStatusHierarchy?.[next as HierarchyKey] || 0;
    const currentStatus = ListingStatusHierarchy?.[current as HierarchyKey] || 0;
    return nextStatus > currentStatus;
  }
  return true;
};
