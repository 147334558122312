import React, {ChangeEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PhotosList} from './PhotosList';
import {
  HintWrapper,
  InnerItems,
  InnerItemsGrid,
  InputLeftIcon,
  ItemForm,
  MobFocused,
  SubTitle,
  Title,
  WrapperForm,
} from './styles';
import {NavContainer} from '../../containers/Navigation';
import {correctTranslate, YesNoOptions, YesNotoBool} from '../../helpers/common';
import {CompanyFormAction, CompanyFormKeys, TCompanyFormValues, TPromoCTA} from '../../types/company';
import {Button} from '../../ui-kit/Button/Button';
import {Input} from '../../ui-kit/Form/Input';
import {Select} from '../../ui-kit/Form/Select';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {ErrorMessage} from '../Auth/styles';
import {MemoInfoHint} from '../common/InfoHint';
import {RichTextEditor} from '../common/RichText/Editor';
import {LottieLoader} from '../Loader/LottieLoader';

import {TCompanyFormData} from '../../hooks/company';
import {useEditorRef} from '../../hooks/common';

type TProps = {
  formData: TCompanyFormData;
  action: CompanyFormAction;
};

export const CompanyForm: React.FC<TProps> = ({formData, action}) => {
  const {t} = useTranslation();
  const {values, onChange, onSubmit, error, loading} = formData;
  const {setRef} = useEditorRef({autoBlur: true});
  const title = t(`company:localBiz.titleForm`, {context: action});
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });

  const getHandleChange = (key: keyof TCompanyFormValues) => (value: unknown) => {
    onChange({key, value});
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const toggleOptions = useMemo(() => {
    return [
      {
        value: YesNotoBool[YesNoOptions.yes],
        label: t('common:commonButtons.yes'),
        key: YesNoOptions.yes,
      },
      {
        value: YesNotoBool[YesNoOptions.no],
        label: t('common:commonButtons.no'),
        key: YesNoOptions.no,
      },
    ];
  }, []);

  const CTAOptions = useMemo(() => {
    return [
      {
        value: TPromoCTA.none,
        label: t('company:localBiz.options.promoCTA.none'),
        key: TPromoCTA.none,
      },
      {
        value: TPromoCTA.visitWebsite,
        label: t('company:localBiz.options.promoCTA.visitWebsite'),
        key: TPromoCTA.visitWebsite,
      },
      {
        value: TPromoCTA.revealCode,
        label: t('company:localBiz.options.promoCTA.revealCode'),
        key: TPromoCTA.revealCode,
      },
      {
        value: TPromoCTA.interested,
        label: t('company:localBiz.options.promoCTA.interested'),
        key: TPromoCTA.interested,
      },
    ];
  }, []);
  return (
    <NavContainer>
      <WrapperForm>
        <Title>{title}</Title>
        <ItemForm onSubmit={handleSubmit}>
          <SubTitle>{t(`events:titlesForm.photos.${action}`)}</SubTitle>
          <PhotosList
            cards={values.images || []}
            onChange={handleChangeImages(getHandleChange(CompanyFormKeys.images))}
            error={!!error?.message}
          />
          <SubTitle>{t(`company:localBiz.titlesForm.details`)}</SubTitle>
          <InnerItems>
            <Input
              $isError={Boolean(error.name)}
              value={values.name}
              onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.name))}
              label={t('company:localBiz.labels.name')}
            />
            <MobFocused>
              <RichTextEditor
                $isError={Boolean(error.description)}
                label={t('company:localBiz.labels.description')}
                value={values?.description}
                key={Boolean(values?.description).toString()}
                name={CompanyFormKeys.description}
                onChange={handleChangeTextEditor(getHandleChange(CompanyFormKeys.description))}
                rows={8}
                inputRef={setRef}
              />
            </MobFocused>
            <Input
              $isError={Boolean(error.category)}
              value={values.category}
              onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.category))}
              label={t('company:localBiz.labels.category')}
            />
            <InputLeftIcon
              $isError={Boolean(error.website)}
              value={values.website}
              onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.website))}
              label={t('company:localBiz.labels.website')}
              icon={<IconSvg type={'link'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />}
            />
            <InputLeftIcon
              $isError={Boolean(error.phone)}
              value={values.phone}
              onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.phone))}
              label={t('company:localBiz.labels.phone')}
              icon={<IconSvg type={'phone'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />}
            />

            <SubTitle>{t(`company:localBiz.titlesForm.promotion`)}</SubTitle>

            <Select
              $isError={Boolean(error.isPromotion)}
              width={'100%'}
              name={CompanyFormKeys.isPromotion}
              label={t('company:localBiz.titlesForm.promotion')}
              values={[toggleOptions.find((el) => el.value === values.isPromotion)].filter(Boolean)}
              options={toggleOptions}
              onChange={handleChangeSelect(getHandleChange(CompanyFormKeys.isPromotion))}
              searchable={false}
            />
            {values.isPromotion && (
              <>
                <Input
                  $isError={Boolean(error.promoTag)}
                  value={values.promoTag}
                  onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.promoTag))}
                  label={t('company:localBiz.labels.promoTag')}
                />
                <MobFocused>
                  <RichTextEditor
                    $isError={Boolean(error.promoDescr)}
                    label={t('company:localBiz.labels.promoDescr')}
                    value={values?.promoDescr}
                    key={Boolean(values?.promoDescr).toString()}
                    name={CompanyFormKeys.promoDescr}
                    onChange={handleChangeTextEditor(getHandleChange(CompanyFormKeys.promoDescr))}
                    rows={8}
                  />
                </MobFocused>
                <InnerItemsGrid>
                  <Select
                    $isError={Boolean(error.promoCTA)}
                    options={CTAOptions}
                    values={[CTAOptions.find((el) => el.value === values.promoCTA)].filter(Boolean)}
                    onChange={handleChangeSelect(getHandleChange(CompanyFormKeys.promoCTA))}
                    label={t('company:localBiz.labels.promoCTA')}
                    name={CompanyFormKeys.promoCTA}
                  />
                  <Input
                    $isError={Boolean(error.valueCTA)}
                    value={values.valueCTA}
                    onChange={handleChangeEvent(getHandleChange(CompanyFormKeys.valueCTA))}
                    label={t('company:localBiz.labels.valueCTA')}
                    disabled={values.promoCTA === TPromoCTA.none || values.promoCTA === TPromoCTA.interested}
                  />
                  <HintWrapper>
                    <MemoInfoHint hintText={t('company:localBiz.hint')} />
                  </HintWrapper>
                </InnerItemsGrid>
              </>
            )}
          </InnerItems>

          <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
          <Button type={'submit'} loading={loading} disabled={loading}>
            {t(`company:localBiz.buttons.submit`, {context: action})}
          </Button>
        </ItemForm>
        <LottieLoader $isVisible={loading} allScreen={true} />
      </WrapperForm>
    </NavContainer>
  );
};

export const handleChangeEvent =
  (fn: (v: unknown) => void) =>
  ({target}: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    return fn(target.value);
  };

export const handleChangeTextEditor =
  (fn: (v: unknown) => void) =>
  ({value}: {value: unknown}) =>
    fn(value);

export const handleChangeSelect =
  (fn: (v: unknown) => void) =>
  ([obj]: [obj?: {value: unknown}]) =>
    fn(obj?.value);

export const handleChangeImages =
  (fn: (v: unknown) => void) =>
  ({value}: {value: unknown}) =>
    fn(value);
