import React from 'react';
import {NotificationEventType, NotificationPostData} from '../../../queries/types/post';
import {SelectedLink, SelectedText, TextWrapper} from './styles';
import {useTranslation} from 'react-i18next';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';

const NotificationText: React.FC<NotificationPostData> = ({personName, event, objectId}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const eventsText = {
    [NotificationEventType.newResident]: t('community:loop.post.notification.events.joined'),
    [NotificationEventType.approvedJoinRequest]: t('community:loop.post.notification.events.joined'),
  };

  return (
    <TextWrapper>
      {objectId ? (
        <SelectedLink to={getLink(route.profile.get({id: objectId}))}>{personName}</SelectedLink>
      ) : (
        <SelectedText>{personName}</SelectedText>
      )}
      {event && eventsText?.[NotificationEventType.newResident]}
    </TextWrapper>
  );
};

const MemoNotificationText = React.memo(NotificationText);
export {MemoNotificationText};
