import styled from 'styled-components';
import {Text} from '../../../ui-kit/Typography/Text';
import {Media} from '../../../ui-kit/theme';
import {fontPrimaryReg} from '../../common/styles';

export const Wrapper = styled.div`
  width: 600px;
  height: max-content;
  box-sizing: border-box;
  padding: 25px 30px;
  background-color: ${({theme}) => theme.palette.background.primary};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;

  ${Media.down.s} {
    width: 95%;
    margin: 0 auto;
  }
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
`;

export const InputWrapper = styled(ContentWrapper)`
  position: relative;
  ${({theme}) => theme.font.primary.regular};
`;

export const InputActionWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const PhoneInputWrapper = styled.div`
  position: absolute;
  inset: 5px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;

  & > :first-child {
    width: 100%;
  }
`;

export const InputActionText = styled(Text).attrs({
  size: 10,
  variant: 'primary',
  color: 'octonary',
  weight: 'semi-bold',
  decoration: 'underline',
})`
  cursor: pointer;
  user-select: none;
`;

export const Title = styled(Text).attrs({
  size: 38,
  variant: 'secondary',
  color: 'octonary',
})`
  ${fontPrimaryReg};
  ${Media.down.s} {
    font-size: 26px;
  }
`;

export const ErrorMessage = styled(Text).attrs({
  size: 12,
  variant: 'tertiary',
})`
  color: ${({theme}) => theme.palette.common.red};
  margin: 0 15px;
`;

export const BackIconWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;
