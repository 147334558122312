import React, {useState} from 'react';
import {HelpListPropsT} from './types';
import {
  DropDownBtn,
  ExtLink,
  HelpItemContent,
  HelpItemContentText,
  HelpItemTitle,
  HelpItemWrapper,
  LinkBtn,
  ListWrapper,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {useTranslation} from 'react-i18next';
import {HelpDeskItemT} from '../../types/helpDesk';
import {deskButtonActionsRes} from '../../hooks/helpDesk';
import {correctButtonTranslate, formatLink} from '../../helpers/helpDesk';
import {HelpDeskItemType} from '../../queries/types/helpDesk';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {RichText} from '../common/RichText/RichText';

export const HelpList: React.FC<HelpListPropsT> = ({mainList, customList, buttonActions, viewerId, analyticReport}) => {
  const [opened, setOpened] = useState<Record<string, boolean>>({});
  const handleClick = (id?: string) => {
    if (id) setOpened((prev) => ({...prev, [id]: !prev?.[id]}));
  };

  return (
    <ListWrapper>
      {mainList.map((el) => (
        <HelpItem
          {...el}
          key={el.id}
          isBase={true}
          handleOpen={handleClick}
          isOpened={el.id ? !!opened?.[el.id] : false}
          buttonActions={buttonActions}
          viewerId={viewerId}
          analyticReport={analyticReport}
        />
      ))}
      {customList.map((el) => (
        <HelpItem
          {...el}
          key={el.id}
          handleOpen={handleClick}
          isOpened={el.id ? !!opened?.[el.id] : false}
          buttonActions={buttonActions}
          viewerId={viewerId}
          analyticReport={analyticReport}
        />
      ))}
    </ListWrapper>
  );
};

type HelpItemProps = HelpDeskItemT & {
  isBase?: boolean;
  isOpened?: boolean;
  handleOpen: (id?: string) => void;
  buttonActions: deskButtonActionsRes;
  viewerId?: string;
  analyticReport: (key: string) => void;
};

const HelpItem: React.FC<HelpItemProps> = ({
  id,
  isOpened,
  handleOpen,
  buttonTitle,
  isBase,
  title,
  descr,
  type,
  Assignee,
  assignTo,
  viewerId,
  analyticReport,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const buttonLink = (type === HelpDeskItemType.link || type === HelpDeskItemType.linkView) && assignTo;
  const correctButtonTitle = type === HelpDeskItemType.linkView ? correctButtonTranslate(t, buttonTitle) : buttonTitle;
  const messageLink = Assignee
    ? viewerId !== Assignee.objectId
      ? getLink(route.messages.get({contactId: Assignee.objectId}))
      : '#'
    : '#';
  const handleReport = () => {
    if (title === 'bugReport') analyticReport('Report a Bug Clicked');
    if (title === 'appFeedback') analyticReport('Share Feedback Clicked');
  };
  return (
    <HelpItemWrapper key={id}>
      <HelpItemTitle onClick={() => handleOpen(id)}>
        {isBase ? t(`helpDesk:baseDesks.title.${title}`) : title}
      </HelpItemTitle>
      <DropDownBtn $isOpened={isOpened} onClick={() => handleOpen(id)}>
        <IconSvg type={'chevrone-down'} />
      </DropDownBtn>
      {id && (
        <HelpItemContent onClick={handleReport} $isOpened={isOpened}>
          <HelpItemContentText>
            <RichText html={isBase ? t(`helpDesk:baseDesks.${descr}`) : descr} />
          </HelpItemContentText>
          {buttonTitle &&
            (buttonLink ? (
              <ExtLink href={formatLink(assignTo)} target={'_blank'}>
                {correctButtonTitle}
              </ExtLink>
            ) : (
              <LinkBtn to={messageLink}>{correctButtonTranslate(t, buttonTitle)}</LinkBtn>
            ))}
        </HelpItemContent>
      )}
    </HelpItemWrapper>
  );
};
