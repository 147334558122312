import styled from 'styled-components';
import {fontPrimaryMedium, fontPrimaryReg} from '../../common/styles';
import {Link} from '../../Events/styles';

export const LabelsWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  margin-bottom: 12px;
  & > * {
    margin-right: 8px;
  }
`;

export const AttendingLabel = styled.div`
  font-size: 10px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._13};
`;

export const EventInfoRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const EventInfoTitle = styled.div`
  font-size: 12px;
  margin-bottom: 6px;
  color: ${({theme}) => theme.palette.grayscale._13};
`;
export const EventInfoDescr = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  color: ${({theme}) => theme.palette.grayscale._6};
`;

export const SuccessAmenityMessageWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 12px;
`;

export const SuccessAmenityText = styled.div`
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
  font-size: 12px;
  margin-left: 15px;
`;

export const BottomLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const GrayLink = styled(Link)`
  color: ${({theme}) => theme.palette.grayscale._5};
  text-decoration: underline;
  ${fontPrimaryMedium};
  font-size: 12px;
`;

export const DatepickerLine = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DatesInfoWrapper = styled.div`
  padding: 13px 0;
  border-top: 1px solid ${({theme}) => theme.palette.background.secondary};
  /* border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary}; */
`;

export const DateInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
`;

export const ReqInfoLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-transform: capitalize;
  color: ${({theme}) => theme.palette.text.duodecimal};
`;

export const ReqInfoValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
`;
