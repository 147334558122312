import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getMobileMenuItems} from '../../helpers/common';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {RangeDatePickerValues, searchPeopleOptionValue} from '../../ui-kit/Search/types';
import {ISimpleAction, TActionsOnItems} from './types';

export const useSearchListings = () => {
  const [searchText, setSearchText] = useState('');
  const [dateValue, setDateValue] = useState<RangeDatePickerValues[]>([null, null]);
  const [listingType, setListingType] = useState<searchPeopleOptionValue[]>([]);

  return {searchText, setSearchText, dateValue, setDateValue, listingType, setListingType};
};

interface IUseGetMenuItems {
  (params: {
    mainActions?: TActionsOnItems;
    otherActions: {
      onDelete: () => void;
      onEdit: () => void;
      onReport: () => void;
    };
    options: {
      isFeatured: boolean;
      isManager: boolean;
      isItemOwner: boolean;
      isCommOwner: boolean;
      itemId: string;
    };
  }): {descItems: Array<MenuItemType>; mobileItems: Array<MobileMenuEntry>};
}

export const useGetMenuItems: IUseGetMenuItems = ({mainActions, otherActions, options}) => {
  const {t} = useTranslation();

  const {onFeature, onUnFeature} = mainActions || {};
  const {onDelete, onEdit, onReport} = otherActions;
  const {isFeatured, itemId, isManager, isItemOwner, isCommOwner} = options;

  const menuItems: Array<MenuItemType> = [];
  const featureItem: MenuItemType = {
    title: t('company:localBiz.menuItems.featured'),
    onClick: handleSimpleAction(itemId, onFeature),
  };
  const unfeatureItem: MenuItemType = {
    title: t('company:localBiz.menuItems.unfeatured'),
    onClick: handleSimpleAction(itemId, onUnFeature),
  };
  const editItem: MenuItemType = {
    title: t('company:localBiz.menuItems.edit'),
    onClick: onEdit,
  };
  const line: MenuItemType = {
    render: 'line',
  };
  const deleteItem: MenuItemType = {
    title: t('company:localBiz.menuItems.delete'),
    onClick: onDelete,
    render: 'danger',
  };
  const reportItem: MenuItemType = {
    title: t('people:actions.report'),
    onClick: onReport,
    render: 'danger',
  };
  if (isManager) {
    menuItems.push(isFeatured ? unfeatureItem : featureItem);
    menuItems.push(editItem);
    menuItems.push(line);
    menuItems.push(deleteItem);
  }
  !isItemOwner && !isCommOwner && menuItems.push(reportItem);

  return {
    descItems: menuItems,
    mobileItems: getMobileMenuItems(menuItems),
  };
};

function handleSimpleAction(id: string, fn?: ISimpleAction): () => void {
  return () => fn?.(id);
}
