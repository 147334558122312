import {isValid as isDateValid} from 'date-fns';

export const isValid = (value?: Date) => isDateValid(value);

export const getDatesArray = (startDate?: Date, endDate?: Date) => {
  if (!startDate || !endDate) return [];
  const arr = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  start.setHours(0);
  end.setHours(0);
  for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const getHours = (start: Date, end: Date) => {
  const hour = 3600000;
  const begin = Math.round(new Date(start).getTime() / hour);
  const finish = Math.round(new Date(end).getTime() / hour);
  const array: Array<number> = [];

  for (let i = begin; i < finish; i++) {
    array.push(i);
  }
  return array;
};

export const getWeeks = (start: Date, end: Date) => {
  const day = 1000 * 3600 * 24;
  const days = Math.round(new Date(end).getTime() / day) - Math.round(new Date(start).getTime() / day) + 1;
  const weeks = Math.ceil(days / 7);
  const array = [];

  for (let i = 0; i < weeks; i++) {
    array.push(i);
  }
  return array;
};

export const getMonths = (start: Date, end: Date) => {
  const day = 1000 * 3600 * 24;
  const days = Math.round(new Date(end).getTime() / day) - Math.round(new Date(start).getTime() / day);
  const months = Math.ceil(days / 30);
  const array: Array<number> = [];

  for (let i = 0; i < months; i++) {
    array.push(i);
  }
  return array;
};
