import React, {useState} from 'react';
import {Text} from '../../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';
import {LangSettingsForm, LangSettingsInfo, LangSettingsWrapper} from './styles';
import {FormButtons, SettingsEntryAdmin} from '../components';
import {ISettingProfileViewOff, OptionBoolObj, profileViewOption} from '../../../hooks/settings';
import {StyledSelect} from '../Community/styles';
import {Br} from '../../../ui-kit/Typography/Br';

type SettingsProfileViewProps = {
  data: ISettingProfileViewOff;
};

export const SettingsProfileView: React.FC<SettingsProfileViewProps> = ({data}) => {
  const {values, enabledOptions, change, submit} = data;

  const [isEditMode, setEditMode] = useState(false);
  const {t} = useTranslation();

  const handleEnableEditMode = () => {
    setEditMode(true);
  };
  const handleDisableEditMode = () => {
    setEditMode(false);
  };
  const handleSubmit = () => {
    submit();
    setEditMode(false);
  };

  const handleChangeLang = (options: Array<OptionBoolObj>) => {
    change({
      key: 'enabled',
      value: options[0] as OptionBoolObj,
    });
  };
  return (
    <LangSettingsWrapper>
      <Text variant={'primary'} size={18} color={'octonary'} weight={'regular'}>
        {t('settings:preference.profileView.title')}
      </Text>
      <Br indent={10} />
      <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
        {t('settings:preference.profileView.descr')}
      </Text>
      <Br indent={10} />
      {isEditMode ? (
        <>
          <LangSettingsForm>
            <StyledSelect
              name={'lang'}
              label={t('settings:preference.profileView.fieldLabel')}
              values={enabledOptions.filter((el) => el.value === values.enabled.value)}
              options={enabledOptions}
              onChange={handleChangeLang}
            />
          </LangSettingsForm>
        </>
      ) : (
        <>
          <LangSettingsInfo>
            <SettingsEntryAdmin
              label={t('settings:preference.profileView.fieldLabel')}
              value={t(`settings:preference.profileView.${profileViewOption(!!values.enabled.value)}`)}
              onClick={values?.enabled.value ? handleEnableEditMode : undefined}
            />
            {/*<SettingsEntryAdmin label={t('settings:preference.langAndCurrency.currencyLabel')} />*/}
          </LangSettingsInfo>
        </>
      )}
      <FormButtons
        disabled={false}
        variant={isEditMode ? 'update' : 'edit'}
        onClick={isEditMode ? handleSubmit : handleEnableEditMode}
        onCancel={handleDisableEditMode}
      />
    </LangSettingsWrapper>
  );
};
