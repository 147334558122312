import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  MessagePreviewCardWrapper,
  //MessageRequestItemName,
  PreviewMessageCardBody,
  PreviewMessageStatusLine,
  RequestOptionsText,
} from '../../../../ui-kit/Chat/Messages/styles';

export const SkeletonOrderCard = () => {
  return (
    <MessagePreviewCardWrapper bordered={true} fullWidth={true}>
      <PreviewMessageCardBody fullWidth={true}>
        <RequestOptionsText>
          <Skeleton width={'80%'} />
        </RequestOptionsText>
        <PreviewMessageStatusLine>
          <Skeleton width={'60%'} />
        </PreviewMessageStatusLine>
      </PreviewMessageCardBody>
    </MessagePreviewCardWrapper>
  );
};
