import {gql} from '@apollo/client';
import {CompanyShortFragment} from './company';

export const PostTranslateFragment = gql`
  fragment PostTranslateFragment on PostTranslate {
    id
    objectId
    createdAt
    updatedAt
    originalLang
    en
    ru
    fr
  }
`;

export const PostAuthorFragment = gql`
  fragment PostAuthorFragment on User {
    id
    objectId
    onlineDate
    isOnline
    firstName
    firstLetter
    isVerified
    email
    listingCount
    Reviews {
      count
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
    recommendations
  }
`;

export const PostAuthorFragmentFull = gql`
  fragment PostAuthorFragmentFull on User {
    id
    objectId
    onlineDate
    isOnline
    firstName
    lastName
    firstLetter
    listingCount
    isVerified
    email
    Reviews {
      count
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
    recommendations
  }
`;

export const PostItemFragment = gql`
  fragment PostItemFragment on Item {
    id
    objectId
    __typename
    listingName: name
    category
    Lister {
      objectId
    }
    listingType
    listingRewardType: rewardType
    listingPricePeriod: pricePeriod
    price
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    isPublished
    isDeleted
    isLike
    Published {
      ... on Community {
        id
        objectId
      }
    }
    AdmHidden {
      ... on Community {
        id
        objectId
      }
    }
  }
`;

export const PostGroupFragment = gql`
  fragment PostGroupFragment on Group {
    id
    objectId
    __typename
    groupName: name
    isDeleted
    alias
    type
    Avatar {
      file {
        url
      }
    }
  }
`;
export const PostEventFragment = gql`
  fragment PostEventFragment on Event {
    id
    objectId
    __typename
    eventName: name
    Lister {
      objectId
    }
    startTime
    eventRewardType: rewardType
    eventPricePeriod: pricePeriod
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    isPublished
    isDeleted
  }
`;
export const PostAmenityFragment = gql`
  fragment PostAmenityFragment on Amenity {
    id
    objectId
    __typename
    eventName: name
    Lister {
      objectId
    }
    rewardType
    bookingTiming
    images {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    isPublished
    isDeleted
  }
`;
export const PostDocumentFragment = gql`
  fragment PostDocumentFragment on Document {
    id
    objectId
    __typename
    documentName: name
    isPublished
    isDeleted
    alias
    type
    Cover {
      file {
        url
      }
    }
    DocFile {
      file {
        url
      }
      mime
    }
  }
`;

export const CommentFragment = gql`
  fragment CommentFragment on Post {
    id
    objectId
    createdAt
    Community {
      id
      objectId
    }
    type
    text
    Author {
      ...PostAuthorFragment
    }
    isDeleted
    countLikes
    Translate {
      ...PostTranslateFragment
    }
    isLike
    isHidden
    localRef
  }
  ${PostTranslateFragment}
  ${PostAuthorFragment}
`;

export const OptionFragment = gql`
  fragment OptionFragment on PollOption {
    id
    objectId
    text
    countVotes
    position
    Translate {
      ...PostTranslateFragment
    }
  }
`;

export default gql`
  fragment PostFragment on Post {
    id
    objectId
    createdAt
    sentAs
    Community {
      id
      objectId
    }
    type
    text
    isMulti
    Attachments {
      ... on AppFile {
        id
        objectId
        file {
          name
          url
        }
        mime
      }
    }
    isDeleted
    Items {
      ... on Item {
        ...PostItemFragment
      }
      ... on Group {
        ...PostGroupFragment
      }
      ... on Event {
        ...PostEventFragment
      }
      ... on Document {
        ...PostDocumentFragment
      }
      ... on Company {
        ...CompanyShortFragment
      }
      ... on Amenity {
        ...PostAmenityFragment
      }
    }
    countLikes
    isLike
    countSmiles
    isSmile
    countWaves
    isWave
    isHidden
    countComments
    localRef
    Options(order: $optionsOrder) {
      ... on PollOptionConnection {
        edges {
          node {
            ...OptionFragment
            isVoted
          }
        }
      }
    }
    data
    Pin {
      ... on User {
        id
        objectId
      }
    }
    Translate {
      ...PostTranslateFragment
    }
    Group {
      objectId
    }
  }
  ${CompanyShortFragment}
  ${PostItemFragment}
  ${OptionFragment}
  ${PostTranslateFragment}
  ${PostGroupFragment}
  ${PostEventFragment}
  ${PostDocumentFragment}
  ${PostAmenityFragment}
`;
