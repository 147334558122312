import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Sticky from 'react-stickynode';

import {MemoSoldItemInfo} from './SoldItemInfo';
import {
  AsideRequests,
  InnerEditAside,
  WrapperEditFormAside,
  InnerRequestCard,
  FullBlock,
  MainContListingView,
  WrapperListingView,
  ListingViewSubTitle,
  InnerListingView,
  ListingViewDescr,
} from './styles';
import {route} from '../../constants/routes';
import {correctTranslate} from '../../helpers/common';
import {getAmountLabel} from '../../helpers/order';
import {getUserName} from '../../helpers/user';
import {ListingType, RewardType} from '../../queries/types/item';
import {TypeCommunity} from '../../types/auth';
import {TItem} from '../../types/item';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {ListingRequestCard} from '../../ui-kit/RequestCard/ListingRequestCard';
import {MessageRequestCard} from '../../ui-kit/RequestCard/MessageRequestCard';
import {ListingCardType, MessageCardUserType, SendMessageStatus, TagType} from '../../ui-kit/RequestCard/types';
import {RangeDatePickerValues} from '../../ui-kit/Search/types';
import {LottieLoader} from '../Loader/LottieLoader';

import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {TInvoice, TResCreateOrder} from '../../hooks/order';
import {RichText} from '../common/RichText/RichText';
import {analyticsTrackFN} from '../../helpers/account';
import {useLocation} from 'react-router-dom';
import {useViewer} from '../../hooks/user';
import {useRecoilValue} from 'recoil';
import {currentCommunity} from '../../states/community';

type TProps = {
  className?: string;
  width?: string;
  user?: MessageCardUserType;
  descr: string;
  item?: Partial<TItem>;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataOrder?: TResCreateOrder;
  dataInvoice?: TInvoice;
  isDesktop?: boolean;
  bottomBoundary?: number;
  changeCardStatusRef?: React.MutableRefObject<((status: SendMessageStatus) => void) | undefined>;
  sendMessageRef?: React.RefObject<HTMLDivElement>;
  requestCardRef?: React.RefObject<HTMLDivElement>;
  isAvailable?: boolean;
  showWarnPopup?: () => void;
  accessRequest?: boolean;
  busyDates?: Date[];
  canSandMessage?: boolean;
  typeUser?: TypeCommunity | null;
  dataOrderSubmit?: () => void;
  description?: string;
  isPreview?: boolean;
  noImages?: boolean;
};

const getTextData = (item?: Partial<TItem>, typeText?: 'buttons' | 'descr') => {
  if (item?.rewardType === RewardType.free && item?.listingType === ListingType.rent) {
    return `requests:${typeText}.request.free`;
  }
  if (item?.rewardType === RewardType.coffee && item?.listingType === ListingType.rent) {
    return `requests:${typeText}.request.coffee`;
  }
  if (item?.rewardType === RewardType.fee && item?.listingType === ListingType.rent) {
    return `requests:${typeText}.request.make`;
  }
  if (item?.rewardType === RewardType.coffee) {
    return `requests:${typeText}.request.coffee`;
  }
  if (item?.rewardType === RewardType.free) {
    return `requests:${typeText}.request.free`;
  }
  if (item?.listingType === ListingType.sell) {
    return `requests:${typeText}.request.buy`;
  }
  if (item?.listingType === ListingType.service) {
    return `requests:${typeText}.request.service`;
  }
  return '';
};

export const RequestAside: React.FC<TProps> = ({
  width,
  user,
  descr,
  item,
  dataCreateMsg,
  dataInvoice,
  dataOrder,
  className,
  bottomBoundary,
  isDesktop,
  changeCardStatusRef,
  sendMessageRef,
  requestCardRef,
  isAvailable,
  accessRequest,
  showWarnPopup,
  busyDates,
  canSandMessage,
  typeUser,
  dataOrderSubmit,
  description,
  isPreview,
  noImages,
}) => {
  const [cardStatus, setCardStatus] = useState<SendMessageStatus>(SendMessageStatus.Write);
  const viewer = useViewer();
  const community = useRecoilValue(currentCommunity);
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const {getSignValue, currency} = useGetCurrencyValue();

  useEffect(() => {
    if (changeCardStatusRef) {
      changeCardStatusRef.current = setCardStatus;
    }
  }, []);

  useEffect(() => {
    if (dataCreateMsg?.success) {
      setCardStatus(SendMessageStatus.Done);
    }
  }, [dataCreateMsg?.success]);

  const titleBtn = t(getTextData(item, 'buttons'));
  const titleDescr = t(getTextData(item, 'descr'));
  const tagRewardType = getAmountLabel(item);
  const errorMsg = correctTranslate({
    text: t(dataOrder?.error?.message || ''),
    defaultText: dataOrder?.error?.message,
  });
  const successMsg = dataOrder?.success ? t(dataOrder.success) : dataOrder?.success;

  const handleWriteMsg = () => {
    setCardStatus(SendMessageStatus.Send);
  };
  const {state} = useLocation();
  const handleSendMsg = () => {
    if (!dataCreateMsg?.onSubmit) return;
    analyticsTrackFN('Lister Messaged', {
      listingId: item?.id,
      listingType: item?.listingType, // service, rental, buy/sell
      listedFor: item?.rewardType, // coffee, free, money
      photoCount: item?.images?.length,
      title: item?.name,
      description: item?.descr,
      rate: item?.price,
      currency: currency?.sign,
      bookingType: item?.pricePeriod,
      category: item?.category,
      listerName: `${item?.Lister?.firstName}`,
      listerEmail: item?.Lister?.email,
      listerId: item?.Lister?.objectId,
      requesterName: `${viewer?.firstName}`,
      requesterEmail: viewer?.email,
      requesterId: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type, // building, neighbourhood, circle
      source: state, // marketplace, loop, profile
    });
    dataCreateMsg.onSubmit();
  };

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!dataCreateMsg?.onChange) return;
    dataCreateMsg?.onChange({name: CreateMsgFields.text, value: target.value});
  };

  const handleChangeDate = (value: RangeDatePickerValues[]) => {
    if (!dataOrder?.onChange) return;
    dataOrder.onChange(value);
  };

  const handleCreateOrder = () => {
    if (!accessRequest) return showWarnPopup?.();
    if (!dataOrderSubmit) return;
    dataOrderSubmit();
  };
  const pickerLabels = useMemo(() => {
    return {
      labelTime: t('requests:labelTime'),
      labelPickUp: t('requests:pickUp'),
      labelDate: t('requests:labelDate'),
      labelDuration: t('requests:labelDuration'),
      labelHours: t('requests:labelHours'),
    };
  }, []);
  const pickerPlaceholders = useMemo(() => {
    return {
      addTime: t('requests:addTime'),
      addDate: t('requests:addDate'),
      addHours: t('requests:addHours'),
    };
  }, []);
  const orderId = dataOrder?.orderInfo ? `?orderId=${dataOrder?.orderInfo}` : '';
  const messageLink = `${getLink(route.messages.get({contactId: item?.Lister?.objectId}))}${orderId}`;
  const userName = getUserName(typeUser, user?.firstName, user?.lastName);
  return (
    <InnerEditAside width={width} className={className} $inFlow={noImages}>
      <AsideRequests $inFlow={noImages}>
        <WrapperEditFormAside>
          <FullBlock ref={sendMessageRef}>
            <MessageRequestCard
              hideReviews={true}
              type={cardStatus}
              user={user}
              userName={userName}
              descr={descr}
              buttonTitle={t('requests:buttons.send')}
              linkTitle={t('requests:buttons.view')}
              itemTitle={item?.name}
              handleWriteMsg={handleWriteMsg}
              handleSendMsg={handleSendMsg}
              pathLink={getLink(route.messages.get({contactId: item?.Lister?.objectId}))}
              messageSent={t('requests:successSend')}
              message={{
                placeholder: t('requests:placeholder'),
                value: dataCreateMsg?.values?.text,
                onChange: handleChange,
                loading: dataCreateMsg?.loading,
              }}
              canSend={canSandMessage}
              profileLink={item?.Lister?.objectId && getLink(route.profile.get({id: item?.Lister?.objectId}))}
              isPreview={isPreview}
            />
          </FullBlock>
          {description && (
            <MainContListingView>
              <WrapperListingView offsetTop={'12px'}>
                <InnerListingView>
                  <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                  <ListingViewDescr>
                    <RichText html={description} />
                  </ListingViewDescr>
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
          )}
          {item?.isSold ? (
            <MemoSoldItemInfo title={item?.name} price={t(tagRewardType.label, {value: tagRewardType?.opts})} />
          ) : (
            <InnerRequestCard id={'requestCard'} ref={requestCardRef} $isPreview={isPreview}>
              <Sticky enabled={!!isDesktop} top={60} bottomBoundary={bottomBoundary}>
                <ListingRequestCard
                  type={ListingCardType.Request}
                  buttonLabel={{label: titleBtn, onClick: handleCreateOrder, loading: dataOrder?.loading}}
                  title={item?.name || ''}
                  descr={titleDescr}
                  dateLabel={{
                    from: {title: t('requests:pickUp')},
                    to: {title: t('requests:dropOff')},
                    canEdit: false,
                    dates: dataOrder?.dates || [null, null],
                    setDates: handleChangeDate,
                    placeholder: {
                      default: t('requests:placeholders.selectDate'),
                      selectStart: t('requests:placeholders.selectStart'),
                      selectEnd: t('requests:placeholders.selectEnd'),
                      start: t('requests:placeholders.start'),
                      end: t('requests:placeholders.end'),
                    },
                  }}
                  tags={[
                    {label: t(`listings:listingsType.${item?.listingType}`), type: TagType.listingType},
                    {
                      label: t(tagRewardType.label, {value: getSignValue(tagRewardType?.opts || 0)}),
                      type: tagRewardType.type,
                    },
                  ]}
                  canEdit={isPreview ? false : true}
                  payments={[{...dataInvoice}]}
                  message={{error: errorMsg, success: successMsg}}
                  pickerLabels={pickerLabels}
                  pickerPlaceholders={pickerPlaceholders}
                  listingType={item?.listingType}
                  pricePeriod={item?.pricePeriod}
                  datePickerData={dataOrder?.datePickerData}
                  successOrder={dataOrder?.success}
                  requestSent={t('requests:requestSend')}
                  pathLink={messageLink}
                  linkTitle={t('requests:buttons.view')}
                  durationUnit={{
                    single: t('requests:durationUnit.single'),
                    many: t('requests:durationUnit.many'),
                  }}
                  captions={{
                    date: t('requests:captions.date'),
                    dateDuration: t('requests:captions.dateDuration'),
                    time: t('requests:captions.time'),
                    duration: t('requests:captions.duration'),
                  }}
                  buttonSelectText={t('requests:buttonSelectText')}
                  isAvailable={isAvailable}
                  notAvailableText={t('requests:notAvailable')}
                  busyDates={busyDates}
                  accessRequest={accessRequest}
                  showWarnPopup={showWarnPopup}
                  isCustom={!isDesktop}
                  bookingLabel={t('listings:card.labels.booking')}
                  isNotSelected={dataOrder?.isNotSelected}
                  rewardType={item?.rewardType}
                />
              </Sticky>
            </InnerRequestCard>
          )}
          <LottieLoader $isVisible={dataOrder?.loading} allScreen={true} />
        </WrapperEditFormAside>
      </AsideRequests>
    </InnerEditAside>
  );
};
