import React from 'react';
import {HangehLogoWrapper, HeaderLogoWrapper} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {TranslateSwitch} from '../Translate/TranslateSwitch';

interface TProps {
  icon?: 'back' | 'close';
  onClick?: () => void;
  centeredLogo?: boolean;
}

export const HeaderLogo: React.FC<TProps> = ({icon, onClick, centeredLogo}) => {
  return (
    <HeaderLogoWrapper $centeredLogo={centeredLogo}>
      <HangehLogoWrapper>
        <IconSvg
          type={'hangeh-logo-beta'}
          stroke={'none'}
          fill={'aqua'}
          width={'122'}
          height={'44'}
          viewBox={'10 0 152 64'}
        />
      </HangehLogoWrapper>
      {icon ? (
        <div onClick={onClick}>
          {icon === 'back' ? (
            <IconSvg type={'right'} width={'24'} height={'24'} stroke={'gray'} />
          ) : (
            <IconSvg type={'close'} stroke={'gray'} />
          )}
        </div>
      ) : (
        <TranslateSwitch variant={'secondary'} />
      )}
    </HeaderLogoWrapper>
  );
};
