import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {EventMenuListWrapper, EventMenuContainer} from './styles';
import {AvatarWrapper, EntryButtonWrapper, RelativeWrapper, TextBlock, Title, WrapperFlex} from './../Settings/styles';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {MemberSkeletonShort} from '../../../common/Skeletons/PeopleSkeleton';
import {currentCommunity} from '../../../../states/community';
import {useRecoilState} from 'recoil';
import {useUrlSearchManager} from '../../../../hooks/urlSearchManager';
import {getEventTypeParams} from '../../../../helpers/event';
import {SearchKey} from '../../../../constants/routes';
import {useEventPageContent} from '../../../../hooks/event';
import {useTagMenuCorrectPosition} from '../../../../hooks/common';

export const EventMenu = (props: {
  top: number;
  left: number;
  handleSetEvent: (
    e: React.SyntheticEvent,
    eventId: string,
    eventNameStr: string,
    setEventName: (param: string) => void,
  ) => void;
  setEventName: (param: string) => void;
  searchText?: string;
}) => {
  const {t} = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);
  const [comm] = useRecoilState(currentCommunity);
  const urlSearch = useUrlSearchManager();
  const searchType = urlSearch.get(SearchKey.type);
  // const today = getDateHourBorder();
  const searchParams = [
    !searchType
      ? {
          name: {
            matchesRegex: props.searchText?.toLocaleLowerCase() ?? '',
            options: 'i',
          },
        }
      : getEventTypeParams(searchType),
  ];
  // searchParams.push({
  //   endTime: {
  //     greaterThan: today,
  //   },
  // });
  const {loadingAllEvents, hasMoreAllEvents, communityEvents} = useEventPageContent(searchParams, comm);
  useTagMenuCorrectPosition(menuRef);
  return (
    <EventMenuContainer style={{top: props.top, left: props.left}} ref={menuRef}>
      <EventMenuListWrapper>
        {communityEvents !== undefined && communityEvents?.length > 0 ? (
          communityEvents.map((el: any, index: number) => (
            <EventMenuItem
              event={el}
              key={index}
              handleSetEvent={props.handleSetEvent}
              setEventName={props.setEventName}
            />
          ))
        ) : loadingAllEvents || hasMoreAllEvents ? (
          <MemberSkeletonShort />
        ) : (
          <Title>{t('events:empty.attendees.noAvailable')}</Title>
        )}
      </EventMenuListWrapper>
    </EventMenuContainer>
  );
};

export const EventMenuItem = (props: {
  event: any;
  setEventName: (param: string) => void;
  handleSetEvent: (
    e: React.SyntheticEvent,
    eventId: string,
    eventNameStr: string,
    setEventName: (param: string) => void,
  ) => void;
}) => {
  const event = props.event;
  return (
    <RelativeWrapper>
      <EntryButtonWrapper onClick={(e) => props.handleSetEvent(e, event?.objectId, event?.name, props.setEventName)}>
        <WrapperFlex flexStart={true}>
          <AvatarWrapper>
            <Avatar src={event?.images[0]?.file?.url} size={'sm'} />
          </AvatarWrapper>
          <TextBlock>
            <Title $overflow={true}>{event?.name}</Title>
          </TextBlock>
        </WrapperFlex>
      </EntryButtonWrapper>
    </RelativeWrapper>
  );
};
