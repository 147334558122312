import React from 'react';
import {DarkBackground, MobileMenuWrapper, MobileMenuEntry, MobileMenuEntryLink} from './styles';
import {BasePortalProps} from '../Popup';

export interface MobileMenuEntry {
  title: string;
  onClick?: () => void;
  type?: 'regular' | 'danger';
  to?: string;
}

export interface MobileMenuProps {
  entries: MobileMenuEntry[];
  isPortal?: boolean;
  toggleCallback?: (v: boolean) => void;
  portalProps?: BasePortalProps;
  closeMenu?: (e: React.SyntheticEvent) => void;
  customIcon?: React.ReactNode;
  portalProvider?: React.FC;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({entries, closeMenu}) => {
  return (
    <DarkBackground onClick={closeMenu}>
      <MobileMenuWrapper>
        {entries.map((e) =>
          e.to ? (
            <MobileMenuEntryLink to={e.to} key={e.title} type={e.type} onClick={e.onClick}>
              {e.title}
            </MobileMenuEntryLink>
          ) : (
            <MobileMenuEntry key={e.title} type={e.type} onClick={e.onClick}>
              {e.title}
            </MobileMenuEntry>
          ),
        )}
      </MobileMenuWrapper>
    </DarkBackground>
  );
};
