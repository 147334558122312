import React, {useMemo, useState} from 'react';
import {DraggableHelpItemProps, HelpListManagerPropsT} from './types';
import {
  HelpItemContent,
  HelpItemContentText,
  HelpItemTitle,
  HelpItemWrapper,
  ListWrapper,
  HelpItemContainer,
  DragBtn,
  ExtLink,
  PublishLabel,
  LinkBtn,
  InnnerHelpDeskCardBtns,
  MenuButtonWrapper,
  DropDownBtn,
} from './styles';
import {DragDropContext, Droppable, Draggable, DropResult, DraggingStyle, NotDraggingStyle} from 'react-beautiful-dnd';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {HelpDeskItemT} from '../../types/helpDesk';
import {useTranslation} from 'react-i18next';
import {
  correctButtonTranslate,
  formatLink,
  getMenuHelpDesk,
  GetMenuHelpDeskFuncOptions,
  GetMenuHelpDeskOptions,
  getMobileMenuHelpDesk,
} from '../../helpers/helpDesk';
import {RichText} from '../common/RichText/RichText';
import {HelpDeskItemType} from '../../queries/types/helpDesk';
import {useLinks} from '../../hooks/common';
import {route} from '../../constants/routes';
import {Label} from '../../ui-kit/Labels';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {HiddenMenu} from '../Amenities/styles';
import {Media} from '../../ui-kit/theme';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

const reorder = (list: HelpDeskItemT[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (removed) result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): React.CSSProperties => ({
  userSelect: 'none',
  opacity: isDragging ? 0.2 : 1,
  ...draggableStyle,
});

export const HelpListManager: React.FC<HelpListManagerPropsT> = ({
  customList,
  mainList,
  setList,
  dragDisabled,
  handleDelete,
  handlePublish,
  handleEdit,
  changeOrder,
  editingOrOpened,
  viewer,
  selectedDesk,
  form,
}) => {
  const {t} = useTranslation();
  const [opened, setOpened] = useState<Record<string, boolean>>({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [focusedId, setFocusedId] = useState<string | undefined>();
  const handleClick = (id?: string) => {
    if (id) setOpened((prev) => ({...prev, [id]: !prev?.[id]}));
  };
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(customList, result.source.index, result.destination.index);
    setList(items);
    changeOrder(items?.map((el) => el.objectId || '') || []);
  };

  const handleOpenDelete = (id?: string) => {
    setShowModalDelete(true);
    setFocusedId(id);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
    setFocusedId(undefined);
  };
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('common:modals.delete.title'),
        okText: t('common:modals.delete.button'),
        cancelText: t('common:modals.delete.close'),
      },
    };
  }, []);
  const onDelete = async () => {
    await handleDelete(focusedId);
    setShowModalDelete(false);
  };

  return (
    <ListWrapper>
      {mainList.map((item) => (
        <HelpItemContainer key={item.id}>
          <HelpItem
            {...item}
            isOpened={item?.objectId ? !!opened?.[item.objectId] : false}
            handleOpen={handleClick}
            isBase={true}
            handleDelete={handleOpenDelete}
            handleEdit={handleEdit}
            handlePublish={handlePublish}
            viewer={viewer}
          />
        </HelpItemContainer>
      ))}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {customList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id || String(index)} index={index}>
                  {(provided, snapshot) => (
                    <HelpItemContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      {!dragDisabled && (
                        <DragBtn {...provided.dragHandleProps}>
                          <IconSvg type={'hamburger'} stroke={'grayLight-2'} />
                        </DragBtn>
                      )}
                      {selectedDesk === item.objectId ? (
                        <>{form}</>
                      ) : (
                        <HelpItem
                          {...item}
                          isBase={false}
                          isOpened={item?.objectId ? !!opened?.[item.objectId] : false}
                          handleOpen={handleClick}
                          handleDelete={handleOpenDelete}
                          handleEdit={handleEdit}
                          handlePublish={handlePublish}
                          editingOrOpened={editingOrOpened}
                          viewer={viewer}
                        />
                      )}
                    </HelpItemContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={onDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
    </ListWrapper>
  );
};

const HelpItem: React.FC<DraggableHelpItemProps> = ({
  id,
  title,
  objectId,
  descr,
  isOpened,
  buttonTitle,
  handleOpen,
  isBase,
  type,
  assignTo,
  Assignee,
  isPublished,
  handleEdit,
  handleDelete,
  handlePublish,
  editingOrOpened,
  viewer,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [published, setPublished] = useState(isPublished);
  const descrText = isBase ? t(`helpDesk:baseDesks.${descr}`) : descr;
  const buttonLink = (type === HelpDeskItemType.link || type === HelpDeskItemType.linkView) && assignTo;
  const correctButtonTitle = type === HelpDeskItemType.linkView ? correctButtonTranslate(t, buttonTitle) : buttonTitle;

  const messageLink = Assignee
    ? viewer !== Assignee.objectId
      ? getLink(route.messages.get({contactId: Assignee.objectId}))
      : '#'
    : '#';

  const getMenuItemsFunctions: GetMenuHelpDeskFuncOptions = {
    t,
    deleteCallBack: handleDelete,
    unPublishCallBack: async (id?: string) => {
      handlePublish(false, id);
      setPublished(false);
    },
    publishCallBack: async (id?: string) => {
      await handlePublish(true, id);
      setPublished(true);
    },
    editCallBack: handleEdit,
    viewCallBack: handleOpen,
  };
  const getMenuHelpDeskOptions: GetMenuHelpDeskOptions = {
    objectId,
    isPublished: published,
    isView: isOpened,
  };
  const menuItems: MenuItemType[] =
    !isBase && !editingOrOpened ? [...getMenuHelpDesk(getMenuHelpDeskOptions, getMenuItemsFunctions)] : [];
  const mobileMenuItems: MobileMenuEntry[] =
    !isBase && !editingOrOpened ? [...getMobileMenuHelpDesk(getMenuHelpDeskOptions, getMenuItemsFunctions)] : [];
  const prevent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <HelpItemWrapper>
        <HelpItemTitle onClick={() => handleOpen(objectId)}>
          {isBase ? t(`helpDesk:baseDesks.title.${title}`) : title}

          {!published && (
            <PublishLabel>
              <Label variant={'quinary'}> {t(`helpDesk:card.unpublished`)}</Label>
            </PublishLabel>
          )}
        </HelpItemTitle>
        <DropDownBtn $isOpened={isOpened} onClick={() => handleOpen(id)}>
          <IconSvg type={'chevrone-down'} />
        </DropDownBtn>
        {id && (
          <HelpItemContent $isOpened={isOpened}>
            <HelpItemContentText>
              <RichText html={descrText} />
            </HelpItemContentText>
            {buttonTitle &&
              (buttonLink ? (
                <ExtLink href={formatLink(assignTo)} target={'_blank'}>
                  {correctButtonTitle}
                </ExtLink>
              ) : (
                <LinkBtn to={messageLink}>{correctButtonTranslate(t, buttonTitle)}</LinkBtn>
              ))}
          </HelpItemContent>
        )}
        <InnnerHelpDeskCardBtns>
          <MenuButtonWrapper onClick={prevent}>
            {!!menuItems.length && (
              <HiddenMenu mq={Media.down.s}>
                <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
              </HiddenMenu>
            )}
            {!!mobileMenuItems.length && (
              <HiddenMenu mq={Media.up.m}>
                <MobileMenuButton isPortal={true} entries={mobileMenuItems} portalProvider={IsModalProvider} />
              </HiddenMenu>
            )}
          </MenuButtonWrapper>
        </InnnerHelpDeskCardBtns>
      </HelpItemWrapper>
    </>
  );
};
