import styled, {keyframes} from 'styled-components';
import {fontPrimaryReg} from '../styles';
import {Media} from '../../../ui-kit/theme';

export const IconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: auto;
`;
export const HintWrapper = styled.label<{$active?: boolean; zIndex?: number; align?: 'left' | 'right'}>`
  position: relative;
  display: block;
  z-index: ${({$active, zIndex}) => ($active ? 1001 : zIndex || 100)};
`;
export const HintBlock = styled.div<{top?: number; float?: 'left' | 'right'}>`
  padding: 6px;
  border-radius: 12px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  font-size: 12px;
  white-space: pre-wrap;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
  top: ${({top}) => (top ? top + '%' : '70%')};
  width: 165px;
  z-index: 1001;
  ${({float}) => {
    if (float === 'right') return ` right: 0;`;
    return ` left: 0;`;
  }}
`;

export const HintWrapperInput = styled.div<{$showMob?: boolean; $isSelect?: boolean}>`
  position: relative;
  & > label:last-of-type {
    position: absolute;
    /* left: 100%;
    margin-left: 10px; */
    top: 55%;
    right: ${({$isSelect}) => ($isSelect ? '32px' : '2px')};
    transform: translateY(-50%);
    ${Media.down.m} {
      position: absolute;
      margin-left: 0;
      background: ${({theme}) => theme.palette.background.primary};
      right: ${({$isSelect}) => ($isSelect ? '32px' : '2px')};
      left: unset;
      top: 58%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  ${Media.down.m} {
    & > div > label {
      font-size: 14px;
      max-width: 82%;
    }
  }
`;

const fadeIn = keyframes`
 0% { opacity: 0; }
 50% { opacity: 0.15 }
 70% { opacity: 0.4; }
 80% { opacity: 0.7; }
 100% { opacity: 1; }
`;

export const HintBlockWrapper = styled.div<{$offsets?: boolean}>`
  width: 100%;
  background: ${({theme}) => theme.palette.common.blueLight};
  border-radius: 10px;
  padding: 14px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  ${({$offsets}) =>
    $offsets &&
    `
    max-width: 100%;
    margin: 0 auto;
  `};
`;
export const HintBlockIcon = styled.div`
  width: 26px;
  margin-right: 7px;
  height: 24px;
`;
export const HintBlockText = styled.div`
  width: 100%;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
  font-size: 12px;
`;
export const HintBlockClose = styled.div`
  width: 26px;
  height: 24px;
`;
