import React from 'react';

import {ViewDocument} from '../../components/Documents/ViewDocument';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {TDocument} from '../../types/document';
import {NavContainer} from '../Navigation';

import {useGetDocument} from '../../hooks/document';

export const ViewDocumentContainer: React.FC = () => {
  const {data, loading} = useGetDocument();

  if (!loading && !data) return null;

  return loading ? (
    <LottieLoader allScreen={true} $isVisible={true} />
  ) : (
    <NavContainer>
      <ViewDocument document={data as TDocument} />
    </NavContainer>
  );
};
