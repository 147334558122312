import {Connection, ParseObject} from './parse';
import {User} from './user';
import {Community} from './community';
import {Review} from './review';
import {PointerFile} from '../../types/common';

export enum ListingType {
  rent = 'rent',
  sell = 'sell',
  service = 'service',
}

export enum RewardType {
  free = 'free',
  coffee = 'coffee',
  fee = 'fee',
}

export enum PricePeriodType {
  month = 'month',
  week = 'week',
  hour = 'hour',
  day = 'day',
  fixed = 'fixed',
}

export interface Item extends ParseObject {
  name: string;
  descr: string;
  Lister: User;
  category: string;
  listingType: ListingType;
  rewardType: RewardType;
  price?: number;
  pricePeriod?: PricePeriodType | null;
  images: PointerFile[];
  originalPrice?: number;
  yearPurchased?: string;
  discount?: number;
  discountType?: PricePeriodType | null;
  Featured?: Community[];
  ApproveReq?: Community[];
  AdmHidden?: Community[];
  Published?: Community[];
  Updated?: Community[];
  Reviews?: Connection<Review>;
  Location?: Location;
  maxPeriod?: number;
  isDeleted: boolean;
  isPublished: boolean;
  isSold: boolean;
  minDuration?: number;
  Viewers?: Connection<User>;
  rating?: number;
  income?: number;
  totalRequests?: number;
  totalViews?: number;
  totalCompletedOrders?: number;
  isLike?: boolean;
}
