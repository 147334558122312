import {atom} from 'recoil';
import {TypeCommunity} from '../types/auth';

export type TypeUserStateType = TypeCommunity | undefined | null;

export const typeUser = atom<TypeUserStateType>({
  key: 'typeUser',
  default: undefined,
});

export const isResidentState = atom<boolean | undefined>({
  key: 'isResident',
  default: undefined,
});
