import styled from 'styled-components';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Text} from '../../../ui-kit/Typography/Text';

export const InnerCard = styled.div<{width?: string}>`
  padding: 8px 58px 8px 16px;
  box-sizing: border-box;
  min-width: ${({width}) => width || '100%'};
  position: relative;
  max-width: 260px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.background.tertiary};
`;

export const Icon = styled(IconSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 21px;
`;

export const Title = styled(Text).attrs({variant: 'tertiary', as: 'div'})`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
`;

export const InnerNumber = styled(Text).attrs({
  variant: 'tertiary',
  as: 'div',
})`
  font-size: 16px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.quinary};
`;

export const WrapperCard = styled.div`
  padding-right: 61px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 260px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const RemoveCardBtn = styled.button<{$inActive?: boolean}>`
  //position: absolute;
  //right: 0;
  //top: 50%;
  //transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 0;
  font-size: 10px;
  line-height: 150%;
  text-decoration-line: underline;
  color: ${({theme}) => theme.palette.text.octonary};
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  cursor: pointer;
  ${({$inActive}) => $inActive && ` opacity: 0.5; point-events: none;`};
`;

export const TextLabelActive = styled.div<{$inActive?: boolean}>`
  font-size: 10px;
  line-height: 150%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.palette.text.denary};
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  ${({$inActive}) => $inActive && ` opacity: 0.5; point-events: none;`};
`;
