import React, {useEffect, useState} from 'react';
import {ButtonLabelProps} from './types';
import {Button} from '../Button/Button';
import {CardLink, CardLinkWrapper} from './styles';

export const CardButton: React.FC<ButtonLabelProps> = ({primary, secondary, type, disabled}) => {
  if (type === 'primary' && !primary.label) return null;
  if (type === 'secondary' && !secondary.label) return null;
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (clicked)
      timeout = setTimeout(() => {
        setClicked(false);
      }, 1000);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [clicked]);

  return type === 'primary' ? (
    <Button
      variant={'danger'}
      loading={primary.loading}
      onClick={() => {
        primary?.onClick?.();
        setClicked(true);
      }}
      disabled={clicked || disabled}>
      {primary.label}
    </Button>
  ) : (
    <CardLinkWrapper>
      <CardLink onClick={secondary.onClick}>{secondary.label}</CardLink>
    </CardLinkWrapper>
  );
};
