import {gql} from '@apollo/client';
import {LocationFragment} from './fragments/locations';
import {Location} from './types/locations';

export type LocationsResponse = {
  locations: {
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
    };
    count: number;
    edges: [
      {
        cursor: string;
        node: Location;
      },
    ];
  };
};

export const GetLocations = gql`
  query locations($cursor: String, $first: Int, $where: LocationWhereInput, $order: [LocationOrder!]) {
    locations(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...LocationFragment
        }
      }
    }
  }
  ${LocationFragment}
`;
