import {gql} from '@apollo/client';
import {CurrencyFragment} from './currency';

export default gql`
  fragment PaymentPlanFragment on PaymentPlan {
    id
    name
    objectId
    priceYear
    priceMonth
    descr
    communityFee
    options {
      ... on Element {
        value
      }
    }
    listingCap
    residentCap
    extraListing
    extraResident
    rentFees
    salesFees
    serviceFees
    serviceExternal
    rentalExternal
    salesExternal
    Currency {
      ...CurrencyFragment
    }
  }
  ${CurrencyFragment}
`;
