import {gql} from '@apollo/client';
import {managerDashboardFragment, residentDashboardFragment} from './fragments/dashboard';
import {residentDashboard as residentDashboardType, managerDashboard as managerDashboardType} from './types/dashboard';

export const residentDashboard = gql`
  mutation residentDashboard($startDate: String, $endDate: String, $location: String) {
    residentDashboard(input: {startDate: $startDate, endDate: $endDate, locationId: $location}) {
      ...residentDashboardFragment
    }
  }
  ${residentDashboardFragment}
`;

export interface ResidentDashboardResponceType {
  residentDashboard: residentDashboardType;
}

export const managerDashboard = gql`
  mutation managerDashboard($id: ID!) {
    managerDashboard(input: {communityId: $id}) {
      ...managerDashboardFragment
    }
  }
  ${managerDashboardFragment}
`;

export interface ManagerDashboardResponceType {
  managerDashboard: managerDashboardType;
}
