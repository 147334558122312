import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {EmptyCard} from '../../ui-kit/Chat/EmptyCard';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {InnerContacts, InnerEmptyCard, InnerPreviewOrderCard} from './styles';
import {useTranslation} from 'react-i18next';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {Order} from '../../queries/types/order';
import {SkeletonLoader} from './Sceleton/Loader';
import {SkeletonOrderCard} from './Sceleton/Card/OrderCard';
import {OrderHelpersT, TContact} from '../../types/messages';
import {RequestMessageSmall} from '../../ui-kit/Chat/Messages/RequestMessageSmall';
import {TResActiveOrder} from '../../hooks/message';
import {RequestCard} from './RequestCard';
import {
  editReviewDataT,
  TDataInvoices,
  TResCreateReview,
  TResEditOrder,
  TResManageOrder,
  useGetOrderError,
  whereTransactionOrder,
} from '../../hooks/order';
import {getTextLabelPreviewCardOrder} from '../../helpers/order';
import {TReview} from '../../types/reviews';
import {useConnectStripe} from '../../hooks/payments';
import {RewardType} from '../../queries/types/item';
import {useGetCurrencyValue} from '../../hooks/currency';
import {RequestCardEvent} from './RequestCardEvent';
import {RequestCardAmenity} from './RequestCardAmenity';
import {usetToTcontact} from '../../helpers/message';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../states/typeUser';
import {useGetSupportData} from '../../hooks/helpDesk';
import {useHistory} from 'react-router-dom';
import {analyticsTrackFN} from '../../helpers/account';
import {format} from 'date-fns';
import {useLinks} from '../../hooks/common';
import {SITE_URL} from '../../constants/env';
import {formatSettinsStripeLink} from '../../helpers/message';
import {useViewer} from '../../hooks/user';
import {route, SearchKey} from '../../constants/routes';

type TProps = {
  viewerId?: string;
  activeOrder?: Order | null;
  activeContact?: TContact | null; //todo
  dataActiveOrder: TResActiveOrder;
  dataOrders: {
    fetchData: () => void;
    hasMore: boolean;
    loading: boolean;
    invoiceLoading?: boolean;
    orders?: Order[] | null;
  };
  dataInvoice: TDataInvoices | null;
  dataManageOrder: TResManageOrder;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  showStripePopup: () => void;
  setCardErrorPopup: (v: string | null) => void;
  setActiveContact: (user: TContact | null) => void;
  separateLoading?: boolean;
  isModal?: boolean;
  onCloseOrder?: () => void;
};

export const RequestsContent: React.FC<TProps> = ({
  dataOrders,
  activeContact,
  dataActiveOrder,
  viewerId,
  dataInvoice,
  dataManageOrder,
  editOrderData,
  reviewOrderData,
  reviewsData,
  editReviewData,
  reviewsDataLoading,
  orderHelpers,
  showStripePopup,
  setCardErrorPopup,
  setActiveContact,
  separateLoading,
  isModal,
  onCloseOrder,
}) => {
  const {search} = useLocation();
  const {t} = useTranslation();
  const [userType] = useRecoilState(typeUser);
  const {orders, fetchData, hasMore, loading, invoiceLoading} = dataOrders;
  const {activeOrder, setActiveOrder} = dataActiveOrder;
  const [stripeError, setStripeError] = useState<boolean | null>(null);
  const getStripeInfo = useConnectStripe();
  const isFee = activeOrder?.rewardType === RewardType.fee;
  const {error, refetch} = useGetOrderError({});
  const {getSignValue} = useGetCurrencyValue();
  const isItem = activeOrder?.Item?.objectId;
  const isEvent = activeOrder?.Event?.objectId;
  const isAmenity = activeOrder?.Amenity?.objectId;
  const {linkOnChat} = useGetSupportData();
  const {push} = useHistory();
  const {getLink} = useLinks();
  const viewer = useViewer();
  const [isCanceled, setIsCanceled] = useState(false);

  const handlePushToSupport = () => push(linkOnChat);

  const handlePushToListing = () => {
    if (!activeOrder?.Item?.objectId) return;
    push(getLink(route.listingView.get({id: activeOrder?.Item?.objectId})));
  };

  useEffect(() => {
    if (!activeOrder?.objectId) return;
    try {
      analyticsTrackFN('Booking Card Viewed', {
        listingId: dataActiveOrder?.activeOrder?.Item?.objectId,
        listingType: dataActiveOrder?.activeOrder?.Item?.listingType, // service, rental, buy/sell
        listedFor: dataActiveOrder?.activeOrder?.Item?.rewardType, // coffee, free, money
        photoCount: dataActiveOrder?.activeOrder?.Item?.images?.length,
        title: dataActiveOrder?.activeOrder?.Item?.name,
        description: dataActiveOrder?.activeOrder?.Item?.descr,
        rate: dataActiveOrder?.activeOrder?.Item?.price,
        currency: dataActiveOrder?.activeOrder?.Currency?.code,
        bookingType: dataActiveOrder?.activeOrder?.pricePeriod,
        category: dataActiveOrder?.activeOrder?.Item?.category,
        listerName: `${activeOrder?.Lister?.firstName}`,
        listerEmail: dataActiveOrder?.activeOrder?.Lister?.email,
        listerId: dataActiveOrder?.activeOrder?.Lister?.objectId,
        requesterName: `${dataActiveOrder?.activeOrder?.Requester?.firstName}`,
        requesterEmail: dataActiveOrder?.activeOrder?.Requester?.email,
        requesterId: dataActiveOrder?.activeOrder?.Requester?.objectId,
        communityName: dataActiveOrder?.activeOrder?.Community?.name,
        communityId: dataActiveOrder?.activeOrder?.Community?.objectId,
        communityType: dataActiveOrder?.activeOrder?.Community?.type, // building, neighbourhood, circle
        requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
        requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
        requestDuration: `${activeOrder?.period} hours`,
        paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
        paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
        paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
        paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
        source: 'chat',
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (activeOrder?.objectId) {
      refetch({where: whereTransactionOrder(activeOrder?.objectId)});
    }
  }, [activeOrder]);

  useEffect(() => {
    setCardErrorPopup(error || null);
  }, [error]);

  // Stripe Connected api
  const params = new URLSearchParams(search);
  const stripeConnectedId = params.get(SearchKey.stripeConnectId);
  const stripeConnectId = localStorage.getItem(SearchKey.stripeConnectId);
  const urlOrderId = params.get(SearchKey.orderId);
  useEffect(() => {
    if (stripeConnectedId && stripeConnectedId === stripeConnectId) {
      analyticsTrackFN('Stripe Connected', {
        name: `${viewer?.firstName} ${viewer?.lastName}`,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
      });
      localStorage.removeItem('stripeConnectId');
    }
  }, [stripeConnectedId]);

  const returnUrl = SITE_URL + getLink(formatSettinsStripeLink(String(new Date().getTime())));

  useEffect(() => {
    getStripeInfo(returnUrl)
      .then((response) => {
        isFee ? setStripeError(!response?.accountConnected) : setStripeError(false);
      })
      .catch(() => {
        setStripeError(true);
      });
  }, [isFee]);
  const handleActiveOrder = (id?: string) => () => {
    setActiveOrder(id);
    editReviewData.setEditReview(false);
    reviewOrderData.change.changeItemReview('');
    reviewOrderData.change.changeUserReview('');
    reviewOrderData.change.changeItemRating(0);
    reviewOrderData.change.changeUserRating(0);
  };
  useEffect(() => {
    if (!activeContact && viewerId === activeOrder?.Requester.objectId) {
      setActiveContact(usetToTcontact(activeOrder?.Lister, userType) || null);
    }
    if (!activeContact && viewerId === activeOrder?.Lister.objectId) {
      setActiveContact(usetToTcontact(activeOrder?.Requester, userType) || null);
    }
  }, [activeOrder?.id]);
  if (!orders?.length && (loading || separateLoading) && !urlOrderId) {
    return <SkeletonLoader card={SkeletonOrderCard} />;
  }

  if (!orders?.length && !loading) {
    return (
      <InnerEmptyCard>
        <EmptyCard
          title={t('messages:emptyState.requests.text1')}
          description={t('messages:emptyState.requests.text2', {name: activeContact?.name || '...'})}
          imageSvg={
            <IconSvg
              stroke={'none'}
              type={'no-requests-light'}
              width={'200px'}
              height={'200px'}
              viewBox="0 0 200 200"
            />
          }
        />
      </InnerEmptyCard>
    );
  }

  if (activeOrder && isItem) {
    return (
      <RequestCard
        handlePushToSupport={handlePushToSupport}
        handlePushToListing={handlePushToListing}
        activeOrder={activeOrder}
        viewerId={viewerId}
        dataManageOrder={dataManageOrder}
        dataInvoice={dataInvoice}
        invoiceLoading={invoiceLoading}
        editOrderData={editOrderData}
        reviewOrderData={reviewOrderData}
        reviewsData={reviewsData}
        editReviewData={editReviewData}
        reviewsDataLoading={reviewsDataLoading}
        orderHelpers={orderHelpers}
        showStripePopup={showStripePopup}
        stripeError={stripeError}
        isModal={isModal}
        onClose={onCloseOrder}
        setIsCanceled={setIsCanceled}
        isCanceled={isCanceled}
      />
    );
  }

  if (activeOrder && isEvent) {
    return (
      <RequestCardEvent
        activeOrder={activeOrder}
        viewerId={viewerId}
        dataManageOrder={dataManageOrder}
        dataInvoice={dataInvoice}
        invoiceLoading={invoiceLoading}
        editOrderData={editOrderData}
        reviewOrderData={reviewOrderData}
        reviewsData={reviewsData}
        editReviewData={editReviewData}
        reviewsDataLoading={reviewsDataLoading}
        orderHelpers={orderHelpers}
        showStripePopup={showStripePopup}
        stripeError={stripeError}
        isModal={isModal}
        onClose={onCloseOrder}
        setIsCanceled={setIsCanceled}
        isCanceled={isCanceled}
      />
    );
  }
  if (activeOrder && isAmenity) {
    return (
      <RequestCardAmenity
        activeOrder={activeOrder}
        viewerId={viewerId}
        dataManageOrder={dataManageOrder}
        dataInvoice={dataInvoice}
        invoiceLoading={invoiceLoading}
        editOrderData={editOrderData}
        reviewOrderData={reviewOrderData}
        reviewsData={reviewsData}
        editReviewData={editReviewData}
        reviewsDataLoading={reviewsDataLoading}
        orderHelpers={orderHelpers}
        showStripePopup={showStripePopup}
        stripeError={stripeError}
        isModal={isModal}
        onClose={onCloseOrder}
        setIsCanceled={setIsCanceled}
        isCanceled={isCanceled}
      />
    );
  }

  return (
    <InnerContacts>
      <InfiniteScroll
        useWindow={false}
        loadMore={fetchData}
        hasMore={hasMore}
        pageStart={-50}
        loader={<SkeletonOrderCard />}>
        {orders?.map((it) => (
          <InnerPreviewOrderCard key={it?.objectId} onClick={handleActiveOrder(it?.objectId)}>
            <RequestMessageSmall
              requestOptions={getTextLabelPreviewCardOrder(it, t, getSignValue)}
              requestStatus={it.status && t('messages:reqStatus', {context: it.status})}
              requestedForLabel={t('messages:requestedFor')}
              messageDate={it?.updatedAt || it?.createdAt}
              itemName={it?.Item?.name || it?.Event?.name || it?.Amenity?.name}
              itemImage={
                it?.Item?.images?.[0]?.file?.url ||
                it?.Event?.images?.[0]?.file?.url ||
                it?.Amenity?.images?.[0]?.file?.url
              }
              bordered={true}
              fullWidth={true}
            />
          </InnerPreviewOrderCard>
        ))}
      </InfiniteScroll>
    </InnerContacts>
  );
};
