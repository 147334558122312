import {gql} from '@apollo/client';
import {GroupFragment, GroupShortFragment} from './fragments/group';
import MemberFragment from './fragments/people';
import {Connection} from './types/parse';
import {Group} from './types/group';
import {
  GQLCreateGroupFieldsInput,
  GQLGroupOrder,
  GQLGroupWhereInput,
  GQLUpdateGroupFieldsInput,
  GQLUserWhereInput,
} from '../graphql.schema';

export interface GetGroupRes {
  group: Group;
}

export interface GetGroupsLinkRes {
  groups: Connection<Group>;
}

export interface GetGroupParams {
  id: string;
}

export const GetGroupQuery = gql`
  query getGroup($id: ID!) {
    group(id: $id) {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

export const GetGroupLinksQuery = gql`
  query getGroupLinks($where: GroupWhereInput!) {
    groups(where: $where) {
      edges {
        node {
          alias
          objectId
        }
      }
    }
  }
`;

export interface GetGroupsRes {
  groups: Connection<Group>;
}

export interface GetGroupsParams {
  where?: GQLGroupWhereInput;
  order?: Array<GQLGroupOrder>;
}

export const GetGroupsQuery = gql`
  query GetGroups($where: GroupWhereInput, $order: [GroupOrder!]) {
    groups(where: $where, order: $order) {
      count
      edges {
        node {
          ...GroupShortFragment
        }
      }
    }
  }
  ${GroupShortFragment}
`;

export interface AddGroupsRes {
  createGroup: Group;
}

export interface AddGroupsParams {
  fields: GQLCreateGroupFieldsInput;
}

export interface UpdateGroupRes {
  group: Group;
}

export interface UpdateGroupParams {
  id: string;
  fields: GQLUpdateGroupFieldsInput;
}

export const UpdateGroupQuery = gql`
  mutation updateGroup($id: ID!, $fields: UpdateGroupFieldsInput) {
    updateGroup(input: {id: $id, fields: $fields}) {
      group {
        ...GroupFragment
      }
    }
  }
  ${GroupFragment}
`;

export interface GroupActionsManagerResponseType {
  groupManageButtons: boolean;
}

export enum GroupManageAction {
  feature = 'feature',
  unfeature = 'unfeature',
}

export interface GroupActionsManagerRequestType {
  groupId: string;
  communityIdList: string[];
  action: GroupManageAction;
}

export const ActionsManagerQueryGroup = gql`
  mutation groupManageButtons($groupId: String!, $communityIdList: [String]!, $action: GroupManageAction!) {
    groupManageButtons(input: {groupId: $groupId, communityIdList: $communityIdList, action: $action})
  }
`;

export const AddGroupQuery = gql`
  mutation addGroup($fields: CreateGroupFieldsInput!) {
    createGroup(input: {fields: $fields}) {
      group {
        ...GroupFragment
      }
    }
  }
  ${GroupFragment}
`;

export const GetJoinedGroupsQuery = gql`
  query GetGroups($where: GroupWhereInput, $order: [GroupOrder!]) {
    groups(where: $where, order: $order) {
      count
      edges {
        node {
          objectId
          name
        }
      }
    }
  }
`;

export interface DeleteGroupRes {
  group: Group;
}

export interface DeleteGroupParams {
  id: string;
}

export const DeleteGroupQuery = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(input: {id: $id}) {
      group {
        objectId
      }
    }
  }
`;

export interface LeaveGroupRes {
  success?: boolean;
}

export interface LeaveGroupParams {
  groupId: string;
}

export const LeaveGroupQuery = gql`
  mutation leaveGroup($groupId: String!) {
    leaveGroup(input: {groupId: $groupId}) {
      success
    }
  }
`;

export interface JoinGroupRes {
  success: boolean;
}

export interface JoinGroupParams {
  groupId: string;
}

export const JoinGroupQuery = gql`
  mutation joinGroup($groupId: String!) {
    joinGroup(input: {groupId: $groupId}) {
      success
    }
  }
`;

export interface GetGroupMembersParams {
  where?: GQLGroupWhereInput;
  membersWhere?: GQLUserWhereInput;
  first?: number;
  order?: Array<GQLGroupOrder>;
}

export const GetGroupMembersQuery = gql`
  query GetGroups($where: GroupWhereInput, $membersWhere: UserWhereInput, $first: Int, $order: [GroupOrder!]) {
    groups(where: $where, order: $order) {
      count
      edges {
        node {
          objectId
          name
          Members(where: $membersWhere, first: $first) {
            ... on UserConnection {
              count
              edges {
                node {
                  ...MemberFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${MemberFragment}
`;
