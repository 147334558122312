import React, {useState} from 'react';
import {
  SelectLabel,
  Title,
  WrapperForm,
  ListingForm,
  InnerItemsGenerate,
  AIPromotionWrapper,
  TextWrapperPromotion,
  MobFocused,
  InnerItemsGrid,
  CloseButton,
  ImageWrapper,
  DeleteImageButton,
  NextImageButton,
} from './styles';
import {GoBack} from '../common/Buttons/GoBack';
import {useTranslation} from 'react-i18next';
import {NavContainer} from '../../containers/Navigation';
import {ItemFields} from '../../types/item';
import {generateState, TResCreateItem, useItemFormData} from '../../hooks/item';
import {Select} from '../../ui-kit/Form/Select';
import {LabelValuePair} from '../../types/settings';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Text} from '../../ui-kit/Typography/Text';
import {Input} from '../../ui-kit/Form/Input';
import {isDesktop} from 'react-device-detect';
import {RichTextEditor} from '../common/RichText/Editor';

import {NumberFormatInput} from '../../ui-kit/Form/Input/NumberFormatInput';
import {NumberFormatValues} from 'react-number-format';
import {SelectOption} from '../../types/common';
import {PricePeriodType} from '../../queries/types/item';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {GenerateButton} from '../../ui-kit/Button/GenerateButton';

type TOnChange = (next: {name: string; value: string}) => void;

interface Props {
  data: TResCreateItem;
  generateState: generateState;
  generate: (value: boolean) => void;
  generateLoading: boolean;
  imageLoading: boolean;
  currency?: {code?: string; sign?: string};
  redirect: () => void;
  deleteImages: () => void;
  nextImage: () => void;
  submit: (e: React.SyntheticEvent) => void;
  goBack: () => void;
}

export const GenerateListing: React.FC<Props> = ({
  data,
  generateState,
  generate,
  generateLoading,
  imageLoading,
  currency,
  redirect,
  deleteImages,
  nextImage,
  submit,
  goBack,
}) => {
  const {t} = useTranslation();
  const {values, error, onChange, loading} = data;
  const {suggestions, listing} = generateState;
  const {pricePeriodOptionsService} = useItemFormData();
  const [changed, setChanged] = useState(false);
  const handleGenerate = () => {
    setChanged(false);
    generate(changed);
  };
  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (target.name === ItemFields.name) setChanged(true);
    onChange({name: target.name, value: target.value});
  };
  const selectHandleKeyDown = (value: string) => {
    onChange({name: ItemFields.name, value: value});
  };
  const handleChangeDropdown = () => (select: LabelValuePair[]) => {
    onChange({name: ItemFields.name, value: select?.[0]?.value || ''});
  };
  const handleSelect = (name: string) => (data: SelectOption<PricePeriodType>[]) => {
    onChange({name: name, value: data[0]?.value || ''});
  };
  const handleNumberChange = (name: ItemFields) => (values: NumberFormatValues) => {
    return onChange({name: name, value: values.floatValue});
  };

  const labelValue = [{label: values?.name, value: values?.name}];

  return (
    <NavContainer>
      <WrapperForm>
        <Title $hideOnMob>
          {listing?.price ? t('listings:generateListing.review') : t('listings:generateListing.title')}
        </Title>
        <GoBack
          goBack={goBack}
          title={listing?.price ? t('listings:generateListing.review') : t('listings:generateListing.title')}
        />
        {Boolean(listing?.price) && (
          <CloseButton onClick={redirect}>
            <IconSvg type={'close'} width={'24px'} height={'24px'} viewBox={'-1 -1 22 22'} />
          </CloseButton>
        )}
        <ListingForm onSubmit={submit}>
          {listing?.price ? (
            <InnerItemsGenerate>
              <AIPromotionWrapper>
                <IconSvg className={'earn'} type={'earn'} width={'24px'} height={'24px'} viewBox={'0 0 22 22'} />
                <TextWrapperPromotion>
                  <Text size={16} color={'tertiary'}>
                    {t('listings:generateListing.earn', {
                      sum: `${currency?.sign || ''}${listing?.price * 2 * 4 * 12}`,
                      currency: currency?.code,
                    })}
                  </Text>
                  <Text color={'tertiary'} size={12}>
                    {t('listings:generateListing.based')}
                  </Text>
                </TextWrapperPromotion>
              </AIPromotionWrapper>
              {Boolean(generateState?.listing?.images !== null && generateState?.listing?.images?.length) && (
                <ImageWrapper>
                  <DeleteImageButton onClick={deleteImages}>
                    <IconSvg type={'close'} width={'24px'} height={'24px'} viewBox={'-1 -2 22 22'} />
                  </DeleteImageButton>
                  <NextImageButton onClick={nextImage}>
                    {imageLoading ? (
                      <IconSvg
                        className={'loading'}
                        type={'loading'}
                        width={'24px'}
                        height={'24px'}
                        viewBox={'-5 -5 22 22'}
                      />
                    ) : (
                      <IconSvg type={'reload'} width={'24px'} height={'24px'} viewBox={'-5 -5 22 22'} />
                    )}
                  </NextImageButton>
                  <PhotoCard className={'image'} height={'310px'} width={'100%'} src={listing?.images?.[0]} />
                </ImageWrapper>
              )}
              <Input
                $isError={Boolean(error.name)}
                name={ItemFields.name}
                value={values.name || ''}
                onChange={handleChange}
                label={t('forms:itemName')}
                autoFocus={true}
                icon={
                  <GenerateButton
                    loading={Boolean(generateLoading)}
                    initText={t('auth:auth.buttons.generate')}
                    className={'generate'}
                    onClick={handleGenerate}
                    count={changed ? 1 : 5}
                  />
                }
              />
              <MobFocused $isDesktop={isDesktop}>
                <RichTextEditor
                  $isError={Boolean(error.descr)}
                  label={t('forms:descr')}
                  name={ItemFields.descr}
                  value={values?.descr}
                  onChange={onChange as TOnChange}
                  rows={6}
                  disableAutoFocus={true}
                  newState={values?.descr}
                />
              </MobFocused>
              <InnerItemsGrid $smallDD={true}>
                <NumberFormatInput
                  $isError={Boolean(error.price)}
                  name={ItemFields.price}
                  value={values.price}
                  onValueChange={handleNumberChange(ItemFields.price)}
                  label={t('forms:rate')}
                  libPrefix={currency?.sign}
                  allowNegative={false}
                  decimalScale={2}
                  decimalSeparator={'.'}
                  thousandSeparator={','}
                />
                <Select
                  $isError={Boolean(error.pricePeriod)}
                  width={'100%'}
                  name={ItemFields.pricePeriod}
                  label={t('forms:per')}
                  values={pricePeriodOptionsService?.filter((it) => it.value === values.pricePeriod)}
                  options={pricePeriodOptionsService}
                  onChange={handleSelect(ItemFields.pricePeriod)}
                />
              </InnerItemsGrid>
              <Button loading={loading} type={'submit'}>
                {t('listings:generateListing.buttons.add')}
              </Button>
            </InnerItemsGenerate>
          ) : (
            <InnerItemsGenerate>
              <SelectLabel>{t('listings:generateListing.descr')}</SelectLabel>
              <Select
                $isError={Boolean(error.name)}
                values={labelValue}
                options={suggestions}
                onChange={handleChangeDropdown()}
                label={t('forms:itemName')}
                name={ItemFields.name}
                shouldCreate={true}
                onKeyDown={selectHandleKeyDown}
              />
              {generateLoading ? (
                <Button loading={true}>{t('listings:generateListing.buttons.generating')}</Button>
              ) : (
                <Button onClick={generate} loading={false}>
                  <IconSvg
                    className={'stars'}
                    type={'stars'}
                    fill={'white'}
                    stroke={'white'}
                    width={'40px'}
                    height={'16px'}
                    viewBox={'0 -1 18 20'}
                  />
                  {t('listings:generateListing.buttons.generate')}
                </Button>
              )}
            </InnerItemsGenerate>
          )}
        </ListingForm>
      </WrapperForm>
    </NavContainer>
  );
};
