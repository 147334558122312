import styled from 'styled-components';
import {Button} from '../../../ui-kit/Button/Button';
import {LinkButton} from '../../../ui-kit/Button/LinkButton';
import {Text as UiText} from '../../../ui-kit/Typography/Text';
import {Media} from '../../../ui-kit/theme';
import {Link} from '../../../ui-kit/Typography/Link';

export type GridProps = {
  maxWidth?: number;
  minWidth?: number;
  columnGap?: number;
  rowGap?: number;
  isDragging?: boolean;
};

const minWidth = ({minWidth}: GridProps) => (minWidth ?? 300) + 'px';
const maxWidth = ({maxWidth}: GridProps) => (maxWidth ?? 310) + 'px' || '1fr';
const columnGap = ({columnGap}: GridProps) => (columnGap ? columnGap : 12) + 'px';
const rowGap = ({rowGap}: GridProps) => (rowGap ? rowGap : 12) + 'px';

export const HangehLogoWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 10px;
`;

export const WrapperSearch = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 975px;
  & .search-block__solo {
    margin-right: 50px;
  }
`;

export const CardsGrid = styled.div<GridProps>`
  max-width: calc(100vw - 20px);
  width: 100%;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${minWidth}, ${maxWidth}));
  grid-row-gap: ${rowGap};
  grid-column-gap: ${columnGap};
  justify-content: center;
  cursor: ${({isDragging}) => (isDragging ? 'grabbing' : 'grab')};
  ${Media.down.m} {
    margin-top: 9px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 95%));
  }
`;

export const InnerCard = styled(Link)`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  background: ${({theme}) => theme.palette.background.primary};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 19px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  & > * {
    width: 100%;
  }
  .button-value {
    font-size: 14px;
  }
`;

export const InnerTitle = styled.div<{$isPrivate?: boolean}>`
  position: relative;
  padding-right: ${({$isPrivate}) => ($isPrivate ? '25px' : '0')};
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InnerIcon = styled.span<{$isPrivate?: boolean}>`
  display: ${({$isPrivate}) => ($isPrivate ? 'inline-block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
`;

export const Title = styled(UiText).attrs({
  variant: 'primary',
  weight: 'medium',
})`
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 18px;
  line-height: 150%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Address = styled(UiText).attrs({
  variant: 'primary',
  weight: 'regular',
  as: 'div',
})`
  color: ${({theme}) => theme.palette.text.septenary};
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
`;

export const Description = styled(Address)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
`;

export const Members = styled(Address)`
  margin-right: 10px;
  font-size: 12px;
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;
`;

export const JoinButton = styled(LinkButton).attrs({
  ghost: true,
  height: '26px',
  variant: 'secondary',
})<{
  pending?: boolean;
}>`
  width: max-content;
  padding: 2px 14px;
  border-width: 0px;
  background-color: ${({pending, theme}) =>
    pending ? theme.palette.background.quinary : theme.palette.background.secondary};
  .button-value {
    font-size: 14px;
    color: ${({pending, theme}) => (pending ? theme.palette.text.primary : theme.palette.background.quindecimal)};
    font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
  }
`;

export const JoinButtonNotLink = styled(Button).attrs({
  ghost: true,
  height: '26px',
  variant: 'secondary',
})<{
  pending?: boolean;
}>`
  width: min-content;
  padding: 2px 8px;
  border-width: 0px;
  background-color: ${({pending, theme}) =>
    pending ? theme.palette.background.quinary : theme.palette.background.secondary};
  .button-value {
    font-size: 14px;
    color: ${({pending, theme}) => (pending ? theme.palette.text.primary : theme.palette.background.quindecimal)};
    font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
  }
`;

export const JoinButtonNew = styled(Button).attrs({
  ghost: true,
  width: '52px',
  height: '22px',
  variant: 'secondary',
})`
  .button-value {
    font-size: 12px;
    font-family: ${({theme}) => theme.font.tertiary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.tertiary.regular.fontWeight};
  }
`;

export const SceletonFlex = styled.div<GridProps>`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(100vw - 20px);
  & > * {
    width: 300px;
    margin-bottom: 23px;
  }
`;

export const BtnCannotFind = styled(Button).attrs({
  ghost: true,
  variant: 'secondary',
  width: '254px',
})`
  margin-top: 86px;
  margin-bottom: 112px;
  border-radius: 25px;
  & .button-value {
    margin: 0;
    font-size: 14px;
    font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    color: ${({theme}) => theme.palette.text.octonary};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media.down.s} {
    width: 100%;
  }
`;
