import {toStateCommunity} from './community';
import {getResize} from './file';
import {toStateItem} from './item';
import {GetOrderReviewResType} from '../queries/reviews';
import {TCommunity} from '../types/community';
import {ReviewsPair, TReview} from '../types/reviews';
import {ReviewT} from '../ui-kit/RequestCard/types';
import {Review} from '../queries/types/review';

export const reviewsToState = (data?: GetOrderReviewResType): TReview[] | undefined => {
  return data?.reviews?.edges?.map((edge) => ({
    ...edge.node,
    Item: toStateItem(edge?.node?.Item),
    // Community: toStateCommunity(edge?.node?.Community) as TCommunity, ???
    Community: toStateCommunity(edge?.node?.Community) as unknown as TCommunity,
  }));
};

export const formatReadyReviewData = (review: TReview): ReviewT => {
  return {
    id: review?.id,
    objectId: review?.objectId,
    User: {
      avatar: getResize(review.User?.Avatar?.file?.url, 'lg') || '',
      firstName: review.User?.firstName || '',
      lastName: review.User?.lastName || '',
    },
    stars: review.stars,
    subject: review.subject,
    Item: review.Item,
    text: review.text,
  };
};

export const reviewsToReceivedAndGiven = (
  data?: GetOrderReviewResType,
  userId?: string,
  onlineUsers?: string[],
): ReviewsPair => {
  const stateData = reviewsToState(data);
  const Data = stateData?.map((el) => ({
    ...el,
    Author: {...el.Author, isOnline: !!el.Author?.objectId && onlineUsers?.includes(el.Author?.objectId)},
  })) as TReview[] | undefined;

  return {
    received: Data?.filter((r) => r?.Author?.objectId !== userId && r?.Author?.id !== userId) ?? [],
    given: Data?.filter((r) => r?.Author?.objectId === userId || r?.Author?.id === userId) ?? [],
  };
};

export const getAvarageReviewsStars = (reviews?: ReviewsPair) => {
  const starsArray = reviews?.received && reviews?.received?.map((el) => el.stars || 0);
  const ratingAverage =
    starsArray && !!starsArray?.length ? starsArray.reduce((a, b) => a + b) / starsArray.length : undefined;
  return ratingAverage ? Number(ratingAverage.toFixed(1)) : undefined;
};

export type Edge<Review> = {
  cursor: string;
  node: Review;
};

export const getAvarageReviewsListingStars = (reviews?: Edge<Review>[]) => {
  const starsArray = reviews && reviews?.map((el) => el.node.stars || 0);
  const ratingAverage =
    starsArray && !!starsArray?.length ? starsArray.reduce((a, b) => a + b) / starsArray.length : undefined;
  return ratingAverage ? Number(ratingAverage.toFixed(1)) : undefined;
};
