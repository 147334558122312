import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAmenityPageContent} from '../../../hooks/amenity';
import {GQLAmenityWhereInput} from '../../../graphql.schema';
import {PageResident} from '../../../components/Amenities/PageResident';
import {CommunityStateType, userPermissions} from '../../../states/community';
import {entities, useReport} from '../../../hooks/report';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {useRecoilState} from 'recoil';
import {PermissionsList} from '../../../types/settings';
import {TypeCommunity} from '../../../types/auth';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
  typeUser?: TypeCommunity;
};
export const PageResidentContainer: React.FC<TProps> = ({viewerId, community, typeUser}) => {
  const [searchParams, setSearchParams] = useState<GQLAmenityWhereInput[]>([]);
  const [permissions] = useRecoilState(userPermissions);

  const location = useLocation();
  const {
    loadingAllAmenities,
    loadingFeaturedAmenities,
    refetchAllAmenities,
    refetchFeaturedAmenities,
    hasMoreAllAmenities,
    fetchDataAllAmenities,
    communityAmenities,
  } = useAmenityPageContent(searchParams, community);
  const {getSignValue} = useGetCurrencyValue();

  const actions = useReport(entities.listing);

  useEffect(() => {
    refetchFeaturedAmenities();
    refetchAllAmenities();
  }, [location]);
  const canAdd = useMemo(
    () => permissions.includes(PermissionsList.manageAmenity) && typeUser === TypeCommunity.admin,
    [permissions, typeUser],
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityAmenities.length && refetchFeaturedAmenities({first: communityAmenities.length});
  //     communityAmenities.length && refetchAllAmenities({first: communityAmenities.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityAmenities.length]);
  return (
    <PageResident
      community={community}
      loading={loadingAllAmenities || loadingFeaturedAmenities}
      allAmenities={communityAmenities}
      fetchDataAllAmenities={fetchDataAllAmenities}
      hasMoreAllAmenities={hasMoreAllAmenities}
      setSearchParams={setSearchParams}
      userId={viewerId}
      reportActions={actions}
      getSignValue={getSignValue}
      canAdd={canAdd}
    />
  );
};
