import React, {useMemo} from 'react';
import {CardWrapper, CheckboxWrapper, InfoWrapper, PersonName} from '../People/styles';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {Label} from '../../ui-kit/Labels';
import {CommunityStateType} from '../../states/community';
import {useTranslation} from 'react-i18next';
import {CommunityCardLink, CommunityPendingLabelWrapper} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';

interface CommunityLabelProps {
  community: CommunityStateType;
  icon: JSX.Element;
  count?: number;
  needConfirmation?: boolean;
  isUnmanaged?: boolean;
  isCheckboxMode?: boolean;
  isChecked?: boolean;
  isUserManager?: boolean;
  onChange?: (checked?: boolean) => void;
  isPublished?: boolean;
  userId?: string;
}

export const CommunityLabel: React.FC<CommunityLabelProps> = (props) => {
  const {community, isUnmanaged, isChecked, onChange, isCheckboxMode, isUserManager, isPublished, userId} = props;
  const {t} = useTranslation();
  const userLabel = useMemo(() => community?.Admins?.find((el) => el?.User?.objectId === userId)?.label, [community]);
  const isAdmin = useMemo(
    () => !!community?.Admins?.find((el) => el?.User?.objectId === userId)?.objectId,
    [community],
  );

  const handleChange = () => {
    onChange?.(!isChecked);
  };
  return (
    <CommunityCardLink $isChecked={isChecked}>
      <CardWrapper>
        <Avatar src={community?.avatar} size={'sm'} $isOnline={false} />
        <InfoWrapper>
          <PersonName $isCommunity>{community?.name}</PersonName>
          {/*<PersonLabels>*/}
          {/*  {icon}*/}
          {/*  {count}*/}
          {/*</PersonLabels>*/}
          {isCheckboxMode && (
            <CommunityPendingLabelWrapper>
              <>
                {userLabel ? (
                  <Label squared variant={'quaternary'}>
                    {userLabel}
                  </Label>
                ) : (
                  <>
                    {isUserManager ? (
                      <Label squared variant={'quaternary'}>
                        {t('common:typeCommunity.admin')}
                      </Label>
                    ) : (
                      <>
                        {isAdmin ? (
                          <Label squared variant={'quinary'}>
                            {t('common:typeCommunity.manager')}
                          </Label>
                        ) : (
                          <Label squared variant={'quinary'}>
                            {t('common:typeCommunity.resident')}
                          </Label>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </CommunityPendingLabelWrapper>
          )}
          {isPublished && (
            <CommunityPendingLabelWrapper>
              <Label squared={true} variant={'quinary'}>
                {isUnmanaged ? t('events:created.published') : t('events:created.pending')}
              </Label>
            </CommunityPendingLabelWrapper>
          )}
        </InfoWrapper>
      </CardWrapper>
      {isCheckboxMode && onChange && (
        <CheckboxWrapper onClick={handleChange}>
          <IconSvg
            type={isChecked ? 'checked-circle' : 'not-checked-circle'}
            stroke={isChecked ? 'white' : 'gray'}
            fill={isChecked ? 'aqua' : 'white'}
            width={'25px'}
            height={'25px'}
            viewBox={'0 0 30 30'}
          />
        </CheckboxWrapper>
      )}
    </CommunityCardLink>
  );
};
