import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {KeyCodes} from '../../../../constants/common';
import {route} from '../../../../constants/routes';
import {correctTranslate, getCommunityLink} from '../../../../helpers/common';
import {validateEmail} from '../../../../helpers/validations';
import {CommunityField} from '../../../../types/community';
import {Button} from '../../../../ui-kit/Button/Button';
import {LinkButton} from '../../../../ui-kit/Button/LinkButton';
import {SimpleTagInput} from '../../../../ui-kit/Form';
import {Input} from '../../../../ui-kit/Form/Input';
import {TextArea} from '../../../../ui-kit/Form/TextArea/TextArea';
import {Br} from '../../../../ui-kit/Typography/Br';
import {Link} from '../../../../ui-kit/Typography/Link';
import {Text} from '../../../../ui-kit/Typography/Text';
import {MemoCopyButton} from '../../../common/Buttons/Copy';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  ButtonArea,
  ButtonsWrapper,
  CopyIconWrapper,
  ErrorMessage,
  GreetingTitle,
  InnerAlias,
  ProfileForm,
  SubscribeText,
  Subtitle,
  SuccessIcon,
  SuccessMessageWrapper,
  UnderlinedText,
  WrapperForm,
} from '../styles';

import {sendEmailType, TInviteReturn} from '../../../../hooks/community';
import {CommunityStateType} from '../../../../states/community';

interface IProps {
  params: TInviteReturn;
  sendEmail?: sendEmailType;
  community: CommunityStateType;
}

export const FormInviteCommunity: React.FC<IProps> = ({params, sendEmail, community}) => {
  const {t} = useTranslation();
  const [emails, setEmails] = useState<Array<string>>([]);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [sendingInvites, setSendingState] = useState(false);
  const [countInvited, setCountInvited] = useState(0);
  const [localErrorMsg, setErrorMsg] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {values, onChange, onSubmit, error, loading} = params;
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({name: target.name, value: target.value});
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const clearCountInvited = () => {
    setCountInvited(0);
  };

  const handleSendInvitation = () => {
    // timeout need because at TagInput must work out onBlur event (it need that input value add to emails list on blur)
    return setTimeout(async () => {
      try {
        setErrorMsg('');

        if (!emails.length) throw t('community:invite.errorMessages.noEmails');
        if (!values.invitation || !sendEmail) return;
        if (emailHasError) throw t('community:invite.errorMessages.notValidEmails');

        setSendingState(true);

        const res = await Promise.all(emails.map((email) => sendEmail(email, values.invitation || '')));
        res.forEach((el, index) => {
          const email = emails?.[index] || '';
          if (!el) throw t('community:invite.errorMessages.exist', {email});
        });
        setCountInvited(emails.length);
        await onSubmit();
      } catch (e) {
        typeof e === 'string' && setErrorMsg(e);
        console.log('Error', e);
      } finally {
        setSendingState(false);
      }
    });
  };

  useEffect(() => {
    setErrorMsg('');
  }, [emails]);
  const link = getCommunityLink(values.alias);
  if (!link) return <LottieLoader $isVisible={true} allScreen={true} />;
  return (
    <>
      <WrapperForm paddingX={'20px'}>
        <GreetingTitle>{t('community:invite.title')}</GreetingTitle>
        <SubscribeText>{t('community:invite.descr.text')}</SubscribeText>
        <ProfileForm name={'invite-community'} onSubmit={handleSubmit}>
          <InnerAlias>
            <Input
              name={CommunityField.alias}
              value={link}
              onChange={() => ''}
              label={t('forms:invitationLink')}
              icon={<MemoCopyButton copiedValue={getCommunityLink(values.alias)} />}
              disabled={true}
            />
            <Br indent={4} />
            <Subtitle>{t('community:invite.hint')}</Subtitle>
          </InnerAlias>
          <InnerAlias>
            <Input
              name={CommunityField.id}
              value={community?.passCode}
              onChange={() => ''}
              label={t('forms:privateCode')}
              icon={<MemoCopyButton copiedValue={community?.passCode} />}
              disabled={true}
            />
            <Br indent={4} />
            <Subtitle>{t('community:invite.hint2')}</Subtitle>
          </InnerAlias>
          {countInvited ? (
            <>
              <SuccessMessageWrapper>
                <LottieLoader $isVisible={loading} />
                <SuccessIcon />
                <Br indent={9} />
                <Text size={16} color={'septenary'}>
                  {t('community:invite.successMessage', {count: countInvited})}
                </Text>
              </SuccessMessageWrapper>
              <ButtonsWrapper>
                <LinkButton to={route.loop.path}>{t('community:invite.buttonTertiary')}</LinkButton>
                <Br indent={25} />
                <ButtonArea onClick={clearCountInvited}>
                  <UnderlinedText>{t('community:invite.buttonQuaternary')}</UnderlinedText>
                </ButtonArea>
              </ButtonsWrapper>
            </>
          ) : (
            <>
              <InnerAlias>
                <TextArea
                  $isError={Boolean(error.invitation)}
                  name={CommunityField.invitation}
                  value={values.invitation}
                  onChange={handleChange}
                  label={t('forms:invitation')}
                  rows={30}
                />
                <CopyIconWrapper>
                  <MemoCopyButton copiedValue={values.invitation} />
                </CopyIconWrapper>
              </InnerAlias>
              <InnerAlias>
                <SimpleTagInput
                  values={emails}
                  onChange={setEmails}
                  validator={validateEmail}
                  label={t('forms:invitationEmails')}
                  setHasError={setEmailHasError}
                  delimiters={[KeyCodes.comma, KeyCodes.enter, KeyCodes.space]}
                />
              </InnerAlias>
              <ErrorMessage open={!!error?.message || !!localErrorMsg}>{errorMsg || localErrorMsg}</ErrorMessage>
              <ButtonsWrapper>
                <Button onClick={handleSendInvitation} loading={sendingInvites}>
                  {t('community:invite.buttonFirst')}
                </Button>
                <Br indent={25} />
                <Link to={route.loop.path}>
                  <UnderlinedText>{t('community:invite.buttonSecond')}</UnderlinedText>
                </Link>
              </ButtonsWrapper>
            </>
          )}
        </ProfileForm>
      </WrapperForm>
    </>
  );
};
