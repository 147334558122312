import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {FileInput} from '../../ui-kit/Form/ImageLoader/FileInput';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {getFileFormatByMime} from '../../helpers/documents';
import {isFile} from '../../helpers/validations';
import {TDocumentFormat} from '../../types/document';
import {formats, FormMode, TDocumentMime} from './types';
import {DocShape} from './DocShape';
import {FileLoaderWrapper} from './styles';

interface IProps {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  docFormat?: TDocumentFormat;
  docFile?: File | null;
  mode: FormMode;
}

const getLabel = (params: {docFormat?: TDocumentFormat; docFile?: File | null}) => {
  const {docFile, docFormat} = params;

  const format = isFile(docFile) ? getFileFormatByMime(docFile.type as TDocumentMime) : docFormat;

  const Label: React.FC = ({children}) => {
    const CoverElement = format ? (
      <DocShape type={format} />
    ) : (
      <IconSvg type={'file'} width={'50px'} height={'50px'} viewBox={'0 0 50 50'} stroke={'grayLight-2'} />
    );

    return (
      <PhotoCard height={'95px'} bordered CoverElement={CoverElement}>
        {children}
      </PhotoCard>
    );
  };
  return Label;
};

export const DocInput: React.FC<IProps> = ({handleChange, docFormat, docFile, mode}) => {
  const {t} = useTranslation();

  const Label = useMemo(() => {
    return getLabel({docFile, docFormat});
  }, [docFile, docFormat]);

  return (
    <FileLoaderWrapper>
      <Text size={18} color={'octonary'}>
        {t('documents:modalForm.titles.file')}
      </Text>
      <Br indent={9} />
      <FileInput label={Label} accept={formats.join(', ')} handleChange={handleChange} />
      <Br indent={5} />
      <Text size={12} color={'octonary'}>
        {t('documents:modalForm.labels.file', {context: mode})}
      </Text>
    </FileLoaderWrapper>
  );
};
