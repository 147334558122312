import React from 'react';
import {Wrapper, Input, Text} from './styles';
import {SwitchProps} from './types';

import {SwitchBase} from './SwitchBase';

export const Switch: React.FC<SwitchProps> = ({
  className,
  variant,
  textUnchecked,
  textChecked,
  onChange,
  checked,
  ...props
}) => {
  return (
    <SwitchBase
      element={Wrapper}
      className={className}
      onChange={onChange}
      checked={checked}
      variant={variant}
      {...props}>
      <Text checked={checked} variant={variant}>
        {textChecked}
      </Text>
      <Text checked={!checked} variant={variant}>
        {textUnchecked}
      </Text>
      <Input type="checkbox" />
    </SwitchBase>
  );
};
