import React, {useMemo} from 'react';
import {SetEventsSearchParams} from '../../containers/Events/types';
import {useTranslation} from 'react-i18next';
import {MobileSearchBlock} from '../common/SearchBlock/MobileSearchBlock';
import {
  getEventTypeParams,
  getSearchEventOptions,
  getShowEventsParams,
  isAdditionalEventOption,
} from '../../helpers/event';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';
import {SearchKey} from '../../constants/routes';
import {useHistory} from 'react-router-dom';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface TProps {
  setSearchParams?: SetEventsSearchParams;
}

export const MobileSearch: React.FC<TProps> = ({setSearchParams}) => {
  const {t} = useTranslation();
  const eventsOptions = getSearchEventOptions(t);
  const searchManager = useUrlSearchManager();
  const {push} = useHistory();
  const selectedOption = useMemo(
    () => eventsOptions.filter((el) => el.value === searchManager.get(SearchKey.type))?.[0],
    [eventsOptions, searchManager],
  );
  const onSearch = (searchText?: string, time?: SelectOptions) => {
    const searchParams = isAdditionalEventOption(time?.value) ? getShowEventsParams() : getEventTypeParams(time?.value);
    if (time?.value) push(searchManager.set(SearchKey.type, time.value).path);
    setSearchParams?.([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        ...searchParams,
      },
    ]);
  };

  return (
    <MobileSearchBlock
      placeholder={t('search:labels.whatLooking')}
      options={eventsOptions}
      selectedOption={selectedOption}
      onSearch={onSearch}
    />
  );
};
