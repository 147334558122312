import {useMutation, useQuery} from '@apollo/client';
import {
  CommunityPostsCountResponse,
  CommunityPostsResponse,
  createPost,
  CreatePostRequestType,
  CreatePostResponseType,
  deleteCommunityPost,
  deletePostReqType,
  deletePostResType,
  GetCommunityPostsPollInfoQuery,
  GetCommunityPostsQuery,
  getPostComments,
  GetPostsCount,
  HidePost,
  hidePostResType,
  isHasPost,
  likeCommunityPost,
  likeCommunityPostReqType,
  likeCommunityPostRespType,
  PinUnpinPost,
  PinUnpinVariables,
  PostCommentsResponse,
  ReactionCommunityCommunityPost,
  ReactionCommunityPostReqType,
  removeVoteInPoll,
  RemoveVoteInPollReqType,
  TUpdatePollOptionReq,
  TUpdatePollOptionRes,
  TUpdatePostReq,
  TUpdatePostRes,
  UpdatePollOptionQuery,
  UpdatePost,
  UpdatePostQuery,
  UpdatePostRequest,
  UpdatePostResponse,
  voteInPoll,
  VoteInPollReqType,
} from '../queries/feed';
import {GQLPollOptionOrder, GQLPostOrder, GQLPostWhereInput} from '../graphql.schema';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {currentCommunity, onlineUsers} from '../states/community';
import {
  createSearchParams,
  formatLocalFeedArray,
  getPostOptions,
  removeLocal,
  toPointerCreatePost,
  toStatePostComments,
  toStatePosts,
} from '../helpers/feed';
import {PostActions, PostFormValue, PostFormValues, PostTypeT, TPollOption, TPost} from '../types/post';
import {useViewer, useViewerId} from './user';
import useMutableState from './useMutableState';
import {useCallback, useEffect, useRef, useState} from 'react';
import {FormErrorFields, useFormError} from './error';
import {CreateAppFile, CreateFileResponseType} from '../queries/file';
import {FormValidation} from '../helpers/FormValidation';
import {changeFileName} from '../helpers/file';
import {PointerFile, imageType} from '../types/common';
import {isFile} from '../helpers/validations';
import {MediumTypes, ReactionTypes} from '../queries/types/post';
import Parse from 'parse';
import {toPOJO, useLiveQuery} from './parse';
import {typeUser as StateTypeUser} from '../states/typeUser';
import {TypeCommunity} from '../types/auth';
import {isModal} from '../states/themeMode';
import {analyticsTrackFN} from '../helpers/account';
import {MemberT} from '../types/people';
import {useTranslation} from 'react-i18next';
import {entities, useReport} from './report';
import {LabelValuePair} from '../types/settings';
import {TCommunity} from '../types/community';
import {useGetAdminsLabels} from './people';
import {toLocalPostState} from './post';
import {toLocalEditedState} from '../helpers/post';
import {LikeState, useItemLike} from './item';
import {toPointer} from '../helpers/parse';
// import {useGetMessages} from './message';

export const useGetCommunityPosts = (options: {
  isManager?: boolean;
  skip?: boolean;
  where?: GQLPostWhereInput;
  order?: GQLPostOrder | Array<GQLPostOrder>;
  optionsOrder?: GQLPollOptionOrder;
  first?: number;
  userId?: string;
}) => {
  const onlineIds = useRecoilValue(onlineUsers);
  const [community] = useRecoilState(currentCommunity);
  const {data, ...response} = useQuery<CommunityPostsResponse>(GetCommunityPostsQuery(false), {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.optionsOrder ? {optionsOrder: options.optionsOrder} : {}),
      ...(options?.first ? {first: options?.first} : {}),
      ...(options?.skip ? {skip: options?.skip} : {}),
      userId: options?.userId,
    },
    skip: !community?.objectId,
    ssr: false,
  });
  const items: TPost[] | undefined = toStatePosts(data?.posts, onlineIds);
  return {...response, data: items || [], otherData: {...data}};
};

export const useGetPost = (options: {
  postId?: string;
  where?: GQLPostWhereInput;
  order?: GQLPostOrder;
  optionsOrder?: GQLPollOptionOrder;
  userId?: string;
}) => {
  const [community] = useRecoilState(currentCommunity);
  const where = {
    OR: [{objectId: {equalTo: options.postId}}, {id: {equalTo: options.postId}}],
    Community: {have: {objectId: {equalTo: community?.objectId}}},
    ...(options?.where ? options.where : {}),
  };
  const {data, ...response} = useQuery<CommunityPostsResponse>(GetCommunityPostsQuery(true), {
    variables: {
      where: where,
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.optionsOrder ? {optionsOrder: options.optionsOrder} : {}),
      userId: options?.userId,
    },
    skip: !community?.objectId || !options.postId,
    ssr: true,
  });
  return {...response, data: toStatePosts(data?.posts)?.[0] || undefined};
};

export const useGetPostPollInfo = (options: {userId?: string}) => {
  const [community] = useRecoilState(currentCommunity);
  const [typeUser] = useRecoilState(StateTypeUser);

  const isManager = typeUser === TypeCommunity.manager;

  const {refetch} = useQuery(GetCommunityPostsPollInfoQuery(isManager), {
    skip: true,
    ssr: true,
  });

  const getData = async (id: string) => {
    return await refetch({
      where: {
        OR: [{objectId: {equalTo: id}}, {id: {equalTo: id}}],
        Community: {have: {objectId: {equalTo: community?.objectId}}},
      },
      optionsOrder: GQLPollOptionOrder.position_ASC,
      userId: options?.userId,
    });
  };

  return {getPollInformation: getData};
};

type useCommunityFeedProps = {
  where?: GQLPostWhereInput;
  communityId?: string;
  userId?: string;
  first?: number;
  isManager?: boolean;
  lastDate?: Date;
  postType?: string;
  skip?: boolean;
};

export const useCommunityFeed = (options: useCommunityFeedProps) => {
  const isRecs = options?.postType === PostTypeT.recommendation;
  const query: GQLPostWhereInput = {
    Community: {
      have: {
        objectId: {
          equalTo: options?.communityId,
        },
      },
    },
    Hide: {
      haveNot: {
        objectId: {
          equalTo: options?.userId,
        },
      },
    },
    Parent: {
      exists: false,
    },
    Group: {
      exists: false,
    },
    notShow: {
      notEqualTo: true,
    },
    ...(isRecs
      ? {
          Author: {
            have: {
              recommendations: {
                exists: true,
              },
            },
          },
        }
      : {}),
    ...(options?.where ?? {}),
  };
  const fetchOptions = {
    skip: options?.skip,
    where: query,
    order: isRecs ? GQLPostOrder.createdAt_DESC : [GQLPostOrder.Pin_DESC, GQLPostOrder.createdAt_DESC],
    optionsOrder: GQLPollOptionOrder.position_ASC,
    userId: options?.userId,
    first: options?.first || 8,
    isManager: options?.isManager,
  };
  const {lastDate} = useLiveFeedCount({commId: options.communityId});
  const result = useGetCommunityPosts(fetchOptions);
  const resultData = result.data.filter((item) => {
    if (item.type !== PostTypeT.poll) {
      return item;
    } else if (item.type === PostTypeT.poll && item.Options?.length) {
      return item;
    }
  });

  return {...result, lastDateLive: lastDate, data: resultData || null};
};

export const useUpdatePost = () => {
  const [update] = useMutation<UpdatePostResponse, UpdatePostRequest>(UpdatePost);

  return async (id?: string, fields?: UpdatePostRequest['input']['fields']) => {
    return (
      await update({
        variables: {
          input: {
            id: id ?? '',
            fields: fields ?? {},
          },
        },
      })
    ).data;
  };
};

export const useHidePost = () => {
  const [hide] = useMutation<hidePostResType>(HidePost);

  return async (id?: string) => {
    return (
      await hide({
        variables: {
          input: {
            postID: id,
          },
        },
      })
    ).data;
  };
};

export const useGetPostMenuActions = () => {
  const hidePost = useHidePost();
  const viewer = useViewer();
  const community = useRecoilValue(currentCommunity);
  const report = (id?: string) => console.log('report', id);
  const pin = (id?: string) => console.log('pin', id);
  const [deletePostRequest] = useMutation<deletePostResType, deletePostReqType>(deleteCommunityPost);
  const deletePost = async (id?: string, onSuccess?: () => void): Promise<boolean> => {
    try {
      if (!id) return false;
      const result = await deletePostRequest({
        variables: {id: id},
      });
      const data = result?.data;
      if (!data) throw new Error('error:delete post');
      analyticsTrackFN('Post Deleted', {
        postId: id,
        type: data?.deletePost?.post?.type, // options: poll, post, notice, request etc.
        content: data?.deletePost?.post?.text,
        sentAs: data?.deletePost?.post?.sentAs,
        authorName: `${viewer?.firstName}`,
        authorEmail: viewer?.email,
        authorId: viewer?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  const hide = async (id?: string, onSuccess?: () => void): Promise<boolean> => {
    try {
      if (!id) return false;
      const result = await hidePost(id);

      if (!result) throw new Error('error:hide post');
      analyticsTrackFN('Post Hidden', {
        postId: id,
        type: result?.hidePost?.post?.type, // options: poll, post, notice, request etc.
        content: result?.hidePost?.post?.text,
        sentAs: result?.hidePost?.post?.sentAs,
        authorName: result?.hidePost?.post?.Author?.firstName,
        authorId: result?.hidePost?.post?.Author?.objectId, // objectID or UID of user
        hiddenByName: viewer?.firstName,
        hiddenByEmail: viewer?.email,
        hiddenById: viewer?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  return {pin, hide, report, deletePost};
};

export const useIsMemberHasPosts = (userId?: string, communityId?: string) => {
  const [hasPost, setHasPost] = useState(true);
  const {data} = useQuery<CommunityPostsResponse>(isHasPost, {
    variables: {
      where: {
        Author: {
          have: {
            objectId: {
              equalTo: userId,
            },
          },
        },
        Community: {
          have: {
            objectId: {
              equalTo: communityId,
            },
          },
        },
        type: {equalTo: 'post'},
      },
    },
    ssr: true,
    fetchPolicy: 'no-cache',
    skip: !userId || !communityId,
  });

  useEffect(() => {
    setHasPost(Boolean(data?.posts?.edges?.length));
  }, [data]);

  return {hasPost, setHasPost};
};

export const usePostActions = () => {
  const [setLikeRequest] = useMutation<likeCommunityPostRespType, likeCommunityPostReqType>(likeCommunityPost);
  const [setReactionRequest] = useMutation<undefined, ReactionCommunityPostReqType>(ReactionCommunityCommunityPost);
  const viewer = useViewer();
  const community = useRecoilValue(currentCommunity);
  const likePost = async (id?: string, onSuccess?: () => void, newResident?: boolean): Promise<boolean> => {
    try {
      if (!id) return false;
      const result = await setLikeRequest({
        variables: {id: id, newResident: newResident},
      });
      const data = result?.data;
      if (!data) throw new Error('error:like post');
      analyticsTrackFN('Post Liked', {
        postId: id,
        type: data?.likeHandler?.post?.type, // options: poll, post, notice, request etc.
        content: data?.likeHandler?.post?.text,
        sentAs: data?.likeHandler?.post?.sentAs,
        likedByName: `${viewer?.firstName}`,
        likedByEmail: viewer?.email,
        likedById: viewer?.objectId, // objectID or UID of user
        authorName: `${data?.likeHandler?.post?.Author?.firstName}`,
        authorId: data?.likeHandler?.post?.Author?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };
  const reactPost = async (id?: string, onSuccess?: () => void, type?: ReactionTypes): Promise<boolean> => {
    try {
      if (!id || !type) return false;
      const result = await setReactionRequest({
        variables: {postId: id, type: type},
      });
      const data = result?.data;
      if (!data) throw new Error('error:wave post');
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  return {likePost, reactPost};
};

export const useGetPostComments = (options: {
  where?: GQLPostWhereInput;
  commentsWhere?: GQLPostWhereInput;
  order?: GQLPostOrder;
  skip?: boolean;
  first?: number;
  userId: string;
}) => {
  const {data, ...response} = useQuery<PostCommentsResponse>(getPostComments, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
      ...(options?.commentsWhere ? {commentsWhere: options?.commentsWhere} : {}),
      userId: options?.userId,
    },
    ssr: true,
    fetchPolicy: 'no-cache',
  });
  const items: TPost[] | undefined = toStatePostComments(data?.posts);

  return {data: items || [], ...response};
};

export const usePostComments = (postId?: string) => {
  const [local, setLocal] = useState<Record<string, TPost | undefined>>({});
  const userId = useViewerId('objectId');
  if (!postId || !userId) return null;

  const query = {
    objectId: {
      equalTo: postId,
    },
  };

  const commentsQuery = {};

  const result = useGetPostComments({
    where: query,
    commentsWhere: commentsQuery,
    order: GQLPostOrder.createdAt_ASC,
    userId: userId,
  });
  const withLocal = [...result?.data, ...((Object.values(local).filter((el) => !!el) || []) as TPost[])];
  const addLocal = (post: TPost) => {
    const localRef = post?.localRef;
    if (localRef) setLocal((prev) => ({...prev, [localRef]: post}));
  };
  const onRemoveLocal = (localRef: string) => {
    setLocal((prev) => ({...prev, [localRef]: undefined}));
  };
  const lastComment = result?.data?.[result?.data?.length - 1];
  useEffect(() => {
    removeLocal(onRemoveLocal, Object.values(local), result?.data, true); // remove comment from local state
  }, [lastComment?.objectId]);
  return {...result, data: result?.data, withLocal, onRemoveLocal, addLocal};
};

const PostSchemaPoll = FormValidation.schema<Partial<PostFormValues>>({
  text: FormValidation.string('error:postPollQuestion'),
  options: FormValidation.handler((value, data) => {
    if (!data?.options?.length || data?.options?.length < 2) return false;
    const ind = data?.options?.findIndex((el) => el?.text?.length === 0 || !el?.text?.trim());
    if (ind >= 0) return false;
    return true;
  }, 'error:postPollOptionsCount'),
});

const PostSchemaVideo = FormValidation.schema<Partial<PostFormValues>>({
  text: FormValidation.string().url('error:postVideo'),
});

export type TResCreatePost = {
  onSubmit: (id?: string) => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<PostFormValues>;
  values: Partial<PostFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: PostFormValue}) => void;
};

type UseCreatePostType = (options: {
  initialState: Partial<PostFormValues>;
  onSuccess?: () => void;
  onError?: () => void;
  groupId?: string;
  AuthorParent?: MemberT;
  addLocalPost?: (post: TPost) => void;
  onLocalSuccess?: () => void;
  setLocalError?: (local: string) => void;
}) => TResCreatePost;

export const useCreatePost: UseCreatePostType = (options) => {
  const viewer = useViewer();
  const [community] = useRecoilState(currentCommunity);
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<PostFormValues>>(options?.initialState);
  const [error, setError] = useFormError<PostFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  //const [createOptionRequest] = useMutation<CreatePollOptionResponseType, createPollOptionReq>(createPollOption);
  useEffect(() => {
    if (state.hasSetInitialValues) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  useEffect(() => {
    if (values?.type === PostTypeT.announcement) {
      setValues({
        ...values,
        medium: {
          loop: true,
          email: false,
          push: false,
          sms: false,
          message: false,
        },
      });
    } else {
      setValues({
        ...values,
        medium: {
          loop: false,
          email: false,
          push: false,
          sms: false,
          message: false,
        },
      });
    }
  }, [values?.type]);
  const [CreatePostRequest, {loading}] = useMutation<CreatePostResponseType, CreatePostRequestType>(createPost);

  const handleChange = (next: {name: string; value?: PostFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => {
      return {...prev, [next.name]: value};
    });
  };

  const onSubmit = async (communityId?: string): Promise<boolean> => {
    const localRef = String(new Date().getTime());
    try {
      if (values.type === PostTypeT.poll)
        if (!PostSchemaPoll.validate<Partial<PostFormValues>>(values)) {
          return false;
        }

      if (values.type === PostTypeT.video)
        if (!PostSchemaVideo.validate<Partial<PostFormValues>>(values)) {
          return false;
        }

      if (!viewer?.id) {
        throw new Error('error:id');
      }
      if (!community?.objectId && !communityId) {
        throw new Error('error:id community');
      }
      const files = values.Attachments?.map((file) => {
        if (file.value) {
          if (typeof file.value !== 'string') {
            return {value: changeFileName(file.value)};
          }
        }
        return {value: file.value};
      });
      const localState: any = [];
      const parseAttachmets = await Promise.all(
        (files || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  Owner: {link: viewer?.objectId},
                  type: imageType.postAttachment,
                },
              },
            });
            localState.push({
              id: result.data?.createAppFile?.appFile?.id,
              objectId: result.data?.createAppFile?.appFile?.objectId,
              file: result.data?.createAppFile?.appFile?.file,
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );
      const withoutTags = values?.text
        ?.replace(/(<([^>]+)>)/gi, '')
        ?.replaceAll(' ', '')
        ?.replaceAll('\n', '');
      if (!withoutTags?.length) {
        options?.onLocalSuccess?.();
        return false;
      }
      const fields = {
        ...toPointerCreatePost(
          values,
          viewer?.objectId,
          communityId || community?.objectId,
          parseAttachmets,
          options.initialState.Parent?.link,
          options.groupId,
          values.options,
          localRef,
        ),
        medium: values?.medium
          ? (Object.keys(values?.medium)?.filter((item) => {
              if (values?.medium?.[item as keyof typeof values.medium]) {
                return item;
              }
            }, []) as MediumTypes[])
          : [],
        text: values?.text
          ?.replaceAll('\n', '')
          .replaceAll(/&nbsp;/gi, '')
          .replaceAll('<p></p>', '<br>')
          .replaceAll('<br></p>', '</p><br>'),
      };
      options?.addLocalPost?.(toLocalPostState(values, viewer, localRef, community, localState, fields));
      const [response] = await Promise.all([
        CreatePostRequest({
          variables: {
            fields: fields,
          },
        }),
        setValues(options?.initialState),
        options?.onLocalSuccess?.(),
      ]);
      const postData = response?.data?.createPost?.post;
      if (!postData && !communityId) throw new Error();

      /*(values.options || [])
        ?.filter((it) => Boolean(it?.text))
        ?.map((opt, index) => {
          return createOptionRequest({
            variables: {
              fields: {
                text: opt?.text,
                position: index,
                Post: {link: postData.objectId},
              },
            },
          });
        }),*/
      if (!options.initialState.Parent?.link)
        analyticsTrackFN('Post Created', {
          postId: postData?.objectId,
          type: values.type, // options: poll, post, notice, request etc.
          content: values?.text,
          sentAs: values?.sentAs,
          authorName: `${viewer?.firstName}`,
          authorEmail: viewer?.email,
          authorId: viewer?.objectId, // objectID or UID of user
          communityName: community?.name,
          communityId: community?.objectId, // objectID or ID of community
          communityType: community?.type, // building, neighbourhood, circle,
        });
      else if (values?.type === PostTypeT.comment)
        analyticsTrackFN('Post Commented', {
          postId: values?.Parent?.link,
          type: values.type, // options: poll, post, notice, request etc.
          comment: values?.text,
          sentAs: values?.sentAs,
          commentedByName: `${viewer?.firstName}`,
          commentedByEmail: viewer?.email,
          commentedById: viewer?.objectId, // objectID or UID of user
          authorName: options?.AuthorParent?.firstName,
          authorId: options?.AuthorParent?.objectId,
          communityName: community?.name,
          communityId: community?.objectId, // objectID or ID of community
          communityType: community?.type, // building, neighbourhood, circle,
        });
      setSuccess('success:createPost');
      setError(null);
      options?.onSuccess?.();
      // setValues(options?.initialState);
    } catch (error) {
      if (communityId) {
        setSuccess('success:createPost');
        setError(null);
        options?.onSuccess?.();
        // setValues(options?.initialState);
        return true;
      }
      options?.onLocalSuccess?.();
      options?.setLocalError?.(localRef);
      setSuccess(null);
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

export const usePollVote = () => {
  const [setVoteRequest] = useMutation<undefined, VoteInPollReqType>(voteInPoll);
  const [removeVoteRequest] = useMutation<undefined, RemoveVoteInPollReqType>(removeVoteInPoll);

  const votePoll = async (postId?: string, optionId?: string, onSuccess?: () => void): Promise<boolean> => {
    try {
      if (!postId || !optionId) return false;
      const result = await setVoteRequest({
        variables: {postId: postId, optionId: optionId},
      });
      const data = result?.data;
      if (!data) throw new Error('error:vote in poll');
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  const removeVote = async (postId?: string, optionId?: string, onSuccess?: () => void): Promise<boolean> => {
    try {
      if (!postId || !optionId) return false;
      const result = await removeVoteRequest({
        variables: {postId: postId, optionId: optionId},
      });
      const data = result?.data;
      if (!data) throw new Error('error:vote in poll');
      onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  return {votePoll, removeVote};
};

export const usePinUnpinPost = (params?: {onSucces?: () => void}) => {
  const [pinUnpin] = useMutation<PinUnpinVariables>(PinUnpinPost);

  const _pinUnpin = async (postID: string) => {
    try {
      await pinUnpin({variables: {postID}});
      params?.onSucces?.();
    } catch (e) {
      console.log('Error', e);
    }
  };

  return _pinUnpin;
};
export const useEditPost = (options?: {
  initialState?: Partial<PostFormValues>;
  onSuccess?: () => void;
  postId?: string;
  prevAttachments?: PointerFile[];
  pollOptions?: {key: number; value: TPollOption}[];
  onLocalEdit?: (values: Partial<TPost>) => void;
  onLocalSuccess?: () => void;
}) => {
  const {initialState, onSuccess, postId, pollOptions, onLocalEdit, onLocalSuccess} = options || {};
  const community = useRecoilValue(currentCommunity);
  const viewer = useViewer();
  const [values, setValues] = useState<Partial<PostFormValues>>(initialState || {});
  const [error, setError] = useFormError<PostFormValues>();
  const [updatePost, {loading}] = useMutation<TUpdatePostRes, TUpdatePostReq>(UpdatePostQuery);
  const [updateOption] = useMutation<TUpdatePollOptionRes, TUpdatePollOptionReq>(UpdatePollOptionQuery);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);

  const handleChange = (next: {name: string; value?: PostFormValue}) => {
    setError(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };

  const onSubmit = async (): Promise<boolean> => {
    const {Attachments} = values;
    try {
      const attachmentsList: Array<Partial<PointerFile>> = [];
      await Promise.all(
        (Attachments || []).map((el) => {
          if (isFile(el.value)) {
            return createAppFile({
              variables: {
                fields: {
                  file: {upload: el.value},
                  Owner: {link: viewer?.objectId},
                  type: imageType.postAttachment,
                },
              },
            }).then((res) => {
              res.data?.createAppFile.appFile && attachmentsList.push(res.data?.createAppFile.appFile);
            });
          } else {
            attachmentsList.push(el.value as Partial<PointerFile>);
          }
        }),
      );
      let text = values?.text
        ?.replaceAll('\n', '')
        .replaceAll(/&nbsp;/gi, '')
        .replaceAll('<p></p>', '<br>')
        .replaceAll('<br></p>', '</p><br>');
      while (text?.includes('<br></p>')) {
        text = text?.replaceAll('<br></p>', '</p><br>');
      }
      onLocalEdit?.(toLocalEditedState(values, attachmentsList));
      onLocalEdit && onLocalSuccess?.();
      const {options, ...fields} = values;
      await updatePost({
        variables: {
          id: postId as string,
          fields: {
            ...fields,
            Attachments: attachmentsList,
            medium: values?.medium
              ? (Object.keys(values?.medium)?.filter((item) => {
                  if (values?.medium?.[item as keyof typeof values.medium]) {
                    return item;
                  }
                }, []) as MediumTypes[])
              : [],
            text: text,
          },
        },
      });

      await Promise.all(
        (options || [])?.map(async (el, idx) => {
          const currentOption = pollOptions?.find((el) => el.key === idx);
          await updateOption({
            variables: {
              id: currentOption?.value.objectId as string,
              fields: {
                text: el?.text,
              },
            },
          });
        }),
      );
      analyticsTrackFN('Post Edited', {
        postId: postId,
        type: values.type, // options: poll, post, notice, request etc.
        content: values?.text,
        sentAs: values?.sentAs,
        authorName: `${viewer?.firstName}`,
        authorEmail: viewer?.email,
        authorId: viewer?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });
      setError(null);
      onSuccess?.();
    } catch (error) {
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    loading,
    onChange: handleChange,
    error,
  };
};

export const useGetPostsCount = (communityId?: string, groupId?: string) => {
  const where: GQLPostWhereInput = {
    Community: {
      have: {
        objectId: {
          equalTo: communityId,
        },
      },
    },
    isDeleted: {
      equalTo: false,
    },
    Group: {
      ...(groupId ? {have: {objectId: {equalTo: groupId}}} : {exists: false}),
    },
  };
  const {data} = useQuery<CommunityPostsCountResponse>(GetPostsCount, {
    variables: {
      where,
    },
    ssr: true,
  });
  return data?.posts.count || 0;
};

export enum LiveFeedCountFields {
  createdAt = 'updatedAt',
}

type LiveFeedCountT = {
  updatedAt?: string;
};

export const useLiveFeedCount = (params: {commId?: string; groupId?: string}) => {
  const {commId, groupId} = params;
  const objectId = useViewerId('objectId');
  const [queryData, setQueryData] = useState<Parse.Query>();
  const [lastDate, setLastDate] = useState<number>(new Date().getTime());
  const memoMap = useCallback(
    (object: Parse.Object): LiveFeedCountT => {
      return toPOJO<LiveFeedCountT>(Object.values(LiveFeedCountFields), object, {});
    },
    [queryData],
  );

  const {data, loading, refetch, unsubscribe} = useLiveQuery<LiveFeedCountT>({
    query: queryData?.greaterThan('updatedAt', new Date(lastDate)) as Parse.Query,
    map: memoMap,
  });

  useEffect(() => {
    if (data) {
      const obj = Object.keys(data)[0];
      if (obj && data[obj]?.updatedAt) {
        const last = new Date(data[obj]?.updatedAt || '').getTime();
        if (lastDate < last) setLastDate(last);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!objectId) return;
    const user = new Parse.User();
    user.id = objectId;
    if (!commId) return;
    const community = new Parse.Object('Community');
    let group;
    community.id = commId;
    if (groupId) {
      group = new Parse.Object('Group');
      group.id = groupId;
    }
    const query = new Parse.Query('Post')
      .equalTo('Community', community)
      .equalTo('Group', group)
      .include(['objectId'])
      .limit(1);
    setQueryData(query);
  }, [commId]);

  useEffect(() => {
    if (!objectId) unsubscribe();
  }, [objectId]);
  return {lastDate, loading, fetchMore: refetch};
};

export const useGalleryListener = () => {
  const setIsModal = useSetRecoilState(isModal);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref?.current?.classList.contains('open')) setIsModal(true);
    if (ref?.current?.classList.contains('open')) setIsModal(false);
  }, [!!ref?.current]);
  return {ref};
};

type useFeedPropsT = {
  typeUser: TypeCommunity;
  viewerId?: string;
  currentComm: Partial<TCommunity>;
};

export const useFeed = ({typeUser, viewerId, currentComm}: useFeedPropsT) => {
  const {t} = useTranslation();
  const viewer = useViewer();
  const menuActions = useGetPostMenuActions();
  const postActions = usePostActions();
  const reportActions = useReport(entities.post);
  const {hasPost, setHasPost} = useIsMemberHasPosts(viewerId, currentComm?.objectId);
  const postOptions = getPostOptions(t);
  const adminLabels = useGetAdminsLabels();
  const isManagerOrAdm = typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin;
  const [itemsLiked, setItemsLiked] = useState<LikeState>({});
  const {handleLike: likeItem} = useItemLike();
  const [CreatePostRequest] = useMutation<CreatePostResponseType, CreatePostRequestType>(createPost);
  const [maxLength, setMaxLength] = useState<number>();
  const [postType, setPostType] = useState<Partial<LabelValuePair> | undefined>(postOptions[0]);
  const [localFeed, setLocalFeed] = useState<Record<string, TPost | undefined>>({});
  const [localDeleted, setLocalDeleted] = useState<string[]>([]);
  const [localEdited, setLocalEdited] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useState<GQLPostWhereInput>({
    type: {notEqualTo: PostTypeT.recommendation},
  });
  const postToLoad = postType?.value === PostTypeT.recommendation ? 16 : 8;
  const {data, refetch, loading, fetchMore, lastDateLive} = useCommunityFeed({
    where: searchParams,
    communityId: currentComm.objectId,
    userId: viewerId,
    first: postToLoad,
    isManager: isManagerOrAdm,
    postType: postType?.value,
  });

  const pinUnpin = usePinUnpinPost({onSucces: refetch});
  const handleFetchMore = (skip?: number) => {
    try {
      if (!skip || !data?.length) return;
      fetchMore({
        variables: {
          skip: data?.length,
        },
      }).then(() => {
        if (maxLength !== data?.length) setMaxLength(data?.length);
        else setMaxLength(-1);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const actions: PostActions = {...reportActions, pinUnpin};
  const handleRefetch = async (num?: number, cb?: () => void) => {
    await refetch({first: num || data?.length > 8 ? data?.length : 8});
    cb?.();
  };
  const {getPollInformation} = useGetPostPollInfo({userId: viewer?.objectId});

  // <- actions
  const getPollData = async (id: string) => {
    const result = await getPollInformation(id);
    return toStatePosts(result?.data?.posts)?.[0]?.Options?.map((item) => item.Voted);
  };
  const addLocalPost = (post: TPost) => {
    if (!hasPost) setHasPost(true);
    const localRef = post?.localRef;
    if (localRef) setLocalFeed((prev) => ({[localRef]: post, ...prev}));
  };
  const handleSelect = (next: Partial<LabelValuePair>) => {
    createSearchParams(next, setSearchParams, viewerId);
    setPostType(next);
  };
  const onRemoveLocal = (localRef: string) => {
    setLocalFeed((prev) => ({...prev, [localRef]: undefined}));
  };
  const onLocalDelete = (id: string) => setLocalDeleted((prev) => [...prev, id]);
  const addLocalEdited = (values: any) => setLocalEdited((prev) => [...prev, values]);

  const setLocalError = (localRef: string) =>
    setLocalFeed((prev) => ({...prev, [localRef]: {...prev[localRef], isError: true}}));
  const removeLocalError = (localRef: string) =>
    setLocalFeed((prev) => ({...prev, [localRef]: {...prev[localRef], isError: false}}));
  const onCreateRetry = async (ref: string) => {
    removeLocalError(ref);
    try {
      const fields = localFeed?.[ref]?.localCreationData;
      if (!fields) return false;
      await CreatePostRequest({variables: {fields}});
      return true;
    } catch (error) {
      setLocalError(ref);
      return false;
    }
  };
  // actions ->

  const hasMorePosts = data?.length % postToLoad === 0 && maxLength !== -1;
  // const showOptions = totalPostsCount > 20 || data?.length > 20;
  const showOptions = true;
  const localFeedArray = Object.values(localFeed);

  // <--- items like
  const listings = data
    ?.map((el) => el?.Items)
    ?.filter((e) => e)
    .flat();
  useEffect(() => {
    setItemsLiked((prev) => {
      const likeMap = {...prev};
      listings.forEach?.((el) => {
        if (el?.isLike && el?.objectId) if (likeMap[el.objectId] === undefined) likeMap[el.objectId] = true;
      });
      return likeMap;
    });
  }, [listings?.length]);
  const onLikeItem = (id: string) => {
    likeItem(id);
    setItemsLiked((prev) => ({...prev, [id]: !prev[id]}));
  };
  // items like --->
  useEffect(() => {
    refetch(); // refetch on new posts
  }, [lastDateLive]);
  useEffect(() => {
    removeLocal(onRemoveLocal, localFeedArray, data); // remove from local state
  }, [`${data?.[0]?.objectId}${data?.[1]?.objectId || ''}`]);
  const dataWithLocal = [...formatLocalFeedArray(localFeedArray, postType?.value), ...data];
  useEffect(() => {
    setMaxLength(undefined);
  }, [currentComm?.objectId, postType?.value]);
  useEffect(() => {
    if (!loading && !data?.length) refetch();
  }, [data?.length, loading]);
  return {
    menuActions,
    postActions,
    hasMorePosts,
    showOptions,
    getPollData,
    handleRefetch,
    handleSelect,
    actions,
    handleFetchMore,
    adminLabels,
    loading,
    refetch,
    postType,
    data: dataWithLocal,
    postOptions,
    addLocalPost,
    onLocalDelete,
    localDeleted,
    addLocalEdited,
    localEdited,
    setLocalError,
    onCreateRetry,
    onLikeItem,
    itemsLiked,
    hasPost,
  };
};
