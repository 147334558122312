import React from 'react';
import {TCommunity} from '../../../types/community';
import {EmptyState} from '../../EmptyState/EmptyState';
import {EmptyAddress} from '../../EmptyAddress/EmptyAddress';
import {MemoSceletonLoader} from './SceletonLoader';
import {CardsGrid} from './styles';
import {SearchCard} from './Card';

type TProps = {
  communities?: Partial<TCommunity>[] | null;
  viewerId?: string;
  loading?: boolean;
  address?: boolean;
  viewerAddress?: string;
  redirectToPersonalSetting: () => void;
};

export const CardsList: React.FC<TProps> = ({
  communities,
  loading,
  viewerId,
  viewerAddress,
  redirectToPersonalSetting,
}) => {
  if (!loading && !viewerAddress) {
    return <EmptyAddress redirectToPersonalSetting={redirectToPersonalSetting} maxWidth={'80%'} />;
  }

  if (!loading && !communities?.length) {
    return <EmptyState maxWidth={'80%'} />;
  }

  if (!communities?.length && loading) {
    return <MemoSceletonLoader />;
  }

  return (
    <CardsGrid columnGap={12} rowGap={12}>
      {communities?.map((it) => (
        <SearchCard
          key={it?.objectId}
          id={it?.objectId || ''}
          name={it?.name || ''}
          accessType={it.accessType}
          descr={it.descr}
          city={it?.city?.name}
          state={it?.state?.name}
          country={it?.country?.name}
          countMembers={it.countMembers}
          joinRequestStatus={it?.JoinRequests ? it.JoinRequests[0]?.status : undefined}
          userId={viewerId}
          community={it}
        />
      ))}
    </CardsGrid>
  );
};
