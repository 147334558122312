import React, {useCallback, useState} from 'react';
import {
  CardDate,
  CardDatesEdit,
  CardDatesWrapperFull,
  CardDateWrapper,
  CardDateWrapperFull,
  DatePickerWrapper,
  CardDatesWrapperGhost,
  CardDateTitle,
} from '../styles';
import {
  ButtonArea,
  Caption,
  CloseButton,
  DateCaption,
  Duration,
  DurationsBox,
  DurationsWrapper,
  PickerContainerOne,
  PickerContainerWithTime,
  SelectWrapper,
  UnitWrapper,
} from './styles';
import {DateTimePickerProps, PickDateTypes} from '../types';
import {IconSvg} from '../../Icon/Svg';
import {format} from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import {Popup} from '../../Popup';
import {Button} from '../../Button/Button';
import {Text} from '../../Typography/Text';
import {Br} from '../../Typography/Br';
import {ButtonProps} from '../../Button/types';
import {DatePickerLanguageProvider} from '../../Form/Input/DatePickerLanguageProvider';
import {useGetLanguage} from '../../utils/language';

type DurationBlockProps = {
  onChange?: (val: number | '') => void;
  isEditing?: boolean;
  duration?: number | '' | null;
  labelHours?: string;
  onBlur?: () => void;
  durationUnit?: {
    single?: string;
    many?: string;
  };
};
export type OpType = 'date' | 'time' | 'hour' | null;
export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  date,
  setDate,
  pickType,
  canEdit,
  pickerLabels,
  duration,
  offsetBottom,
  setDuration,
  pickerPlaceholders,
  smallPicker,
  durationUnit,
  captions,
  buttonSelectText,
  busyDates,
  filterDate,
  filterTime,
  timeIntervals,
  hidePastTimes,
  setTime,
  time,
  busyTimes,
  includeDates,
}) => {
  const [openedType, setOpenedType] = useState<OpType>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const getLanguage = useGetLanguage();
  const handleChange = (dateN: Date) => {
    if (openedType === 'date') {
      const time = date ? {hours: new Date(date).getHours(), minutes: new Date(date).getMinutes()} : null;
      if (time) {
        dateN.setHours(time.hours);
        dateN.setMinutes(time.minutes);
      }
      setDate?.(dateN);
    }
    if (openedType === 'time') {
      setTime?.(dateN);
      const selectedDate = date ? new Date(date) : null;
      if (selectedDate) {
        const hours = new Date(dateN).getHours();
        const minutes = new Date(dateN).getMinutes();
        selectedDate.setHours(hours);
        selectedDate.setMinutes(minutes);
        setDate?.(selectedDate);
      }
    }
  };

  const handleEditStart = () => {
    if (!canEdit) return;
    setIsEditing(true);
  };
  const showDatePopup = useCallback(() => setOpenedType('date'), []);
  const showTimePopup = useCallback(() => setOpenedType('time'), []);
  const showHourPopup = useCallback(() => setOpenedType('hour'), []);
  const restOpType = useCallback(() => setOpenedType(null), []);
  const handleEditStop = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsEditing(false);
    restOpType();
  };
  const strDots = (str?: string) => `${str}...`;
  const formatedBusyTimes = busyTimes?.map((el) => format(new Date(el), 'p'));
  if (pickType === PickDateTypes.fullDate)
    return (
      <CardDatesWrapperFull offsetBottom={offsetBottom} onClick={handleEditStart} zIndex={200} tabIndex={-1}>
        <CardDateWrapperFull onClick={showDatePopup}>
          <CardDateTitle>{date ? pickerLabels?.labelDate : strDots(pickerPlaceholders?.addDate)}</CardDateTitle>
          <CardDate>{date && format(date, 'MM/dd/yyyy')}</CardDate>
        </CardDateWrapperFull>
        {canEdit && isEditing && (
          <CardDatesEdit onClick={handleEditStop}>
            <IconSvg width="12" height="12" viewBox="0 0 18 18" type={'close'} stroke={'darkgray'} />
          </CardDatesEdit>
        )}
        <DatePickerWrapper>
          <Popup isOpen={isEditing} onClose={handleEditStop} mobileCentered={true}>
            <PickerWrapperWithBtnsWrapper
              dateCaption={captions?.date}
              onSelect={handleEditStop}
              onClose={handleEditStop}
              textButton={buttonSelectText}
              isReady={!!date}
              openType={openedType}>
              <PickerContainerOne>
                <DatePickerLanguageProvider>
                  <ReactDatePicker
                    selected={date}
                    excludeDates={busyDates}
                    onChange={(dateN: Date) => setDate?.(dateN)}
                    minDate={new Date()}
                    inline
                    locale={getLanguage()}
                    includeDates={includeDates}
                  />
                </DatePickerLanguageProvider>
              </PickerContainerOne>
            </PickerWrapperWithBtnsWrapper>
          </Popup>
        </DatePickerWrapper>
      </CardDatesWrapperFull>
    );

  if (pickType === PickDateTypes.dateTime)
    return (
      <CardDatesWrapperGhost offsetBottom={offsetBottom} onClick={handleEditStart} zIndex={199} tabIndex={-1}>
        <CardDateWrapper $independent onClick={showDatePopup}>
          <CardDateTitle>{date ? pickerLabels?.labelPickUp : strDots(pickerPlaceholders?.addDate)}</CardDateTitle>
          <CardDate>{date && format(date, 'MM/dd/yyyy')}</CardDate>
        </CardDateWrapper>
        <CardDateWrapper $independent $ml onClick={showTimePopup}>
          <CardDateTitle>{time ? pickerLabels?.labelTime : strDots(pickerPlaceholders?.addTime)}</CardDateTitle>
          <CardDate>{time && format(time, 'p')}</CardDate>
        </CardDateWrapper>
        {canEdit && isEditing && (
          <CardDatesEdit onClick={handleEditStop}>
            <IconSvg width="12" height="12" viewBox="0 0 18 18" type={'close'} stroke={'darkgray'} />
          </CardDatesEdit>
        )}
        <DatePickerWrapper>
          <Popup isOpen={isEditing} onClose={handleEditStop} mobileCentered={true}>
            <PickerWrapperWithBtnsWrapper
              onSelect={handleEditStop}
              onClose={handleEditStop}
              dateCaption={captions?.date}
              textButton={buttonSelectText}
              isReady={!!date}
              openType={openedType}>
              <PickerContainerWithTime $small={smallPicker} $hideDisabled={hidePastTimes}>
                <DatePickerLanguageProvider>
                  {openedType === 'date' && (
                    <ReactDatePicker
                      selected={date}
                      onChange={handleChange}
                      inline
                      timeFormat="p"
                      timeIntervals={timeIntervals || 30}
                      timeCaption={captions?.time}
                      excludeDates={busyDates}
                      minDate={new Date()}
                      locale={getLanguage()}
                      filterDate={filterDate}
                      filterTime={filterTime}
                    />
                  )}
                  {openedType === 'time' && (
                    <ReactDatePicker
                      selected={time}
                      onChange={handleChange}
                      inline
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={timeIntervals || 30}
                      timeCaption={captions?.time}
                      excludeTimes={busyTimes}
                      minDate={new Date()}
                      locale={getLanguage()}
                      filterDate={filterDate}
                      filterTime={filterTime}
                      timeClassName={(date) =>
                        formatedBusyTimes?.includes(format(date, 'p')) ? 'excluded' : 'notExcluded'
                      }
                    />
                  )}
                </DatePickerLanguageProvider>
              </PickerContainerWithTime>
            </PickerWrapperWithBtnsWrapper>
          </Popup>
        </DatePickerWrapper>
      </CardDatesWrapperGhost>
    );

  if (pickType === PickDateTypes.TimeAndDuration)
    return (
      <>
        <CardDatesWrapperFull onClick={handleEditStart} zIndex={200} tabIndex={-1}>
          <CardDateWrapperFull onClick={showDatePopup}>
            <CardDateTitle>{date ? pickerLabels?.labelDate : strDots(pickerPlaceholders?.addDate)}</CardDateTitle>
            <CardDate>{date && format(date, 'MM/dd/yyyy')}</CardDate>
          </CardDateWrapperFull>
        </CardDatesWrapperFull>
        <Br indent={12} />
        <CardDatesWrapperGhost offsetBottom={offsetBottom} onClick={handleEditStart} zIndex={199} tabIndex={-1}>
          <CardDateWrapper $independent onClick={showTimePopup}>
            <CardDateTitle>{time ? pickerLabels?.labelTime : strDots(pickerPlaceholders?.addTime)}</CardDateTitle>
            <CardDate>{time && format(time, 'p')}</CardDate>
          </CardDateWrapper>
          <CardDateWrapper $noOverflow={true} $independent $ml onClick={showHourPopup}>
            <CardDateTitle>{pickerLabels?.labelDuration}</CardDateTitle>
            <CardDate>
              {duration ? (
                <>
                  {duration}{' '}
                  <UnitWrapper>{(duration > 1 ? durationUnit?.many : durationUnit?.single) || ''}</UnitWrapper>
                </>
              ) : (
                ''
              )}
            </CardDate>
          </CardDateWrapper>
          <DatePickerWrapper>
            <Popup isOpen={isEditing} onClose={handleEditStop} mobileCentered={true}>
              <PickerWrapperWithBtnsWrapper
                onSelect={handleEditStop}
                onClose={handleEditStop}
                dateCaption={captions?.date}
                textButton={buttonSelectText}
                isReady={!!date || !!duration}
                openType={openedType}>
                <PickerContainerWithTime $small={smallPicker} $hideDisabled={hidePastTimes}>
                  <DatePickerLanguageProvider>
                    {openedType === 'date' && (
                      <ReactDatePicker
                        selected={date}
                        onChange={handleChange}
                        inline
                        timeFormat="p"
                        timeIntervals={timeIntervals || 30}
                        timeCaption={captions?.time}
                        excludeDates={busyDates}
                        minDate={new Date()}
                        locale={getLanguage()}
                      />
                    )}
                    {openedType === 'time' && (
                      <ReactDatePicker
                        selected={time}
                        onChange={handleChange}
                        inline
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={timeIntervals || 30}
                        timeCaption={captions?.time}
                        excludeDates={busyDates}
                        minDate={new Date()}
                        locale={getLanguage()}
                        className={'date-picker_onlyTime'}
                      />
                    )}
                  </DatePickerLanguageProvider>
                </PickerContainerWithTime>
                {openedType === 'hour' && (
                  <DurationBlock
                    duration={duration}
                    onChange={setDuration}
                    labelHours={captions?.duration}
                    isEditing={isEditing}
                    durationUnit={durationUnit}
                  />
                )}
              </PickerWrapperWithBtnsWrapper>
            </Popup>
          </DatePickerWrapper>
        </CardDatesWrapperGhost>
      </>
    );
  return null;
};

export const PickerWrapperWithBtnsWrapper: React.FC<{
  onSelect?: () => void;
  onClose?: () => void;
  textButton?: string;
  dateCaption?: string;
  isReady?: boolean;
  openType?: OpType;
}> = ({children, isReady, onSelect, textButton, onClose, dateCaption, openType}) => {
  const buttonProps: ButtonProps = isReady
    ? {
        width: '154px',
      }
    : {
        width: '91px',
        ghost: true,
        variant: 'custom',
      };

  return (
    <SelectWrapper openType={openType}>
      <CloseButton onClick={onClose}>
        <IconSvg type={'close'} width={'24'} height={'24'} viewBox={'0 0 20 20'} />
      </CloseButton>
      <DateCaption>{openType === 'date' && dateCaption}</DateCaption>
      <Br indent={7} />
      {children}
      <Button rounded onClick={isReady ? onSelect : undefined} {...buttonProps}>
        {textButton}
      </Button>
    </SelectWrapper>
  );
};

const DurationBlock: React.FC<DurationBlockProps> = ({
  isEditing,
  duration,
  onChange,
  labelHours,
  onBlur,
  durationUnit,
}) => {
  const handleChange = (hour?: [{label?: string; value?: number}]) => () => {
    onChange?.(Number(hour?.[0]?.value) || '');
    onBlur?.();
  };
  const hours = [];
  for (let i = 0; i < 8; i++) {
    hours.push({label: `${i + 1} ${(i > 0 ? durationUnit?.many : durationUnit?.single) || ''}`, value: i + 1});
  }
  if (isEditing)
    return (
      <DurationsWrapper>
        <Caption>{labelHours}</Caption>
        <DurationsBox>
          {hours.map((el, idx) => (
            <ButtonArea onClick={handleChange([el])} key={idx}>
              <Duration $isSelected={duration === el.value}>
                <Text size={14}>{el.label}</Text>
              </Duration>
            </ButtonArea>
          ))}
        </DurationsBox>
      </DurationsWrapper>
    );

  const formatedDuration = duration ? `${duration} ${labelHours}` : '';
  return <>{formatedDuration}</>;
};
