import React, {memo, useEffect, useState} from 'react';
import {GoBackWrapper, Hidden} from '../../../ui-kit/Navigation/styles';
import {ProfileHeaderMobileContainer} from '../../../ui-kit/ProfileIntro/styles';
import {Media} from '../../../ui-kit/theme';
import throttle from 'lodash.throttle';
import {GoBackHeader, GoBackTitle} from './styles';
import {useLinks} from '../../../hooks/common';
import {useHistory} from 'react-router-dom';
import {route} from '../../../constants/routes';
import {useRecoilValue} from 'recoil';
import {prevPaths} from '../../../states/navState';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

type GoBackPropsT = {goBack: () => void; title?: string; redirectPath?: string};

const GoBack: React.FC<GoBackPropsT> = memo(({goBack, title, redirectPath}) => {
  const [scrolled, setScrolled] = useState(false);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const onRedirect = () => push(getLink(redirectPath || route.listings.path));
  const previousPaths = useRecoilValue(prevPaths);
  const onGoBack = () => {
    if (previousPaths.prev && previousPaths?.current !== previousPaths.prev && previousPaths.prev !== '/') {
      goBack();
    } else {
      onRedirect();
    }
  };
  useEffect(() => {
    const handleScroll = throttle(() => {
      window?.pageYOffset > 30 ? setScrolled(true) : setScrolled(false);
    }, 500);
    window?.addEventListener('scroll', handleScroll);
    return () => window?.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Hidden mq={Media.up.s}>
      <GoBackHeader $scrolled={scrolled}>
        <ProfileHeaderMobileContainer>
          <GoBackWrapper onClick={onGoBack}>
            <IconSvg stroke={'darkBlue'} type={'arrow-left'} width="26px" height="26px" strokeWidth={1.5} />
          </GoBackWrapper>
          <GoBackTitle onClick={onGoBack}>{title}</GoBackTitle>
        </ProfileHeaderMobileContainer>
      </GoBackHeader>
    </Hidden>
  );
});

GoBack.displayName = 'GoBack';
export {GoBack};
