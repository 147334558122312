import {isBoolean} from '../validations';
import {Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';

export class ValidationBoolean<T extends anyObject> extends Validation<T> {
  constructor(message?: Message) {
    super(message);
    this.$validators.push(Validator.of((value: unknown) => isBoolean(value), message));
  }

  public invalid(message?: Message) {
    this.$message = message;
    this.$validators.push(Validator.of((value: unknown) => value === false, this.$message));
    return this;
  }

  public valid(message?: Message) {
    this.$message = message;
    this.$validators.push(Validator.of((value: unknown) => value === true, this.$message));
    return this;
  }
}
