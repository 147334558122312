import {TFunction} from 'react-i18next';
import {
  ArtsAndCrafts,
  Biking,
  BoardAndCardGames,
  BookClub,
  CookingAndBaking,
  HikingAndRunning,
  MoviesAndShows,
  PhotoAndVideo,
  VideoAndConsoleGames,
  YogaAndMeditation,
  MusicAndFestivals,
  TravelAndTours,
} from '../files';
import {Group, TTypeOfGroup} from '../queries/types/group';
import {Connection} from '../queries/types/parse';
import {TGroup} from '../types/group';
import {correctTranslate} from './common';
import {PointerFile} from '../types/common';

const defaultAvatars: Record<string, string> = {
  bookClub: BookClub,
  biking: Biking,
  yogaMeditation: YogaAndMeditation,
  artsHandicrafts: ArtsAndCrafts,
  videoConsoleGames: VideoAndConsoleGames,
  hikingRunning: HikingAndRunning,
  moviesShows: MoviesAndShows,
  boardCardGames: BoardAndCardGames,
  cookingBaking: CookingAndBaking,
  photographyVideography: PhotoAndVideo,
  travelTours: TravelAndTours,
  musicFestivals: MusicAndFestivals,
};

interface IGetTextForGroupe {
  (params: {text?: string; t: TFunction<'translation'>}): string;
}

export const getNameForGroupe: IGetTextForGroupe = ({text, t}) => {
  return correctTranslate({
    text: t('groups:defaultGroups.name', {context: text}),
    defaultText: text || '',
  }) as string;
};

export const getDescrForGroupe: IGetTextForGroupe = ({text, t}) => {
  return correctTranslate({
    text: t('groups:defaultGroups.description', {context: text}),
    defaultText: text || '',
  }) as string;
};

export const getUrlForGroupAvatar = (alias: string) => {
  return defaultAvatars[alias] || '';
};

export const groupsToState = (
  groups: Connection<Group> | undefined,
  t: TFunction<'translation'>,
): TGroup[] | undefined => {
  return groups?.edges?.map((el) => {
    const name = getNameForGroupe({
      text: el.node.name,
      t,
    });

    const description = getDescrForGroupe({
      text: el.node.description,
      t,
    });

    const defaultAvatar = getUrlForGroupAvatar(el.node.alias || '');

    const avatar: {Avatar?: PointerFile} = {};

    if (!el.node.Avatar?.file.url && el.node.type === TTypeOfGroup.default && defaultAvatar) {
      avatar.Avatar = {
        file: {
          name: '',
          url: defaultAvatar,
        },
      } as PointerFile;
    }

    return {
      ...el?.node,
      ...avatar,
      Community: {objectId: el.node.Community?.objectId, name: el.node.Community?.name},
      Members: [],
      MembersCount: el?.node.Members?.count,
      name,
      description,
    };
  });
};

export const groupToState = (group: Group | undefined, t: TFunction<'translation'>): TGroup | undefined => {
  const name = getNameForGroupe({
    text: group?.name,
    t,
  });

  const description = getDescrForGroupe({
    text: group?.description,
    t,
  });

  const defaultAvatar = getUrlForGroupAvatar(group?.alias || '');

  const avatar: {Avatar?: PointerFile} = {};

  if (!group?.Avatar?.file.url && group?.type === TTypeOfGroup.default && defaultAvatar) {
    avatar.Avatar = {
      file: {
        name: '',
        url: defaultAvatar,
      },
    } as PointerFile;
  }

  return {
    ...group,
    ...avatar,
    Community: {objectId: group?.Community?.objectId, name: group?.Community?.name},
    Members: [],
    MembersCount: group?.Members?.count || 0,
    name,
    description,
  };
};
