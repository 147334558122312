import styled, {css} from 'styled-components';
import {dropdownPositionType} from './types';
import {theme} from '../theme';

const isOpenStyles = css`
  opacity: 1;
`;

const isHiddenStyles = css`
  display: none;
  opacity: 0;
`;

export const DropdownContainer = styled.div<{
  offsetY?: number;
  offsetX?: number;
  position: dropdownPositionType;
  $isOpen: boolean;
  padding?: string;
}>`
  position: absolute;
  z-index: 1001;
  width: auto;
  max-width: 900px;
  max-height: max-content;
  overflow-y: scroll;
  background: ${theme.palette.background.primary};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  ${(props) => (props.padding ? `padding: ${props.padding}` : ``)};
  top: calc(100% + ${(props) => (props.offsetY ? `${props.offsetY}px` : `14px`)});
  margin-left: ${(props) => (props.offsetX ? `${props.offsetX}px;` : `0px;`)};
  transition: 0.2s all;

  ${(props) => (props.$isOpen ? isOpenStyles : isHiddenStyles)};
  ${(props) => props.position === 'left' && `left: 0px;`};
  ${(props) => props.position === 'right' && `right: 0px;`};
  ${(props) => props.position === 'center' && `left: 50%; transform: translateX(-50%);`};

  &::-webkit-scrollbar {
    width: 0;
  }
`;
