import React from 'react';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Label} from '../../../../ui-kit/Labels';
import {PayoutsItem} from '../../../../types/settings';
import {parseJSON} from 'date-fns';
import {IGetCurrValue, translateFn} from '../../../../types/common';

export const getDate = (t: translateFn) => {
  const Date = (item: PayoutsItem) => {
    return (
      <Text color={'tertdecimal'} variant={'quaternary'} size={12}>
        {item.createdAt && t('common:datePP', {date: parseJSON(item.createdAt)})}
      </Text>
    );
  };
  return Date;
};

export const Listing = (item: PayoutsItem) => {
  return (
    <Text color={'quartdecimal'} variant={'quaternary'} weight={'semi-bold'} size={14}>
      {cutLongName(item.name)}
    </Text>
  );
};

export const getType = (t: translateFn) => {
  const Type = (item: PayoutsItem) => {
    return <Label>{t(`listings:options.${item.listingType}`)}</Label>;
  };
  return Type;
};

export const getPrice = (getSignValue: IGetCurrValue) => {
  const Price = (item: PayoutsItem) => {
    return (
      <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
        {item.price ? getSignValue((item.price / 100).toFixed(2)) : item.rewardType}
      </Text>
    );
  };

  return Price;
};

export const Plan = (item: PayoutsItem) => {
  return (
    <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
      {item.planFee}
    </Text>
  );
};

export const getStatus = (t: translateFn) => {
  const Status = (item: PayoutsItem) => {
    return (
      <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
        {t('listings:status', {context: item.status})}
      </Text>
    );
  };

  return Status;
};

export const Earned = (item: PayoutsItem) => {
  return (
    <Text color={'tertdecimal'} variant={'quaternary'} size={14}>
      {item.calcIncome || 0}
    </Text>
  );
};

function cutLongName(string?: string) {
  if (!string) return;
  if (string.length > 25) return string.slice(0, 25) + '...';
  return string;
}
