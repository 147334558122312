import React from 'react';
import {WrapperPageListings, WrapperSearch} from './styles';
import {TItem} from '../../types/item';
import {TypeCommunity} from '../../types/auth';
import {CommunityStateType} from '../../states/community';
import {ListItemsWithPreview} from './ListItemsWithPreview';
import {Search} from './Search';
import {SetItemsSearchParams} from '../../containers/Listings/types';
import {MobileSearch} from './MobileSearch';
import {route} from '../../constants/routes';
import {EmptyState} from '../EmptyState/EmptyState';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {LikeState} from '../../hooks/item';
import {useTranslation} from 'react-i18next';

interface IProps {
  community: CommunityStateType;
  loading?: boolean;
  allItems?: Partial<TItem>[];
  fetchDataAllItems: () => void;
  hasMoreAllItems: boolean;
  onUnlist: (itemId?: string) => () => void;
  onPublish: (itemId?: string) => () => void;
  setSearchParams: SetItemsSearchParams;
  userId?: string;
  reportActions: reportActions;
  getSignValue: IGetCurrValue;
  onLike?: (id: string) => void;
  liked?: LikeState;
}

export const PageResident: React.FC<IProps> = ({
  allItems,
  hasMoreAllItems,
  fetchDataAllItems,
  community,
  loading,
  setSearchParams,
  userId,
  onPublish,
  onUnlist,
  reportActions,
  getSignValue,
  onLike,
  liked,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  return (
    <>
      <WrapperPageListings>
        <WrapperSearch>
          <Search setSearchParams={setSearchParams} />
          <MobileSearch setSearchParams={setSearchParams} />
        </WrapperSearch>
        {!allItems?.length && !loading ? (
          <EmptyState />
        ) : (
          <ListItemsWithPreview
            communityId={community?.objectId}
            items={allItems}
            fetchDataItems={fetchDataAllItems}
            hasMoreItems={hasMoreAllItems}
            isPreview={false}
            typeUser={TypeCommunity.resident}
            loading={loading}
            userId={userId}
            onPublish={onPublish}
            onUnlist={onUnlist}
            reportActions={reportActions}
            getSignValue={getSignValue}
            onLike={onLike}
            liked={liked}
          />
        )}
        <AddButtonPortal
          link={getLink(route.createItem.path)}
          iconType={'cube'}
          viewBox={'-1 -1 22 22'}
          animated
          animatedText={t('common:addButtons.list')}
        />
      </WrapperPageListings>
    </>
  );
};
