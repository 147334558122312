import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilState} from 'recoil';

import {CommunityPeoplePage} from './CommunityPeoplePage';
import {ManagePeoplePage} from './ManagePeoplePage';
import {ItemsSearchParams} from './types';
import {currentCommunity, userPermissions} from '../../states/community';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {PeopleSearchType} from '../../types/people';
import {PermissionsList} from '../../types/settings';
import {MobContainerProps} from '../MobileTabs';

import {useGetAdminsLabels} from '../../hooks/people';
import {useViewer} from '../../hooks/user';

export const People: React.FC<MobContainerProps> = ({hideNav}) => {
  const viewer = useViewer();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const [permissions] = useRecoilState(userPermissions);
  const [loading, setLoading] = useState(false);
  const isManager = typeUser === TypeCommunity.manager;
  const [searchParams, setSearchParams] = useState<ItemsSearchParams>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [peopleType, setPeopleType] = useState<PeopleSearchType>(PeopleSearchType.Everyone);
  const adminLabels = useGetAdminsLabels();
  const canInvite = useMemo(
    () => (permissions?.includes(PermissionsList.manageInvitation) && typeUser === TypeCommunity.admin) || isManager,
    [permissions, typeUser],
  );

  const canManage = useMemo(
    () => (permissions?.includes(PermissionsList.managePeople) && typeUser === TypeCommunity.admin) || isManager,
    [permissions, typeUser],
  );

  useEffect(() => {
    if (!typeUser) {
      return setLoading(true);
    }
    setLoading(false);
  }, [typeUser]);
  return (
    <>
      {isManager || canManage ? (
        <ManagePeoplePage
          user={viewer}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={loading}
          peopleType={peopleType}
          setPeopleType={setPeopleType}
          community={community}
          adminLabels={adminLabels}
          hideNav={hideNav}
          canInvite={canInvite}
        />
      ) : (
        <CommunityPeoplePage
          user={viewer}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={loading}
          peopleType={peopleType}
          setPeopleType={setPeopleType}
          community={community}
          canInvite={canInvite}
          canManage={canManage}
          hideNav={hideNav}
        />
      )}
    </>
  );
};
