import React from 'react';
import {TabWrapper, TabItem, TabItemTitle} from './styles';
import {TabProps} from './types';
import {IsNoticesLbl} from '../../../ui-kit/Navigation/styles';

export const Tab: React.FC<TabProps> = ({title, isActive, onClick, redDot}) => {
  return (
    <TabWrapper>
      <TabItem $isActive={isActive} onClick={onClick}>
        <TabItemTitle $padd={redDot}>
          {title}
          {redDot && <IsNoticesLbl top="0px" />}
        </TabItemTitle>
      </TabItem>
    </TabWrapper>
  );
};
