import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AsideEdit,
  CardInsight,
  InnerEditAside,
  InnerInsights,
  TitleInsight,
  ValueInsight,
  WrapperEditFormAside,
  SubTitle,
} from './styles';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {DaysList, shortToFullDay} from '../../helpers/common';

type TProps = {
  totalBooking?: number;
  countViewers?: number;
  popularTime?: Date;
  popularDay?: string;
  $isPublished?: boolean;
  dateAndTime?: Date;
  endTime?: Date;
  location?: string;
  width?: string;
  onPublish?: () => void;
  onUnlist?: () => void;
  onDelete?: () => void;
  editPath?: string;
  className?: string;
  isAdmHidden?: boolean;
  communityListPath?: string;
};

export const EditFormAside: React.FC<TProps> = ({
  countViewers,
  onDelete,
  width,
  className,
  totalBooking,
  popularTime,
  popularDay,
}) => {
  const {t} = useTranslation();
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const dataInsights = useMemo(() => {
    return [
      {
        title: t('amenities:insights.views'),
        value: countViewers || 0,
      },
      {
        title: t('amenities:insights.totalBookings'),
        value: totalBooking || 0,
      },
      {
        title: t('amenities:insights.popularTime'),
        value: (popularTime && format(new Date(popularTime), 'p', getLanguage())) || '-',
      },
      {
        title: t('amenities:insights.popularDay'),
        value: (popularDay && t(`common:days.${shortToFullDay?.[popularDay as DaysList]}`)) || '-',
      },
    ];
  }, [countViewers, totalBooking, popularTime, popularDay]);

  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('amenities:modals.delete.title'),
        okText: t('amenities:modals.delete.okText'),
        cancelText: t('amenities:modals.delete.cancelText'),
      },
    };
  }, []);
  const showInsights = useMemo(
    () => !!dataInsights.filter((el) => el.value !== 0 && el.value !== '-').length,
    [dataInsights],
  );
  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  return (
    <>
      {showInsights && (
        <InnerEditAside width={width} className={className}>
          <>
            <SubTitle>{t('amenities:insights.title')}</SubTitle>
            <AsideEdit>
              <WrapperEditFormAside>
                <div>
                  <InnerInsights>
                    {dataInsights?.map((it) => (
                      <CardInsight key={it.title}>
                        <TitleInsight>{it.title}</TitleInsight>
                        <ValueInsight>{it.value}</ValueInsight>
                      </CardInsight>
                    ))}
                  </InnerInsights>
                </div>
              </WrapperEditFormAside>
            </AsideEdit>
          </>
        </InnerEditAside>
      )}
      {isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={onDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
    </>
  );
};
