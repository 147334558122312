import styled, {css} from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import {Media} from '../../../ui-kit/theme';

export const ChangePostTypeIcon = styled.div`
  background-color: ${({theme}) => theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 0;
  position: relative;
  width: 20px;
  height: 20px;
  outline: none;
  position: relative;
  & > div {
    transform: translateX(30%);
  }
  & > div > div {
    transform: translateY(-10%) translateX(100%);
  }
`;

export const InputsAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  ${Media.up.m} {
    flex-direction: column;
    .generate {
      width: auto;
      span {
        display: none;
      }
    }
  }
`;

export const CancelWrapper = styled.div<{$isEdit?: boolean}>`
  & > button {
    margin-right: 20px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  ${Media.down.l} {
    ${({$isEdit}) => !$isEdit && 'display: none;'}
  }
`;

export const PrimarySubmitButton = styled.div<{$isEdit?: boolean}>`
  ${Media.down.l} {
    ${({$isEdit}) => !$isEdit && 'display: none;'}
  }
`;

export const SecondarySubmitButton = styled.div<{$isEdit?: boolean}>`
  ${({$isEdit}) => $isEdit && 'display: none;'}

  ${Media.up.l} {
    display: none;
  }
`;

const partsPaddingStyles = css`
  padding-left: 26px;
  padding-right: 39px;
  ${Media.down.m} {
    padding-left: 12px;
    padding-right: 6px;
  }
`;

export const CommentInput = styled(TextareaAutosize)`
  max-height: 100px;
  min-height: 30px;
  border-radius: 25px;
  padding: 7px 18px;
  box-sizing: border-box;
  border: none;
  outline: none;
  flex-grow: 1;
  display: block;
  height: 100%;
  width: 98%;
  padding-right: 45px;
  background-color: ${({theme}) => theme.palette.background.septenary};
  font-size: 12px;
  resize: none;
  overflow: hidden;
  &::placeholder {
    font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
    font-size: 12px;
    line-height: 150%;
    color: ${({theme}) => theme.palette.text.septenary};
    ${Media.down.xs} {
      font-size: 12px;
    }
    ${Media.down.l} {
      font-size: 16px;
    }
  }
  ${Media.down.l} {
    margin: 0 14px 0 10px;
    padding: 7px 12px;
    padding-right: 45px;
    font-size: 16px;
  }
`;

export const CommentSend = styled.button<{isDisabled?: boolean}>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  right: 4px;
  top: 55%;
  transform: translateY(-50%);
  position: absolute;
  ${Media.down.m} {
    right: -10px;
  }
  ${({isDisabled}) => isDisabled && `opacity: 0.5; pointer-events: none;`};
`;

export const PostCommendWrapper = styled.div`
  padding-left: 96px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  ${Media.down.m} {
    ${partsPaddingStyles};
    padding-left: 12px;
    padding-right: 4px;
  }
`;

export const CommentInputContainer = styled.div<{$isEdit?: boolean}>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  & svg,
  & img {
    flex-grow: 1;
    min-width: 36px;
    margin-right: 10px;
    margin-top: 16px;
  }
  padding-right: 6px;
  .EmojiAction svg {
    margin-top: 0px !important;
    margin-right: 0px !important;
  }
  .emoji-mart-search-icon svg {
    margin-top: 0px !important;
    margin-right: 0px !important;
  }
`;

export const CommentInputWrapper = styled.div`
  flex-grow: 1;
  margin-left: 15px;

  .EmojiAction {
    position: absolute;
    transform: translateY(-100%);
    right: 30px;
  }

  .ModalWindow {
    top: revert;
    bottom: revert;
  }

  ${Media.down.s} {
    margin-left: 1px;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  padding-left: 100px;

  & > :last-child {
    margin-right: 14px;
  }

  ${Media.down.m} {
    padding-left: 16px;
  }
`;
