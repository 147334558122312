import React from 'react';
import {NotInText, NotInWrapper, Icon} from './styles';
import {useTranslation} from 'react-i18next';
import {NavContainer} from '../../../containers/Navigation';
import {MobileBtn} from '../../Listings/Slider/styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';

type TProps = {
  type: 'listing' | 'user';
};

const NotInCommunity: React.FC<TProps> = ({type}) => {
  const {t} = useTranslation();
  const text = t(`profile:notInCommunity.${type}`);
  const handleBackNoReactRouter = () => window?.history.back();
  return (
    <NavContainer>
      <MobileBtn top={'64px'} $align={'left'} onClick={handleBackNoReactRouter}>
        <IconSvg type={'close'} width={'32'} height={'32'} viewBox={'1 -3 18 26'} />
      </MobileBtn>
      <NotInWrapper>
        <Icon />
        <NotInText>{text}</NotInText>
      </NotInWrapper>
    </NavContainer>
  );
};

const MemoNotInCommunity = React.memo(NotInCommunity);
export {MemoNotInCommunity};
