import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {EditFormAside} from './EditFormAside';
import {RequestAside} from './RequestAside';
import {MemoReviewerCard} from './ReviewerCard';
import {Slider} from './Slider';
import {
  DesktopView,
  InfoListing,
  InnerInfo,
  InnerListingView,
  InnerTitleReviewers,
  InnerWithDelimiter,
  ListingViewDescr,
  ListingViewSubTitle,
  ListingViewTitle,
  MainContListingView,
  MobileView,
  WrapperListingView,
  InnerInfoTags,
  MobileListingWrapper,
} from './styles';
import {route} from '../../constants/routes';
import {AddCreditCardPopup} from '../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {NavContainer} from '../../containers/Navigation';
import {makeSeoKeywords} from '../../helpers/common';
import {getResize} from '../../helpers/file';
import {getUserName} from '../../helpers/user';
import {TypeCommunity} from '../../types/auth';
import {ItemFormAction, TItem} from '../../types/item';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {SendMessageStatus, TagType} from '../../ui-kit/RequestCard/types';
import {Linkify} from '../common/Buttons/Linkify';
import {RichText} from '../common/RichText/RichText';
import {ListingViewSkeleton} from '../common/Skeletons/ListingViewSkeleton';
import {ListingNotFound} from '../Extras';
import {Seo} from '../SEO';

import {useLinks} from '../../hooks/common';
import {useGetMessagesAllows} from '../../hooks/community';
import {TGetInsightsItem} from '../../hooks/item';
import {TDataLister, TInvoice, TResCreateOrder} from '../../hooks/order';
import {Gallery} from 'react-photoswipe-gallery';
import {getAmountLabel} from '../../helpers/order';
import {useGetCurrencyValue} from '../../hooks/currency';
import {CardTags} from '../../ui-kit/RequestCard/CardTags';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {isModal} from '../../states/themeMode';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {PopupView} from '../common/Modal/PopupView';
import {analyticsTrackFN} from '../../helpers/account';
import {CommunityStateType} from '../../states/community';
import {useViewer} from '../../hooks/user';
import {prevLocation} from '../../states/navState';
import {useHistory} from 'react-router-dom';

interface IProps {
  item?: Partial<TItem>;
  $loading?: boolean;
  dataInsights?: TGetInsightsItem;
  action: ItemFormAction;
  onPublish?: () => void;
  onUnlist?: () => void;
  viewerObjId?: string;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataOrder?: TResCreateOrder;
  dataLister?: TDataLister;
  dataOrderSubmit?: () => void;
  dataInvoice?: TInvoice;
  onDelete?: () => void;
  onRenew?: () => void;
  onSold?: () => void;
  isAvailable?: boolean;
  community?: CommunityStateType;
  requestAccess?: boolean;
  busyDates?: Date[];
  refetch?: () => void;
  typeUser?: TypeCommunity | null;
  manyCommunities: boolean;
  commList?: {objectId?: string; name?: string}[];
  previewLink?: string;
  isPreview?: boolean;
  handleLike?: () => Promise<boolean>;
}
const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};
export const ViewListing: React.FC<IProps> = ({
  item,
  $loading,
  action,
  onUnlist,
  onPublish,
  viewerObjId,
  dataCreateMsg,
  dataOrder,
  dataLister,
  dataInvoice,
  onDelete,
  onRenew,
  isAvailable,
  community,
  requestAccess,
  busyDates,
  refetch,
  typeUser,
  manyCommunities,
  dataOrderSubmit,
  onSold,
  commList,
  previewLink,
  isPreview,
  handleLike,
}) => {
  const {t} = useTranslation();
  const textSeo = item?.name || '';
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const viewerIsNotOwner = action === ItemFormAction.view && viewerObjId !== item?.Lister?.objectId;
  const isAdmHidden = Boolean(item?.AdmHidden?.filter((el) => el.objectId === community?.objectId).length);
  const isPublished = Boolean(item?.Published?.filter((el) => el.objectId === community?.objectId).length);
  const hasApprovalRequest = Boolean(item?.ApproveReq?.filter((el) => el.objectId === community?.objectId).length);
  const descrBlock = useRef<HTMLDivElement>(null);
  const imagesBlock = useRef<HTMLDivElement>(null);
  const sendMessageRef = useRef<HTMLDivElement>(null);
  const requestCardRef = useRef<HTMLDivElement>(null);
  const setIsModal = useSetRecoilState(isModal);
  const changeCardStatusRef = useRef<(status: SendMessageStatus) => void>();
  const [noRequestModal, setNoRequestModal] = useState<boolean>(false);
  const [showPropositionAddCardPopup, setShowProposition] = useState<boolean>(false);
  const showWarnPopup = () => setShowProposition(true);
  const showAddCardPopup = () => setNoRequestModal(true);
  const {getLink} = useLinks();
  const viewer = useViewer();
  const {push} = useHistory();
  const [isShown, setIsShown] = useState(false);
  const [prevpath] = useRecoilState(prevLocation);

  useEffect(() => {
    if (isShown) {
      analyticSend('Credit Card Prompt');
    }
  }, [isShown]);
  const handleBackNoReactRouter = () => {
    prevpath ? window?.history.back() : push(getLink(route.listings.path));
  };
  const blocksHeight = (descrBlock?.current?.offsetHeight || 0) + (imagesBlock.current?.offsetHeight || 0) || undefined;
  const requestCardRange = blocksHeight ? blocksHeight + 60 : blocksHeight;

  const listingOg = useMemo(() => {
    return {
      title: item?.name || item?.category,
      description: item?.descr,
      image: item?.images?.[0]?.file?.url,
    };
  }, [item]);

  const tagRewardType = getAmountLabel(item);
  const {getSignValue, currency} = useGetCurrencyValue();
  const tags = [
    {label: t(`listings:listingsType.${item?.listingType}`), type: TagType.listingType},
    {
      label: t(tagRewardType.label, {value: getSignValue(tagRewardType?.opts || 0)}),
      type: tagRewardType.type,
    },
  ];

  const onOpen = () => {
    if (!isMobile) return;
    setIsModal?.(true);
    const interval = setInterval(() => {
      const element = document?.querySelector('.pswp--open');
      if (!element) {
        clearInterval(interval);
        setIsModal?.(false);
      }
    }, 500);
  };
  // const handleWriteMsg = useCallback(() => {
  //   return changeCardStatusRef.current?.(SendMessageStatus.Send);
  // }, [changeCardStatusRef.current]);
  // const handleScrollMessage = () => {
  //   handleWriteMsg?.();
  //   sendMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  // };
  // const handleScrollRequest = () => {
  //   requestCardRef.current?.scrollIntoView({behavior: 'smooth'});
  // };

  const handleCloseAddCreditCardPopup = () => {
    setNoRequestModal(false);
    setIsShown(true);
    refetch?.();
  };

  const closeProposition = () => {
    setShowProposition(false);
  };

  const handleAddCC = () => {
    closeProposition();
    showAddCardPopup();
  };

  // useEffect(() => {
  //   addEventListener('scroll', () => {
  //     if (!requestCardRef) return;
  //     const isShow =
  //       (requestCardRef.current?.getBoundingClientRect().bottom || 0) >
  //       window.pageYOffset + document.documentElement.clientHeight;
  //     setShowBottomMenu(isShow);
  //   });
  // }, []);
  const analyticSend = (key?: string) => {
    analyticsTrackFN(key ? key : 'Listing Liked', {
      listingId: item?.id,
      listingType: item?.listingType, // service, rental, buy/sell
      listedFor: item?.rewardType, // coffee, free, money
      photoCount: item?.images?.length,
      title: item?.name,
      description: item?.descr,
      rate: item?.price,
      currency: currency?.code,
      bookingType: item?.pricePeriod,
      category: item?.category,
      listerName: `${item?.Lister?.firstName}`,
      listerEmail: item?.Lister?.email,
      listerId: item?.Lister?.objectId,
      likedByName: `${viewer?.firstName}`,
      likedByEmail: viewer?.email,
      likedById: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type, // building, neighbourhood, circle
    });
  };
  const allows = useGetMessagesAllows({directUserId: item?.Lister?.objectId});
  const hasDescr = !!item?.descr?.replaceAll('<p>', '')?.replaceAll('</p>', '').trim()?.length;
  return (
    <NavContainer>
      {!requestAccess && noRequestModal && (
        <>
          {isMobile ? (
            <PopupView>
              <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />
            </PopupView>
          ) : (
            <AddCreditCardPopup handleClose={handleCloseAddCreditCardPopup} />
          )}
        </>
      )}
      <Seo title={textSeo} keywords={seoKeywords} og={listingOg} />
      {$loading ? (
        <ListingViewSkeleton />
      ) : item?.isDeleted || !item?.objectId ? (
        <ListingNotFound goBack={handleBackNoReactRouter} />
      ) : (
        <>
          <DesktopView>
            <MainContListingView ref={imagesBlock}>
              <WrapperListingView>
                <InnerListingView>
                  <ListingViewTitle>
                    <Linkify>{item?.name || item?.category}</Linkify>
                    <CardTags tags={tags} />
                  </ListingViewTitle>
                  <InnerInfo>
                    {(!!item?.rating || !!item?.Reviews?.count) && (
                      <InnerWithDelimiter>
                        <IconSvg type={'star'} fill={'orange'} stroke={'orange'} width={'9px'} height={'9px'} />
                        <span>{`${item?.rating?.toFixed(2) || 0} (${item?.Reviews?.count ?? 0})`}</span>
                      </InnerWithDelimiter>
                    )}
                    <InfoListing>
                      {t('listings:view.posted')}
                      {item?.createdAt && t('common:dateMMMM_yyyy', {date: new Date(item?.createdAt)})}
                    </InfoListing>
                  </InnerInfo>
                  <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
                    <Slider images={item?.images} />
                  </Gallery>
                  {hasDescr && (
                    <MainContListingView ref={descrBlock}>
                      <WrapperListingView offsetTop={'25px'}>
                        <ListingViewSubTitle>{t('common:descr')}</ListingViewSubTitle>
                        <ListingViewDescr>
                          <RichText html={item?.descr} />
                        </ListingViewDescr>
                      </WrapperListingView>
                    </MainContListingView>
                  )}
                  {isPreview ? (
                    <RequestAside
                      width={'300px'}
                      user={dataLister}
                      descr={t('requests:descr.send')}
                      item={item}
                      dataCreateMsg={dataCreateMsg}
                      dataOrder={dataOrder}
                      dataInvoice={dataInvoice}
                      dataOrderSubmit={dataOrderSubmit}
                      className={'listing-view__form-aside'}
                      changeCardStatusRef={changeCardStatusRef}
                      sendMessageRef={sendMessageRef}
                      requestCardRef={requestCardRef}
                      isAvailable={isAvailable}
                      accessRequest={requestAccess}
                      showWarnPopup={showWarnPopup}
                      busyDates={busyDates}
                      typeUser={typeUser}
                      canSandMessage={allows.allow}
                      description={item?.descr}
                      isPreview={isPreview}
                      noImages={!item?.images?.length}
                    />
                  ) : (
                    <>
                      {viewerIsNotOwner && item?.isPublished ? (
                        <RequestAside
                          width={'300px'}
                          user={dataLister}
                          descr={t('requests:descr.send')}
                          item={item}
                          dataCreateMsg={dataCreateMsg}
                          dataOrder={dataOrder}
                          dataInvoice={dataInvoice}
                          dataOrderSubmit={dataOrderSubmit}
                          className={'listing-view__form-aside'}
                          bottomBoundary={requestCardRange}
                          isAvailable={isAvailable}
                          isDesktop
                          accessRequest={requestAccess}
                          showWarnPopup={showWarnPopup}
                          busyDates={busyDates}
                          canSandMessage={allows.allow}
                          typeUser={typeUser}
                          noImages={!item?.images?.length}
                        />
                      ) : (
                        <>
                          {!viewerIsNotOwner && (
                            <EditFormAside
                              width={'300px'}
                              $isPublished={isPublished}
                              onUnlist={onUnlist}
                              onPublish={onPublish}
                              countViewers={item?.totalViews}
                              className={'listing-view__form-aside'}
                              editPath={getLink(route.editItem.get({id: item?.objectId || ''}))}
                              amountIncome={Number(item?.income) / 100}
                              completedCount={item?.totalCompletedOrders}
                              totalCount={item?.totalRequests}
                              hasApprovalRequest={hasApprovalRequest}
                              isAdmHidden={isAdmHidden}
                              onDelete={onDelete}
                              isSold={item?.isSold}
                              onRenew={onRenew}
                              onSold={onSold}
                              published={item?.Published}
                              communityListPath={getLink(
                                route.configureListCommunities.get({id: item?.objectId || ''}),
                              )}
                              manyCommunities={manyCommunities}
                              commList={commList}
                              previewLink={previewLink}
                              listingType={item?.listingType}
                              getSignValue={getSignValue}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </InnerListingView>
              </WrapperListingView>
            </MainContListingView>
            <MainContListingView>
              <WrapperListingView offsetTop={'33px'}>
                <InnerTitleReviewers>
                  {(!!item?.rating || !!item?.Reviews?.count) && (
                    <ListingViewSubTitle>
                      <IconSvg type={'star'} stroke={'orange'} width={'18px'} height={'18px'} />
                      {item?.Reviews?.count || 0} {t('common:reviews')}
                    </ListingViewSubTitle>
                  )}
                </InnerTitleReviewers>
                {item?.Reviews?.edges?.map((r) => {
                  return (
                    <MemoReviewerCard
                      key={r.node.id}
                      name={`${getUserName(typeUser, r.node.Author.firstName, r.node.Author.lastName)}`}
                      avatar={getResize(r.node?.Author?.Avatar?.file?.url, 'lg')}
                      descr={r.node.text}
                      rating={r.node.stars}
                      createAt={r.node.createdAt}
                      isOnline={r.node.Author.isOnline}
                    />
                  );
                })}
              </WrapperListingView>
            </MainContListingView>
          </DesktopView>

          <MobileView>
            <MobileListingWrapper>
              <MainContListingView>
                <Gallery
                  shareButton={false}
                  zoomButton={true}
                  fullscreenButton={false}
                  options={options}
                  onOpen={onOpen}>
                  <Slider
                    images={item?.images}
                    communityAlias={community?.alias}
                    isPreview={isPreview}
                    isEdit={!viewerIsNotOwner && !isPreview}
                    previewLink={previewLink}
                    editLink={getLink(route.editItem.get({id: item?.objectId || ''}))}
                    handleLike={handleLike}
                    isLike={item?.isLike}
                    analyticSend={analyticSend}
                  />
                </Gallery>
                <WrapperListingView>
                  <InnerListingView>
                    <ListingViewTitle>{item?.name || item?.category}</ListingViewTitle>
                    <InnerInfoTags>
                      <CardTags tags={tags} />
                      {(!!item?.rating || !!item?.Reviews?.count) && (
                        <InnerWithDelimiter>
                          <IconSvg type={'star'} fill={'orange'} stroke={'orange'} width={'9px'} height={'9px'} />
                          <span>{`${item?.rating?.toFixed(2) || 0} (${item?.Reviews?.count ?? 0})`}</span>
                        </InnerWithDelimiter>
                      )}
                    </InnerInfoTags>
                  </InnerListingView>
                </WrapperListingView>
              </MainContListingView>
              <MainContListingView>
                <WrapperListingView offsetTop={'6px'}>
                  {isPreview ? (
                    <RequestAside
                      width={'300px'}
                      user={dataLister}
                      descr={t('requests:descr.send')}
                      item={item}
                      dataCreateMsg={dataCreateMsg}
                      dataOrder={dataOrder}
                      dataInvoice={dataInvoice}
                      dataOrderSubmit={dataOrderSubmit}
                      className={'listing-view__form-aside'}
                      changeCardStatusRef={changeCardStatusRef}
                      sendMessageRef={sendMessageRef}
                      requestCardRef={requestCardRef}
                      isAvailable={isAvailable}
                      accessRequest={requestAccess}
                      showWarnPopup={showWarnPopup}
                      busyDates={busyDates}
                      typeUser={typeUser}
                      canSandMessage={allows.allow}
                      description={item?.descr}
                      isPreview={isPreview}
                    />
                  ) : (
                    <>
                      {viewerIsNotOwner && item?.isPublished ? (
                        <RequestAside
                          width={'300px'}
                          user={dataLister}
                          descr={t('requests:descr.send')}
                          item={item}
                          dataCreateMsg={dataCreateMsg}
                          dataOrder={dataOrder}
                          dataInvoice={dataInvoice}
                          dataOrderSubmit={dataOrderSubmit}
                          className={'listing-view__form-aside'}
                          changeCardStatusRef={changeCardStatusRef}
                          sendMessageRef={sendMessageRef}
                          requestCardRef={requestCardRef}
                          isAvailable={isAvailable}
                          accessRequest={requestAccess}
                          showWarnPopup={showWarnPopup}
                          busyDates={busyDates}
                          typeUser={typeUser}
                          canSandMessage={allows.allow}
                          description={item?.descr}
                        />
                      ) : (
                        <>
                          {!viewerIsNotOwner && (
                            <EditFormAside
                              width={'300px'}
                              $isPublished={isPublished}
                              onUnlist={onUnlist}
                              onPublish={onPublish}
                              countViewers={item?.totalViews}
                              className={'listing-view__form-aside'}
                              editPath={getLink(route.editItem.get({id: item?.objectId || ''}))}
                              amountIncome={Number(item?.income) / 100}
                              completedCount={item?.totalCompletedOrders}
                              totalCount={item?.totalRequests}
                              hasApprovalRequest={hasApprovalRequest}
                              isAdmHidden={isAdmHidden}
                              onDelete={onDelete}
                              isSold={item?.isSold}
                              onRenew={onRenew}
                              communityListPath={getLink(
                                route.configureListCommunities.get({id: item?.objectId || ''}),
                              )}
                              manyCommunities={manyCommunities}
                              commList={commList}
                              published={item?.Published}
                              previewLink={previewLink}
                              listingType={item?.listingType}
                              getSignValue={getSignValue}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </WrapperListingView>
              </MainContListingView>

              {!!item?.Reviews?.edges?.length && (
                <MainContListingView>
                  <WrapperListingView offsetTop={'33px'} $isReviews>
                    <InnerTitleReviewers>
                      <ListingViewSubTitle>{t('common:reviews')}</ListingViewSubTitle>
                    </InnerTitleReviewers>
                    {item?.Reviews?.edges?.map((r) => {
                      return (
                        <MemoReviewerCard
                          key={r.node.id}
                          name={`${getUserName(typeUser, r.node.Author.firstName, r.node.Author.lastName)}`}
                          avatar={getResize(r.node?.Author?.Avatar?.file?.url, 'lg')}
                          descr={r.node.text}
                          rating={r.node.stars}
                          createAt={r.node.createdAt}
                          isOnline={r.node.Author.isOnline}
                        />
                      );
                    })}
                  </WrapperListingView>
                </MainContListingView>
              )}
              {/* {viewerIsNotOwner && showBottomMenu && (
              <MobileBar>
                <Button variant={'secondary'} ghost={true} onClick={handleScrollMessage}>
                  {t('listings:view.buttons.message')}
                </Button>
                <Button variant={'primary'} onClick={handleScrollRequest}>
                  {t('listings:view.buttons.checkAvailability')}
                </Button>
              </MobileBar>
            )} */}
            </MobileListingWrapper>
          </MobileView>
          {showPropositionAddCardPopup && (
            <BasePortal>
              <Confirmation
                title={t('common:modals.addCC.title')}
                subTitle={t('common:modals.addCC.descr')}
                okText={t('common:modals.addCC.button')}
                onClose={closeProposition}
                onOk={handleAddCC}
              />
            </BasePortal>
          )}
        </>
      )}
    </NavContainer>
  );
};
