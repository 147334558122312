import {ParseObject} from './parse';

export enum LocationType {
  city = 'city',
  state = 'state',
  country = 'country',
  buildings = 'buildings',
  units = 'units',
}

export enum LocationFields {
  name = 'name',
  type = 'type',
  objectId = 'objectId',
}

export interface Location extends ParseObject {
  id?: string;
  name?: string;
  type?: string;
  code?: string;
  City?: Location;
  State?: Location;
  Country?: Location;
  isDisabled?: boolean;
}

export const commonFields = [LocationFields.objectId, LocationFields.name, LocationFields.type];
