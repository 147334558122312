import React, {useState} from 'react';
import {CommentInputContainer, CommentInputWrapper, CommentSend} from '../styles';
import {PostCommentInputProps} from '../types';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {useTranslation} from 'react-i18next';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {useCreatePost} from '../../../../hooks/feed';
import {PostTypeT} from '../../../../types/post';
import {useViewer} from '../../../../hooks/user';
import {getResize} from '../../../../helpers/file';
import {EmojiTab} from '../../../common/EmojiTab/EmojiTab';
import {RichTextEditor} from '../../../common/RichText/Editor';
import {handleEditorChangeForUserEvent, handleSetUser, handleSetEvent} from '../../../../helpers/common';
import {UserMenu} from '../../../Account/Popups/HeaderMenu/UserMenu';
import {EventMenu} from '../../../Account/Popups/HeaderMenu/EventMenu';
import {RTEdefault} from '../../../../constants/common';

export const PostCommentInput: React.FC<PostCommentInputProps> = ({
  objectId,
  refetchComments,
  Author,
  addLocalPost,
  onLocalSuccess,
  toggleCreating,
}) => {
  const viewer = useViewer();
  const {t} = useTranslation();
  const placeholder = t('community:loop.post.comment.placeholder');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const removeDisable = () => setBtnDisabled(false);
  const [emoji, setEmoji] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [positionSubMenu, setPositionSubMenu] = useState({top: 0, left: 0});
  if (!objectId) return null;

  const {onChange, values, onSubmit} = useCreatePost({
    initialState: {text: '', type: PostTypeT.comment, Parent: {link: objectId}},
    AuthorParent: Author,
    onSuccess: () => {
      refetchComments?.();
      removeDisable();
    },
    addLocalPost,
    onLocalSuccess: () => {
      onLocalSuccess?.();
      removeDisable();
    },
  });

  const handleChane = (next: {name: string; value: string; key: string}, pos: any, existingValues: string) => {
    handleEditorChangeForUserEvent(
      next,
      pos,
      existingValues,
      positionSubMenu,
      setPositionSubMenu,
      userMenuOpen,
      setUserMenuOpen,
      eventMenuOpen,
      setEventMenuOpen,
      setSearchText,
    );
    onChange({name: next.name, value: next.value});
    removeDisable();
  };
  // const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   onChange({name: 'text', value: e.target.value});
  //   removeDisable();
  // };
  const handleSubmit = () => {
    if (values?.text?.trim()) {
      !btnDisabled && onSubmit();
      onChange({name: 'text', value: ''});
      setBtnDisabled(true);
    }
  };

  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };
  const onFocus = () => {
    toggleCreating?.(true);
  };
  const onBlur = () => {
    toggleCreating?.(false);
  };
  return (
    <CommentInputContainer>
      <Avatar src={getResize(viewer?.Avatar?.file?.url, 'lg')} size={'sm'} />
      <CommentInputWrapper>
        <RichTextEditor
          placeholder={placeholder}
          name={'text'}
          value={values?.text}
          onChange={(e, position) => handleChane(e, position, values?.text ? values?.text : RTEdefault)}
          rows={8}
          emoji={emoji}
          setEmoji={setEmoji}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          eventName={eventName}
          setEventName={setEventName}
          resetWatcher={values?.text ? 'editing' : 'reset'}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
        {userMenuOpen && (
          <UserMenu
            top={positionSubMenu.top}
            left={positionSubMenu.left}
            handleSetUser={handleSetUser}
            setUserProfile={setUserProfile}
            searchText={searchText}></UserMenu>
        )}
        {eventMenuOpen && (
          <EventMenu
            top={positionSubMenu.top}
            left={positionSubMenu.left}
            handleSetEvent={handleSetEvent}
            setEventName={setEventName}
            searchText={searchText}></EventMenu>
        )}
        <CommentSend onClick={handleSubmit} isDisabled={btnDisabled} disabled={btnDisabled}>
          <IconSvg type={'send'} width={'20px'} height={'20px'} />
        </CommentSend>
      </CommentInputWrapper>
    </CommentInputContainer>
  );
};
