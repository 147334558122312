import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {CommunityItemStateType, CommunityStateType} from '../../states/community';
import {TypeCommunity} from '../../types/auth';
import {commLoading} from '../../types/community';
import {TCompany} from '../../types/company';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {CommunityLabel} from './CommunityLabel';
import {ItemCard} from './ItemCard';
import {
  ListingCreatedButtonsWrapper,
  ListingCreatedCardWrapper,
  ListingCreatedContentWrapper,
  ListingCreatedSubtitle,
  ListingCreatedTitle,
  ListingCreatedWrapper,
  TopWrapper,
} from './styles';

const modeName: Record<string, string> = {
  true: 'review',
  false: 'managing',
};

interface EventCreatedProps {
  userId: string;
  typeUser: TypeCommunity;
  item?: TCompany;
  community: CommunityStateType;
  communities: Array<CommunityItemStateType>;
  onSubmit: (communityIdsPublish?: string[] | undefined, communityIdsUnlist?: string[] | undefined) => void;
  reviewMode: boolean;
  isConfigureMode?: boolean;
  allCommunitiesIds: string[];
  publishedList: string[];
  setPublishedList: React.Dispatch<React.SetStateAction<string[]>>;
  initialListCommunity?: string[];
  addCommLoading?: commLoading;
  setCommLoading?: (t: commLoading) => void;
}

export const BizCreated: React.FC<EventCreatedProps> = ({
  item,
  communities,
  community,
  onSubmit,
  reviewMode,
  setPublishedList,
  publishedList,
  allCommunitiesIds,
  isConfigureMode,
  initialListCommunity = [],
  addCommLoading,
  typeUser,
  setCommLoading,
}) => {
  const {t} = useTranslation();
  const {push} = useHistory();
  const {getLink} = useLinks();

  const isSelectAll: boolean = allCommunitiesIds.length === publishedList.length;

  const title = t('company:localBiz.configurePublishing.title', {context: modeName[String(reviewMode)]});
  const subtitle = t('company:localBiz.configurePublishing.subtitle', {context: modeName[String(reviewMode)]});

  const handleRedirect = (to: string) => () => push(to);
  const handlePublish = () => {
    setCommLoading?.('one');
    onSubmit(
      publishedList.filter((communityId) => !initialListCommunity?.includes(communityId)),
      allCommunitiesIds.filter(
        (communityId) => !publishedList.includes(communityId) && initialListCommunity?.includes(communityId),
      ),
    );
  };
  /*const handlePublishForAll = () => {
    setCommLoading?.('all');
    onSubmit(allCommunitiesIds.filter((communityId) => !item?.Published?.find((el) => el.objectId === communityId)));
    setPublishedList(allCommunitiesIds);
  };*/

  const communitiesList = useMemo(
    () => (reviewMode ? communities.filter((el) => el?.objectId && publishedList.includes(el.objectId)) : communities),
    [communities, reviewMode, publishedList],
  );

  const getOnChange = (id?: string) => (checked?: boolean) => {
    if (!id) return;
    checked ? setPublishedList((prev) => [...prev, id]) : setPublishedList((prev) => prev.filter((el) => el !== id));
  };

  if (!item) return null;

  const changeChooseCommunities = () => {
    if (isSelectAll) {
      setPublishedList([]);
    } else {
      setPublishedList(allCommunitiesIds);
    }
  };
  return (
    <ListingCreatedWrapper>
      <ListingCreatedContentWrapper>
        <ListingCreatedTitle>{title}</ListingCreatedTitle>
        <ListingCreatedSubtitle>{subtitle}</ListingCreatedSubtitle>
        <ListingCreatedCardWrapper>
          <ItemCard
            to={getLink(route.bizView.get({id: item?.id || '/'}))}
            item={item}
            src={item?.images?.[0]?.file?.url}
            communityId={community?.id}
            isManager={false}
          />
        </ListingCreatedCardWrapper>
        <TopWrapper>
          <ListingCreatedSubtitle>{t('events:created.postedIn')}:</ListingCreatedSubtitle>
          {!!communitiesList.length && communitiesList.length > 1 && !reviewMode && (
            <Button
              variant={'select'}
              width={'73px'}
              height={'21px'}
              ghost
              fontSize={'10px'}
              onClick={changeChooseCommunities}>
              {!isSelectAll ? t('buttons:common.selectAll') : t('buttons:common.unselectAll')}
            </Button>
          )}
        </TopWrapper>
        {communitiesList.map((el) => {
          const isChecked = !!el?.objectId && publishedList.includes(el.objectId);
          return (
            <CommunityLabel
              key={`${el.objectId}` + isChecked}
              community={el}
              icon={<IconSvg type={'people'} />}
              count={el.countMembers}
              isCheckboxMode={!reviewMode}
              isChecked={isChecked}
              onChange={getOnChange(el?.objectId)}
              isPublished={reviewMode || (el.objectId ? initialListCommunity?.includes(el.objectId) : false)}
              typeUser={typeUser}
            />
          );
        })}
        <ListingCreatedButtonsWrapper>
          {reviewMode ? (
            <>
              <Button
                type={'button'}
                variant={'primary'}
                rounded={true}
                onClick={handleRedirect(getLink(route.createBiz.path))}>
                {t('events:created.addAnother')}
              </Button>
              <Button
                type={'button'}
                variant={'secondary'}
                rounded={true}
                ghost={true}
                onClick={handleRedirect(getLink(route.editBiz.get({id: item?.id || '/'})))}>
                {t('events:created.edit')}
              </Button>
            </>
          ) : isConfigureMode ? (
            <>
              <Button
                variant={'secondary'}
                ghost
                rounded={true}
                onClick={handleRedirect(getLink(route.eventView.get({id: item?.id || '/'})))}>
                {t('events:created.cancel')}
              </Button>
              <Button variant={'primary'} rounded={true} onClick={handlePublish} loading={!!addCommLoading}>
                {t('events:created.save')}
              </Button>
            </>
          ) : (
            <>
              <Button variant={'primary'} rounded={true} loading={addCommLoading === 'one'} onClick={handlePublish}>
                {t('events:created.confirm')}
              </Button>
            </>
          )}
        </ListingCreatedButtonsWrapper>
      </ListingCreatedContentWrapper>
    </ListingCreatedWrapper>
  );
};
