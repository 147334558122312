import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {parseJSON} from 'date-fns';
import {route} from '../../constants/routes';
import {getBlobUrl} from '../../helpers/file';
import {TDocument} from '../../types/document';
import {Text} from '../../ui-kit/Typography/Text';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Br} from '../../ui-kit/Typography/Br';
import {
  BackBtn,
  DownloadLink,
  FormatLabel,
  DocumentContainer,
  Title,
  ViewPageWrapper,
  DocName,
  DocNameWrapper,
} from './styles';
import {IPDFViewer} from './PDFViewer';

// eslint-disable-next-line
// @ts-ignore
import loadable, {LoadableComponent} from '@loadable/component';
import {useLinks} from '../../hooks/common';

const PDFViewer: LoadableComponent<IPDFViewer> = loadable(() => import('./PDFViewer'), {ssr: false});

interface IProps {
  document: TDocument;
}

export const ViewDocument: React.FC<IProps> = ({document}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [blobLink, setBlobLink] = useState<string>();
  const [pageWidth, setPageWidth] = useState<number | undefined>();
  const pageWrapper = useRef<HTMLDivElement>(null);

  const {name, DocFile, format} = document;

  useEffect(() => {
    DocFile.file.url &&
      getBlobUrl(DocFile.file.url).then((link) => {
        setBlobLink(link);
      });
  }, [DocFile.file.url]);

  useEffect(() => {
    const width = pageWrapper?.current?.getBoundingClientRect()?.width;
    setPageWidth(width);
  }, [pageWrapper]);

  return (
    <ViewPageWrapper ref={pageWrapper}>
      <BackBtn to={getLink(route.documents.get())}>
        <IconSvg type={'chevrone-left'} viewBox={'6 0 24 24'} height={'24px'} width={'24px'} />
        <Text size={16} color={'octonary'} weight={'primary'}>
          {t('documents:buttons.backToDocuments')}
        </Text>
      </BackBtn>
      <Br indent={5} />
      <Title>
        <DocNameWrapper>
          <DocName size={26} color={'quaternary'} weight={'medium'}>
            {name}
          </DocName>
        </DocNameWrapper>
        <FormatLabel>
          <Text size={10} color={'octonary'} case={'uppercase'}>
            {document.format}
          </Text>
        </FormatLabel>
        <Text size={14} color={'octonary'} weight={'medium'}>
          {t('common:dateShort', {date: parseJSON(document.createdAt)})}
        </Text>
        <DownloadLink href={blobLink} download target={'_blank'}>
          <IconSvg type={'download'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />
        </DownloadLink>
      </Title>
      <Br indent={32} mobileIndent={20} />
      {format === 'PDF' && (
        <DocumentContainer>
          <PDFViewer url={DocFile.file.url} pageWidth={pageWidth} />
        </DocumentContainer>
      )}
      {(format === 'PNG' || format === 'JPEG') && (
        <DocumentContainer>
          <img alt={'file img'} src={DocFile.file.url} />
        </DocumentContainer>
      )}
    </ViewPageWrapper>
  );
};
