import {useRecoilValue} from 'recoil';
import {useQuery} from '@apollo/client';
import {currencyState} from '../states/community';
import {
  GetCurrencyByCountryQuery,
  TGetCurrencyByCountryRequest,
  TGetCurrencyByCountryResponse,
} from '../queries/currency';
import {userState} from '../states/session';
import {Currency} from '../queries/types/currency';

export const useGetCurrencyValue = () => {
  const currFromCommunity = useRecoilValue(currencyState);
  const user = useRecoilValue(userState);
  const {data: currByCountry} = useQuery<TGetCurrencyByCountryResponse, TGetCurrencyByCountryRequest>(
    GetCurrencyByCountryQuery,
    {
      variables: {
        countryId: user?.Location?.Country?.objectId || '',
      },
    },
  );

  const currency = currFromCommunity || currByCountry?.paymentPlans.edges[0]?.node.Currency;

  const getSignValue = (value: string | number): string => {
    return (currency?.sign || '$') + value;
  };

  const getCodeValue = (value: string | number): string => {
    return `${currency?.sign || '$'}${value} ${currency?.code || ''}`;
  };
  const safeCurrency: Currency | undefined = {
    ...currency,
    name: currency?.name || '$',
    code: currency?.code || '$',
    sign: currency?.sign || '$',
  };
  return {getSignValue, getCodeValue, currency: safeCurrency};
};
