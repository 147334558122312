import React, {useEffect, useState} from 'react';
import {CommunityStateType} from '../../../states/community';
import {useLocation} from 'react-router-dom';
import {
  useAmenityPageContent,
  useListAmenities,
  useResidentActionsAmenity,
  useSetAmenityStatusDeleted,
} from '../../../hooks/amenity';
import {GQLAmenityManageAction, GQLAmenityOrder, GQLAmenityWhereInput} from '../../../graphql.schema';
import {PageManager} from '../../../components/Amenities/PageManager';
import {entities, useReport} from '../../../hooks/report';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {toPointer} from '../../../helpers/parse';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
};

export const PageManagerContainer: React.FC<TProps> = ({viewerId, community}) => {
  const [searchParams, setSearchParams] = useState<GQLAmenityWhereInput[]>([]);
  const location = useLocation();
  const actions = useReport(entities.listing);

  const {getSignValue} = useGetCurrencyValue();
  const {
    loadingAllAmenities,
    loadingFeaturedAmenities,
    refetchAllAmenities,
    refetchFeaturedAmenities,
    hasMoreAllAmenities,
    fetchDataAllAmenities,
    communityAmenities,
  } = useAmenityPageContent(searchParams, community, true);

  const {
    data: newAmenities,
    fetchData: fetchDataNewAmenities,
    hasMore: hasMoreNewAmenities,
    loading: loadingNewAmenities,
    refetch: refetchNewAmenities,
  } = useListAmenities({
    where: {
      ApproveReq: {
        contains: [
          toPointer({
            __typename: community?.__typename || '',
            objectId: community?.objectId || '',
          }),
        ],
      },
    },
    first: 12,
    order: [GQLAmenityOrder.createdAt_DESC],
  });

  const onSuccess = () => {
    refetchFeaturedAmenities();
    refetchAllAmenities();
    refetchNewAmenities();
  };

  useEffect(() => {
    refetchFeaturedAmenities();
    refetchAllAmenities();
    refetchNewAmenities();
  }, [location]);

  const {onSubmit: onPubSubmit} = useResidentActionsAmenity({
    communityId: community?.objectId,
    onSuccess,
  });
  const {onDelete} = useSetAmenityStatusDeleted({onSuccess});
  const onUnPublish = (id?: string) => () => onPubSubmit({amenityId: id, typeBtn: GQLAmenityManageAction.unpublish});
  const onPublish = (id?: string) => () => onPubSubmit({amenityId: id, typeBtn: GQLAmenityManageAction.publish});
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityAmenities.length && refetchFeaturedAmenities({first: communityAmenities.length});
  //     communityAmenities.length && refetchAllAmenities({first: communityAmenities.length});
  //     communityAmenities.length && refetchNewAmenities({first: communityAmenities.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityAmenities.length]);
  return (
    <PageManager
      community={community}
      loading={loadingAllAmenities || loadingNewAmenities || loadingFeaturedAmenities}
      allAmenities={communityAmenities}
      fetchDataAllAmenities={fetchDataAllAmenities}
      hasMoreAllAmenities={hasMoreAllAmenities}
      newAmenities={[] || newAmenities}
      fetchDataNewAmenities={fetchDataNewAmenities}
      hasMoreNewAmenities={hasMoreNewAmenities}
      setSearchParams={setSearchParams}
      userId={viewerId}
      onDelete={onDelete}
      reportActions={actions}
      getSignValue={getSignValue}
      onUnPublish={onUnPublish}
      onPublish={onPublish}
    />
  );
};
