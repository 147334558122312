import React from 'react';
import {useTranslation} from 'react-i18next';

import {FormInviteCommunity} from './Forms/InviteCommunity';
import {PageWrapper} from './styles';
import {NavContainer} from '../../../containers/Navigation';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../SEO';

import {sendEmailType, TInviteReturn} from '../../../hooks/community';
import {CommunityStateType} from '../../../states/community';

interface IProps {
  params: TInviteReturn;
  sendEmail: sendEmailType;
  community: CommunityStateType;
}

export const PageInviteCommunity: React.FC<IProps> = ({params, sendEmail, community}) => {
  const {t} = useTranslation();
  const seoTitle = t('common:seoTitle', {
    text: t('common:seo.create.community'),
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.create.community')]);

  return (
    <NavContainer>
      <PageWrapper>
        <Seo title={seoTitle} keywords={seoKeywords} />
        <FormInviteCommunity params={params} sendEmail={sendEmail} community={community} />
      </PageWrapper>
    </NavContainer>
  );
};
