import React, {useEffect} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {route} from '../../../../constants/routes';
import {
  useCommunityUpdate,
  useGetActualCommunity,
  useInviteCommunity,
  useSendInvite,
} from '../../../../hooks/community';
import {getDomainWithoutAlias} from '../../../../helpers/common';
import {useLinks} from '../../../../hooks/common';
import {PageInviteCommunity} from '../../../../components/Account/Create/PageInviteCommunity';
import {useRecoilState} from 'recoil';
import {userPermissions} from '../../../../states/community';
import {typeUser} from '../../../../states/typeUser';
import {TypeCommunity} from '../../../../types/auth';
import {PermissionsList} from '../../../../types/settings';
import {CommunityOptionsFields} from '../../../../types/community';

export const InviteCommunityContainer: React.FC = () => {
  const [permissions] = useRecoilState(userPermissions);
  const [userType] = useRecoilState(typeUser);
  const {push} = useHistory();
  const community = useGetActualCommunity();
  const {t} = useTranslation();
  const sendEmail = useSendInvite();
  const {getLink} = useLinks();
  const {update: updateCommunity} = useCommunityUpdate();
  const defaultInvitation = t('community:invite.defaultInvitation', {
    communityName: community?.name,
    communityLink: community?.alias,
    website: getDomainWithoutAlias(),
    interpolation: {
      escapeValue: false,
    },
  });
  const canInvite =
    userType === TypeCommunity.manager ||
    permissions?.includes(PermissionsList.manageInvitation) ||
    community?.options?.[CommunityOptionsFields.passCode] !== false;
  const params = useInviteCommunity({
    initialState: {
      id: community?.id,
      alias: community?.alias,
      invitation: community?.invitation || defaultInvitation,
    },

    onSuccess: () => push(getLink(route.loop.path)),
  });

  const {values} = params;

  const updateInvitationText = () => {
    if (!values.invitation || !community?.objectId) return;
    if (values.invitation === community.invitation) return;

    updateCommunity({
      id: community?.objectId,
      fields: {
        invitation: values.invitation,
      },
    });
  };

  useEffect(() => updateInvitationText, [values.invitation]);
  if (!canInvite) return <Redirect to={getLink(route.pageUser.get({user: userType}))} />;

  return <PageInviteCommunity params={params} sendEmail={sendEmail} community={community} />;
};
