import {useTranslation} from 'react-i18next';
import {imageType, PointerFile, SelectOption} from '../types/common';
import {Event, EventRepeatType, OpenToType, RewardType} from '../queries/types/event';
import {EventFormValue, EventFormValues, PreEventOrderCalcDataT, TEvent, TImage} from '../types/event';
import {YesNoOptions, YesNotoBool} from '../helpers/common';
import {useViewer, useViewerId} from './user';
import useMutableState from './useMutableState';
import {useEffect, useRef, useState} from 'react';
import {FormErrorFields, useFormError} from './error';
import {useMutation, useQuery} from '@apollo/client';
import {
  CreateAppFile,
  CreateFileResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {isString} from '../helpers/validations';
import {
  CreateEventQuery,
  CreateEventRequestType,
  CreateEventResponseType,
  eventResidentButtonsQuery,
  eventResidentButtonsRequestType,
  eventResidentButtonsResponseType,
  EventsResponseType,
  GetEventReqType,
  GetEventRequest,
  GetEventResType,
  GetEventsQuery,
  HandleLikeEvent,
  UpdateEventQuery,
  UpdateEventRequestType,
  UpdateEventResponseType,
} from '../queries/event';
import {createTimeOptions, toPointerCreateEvent, toPointerEditEvent, toStateEvent} from '../helpers/event';
import {FormValidation} from '../helpers/FormValidation';
import {
  GQLEventManageAction,
  GQLEventOrder,
  GQLEventWhereInput,
  GQLOrderStatuses,
  GQLOrderType,
  GQLOrderWhereInput,
} from '../graphql.schema';
import {CommunityStateType} from '../states/community';
import {toPointer} from '../helpers/parse';
import {User} from '../queries/types/user';
import {useGetContactsForChat} from './message';
import {checkItemImagesFormat, getResize} from '../helpers/file';
import {MakeOrder, MakeOrderRequestType, MakeOrderResponseType} from '../queries/order';
import {useOrders} from './order';
import {analyticsTrackFN} from '../helpers/account';
import {LikeState} from './item';
import {format} from 'date-fns';

export type TEventFormData = {
  toggleOptions: SelectOption<boolean>[];
  startTimeOptions: SelectOption<Date>[];
  endTimeOptions: SelectOption<Date>[];
  rewardTypeOptions: SelectOption<RewardType>[];
  dataImages: TImage[];
  openToOptions: SelectOption<OpenToType>[];
  eventTypeOptions: SelectOption<EventRepeatType>[];
};

export const useEventFormData = (options?: {images?: Partial<PointerFile>[]}): TEventFormData => {
  const {t} = useTranslation();
  const timeOptions1 = createTimeOptions();
  const timeOptions2 = createTimeOptions();

  const toggleOptions = [
    {
      value: YesNotoBool[YesNoOptions.yes],
      label: t('common:commonButtons.yes'),
      key: YesNoOptions.yes,
    },
    {
      value: YesNotoBool[YesNoOptions.no],
      label: t('common:commonButtons.no'),
      key: YesNoOptions.no,
    },
  ];

  const startTimeOptions: SelectOption<Date>[] = timeOptions1;
  const endTimeOptions: SelectOption<Date>[] = timeOptions2;

  const rewardTypeOptions: SelectOption<RewardType>[] = [
    {
      value: RewardType.free,
      label: t('listings:options.free'),
      key: RewardType.free,
    },
  ];

  const openToOptions: SelectOption<OpenToType>[] = [
    {
      value: OpenToType.community,
      label: t('events:openTo.community'),
      key: OpenToType.community,
    },
    {
      value: OpenToType.public,
      label: t('events:openTo.public'),
      key: OpenToType.public,
    },
  ];

  const eventTypeOptions: SelectOption<EventRepeatType>[] = [
    {
      value: EventRepeatType.one,
      label: t('events:repeatEventType.one'),
      key: EventRepeatType.one,
    },
    {
      value: EventRepeatType.daily,
      label: t('events:repeatEventType.daily'),
      key: EventRepeatType.daily,
    },
    {
      value: EventRepeatType.onceWeek,
      label: t('events:repeatEventType.onceWeek'),
      key: EventRepeatType.onceWeek,
    },
    {
      value: EventRepeatType.everyTwoWeeks,
      label: t('events:repeatEventType.everyTwoWeeks'),
      key: EventRepeatType.everyTwoWeeks,
    },
    {
      value: EventRepeatType.month,
      label: t('events:repeatEventType.month'),
      key: EventRepeatType.month,
    },
    {
      value: EventRepeatType.quarterly,
      label: t('events:repeatEventType.quarterly'),
      key: EventRepeatType.quarterly,
    },
  ];

  const dataImages = Array.from({length: 6}, (v, k) => ({
    id: options?.images?.[k]?.objectId || String(k),
    value: options?.images?.[k]?.file?.url || '',
    order: k,
  }));

  return {
    toggleOptions,
    rewardTypeOptions,
    dataImages,
    endTimeOptions,
    startTimeOptions,
    openToOptions,
    eventTypeOptions,
  };
};

const EventSchema = FormValidation.schema<Partial<EventFormValues>>({
  name: FormValidation.string('error:allRequired'),
  descr: FormValidation.string('error:allRequired'),
  rewardType: FormValidation.string('error:allRequired'),
  eventDate: FormValidation.date('error:allRequired'),
  startTime: FormValidation.date('error:allRequired'),
  endTime: FormValidation.date('error:allRequired'),
  locationDetails: FormValidation.string('error:allRequired'),
  maxAttendees: FormValidation.handler((value, data) => {
    return !!data.maxAttendees;
  }, 'error:allRequired'),
  maxGuestsPerAttendee: FormValidation.handler((value, data) => {
    const maxAttendees = data.maxAttendees;
    const maxGuests = data.maxGuestsPerAttendee;
    if (!maxGuests || !maxAttendees) return false;
    if (Number(maxGuests) >= Number(maxAttendees)) return false;
    return true;
  }, 'error:maxGuests'),
  images: FormValidation.handler((value, data) => {
    const addedImage = data.images?.filter((el) => !!el.value);
    if (!addedImage?.length) return false;
    return true;
  }, 'error:allRequired'),
});

export type TResCreateEvent = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<EventFormValues>;
  values: Partial<EventFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: EventFormValue}) => void;
  // options: TOptionsSelect;
};

type UseCreateEventType = (options: {
  initialState: Partial<EventFormValues>;
  onSuccess?: (item: Event) => void;
  onError?: () => void;
}) => TResCreateEvent;

export const useCreateEvent: UseCreateEventType = (options) => {
  const viewerId = useViewerId();
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<EventFormValues>>(options?.initialState);
  const [error, setError] = useFormError<EventFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name)) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const [CreateEventRequest, {loading}] = useMutation<CreateEventResponseType, CreateEventRequestType>(
    CreateEventQuery,
  );

  const handleChange = (next: {name: string; value?: EventFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };
  const expireDate = values?.startTime && new Date(values?.startTime);
  if (values.startTime) expireDate?.setFullYear(new Date(values.startTime).getFullYear() + 1);

  const {..._values} = {
    ...values,
    name: values?.name?.trim(),
    descr: values?.descr?.trim(),
    expiresDate: expireDate,
  } as Partial<EventFormValues>;
  const onSubmit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!EventSchema.validate<Partial<EventFormValues>>(_values)) {
        return false;
      }

      if (!viewerId) {
        throw new Error('error:id');
      }
      checkItemImagesFormat(_values.images);

      const parseImages = await Promise.all(
        (_values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  Owner: {link: viewerId},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );

      const response = await CreateEventRequest({
        variables: {
          fields: toPointerCreateEvent(_values, viewerId, parseImages),
        },
      });

      const data = response?.data?.createEvent?.event;
      if (!data) throw new Error();
      analyticsTrackFN('Event Created', {
        eventId: data?.objectId,
        eventType: data?.eventType, // service, rental, buy/sell
        rewardType: data?.rewardType, // coffee, free, money
        photoCount: data?.images?.length,
        title: data?.name,
        description: data?.descr,
        openTo: data?.openTo,
        startTime: data?.startTime && format(new Date(data?.startTime), 'p'),
        endTime: data?.endTime && format(new Date(data?.endTime), 'p'),
        eventDate: data?.eventDate && format(new Date(data?.eventDate), 'P'),
        maxAttendees: data?.maxAttendees,
        maxGuestsPerAttendee: data?.maxGuestsPerAttendee,
        showAttendees: data?.showAttendees,
        creatorName: `${data?.Lister?.firstName}`,
        creatorEmail: `${data?.Lister?.email}`,
        creatorId: data?.Lister?.objectId,
        communitiesCount: data?.Published?.length,
        communities: [
          ...(data?.Published?.map((item) => {
            return {
              communityName: item?.name,
              communityId: item?.objectId,
              status: 'published',
              postedIn: true,
              communityType: item?.type,
            };
          }) || []),
        ],
      });
      setSuccess('success:createEvent');
      setError(null);
      options?.onSuccess?.(data);
      setValues(options?.initialState);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading: isLoading,
    onChange: handleChange,
    error,
  };
};

export type TResEditEvent = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<EventFormValues>;
  values: Partial<EventFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: EventFormValue}) => void;
};

type UseEditEventType = (options: {
  initialState: Partial<EventFormValues>;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResEditEvent;

export const useEditEvent: UseEditEventType = (options) => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<EventFormValues>>(options?.initialState);
  const [error, setError] = useFormError<EventFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [updateAppFile] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(UpdateAppFile);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name)) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const [UpdateEventRequest, {loading}] = useMutation<UpdateEventResponseType, UpdateEventRequestType>(
    UpdateEventQuery,
  );

  const handleChange = (next: {name: string; value?: EventFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };
  const expiresDate = !values?.expiresDate && values?.startTime ? new Date(values?.startTime) : undefined;
  if (values.startTime && expiresDate) expiresDate.setFullYear(new Date(values.startTime).getFullYear() + 1);
  const _values = {
    ...values,
    name: values?.name?.trim(),
    descr: values?.descr?.trim(),
    ...(expiresDate ? {expiresDate: expiresDate} : {}),
  } as Partial<EventFormValues>;
  const onSubmit = async (): Promise<boolean> => {
    try {
      if (!EventSchema.validate<Partial<EventFormValues>>(values)) {
        return false;
      }

      if (!values.objectId) {
        throw new Error('error:id');
      }
      checkItemImagesFormat(_values.images);

      const parseImages = await Promise.all(
        (_values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            if (typeof file.value === 'string') return values?.serverImages?.find((it) => it.objectId === file.id);
            if (Number(file?.id?.length) > 1) {
              const result = await updateAppFile({
                variables: {
                  id: file.id as string,
                  fields: {
                    file: {upload: file.value as File},
                    type: imageType.itemImage,
                  },
                },
              });
              return toPointer(result.data?.updateAppFile?.appFile);
            }
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );

      const {objectId, ...item} = _values;

      const response = await UpdateEventRequest({
        variables: {
          id: objectId || values.objectId,
          fields: toPointerEditEvent({...item, images: parseImages}),
        },
      });

      const data = response?.data?.updateEvent?.event;
      if (!data) throw new Error();
      analyticsTrackFN('Event Updated', {
        listingId: data?.objectId,
        eventType: data?.eventType,
        rewardType: data?.rewardType,
        photoCount: data?.images?.length,
        title: data?.name,
        description: data?.descr,
        openTo: data?.openTo,
        startTime: data?.startTime && format(new Date(data?.startTime), 'p'),
        endTime: data?.endTime && format(new Date(data?.endTime), 'p'),
        eventDate: data?.eventDate && format(new Date(data?.eventDate), 'P'),
        maxAttendees: data?.maxAttendees,
        maxGuestsPerAttendee: data?.maxGuestsPerAttendee,
        showAttendees: data?.showAttendees,
        creatorName: `${data?.Lister?.firstName}`,
        creatorEmail: `${data?.Lister?.email}`,
        creatorId: data?.Lister?.objectId,
        communitiesCount: data?.Published?.length,
        communities: [
          ...(data?.Published?.map((item) => {
            return {
              communityName: item?.name,
              communityId: item?.objectId,
              status: 'published',
              postedIn: true,
              communityType: item?.type,
            };
          }) || []),
        ],
      });
      setSuccess('success:editEvent');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      console.log(error);
      setSuccess(null);
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

export const useGetEvent = (params: {id?: string}) => {
  const {data, ...response} = useQuery<GetEventResType, GetEventReqType>(GetEventRequest, {
    variables: {
      id: params.id || '',
    },
    skip: !params?.id,
    ssr: true,
  });

  return {...response, data: toStateEvent(data?.event) || null};
};

type EventsParams = {
  where?: GQLEventWhereInput;
  order?: GQLEventOrder[];
  cursor?: string;
  first?: number;
};

export const useListEvents = (params: {
  where?: GQLEventWhereInput;
  order?: GQLEventOrder[];
  skip?: boolean;
  first?: number;
  hideDeletedEvents?: boolean;
}) => {
  const previousCursor = useRef<string | null>(null);
  const hideDeletedEvents = params?.hideDeletedEvents ?? true;

  const whereParams: GQLEventWhereInput | undefined =
    params?.where || hideDeletedEvents
      ? {
          AND: [
            ...(params.where ? [params.where] : []),
            ...(hideDeletedEvents ? [{isDeleted: {notEqualTo: true}}] : []),
          ],
        }
      : undefined;

  const variables: EventsParams = {
    ...(whereParams ? {where: whereParams} : {}),
    ...(params?.order ? {order: params.order} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<EventsResponseType, EventsParams>(GetEventsQuery, {
    variables,
    // notifyOnNetworkStatusChange: true,
    ssr: true,
  });
  const fetch = async () => {
    const {hasNextPage, endCursor} = data?.events?.pageInfo || {};

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.events?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.events.edges;
          const nextChunk = fetchMoreResult.events.edges;

          return {
            events: {
              ...fetchMoreResult.events,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const edges = data?.events?.edges;
  const eventsData = edges ? edges?.map((edge) => toStateEvent(edge.node)) : [];
  return {
    data: eventsData,
    loading,
    total: data?.events?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.events?.pageInfo?.hasNextPage),
    refetch,
  };
};

export const useEventPageContent = (
  searchParams: GQLEventWhereInput[],
  community?: CommunityStateType,
  showUnpublished?: boolean,
) => {
  const viewer = useViewer();
  const [liked, setLiked] = useState<LikeState>({});
  const {handleLike} = useEventLike();
  const {
    data: featuredEvents,
    loading: loadingFeaturedEvents,
    refetch: refetchFeaturedEvents,
  } = useListEvents({
    where: {
      AND: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
    },
    order: [GQLEventOrder.startTime_ASC],
  });

  const {
    data: allEvents,
    fetchData: fetchDataAllEvents,
    hasMore: hasMoreAllEvents,
    loading: loadingAllEvents,
    refetch: refetchAllEvents,
  } = useListEvents({
    order: [GQLEventOrder.startTime_ASC],
    first: 12,
    where: {
      AND: [
        {
          OR: [
            {
              Published: {
                contains: [
                  toPointer({
                    __typename: community?.__typename || '',
                    objectId: community?.objectId || '',
                  }),
                ],
              },
            },
            ...(showUnpublished
              ? [
                  {
                    ApproveReq: {
                      contains: [
                        toPointer({
                          __typename: community?.__typename || '',
                          objectId: community?.objectId || '',
                        }),
                      ],
                    },
                  },
                ]
              : []),
          ],
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
      NOR: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (searchParams?.[0]?.name?.matchesRegex && !!allEvents?.length)
      analyticsTrackFN('Searched Event', {
        query: searchParams?.[0]?.name?.matchesRegex,
        results: allEvents?.length || 0,
        userName: viewer?.username,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
  }, [!!allEvents?.length]);

  const communityEvents = [...featuredEvents, ...allEvents];
  useEffect(() => {
    setLiked((prev) => {
      const likeMap = {...prev};
      allEvents?.forEach?.((el) => {
        if (el?.isLike && el?.objectId) if (likeMap[el.objectId] === undefined) likeMap[el.objectId] = true;
      });
      return likeMap;
    });
  }, [allEvents?.length]);
  const onLike = (id: string) => {
    handleLike(id);
    setLiked((prev) => ({...prev, [id]: !prev[id]}));
  };
  return {
    loadingFeaturedEvents,
    loadingAllEvents,
    refetchFeaturedEvents,
    refetchAllEvents,
    fetchDataAllEvents,
    hasMoreAllEvents,
    communityEvents,
    onLike,
    liked,
  };
};

export const useResidentActionsEvent = (params: {
  communityId?: string;
  onSuccess?: (type: GQLEventManageAction) => void;
  onError?: () => void;
}) => {
  const {onSuccess, onError, communityId} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionRequest, {loading}] = useMutation<eventResidentButtonsResponseType, eventResidentButtonsRequestType>(
    eventResidentButtonsQuery,
  );
  const submit = async ({
    eventId,
    typeBtn,
    communityIdList,
  }: {
    eventId: string;
    typeBtn: GQLEventManageAction;
    communityIdList: string[];
  }) => {
    const response = await ActionRequest({
      variables: {
        eventId: eventId,
        communityIdList: communityIdList,
        action: typeBtn,
      },
    });

    const data = response?.data?.eventManagerButtons;
    onSuccess?.(typeBtn);
    if (!data) throw new Error();
  };

  const onSubmit = async ({
    eventId,
    typeBtn,
    communityIds,
  }: {
    eventId?: string;
    typeBtn: GQLEventManageAction;
    communityIds?: Array<string>;
  }) => {
    if (!eventId) return;

    try {
      if (communityIds) await submit({communityIdList: communityIds, typeBtn, eventId});
      else {
        if (!communityId) return;
        await submit({communityIdList: [communityId], typeBtn, eventId});
      }

      setError(null);
      onSuccess?.(typeBtn);
    } catch (error) {
      setError(error as any);
      onError?.();
    }
  };

  return {onSubmit, error, loading};
};

export const isEventInCommunity = (options: {event: Partial<TEvent>; commId?: string; default?: boolean}) => {
  return (
    options?.default ||
    !!options.event.Published?.find((el) => el.objectId === options.commId || el.id === options.commId)
  );
};

export type TDataCreator = {
  verified: string;
  reviews: string;
  isOnline?: boolean;
  avatar: string;
  firstName: string;
  lastName: string;
};

export const useDataCreator = (creator?: User): TDataCreator => {
  const {t} = useTranslation();
  const {contacts} = useGetContactsForChat({
    initUsers: creator ? [creator] : null,
    msgs: [],
    contactsIds: creator ? [creator.objectId] : undefined,
  });

  const verified = creator?.isVerified ? t('requests:verified') : '';
  const reviews = t('requests:reviews', {count: creator?.Reviews?.count || 0});
  return {
    verified,
    reviews,
    isOnline: contacts?.[0]?.isOnline,
    avatar: getResize(creator?.Avatar?.file?.url, 'lg') || '',
    firstName: creator?.firstName || '',
    lastName: creator?.lastName || '',
  };
};

export const useGetEventNoCache = ({id}: {id?: string}) => {
  const {data, ...response} = useQuery<GetEventResType, GetEventReqType>(GetEventRequest, {
    variables: {
      id: id || '',
    },
    skip: !id,
    ssr: true,
    fetchPolicy: 'no-cache',
  });

  return {...response, data: toStateEvent(data?.event) || null};
};

export const useSetEventStatusDeleted = (options: {id?: string; onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [UpdateEventRequest, {loading}] = useMutation<UpdateEventResponseType, UpdateEventRequestType>(
    UpdateEventQuery,
  );
  const {id, onSuccess} = options;

  const onDelete = async (custId?: string): Promise<boolean> => {
    try {
      if (!id && !custId) {
        throw new Error('error:id');
      }

      const response = await UpdateEventRequest({
        variables: {id: id || custId || '', fields: {isDeleted: true}},
      });

      const data = response?.data;
      if (!data) throw new Error();

      setSuccess('success:updatedEvent');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      onSuccess?.();
    }

    return true;
  };

  return {
    onDelete,
    success,
    loading,
    error,
  };
};

export type orderEventChangeData = {
  guests: number;
  onChangeGuests: (v: number) => void;
  date?: Date;
  setDate?: (val: Date) => void;
};

export type TResCreateEventOrder = {
  onSubmit: (subOptions?: SubmitEventOrderOptions) => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  success?: string | null;
  preCalcData?: PreEventOrderCalcDataT;
  changeData: orderEventChangeData;
  orderInfo?: string;
};

type TOptsCreateEventOrderType = (options: {
  communityId?: string;
  eventId?: string;
  startTime?: Date;
  rewardType?: RewardType | null;
  onSuccess?: (date?: Date) => void;
  onError?: () => void;
  eventType?: EventRepeatType;
}) => TResCreateEventOrder;

export type SubmitEventOrderOptions = {
  isRecurring?: boolean;
};

export const useCreateEventOrder: TOptsCreateEventOrderType = (options) => {
  const [error, setError] = useState<{message?: string} | null>();
  const [orderInfo, setOrderInfo] = useState('');
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [guests, setGuests] = useState<number>(0);
  const [date, setDate] = useState<Date | undefined>();
  const isRecurring = options?.eventType ? options.eventType !== EventRepeatType.one : false;
  const onDateChange = (value: Date) => {
    if (!options?.startTime) return;
    const hours = new Date(options.startTime).getHours();
    const minutes = new Date(options.startTime).getMinutes();
    const newDate = new Date(value);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    setDate(newDate);
  };
  const onChangeGuests = (v: number) => setGuests(v);
  // const [preCalcData, setPreCalcData] = useState<PreOrderCalcDataT>(preCalcInitial);

  const [CreateOrderRequest, {loading}] = useMutation<MakeOrderResponseType, MakeOrderRequestType>(MakeOrder);
  // const [getCalcOrderRequest] = useMutation<PreOrderCalcDataResponseType, PreOrderCalcDataRequestType>(
  //     preOrderCalcData,
  // );

  const preCalc = async (): Promise<boolean> => {
    try {
      if (options?.rewardType !== RewardType.free) {
        return false;
      }
      // const response = await getCalcOrderRequest({
      //   variables: {
      //     communityId: options.communityId,
      //     eventId: options.eventId,
      //   },
      // });
      // const data = response.data?.preOrderCalcData;
      // if (data) {
      //   setPreCalcData(data);
      // }
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  useEffect(() => {
    preCalc();
  }, []);
  const onSubmit = async (subOptions?: SubmitEventOrderOptions): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!options?.communityId || !options?.eventId || !options.startTime) {
        return false;
      }
      if (isRecurring && !date) return false;
      const response = await CreateOrderRequest({
        variables: {
          communityId: options.communityId,
          objectId: options.eventId,
          orderType: GQLOrderType.event,
          startTime: date ? new Date(date).toISOString() : new Date(options?.startTime).toISOString(),
          guests: guests,
          recurringEvent: !!subOptions?.isRecurring,
        },
      });

      const data = response?.data?.makeOrder?.order;
      if (!data) throw new Error();
      setOrderInfo(data);
      setSuccess('success:createOrderEvent');
      setError(null);
      options?.onSuccess?.(date ? new Date(date) : new Date(options?.startTime));
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  const changeData = {
    guests,
    onChangeGuests,
    setDate: onDateChange,
    date,
  };

  return {
    onSubmit,
    success,
    loading: isLoading,
    error,
    changeData,
    orderInfo,
  };
};

type checkAvailabilityOoptions = {
  event?: Partial<TEvent>;
  userId?: string;
  strictCheck?: boolean;
};

export type eventAvailableDataT = {
  check: boolean;
  text?: string;
};

export enum notAvRequestText {
  requested = 'requested',
  past = 'past',
}

export const useCheckEventForAvailability = (options: checkAvailabilityOoptions) => {
  const notAvailableStatuses = [GQLOrderStatuses.pending, GQLOrderStatuses.attending];
  const where: GQLOrderWhereInput = {
    AND: [
      {status: {in: notAvailableStatuses}},
      {Event: {have: {objectId: {equalTo: options.event?.objectId}}}},
      {Requester: {have: {objectId: {equalTo: options.userId}}}},
    ],
  };
  const {count, loading} = useOrders({where});
  const isRecurring = options.event?.eventType && options.event.eventType !== EventRepeatType.one;
  const expireDate = options.event?.startTime && new Date(options.event?.startTime);
  if (options?.event?.startTime) expireDate?.setFullYear(new Date(options.event.startTime).getFullYear() + 1);

  const isPast = options.event?.endTime
    ? isRecurring && expireDate
      ? new Date().getTime() > new Date(expireDate).getTime()
      : new Date().getTime() > new Date(options.event.endTime).getTime()
    : false;

  let availableData = {
    check: true,
    text: '',
  };
  if (options.strictCheck && count) {
    availableData = {
      check: false,
      text: notAvRequestText.requested,
    };
  }
  if (isPast) {
    availableData = {
      check: false,
      text: notAvRequestText.past,
    };
  }
  return {availableData: availableData as eventAvailableDataT, loading};
};

export const useEventLike = (id?: string) => {
  const [like] = useMutation<undefined, {id: string}>(HandleLikeEvent);

  const handleLike = async (targetId?: string) => {
    if (!id && !targetId) return false;
    try {
      await like({
        variables: {
          id: id || targetId || '',
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  };
  return {handleLike};
};
