import {gql} from '@apollo/client';
import {Item} from './types/item';
import {ItemFragment, ItemShortFragment, ItemShortFragmentForListing} from './fragments/item';
import {CreateItemFieldsInput, EditItemFieldsInput, TItem, TQueryItem} from '../types/item';
import {Connection} from './types/parse';
import {GQLButtonsManagerTypes, GQLButtonsResidentTypes} from '../graphql.schema';

export interface CreateItemResponseType {
  createItem: {
    item: Item;
  };
}

export interface CreateItemRequestType {
  fields: CreateItemFieldsInput;
}

export const CreateItemQuery = gql`
  mutation createItem($fields: CreateItemFieldsInput) {
    createItem(input: {fields: $fields}) {
      item {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;

export interface CreateMultiplyResponseType {
  createMultiplyItems: {
    listId: string[];
  };
}

export interface CreateMultiplyItemRequestType {
  communityIds: string[];
  items: any[];
}

export const CreateMultiplyItemQuery = gql`
  mutation createMultiplyItems($communityIds: [String], $items: [Object]) {
    createMultiplyItems(input: {communityIds: $communityIds, items: $items}) {
      listId
    }
  }
`;

export interface UpdateItemResponseType {
  updateItem: {
    item: Item;
  };
}

export interface UpdateItemRequestType {
  id: string;
  fields: EditItemFieldsInput;
}

export const UpdateItemQuery = gql`
  mutation updateItem($id: ID!, $fields: UpdateItemFieldsInput) {
    updateItem(input: {id: $id, fields: $fields}) {
      item {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;

export interface ActionsResResponseType {
  itemResidentButtons: boolean;
}

export interface ActionsResidentRequestType {
  itemId: string;
  communityIds: string[];
  type: GQLButtonsResidentTypes;
}

export const ActionsResidentQuery = gql`
  mutation itemResidentButtons($itemId: ID!, $communityIds: [ID]!, $type: ButtonsResidentTypes!) {
    itemResidentButtons(input: {itemId: $itemId, communityIDs: $communityIds, type: $type})
  }
`;

export interface ActionsManagerResponseType {
  itemManagerButtons: boolean;
}

export interface ActionsManagerRequestType {
  itemId: string;
  communityId: string;
  type: GQLButtonsManagerTypes;
}

export const ActionsManagerQuery = gql`
  mutation itemManagerButtons($itemId: ID!, $communityId: ID!, $type: ButtonsManagerTypes!) {
    itemManagerButtons(input: {itemId: $itemId, communityId: $communityId, type: $type})
  }
`;

export interface DeleteItemResponseType {
  deleteItem: {
    item: Item;
  };
}

export interface DeleteItemRequestType {
  id: string;
}

export const DeleteItemQuery = gql`
  mutation deleteItem($id: ID!) {
    deleteItem(input: {id: $id}) {
      item {
        ...ItemFragment
      }
    }
  }
  ${ItemFragment}
`;

export type ItemsResponseType = {
  items: Connection<TQueryItem>;
};

export const GetItemsQuery = gql`
  query items($cursor: String, $first: Int, $skip: Int, $where: ItemWhereInput, $order: [ItemOrder!]) {
    items(after: $cursor, order: $order, first: $first, skip: $skip, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...ItemShortFragment
        }
      }
    }
  }
  ${ItemShortFragment}
`;

export type GetItemReqType = {
  id: string;
};

export type GetItemResType = {
  item: TQueryItem;
};

export const GetItemRequest = gql`
  query item($id: ID!) {
    item(id: $id) {
      ...ItemFragment
    }
  }
  ${ItemFragment}
`;

export type PublishedItemsResponseType = {
  items: Connection<Item>;
};

export const GetPublishedItemsQuery = gql`
  query publishedItems($cursor: String, $first: Int, $where: ItemWhereInput, $order: [ItemOrder!]) {
    items(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...ItemShortFragmentForListing
        }
      }
    }
  }
  ${ItemShortFragmentForListing}
`;

export const GetBaseItemsQuery = gql`
  query Items($cursor: String, $first: Int, $where: ItemWhereInput, $order: [ItemOrder!]) {
    items(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...ItemShortFragmentForListing
        }
      }
    }
  }
  ${ItemShortFragmentForListing}
`;
export type UserItemsIdsResponseType = {
  items: Connection<{Lister: {id: string; objectId: string}; objectId: string}>;
};

export const GetUserItemsIdsQuery = gql`
  query items($where: ItemWhereInput) {
    items(where: $where) {
      edges {
        node {
          Lister {
            id
            objectId
          }
          objectId
        }
      }
    }
  }
`;

export type GetApprovedAndFeaturedItemsResponseType = {
  getItemArrays: {
    approvedItems: Partial<TItem>[];
    featuredItems: Partial<TItem>[];
  };
};

export type GetApprovedAndFeaturedItemsRequestType = {
  communityId: string;
};

export const GetApprovedAndFeaturedItemsQuery = gql`
  mutation getItemArrays($communityId: String!) {
    getItemArrays(input: {communityId: $communityId}) {
      approvedItems
      featuredItems
    }
  }
`;

export const HandleLikeItem = gql`
  mutation likeHandlerListing($id: String!) {
    likeHandlerListing(input: {listingId: $id}) {
      success
    }
  }
`;

export const HandleWaveUser = gql`
  mutation waveHandlerUser($communityId: String!, $userId: String!) {
    waveHandlerUser(input: {communityId: $communityId, userId: $userId}) {
      success
    }
  }
`;
