import React from 'react';
import {useTranslation} from 'react-i18next';

import {WrapperAuth} from './WrapperAuth';
import {
  InnerAuth,
  SocialTitle,
  SocialSubtitle,
  Divider,
  DividerElement,
  DividerText,
  SocialButtonsWrapper,
  LoaderWrapper,
  SocialTermsWrapper,
  Terms,
} from './styles';
import {AuthTab, TypeCommunity, SocialAuth, SocialProps} from '../../types/auth';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {route} from '../../constants/routes';
import {LoaderChildren} from '../../ui-kit/Loader/Children';
import {StyledSpinnerLoader} from '../../ui-kit/Loader/styles';
import {SocialButtonsComponent} from './SocialButtonsComponent';
import {TermsLinks} from './TermsLinks';

export const Social: React.FC<SocialProps> = ({
  tab,
  reqWIthGoogle,
  reqWIthFacebook,
  reqWIthApple,
  error,
  loading,
  disconnectAccount,
  loadingButton,
  fbReady,
  googleReady,
  onGoogleLoginClick,
  onFBClick,
}) => {
  const {t} = useTranslation();

  return (
    <WrapperAuth tab={AuthTab.logIn} isStart={false} isSocial>
      <InnerAuth>
        <SocialTitle>{t('auth:social.title')}</SocialTitle>
        <SocialSubtitle size={16} variant={'primary'} color={'octonary'}>
          {t('auth:social.subtitle')}
        </SocialSubtitle>
        {loading ? (
          <LoaderWrapper>
            <StyledSpinnerLoader>
              <LoaderChildren type={'spinner'} />
            </StyledSpinnerLoader>
          </LoaderWrapper>
        ) : (
          <>
            <SocialButtonsWrapper>
              <SocialButtonsComponent
                reqWIthGoogle={reqWIthGoogle}
                reqWIthFacebook={reqWIthFacebook}
                reqWIthApple={reqWIthApple}
                error={error}
                disconnectAccount={disconnectAccount}
                loadingButton={loadingButton}
                fbReady={fbReady}
                googleReady={googleReady}
                onGoogleLoginClick={onGoogleLoginClick}
                onFBClick={onFBClick}
              />
            </SocialButtonsWrapper>
            <Divider>
              <DividerElement>
                <DividerText>or</DividerText>
              </DividerElement>
            </Divider>
            <SocialButtonsWrapper>
              {tab === SocialAuth.logIn && (
                <LinkButton
                  icon={<IconSvg type={'chat'} width="22" height="20" viewBox="0 0 22 20" />}
                  to={route.auth.get({tab: AuthTab.logIn})}
                  variant={'social'}
                  ghost>
                  {t('auth:social.buttons.continue')}
                </LinkButton>
              )}
              {tab === SocialAuth.signUp && (
                <>
                  <LinkButton
                    icon={<IconSvg type={'chat'} width="22" height="20" viewBox="0 0 22 20" />}
                    to={route.signUp.get({user: TypeCommunity.resident})}
                    variant={'social'}
                    ghost>
                    {t('auth:social.buttons.signUp')}
                  </LinkButton>
                  <LinkButton
                    icon={<IconSvg type={'chat'} width="22" height="20" viewBox="0 0 22 20" />}
                    variant={'social'}
                    to={route.auth.get({tab: AuthTab.logIn})}
                    ghost>
                    {t('auth:social.buttons.logIn')}
                  </LinkButton>
                </>
              )}
            </SocialButtonsWrapper>
          </>
        )}
        <SocialTermsWrapper>
          <Terms>
            {t('auth:terms.text_3')}
            <TermsLinks />
          </Terms>
        </SocialTermsWrapper>
      </InnerAuth>
    </WrapperAuth>
  );
};
