import React from 'react';
import {RequestMessageSmallProps} from './types';
import {
  MessagePreviewCardWrapper,
  MessageRequestItemName,
  PreviewMessageDate,
  PreviewMessageStatusLine,
  PreviewRequestAvatar,
  RequestOptionsText,
  PreviewRequestCardBody,
} from './styles';

import {format} from 'date-fns';

export const RequestMessageSmall: React.FC<RequestMessageSmallProps> = ({
  itemName,
  requestOptions,
  messageDate,
  $isHighlighted,
  bordered,
  fullWidth,
  itemImage,
  requestedForLabel,
}) => {
  return (
    <MessagePreviewCardWrapper $isHighlighted={$isHighlighted} bordered={bordered} fullWidth={fullWidth}>
      <PreviewRequestCardBody fullWidth={fullWidth}>
        <PreviewRequestAvatar src={itemImage} size="md" />
        <div>
          <MessageRequestItemName>{itemName}</MessageRequestItemName>
          <RequestOptionsText>
            {requestedForLabel} {requestOptions?.join(' • ')}
          </RequestOptionsText>
          <PreviewMessageStatusLine>
            <PreviewMessageDate>{format(new Date(messageDate), 'LLL d, y')}</PreviewMessageDate>
          </PreviewMessageStatusLine>
        </div>
      </PreviewRequestCardBody>
    </MessagePreviewCardWrapper>
  );
};
