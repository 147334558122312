import React, {useState} from 'react';
import {SearchBarInputText} from '../../types';
import {InputWrapper, SearchInputField, InputActionButton} from './styles';
import {OptionsWrapper, OptionRow, HintName, OptionDelimetr, OptionTextWrapper} from '../../styles';
import {Dropdown} from '../../../Dropdown/Dropdown';
import {IconSvg} from '../../../Icon/Svg';

type InputProps = Omit<SearchBarInputText, 'changeBarActive' | 'size' | 'label'> & {
  onSearch: (searchText?: string) => void;
  className?: string;
};

export const SeparatedTextInput: React.FC<InputProps> = ({
  placeholder,
  onChange,
  value,
  hints,
  onSearch,
  className,
}) => {
  const [active, setActive] = useState(false);

  const toggleActiveOn = () => {
    setActive(true);
  };
  const toggleActiveOff = () => {
    setActive(false);
  };
  const handleSearch = () => onSearch(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  const selectHint = (hint: string) => {
    onChange(hint);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <InputWrapper $isActive={active} className={className}>
      <SearchInputField
        value={value}
        onChange={handleChange}
        onFocusCapture={toggleActiveOn}
        onBlurCapture={toggleActiveOff}
        placeholder={placeholder}
        onKeyPress={handleEnter}
      />
      {hints?.length && (
        <Dropdown position={'left'} $isOpen={active}>
          {value && (
            <OptionsWrapper>
              {hints.map(
                (el, i) =>
                  el.includes(value) && (
                    <OptionRow key={i}>
                      <OptionTextWrapper onClick={() => selectHint(el)}>
                        <HintName>{el}</HintName>
                        {i !== hints.length - 1 && <OptionDelimetr />}
                      </OptionTextWrapper>
                    </OptionRow>
                  ),
              )}
            </OptionsWrapper>
          )}
        </Dropdown>
      )}
      <InputActionButton onClick={handleSearch}>
        <IconSvg type={'search'} width={'15px'} height={'14px'} stroke={'gray'} fill={'gray'} viewBox={'0 0 22 21'} />
      </InputActionButton>
    </InputWrapper>
  );
};
