import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import defaultCommunityAvatar from '../../../../ui-kit/ProfileIntro/images/avatar-default-community.png';
// eslint-disable-next-line
// @ts-ignore
import loadable from '@loadable/component';
import {
  ArrowRight,
  AvatarWrapper,
  CopyrightYear,
  BackgroundSettings,
  EntryButtonWrapper,
  EntryLinkWrapper,
  Header,
  HeaderEntry,
  HeaderWrapper,
  ButtonWrapper,
  IconWrapper,
  LabelsWrapper,
  LogOutBtn,
  PopupWrapper,
  RelativeWrapper,
  Subtitle,
  TextBlock,
  TextMenuBlock,
  Title,
  WrapperFlex,
  JoinButton,
  LinkLabel,
} from './styles';
import {CommunityElementProps, TLinkProps, UserCommunitySettingsProps, UserSettingsProps} from './types';
import {SLACK_BOT_ACCESS_TOKEN, SLACK_CHANNEL} from '../../../../constants/env';
import {TypeCommunity} from '../../../../types/auth';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {Label} from '../../../../ui-kit/Labels';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {HelpCentre} from '../HelpCentre/HelpCentre';

import {useSignOut} from '../../../../hooks/auth';
import {useViewerId} from '../../../../hooks/user';
import {getNavStrokeColor} from '../../../../ui-kit/Navigation/styles';
import {IsModalProvider} from '../../../../containers/Navigation/IsModalProvider';
import {SpinnerWrapper} from '../../../../ui-kit/Button/styles';
import {referralReward} from '../../../../constants/common';

// eslint-disable-next-line
// @ts-ignore

const FeedbackComponent: any = loadable(() => import('simple-screenshot-feedback'), {ssr: false});

export const MenuContent: React.FC<TLinkProps & {handleClose: () => void}> = ({links, handleClose}) => {
  const {t} = useTranslation();
  return (
    <div onClick={handleClose}>
      {links?.referrals && (
        <EntryLinkWrapper to={links?.referrals}>
          <TextMenuBlock>
            <IconSvg
              type={'star-3'}
              stroke={getNavStrokeColor('web', 'light')}
              width={'24'}
              height={'24'}
              viewBox={'0 0 20 20'}
            />
            <Title>{t('settings:popup.menu.referrals')}</Title>
            <LinkLabel>
              <Label variant={'quaternary'}>{t('settings:referrals.earn', {value: `$${referralReward}`})}</Label>
            </LinkLabel>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.event && (
        <EntryLinkWrapper to={links?.event}>
          <TextMenuBlock>
            <IconSvg type={'calendar-2'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.events')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.localBiz && (
        <EntryLinkWrapper to={links?.localBiz}>
          <TextMenuBlock>
            <IconSvg type={'biz-box'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.localBiz')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.groups && (
        <EntryLinkWrapper to={links?.groups}>
          <TextMenuBlock>
            <IconSvg type={'groups'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.groups')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.bookings && (
        <EntryLinkWrapper to={links?.bookings}>
          <TextMenuBlock>
            <IconSvg type={'layers'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.bookings')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.documents && (
        <EntryLinkWrapper to={links?.documents}>
          <TextMenuBlock>
            <IconSvg type={'folder'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.documents')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.insights && (
        <EntryLinkWrapper to={links?.insights}>
          <TextMenuBlock>
            <IconSvg type={'grid'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.insights')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
      {links?.helpDesk && (
        <EntryLinkWrapper to={links?.helpDesk}>
          <TextMenuBlock>
            <IconSvg type={'info-alt'} stroke={getNavStrokeColor('web', 'light')} width={'24'} height={'24'} />
            <Title>{t('settings:popup.menu.helpdesk')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
      )}
    </div>
  );
};

interface SettingProps extends TLinkProps {
  active: boolean;
  handleActive: () => void;
  handleClose: () => void;
  communityName: string;
}

export const SettingsMenu: React.FC<SettingProps> = ({active, handleActive, communityName, handleClose}) => {
  const {t} = useTranslation();
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();

  return (
    <>
      <ButtonWrapper as={'div'} onClick={handleActive}>
        <TextMenuBlock>
          <IconSvg type={'settings'} stroke={'gray'} width={'24'} height={'24'} />
          <Title>{t('settings:popup.menu.settings')}</Title>
        </TextMenuBlock>
      </ButtonWrapper>
      {active && (
        <BackgroundSettings onClick={handleActive}>
          <IsModalProvider>
            <PopupWrapper onClick={stop}>
              <HelpCentre active={active} close={handleClose} nameCommunity={communityName} />
            </PopupWrapper>
          </IsModalProvider>
        </BackgroundSettings>
      )}
      <FeedbackComponent
        slackToken={SLACK_BOT_ACCESS_TOKEN}
        slackChannel={SLACK_CHANNEL}
        location={'top-middle'}
        handleSubmitError={(error: any) => console.log('Feedback', error)}
      />
    </>
  );
};

export const UserSettings: React.FC<UserSettingsProps> = ({src, title, subtitle, to, handleClose}) => {
  return (
    <EntryLinkWrapper to={to} onClick={handleClose}>
      <WrapperFlex flexStart={true}>
        <AvatarWrapper>
          <Avatar src={src} size={'sm'} />
        </AvatarWrapper>
        <TextBlock>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextBlock>
      </WrapperFlex>
    </EntryLinkWrapper>
  );
};

const EntryWrapper: React.FC<{type?: TypeCommunity; to: string; onClick?: (e: React.SyntheticEvent) => void}> = ({
  children,
  type,
  onClick,
  to,
}) => {
  return type === TypeCommunity.manager ? (
    <EntryLinkWrapper to={to}>{children}</EntryLinkWrapper>
  ) : (
    <EntryButtonWrapper onClick={onClick}>{children}</EntryButtonWrapper>
  );
};

export const UserCommunitySettings: React.FC<UserCommunitySettingsProps> = ({
  src,
  title,
  subtitle,
  to,
  type,
  onClick,
}) => {
  return (
    <EntryWrapper type={type} to={to} onClick={onClick}>
      <WrapperFlex flexStart={true}>
        <AvatarWrapper>
          <Avatar src={src} size={'sm'} />
        </AvatarWrapper>
        <TextBlock>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextBlock>
      </WrapperFlex>
    </EntryWrapper>
  );
};

export const CommunityElement: React.FC<CommunityElementProps> = ({
  community,
  setCurrentCommunity,
  isPending,
  isInvited,
  joinLoading,
}) => {
  const currentId = useViewerId('objectId');
  const {t} = useTranslation();
  const handleSetCurrentCommunity = () => {
    community?.objectId && setCurrentCommunity(community.objectId);
  };
  const userLabel = community.Admins?.find((el) => el?.User?.objectId === currentId)?.label;
  const isAdmin = !!community.Admins?.find((el) => el?.User?.objectId === currentId)?.objectId;

  return (
    <RelativeWrapper>
      <EntryButtonWrapper onClick={isInvited ? undefined : handleSetCurrentCommunity}>
        <WrapperFlex flexStart={true}>
          <AvatarWrapper>
            <Avatar
              src={community?.avatar || (community?.identityData?.autogenerated ? defaultCommunityAvatar : '')}
              size={'sm'}
            />
          </AvatarWrapper>
          <TextBlock>
            <Title $overflow={true}>{community.name}</Title>
            <LabelsWrapper>
              <>
                {userLabel ? (
                  <Label squared variant={'quaternary'}>
                    {userLabel}
                  </Label>
                ) : (
                  <>
                    {isPending ? (
                      <Label squared variant={'septenary'}>
                        {t('common:typeCommunity.pending')}
                      </Label>
                    ) : isInvited ? (
                      <Label squared variant={'septenary'}>
                        {t('common:typeCommunity.invited')}
                      </Label>
                    ) : null}
                    {community?.Owner?.objectId === currentId ? (
                      <Label squared variant={'quaternary'}>
                        {t('common:typeCommunity.admin')}
                      </Label>
                    ) : (
                      <>
                        {Boolean(!isInvited && !isPending) && (
                          <>
                            {isAdmin ? (
                              <Label squared variant={'quinary'}>
                                {t('common:typeCommunity.manager')}
                              </Label>
                            ) : (
                              <Label squared variant={'quinary'}>
                                {t('common:typeCommunity.resident')}
                              </Label>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {Boolean(community?.type && !isPending) && (
                      <Label squared variant={'quinary'}>
                        {t(`common:communityPurposeType.${community?.type}`)}
                      </Label>
                    )}
                  </>
                )}
              </>
            </LabelsWrapper>
          </TextBlock>
        </WrapperFlex>
      </EntryButtonWrapper>
      {isInvited ? (
        <JoinButton $disabled={joinLoading} onClick={handleSetCurrentCommunity}>
          {t('auth:foundCommunity.founded.join')}
          <SpinnerWrapper $active={joinLoading}>
            <IconSvg type={'loading'} width={'1.5em'} height={'1.5em'} viewBox={'-3 -3 19 19'} />
          </SpinnerWrapper>
        </JoinButton>
      ) : (
        <ArrowRight />
      )}
    </RelativeWrapper>
  );
};

export const SettingsHeader: React.FC<{handleClose: () => void}> = ({handleClose}) => {
  const {t} = useTranslation();

  return (
    <HeaderEntry as={'div'}>
      <HeaderWrapper>
        <Header as={'span'}>{t('settings:popup.header')}</Header>
        <IconWrapper onClick={handleClose}>
          <IconSvg type={'close'} viewBox={'0 0 20 20'} />
        </IconWrapper>
      </HeaderWrapper>
    </HeaderEntry>
  );
};

export const Copyrights: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  const handleSignOut = async () => await signOut();
  const {signOut} = useSignOut({
    onSuccess: () => {
      setLoader(false);
    },
  });
  const logoutClick = () => {
    handleSignOut();
    setLoader(true);
  };

  if (loader) return <LottieLoader allScreen={true} $isVisible={true} />;

  return (
    <WrapperFlex>
      <IconWrapper>
        <IconSvg
          type={'hangeh-logo-beta'}
          viewBox={'15 0 152 64'}
          fill={'aqua'}
          stroke={'none'}
          width={'72px'}
          height={'60px'}
        />
        <CopyrightYear>© {currentYear}</CopyrightYear>
      </IconWrapper>
      <LogOutBtn onClick={logoutClick}>{t('settings:popup.logOut')}</LogOutBtn>
    </WrapperFlex>
  );
};
