import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Button} from '../../../../ui-kit/Button/Button';
import {MainWrapper, FormTopTitle, AvaratLg, AvatarBox, UpdateWrapper} from '../styles';
import {Box} from '../../../common/Box';
import {genConfig, AvatarFullConfig} from 'react-nice-avatar';
import {UpdatePhotoError, UpdatePhotoInput} from '../../../../ui-kit/ProfileIntro/styles';
import {isImage} from '../../../../helpers/settings';
import {UpdateAvatarError} from '../../../Auth/styles';
import {getResize} from '../../../../helpers/file';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {analyticsTrackFN} from '../../../../helpers/account';

interface IProps {
  submit: () => Promise<void> | void;
  upload: (photo: File | undefined) => Promise<void>;
  loadingAvatar: boolean;
  photoSrc: string;
  gender: 'man' | 'woman' | '';
  getFileFromDom: () => Promise<void>;
  // ref: Ref<HTMLDivElement>;
  avatarRef: React.RefObject<HTMLDivElement>;
}

export const PhotoUpload: React.FC<IProps> = ({
  submit,
  upload,
  loadingAvatar,
  photoSrc,
  gender,
  getFileFromDom,
  avatarRef,
}) => {
  const {t} = useTranslation();
  const [config, setConfig] = useState<AvatarFullConfig>();
  const [photoError, setPhotoError] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  const [analytic, setAnalytic] = useState<{personalPhoto: boolean; avatarPhoto: boolean; triedAvatarPhoto: boolean}>({
    personalPhoto: false,
    avatarPhoto: false,
    triedAvatarPhoto: false,
  });

  const handleRandomAvatar = () => {
    setAnalytic({personalPhoto: false, avatarPhoto: true, triedAvatarPhoto: true});
    setConfig(gender ? genConfig({sex: gender}) : genConfig());
  };

  const handleUpdatePhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setPhotoError('');
    if (file && file?.size / 1024 / 1024 > 10) {
      setPhotoError(t('error:invalidSize'));
    } else if (!isImage(file?.name)) {
      setPhotoError(t('error:invalidFormat'));
    } else {
      await upload(file);
      e.target.value = '';
    }
    setAnalytic((prevState) => {
      return {...prevState, personalPhoto: true, avatarPhoto: false};
    });
    setConfig(undefined);
  };

  const handleSubmit = async () => {
    if (config) await getFileFromDom();
    analyticsTrackFN('Photo Selected', {
      ...analytic,
    });
    submit();
  };

  const handleClickAvatar = () => {
    ref?.current?.click();
  };
  return (
    <>
      <MainWrapper>
        <FormTopTitle $noOffset>
          <Text variant={'secondary'} color={'quaternary'} size={38}>
            {t('auth:photoUpload.title')}
          </Text>
        </FormTopTitle>
        <Box direction="column" display="flex" gap="16px">
          <Box margin="0 0 2px 0" textAlign="left">
            <Text variant={'primary'} color={'octonary'} size={16}>
              {t('auth:photoUpload.firstSubtitle')}
            </Text>
            <AvatarBox onClick={handleClickAvatar} margin="20px 0" display={'flex'} justify={'center'}>
              {!config ? (
                <Avatar
                  loading={loadingAvatar}
                  className={'avatar'}
                  size={'xl'}
                  src={getResize(photoSrc, 'lg') || ''}
                />
              ) : (
                <div ref={avatarRef}>
                  <AvaratLg id={'avatar'} className={'avatar'} {...config} />
                </div>
              )}
            </AvatarBox>
            <UpdateAvatarError>{photoError && <UpdatePhotoError>{photoError}</UpdatePhotoError>}</UpdateAvatarError>
            <Box
              margin={'0 0 16px 0'}
              gap={'10px'}
              display={'flex'}
              justify={'center'}
              direction={'column'}
              items={'center'}>
              {!config && !photoSrc && (
                <UpdateWrapper>
                  <Button
                    width={'160px'}
                    loading={loadingAvatar}
                    type={'button'}
                    variant={'primary'}
                    onClick={handleSubmit}>
                    {t('auth:photoUpload.buttons.upload')}
                  </Button>
                  <UpdatePhotoInput
                    ref={ref}
                    onChange={handleUpdatePhoto}
                    accept={'.jpg, .png, .jpeg, .webp, .svg, .gif, .bmp'}
                  />
                </UpdateWrapper>
              )}
              {(config || photoSrc) && (
                <>
                  <UpdateWrapper>
                    <Button
                      ghost={true}
                      width={'160px'}
                      loading={loadingAvatar}
                      type={'button'}
                      variant={'primary'}
                      onClick={handleSubmit}>
                      {t('auth:photoUpload.buttons.change')}
                    </Button>
                    <UpdatePhotoInput ref={ref} onChange={handleUpdatePhoto} accept={'image/*'} />
                  </UpdateWrapper>
                  <Button
                    width={'160px'}
                    disabled={loadingAvatar}
                    type={'button'}
                    variant={'primary'}
                    onClick={handleSubmit}>
                    {t('auth:photoUpload.buttons.continue')}
                  </Button>
                </>
              )}
            </Box>
            <Box display={'flex'} justify={'center'}>
              <div onClick={handleRandomAvatar}>
                <Text decoration={'underline'} variant={'primary'} color={'quaternary'} size={14}>
                  {t('auth:photoUpload.buttons.random')}
                </Text>
              </div>
            </Box>
          </Box>
        </Box>
      </MainWrapper>
    </>
  );
};
PhotoUpload.displayName = 'PhotoUpload';
