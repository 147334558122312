import React, {useMemo} from 'react';
import {TypeCommunity} from '../../../types/auth';
import {NavContainer} from '../../Navigation';
import {ManagerDashboardContainer} from './ManagerDashboard';
import {ResidentDashboardContainer} from './ResidentDashboard';
import {useRecoilState} from 'recoil';
import {typeUser as StateTypeUser} from '../../../states/typeUser';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {EmptyTag, MobContainerProps} from '../../MobileTabs';
import {userPermissions} from '../../../states/community';
import {PermissionsList} from '../../../types/settings';

export const Dashboard: React.FC<MobContainerProps> = ({hideNav}) => {
  const [typeUser] = useRecoilState(StateTypeUser);
  const [permissions] = useRecoilState(userPermissions);
  const {getSignValue} = useGetCurrencyValue();
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  const dashboardAccess = useMemo(
    () => permissions.includes(PermissionsList.dashboardAccess) || typeUser === TypeCommunity.manager,
    [permissions, typeUser],
  );
  return (
    <Wrapper>
      {typeUser === TypeCommunity.resident && <ResidentDashboardContainer getSignValue={getSignValue} />}
      {dashboardAccess && <ManagerDashboardContainer getSignValue={getSignValue} />}
    </Wrapper>
  );
};
