import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {MemoNotInCommunity} from '../../components/common/NotInCommunity';
import {PageLoaderWrapper} from '../../components/Listings/styles';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {ViewItem} from './ViewItem';
import {currentCommunity} from '../../states/community';
import {NavContainer} from '../Navigation';

import {isListingInCommunity, useGetActualCommunityList} from '../../hooks/community';
import {useGetItem} from '../../hooks/item';
import {useDataLister} from '../../hooks/order';

export const ViewListingContainer = () => {
  const {id} = useParams<{id?: string}>();
  const [community] = useRecoilState(currentCommunity);
  const {data: item, loading} = useGetItem({id});
  const dataLister = useDataLister(item?.Lister);
  const communities = useGetActualCommunityList();
  const exist = isListingInCommunity({item, commId: community?.objectId});
  const isManyCommunities = communities?.length > 1;
  const commList = useMemo(
    () => communities?.map((el) => ({objectId: el?.objectId, name: el?.name})),
    [communities?.length],
  );
  if (loading && !item?.objectId) {
    return (
      <NavContainer>
        <PageLoaderWrapper>
          <LottieLoader allScreen={true} $isVisible={true} />
        </PageLoaderWrapper>
      </NavContainer>
    );
  }

  if (!exist && item?.objectId) {
    return <MemoNotInCommunity type={'listing'} />;
  }

  return (
    <ViewItem
      item={item}
      id={id}
      dataLister={dataLister}
      community={community}
      loading={loading}
      manyCommunities={isManyCommunities}
      commList={commList}
    />
  );
};
