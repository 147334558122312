import React from 'react';
import {JoinByReferral} from '../../components/Account/JoinByReferral';
import {useSetRecoilState} from 'recoil';
import {inviteReferralState} from '../../states/community';
import {UserPartsT} from '../../types/people';

type ApplyReferralPropsT = {
  user?: UserPartsT;
  code: string;
  forwardJoinIn: () => void;
};

export const ApplyReferral: React.FC<ApplyReferralPropsT> = ({user, code, forwardJoinIn}) => {
  const setReferral = useSetRecoilState(inviteReferralState);
  const onApply = () => {
    if (!user) return;
    setReferral({user: user, code: code});
    forwardJoinIn();
  };
  return <JoinByReferral name={user?.firstName || ''} avatar={user?.avatar} onApply={onApply} />;
};
