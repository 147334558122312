import React from 'react';
import {
  CloseButton,
  HeaderMobileWrapper,
  ListingCreatedButtonsWrapper,
  ListingCreatedCardWrapper,
  ListingCreatedContentWrapper,
  ListingCreatedSubtitle,
  ListingCreatedTitle,
  ListingCreatedWrapper,
  TopWrapper,
} from './styles';
import {useTranslation} from 'react-i18next';
import {TItem} from '../../types/item';
import {ListingCard} from './ListingCard';
import {route} from '../../constants/routes';
import {CommunityItemStateType, CommunityStateType} from '../../states/community';
import {CommunityLabel} from '../Events/CommunityLabel';
import {Button} from '../../ui-kit/Button/Button';
import {useHistory} from 'react-router-dom';
import {isEqual} from 'date-fns';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {useLinks} from '../../hooks/common';
import {useViewerId} from '../../hooks/user';
import {IGetCurrValue} from '../../types/common';
import {commLoading} from '../../types/community';
import {GoBack} from '../common/Buttons/GoBack';
import {NavContainer} from '../../containers/Navigation';
import {LottieLoader} from '../Loader/LottieLoader';

interface ListingCreatedProps {
  item?: Partial<TItem>;
  needConfirmation: boolean;
  community: CommunityStateType;
  communities: Array<CommunityItemStateType>;
  onSubmit: (communityIdsPublish?: string[] | undefined, communityIdsUnlist?: string[] | undefined) => void;
  reviewMode: boolean;
  isConfigureMode?: boolean;
  allCommunitiesIds: string[];
  publishedList: string[];
  setPublishedList: React.Dispatch<React.SetStateAction<string[]>>;
  initialListCommunity?: string[];
  getSignValue: IGetCurrValue;
  addCommLoading?: commLoading;
  setCommLoading?: (t: commLoading) => void;
}

export const ListingCreated: React.FC<ListingCreatedProps> = ({
  needConfirmation,
  item,
  communities,
  community,
  onSubmit,
  reviewMode,
  setPublishedList,
  publishedList,
  allCommunitiesIds,
  isConfigureMode,
  initialListCommunity = [],
  getSignValue,
  addCommLoading,
  setCommLoading,
}) => {
  const {t} = useTranslation();
  const {push, goBack} = useHistory();
  const userId = useViewerId('objectId');

  const isSelectAll: boolean = allCommunitiesIds.length === publishedList.length;

  const {getLink} = useLinks();
  const isUpdated = isEqual(new Date(item?.createdAt ?? ''), new Date(item?.updatedAt ?? ''));
  const title = reviewMode
    ? isUpdated
      ? t('listings:created.titleUpdate')
      : t('listings:created.title')
    : t('listings:created.titleConfirm');
  const subtitle = reviewMode
    ? isUpdated
      ? t('listings:created.subtitleUpdate')
      : needConfirmation
      ? t('listings:created.subtitleApprove')
      : t('listings:created.subtitle')
    : t('listings:created.subtitleConfirm');

  const handleRedirect = (to: string) => () => push(to);
  const handleSubmit = () => {
    setCommLoading?.('one');
    onSubmit(
      publishedList.filter((communityId) => !initialListCommunity?.includes(communityId)),
      allCommunitiesIds.filter(
        (communityId) => !publishedList.includes(communityId) && initialListCommunity?.includes(communityId),
      ),
    );
  };
  /*const handleSubmitAll = () => {
    setCommLoading?.('all');
    onSubmit(allCommunitiesIds.filter((communityId) => !item?.Published?.find((el) => el.objectId === communityId)));
    setPublishedList(allCommunitiesIds);
  };*/

  const communitiesList = reviewMode
    ? communities.filter((el) => el?.objectId && publishedList.includes(el.objectId))
    : communities.length
    ? communities
    : community
    ? [community]
    : [];

  const getOnChange = (id?: string) => (checked?: boolean) => {
    if (!id) return;
    checked ? setPublishedList((prev) => [...prev, id]) : setPublishedList((prev) => prev.filter((el) => el !== id));
  };

  const changeChooseCommunities = () => {
    if (isSelectAll) {
      setPublishedList([]);
    } else {
      setPublishedList(allCommunitiesIds);
    }
  };

  const handleRedirectToLoop = () => {
    push(getLink(route.loop.path));
  };

  if (!item?.objectId)
    return (
      <NavContainer>
        <LottieLoader $isVisible={true} allScreen={true} />
      </NavContainer>
    );

  return (
    <NavContainer>
      <ListingCreatedWrapper>
        <HeaderMobileWrapper>
          <GoBack goBack={goBack} title={t('listings:generateListing.reviewCom')} />
          <CloseButton onClick={handleRedirectToLoop}>
            <IconSvg type={'close'} width={'24px'} height={'24px'} viewBox={'-1 -1 22 22'} />
          </CloseButton>
        </HeaderMobileWrapper>
        <ListingCreatedContentWrapper>
          <ListingCreatedTitle>{title}</ListingCreatedTitle>
          <ListingCreatedSubtitle>{subtitle}</ListingCreatedSubtitle>
          <ListingCreatedCardWrapper>
            <ListingCard
              to={getLink(route.listingView.get({id: item?.id || '/'}))}
              item={item ?? {}}
              aspectRatio={'300:200'}
              src={item?.serverImages?.[0]?.file?.url}
              communityId={community?.id}
              isOwner={true}
              isHideLabel
              getSignValue={getSignValue}
              lister={item?.Lister}
            />
          </ListingCreatedCardWrapper>
          <TopWrapper>
            <ListingCreatedSubtitle>{t('listings:created.postedIn')}:</ListingCreatedSubtitle>
            {!!communitiesList.length && communitiesList.length > 1 && !reviewMode && (
              <Button
                variant={'select'}
                width={'73px'}
                height={'21px'}
                ghost
                fontSize={'10px'}
                onClick={changeChooseCommunities}>
                {!isSelectAll ? t('buttons:common.selectAll') : t('buttons:common.unselectAll')}
              </Button>
            )}
          </TopWrapper>
          {communitiesList.map((el) => {
            const isChecked = !!el?.objectId && publishedList.includes(el.objectId);
            return (
              <CommunityLabel
                key={`${el.objectId}` + isChecked}
                community={el}
                icon={<IconSvg type={'people'} />}
                count={el.countMembers}
                needConfirmation={needConfirmation}
                isUnmanaged={
                  el.listingApproval === 'unmanaged' || item?.Published?.some((cmt) => cmt.objectId === el.objectId)
                }
                isCheckboxMode={!reviewMode}
                isChecked={isChecked}
                onChange={getOnChange(el?.objectId)}
                isUserManager={el?.Owner?.objectId === userId}
                userId={userId}
                isPublished={el.objectId ? initialListCommunity?.includes(el.objectId) : false}
              />
            );
          })}
          <ListingCreatedButtonsWrapper>
            {reviewMode ? (
              <>
                <Button
                  type={'button'}
                  variant={'secondary'}
                  ghost={true}
                  onClick={handleRedirect(getLink(route.listingViewEdit.get({id: item?.objectId || '/'})))}>
                  {t('listings:created.preview')}
                </Button>
                <Button type={'button'} variant={'primary'} onClick={handleRedirect(getLink(route.createItem.path))}>
                  {t('listings:created.addAnother')}
                </Button>
              </>
            ) : isConfigureMode ? (
              <>
                <Button
                  variant={'secondary'}
                  ghost
                  rounded={true}
                  onClick={handleRedirect(getLink(route.listingView.get({id: item?.id || '/'})))}>
                  {t('listings:created.cancel')}
                </Button>
                <Button variant={'primary'} rounded={true} onClick={handleSubmit} loading={addCommLoading === 'one'}>
                  {t('listings:created.save')}
                </Button>
              </>
            ) : (
              <>
                <Button variant={'primary'} width={'100%'} loading={addCommLoading === 'one'} onClick={handleSubmit}>
                  {t('listings:created.confirm')}
                </Button>
              </>
            )}
          </ListingCreatedButtonsWrapper>
        </ListingCreatedContentWrapper>
      </ListingCreatedWrapper>
    </NavContainer>
  );
};
