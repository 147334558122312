import React from 'react';
import {CloseButton, IconWrapper, Subtitle, Title, Wrapper} from './styles';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Br} from '../../../ui-kit/Typography/Br';
import {useTranslation} from 'react-i18next';

export const ListingNotFound: React.FC<{goBack?: () => void}> = ({goBack}) => {
  const {t} = useTranslation();

  return (
    <Wrapper contentWidth={600} topPadding={120}>
      <CloseButton onClick={goBack}>
        <IconSvg type="close" viewBox="-1 -1 22 22" />
      </CloseButton>
      <IconWrapper>
        <IconSvg type={'bored'} viewBox={'0 0 298 158'} width="298" height="158" />
      </IconWrapper>
      <Br indent={16} />
      <Title>{t('listings:notFound.title')}</Title>
      <Br indent={20} />
      <Subtitle>{t('listings:notFound.subtitle')}</Subtitle>
    </Wrapper>
  );
};
