import React, {memo, useEffect, useState} from 'react';

export type ImgPropsT = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  src?: string;
  fallbackSrc?: string;
};

export const Img: React.FC<ImgPropsT> = memo(({src, fallbackSrc, ...props}) => {
  const [state, setState] = useState<{src?: string | null; errored: boolean}>({src: src, errored: false});

  useEffect(() => {
    setState({src: src, errored: false});
  }, [src]);

  const onError = () => {
    if (state.src === fallbackSrc && !state.errored) return;
    if (!state.errored) {
      setState({
        src: fallbackSrc,
        errored: true,
      });
    } else if (state.errored && state.src) {
      setState({
        src: fallbackSrc, //null
        errored: false,
      });
    }
  };
  return <img src={state?.src || ''} onError={onError} {...props} />;
});
