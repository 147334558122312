import React from 'react';
import {RequestCardProps} from './types';
import {Card} from './Card';
import {Review} from './Review';

export const RequestCard: React.FC<RequestCardProps> = ({
  title,
  cardType,
  userType,
  rewardType,
  image,
  tags,
  dateLabel,
  buttonLabel,
  paymentLabel,
  payments,
  descr,
  review,
  users,
  check,
  rating,
  maxPeriod,
  reviewType,
  cancelled,
  pickerLabels,
  listingType,
  pickerPlaceholders,
  pricePeriod,
  smallPicker,
  editOrderData,
  opponentInfo,
  itemInfo,
  readyReviewsData,
  isRequester,
  reviewLoading,
  isDeleted,
  durationUnit,
  captions,
  buttonSelectText,
  showCredit,
  toggleCredit,
  creditInfo,
  textMode,
  dateInfoLabels,
  handlePushToListing,
  Gallery,
  Item,
}) => {
  if (cardType === 'regular') {
    return (
      <Card
        Gallery={Gallery}
        Item={Item}
        title={title}
        tags={tags}
        image={image}
        descr={descr}
        userType={userType}
        rewardType={rewardType}
        dateLabel={dateLabel}
        paymentLabel={paymentLabel}
        buttonLabel={buttonLabel}
        payments={payments}
        pickerLabels={pickerLabels}
        listingType={listingType}
        pickerPlaceholders={pickerPlaceholders}
        pricePeriod={pricePeriod}
        smallPicker={smallPicker}
        editOrderData={editOrderData}
        isDeleted={isDeleted}
        durationUnit={durationUnit}
        captions={captions}
        buttonSelectText={buttonSelectText}
        showCredit={showCredit}
        toggleCredit={toggleCredit}
        creditInfo={creditInfo}
        textMode={textMode}
        dateInfoLabels={dateInfoLabels}
        handlePushToListing={handlePushToListing}
      />
    );
  } else {
    return (
      <Review
        title={title}
        tags={tags}
        descr={descr}
        userType={userType}
        rewardType={rewardType}
        dateLabel={dateLabel}
        paymentLabel={paymentLabel}
        buttonLabel={buttonLabel}
        payments={payments}
        check={check}
        review={review}
        users={users}
        rating={rating}
        maxPeriod={maxPeriod}
        reviewType={reviewType}
        cancelled={cancelled}
        opponentInfo={opponentInfo}
        itemInfo={itemInfo}
        listingType={listingType}
        pricePeriod={pricePeriod}
        pickerPlaceholders={pickerPlaceholders}
        pickerLabels={pickerLabels}
        smallPicker={smallPicker}
        readyReviewsData={readyReviewsData}
        isRequester={isRequester}
        reviewLoading={reviewLoading}
        showCredit={showCredit}
        toggleCredit={toggleCredit}
        creditInfo={creditInfo}
        dateInfoLabels={dateInfoLabels}
      />
    );
  }
};
