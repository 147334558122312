import {useRef} from 'react';

function useMutableState<TState extends unknown>(state: TState): [TState, (s: TState) => void] {
  const mState = useRef<TState>(state);

  function setState(nextState: TState | ((prev: TState) => TState)): void {
    if (nextState instanceof Function) {
      mState.current = nextState(mState.current);
      return;
    }

    mState.current = nextState;
  }

  return [mState.current, setState] as [TState, typeof setState];
}

export default useMutableState;
