import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Earn} from './Earn';
import {HeaderPlan} from './HeaderPlanCard';
import {PaymentPlanName} from '../../../../queries/types/payments';
import {IGetCurrValue} from '../../../../types/common';
import {Collapse} from '../../../../ui-kit/Reveal/Collapse';
import {Media} from '../../../../ui-kit/theme';
import {Br} from '../../../../ui-kit/Typography/Br';
import {Text} from '../../../../ui-kit/Typography/Text';
import {
  EmptySpace,
  Hidden,
  IconSubscr,
  ItemSubscr,
  LabelCard,
  LabelContainer,
  PlanButton,
  PlanCardDescr,
  PlanOptionsContainer,
  TitleSubscr,
  ViewOptions,
  WrapperPlanCard,
} from '../styles';

import {SelectedTPlan, SelectPlanRes} from '../../../../hooks/community';

type TProps = {
  data: SelectedTPlan;
  params: SelectPlanRes;
  isChecked?: boolean;
  updateMode?: {
    isRecommended?: boolean;
    isCurrent?: boolean;
    isUpgrade?: boolean;
  };
  getCodeValue: IGetCurrValue;
  getSignValue: IGetCurrValue;
  currency: string;
};

type TOptions = {options: {isSupport?: boolean; text?: string}[]};

const Options: React.FC<TOptions> = ({options}) => {
  const {t} = useTranslation();

  return (
    <>
      <TitleSubscr>{t('community:plan.titleSubscr')}</TitleSubscr>
      {options.map((opt) => (
        <ItemSubscr key={opt.text}>
          <IconSubscr
            width={'20'}
            height={'20'}
            type={opt.isSupport ? 'circle-ok' : 'close'}
            stroke={opt.isSupport ? 'green' : 'darkgray'}
            viewBox="0 0 20 20"
          />
          {t(`community:plan.options.${opt.text}`)}
        </ItemSubscr>
      ))}
    </>
  );
};

const Label: React.FC<{type: 'current' | 'recommended'}> = ({type}) => {
  const {t} = useTranslation();

  return (
    <LabelContainer type={type}>
      <Text weight={'semi-bold'} color={'primary'}>
        {t(`community:plan.labels.${type}`)}
      </Text>
    </LabelContainer>
  );
};

export const PlanCard: React.FC<TProps> = ({
  params,
  data,
  getCodeValue,
  getSignValue,
  currency,
  isChecked,
  updateMode,
}) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const {t} = useTranslation();
  const {objectId, name, currentPriceMonth, currentPriceYear, descr, options, communityFee} = data;
  const {onSubmit} = params;

  const {isCurrent, isUpgrade, isRecommended} = updateMode || {};

  const updateButtonText = t(
    `community:plan.button.${isCurrent ? 'currentPlan' : isUpgrade ? 'upgrade' : 'downgrade'}`,
  );

  const handleSubmit = () => {
    if (updateMode?.isCurrent) return;
    onSubmit({name: 'plan', value: objectId});
  };

  const handleCollapse = () => {
    setIsCollapse((prev) => !prev);
  };

  return (
    <WrapperPlanCard isChecked={isChecked || (isRecommended && !isCurrent)}>
      {(isCurrent || isRecommended) && <Label type={isCurrent ? 'current' : 'recommended'} />}
      {name === PaymentPlanName.pro && <LabelCard>{t('community:plan.recommended')}</LabelCard>}
      <HeaderPlan
        name={name}
        priceMonth={currentPriceMonth}
        priceYear={currentPriceYear}
        getCodeValue={getCodeValue}
        currency={currency}
        getSignValue={getSignValue}
      />
      <PlanCardDescr>{t(`community:plan.descr.${descr}`)}</PlanCardDescr>
      <EmptySpace />
      <Hidden mq={Media.down.l}>
        <Options options={options} />
      </Hidden>
      <Hidden mq={Media.up.l}>
        <PlanOptionsContainer>
          <Collapse in={isCollapse}>
            <Options options={options} />
          </Collapse>
          <ViewOptions onClick={handleCollapse}>{t(`community:plan.button.viewOptions`)}</ViewOptions>
        </PlanOptionsContainer>
      </Hidden>
      <Br indent={17} />
      <Earn
        isHaveCommunityFee={communityFee}
        fees={{
          serviceFees: data.serviceFees,
          rentFees: data.rentFees,
          salesFees: data.salesFees,
        }}
        getSignValue={getSignValue}
      />

      <PlanButton
        variant={!updateMode ? (isChecked ? 'secondary' : 'primary') : 'primary'}
        disabled={isChecked}
        type={'button'}
        ghost={!!updateMode && !isUpgrade}
        onClick={handleSubmit}>
        {!updateMode
          ? isChecked
            ? t(`community:plan.button.selectedPlan`)
            : t(`community:plan.button.selectPlan`)
          : updateButtonText}
      </PlanButton>
    </WrapperPlanCard>
  );
};
