import {TInterest, TRecommendantions} from './interests';

export enum ProfileField {
  username = 'username',
  bio = 'bio',
  languages = 'languages',
  study = 'study',
  work = 'work',
  howDidYouHear = 'howDidYouHear',
  birthDate = 'birthDate',
  interests = 'interests',
  livingWith = 'livingWith',
  occupation = 'occupation',
}
export type Profile = {
  username: string;
  bio: string;
  languages: string;
  study: string;
  work: string;
  zip?: string;
  aptSuite?: string;
  address?: string;
  Location?: {
    link?: string;
  };
  Language?: {
    link?: string;
  };
  howDidYouHear?: string;
  interests?: (string | undefined)[];
  livingWith?: string;
  birthDate?: Date | null;
  gender?: string;
  firstName?: string;
  lastName?: string;
  checkListSeen?: boolean;
  profileViewOff?: boolean;
  phone?: string;
  personInterests?: Array<TInterest>;
  recommendations?: TRecommendantions;
  occupation?: string;
  tips?: {
    welcomeTipsViewed: boolean;
    multiplyCommunityTip: boolean;
  };
  lastVisitedCommunity?: string;
};
