import {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {useMutation, useQuery} from '@apollo/client';
import {useLinks} from './common';
import {useGetCommunityAdmin, useRemoveCommunityAdmin} from './community';
import {useGetUsersItemsIds} from './item';
import {route} from '../constants/routes';
import {GQLJoinRequestOrder, GQLJoinRequestWhereInput, GQLUserOrder, GQLUserWhereInput} from '../graphql.schema';
import {toPointer} from '../helpers/parse';
import {toStateNewMembers, toStatePeople} from '../helpers/people';
import {isString} from '../helpers/validations';
import {RemoveResidentFromCommunityRequest} from '../queries/community';
import {
  EditCommunityAdminQuery,
  EditCommunityAdminRequest,
  EditCommunityAdminResponse,
  GetAdminsLabelsQuery,
  GetCommunityMembersReqType,
  GetCommunityMembersRequest,
  GetCommunityMembersRequestManager,
  GetCommunityMembersResType,
  GetNewMembersReqType,
  GetNewMembersRequest,
  GetNewMembersResType,
  GetUserReqType,
  GetUserRequest,
  GetUserRequestFull,
  GetUserResType,
  GetUsersAvatar,
  GetUsersAvatarReqType,
  GetUsersAvatarResType,
  SetJoinRequestStatus,
  SetJoinRequestStatusReqType,
  SetJoinRequestStatusResType,
  setUserAdminReqType,
  SetUserAdminRequest,
  SetUserAdminResType,
  TGetAdminsLabelsRequest,
  TGetAdminsLabelsResponse,
  TUpdateCommunityAdminRequest,
  TUpdateCommunityAdminResponse,
  UpdateCommunityAdminQuery,
} from '../queries/people';
import {currentCommunity, onlineUsers} from '../states/community';
import {TypeCommunity} from '../types/auth';
import {JoinRequestManageAction, JoinRequestStatuses, MemberT, PeopleSearchType} from '../types/people';
import {TAdminsPermissions} from '../types/settings';
import {useViewer} from './user';
import {analyticsTrackFN} from '../helpers/account';

export const useGetPeople = (options?: {
  where?: GQLUserWhereInput;
  order?: GQLUserOrder | GQLUserOrder[];
  skip?: number;
  first?: number;
  communityId?: string;
  withManager?: boolean;
}) => {
  const online = useRecoilValue(onlineUsers);
  const {data, ...response} = useQuery<GetCommunityMembersResType, GetCommunityMembersReqType>(
    options?.withManager ? GetCommunityMembersRequestManager : GetCommunityMembersRequest,
    {
      variables: {
        ...(options?.where ? {where: options.where} : {}),

        ...(options?.where ? {whereAdmin: {User: {have: {...options.where}}}} : {}),

        ...(options?.order ? {order: options.order} : {}),
        ...(options?.first ? {first: options?.first} : {}),
        ...(options?.skip ? {skip: options?.skip} : {}),
        id: options?.communityId || '',
      },

      skip: !isString(options?.communityId),
      ssr: true,
    },
  );

  return {
    ...response,
    data: toStatePeople(data?.community, options?.where, online) || null,
  };
};
export const PeopleLoadCount = 16;
export const useGetCommunityPeople = (params: {
  id?: string;
  skip?: number;
  withManager?: boolean;
  first?: number;
  where?: GQLUserWhereInput;
  peopleType?: PeopleSearchType;
}) => {
  const viewer = useViewer();
  const {data, ...response} = useGetPeople({
    skip: params?.skip || 0,
    first: params?.first || PeopleLoadCount,
    communityId: params.id,
    where: params.where,
    withManager: params.withManager,
    order: [GQLUserOrder.listingCount_DESC, GQLUserOrder.Avatar_DESC],
  });
  const community = useRecoilValue(currentCommunity);
  let hasNextPage = (data?.residentsCount || 0) > (data?.Residents?.length || 0);
  if (params?.peopleType === PeopleSearchType.Admins) hasNextPage = false;

  useEffect(() => {
    if (
      (params?.where?.aptSuite?.matchesRegex && !!data?.AllMembers?.length) ||
      (params?.where?.firstName?.matchesRegex && !!data?.AllMembers?.length)
    ) {
      analyticsTrackFN('Searched People', {
        query: params?.where?.aptSuite?.matchesRegex || params?.where?.firstName?.matchesRegex,
        results: data?.AllMembers?.length || 0,
        userName: viewer?.username,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
    }
  }, [!!data?.AllMembers?.length]);

  return {...response, data, hasNextPage};
};

export const getNewMembers = (options?: {
  where?: GQLJoinRequestWhereInput;
  order?: GQLJoinRequestOrder;
  skip?: boolean;
  first?: number;
  communityId?: string;
}) => {
  const {data, ...response} = useQuery<GetNewMembersResType, GetNewMembersReqType>(GetNewMembersRequest, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
      id: options?.communityId || '',
    },
    skip: !isString(options?.communityId),
    ssr: true,
    fetchPolicy: 'cache-and-network',
  });
  return {...response, data: toStateNewMembers(data?.community) || null, count: data?.community.JoinRequests.count};
};

export const useGetMembersAvatar = () => {
  const [getMembersAvatars, {loading}] = useMutation<GetUsersAvatarResType, GetUsersAvatarReqType>(GetUsersAvatar);
  return {getMembersAvatars, loading};
};

export const useGetNewMembers = (params: {id?: string}) => {
  const {data, ...response} = getNewMembers({
    skip: !params.id,
    first: 50,
    communityId: params.id,
    where: {status: {equalTo: JoinRequestStatuses.pending}},
    order: GQLJoinRequestOrder.createdAt_DESC,
  });

  const newMembers: MemberT[] = [];
  const uniqIds: string[] = [];
  data?.newMembers?.forEach((el) => {
    if (!uniqIds.includes(el.objectId)) {
      newMembers.push(el);
      uniqIds.push(el.objectId);
    }
  });

  return {...response, data: {newMembers}};
};

export const useCheckUserType = (id?: string) => {
  const [community] = useRecoilState(currentCommunity);

  if (!id || !community) return;
  if (id === community.Owner?.objectId) return TypeCommunity.manager;
  if (community?.Admins?.find((el) => el?.User?.objectId === id)) return TypeCommunity.admin;
  if (community?.Residents?.find((el) => el?.objectId === id)) return TypeCommunity.resident;
  return;
};

export const useGetUserInfo = (options: {id?: string; fullName?: boolean}) => {
  const {data, ...response} = useQuery<GetUserResType, GetUserReqType>(
    options?.fullName ? GetUserRequestFull : GetUserRequest,
    {variables: {id: options.id || ''}, skip: !options.id},
  );

  return {data, ...response};
};

export const getUserInfo = (params: {id: string; fullName?: boolean}) => {
  const response = useGetUserInfo({id: params.id, fullName: params?.fullName});
  return {
    ...response,
    data: response?.data?.user ? {...response?.data?.user, isOnline: response?.data?.user?.isOnline} : undefined,
  };
};

export const useMakeUserAdmin = () => {
  const [result, setResult] = useState<SetUserAdminResType | null>(null);
  const [error, setError] = useState<string>('');
  const [setAdminRequest, otherData] = useMutation<SetUserAdminResType, setUserAdminReqType>(SetUserAdminRequest);

  const makeAdmin = async (options: {
    id: string;
    communityId: string;
    label?: string;
    permissionList?: Array<keyof TAdminsPermissions>;
    onSuccess?: (data?: SetUserAdminResType | null) => void;
  }): Promise<boolean> => {
    try {
      if (!options.id || !options.communityId) return false;

      const result = await setAdminRequest({
        variables: {
          userId: options.id,
          communityId: options.communityId,
          label: options.label,
          permissionList: options.permissionList || [],
        },
      });
      const data = result?.data;
      if (!data) throw new Error('error:makeAdmin');
      setResult(data);
      options?.onSuccess?.(data);
      return true;
    } catch (error) {
      setError(error);
    }

    return false;
  };

  return {...otherData, makeAdmin, error, result};
};

export const useManagePeople = () => {
  const [setStatusRequest] = useMutation<SetJoinRequestStatusResType, SetJoinRequestStatusReqType>(
    SetJoinRequestStatus,
  );

  const manageRequestStatus = async (options: {
    requestId: string;
    type: JoinRequestManageAction;
    onSuccess?: () => void;
  }): Promise<boolean> => {
    try {
      if (!options.requestId || !options.type) return false;
      const newStatus =
        options.type === JoinRequestManageAction.approve ? JoinRequestStatuses.approved : JoinRequestStatuses.rejected;

      const result = await setStatusRequest({
        variables: {id: options.requestId, fields: {status: newStatus}},
      });
      const data = result?.data;
      if (!data) throw new Error('error:setStatus');
      options?.onSuccess?.();
    } catch (error) {
      return false;
    }
    return true;
  };

  return {manageRequestStatus};
};

export const useManageActions = () => {
  const {makeAdmin} = useMakeUserAdmin();
  const {manageRequestStatus} = useManagePeople();
  const {remove} = useRemoveCommunityAdmin();
  const getAdmin = useGetCommunityAdmin();
  const setCommunity = useSetRecoilState(currentCommunity);
  const removeFromCommunity = useRemoveFromCommunity();
  const {getLink} = useLinks();

  const personDeny = (joinReqId: string, onSuccess?: () => void) => {
    manageRequestStatus({
      requestId: joinReqId,
      type: JoinRequestManageAction.deny,
      onSuccess: onSuccess,
    });
  };

  const personApprove = (joinReqId: string, onSuccess?: () => void) => {
    manageRequestStatus({
      requestId: joinReqId,
      type: JoinRequestManageAction.approve,
      onSuccess: onSuccess,
    });
  };

  const personView = (id: string) => getLink(route.profile.get({id}));

  const personRemoveAdmin = (id: string, communityId?: string, onSuccess?: () => void) => {
    getAdmin(id).then((res) => {
      if (res?.id) {
        remove(res.id).then(() => onSuccess?.());
      }
    });
  };

  const personMakeAdmin = (id: string, communityId: string, onSuccess?: () => void) => {
    makeAdmin({id, communityId, onSuccess: onSuccess}).then(() => {
      getAdmin(id).then((res) => {
        if (res) {
          setCommunity((community) => ({
            ...community,
            Admins: [...(community?.Admins ?? []), res],
          }));
        }
      });
    });
  };

  const personRemove = (id: string, onSuccess?: () => void) => {
    return removeFromCommunity(id).then(() => onSuccess?.());
  };

  return {
    personDeny,
    personApprove,
    personMakeAdmin,
    personRemoveAdmin,
    personView,
    personRemove,
  };
};

export const useMembersListingsCount = (
  members: MemberT[],
  communityId?: string,
  communtiyTypeName?: string,
): [string, number][] => {
  const membersIds = members?.map((m) => m?.id);
  const membersItems = useGetUsersItemsIds({
    ...(membersIds?.length > 0
      ? {
          where: {
            AND: [
              {
                Lister: {
                  have: {
                    id: {in: membersIds?.filter((el) => el)},
                  },
                },
              },
              {
                Published: {
                  contains: [
                    toPointer({
                      __typename: communtiyTypeName || '',
                      objectId: communityId || '',
                    }),
                  ],
                },
              },
            ],
          },
        }
      : {}),
  });
  return membersIds.map((mId) => [mId, membersItems?.filter((el) => el?.Lister?.id === mId)?.length]);
};

export const useRemoveFromCommunity = () => {
  const [community] = useRecoilState(currentCommunity);
  const [removeFormCommunity] = useMutation<any, {userId: string; communityId: string}>(
    RemoveResidentFromCommunityRequest,
  );

  const remove = async (userId: string) => {
    if (!community?.objectId) return;
    try {
      const {data} = await removeFormCommunity({variables: {communityId: community.objectId, userId}});

      if (!data) throw new Error('error:error');
    } catch (e) {
      console.log('Error', e);
    }
  };

  return remove;
};

export const useUpdateAdmin = () => {
  const [callUpdate, otherData] = useMutation<TUpdateCommunityAdminResponse, TUpdateCommunityAdminRequest>(
    UpdateCommunityAdminQuery,
  );

  const updateAdmin = async (options: {id: string; label?: string}) => {
    const {id, label} = options;

    await callUpdate({
      variables: {
        id,
        fields: {
          label,
        },
      },
    });
  };

  return {
    ...otherData,
    updateAdmin,
  };
};

export type editAdminT = (options: {
  userId?: string;
  label?: string;
  communityId?: string;
  permissionList?: string[];
}) => Promise<void>;

export const useEditAdmin = () => {
  const [edit, otherData] = useMutation<EditCommunityAdminResponse, EditCommunityAdminRequest>(EditCommunityAdminQuery);

  const editAdmin: editAdminT = async (options: {
    userId?: string;
    label?: string;
    communityId?: string;
    permissionList?: string[];
  }) => {
    const {userId, label, communityId, permissionList} = options;
    if (!userId || !communityId) return;
    await edit({
      variables: {
        userId,
        label,
        communityId,
        permissionList,
      },
    });
  };

  return {
    ...otherData,
    editAdmin,
  };
};

export const useGetAdminsLabels = (): Record<string, string> => {
  const community = useRecoilValue(currentCommunity);
  const {data} = useQuery<TGetAdminsLabelsResponse, TGetAdminsLabelsRequest>(GetAdminsLabelsQuery, {
    variables: {
      communityId: community?.objectId as string,
    },
    skip: !community?.objectId,
  });
  const adminsData = data?.communityAdmins.edges.map((el) => el.node);

  return adminsData?.reduce((acc, el) => ({...acc, [el?.User?.objectId]: el?.label}), {}) || {};
};
