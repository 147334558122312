import styled from 'styled-components';

import {IconSvg} from '../Icon/Svg';

export const NoteWrapper = styled.div`
  padding: 8px 10px;
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.background.septenary};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 10px;
  line-height: 150%;
  display: flex;
  gap: 10px;
  align-items: center;
  & > span {
    width: auto;
  }
`;

export const NoteIconWrapper = styled.div``;

export const NoteIcon = styled(IconSvg).attrs({
  width: '20px',
  height: '20px',
  viewBox: '0 0 20 20',
})`
  display: block;
`;

export const NoteText = styled.span``;
