import React, {useEffect, useState} from 'react';
import {JoinIn} from '../../components/Auth/JoinIn';
import {useGetJoinCommunity} from '../../hooks/community';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {SocialAuth} from '../../types/auth';
import {User} from '../../queries/types/user';
import {useGetMembersAvatar} from '../../hooks/people';
import {CommunityType} from '../../types/settings';
import {useSetRecoilState} from 'recoil';
import {aliasCommunity, skipParams} from '../../states/community';
import {toStateCommunity} from '../../helpers/community';
import {CommunityOptionsFields} from '../../types/community';

export const JoinInContainer = () => {
  const {onSearch: SearchJoinIn} = useGetJoinCommunity();
  const {push} = useHistory();
  const {getMembersAvatars, loading: loadingMembers} = useGetMembersAvatar();
  const [members, setMembers] = useState<User[]>([]);
  const [community, setCommunity] = useState<Partial<CommunityType>>();
  const setAlisedCommunity = useSetRecoilState(aliasCommunity);
  const setSkipParams = useSetRecoilState(skipParams);

  useEffect(() => {
    const getMembers = async () => {
      if (!community?.objectId) return;
      const result = await getMembersAvatars({
        variables: {
          communityId: community?.objectId,
        },
      });
      setMembers(result?.data?.getResidentsAvatar?.users || []);
    };
    getMembers();
  }, [community?.objectId]);

  const onSearch = async (id: string) => {
    try {
      const result = await SearchJoinIn(id);
      if (result) {
        setCommunity(toStateCommunity(result));
        return true;
      } else return false;
    } catch {
      return false;
    }
  };

  const onJoin = async () => {
    setAlisedCommunity(community);
    const skipPhone = community?.options?.[CommunityOptionsFields.requirePhone] === false;
    const skipAddress = community?.options?.[CommunityOptionsFields.requireAddress] === false;
    setSkipParams({autoAddKey: community?.objectId, autoAddress: true, skipAddress, skipPhone});
    push(route.social.get({tab: SocialAuth.signUp}));
  };

  return (
    <JoinIn
      onSearch={onSearch}
      communityName={community?.name || ''}
      onJoin={onJoin}
      loadingMembers={loadingMembers}
      members={members}
      communityAvatar={community?.avatar}
    />
  );
};
