import {isNumber} from '../validations';
import {Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';

export type LengthOptions = {
  equal?: number;
  gt?: number;
  lt?: number;
  gte?: number;
  lte?: number;
};

export class ValidationNumber<T extends anyObject> extends Validation<T> {
  constructor(message?: Message) {
    super(message);
    this.$validators = [];
    this.$validators.push(Validator.of((value: unknown) => isNumber(value), message));
  }

  // gt() {}
  // gte() {}
  // lt() {}
  // lte() {}
  // equal() {}
  // positive() {}
  // int() {}
  // float() {}
}
