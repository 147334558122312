import React from 'react';
import {useTranslation} from 'react-i18next';
import {CompanyForm} from '../../components/LocalBiz/Form';
import {Seo} from '../../components/SEO';
import {makeSeoKeywords} from '../../helpers/common';
import {useCreateCompany} from '../../hooks/company';
import {CompanyFormAction} from '../../types/company';

export const CreateCompanyContainer = () => {
  const {t} = useTranslation();

  const formData = useCreateCompany();

  const textSeo = t('common:seo.events.add');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <CompanyForm action={CompanyFormAction.create} formData={formData} />
    </>
  );
};
