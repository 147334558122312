import {useState, Dispatch, SetStateAction, useMemo} from 'react';
import {ApolloError} from '@apollo/client';
import {ValidationError} from '../helpers/FormValidation/ValidationError';

type AnyRecord<T> = Partial<Record<keyof T, any>>;

interface FormFieldError {
  field?: string;
  message?: string;
}

export type FormErrorFields<T extends AnyRecord<T>> = FormFieldError & Record<keyof T, boolean>;

const errorProxyHandler = {
  get(target: FormFieldError, key: string) {
    if (key === 'message') return target.message;
    // error.username returns username === target.field
    return key === target.field;
  },
};

type TReturn<T> = [FormErrorFields<T>, (error: Error | null) => void];

export const useFormError = <T extends AnyRecord<T>>(): TReturn<T> => {
  const [state, setState] = useState<FormFieldError>({});

  const setErrors = (error: Error | null): void => {
    if (error === null) {
      return setState({});
    }

    if (error instanceof ValidationError) {
      return setState({field: error.field, message: error.message});
    }

    if (error instanceof ApolloError) {
      return setState({message: error?.graphQLErrors?.[0]?.message || ''});
    }

    if (error instanceof Error) {
      return setState({message: error?.message || ''});
    }
  };

  const proxyError = useMemo(() => new Proxy(state, errorProxyHandler), [state]) as FormErrorFields<T>;

  return [proxyError, setErrors];
};

export const useError = (): [string, Dispatch<SetStateAction<string>>] => {
  const [error, setError] = useState('');

  const setErrors = (error: unknown): void => {
    if (error instanceof ApolloError) {
      setError(error?.graphQLErrors?.[0]?.message || '');
    }

    if (error instanceof Error) {
      setError(error?.message || '');
    }

    if (typeof error === 'string') {
      setError(error);
    }
  };

  return [error, setErrors];
};
