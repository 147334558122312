import {Community} from '../queries/types/community';
import {ParseObject} from '../queries/types/parse';
import {User} from '../queries/types/user';
import {PointerFile, TImage} from './common';
export interface TCompany extends ParseObject {
  name: string;
  description: string;
  category: string;
  website: string;
  phone: string;
  Owner: User;
  Published: Array<Partial<Community>>;
  Featured: Array<Partial<Community>>;
  promotion: boolean;
  promoTagline: string;
  promoDescr: string;
  interestedUsers: Array<Partial<User>>;
  visitedWebsite: string;
  revealCode: string;
  images: PointerFile[];
  isFeatured: boolean;
  isPublished?: boolean;
  countViews?: number;
  countClicks?: number;
  promoCTA?: TPromoCTA;
}

export interface TCompanyFormValues {
  name: string;
  description: string;
  category: string;
  website: string;
  phone: string;
  isPromotion: boolean;
  promoTag?: string;
  promoDescr?: string;
  promoCTA?: TPromoCTA;
  valueCTA?: string;
  images?: Array<TImage>;
  serverImages?: Partial<PointerFile>[];
}

export const CompanyFormKeys: Record<keyof TCompanyFormValues, keyof TCompanyFormValues> = {
  name: 'name',
  description: 'description',
  category: 'category',
  website: 'website',
  phone: 'phone',
  isPromotion: 'isPromotion',
  promoTag: 'promoTag',
  promoDescr: 'promoDescr',
  promoCTA: 'promoCTA',
  valueCTA: 'valueCTA',
  images: 'images',
  serverImages: 'serverImages',
};

export enum TPromoCTA {
  none = 'none',
  visitWebsite = 'visitWebsite',
  revealCode = 'revealCode',
  interested = 'interested',
}

export enum CompanyFormAction {
  create = 'create',
  edit = 'edit',
}
