import React, {useEffect, useState} from 'react';
import {
  BodyWrapper,
  BottomLogoWrapper,
  ButtonWrapper,
  CommunityAvatar,
  CommunityLabelText,
  CommunityLabelWrapper,
  DarkBackground,
  FooterTextAuth,
  HaveAccountWrapper,
  LinkAuth,
  MoreButtonWrapper,
  NavigationButtonWrapper,
  PanelWrapper,
  PopupWrapper,
  SignupBgImage,
  TopLogoWrapper,
  UpdateAvatarError,
  WelcomeAvatarWrapper,
  WelcomeUserText,
  WelcomeUserWrapper,
  WrapperAuthContainer,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {AuthTab, SocialAuth} from '../../types/auth';
import {useSignOut} from '../../hooks/auth';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {useUpdateProfilePhoto, useViewer} from '../../hooks/user';
import DefaultAvatar from '../../ui-kit/Avatar/assets/avatar-default.png';
import {Image} from '../../ui-kit/Avatar/styles';
import teamworkImg from './assets/teamwork.png';
import {useRecoilState} from 'recoil';
import {aliasCommunity} from '../../states/community';
import {TCommunity} from '../../types/community';
import {UpdatePhotoError, UpdatePhotoInput} from '../../ui-kit/ProfileIntro/styles';
import {isImage} from '../../helpers/settings';
import Loader from '../../ui-kit/Avatar/assets/loader.png';
import {LocalStorageItems} from '../../constants/common';
import CircleProgressBar from '../../ui-kit/CircleProgressBar';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {Media} from '../../ui-kit/theme';
import {Button} from '../../ui-kit/Button/Button';
import {useHistory} from 'react-router-dom';
import {Text} from '../../ui-kit/Typography/Text';

type WrapperAuthT = {
  tab?: AuthTab;
  step?: number;
  isStart?: boolean;
  joinPage?: boolean;
  fixed?: boolean;
  hideImage?: boolean;
  nextPage?: () => void;
  previousPage?: () => void;
  isSocial?: boolean;
};
export const WrapperAuth: React.FC<WrapperAuthT> = ({
  children,
  tab,
  step,
  joinPage,
  fixed,
  isStart,
  hideImage,
  previousPage,
  nextPage,
  isSocial,
}) => {
  const logInPath = route.social.get({tab: SocialAuth.logIn});
  const {t} = useTranslation();
  const viewer = useViewer();
  const joinInPath = route.joinIn.path;
  const SignUpPath = route.social.get({tab: SocialAuth.signUp});
  const path = tab === AuthTab.signUp ? logInPath : SignUpPath;
  const text = tab === AuthTab.signUp ? t('auth:signUp.existAccount') : t('auth:logIn.newAccount');
  const btn = tab === AuthTab.signUp ? t('auth:buttons.logIn') : t('auth:signUp.title');
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const [isApp, setIsApp] = useState(false);
  const fromJoinPage = !joinPage && aliasedComm?.objectId;
  const {call: updateAvatar, loading: loadingAvatar} = useUpdateProfilePhoto();
  const [photoError, setPhotoError] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const {push} = useHistory();
  const {signOut, loading} = useSignOut({
    onSuccess: () => {
      push(route.home.path);
    },
  });
  const handleUpdatePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!viewer?.id) return;
    const file = e.target.files?.[0];
    setPhotoError('');
    if (file && file?.size / 1024 / 1024 > 10) {
      setPhotoError(t('error:invalidSize'));
    } else if (!isImage(file?.name)) {
      setPhotoError(t('error:invalidFormat'));
    } else {
      updateAvatar?.(file, viewer?.id ?? '')
        .then(() => (e.target.value = ''))
        .catch(() => {
          e.target.value = '';
        });
    }
  };
  const userAvatar = loadingAvatar ? Loader : viewer?.Avatar?.file?.url || DefaultAvatar;
  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setIsApp(!!key);
  };

  const showPanel = () => setVisible(true);

  useEffect(() => {
    getDeviceToken();
  }, []);
  return (
    <WrapperAuthContainer $box={!!viewer?.objectId} $isApp={isApp} $fixed={fixed}>
      <SignupBgImage $isStart={Boolean(isStart) && Boolean(!hideImage)}>
        <img alt={'teamwork image'} src={teamworkImg} />
      </SignupBgImage>
      {fromJoinPage && aliasedComm && (
        <TopLogoWrapper to={'#'} $isApp={isApp} $isLogged={!!viewer?.id}>
          <CommunitySmallLabel community={aliasedComm} />
        </TopLogoWrapper>
      )}
      {!viewer?.objectId && (tab === AuthTab.logIn || tab === AuthTab.signUp) && (
        <>
          <TopLogoWrapper to={route.home.path} $isApp={isApp}>
            {!fromJoinPage && (
              <IconSvg
                type={'hangeh-logo-beta'}
                stroke={'none'}
                fill={'aqua'}
                width={'132'}
                height={'55'}
                viewBox={'10 0 152 64'}
              />
            )}
          </TopLogoWrapper>
          <HaveAccountWrapper $isApp={isApp}>
            {tab === AuthTab.signUp && !aliasedComm && (
              <>
                <HiddenElement mq={Media.up.m}>
                  <LinkAuth $noBorder={true} to={route.home.path}>
                    <IconSvg stroke={'gray'} type={'close-4'} height={'40px'} width={'40px'} />
                  </LinkAuth>
                </HiddenElement>
                <HiddenElement mq={Media.down.m}>
                  <FooterTextAuth>{t('auth:joinIn.haveCode')}</FooterTextAuth>
                  <LinkAuth to={joinInPath}>{t('auth:auth.buttons.join')}</LinkAuth>
                </HiddenElement>
              </>
            )}
            {tab === AuthTab.logIn && !aliasedComm && (
              <>
                <FooterTextAuth>{t('auth:joinIn.haveCode')}</FooterTextAuth>
                <LinkAuth to={joinInPath}>{t('auth:auth.buttons.join')}</LinkAuth>
              </>
            )}
            {aliasedComm && !isSocial && (
              <>
                <FooterTextAuth>{text}</FooterTextAuth>
                <LinkAuth to={path}>{btn}</LinkAuth>
              </>
            )}
          </HaveAccountWrapper>
        </>
      )}
      {!viewer?.objectId && tab === AuthTab.joinIn && (
        <>
          <TopLogoWrapper to={'/'} $isApp={isApp}>
            {!fromJoinPage && (
              <IconSvg
                type={'hangeh-logo-beta'}
                stroke={'none'}
                fill={'aqua'}
                width={'132'}
                height={'55'}
                viewBox={'10 0 152 64'}
              />
            )}
          </TopLogoWrapper>
          <HaveAccountWrapper $isApp={false}>
            <HiddenElement mq={Media.up.m}>
              <LinkAuth $noBorder={true} to={route.home.path}>
                <IconSvg stroke={'gray'} type={'close-4'} height={'40px'} width={'40px'} />
              </LinkAuth>
            </HiddenElement>
            <HiddenElement mq={Media.down.m}>
              <FooterTextAuth>{t('auth:signUp.existAccount')}</FooterTextAuth>
              <LinkAuth to={route.social.get({tab: SocialAuth.logIn})}>{t('auth:auth.buttons.login')}</LinkAuth>
            </HiddenElement>
          </HaveAccountWrapper>
        </>
      )}
      {viewer?.objectId && tab !== AuthTab.logIn && (
        <>
          <WelcomeUserWrapper $isApp={isApp}>
            <CircleProgressBar size={'large'} text={''} value={(step && step * 10) || 0} />
            <WelcomeAvatarWrapper>
              <Image src={userAvatar} $visibility={true} $loading={loadingAvatar} alt={'Avatar'} />
              <UpdatePhotoInput onChange={handleUpdatePhoto} accept={'image/*'} />
            </WelcomeAvatarWrapper>
            <WelcomeUserText>{t('auth:welcomeUser', {userName: viewer?.firstName})}</WelcomeUserText>
          </WelcomeUserWrapper>
          <HiddenElement mq={Media.up.m}>
            <MoreButtonWrapper onClick={showPanel} $isApp={false}>
              <IconSvg type={'more'} />
            </MoreButtonWrapper>
          </HiddenElement>
          <HiddenElement mq={Media.down.m}>
            <ButtonWrapper>
              <Button
                onClick={signOut}
                loading={loading}
                variant={'secondary'}
                ghost={true}
                height={'40px'}
                width={'95px'}>
                {t('common:menu.logOut')}
              </Button>
            </ButtonWrapper>
          </HiddenElement>
          <UpdateAvatarError>{photoError && <UpdatePhotoError>{photoError}</UpdatePhotoError>}</UpdateAvatarError>
          <BottomLogoWrapper>
            <IconSvg
              type={'hangeh-logo-beta'}
              stroke={'none'}
              fill={'aqua'}
              width={'100'}
              height={'40'}
              viewBox={'10 0 160 58'}
            />
          </BottomLogoWrapper>
        </>
      )}
      <BodyWrapper $fullWidth={tab === AuthTab.logIn || tab === AuthTab.joinIn}>
        {children}
        <NavigationButtonWrapper>
          {previousPage && (
            <div className={'back'} onClick={previousPage}>
              <Text size={16} weight={'semi-bold'} color={'duodecimal'}>
                {t('auth:auth.buttons.back')}
              </Text>
            </div>
          )}
          {nextPage && (
            <div className={'next'} onClick={nextPage}>
              <Text size={16} weight={'semi-bold'} color={'duodecimal'}>
                {t('auth:auth.buttons.skip')}
              </Text>
            </div>
          )}
        </NavigationButtonWrapper>
      </BodyWrapper>
      <ShowMorePanel visible={visible} setVisible={setVisible} loading={loading} signOut={signOut} />
    </WrapperAuthContainer>
  );
};

interface PropsMore {
  visible: boolean;
  setVisible: (value: boolean) => void;
  signOut: () => Promise<boolean>;
  loading: boolean;
}

const ShowMorePanel: React.FC<PropsMore> = ({visible, setVisible, loading, signOut}) => {
  const {t} = useTranslation();

  const stopEvent = (e: React.SyntheticEvent) => e.stopPropagation();
  const hide = () => setVisible(false);

  if (!visible) return null;
  return (
    <DarkBackground onClick={hide}>
      <PopupWrapper onClick={stopEvent}>
        <PanelWrapper>
          <Button
            loading={loading}
            onClick={signOut}
            height={'52px'}
            width={'310px'}
            variant={'secondary'}
            rounded={true}
            ghost={true}>
            {t('common:menu.logOut')}
          </Button>
        </PanelWrapper>
      </PopupWrapper>
    </DarkBackground>
  );
};

/*export const ActiveSteps: React.FC<{steps?: number}> = ({steps}) => {
  return (
    <StepProgress stepsCount={steps}>
      <div></div>
    </StepProgress>
  );
};*/

export const CommunitySmallLabel: React.FC<{community: Partial<TCommunity> | null | undefined}> = ({community}) => {
  return (
    <CommunityLabelWrapper>
      <CommunityAvatar src={community?.avatar || DefaultAvatar} />
      <CommunityLabelText>{community?.name}</CommunityLabelText>
    </CommunityLabelWrapper>
  );
};
