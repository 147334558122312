import React, {useState} from 'react';
import {PostBottomPart} from '../styles';
import {PostBottomContentProps} from '../types';
import {CommentBottom} from './BottomPart/CommentBottom';
import {PostBottom} from './BottomPart/PostBottom';

export const PostBottomContent: React.FC<PostBottomContentProps> = ({
  comments,
  isComment,
  Author,
  likes,
  viewerId,
  postActions,
  objectId,
  isLiked,
  refetch,
  refetchComments,
  type,
  Options,
  createdAt,
  communityPeople,
  translateState,
  adminLabels,
  actions,
  menuActions,
  handleDelete,
  typeUser,
  editMode,
  setEditing,
  isMulti,
  isLocal,
  toggleCreating,
}) => {
  const [commentsShow, setCommentsShow] = useState(false);
  const showCommentsShow = () => setCommentsShow(true);
  const showCommentsClose = () => setCommentsShow(false);

  return (
    <PostBottomPart>
      {isComment ? (
        <CommentBottom
          viewerId={viewerId}
          showCommentsClose={showCommentsClose}
          showCommentsShow={showCommentsShow}
          commentsShow={commentsShow}
          refetchComments={refetchComments}
          objectId={objectId}
          isLiked={isLiked}
          postActions={postActions}
          likes={likes}
          translateState={translateState}
          createdAt={createdAt}
          handleDelete={handleDelete}
          isLocal={isLocal}
        />
      ) : (
        <PostBottom
          viewerId={viewerId}
          showCommentsClose={showCommentsClose}
          showCommentsShow={showCommentsShow}
          commentsShow={commentsShow}
          refetch={refetch}
          objectId={objectId}
          isLiked={isLiked}
          postActions={postActions}
          likes={likes}
          Author={Author}
          comments={comments}
          refetchComments={refetchComments}
          type={type}
          Options={Options}
          createdAt={createdAt}
          communityPeople={communityPeople}
          translateState={translateState}
          adminLabels={adminLabels}
          actions={actions}
          menuActions={menuActions}
          handleDelete={handleDelete}
          typeUser={typeUser}
          editMode={editMode}
          setEditing={setEditing}
          isMulti={isMulti}
          isLocal={isLocal}
          toggleCreating={toggleCreating}
        />
      )}
    </PostBottomPart>
  );
};
