import {atom} from 'recoil';
import {PathsList} from '../helpers/mobNavigation';

export const navState = atom({
  key: 'navState',
  default: true,
});

export const mobTab = atom<PathsList | null>({
  key: 'mobTab',
  default: null,
});

export const prevLocation = atom({
  key: 'prevLocation',
  default: '',
});

export type prevPathsStateT = {prev: string; current: string};
export const prevPaths = atom<prevPathsStateT>({
  key: 'prevPaths',
  default: {prev: '', current: ''},
});

export type signUpRedirectStateT = string | null;
export const signUpRedirect = atom<signUpRedirectStateT>({
  key: 'signUpRedirect',
  default: null,
});
