import React, {useEffect, useMemo, useState} from 'react';
import {isEqual} from 'date-fns';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {EventCreated} from '../../components/Events/EventCreated';
import {route} from '../../constants/routes';
import {GQLEventManageAction} from '../../graphql.schema';
import {Community} from '../../queries/types/community';
import {currentCommunity} from '../../states/community';
import {typeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {commLoading} from '../../types/community';
import {NavContainer} from '../Navigation';

import {useLinks} from '../../hooks/common';
import {useGetActualCommunityList} from '../../hooks/community';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useGetEvent, useResidentActionsEvent} from '../../hooks/event';

export const EventCreatedContainer: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {data: event, refetch} = useGetEvent({id});
  const [reviewMode, setReviewMode] = useState(false);
  const [type] = useRecoilState(typeUser);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [addCommLoading, setAddCommLoading] = useState<commLoading>(false);
  const setCommLoading = (t: commLoading) => setAddCommLoading(t);
  const {onSubmit} = useResidentActionsEvent({
    communityId: community?.objectId,
    // onSuccess: () => setIsPublished(true),
  });
  const {getSignValue} = useGetCurrencyValue();
  const [hardUpdated, setHardUpdated] = useState<'created' | 'updated'>();

  // const needConfirmation =
  //   hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.listingsApproval) ?? false;
  const needConfirmation = false;

  const handleSubmit = (communityIdsPublish?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length
        ? [
            onSubmit({
              eventId: event?.objectId,
              communityIds: communityIdsPublish,
              typeBtn: GQLEventManageAction.publish,
            }),
          ]
        : []),
    ]).then(() => {
      refetch();
      setReviewMode(true);
      setCommLoading(false);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [communities, community],
  );

  const [publishedList, setPublishedList] = useState<Array<string>>(allCommunitiesIds);

  const selectedCommunitiesIds = useMemo(() => {
    return [
      ...((event?.Published || []) as Community[]),
      // ...((event?.ApproveReq || []) as Community[]),
    ].map((el) => {
      return el.objectId;
    });
  }, [event]);

  useEffect(() => {
    setPublishedList(allCommunitiesIds);
  }, [communities?.length]);

  useEffect(() => {
    if (type && type !== TypeCommunity.manager && type !== TypeCommunity.admin && type !== TypeCommunity.resident) {
      push(getLink(route.events.get()));
    }
  }, [type]);

  useEffect(() => {
    const isUpdated = !isEqual(new Date(event?.createdAt ?? ''), new Date(event?.updatedAt ?? ''));
    if (!isUpdated) {
      setHardUpdated('created');
    }
  }, [event?.objectId]);

  const oneCommunitySubmit = () => {
    setCommLoading?.('one');
    handleSubmit(publishedList.filter((communityId) => !selectedCommunitiesIds?.includes(communityId)));
  };

  useEffect(() => {
    if (communities.length === 1 && event?.objectId) {
      setReviewMode(true);
      oneCommunitySubmit();
    } else setReviewMode(false);
  }, [event?.objectId, communities.length]);

  return (
    <NavContainer>
      <EventCreated
        needConfirmation={needConfirmation}
        event={event}
        createdType={hardUpdated}
        community={community}
        communities={communities}
        onSubmit={handleSubmit}
        reviewMode={reviewMode}
        publishedList={publishedList}
        setPublishedList={setPublishedList}
        allCommunitiesIds={allCommunitiesIds}
        initialListCommunity={selectedCommunitiesIds}
        getSignValue={getSignValue}
        addCommLoading={addCommLoading}
        setCommLoading={setCommLoading}
      />
    </NavContainer>
  );
};
