import React from 'react';
import {DarkBackgroundWrapper} from '../../../../components/common/DarkBackground';
import {AddCreditCard} from '../../../../components/Account/Create/Forms/AddCreditCard';
import {useAddCreditCard} from '../../../../hooks/payments';
import {getOptionsLocation} from '../../../../helpers/locations';
import {useCountries} from '../../../../hooks/locations';
import {TCreditCard} from '../../../../queries/types/payments';

interface AddCreditCardPopupProps {
  handleClose: () => void;
  initialState?: Partial<TCreditCard>;
}

export const AddCreditCardPopup: React.FC<AddCreditCardPopupProps> = ({handleClose, initialState}) => {
  const countries = useCountries();
  const options = getOptionsLocation(countries);
  const cardData = useAddCreditCard({
    initialState: initialState ? initialState : {},
    onSuccess: handleClose,
  });

  const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

  return (
    <DarkBackgroundWrapper onClick={handleClose}>
      <div onClick={stopPropagation}>
        <AddCreditCard cardData={cardData} options={options} />
      </div>
    </DarkBackgroundWrapper>
  );
};
