import React from 'react';
import {StarsWrapper, TextContainerEntry} from './styles';
import {RatingStars} from '../Rating/RatingStars';

// const getStars = (rate: number) => {
//   const maxStars = 5;
//   const stars: JSX.Element[] = [];
//
//   if (rate > 0) {
//     for (let i = 0; i < rate; i++) {
//       stars.push(<IconSvg type={'star'} stroke={'orange'} fill={'orange'} />);
//     }
//
//     for (let i = 0; i < maxStars - rate; i++) {
//       stars.push(<IconSvg type={'star'} stroke={'orange'} />);
//     }
//   } else {
//     for (let i = 0; i < maxStars; i++) {
//       stars.push(<IconSvg type={'star'} stroke={'lightGray'} />);
//     }
//   }
//
//   return stars;
// };

interface StartSectionProps {
  text?: string;
  rating: number;
  starWidth?: number;
  onChange?: (t: number) => void;
  notEditable?: boolean;
  small?: boolean;
}

export const StarsSection: React.FC<StartSectionProps> = ({text, rating, starWidth, onChange, notEditable, small}) => {
  return (
    <>
      <TextContainerEntry bold={true}>{text}</TextContainerEntry>
      <StarsWrapper width={starWidth}>
        <RatingStars value={rating} onChange={onChange} notEditable={notEditable} small={small} />
      </StarsWrapper>
    </>
  );
};
