import React, {useState} from 'react';
import {parseJSON} from 'date-fns';
import {useTranslation} from 'react-i18next';

import {PostTypeT} from '../../../../../types/post';
import {Comment, Like} from '../../../../../ui-kit/Feed/labels/labels';
import {BottomContentWrapper, PostActions, PostActionsLeft, PostActionsRight, PostDate} from '../../styles';
import {TranslatePanel} from '../../TranslatePanel';
import {PostBottomContentProps} from '../../types';
import {PostComments} from '../PostComments';
import {PostPoll} from '../PostPoll';
import {VoteRemove} from '../PostPoll/VoteRemove';

type TProps = {
  showCommentsShow: () => void;
  showCommentsClose: () => void;
  commentsShow?: boolean;
};

export const PostBottom: React.FC<PostBottomContentProps & TProps> = ({
  comments,
  isComment,
  Author,
  likes,
  viewerId,
  postActions,
  objectId,
  isLiked,
  refetch,
  showCommentsShow,
  commentsShow,
  showCommentsClose,
  refetchComments,
  type,
  Options,
  createdAt,
  communityPeople,
  translateState,
  adminLabels,
  actions,
  menuActions,
  handleDelete,
  typeUser,
  editMode,
  setEditing,
  isMulti,
  isLocal,
  toggleCreating,
}) => {
  const {t} = useTranslation();
  const [acts, setActs] = useState({
    liked: isLiked || false,
    likes: likes || 0,
    pollId: Options?.filter((op) => op.isVoted).map((el) => el.objectId),
  });
  const handleLike = () => {
    setActs((prev) => ({...prev, liked: !prev.liked, likes: prev.liked ? prev.likes - 1 : prev.likes + 1}));
    postActions?.likePost?.(objectId, refetch);
  };
  const changeVote = (id?: string) =>
    setActs((prev) => ({
      ...prev,
      pollId: isMulti ? [...(prev.pollId || []), id] : [id],
    }));

  const changeRmVote = (id?: string) => {
    setActs((prev) => ({
      ...prev,
      pollId: prev?.pollId?.filter((el) => el !== id),
    }));
  };

  return (
    <BottomContentWrapper>
      {type === PostTypeT.poll && (
        <PostPoll
          type={type}
          refetch={refetch}
          Options={Options}
          objectId={objectId}
          handleVote={changeVote}
          votedIds={acts.pollId}
          isMulti={isMulti}
          disabled={isLocal}
        />
      )}
      {type !== PostTypeT.items && type !== PostTypeT.video && <TranslatePanel {...translateState} />}
      <PostActions>
        <PostActionsLeft>
          <Like
            active={acts.liked}
            onClick={handleLike}
            label={t('common:like', {count: acts.likes || 0})}
            disabled={isLocal}
          />
          <Comment
            onClick={commentsShow ? showCommentsClose : showCommentsShow}
            label={t('common:comment', {count: comments || 0})}
            disabled={isLocal}
          />
        </PostActionsLeft>
        <PostActionsRight>
          <VoteRemove
            type={type}
            objectId={objectId}
            optionId={acts.pollId?.[0]}
            refetch={refetch}
            handleVote={changeRmVote}
          />
          <PostDate>{createdAt && t('common:datePPp', {date: parseJSON(createdAt)})}</PostDate>
          <PostDate showMob={true}>{createdAt && t('common:datePP', {date: new Date(createdAt)})}</PostDate>
        </PostActionsRight>
      </PostActions>
      {commentsShow && (
        <PostComments
          comments={comments}
          viewerId={viewerId}
          objectId={objectId}
          isComment={isComment}
          Author={Author}
          commentsClose={showCommentsClose}
          refetch={refetch}
          refetchComments={refetchComments}
          postActions={postActions}
          communityPeople={communityPeople}
          adminLabels={adminLabels}
          actions={actions}
          menuActions={menuActions}
          handleDelete={handleDelete}
          typeUser={typeUser}
          editMode={editMode}
          setEditing={setEditing}
          toggleCreating={toggleCreating}
        />
      )}
    </BottomContentWrapper>
  );
};
