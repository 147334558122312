import React, {useMemo} from 'react';
import {User} from '../../queries/types/user';
import {NavContainer} from '../Navigation';
import {TypeCommunity} from '../../types/auth';
import {ManagePeople} from '../../components/People/ManagePeople';
import {useGetCommunityPeople, useGetNewMembers, useManageActions} from '../../hooks/people';
import {CommunityStateType} from '../../states/community';
import {ItemsSearchParams, SetItemsSearchParams} from './types';
import {PeopleSearchType} from '../../types/people';
import {entities, useReport} from '../../hooks/report';
import {EmptyTag} from '../MobileTabs';

type Props = {
  user: User | null;
  searchParams: ItemsSearchParams;
  setSearchParams: SetItemsSearchParams;
  setPeopleType: React.Dispatch<React.SetStateAction<PeopleSearchType>>;
  loading?: boolean;
  peopleType: PeopleSearchType;
  community?: CommunityStateType;
  adminLabels: Record<string, string>;
  hideNav?: boolean;
  canInvite?: boolean;
};

export const ManagePeoplePage: React.FC<Props> = ({
  user,
  searchParams,
  setSearchParams,
  peopleType,
  setPeopleType,
  community,
  adminLabels,
  hideNav,
  canInvite,
}) => {
  const {
    data: members,
    refetch,
    loading,
    fetchMore,
    hasNextPage,
  } = useGetCommunityPeople({
    id: community?.id,
    withManager: true,
    where: searchParams,
    peopleType,
  });
  const {data: newMembersList, refetch: refetchNew} = useGetNewMembers({
    id: community?.id,
  });
  const newMembers = useMemo(() => {
    if (!members?.countMembers) return newMembersList;
    const filtered = newMembersList?.newMembers?.filter((m) => {
      return !members?.Residents?.some((r) => r?.objectId === m?.objectId);
    });
    return {newMembers: filtered};
  }, [members?.countMembers, newMembersList?.newMembers?.length]);

  const {
    personDeny: pDeny,
    personApprove: pApprove,
    personView,
    personMakeAdmin: pMakeAdmin,
    personRemove,
    personRemoveAdmin: pRemoveAdmin,
  } = useManageActions();

  const actions = useReport(entities.user);

  const refetchMembers = () => {
    refetch();
    refetchNew();
  };

  //const membersItemsCount = useMembersListingsCount(members?.AllMembers, community?.objectId, community?.__typename);

  const personDeny = (id: string) => pDeny(id, refetchMembers);
  const personApprove = (id: string) => pApprove(id, refetchMembers);
  const personMakeAdmin = (id: string, commId: string) => pMakeAdmin(id, commId, refetch);
  const personRemoveFromCommunity = (id: string) => personRemove(id, refetchMembers);
  const personRemoveAdmin = (id: string, commId: string) => pRemoveAdmin(id, commId, refetch);

  const loadMore = () => {
    fetchMore({
      variables: {
        skip: members?.Residents?.length,
      },
    });
  };
  const actionsList = {
    personDeny,
    personApprove,
    personView,
    personMakeAdmin,
    personRemove: personRemoveFromCommunity,
    personRemoveAdmin,
  };
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  return (
    <Wrapper>
      <ManagePeople
        typeUser={TypeCommunity.manager}
        user={user}
        members={members}
        newMembers={newMembers}
        actionsList={actionsList}
        setSearchParams={setSearchParams}
        loading={loading}
        peopleType={peopleType}
        setPeopleType={setPeopleType}
        //membersItemsCount={membersItemsCount}
        community={community}
        reportActions={actions}
        refetch={refetch}
        adminLabels={adminLabels}
        canInvite={canInvite}
        loadMore={loadMore}
        hasNextPage={hasNextPage}
      />
    </Wrapper>
  );
};
