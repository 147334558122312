import React from 'react';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';
import {route} from '../../constants/routes';
import {useHistory, useParams} from 'react-router-dom';
import {useLinks} from '../../hooks/common';
import {useRecoilState} from 'recoil';
import {aliasCommunity, skipParams} from '../../states/community';
import {useAutoAddToCommunity} from '../../hooks/auth';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {CommunityRequested} from '../../components/Auth/CommunityRequest';

export const CommunityRequestContainer: React.FC = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const {user} = useParams<{user: TypeCommunity}>();
  const {addByKey} = useAutoAddToCommunity();
  const [{autoAddKey}] = useRecoilState(skipParams);

  const successPath =
    user === TypeCommunity.manager
      ? getLink(route.profileCreateManager.path)
      : getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests}));

  const onSuccess = () => {
    if (aliasedComm?.objectId === autoAddKey) addByKey(autoAddKey);
    push(successPath);
  };

  return (
    <>
      <WrapperAuth>
        <CommunityRequested onClickSecond={onSuccess} />
      </WrapperAuth>
    </>
  );
};
