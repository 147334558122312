import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Sticky from 'react-stickynode';
import {useRecoilState} from 'recoil';

import {AmenityRequestCard} from './AmenityRequestCard';
import {DatepickerLine} from './AmenityRequestCard/styles';
import {
  AsideRequests,
  InnerEditAside,
  InnerRequestCard,
  WrapperEditFormAside,
  DesktopView,
  MobileView,
  AmenityInfoTitle,
  AmenityInfoDescr,
  AmenityInfoRow,
  FullBlock,
} from './styles';
import {route} from '../../constants/routes';
import {getAmenityLabels, getTime12to24} from '../../helpers/amenity';
import {correctTranslate, DaysList, shortToFullDay} from '../../helpers/common';
import {UserLabelType} from '../../helpers/people';
import {getUserName} from '../../helpers/user';
import {optionsI18n} from '../../libs/i18nextUtils';
import {RewardType} from '../../queries/types/item';
import {typeUser} from '../../states/typeUser';
import {bookingDay, TAmenity} from '../../types/amenity';
import {CreateMsgFields, MessageData} from '../../types/messages';
import {MessageRequestCard} from '../../ui-kit/RequestCard/MessageRequestCard';
import {CardContentWrapper, CardWrapper} from '../../ui-kit/RequestCard/styles';
import {MessageCardUserType, SendMessageStatus} from '../../ui-kit/RequestCard/types';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {LottieLoader} from '../Loader/LottieLoader';

import {amenityAvailableDataT, TResCreateAmenityOrder} from '../../hooks/amenity';
import {useLinks} from '../../hooks/common';

type TProps = {
  className?: string;
  width?: string;
  user?: MessageCardUserType;
  descr: string;
  amenity?: Partial<TAmenity>;
  dataCreateMsg?: {
    values: Omit<MessageData, 'ShowTo'> & {
      ShowTo?: string;
    };
    onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
    onSubmit: () => void;
    loading: boolean;
    success: boolean;
  };
  dataOrder?: TResCreateAmenityOrder;
  isDesktop?: boolean;
  bottomBoundary?: number;
  changeCardStatusRef?: React.MutableRefObject<((status: SendMessageStatus) => void) | undefined>;
  sendMessageRef?: React.RefObject<HTMLDivElement>;
  requestCardRef?: React.RefObject<HTMLDivElement>;
  showWarnPopup?: () => void;
  accessRequest?: boolean;
  busyDates?: Date[];
  canSandMessage?: boolean;
  isAvailable?: amenityAvailableDataT;
  userLabel?: UserLabelType;
};

const getTextData = (item?: Partial<TAmenity>, typeText?: 'buttons' | 'descr', success?: string | null) => {
  if (item?.rewardType === RewardType.free && typeText === 'descr' && success) {
    return `amenities:requests.${typeText}.request.success`;
  }
  if (item?.rewardType === RewardType.free) {
    return `amenities:requests.${typeText}.request.free`;
  }
  return '';
};

export const RequestAside: React.FC<TProps> = ({
  width,
  user,
  amenity,
  dataCreateMsg,
  dataOrder,
  className,
  bottomBoundary,
  isDesktop,
  changeCardStatusRef,
  sendMessageRef,
  requestCardRef,
  isAvailable,
  accessRequest,
  showWarnPopup,
  canSandMessage,
  userLabel,
}) => {
  const [cardStatus, setCardStatus] = useState<SendMessageStatus>(SendMessageStatus.Write);
  const [userType] = useRecoilState(typeUser);
  const {t} = useTranslation();
  const {getLink} = useLinks();

  useEffect(() => {
    if (changeCardStatusRef) {
      changeCardStatusRef.current = setCardStatus;
    }
  }, []);

  useEffect(() => {
    if (dataCreateMsg?.success) {
      setCardStatus(SendMessageStatus.Done);
    }
  }, [dataCreateMsg?.success]);

  const titleBtn = t(getTextData(amenity, 'buttons'));
  const titleDescr = t(getTextData(amenity, 'descr', dataOrder?.success));
  // const tagRewardType = getAmountLabelAmenity(amenity);

  const errorMsg = correctTranslate({
    text: t(dataOrder?.error?.message || ''),
    defaultText: dataOrder?.error?.message,
  });
  const successMsg = dataOrder?.success ? t(dataOrder.success) : dataOrder?.success;

  const handleWriteMsg = () => {
    setCardStatus(SendMessageStatus.Send);
  };

  const handleSendMsg = () => {
    if (!dataCreateMsg?.onSubmit) return;
    dataCreateMsg.onSubmit();
  };

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!dataCreateMsg?.onChange) return;
    dataCreateMsg?.onChange({name: CreateMsgFields.text, value: target.value});
  };

  const handleCreateOrder = () => {
    if (!accessRequest) return showWarnPopup?.();
    if (!dataOrder?.onSubmit) return;
    dataOrder.onSubmit();
  };
  const labels = getAmenityLabels(t, amenity);
  const orderId = dataOrder?.orderInfo ? `?orderId=${dataOrder?.orderInfo}` : '';
  const messageLink = `${getLink(route.messages.get({contactId: amenity?.Lister?.objectId}))}${orderId}`;
  const notAvailableText = isAvailable?.text && t(`amenities:requests.notAvailable.${isAvailable.text}`);
  const available = isAvailable?.check && (isAvailable?.busyDates?.length || 0) < (amenity?.maxBookingsPerDay || 0);
  const userName = getUserName(userType, user?.firstName, user?.lastName || user?.firstName);

  return (
    <InnerEditAside width={width} className={className}>
      <AsideRequests>
        <WrapperEditFormAside>
          {/*<div ref={sendMessageRef}>*/}
          {/*</div>*/}
          <OpenTillCard amenity={amenity} />
          <AmenityInfoRow />
          <MobileView>
            <FullBlock ref={sendMessageRef}>
              <MessageRequestCard
                hideReviews={true}
                type={cardStatus}
                user={user}
                userName={userName}
                descr={t('amenities:messages.sendDescr')}
                buttonTitle={t('requests:buttons.send')}
                linkTitle={t('requests:buttons.view')}
                itemTitle={amenity?.name}
                handleWriteMsg={handleWriteMsg}
                handleSendMsg={handleSendMsg}
                pathLink={getLink(route.messages.get({contactId: amenity?.Lister?.objectId}))}
                messageSent={t('requests:successSend')}
                message={{
                  placeholder: t('requests:placeholder'),
                  value: dataCreateMsg?.values?.text,
                  onChange: handleChange,
                  loading: dataCreateMsg?.loading,
                }}
                canSend={canSandMessage}
                profileLink={amenity?.Lister?.objectId && getLink(route.profile.get({id: amenity?.Lister?.objectId}))}
                userLabel={userLabel}
                hideVerified={true}
              />
            </FullBlock>
          </MobileView>
          <InnerRequestCard id={'requestCard'} ref={requestCardRef}>
            <Sticky enabled={!!isDesktop} top={60} bottomBoundary={bottomBoundary}>
              {amenity?.isBookable && (
                <AmenityRequestCard
                  amenity={amenity}
                  buttonLabel={{label: titleBtn, onClick: handleCreateOrder, loading: dataOrder?.loading}}
                  descr={titleDescr}
                  tags={labels}
                  message={{error: errorMsg, success: successMsg}}
                  successOrder={dataOrder?.success}
                  requestSent={t('amenities:messages.viewBooking')}
                  pathLink={messageLink}
                  linkTitle={t('amenities:messages.viewBooking')}
                  isAvailable={available}
                  notAvailableText={notAvailableText}
                  orderChangeData={dataOrder?.changeData}
                  busyDates={isAvailable?.busyDates}
                />
              )}
              <DatepickerLine />
              <DesktopView>
                <FullBlock>
                  <MessageRequestCard
                    hideReviews={true}
                    type={cardStatus}
                    user={user}
                    descr={t('amenities:messages.sendDescr')}
                    buttonTitle={t('requests:buttons.send')}
                    linkTitle={t('requests:buttons.view')}
                    itemTitle={amenity?.name}
                    handleWriteMsg={handleWriteMsg}
                    handleSendMsg={handleSendMsg}
                    userName={userName}
                    pathLink={getLink(route.messages.get({contactId: amenity?.Lister?.objectId}))}
                    messageSent={t('requests:successSend')}
                    message={{
                      placeholder: t('requests:placeholder'),
                      value: dataCreateMsg?.values?.text,
                      onChange: handleChange,
                      loading: dataCreateMsg?.loading,
                    }}
                    canSend={canSandMessage}
                    profileLink={
                      amenity?.Lister?.objectId && getLink(route.profile.get({id: amenity?.Lister?.objectId}))
                    }
                    userLabel={userLabel}
                    hideVerified={true}
                  />
                </FullBlock>
              </DesktopView>
            </Sticky>
          </InnerRequestCard>
          <LottieLoader $isVisible={dataOrder?.loading} allScreen={true} />
        </WrapperEditFormAside>
      </AsideRequests>
    </InnerEditAside>
  );
};

const OpenTillCard: React.FC<{
  amenity?: Partial<TAmenity>;
}> = ({amenity}) => {
  const {t} = useTranslation();
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;

  const toTime = (time?: string) => {
    if (!time) return '';
    const {hours, minutes} = getTime12to24(time);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return format(date, 'p', getLanguage());
  };
  const hoursLabel = t('amenities:hours');
  const closedLabel = t('amenities:closed');
  const timing = amenity?.bookingTiming;
  if (!timing) return null;

  const tList = Object.keys(timing).map((key) => ({
    day: key,
    available: timing?.[key as bookingDay]?.available,
    from: toTime(timing?.[key as bookingDay]?.from),
    till: toTime(timing?.[key as bookingDay]?.till),
  }));

  return (
    <CardWrapper padding={13}>
      <CardContentWrapper>
        <AmenityInfoRow>
          <AmenityInfoDescr>{hoursLabel}</AmenityInfoDescr>
        </AmenityInfoRow>
        {tList.map((el, i) => (
          <AmenityInfoRow key={i}>
            <AmenityInfoTitle>{t(`common:days.${shortToFullDay[el.day as DaysList]}`)}</AmenityInfoTitle>

            {el?.available ? (
              <AmenityInfoDescr>
                {el?.from}
                {' - '}
                {el?.till}
              </AmenityInfoDescr>
            ) : (
              <AmenityInfoDescr>{closedLabel}</AmenityInfoDescr>
            )}
          </AmenityInfoRow>
        ))}
      </CardContentWrapper>
    </CardWrapper>
  );
};
