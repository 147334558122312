import React, {useState, useEffect} from 'react';
import throttle from 'lodash.throttle';

import {Avatar as UiAvatar} from '../Avatar/Avatar';
import {IconSvg} from '../Icon/Svg';
import {Media} from '../theme';
import {Br} from '../Typography/Br';
import {HeaderCard} from './HeaderCard';
import defaultAvatarEmpty from './images/defaultAvatar.png';
import defaultAvatarCommunity from './images/avatar-default-community.png';
import {Education, Languages, Members, Work} from './labels';
import {
  AvatarWithButton,
  AvatarWrapper,
  ButtonsWrapper,
  CustomAvatar,
  Description,
  FooterLabelsWrapper,
  Hidden,
  InterestsWrapper,
  InterestWrapper,
  ProfileIntroButton,
  ProfileWrapper,
  RecommendationsWrapper,
  RecommendationWrapper,
  UpdatePhotoError,
  UpdatePhotoIcon,
  UpdatePhotoInput,
  UpdatePhotoWrapper,
  WrapperButton,
  WrapperContent,
  ProfileHeaderMobile,
  ProfileHeaderMobileContainer,
  IconButtonWrapper,
  GoBackWrapper,
  ProfileSectionTitle,
  LabelTitle,
  ButtonText,
} from './styles';
import {AvatarType, ProfileIntroProps} from './types';
import {iconOptions} from '../Icon/Svg/types';

const Avatar: React.FC<{Avtr?: AvatarType; isOnline?: boolean; loadingAvatar?: boolean; onClick?: () => void}> = ({
  Avtr,
  isOnline,
  loadingAvatar,
  onClick,
}) => {
  if (typeof Avtr !== 'string' && Avtr !== undefined) return <>{Avtr}</>;

  return (
    <CustomAvatar onClick={onClick}>
      <UiAvatar src={Avtr || defaultAvatarEmpty} size="xl" $isOnline={isOnline} loading={loadingAvatar} />
    </CustomAvatar>
  );
};

const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};

export const ProfileIntro: React.FC<ProfileIntroProps> = ({
  title,
  description,
  avatar,
  originalAvatar,
  buttonText,
  isOwner,
  isWaved,
  goBack,
  buttonOnClick,
  countReviews,
  rating,
  isVerified,
  isVerifiedClick,
  countMembers,
  languages,
  work,
  status,
  joined,
  education,
  footerLblsGapX,
  footerLblsGapY,
  className,
  labels,
  onUpdatePhoto,
  canUpdatePhoto,
  updatePhotoError,
  isOnline,
  secondButtonText,
  secondButtonOnClick,
  interestsTitle,
  recommendsTitle,
  interests,
  recommends,
  loadingAvatar,
  RichText,
  onScrollToReviews,
  Gallery,
  Item,
  setIsModal,
  isMobile,
  interestsLink,
  interestsValues,
  goToGroup,
  autogenerated,
}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      window?.pageYOffset > 30 ? setScrolled(true) : setScrolled(false);
    }, 500);
    window?.addEventListener('scroll', handleScroll);
    return () => window?.removeEventListener('scroll', handleScroll);
  }, []);

  const interestsLabels = interests?.map((el) => interestsValues?.[el?.value]) || [];

  const onOpen = () => {
    if (!isMobile) return;
    if (typeof document !== 'undefined') {
      setIsModal?.(true);
      const interval = setInterval(() => {
        const element = document?.querySelector('.pswp--open');
        if (!element) {
          clearInterval(interval);
          setIsModal?.(false);
        }
      }, 500);
    }
  };

  const defaultImage = autogenerated ? defaultAvatarCommunity : defaultAvatarEmpty;
  const handleRedirect = (link?: string) => () => goToGroup?.(link || '');

  return (
    <ProfileWrapper className={className}>
      <Hidden mq={Media.up.s}>
        <ProfileHeaderMobile $scrolled={scrolled}>
          <ProfileHeaderMobileContainer>
            <GoBackWrapper onClick={goBack}>
              <IconSvg type={'arrow-left'} />
            </GoBackWrapper>
            <ButtonsWrapper>
              {secondButtonText && (
                <WrapperButton>
                  <ProfileIntroButton onClick={secondButtonOnClick} disabled={isWaved}>
                    <IconButtonWrapper>
                      <ButtonText>{secondButtonText}</ButtonText>
                      <IconSvg
                        type={'wave'}
                        fill={isWaved ? 'gray-2' : 'none'}
                        width={'14'}
                        height={'14'}
                        viewBox={'0 0 20 20'}
                      />
                    </IconButtonWrapper>
                  </ProfileIntroButton>
                </WrapperButton>
              )}
              {buttonText && (
                <WrapperButton>
                  <ProfileIntroButton onClick={buttonOnClick}>
                    <IconButtonWrapper>
                      <ButtonText>{buttonText}</ButtonText>
                      <IconSvg
                        type={isOwner ? 'edit' : 'combined-shape'}
                        width={'14'}
                        height={'14'}
                        viewBox={'0 0 14 14'}
                      />
                    </IconButtonWrapper>
                  </ProfileIntroButton>
                </WrapperButton>
              )}
            </ButtonsWrapper>
          </ProfileHeaderMobileContainer>
        </ProfileHeaderMobile>
      </Hidden>
      <AvatarWrapper>
        <AvatarWithButton>
          <UpdatePhotoWrapper>
            {originalAvatar ? (
              <>
                {Gallery && Item && (
                  <Gallery
                    shareButton={false}
                    zoomButton={true}
                    fullscreenButton={false}
                    options={options}
                    onOpen={onOpen}>
                    <Item original={`${avatar || defaultImage}`} thumbnail={`${avatar}`} width="600" height="600">
                      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
                      /*@ts-ignore*/}
                      {({open}) => (
                        <Avatar
                          onClick={open}
                          Avtr={avatar || defaultImage}
                          isOnline={isOnline}
                          loadingAvatar={loadingAvatar}
                        />
                      )}
                    </Item>
                  </Gallery>
                )}
              </>
            ) : (
              <Avatar Avtr={avatar || defaultImage} isOnline={isOnline} loadingAvatar={loadingAvatar} />
            )}
            {canUpdatePhoto && (
              <UpdatePhotoIcon>
                <UpdatePhotoInput onChange={onUpdatePhoto} accept={'.jpg, .png, .jpeg, .webp, .svg, .gif, .bmp'} />
                <IconSvg type={'camera'} width={'16'} height={'16'} viewBox={'0 0 46 46'} />
              </UpdatePhotoIcon>
            )}
          </UpdatePhotoWrapper>
          {updatePhotoError && <UpdatePhotoError>{updatePhotoError}</UpdatePhotoError>}
        </AvatarWithButton>
      </AvatarWrapper>
      <WrapperContent>
        <HeaderCard
          labels={labels}
          title={title}
          countReviews={countReviews}
          rating={rating}
          isVerified={isVerified}
          isVerifiedClick={isVerifiedClick}
          status={status}
          joined={joined}
          buttonOnClick={buttonOnClick}
          buttonText={buttonText}
          secondButtonText={secondButtonText}
          secondButtonOnClick={secondButtonOnClick}
          interestsTitle={interestsTitle}
          recommendsTitle={recommendsTitle}
          interests={interests}
          recommends={recommends}
          isWaved={isWaved}
          onScrollToReviews={onScrollToReviews}
          isOwner={isOwner}
        />
        <Description>
          {RichText && (
            <RichText
              disableShowMore={true}
              isForProfile={false}
              noLinkify={true}
              isForDescription={true}
              html={description}
            />
          )}
        </Description>
        <FooterLabelsWrapper footerLblsGapX={footerLblsGapX} footerLblsGapY={footerLblsGapY}>
          {countMembers !== undefined && <Members count={countMembers} data={labels?.members} />}
          {languages && <Languages languages={languages} label={labels?.languages} />}
          {education && <Education education={education} label={labels?.study} />}
          {work && <Work work={work} label={labels?.work} />}
        </FooterLabelsWrapper>
        {Boolean(interests?.length) && (
          <>
            <Br indent={20} />
            <ProfileSectionTitle>{interestsTitle}</ProfileSectionTitle>
            <InterestsWrapper>
              {interests?.map((el, index) => (
                <InterestWrapper
                  key={el?.value}
                  onClick={handleRedirect(interestsLink?.find((item) => item?.interest === el?.value)?.link)}>
                  <IconSvg type={iconOptions[iconOptions.findIndex((key) => key.startsWith(el?.value))]} />
                  <LabelTitle>{interestsLabels?.[index]}</LabelTitle>
                </InterestWrapper>
              ))}
            </InterestsWrapper>
          </>
        )}
        {Boolean(Object.values(recommends || {})?.length) && (
          <>
            <Br indent={10} />
            <ProfileSectionTitle>{recommendsTitle}</ProfileSectionTitle>

            <RecommendationsWrapper>
              {recommends?.eatery && (
                <RecommendationWrapper>
                  <IconSvg type="coffee" />
                  <LabelTitle>{recommends?.eatery}</LabelTitle>
                </RecommendationWrapper>
              )}
              {recommends?.movies && (
                <RecommendationWrapper>
                  <IconSvg type="tv" />
                  <LabelTitle>{recommends.movies}</LabelTitle>
                </RecommendationWrapper>
              )}
              {recommends?.places && (
                <RecommendationWrapper>
                  <IconSvg type="car-alt" />
                  <LabelTitle>{recommends.places}</LabelTitle>
                </RecommendationWrapper>
              )}
            </RecommendationsWrapper>
          </>
        )}
      </WrapperContent>
    </ProfileWrapper>
  );
};
