import {TDocument, TDocumentFormat} from '../../types/document';

export enum FormMode {
  Add = 'add',
  Edit = 'edit',
}

export type TModalData = {
  mode: FormMode;
  selectedDoc?: TDocument;
};

export type TFormDataType = {
  name?: string;
  docFile?: File | null;
  cover?: File | null;
};

export interface IActionsOnDocumentsData {
  addDocument: (cb?: () => void) => Promise<void>;
  clearValues: () => void;
  setValue: (params: {key: keyof TFormDataType; value: any}) => void;
  setFile: (params: {key: keyof TFormDataType; value?: File | null}) => void;
  values: TFormDataType;
  error?: string;
  loading: boolean;
  publish: (id: string) => Promise<void>;
  unPublish: (id: string) => Promise<void>;
  deleteDoc: (id: string) => Promise<void>;
  edit?: () => void;
  updateDocument: (doc: TDocument, cb?: () => void) => Promise<void>;
}

export type TDocumentMime =
  | 'application/pdf'
  | 'text/csv'
  | 'application/msword'
  | 'image/png'
  | 'image/jpeg'
  | 'application/vnd.ms-excel';

export const formats = ['.pdf', '.png', '.jpeg', '.doc', '.csv'];

export const acceptedMimes: Array<TDocumentMime> = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.ms-excel',
  'text/csv',
];

export const DocumentFormatsByMime: Record<TDocumentMime, TDocumentFormat> = {
  'application/pdf': 'PDF',
  'text/csv': 'CSV',
  'application/msword': 'DOC',
  'image/png': 'PNG',
  'image/jpeg': 'JPEG',
  'application/vnd.ms-excel': 'XLS',
};
