import styled, {css} from 'styled-components';
import {BasePortal} from '../../../ui-kit/Popup';
import {fontPrimaryReg} from '../styles';
import {Media, theme} from '../../../ui-kit/theme';
import {isFromApp} from '../../../ui-kit/Navigation/mobile/styles';
import {Text} from '../../../ui-kit/Typography/Text';

export const TipContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const TipPortal = styled(BasePortal)`
  width: 100%;
  height: 100%;
  z-index: 1001;
  left: 0;
  top: 0;
`;

export const HideMob = styled.div`
  ${Media.down.l} {
    display: none;
  }
`;

export const ShowMob = styled.div`
  display: none;
  ${Media.down.l} {
    display: block;
  }
`;

const TipWrapperStyles = css`
  background-color: ${({theme}) => theme.palette.grayscale._0};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: absolute;
  padding: 28px 23px 32px 23px;
  box-sizing: border-box;
  max-width: 330px;
  min-width: 310px;
  animation-name: appear;
  animation-duration: 1.2s;
  @keyframes appear {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ToolTipTitle = styled.div`
  font-size: 26px;
  line-height: 125%;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
  margin-top: 21px;
`;
export const ToolTipText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${fontPrimaryReg};
  margin-top: 10px;
`;

export const ToolTipTitlePc = styled(ToolTipTitle)`
  ${Media.down.l} {
    display: none;
  }
`;
export const ToolTipTextPc = styled(ToolTipText)`
  ${Media.down.l} {
    display: none;
  }
`;

export const ToolTipTitleMobile = styled(ToolTipTitle)`
  ${Media.up.l} {
    display: none;
  }
`;
export const ToolTipTextMobile = styled(ToolTipText)`
  ${Media.up.l} {
    display: none;
  }
`;

export const ToolTipButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ToolTipButtonNext = styled.button`
  background: none;
  border: none;
  outline: none;
  ${fontPrimaryReg};
  font-size: 16px;
  color: ${({theme}) => theme.palette.common.aqua};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  width: 237px;
  height: 247px;
  margin: 0 auto;
  display: block;
`;
export const Image = styled.img`
  max-width: 237px;
  height: auto;
`;

export const TipWrapperStart = styled.div`
  ${TipWrapperStyles};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TipWrapperHome = styled.div`
  ${TipWrapperStyles};
  left: 23px;
  top: 76px;
  ${Media.down.l} {
    top: unset;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperPeople = styled.div`
  ${TipWrapperStyles};
  left: 23px;
  top: 292px;
  ${Media.down.l} {
    top: unset;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperExplore = styled.div`
  ${TipWrapperStyles};
  left: 23px;
  top: 252px;
  ${Media.down.l} {
    top: unset;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperMessages = styled.div`
  ${TipWrapperStyles};
  left: 23px;
  top: 360px;
  ${Media.down.l} {
    top: unset;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperHelpDesk = styled.div`
  ${TipWrapperStyles};
  left: 23px;
  bottom: 200px;
  ${Media.down.l} {
    top: unset;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperProfile = styled.div`
  ${TipWrapperStyles};
  left: 46px;
  top: 73px;
  ${Media.down.l} {
    top: 100px;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperSwitch = styled.div`
  ${TipWrapperStyles};
  left: 255px;
  top: 73px;
  ${Media.down.l} {
    top: 100px;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TipWrapperEnd = styled.div`
  ${TipWrapperStyles};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const highlightStyles = css`
  border-radius: 7px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  padding: 10px;
  position: absolute;
  box-sizing: border-box;
  & > li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    & > a {
      cursor: auto;
    }
  }
`;

export const HighlightHomeWrapper = styled.div`
  ${highlightStyles};
  left: 23px;
  top: 10px;
`;
export const HighlightPeopleWrapper = styled.div<{offset: number}>`
  ${highlightStyles};
  top: ${({offset}) => (offset ? `${256 - offset}px` : '256px')};
  left: 16px;
`;
export const HighlightExploreListingsWrapper = styled.div`
  ${highlightStyles};
  top: 68px;
  left: 16px;
`;
export const HighlightExploreEventsWrapper = styled.div<{offset: number}>`
  ${highlightStyles};
  top: ${({offset}) => (offset ? `${218 - offset}px` : '218px')};
  left: 16px;
  ${Media.down.l} {
    display: none;
  }
`;
export const HighlightMessagesWrapper = styled.div<{offset: number}>`
  ${highlightStyles};
  top: ${({offset}) => (offset ? `${296 - offset}px` : '296px')};
  left: 16px;
`;
export const HighlightHelpDeskWrapper = styled.div`
  ${highlightStyles};
  bottom: 81px;
  left: 16px;
`;
export const HighlightProfileWrapper = styled.div`
  ${highlightStyles};
  top: 7px;
  right: 31px;
  ${Media.down.l} {
    top: ${isFromApp() ? '4px' : '10px'};
    right: 41px;
    border-radius: 100%;
  }
`;

export const HighlightSwitchWrapper = styled.div`
  ${highlightStyles};
  top: 9px;
  left: 22px;
  display: flex;
  flex-direction: row;
  width: 235px;
  align-items: center;
  ${Media.down.l} {
    max-width: 260px;
    width: 100%;
    top: ${isFromApp() ? '4px' : '7px'};
    left: 10px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    }
    ${Media.down.xs} {
      max-width: 245px;
    }
  }
`;

export const SwitchText = styled(Text)`
  font-size: 16px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.palette.common.aqua};
  ${Media.down.l} {
    font-size: 14px;
    color: ${theme.palette.grayscale._6};
  }
`;

export const HomeWrapperMob = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 5px;
`;

export const MobMenuItemHighlight = styled.div<{$active?: boolean}>`
  display: none;
  ${Media.down.l} {
    border-radius: 100%;
    height: 66px;
    width: 66px;
    margin-left: -8px;
    margin-top: -8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.palette.grayscale._0};
    opacity: ${({$active}) => ($active ? 1 : 0)};
    ${isFromApp() &&
    `
      height: 52px;
      width: 52px;
      margin-left: 0px;
      margin-top: 0px;
    `};
  }
`;

export const BottomHighLightsWrapper = styled.div`
  display: none;
  min-height: 86px;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 18px 35px;
  transition: padding 0.2s ease;
  position: absolute;
  bottom: 0;
  width: 100%;
  ${isFromApp() &&
  `
  padding-bottom: 0;
  align-items: flex-end;
    `}
  ${Media.down.l} {
    display: flex;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 12px;
  top: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
`;

export const StepsProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-self: flex-start;
`;

export const StepsProgressDot = styled.div<{$active?: boolean}>`
  width: 12px;
  height: 12px;
  margin-right: 12px;
  border-radius: 100%;
  background-color: ${({theme, $active}) => ($active ? theme.palette.common.aqua : theme.palette.grayscale._3)};
`;

export const EmptySpace = styled.div``;
