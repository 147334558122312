import {HelpDeskItem} from '../queries/types/helpDesk';
import {HelpDeskCreateTypes} from '../helpers/helpDesk';
import {User} from '../queries/types/user';

export enum helpDeskButtonType {
  shareFeedback = 'shareFeedback',
  bugReport = 'bugReport',
  faqView = 'faqView',
}

export enum quoteBase {
  defaultDailyQuote = 'defaultDailyQuote',
  dailyQuote = 'dailyQuote',
  defaultQuoteAuthor = 'defaultQuoteAuthor',
}

export enum baseHelpDeskItem {
  haveAppExpIdeas = 'haveAppExpIdeas',
  haveAppIssues = 'haveAppIssues',
}

export type THelpDeskItem = HelpDeskItem & {
  buttonTitle?: helpDeskButtonType;
};

export enum HelpDeskFormAction {
  create = 'create',
  edit = 'edit',
  view = 'view',
}

export enum HelpDeskFields {
  objectId = 'objectId',
  title = 'title',
  descr = 'descr',
  layOutOrder = 'layOutOrder',
  isPublished = 'isPublished',
  buttonTitle = 'buttonTitle',
  Community = 'Community',
  Assignee = 'Assignee',
  assignTo = 'assignTo',
  dailyQuoteAuthor = 'dailyQuoteAuthor',
  isDeleted = 'isDeleted',
  type = 'type',
}

type CreateParseFields = {
  Assignee?: {
    link?: string;
  };
  Community: {
    link?: string;
  };
};

type NewItemFields = {
  Assignee?: any;
  type: HelpDeskCreateTypes;
};

type NewHelpDeskItemFields = {
  Assignee?: User;
};

export type HelpDeskFormValues = Omit<Pick<HelpDeskItem, HelpDeskFields>, keyof NewItemFields> & NewItemFields;
export type HelpDeskFormField = keyof HelpDeskFormValues;
export type HelpDeskFormValue = HelpDeskFormValues[HelpDeskFormField];
export type CreateHelpDeskFieldsInput = Omit<Partial<HelpDeskFormValues>, keyof CreateParseFields> & CreateParseFields;
export type EditHelpDeskFieldsInput = Partial<HelpDeskFormValues>;

export type HelpDeskItemT = Omit<THelpDeskItem, keyof NewHelpDeskItemFields> & NewHelpDeskItemFields;
