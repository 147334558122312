import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CopiedText, UiCopyButton} from './styles';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';

type TButton = {copiedValue?: string};

const CopyButton: React.FC<TButton> = ({copiedValue}) => {
  const {t} = useTranslation();
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setCopySuccess('');
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [copySuccess]);

  const handleCopy = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!copiedValue) return;
    try {
      await navigator.clipboard.writeText(copiedValue);
      setCopySuccess(t('community:invite.descr.copy'));
    } catch (err) {
      setCopySuccess(t('community:invite.descr.failedCopy'));
    }
  };
  return (
    <UiCopyButton onTouchStart={handleCopy} onClick={handleCopy}>
      {copySuccess && <CopiedText>{copySuccess}</CopiedText>}
      <IconSvg type={'copy'} />
    </UiCopyButton>
  );
};

const MemoCopyButton = React.memo(CopyButton);
export {MemoCopyButton};
