import {anyObject, ArrayOfKeys, ArrayValues} from '../types/helpers';

export function keys<T extends anyObject>(obj: T): ArrayOfKeys<T> {
  return Object.keys(obj) as ArrayOfKeys<T>;
}

type OmitKeys<T extends anyObject, K extends ArrayOfKeys<T>> = {[K2 in Exclude<keyof T, ArrayValues<K>>]: T[K2]};

export function omit<T extends anyObject, K extends ArrayOfKeys<T>>(obj: T, props: K): OmitKeys<T, K> {
  return props.reduce(
    (acc, key) => {
      delete acc[key];
      return acc;
    },
    {...obj},
  );
}
