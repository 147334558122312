import {Link as ReactLink} from 'react-router-dom';
import styled, {css, StyledProps} from 'styled-components';
import {LinkButton} from '../../ui-kit/Button/LinkButton';
import {Input} from '../../ui-kit/Form/Input';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Media, theme} from '../../ui-kit/theme';
import {Text} from '../../ui-kit/Typography/Text';
import {fontPrimaryMedium, fontPrimaryReg, fontPrimarySemiBold, fontTertiaryReg} from '../common/styles';

export type GridProps = {
  maxWidth?: number;
  minWidth?: number;
  columnGap?: number;
  rowGap?: number;
  isDragging?: boolean;
};

const cardStyles = css`
  width: 100%;
  max-width: 100%;
  min-width: 250px;
`;

export const cardBodyStyles = css`
  ${cardStyles};
  padding: 12px 11px;
  margin-bottom: 15px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  cursor: pointer;
  transition: 0.15s all;
  width: 100%;

  &:hover {
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.08);
  }
`;

export const PageWrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
  padding: 54px 0;
`;

export const WrapperForm = styled.div`
  max-width: 1184px;
  width: 100%;
  margin: 0 auto;
  padding: 54px 124px 100px 108px;
  box-sizing: border-box;
  ${Media.down.xl} {
    padding: 54px 20px 100px 20px;
    max-width: 870px;
  }
  ${Media.down.l} {
    padding: 54px 50px 60px 50px;
  }
  ${Media.down.s} {
    padding: 17px 20px 60px 20px;
  }
`;

export const ItemForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  box-sizing: border-box;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  ${Media.down.l} {
    padding-right: 0;
  }
  ${Media.down.xs} {
    width: 100%;
  }
`;

export const ContainerListingCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const InnerListingCard = styled.div<{isOpacity?: boolean}>`
  position: relative;
  opacity: ${({isOpacity}) => (isOpacity ? 0.4 : 1)};
`;

export const InnerItems = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const getTextColor = ({theme, variantText}: StyledProps<{variantText?: string}>) => {
  return variantText && theme.palette.text[variantText];
};

export const Title = styled.h1.attrs({textColor: 'quaternary'})<{
  textColor: string;
}>`
  ${fontPrimaryReg};
  font-size: 26px;
  line-height: 150%;
  color: ${getTextColor};
  margin: 0 0 15px 0;
  ${Media.down.xs} {
    font-size: 16px;
  }
`;

export const SubTitle = styled(Title).attrs({textColor: 'octonary'})<{
  variantText?: string;
  offsetBottom?: number;
}>`
  font-size: 18px;
  color: ${getTextColor};
  margin-bottom: ${({offsetBottom}) => `${offsetBottom || 0}px`};
  ${Media.down.m} {
    font-size: 18px;
  }
`;
export const ListingCardTitle = styled(SubTitle)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const InnerItemsGrid = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 26px;
`;

export const InnerAside = styled.div`
  z-index: ${({theme}) => theme.zIndex._1};
  ${Media.up.m} {
    position: absolute;
    top: 0;
    right: 0;
    width: 456px;
    height: 100%;
  }

  ${Media.down.xl} {
    width: 420px;
  }
`;

export const InnerEditAside = styled(InnerAside)<{width?: string}>`
  width: 310px;
  margin-top: 25px;

  ${Media.down.m} {
    width: 100%;
    position: relative;
  }
`;

export const Aside = styled.aside.attrs({className: 'form-aside__wrapper'})`
  background: ${({theme}) => theme.palette.background.primary};
  position: fixed;
  width: 455px;
  max-width: inherit;
  ${Media.down.xl} {
    width: 420px;
  }
  ${Media.down.m} {
    position: initial;
  }
`;

export const AsideEdit = styled(Aside)`
  width: 350px;
  background-color: transparent;
`;

export const TipsTitle = styled.div`
  margin: 0;
  padding: 0;
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 12px;
  line-height: 150%;
`;

export const InnerDesrPhotos = styled(TipsTitle)`
  ${fontPrimaryReg};
  margin-top: 5px;
  text-align: right;
`;

export const LabelPhoto = styled.label<{
  isUndercard?: boolean;
  typeCursor?: string;
  width?: string;
  height?: string;
  bordered?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || 'auto'};
  cursor: ${({typeCursor}) => typeCursor || 'inherit'};
  opacity: ${({isUndercard}) => (isUndercard ? 0.7 : 1)};
  background-color: ${({isUndercard, theme}) =>
    isUndercard ? theme.palette.background.secondary : theme.palette.background.primary};
`;

export const InnerDataPreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
`;

const minWidth = ({minWidth}: GridProps) => (minWidth ?? 138) + 'px';
const columnGap = ({columnGap}: GridProps) => (columnGap ? columnGap : 14) + 'px';
const rowGap = ({rowGap}: GridProps) => (rowGap ? rowGap : 22) + 'px';

export const PhotoGrid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-row-gap: ${rowGap};
  grid-column-gap: ${columnGap};
  grid-template-columns: auto auto auto;
`;

export const InnerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;

export const ItemsCardsGrid = styled(PhotoGrid)`
  grid-template-columns: repeat(auto-fill, minmax(${minWidth}, 1fr));
  ${Media.down.xl} {
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  }
  & > * {
    max-width: 300px;
  }
  ${Media.down.m} {
    //grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    & > * {
      max-width: 100%;
    }
  }
`;

export const Link = styled(ReactLink)`
  font-size: 12px;
  line-height: 150%;
  ${fontTertiaryReg};
  color: ${({theme}) => theme.palette.text.quinary};
  text-decoration: underline;
`;

export const ViewAllBtn = styled.button`
  font-size: 12px;
  line-height: 150%;
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const InnerAllItems = styled.div``;

export const ContainerItemCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const InnerItemsCard = styled.div<{$isOpacity?: boolean}>`
  position: relative;
  opacity: ${({$isOpacity}) => ($isOpacity ? 0.4 : 1)};
`;

export const IconStar = styled(IconSvg)`
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 100;
`;

export const InnerLabelCard = styled.div`
  margin-top: 4px;
`;

export const InnerLabelItemTitle = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-right: 29px;
`;

export const InnnerItemCardBtns = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:last-child {
    margin-left: 6px;
  }
`;

export const WrapperEditFormAside = styled.div`
  max-width: 340px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  ${Media.down.m} {
    & .aside__actions-block {
      order: 1;
    }
  }
`;

export const InnerInsights = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  & > *:nth-child(2n) {
    margin-left: 10px;
  }
`;

export const CardInsight = styled.div`
  box-sizing: border-box;
  padding: 12px 7px 12px 20px;
  margin-bottom: 10px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._3};
  border-radius: 6px;
  width: 47%;
  max-width: 47%;
  flex-grow: 1;
  height: 100%;
  background: ${({theme}) => theme.palette.background.primary};
`;

export const TitleInsight = styled.div`
  color: ${({theme}) => theme.palette.grayscale._13};
  font-size: 12px;
  line-height: 150%;
  ${fontPrimarySemiBold};
`;

export const ValueInsight = styled.div`
  font-size: 20px;
  ${fontPrimaryReg};
`;

export const InnerActions = styled(InnerInsights)`
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const ActionBtnStyles = css`
  padding: 11px 20px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  background: none;
  font-size: 14px;
  line-height: 150%;
  ${fontPrimarySemiBold};
  color: ${({theme}) => theme.palette.text.octonary};
  border-radius: 34px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const ActionButton = styled.button`
  ${ActionBtnStyles};
`;

export const ActionLink = styled(ReactLink)`
  ${ActionBtnStyles};
`;

export const WrapperPageLocalBiz = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 15px 90px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > :first-child {
    padding-bottom: 10px;
  }
  ${Media.down.l} {
    padding-top: 50px;
  }
`;

export const WrapperSearch = styled.div`
  width: 100%;

  & > * {
    z-index: 200;
  }

  ${Media.down.s} {
    display: block;
    margin: -35px 0 -15px;
  }
`;

export const MainContItemView = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const WrapperItemView = styled.div<{offsetTop?: string}>`
  max-width: 953px;
  width: 100%;
  margin: 0 auto;
  padding: ${({offsetTop}) => offsetTop || '54px'} 30px 18px 20px;
  box-sizing: border-box;
`;

export const ItemViewTitle = styled.div`
  ${fontPrimaryMedium};
  font-size: 26px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.black};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  ${Media.down.m} {
    font-size: 20px;
  }
`;

export const ItemViewSubTitle = styled(ItemViewTitle)`
  font-size: 20px;

  ${Media.down.m} {
    font-size: 14px;
  }
`;

export const ItemViewDescr = styled(ItemViewTitle)`
  font-size: 16px;
  padding-top: 12px;
  ${fontPrimaryReg};

  & > div {
    display: flex;
    flex-direction: column;
  }

  & p {
    margin: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const InnerItemView = styled.div<{$isLine?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 330px;
  box-sizing: border-box;

  ${Media.down.l} {
    padding-right: 0;
  }

  ${Media.down.xs} {
    width: 100%;
  }
  .pswp__img {
    object-fit: contain !important;
  }
`;

export const ListingTypeSelectWrapper = styled.div`
  width: 100%;

  ${Media.up.xl} {
    & > div > div > div:last-child {
      margin-left: auto;
    }
  }
`;

// Mobile search

export const Hidden = styled.div`
  ${Media.down.s} {
    display: none;
  }
`;

export const MobileSearchWrapper = styled.div`
  position: fixed;
  inset: 0;
  height: max-content;
  z-index: 301;

  & * {
    box-sizing: border-box;
  }

  ${Media.up.s} {
    display: none;
  }
`;

export const InnerMobileSearchWrapper = styled.div`
  & * {
    box-sizing: border-box;
  }

  ${Media.up.s} {
    display: none;
  }
`;

// listing created
export const ListingCreatedWrapper = styled.div`
  width: 100%;
  padding: min(55px, 5vw) min(110px, 8vw);
  box-sizing: border-box;
`;

export const ListingCreatedContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  box-sizing: border-box;
  ${Media.down.m} {
    gap: 12px;
  }
`;

export const ListingCreatedTitle = styled(Text).attrs({
  variant: 'primary',
  color: 'quaternary',
  weight: 'semiBold',
})`
  font-size: 36px;
  ${Media.down.m} {
    display: none;
  }
`;

export const ListingCreatedSubtitle = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
})`
  font-size: 16px;
`;

export const ListingCreatedButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ListingCreatedCardWrapper = styled.div`
  max-width: 300px;
  width: 100%;
`;

export const CommunityCardLink = styled.div<{$isCheckboxMode?: boolean; $isChecked?: boolean}>`
  ${cardBodyStyles};

  margin: 0;
  position: relative;
  ${({$isCheckboxMode}) => $isCheckboxMode && 'pointer-events: none;'}
  ${({$isChecked}) => $isChecked && `background: ${theme.palette.common.blueLight};`}
  ${({$isChecked}) => $isChecked && `border-color: ${theme.palette.common.aqua};`}
  & div > div > div {
    color: ${({theme}) => theme.palette.text.duodecimal};
  }
`;

export const CommunityPendingLabelWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const MenuButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  pointer-events: all;

  & > div {
    top: 10px;
  }

  & button {
    padding: 0 10px;
  }
`;

export const HiddenMenu = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

export const DesktopView = styled.div`
  display: block;
  ${Media.down.m} {
    display: none;
  }
`;

export const MobileView = styled.div`
  display: none;
  ${Media.down.m} {
    display: block;
    width: 100%;
  }
`;

export const MobFocused = styled.div`
  ${Media.down.m} {
    & .rt-editor .DraftEditor-root {
      height: 100% !important;
      & .public-DraftEditor-content > div {
        height: 100%;
      }
    }
  }
`;

export const PageLoaderWrapper = styled.div`
  display: block;
  position: relative;
  & .page-loader__overlay {
    position: absolute;
  }
`;

export const AddListingBtn = styled(LinkButton)`
  .button-value {
    display: flex;
    align-items: center;
  }
`;

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
`;

export const ViewCompanyPageWrapper = styled.div`
  margin-bottom: 80px;

  ${Media.down.m} {
    margin-bottom: 40px;
  }
`;

export const HintWrapper = styled.div`
  position: absolute;

  top: 0;
  bottom: 0;
  left: calc(100% + 16px);

  display: flex;
  align-items: center;

  & > label > div:not(:first-child) {
    top: -70px !important;
    left: 25px !important;
  }
  ${Media.down.l} {
    left: unset;
    right: 0;
    & > label > div:not(:first-child) {
      top: -70px !important;
      right: 25px !important;
      left: unset !important;
    }
  }
`;

export const InputLeftIcon = styled(Input)<{$isError: boolean}>`
  gap: 8px;
  border: ${({$isError, theme}) =>
    $isError ? `2px solid ${theme.palette.common.red}` : `1px solid ${theme.palette.text.heptadecimal}`};

  & > div:not(.fieldset-box__inner-input) {
    order: -1;
    margin: 0;
  }

  & svg {
    margin: 0 !important;
  }
`;

export const TopWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
