import {useCallback, useEffect, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {CustomCommunity} from '../components/Account/Popups/Settings/types';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {useGetCurrencyValue} from './currency';
import {FormErrorFields, useFormError} from './error';
import {useCheckUserHasMessages} from './message';
import {useCurrencies, usePaymentPlans} from './payments';
import {useCheckUserType} from './people';
import useMutableState from './useMutableState';
import {useGetTypeUser, useMarkCommunitySwitched, useRefetchPermissions, useViewer, useViewerId} from './user';
import {client} from '../ApolloProvider';
import {AccessType, CommunityUsers, ListingApproval} from '../constants/community';
import {
  GQLCommunityOrder,
  GQLCommunityWhereInput,
  GQLCreateCommunityFieldsInput,
  GQLJoinRequestWhereInput,
} from '../graphql.schema';
import {getOptions} from '../helpers/common';
import {
  getCurrentCommunity,
  setCurrentCommunity as setCurrentCommunityInLS,
  setCurrentCommunity,
  toPointerCommunity,
  toStateCommunity,
  useGetAccTypes,
  useGetListingsApprovalOptions,
  CommunityTypes,
} from '../helpers/community';
import {changeFileName} from '../helpers/file';
import {FormValidation} from '../helpers/FormValidation';
import {getPlan} from '../helpers/payment';
import {equalToQuery} from '../helpers/query';
import {isString} from '../helpers/validations';
import {
  AccountTransfer,
  AccountTransferRequest,
  AccountTransferResponse,
  CommunitiesResponseType,
  CreateCommunityQuery,
  createJoinRequest,
  createJoinRequestType,
  createJoinResponseType,
  GetCommunitiesCount,
  GetCommunitiesMicroQuery,
  GetCommunitiesQuery,
  GetCommunityAdmin,
  GetCommunityAdminResponse,
  GetCommunityAlias,
  GetCommunityInfoRequests,
  GetCommunityList,
  GetCommunityReqType,
  GetCommunityRequest,
  GetCommunityResType,
  GetCommunityShortRequest,
  GetCommunitySmallRequest,
  GetCountJoinRequests,
  GetInvitationRequests,
  GetPaymentPlanReqType,
  GetPaymentPlanRequest,
  GetPaymentPlanResType,
  GetShortCommunitiesQuery,
  GetShortCommunityInfo,
  leaveCommunity,
  leaveCommunityParams,
  leaveCommunityRes,
  RemoveCommunityAdmin,
  RemoveCommunityAdminRequest,
  RemoveCommunityAdminResponse,
  SendInvite,
  SendInviteRequest,
  SendInviteResponse,
  SendManagerInvite,
  SendManagerInviteRequest,
  SendManagerInviteResponse,
  UpdateCommunityQuery,
  UpdateCommunityRequestType,
  UpdateCommunityResponseType,
} from '../queries/community';
import {
  ChangePlanRequest,
  ChangePlanResponseType,
  ChangePlanVariablesType,
  GetPaymentPlanByIdRequest,
} from '../queries/payments';
import {Community, CommunityAdmin, JoinRequest} from '../queries/types/community';
import {Connection} from '../queries/types/parse';
import {PaymentPlan, PaymentPlanName, TFees} from '../queries/types/payments';
import {GetUsersQuery, UsersResponseType} from '../queries/user';
import {
  CommunityStateType,
  currentCommunity,
  InviteState,
  inviteToCommunityState,
  userCommunities,
  passCodeToCommunity,
  onlineUsers,
} from '../states/community';
import {typeUser, TypeUserStateType} from '../states/typeUser';
import {TypeCommunity} from '../types/auth';
import {imageType} from '../types/common';
import {
  addAddressContainer,
  addAddressProps,
  CommunityField,
  CommunityFormValue,
  CommunityFormValues,
  TCommunity,
  TOptions,
} from '../types/community';
import {TItem} from '../types/item';
import {CurrencyCode, PaymentPeriodType} from '../types/payment';
import {JoinRequestStatuses} from '../types/people';
import {useHistory} from 'react-router-dom';
import {useLinks} from './common';
import {route} from '../constants/routes';
import {GetCommunityMembersResType, GetCommunityUsersShort} from '../queries/people';
import {useResetPreload} from './preload';
import {User} from '../queries/types/user';
import {toPOJO, useLiveQuery} from './parse';
import Parse from 'parse';
import {getToken} from '../libs/auth';

export const useRemoveCommunityAddAddress = () => {
  const {update} = useCommunityUpdate();
  const remove = async (id: string, params: addAddressContainer[], idComm?: string) => {
    const addAdd: addAddressProps[] = [];

    const getAllZip = (data: addAddressProps[]) => {
      const allZip = [];
      for (const el of data) {
        allZip.push(el.zip);
      }
      return allZip;
    };
    // const formatData = (info: addAddressContainer[] | addAddressProps) => {
    const formatData = (info: any) => {
      if (Array.isArray(info)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const newValue = info.map((item) => {
          if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
            Object.keys(item).length < 4 ? formatData(item.value) : addAdd.push(item);
          }
        });
      }
      if (typeof info === 'object' && !Array.isArray(info) && info !== null) {
        Object.keys(info).length > 3 ? addAdd.push(info) : formatData(info.value);
      }
      return addAdd;
    };

    try {
      const newFields = transformation(id, params);
      const workWithFields = formatData(newFields);
      const allZip = getAllZip(workWithFields);
      if (!idComm) return;
      await update({
        id: idComm,
        fields: {
          address_add: workWithFields,
          zip_add: allZip,
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  return {remove};
};

const transformation = (id: string, params?: addAddressContainer[]) => {
  const newVal = params?.flat();
  const newFilerrParams = newVal?.filter((item: addAddressContainer) => item?.value.id !== id);
  const address_add = newFilerrParams?.flat();
  return address_add;
};

export const useCommunities = (options?: {
  where?: GQLCommunityWhereInput;
  requestsWhere?: GQLJoinRequestWhereInput;
  order?: GQLCommunityOrder;
  skip?: boolean;
  first?: number;
  userId?: string;
  short?: boolean;
}) => {
  const {data, ...response} = useQuery<CommunitiesResponseType>(
    options?.short ? GetCommunitiesMicroQuery : GetCommunitiesQuery,
    {
      variables: {
        ...(options?.where ? {where: options.where} : {}),
        ...(options?.order ? {order: options.order} : {}),
        ...(options?.first ? {first: options?.first} : {first: 15}),
        userId: options?.userId,
      },
      ssr: true,
      fetchPolicy: 'cache-and-network',
      skip: options?.skip,
    },
  );
  return {
    data: data?.communities?.edges?.map((edge) => toStateCommunity(edge.node)) || [],
    ...response,
  };
};

export const useCommunitiesShort = (options?: {
  where?: GQLCommunityWhereInput;
  requestsWhere?: GQLJoinRequestWhereInput;
  order?: GQLCommunityOrder;
  skip?: boolean;
  first?: number;
  userId?: string;
}) => {
  const {data, ...response} = useQuery<CommunitiesResponseType>(GetCommunityList, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {first: 15}),
      userId: options?.userId,
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
  return {
    data: data?.communities?.edges?.map((edge) => toStateCommunity(edge.node)) || [],
    infoData: data,
    ...response,
  };
};

export const useCommunity = (viewerId?: string, typeUser?: TypeUserStateType) => {
  const field = typeUser === TypeCommunity.manager ? 'Owner' : 'Residents';
  const {data, loading} = useCommunities({
    skip: !viewerId,
    where: {[field]: {have: {id: {equalTo: viewerId}}}},
  });

  return {data: data ? data[0] : null, communities: data, loading};
};

export const useGetListCommunities = (viewerId?: string, first?: number, short?: boolean) => {
  const {data, loading, refetch} = useCommunities({
    skip: !viewerId,
    where: {
      OR: [
        {[CommunityUsers.Owner]: {have: {id: {equalTo: viewerId}}}},
        {[CommunityUsers.Residents]: {have: {id: {equalTo: viewerId}}}},
      ],
    },
    first: first || 15,
    short,
  });

  const handleRefetch = async (viewerId: string) => {
    const result = await refetch({
      where: {
        OR: [
          {[CommunityUsers.Owner]: {have: {id: {equalTo: viewerId}}}},
          {[CommunityUsers.Residents]: {have: {id: {equalTo: viewerId}}}},
        ],
      },
    });
    return result?.data?.communities?.edges?.map((edge) => toStateCommunity(edge.node)) || [];
  };

  return {data, loading, refetch: handleRefetch};
};

export const useGetCommunity = (params: {id?: string; short?: boolean; small?: boolean}) => {
  const {data, ...response} = useQuery<GetCommunityResType, GetCommunityReqType>(
    params?.short ? GetCommunityShortRequest : params?.small ? GetCommunitySmallRequest : GetCommunityRequest,
    {
      variables: {
        id: params.id || '',
      },
      skip: !params?.id,
      ssr: true,
    },
  );

  return {...response, data: toStateCommunity(data?.community) || null};
};

export const useGetJoinCommunity = () => {
  const setPassCodeToCommunity = useSetRecoilState(passCodeToCommunity);

  const {loading, refetch} = useCommunities({
    skip: true,
    short: true,
  });

  const onSearch = async (code: string) => {
    const result = await refetch({
      where: {passCode: {equalTo: code}},
    });
    setPassCodeToCommunity({passCode: code});
    return result?.data?.communities?.edges?.[0]?.node;
  };
  return {loading, onSearch};
};
export const useGetPayPlanCommunity = (params: {id?: string}) => {
  const {data, ...response} = useQuery<GetPaymentPlanResType, GetPaymentPlanReqType>(GetPaymentPlanRequest, {
    variables: {
      id: params.id || '',
    },
    skip: !params?.id,
    ssr: true,
  });

  return {...response, data: data?.community?.Subscr?.PaymentPlan || null};
};

type SelectPlanState = {
  plan?: string;
  billing?: PaymentPeriodType | string;
};

export type TPlan = {
  id?: string;
  objectId?: string;
  name?: string;
  descr?: string;
  priceYear?: number;
  priceMonth?: number;
  communityFee?: boolean;
  options: {isSupport?: boolean; text?: string}[];
  serviceFees?: TFees;
  salesFees?: TFees;
  rentFees?: TFees;
};

export type TPaymentParams = {
  name: keyof SelectPlanState;
  value?: PaymentPeriodType | PaymentPlanName | string;
};

export type SelectedTPlan = TPlan & {
  currentPriceMonth?: string | number;
  currentPriceYear?: string | number;
};

export type SelectPlanRes = {
  paymentPlans?: SelectedTPlan[];
  paymentPeriods: string[];
  accent: PaymentPlanName;
  values: SelectPlanState;
  onSubmit: (next: TPaymentParams) => Promise<boolean> | void;
  loading: boolean;
  error: FormErrorFields<SelectPlanState>;
  success: string | null;
  onChange: (next: TPaymentParams) => void;
};

export const useSelectPlan = (options: {
  initialState: {
    plan?: string;
    billing?: PaymentPeriodType | string;
  };
  onSuccess?: () => void;
  onError?: () => void;
  communityId?: string;
}): SelectPlanRes => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<SelectPlanState>(options?.initialState);
  const [error, setError] = useFormError<SelectPlanState>();
  const [success, setSuccess] = useState<string | null>(null);
  const setCommunity = useSetRecoilState(currentCommunity);
  const {currency} = useGetCurrencyValue();

  const {data: paymentPlans} = usePaymentPlans({
    where: {AND: [{Currency: {have: equalToQuery('objectId', currency?.objectId || '')}}, {isPublic: {equalTo: true}}]},
  });
  const [ChangeCmntRequest, {loading}] = useMutation<UpdateCommunityResponseType, UpdateCommunityRequestType>(
    UpdateCommunityQuery,
  );

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.billing)) return;
    setValues(options.initialState);
    setState({hasSetInitialValues: true});
  }, [options.initialState]);

  const UiPaymentPeriods = Object.values(PaymentPeriodType);
  const UiPaymentPlans = paymentPlans?.map((plan) => getPlan(plan, values?.billing as PaymentPeriodType));

  const handleChange = (next: TPaymentParams) => {
    setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const onSubmitCommunityExist = async (next: TPaymentParams): Promise<boolean> => {
    try {
      handleChange(next);
      const paymentPlan = (next.name === 'plan' && next.value) || values.plan;
      const paymentPeriod = (next.name === 'billing' && next.value) || values.billing;

      if (!paymentPlan || !paymentPeriod) return false;

      if (!options?.communityId) {
        throw new Error('error:id');
      }

      const response = await ChangeCmntRequest({
        variables: {
          id: options?.communityId,
          fields: {
            Subscr: {
              createAndLink: {
                PaymentPlan: {link: paymentPlan},
                paymentPeriod: paymentPeriod,
              },
            },
          },
        },
      });

      const data = response?.data?.updateCommunity?.community;
      if (!data) throw new Error();

      await setCommunity(toStateCommunity(data));
      setSuccess('success:paymentPlan');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };
  const onSubmitCreateSecondary = async (next: TPaymentParams): Promise<boolean> => {
    try {
      handleChange(next);
      const paymentPlan = (next.name === 'plan' && next.value) || values.plan;
      const paymentPeriod = (next.name === 'billing' && next.value) || values.billing;

      if (!paymentPlan || !paymentPeriod) return false;

      const {data: paymentPlanData} = await client.query<{paymentPlan: PaymentPlan}>({
        query: GetPaymentPlanByIdRequest,
        variables: {id: paymentPlan},
      });

      await setCommunity(
        toStateCommunity({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Subscr: {PaymentPlan: paymentPlanData.paymentPlan, paymentPeriod: paymentPeriod},
        }),
      );
      setSuccess('success:paymentPlan');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  const onSubmit = async (next: TPaymentParams): Promise<boolean> => {
    return options?.communityId ? onSubmitCommunityExist(next) : onSubmitCreateSecondary(next);
  };

  return {
    paymentPlans: UiPaymentPlans,
    paymentPeriods: UiPaymentPeriods,
    accent: PaymentPlanName.pro,
    values,
    onSubmit,
    loading,
    error,
    success,
    onChange: handleChange,
  };
};

export const useChangePlan = (options: {
  initialState: {
    plan?: string;
    billing?: PaymentPeriodType | string;
  };
  onSuccess?: () => void;
  onError?: () => void;
  communityId?: string;
}): SelectPlanRes => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<SelectPlanState>(options?.initialState);
  const [error, setError] = useFormError<SelectPlanState>();
  const [success, setSuccess] = useState<string | null>(null);
  const [community] = useRecoilState(currentCommunity);
  const {refetchCommunity} = useRefetchCommunity();
  const {currency} = useGetCurrencyValue();
  const currencyCode = currency?.code.toLocaleLowerCase() === 'cad' ? CurrencyCode.CAD : CurrencyCode.USD;
  const {data: currencyUsdCad} = useCurrencies({
    where: equalToQuery('code', currencyCode),
  });
  const {data: paymentPlans} = usePaymentPlans({
    where: {AND: [{Currency: {have: equalToQuery('id', currencyUsdCad[0]?.id || '')}}, {isPublic: {equalTo: true}}]},
  });
  const [changePlan, {loading, data}] = useMutation<ChangePlanResponseType, ChangePlanVariablesType>(ChangePlanRequest);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.billing)) return;
    setValues(options.initialState);
    setState({hasSetInitialValues: true});
  }, [options.initialState]);

  const UiPaymentPeriods = Object.values(PaymentPeriodType);
  const UiPaymentPlans = paymentPlans?.map((plan) => getPlan(plan, values?.billing as PaymentPeriodType));

  const handleChange = (next: TPaymentParams) => {
    setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const onSubmit = async (next: TPaymentParams): Promise<boolean> => {
    try {
      handleChange(next);
      const paymentPlan = (next.name === 'plan' && next.value) || values.plan;
      const paymentPeriod = (next.name === 'billing' && next.value) || values.billing;

      if (!paymentPlan || !paymentPeriod) return false;

      if (!community?.Subscr?.objectId || !paymentPlan || !paymentPeriod) return false;
      await changePlan({
        variables: {
          subscrId: community?.Subscr?.objectId,
          newPayPlanId: paymentPlan,
          newPayPeriod: paymentPeriod as PaymentPeriodType,
        },
      });

      setSuccess('success:paymentPlan');
      setError(null);

      await refetchCommunity();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (data?.changeSubscriptionPlan) options?.onSuccess?.();
  }, [community]);

  return {
    paymentPlans: UiPaymentPlans,
    paymentPeriods: UiPaymentPeriods,
    accent: PaymentPlanName.pro,
    values,
    onSubmit,
    loading,
    error,
    success,
    onChange: handleChange,
  };
};

export type TOptionsSelect = {
  accTypes: TOptions[];
  listingsApproval: TOptions[];
};

export const useOptionsSelectCommunity = (): TOptionsSelect => {
  return {
    accTypes: getOptions(AccessType),
    listingsApproval: getOptions(ListingApproval),
  };
};

const ProfileSchema = FormValidation.schema<Partial<CommunityFormValues>>({
  name: FormValidation.string('error:allRequired'),
  descr: FormValidation.string('error:allRequired'),
  alias: FormValidation.string('error:allRequired'),
  country: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  state: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  city: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  zip: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  address: FormValidation.handler((value, data) => {
    if (data.type === CommunityTypes.circle || data.type === CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  neighbourhood: FormValidation.handler((value, data) => {
    if (data.type !== CommunityTypes.neighbourhood) return true;
    return !!value;
  }, 'error:allRequired'),
  accessType: FormValidation.string('error:allRequired'),
  listingApproval: FormValidation.string('error:allRequired'),
});

export type ResCmntType = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<CommunityFormValues>;
  values: Partial<CommunityFormValues>;
  success: string | null;
  onChange: (next: {name: string; value: CommunityFormValue | TOptions}) => void;
  options: TOptionsSelect;
};

type UseUpdateCmntType = (options: {
  initialState: Partial<CommunityFormValues>;
  isCreate?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
}) => ResCmntType;

export const useUpdateCommunity: UseUpdateCmntType = (options) => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const setCommunities = useSetRecoilState(userCommunities);
  const setTypeUser = useSetRecoilState(typeUser);
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<CommunityFormValues>>(options.initialState);
  const {accTypes, listingsApproval} = useOptionsSelectCommunity();
  const [error, setError] = useFormError<CommunityFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const planOptions = values?.Subscr?.PaymentPlan?.options;
  const [createCmnty, {loading: loadingCreate}] = useMutation<
    {createCommunity: {community: Community}},
    {fields: GQLCreateCommunityFieldsInput}
  >(CreateCommunityQuery);

  const id = useViewerId('objectId');

  const accessType = useGetAccTypes(planOptions)[0]?.value;
  const listingApproval = useGetListingsApprovalOptions(planOptions)[0]?.value;

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if ((!isString(options.initialState?.name) && !options?.isCreate) || !accTypes || !listingsApproval) return;
    setValues({
      ...options.initialState,
      accessType,
      listingApproval,
    });
    setState({hasSetInitialValues: true});
  }, [options.initialState, state, accTypes, listingsApproval]);

  const [ChangeCmntRequest, {loading}] = useMutation<UpdateCommunityResponseType, UpdateCommunityRequestType>(
    UpdateCommunityQuery,
  );

  const handleChange = (next: {name: string; value: CommunityFormValue}) => {
    if (next.name === CommunityField.state) {
      setValues((prev) => ({...prev, [CommunityField.city]: null}));
    }
    if (next.name === CommunityField.country && next.value === values.country) {
      setValues((prev) => ({
        ...prev,
        [CommunityField.city]: null,
        [CommunityField.state]: null,
      }));
    }
    setError(null);
    setSuccess(null);
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const updateCommunity = async (): Promise<boolean> => {
    try {
      if (!ProfileSchema.validate<CommunityFormValues>(values)) {
        return false;
      }

      if (!values.id) {
        throw new Error('error:id');
      }
      const {id, ...fields} = values;
      const response = await ChangeCmntRequest({
        variables: {id: id, fields: toPointerCommunity(fields)},
      });

      const data = response?.data?.updateCommunity?.community;
      if (!data) throw new Error();

      const newCommunity = toStateCommunity(data);
      await setCommunity(newCommunity);
      await setCommunities((prev) => [...prev, newCommunity]);
      setTypeUser(TypeCommunity.manager);

      setSuccess('success:createCommunity');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  const createCommunity = async (): Promise<boolean> => {
    try {
      if (!ProfileSchema.validate<CommunityFormValues>(values)) {
        return false;
      }

      const {data: community} = await createCmnty({
        variables: {
          fields: {
            ...toPointerCommunity(values),
            ...(values.Subscr?.PaymentPlan.objectId
              ? {
                  Subscr: {
                    createAndLink: {
                      PaymentPlan: {link: values.Subscr.PaymentPlan.objectId},
                      paymentPeriod: values.Subscr.paymentPeriod,
                    },
                  },
                }
              : {}),
            Owner: {link: id},
          },
        },
      });

      const data = community?.createCommunity?.community;
      if (!data) throw new Error();

      const newCommunity = toStateCommunity(data);
      await setCommunity(newCommunity);
      await setCommunities((prev) => [...prev, newCommunity]);
      setSuccess('success:createCommunity');
      setError(null);
      newCommunity.objectId && setCurrentCommunity(newCommunity.objectId);
      setTypeUser(TypeCommunity.manager);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    return options?.isCreate ? createCommunity() : values.id ? updateCommunity() : createCommunity();
  };

  const accTypesOptions = useGetAccTypes(planOptions);

  const listingsApprovalOptions = useGetListingsApprovalOptions(planOptions);

  return {
    values,
    onSubmit,
    success,
    loading: loading || loadingCreate,
    onChange: handleChange,
    error,
    options: {
      accTypes: accTypesOptions,
      listingsApproval: listingsApprovalOptions,
    },
  };
};

export type TInviteCommunity = {
  id: string;
  alias: string;
  invitation?: string;
};

const ProfileSchemaInvite = FormValidation.schema<Partial<TInviteCommunity>>({
  invitation: FormValidation.string('error:invitation'),
});

export type TInviteReturn = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<TInviteCommunity>;
  values: Partial<TInviteCommunity>;
  success: string | null;
  onChange: (next: {name: string; value: string}) => void;
};

type TuseInviteCommunity = (options: {
  initialState: Partial<TInviteCommunity>;
  onSuccess?: () => void;
  onError?: () => void;
}) => TInviteReturn;

export const useInviteCommunity: TuseInviteCommunity = (options) => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<TInviteCommunity>>(options.initialState);
  const [error, setError] = useFormError<CommunityFormValues>();
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.alias)) return;
    setValues(options.initialState);
    setState({hasSetInitialValues: true});
  }, [options.initialState]);

  const [ChangeCmntRequest, {loading}] = useMutation<UpdateCommunityResponseType, UpdateCommunityRequestType>(
    UpdateCommunityQuery,
  );

  const handleChange = (next: {name: string; value: CommunityFormValue}) => {
    setError(null);
    setSuccess(null);
    return setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const update = async (): Promise<boolean> => {
    try {
      if (!ProfileSchemaInvite.validate<TInviteCommunity>(values)) {
        return false;
      }

      if (!values.id) {
        throw new Error('Id is undefined!!!');
      }
      const response = await ChangeCmntRequest({
        variables: {id: values.id, fields: {invitation: values.invitation}},
      });

      const data = response?.data?.updateCommunity?.community;
      if (!data) throw new Error();

      await setCommunity(toStateCommunity(data));
      setSuccess('Community created successfully');
      setError(null);

      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };
  return {
    values,
    onSubmit: update,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

export type TJoinReturn = {
  onSubmit: (option?: {
    localUserId?: string;
    localCommId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    invite?: InviteState;
  }) => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  success: string | null;
};

type TJoin = (options?: {
  initialState: {userId?: string; communityId?: string};
  isPrivate: boolean;
  onSuccess?: (user: User) => void;
  onError?: () => void;
}) => TJoinReturn;

export const useJoinToCommunity: TJoin = (options) => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const invite = useRecoilValue(inviteToCommunityState);
  const viewer = useViewer();
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<{
    userId?: string;
    communityId?: string;
  }>(options?.initialState || {userId: '', communityId: ''});
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [JoinRequest] = useMutation<createJoinResponseType, createJoinRequestType>(createJoinRequest);
  const inviteId =
    (invite?.communityId === values?.communityId && invite?.inviteId) ||
    (invite?.communityId === values?.communityId && invite?.inviteId);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (
      !isString(options?.initialState?.userId) ||
      !isString(options?.initialState?.communityId) ||
      !options?.initialState
    )
      return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const onSubmit = async (option?: {
    localUserId?: string;
    localCommId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    invite?: InviteState;
  }): Promise<boolean> => {
    try {
      if (loading) return false;
      if (
        (invite?.inviteId && invite.inviteEmail !== viewer?.email && invite.inviteEmail !== option?.email) ||
        (option?.invite?.inviteId &&
          option?.invite?.inviteEmail !== viewer?.email &&
          option?.invite?.inviteEmail !== option?.email)
      )
        return false;
      setLoading(true);
      if (
        (!values?.communityId && !option?.localCommId) ||
        (!values?.userId && !viewer?.objectId && !option?.localUserId)
      ) {
        throw new Error();
      }
      const response = await JoinRequest({
        variables: {
          fields: {
            User: {link: values.userId || viewer?.objectId || option?.localUserId || ''},
            Community: {link: values.communityId || option?.localCommId || ''},
            ...(inviteId
              ? {Invitation: {link: inviteId}}
              : option?.invite?.inviteId
              ? {Invitation: {link: option?.invite?.inviteId}}
              : {}),
          },
        },
      });
      const data = response?.data?.createJoinRequest?.joinRequest?.status;
      const community = response?.data?.createJoinRequest?.joinRequest?.Community;
      if (!data || !community) throw new Error('error:join');

      if (data === JoinRequestStatuses.approved) {
        setCommunity(toStateCommunity(community));
      }
      setSuccess('success:jonToCommunity');
      setError(null);
      const user = response?.data?.createJoinRequest?.joinRequest?.User || viewer;
      if (user) options?.onSuccess?.(user);
    } catch (error) {
      setSuccess(null);
      setError(error);
      options?.onError?.();
      return false;
    } finally {
      setLoading(false);
    }
    return true;
  };

  return {
    onSubmit,
    success,
    loading,
    error,
  };
};

export const useUpdateCommunityAvatar = () => {
  const [upload, otherData] = useMutation<UpdateCommunityResponseType>(UpdateCommunityQuery);
  const setCommunity = useSetRecoilState(currentCommunity);

  const call = async (photo: File | undefined, id: string) => {
    const file = photo ? changeFileName(photo) : photo;
    const Avatar = {
      createAndLink: {
        Owner: {link: id},
        file: {upload: file},
        type: imageType.avatar,
      },
    };

    const response = await upload({
      variables: {
        id,
        fields: {
          Avatar,
        },
      },
    });

    const data = toStateCommunity(response.data?.updateCommunity.community);
    setCommunity(data);
  };

  return {
    call,
    ...otherData,
  };
};

export const useRemoveCommunityAdmin = () => {
  const [mutate, otherData] = useMutation<RemoveCommunityAdminResponse, RemoveCommunityAdminRequest>(
    RemoveCommunityAdmin,
  );
  const setCommunity = useSetRecoilState(currentCommunity);

  const remove = async (id: string): Promise<CommunityAdmin | undefined> => {
    try {
      const data = (await mutate({variables: {id}})).data?.deleteCommunityAdmin.communityAdmin;

      if (data) {
        setCommunity((community) => ({
          ...community,
          Admins: community?.Admins?.filter((a) => a.User?.id !== data.User?.id),
        }));
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  return {...otherData, remove};
};

export const useAccountTransfer = () => {
  const [mutate, otherData] = useMutation<AccountTransferResponse, AccountTransferRequest>(AccountTransfer);
  const {refetchCommunity} = useRefetchCommunity();
  const {refetchPermissions} = useRefetchPermissions();

  const makeOwner = async (adminId: string, communityId: string): Promise<boolean> => {
    try {
      const response = await mutate({variables: {adminId, communityId}});
      if (response.data?.accountTransfer.success) {
        await refetchCommunity();
        await refetchPermissions();
        return response.data.accountTransfer.success ? true : false;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  return {...otherData, makeOwner};
};

export const useGetCommunityAdmin = () => {
  const {refetch} = useQuery<GetCommunityAdminResponse>(GetCommunityAdmin, {skip: true});

  return async (id: string) => {
    const response = await refetch({id});

    return response?.data?.communityAdmins?.edges?.[0]?.node;
  };
};

export const useGetCommunityRequests = (options: {commId?: string; userId?: string}) => {
  const CommData = useQuery<CommunitiesResponseType>(GetCommunityInfoRequests, {
    variables: {
      where: {objectId: {equalTo: options.commId}},
      requestsWhere: {
        User: {have: {id: {equalTo: options.userId}}},
      },
    },
  });
  const requestsData = CommData?.data?.communities?.edges
    ?.filter((edge) => !edge?.node?.JoinRequests?.edges?.some((item) => item?.node?.status === 'rejected'))
    ?.map((el) => toStateCommunity(el.node));
  return {...CommData, data: requestsData};
};

type ReqsResponse = {
  joinRequests: Connection<JoinRequest>;
};
export const useGetCommReqs = (options: {commId?: string; userId?: string; ssr?: boolean}) => {
  const {data, refetch, loading} = useQuery<ReqsResponse>(GetCountJoinRequests, {
    variables: {
      where: {
        AND: [
          {User: {have: {id: {equalTo: options.userId}}}},
          {status: {notEqualTo: JoinRequestStatuses.rejected}},
          {Community: {have: {objectId: {equalTo: options.commId}}}},
        ],
      },
    },
    ssr: options?.ssr,
    skip: !options?.userId,
  });
  const requests = data?.joinRequests?.edges?.map((el) => el?.node);
  return {data: requests, refetch, loading};
};

export type sendEmailType = (email: string, text: string) => Promise<boolean>;

export const useSendManagerInvite = (onSuccess?: () => void) => {
  const [sendInvite] = useMutation<SendManagerInviteResponse, SendManagerInviteRequest>(SendManagerInvite);

  const sendEmail: sendEmailType = async (email: string, text: string) => {
    const response = await sendInvite({
      variables: {
        email,
        text,
      },
    });
    onSuccess?.();
    return response.data?.notifyManager?.ok === null;
  };

  return sendEmail;
};

export const useSendInvite = () => {
  const [sendInvite] = useMutation<SendInviteResponse, SendInviteRequest>(SendInvite);
  const community = useRecoilValue(currentCommunity);
  const {refetchCommunity} = useRefetchCommunity();

  const sendEmail: sendEmailType = async (email: string, text: string) => {
    if (!community?.objectId) return false;

    try {
      const response = await sendInvite({
        variables: {
          email,
          text,
          community: {
            link: community?.objectId,
          },
        },
      });

      refetchCommunity();
      return !!response.data?.createInvitation?.invitation.id;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return sendEmail;
};

interface ResponseJoinRequest {
  joinRequests: Connection<{Community: Community}>;
}

interface ResponseInvitationsRequest {
  invitations: Connection<{Community: Community; invLink: string}>;
}

export const useGetUserPendingCommunities = (listOfJoinedCommunities?: CustomCommunity[], skip?: boolean) => {
  const viewer = useViewer();
  const {data} = useQuery<ResponseJoinRequest>(GetCountJoinRequests, {
    variables: {
      where: {
        AND: [
          {User: {have: {objectId: {equalTo: viewer?.objectId}}}},
          {status: {equalTo: JoinRequestStatuses.pending}},
        ],
      },
    },
    skip: skip || false,
    fetchPolicy: 'network-only',
  });

  const {data: invited} = useQuery<ResponseInvitationsRequest>(GetInvitationRequests, {
    variables: {
      where: {
        AND: [{User: {have: {objectId: {equalTo: viewer?.objectId}}}}, {status: {equalTo: 'active'}}],
      },
    },
    skip: skip || false,
    fetchPolicy: 'network-only',
  });

  const {data: neighborhood} = useQuery<CommunitiesResponseType>(GetShortCommunitiesQuery, {
    variables: {
      where: {
        AND: [
          {alias: {equalTo: viewer?.Location?.name?.replaceAll(' ', '-')?.replaceAll('.', '').toLowerCase()}},
          {type: {equalTo: CommunityTypes.neighbourhood}},
        ],
      } as GQLCommunityWhereInput,
      first: 1,
    },
    skip: !viewer?.Location?.name,
  });
  const joinCommunities: Array<CustomCommunity> =
    data?.joinRequests.edges.map((el) => ({...toStateCommunity(el.node.Community), pendingToJoin: true})) || [];
  const invitedCommunities: Array<CustomCommunity> =
    invited?.invitations.edges.map((el) => ({...toStateCommunity(el.node.Community), invited: el.node?.invLink})) || [];
  const neighborhoodCommunity: CustomCommunity | undefined =
    !listOfJoinedCommunities?.length ||
    listOfJoinedCommunities?.some(
      (item) =>
        item?.alias === viewer?.Location?.name?.replaceAll(' ', '-')?.replaceAll('.', '').toLowerCase() ||
        item?.objectId === neighborhood?.communities?.edges?.[0]?.node?.objectId,
    )
      ? undefined
      : {
          ...toStateCommunity(neighborhood?.communities?.edges?.[0]?.node),
          invited: CommunityTypes.neighbourhood,
        };

  return {
    data: [...joinCommunities, ...invitedCommunities, neighborhoodCommunity]?.filter(
      (item) => item?.objectId,
    ) as Array<CustomCommunity>,
  };
};

export const getCommunityByAlias = (alias?: string | null) => {
  const {data, loading} = useQuery<CommunitiesResponseType>(GetShortCommunityInfo, {
    ssr: true,
    skip: !alias,
    variables: {
      where: {
        alias: {equalTo: alias},
      },
    },
    fetchPolicy: 'cache-first',
  });
  return {data: data?.communities?.edges?.map((el) => toStateCommunity(el.node))[0], loading};
};

export const isInCommunity = (userId?: string) => {
  const [community] = useRecoilState(currentCommunity);

  const {data, loading} = useQuery<UsersResponseType>(GetUsersQuery, {
    variables: {
      where: {
        id: {
          equalTo: userId,
        },
        Communities: {
          have: {
            objectId: {
              equalTo: community?.objectId,
            },
          },
        },
      },
    },
    ssr: true,
    fetchPolicy: 'cache-first',
  });

  return {inCommunity: data?.users ? {exist: !!data?.users?.count, loaded: true} : null, loading};
};

export const isListingInCommunity = (options: {item: Partial<TItem>; commId?: string}) => {
  return (
    !!options.item?.Published?.find((el) => el?.objectId === options?.commId) &&
    !options.item?.AdmHidden?.find((el) => el?.objectId === options?.commId) &&
    !options.item?.isDeleted
  );
};

export const useSelectCommunity = () => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const markSwitched = useMarkCommunitySwitched();
  const reset = useResetPreload();
  const setCurrentCommunity = async (newCommunity?: Partial<TCommunity>) => {
    if (!newCommunity) return;
    setCommunity(newCommunity as CommunityStateType);
    markSwitched();
    reset();
    newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
  };

  return {setCurrentCommunity};
};

export const changeCommunityByAlias = () => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const reset = useResetPreload();

  const setCurrentCommunity = async (
    currentCommId?: string,
    alias?: string,
    onSet?: () => void,
    onFail?: () => void,
    communities?: Partial<TCommunity>[],
  ) => {
    const newCommunity = communities?.find((el) => el.alias === alias);
    if (!newCommunity || currentCommId === newCommunity.objectId) {
      onFail?.();
      return;
    }
    setCommunity(newCommunity as CommunityStateType);
    reset();
    newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
    onSet?.();
  };

  return {setCurrentCommunity};
};

export const useCommunityUpdate = () => {
  const [mutate, otherData] = useMutation<UpdateCommunityResponseType, UpdateCommunityRequestType>(
    UpdateCommunityQuery,
  );

  const update = (variables: UpdateCommunityRequestType) => {
    return mutate({variables});
  };

  return {...otherData, update};
};

export const useRefetchCommunity = () => {
  const [currentComm, setCommunity] = useRecoilState(currentCommunity);
  const [getCommunity, {data}] = useLazyQuery<GetCommunityResType, GetCommunityReqType>(GetCommunityRequest);

  useEffect(() => {
    if (!data) return;
    const community = toStateCommunity(data.community);
    setCommunity(community);
  }, [data]);

  const refetchCommunity = async () => {
    if (!currentComm?.id) return;
    getCommunity({
      variables: {
        id: currentComm.id,
      },
    });
  };

  return {refetchCommunity};
};

export const useHaveOwnCommunity = () => {
  const id = useViewerId('objectId');
  const communities = useCommunities({where: {Owner: {have: {objectId: {equalTo: id}}}}});
  return !!communities.data.length;
};

export const useGetActualCommunityList = (short?: boolean) => {
  const id = useViewerId('objectId');
  const {data} = useGetListCommunities(id, 100, short);
  const communities = useRecoilValue(userCommunities);

  // return data ? (data as Array<TCommunity>) : communities;
  return data ? (data as unknown as Array<TCommunity>) : communities;
};

export type CommunityAllowsT = {
  allow: boolean;
  loading: boolean;
};

type useGetMessagesAllowsProps = {
  directUserId?: string;
};

export const useGetMessagesAllows = ({directUserId}: useGetMessagesAllowsProps) => {
  const selfType = useGetTypeUser();
  const selfId = useViewerId('objectId');
  const [currentComm] = useRecoilState(currentCommunity);
  const getAllow = currentComm?.allowChat || false;

  const directType = useCheckUserType(directUserId);
  const haveMessages = useCheckUserHasMessages(selfId, directUserId, getAllow);
  const managerOrAdmin =
    selfType === TypeCommunity.manager ||
    selfType === TypeCommunity.admin ||
    directType === TypeCommunity.manager ||
    directType === TypeCommunity.admin;

  return {allow: managerOrAdmin || haveMessages || getAllow, loading: false};
};

export const useGetPostsAllows = () => {
  const selfType = useGetTypeUser();
  const getAllow = false;
  const managerOrAdmin = selfType === TypeCommunity.manager || selfType === TypeCommunity.admin;

  return {allow: managerOrAdmin || getAllow, loading: false};
};

export const useGetActualCommunity = () => {
  const community = useRecoilValue(currentCommunity);
  const {data} = useGetCommunity({id: community?.id});

  return data || community;
};

export const isInJoinRequests = (userId?: string) => {
  const [community] = useRecoilState(currentCommunity);
  const requestsWhere: GQLJoinRequestWhereInput = {
    User: {have: {objectId: {equalTo: userId}}},
  };

  const {data, loading} = useQuery<CommunitiesResponseType>(GetCommunityInfoRequests, {
    variables: {
      where: {objectId: {equalTo: community?.objectId}},
      requestsWhere,
    },
  });
  return {
    exist: !!data?.communities?.edges?.[0]?.node?.JoinRequests?.edges?.[0]?.node?.User?.objectId,
    loading: data === undefined && loading,
  };
};

export const useSetAutoAddedCommunity = (aliasedId?: string, autoAddKey?: string) => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const setTypeUser = useSetRecoilState(typeUser);
  const {data} = useCommunities({where: {objectId: {equalTo: autoAddKey}}, skip: !autoAddKey});

  const setAutoAddedComm = () => {
    if (aliasedId !== autoAddKey) return;
    if (data?.[0]) {
      setTypeUser(TypeCommunity.resident);
      setCommunity(data[0]);
    }
  };
  return {setAutoAddedComm, loaded: !!data?.[0]?.objectId};
};

export const useSearchCommunities = (options?: {
  where?: GQLCommunityWhereInput;
  requestsWhere?: GQLJoinRequestWhereInput;
  order?: GQLCommunityOrder;
  skip?: boolean;
  first?: number;
  userId?: string;
}) => {
  const {data, ...response} = useQuery<CommunitiesResponseType>(GetCommunitiesQuery, {
    variables: {
      ...(options?.where ? {where: options.where} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {first: 15}),
      userId: options?.userId,
    },
    ssr: false,
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
  return {
    data:
      data?.communities?.edges
        ?.filter((edge) => !edge?.node?.JoinRequests?.edges?.some((item) => item?.node?.status === 'rejected'))
        ?.map((edge) => toStateCommunity(edge.node)) || [],
    infoData: data,
    ...response,
  };
};

export const useLeaveCommunity = (onSuccess?: () => void) => {
  const user = useViewer();
  const setCommunities = useSetRecoilState(userCommunities);
  const setCommunity = useSetRecoilState(currentCommunity);

  const updateUser = async () => {
    try {
      if (!user?.objectId) return false;
      const {community, communities} = await getCurrentCommunity(
        user?.objectId,
        client,
        false,
        user?.lastVisitedCommunity,
      );
      if (!community) {
        setCommunity(null);
        return;
      }
      setCommunities(communities?.map(toStateCommunity));
      setCommunity(toStateCommunity(community));
    } catch (e) {
      console.error(e);
    }
  };

  const [leave] = useMutation<leaveCommunityRes, leaveCommunityParams>(leaveCommunity);
  const onLeave = async (id?: string) => {
    if (!id) return;
    try {
      const res = await leave({variables: {communityId: id}});
      if (res) {
        onSuccess?.();
        await updateUser();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return {onLeave};
};

export const useGetMenuCommunities = (
  featuredList: Array<CustomCommunity>,
  viewerId?: string,
  first?: number,
  communityCount?: number,
) => {
  const previousCursor = useRef<string | null>(null);
  const blocksToShow = first || 11;
  const {data, loading, fetchMore, infoData, refetch} = useCommunitiesShort({
    skip: !viewerId,
    where: {
      OR: [
        {[CommunityUsers.Owner]: {have: {id: {equalTo: viewerId}}}},
        {[CommunityUsers.Residents]: {have: {id: {equalTo: viewerId}}}},
      ],
    },
    first: blocksToShow,
  });

  useEffect(() => {
    if (communityCount)
      refetch({
        where: {
          OR: [
            {[CommunityUsers.Owner]: {have: {id: {equalTo: viewerId}}}},
            {[CommunityUsers.Residents]: {have: {id: {equalTo: viewerId}}}},
          ],
        },
        first: blocksToShow,
      });
  }, [communityCount]);

  const fetch = async () => {
    const pageInfoItems = infoData?.communities?.pageInfo;
    if (!pageInfoItems || !viewerId) return;
    const {hasNextPage, endCursor} = pageInfoItems;

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.communities?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.communities.edges;
          const nextChunk = fetchMoreResult.communities.edges;

          return {
            communities: {
              ...fetchMoreResult.communities,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: [...featuredList, ...data],
    hasMore: !!infoData?.communities?.pageInfo.hasNextPage,
    loading,
    loadMore: fetch,
  };
};

export const useSetCommunityIfNotSetted = (viewerId?: string, currentCommId?: string, commLoading?: boolean) => {
  const setCommunity = useSetRecoilState(currentCommunity);
  const {data: listOfJoinedCommunities} = useGetMenuCommunities([], viewerId, 1);
  const {push} = useHistory();
  const {getLink} = useLinks();

  const setCurrentCommunity = async (id: string) => {
    const newCommunity = listOfJoinedCommunities.find((el) => el.objectId === id);
    if (!newCommunity) return;
    setCommunity(newCommunity as CommunityStateType);
    newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
    push(getLink(route.loop.get(), newCommunity.alias));
  };

  useEffect(() => {
    if (!currentCommId && listOfJoinedCommunities?.length && !commLoading) {
      const firstCommId = listOfJoinedCommunities?.[0]?.objectId;
      if (firstCommId) setCurrentCommunity(firstCommId);
    }
  }, [currentCommId, listOfJoinedCommunities?.length, commLoading]);
};

export const useGetCommunityManagingInfo = (viewerId?: string) => {
  const {data: dataOwner} = useQuery<CommunitiesResponseType>(GetCommunitiesCount, {
    variables: {
      skip: !viewerId,
      where: {[CommunityUsers.Owner]: {have: {id: {equalTo: viewerId}}}},
    },
  });
  const {data: dataAdmin} = useQuery<CommunitiesResponseType>(GetCommunitiesCount, {
    variables: {
      skip: !viewerId,
      where: {Admins: {have: {User: {have: {id: {equalTo: viewerId}}}}}},
      first: 2,
    },
  });

  return {owner: dataOwner?.communities?.count, admin: dataAdmin?.communities.count};
};

export const useIsInAliasedCommunity = (viewerId?: string, alias?: string) => {
  const {data, loading, refetch} = useQuery<CommunitiesResponseType>(GetCommunitiesCount, {
    skip: !viewerId,
    variables: {
      where: {
        AND: [
          {
            OR: [
              {[CommunityUsers.Owner]: {have: {objectId: {equalTo: viewerId}}}},
              {[CommunityUsers.Residents]: {have: {objectId: {equalTo: viewerId}}}},
            ],
          },
          {alias: {equalTo: alias}},
        ],
      },
    },
  });
  useEffect(() => {
    if (alias && viewerId) {
      refetch();
    }
  }, [viewerId, alias]);
  return {data, loading};
};

export const getCommunityAlias = (id?: string) => {
  const {data, loading} = useQuery<GetCommunityResType>(GetCommunityAlias, {
    skip: !id,
    variables: {
      skip: !id,
      id: id || '',
    },
  });
  return {alias: data?.community?.alias, loading};
};

export const useGetCommunityUsers = (id?: string, first?: number) => {
  const limit = (first || 500) + 30;
  const {data, refetch} = useQuery<GetCommunityMembersResType>(GetCommunityUsersShort, {
    variables: {
      id,
      first: limit,
    },
    skip: !id,
  });
  return {users: data?.community?.Residents?.edges?.map((el) => el?.node), refetch};
};

export const useGetOneCommunity = () => {
  const {data, loading, refetch} = useQuery<CommunitiesResponseType>(GetCommunitiesQuery, {
    variables: {
      first: 0,
    },
  });
  const fetch = async (id: string) => {
    const where: GQLCommunityWhereInput = {
      objectId: {
        equalTo: id,
      },
    };
    const res = await refetch({first: 1, where});
    const community = res?.data?.communities?.edges?.[0]?.node;
    return toStateCommunity(community);
  };
  return {fetch, data, loading};
};

export interface OnlineUser {
  objectId: string;
}

export enum OnlineUserFields {
  objectId = 'objectId',
}

export const onlineUsersParseObject = (object: Parse.Object): OnlineUser => {
  return toPOJO<OnlineUser>(Object.values(OnlineUserFields), object, {});
};

export const useGetOnlineUsers = () => {
  const token = getToken();
  const comm = useRecoilValue(currentCommunity);
  const setOnlineUsers = useSetRecoilState(onlineUsers);
  const [queryOnline, setQueryOnline] = useState<Parse.Query>();
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return onlineUsersParseObject(object);
    },
    [queryOnline],
  );
  const {data, unsubscribe} = useLiveQuery<OnlineUser>({query: queryOnline as Parse.Query, map: memoMap});
  const commId = comm?.objectId;
  useEffect(() => {
    const fetch = async () => {
      if (!commId || !token) return;
      const users = await new Parse.Query('Community')
        .equalTo('objectId', commId)
        .first({sessionToken: token})
        .then((res) => res?.relation('Residents').query().select('objectId').find({sessionToken: token}));
      const usersWithOwner = [...(users?.map((item) => item?.id) || []), comm?.Owner?.id];
      const query = new Parse.Query('_User').containedIn('objectId', usersWithOwner).equalTo('isOnline', true);
      setQueryOnline(query);
    };
    fetch();
  }, [commId]);
  const online_ids = Object.values(data || {})?.map((el) => el?.objectId) || [];
  useEffect(() => {
    setOnlineUsers(online_ids);
  }, [online_ids?.length]);
  useEffect(() => {
    if (!token) unsubscribe();
  }, [token]);
};
