import React from 'react';
import {CommunitiesSkeletonWrapper, ProfileIntroSkeletonContent} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const CommunitiesMenuSkeleton: React.FC = () => {
  return (
    <CommunitiesSkeletonWrapper>
      <ProfileIntroSkeletonContent>
        <MemoLoadingSkeleton width={168} height={19} />
        <MemoLoadingSkeleton width={94} height={16} />
      </ProfileIntroSkeletonContent>
    </CommunitiesSkeletonWrapper>
  );
};

export const CommunitiesMenuSkeletonSection: React.FC = () => {
  return (
    <>
      <CommunitiesMenuSkeleton />
      <CommunitiesMenuSkeleton />
      <CommunitiesMenuSkeleton />
    </>
  );
};
