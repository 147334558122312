import styled from 'styled-components';
import {IconSvg as UiIconSvg} from '../../Icon/Svg';
import {Media, theme} from '../../theme';
import {Text} from '../../Typography/Text';

export const IconSvg = styled(UiIconSvg)`
  flex-shrink: 0;
  margin-right: 6px;
  ${Media.down.xs} {
    width: 13px;
    height: 13px;
  }
`;
export const LabelWrapper = styled.div<{width?: number; $disabled?: boolean}>`
  display: flex;
  align-items: center;
  ${({width}) => width && `width: ${width}px;`};
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;

    ${Media.down.m} {
      height: 16px;
      width: 16px;
    }
  }
  ${({$disabled}) =>
    $disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const LabelText = styled(Text)`
  color: ${({theme}) => theme.palette.grayscale._13};
  line-height: 150%;
  font-size: 12px;
  ${theme.font.primary.regular};

  ${Media.down.m} {
    font-size: 14px;
  }
`;
