import React from 'react';
import {
  RadioInputContainer,
  HiddenRadio,
  StyledRadio,
  FilledCircle,
  RadioText,
  FilledSquare,
  StyledCheck,
  HiddenCheck,
} from './styles';
import {RadioProps} from './types';

export const RadioInput: React.FC<RadioProps> = ({onChange, text, name, chosen, id, value, checked}) => {
  return (
    <RadioInputContainer className={chosen === id ? 'custom-radio--chosen' : ''}>
      <HiddenRadio onChange={onChange} name={name} id={id} value={value} checked={checked || false} />
      <StyledRadio className={'custom-radio__icon'}>
        <FilledCircle />
      </StyledRadio>
      <RadioText>{text}</RadioText>
    </RadioInputContainer>
  );
};

export const CheckInput: React.FC<RadioProps> = ({onChange, text, chosen, id, value, checked}) => {
  return (
    <RadioInputContainer className={chosen === id ? 'custom-radio--chosen' : ''}>
      <HiddenCheck onChange={onChange} id={id} value={value} checked={checked || false} />
      <StyledCheck className={'custom-radio__icon'}>
        <FilledSquare />
      </StyledCheck>
      <RadioText>{text}</RadioText>
    </RadioInputContainer>
  );
};
