import React from 'react';
import {useTranslation} from 'react-i18next';

import defaultAvatar from './images/defaultAvatar.png';
import {
  InfoListing,
  InnerInfo,
  InnerReviewerAvatar,
  InnerReviewerCard,
  InnerWithDelimiter,
  ReviewerDescr,
  ReviewerName,
} from './styles';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {IconSvg} from '../../ui-kit/Icon/Svg';

type TProps = {
  avatar?: string;
  name?: string;
  descr?: string;
  rating?: number;
  createAt?: Date;
  isOnline?: boolean;
};

const ReviewerCard: React.FC<TProps> = ({avatar, name, descr, rating, createAt, isOnline}) => {
  const {t} = useTranslation();
  return (
    <InnerReviewerCard>
      <InnerReviewerAvatar>
        <Avatar src={avatar || defaultAvatar} size={'lg'} $isOnline={isOnline} />
      </InnerReviewerAvatar>
      <ReviewerName>{name}</ReviewerName>
      <InnerInfo>
        {rating ? (
          <InnerWithDelimiter>
            <IconSvg type={'star'} fill={'orange'} stroke={'orange'} width={'9px'} height={'9px'} />
            <span>
              {rating || 0} {t('listings:view.stars')}
            </span>
          </InnerWithDelimiter>
        ) : (
          <></>
        )}
        <InfoListing>{createAt && t('common:dateMMMM_yyyy', {date: new Date(createAt)})}</InfoListing>
      </InnerInfo>
      <ReviewerDescr>{descr}</ReviewerDescr>
    </InnerReviewerCard>
  );
};

const MemoReviewerCard = React.memo(ReviewerCard);
export {MemoReviewerCard};
