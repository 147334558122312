import styled from 'styled-components';
import {fontPrimaryReg} from '../../../common/styles';
import {Link} from 'react-router-dom';
import {Media} from '../../../../ui-kit/theme';

export const MenuContainer = styled.div`
  width: 160px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  right: 40px;
  top: 60px;
  z-index: 1010;
`;

export const CommunityMenuContainer = styled.div`
  width: 330px;
  overflow-y: auto;
  max-height: 80vh;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  left: 40px;
  top: 60px;
  z-index: 1010;
  padding: 15px;
`;

export const UserMenuContainer = styled.div`
  width: 220px;
  overflow-y: auto;
  max-height: 45vh;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  left: 40px;
  top: 60px;
  z-index: 1010;
  padding: 10px;
  ${Media.down.m} {
    max-height: 40vh;
  }
`;

export const EventMenuContainer = styled.div`
  width: 260px;
  overflow-y: auto;
  max-height: 45vh;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  left: 40px;
  top: 60px;
  z-index: 1010;
  padding: 5px 10px;
  ${Media.down.m} {
    max-height: 40vh;
  }
`;

export const CommunitiesMenuListWrapper = styled.div`
  padding-right: 4px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.grayscale._2};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.grayscale._4};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.grayscale._5};
  }
`;

export const UserMenuListWrapper = styled.div`
  padding-right: 4px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.grayscale._2};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.grayscale._4};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.grayscale._5};
  }
`;

export const EventMenuListWrapper = styled.div`
  padding-right: 4px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.grayscale._2};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.grayscale._4};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.grayscale._5};
  }
`;

export const BorderLine = styled.div`
  width: 98%;
  margin: 0 auto;
  height: 1px;
  background-color: ${({theme}) => theme.palette.grayscale._3};
`;

export const MenuLink = styled(Link)`
  width: 100%;
  padding: 11px 16px;
  font-size: 14px;
  display: block;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._5};
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
`;

export const MenuLinkButton = styled.button`
  width: 100%;
  padding: 11px 16px;
  font-size: 14px;
  display: block;
  border: none;
  background-color: transparent;
  ${fontPrimaryReg};
  text-align: left;
  cursor: pointer;
  color: ${({theme}) => theme.palette.grayscale._5};
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
`;

export const AddMenuContainer = styled.div`
  ${Media.down.s} {
    display: none;
  }
  min-width: 350px;
  padding: 5px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: absolute;
  right: 190px;
  top: 60px;
  z-index: 1010;
  & > a {
    border-bottom: 0.5px solid #e0e0e0;
  }
  & > a:last-child {
    border-bottom: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const AddMenuLink = styled(Link)`
  ${Media.down.s} {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._5};
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
`;

export const MobileAddMenuContainer = styled.div`
  ${Media.up.s} {
    display: none;
  }
  margin-bottom: 180px;
  padding: 5px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 14px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  width: 90%;
  z-index: 101;
  & > a {
    border-bottom: 0.5px solid #e0e0e0;
  }
  & > a:last-child {
    border-bottom: none;
  }
`;

export const MobileAddMenuLink = styled(Link)`
  ${Media.up.s} {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._5};
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
`;
