import React from 'react';
import {PostListingSlider, PostListingSliderWrapper} from './styles';
import Slider from 'react-slick';
import {IconSvg} from '../../../../../ui-kit/Icon/Svg';

type arrowProps = {
  className?: string;
  onClick?: () => void;
};

const SampleNextArrow = ({className, onClick}: arrowProps) => {
  return (
    <div className={className} onClick={onClick}>
      <IconSvg type={'arrow-left'} />
    </div>
  );
};

const SamplePrevArrow = ({className, onClick}: arrowProps) => {
  return (
    <div className={className} onClick={onClick}>
      <IconSvg type={'arrow-left'} />
    </div>
  );
};

type propsT = {
  margin?: number;
  isListings?: boolean;
};

export const ListingSlider: React.FC<propsT> = ({children, isListings}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchThreshold: 100,
    draggable: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <PostListingSlider $isListings={isListings}>
      <PostListingSliderWrapper $isListings={isListings}>
        <Slider {...settings}>{children}</Slider>
        <Slider {...settings} slidesToShow={1} className={'listing-slider__mobile'}>
          {children}
        </Slider>
      </PostListingSliderWrapper>
    </PostListingSlider>
  );
};
