import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {PaymentDetails} from '../../../../components/Account/Create/Forms/PaymentDetails';
import {LottieLoader} from '../../../../components/Loader/LottieLoader';
import {Steps} from './Steps';
import {route} from '../../../../constants/routes';
import {getRouteStepCreate} from '../../../../helpers/account';
import {getOptionsLocation} from '../../../../helpers/locations';
import {getPlan, isPaymentPlanSelected} from '../../../../helpers/payment';
import {equalToQuery} from '../../../../helpers/query';
import {isProfileCreated} from '../../../../helpers/user';
import {currentCommunity} from '../../../../states/community';
import {TypeCommunity} from '../../../../types/auth';
import {PaymentPeriodType} from '../../../../types/payment';

import {useLinks} from '../../../../hooks/common';
import {useCommunity} from '../../../../hooks/community';
import {useGetCurrencyValue} from '../../../../hooks/currency';
import {useGetLocations} from '../../../../hooks/locations';
import {useAddCreditCard, usePaymentPlans, useViewerCards} from '../../../../hooks/payments';
import {useViewer} from '../../../../hooks/user';

const CreatePaymentDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const viewer = useViewer();
  const {push} = useHistory();
  const {data: firstCommunity, loading: loadingCmnt} = useCommunity(viewer?.id, TypeCommunity.manager);
  const [currComm] = useRecoilState(currentCommunity);
  const community = currComm?.objectId ? firstCommunity : currComm;
  const {countries} = useGetLocations();
  const {cards, refetch: refetchCards, activeCard, loading: loadingCards} = useViewerCards(viewer?.id);
  const isProfile = isProfileCreated(viewer);
  const isPlan = isPaymentPlanSelected(community);
  const options = getOptionsLocation(countries);
  const {getCodeValue, currency, getSignValue} = useGetCurrencyValue();
  const {data: paymentPlans} = usePaymentPlans({
    where: {AND: [{Currency: {have: equalToQuery('objectId', currency?.objectId || '')}}, {isPublic: {equalTo: true}}]},
  });

  const paymentPlan = getPlan(
    community?.Subscr?.PaymentPlan || paymentPlans?.[2],
    (community?.Subscr?.paymentPeriod as PaymentPeriodType) || PaymentPeriodType.year,
  );
  const {getLink} = useLinks();

  useEffect(() => {
    if (!viewer || loadingCmnt || loadingCards) {
      return setLoading(true);
    }
    return setLoading(false);
  }, [viewer, loadingCmnt, loadingCards]);

  useEffect(() => {
    if (!viewer || !community) return;

    const successPath = getLink(
      getRouteStepCreate({
        isProfile,
        isPlan,
      }),
    );
    successPath && push(successPath);
  }, [viewer, community]);

  const cardData = useAddCreditCard({
    initialState: {country: options[0]?.label},
    onSuccess: () => {
      push(getLink(route.createCommunity.path));
    },
  });

  if (loading && !cards) return <LottieLoader $isVisible={loading} allScreen={true} />;
  return (
    <Steps step={'paymentInfo'}>
      <PaymentDetails
        cards={cards}
        refetchCards={refetchCards}
        cardData={cardData}
        paymentPlan={paymentPlan}
        options={options}
        activeCardId={activeCard}
        paymentPeriod={community?.Subscr?.paymentPeriod}
        getCodeValue={getCodeValue}
        getSignValue={getSignValue}
        currency={currency?.code || ''}
      />
    </Steps>
  );
};

export default CreatePaymentDetails;
