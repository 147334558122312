import React, {useEffect} from 'react';
import {registerLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';

export const DatePickerLanguageProvider: React.FC = ({children}) => {
  useEffect(() => {
    registerLocale('ru', ru);
  }, []);

  return <>{children}</>;
};
