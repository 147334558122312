import React, {useState} from 'react';
import {NotificationsEventTypes, NotificationStatus, NotificationsType} from '../../../types/notifications';
import {EntryWrapper, NotificationContent, NotificationText, TextWrapper as TextWrapperLink} from './styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {Text} from '../../../ui-kit/Typography/Text';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {useHistory} from 'react-router-dom';
import {useNotificationTranslation} from '../../../hooks/notifications';
import {useTranslation} from 'react-i18next';
import {route} from '../../../constants/routes';
import {Link} from '../../../ui-kit/Typography/Link';
import {formatNotificationLink, engagementNotifications} from '../../../helpers/notifications';
import {useLinks} from '../../../hooks/common';
import {getResize} from '../../../helpers/file';
import {typeUser} from '../../../states/typeUser';
import {useRecoilState} from 'recoil';
import HangehIcon from '../../../ui-kit/Avatar/assets/hangeh_icon.png';

interface NotificationProps {
  notification: NotificationsType;
  archiveNotification: (id: string) => Promise<void>;
  close: () => void;
  setNotificationSeen: (s: string, id: string) => void;
}

const TextWrapper: React.FC<{to: string; onClick: () => void}> = ({to, onClick, ...props}) => {
  return !Boolean(to) ? (
    <TextWrapperLink {...props} />
  ) : (
    <TextWrapperLink to={to} onClick={onClick} as={Link} {...props} />
  );
};
export const Notification: React.FC<NotificationProps> = ({
  notification,
  archiveNotification,
  close: hide,
  setNotificationSeen,
}) => {
  const {t} = useTranslation();
  const {push, location, go} = useHistory();
  const [userType] = useRecoilState(typeUser);
  const translateNotification = useNotificationTranslation(userType);
  const [archived, setArchived] = useState<boolean>(false);
  const {getLink} = useLinks();
  if (archived) return null;

  const handleRedirectProfile = (id: string) => () => push(getLink(route.profile.get({id})));
  const handleArchive = () => archiveNotification(notification.id ?? '').then(() => setArchived(true));
  const to = getLink(
    formatNotificationLink({
      event: notification.event as NotificationsEventTypes,
      data: notification.data,
      from: notification.from,
      to: notification.to,
    }),
  );
  const handleOnClick = () => {
    if (to) {
      notification.id && setNotificationSeen(notification.status, notification.id);
      if (location.pathname === to) {
        go(0);
      }
      hide();
    }
  };
  const avatar = engagementNotifications.includes(notification.event)
    ? HangehIcon
    : notification.event === NotificationsEventTypes.newManagerAdminPost && notification?.data?.creator === 'manager'
    ? getResize(notification.Community?.avatar, 'md')
    : notification?.data?.creator === 'community'
    ? getResize(notification.Community?.avatar, 'md')
    : getResize(notification.from?.Avatar?.file?.url, 'md');
  return (
    <EntryWrapper seen={notification.status === NotificationStatus.Seen}>
      <NotificationContent>
        <div onClick={handleRedirectProfile(notification.from?.objectId)}>
          <Avatar
            src={
              notification?.data?.listingImg || notification?.data?.eventImg || notification?.data?.amenityImg || avatar
            }
            size={'xxs'}
          />
        </div>
        <TextWrapper to={to} onClick={handleOnClick}>
          <NotificationText>{translateNotification(notification)}</NotificationText>
          <Text variant={'primary'} size={11} color={'septenary'}>
            {notification.createdAt && t('utils:distance', {date: new Date(notification.createdAt)})} {t('common:ago')}
          </Text>
        </TextWrapper>
        <div onClick={handleArchive}>
          <IconSvg type={'close'} width={'24'} height={'24'} />
        </div>
      </NotificationContent>
    </EntryWrapper>
  );
};
