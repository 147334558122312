import React from 'react';
import {Icon, InnerCard, InnerNumber, RemoveCardBtn, Title, WrapperCard, TextLabelActive} from './styles';
import {useTranslation} from 'react-i18next';
import {TCreditCard} from '../../../queries/types/payments';

export type TPaymentCard = {
  width?: string;
  card: TCreditCard;
  onRemove: () => void;
  isActive?: boolean;
  switchCard?: () => void;
  isSwitching?: boolean;
};

export const PaymentCard: React.FC<TPaymentCard> = ({width, card, isActive, onRemove, switchCard, isSwitching}) => {
  const {t} = useTranslation();

  const {last4, brand} = card;

  return (
    <WrapperCard>
      <InnerCard width={width}>
        <Title>{t('common:payment.titleCard')}</Title>
        <InnerNumber>
          {brand} ****{last4}
        </InnerNumber>
        <Icon type={'credit-card'} />
      </InnerCard>
      <>
        {isActive ? (
          <TextLabelActive $inActive={isSwitching}>{t('common:payment.active')}</TextLabelActive>
        ) : (
          <RemoveCardBtn onClick={switchCard} $inActive={isSwitching}>
            {t('common:payment.btnSwitchCard')}
          </RemoveCardBtn>
        )}
      </>
      <RemoveCardBtn onClick={onRemove}>{t('common:payment.btnRemoveCard')}</RemoveCardBtn>
    </WrapperCard>
  );
};
