import React from 'react';

import {ApolloClient, ApolloProvider, from, NormalizedCacheObject} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {GraphQLError} from 'graphql';

import {API_KEY, APPLICATION_ID, GRAPHQL_URI} from './constants/env';
import {link as authLink} from './libs/auth';
import {createUploadLink} from 'apollo-upload-client';
import {apolloClientCache} from './helpers/apollo';

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
  headers: {
    'X-Parse-Application-Id': APPLICATION_ID,
    'X-Parse-REST-API-Key': API_KEY,
  },
});

const errorLink = onError(({networkError, graphQLErrors}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path}, index) => {
      console.log(`Message: ${message}, Location: ${locations}, Path: ${path}`);
      const err = graphQLErrors[index];

      if (err instanceof GraphQLError) {
        err.message = message.replace('GraphQL error: ', '');
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cache: apolloClientCache.restore(globalThis.__APOLLO_STATE__ || {}),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink, httpLink]),
  ssrForceFetchDelay: 100,
});

const Provider: React.FC = ({children}) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export default Provider;
