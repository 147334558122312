import React, {useState} from 'react';
import {WrapperAuth} from './WrapperAuth';
import {AuthTab, SocialAuth} from '../../types/auth';
import {Text} from '../../ui-kit/Typography/Text';
import {
  ContentJoinInWrapper,
  JoinInTitle,
  JoinInTitleReady,
  NoCodeBtn,
  SubtitleJoinInReady,
  WrapperJoinIn,
  MemberWrapper,
  MembersText,
  JoinInAvatarWrapper,
} from './styles';
import {useTranslation} from 'react-i18next';
import {Input} from '../../ui-kit/Form/Input';
import {Button} from '../../ui-kit/Button/Button';
import {route} from '../../constants/routes';
import {User} from '../../queries/types/user';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {getResize} from '../../helpers/file';
import {ErrorMessage} from '../Settings/components';
interface Props {
  onSearch: (id: string) => Promise<any>;
  communityName: string;
  onJoin: () => Promise<void>;
  members?: User[];
  loadingMembers?: boolean;
  communityAvatar?: string;
}
export const JoinIn: React.FC<Props> = ({onSearch, communityName, onJoin, members, communityAvatar}) => {
  const {t} = useTranslation();
  const [id, setId] = useState<{value: string; error: boolean}>({value: '', error: false});
  const [loading, setLoading] = useState<boolean>(false);
  const [haveCommunity, setHaveCommunity] = useState<boolean>(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHaveCommunity(true);
    setId({value: event?.target?.value?.toUpperCase(), error: false});
  };

  const handleSubmit = async () => {
    if (!id?.value || id?.value?.length < 6) {
      setId({value: id?.value, error: true});
      return;
    }
    setLoading(true);
    const result = await onSearch(id.value);
    if (!result) setHaveCommunity(false);
    setLoading(false);
  };

  const handleJoin = async () => {
    setLoading(true);
    await onJoin();
    setLoading(false);
  };

  return (
    <WrapperAuth isStart={true} hideImage={communityName ? true : false} tab={AuthTab.joinIn}>
      <WrapperJoinIn>
        <ContentJoinInWrapper>
          {communityName ? (
            <>
              {communityAvatar && (
                <JoinInAvatarWrapper>
                  <Avatar src={communityAvatar} />
                </JoinInAvatarWrapper>
              )}
              <JoinInTitleReady>{communityName}</JoinInTitleReady>
            </>
          ) : (
            <JoinInTitle>{t('auth:joinIn.title')}</JoinInTitle>
          )}
        </ContentJoinInWrapper>
        <ContentJoinInWrapper>
          {communityName ? (
            <SubtitleJoinInReady>{t('auth:joinIn.letsGo')}</SubtitleJoinInReady>
          ) : (
            <Text size={16} variant={'primary'} color={'octonary'}>
              {t('auth:joinIn.subtitle')}
            </Text>
          )}
        </ContentJoinInWrapper>
        {!communityName && (
          <ContentJoinInWrapper>
            <Input
              $isError={Boolean(id.error)}
              name={'address'}
              type={'text'}
              label={t('auth:joinIn.placeholderCode')}
              value={id.value}
              onChange={handleChange}
            />
          </ContentJoinInWrapper>
        )}
        <ContentJoinInWrapper>{!haveCommunity && <ErrorMessage error={'auth:joinIn.error'} />}</ContentJoinInWrapper>
        <Button loading={loading} onClick={communityName ? handleJoin : handleSubmit} variant={'primary'}>
          {communityName ? t('auth:joinIn.buttons.join') : t('auth:joinIn.buttons.confirm')}
        </Button>
        <NoCodeBtn
          to={communityName ? route.social.get({tab: SocialAuth.logIn}) : route.social.get({tab: SocialAuth.signUp})}>
          <Text variant={'primary'} color={'quaternary'} size={14} decoration={'underline'}>
            {communityName ? t('auth:joinIn.haveAccount') : t('auth:joinIn.noCode')}
          </Text>
        </NoCodeBtn>
      </WrapperJoinIn>
      {communityName && members && members?.length >= 3 && (
        <>
          <MemberWrapper>
            {members?.map((mb, index) => (
              <Avatar
                className={'avatar'}
                key={mb?.objectId || index}
                size={'sm'}
                src={getResize(mb?.Avatar?.file?.url, 'lg')}
              />
            ))}
          </MemberWrapper>
          <MembersText>
            {t('community:join.members', {
              name1: members?.[0]?.firstName,
              name2: members?.[1]?.firstName,
              name3: members?.[2]?.firstName,
            })}
          </MembersText>
        </>
      )}
    </WrapperAuth>
  );
};
