import styled from 'styled-components';
import {fontPrimaryMedium, fontPrimaryReg} from '../common/styles';
import {Media} from '../../ui-kit/theme/breakpoints';

export const PageWrapper = styled.div`
  box-sizing: border-box;
  padding: 50px 18px 2px 24px;
  max-width: 440px;
  margin-left: 100px;
  ${Media.down.l} {
    margin-left: 0px;
  }
`;

export const EarnTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 14px;
  ${fontPrimaryReg}
  font-size: 38px;
  color: ${({theme}) => theme.palette.common.aqua};
`;

export const EarnDescription = styled.div`
  font-size: 14px;
  line-height: 150%;
  ${fontPrimaryReg}
`;

export const InviteLinkWrapper = styled.div`
  position: relative;
  background: ${({theme}) => theme.palette.background.primary};
  border: 1px solid ${({theme}) => theme.palette.grayscale._3};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 20px;
`;

export const LinkLabel = styled.div`
  color: ${({theme}) => theme.palette.text.octonary};
  ${fontPrimaryReg};
  background-color: ${({theme}) => theme.palette.background.primary};
  position: absolute;
  left: 10px;
  top: -7px;
  font-size: 11px;
  padding: 2px 1px;
  box-sizing: border-box;
`;

export const InviteLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CopiedLabel = styled.div`
  color: ${({theme}) => theme.palette.text.primary};
  ${fontPrimaryReg};
  background-color: ${({theme}) => theme.palette.background.octonary};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -7px;
  font-size: 11px;
  padding: 3px 6px;
  border-radius: 3px;
  box-sizing: border-box;
  display: none;
  ${Media.down.m} {
    display: block;
  }
`;

export const LinkValue = styled.div`
  ${fontPrimaryReg};
  display: flex;
  font-size: 14px;
  line-height: 150%;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const LinkButton = styled.button<{$mobileOnly?: boolean; $active?: boolean}>`
  ${fontPrimaryReg};
  display: block;
  color: ${({theme, $active}) => ($active ? theme.palette.text.octonary : theme.palette.text.primary)};
  background-color: ${({theme, $active}) => ($active ? theme.palette.common.yellowLight : theme.palette.common.aqua)};
  font-size: 12px;
  line-height: 150%;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  padding: 4px 10px;
  box-sizing: border-box;
  ${({$mobileOnly}) => $mobileOnly && ` display: none;`};
  ${Media.down.l} {
    ${({$mobileOnly}) => ($mobileOnly ? ` display: block` : `display: none`)};
  }
  transition: 0.6s all;
`;

export const SectionTitle = styled.div`
  ${fontPrimaryMedium};
  display: flex;
  color: ${({theme}) => theme.palette.text.octonary};
  margin: 12px 0;
  & > label {
    margin-left: 10px;
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CreditsCard = styled.div`
  background: ${({theme}) => theme.palette.grayscale._20};
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 8px;
  width: 48%;
  padding: 6px 8px;
  box-sizing: border-box;
`;

export const CardTitle = styled.div`
  ${fontPrimaryReg};
  font-size: 12px;
  margin-bottom: 3px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const CardValue = styled.div`
  ${fontPrimaryReg};
  font-size: 20px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const UsersList = styled.div`
  width: 100%;
  .lds-spinner {
    margin: 0 auto;
    display: block;
  }
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 4px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.grayscale._2};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.grayscale._4};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.grayscale._5};
  }
  margin-bottom: 60px;
  min-height: 100px;
`;

export const UsersCardWrapper = styled.div`
  background: ${({theme}) => theme.palette.background.primary};
  border: 0.5px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  padding: 6px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  ${fontPrimaryReg};
  font-size: 12px;
  margin-left: 6px;
  color: ${({theme}) => theme.palette.text.octonary};
`;

export const EmptyImage = styled.img`
  width: 100%;
  ${Media.up.m} {
    display: none;
  }
`;
