import React from 'react';
import {useGetResidentDashboard} from '../../../hooks/dashboard';
import {Dashboard} from '../../../components/Dashboard/Resident';
import {IDashboard} from '../../../components/Dashboard/types';

export const ResidentDashboardContainer: React.FC<IDashboard> = (props) => {
  const {data, loading} = useGetResidentDashboard();

  return <Dashboard {...props} data={data} loading={loading} />;
};
