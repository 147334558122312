import React, {useState} from 'react';
import {Text} from '../../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';
import {LangSettingsForm, LangSettingsInfo, LangSettingsWrapper} from './styles';
import {FormButtons, SettingsEntryAdmin} from '../components';
import {ISettingLangAndCurrency, OptionObj} from '../../../hooks/settings';
import {StyledSelect} from '../Community/styles';

interface IProps {
  langData: ISettingLangAndCurrency;
}

export const SettingsLangAndCurrency: React.FC<IProps> = ({langData}) => {
  const {values, langOptions, change, submit} = langData;

  const [isEditMode, setEditMode] = useState(false);
  const {t} = useTranslation();

  const handleEnableEditMode = () => {
    setEditMode(true);
  };
  const handleDisableEditMode = () => {
    setEditMode(false);
  };
  const handleSubmit = () => {
    submit();
    setEditMode(false);
  };

  const handleChangeLang = (lngs: Array<OptionObj>) => {
    change({
      key: 'lang',
      value: lngs[0] as OptionObj,
    });
  };

  return (
    <LangSettingsWrapper>
      <Text variant={'primary'} size={18} color={'octonary'} weight={'regular'}>
        {t('settings:preference.langAndCurrency.title')}
      </Text>
      {isEditMode ? (
        <>
          <LangSettingsForm>
            <StyledSelect
              name={'lang'}
              label={t('settings:preference.langAndCurrency.langLabel')}
              values={[values.lang]}
              options={langOptions}
              onChange={handleChangeLang}
            />
          </LangSettingsForm>
        </>
      ) : (
        <>
          <LangSettingsInfo>
            <SettingsEntryAdmin label={t('settings:preference.langAndCurrency.langLabel')} value={values.lang?.label} />
            {/*<SettingsEntryAdmin label={t('settings:preference.langAndCurrency.currencyLabel')} />*/}
          </LangSettingsInfo>
        </>
      )}
      <FormButtons
        variant={isEditMode ? 'update' : 'edit'}
        onClick={isEditMode ? handleSubmit : handleEnableEditMode}
        onCancel={handleDisableEditMode}
      />
    </LangSettingsWrapper>
  );
};
