import React from 'react';
import {useHistory} from 'react-router-dom';
import {RewardType} from '../../queries/types/item';
import {route} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useCreateAmenity, useAmenityFormData} from '../../hooks/amenity';
import {AmenityFormAction, BookingTimingField} from '../../types/amenity';
import {AmenityForm} from '../../components/Amenities/Form';

export const CreateAmenityContainer = () => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const textSeo = t('common:seo.amenities.add');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {push} = useHistory();
  const formData = useAmenityFormData({});
  const {currency} = useGetCurrencyValue();

  const data = useCreateAmenity({
    initialState: {
      rewardType: formData?.rewardTypeOptions[0]?.value as RewardType,
      images: formData.dataImages,
      isBookable: true,
      bookingTiming: {
        mon: {[BookingTimingField.available]: true},
        tue: {[BookingTimingField.available]: true},
        wed: {[BookingTimingField.available]: true},
        thu: {[BookingTimingField.available]: true},
        fri: {[BookingTimingField.available]: true},
        sat: {[BookingTimingField.available]: false},
        sun: {[BookingTimingField.available]: false},
      },
    },
    onSuccess: (item) => push(getLink(route.amenityCreated.get({id: item.id ?? ''}))),
    // onSuccess: (item) => handlePublish(item),
  });
  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <AmenityForm action={AmenityFormAction.create} data={data} formData={formData} currSign={currency?.sign || ''} />
    </>
  );
};
