import {gql} from '@apollo/client';

export const LocationFragment = gql`
  fragment LocationFragment on Location {
    id
    objectId
    createdAt
    updatedAt
    name
    type
    code
    __typename
    isDisabled
    City {
      id
      objectId
      name
    }
    State {
      id
      objectId
      name
    }
    Country {
      id
      objectId
      name
    }
  }
`;
