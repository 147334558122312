import {User} from '../queries/types/user';
import {TCommunity} from './community';
import {Group, TTypeOfGroup} from '../queries/types/group';
import {TImage} from './item';
import {Community} from '../queries/types/community';

export type TGroup = {
  objectId?: string;
  createdAt?: Date;
  name?: string;
  description?: string;
  Members?: Partial<User>[];
  isDeleted?: boolean;
  Community?: Partial<TCommunity>;
  MembersCount?: number;
  type?: TTypeOfGroup;
  alias?: string;
  Avatar?: {
    objectId: string;
    mime: string;
    file: {
      name: string;
      url: string;
    };
  };
  Featured?: Community[];
};

export enum GroupFields {
  objectId = 'objectId',
  name = 'name',
  description = 'description',
  Avatar = 'Avatar',
  isDeleted = 'isDeleted',
}

export type TGroupFormDataType = {
  objectId?: string;
  name?: string;
  description?: string;
  Avatar?: File | null;
  isDeleted?: string;
};

type NewGroupFields = {
  Avatar?: TImage;
};

export type GroupFormValues = Omit<Pick<Group, GroupFields>, keyof NewGroupFields> & NewGroupFields;
export type GroupFormField = keyof GroupFormValues;
export type GroupFormValue = GroupFormValues[GroupFormField];
