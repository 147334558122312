import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';

export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 41px;
  position: relative;
  height: 380px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.primary};
  border: 1px solid ${({theme}) => theme.palette.text.hexadecimal};

  ${Media.down.l} {
    flex-direction: column;
    padding: 10px;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 565px;
`;

export const ActionBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    width: initial;
    padding: 0 52px;
  }
`;

export const ImageContainer = styled.img`
  position: absolute;
  right: 35px;

  ${Media.down.l} {
    position: initial;
  }
`;
