import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {TypeCommunity} from '../../types/auth';
import {useViewerCards} from '../../hooks/payments';
import {isProfileCreated} from '../../helpers/user';
import {isPaymentPlanSelected, isViewerCards} from '../../helpers/payment';
import {isCommunityCreate} from '../../helpers/community';
import {getRouteStepCreate} from '../../helpers/account';
import {LoopCommunity} from '../../components/Loop';
import {CommunityStateType} from '../../states/community';
import {User} from '../../queries/types/user';
import {useUpdateCommunityAvatar} from '../../hooks/community';
import {NavContainer} from '../Navigation';
import {useLinks} from '../../hooks/common';
import {EmptyTag} from '../MobileTabs';
import {PermissionsList} from '../../types/settings';

export type LoopManagerContainerProps = {
  viewer: User | null;
  community?: CommunityStateType;
  loading: boolean;
  viewerId?: string;
  hideNav?: boolean;
  permissions?: PermissionsList[];
};

const LoopManagerContainer: React.FC<LoopManagerContainerProps> = ({
  viewer,
  community,
  loading,
  viewerId,
  hideNav,
  permissions,
}) => {
  const {push} = useHistory();
  const {cards} = useViewerCards(viewer?.id);
  const {getLink} = useLinks();
  const isProfile = isProfileCreated(viewer);
  const isPlan = isPaymentPlanSelected(community);
  const isAddCard = isViewerCards(cards);
  const isCommunity = isCommunityCreate(community);

  const {call: updateAvatar, loading: loadingAvatar} = useUpdateCommunityAvatar();

  useEffect(() => {
    if (!viewer || !community || !cards) return;

    const successPath = getLink(
      getRouteStepCreate({
        isProfile,
        isPlan,
        isAddCard,
        isCommunity,
      }),
    );
    successPath && push(successPath);
  }, [viewer, community, cards]);
  const Wrapper = hideNav ? EmptyTag : NavContainer;

  return (
    <Wrapper>
      <LoopCommunity
        user={viewer}
        community={community ?? {}}
        typeUser={TypeCommunity.manager}
        updateAvatar={updateAvatar}
        loading={loading}
        viewerId={viewerId}
        permissions={permissions}
        loadingAvatar={loadingAvatar}
      />
    </Wrapper>
  );
};

export default LoopManagerContainer;
