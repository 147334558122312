import {MenuItemType} from '../../../ui-kit/Menu/types';
import {PostFormValue, PostFormValues} from '../../../types/post';
import {TypeCommunity} from '../../../types/auth';
import {UserPostInfo} from '../../../types/community';
import {FormErrorFields} from '../../../hooks/error';
import {PermissionsList} from '../../../types/settings';
import {MutableRefObject} from 'react';

export enum inputPostType {
  none = 'none',
  message = 'message',
  request = 'request',
  poll = 'poll',
  video = 'video',
  announcement = 'announcement',
  comment = 'comment',
}

export type InputsProps = {
  typeUser?: TypeCommunity;
  inputType?: inputPostType;
  resetType: () => void;
  user?: UserPostInfo;
  handleSubmit?: () => void;
  handleChange?: (next: {name: string; value?: PostFormValue}) => void;
  success?: string | null;
  values?: PostFormValues;
  refetchPosts?: () => void;
  error?: FormErrorFields<PostFormValues>;
  handleChangePostType?: (t: inputPostType) => void;
  isManager: boolean;
  menuOptions: MenuItemType[];
  isEdit?: boolean;
  initialPollOptions?: PollOptions;
  ManagerOrAdmin: boolean;
  permissions?: PermissionsList[];
  hideAttach?: boolean;
  inputRef?: (ref: any) => void;
  pollRef?: MutableRefObject<HTMLInputElement>;
  inputFocus?: () => void;
  loadingGenerate?: boolean;
  generateData?: (type: 'bio' | 'post') => Promise<string>;
  isGroup?: boolean;
};

export type pollIdType = string;

export type PollOptions = {
  [key: string]: {
    text?: string;
  };
};
