import React from 'react';
import {ModalOverlay, ModalWrapper} from './styles';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../../ui-kit/Popup';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';

type PropsT = {
  dark?: boolean;
  active: boolean;
  setActive: (v: boolean) => void;
  wide?: boolean;
  isPortal?: boolean;
};

export const Modal: React.FC<PropsT> = ({children, setActive, active, wide, isPortal}) => {
  const handleClose = () => setActive(false);
  const stop = (e: React.SyntheticEvent) => e.stopPropagation();

  if (!active) return <></>;
  if (isMobile || isPortal)
    return (
      <BasePortal>
        <IsModalProvider>
          <ModalOverlay onClick={handleClose} $isWide={wide}>
            <ModalWrapper onClick={stop} $isWide={wide}>
              {children}
            </ModalWrapper>
          </ModalOverlay>
        </IsModalProvider>
      </BasePortal>
    );
  return (
    <ModalOverlay onClick={handleClose}>
      <ModalWrapper onClick={stop}>{children}</ModalWrapper>
    </ModalOverlay>
  );
};
