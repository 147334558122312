import React from 'react';
import {useTranslation} from 'react-i18next';
import {AttachFileInput, TextBtn, AttachFileLabel} from '../../../Loop/PostCreate/styles';

export type FileLoaderProps = {
  handleChange?: (next: any) => void;
  name?: string;
  dataId?: string;
  btnDisabled?: boolean;
};

export const FileInput = ({name, handleChange, dataId, btnDisabled}: FileLoaderProps) => {
  const {t} = useTranslation();
  const attachBtnText = t('community:loop.buttons.attachFile');

  return (
    <AttachFileLabel disabled={!!btnDisabled}>
      <AttachFileInput
        data-id={dataId}
        accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, image/*"
        type={'file'}
        name={name}
        onChange={handleChange}
      />
      <TextBtn>{attachBtnText}</TextBtn>
    </AttachFileLabel>
  );
};
