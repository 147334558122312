import React from 'react';
import {ttSteps} from './index';
import {
  CloseBtn,
  Image,
  ImageContainer,
  StepsProgressWrapper,
  TipContainer,
  TipPortal,
  TipWrapperEnd,
  TipWrapperExplore,
  TipWrapperHelpDesk,
  TipWrapperHome,
  TipWrapperMessages,
  TipWrapperPeople,
  TipWrapperProfile,
  TipWrapperStart,
  ToolTipButtonNext,
  ToolTipButtonRow,
  ToolTipText,
  ToolTipTitle,
  StepsProgressDot,
  EmptySpace,
  ToolTipTitlePc,
  ToolTipTextPc,
  ToolTipTitleMobile,
  ToolTipTextMobile,
  TipWrapperSwitch,
} from './styles';
import picture from './assets/Run.png';
import workflow from './assets/Workflow.png';
import {useTranslation} from 'react-i18next';
import {
  HighlightExploreEvents,
  HighlightExploreListings,
  HighlightHelpDesk,
  HighlightHome,
  HighlightMessages,
  HighlightPeople,
  HighlightProfile,
  highlightsExists,
  HighlightSwitch,
} from './Highlights';
import {TCommunity} from '../../../types/community';
import {User} from '../../../queries/types/user';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {ttStepsMap} from '../../../helpers/user';

export type ToolTipsStepsT = {
  step: ttSteps;
  nextStep: () => void;
  close: () => void;
  goToEnd: () => void;
  comm: Partial<TCommunity>;
  user: User;
  exList: highlightsExists;
};

export const ToolTipsSteps: React.FC<ToolTipsStepsT> = ({close, step, nextStep, comm, user, exList, goToEnd}) => {
  const Components = {
    [ttSteps.start]: StartStep,
    [ttSteps.home]: HomeStep,
    [ttSteps.people]: PeopleStep,
    [ttSteps.explore]: ExploreStep,
    [ttSteps.messages]: MessagesStep,
    [ttSteps.helpDesk]: HelpDeskStep,
    [ttSteps.profile]: ProfileStep,
    [ttSteps.switch]: SwitchStep,
    [ttSteps.end]: EndStep,
    [ttSteps.none]: NoneStep,
  };
  const Component = Components[step];
  return (
    <TipPortal>
      <Component onClose={close} onNext={nextStep} comm={comm} user={user} exList={exList} goToEnd={goToEnd} />
    </TipPortal>
  );
};

type StepProps = {
  onClose: () => void;
  onNext: () => void;
  comm: Partial<TCommunity>;
  user: User;
  exList: highlightsExists;
  goToEnd: () => void;
};

const StartStep: React.FC<StepProps> = ({onNext, goToEnd}) => {
  const {t} = useTranslation();
  const title = t('common:tips.start.title');
  const text = t('common:tips.start.text');
  const button = t('common:tips.buttons.begin');
  return (
    <TipContainer>
      <TipWrapperStart onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ImageContainer>
          <Image src={picture} />
        </ImageContainer>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <EmptySpace />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperStart>
    </TipContainer>
  );
};
const HomeStep: React.FC<StepProps> = ({onNext, comm, exList, goToEnd}) => {
  const {t} = useTranslation();
  const title = t('common:tips.home.title');
  const text = t('common:tips.home.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      <HighlightHome commName={comm?.name} exList={exList} />
      <TipWrapperHome onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.home} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperHome>
    </TipContainer>
  );
};
const PeopleStep: React.FC<StepProps> = ({onNext, exList, goToEnd}) => {
  const {t} = useTranslation();
  const title = t('common:tips.people.title');
  const text = t('common:tips.people.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      <HighlightPeople exList={exList} />
      <TipWrapperPeople onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.people} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperPeople>
    </TipContainer>
  );
};
const ExploreStep: React.FC<StepProps> = ({onNext, exList, goToEnd}) => {
  const {t} = useTranslation();
  const title = t('common:tips.explore.title');
  const text = t('common:tips.explore.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      {exList?.listings && <HighlightExploreListings exList={exList} />}
      {exList?.events && <HighlightExploreEvents exList={exList} />}
      <TipWrapperExplore onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.explore} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperExplore>
    </TipContainer>
  );
};
const MessagesStep: React.FC<StepProps> = ({onNext, exList, goToEnd}) => {
  const {t} = useTranslation();
  const title = t('common:tips.messages.title');
  const text = t('common:tips.messages.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      <HighlightMessages exList={exList} />
      <TipWrapperMessages onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.messages} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperMessages>
    </TipContainer>
  );
};
const HelpDeskStep: React.FC<StepProps> = ({onNext, exList, goToEnd}) => {
  const {t} = useTranslation();
  const titleMobile = t('common:tips.more.title');
  const titlePc = t('common:tips.helpDesk.title');
  const textPC = t('common:tips.helpDesk.text');
  const textMobile = t('common:tips.more.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      <HighlightHelpDesk exList={exList} />
      <TipWrapperHelpDesk onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitlePc>{titlePc}</ToolTipTitlePc>
        <ToolTipTextPc>{textPC}</ToolTipTextPc>
        <ToolTipTitleMobile>{titleMobile}</ToolTipTitleMobile>
        <ToolTipTextMobile>{textMobile}</ToolTipTextMobile>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.helpDesk} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperHelpDesk>
    </TipContainer>
  );
};
const ProfileStep: React.FC<StepProps> = ({onNext, user, goToEnd, exList}) => {
  const {t} = useTranslation();
  const title = t('common:tips.profile.title');
  const text = t('common:tips.profile.text');
  const button = t('common:tips.buttons.next');
  return (
    <TipContainer>
      <HighlightProfile user={user} />
      <TipWrapperProfile onClick={onNext}>
        <CloseBtn onClick={goToEnd}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <StepsProgress exList={exList} active={ttSteps.profile} />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperProfile>
    </TipContainer>
  );
};

const SwitchStep: React.FC<StepProps> = ({onNext, comm, exList, onClose}) => {
  const {t} = useTranslation();
  const text = t('common:tips.switch.text');
  return (
    <TipContainer>
      <HighlightSwitch commName={comm?.name} exList={exList} />
      <TipWrapperSwitch onClick={onNext}>
        <CloseBtn onClick={onClose}>
          <IconSvg type={'close'} />
        </CloseBtn>
        <ToolTipText>{text}</ToolTipText>
        {/*!user?.tips?.welcomeTipsViewed && (
          <ToolTipButtonRow>
            <StepsProgress exList={exList} active={ttSteps.switch} />
            <ToolTipButtonNext>{button}</ToolTipButtonNext>
          </ToolTipButtonRow>
        )*/}
      </TipWrapperSwitch>
    </TipContainer>
  );
};

const EndStep: React.FC<StepProps> = ({onClose}) => {
  const {t} = useTranslation();
  const title = t('common:tips.end.title');
  const text = t('common:tips.end.text');
  const button = t('common:tips.buttons.close');
  return (
    <TipContainer>
      <TipWrapperEnd onClick={onClose}>
        <ImageContainer>
          <Image src={workflow} />
        </ImageContainer>
        <ToolTipTitle>{title}</ToolTipTitle>
        <ToolTipText>{text}</ToolTipText>
        <ToolTipButtonRow>
          <EmptySpace />
          <ToolTipButtonNext>{button}</ToolTipButtonNext>
        </ToolTipButtonRow>
      </TipWrapperEnd>
    </TipContainer>
  );
};

const NoneStep: React.FC<StepProps> = () => {
  return null;
};

const StepsProgress: React.FC<{exList: highlightsExists; active: ttSteps}> = ({exList, active}) => {
  const activeStep = ttStepsMap?.[active];
  const list = [
    ttStepsMap[ttSteps.home],
    ...(exList?.people ? [ttStepsMap[ttSteps.people]] : []),
    ...(exList?.listings || exList.events ? [ttStepsMap[ttSteps.explore]] : []),
    ttStepsMap[ttSteps.messages],
    ...(exList?.helpDeskAndMore ? [ttStepsMap[ttSteps.helpDesk]] : []),
    ttStepsMap[ttSteps.profile],
    //...(exList?.hasMultiplyCommunity ? [ttStepsMap[ttSteps.switch]] : []),
  ];
  return (
    <StepsProgressWrapper>
      {list.map((el) => (
        <StepsProgressDot $active={el === activeStep} key={el} />
      ))}
    </StepsProgressWrapper>
  );
};
