import React, {useMemo} from 'react';
import {Table} from '../../ui-kit/Table';
import {Columns, ItemType} from '../../ui-kit/Table/types';
import {TableAmenityItem, TAmenity} from '../../types/amenity';
import {useTranslation} from 'react-i18next';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {
  MenuButtonWrapper,
  TableDuration,
  TableMenuItem,
  TableResident,
  TableStatusLabel,
  TableTime,
  TableUnit,
  TableUpdated,
  TableWrapper,
  HiddenMenu,
} from './styles';
import {Label} from '../../ui-kit/Labels';
import {format} from 'date-fns';
import {
  bookingSlotsMap,
  bookingSlotsMapkeys,
  bookingSlotsMapReverse,
  bsDaysMap,
  GetMenuTableAmenitiesFuncOptions,
  GetMenuTableAmenitiesOptions,
  getMobileTableMenuAmenities,
  ordersToAmenityItems,
  statusesColor,
  getMenuTableAmenities,
} from '../../helpers/amenity';
import {route} from '../../constants/routes';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {useLinks} from '../../hooks/common';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {Order} from '../../queries/types/order';
import {TResManageOrder} from '../../hooks/order';
import {GQLOrderStatuses} from '../../graphql.schema';
import {EmptyBookings} from './EmptyState';
import {ContentLoader} from '../../ui-kit/Loader/ContentLoader';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {Media} from '../../ui-kit/theme';
import {getLastName} from '../../helpers/common';

type AmenitysTableProps = {
  orders?: Order[];
  amenity?: Partial<TAmenity>;
  manageOrder?: TResManageOrder;
  loading?: boolean;
};

export const AmenitiesTable: React.FC<AmenitysTableProps> = ({orders, manageOrder, loading}) => {
  const {t} = useTranslation();
  const items: ItemType<TableAmenityItem>[] = ordersToAmenityItems(orders, manageOrder);
  const columns: Columns<TableAmenityItem & {menu?: boolean}> = useMemo(() => {
    return [
      {
        title: t('amenities:table.time'),
        width: '70px',
        render: renderTime,
        dataIndex: 'time',
      },
      {
        title: t('amenities:table.status'),
        width: '90px',
        render: renderStatus,
        dataIndex: 'status',
      },
      {
        title: t('amenities:table.resident'),
        width: '90px',
        render: renderResident,
        dataIndex: 'resident',
      },
      {
        title: t('amenities:table.unit'),
        width: '90px',
        render: renderUnit,
        dataIndex: 'unit',
      },
      {
        title: t('amenities:table.duration'),
        width: '90px',
        render: renderDuration,
        dataIndex: 'duration',
      },
      {
        title: t('amenities:table.bookingDate'),
        width: '90px',
        render: renderBookingDate,
        dataIndex: 'createdAt',
      },
      {
        title: '',
        width: '10px',
        render: renderMenu,
        dataIndex: 'menu',
      },
    ];
  }, [renderMenu, renderBookingDate, renderDuration, renderUnit, renderResident, renderStatus, renderTime]);

  return (
    <TableWrapper>
      <Table variant={'secondary'} items={items} columns={columns} />
      {!items.length && loading && <ContentLoader padding={'50px 0px'} />}
      {!items.length && !loading && <EmptyBookings />}
    </TableWrapper>
  );
};
const renderTime = (amenity: TableAmenityItem) => {
  return <TableTime>{amenity?.time ? format(amenity.time, 'p') : '-'}</TableTime>;
};
const renderResident = (amenity: TableAmenityItem) => {
  return (
    <TableResident>
      {amenity?.resident?.firstName} {getLastName(amenity.resident?.lastName, amenity.resident?.firstLetter)}
    </TableResident>
  );
};
const renderUnit = (amenity: TableAmenityItem) => {
  return <TableUnit>{amenity?.unit}</TableUnit>;
};

const renderDuration = (amenity: TableAmenityItem) => {
  const {t} = useTranslation();
  if (!amenity?.duration) return null;

  const durSymbol = amenity.duration as bookingSlotsMapkeys;
  const durValue = bookingSlotsMapReverse?.[durSymbol];
  const duration = [bookingSlotsMap['1'], bookingSlotsMap['0.5']].includes(durSymbol)
    ? t(`amenities:form.options.${bsDaysMap[durValue]}`)
    : t(`amenities:form.options.mins`, {value: durValue});

  return <TableDuration>{duration}</TableDuration>;
};
const renderStatus = (amenity: TableAmenityItem) => {
  const {t} = useTranslation();
  const type = amenity?.status ? statusesColor?.[amenity.status] : undefined;
  return (
    <TableStatusLabel>
      <Label variant={type}>{t(`amenities:status.${amenity?.status}`)}</Label>
    </TableStatusLabel>
  );
};

const renderMenu = (amenity: TableAmenityItem) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const getMenuTableAmenitiesOptions: GetMenuTableAmenitiesOptions = {
    objectId: amenity?.objectId,
    messageLink: getLink(route.messages.get({contactId: amenity?.resident?.objectId || ''})),
    profileLink: getLink(route.profile.get({id: amenity?.resident?.objectId || '/'})),
    requestLink: getLink(route.messages.get({contactId: amenity?.resident?.objectId || ''})),
    isManager: amenity?.isManager,
    isBooked: amenity?.isBooked,
  };

  const getMenuItemsFunctions: GetMenuTableAmenitiesFuncOptions = {
    t,
    cancelCallBack: () => {
      amenity.manage?.manageOrder({orderId: amenity.orderId, status: GQLOrderStatuses.canceled});
    },
    denyCallBack: () => {
      amenity.manage?.manageOrder({orderId: amenity.orderId, status: GQLOrderStatuses.pending});
    },
    acceptCallBack: () => {
      amenity.manage?.manageOrder({orderId: amenity.orderId, status: GQLOrderStatuses.booked});
    },
  };
  const menuItems: MenuItemType[] = [...getMenuTableAmenities(getMenuTableAmenitiesOptions, getMenuItemsFunctions)];
  const mobileMenuItems: MobileMenuEntry[] = [
    ...getMobileTableMenuAmenities(getMenuTableAmenitiesOptions, getMenuItemsFunctions),
  ];
  const pramenityRedirect = (e: React.SyntheticEvent) => e.preventDefault();

  return (
    <TableMenuItem>
      <MenuButtonWrapper onClick={pramenityRedirect}>
        {!!menuItems.length && (
          <HiddenMenu mq={Media.down.s}>
            <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
          </HiddenMenu>
        )}
        {!!mobileMenuItems.length && (
          <HiddenMenu mq={Media.up.m}>
            <MobileMenuButton entries={mobileMenuItems} />
          </HiddenMenu>
        )}
      </MenuButtonWrapper>
    </TableMenuItem>
  );
};
const renderBookingDate = (amenity: TableAmenityItem) => {
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;

  return <TableUpdated>{amenity?.time && format(amenity?.time, 'dd MMM u', getLanguage())}</TableUpdated>;
};
