import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getResize} from '../../../helpers/file';
import {PhotoCard} from '../../common/PhotoCard/PhotoCard';
import {
  FooterSlider,
  InnerImagesBtns,
  InnerMobileBtns,
  InnerPhoto,
  MobileBtn,
  SliderButton,
  SliderWrapper,
  ViewBtn,
} from './styles';
import {TImage} from '../../../types/item';
import {Media} from '../../../ui-kit/theme';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Item, useGallery} from 'react-photoswipe-gallery';
import {isMobile} from 'react-device-detect';
import {PhotoSlider} from '../../common/PhotoSlider';
import {useHistory, useLocation} from 'react-router-dom';
import {useLinks} from '../../../hooks/common';
import {paths, route} from '../../../constants/routes';
import {useRecoilState, useRecoilValue} from 'recoil';
import {prevLocation, prevPaths} from '../../../states/navState';
import {getAliasFromPath} from '../../../helpers/community';

interface IProps {
  images?: TImage[];
  communityAlias?: string;
  isPreview?: boolean;
  isEdit?: boolean;
  previewLink?: string;
  editLink?: string;
  handleLike?: () => Promise<boolean>;
  isLike?: boolean;
  analyticSend?: () => void;
  isPublicView?: boolean;
}

export const Slider: React.FC<IProps> = ({
  images,
  isPreview,
  isEdit,
  isLike,
  editLink,
  handleLike,
  analyticSend,
  previewLink,
  isPublicView,
}) => {
  const {t} = useTranslation();
  const [isLiked, setIsLiked] = useState(false);
  //const {location} = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [prevpath] = useRecoilState(prevLocation);
  const previousPaths = useRecoilValue(prevPaths);
  const [activeCardId, setActiveCardId] = useState<string | undefined>(images?.[0]?.id);
  const [index, setIndex] = useState<number>(0);
  const titleButton = !isShow ? t('listings:view.buttons.viewAllPhotos') : t(`listings:view.buttons.hidePhotos`);
  const isAllBtn = Number(images?.length) > 3;
  const {open} = useGallery();
  const {push} = useHistory();
  const {pathname} = useLocation();
  const {getLink} = useLinks();
  const handleClick = (id?: string) => () => {
    setActiveCardId(id);
  };

  const handleSetActive = (id: string, index: number) => () => {
    setIndex(index);
    setActiveCardId(id);
  };

  const viewAll = () => {
    setIsShow((prev) => !prev);
  };
  const handleOpenPreview = () => open(index);
  const handleOpenPreviewMob = (ind: number) => () => open(ind);
  // const handleBackNoReactRouter = () => window?.history.back();

  const stuckPages =
    previousPaths?.prev?.includes(paths.eventCreate) ||
    previousPaths?.prev?.includes(paths.eventView) ||
    previousPaths?.prev?.includes(paths.communityList);

  const handleBackNoReactRouter = () => {
    if (isEdit && stuckPages) {
      return push(getLink(prevpath ? prevpath : route.events.path));
    }
    prevpath ? window?.history.back() : push(getLink(route.events.path));
  };
  const goPreview = () => {
    previewLink && push(previewLink);
  };
  const goEdit = () => editLink && push(editLink);
  const onLike = async () => {
    const res = handleLike?.();
    if (!res) return;
    if (!isLiked) analyticSend?.();
    setIsLiked((prev) => {
      return !prev;
    });
  };
  const alias = getAliasFromPath(pathname);
  const goSignUp = () => {
    push(getLink(route.home.path, alias));
  };
  const likeFetched = isLike !== undefined;
  useEffect(() => {
    likeFetched && setIsLiked(!!isLike);
  }, [likeFetched]);
  return (
    <>
      <SliderWrapper>
        <InnerPhoto>
          {!isMobile && (
            <PhotoCard
              aspectRatio={'623:445'}
              src={getResize(images?.find((it) => it.id === activeCardId)?.file?.url, 'lg')}
              onClick={handleOpenPreview}
            />
          )}

          {isMobile && (
            <PhotoSlider>
              {images?.map((img, ind) => (
                <PhotoCard
                  aspectRatio={'623:445'}
                  src={getResize(img?.file?.url, 'lg')}
                  onClick={handleOpenPreviewMob(ind)}
                  key={ind}
                />
              ))}
            </PhotoSlider>
          )}
          <HiddenElement mq={Media.up.m}>
            <InnerMobileBtns>
              {images?.map((img) => (
                <SliderButton key={img.id} isActive={img.id === activeCardId} onClick={handleClick(img.id)} />
              ))}
            </InnerMobileBtns>
          </HiddenElement>
        </InnerPhoto>
        <HiddenElement mq={Media.down.m}>
          <FooterSlider>
            <InnerImagesBtns>
              {images?.map((item, index) => (
                <span key={index}>
                  <Item original={getResize(item?.file?.url, 'lg')} width={'1024'} height={'768'}>
                    {({ref}) => (
                      <img
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={handleSetActive(item?.id || '', index)}
                        src={getResize(item?.file?.url, 'sm')}
                        alt={''}
                      />
                    )}
                  </Item>
                </span>
              ))}
            </InnerImagesBtns>
            {isAllBtn && (
              <ViewBtn type={'button'} onClick={viewAll}>
                {titleButton}
              </ViewBtn>
            )}
          </FooterSlider>
        </HiddenElement>
        <HiddenElement mq={Media.up.m}>
          <ActionButtons
            isPreview={isPreview}
            isEdit={isEdit}
            handleBackNoReactRouter={handleBackNoReactRouter}
            goEdit={goEdit}
            goPreview={goPreview}
            isLiked={isLiked}
            handleLike={onLike}
            goSignUp={goSignUp}
            isPublicView={isPublicView}
          />
        </HiddenElement>
        {/*
        <PreviewImage
          previewImg={popupImg}
          images={images?.map((i) => i.file?.url ?? '') ?? []}
          handleClose={handleClosePreview}
          closeOutside={true}
        />*/}
      </SliderWrapper>
    </>
  );
};

const ActionButtons: React.FC<{
  isPreview?: boolean;
  isEdit?: boolean;
  handleBackNoReactRouter: () => void;
  handleLike?: () => void;
  goEdit?: () => void;
  goPreview?: () => void;
  isLiked?: boolean;
  isPublicView?: boolean;
  goSignUp?: () => void;
}> = ({isPreview, isEdit, handleBackNoReactRouter, goEdit, goPreview, handleLike, isLiked, isPublicView, goSignUp}) => {
  if (isPreview)
    return (
      <>
        <MobileBtn alignValue="60px" $align={'right'} onClick={handleBackNoReactRouter}>
          <IconSvg type={'close'} width={'32'} height={'32'} viewBox={'1 -3 18 26'} />
        </MobileBtn>
        <MobileBtn $align={'right'} onClick={goEdit}>
          <IconSvg type={'pencil'} width={'32'} height={'32'} viewBox={'-4 -3 20 20'} />
        </MobileBtn>
      </>
    );
  if (isEdit)
    return (
      <>
        <MobileBtn $align={'left'} onClick={handleBackNoReactRouter}>
          <IconSvg type={'close'} width={'32'} height={'32'} viewBox={'1 -3 18 26'} />
        </MobileBtn>
        <MobileBtn alignValue="60px" $align={'right'} onClick={goPreview}>
          <IconSvg type={'eye-open'} width={'32'} height={'24'} viewBox={'3 -4 18 24'} />
        </MobileBtn>
        <MobileBtn $align={'right'} onClick={goEdit}>
          <IconSvg type={'pencil'} width={'32'} height={'32'} viewBox={'-4 -3 20 20'} />
        </MobileBtn>
      </>
    );

  return (
    <>
      {isPublicView ? (
        <MobileBtn $align={'left'} onClick={goSignUp}>
          <IconSvg type={'logo-small'} width={'19'} height={'30'} viewBox={'0 0 19 20'} stroke={'none'} />
        </MobileBtn>
      ) : (
        <MobileBtn $align={'left'} onClick={handleBackNoReactRouter}>
          <IconSvg type={'close'} width={'32'} height={'32'} viewBox={'1 -3 18 26'} />
        </MobileBtn>
      )}

      <MobileBtn $align={'right'} onClick={isPublicView ? goSignUp : handleLike}>
        <IconSvg
          type={'shape'}
          width={'32'}
          height={'28'}
          viewBox={'-1 -7 18 24'}
          fill={isLiked ? 'red' : 'none'}
          stroke={isLiked ? 'red' : 'gray'}
        />
      </MobileBtn>
    </>
  );
};
