import React from 'react';
import {Link} from '../../ui-kit/Typography/Link';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {ContainerListingCard, InnerListingCard} from './styles';
import {BookingTimingT} from '../../types/amenity';
import {LabelCard} from './LabelCard';
import {RewardType} from '../../queries/types/event';

interface IProps {
  eventName?: string;
  rewardType?: RewardType;
  bookingTiming?: BookingTimingT;
  to: string;
  src: string;
}

const SimpleAmenityCard: React.FC<IProps> = ({eventName, rewardType, bookingTiming, to, src}) => {
  return (
    <ContainerListingCard>
      <Link to={to}>
        <InnerListingCard>
          <PhotoCard aspectRatio={'300:200'} src={src} />
          <LabelCard name={eventName} rewardType={rewardType} bookingTiming={bookingTiming} />
        </InnerListingCard>
      </Link>
    </ContainerListingCard>
  );
};

const MemoSimpleAmenityCard = React.memo(SimpleAmenityCard);
export {MemoSimpleAmenityCard};
