import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IGetCurrValue} from '../../../../types/common';
import {PaymentPlanAnually, PaymentPlanMonth, PaymentPlanPrice, PaymentPlanType} from '../styles';

type THeaderPlan = {
  name?: string;
  priceMonth?: string | number;
  priceYear?: string | number;
  getCodeValue: IGetCurrValue;
  currency: string;
  getSignValue: IGetCurrValue;
};

export const HeaderPlan: React.FC<THeaderPlan> = ({
  name,
  priceMonth,
  priceYear,
  getCodeValue,
  currency,
  getSignValue,
}) => {
  const {t} = useTranslation();
  const [formattedName, setFormattedName] = useState<string | undefined>('');
  useEffect(() => {
    if (name?.indexOf('_') !== -1) {
      setFormattedName(name?.toUpperCase().replaceAll('_', ' '));
    }
  }, [name]);

  return (
    <>
      <PaymentPlanType>{formattedName ? formattedName : t(`community:plan.type.${name}`)}</PaymentPlanType>
      <div>
        <PaymentPlanPrice>{!priceMonth ? t('community:plan.free') : `${getSignValue(priceMonth)}`}</PaymentPlanPrice>
        {Boolean(priceMonth) && (
          <PaymentPlanMonth>
            {currency} {t('community:plan.month')}
          </PaymentPlanMonth>
        )}
      </div>
      <PaymentPlanAnually>
        {!priceYear
          ? t('community:plan.descr.free')
          : t('community:plan.descr.billed', {text: getCodeValue(priceYear)})}
      </PaymentPlanAnually>
    </>
  );
};
