import {parseJSON} from 'date-fns';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {TDocumentFormat} from '../../types/document';
import {Br} from '../../ui-kit/Typography/Br';
import {Text} from '../../ui-kit/Typography/Text';
import {MemoFileCover} from './FileCover';
import {CardWrapper, DocumentCardTitle, FormatLabel, SubtitleWrapper} from './styles';

interface TProps {
  objectId: string;
  createdAt: Date;
  name: string;
  coverUrl?: string;
  format: TDocumentFormat;
}

const SimpleCardDocument: React.FC<TProps> = ({name, createdAt, coverUrl, objectId, format}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  return (
    <CardWrapper>
      <Link to={getLink(route.documentView.get({id: objectId}))}>
        <MemoFileCover src={coverUrl} format={format} isHaveLabel={false} />
        <Br indent={8} />
        <DocumentCardTitle>{name}</DocumentCardTitle>
      </Link>
      <SubtitleWrapper>
        <FormatLabel>
          <Text size={10} color={'octonary'} case={'uppercase'}>
            {format}
          </Text>
        </FormatLabel>
        <Text size={14} weight={'medium'} color={'octonary'}>
          {createdAt && t('common:datePP', {date: parseJSON(createdAt)})}
        </Text>
      </SubtitleWrapper>
    </CardWrapper>
  );
};

const MemoSimpleCardDocument = React.memo(SimpleCardDocument);
export {MemoSimpleCardDocument};
