import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActionButton,
  ActionLink,
  AsideEdit,
  CardInsight,
  InnerActions,
  InnerEditAside,
  InnerInsights,
  SubTitle,
  TitleInsight,
  ValueInsight,
  WrapperEditFormAside,
} from './styles';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';

type TProps = {
  countViews?: number;
  countClicks?: number;
  countCommunities?: number;
  width?: string;
  onPublish?: () => void;
  onUnpublish?: () => void;
  onDelete?: () => void;
  editPath: string;
  managePublishingPath: string;
  className?: string;
  manyCommunities: boolean;
  isPublished: boolean;
};

export const EditFormAside: React.FC<TProps> = ({
  countViews,
  countClicks,
  countCommunities,
  editPath,
  className,
  managePublishingPath,
  manyCommunities,
  onDelete,
}) => {
  const {t} = useTranslation();

  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const dataInsights = useMemo(() => {
    return [
      {
        title: t('company:localBiz.insights.views'),
        value: countViews || 0,
      },
      {
        title: t('company:localBiz.insights.clicks'),
        value: countClicks || 0,
      },
      {
        title: t('company:localBiz.insights.communities'),
        value: countCommunities || 0,
      },
    ];
  }, [countViews, countClicks, countCommunities]);

  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('company:localBiz.modals.delete.title'),
        okText: t('company:localBiz.modals.delete.okText'),
        cancelText: t('company:localBiz.modals.delete.cancelText'),
      },
    };
  }, []);

  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  return (
    <>
      <InnerEditAside className={className}>
        <AsideEdit>
          <WrapperEditFormAside>
            <div className={'aside__actions-block'}>
              <SubTitle>{t('listings:actions.title')}</SubTitle>
              <InnerActions>
                <ActionLink to={editPath}>{t('company:localBiz.buttonsActions.edit')}</ActionLink>
                {manyCommunities && (
                  <ActionLink to={managePublishingPath || ''}>
                    {t('company:localBiz.buttonsActions.communityList')}
                  </ActionLink>
                )}
                <ActionButton type="button" onClick={handleOpenDelete}>
                  {t('company:localBiz.buttonsActions.delete')}
                </ActionButton>
              </InnerActions>
            </div>
            <div>
              <SubTitle>{t('listings:insights.title')}</SubTitle>
              <InnerInsights>
                {dataInsights?.map((it) => (
                  <CardInsight key={it.title}>
                    <TitleInsight>{it.title}</TitleInsight>
                    <ValueInsight>{it.value}</ValueInsight>
                  </CardInsight>
                ))}
              </InnerInsights>
            </div>
          </WrapperEditFormAside>
        </AsideEdit>
      </InnerEditAside>
      {isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={onDelete}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
    </>
  );
};
