import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {BizCreated} from '../../components/LocalBiz/BizCreated';
import {PlanOptionsValues} from '../../constants/plan';
import {useGetActualCommunityList} from '../../hooks/community';
import {useGetActionsOnCompany, useGetCompany} from '../../hooks/company';
import {Community} from '../../queries/types/community';
import {currentCommunityState, typeUserState, userState} from '../../states';
import {TypeCommunity} from '../../types/auth';
import {commLoading} from '../../types/community';
import {NavContainer} from '../Navigation';

export const ManageCompanyPublishingContainer: React.FC = () => {
  const {id: companyId} = useParams<{id: string}>();

  const {id} = useRecoilValue(userState) || {};
  const community = useRecoilValue(currentCommunityState);
  const typeUser = useRecoilValue(typeUserState);
  const _communities = useGetActualCommunityList();
  const communities = useMemo(
    () =>
      _communities.filter((el) => el.Subscr?.PaymentPlan.options.some((el) => el.value === PlanOptionsValues.company)),
    [_communities],
  );
  const [addCommLoading, setAddCommLoading] = useState<commLoading>(false);
  const [reviewMode, setReviewMode] = useState(false);

  const {data: item, refetch} = useGetCompany(companyId);
  const {unpublishItem, publishItem} = useGetActionsOnCompany(item?.objectId || companyId);

  const handleSubmit = (communityIdsPublish?: Array<string>, communityIdsUnlist?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length ? [publishItem(communityIdsPublish)] : []),
      ...(communityIdsUnlist?.length ? [unpublishItem(communityIdsUnlist)] : []),
    ]).then(() => {
      refetch();
      setReviewMode(true);
      setCommLoading(false);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [communities, community],
  );

  const selectedCommunitiesIds = useMemo(() => {
    return [...((item?.Published || []) as Community[])].map((el) => {
      return el.objectId;
    });
  }, [item]);

  const [publishedList, setPublishedList] = useState<Array<string>>(allCommunitiesIds);

  useEffect(() => {
    setPublishedList(allCommunitiesIds);
  }, [communities?.length]);

  const setCommLoading = (t: commLoading) => setAddCommLoading(t);

  const oneCommunitySubmit = () => {
    setCommLoading?.('one');
    handleSubmit(
      publishedList.filter((communityId) => !selectedCommunitiesIds?.includes(communityId)),
      allCommunitiesIds.filter(
        (communityId) => !publishedList.includes(communityId) && selectedCommunitiesIds?.includes(communityId),
      ),
    );
  };

  useEffect(() => {
    if (communities.length === 1 && item?.objectId) {
      setReviewMode(true);
      oneCommunitySubmit();
    } else setReviewMode(false);
  }, [item?.objectId, communities.length]);

  return (
    <NavContainer>
      <BizCreated
        userId={id as string}
        item={item}
        community={community}
        communities={communities}
        onSubmit={handleSubmit}
        reviewMode={reviewMode}
        publishedList={publishedList}
        setPublishedList={setPublishedList}
        allCommunitiesIds={allCommunitiesIds}
        initialListCommunity={selectedCommunitiesIds}
        setCommLoading={setCommLoading}
        addCommLoading={addCommLoading}
        typeUser={typeUser as TypeCommunity}
      />
    </NavContainer>
  );
};
