import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {EventCard} from './EventCard';
import {
  InnerAllListings,
  InnerMyListings,
  InnerTitle,
  ListCardsFlex,
  ListCardsGrid,
  ListingDivider,
  SubTitle,
  ViewAllBtn,
  WrapperMyListings,
} from './styles';
import {route} from '../../constants/routes';
import {currentCommunity, userPermissions} from '../../states/community';
import {TypeCommunity} from '../../types/auth';
import {IGetCurrValue} from '../../types/common';
import {TEvent} from '../../types/event';
import {PermissionsList} from '../../types/settings';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {ItemsSkeletonLoaderSection, ItemsSkeletonSection} from '../common/Skeletons/ItemsSkeleton';
import {TouchScrollableBlock} from '../common/Tabs/TouchScrollableBlock';

import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {useViewerId} from '../../hooks/user';
import {LikeState} from '../../hooks/item';

type TListEventsWithPreview = {
  isPreview: boolean;
  typeUser?: TypeCommunity | null;
  events?: Partial<TEvent>[];
  fetchDataEvents: () => void;
  hasMoreEvents: boolean;
  communityId?: string;
  title?: string;
  isEdit?: boolean;
  isHideLabel?: boolean;
  featuredDivided?: boolean;
  loading?: boolean;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  onDelete?: (id?: string) => Promise<boolean>;
  onUnPublish?: (id?: string) => () => Promise<void>;
  onPublish?: (id?: string) => () => Promise<void>;
  onLike?: (id: string) => void;
  liked?: LikeState;
};

export const ListEventsWithPreview: React.FC<TListEventsWithPreview> = ({
  isPreview,
  events,
  communityId,
  fetchDataEvents,
  hasMoreEvents,
  isEdit,
  onUnPublish,
  title,
  isHideLabel,
  featuredDivided,
  loading,
  typeUser,
  reportActions,
  getSignValue,
  onDelete,
  onPublish,
  liked,
  onLike,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const [isShow, setIsShow] = useState(isPreview);
  const titleButton = isShow ? t('events:page.viewAll') : t(`events:page.hide`);
  const isAllBtn = Number(events?.length) > 3;
  const [permissions] = useRecoilState(userPermissions);
  const [currentComm] = useRecoilState(currentCommunity);
  const viewerId = useViewerId('objectId');

  const isManager =
    typeUser === TypeCommunity.manager ||
    (permissions.includes(PermissionsList.manageEvent) && typeUser === TypeCommunity.admin);

  const viewAll = () => {
    setIsShow((prev) => !prev);
  };
  const endFeatured = useMemo(
    () => (featuredDivided ? events?.findIndex((el) => !Boolean(el?.Featured)) : null),
    [featuredDivided, events],
  );

  return (
    <InnerAllListings>
      {title && (
        <InnerTitle>
          <SubTitle>{title}</SubTitle>
          {isAllBtn && isPreview && (
            <ViewAllBtn type={'button'} onClick={viewAll}>
              {titleButton}
            </ViewAllBtn>
          )}
        </InnerTitle>
      )}
      {isShow && isPreview && (
        <WrapperMyListings>
          <InnerMyListings>
            <TouchScrollableBlock>
              <ListCardsFlex>
                {loading && !events?.length ? (
                  <ItemsSkeletonSection />
                ) : (
                  events?.map((item) => (
                    <EventCard
                      key={item.id}
                      to={
                        isManager || viewerId === item.Lister?.objectId
                          ? getLink(route.eventViewEdit.get({id: item?.objectId || ''}))
                          : getLink(route.eventView.get({id: item?.objectId || ''}))
                      }
                      communityId={communityId}
                      aspectRatio={'300:200'}
                      event={item}
                      src={item?.images?.[0]?.file?.url}
                      editPath={
                        isEdit || isManager || viewerId === item.Lister?.objectId
                          ? getLink(
                              route.eventViewEdit.get({
                                id: item?.objectId || '',
                              }),
                            )
                          : null
                      }
                      viewerId={viewerId}
                      onUnPublish={onUnPublish}
                      onPublish={onPublish}
                      onDelete={onDelete}
                      isHideLabel={isHideLabel}
                      isManager={isManager || viewerId === item.Lister?.objectId}
                      isOwner={currentComm?.Owner?.objectId === viewerId}
                      reportActions={reportActions}
                      getSignValue={getSignValue}
                      eventType={item?.eventType}
                    />
                  ))
                )}
              </ListCardsFlex>
            </TouchScrollableBlock>
          </InnerMyListings>
        </WrapperMyListings>
      )}

      {!isShow && (
        <InfiniteScroll
          loadMore={fetchDataEvents}
          hasMore={!isShow ? hasMoreEvents : false}
          pageStart={-50}
          loader={<ItemsSkeletonLoaderSection />}>
          <ListCardsGrid minWidth={280}>
            {loading && !events?.length ? (
              <ItemsSkeletonSection />
            ) : (
              events?.map((event, i) => (
                <>
                  {endFeatured && i === endFeatured ? <ListingDivider /> : <></>}
                  <EventCard
                    key={event.id}
                    to={
                      isManager || viewerId === event.Lister?.objectId
                        ? getLink(route.eventViewEdit.get({id: event?.objectId || ''}))
                        : getLink(route.eventView.get({id: event?.objectId || ''}))
                    }
                    viewerId={viewerId}
                    onUnPublish={onUnPublish}
                    onPublish={onPublish}
                    communityId={communityId}
                    aspectRatio={'300:200'}
                    event={event}
                    src={event?.images?.[0]?.file?.url}
                    editPath={
                      isEdit || isManager || viewerId === event.Lister?.objectId
                        ? getLink(route.eventViewEdit.get({id: event.objectId || ''}))
                        : null
                    }
                    isHideLabel={isHideLabel}
                    isManager={isManager || viewerId === event.Lister?.objectId}
                    isOwner={currentComm?.Owner?.objectId === viewerId}
                    onDelete={onDelete}
                    reportActions={reportActions}
                    getSignValue={getSignValue}
                    liked={liked}
                    onLike={onLike}
                    eventType={event?.eventType}
                  />
                </>
              ))
            )}
          </ListCardsGrid>
        </InfiniteScroll>
      )}
    </InnerAllListings>
  );
};
