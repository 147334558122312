import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {Seo} from '../../../components/SEO';
import {AccountSettings} from '../../../components/Settings/Account/AccountSettings';
import {AccountFormSchema} from '../../../components/Settings/schemas';
import {makeSeoKeywords} from '../../../helpers/common';
import {typeUser} from '../../../states/typeUser';
import {Profile} from '../../../types/profile';
import {AccountFormValues} from '../../../types/settings';
import {NavContainer} from '../../Navigation';

import {
  useGetActualCommunityList,
  useGetOneCommunity,
  useGetUserPendingCommunities,
  useLeaveCommunity,
} from '../../../hooks/community';
import {useFormEdit, useUpdateProfileForm} from '../../../hooks/settings';
import {
  useDeactivateUser,
  useDeleteUser,
  useGenerateUsername,
  useMarkCommunitySwitched,
  useViewer,
} from '../../../hooks/user';
import {CommunityStateType, currentCommunity} from '../../../states/community';
import {useHistory} from 'react-router-dom';
import {useLinks} from '../../../hooks/common';
import {setCurrentCommunity as setCurrentCommunityInLS} from '../../../helpers/community';
import {route} from '../../../constants/routes';
import {useResetPreload} from '../../../hooks/preload';

export const Account: React.FC = () => {
  const communities = useGetActualCommunityList(true);
  const {onLeave} = useLeaveCommunity();
  const {fetch} = useGetOneCommunity();
  const [loading, setLoading] = useState(false);
  const [typeCommunity] = useRecoilState(typeUser);
  const user = useViewer();
  const generateUsername = useGenerateUsername();
  const {isEditing, handleEditStart, handleEditEnd} = useFormEdit();
  const {t} = useTranslation();
  const textSeo = t('common:seo.settings.account');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {confirmRemoval} = useDeleteUser();
  const {deactivate} = useDeactivateUser();
  const initialState: Partial<AccountFormValues> = {
    email: user?.email,
    phone: user?.phone,
    username: user?.username,
  };
  const form = useUpdateProfileForm<AccountFormValues, Profile>({
    validationSchema: AccountFormSchema,
    initialState,
    onSuccess: () => handleEditEnd(),
  });

  const [community, setCommunity] = useRecoilState(currentCommunity);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {data: listPendingCommunities} = useGetUserPendingCommunities();
  const reset = useResetPreload();
  const markSwitched = useMarkCommunitySwitched();

  const setCurrentCommunity = async (id: string) => {
    if (community?.objectId !== id) {
      if (!listPendingCommunities.find((el) => el.objectId === id)) {
        setLoading(true);
        const newCommunity = await fetch(id);
        // const newCommunity = communities.find((el) => el.objectId === id);
        setCommunity(newCommunity as CommunityStateType);
        reset();
        markSwitched();
        newCommunity?.objectId && setCurrentCommunityInLS(newCommunity.objectId);
        setLoading(false);
        push(getLink(route.loop.get(), newCommunity?.alias));
      } else push(getLink(route.joinCommunity.get({id: id || ''})));
    } else {
      push(getLink(route.loop.get()));
    }
  };

  return (
    <NavContainer>
      <Seo title={textSeo} keywords={seoKeywords} />
      <AccountSettings
        pendingCommunities={listPendingCommunities}
        setCurrentCommunity={setCurrentCommunity}
        deleteUserConfirm={confirmRemoval}
        deactiveteUserConfirm={deactivate}
        typeUser={typeCommunity}
        user={user}
        form={form}
        isEditing={isEditing}
        onEdit={handleEditStart}
        onCancel={handleEditEnd}
        generateUsername={generateUsername}
        communities={communities}
        onLeave={onLeave}
        loading={loading}
      />
    </NavContainer>
  );
};
