import styled, {StyledProps} from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {StepStylesProps} from './types';
import {Link as ReactLink} from 'react-router-dom';

const colorStepText = ({$isdone, $active, theme}: StyledProps<StepStylesProps>) => {
  if ($isdone) {
    return theme.palette.text.octonary;
  }
  if ($active) {
    return theme.palette.text.nonary;
  }
  return theme.palette.text.septenary;
};

const widthBorder = ({$isdone, $active}: StyledProps<StepStylesProps>) => {
  return $isdone || $active ? '2px' : '1px';
};

const bgStepBorder = ({$isdone, $active, theme}: StyledProps<StepStylesProps>) => {
  if ($isdone) {
    return theme.palette.background.quaternary;
  }
  if ($active) {
    return theme.palette.background.quinary;
  }
  return theme.palette.background.tertiary;
};

const bgStepStroke = ({$isdone, $active, theme}: StyledProps<StepStylesProps>) => {
  return $isdone || $active ? theme.palette.background.quaternary : theme.palette.background.tertiary;
};

const bgStepMobile = ({$isdone, theme}: StyledProps<StepStylesProps>) => {
  return $isdone ? theme.palette.background.quaternary : theme.palette.background.primary;
};

export const StepLinkDesktop = styled(ReactLink)<StepStylesProps>`
  position: relative;
  white-space: nowrap;
  padding: 0 30px 0 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: Inter, sans-serif;
  text-decoration: none;
  color: ${colorStepText};
  font-weight: ${({$active, $isdone}) => ($active || $isdone ? '700' : '400')};
  &:last-child {
    &::after {
      display: none;
    }
  }
  &::after {
    content: '';
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    width: 7px;
    height: 7px;
    border-top: ${widthBorder} solid ${colorStepText};
    border-right: ${widthBorder} solid ${colorStepText};
    margin-right: 7px;
  }
  ${Media.down.s} {
    display: none;
  }
`;

export const StepsWrapper = styled.div`
  max-width: 587px;
  display: flex;
  justify-content: space-between;
  width: max-content;

  & > :first-child .step-stroke {
    display: none;
  }
  ${Media.down.s} {
    max-width: 210px;
  }
`;

export const StepLinkMobile = styled(ReactLink)<StepStylesProps>`
  display: none;
  justify-content: center;
  align-items: center;
  width: max-content;

  ${Media.down.s} {
    display: flex;
  }
`;

export const StepBorder = styled.div<StepStylesProps>`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px ${bgStepBorder};
  background-color: ${bgStepMobile};
  border-radius: 100%;
  padding: 3px;
  box-sizing: border-box;

  ${Media.up.m} {
    width: 22px;
    height: 22px;
  }
`;

export const StyledStep = styled.div<StepStylesProps>`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: ${({$active, theme}) => ($active ? theme.palette.background.quinary : 'transparent')};
`;

export const StepStroke = styled.div.attrs({
  className: 'step-stroke',
})<StepStylesProps>`
  background-color: ${bgStepStroke};
  width: 48px;
  height: 1px;
  display: inline-block;
`;
