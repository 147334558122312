import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Text';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Link} from 'react-router-dom';

export const PopupWrapper = styled.div`
  position: fixed;
  right: 50px;
  top: 50px;

  ${Media.down.s} {
    inset: 0;
    top: unset;
  }
`;

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
`;

export const NotificationsWrapper = styled.div`
  max-height: max-content;
  max-width: 375px;
  width: 100vw;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;

  & > * {
    box-sizing: border-box;
  }

  ${Media.down.s} {
    max-width: 100%;
  }
`;

export const Title = styled(Text).attrs({variant: 'primary', weight: 'medium', color: 'octonary'})`
  display: inline-block;
  font-size: 20px;
  padding: 20px 15px;
`;

export const NotificationLabel = styled(Title)`
  display: flex;
  background-color: ${({theme}) => theme.palette.background.primary};
  position: sticky;
  top: 0;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;

export const NotificationsClear = styled.span<{$loading?: boolean}>`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  ${({$loading}) => $loading && ` pointer-events: none; opacity: 0.5;`}
`;

export const NoNotificationsText = styled(Text).attrs({
  variant: 'primary',
  weight: 'regular',
  color: 'octonary',
  size: 14,
})`
  display: block;
  padding: 0 15px 15px;
`;

export const NotificationsBlockWrapper = styled.div`
  max-height: min(700px, calc(100vh - 200px));
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: auto;
  position: relative;
`;

export const NotificationText = styled(Text).attrs({variant: 'primary', weight: 'regular', color: 'octonary'})`
  font-size: 12px;
  margin-bottom: 5px;

  ${Media.down.m} {
    font-size: 14px;
  }
`;

export const EntryWrapper = styled.div<{seen?: boolean}>`
  padding: 12px 15px;
  max-width: 100%;
  position: relative;
  background-color: ${({theme, seen}) => (seen ? 'transparent' : theme.palette.common.blueLight)};

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.palette.background.secondary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const NotificationContent = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 24px;
  grid-column-gap: 10px;

  & :is(img, svg) {
    cursor: pointer;
  }
`;

export const SkeletonWrapper = styled.div`
  padding: 12px 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 10px;
  box-sizing: border-box;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CheckWrapper = styled(Link)`
  max-width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 56px;
  position: relative;
  cursor: pointer;
  & > *:last-child {
    position: absolute;
    right: 19px;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.palette.background.secondary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const IsCompleteIcon = styled(IconSvg).attrs({
  width: '25px',
  height: '25px',
  viewBox: '0 0 20 20',
  type: 'circle-ok',
})<{$isComplete?: boolean}>`
  position: absolute;
  left: 14px;
  & path {
    ${({theme, $isComplete}) => $isComplete && `stroke: ${theme.palette.common.aqua};`}

    &:first-child {
      ${({$isComplete}) => !$isComplete && 'display: none;'}
    }
  }
`;

export const CheckContent = styled(Text).attrs({
  size: 12,
  color: 'octonary',
})`
  ${Media.down.m} {
    font-size: 14px;
    width: 80%;
  }
`;

export const ActionIcon = styled(IconSvg).attrs({
  viewBox: '0 0 20 20',
})`
  transform: rotate(180deg);

  & path {
    stroke: ${({theme}) => theme.palette.background.tertiary};
    stroke-width: 1;
  }
`;

export const Button = styled.span`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const CloseNotificationsBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 8px;
  display: none;
  ${Media.down.m} {
    display: none;
  }
`;
