import React, {ReactNode} from 'react';
import {ButtonContinueToPersonal, Icon, MainText, SubText, Wrapper} from './styles';
import {useTranslation} from 'react-i18next';

type TProps = {
  mainText?: string;
  ElemSubText?: ReactNode;
  maxWidth?: string;
  showIcon?: boolean;
  redirectToPersonalSetting: () => void;
};

export const EmptyAddress: React.FC<TProps> = ({
  mainText,
  ElemSubText,
  maxWidth,
  showIcon = true,
  redirectToPersonalSetting,
}) => {
  const {t} = useTranslation();
  const _mainText = mainText ?? t('common:emptyAddress.mainText');
  const subtextText = ElemSubText ?? t('common:emptyAddress.subText');

  return (
    <Wrapper maxWidth={maxWidth}>
      {showIcon && <Icon />}
      <MainText>{_mainText}</MainText>
      <SubText>{subtextText}</SubText>
      <ButtonContinueToPersonal link={true} onClick={redirectToPersonalSetting}>
        {t('common:emptyAddress.buttonText')}
      </ButtonContinueToPersonal>
    </Wrapper>
  );
};
