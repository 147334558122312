import {ApolloArrayElement, Pointer} from '../queries/types/parse';
import {ParseEntity} from '../types/parse';

export const toPointer = (item?: {__typename: string; objectId: string}): Pointer => {
  return {
    __type: 'Pointer',
    className: item?.__typename || '',
    objectId: item?.objectId || '',
  };
};

export const toGQLId = (ParseClassName: ParseEntity, objectId: string) => {
  return btoa(`${ParseClassName}:${objectId}`);
};

export const getArrayElement = <T extends unknown>(el: ApolloArrayElement<T>) => {
  return el.value;
};
