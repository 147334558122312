/* eslint-disable @typescript-eslint/no-empty-function */
import {isNumber, isString, StringValidation} from '../validations';
import {keys} from '../object';
import {Message} from './types';
import {Validator} from './Validator';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';

export type LengthOptions = {
  equal?: number;
  gt?: number;
  lt?: number;
  gte?: number;
  lte?: number;
};

export class ValidationString<T extends anyObject> extends Validation<T> {
  constructor(message?: Message) {
    super(message);
    this.$validators = [];
    this.$validators.push(Validator.of((value: unknown) => isString(value), message));
  }

  public length(options: LengthOptions, message?: string) {
    this.$message = message;
    const handlers: Validator<T>[] = [];

    keys(options).forEach((option) => {
      const handler = StringValidation[option];
      const param = options[option];

      if (!isNumber(param)) {
        return console.error(`Option ${option} should be a number`);
      }

      handlers.push(Validator.of((value: unknown) => handler(param, value as string), this.$message));
    });

    this.$validators = this.$validators.concat(handlers);
    return this;
  }

  public email(message: Message) {
    this.$message = message;
    this.$validators.push(Validator.of(StringValidation.email, this.$message));
    return this;
  }

  public url(message: Message) {
    this.$message = message;
    this.$validators.push(Validator.of(StringValidation.url, this.$message));
    return this;
  }
}
