import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
  manageMessageHints,
  useActiveContact,
  useActiveOrder,
  useContacts,
  useCreateMessage,
  useGetContactsForChat,
  useGetMessages,
  useSearchCommunityMember,
  useSetSeenMessage,
} from '../../hooks/message';
import {useViewer} from '../../hooks/user';
import {Messages} from '../../components/Messages';
import {formatOrderLink, getCountUnreadMsgs, getMesssages} from '../../helpers/message';
import {GQLOrderOrder, GQLOrderStatuses} from '../../graphql.schema';
import {
  useCalcOrderData,
  useCreateOrderReview,
  useDataInvoiceForMsgs,
  useEditOrder,
  useGetOrderReviews,
  useLQOrders,
  useManageOrder,
  useOrdersList,
} from '../../hooks/order';
import {getOrdersIds} from '../../helpers/order';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {MobTabMsgs, OrderHelpersT} from '../../types/messages';
import {Modals} from './Modals';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {MobContainerProps} from '../MobileTabs';
import {typeUser} from '../../states/typeUser';
import {getUserName} from '../../helpers/user';
import {SITE_URL} from '../../constants/env';
import {useLinks} from '../../hooks/common';
import {route} from '../../constants/routes';
import {analyticsTrackFN} from '../../helpers/account';
import {ListingType} from '../../queries/types/item';
import {preloadContactIdsState, preloadContactsState, preloadMessages} from '../../states/preload';
import {SearchKey} from '../../constants/routes';
import {formatOrderReviewObject, formatRequestCancelObject, formatRequestObject} from '../../helpers/segment';

export const getTypeOrder = (status?: GQLOrderStatuses, type?: ListingType) => {
  let key = '';
  switch (status) {
    case GQLOrderStatuses.accepted: {
      key = 'Request Accepted';
      break;
    }
    case GQLOrderStatuses.rejected: {
      key = 'Request Cancelled';
      break;
    }
    case GQLOrderStatuses.completed: {
      if (type === ListingType.service) key = 'Service Completed';
      if (type === ListingType.sell) key = 'Listing Sold Confirmed';
      if (type === ListingType.rent) key = 'Listing Returned';
      break;
    }
    case GQLOrderStatuses.performing: {
      if (type === ListingType.service) key = 'Service Provided';
      if (type === ListingType.rent) key = 'Listing Rented';
      if (type === ListingType.sell) key = 'Listing Marked Sold';
      break;
    }
  }
  return key;
};

export const MessagesContainer: React.FC<MobContainerProps> = ({hideNav}) => {
  const {contactId} = useParams<{contactId?: string}>();
  const [community] = useRecoilState(currentCommunity);
  const [userType] = useRecoilState(typeUser);
  const {search} = useLocation();
  const viewer = useViewer();
  const [activeMobTab, setActiveMobTab] = useState<MobTabMsgs | null>(MobTabMsgs.messages);
  const [preloadMsgs] = useRecoilState(preloadMessages);
  const [preloadContatIds] = useRecoilState(preloadContactIdsState);
  const [preloadContacts] = useRecoilState(preloadContactsState);
  const [editReview, setEditReview] = useState(false);
  const setEditRev = (edit: boolean) => setEditReview(edit);
  const [stripePopup, setStripePopup] = useState<boolean>(false);
  const params = new URLSearchParams(search);
  const orderId = params.get(SearchKey.orderId);
  const [isSearching, setIsSearching] = useState(false);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {
    data: msgs,
    loading: LoadingMsg,
    refetch,
    addLocalmessage,
    setDelivered,
    supportUser,
  } = useGetMessages(community?.objectId, contactId, preloadMsgs);
  const {
    contacts: users,
    contactsIds: hookContactsIds,
    loading: loadingActiveContact,
  } = useContacts(msgs, viewer?.objectId, contactId, community?.objectId);
  const contactsIds = hookContactsIds?.length ? hookContactsIds : preloadContatIds;
  const {contacts: hookContacts, loading: loadingContacts} = useGetContactsForChat({
    initUsers: users,
    msgs,
    loading: LoadingMsg || loadingActiveContact,
    contactsIds,
    supportUser,
    commId: community?.objectId,
  });
  const contacts = !hookContacts?.length ? preloadContacts : hookContacts;
  const [activeContact, setActiveContact] = useActiveContact({contactId, contacts, supportUser});
  const {setSeenMessage: updateSeenMsg, loading: LoadingUpdMsg} = useSetSeenMessage({onSuccess: refetch});
  const {t} = useTranslation();
  const ordersIds = getOrdersIds(msgs);
  const {data: GQLOrders, ...dataOrders} = useOrdersList({
    first: 10,
    where: {
      objectId: {in: ordersIds},
      ...(activeContact
        ? {
            OR: [
              {Lister: {have: {objectId: {equalTo: activeContact.objectId}}}},
              {Requester: {have: {objectId: {equalTo: activeContact.objectId}}}},
            ],
          }
        : {}),
    },
    skip: !ordersIds?.length,
    order: GQLOrderOrder.updatedAt_DESC,
  });
  const {orders, refetch: refetchOrders, loading: orderLoading} = useLQOrders({orders: GQLOrders, ordersIds});
  const {activeOrder, setActiveOrder, clearActiveOrder} = useActiveOrder(orders, activeContact?.objectId);
  const textSeo = activeOrder?.objectId ? activeOrder?.Item?.name : t('common:seo.messages');
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const {calcDataOrder, loading: invoiceLoading} = useCalcOrderData({orderId: activeOrder?.objectId});
  const dataInvoice = useDataInvoiceForMsgs(calcDataOrder, activeOrder);
  const dataManageOrder = useManageOrder({
    eventStartTime: activeOrder?.Event?.startTime,
    eventEndTime: activeOrder?.Event?.endTime,
    eventType: activeOrder?.Event?.eventType,
    orderStartTime: activeOrder?.startTime,
    onSuccess: async (status?: GQLOrderStatuses) => {
      await refetchOrders();
      await refetch();
      const key = getTypeOrder(status, activeOrder?.listingType);
      if (!key) return;
      if (key === 'Request Cancelled') {
        analyticsTrackFN(key, formatRequestCancelObject(activeOrder, dataInvoice, viewer));
        return;
      }
      analyticsTrackFN(key, formatRequestObject(activeOrder, dataInvoice));
    },
  });
  const showStripePopup = () => {
    setStripePopup(true);
    analyticsTrackFN('Stripe Connect Prompt', formatRequestObject(activeOrder, dataInvoice));
  };
  const [cardError, setCardError] = useState<string | null>(null);
  const setCardErrorPopup = (v: string | null) => setCardError(v);
  useEffect(() => {
    if (orderId && orders?.length) {
      setActiveOrder(orderId);
      setActiveMobTab(MobTabMsgs.requests);
    }
  }, [!!orders?.length, orderId]);

  const editOrderData = useEditOrder({
    dates: [
      (activeOrder?.startTime && new Date(activeOrder?.startTime)) || null,
      (activeOrder?.endTime && new Date(activeOrder?.endTime)) || null,
    ],
    orderId: activeOrder?.id,
    listingType: activeOrder?.listingType,
  });
  const reviewsData = useGetOrderReviews({orderId: activeOrder?.id});
  const handleRefetchReview = () => {
    if (activeOrder?.Item?.id && (reviewOrderData?.data?.userReview || reviewOrderData?.data?.itemReview)) {
      try {
        analyticsTrackFN(
          'Review Provided',
          formatOrderReviewObject(activeOrder, dataInvoice, reviewOrderData, viewer, 'review'),
        );
      } catch (e) {
        console.log(e);
      }
    }
    if (activeOrder?.Item?.id && (reviewOrderData?.data?.itemRating || reviewOrderData?.data?.userRating)) {
      try {
        analyticsTrackFN(
          'Rating Provided',
          formatOrderReviewObject(activeOrder, dataInvoice, reviewOrderData, viewer, 'rating'),
        );
      } catch (e) {
        console.log(e);
      }
    }
    reviewsData.refetch();
  };
  const reviewOrderData = useCreateOrderReview({
    orderId: activeOrder?.id,
    itemId: activeOrder?.Item?.id,
    eventId: activeOrder?.Event?.id,
    amenityId: activeOrder?.Amenity?.id,
    opponentId: viewer?.id === activeOrder?.Requester.id ? activeOrder?.Lister.id : activeOrder?.Requester.id,
    isRequester: viewer?.id === activeOrder?.Requester.id,
    onSuccess: handleRefetchReview,
    reviewData: reviewsData?.data,
  });
  const {
    values,
    onChange,
    deleteImage,
    onSubmit,
    loading: LoadingCreateMsg,
  } = useCreateMessage({
    idActiveContact: contactId,
    communityId: community?.objectId,
    initialState: {
      Author: viewer?.objectId,
      text: '',
    },
    onSuccess: refetch,
    contactIsSupport: activeContact?.isSupport,
    idActiveChat: activeContact?.chatId,
    addLocalmessage,
    activeContact: activeContact,
    setDelivered,
  });
  const loadingMsg = useMemo(
    () => (LoadingMsg || LoadingCreateMsg || LoadingUpdMsg || loadingContacts) && (!msgs?.length || !contacts?.length),
    [LoadingMsg, LoadingCreateMsg, LoadingUpdMsg, loadingContacts, !!msgs?.length, !!contacts?.length],
  );
  const messages = getMesssages(msgs, activeContact?.objectId);
  const countUnReadMsg = getCountUnreadMsgs(msgs, viewer?.objectId);
  const orderHelpers: OrderHelpersT = {
    refetchMsgs: refetch,
    orderLoading: orderLoading,
  };
  // Stripe Connected api
  const stripeConnectedId = params.get(SearchKey.stripeConnectId);
  const stripeConnectId = localStorage.getItem(SearchKey.stripeConnectId);
  useEffect(() => {
    if (stripeConnectedId && stripeConnectedId === stripeConnectId) {
      analyticsTrackFN('Stripe Connected', {
        name: `${viewer?.firstName} ${viewer?.lastName}`,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
      });
      localStorage.removeItem('stripeConnectId');
    }
  }, [stripeConnectedId]);

  const returnUrl =
    SITE_URL + getLink(formatOrderLink(activeContact?.objectId, activeOrder?.objectId, String(new Date().getTime())));
  const {searchText, setPeopleSearchText, members, hasMore, fetchData, clearSearchText} = useSearchCommunityMember(
    viewer?.objectId,
  );

  const forwardToUser = (id: string) => {
    setIsSearching(false);
    push(getLink(route.messages.get({contactId: id})));
  };

  useEffect(() => {
    if (!contactId?.trim()) setActiveContact(null);
    if (isSearching) {
      setActiveContact(null);
    }
  }, [isSearching, contactId]);

  useEffect(() => {
    if (activeContact?.objectId) {
      setActiveMobTab(MobTabMsgs.chat);
    }
  }, [activeContact?.objectId]);
  manageMessageHints(msgs?.length, orders);
  return (
    <>
      <Seo
        title={textSeo}
        keywords={seoKeywords}
        description={activeOrder?.Item?.descr}
        og={{image: activeOrder?.Item?.images?.[0]?.file?.url}}
      />
      <Modals
        stripePopup={stripePopup}
        setStripePopup={(b: boolean) => setStripePopup(b)}
        cardError={cardError}
        setCardErrorPopup={setCardErrorPopup}
        returnUrl={returnUrl}
      />
      <Messages
        msgs={messages}
        dataMessage={{
          values,
          onChange,
          onSubmit,
          deleteImage,
          loading: loadingMsg,
          LoadingCreateMsg,
          updateSeenMsg,
        }}
        countUnReadMsg={countUnReadMsg}
        contactsLoading={loadingContacts}
        contactsList={contacts}
        activeContact={activeContact}
        setActiveContact={setActiveContact}
        viewerId={viewer?.objectId}
        viewerData={{
          name: getUserName(userType, viewer?.firstName, viewer?.lastName || viewer?.firstLetter),
          avatar: viewer?.Avatar?.file.url,
        }}
        dataActiveOrder={{activeOrder, setActiveOrder, clearActiveOrder}}
        dataOrders={{...dataOrders, orders, invoiceLoading}}
        dataInvoice={dataInvoice}
        dataManageOrder={dataManageOrder}
        editOrderData={editOrderData}
        reviewOrderData={reviewOrderData}
        reviewsData={reviewsData?.data}
        reviewsDataLoading={reviewsData?.loading}
        editReviewData={{
          isEditing: editReview,
          setEditReview: setEditRev,
        }}
        orderHelpers={orderHelpers}
        activeMobTab={activeMobTab}
        setActiveMobTab={setActiveMobTab}
        showStripePopup={showStripePopup}
        setCardErrorPopup={setCardErrorPopup}
        hideNav={hideNav}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        setPeopleSearchText={setPeopleSearchText}
        searchText={searchText}
        members={members}
        forwardToUser={forwardToUser}
        hasMore={hasMore}
        fetchMore={fetchData}
        clearSearchText={clearSearchText}
        loading={Boolean(loadingActiveContact)}
        commId={community?.objectId}
      />
    </>
  );
};
