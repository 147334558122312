import React from 'react';
import {Input} from '../../ui-kit/Form/Input';
import {ErrorMessage, InnerForm, InnerName, Terms} from './styles';
import {Button} from '../../ui-kit/Button/Button';
import {AuthField, SignUpValues, TypeCommunity} from '../../types/auth';
import {useTranslation} from 'react-i18next';
import {TermsLinks} from './TermsLinks';
import {ISignUpRes} from '../../hooks/auth';
import {correctTranslate} from '../../helpers/common';

type SignUpField = keyof SignUpValues;

interface IProps {
  typeCommunity: TypeCommunity;
  dataAuth: ISignUpRes;
}

export const SignUpForm: React.FC<IProps> = ({typeCommunity, dataAuth}) => {
  const {onSubmit, values, onChange, error, loading} = dataAuth;
  const {t} = useTranslation();
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({name: e.target.name as SignUpField, value: e.target.value});
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <InnerForm onSubmit={handleSubmit}>
      <InnerName>
        <Input
          $isError={Boolean(error.firstName)}
          type={'text'}
          name={AuthField.firstName}
          value={values.firstName}
          label={t('forms:firstName')}
          onChange={handleChange}
        />
        <Input
          $isError={Boolean(error.lastName)}
          type={'text'}
          name={AuthField.lastName}
          value={values.lastName}
          label={t('forms:lastName')}
          onChange={handleChange}
        />
      </InnerName>
      {typeCommunity === TypeCommunity.manager && (
        <Input
          $isError={Boolean(error.work)}
          type={'text'}
          name={AuthField.work}
          value={values.work}
          label={t('forms:communityName')}
          onChange={handleChange}
        />
      )}
      <Input
        $isError={Boolean(error.email)}
        type={'email'}
        name={AuthField.email}
        value={values.email}
        label={t('forms:emailAddress')}
        onChange={handleChange}
      />
      <Input
        $isError={Boolean(error.password)}
        type={'password'}
        name={AuthField.password}
        value={values.password}
        label={t('forms:password')}
        onChange={handleChange}
      />
      <Input
        $isError={Boolean(error.confirmPassword)}
        type={'password'}
        name={AuthField.confirmPassword}
        value={values.confirmPassword}
        label={t('forms:confirmPassword')}
        onChange={handleChange}
      />
      <Terms>
        {t('auth:terms.text_2')}
        <TermsLinks />
      </Terms>
      <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
      <Button type={'submit'} variant={'primary'} loading={loading} size={'md'}>
        {t('auth:buttons.signUp')}
      </Button>
    </InnerForm>
  );
};
