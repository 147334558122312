import React from 'react';
// import {GlobalStyles} from './components/Layout/globalStyles';
import {Normalize} from 'styled-normalize';
import {GoogleOAuthProvider} from '@react-oauth/google';
import Routes from './Routes';
import RecoilProvider from './containers/RecoilProvider';
import {UrlSearchProvider} from './hooks/urlSearchManager';
import CurrentUser from './containers/Auth/CurrentUser';
import {ThemeProvider} from './containers/ThemeProvider';
import {AppLayout} from './components/AppLayout';
import {OnlineProvider} from './OnlineProvider';
import {PreloadProvider} from './containers/PreloadProvider';
import {SeoHelper} from './components/SEO';
import {OGDataT} from './types/common';
import {CLIENT_GOOGLE_ID} from './constants/env';

type AppProps = {
  communityAlias?: string | null;
  skipSignUpParams?: URLSearchParams;
  currentCommId?: string | null;
  OGData?: OGDataT | null;
};

const App: React.FC<AppProps> = ({communityAlias, skipSignUpParams, currentCommId, OGData}) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_GOOGLE_ID as string}>
      <RecoilProvider alias={communityAlias} skipSignUpParams={skipSignUpParams} currentComm={currentCommId}>
        <CurrentUser alias={communityAlias}>
          <PreloadProvider>
            <ThemeProvider>
              <UrlSearchProvider>
                <AppLayout>
                  <SeoHelper OGData={OGData} />
                  <OnlineProvider>
                    <Routes />
                  </OnlineProvider>
                </AppLayout>
              </UrlSearchProvider>
            </ThemeProvider>
            <Normalize />
          </PreloadProvider>
        </CurrentUser>
      </RecoilProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
