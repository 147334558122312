import React from 'react';
import {useTranslation} from 'react-i18next';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {Label} from '../../../../ui-kit/Labels';
import {CloseBtnMob} from '../../../Loop/PostCreate/styles';

type TProps = {
  resetType: () => void;
  postType?: string;
};

export const CloseButtonMobile: React.FC<TProps> = ({resetType, postType}) => {
  const {t} = useTranslation();

  return (
    <CloseBtnMob onClick={resetType}>
      <Label>{t('common:postType', {context: postType})}</Label>
      <IconSvg type={'close'} viewBox={'0 0 20 20'} />
    </CloseBtnMob>
  );
};
