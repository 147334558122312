import React, {useMemo} from 'react';

import {ViewGroup} from '../../components/Groups/View';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {NavContainer} from '../Navigation';

import {useGetGroup, useGetJoinedGroups, useJoinLeaveGroup} from '../../hooks/groups';
import {useViewerId} from '../../hooks/user';

export const GroupView = () => {
  const viewerId = useViewerId('objectId');
  const {data, loading, refetch} = useGetGroup();
  const joined = useGetJoinedGroups(viewerId);
  const onJoinSuccess = () => {
    refetch();
    joined?.refetch();
  };
  const join = useJoinLeaveGroup(onJoinSuccess).join;
  const isJoined = useMemo(
    () => !!joined?.joinedList?.find((el) => el.objectId === data?.objectId)?.objectId,
    [joined],
  );
  const onJoin = () => join(data?.objectId);
  return (
    <NavContainer>
      {(loading || joined.loading) && (!data?.objectId || !joined.joinedList) ? (
        <LottieLoader allScreen={true} $isVisible={true} />
      ) : (
        <ViewGroup group={data} isJoined={isJoined} onJoin={onJoin} />
      )}
    </NavContainer>
  );
};
