import React, {useEffect} from 'react';
import {useGetUserPermissionsClient, useViewer} from './hooks/user';
import {useSetUserLastVisited} from './hooks/message';
// import {useLocation} from 'react-router-dom';
// import {route} from './constants/routes';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {currentCommunity, userPermissions} from './states/community';
import {RNMessages, SendMessageToRNApp} from './helpers/common';
import {theme} from './ui-kit/theme';
import {isModal} from './states/themeMode';
import {getTypeUserByTCommunity} from './helpers/user';
import {TypeCommunity} from './types/auth';
import {typeUser} from './states/typeUser';
import {useGetOnlineUsers} from './hooks/community';
import {useLocation} from 'react-router-dom';

export const OnlineProvider: React.FC = ({children}) => {
  const viewer = useViewer();
  const [comm] = useRecoilState(currentCommunity);
  const [modalOpened] = useRecoilState(isModal);
  const {setLastVisited} = useSetUserLastVisited({});
  const {pathname} = useLocation();
  // const {subscribeUserForPush} = useSetUserPushNotifications();
  const getPermissions = useGetUserPermissionsClient();
  const setPermissions = useSetRecoilState(userPermissions);
  const setTypeUser = useSetRecoilState(typeUser);
  useGetOnlineUsers();
  useEffect(() => {
    const getPermission = async () => {
      const permissions = await getPermissions({userId: viewer?.objectId, communityId: comm?.objectId});
      const typeUser = getTypeUserByTCommunity(viewer?.objectId, comm);
      const permissionsList = typeUser === TypeCommunity.resident ? [] : permissions;
      setPermissions(permissionsList);
      setTypeUser(typeUser);
    };
    if (viewer?.objectId && comm?.objectId) {
      if (viewer?.lastVisitedCommunity !== comm?.objectId)
        setLastVisited({objectId: viewer?.objectId, commId: comm?.objectId});
      getPermission();
    }
  }, [viewer?.objectId, comm?.objectId]);

  // useEffect(() => {
  //   if (viewer?.objectId) {
  //     int2 = setInterval(async () => {
  //       const token = localStorage?.getItem(LocalStorageItems.deviceToken);
  //       if (token) {
  //         if (!tokenD) {
  //           // await subscribeUserForPush({userId: viewer?.objectId, token: token});
  //           setToken(token);
  //         }
  //       }
  //     }, 10000);
  //   } else {
  //     if (int2) {
  //       clearInterval(int2);
  //     }
  //   }

  //   return () => int2 && clearInterval(int2);
  // }, [viewer?.objectId, tokenD]);
  const isHomePage = pathname === '/';

  useEffect(() => {
    // set safe area view color in app
    const getColor = () => {
      if (modalOpened) return theme?.palette?.grayscale?._6;
      if (!viewer?.objectId && !isHomePage) return theme?.palette?.grayscale?._20;
      return theme?.palette?.grayscale?._0;
    };
    const color = getColor();

    SendMessageToRNApp(JSON.stringify({[RNMessages.areaColor]: color}));
    SendMessageToRNApp(JSON.stringify({[RNMessages.isModal]: modalOpened}));
  }, [!!viewer?.objectId, modalOpened, isHomePage]);

  return <>{children}</>;
};
