import React from 'react';
import styled from 'styled-components';
import {Text} from '../../../ui-kit/Typography/Text';

const WrapCounter = styled(Text)`
  font-family: ${({theme}) => theme.font.tertiary.fontFamily};
  font-weight: ${({theme}) => theme.font.tertiary.fontWeight};
  color: ${({theme}) => theme.palette.text.quinary};
  font-size: 13px;
`;

type TProps = {
  value?: number;
  limit: number;
};

const SymbolsCounter: React.FC<TProps> = ({value = 0, limit}) => {
  const newValue = value > limit ? limit : value;

  return (
    <WrapCounter>
      {newValue}/{limit}
    </WrapCounter>
  );
};

const MemoSymbolsCounter = React.memo(SymbolsCounter);
export {MemoSymbolsCounter};
