import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';

export const SkeletonWrapper = styled.div<{
  width?: number | string;
  height?: number | string;
  circle?: boolean;
  mobileWidth?: number | string;
  mobileHeight?: number | string;
}>`
  max-width: ${({width}) => (width ? (typeof width === 'number' ? `${width}px` : width) : '100%')};
  max-height: ${({height}) => (height ? (typeof height === 'number' ? `${height}px` : height) : '100%')};
  width: 100%;
  height: 100%;
  border-radius: ${({circle}) => (circle ? '100%' : '12px')};

  ${Media.down.xs} {
    & {
      ${({mobileWidth}) =>
        mobileWidth !== undefined &&
        (typeof mobileWidth === 'number' ? `max-width: ${mobileWidth}px;` : `max-width: ${mobileWidth};`)}
      ${({mobileHeight}) =>
        mobileHeight !== undefined &&
        (typeof mobileHeight === 'number' ? `max-height: ${mobileHeight}px;` : `max-height: ${mobileHeight};`)}
    }
  }

  span {
    width: 100%;
    height: 100%;
    border-radius: ${({circle}) => (circle ? '100%' : '12px')};
  }
`;

export const ProfileIntroSkeletonWrapper = styled.div<{$hideOnMob?: boolean}>`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(max-content, 128px) 1fr;
  gap: 10px;

  ${Media.down.m} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  ${({$hideOnMob}) =>
    $hideOnMob &&
    `
    ${Media.down.l} {
      display: none;
    }
  `}
`;

export const ProfileIntroSkeletonContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 6px;
`;

export const ListingSkeletonWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  border-radius: 12px;
  padding: 9px 11px;
  background-color: ${({theme}) => theme.palette.background.primary};
  width: 280px;
  display: flex;
  align-items: center;
  ${Media.down.l} {
    margin: 0 auto;
    margin-bottom: 10px;
    &:last-of-type {
      display: none;
    }
  }
  ${Media.down.m} {
    width: 100%;
  }
`;

export const SkeletonItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  max-width: unset !important;
  ${Media.down.m} {
    /* & > div {
      width: 49%;
    } */
  }
  ${Media.down.xxs} {
    & > div {
      width: 100%;
    }
  }
`;
export const CommunitiesSkeletonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 15px;
`;

export const DashboardSkeletonWrapper = styled.div`
  padding: 16px 15px;
  background-color: ${({theme}) => theme.palette.background.primary};
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 12px;
  column-gap: 6px;
`;

export const DashboardSkeletonAreaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-row-gap: 15px;
  grid-column-gap: 20px;

  ${Media.down.s} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ListingViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: min(60px, 2vw) min(110px, 5vw);
  box-sizing: border-box;
`;

export const ListingViewContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(max-content, 600px) 1fr;
  gap: 30px;

  ${Media.down.m} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export const ListingViewAsideWrapper = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;

  & :first-child {
    margin-bottom: 15px;
  }

  & :last-child {
    margin-top: 5px;
  }
`;

export const PeopleSkeletonWrapper = styled(DashboardSkeletonWrapper)`
  min-width: 300px;
  padding: 12px 11px;
  display: flex;
`;

export const ContactSkeletonWrapper = styled.div`
  min-width: 300px;
  padding: 12px 8px;
  display: flex;
`;

export const MemberSkeletonWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  padding: 5px 11px;
  display: flex;
  margin-bottom: 8px;
  background-color: ${({theme}) => theme.palette.background.primary};
  box-sizing: border-box;
  border-radius: 12px;
`;

export const PeopleSkeletonSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-row-gap: 15px;
  grid-column-gap: 12px;

  ${Media.down.m} {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    width: 100%;
  }
`;

export const PairWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 3px;

  & :first-child {
    margin-bottom: 5px;
  }
`;

export const ListingPairWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 3px;
  margin-left: 3px;
  & > div {
    border-radius: 0px;
    span {
      border-radius: 2px;
    }
  }
`;

export const PairMiddleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const FeedSkeletonWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.primary};
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 12px;
  margin: 14px 0;
  padding: 16px 12px;
  column-gap: 10px;
  display: flex;
  ${Media.down.m} {
    padding: 10px 12px;
    margin: 10px 0;
  }
`;
