import React from 'react';
import styled from 'styled-components';

const HiddenWrapper = styled.div<{mq: string}>`
  ${({mq}) => mq} {
    display: none;
  }
`;

interface TProps {
  mq: string;
}

export const HiddenElement: React.FC<TProps> = ({children, mq}) => {
  return <HiddenWrapper mq={mq}>{children}</HiddenWrapper>;
};
