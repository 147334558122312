import React from 'react';
import {GroupFeedContainer} from './styles';
import {GroupPostCreate} from '../Loop/PostCreate';
import {User} from '../../queries/types/user';
import {PostActions, TPost} from '../../types/post';
import {Feed} from '../Feed';
import {TypeCommunity} from '../../types/auth';
import {useGetPostMenuActions, usePostActions} from '../../hooks/feed';
import {CommunityStateType} from '../../states/community';
import {reportActions} from '../../hooks/report';

type GroupPostsFeedProps = {
  viewer: User | null;
  community?: CommunityStateType;
  groupId?: string;
  refetchPosts?: () => void;
  posts: TPost[];
  typeUser: TypeCommunity;
  postsLoading?: boolean;
  fetchMore: (c?: number) => void;
  hasMorePosts: boolean;
  actions?: PostActions;
  adminLabels?: Record<string, string>;
  totalPostsCount?: number;
  getPollData?: (id: string) => Promise<(User[] | undefined)[] | undefined>;
  reportActions?: reportActions;
};

export const GroupPostsFeed: React.FC<GroupPostsFeedProps> = ({
  viewer,
  groupId,
  refetchPosts,
  posts,
  typeUser,
  postsLoading,
  community,
  fetchMore,
  hasMorePosts,
  totalPostsCount,
  adminLabels,
  actions,
  getPollData,
  reportActions,
}) => {
  const menuActions = useGetPostMenuActions();
  const postActions = usePostActions();
  return (
    <GroupFeedContainer>
      <GroupPostCreate user={viewer} groupId={groupId} typeUser={typeUser} refetchPosts={refetchPosts} />
      <Feed
        getPollData={getPollData}
        posts={posts}
        typeUser={typeUser}
        menuActions={menuActions}
        viewerId={viewer?.objectId || ''}
        postActions={postActions}
        loading={postsLoading}
        refetch={refetchPosts}
        community={community}
        loadMore={fetchMore}
        hasMorePosts={hasMorePosts}
        viewer={viewer}
        actions={actions}
        adminLabels={adminLabels}
        totalPostsCount={totalPostsCount}
        hideAttach={true}
        isGroup={true}
        reportActions={reportActions}
      />
    </GroupFeedContainer>
  );
};
