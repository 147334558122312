import React, {useEffect} from 'react';
import {ReactTagsProps} from 'react-tag-input';
import {InputWrapper} from '../Input/InputWrapper';
import {HTMLInputProps, StyledInputProps} from '../Input/types';
import {TagInputContainer} from './styles';

// eslint-disable-next-line
// @ts-ignore
import loadable from '@loadable/component';

interface IReactTags extends ReactTagsProps {
  inputProps?: {
    disabled?: boolean;
  };
}

const ReactTags = loadable(() => import('./ReactTagInput'), {ssr: false}) as React.FC<IReactTags>;

export interface ITagInput extends HTMLInputProps, StyledInputProps, ReactTagsProps {
  notValidItems?: Array<number>;
}

const CustomInput: React.FC<ITagInput> = ({notValidItems, onFocus, tags, disabled, ...props}) => {
  const hasTags = !!tags?.length;
  useEffect(() => {
    if (!hasTags) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onFocus();
  }, [hasTags]);

  return (
    <>
      <TagInputContainer notValidItems={notValidItems} onFocus={onFocus}>
        <ReactTags {...props} tags={tags} autofocus={!!tags?.length} inputProps={{disabled}} />
      </TagInputContainer>
    </>
  );
};

export const TagInput: React.FC<ITagInput> = (props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <InputWrapper<ITagInput> customInput={CustomInput} {...props} />;
};
