import styled from 'styled-components';
import {fontPrimaryMedium} from '../styles';

export const ShowMoreWrapper = styled.div<{$isEnabled?: boolean; text: string; $haveTags?: boolean}>`
  //max-height: ${({$isEnabled}) => ($isEnabled ? `120px` : `unset`)};
  overflow-y: hidden;
  line-height: 20px;
  white-space: normal;
  position: relative;
  ${({$haveTags, text, theme}) =>
    !$haveTags &&
    `
    &::after {
      pointer-events: all;
      color: ${theme.palette.text.quaternary};
      text-decoration: underline;
      font-weight: 500;
      content: ${text ? `"${text}"` : null};
      display: inline-block;
      padding-left: 4px;
      cursor: pointer;
    }`};

  & > p:last-child {
    cursor: pointer;
    &::after {
      pointer-events: all;
      color: ${({theme}) => theme.palette.text.quaternary};
      text-decoration: underline;
      font-weight: 500;
      content: ${({text}) => (text ? `"${text}"` : null)};
      display: inline-block;
      padding-left: 4px;
    }
  }
  br {
    line-height: 16px;
  }
`;

export const ShowMoreContainer = styled.div`
  position: relative;
`;

export const ExpandContainer = styled.div`
  display: none;
  bottom: 0;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  //display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
`;

export const ExpandButton = styled.button`
  color: ${({theme}) => theme.palette.common.aqua};
  cursor: pointer;
  background-color: transparent;
  border: none;
  bottom: 0;
  z-index: 150;
  ${fontPrimaryMedium};
`;

export const ExpandShadow = styled.div`
  width: 100%;
  height: 85px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgb(255, 255, 255) 1%, transparent);
`;
