import {useTranslation} from 'react-i18next';
import {imageType, PointerFile, SelectOption} from '../types/common';
import {Amenity, RewardType} from '../queries/types/amenity';
import {
  TImage,
  AmenityFormValue,
  AmenityFormValues,
  TAmenity,
  BookingPeriod,
  BookingTimingT,
  PreAmenityOrderCalcDataT,
} from '../types/amenity';
import {YesNoOptions, YesNotoBool} from '../helpers/common';
import {useViewerId} from './user';
import useMutableState from './useMutableState';
import {useEffect, useRef, useState} from 'react';
import {FormErrorFields, useFormError} from './error';
import {useMutation, useQuery} from '@apollo/client';
import {
  CreateAppFile,
  CreateFileResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {isString} from '../helpers/validations';
import {
  CreateAmenityQuery,
  CreateAmenityRequestType,
  CreateAmenityResponseType,
  amenityManageQuery,
  amenityManageRequestType,
  amenityManageResponseType,
  amenityResidentButtonsQuery,
  amenityResidentButtonsRequestType,
  amenityResidentButtonsResponseType,
  AmenitiesResponseType,
  GetAmenityReqType,
  GetAmenityRequest,
  GetAmenityResType,
  GetAmenitiesQuery,
  UpdateAmenityQuery,
  UpdateAmenityRequestType,
  UpdateAmenityResponseType,
} from '../queries/amenity';
import {
  toPointerCreateAmenity,
  toPointerEditAmenity,
  createTimeOptions,
  bookingSlotsMap,
  bsMapKeyOf,
  bsDaysMap,
  getTimeParts,
} from '../helpers/amenity';
import {FormValidation} from '../helpers/FormValidation';
import {
  GQLAmenityOrder,
  GQLAmenityWhereInput,
  GQLAmenityManageAction,
  GQLOrderType,
  GQLOrderStatuses,
  GQLOrderWhereInput,
} from '../graphql.schema';
import {CommunityStateType} from '../states/community';
import {toPointer} from '../helpers/parse';
import {User} from '../queries/types/user';
import {useGetContactsForChat} from './message';
import {checkItemImagesFormat, getResize} from '../helpers/file';
import {MakeOrder, MakeOrderRequestType, MakeOrderResponseType} from '../queries/order';
import {useOrders} from './order';

export type TAmenityFormData = {
  toggleOptions: SelectOption<boolean>[];
  rewardTypeOptions: SelectOption<RewardType>[];
  dataImages: TImage[];
  bookByOptions: SelectOption<string>[];
  bookingSlotsHour: SelectOption<string>[];
  bookingSlotsDay: SelectOption<string>[];
  bookingPerDay: SelectOption<string>[];
  autoBookingOptions: SelectOption<boolean>[];
  baseTimeOptions: SelectOption<string>[];
};

export const useAmenityFormData = (options?: {images?: Partial<PointerFile>[]}): TAmenityFormData => {
  const {t} = useTranslation();

  const baseTimeOptions = createTimeOptions();

  const hours: bsMapKeyOf[] = [30, 45, 60, 90, 120];
  const days: bsMapKeyOf[] = [0.5, 1];

  const autoBookingOptions = [
    {
      value: false,
      label: t('amenities:form.options.needsApproval'),
      key: '1',
    },
    {
      value: true,
      label: t('amenities:form.options.autoBooking'),
      key: '2',
    },
  ];

  const bookByOptions = [
    {
      value: BookingPeriod.hour,
      label: t('amenities:form.options.hour'),
      key: BookingPeriod.hour,
    },
    {
      value: BookingPeriod.day,
      label: t('amenities:form.options.day'),
      key: BookingPeriod.day,
    },
  ];

  const bookingSlotsHour = hours.map((el) => ({
    value: bookingSlotsMap[el],
    label: t('amenities:form.options.mins', {value: el}),
    key: String(el),
  }));

  const bookingSlotsDay = days.map((el) => ({
    value: bookingSlotsMap[el],
    label: t(`amenities:form.options.${bsDaysMap[el]}`),
    key: bookingSlotsMap[el],
  }));

  const toggleOptions = [
    {
      value: YesNotoBool[YesNoOptions.yes],
      label: t('common:commonButtons.yes'),
      key: YesNoOptions.yes,
    },
    {
      value: YesNotoBool[YesNoOptions.no],
      label: t('common:commonButtons.no'),
      key: YesNoOptions.no,
    },
  ];

  const bookingPerDay = [...Array(15).keys()].map((i) => ({
    value: String(i + 1),
    label: String(i + 1),
    key: String(i + 1),
  }));

  const rewardTypeOptions: SelectOption<RewardType>[] = [
    {
      value: RewardType.free,
      label: t('listings:options.free'),
      key: RewardType.free,
    },
  ];

  const dataImages = Array.from({length: 6}, (v, k) => ({
    id: options?.images?.[k]?.objectId || String(k),
    value: options?.images?.[k]?.file?.url || '',
    order: k,
  }));

  return {
    toggleOptions,
    rewardTypeOptions,
    dataImages,
    bookByOptions,
    bookingPerDay,
    bookingSlotsDay,
    bookingSlotsHour,
    autoBookingOptions,
    baseTimeOptions,
  };
};

const AmenitySchema = FormValidation.schema<Partial<AmenityFormValues>>({
  name: FormValidation.string('error:allRequired'),
  descr: FormValidation.string('error:allRequired'),
  rewardType: FormValidation.string('error:allRequired'),
  isBookable: FormValidation.boolean('error:allRequired'),
  maxBookingPerSlot: FormValidation.handler((v, d) => {
    if (d.isBookable) return !!d.maxBookingPerSlot || d.maxBookingPerSlot === 0;
    return true;
  }, 'error:allRequired'),
  maxBookingsPerDay: FormValidation.handler((v, d) => {
    if (d.isBookable) return !!d.maxBookingsPerDay || d.maxBookingsPerDay === 0;
    return true;
  }, 'error:allRequired'),
  maxResidentsPerBooking: FormValidation.handler((v, d) => {
    if (d.isBookable) return !!d.maxResidentsPerBooking || d.maxResidentsPerBooking === 0;
    return true;
  }, 'error:allRequired'),
  bookingPeriod: FormValidation.handler((v, d) => {
    if (d.isBookable) return !!d.bookingPeriod;
    return true;
  }, 'error:allRequired'),
  bookingSlots: FormValidation.handler((v, d) => {
    if (d.isBookable) return !!d.bookingPeriod;
    return true;
  }, 'error:allRequired'),
  bookingTiming: FormValidation.handler((v, d) => {
    if (!d.bookingTiming) return false;
    const notSeted = Object.keys(d?.bookingTiming)
      .map((el) => d.bookingTiming?.[el as keyof BookingTimingT])
      ?.filter((el) => {
        if (el?.available) {
          if (!el.till || !el.from) return true;
        }
        return false;
      });
    return !notSeted?.length;
  }, 'error:allRequired'),
  autoBooking: FormValidation.handler((v, d) => {
    if (d.isBookable) return d.autoBooking !== undefined;
    return true;
  }, 'error:allRequired'),

  allowGuests: FormValidation.handler((v, d) => {
    if (d.isBookable) return d.allowGuests !== undefined;
    return true;
  }, 'error:allRequired'),
  openFrom: FormValidation.handler((v, d) => {
    if (!d.openFrom) return false;
    if (d.openUntil) {
      const {hours: fHours, minutes: fMinutes} = getTimeParts(d.openFrom);
      const {hours: uHours, minutes: uMinutes} = getTimeParts(d.openUntil);

      if (fHours * 60 + fMinutes >= uHours * 60 + uMinutes) return false;
    }
    return true;
  }, 'error:correctOpenTime'),
  openUntil: FormValidation.handler((v, d) => {
    return !!d.openUntil;
  }, 'error:allRequired'),
  images: FormValidation.handler((value, data) => {
    const addedImage = data.images?.filter((el) => !!el.value);
    if (!addedImage?.length) return false;
    return true;
  }, 'error:allRequired'),
});

export type TResCreateAmenity = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<AmenityFormValues>;
  values: Partial<AmenityFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: AmenityFormValue}) => void;
  // options: TOptionsSelect;
};

type UseCreateAmenityType = (options: {
  initialState: Partial<AmenityFormValues>;
  onSuccess?: (item: Amenity) => void;
  onError?: () => void;
}) => TResCreateAmenity;

export const useCreateAmenity: UseCreateAmenityType = (options) => {
  const viewerId = useViewerId();
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<AmenityFormValues>>(options?.initialState);
  const [error, setError] = useFormError<AmenityFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name)) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const [CreateAmenityRequest, {loading}] = useMutation<CreateAmenityResponseType, CreateAmenityRequestType>(
    CreateAmenityQuery,
  );

  const handleChange = (next: {name: string; value?: AmenityFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };
  const _values = {
    ...values,
    name: values?.name?.trim(),
  } as Partial<AmenityFormValues>;

  const onSubmit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!AmenitySchema.validate<Partial<AmenityFormValues>>(values)) {
        return false;
      }

      if (!viewerId) {
        throw new Error('error:id');
      }

      checkItemImagesFormat(values.images);

      const parseImages = await Promise.all(
        (values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  Owner: {link: viewerId},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );

      const response = await CreateAmenityRequest({
        variables: {
          fields: toPointerCreateAmenity(_values, viewerId, parseImages),
        },
      });

      const data = response?.data?.createAmenity?.amenity;
      if (!data) throw new Error();

      setSuccess('success:createAmenity');
      setError(null);
      options?.onSuccess?.(data);
      setValues(options?.initialState);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading: isLoading,
    onChange: handleChange,
    error,
  };
};

export type TResEditAmenity = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<AmenityFormValues>;
  values: Partial<AmenityFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: AmenityFormValue}) => void;
};

type UseEditAmenityType = (options: {
  initialState: Partial<AmenityFormValues>;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResEditAmenity;

export const useEditAmenity: UseEditAmenityType = (options) => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<AmenityFormValues>>(options?.initialState);
  const [error, setError] = useFormError<AmenityFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [updateAppFile] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(UpdateAppFile);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name)) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const [UpdateAmenityRequest, {loading}] = useMutation<UpdateAmenityResponseType, UpdateAmenityRequestType>(
    UpdateAmenityQuery,
  );

  const handleChange = (next: {name: string; value?: AmenityFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };

  const _values = {
    ...values,
    name: values?.name?.trim(),
  } as Partial<AmenityFormValues>;

  const onSubmit = async (): Promise<boolean> => {
    try {
      if (!AmenitySchema.validate<Partial<AmenityFormValues>>(values)) {
        return false;
      }

      if (!values.objectId) {
        throw new Error('error:id');
      }
      checkItemImagesFormat(values.images);

      const parseImages = await Promise.all(
        (values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            if (typeof file.value === 'string') return values?.serverImages?.find((it) => it.objectId === file.id);
            if (Number(file?.id?.length) > 1) {
              const result = await updateAppFile({
                variables: {
                  id: file.id as string,
                  fields: {
                    file: {upload: file.value as File},
                    type: imageType.itemImage,
                  },
                },
              });
              return toPointer(result.data?.updateAppFile?.appFile);
            }
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );

      const {objectId, ...item} = _values;

      const response = await UpdateAmenityRequest({
        variables: {
          id: objectId || values.objectId,
          fields: toPointerEditAmenity({...item, images: parseImages}),
        },
      });

      const data = response?.data?.updateAmenity?.amenity;
      if (!data) throw new Error();

      setSuccess('success:editAmenity');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

export const useGetAmenity = (params: {id?: string}) => {
  const {data, ...response} = useQuery<GetAmenityResType, GetAmenityReqType>(GetAmenityRequest, {
    variables: {
      id: params.id || '',
    },
    skip: !params?.id,
    ssr: true,
  });

  return {...response, data: data?.amenity};
};

type AmenitiesParams = {
  where?: GQLAmenityWhereInput;
  order?: GQLAmenityOrder[];
  cursor?: string;
  first?: number;
};

export const useListAmenities = (params: {
  where?: GQLAmenityWhereInput;
  order?: GQLAmenityOrder[];
  skip?: boolean;
  first?: number;
  hideDeletedAmenities?: boolean;
}) => {
  const previousCursor = useRef<string | null>(null);
  const hideDeletedAmenities = params?.hideDeletedAmenities ?? true;

  const whereParams: GQLAmenityWhereInput | undefined =
    params?.where || hideDeletedAmenities
      ? {
          AND: [
            ...(params.where ? [params.where] : []),
            ...(hideDeletedAmenities ? [{isDeleted: {notEqualTo: true}}] : []),
          ],
        }
      : undefined;

  const variables: AmenitiesParams = {
    ...(whereParams ? {where: whereParams} : {}),
    ...(params?.order ? {order: params.order} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<AmenitiesResponseType, AmenitiesParams>(GetAmenitiesQuery, {
    variables,
    // notifyOnNetworkStatusChange: true,
    ssr: true,
  });

  const fetch = async () => {
    const {hasNextPage, endCursor} = data?.amenities?.pageInfo || {};

    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;

          if (!previousResult?.amenities?.pageInfo?.hasNextPage) return previousResult;

          const prevChunk = previousResult.amenities.edges;
          const nextChunk = fetchMoreResult.amenities.edges;

          return {
            amenities: {
              ...fetchMoreResult.amenities,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: data?.amenities?.edges?.map((edge) => edge.node) || [],
    loading,
    total: data?.amenities?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.amenities?.pageInfo?.hasNextPage),
    refetch,
  };
};

export const useAmenityPageContent = (
  searchParams: GQLAmenityWhereInput[],
  community?: CommunityStateType,
  showUnpublished?: boolean,
) => {
  const {
    data: featuredAmenities,
    loading: loadingFeaturedAmenities,
    refetch: refetchFeaturedAmenities,
  } = useListAmenities({
    where: {
      AND: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
    },
    order: [GQLAmenityOrder.createdAt_DESC],
  });

  const {
    data: allAmenities,
    fetchData: fetchDataAllAmenities,
    hasMore: hasMoreAllAmenities,
    loading: loadingAllAmenities,
    refetch: refetchAllAmenities,
  } = useListAmenities({
    order: [GQLAmenityOrder.createdAt_DESC],
    first: 12,
    where: {
      AND: [
        {
          OR: [
            {
              Published: {
                contains: [
                  toPointer({
                    __typename: community?.__typename || '',
                    objectId: community?.objectId || '',
                  }),
                ],
              },
            },
            ...(showUnpublished
              ? [
                  {
                    ApproveReq: {
                      contains: [
                        toPointer({
                          __typename: community?.__typename || '',
                          objectId: community?.objectId || '',
                        }),
                      ],
                    },
                  },
                ]
              : []),
          ],
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
      NOR: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
      ],
    },
  });

  const communityAmenities = [...featuredAmenities, ...allAmenities];

  return {
    loadingFeaturedAmenities,
    loadingAllAmenities,
    refetchFeaturedAmenities,
    refetchAllAmenities,
    fetchDataAllAmenities,
    hasMoreAllAmenities,
    communityAmenities,
  };
};

export const useManagerActionsAmenity = (params: {
  communityId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const {onSuccess, onError, communityId} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionRequest, {loading}] = useMutation<amenityManageResponseType, amenityManageRequestType>(
    amenityManageQuery,
  );

  const onSubmit = async (params: {amenityId?: string; typeBtn: GQLAmenityManageAction}) => {
    if (!params?.amenityId || !communityId) return;

    try {
      const response = await ActionRequest({
        variables: {
          amenityId: params?.amenityId,
          communityIdList: communityId,
          type: params.typeBtn,
        },
      });

      const data = response?.data?.amenityManagerButtons;
      if (!data) throw new Error();

      setError(null);
      onSuccess?.();
    } catch (error) {
      setError(error as any);
      onError?.();
    }
  };
  return {onSubmit, error, loading};
};

export const useResidentActionsAmenity = (params: {
  communityId?: string;
  onSuccess?: (type?: GQLAmenityManageAction) => void;
  onError?: () => void;
}) => {
  const {onSuccess, onError, communityId} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionRequest, {loading}] = useMutation<amenityResidentButtonsResponseType, amenityResidentButtonsRequestType>(
    amenityResidentButtonsQuery,
  );
  const submit = async ({
    amenityId,
    typeBtn,
    communityIdList,
  }: {
    amenityId: string;
    typeBtn: GQLAmenityManageAction;
    communityIdList: string[];
  }) => {
    const response = await ActionRequest({
      variables: {
        amenityId: amenityId,
        communityIdList: communityIdList,
        action: typeBtn,
      },
    });

    const data = response?.data?.amenityManagerButtons;
    onSuccess?.();
    if (!data) throw new Error();
  };

  const onSubmit = async ({
    amenityId,
    typeBtn,
    communityIds,
  }: {
    amenityId?: string;
    typeBtn: GQLAmenityManageAction;
    communityIds?: Array<string>;
  }) => {
    if (!amenityId) return;

    try {
      if (communityIds) await submit({communityIdList: communityIds, typeBtn, amenityId});
      else {
        if (!communityId) return;
        await submit({communityIdList: [communityId], typeBtn, amenityId});
      }

      setError(null);
      onSuccess?.(typeBtn);
    } catch (error) {
      setError(error as any);
      onError?.();
    }
  };

  return {onSubmit, error, loading};
};

export const isAmenityInCommunity = (options: {amenity?: Partial<Amenity>; commId?: string; default?: boolean}) => {
  return (
    options?.default ||
    !!options?.amenity?.Published?.find((el) => el.objectId === options.commId || el.id === options.commId)
  );
};
export type TDataCreator = {
  verified: string;
  reviews: string;
  isOnline?: boolean;
  avatar: string;
  firstName: string;
  lastName: string;
};

export const useDataCreator = (creator?: User): TDataCreator => {
  const {t} = useTranslation();
  const {contacts} = useGetContactsForChat({
    initUsers: creator ? [creator] : null,
    msgs: [],
    contactsIds: creator ? [creator.objectId] : undefined,
  });

  const verified = creator?.isVerified ? t('requests:verified') : '';
  const reviews = t('requests:reviews', {count: creator?.Reviews?.count || 0});
  return {
    verified,
    reviews,
    isOnline: contacts?.[0]?.isOnline,
    avatar: getResize(creator?.Avatar?.file?.url, 'lg') || '',
    firstName: creator?.firstName || '',
    lastName: creator?.lastName || '',
  };
};

export const useGetAmenityNoCache = ({id}: {id?: string}) => {
  const {data, ...response} = useQuery<GetAmenityResType, GetAmenityReqType>(GetAmenityRequest, {
    variables: {
      id: id || '',
    },
    skip: !id,
    ssr: true,
    fetchPolicy: 'no-cache',
  });

  return {...response, data: data?.amenity};
};

export const useSetAmenityStatusDeleted = (options: {id?: string; onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [UpdateAmenityRequest, {loading}] = useMutation<UpdateAmenityResponseType, UpdateAmenityRequestType>(
    UpdateAmenityQuery,
  );
  const {id, onSuccess} = options;

  const onDelete = async (custId?: string): Promise<boolean> => {
    try {
      if (!id && !custId) {
        throw new Error('error:id');
      }

      const response = await UpdateAmenityRequest({
        variables: {id: id || custId || '', fields: {isDeleted: true}},
      });

      const data = response?.data;
      if (!data) throw new Error();

      setSuccess('success:updatedAmenity');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      onSuccess?.();
    }

    return true;
  };

  return {
    onDelete,
    success,
    loading,
    error,
  };
};

export type orderAmenityChangeData = {
  guests: number;
  onChangeGuests: (v: number) => void;
  startDate: {
    value: Date | null;
    set: (d: Date) => void;
  };
};

export type TResCreateAmenityOrder = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error?: {message?: string} | null;
  success?: string | null;
  preCalcData?: PreAmenityOrderCalcDataT;
  changeData: orderAmenityChangeData;
  orderInfo?: string;
};

type TOptsCreateAmenityOrderType = (options: {
  communityId?: string;
  amenityId?: string;
  rewardType?: RewardType | null;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResCreateAmenityOrder;

export const useCreateAmenityOrder: TOptsCreateAmenityOrderType = (options) => {
  const [error, setError] = useState<{message?: string} | null>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [guests, setGuests] = useState<number>(0);
  const [startdate, setStartdate] = useState<Date | null>(null);
  const [orderInfo, setOrderInfo] = useState<string>('');

  const handleSetStartdate = (t: Date) => setStartdate(t);
  const onChangeGuests = (v: number) => setGuests(v);
  // const [preCalcData, setPreCalcData] = useState<PreOrderCalcDataT>(preCalcInitial);

  const [CreateOrderRequest, {loading}] = useMutation<MakeOrderResponseType, MakeOrderRequestType>(MakeOrder);
  // const [getCalcOrderRequest] = useMutation<PreOrderCalcDataResponseType, PreOrderCalcDataRequestType>(
  //     preOrderCalcData,
  // );

  const preCalc = async (): Promise<boolean> => {
    try {
      if (options?.rewardType !== RewardType.free) {
        return false;
      }
      // const response = await getCalcOrderRequest({
      //   variables: {
      //     communityId: options.communityId,
      //     amenityId: options.amenityId,
      //   },
      // });
      // const data = response.data?.preOrderCalcData;
      // if (data) {
      //   setPreCalcData(data);
      // }
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  useEffect(() => {
    preCalc();
  }, []);
  const onSubmit = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!options?.communityId || !options?.amenityId) {
        return false;
      }
      if (!startdate) {
        throw new Error('error:datesRequests');
      }

      const response = await CreateOrderRequest({
        variables: {
          communityId: options.communityId,
          objectId: options.amenityId,
          orderType: GQLOrderType.amenity,
          startTime: startdate,
          guests: guests,
        },
      });

      const data = response?.data?.makeOrder?.order;
      if (!data) throw new Error();
      setOrderInfo(data);
      setSuccess('success:createOrderAmenity');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  const changeData = {
    guests,
    onChangeGuests,
    startDate: {
      value: startdate,
      set: handleSetStartdate,
    },
  };

  return {
    onSubmit,
    success,
    loading: isLoading,
    error,
    changeData,
    orderInfo,
  };
};

type checkAvailabilityOptions = {
  amenity?: Partial<TAmenity>;
  userId?: string;
  strictCheck?: boolean;
};

export type amenityAvailableDataT = {
  check: boolean;
  text?: string;
  busyDates?: Date[];
};

export enum notAvRequestText {
  requested = 'requested',
}

export const useCheckAmenityForAvailability = (options: checkAvailabilityOptions) => {
  const notAvailableStatuses = [GQLOrderStatuses.pending, GQLOrderStatuses.booked];
  const where: GQLOrderWhereInput = {
    AND: [
      {status: {in: notAvailableStatuses}},
      {Amenity: {have: {objectId: {equalTo: options.amenity?.objectId}}}},
      {Requester: {have: {objectId: {equalTo: options.userId}}}},
    ],
  };
  const {count, data, loading} = useOrders({where});
  const busyDates = data?.map((el) => new Date(el.startTime));
  let availableData = {
    check: true,
    text: '',
    busyDates,
  };
  if (options.strictCheck && count) {
    availableData = {
      check: false,
      text: notAvRequestText.requested,
      busyDates,
    };
  }

  return {availableData: availableData as amenityAvailableDataT, loading};
};
