import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const A = styled(Link)`
  text-decoration: none;
  color: initial;
`;

interface IProps {
  to: string;
}

export const BlankReactLink: React.FC<IProps> = ({to, children}) => {
  return <A to={to}>{children}</A>;
};
