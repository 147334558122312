import {TypeCommunity} from '../types/auth';
import {route} from '../constants/routes';

export const getRouteStepCreate = ({
  isProfile,
  isPlan,
  isCommunity,
  typeUser,
}: {
  isProfile?: boolean;
  isPlan?: boolean;
  isAddCard?: boolean;
  isCommunity?: boolean;
  typeUser?: TypeCommunity;
}) => {
  if (typeUser === TypeCommunity.resident) {
    return isProfile ? route.pageUser.path : route.profileCreateResident.path;
  }

  if (!isProfile && isProfile !== undefined) return route.profileCreateManager.path;
  if (!isPlan && isPlan !== undefined) return route.selectPlan.path;
  //if (!isAddCard && isAddCard !== undefined) return route.paymentCommunity.path;
  if (!isCommunity && isCommunity !== undefined) return route.createCommunity.path;
};

export const analyticsTrackFN = (key: string, object?: any) => {
  // eslint-disable-next-line
  // @ts-ignore
  analytics.track(key, object);
};
