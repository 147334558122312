import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {AddMenuContainer, AddMenuLink, IconWrapper, MobileAddMenuContainer, MobileAddMenuLink} from './styles';
import {PlanOptionsValues} from '../../../../constants/plan';
import {route} from '../../../../constants/routes';
import {useHasPlanOption} from '../../../../helpers/community';
import {currentCommunity} from '../../../../states/community';
import {TypeUserStateType} from '../../../../states/typeUser';
import {TypeCommunity} from '../../../../types/auth';
import {PermissionsList} from '../../../../types/settings';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {icon} from '../../../../ui-kit/Icon/Svg/types';

import {useLinks} from '../../../../hooks/common';
import {BetaLogo} from '../../../Feed/Notification/AIPromotion';

type AddMenuPropsType = {
  onClose: () => void;
  typeUser: TypeUserStateType;
  permissions?: PermissionsList[];
};

export const AddMenu: React.FC<AddMenuPropsType> = ({onClose, typeUser, permissions}) => {
  const [communitState] = useRecoilState(currentCommunity);
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const links = [
    {
      type: 'stars',
      label: t('addMenu.promotion'),
      link: getLink(`${route.generateItem.path}`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.listingsFeed),
      icon: <BetaLogo />,
    },
    {
      type: 'umbrella',
      label: t('addMenu.provideService'),
      link: getLink(`${route.createItem.path}?type=service`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.listingsFeed),
    },
    {
      type: 'star-2',
      label: t('addMenu.rentItem'),
      link: getLink(`${route.createItem.path}?type=rent`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.listingsFeed),
    },
    {
      type: 'cart',
      label: t('addMenu.sellItem'),
      link: getLink(`${route.createItem.path}?type=sell`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.listingsFeed),
    },
    {
      type: 'entrance',
      label: t('addMenu.makeRequest'),
      link: getLink(`${route.loop.path}?type=request`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.communityFeed),
    },
    {
      type: 'sticker',
      label: t('addMenu.makePost'),
      link: getLink(`${route.loop.path}?type=message`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.communityFeed),
    },
    {
      type: 'poll',
      label: t('addMenu.pollYourNeighbours'),
      link: getLink(`${route.loop.path}?type=poll`),
      isLinkVisible: useHasPlanOption(PlanOptionsValues.communityFeed),
    },
    {
      type: 'calendar',
      label: t('addMenu.createEvent'),
      link: getLink(route.createEvent.path),
      isLinkVisible:
        (useHasPlanOption(PlanOptionsValues.events) &&
          (typeUser === TypeCommunity.manager ||
            (typeUser === TypeCommunity.admin && permissions?.includes(PermissionsList.manageEvent)))) ||
        communitState?.allowEvents,
    },
    {
      type: 'person-add',
      label: t('addMenu.invitePeople'),
      link: getLink(route.inviteCommunity.path),
      isLinkVisible:
        typeUser === TypeCommunity.manager ||
        (typeUser === TypeCommunity.admin && permissions?.includes(PermissionsList.managePeople)),
    },
  ];

  return (
    <>
      <AddMenuContainer>
        {links.map((el) =>
          el.isLinkVisible ? (
            <AddMenuLink to={el.link} key={el.type} onClick={onClose}>
              <IconWrapper>
                <IconSvg type={el.type as icon} /> <span>{el.label}</span>
              </IconWrapper>
              {el?.icon && el.icon}
            </AddMenuLink>
          ) : (
            <></>
          ),
        )}
      </AddMenuContainer>
      <MobileAddMenuContainer>
        {links.map((el) =>
          el.isLinkVisible ? (
            <MobileAddMenuLink to={el.link} key={el.type} onClick={onClose}>
              <IconWrapper>
                <IconSvg type={el.type as icon} /> <span>{el.label}</span>
              </IconWrapper>
              {el?.icon && el.icon}
            </MobileAddMenuLink>
          ) : (
            <></>
          ),
        )}
      </MobileAddMenuContainer>
    </>
  );
};
