import React from 'react';
import {
  MemberSkeletonWrapper,
  PairWrapper,
  PairMiddleWrapper,
  PeopleSkeletonSectionWrapper,
  PeopleSkeletonWrapper,
  ContactSkeletonWrapper,
} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const PeopleSkeleton: React.FC = () => {
  return (
    <PeopleSkeletonWrapper>
      <MemoLoadingSkeleton width={36} height={36} circle={true} />
      <PairWrapper>
        <MemoLoadingSkeleton width={110} height={16} />
        <MemoLoadingSkeleton width={50} height={10} />
      </PairWrapper>
    </PeopleSkeletonWrapper>
  );
};

export const ContactSkeleton: React.FC = () => {
  return (
    <ContactSkeletonWrapper>
      <MemoLoadingSkeleton width={36} height={36} circle={true} />
      <PairWrapper>
        <MemoLoadingSkeleton width={110} height={16} />
      </PairWrapper>
    </ContactSkeletonWrapper>
  );
};

export const PeopleSkeletonLoader: React.FC = () => {
  return (
    <PeopleSkeletonSectionWrapper>
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
    </PeopleSkeletonSectionWrapper>
  );
};

export const PeopleSkeletonSection: React.FC = () => {
  return (
    <PeopleSkeletonSectionWrapper>
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
      <PeopleSkeleton />
    </PeopleSkeletonSectionWrapper>
  );
};

export const MemberSkeleton: React.FC = () => {
  return (
    <MemberSkeletonWrapper>
      <MemoLoadingSkeleton width={36} height={36} circle={true} />
      <PairWrapper>
        <MemoLoadingSkeleton width={110} height={16} />
        <MemoLoadingSkeleton width={50} height={10} />
      </PairWrapper>
    </MemberSkeletonWrapper>
  );
};

export const MemberSkeletonShort: React.FC = () => {
  return (
    <MemberSkeletonWrapper>
      <MemoLoadingSkeleton width={36} height={36} circle={true} />
      <PairMiddleWrapper>
        <MemoLoadingSkeleton width={110} height={16} />
      </PairMiddleWrapper>
    </MemberSkeletonWrapper>
  );
};
