import React from 'react';
import {CardProps, ListingType, PickDateTypes, RewardType, PricePeriodType, CreditInfoT, DateLabelProps} from './types';
import {
  CardContentWrapper,
  CardInfoText,
  CardTitle,
  CardWrapper,
  CreditBlockWrapper,
  MarkDeleted,
  CloseBtn,
  CreditRow,
  DatesInfoWrapper,
  DateInfoRow,
  ReqInfoLabel,
  ReqInfoValue,
  ListingInfo,
} from './styles';
import {CardTags} from './CardTags';
import {DateLabel} from './DateBlock/DateLabel';
import {PaymentLabel} from './PaymentLabel';
import {PaymentInfo} from './PaymentInfo';
import {CardButton} from './CardButton';
import {DateTimePicker} from './DateBlock/DateTimePicker';
import {Text} from '../Typography/Text';
import {IconSvg} from '../Icon/Svg';
import {format} from 'date-fns';

const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};
export const Card: React.FC<CardProps> = ({
  title,
  descr,
  image,
  payments,
  paymentLabel,
  buttonLabel,
  dateLabel,
  rewardType,
  tags,
  listingType,
  pickerLabels,
  pickerPlaceholders,
  smallPicker,
  pricePeriod,
  isDeleted,
  durationUnit,
  captions,
  buttonSelectText,
  showCredit,
  toggleCredit,
  creditInfo,
  textMode,
  dateInfoLabels,
  handlePushToListing,
  Gallery,
  Item,
}) => {
  const isFreeOrCoffee = rewardType === RewardType.free || rewardType === RewardType.coffee;
  return (
    <CardWrapper>
      {isDeleted && (
        <MarkDeleted>
          <Text size={12} color={'primary'}>
            Deleted
          </Text>
        </MarkDeleted>
      )}
      {Boolean(Gallery && Item) && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-ignore */}
          <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-ignore */}
            <Item original={image} width={'1024'} height={'768'}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore */}
              {({ref, open}) => (
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  className={'pswp_image'}
                  onClick={open}
                  src={image}
                  alt={''}
                />
              )}
            </Item>
          </Gallery>
        </>
      )}
      <CardContentWrapper>
        <ListingInfo onClick={handlePushToListing}>
          <CardTitle>{title}</CardTitle>
          <CardTags tags={tags} />
        </ListingInfo>

        {!isDeleted && (
          <>
            {textMode ? (
              <DatesTextInfo
                listingType={listingType}
                pricePeriod={pricePeriod}
                dateLabel={dateLabel}
                dateInfoLabels={dateInfoLabels}
              />
            ) : (
              <>
                {!showCredit && (
                  <>
                    {listingType === ListingType.sell ? (
                      <DateTimePicker
                        date={dateLabel?.dates[0]}
                        pickType={PickDateTypes.dateTime}
                        canEdit={dateLabel.canEdit}
                        pickerLabels={pickerLabels}
                        pickerPlaceholders={pickerPlaceholders}
                        smallPicker={smallPicker}
                        durationUnit={durationUnit}
                        captions={captions}
                        buttonSelectText={buttonSelectText}
                      />
                    ) : (
                      <>
                        {pricePeriod === PricePeriodType.hour ? (
                          <>
                            <DateTimePicker
                              date={dateLabel?.dates[0]}
                              duration={dateLabel?.duration}
                              pickType={PickDateTypes.TimeAndDuration}
                              canEdit={dateLabel.canEdit}
                              pickerLabels={pickerLabels}
                              pickerPlaceholders={pickerPlaceholders}
                              smallPicker={smallPicker}
                              durationUnit={durationUnit}
                              buttonSelectText={buttonSelectText}
                              captions={captions}
                            />
                          </>
                        ) : (
                          <DateLabel
                            from={dateLabel.from}
                            to={dateLabel.to}
                            dates={dateLabel?.dates || [null, null]}
                            setDates={dateLabel.setDates}
                            canEdit={dateLabel.canEdit}
                            small={smallPicker}
                            durationUnit={durationUnit}
                            buttonSelectText={buttonSelectText}
                            captions={captions}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {!textMode ? (
              <>
                {' '}
                {!showCredit ? (
                  <PaymentLabel
                    rewardType={rewardType}
                    descr={paymentLabel.descr}
                    title={paymentLabel.title}
                    onClick={() => toggleCredit?.(true)}
                  />
                ) : (
                  <CreditBlock close={() => toggleCredit?.(false)} creditInfo={creditInfo} />
                )}
              </>
            ) : null}
            {!isFreeOrCoffee && <PaymentInfo payments={payments} />}
            <CardButton
              primary={buttonLabel.primary}
              secondary={buttonLabel.secondary}
              type={'primary'}
              disabled={buttonLabel?.disabled}
            />
            <CardInfoText>{descr}</CardInfoText>
            <CardButton primary={buttonLabel.primary} secondary={buttonLabel.secondary} type={'secondary'} />
          </>
        )}
      </CardContentWrapper>
    </CardWrapper>
  );
};

type CreditBlockProps = {
  close?: () => void;
  creditInfo?: CreditInfoT;
  type?: ListingType;
};

export const CreditBlock: React.FC<CreditBlockProps> = ({close, creditInfo}) => {
  return (
    <CreditBlockWrapper>
      <CloseBtn onClick={close}>
        <IconSvg type={'close'} width={'20px'} height={'20px'} />
      </CloseBtn>
      {creditInfo &&
        Object.values(creditInfo)?.map(
          (credit) =>
            credit.value && (
              <CreditRow>
                <ReqInfoLabel>{credit.label}</ReqInfoLabel>
                <ReqInfoValue>{credit.value}</ReqInfoValue>
              </CreditRow>
            ),
        )}
    </CreditBlockWrapper>
  );
};

type DatesTextInfoPropsT = {
  listingType?: ListingType;
  pricePeriod?: PricePeriodType;
  dateLabel?: DateLabelProps;
  dateInfoLabels?: {
    duration: string;
    from: string;
    until: string;
    hours: string;
  };
};
export const DatesTextInfo: React.FC<DatesTextInfoPropsT> = ({dateLabel, pricePeriod, dateInfoLabels}) => {
  const from = dateLabel?.dates?.[0] && new Date(dateLabel.dates[0]);
  const until = dateLabel?.dates?.[1] && new Date(dateLabel.dates[1]);
  const fromValue = from ? `${format(from, 'p')} on ${format(from, 'P')}` : '';
  const untilValue = until ? `${format(until, 'p')} on ${format(until, 'P')}` : '';

  const onDate = fromValue === untilValue;
  return (
    <DatesInfoWrapper>
      {pricePeriod === PricePeriodType.hour && (
        <DateInfoRow>
          <ReqInfoLabel>{dateInfoLabels?.duration}</ReqInfoLabel>
          <ReqInfoValue>
            {dateLabel?.duration} {dateInfoLabels?.hours}
          </ReqInfoValue>
        </DateInfoRow>
      )}
      {onDate ? (
        <DateInfoRow>
          <ReqInfoLabel>{dateLabel?.from.title}</ReqInfoLabel>
          <ReqInfoValue>{fromValue}</ReqInfoValue>
        </DateInfoRow>
      ) : (
        <>
          <DateInfoRow>
            <ReqInfoLabel>{dateInfoLabels?.from}</ReqInfoLabel>
            <ReqInfoValue>{fromValue}</ReqInfoValue>
          </DateInfoRow>
          <DateInfoRow>
            <ReqInfoLabel>{dateInfoLabels?.until}</ReqInfoLabel>
            <ReqInfoValue>{untilValue}</ReqInfoValue>
          </DateInfoRow>
        </>
      )}
    </DatesInfoWrapper>
  );
};
