import styled, {css} from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Image} from '../styles';

const listingLabelStyles = css`
  width: 57px;
  height: 57px;
  border-radius: 100%;
  box-shadow: none;
  border: none !important;
  & > div {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
  }
`;

const listingImageStyles = css`
  border-radius: 100% !important;
`;

export const LabelPhoto = styled.label<{
  $isUndercard?: boolean;
  typeCursor?: string;
  width?: string;
  height?: string;
  $bordered?: boolean;
  $isListing?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || 'auto'};
  cursor: ${({typeCursor}) => typeCursor || 'inherit'};
  opacity: ${({$isUndercard}) => ($isUndercard ? 0.7 : 1)};
  background-color: ${({$isUndercard, theme}) =>
    $isUndercard ? theme.palette.background.secondary : theme.palette.background.primary};
  ${({$isListing}) => $isListing && listingLabelStyles};
`;

export const CustomImage = styled(Image)<{$isListing?: boolean}>`
  ${Media.down.m} {
    border-radius: 12px;
  }
  ${({$isListing}) => $isListing && listingImageStyles};
`;

const BorderedLabel = css`
  border: 1px solid ${({theme}) => theme.palette.text.septenary};
  border-radius: 12px;
`;

export const CustomLabelPhoto = styled(LabelPhoto)`
  border-radius: 12px;
  border: 1px solid #00000020;
  ${({$bordered}) => $bordered && BorderedLabel};
`;
export const IconPhoto = styled(IconSvg)`
  pointer-events: none;
`;

export const InnerIconPhoto = styled.div<{
  $showError?: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({$showError, theme}) => $showError && ` border: 2px solid ${theme.palette.button.danger}`}
`;

export const InnerImagePhoto = styled(InnerIconPhoto)``;
