import React from 'react';
import {StateNotificationSettingsType} from '../../../types/notifications';
import {FormEditType, FormType} from '../../../types/settings';
import {PageWrapper, SettingsWrapper} from '../commonStyles';
import {SettingsHeader} from '../components';
import {useTranslation} from 'react-i18next';
import {SettingsLangAndCurrency} from './SettingsLangAndCurrency';
import {ISettingLangAndCurrency, ISettingProfileViewOff} from '../../../hooks/settings';
import {SettingsProfileView} from './SettingsProfileView';
import {getSettingsNotifications} from '../../../helpers/notifications';
import {SettingsNotifications} from '../../../containers/Settings/Notifications';
import {GoBack} from '../../common/Buttons/GoBack';

interface NotificationsSettingsProps {
  notificationSettings: StateNotificationSettingsType;
  isManager: boolean;
  editNotifications: FormEditType;
  formNotifications: FormType<StateNotificationSettingsType>;
  langData: ISettingLangAndCurrency;
  profileViewsData: ISettingProfileViewOff;
  userId?: string;
}

export const PreferencesSettings: React.FC<NotificationsSettingsProps> = ({
  notificationSettings,
  isManager,
  editNotifications,
  formNotifications,
  langData,
  profileViewsData,
  userId,
}) => {
  const {t} = useTranslation();
  const notifications = getSettingsNotifications(t);
  const goBack = () => window?.history.back();
  return (
    <>
      <GoBack goBack={goBack} title={t('settings:notifications.title')} />
      <PageWrapper>
        <SettingsWrapper max={650}>
          <SettingsHeader
            title={t('settings:notifications.title')}
            subtitle={t('settings:notifications.subtitle')}
            hideMob
          />
          <SettingsLangAndCurrency langData={langData} />
          <SettingsNotifications
            notificationSettings={notificationSettings}
            isManager={isManager}
            edit={editNotifications}
            form={formNotifications}
            notificationsList={notifications}
            userId={userId}
          />
          <SettingsProfileView data={profileViewsData} />
        </SettingsWrapper>
      </PageWrapper>
    </>
  );
};
