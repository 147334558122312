import React from 'react';
import {ContentReviewsProps} from '../types';
import {Tab as TabItem} from '../../../ui-kit/Tabs/Tab';
import {Tabs as TabMenu} from '../../../ui-kit/Tabs/Tabs';
import {useTranslation} from 'react-i18next';
import {ReviewContentWrapper, ReviewLabelsWrapper, ReviewsSectionWrapper, ReviewWrapper, ReviewLabel} from '../styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {Text} from '../../../ui-kit/Typography/Text';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {TReview} from '../../../types/reviews';
import {Link} from '../../../ui-kit/Typography/Link';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';
import {getResize} from '../../../helpers/file';

const ReviewsSection: React.FC<{reviews?: TReview[]; given?: boolean}> = ({reviews, given}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  return (
    <ReviewsSectionWrapper>
      {reviews?.map((i) => {
        const user = given ? i.User : i.Author;

        if (Number(i?.stars) === 0 && !i.text) return null;

        return (
          <ReviewWrapper key={i?.id}>
            <Link to={getLink(route.profile.get({id: user?.id ?? ''}))}>
              <Avatar size={'sm'} src={getResize(user?.Avatar?.file?.url, 'md')} $isOnline={user?.isOnline} />
            </Link>
            <ReviewContentWrapper>
              <Text variant={'primary'} size={14} weight={'medium'} color={'octonary'}>{`${user?.firstName} ${
                user?.lastName || ''
              }`}</Text>
              <ReviewLabelsWrapper>
                {Number(i?.stars) > 0 && (
                  <>
                    <IconSvg type={'star'} stroke={'yellow'} fill={'yellow'} />
                    <Text variant={'primary'} size={12} weight={'medium'} color={'septenary'}>
                      {t('requests:stars', {count: i.stars})}
                    </Text>
                  </>
                )}
                <Text variant={'primary'} size={12} weight={'medium'} color={'septenary'}>
                  {i.createdAt && t('common:dateMMMM_yyyy', {date: new Date(i.createdAt)})}
                </Text>
              </ReviewLabelsWrapper>
              {i.text && (
                <Text variant={'primary'} size={12} color={'duodecimal'}>
                  {i.text}
                </Text>
              )}
            </ReviewContentWrapper>
          </ReviewWrapper>
        );
      })}
    </ReviewsSectionWrapper>
  );
};

export const Reviews: React.FC<ContentReviewsProps> = ({items}) => {
  const {t} = useTranslation();
  const tReceived = t('people:profile.common.received');
  const tGiven = t('people:profile.common.given');
  const received = items?.received;
  const given = items?.given;

  if (!!received?.length && !!given?.length)
    return (
      <TabMenu variant="primary">
        <TabItem label={`${tReceived} (${received?.length}) `}>
          <ReviewsSection reviews={received} />
        </TabItem>
        <TabItem label={`${tGiven} (${given?.length}) `}>
          <ReviewsSection reviews={given} given={true} />
        </TabItem>
      </TabMenu>
    );

  return (
    <>
      {!!received?.length && (
        <>
          <ReviewLabel>{`${tReceived} (${received?.length}) `}</ReviewLabel>
          <ReviewsSection reviews={received} />
        </>
      )}
      {!!given?.length && (
        <>
          <ReviewLabel>{`${tGiven} (${given?.length}) `}</ReviewLabel>
          <ReviewsSection reviews={given} given={true} />
        </>
      )}
    </>
  );
};
