import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {InnerCard, InnerTitle, Title, Address, Description} from './styles';

export const SceletonCard: React.FC = () => {
  return (
    <InnerCard to={'#'}>
      <InnerTitle>
        <Title>
          <Skeleton width={'80%'} />
        </Title>
      </InnerTitle>
      <Address>
        <Skeleton />
      </Address>
      <Description>
        <Skeleton />
      </Description>
    </InnerCard>
  );
};
