import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';

import {PageResident} from '../../../components/Events/PageResident';
import {SearchKey} from '../../../constants/routes';
import {GQLEventWhereInput} from '../../../graphql.schema';
import {getDateHourBorder, getEventTypeParams} from '../../../helpers/event';
import {CommunityStateType, userPermissions} from '../../../states/community';
import {typeUser as typeUserState} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {PermissionsList} from '../../../types/settings';

import {useGetCurrencyValue} from '../../../hooks/currency';
import {useEventPageContent, useSetEventStatusDeleted} from '../../../hooks/event';
import {entities, useReport} from '../../../hooks/report';
import {useUrlSearchManager} from '../../../hooks/urlSearchManager';
import {EventRepeatType} from '../../../queries/types/event';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
};
export const PageResidentContainer: React.FC<TProps> = ({viewerId, community}) => {
  const userType = useRecoilValue(typeUserState);
  const urlSearch = useUrlSearchManager();
  const searchType = urlSearch.get(SearchKey.type);
  const today = getDateHourBorder();
  const [permissions] = useRecoilState(userPermissions);
  const [searchParams, setSearchParams] = useState<GQLEventWhereInput[]>([
    !searchType
      ? {
          OR: [
            {
              AND: [
                {
                  endTime: {
                    greaterThan: today,
                  },
                },
                {OR: [{eventType: {equalTo: EventRepeatType.one}}, {eventType: {exists: false}}]},
              ],
            },
            {
              AND: [
                {
                  expiresDate: {
                    greaterThan: today,
                  },
                },
                {eventType: {notEqualTo: EventRepeatType.one}},
                {eventType: {exists: true}},
              ],
            },
          ],
        }
      : getEventTypeParams(searchType),
  ]);
  const location = useLocation();
  const {
    loadingAllEvents,
    loadingFeaturedEvents,
    refetchAllEvents,
    refetchFeaturedEvents,
    hasMoreAllEvents,
    fetchDataAllEvents,
    communityEvents,
    liked,
    onLike,
  } = useEventPageContent(searchParams, community);
  const {getSignValue} = useGetCurrencyValue();

  const actions = useReport(entities.event);

  useEffect(() => {
    refetchFeaturedEvents();
    refetchAllEvents();
  }, [location]);

  const canAdd =
    (permissions?.includes(PermissionsList.manageEvent) && userType === TypeCommunity.admin) || community?.allowEvents;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityEvents.length && refetchFeaturedEvents({first: communityEvents.length});
  //     communityEvents.length && refetchAllEvents({first: communityEvents.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityEvents.length]);

  const onSuccess = () => {
    refetchFeaturedEvents();
    refetchAllEvents();
  };

  const {onDelete} = useSetEventStatusDeleted({onSuccess});

  return (
    <PageResident
      onDelete={onDelete}
      community={community}
      loading={loadingAllEvents || loadingFeaturedEvents}
      allEvents={communityEvents}
      fetchDataAllEvents={fetchDataAllEvents}
      hasMoreAllEvents={hasMoreAllEvents}
      setSearchParams={setSearchParams}
      userId={viewerId}
      reportActions={actions}
      getSignValue={getSignValue}
      canAdd={canAdd}
      typeUser={userType}
      liked={liked}
      onLike={onLike}
    />
  );
};
