import React from 'react';
import Helmet from 'react-helmet';
import {useLocation} from 'react-router';
import {SITE_URL} from '../../constants/env';
import {paths} from '../../constants/routes';
import {OGCommunityInfo, OGEventInfo, OGOrderInfo} from '../../containers/OGPages';
import {invidKey} from '../../helpers/common';
import {getAliasFromPath} from '../../helpers/community';
import {getToken} from '../../libs/auth';
import {OGDataT} from '../../types/common';

const defaults = {
  title: 'Hangeh App',
  description:
    'Hangeh is an integrated localized engagement platform that allows residents to seamlessly connect, share and collaborate.',
};

type TOpenGraph = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  imageWidth?: string;
  imageHeight?: string;
};

type TSeoProps = {
  title?: string;
  description?: string;
  keywords?: string;
  og?: TOpenGraph;
};

export const Seo: React.FC<TSeoProps> = ({
  title = defaults.title,
  description = defaults.title,
  keywords = defaults.description,
  og = {} as TOpenGraph,
}) => {
  const {pathname} = useLocation();
  const url = SITE_URL + pathname;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" content={og.title || title} />
      <meta name="og:description" content={og.description || description} />
      <meta name="og:url" content={url} />
      {og.image && <meta name="og:image" content={og.image} />}
      {og.imageWidth && <meta name="og:image:width" content={og.imageWidth} />}
      {og.imageHeight && <meta property="og:image:height" content={og.imageHeight} />}

      {og.image && <meta name="twitter:card" content="summary_large_image" />}
      {og.title && <meta name="twitter:title" content={og.title} />}
      {og.description && <meta name="twitter:description" content={og.description} />}
      {og.image && <meta name="twitter:image" content={og.image} />}

      {og.title && <meta itemProp="name" content={og.title} />}
      {og.description && <meta itemProp="description" content={og.description} />}
      {og.image && <meta itemProp="image" content={og.image} />}
    </Helmet>
  );
};

export const SeoHelper: React.FC<{OGData?: OGDataT | null}> = ({OGData}) => {
  const token = getToken();
  const {pathname} = useLocation();
  const windowURL = typeof window !== 'undefined' ? window?.reqURL : '';
  const windowOGData = typeof window !== 'undefined' ? window?.OGData : null;
  // const urlSearch = new URLSearchParams(search);
  // const orderId = urlSearch.get(SearchKey.orderId);
  // const urlOrderId = getOrderFromUrl(windowURL);
  const alias = getAliasFromPath(pathname);
  const urlAlias = windowURL ? getAliasFromPath(windowURL) : '';

  if (token) return null;
  if (pathname?.includes(paths.eventView)) return <OGEventInfo event={OGData?.event || windowOGData?.event} />;
  if (pathname?.includes(invidKey)) return <OGCommunityInfo alias={alias || urlAlias} isJoin={true} />;
  if (pathname?.includes(paths.people) || pathname?.includes(paths.loop))
    return <OGCommunityInfo alias={alias || urlAlias} />;
  if (pathname?.includes(paths.messages)) return <OGOrderInfo item={OGData?.item || windowOGData?.item} />;
  return null;
};
