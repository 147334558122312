import styled from 'styled-components';
import {WrapperProfileCard} from '../HomePageUser/styles';
import {Link} from 'react-router-dom';
import {Media, theme} from '../../ui-kit/theme';
import {ProfileCard as ProfileIntro} from '../HomePageUser/styles';

export const WrapperCommunityCard = styled(WrapperProfileCard)`
  border-bottom: none;
  position: relative;
  ${Media.down.l} {
    padding-top: 0px;
  }
`;

export const ProfileCard = styled(ProfileIntro)`
  ${Media.down.l} {
    display: none;
  }
`;

export const UpdatePhotoText = styled.span`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 10px;
  text-align: center;
  text-decoration: underline currentColor;
  color: ${({theme}) => theme.palette.text.quaternary};
  cursor: pointer;
`;

export const PostPageWrapper = styled.div`
  max-width: 1000px;
  padding: 60px 30px 0px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${Media.down.m} {
    padding: 0;
  }
`;

export const PostPageNavLine = styled.div`
  width: 100%;
  display: flex;
  ${Media.down.m} {
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._11};
  }
`;
export const PostPageNavLink = styled(Link)`
  width: 100%;
  padding: 8px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  color: unset;
`;

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  cursor: initial;

  ${Media.down.s} {
    justify-content: center;
    align-items: flex-end;
  }
`;

export const PopupWrapper = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin-bottom: 40px;
  max-height: 75vh;
  overflow-y: auto;
`;

export const InteractionWrapper = styled.div`
  position: relative;
  width: 330px;
  min-height: 400px;
  background-color: ${theme.palette.background.primary};
  display: flex;
  justify-content: center;
  padding: 15px;
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
`;

export const UserOption = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 0.5px;
  padding: 6px 11px;
  border-radius: 4px;
  border-color: ${theme.palette.grayscale._11};
  margin-top: 7px;
`;
export const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 7px;
  cursor: pointer;
`;

export const ProfileLink = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
`;

export const MessageLink = styled(Link)`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${theme.palette.grayscale._3};
  padding: 1px 6px;
`;

export const NavLineText = styled.span`
  ${theme.font.primary.regular};
  font-size: 16px;
  margin-left: 15px;
`;

export const PostPageContainer = styled.div`
  width: 100%;
  ${Media.down.m} {
    padding: 0 15px;
    box-sizing: border-box;
  }
`;
