import {gql} from '@apollo/client';
import UserFragment from './user';
import PaymentPlanFragment from './paymentPlan';
import {CommunityShortFragment} from './community';
import {OrderFragment} from './order';

export const TransactionFragment = gql`
  fragment TransactionFragment on Transaction {
    id
    objectId
    createdAt
    updatedAt
    User {
      ...UserFragment
    }
    outerId
    date
    amount
    type
    descr
    data
    status
    Community {
      ...CommunityShortFragment
    }
    Order {
      ...OrderFragment
    }
  }

  ${UserFragment}
  ${CommunityShortFragment}
  ${OrderFragment}
`;

export const SubscrPaymentFragment = gql`
  fragment SubscrPaymentFragment on SubscrPayment {
    id
    objectId
    createdAt
    updatedAt
    User {
      ...UserFragment
    }
    Subscr {
      id
      objectId
      PaymentPlan {
        ...PaymentPlanFragment
      }
      paymentPeriod
    }
    outerId
    date
    amount
    type
    descr
    data
    status
  }

  ${UserFragment}
  ${PaymentPlanFragment}
`;
