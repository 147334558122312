import Scrollbar from 'react-scrollbars-custom';
import styled, {css, CSSProperties} from 'styled-components';
import {fontPrimaryMedium, fontPrimaryReg, fontPrimarySemiBold} from '../common/styles';
import {Media, theme} from '../../ui-kit/theme';
import {isFromApp} from '../../ui-kit/Navigation/mobile/styles';
import {getScreenHeight} from '../../hooks/common';

const stylesHeadCard = css`
  height: 90px;
  flex-shrink: 0;
  z-index: 2;
  background: ${({theme}) => theme.palette.background.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  ${Media.down.m} {
    padding: 12px 20px;
    height: 50px;
  }
`;

export const InnerMsgs = styled.div`
  display: grid;
  grid-template-columns: 25% 1fr 25%;
  //max-width: 1440px;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  ${Media.down.l} {
    padding-bottom: 22px;
  }
`;

export const ContentWrapper = styled.div`
  ${Media.down.m} {
    height: 100vh;
    width: 100%;
  }
`;

export const MobInnerMsgs = styled.div<{$isActiveChat?: boolean; $isFocused?: boolean}>`
  height: calc(100vh - 86px);
  overflow: hidden;
  ${Media.down.m} {
    height: 100%;
    box-sizing: border-box;
    ${({$isActiveChat}) => $isActiveChat && 'height: calc(100vh - 36px);'};
  }
`;

export const InnerSideCard = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
`;

export const MembersWrapper = styled.div`
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;

export const DesktopButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  background-color: transparent;
`;

export const MobileButtonWrapper = styled.div`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${theme.palette.common.aqua};
  text-decoration: none;
  font-weight: 400;
  box-shadow: 1px 0 9px rgba(0, 0, 0, 0.5);
  display: none;

  position: fixed;
  top: unset;
  right: 15px;
  left: unset;
  bottom: 20px;
  z-index: 100;
  cursor: initial;

  ${Media.down.s} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MemberCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 40px;
  padding: 5px;
  border-bottom: 0.5px solid ${theme.palette.background.secondary};
`;

export const InnerChat = styled(InnerSideCard)`
  border-bottom: none;
  border-right: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-left: 1px solid ${({theme}) => theme.palette.background.secondary};
  flex-grow: 1;
`;

export const RequestCardModal = styled.div<{$isActive?: boolean}>`
  ${Media.down.m} {
    padding-top: 40px;
    background: rgba(0, 0, 0, 0.7);
  }
`;

const calcChatWindow = () => {
  const height = getScreenHeight();
  return height / 2 - (height > 700 ? 80 : 35);
};

export const InnerBodyCard = styled.article<{$isFocused?: boolean}>`
  height: calc(100% - 90px);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  ${Media.down.m} {
    //height: calc(100% - 50px);
    height: calc(100% - 36px);
    ${({$isFocused}) =>
      $isFocused &&
      `
      height: auto;
      max-height: ${calcChatWindow()}px;
      overflow: auto;
    `};
  }
`;

export const InnerEmptyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 90px);
  ${Media.down.m} {
    height: calc(100% - 50px);
  }
`;

export const HeaderSideCard = styled.div`
  ${stylesHeadCard};
  width: 100%;
`;

export const CloseButton = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HiddenButton = styled.div<{mq: string}>`
  display: flex;
  ${({mq}) => mq} {
    display: none;
  }
`;

export const SubInnerHeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:first-child {
    margin-right: 10px;
  }
`;

export const ContactInfoLine = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  ${Media.down.m} {
    margin-top: 8px;
  }
`;

export const HeaderMainCard = styled.div<{$isFocused?: boolean}>`
  ${stylesHeadCard};
  position: relative;
  width: 100%;
`;

export const TitleHeaderCard = styled.div`
  ${fontPrimaryMedium};
  color: ${({theme}) => theme.palette.text.quaternary};
  font-size: 20px;
  line-height: 150%;
  cursor: pointer;
  ${Media.down.m} {
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
  .preview-message_avatar {
    ${Media.down.m} {
      width: 28px;
      height: 28px;
    }
  }
`;

export const SubTitleHeaderMsgList = styled.div`
  margin-top: 3px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.septenary};
  font-size: 12px;
  line-height: 150%;
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  font-size: 0;
`;

export const InnerContacts = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${Media.down.l} {
    min-height: 80vh;
  }
  & a:visited {
    background-color: ${({theme}) => theme.palette.grayscale._11}; !important;
    color: ${({theme}) => theme.palette.grayscale._11}; !important;
    border-color: ${({theme}) => theme.palette.grayscale._11}; !important;
  }
`;

export const Tab = styled.div<{$active: boolean}>`
  display: ${({$active}) => ($active ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;

//chatArea

export const WrapMsgBox = styled.div`
  padding: 14px 30px 10px 30px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  //align-self: stretch;
`;

export const LastMessageAnchor = styled.div`
  width: 1px;
  height: 1px;
  opacity: 0;
  margin-top: -80px;
`;

export const ChatAreaSend = styled.button<{isDisabled?: boolean}>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  ${({isDisabled}) => isDisabled && `opacity: 0.5; pointer-events: none;`};
`;
// export const InnerScroll = styled.div`
//   flex-grow: 1;
//   flex-shrink: 1;
//   position: relative;
//   height: 100%;
//   overflow-y: hidden;
// `;

export const ChatInputWrapper = styled.div`
  position: relative;
`;

export const FormChat = styled.div`
  //background-color: ${({theme}) => theme.palette.background.primary};
  background-color: transparent;
  padding: 10px 30px 30px 30px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  min-height: 68px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.05);
  .EmojiAction {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 42px;
    z-index: 500;
  }

  .editorWrapper {
    height: auto;
    max-height: 120px;
    min-height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    background: #f2f2f2;
    transition: 0.4s all;
    ${Media.down.m} {
      min-height: 45px;
    }
  }

  .editorWrapper .rt-wrapper {
    height: auto;
    max-height: 120px;
    min-height: 60px;
    ${Media.down.m} {
      min-height: 45px;
    }
  }

  .editorWrapper .rdw-editor-main {
    position: relative;
    height: auto;
    max-height: 120px;
    min-height: 60px;
    box-sizing: border-box;
    padding: 20px 85px 20px 60px;
    ${Media.down.m} {
      padding: 12px 50px 9px 53px;
    }
    ${Media.down.m} {
      min-height: 45px;
    }
  }

  ${Media.down.m} {
    padding: 2px;
  }
  // button {
  //   top: 46%;
  //   right: 40px;
  //   ${Media.down.m} {
  //     right: 32px;
  //   }
  // }

  ${Media.down.m} {
    padding: 10px 20px 14px 20px;
    ${isFromApp() ? ` padding-bottom: 11px;` : ''}
  }
`;

export const AddFileWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  z-index: 500;
  ${Media.down.m} {
    left: 10px;
  }
`;

export const ThumbStyles: CSSProperties = {
  background: `${({theme}: any) => theme.palette.grayscale.gray}`,
  position: 'absolute',
  overflow: 'hidden',
  borderRadius: '4px',
  userSelect: 'none',
  width: '5px',
  right: '5px',
  height: 'calc(100% - 20px)',
};

export const CustomScrollbar = styled(Scrollbar)<{$isModalOpened?: boolean}>`
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  ${({$isModalOpened}) =>
    $isModalOpened &&
    `
  .ScrollbarsCustom-Scroller {
    overflow: hidden !important;
  }
  `}

  & .ScrollbarsCustom-Content {
    ${Media.down.m} {
      min-height: 80vh !important;
    }
    display: flex;
    flex-direction: column;
    & > div > * {
      margin-bottom: 6px;
    }
  }
  & .tHuMbY {
    background: ${({theme}) => theme.palette.background.secondary};
  }
`;

export const BaseChatLoader = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerImages = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  min-height: 70px;
  max-height: 130px;
  overflow-y: auto;
  padding-top: 10px;
  margin-left: 35px;
  box-sizing: border-box;
  & > * {
    margin-bottom: 5px;
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export const InnerImgMsg = styled.div`
  position: relative;
  //width: 100%;
  //height: 100%;
`;

export const ImgMsg = styled.img`
  height: 50px;
  width: 70px;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 12px;
`;
export const BtnDelImg = styled.button`
  position: absolute;
  top: -7px;
  right: -3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 0;
  background: ${({theme}) => theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({theme}) => theme.palette.text.quaternary};
  z-index: 3;
  box-shadow: 0 0 5px ${({theme}) => theme.palette.background.quinary};
  -webkit-appearance: none;
`;

export const InnerPreviewOrderCard = styled.div`
  cursor: pointer;
`;

export const InnerRequestCard = styled.div`
  overflow: scroll;
  height: 100%;
  background-color: white;
`;

export const SendingMessageWrapper = styled.div`
  position: relative;
  & > * {
    opacity: 0.5;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  left: 8px;
  top: 50px;
  opacity: 1;
  animation: 2s linear 0s normal none infinite rotate;

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const RequestMessageBoxWrapper = styled.div`
  & > button {
    background-color: ${({theme}) => theme.palette.common.yellowLight};
  }
`;

export const DateLine = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  width: 100%;
  ${Media.down.m} {
    font-size: 12px;
  }
  color: ${({theme}) => theme.palette.grayscale._13};
  ${fontPrimarySemiBold};
`;

export const UiMessageBoxWrapper = styled.div`
  word-break: break-word;
`;
