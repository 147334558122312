import React, {useMemo} from 'react';
import {Dashboard} from '../../../components/Dashboard/Manager';
import {CommunityField, IDashboard} from '../../../components/Dashboard/types';
import {PlanOptionsValues} from '../../../constants/plan';
import {route} from '../../../constants/routes';
import {toPointer} from '../../../helpers/parse';
import {useGetActualCommunity} from '../../../hooks/community';
import {useGetFilterCommunityFields, useGetManagerDashboard} from '../../../hooks/dashboard';
import {useListItems} from '../../../hooks/item';
import {useGetNewMembers} from '../../../hooks/people';

export const ManagerDashboardContainer: React.FC<IDashboard> = (props) => {
  const community = useGetActualCommunity();
  const {data, loading} = useGetManagerDashboard();
  const {count: countNewMembers} = useGetNewMembers({id: community.id});
  const {count: countListings} = useListItems({
    where: {
      ApproveReq: {
        contains: [
          toPointer({
            __typename: community?.__typename || '',
            objectId: community?.objectId || '',
          }),
        ],
      },
    },
  });

  const fields: CommunityField[] = useMemo(() => {
    return [
      {
        dataIndex: 'countMembers',
        link: countNewMembers
          ? {
              title: 'reviewNewMembers',
              url: route.people.path,
            }
          : undefined,
      },
      {
        dataIndex: 'countListings',
        link: countListings
          ? {
              title: 'reviewNewListings',
              url: route.listings.path,
            }
          : undefined,
        plan: PlanOptionsValues.featureListings,
      },
      {
        dataIndex: 'totalInvitations',
      },
      {
        dataIndex: 'countEventAttended',
        plan: PlanOptionsValues.events,
      },
      {
        dataIndex: 'countAmenityBooked',
        plan: PlanOptionsValues.amenities,
      },
      {
        dataIndex: 'completedInvitations',
      },
      {
        dataIndex: 'countTransactions',
      },
      {
        dataIndex: 'countTransactionValue',
        render: 'value',
      },
      {
        dataIndex: 'countEventViews',
        plan: PlanOptionsValues.events,
      },
      {
        dataIndex: 'averageTransactionRating',
        render: 'stars',
      },
      {
        dataIndex: 'countListingValue',
        render: 'value',
        plan: PlanOptionsValues.featureListings,
      },
      {
        dataIndex: 'countAmenityViews',
        plan: PlanOptionsValues.amenities,
      },
      {
        dataIndex: 'countLoopPosts',
      },
      {
        dataIndex: 'countLoopPostLikes',
      },
      {
        dataIndex: 'countProfileViews',
      },
      {
        dataIndex: 'countReports',
      },
      {
        dataIndex: 'countLoopPostComments',
      },
      {
        dataIndex: 'countInternalMessages',
      },
    ];
  }, []);
  const communityFields = useGetFilterCommunityFields(fields);

  return <Dashboard {...props} data={data} loading={loading} fields={communityFields} />;
};
