import React from 'react';
import {useTranslation} from 'react-i18next';

import {SectionWrapperForm} from '../commonStyles';
import {SettingsSubsection} from '../components';
import {ConnectionCardsWrapper} from './styles';
import {SocialButtonsComponent} from '../../Auth/SocialButtonsComponent';
import {SocialProps} from '../../../types/auth';

export const SettingsAccountConnections: React.FC<SocialProps> = ({
  reqWIthGoogle,
  reqWIthFacebook,
  reqWIthApple,
  connectSocialInfo,
  disconnectAccount,
  loadingButton,
  error,
  fbReady,
  onGoogleLoginClick,
  googleReady,
  onFBClick,
}) => {
  const {t} = useTranslation();

  return (
    <SectionWrapperForm>
      <SettingsSubsection label={t('auth:social.account.title')} value={t('auth:social.account.subtitle')} />
      <ConnectionCardsWrapper>
        <SocialButtonsComponent
          reqWIthGoogle={reqWIthGoogle}
          reqWIthFacebook={reqWIthFacebook}
          reqWIthApple={reqWIthApple}
          connectSocialInfo={connectSocialInfo}
          disconnectAccount={disconnectAccount}
          loadingButton={loadingButton}
          error={error}
          fbReady={fbReady}
          onGoogleLoginClick={onGoogleLoginClick}
          googleReady={googleReady}
          onFBClick={onFBClick}
        />
      </ConnectionCardsWrapper>
    </SectionWrapperForm>
  );
};
