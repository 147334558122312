import React from 'react';
import {Modal} from '.';
import {RoundModalWrapper} from './styles';

type RoundModalProps = {
  active: boolean;
  setActive: (v: boolean) => void;
  isPortal?: boolean;
};

export const RoundModal: React.FC<RoundModalProps> = ({active, setActive, children, isPortal}) => {
  return (
    <Modal active={active} setActive={setActive} wide={true} isPortal={isPortal}>
      <RoundModalWrapper>{children}</RoundModalWrapper>
    </Modal>
  );
};
