import React, {useMemo} from 'react';
import {route} from '../../../../constants/routes';
import {TypeSteps} from '../../../../types/resident';
import {PageCreateResident} from '../../../../components/Account/Create/PageCreateResident';
import {useLinks} from '../../../../hooks/common';
import {useRecoilValue} from 'recoil';
import {userCommunities} from '../../../../states/community';

type PageProps = {
  step: TypeSteps;
};

export const Steps: React.FC<PageProps> = ({children, step}) => {
  const {getLink} = useLinks();
  const communities = useRecoilValue(userCommunities);
  const isHaveCommunities = !!communities.length;

  const dataSteps = useMemo(() => {
    return {
      ...(isHaveCommunities
        ? {}
        : {
            [TypeSteps.profile]: {
              code: 0,
              title: 'profile:steps.resident.profile',
              link: getLink(route.profileCreateResident.path),
            },
          }),
      [TypeSteps.searchCommunity]: {
        code: 1,
        title: 'profile:steps.resident.searchCommunity',
        link: getLink(route.searchCommunityResident.path),
      },
      ...(isHaveCommunities
        ? {}
        : {
            [TypeSteps.done]: {
              code: 2,
              title: 'profile:steps.resident.done',
              link: getLink(route.profileCreateResident.path),
            },
          }),
    };
  }, [isHaveCommunities]);

  return (
    <PageCreateResident dataSteps={dataSteps} step={step}>
      {children}
    </PageCreateResident>
  );
};
