import {ParseObject} from './parse';
import {Order} from './order';
import {Community, Subscription} from './community';
import {User} from './user';

export interface Transaction extends ParseObject {
  objectId: string;
  outerId?: string;
  date?: string;
  amount?: number;
  type?: string;
  descr?: string;
  data?: string;
  User?: User;
  Community?: Community;
  Order?: Order;
  status?: SubscrPaymentStatus;
}

export interface SubscrPayment extends ParseObject {
  outerId?: string;
  date?: string;
  amount?: number;
  type?: string;
  descr?: string;
  data?: string;
  status?: SubscrPaymentStatus;
  User?: User;
  Subscr?: Subscription;
}

export enum TransactionsTypes {
  Payment = 'payment',
  Credit = 'credit',
}

export enum SubscrPaymentStatus {
  succeeded = 'succeeded',
  failed = 'failed',
  active = 'active',
}
