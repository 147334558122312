import styled from 'styled-components';

export const EmojiMartWrapper = styled.div`
  .emoji-mart-bar {
    display: none;
  }
`;

export const EmojiAction = styled.span`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 6px;
  outline: none;
`;
