import React, {useEffect, useState} from 'react';
import {ReactTagsProps, Tag as TTag} from 'react-tag-input';
import {TagInput} from './TagInput';

export interface ISimpleTagInput {
  label?: string;
  values: Array<string>;
  validator?: (value: string) => boolean; // if value valid - return true, else - false
  onChange: (values: Array<string>) => void;
  setHasError?: (hasError: boolean) => void;
  delimiters?: ReactTagsProps['delimiters'];
  disabled?: boolean;
}

export const SimpleTagInput: React.FC<ISimpleTagInput> = ({values, onChange, validator, setHasError, ...props}) => {
  const [tags, setTags] = useState<Array<TTag>>([]);
  const [errors, setErrors] = useState<Array<number>>([]);

  const handleAddition: ReactTagsProps['handleAddition'] = (tag) => {
    setTags((prev) => [...prev, tag]);
  };
  const handleDelete: ReactTagsProps['handleDelete'] = (tagIdx) => {
    setTags((prev) => prev.filter((tag, idx) => idx !== tagIdx));
  };
  const handleBlur: ReactTagsProps['handleInputBlur'] = (text) => {
    if (!text || tags.some((el) => el.text === text)) return;

    handleAddition({text, id: text});
  };

  useEffect(() => {
    onChange(tags.map((el) => el.text));
  }, [tags]);

  useEffect(() => {
    if (!validator) return;
    setErrors(
      tags.map((el, idx) => (!validator(el.text) ? idx + 1 : null)).filter((el) => el !== null) as Array<number>,
    );
  }, [tags]);

  useEffect(() => {
    setHasError?.(Boolean(errors.length));
  }, [Boolean(errors.length)]);

  useEffect(() => {
    setTags(
      values.map((el) => ({
        text: el,
        id: el,
      })),
    );
  }, []);

  return (
    <TagInput
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      handleInputBlur={handleBlur}
      tags={tags}
      placeholder={''}
      notValidItems={errors}
      {...props}
    />
  );
};
