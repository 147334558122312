import styled from 'styled-components';

export const VerifyEmailWrapper = styled.div`
  width: 100%;
  max-width: 490px;
  min-height: calc(100vh - 60px);
  margin: 0 auto;
  padding: 0 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
`;
