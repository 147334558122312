import React, {useEffect, useState} from 'react';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useVerification} from '../../hooks/phone';
import {VerifyPhone} from '../../components/Auth/PhoneVerification/VerifyPhone';
import {VerifyPhoneCode} from '../../components/Auth/PhoneVerification/VerifyPhoneCode';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import styled from 'styled-components';
import {CreateProfileSteps, PhoneVerifySteps, TypeCommunity} from '../../types/auth';
import {useHistory, useParams} from 'react-router-dom';
import {useLinks} from '../../hooks/common';
import {useRecoilState} from 'recoil';
import {route} from '../../constants/routes';
import {typeUser} from '../../states/typeUser';
import {skipParams} from '../../states/community';
import {LocalStorageItems} from '../../constants/common';
import {userState} from '../../states';

const CloseBtnWrapper = styled.button`
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  border: none;
  height: 15px;
  width: 20px;
  background-color: transparent;
`;

enum Steps {
  EnterPhone,
  VerifyCode,
}

interface TProps {
  onSubmit?: () => void;
  refOnPopupWrapper?: React.Ref<HTMLDivElement>;
  onClose?: () => void;
}

export const VerifyPhonePage: React.FC<TProps> = ({onSubmit, onClose}) => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {user} = useParams<{user: TypeCommunity}>();
  const [type] = useRecoilState(typeUser);
  const {steps} = useParams<{steps: PhoneVerifySteps}>();
  const [step, setStep] = useState<Steps>(Steps.EnterPhone);
  const [phone, setPhone] = useState('');
  const [{skipAddress}] = useRecoilState(skipParams);
  const [isErrorCode, setIsErrorCode] = useState<boolean>(false);
  const [isErrorPhone, setIsErrorPhone] = useState<boolean>(false);
  const [isApp, setIsApp] = useState(false);
  const [_user, updateUser] = useRecoilState(userState);

  useEffect(() => {
    switch (steps) {
      case PhoneVerifySteps.number:
        setStep(Steps.EnterPhone);
        break;
      case PhoneVerifySteps.verification:
        setStep(Steps.VerifyCode);
        break;
    }
  }, [steps]);

  const {sendCode, sendCodeData, verifyCode, verifyCodeData} = useVerification(
    {
      onSuccess: () => {
        onSubmit
          ? setStep(Steps.VerifyCode)
          : push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.verification, user: user || type})));
        setIsErrorPhone(false);
        if (!updateUser) return;
        updateUser({
          ..._user,
          phoneVerified: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          checkList: {
            ..._user?.checkList,
            phoneVerify: {
              isChecked: true,
              isHide: false,
            },
          },
        });
      },
      onError: () => setIsErrorPhone(true),
    },
    {
      onSuccess: () => {
        onSubmit ? onSubmit() : handleAddressSubmit();
        setIsErrorCode(false);
      },
      onError: () => setIsErrorCode(true),
    },
  );

  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setIsApp(!!key);
  };

  useEffect(() => {
    getDeviceToken();
  }, []);

  const successPath =
    user === TypeCommunity.manager
      ? route.profileCreateManager.path
      : route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests});

  function handleAddressSubmit() {
    return isApp
      ? push(getLink(route.allowPush.get({user: user})))
      : skipAddress
      ? push(successPath)
      : push(route.address.get({user: user}));
  }

  const handleSubmitPhone = (phone: string) => {
    setPhone(phone);
    if (isPossiblePhoneNumber(phone)) {
      sendCode(phone);
    } else {
      setIsErrorPhone(true);
    }
  };

  const handleSubmitCode = (code: string) => {
    verifyCode(phone, code);
  };

  const handleCancelCode = () => {
    setPhone('');
    push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.number, user: user})));
  };

  const handleResendCode = () => {
    sendCode(phone);
  };
  return (
    <WrapperAuth fixed={!!onClose} previousPage={step === Steps.VerifyCode ? handleCancelCode : undefined} step={1}>
      {step === Steps.EnterPhone && (
        <VerifyPhone onSubmit={handleSubmitPhone} error={isErrorPhone} loading={sendCodeData.loading} />
      )}
      {step === Steps.VerifyCode && (
        <VerifyPhoneCode
          onSubmit={handleSubmitCode}
          onCancel={handleCancelCode}
          resendCode={handleResendCode}
          error={isErrorCode}
          loading={verifyCodeData.loading}
        />
      )}
      {onClose && (
        <CloseBtnWrapper onClick={onClose}>
          <IconSvg type={'close'} />
        </CloseBtnWrapper>
      )}
    </WrapperAuth>
  );
};
