import styled from 'styled-components';
import {Media} from '../../ui-kit/theme';
import {Text} from '../../ui-kit/Typography/Text';

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 80px;

  ${Media.down.s} {
    overflow: hidden;
    max-width: 100%;
    padding: 0 14px 14px;
    box-sizing: border-box;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 21px;

  ${Media.down.s} {
    grid-template-columns: 1fr;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 54px 0 32px;

  ${Media.down.s} {
    align-items: center;
    padding: 0;
    height: 41px;
  }
`;

export const Title = styled(Text).attrs({
  size: 26,
  weight: 'medium',
  color: 'quaternary',
})`
  ${Media.down.s} {
    visibility: hidden;
  }
`;

export const DisabledWrapper = styled.div`
  z-index: -1;
`;
