import styled, {css} from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {Text} from '../../../ui-kit/Typography/Text';
import {fontPrimaryReg} from '../../common/styles';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

export const TabsWrapper = styled.div`
  & > div > div > span {
    margin-right: 10px;
  }
`;

export const TransactionsGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  width: 100%;
  margin: 20px 0;
`;

export const TransactionBox = styled.div`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > :last-child:is(span) {
    cursor: pointer;
  }
`;

export const TransactionInfoWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  & > :first-child {
    list-style: none;
  }

  &::marker {
    color: ${({theme}) => theme.palette.text.octonary};
  }
`;

export const ViewButton = styled.button`
  display: block;
  outline: none;
  background: transparent;
  cursor: pointer;
  border: none;
  &:hover {
    & > span {
      text-decoration: underline;
    }
  }
`;

export const TransactionInfoEntry = styled.li<{$isDisabled?: boolean}>`
  font-size: 12px;
  text-transform: capitalize;
  color: ${({theme}) => theme.palette.text.octonary};
  ${({theme}) => theme.font.primary.regular};
  & button {
    height: 26px;
    min-width: 100px;
  }
  ${({$isDisabled}) => $isDisabled && ` opacity: 0.5;  pointer-events: none;`}
`;

export const GiftBalanceWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  max-width: 175px;
  width: 100%;
  position: absolute;
  top: 15px;
  right: -40%;

  ${Media.down.s} {
    position: revert;
    max-width: 100%;
  }
`;

export const CurrentPlanCardContainer = styled.section`
  border: 1px solid ${({theme}) => theme.palette.grayscale._11};
  box-sizing: border-box;
  border-radius: 12px;
  display: inline-block;
  width: 300px;
  height: 260px;
  padding: 27px 24px 34px;
  position: absolute;
  left: calc(100% + 30px);
  top: 77px;
  
  ${Media.down.s} {
    position: initial;
  };
}
`;

export const PlanDescription = styled(Text).attrs({size: 16, color: 'septenary'})`
  line-height: 24px;
`;

export const ErorrResultBlock = styled.div`
  padding-top: 10px;
  color: ${({theme}) => theme.palette.common.red};
  ${({theme}) => theme.font.primary.regular};
`;

const grayLine = css`
  border-top: 1px solid ${({theme}) => theme.palette.grayscale._11};
  padding-top: 11px;
  padding-bottom: 11px;
`;

export const TransactionPreviewWrapper = styled.div``;

export const TransactionPreviewActionsLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 24px 24px 0px 24px;
`;

export const TPHead = styled.div`
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TPItemBlock = styled.div`
  ${grayLine};
`;

export const TPPaymentsBlock = styled.div`
  ${grayLine};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
  min-height: 115px;
`;

export const TPTotalLine = styled.div`
  ${grayLine};
`;

export const TPFooter = styled.div`
  ${grayLine};
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.septenary};
  font-weight: 400;
  font-size: 11px;
  line-height: 180%;
`;

export const TransactText = styled.div<{$dark?: boolean}>`
  ${fontPrimaryReg};
  color: ${({theme, $dark}) => ($dark ? theme.palette.text.octonary : theme.palette.text.septenary)};
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
`;

export const ActionButton = styled.div<{$disabled?: boolean}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: ${({theme}) => theme.palette.background.primary};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  ${({$disabled}) => $disabled && ` opacity: 0.3; pointer-events: none;`}
`;

export const ItemName = styled.div`
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.octonary};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`;

export const ItemPricePeriod = styled.div`
  & div {
    ${fontPrimaryReg};
    color: ${({theme}) => theme.palette.text.septenary};
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  & > span {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 4px;
`;

export const TPaymentLabel = styled.div<{$bold?: boolean}>`
  ${fontPrimaryReg};
  text-decoration: underline;
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 14px;
  ${({$bold}) =>
    $bold &&
    `
  font-weight: 600;
  text-decoration: unset;`}
`;
export const TPaymentValue = styled.div<{$bold?: boolean}>`
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.text.octonary};
  font-size: 14px;
  font-size: 14px;
  ${({$bold}) =>
    $bold &&
    `
  font-weight: 600;
  text-decoration: unset;`}
`;

export const PairLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  box-sizing: border-box;
  width: 100%;
`;

export const TransactionImage = styled.div`
  padding: 14px 24px 28px 24px;
  box-sizing: border-box;
`;
