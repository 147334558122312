import {ContentWrapper, CopyIcon, StyledTextArea, TitleWrapper, Wrapper} from './styles';
import {Text} from '../../ui-kit/Typography/Text';
import {MemoCopyButton} from '../common/Buttons/Copy';
import {Input} from '../../ui-kit/Form/Input';
import {Button} from '../../ui-kit/Button/Button';
import {Link} from '../../ui-kit/Typography/Link';
import {route} from '../../constants/routes';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLinks} from '../../hooks/common';

export interface TRequestCommunityProps {
  sendEmail: (email: string, text: string) => Promise<boolean>;
}

export const RequestCommunity: React.FC<TRequestCommunityProps> = ({sendEmail}) => {
  const {t} = useTranslation();
  const [invitation, setInvitation] = useState('');
  const [email, setEmail] = useState('');
  const [sendSuccess, setSendSuccess] = useState<boolean | undefined>();
  const {getLink} = useLinks();

  const handleInvitationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setInvitation(e.target.value);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handleSendInvitation = () => {
    if (email.trim() && invitation.trim()) {
      sendEmail(email, invitation).then((data) => setSendSuccess(data));
    } else {
      setSendSuccess(false);
    }
  };

  const buttonLabel =
    sendSuccess === undefined
      ? t('community:request.buttonSend')
      : sendSuccess
      ? t('community:request.sendSuccess')
      : t('community:request.sendFail');

  return (
    <Wrapper>
      <ContentWrapper>
        <TitleWrapper>
          <Text variant={'secondary'} size={50} color={'quaternary'}>
            {t('community:request.title')}
          </Text>
        </TitleWrapper>
        <TitleWrapper>
          <Text variant={'primary'} size={16} color={'octonary'}>
            {t('community:request.subtitle')}
          </Text>
        </TitleWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <StyledTextArea
          value={invitation}
          onChange={handleInvitationChange}
          label={t('community:request.label.invitation')}
          placeholder={t('community:request.placeholder.invitation')}
        />
        <CopyIcon>
          <MemoCopyButton copiedValue={invitation} />
        </CopyIcon>
      </ContentWrapper>
      <ContentWrapper>
        <Input value={email} onChange={handleEmailChange} label={t('community:request.label.email')} />
      </ContentWrapper>
      <ContentWrapper>
        <Button variant={'primary'} onClick={handleSendInvitation}>
          {buttonLabel}
        </Button>
      </ContentWrapper>
      <Link
        to={getLink(route.pageUser.path)}
        color={'octonary'}
        size={10}
        decoration={'underline'}
        weight={'semi-bold'}>
        {t('community:request.continuePersonal')}
      </Link>
    </Wrapper>
  );
};
