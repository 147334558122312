import React, {useEffect} from 'react';
import {useSendVerifyEmail} from '../../hooks/user';
import {VerifyEmailMessage} from '../../components/VerifyEmailMessage';
import {useQuery} from '@apollo/client';
import {ViewerQuery} from '../../queries/auth';

export const VerifyEmailMessageContainer: React.FC<{appendTop?: boolean; setIsVerified?: (value: boolean) => void}> = ({
  appendTop,
  setIsVerified,
}) => {
  const {sendEmail} = useSendVerifyEmail();
  const {data, stopPolling} = useQuery(ViewerQuery, {skip: false, pollInterval: 5000});
  useEffect(() => {
    if (data?.viewer?.user?.emailVerified) {
      setIsVerified?.(true);
      stopPolling();
    }
  }, [data]);
  return <VerifyEmailMessage send={sendEmail} appendTop={appendTop} />;
};
