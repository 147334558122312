export interface AccountCheckListType {
  avatarSet: {
    isChecked: boolean;
    isHide: boolean;
  };
  phoneVerify: {
    isChecked: boolean;
    isHide: boolean;
  };
  communityProfileFill: {
    isChecked: boolean;
    isHide: boolean;
  };
  joinCommunity: {
    isChecked: boolean;
    isHide: boolean;
  };
  addListing: {
    isChecked: boolean;
    isHide: boolean;
  };
  addPost: {
    isChecked: boolean;
    isHide: boolean;
  };
  makeOrder: {
    isChecked: boolean;
    isHide: boolean;
  };
  inviteResident: {
    isChecked: boolean;
    isHide: boolean;
  };
}

export enum communityCheckItem {
  inviteResident = 'inviteResident',
  communityProfileFill = 'communityProfileFill',
}
