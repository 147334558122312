import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {NavContainer} from '../../Navigation';
import {useConnectStripe, useViewerCards} from '../../../hooks/payments';
import {PaymentSettings} from '../../../components/Settings/Payments/PaymentSettings';
import {useSubscrPay, useSubscrPayments, useTransactions} from '../../../hooks/transactions';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {useViewerId} from '../../../hooks/user';
import {StripeInfo} from '../../../queries/payments';
import {currentCommunity} from '../../../states/community';
import {SubscrPaymentStatus} from '../../../queries/types/transactions';
import {useGetCurrencyValue} from '../../../hooks/currency';
import {useLinks} from '../../../hooks/common';
import {SITE_URL} from '../../../constants/env';
import {formatSettinsStripeLink} from '../../../helpers/message';
import {analyticsTrackFN} from '../../../helpers/account';
import {useViewer} from '../../../hooks/user';
import {SearchKey} from '../../../constants/routes';
import {useGetUserCredits} from '../../../hooks/referral';

export const Payments: React.FC = () => {
  const {search} = useLocation();
  const viewerId = useViewerId('objectId');
  const [typeCommunity] = useRecoilState(typeUser);
  const [community] = useRecoilState(currentCommunity);
  const [stripeInfo, setStripeInfo] = useState<StripeInfo | undefined | null>(null);
  const {cards, activeCard, refetch} = useViewerCards(viewerId);
  const {data: payments, loading: paymentsLoading} = useTransactions({
    userId: viewerId ?? '',
    type: 'order',
    where: {status: {equalTo: SubscrPaymentStatus.succeeded}},
  });
  const {data: subscriptions, refetch: refetchSubscr} = useSubscrPayments(viewerId ?? '', community?.objectId ?? '');
  const {totalCredits, totalReferrals, loading: referralsLoading} = useGetUserCredits();
  const subscrPayData = useSubscrPay();
  const getStripeInfo = useConnectStripe();
  const {getSignValue: getAmount} = useGetCurrencyValue();
  const {getLink} = useLinks();
  const viewer = useViewer();

  // Stripe Connected api
  const params = new URLSearchParams(search);
  const stripeConnectedId = params.get(SearchKey.stripeConnectId);
  const stripeConnectId = localStorage.getItem(SearchKey.stripeConnectId);
  useEffect(() => {
    if (stripeConnectedId && stripeConnectedId === stripeConnectId) {
      analyticsTrackFN('Stripe Connected', {
        name: `${viewer?.firstName} ${viewer?.lastName}`,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
      });
      localStorage.removeItem('stripeConnectId');
    }
  }, [stripeConnectedId]);

  const returnUrl = SITE_URL + getLink(formatSettinsStripeLink(String(new Date().getTime())));

  useEffect(() => {
    getStripeInfo(returnUrl)
      .then((response) => setStripeInfo(response || undefined))
      .catch((err) => {
        console.log(err);
        setStripeInfo(undefined);
      });
  }, []);

  const isManager = typeCommunity === TypeCommunity.manager;

  return (
    <NavContainer>
      <PaymentSettings
        cards={cards}
        payments={payments}
        subscriptions={subscriptions}
        stripeInfo={stripeInfo}
        isManager={isManager}
        refetchCards={refetch}
        promoBalance={0}
        currentPlan={community?.Subscr?.PaymentPlan}
        subscrPayData={subscrPayData}
        refetchSubscr={refetchSubscr}
        activeCardId={activeCard}
        getAmount={getAmount}
        paymentsLoading={paymentsLoading}
        referralsLoading={referralsLoading}
        totalCredits={totalCredits}
        totalReferrals={totalReferrals}
      />
    </NavContainer>
  );
};
