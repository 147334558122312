import React, {useEffect, useRef, useState} from 'react';
import {SeparatedSelect} from '../../../ui-kit/Form/Select/SeparatedSelect';
import {InnerMobileSearchWrapper, MobileSearchInputs, MobileSelectWrapper} from './styles';
import {SeparatedTextInput} from '../../../ui-kit/Search/inputs/separated/SeparatedTextInput';
import {usePageView} from '../../../hooks/common';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface MobileSearchBlockProps {
  placeholder?: string;
  options?: SelectOptions[];
  onSearch: (searchText?: string, params?: SelectOptions) => void;
  selectedOption?: SelectOptions;
}

export const MobileSearchBlock: React.FC<MobileSearchBlockProps> = ({
  placeholder,
  options,
  onSearch,
  selectedOption,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<SelectOptions | undefined>(selectedOption || options?.[0]);
  const {view, setView} = usePageView();
  const handleSelect = (next: SelectOptions) => {
    setSelectOptions(next);
    onSearch(searchText, next);
  };
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const isolateTouch = (ev: TouchEvent) => {
      ev.stopPropagation();
    };

    scrollRef?.current?.addEventListener('touchstart', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchmove', isolateTouch, {passive: true});
    scrollRef?.current?.addEventListener('touchend', isolateTouch, {passive: true});
    return () => {
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchstart', isolateTouch, {passive: true});
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchmove', isolateTouch, {passive: true});
      // eslint-disable-next-line
      // @ts-ignore
      scrollRef?.current?.removeEventListener('touchend', isolateTouch, {passive: true});
    };
  }, [scrollRef]);
  useEffect(() => {
    if (selectedOption?.value) setSelectOptions(selectedOption);
  }, [selectedOption?.value]);
  return (
    <InnerMobileSearchWrapper ref={scrollRef}>
      <MobileSearchInputs>
        <SeparatedTextInput
          placeholder={placeholder ?? ''}
          onChange={setSearchText}
          value={searchText}
          onSearch={onSearch}
        />
        {options && (
          <MobileSelectWrapper>
            <SeparatedSelect
              options={options}
              value={selectOptions}
              onChange={handleSelect}
              view={view}
              setView={setView}
            />
          </MobileSelectWrapper>
        )}
      </MobileSearchInputs>
    </InnerMobileSearchWrapper>
  );
};
