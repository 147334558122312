import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {PlanOptionsValues} from '../../constants/plan';
import {hasPlanOption} from '../../helpers/community';
import {useGetItem, useResidentActionsItem} from '../../hooks/item';
import {useGetActualCommunityList} from '../../hooks/community';
import {GQLButtonsResidentTypes} from '../../graphql.schema';
import {Community} from '../../queries/types/community';
import {ListingCreated} from '../../components/Listings/ListingCreated';
import {useGetCurrencyValue} from '../../hooks/currency';
import {commLoading} from '../../types/community';
import {analyticsTrackFN} from '../../helpers/account';
import {generatedItem} from '../../states/item';

export const ListingCreatedContainer: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {data: item, refetch} = useGetItem({id});
  const {currency} = useGetCurrencyValue();
  const [reviewMode, setReviewMode] = useState(true);
  const {onSubmit} = useResidentActionsItem({
    communityId: community?.objectId,
    // onSuccess: () => setIsPublished(true),
  });
  const [generated, setGenerated] = useRecoilState(generatedItem);
  const {getSignValue} = useGetCurrencyValue();
  const [addCommLoading, setAddCommLoading] = useState<commLoading>(false);
  const setCommLoading = (t: commLoading) => setAddCommLoading(t);
  const needConfirmation =
    hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.listingsApproval) ?? false;

  const handleSubmit = (communityIdsPublish?: Array<string>, communityIdsUnlist?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length
        ? [
            onSubmit({
              itemId: item?.objectId,
              communityIds: communityIdsPublish,
              typeBtn: GQLButtonsResidentTypes.publish,
            }),
          ]
        : []),
      ...(communityIdsUnlist?.length
        ? [
            onSubmit({
              itemId: item?.objectId,
              communityIds: communityIdsUnlist,
              typeBtn: GQLButtonsResidentTypes.unlist,
            }),
          ]
        : []),
    ]).then(async () => {
      const result = await refetch();
      if (generated?.price) {
        analyticsTrackFN('AI Listing Posted', {
          listingId: id,
          listingType: item?.listingType, // service, rental, buy/sell
          listedFor: item?.rewardType, // coffee, free, money
          photoCount: item?.images?.length,
          title: item?.name,
          description: item?.descr,
          rate: item?.price,
          currency: currency?.code,
          bookingType: item?.pricePeriod,
          category: item?.category,
          customPrice: generated?.price !== item?.price,
          listerName: `${item?.Lister?.firstName}`,
          listerEmail: `${item?.Lister?.email}`,
          listerId: item?.Lister?.objectId,
          communitiesCount: communityIdsPublish?.length,
          communities: communityIdsPublish?.map((item) => {
            const comm = communities?.find((el) => el?.objectId === item || el?.id === item);
            const isPub = result?.data?.item?.Published?.find((pub) => pub?.objectId === item || pub?.id === item);
            return {
              communityName: comm?.name,
              communityId: comm?.objectId,
              listingStatus: isPub ? 'published' : 'pending', // pending, published, not posted
              postedIn: true, // true if community was selected
              communityType: comm?.type, // building, neighbourhood, circle
            };
          }),
        });
      } else
        analyticsTrackFN('Listing Posted', {
          listingId: id,
          listingType: item?.listingType, // service, rental, buy/sell
          listedFor: item?.rewardType, // coffee, free, money
          photoCount: item?.images?.length,
          title: item?.name,
          description: item?.descr,
          rate: item?.price,
          currency: currency?.code,
          bookingType: item?.pricePeriod,
          category: item?.category,
          listerName: `${item?.Lister?.firstName}`,
          listerEmail: `${item?.Lister?.email}`,
          listerId: item?.Lister?.objectId,
          communitiesCount: communityIdsPublish?.length,
          communities: communityIdsPublish?.map((item) => {
            const comm = communities?.find((el) => el?.objectId === item || el?.id === item);
            const isPub = result?.data?.item?.Published?.find((pub) => pub?.objectId === item || pub?.id === item);
            return {
              communityName: comm?.name,
              communityId: comm?.objectId,
              listingStatus: isPub ? 'published' : 'pending', // pending, published, not posted
              postedIn: true, // true if community was selected
              communityType: comm?.type, // building, neighbourhood, circle
            };
          }),
        });
      setGenerated(undefined);
      setReviewMode(true);
      setCommLoading(false);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [communities, community],
  );

  const [publishedList, setPublishedList] = useState<Array<string>>(allCommunitiesIds);

  const selectedCommunitiesIds = useMemo(() => {
    return [...((item?.Published || []) as Community[]), ...((item?.ApproveReq || []) as Community[])].map((el) => {
      return el.objectId;
    });
  }, [item]);

  useEffect(() => {
    setPublishedList(allCommunitiesIds);
  }, [communities?.length]);

  const oneCommunitySubmit = () => {
    setCommLoading?.('one');
    handleSubmit(
      publishedList.filter((communityId) => !selectedCommunitiesIds?.includes(communityId)),
      allCommunitiesIds.filter(
        (communityId) => !publishedList.includes(communityId) && selectedCommunitiesIds?.includes(communityId),
      ),
    );
  };
  useEffect(() => {
    if (communities.length === 1 && item?.objectId) {
      setReviewMode(true);
      oneCommunitySubmit();
    } else setReviewMode(false);
  }, [item?.objectId, communities.length]);

  return (
    <ListingCreated
      needConfirmation={needConfirmation}
      item={item}
      community={community}
      communities={communities}
      onSubmit={handleSubmit}
      reviewMode={reviewMode}
      publishedList={publishedList}
      setPublishedList={setPublishedList}
      allCommunitiesIds={allCommunitiesIds}
      initialListCommunity={selectedCommunitiesIds}
      getSignValue={getSignValue}
      setCommLoading={setCommLoading}
      addCommLoading={addCommLoading}
    />
  );
};
