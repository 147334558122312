import React from 'react';
import {
  ListingCreatedButtonsWrapper,
  ListingCreatedCardWrapper,
  ListingCreatedContentWrapper,
  ListingCreatedSubtitle,
  ListingCreatedTitle,
  ListingCreatedWrapper,
  TopWrapper,
} from './styles';
import {useTranslation} from 'react-i18next';
import {route} from '../../constants/routes';
import {CommunityItemStateType, CommunityStateType} from '../../states/community';
import {CommunityLabel} from '../Events/CommunityLabel';
import {Button} from '../../ui-kit/Button/Button';
import {useHistory} from 'react-router-dom';
import {isEqual} from 'date-fns';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {useLinks} from '../../hooks/common';
import {useViewerId} from '../../hooks/user';
import {IGetCurrValue} from '../../types/common';
import {AmenityCard} from './AmenityCard';
import {commLoading} from '../../types/community';
import {GoBack} from '../common/Buttons/GoBack';
import {CloseButton, HeaderMobileWrapper} from './styles';
import {Amenity} from '../../queries/types/amenity';

interface AmenityCreatedProps {
  amenity?: Partial<Amenity> | null;
  needConfirmation: boolean;
  community: CommunityStateType;
  communities: Array<CommunityItemStateType>;
  onSubmit: (communityIdsPublish?: string[] | undefined, communityIdsUnlist?: string[] | undefined) => void;
  reviewMode: boolean;
  isConfigureMode?: boolean;
  allCommunitiesIds: string[];
  publishedList: string[];
  setPublishedList: React.Dispatch<React.SetStateAction<string[]>>;
  initialListCommunity?: string[];
  getSignValue: IGetCurrValue;
  createdType?: 'created' | 'updated';
  addCommLoading?: commLoading;
  setCommLoading?: (t: commLoading) => void;
}

export const AmenityCreated: React.FC<AmenityCreatedProps> = ({
  needConfirmation,
  amenity,
  communities,
  community,
  onSubmit,
  reviewMode,
  setPublishedList,
  publishedList,
  allCommunitiesIds,
  isConfigureMode,
  initialListCommunity = [],
  getSignValue,
  createdType,
  addCommLoading,
  setCommLoading,
}) => {
  const {t} = useTranslation();
  const {push, goBack} = useHistory();
  const userId = useViewerId('objectId');

  const isSelectAll: boolean = allCommunitiesIds.length === publishedList.length;

  const {getLink} = useLinks();
  const isUpdated =
    createdType === 'created'
      ? false
      : !isEqual(new Date(amenity?.createdAt ?? ''), new Date(amenity?.updatedAt ?? ''));
  const title = reviewMode
    ? isUpdated
      ? t('amenities:created.titleUpdate')
      : t('amenities:created.title')
    : t('amenities:created.titleConfirm');
  const subtitle = reviewMode
    ? isUpdated
      ? t('amenities:created.subtitleUpdate')
      : needConfirmation
      ? t('amenities:created.subtitleApprove')
      : t('amenities:created.subtitle')
    : t('amenities:created.subtitleConfirm');

  const handleRedirect = (to: string) => () => push(to);

  const handleRedirectToLoop = () => {
    push(getLink(route.loop.path));
  };
  const handleSubmit = () => {
    setCommLoading?.('one');
    onSubmit(
      publishedList.filter((communityId) => !initialListCommunity?.includes(communityId)),
      allCommunitiesIds.filter(
        (communityId) => !publishedList.includes(communityId) && initialListCommunity?.includes(communityId),
      ),
    );
  };
  /*const handleSubmitAll = () => {
    setCommLoading?.('all');
    onSubmit(allCommunitiesIds.filter((communityId) => !amenity?.Published?.find((el) => el.objectId === communityId)));
    setPublishedList(allCommunitiesIds);
  };*/

  const communitiesList = reviewMode
    ? communities.filter((el) => el?.objectId && publishedList.includes(el.objectId))
    : communities.length
    ? communities
    : community
    ? [community]
    : [];

  const getOnChange = (id?: string) => (checked?: boolean) => {
    if (!id) return;
    checked ? setPublishedList((prev) => [...prev, id]) : setPublishedList((prev) => prev.filter((el) => el !== id));
  };

  const changeChooseCommunities = () => {
    if (isSelectAll) {
      setPublishedList([]);
    } else {
      setPublishedList(allCommunitiesIds);
    }
  };

  return (
    <ListingCreatedWrapper>
      <ListingCreatedContentWrapper>
        <HeaderMobileWrapper>
          <GoBack goBack={goBack} title={t('listings:generateListing.reviewCom')} />
          <CloseButton onClick={handleRedirectToLoop}>
            <IconSvg type={'close'} width={'24px'} height={'24px'} viewBox={'-1 -1 22 22'} />
          </CloseButton>
        </HeaderMobileWrapper>
        <ListingCreatedTitle>{title}</ListingCreatedTitle>
        <ListingCreatedSubtitle>{subtitle}</ListingCreatedSubtitle>
        <ListingCreatedCardWrapper>
          <AmenityCard
            to={getLink(route.amenityView.get({id: amenity?.id || '/'}))}
            amenity={amenity ?? {}}
            aspectRatio={'300:200'}
            src={amenity?.images?.[0]?.file?.url}
            communityId={community?.id}
            isOwner={true}
            isHideLabel
            getSignValue={getSignValue}
          />
        </ListingCreatedCardWrapper>
        <TopWrapper>
          <ListingCreatedSubtitle>{t('events:created.postedIn')}:</ListingCreatedSubtitle>
          {!!communitiesList.length && communitiesList.length > 1 && !reviewMode && (
            <Button
              variant={'select'}
              width={'73px'}
              height={'21px'}
              ghost
              fontSize={'10px'}
              onClick={changeChooseCommunities}>
              {!isSelectAll ? t('buttons:common.selectAll') : t('buttons:common.unselectAll')}
            </Button>
          )}
        </TopWrapper>
        {communitiesList.map((el) => {
          const isChecked = !!el?.objectId && publishedList.includes(el.objectId);
          return (
            <CommunityLabel
              key={`${el.objectId}` + isChecked}
              community={el}
              icon={<IconSvg type={'people'} />}
              count={el.countMembers}
              needConfirmation={needConfirmation}
              isUnmanaged={
                el.listingApproval === 'unmanaged' || amenity?.Published?.some((cmt) => cmt.objectId === el.objectId)
              }
              isCheckboxMode={!reviewMode}
              isChecked={isChecked}
              onChange={getOnChange(el?.objectId)}
              isUserManager={el?.Owner?.objectId === userId}
              userId={userId}
              isPublished={el.objectId ? initialListCommunity?.includes(el.objectId) : false}
            />
          );
        })}
        <ListingCreatedButtonsWrapper>
          {reviewMode ? (
            <>
              <Button
                type={'button'}
                variant={'primary'}
                rounded={true}
                onClick={handleRedirect(getLink(route.createAmenity.path))}>
                {t('amenities:created.addAnother')}
              </Button>
              <Button
                type={'button'}
                variant={'secondary'}
                rounded={true}
                ghost={true}
                onClick={handleRedirect(getLink(route.amenityViewEdit.get({id: amenity?.id || '/'})))}>
                {t('amenities:created.edit')}
              </Button>
            </>
          ) : isConfigureMode ? (
            <>
              <Button
                variant={'secondary'}
                ghost
                rounded={true}
                onClick={handleRedirect(getLink(route.amenityView.get({id: amenity?.id || '/'})))}>
                {t('amenities:created.cancel')}
              </Button>
              <Button variant={'primary'} rounded={true} onClick={handleSubmit} loading={addCommLoading === 'all'}>
                {t('amenities:created.save')}
              </Button>
            </>
          ) : (
            <>
              <Button variant={'primary'} loading={addCommLoading === 'one'} rounded={true} onClick={handleSubmit}>
                {t('amenities:created.confirm')}
              </Button>
            </>
          )}
        </ListingCreatedButtonsWrapper>
      </ListingCreatedContentWrapper>
    </ListingCreatedWrapper>
  );
};
