import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Button} from '../../../../ui-kit/Button/Button';
import {MainWrapper, FormTopTitle, MobFocused} from '../styles';
import {Box} from '../../../common/Box';
import {isDesktop} from 'react-device-detect';
import {RichTextEditor} from '../../../common/RichText/Editor';
import {EmojiTab} from '../../../common/EmojiTab/EmojiTab';
import {PostFormValue, PostFormValues} from '../../../../types/post';
import {FormErrorFields} from '../../../../hooks/error';
import {ErrorMessage} from '../../../Auth/styles';
import {analyticsTrackFN} from '../../../../helpers/account';
import {GenerateButton} from '../../../../ui-kit/Button/GenerateButton';
import {TRecommendantions} from '../../../../types/interests';

interface IProps {
  firstName?: string;
  bio?: string;
  recommendations?: TRecommendantions;
  skip: () => void;
  handleSubmit: () => void;
  handleChange: (next: {name: string; value?: PostFormValue}) => void;
  values: PostFormValues;
  error?: FormErrorFields<PostFormValues>;
  loading: boolean;
  loadingGenerate: boolean;
  generateData: (type: 'bio' | 'post') => Promise<string>;
}

export const FirstPost: React.FC<IProps> = ({
  //skip,
  firstName,
  bio,
  recommendations,
  error,
  handleSubmit,
  handleChange,
  values,
  loading,
  loadingGenerate,
  generateData,
}) => {
  const {t} = useTranslation();
  const [emoji, setEmoji] = useState<string>('');
  const [localError, setLocalError] = useState<string>('');
  const [changed, setChanged] = useState(false);
  const [generatedData, setGeneratedData] = useState<{count: number; text: string}>({count: 0, text: ''});

  /*const handleSkip = () => {
    skip();
  };*/
  const emojiHandleChange = (str: string) => {
    setEmoji(str);
  };

  const handlePostSubmit = () => {
    if (values?.text && values?.text?.length <= 8) {
      setLocalError(t('error:postEmpty'));
      return;
    }
    setLocalError('');
    analyticsTrackFN('Introduction Posted', {
      defaultIntroduction: changed,
    });
    handleSubmit();
  };

  const handlePostChange = (next: {name: string; value?: PostFormValue}) => {
    setChanged(true);
    handleChange(next);
  };

  const handleGenerate = async () => {
    if (loadingGenerate) return;
    const data = await generateData?.('post');
    setGeneratedData({text: data || '', count: generatedData?.count + 1});
  };

  useEffect(() => {
    if (generatedData?.text) {
      handleChange({
        name: 'text',
        value: generatedData?.text,
      });
    }
  }, [generatedData?.text]);

  useEffect(() => {
    if (firstName && bio) {
      const message_part1 = t('auth:firstPost.text_part1', {
        firstName: firstName,
        profileBio: bio,
      });
      const message_part2 = recommendations?.eatery
        ? t('auth:firstPost.text_part2', {
            foodRecommendation: recommendations?.eatery,
          })
        : '';
      const message_part3 = recommendations?.movies
        ? t('auth:firstPost.text_part3', {
            moviesRecommendation: recommendations?.movies,
          })
        : '';
      const message_part4 = recommendations?.places
        ? t('auth:firstPost.text_part4', {
            travelRecommendation: recommendations?.places,
          })
        : '';
      const message_part5 = t('auth:firstPost.text_part5');
      handleChange({
        name: 'text',
        value: message_part1 + message_part2 + message_part3 + message_part4 + message_part5,
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => handleGenerate(), 500);
  }, []);

  return (
    <MainWrapper>
      <FormTopTitle $noOffset>
        <Text variant={'secondary'} color={'quaternary'} size={38}>
          {t('auth:firstPost.title')}
        </Text>
        {/*
        <SkipBtn onClick={handleSkip}>
          <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
            {t('auth:firstPost.skip')}
          </Text>
        </SkipBtn>*/}
      </FormTopTitle>
      <Box direction="column" display="flex" gap="16px">
        <Box margin="0 0 8px 0" textAlign="left">
          <Text variant={'primary'} color={'octonary'} size={16}>
            {t('auth:firstPost.firstSubtitle')}
          </Text>
        </Box>
        <MobFocused $isDesktop={isDesktop}>
          {generatedData?.count <= 3 && (
            <GenerateButton
              loading={loadingGenerate}
              initText={t('auth:auth.buttons.generate')}
              className={'generate'}
              onClick={handleGenerate}
              count={generatedData?.count || 0}
            />
          )}
          <RichTextEditor
            label={t('auth:firstPost.label')}
            name={'text'}
            value={values?.text}
            onChange={handlePostChange}
            rows={isDesktop ? 12 : 16}
            emoji={emoji}
            setEmoji={setEmoji}
            $isError={Boolean(error?.message)}
            removeTags={true}
            newState={generatedData?.text}
          />
          <EmojiTab onlyEmoji={true} onChange={emojiHandleChange} value={values?.text} />
        </MobFocused>
        <ErrorMessage open={!!localError || !!error?.message}>{localError || error?.message}</ErrorMessage>
        <Box margin="5px 0 10px 0">
          <Button disabled={loading} loading={loading} type={'button'} variant={'primary'} onClick={handlePostSubmit}>
            {t('auth:firstPost.buttons.continue')}
          </Button>
        </Box>
      </Box>
    </MainWrapper>
  );
};
