import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {PlanOptionsValues} from '../../constants/plan';
import {hasPlanOption} from '../../helpers/community';
import {useGetActualCommunityList} from '../../hooks/community';
import {useGetItem, useResidentActionsItem} from '../../hooks/item';
import {useGetCurrencyValue} from '../../hooks/currency';
import {GQLButtonsResidentTypes} from '../../graphql.schema';
import {Community} from '../../queries/types/community';
import {ListingCreated} from '../../components/Listings/ListingCreated';
import {NavContainer} from '../Navigation';
import {commLoading} from '../../types/community';

export const ConfigureListCommunities: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const [community] = useRecoilState(currentCommunity);
  const communities = useGetActualCommunityList();
  const {data: item, refetch} = useGetItem({id});
  const [reviewMode, setReviewMode] = useState(false);
  const {onSubmit} = useResidentActionsItem({
    communityId: community?.objectId,
    // onSuccess: () => setIsPublished(true),
  });
  const [addCommLoading, setAddCommLoading] = useState<commLoading>(false);
  const setCommLoading = (t: commLoading) => setAddCommLoading(t);
  const {getSignValue} = useGetCurrencyValue();
  const needConfirmation =
    hasPlanOption(community?.Subscr?.PaymentPlan.options, PlanOptionsValues.listingsApproval) ?? false;

  const handleSubmit = (communityIdsPublish?: Array<string>, communityIdsUnlist?: Array<string>) => {
    Promise.all([
      ...(communityIdsPublish?.length
        ? [
            onSubmit({
              itemId: item?.objectId,
              communityIds: communityIdsPublish,
              typeBtn: GQLButtonsResidentTypes.publish,
            }),
          ]
        : []),
      ...(communityIdsUnlist?.length
        ? [
            onSubmit({
              itemId: item?.objectId,
              communityIds: communityIdsUnlist,
              typeBtn: GQLButtonsResidentTypes.unlist,
            }),
          ]
        : []),
    ]).then(() => {
      refetch();
      setReviewMode(true);
      setAddCommLoading(false);
    });
  };

  const allCommunitiesIds = useMemo(
    () =>
      (communities?.length ? communities.map((el) => el.objectId) : [community?.objectId]).filter(
        (el) => !!el,
      ) as Array<string>,
    [community, communities],
  );

  const selectedCommunitiesIds = useMemo(() => {
    return [...((item?.Published || []) as Community[]), ...((item?.ApproveReq || []) as Community[])].map((el) => {
      return el.objectId;
    });
  }, [item]);

  const [publishedList, setPublishedList] = useState<Array<string>>(selectedCommunitiesIds);

  useEffect(() => {
    if (!selectedCommunitiesIds?.length) return;
    setPublishedList(selectedCommunitiesIds);
  }, [selectedCommunitiesIds?.length]);

  return (
    <NavContainer>
      <ListingCreated
        needConfirmation={needConfirmation}
        item={item}
        community={community}
        communities={communities}
        onSubmit={handleSubmit}
        reviewMode={reviewMode}
        publishedList={publishedList}
        setPublishedList={setPublishedList}
        allCommunitiesIds={allCommunitiesIds}
        initialListCommunity={selectedCommunitiesIds}
        isConfigureMode
        getSignValue={getSignValue}
        addCommLoading={addCommLoading}
        setCommLoading={setCommLoading}
      />
    </NavContainer>
  );
};
