import {format} from 'date-fns';
import {TDataInvoices, TResCreateReview} from '../hooks/order';
import {Order} from '../queries/types/order';
import {User} from '../queries/types/user';

export const formatRequestObject = (activeOrder?: Order | null, dataInvoice?: TDataInvoices | null) => ({
  listingId: activeOrder?.Item?.id,
  listingType: activeOrder?.Item?.listingType,
  listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
  photoCount: activeOrder?.Item?.images?.length,
  title: activeOrder?.Item?.name,
  description: activeOrder?.Item?.descr,
  rate: activeOrder?.price,
  currency: activeOrder?.Currency?.code,
  bookingType: activeOrder?.Item?.pricePeriod,
  category: activeOrder?.Item?.category,
  listerName: `${activeOrder?.Lister?.firstName}`,
  listerEmail: activeOrder?.Lister?.email,
  listerId: activeOrder?.Lister?.objectId,
  requesterName: `${activeOrder?.Requester?.firstName}`,
  requesterEmail: activeOrder?.Requester?.email,
  requesterId: activeOrder?.Requester?.objectId,
  communityName: activeOrder?.Community?.name,
  communityId: activeOrder?.Community?.objectId,
  communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
  requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
  requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
  requestDuration: `${activeOrder?.period} hours`,
  paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
  paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
  paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
  paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
});

export const formatRequestCancelObject = (
  activeOrder?: Order | null,
  dataInvoice?: TDataInvoices | null,
  viewer?: User | null,
) => ({
  listingId: activeOrder?.Item?.id,
  listingType: activeOrder?.Item?.listingType,
  listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
  photoCount: activeOrder?.Item?.images?.length,
  title: activeOrder?.Item?.name,
  description: activeOrder?.Item?.descr,
  rate: activeOrder?.price,
  currency: activeOrder?.Currency?.code,
  bookingType: activeOrder?.Item?.pricePeriod,
  category: activeOrder?.Item?.category,
  listerName: `${activeOrder?.Lister?.firstName}`,
  listerEmail: activeOrder?.Lister?.email,
  listerId: activeOrder?.Lister?.objectId,
  requesterName: `${activeOrder?.Requester?.firstName}`,
  requesterEmail: activeOrder?.Requester?.email,
  requesterId: activeOrder?.Requester?.objectId,
  communityName: activeOrder?.Community?.name,
  communityId: activeOrder?.Community?.objectId,
  communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
  requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
  requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
  requestDuration: `${activeOrder?.period} hours`,
  paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
  paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
  paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
  paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
  canceledBy: activeOrder?.Lister?.objectId === viewer?.objectId ? 'lister' : 'requester', // lister or requester
  transactionsState: activeOrder?.status, // pending or accepted
});

export const formatOrderReviewObject = (
  activeOrder?: Order | null,
  dataInvoice?: TDataInvoices | null,
  reviewOrderData?: TResCreateReview,
  viewer?: User | null,
  type?: 'rating' | 'review',
) => {
  const author = activeOrder?.Lister?.objectId === viewer?.objectId ? 'lister' : 'requester';
  return {
    listingId: activeOrder?.Item?.id,
    listingType: activeOrder?.Item?.listingType,
    listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
    photoCount: activeOrder?.Item?.images?.length,
    title: activeOrder?.Item?.name,
    description: activeOrder?.Item?.descr,
    rate: activeOrder?.price,
    currency: activeOrder?.Currency?.code,
    bookingType: activeOrder?.Item?.pricePeriod,
    category: activeOrder?.Item?.category,
    listerName: `${activeOrder?.Lister?.firstName}`,
    listerEmail: activeOrder?.Lister?.email,
    listerId: activeOrder?.Lister?.objectId,
    requesterName: `${activeOrder?.Requester?.firstName}`,
    requesterEmail: activeOrder?.Requester?.email,
    requesterId: activeOrder?.Requester?.objectId,
    communityName: activeOrder?.Community?.name,
    communityId: activeOrder?.Community?.objectId,
    communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
    requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
    requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
    requestDuration: `${activeOrder?.period} hours`,
    paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
    paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
    paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
    paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
    ...(type === 'rating'
      ? {
          ratingBy: author,
          listingRating: reviewOrderData?.data?.itemRating,
          personRating: reviewOrderData?.data?.userRating,
        }
      : {
          reviewBy: author,
          listingReview: reviewOrderData?.data?.itemReview,
          personReview: reviewOrderData?.data?.userReview,
        }),
  };
};
