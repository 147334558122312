import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {Input} from '../../ui-kit/Form/Input';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Message} from '../../ui-kit/Form/Message';
import {getHtmlTextLength, stopPropagation} from '../../helpers/common';
import {FormMode, IActionsOnGroupsData, TFormDataType, TModalData} from './types';
import {
  CloseBtnWrapper,
  DarkBackground,
  LoadersWrapper,
  ModalWrapper,
  LabelWrapper,
  CounterBlock,
  CounterItem,
} from './styles';
import {GroupFields, TGroup} from '../../types/group';
import {CoverInput} from './CoverInput';
import {AddGroupData} from '../../hooks/groups';
import {RichTextEditor} from '../common/RichText/Editor';
import {MobFocused} from '../Listings/styles';

interface IProps extends TModalData {
  actionsData: IActionsOnGroupsData;
  close?: () => void;
}

export const ModalGroupForm: React.FC<IProps> = ({mode, close, actionsData, selectedGroup}) => {
  const {t} = useTranslation();

  const {setValue, setFile, addGroup, updateGroup, values, loading, error} = actionsData;
  const isDisableSubmit = isDisableSbmtBtn(values, selectedGroup);

  const handleSetValue = (params: {key: keyof AddGroupData; value: any}) => {
    if (!loading) setValue(params);
  };
  const handleClose = () => {
    if (!loading) close?.();
  };
  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSetValue({key: 'name', value: event.target.value});
  };
  const handleChange = (key: keyof AddGroupData) => (next: {name: string; value: string}) => {
    if (key === 'description' && getHtmlTextLength(next.value) > 300) {
      return false;
    } else {
      handleSetValue({key: key, value: next.value});
    }
  };

  const getHandleSetFile = (key: keyof AddGroupData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.files?.[0];

    setFile({key: key, value});
  };
  const handleDeleteCover = () => {
    setFile({key: 'Avatar', value: null});
  };

  const handleSubmit = async () => {
    if (mode === FormMode.Add) await addGroup(close);
    if (mode === FormMode.Edit && selectedGroup) await updateGroup(selectedGroup, close);
  };
  const currLengthDescr = getHtmlTextLength(values?.description || selectedGroup?.description);
  return (
    <DarkBackground onClick={handleClose}>
      <ModalWrapper onClick={stopPropagation}>
        <LabelWrapper indent={16}>
          <Text size={20} color={'octonary'} weight={'medium'}>
            {t('groups:modalForm.title', {context: mode})}
          </Text>
        </LabelWrapper>
        <LoadersWrapper>
          <CoverInput
            handleChange={getHandleSetFile('Avatar')}
            handleDelete={handleDeleteCover}
            coverFile={values.Avatar}
            coverUrl={selectedGroup?.Avatar?.file.url}
            mode={mode}
          />
        </LoadersWrapper>
        <LabelWrapper indent={14} />
        <LabelWrapper indent={10}>
          <Text size={18} color={'octonary'}>
            {t('groups:modalForm.titles.name')}
          </Text>
        </LabelWrapper>
        <Input
          label={t('groups:modalForm.labels.name')}
          onChange={handleOnChangeName}
          value={values.name ?? selectedGroup?.name}
        />
        <LabelWrapper indent={18} />

        <MobFocused $isDesktop={false}>
          <RichTextEditor
            label={t('groups:modalForm.labels.description')}
            name={GroupFields.description}
            value={values?.description ?? selectedGroup?.description}
            onChange={handleChange('description')}
            rows={5}
          />
          <CounterBlock $danger={currLengthDescr >= 300}>
            <CounterItem>{currLengthDescr}</CounterItem>/<CounterItem>300</CounterItem>
          </CounterBlock>
        </MobFocused>
        <LabelWrapper indent={18} />
        <Button loading={loading} onClick={handleSubmit} disabled={isDisableSubmit}>
          {t('groups:modalForm.button', {context: mode})}
        </Button>
        <Br indent={5} />
        {error ? <Message variant={'error'}>{t(error)}</Message> : null}
        <CloseBtnWrapper onClick={handleClose}>
          <IconSvg type={'close'} height={'24px'} width={'24px'} viewBox={'0 0 20 20'} />
        </CloseBtnWrapper>
      </ModalWrapper>
    </DarkBackground>
  );
};

function isDisableSbmtBtn(values: TFormDataType, initial?: TGroup) {
  if (!Object.values(values).length) return true;
  if (!initial) return false;

  if (values.Avatar) return false;
  if (values.name === initial.name) return true;

  return false;
}
