import React, {useState} from 'react';
import {EmojiData, Picker} from 'emoji-mart';
import {EmojiAction, EmojiMartWrapper} from './styles';
import {isDesktop} from 'react-device-detect';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {ModalEmoji} from './ModalEmoji';

interface IEmojiProps {
  onChange?: (str: string) => void;
  value?: string;
  onlyEmoji?: boolean;
}

export const EmojiTab: React.FC<IEmojiProps> = ({onChange, value, onlyEmoji}) => {
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const toggleModal = () => {
    setActiveModal(true);
  };

  const handleSelect = (emoji: EmojiData, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if ('native' in emoji) {
      if (!onlyEmoji) {
        onChange && onChange(value + emoji.native);
      } else {
        onChange && onChange(emoji.native);
      }
      setActiveModal(false);
    }
  };

  return (
    <>
      <ModalEmoji setActive={setActiveModal} active={activeModal}>
        <EmojiMartWrapper>
          <Picker emojiSize={26} theme="light" onClick={handleSelect} showPreview={false} />
        </EmojiMartWrapper>
      </ModalEmoji>
      {isDesktop && (
        <EmojiAction className="EmojiAction" onClick={toggleModal}>
          <IconSvg type={'happy-face'} width={'24'} height={'24'} stroke={'gray'} viewBox={'0 0 20 20'} />
        </EmojiAction>
      )}
    </>
  );
};
