import React from 'react';
import {ContentWrapper, CardRatingWrapper, TextContainerEntry, RatingContent, AvatarWrapper} from './styles';
import {CardRatingSectionProps, UserType, ItemType, RatingInfo} from './types';
import {Avatar} from '../Avatar/Avatar';
import {StarsSection} from './StarsSection';

const CardRatingFragment: React.FC<{user?: UserType; item?: ItemType; rating: RatingInfo}> = ({user, item, rating}) => {
  if (user)
    return (
      <CardRatingWrapper direction={'row'}>
        <AvatarWrapper>
          <Avatar size={'md'} src={user.avatar} />
        </AvatarWrapper>
        <RatingContent>
          <StarsSection
            text={`${user.firstName} ${user.lastName}`}
            rating={rating.input.user.value || 0}
            onChange={rating.input.user.onChange}
          />
        </RatingContent>
      </CardRatingWrapper>
    );

  if (item)
    return (
      <CardRatingWrapper direction={'row'}>
        <AvatarWrapper>
          <Avatar size={'md'} src={item.avatar} />
        </AvatarWrapper>
        <RatingContent>
          <StarsSection
            text={`${item.name}`}
            rating={rating.input.item.value || 0}
            onChange={rating.input.item.onChange}
          />
        </RatingContent>
      </CardRatingWrapper>
    );
  return null;
};

export const CardRatingSection: React.FC<CardRatingSectionProps> = ({info, rating, isRequester}) => {
  const [user, item] = info;

  return (
    <ContentWrapper>
      <TextContainerEntry>{rating.title}</TextContainerEntry>
      <CardRatingFragment user={user} rating={rating} />
      {isRequester && <CardRatingFragment item={item} rating={rating} />}
    </ContentWrapper>
  );
};
