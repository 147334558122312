import React, {useMemo, useRef, useState} from 'react';
import {
  AvatarWrapper,
  BtnText,
  EntryLinkWrapper,
  LabelsWrapper,
  RelativeWrapper,
  SettingsBtn,
  TextBlock,
  Title,
  WrapperFlex,
} from '../Settings/styles';
import {CommunityElement} from '../Settings/components';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {aliasPart, currentCommunity} from '../../../../states/community';
import {
  useGetCommunity,
  useGetMenuCommunities,
  useGetUserPendingCommunities,
  useJoinToCommunity,
} from '../../../../hooks/community';
import {CustomCommunity} from '../Settings/types';
import {useMarkCommunitySwitched, useViewer, useViewerId} from '../../../../hooks/user';
import {
  CommunityTypes,
  getMaxCountOfCommunity,
  setCurrentCommunity as setCurrentCommunityInLS,
  toStateCommunity,
} from '../../../../helpers/community';
import {route} from '../../../../constants/routes';
import {typeUser} from '../../../../states/typeUser';
import {InfiniteScroll} from '../../../common/InfiniteScroll';
import {CommunitiesMenuSkeleton} from '../../../common/Skeletons/CommunitiesMenuSkeleton';
import {useHistory, useLocation} from 'react-router-dom';
import {useLinks} from '../../../../hooks/common';
import {CommunitiesMenuListWrapper, CommunityMenuContainer} from './styles';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {Label} from '../../../../ui-kit/Labels';
import {TCommunity} from '../../../../types/community';
import {useTranslation} from 'react-i18next';
import {Br} from '../../../../ui-kit/Typography/Br';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {useGetAdminsLabels} from '../../../../hooks/people';
import {TypeCommunity} from '../../../../types/auth';
import {variant} from '../../../../ui-kit/Labels/types';
import {analyticsTrackFN} from '../../../../helpers/account';
import {getInviteData} from '../../../../helpers/common';
import {useResetPreload} from '../../../../hooks/preload';
import defaultCommunityAvatar from '../../../../ui-kit/ProfileIntro/images/avatar-default-community.png';
import {useAutoAddToCommunity} from '../../../../hooks/auth';

export const CommunityMenu = () => {
  const viewer = useViewer();
  const {t} = useTranslation();
  const [community, setCommunity] = useRecoilState(currentCommunity);
  let communitiesWithoutCurrentNew: Array<CustomCommunity> = [];
  const [communitiesWithoutCurrent, setCommunitiesWithoutCurrent] = useState(communitiesWithoutCurrentNew);
  const featuredCommunities = communitiesWithoutCurrent.filter(
    (joinedCommunity: CustomCommunity) =>
      !Boolean(joinedCommunity?.pendingToJoin) || !Boolean(joinedCommunity?.invited),
  );
  const {
    data: listOfJoinedCommunities,
    hasMore: hasMoreItems,
    loading: loadingCommunities,
    loadMore,
  } = useGetMenuCommunities(featuredCommunities, viewer?.id, viewer?.Communities?.count);
  const {refetch: getCommunity} = useGetCommunity({id: undefined, short: false, small: false});
  const {pathname} = useLocation();
  const [userType] = useRecoilState(typeUser);
  const {addByKey, loading} = useAutoAddToCommunity();
  const {push} = useHistory();
  const {getLink} = useLinks();
  const setAlias = useSetRecoilState(aliasPart);
  const adminsLabels = useGetAdminsLabels();
  const maxCountCommunity = getMaxCountOfCommunity(viewer?.email);
  const statusType = userType === TypeCommunity.manager || userType === TypeCommunity.admin ? 'quaternary' : 'quinary';
  const statusLabel =
    (viewer?.objectId && adminsLabels[viewer.objectId]) ||
    t('common:typeUser', {context: userType || TypeCommunity.resident});
  const {onSubmit: join, loading: joinLoading} = useJoinToCommunity();
  const {data: listPendingCommunities} = useGetUserPendingCommunities(listOfJoinedCommunities);
  const reset = useResetPreload();
  const markSwitched = useMarkCommunitySwitched();

  communitiesWithoutCurrentNew = listOfJoinedCommunities as any as Array<CustomCommunity>;
  communitiesWithoutCurrentNew = useMemo(
    () =>
      [...listPendingCommunities, ...communitiesWithoutCurrentNew].filter((el) => el.objectId !== community?.objectId),
    [listPendingCommunities, communitiesWithoutCurrentNew, community?.objectId],
  );

  const allCommunities = communitiesWithoutCurrentNew.filter((concatItem, index) => {
    return communitiesWithoutCurrentNew.findIndex((commItem) => commItem?.id === concatItem?.id) === index;
  });

  if (communitiesWithoutCurrent.length < allCommunities.length) {
    setCommunitiesWithoutCurrent(allCommunities);
  }

  const openSearch = () => {
    setAlias(undefined);
    push(getLink(route.searchCommunityResident.path));
  };

  const setCurrentCommunity = async (id: string) => {
    const newCommunity = communitiesWithoutCurrentNew.find((el) => el.objectId === id);
    if (!newCommunity) return;
    if (newCommunity.pendingToJoin) {
      push(getLink(route.joinCommunity.get({id: id || ''})));
      return;
    }
    if (newCommunity.invited && newCommunity.objectId) {
      if (joinLoading || loading) return;
      if (newCommunity.invited === CommunityTypes.neighbourhood) {
        await addByKey(newCommunity.objectId);
        return;
      }
      await join({
        localCommId: newCommunity?.objectId,
        localUserId: viewer?.objectId,
        email: viewer?.email,
        invite: {communityId: newCommunity.objectId, ...getInviteData(newCommunity.invited)},
      });
      return;
    }
    const fetchCommunity = await getCommunity({id: newCommunity?.objectId});
    const communityNew = fetchCommunity?.data?.community;
    setCommunity(toStateCommunity(communityNew));
    markSwitched();
    reset();
    newCommunity?.objectId && setCurrentCommunityInLS(communityNew.objectId);
    analyticsTrackFN('Community Switched', {
      userName: viewer?.username,
      userEmail: viewer?.email,
      userId: viewer?.objectId, // objectID or UID of user
      communityName: communityNew?.name,
      communityId: communityNew?.objectId, // objectID or ID of community
      communityType: communityNew?.type,
      previousCommunityName: community?.name,
      previousCommunityId: community?.objectId, // objectID or ID of community
      previousCommunityType: community?.type,
      page: pathname?.split('/')?.[2] || '', // home, explore, inbox etc.
    });
    push(getLink(route.loop.get(), communityNew?.alias));
  };

  const haveManagerRank = listOfJoinedCommunities.some((cmt) => cmt.Owner?.objectId == viewer?.objectId);
  const haveAdminRank = listOfJoinedCommunities.some((cmt) =>
    cmt.Admins?.some((usr) => usr.User?.objectId === viewer?.objectId),
  );
  const commCount = allCommunities.length + 1;
  const ref = useRef<HTMLDivElement>(null);
  const handleGetScrollParent = () => {
    return ref.current;
  };
  return (
    <CommunityMenuContainer className={'Scroller'} ref={ref}>
      {(haveManagerRank || haveAdminRank) && commCount < maxCountCommunity && (
        <SettingsBtn to={'#'} onClick={openSearch}>
          <WrapperFlex>
            <BtnText>{t('common:menu.searchCommunities')}</BtnText>
            <IconSvg type={'search-filled'} />
          </WrapperFlex>
        </SettingsBtn>
      )}
      {haveManagerRank && commCount < maxCountCommunity && (
        <SettingsBtn to={route.selectPlan.path}>
          <WrapperFlex>
            <BtnText>{t('settings:popup.buttons.createCommunity')}</BtnText>
            <IconSvg type={'add'} />
          </WrapperFlex>
        </SettingsBtn>
      )}
      <Br indent={20} />
      <CommunitiesMenuListWrapper>
        <SelectedCommunityLine community={community} statusType={statusType} statusLabel={statusLabel} />
        <InfiniteScroll
          useWindow={false}
          getScrollParent={handleGetScrollParent}
          loadMore={loadMore}
          hasMore={(hasMoreItems && loadingCommunities) || !listOfJoinedCommunities?.length ? true : hasMoreItems}
          loader={<CommunitiesMenuSkeleton />}>
          {allCommunities.map((el: any) => (
            <CommunityElement
              community={el}
              key={el?.objectId}
              setCurrentCommunity={setCurrentCommunity}
              isPending={!!el?.pendingToJoin}
              isInvited={!!el?.invited}
              joinLoading={joinLoading}
            />
          ))}
        </InfiniteScroll>
      </CommunitiesMenuListWrapper>
      {haveManagerRank && <Br indent={20} />}
    </CommunityMenuContainer>
  );
};

export const SelectedCommunityLine: React.FC<{
  community?: Partial<TCommunity> | null;
  statusType: variant;
  statusLabel?: string;
}> = ({community, statusLabel, statusType}) => {
  const currentId = useViewerId('objectId');
  const {t} = useTranslation();
  const {getLink} = useLinks();

  if (!community) return null;
  return (
    <RelativeWrapper>
      <EntryLinkWrapper to={getLink(route.loop.path)}>
        <WrapperFlex flexStart={true}>
          <AvatarWrapper>
            <Avatar
              src={community?.avatar || (community?.identityData?.autogenerated ? defaultCommunityAvatar : '')}
              size={'sm'}
            />
          </AvatarWrapper>
          <TextBlock>
            <Title $overflow={true}>{community?.name}</Title>
            <LabelsWrapper>
              <>
                {community?.Owner?.objectId === currentId ? (
                  <Label squared variant={'quaternary'}>
                    {t('common:typeCommunity.admin')}
                  </Label>
                ) : (
                  <Label squared variant={statusType}>
                    {statusLabel}
                  </Label>
                )}
              </>
            </LabelsWrapper>
          </TextBlock>
        </WrapperFlex>
      </EntryLinkWrapper>
    </RelativeWrapper>
  );
};
