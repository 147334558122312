import styled from 'styled-components';
import {starSize as starSizeT} from './types';

export const StarsContainer = styled.div<{notChosen?: boolean; notEditable?: boolean}>`
  ${({notChosen, notEditable, theme}) =>
    notChosen &&
    `
  & > span {
    color: ${theme.palette.grayscale._4};
    cursor: ${notEditable ? 'auto !important' : 'pointer'};
  }
`}
`;

export const StarIcon = styled.span<{size?: starSizeT}>`
  color: ${({theme}) => theme.palette.common.yellow};
  cursor: pointer;
  font-size: ${({size}) => (size === 'small' ? `8px` : `18px`)};
`;
