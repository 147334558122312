import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {SuperResidentProgramInfo} from '../../../constants/links';
import {Button} from '../../../ui-kit/Button/Button';
import {IconImage} from '../../../ui-kit/Icon/Image';
import {Text} from '../../../ui-kit/Typography/Text';
import {BlankExternalLink, BlankReactLink} from '../../common/Links';
import {InnerAuth, InnerContinueBtn, SubtitleWrapper, TextButton, TitleAuth} from '../styles';

interface IProps {
  feedBackLink: string;
  skip: () => void;
}

const Info: React.FC<IProps> = ({feedBackLink}) => {
  const {t} = useTranslation();

  const title = t('auth:foundCommunity.superResident.info.title');
  const subTitle = t('auth:foundCommunity.superResident.info.subTitle');
  const firstCTA = t('auth:foundCommunity.superResident.info.firstCTA');
  const secondCTA = t('auth:foundCommunity.superResident.info.secondCTA');

  return (
    <>
      <IconImage type="hello-yellow" width="140px" height="140px" />
      <TitleAuth>{title}</TitleAuth>
      <SubtitleWrapper>{subTitle}</SubtitleWrapper>

      <BlankExternalLink to={SuperResidentProgramInfo}>
        <Button height="46px">
          <Text weight="bold" color="secondary" size={14}>
            {firstCTA}
          </Text>
        </Button>
      </BlankExternalLink>

      <BlankReactLink to={feedBackLink}>
        <TextButton>{secondCTA}</TextButton>
      </BlankReactLink>
    </>
  );
};

export const SuperResidentInfo: React.FC<IProps & {hasLocation?: boolean; redirectToRequest?: () => void}> = (
  props,
) => {
  const {hasLocation, skip, redirectToRequest} = props;
  const {t} = useTranslation();
  const btnText = t('auth:foundCommunity.superResident.continueBtn');

  useEffect(() => {
    if (!hasLocation) {
      redirectToRequest && redirectToRequest();
    }
  }, []);

  return (
    <InnerAuth>
      <InnerContinueBtn onClick={skip}>
        <Text weight="medium" size={16} color="duodecimal">
          {btnText}
        </Text>
      </InnerContinueBtn>
      <Info {...props} />
    </InnerAuth>
  );
};
