import {gql} from '@apollo/client';

const ReferralUserFragment = gql`
  fragment ReferralUserFragment on User {
    id
    objectId
    firstName
    firstLetter
    Avatar {
      file {
        name
        url
      }
    }
  }
`;

export const ReferralFragment = gql`
  fragment ReferralFragment on Referral {
    objectId
    createdAt
    updatedAt
    accumulatedDiscount
    ReferralBy {
      ...ReferralUserFragment
    }
    ReferralsCount
    userMadeTransaction
    Referrals {
      ... on UserConnection {
        edges {
          node {
            ...ReferralUserFragment
          }
        }
      }
    }
    User {
      ...ReferralUserFragment
    }
  }
  ${ReferralUserFragment}
`;
