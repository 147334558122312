import {FileInfo} from '../queries/types/parse';
import {TImage} from '../types/common';
import {isFile, isFileInfo, isServer, isString} from './validations';

export const getSourceUrl = (value?: File | FileInfo | string): string | undefined => {
  if (!value) return;

  if (isString(value)) return value;

  if (isFileInfo(value)) return value.url;

  if (isFile(value) && !isServer()) {
    return (window.URL || window.webkitURL)?.createObjectURL(value);
  }
};

export const excludeImages = ['webp', 'gif', 'svg'];

export const getResize = (url?: string, size?: 'sm' | 'md' | 'lg') => {
  if (!url || !size || url?.includes('avatar-default')) return url as string;
  const ext = url.split('.')?.pop();
  if (!ext || excludeImages.includes(ext?.toLowerCase())) return url as string;

  return url.replace(`.${ext}`, `.${size}.${ext}`);
};

export const changeFileName = (file: File): File => {
  let myRenamedFile: File | null = null;
  const ext = file.name.split('.').pop();
  const newName = `file_${Date.now()}.${ext}`;
  myRenamedFile = new File([file], newName, {type: file.type});

  return myRenamedFile || file;
};

export const getBlobUrl = (fileUrl: string) => {
  return fetch(fileUrl)
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res));
};

const supportedFormats = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
export const checkItemImagesFormat = (images?: TImage[]) => {
  if (
    images?.some((el) => {
      const file = el?.value as File;
      if (!file?.name) return false;
      const ext = file?.name?.split('.')?.pop()?.toLocaleLowerCase();
      if (!ext) return true;
      return !supportedFormats?.includes(ext);
    })
  )
    throw new Error('error:imageSupport');
};
