import React, {useEffect, useState} from 'react';
import {isDesktop} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {AddAddressFormEntry, ErrorMessage, FormButtons, SettingsEntry, SettingsSubsection} from '../components';
import {ConfirmationAdress} from './ConfirmationAdress';
import {
  AddFieldStyles,
  AddFieldText,
  AddFieldTextWrapper,
  AliasInfo,
  ConfirmBtn,
  MobFocused,
  StyledInput,
  WrapperFlex,
} from './styles';
import {TEXTAREA_MAX_LENGTH} from '../../../constants/common';
import {getCommunityLink} from '../../../helpers/common';
import {CommunityProfileValues, CommunityType, FormEditType, FormType} from '../../../types/settings';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {CleaveInput, Input} from '../../../ui-kit/Form/Input';
import {Select} from '../../../ui-kit/Form/Select';
import {TextArea} from '../../../ui-kit/Form/TextArea/TextArea';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {
  UpdatePhotoError,
  UpdatePhotoIcon,
  UpdatePhotoInput,
  UpdatePhotoWrapper,
} from '../../../ui-kit/ProfileIntro/styles';
import {Br} from '../../../ui-kit/Typography/Br';
import {EmojiTab} from '../../common/EmojiTab/EmojiTab';
import {MemoSymbolsCounter} from '../../common/SymbolsCounter';
import {
  AdditionalInfo,
  AvatarWithButtonWrapper,
  InfoPartFormWrapper,
  SectionWrapper,
  SectionWrapperForm,
  SettingsInfoFormWrapper,
  SettingsInfoFormWrapperAddAdress,
  SettingsInfoWrapper,
  RowEntry,
} from '../commonStyles';

import {LocationOptionsType} from '../../../hooks/locations';
import {ChangeLocationType} from '../../../hooks/settings';
import {useViewer} from '../../../hooks/user';
import {CommunityTypes} from '../../../helpers/community';

export interface AddAddressProps {
  unit: string;
  zip: string;
  address: string;
  id: string;
}

interface addAddressContainer {
  value: AddAddressProps;
  __typename: string;
}

interface TProps {
  community?: Partial<CommunityType> | null;
  edit: FormEditType;
  canEdit: boolean;
  form: FormType<CommunityProfileValues>;
  changeLocation: ChangeLocationType;
  locationOptions: LocationOptionsType;
  updateAvatar: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  loadingAvatar: boolean;
  removeAddAddress: (id: string, params: addAddressContainer[], idComm: string | undefined) => Promise<any | undefined>;
}

export const SettingsProfile: React.FC<TProps> = ({
  community,
  edit,
  form,
  changeLocation,
  locationOptions,
  canEdit,
  updateAvatar,
  updatePhotoError,
  loadingAvatar,
  removeAddAddress,
}) => {
  return !edit.isEditing ? (
    <CommunityInfo
      onEdit={edit.handleEditStart}
      community={community}
      canEdit={canEdit}
      updateAvatar={updateAvatar}
      updatePhotoError={updatePhotoError}
      loadingAvatar={loadingAvatar}
    />
  ) : (
    <CommunityForm
      community={community}
      form={form}
      changeLocation={changeLocation}
      locationOptions={locationOptions}
      onCancel={edit.handleEditEnd}
      updateAvatar={updateAvatar}
      updatePhotoError={updatePhotoError}
      avatar={community?.avatar}
      loadingAvatar={loadingAvatar}
      removeAddAddress={removeAddAddress}
    />
  );
};

interface InfoProps {
  community?: Partial<CommunityType> | null;
  onEdit: () => void;
  canEdit: boolean;
  updateAvatar: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  loadingAvatar: boolean;
}

const CommunityInfo: React.FC<InfoProps> = ({
  community,
  onEdit,
  canEdit,
  updateAvatar,
  updatePhotoError,
  loadingAvatar,
}) => {
  const {t} = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);

  const handleEdit = () => {
    setClicked(true);
    if (canEdit) {
      onEdit();
    }
    setTimeout(() => setClicked(canEdit), 3000);
  };
  return (
    <SectionWrapper>
      <SettingsSubsection label={t('settings:community.profile.title')} />
      <AvatarWithButtonWrapper>
        <UpdatePhotoWrapper>
          <Avatar src={community?.avatar} size={'xl'} loading={loadingAvatar} />
          <UpdatePhotoIcon>
            {updateAvatar && (
              <UpdatePhotoInput onChange={updateAvatar} accept={'.jpg, .png, .jpeg, .webp, .svg, .gif, .bmp'} />
            )}
            <IconSvg type={'camera'} width={'16'} height={'16'} viewBox={'0 0 46 46'} />
          </UpdatePhotoIcon>
        </UpdatePhotoWrapper>
        {updatePhotoError && <UpdatePhotoError>{updatePhotoError}</UpdatePhotoError>}
      </AvatarWithButtonWrapper>
      <SettingsInfoWrapper noGap={true}>
        <SettingsEntry label={t('settings:community.profile.name')} onClick={onEdit} value={community?.name} />
        <SettingsEntry label={t('settings:community.profile.descr')} onClick={onEdit} value={community?.descr} />
        <SettingsEntry
          label={t('settings:community.profile.alias')}
          onClick={onEdit}
          value={getCommunityLink(community?.alias)}
        />
      </SettingsInfoWrapper>
      <SettingsSubsection label={t('settings:community.address.title')} />
      <SettingsInfoWrapper noGap={true}>
        <SettingsEntry label={t('settings:community.address.address')} onClick={onEdit} value={community?.address} />
        <InfoPartFormWrapper>
          <SettingsEntry
            label={t('settings:community.address.city')}
            onClick={onEdit}
            value={community?.Location?.name}
          />
          <SettingsEntry
            label={t('settings:community.address.state')}
            onClick={onEdit}
            value={community?.Location?.State?.name}
          />
          <SettingsEntry label={t('settings:community.address.zip')} onClick={onEdit} value={community?.zip} />
          <SettingsEntry
            label={t('settings:community.address.country')}
            onClick={onEdit}
            value={community?.Location?.Country?.name}
          />
          <SettingsEntry label={t('settings:community.address.units')} onClick={onEdit} value={community?.units} />
          {/* <SettingsEntry
            label={t('settings:community.address.buildings')}
            onClick={onEdit}
            value={community?.buildings}
          /> */}
        </InfoPartFormWrapper>
      </SettingsInfoWrapper>
      {community?.address_add?.length && (
        <>
          <SettingsSubsection label={t('settings:community.address.addTitle')} />
          <SettingsInfoWrapper noGap={true}>
            {community?.address_add?.map((el) => (
              <RowEntry key={el?.value?.id}>
                <SettingsEntry
                  label={t('settings:community.address.address')}
                  onClick={onEdit}
                  value={el?.value?.address}
                />
                <SettingsEntry label={t('settings:community.address.zip')} onClick={onEdit} value={el?.value?.zip} />
              </RowEntry>
            ))}
          </SettingsInfoWrapper>
        </>
      )}
      {clicked && !canEdit && <ErrorMessage error={'settings:personal.saveOrCancel'} />}
      <FormButtons variant={'edit'} onClick={handleEdit} />
    </SectionWrapper>
  );
};

interface FormProps {
  community?: Partial<CommunityType> | null;
  form: FormType<CommunityProfileValues>;
  changeLocation: ChangeLocationType;
  locationOptions: LocationOptionsType;
  onCancel: () => void;
  updateAvatar: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  avatar?: string;
  loadingAvatar: boolean;
  removeAddAddress: (id: string, params: addAddressContainer[], idComm: string | undefined) => Promise<any | undefined>;
}

const CommunityForm: React.FC<FormProps> = ({
  form,
  community,
  changeLocation,
  locationOptions,
  onCancel,
  updateAvatar,
  updatePhotoError,
  avatar,
  loadingAvatar,
  removeAddAddress,
}) => {
  const viewer = useViewer();
  const {t} = useTranslation();
  const {country, state, city, handleChangeDropdown} = changeLocation;
  const {countries, states, cities} = locationOptions;
  const [isShowModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  // const [isError, setIsError] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.onChange({name: e.target.name, value: e.target.value});
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.values.Location = {link: city.value || form.values.Location?.link || viewer?.Location?.objectId};
    form.onSubmit();
  };

  const handleChangeDescr = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > TEXTAREA_MAX_LENGTH) {
      form.setError(new Error('error:descr'));
    } else {
      handleChange(e);
    }
  };

  const emojiHandleChange = (str: string) => {
    if (str.length > TEXTAREA_MAX_LENGTH) {
      form.setError(new Error('error:bio'));
    } else {
      form.onChange({name: 'descr', value: str});
    }
  };

  const showModal = (e: any) => {
    e?.preventDefault();
    if (community?.address_add == null || community?.address_add?.length < 5) {
      setShowModalConfirm(true);
    } else alert(t('settings:modals.addAdress.error'));
  };

  const handleCloseConfirm = () => {
    setShowModalConfirm(false);
  };

  const modalsText = {
    title: t('settings:modals.addAdress.title'),
    okText: t('settings:modals.addAdress.button'),
    cancelText: t('settings:modals.addAdress.close'),
  };

  const prepareToRemoveAddress = (id: string) => {
    const params: any = [community?.address_add];
    removeAddAddress(id, params, community?.id);
  };
  const isCircle = community?.type === CommunityTypes.circle;
  useEffect(() => {
    if (isCircle && city?.value && city?.label) {
      handleChangeDropdown('city')([{value: city.value, label: city.label}]);
    }
  }, [isCircle]);
  return (
    <SectionWrapperForm onSubmit={handleSubmit}>
      <SettingsSubsection label={t('settings:community.profile.title')} />
      <AvatarWithButtonWrapper>
        <UpdatePhotoWrapper>
          <Avatar src={avatar} size={'xl'} loading={loadingAvatar} />
          <UpdatePhotoIcon>
            {updateAvatar && <UpdatePhotoInput onChange={updateAvatar} accept={'image/*'} />}
            <IconSvg type={'camera'} width={'16'} height={'16'} viewBox={'0 0 46 46'} />
          </UpdatePhotoIcon>
        </UpdatePhotoWrapper>
        {updatePhotoError && <UpdatePhotoError>{updatePhotoError}</UpdatePhotoError>}
      </AvatarWithButtonWrapper>
      <SettingsInfoFormWrapper>
        <Input
          $isError={Boolean(form.error.name)}
          label={t('settings:community.profile.name')}
          name={'name'}
          type={'text'}
          value={form.values.name}
          onChange={handleChange}
        />
        <WrapperFlex>
          <MobFocused $isDesktop={isDesktop}>
            <TextArea
              className="editor"
              $isError={Boolean(form.error.descr)}
              label={t('settings:community.profile.descr')}
              name={'descr'}
              inputMode={'text'}
              value={form.values.descr}
              onChange={handleChangeDescr}
            />
            <EmojiTab onChange={emojiHandleChange} value={form.values.descr} />
          </MobFocused>
          <AdditionalInfo>
            <MemoSymbolsCounter limit={TEXTAREA_MAX_LENGTH} value={form.values.descr?.length} />
          </AdditionalInfo>
        </WrapperFlex>
        <WrapperFlex>
          <StyledInput
            $isError={Boolean(form.error.alias)}
            label={t('settings:community.profile.alias')}
            name={'alias'}
            type={'text'}
            value={getCommunityLink(form.values.alias)}
            disabled={true}
            icon={<IconSvg type={'circle-ok'} stroke={'green'} />}
          />
          <AliasInfo>
            <span>{t('settings:community.profile.changeUrl')}</span>
            <MemoSymbolsCounter limit={20} value={form.values.alias?.length} />
          </AliasInfo>
        </WrapperFlex>
      </SettingsInfoFormWrapper>
      <SettingsSubsection label={t('settings:community.address.title')} />
      <SettingsInfoFormWrapper>
        <Input
          $isError={Boolean(form.error.address)}
          label={t('settings:community.address.address')}
          name={'address'}
          type={'text'}
          value={form.values.address}
          onChange={handleChange}
        />
        <InfoPartFormWrapper>
          <Select
            $isError={form.error.Location}
            label={t('settings:community.address.city')}
            values={[city] ?? []}
            options={cities}
            onChange={handleChangeDropdown('city')}
            name={'city'}
          />
          <Select
            $isError={form.error.Location}
            label={t('settings:community.address.state')}
            values={[state] ?? []}
            options={states}
            onChange={handleChangeDropdown('state')}
            name={'state'}
          />
          <CleaveInput
            options={{
              blocks: [3, 3],
              uppercase: true,
            }}
            $isError={Boolean(form.error.zip)}
            label={t('settings:community.address.zip')}
            name={'zip'}
            type={'text'}
            value={form.values.zip}
            onChange={handleChange}
          />
          <Select
            $isError={form.error.Location}
            label={t('settings:community.address.country')}
            values={[country] ?? []}
            options={countries}
            onChange={handleChangeDropdown('country')}
            name={'country'}
            disabled={viewer?.objectId !== community?.Owner?.objectId}
          />
          <CleaveInput
            options={{
              blocks: [3, 3],
              uppercase: true,
            }}
            $isError={Boolean(form.error.units)}
            label={t('settings:community.address.units')}
            name={'units'}
            type={'text'}
            value={form.values.units}
            onChange={handleChange}
          />
          <CleaveInput
            options={{
              blocks: [3, 3],
              uppercase: true,
            }}
            $isError={Boolean(form.error.buildings)}
            label={t('settings:community.address.buildings')}
            name={'buildings'}
            type={'text'}
            value={form.values.buildings}
            onChange={handleChange}
          />
        </InfoPartFormWrapper>
      </SettingsInfoFormWrapper>
      <SettingsInfoFormWrapperAddAdress>
        <Br indent={8} />
        <SettingsSubsection label={t('settings:community.address.addTitle')} />
        <Br indent={4} />
        {community?.address_add?.map((el: addAddressContainer) => (
          <div key={el?.value?.id}>
            <Br indent={8} />
            <AddAddressFormEntry
              community={community}
              id={el.value.id}
              key={el.value.id}
              removeAddAddress={prepareToRemoveAddress}
              zip={el.value.zip}
              address={el.value.address}
              unit={el.value.unit}
            />
            <Br indent={8} />
          </div>
        ))}
        <>
          <Br indent={16} />
          <AddFieldStyles>
            <AddFieldTextWrapper>
              <AddFieldText>{t('settings:community.address.addAdressFirst')}</AddFieldText>
              <AddFieldText>{t('settings:community.address.addAdressSecond')}</AddFieldText>
            </AddFieldTextWrapper>

            <ConfirmBtn onClick={showModal}>{t('settings:community.admins.addAnAdress')}</ConfirmBtn>
          </AddFieldStyles>
          <Br indent={10} />
        </>
        {isShowModalConfirm && (
          <ConfirmationAdress
            community={community}
            title={modalsText.title}
            okText={modalsText.okText}
            onClose={handleCloseConfirm}
          />
        )}
      </SettingsInfoFormWrapperAddAdress>

      <Br indent={15} />
      <ErrorMessage error={form.error.message} />
      <FormButtons variant={'update'} disabled={form.loading} loading={form.loading} onCancel={onCancel} />
    </SectionWrapperForm>
  );
};
