import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {userState} from '../../../states/session';
import {User as UserType} from '../../../queries/types/user';
import {useCheckUserOnboarding, useVerifyEmail} from '../../../hooks/auth';
import {NavContainer} from '../../Navigation';
import {VerifyEmail} from '../../../components/Account/VerifyEmail/VerifyEmail';
import {typeUser as StateTypeUser} from '../../../states/typeUser';

export const VerifyEmailContainer: React.FC = () => {
  const {search} = useLocation();
  const verifyEmail = useVerifyEmail();
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const [user, setUser] = useRecoilState(userState);
  const typeUser = useRecoilValue(StateTypeUser);
  const checkUser = useCheckUserOnboarding();

  useEffect(() => {
    if (id && token) {
      verifyEmail(id, token)
        .then(() => {
          setUser((user) => ({...(user || {}), emailVerified: true} as UserType));
        })
        .catch((err) => console.log(err));
    }
  }, [id, token]);

  useEffect(() => {
    if (typeUser && user?.objectId) {
      setTimeout(() => {
        checkUser(typeUser, user);
      }, 3000);
    }
  }, [typeUser, user?.objectId]);

  return (
    <NavContainer>
      <VerifyEmail />
    </NavContainer>
  );
};
