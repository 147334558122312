import React, {FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Box} from '../../../common/Box';
import {
  CheckButton,
  CreateOwnBtnWrapper,
  CreateOwnText,
  CreateYourOwnWrapper,
  FormTopTitle,
  // InputCreateDescription,
  InputCreateName,
  ListingForm,
  MainWrapper,
  PriceInput,
  PriceWrapper,
  ServiceHeader,
  ServiceWrapper,
  SkipBtn,
} from '../styles';
import {ItemFields, OnboardingCategories, ServiceCategories, ServiceItem} from '../../../../types/item';
import {onboardingServices} from '../../../../hooks/item';
import {Menu} from '../../../../ui-kit/Menu';
import {MenuButton} from '../../../../ui-kit/Button/MenuButton';
import {MenuItemType} from '../../../../ui-kit/Menu/types';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {PricePeriodType} from '../../../../queries/types/item';
import {Button} from '../../../../ui-kit/Button/Button';
import {NumberFormatValues} from 'react-number-format';

interface IProps {
  currSign: string;
  onSubmit: () => Promise<void>;
  loading: boolean;
  setListings: React.Dispatch<React.SetStateAction<any>>;
  listings: {[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>};
  onSkipListing?: () => void;
  canSkipServices?: boolean;
}

interface OwnListingType {
  isChecked: boolean;
  isEdit: boolean;
  price: number;
  period: PricePeriodType;
  title: string;
  description: string;
  images: string;
}

const menu = ['day', 'hour', 'week', 'month', 'fixed'];

const initStateOwn = {
  isEdit: false,
  isChecked: true,
  price: 0,
  period: PricePeriodType.day,
  title: '',
  description: '',
  images: onboardingServices?.others?.images,
};

export const FirstListing: React.FC<IProps> = ({
  currSign,
  onSubmit,
  listings,
  setListings,
  loading,
  onSkipListing,
  canSkipServices,
}) => {
  const {t} = useTranslation();
  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [ownListing, setOwnListing] = useState<OwnListingType>(initStateOwn);
  const [isActiveCreate, setIsActiveCreate] = useState<boolean>(false);

  useEffect(() => {
    const count = Object.values(listings)?.reduce(
      (acc, item) => Object.values(item)?.reduce((acc, record) => (record?.isChecked ? ++acc : acc), acc),
      0,
    );
    setCheckedCount(count);
  }, [listings]);

  const handleCreateOwn = (key: string) => () => {
    if (!ownListing.title) return;
    const services = listings['others' as keyof typeof listings];
    const newStateService = {[key]: ownListing};
    const newState = {...listings, ['others' as keyof typeof listings]: {...services, ...newStateService}};
    setListings(newState);
    setOwnListing(initStateOwn);
    setIsCreating(false);
    setIsActiveCreate(false);
  };

  const handleChangeParams = (category: string, service: string, field: {key: string; value: any}) => () => {
    const key = field?.key;
    let value = field?.value;
    const services = listings[category as keyof typeof listings];
    const price = services?.[service as keyof typeof services]?.[key as keyof ServiceItem];
    if (key === 'price' && price === 0 && String(value)?.indexOf('0')) value = Number(String(value)?.replace('0', ''));
    const newStateService = {[service]: {...services?.[service as keyof typeof services], [key]: value}};
    const newState = {...listings, [category as keyof typeof listings]: {...services, ...newStateService}};
    setListings(newState);
  };

  const handleChangeOwn = (params: {key: keyof typeof ownListing; value: any}) => () => {
    const key = params?.key;
    let value = params?.value;
    const price = ownListing?.price;
    if (key === 'price' && price === 0 && String(value)?.indexOf('0')) value = Number(String(value)?.replace('0', ''));
    const newState = {...ownListing, [key]: value};
    setOwnListing(newState);
  };

  const handleCancelCreating = () => {
    setIsCreating(false);
    setOwnListing(initStateOwn);
    setIsActiveCreate(false);
  };

  const handleStartCreating = () => setIsCreating(true);

  const menuOptionsOwn = menu.map((opt) => {
    return {
      title: t(`listings:pricePeriod.${opt}`),
      onClick: handleChangeOwn({key: 'period', value: opt}),
    };
  }) as MenuItemType[];

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (ownListing.title?.length > 0) return setIsActiveCreate(true);
    onSubmit();
  };
  const canSave = ownListing.title?.length > 2;
  const showActions = ownListing.title?.length > 0;
  return (
    <MainWrapper>
      <FormTopTitle $noOffset>
        <Text variant={'secondary'} color={'quaternary'} size={38}>
          {t('auth:firstListing.secondTitle')}
        </Text>
      </FormTopTitle>
      <Box direction="column" display="flex" gap="16px">
        <Text variant={'primary'} color={'octonary'} size={16}>
          {t('auth:firstListing.secondSubtitle')}
        </Text>
        <ListingForm onSubmit={handleSubmit}>
          {Object.keys(listings || {})?.map((item, listingIndex) => {
            return (
              <>
                <ServiceHeader key={listingIndex}>
                  <Text variant={'secondary'} color={'quaternary'} size={16} weight={'bold'}>
                    {t(`common:onboarding_services.${item}.title`)}
                  </Text>
                </ServiceHeader>
                {Object.keys(listings[item as keyof typeof listings] || {})?.map((el, index) => {
                  const menuOptions = menu.map((opt) => {
                    return {
                      title: t(`listings:pricePeriod.${opt}`),
                      onClick: handleChangeParams(item, el, {key: 'period', value: opt}),
                    };
                  }) as MenuItemType[];
                  const localCategory = listings?.[item as keyof typeof listings];
                  const isEdit = localCategory?.[el as keyof typeof localCategory]?.isEdit;
                  const period = localCategory?.[el as keyof typeof localCategory]?.period;
                  const price = localCategory?.[el as keyof typeof localCategory]?.price;
                  const isChecked = localCategory?.[el as keyof typeof localCategory]?.isChecked;
                  const title = localCategory?.[el as keyof typeof localCategory]?.title;
                  return (
                    <div key={index}>
                      <ServiceParams
                        service={el}
                        category={item}
                        period={period}
                        isChecked={isChecked}
                        price={price}
                        isEdit={isEdit}
                        title={title}
                        menuOptions={menuOptions}
                        handleChangeParams={handleChangeParams}
                        sigh={currSign}
                      />
                    </div>
                  );
                })}
                {item === 'others' &&
                  (isCreating ? (
                    <CreateYourOwnWrapper $isActive={isActiveCreate}>
                      <Box direction={'column'} display={'flex'} gap={'5px'} height={'100%'} width={'70%'}>
                        <InputCreateName
                          placeholder={t('auth:firstListing.placeholderName')}
                          value={ownListing.title}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeOwn({key: 'title', value: event?.target?.value || ''})()
                          }
                        />
                        {/* <InputCreateDescription
                          placeholder={t('auth:firstListing.placeholderDescription')}
                          value={ownListing.description}
                          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                            handleChangeOwn({key: 'description', value: event?.target?.value || ''})()
                          }
                        /> */}
                      </Box>
                      <Box direction={'column'} display={'flex'} gap={'5px'}>
                        <PriceParams
                          service={String(Object.keys(listings?.[item as keyof typeof listings])?.length)}
                          category={item}
                          period={ownListing.period}
                          isChecked={ownListing.isChecked}
                          price={ownListing.price}
                          isEdit={ownListing.isEdit}
                          menuOptions={menuOptionsOwn}
                          handleChangeOwn={handleChangeOwn}
                          sigh={currSign}
                        />
                        {showActions && (
                          <Box
                            direction={'row'}
                            display={'flex'}
                            justify={'flex-end'}
                            gap={'15px'}
                            padding={'5px 0 0 0'}>
                            <CreateOwnBtnWrapper $enabled={true} onClick={handleCancelCreating}>
                              <IconSvg type={'close-circle'} width={'25px'} height={'25px'} />
                            </CreateOwnBtnWrapper>
                            <CreateOwnBtnWrapper
                              $enabled={canSave}
                              onClick={handleCreateOwn(
                                String(Object.keys(listings?.[item as keyof typeof listings])?.length),
                              )}>
                              <IconSvg
                                type={'check-circle'}
                                width={'25px'}
                                height={'25px'}
                                stroke={canSave ? 'aqua' : 'darkgray'}
                              />
                            </CreateOwnBtnWrapper>
                          </Box>
                        )}
                      </Box>
                    </CreateYourOwnWrapper>
                  ) : (
                    <ServiceWrapper onClick={handleStartCreating}>
                      <CheckButton>
                        <IconSvg
                          type={'add-circle'}
                          stroke={'gray'}
                          width={'25px'}
                          height={'25px'}
                          viewBox={'0 0 32 32'}
                        />
                        <CreateOwnText>{t('auth:firstListing.buttons.create')}</CreateOwnText>
                      </CheckButton>
                    </ServiceWrapper>
                  ))}
              </>
            );
          })}
          <Button
            loading={loading}
            width={'100%'}
            variant={'primary'}
            type={'submit'}
            disabled={!checkedCount || loading}>
            {t('auth:firstListing.buttons.add')}
          </Button>
        </ListingForm>
      </Box>
      {canSkipServices && (
        <SkipBtn onClick={onSkipListing} $isRow={true}>
          <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
            {t('auth:firstPost.skip')}
          </Text>
        </SkipBtn>
      )}
    </MainWrapper>
  );
};
interface Props {
  isEdit: boolean;
  period: string;
  price: number | string | undefined;
  isChecked: boolean;
  handleChangeParams?: (category: string, service: string, value: {key: string; value: any}) => () => void;
  sigh: string;
  category: string;
  service: string;
  menuOptions: MenuItemType[];
  title?: string;
}

const ServiceParams: React.FC<Props> = ({
  period,
  isEdit,
  price,
  isChecked,
  handleChangeParams,
  category,
  service,
  sigh,
  menuOptions,
  title,
}) => {
  return (
    <ServiceWrapper $isChecked={isChecked}>
      <CheckButton onClick={handleChangeParams?.(category, service, {key: 'isChecked', value: !isChecked})}>
        <IconSvg
          type={isChecked ? 'checked-circle' : 'not-checked-circle'}
          stroke={isChecked ? 'white' : 'gray'}
          fill={isChecked ? 'aqua' : 'white'}
          width={'25px'}
          height={'25px'}
          viewBox={'0 0 30 30'}
        />
        <Text variant={'secondary'} color={'quaternary'} size={14}>
          {title}
        </Text>
      </CheckButton>
      <PriceParams
        service={service}
        category={category}
        period={period}
        isChecked={isChecked}
        price={price}
        isEdit={isEdit}
        menuOptions={menuOptions}
        handleChangeParams={handleChangeParams}
        sigh={sigh}
      />
    </ServiceWrapper>
  );
};

interface OwnProps {
  handleChangeOwn?: (params: {key: keyof OwnListingType; value: any}) => () => void;
}

const PriceParams: React.FC<Props & OwnProps> = ({
  period,
  isEdit,
  price,
  handleChangeParams,
  category,
  service,
  sigh,
  menuOptions,
  handleChangeOwn,
}) => {
  const {t} = useTranslation();
  return (
    <PriceWrapper>
      {!price && !isEdit ? (
        <Box
          width={'100%'}
          padding={'0 8px 0 0'}
          onClick={
            handleChangeOwn
              ? handleChangeOwn({key: 'isEdit', value: !isEdit})
              : handleChangeParams?.(category, service, {key: 'isEdit', value: !isEdit})
          }>
          <Text size={16} decoration={'underline'} color={'octonary'}>
            {t('listings:options.free')}
          </Text>
        </Box>
      ) : (
        <PriceInput
          value={price}
          name={ItemFields.price}
          allowNegative={false}
          decimalScale={2}
          decimalSeparator={'.'}
          thousandSeparator={','}
          libPrefix={sigh || '$'}
          onBlur={
            handleChangeOwn
              ? handleChangeOwn({key: 'isEdit', value: !isEdit})
              : handleChangeParams?.(category, service, {key: 'isEdit', value: !isEdit})
          }
          onValueChange={(event: NumberFormatValues) =>
            handleChangeOwn
              ? handleChangeOwn({key: 'price', value: event?.floatValue || 0})()
              : handleChangeParams?.(category, service, {key: 'price', value: event?.floatValue || 0})()
          }
          allowLeadingZeros={false}
        />
      )}
      <MenuButton
        item={category}
        menu={Menu}
        options={menuOptions}
        prevent={true}
        alwaysShowBtn={true}
        right={true}
        customIcon={
          <Box display={'flex'} direction={'row'} justify={'flex-end'} items={'center'}>
            <Text variant={'primary'} color={'octonary'} size={12}>
              {t(`listings:pricePeriod.${period}`)}
            </Text>
            <IconSvg type={'arrow-down'} height={'10px'} width={'10px'} viewBox={'0 0 8 8'} />
          </Box>
        }
      />
    </PriceWrapper>
  );
};
