import React, {useState} from 'react';

import {useUpdateUser, useViewerId} from '../../hooks/user';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {userState} from '../../states';
import {useCommunityUpdate} from '../../hooks/community';
import {AccountCheckListType, communityCheckItem} from '../../queries/types/checklist';
import {toStateCommunity} from '../../helpers/community';
import {ChecklistMobile} from '../../components/Checklist/ChecklistMobile';

const ChecklistMobileContainer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(!isOpen);
  const userId = useViewerId();
  const [currentComm, updateCurrentComm] = useRecoilState(currentCommunity);
  const [user, updateUser] = useRecoilState(userState);
  const update = useUpdateUser();
  const {update: updateCommunity} = useCommunityUpdate();

  const hideCompletedCheck = (nameField: keyof AccountCheckListType) => async () => {
    if (nameField in communityCheckItem) {
      if (!currentComm?.objectId || !currentComm.checkList) return;

      const {data} = await updateCommunity({
        id: currentComm?.objectId,
        fields: {checkList: {...currentComm.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });

      data?.updateCommunity.community && updateCurrentComm(toStateCommunity(data.updateCommunity.community));
    } else {
      if (!userId || !user?.checkList) return;

      const {data} = await update({
        id: userId,
        fields: {checkList: {...user.checkList, [nameField]: {isHide: true, isChecked: true}}},
      });
      data?.updateUser.user && updateUser(data?.updateUser.user);
    }
  };

  return (
    <ChecklistMobile
      onClose={onClose}
      hideCompletedCheck={hideCompletedCheck}
      checkList={{...(user?.checkList || {}), ...(currentComm?.checkList || {})} as AccountCheckListType}
      isOpen={isOpen}
    />
  );
};

export default ChecklistMobileContainer;
