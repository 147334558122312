import React, {useEffect, useState} from 'react';
import {QuoteText, QuoteTitle, QuoteWrapper, QuoteAuthor, CloseMobBtn} from './styles';
import {QuoteBlockManagerPropsT, QuoteBlockPropsT, QuotesJsonList} from './types';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {quoteBase} from '../../types/helpDesk';
import {useTranslation} from 'react-i18next';

export const QuoteBlock: React.FC<QuoteBlockPropsT> = ({handleClose, quotes}) => {
  const {t} = useTranslation();
  const [randomQuote, setRandomQuote] = useState<QuotesJsonList | undefined>();
  useEffect(() => {
    const quote = quotes?.[Math.floor(Math.random() * quotes?.length)];
    !randomQuote?.author && setRandomQuote(quote);
  }, [quotes?.length]);
  return (
    <QuoteWrapper>
      <CloseMobBtn onClick={handleClose}>
        <IconSvg type={'close'} />
      </CloseMobBtn>
      <QuoteTitle>{t(`helpDesk:baseDesks.quote.${quoteBase.dailyQuote}`)}</QuoteTitle>
      {randomQuote?.author && randomQuote?.text && (
        <>
          <QuoteText>{randomQuote.text}</QuoteText>
          <QuoteAuthor>{randomQuote.author}</QuoteAuthor>
        </>
      )}
    </QuoteWrapper>
  );
};

export const QuoteBlockManager: React.FC<QuoteBlockManagerPropsT> = ({quotes}) => {
  const {t} = useTranslation();
  // const [published, setPublished] = useState(quote?.isPublished);
  // const base = quote?.title === quoteBase.dailyQuote;
  // const getMenuItemsFunctions: GetMenuQuoteFuncOptions = {
  //   t,
  //   unPublishCallBack: async (id?: string) => {
  //     handlePublish(false, id);
  //     setPublished(false);
  //   },
  //   publishCallBack: async (id?: string) => {
  //     await handlePublish(true, id);
  //     setPublished(true);
  //   },
  // };
  // const getMenuQuoteOptions: GetMenuQuoteOptions = {
  //   objectId: quote?.objectId,
  //   isPublished: published,
  // };
  // const menuItems: MenuItemType[] = !base ? [...getMenuQuote(getMenuQuoteOptions, getMenuItemsFunctions)] : [];
  // const mobileMenuItems: MobileMenuEntry[] = !base
  //   ? [...getMobileMenuQuote(getMenuQuoteOptions, getMenuItemsFunctions)]
  //   : [];
  // const prevent = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };
  const [randomQuote, setRandomQuote] = useState<QuotesJsonList | undefined>();
  useEffect(() => {
    const quote = quotes?.[Math.floor(Math.random() * quotes?.length)];
    !randomQuote?.author && setRandomQuote(quote);
  }, [quotes?.length]);
  return (
    <QuoteWrapper>
      <QuoteTitle>{t(`helpDesk:baseDesks.quote.${quoteBase.dailyQuote}`)}</QuoteTitle>
      {randomQuote?.author && randomQuote?.text && (
        <>
          <QuoteText>{randomQuote.text}</QuoteText>
          <QuoteAuthor>{randomQuote.author}</QuoteAuthor>
        </>
      )}
    </QuoteWrapper>
  );
};
