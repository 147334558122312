import styled from 'styled-components';

export const UiCopyButton = styled.button`
  text-decoration: underline;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  position: relative;
`;

export const InnerAlias = styled.div`
  position: relative;
`;

export const CopiedText = styled.div`
  --bg-color: ${({theme}) => theme.palette.background.quaternary};
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  position: absolute;
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.primary};
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s;
  width: max-content;
  background-color: var(--bg-color);
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--bg-color) transparent transparent transparent;
  }
`;
