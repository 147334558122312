declare global {
  interface Window {
    _env_: {
      REACT_APP_APPLICATION_ID: string | undefined;
      REACT_APP_GRAPHQL_URI: string | undefined;
      REACT_APP_API_KEY: string | undefined;
      REACT_APP_SERVER_URI: string | undefined;
      REACT_APP_SITE_URL: string | undefined;
      REACT_APP_HANGEH_VERSION: string | undefined;
      REACT_APP_ANALYTICS_KEY: string | undefined;
      REACT_APP_SLACK_BOT_ACCESS_TOKEN: string | undefined;
      REACT_APP_SLACK_CHANNEL: string | undefined;
      REACT_APP_GTAG_ID: string | undefined;
      REACT_APP_LQ_SERVER_URL: string | undefined;
      REACT_APP_GENDER_API: string | undefined;
      REACT_APP_PIXELS_KEY: string | undefined;
      REACT_APP_APP_STORE_ID: string | undefined;
      REACT_APP_APP_STORE_URL: string | undefined;
      REACT_APP_APP_GOOGLE_PLAY_URL: string | undefined;
      REACT_APP_HANGEH_COM: string | undefined;
      REACT_APP_CLIENT_GOOGLE_ID: string | undefined;
      REACT_APP_CLIENT_FACEBOOK_ID: string | undefined;
      REACT_APP_CLIENT_APPLE_ID: string | undefined;
    };
    credE?: string;
    credP: string;
    isFromApp?: boolean;
    reqURL?: string;
    OGData?: any;
  }
}

export const APPLICATION_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APPLICATION_ID
    : process.env.REACT_APP_APPLICATION_ID;

export const GRAPHQL_URI =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GRAPHQL_URI
    : process.env.REACT_APP_GRAPHQL_URI;

export const SERVER_URI =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SERVER_URI : process.env.REACT_APP_SERVER_URI;

export const SITE_URL =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SITE_URL : process.env.REACT_APP_SITE_URL;

export const API_KEY =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_API_KEY : process.env.REACT_APP_API_KEY;

export const HANGEH_VERSION =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_HANGEH_VERSION
    : process.env.REACT_APP_HANGEH_VERSION;

export const ANALYTICS_KEY =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_ANALYTICS_KEY
    : process.env.REACT_APP_ANALYTICS_KEY;

export const SLACK_BOT_ACCESS_TOKEN =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_SLACK_BOT_ACCESS_TOKEN
    : process.env.REACT_APP_SLACK_BOT_ACCESS_TOKEN;

export const SLACK_CHANNEL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_SLACK_CHANNEL
    : process.env.REACT_APP_SLACK_CHANNEL;

export const GTAG_ID =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_GTAG_ID : process.env.REACT_APP_GTAG_ID;

export const LQ_SERVER_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_LQ_SERVER_URL
    : process.env.REACT_APP_LQ_SERVER_URL;

export const GENDER_API =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_GENDER_API : process.env.REACT_APP_GENDER_API;

export const PIXELS_API =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_PIXELS_KEY : process.env.REACT_APP_PIXELS_KEY;

export const APP_STORE_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APP_STORE_ID
    : process.env.REACT_APP_APP_STORE_ID;

export const APP_STORE_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APP_STORE_URL
    : process.env.REACT_APP_APP_STORE_URL;

export const GOOGLE_PLAY_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APP_GOOGLE_PLAY_URL
    : process.env.REACT_APP_APP_GOOGLE_PLAY_URL;

export const HANGEH_COM =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_HANGEH_COM : process.env.REACT_APP_HANGEH_COM;

export const CLIENT_GOOGLE_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_CLIENT_GOOGLE_ID
    : process.env.REACT_APP_CLIENT_GOOGLE_ID;

export const CLIENT_FACEBOOK_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_CLIENT_FACEBOOK_ID
    : process.env.REACT_APP_CLIENT_FACEBOOK_ID;

export const CLIENT_APPLE_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_CLIENT_APPLE_ID
    : process.env.REACT_APP_CLIENT_APPLE_ID;

console.log('APPLICATION_ID', APPLICATION_ID);
