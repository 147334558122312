import React from 'react';
import {TypeSteps} from '../../../types/resident';
import {Steps} from '../../../containers/Account/Create/Resident/Steps';
import {RequestCommunity, TRequestCommunityProps} from '../../RequestCommunity/RequestCommunity';

export const StepRequestCommunity: React.FC<TRequestCommunityProps> = ({sendEmail}) => {
  return (
    <Steps step={TypeSteps.searchCommunity}>
      <RequestCommunity sendEmail={sendEmail} />
    </Steps>
  );
};
