import Cookies from 'js-cookie';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';

type TokenType = string | null | undefined;
// let token: TokenType;
export const getToken = (): TokenType => {
  return Cookies.get('token');
};

export const setToken = (token: TokenType): TokenType | void => {
  if (!token) {
    return Cookies.remove('token');
  }

  return Cookies.set('token', token, {expires: 365});
};

const withToken = setContext(async (_, {headers}) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      ...(token ? {'X-Parse-Session-Token': token} : {}),
    },
  };
});

const resetToken = onError(({graphQLErrors}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (graphQLErrors && graphQLErrors.find((err) => err?.extensions?.exception?.networkError?.result?.code === 209)) {
    setToken(null);
  }

  // if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
  //   setToken(null).then();
  // }
});

export const link = withToken.concat(resetToken);
