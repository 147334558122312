import React from 'react';
import {CardWrapper, InfoWrapperSmall, PeopleCard, PersonLabels, PersonName} from '../../People/styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {route} from '../../../constants/routes';
import {useLinks} from '../../../hooks/common';
import {getResize} from '../../../helpers/file';
import {PersonType} from '../../People/PersonCard/PersonType';
import {useGetAdminsLabels} from '../../../hooks/people';
import {MemberT} from '../../../types/people';
import {Listings} from '../../../ui-kit/ProfileIntro/labels';
import {useTranslation} from 'react-i18next';
import {TCommunity} from '../../../types/community';
import {useGetUserItems} from '../../../hooks/item';
import {TypeCommunity} from '../../../types/auth';
import {getUserName} from '../../../helpers/user';

type PersonProps = Pick<
  MemberT,
  | 'Avatar'
  | 'isVerified'
  | 'id'
  | 'objectId'
  | 'status'
  | 'firstName'
  | 'lastName'
  | 'aptSuite'
  //| 'listingsCount'
  | 'listingCount'
  | 'Reviews'
  | 'firstLetter'
> & {
  viewerId?: string;
  isAdmin?: boolean;
  isManager?: boolean;
  comm?: Partial<TCommunity> | null;
  onlineDate?: Date;
  isOnline?: boolean;
  typeUser?: TypeCommunity | null;
};

export const PersonCommonCard: React.FC<PersonProps> = ({
  firstName,
  lastName,
  Avatar: avatar,
  objectId,
  isAdmin,
  isManager,
  viewerId,
  comm,
  typeUser,
  firstLetter,
  isOnline,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const adminLabels = useGetAdminsLabels();
  const items = useGetUserItems(objectId, comm);
  const profileLink = viewerId !== objectId ? getLink(route.profile.get({id: objectId})) : getLink(route.pageUser.path);
  const userName = getUserName(typeUser, firstName, lastName || firstLetter);
  return (
    <>
      <PeopleCard to={profileLink}>
        <CardWrapper>
          <Avatar src={getResize(avatar?.file?.url, 'md')} size={'sm'} $isOnline={isOnline} />
          <InfoWrapperSmall>
            <PersonName>
              <span>{userName}</span>
            </PersonName>
            <PersonLabels>
              <PersonType isAdmin={isAdmin} isManager={isManager} adminLabels={adminLabels} userId={objectId} />
              <Listings count={items.data.length || 0} label={t('search:labels.listings')} />
            </PersonLabels>
          </InfoWrapperSmall>
        </CardWrapper>
      </PeopleCard>
    </>
  );
};
