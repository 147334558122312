import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {parseJSON} from 'date-fns';
import {route} from '../../constants/routes';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {Media} from '../../ui-kit/theme';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {IActionsOnDocumentsData} from './types';
import {useGetMenuItemsForDocument} from './hooks';
import {TDocument} from '../../types/document';
import {MemoFileCover} from './FileCover';
import {CardWrapper, DocumentCardTitle, FormatLabel, MenuBtnWrapper, StateLabel, SubtitleWrapper} from './styles';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {useLinks} from '../../hooks/common';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {EmptyTag} from '../../containers/MobileTabs';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

interface IProps {
  document: TDocument;
  actionsData: IActionsOnDocumentsData;
  openEditDocModal: (doc: TDocument) => void;
  canEdit: boolean;
}

export const CardDocument: React.FC<IProps> = ({document, actionsData, openEditDocModal, canEdit}) => {
  const {name, createdAt, Cover, objectId, isPublished, viewerIsOwner, format} = document || {};
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);
  const {getLink} = useLinks();
  const {t} = useTranslation();

  const label = !isPublished ? t('documents:card.labels.unpublished') : undefined;

  const editDocument = () => {
    openEditDocModal(document);
  };
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  const {menuItems, mobileMenuItems} = useGetMenuItemsForDocument({
    data: {...actionsData, onDelete: handleOpenDelete, edit: editDocument},
    document,
    canEdit,
  });

  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('common:modals.delete.title'),
        okText: t('common:modals.delete.button'),
        cancelText: t('common:modals.delete.close'),
      },
    };
  }, []);
  const ModalWrapper = isMobile ? BasePortal : EmptyTag;
  const handleDelete = () => actionsData.deleteDoc(objectId);
  if (!isPublished && !(viewerIsOwner || canEdit)) return null;

  return (
    <>
      <CardWrapper>
        <Link to={getLink(route.documentView.get({id: objectId}))}>
          <MemoFileCover src={Cover?.file.url} format={format} isHaveLabel={!!label} />
          <Br indent={8} />
          <DocumentCardTitle>{name}</DocumentCardTitle>
          {label ? <StateLabel>{label}</StateLabel> : null}
        </Link>
        <SubtitleWrapper>
          <FormatLabel>
            <Text size={10} color={'octonary'} case={'uppercase'}>
              {format}
            </Text>
          </FormatLabel>
          <Text size={14} weight={'medium'} color={'octonary'}>
            {createdAt && t('common:datePP', {date: parseJSON(createdAt)})}
          </Text>
        </SubtitleWrapper>
        {canEdit && (
          <MenuBtnWrapper>
            {!!menuItems.length && (
              <HiddenElement mq={Media.down.s}>
                <MenuButton menu={Menu} item={{objectId}} options={menuItems} />
              </HiddenElement>
            )}
            {!!mobileMenuItems.length && (
              <HiddenElement mq={Media.up.m}>
                <MobileMenuButton entries={mobileMenuItems} isPortal={true} portalProvider={IsModalProvider} />
              </HiddenElement>
            )}
          </MenuBtnWrapper>
        )}
      </CardWrapper>
      {isShowModalDelete && (
        <ModalWrapper>
          <Confirmation
            title={modalsText.delete.title}
            onClose={handleCloseDelete}
            okText={modalsText.delete.okText}
            onOk={handleDelete}
            buttonType={'danger'}
            cancelText={modalsText.delete.cancelText}
          />
        </ModalWrapper>
      )}
    </>
  );
};
