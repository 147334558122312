import React, {useMemo} from 'react';
import {AttachmentFile, AttachmentImage, AttachmentItem, AttachmentsLine} from '../styles';
import {checkIsImage, getClearName} from '../../../../helpers/feed';
import {isImage} from '../../../../helpers/settings';
import {getResize} from '../../../../helpers/file';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {isMobile} from 'react-device-detect';
import {PointerFile} from '../../../../types/common';

type TProps = {
  Attachments?: PointerFile[];
  setIsModal?: (val: boolean) => void;
  isLocal?: boolean;
};

const options = {
  showHideAnimationType: 'zoom',
  closeOnScroll: false,
};

const Attachment: React.FC<{name: string; url: string; onClick?: (e?: any) => void}> = ({name, url, onClick}) => {
  if (!name || !url) return null;
  if (checkIsImage(name)) return <AttachmentImage src={getResize(url, 'sm')} onClick={onClick} />;

  return (
    <AttachmentFile href={url} download={name} target={'_self'}>
      <IconSvg type={'file'} viewBox={'0 0 50 50'} width={'50px'} height={'50px'} stroke={'gray-2'} />
      <span>{getClearName(name)}</span>
    </AttachmentFile>
  );
};

export const PostAttachments: React.FC<TProps> = ({Attachments, setIsModal, isLocal}) => {
  //const [previewImg, setPreviewImg] = useState<string | undefined>();
  const images = useMemo(
    () =>
      Attachments?.filter((a) => isImage(a.file.name))?.map((a) =>
        isLocal ? a.file?.url : getResize(a.file.url, 'lg'),
      ) ?? [],
    [Attachments, isLocal],
  );
  const files = useMemo(() => Attachments?.filter((a) => !isImage(a.file.name)) ?? [], [Attachments]);
  //const handleClose = () => setPreviewImg(undefined);
  /*const handleClickImg = (src: string) => (e?: any) => {
    e.preventDefault();
    setPreviewImg(src);
  };*/
  const prevent = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const onOpen = () => {
    if (!isMobile) return;
    setIsModal?.(true);
    const interval = setInterval(() => {
      const element = document?.querySelector('.pswp--open');
      if (!element) {
        clearInterval(interval);
        setIsModal?.(false);
      }
    }, 500);
  };
  if (!Attachments?.length) return null;
  return (
    <AttachmentsLine onClick={prevent}>
      <Gallery shareButton={false} zoomButton={true} fullscreenButton={false} options={options} onOpen={onOpen}>
        {files?.map((el, ind) => (
          <AttachmentItem $bordered={!checkIsImage(el.file.name)} key={el.objectId || ind}>
            <Attachment name={el.file.name} url={el.file.url} />
          </AttachmentItem>
        ))}
        {images?.map((item, index) => (
          <span key={index} className={'whi2'}>
            <Item original={item} width={'1024'} height={'768'}>
              {({ref, open}) => (
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  className={'whi1'}
                  onClick={open}
                  src={item}
                  alt={''}
                />
              )}
            </Item>
          </span>
        ))}
        {/*<PreviewImage
        images={images}
        previewImg={getResize(previewImg, 'lg')}
        handleClose={handleClose}
        closeOutside={true}
      />*/}
      </Gallery>
    </AttachmentsLine>
  );
};
