import React, {useEffect, useRef, useState} from 'react';
import {fallbackLng, localeType} from '../../libs/i18nextUtils';
import {setLocale} from '../../libs/i18next';
import {
  Arrow,
  Label,
  SecondarySelect,
  SecondarySelectOption,
  SecondarySelectWrapper,
  Select,
  SelectBody,
  SelectBodySelected,
  SelectOption,
  SelectWrapper,
} from './styles';
import {langLabel} from '../../constants/lang';
import {useGetLanguages} from '../../hooks/language';

interface SelectProps {
  values: localeType[];
  onChange: (value: any) => void;
  selected: localeType;
}

type switchVariant = 'primary' | 'secondary';

const PrimaryTranslateSelect: React.FC<SelectProps> = ({values, onChange, selected}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleChange = (value: localeType) => () => {
    onChange(value);
    handleClose();
    selectRef?.current?.blur();
  };

  return (
    <SelectWrapper>
      <Select tabIndex={-1} onFocus={handleOpen} onBlur={handleClose} ref={selectRef}>
        <SelectBodySelected>{selected}</SelectBodySelected>
        {open && (
          <SelectBody>
            {values.map((el) => (
              <SelectOption onClick={handleChange(el)} key={el} $selected={el === selected}>
                {el}
              </SelectOption>
            ))}
          </SelectBody>
        )}
      </Select>
    </SelectWrapper>
  );
};

const SecondaryTranslateSelect: React.FC<SelectProps> = ({values, onChange, selected}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleChange = (value: localeType) => () => {
    onChange(value);
    handleClose();
    selectRef?.current?.blur();
  };

  const label = langLabel[selected];

  return (
    <SecondarySelectWrapper>
      <SecondarySelect tabIndex={-1} onFocus={handleOpen} onBlur={handleClose} ref={selectRef}>
        <Label>
          {label} <Arrow />
        </Label>
        <SelectBody $visibility={open}>
          {values.map((el) => (
            <SecondarySelectOption onClick={handleChange(el)} key={el} $selected={el === selected}>
              {langLabel[el]}
            </SecondarySelectOption>
          ))}
        </SelectBody>
      </SecondarySelect>
    </SecondarySelectWrapper>
  );
};

const SelectComponent: Record<switchVariant, React.FC<SelectProps>> = {
  primary: PrimaryTranslateSelect,
  secondary: SecondaryTranslateSelect,
};

export const TranslateSwitch: React.FC<{variant?: switchVariant}> = ({variant = 'primary'}) => {
  const languages = useGetLanguages();
  const [lang, setLang] = useState<localeType>(localeType.en);
  const [options, setOptions] = useState<localeType[]>([]);

  const handle = (value: any) => {
    setLang(value);
  };
  useEffect(() => {
    lang && setLocale(lang);
  }, [lang]);

  useEffect(() => {
    languages.refetch();
  }, []);

  useEffect(() => {
    const newOptions = languages.data?.map((el) => el.code as localeType);
    setOptions(newOptions || []);
  }, [languages.data?.length]);

  const Select = SelectComponent[variant];

  useEffect(() => {
    (async () => {
      const lng = (await window.localStorage.getItem('language')) || fallbackLng;
      setLocale(lng as localeType);
      setLang(lng as localeType);
    })();
  }, []);

  const showSelect = options?.length > 1;

  return <>{showSelect && <Select values={options} onChange={handle} selected={lang} />}</>;
};
