import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {EventForm} from '../../components/Events/Form';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {route} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useEditEvent, useEventFormData, useGetEvent} from '../../hooks/event';
import {EventFormAction} from '../../types/event';
import {dataToEditEventFields} from '../../helpers/event';

export const EditEventContainer = () => {
  const [community] = useRecoilState(currentCommunity);
  const {id} = useParams<{id?: string}>();
  const {push} = useHistory();
  const {data: event, loading: loadingGetItem} = useGetEvent({id});
  const formData = useEventFormData({images: event?.images});
  const {getLink} = useLinks();
  const {currency} = useGetCurrencyValue();

  const {t} = useTranslation();
  const textSeo = t('common:seo.events.edit', {text: event?.name});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  const {loading: loadingEditItem, ...data} = useEditEvent({
    initialState: {...dataToEditEventFields(event), images: formData.dataImages},
    onSuccess: () => push(getLink(route.eventView.get({id: id ?? ''}))),
  });

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <EventForm
        communityId={community?.id}
        action={EventFormAction.edit}
        data={{...data, loading: loadingGetItem || loadingEditItem}}
        formData={formData}
        currSign={currency?.sign || ''}
      />
    </>
  );
};
