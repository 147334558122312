import React from 'react';
import {NavContainer} from '../Navigation';
import {ReferralsSettings} from '../../components/Referrals/ReferralsSettings';
import {useViewer} from '../../hooks/user';
import {useGetUserCredits, useGetUserReferrals} from '../../hooks/referral';
import {createReferralLink} from '../../helpers/referral';

export const Referrals = () => {
  const viewer = useViewer();
  const link = createReferralLink(viewer?.ReferralCode);
  const {referrals} = useGetUserReferrals();
  const {totalCredits, totalReferrals} = useGetUserCredits();
  return (
    <NavContainer>
      <ReferralsSettings
        referralLink={link}
        inviterName={viewer?.firstName || ''}
        referrals={referrals}
        totalCredits={totalCredits}
        totalReferrals={totalReferrals}
      />
    </NavContainer>
  );
};
