import {IValidation} from './types';
import {anyObject} from '../../types/helpers';
import {Validation} from './Validation';

export class ValidationUnion<T extends anyObject> extends Validation<T> {
  private readonly $validations: IValidation<T>[];

  constructor(validations: IValidation<T>[], message?: string) {
    super(message);
    this.$validations = validations;
  }

  validate(value: unknown, data: T): boolean {
    if (this.$validateNullable(value)) return this.$isNullable;

    const results = this.$validations.map((validation) => validation.validate(value as T[keyof T], data));

    // OR
    const isValid = results.includes(true);

    if (!isValid) {
      this.$message = this.$validations[results.findIndex((valid) => !valid)]?.message || this.$message;
    }

    return isValid;
  }
}
