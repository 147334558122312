import React from 'react';
import {UserWidgetProps} from './types';
import {
  CardLinkWrapper,
  CardUserInfoWrapper,
  ContentWrapperFlex,
  TextContainer,
  TextContainerEntry,
  UserCardAvatarWrapper,
  UserCardIcon,
  UserCardUsername,
  UserCardWrapper,
} from './styles';
import {Avatar} from '../Avatar/Avatar';
import {getFullName} from './utils';
import {IconSvg} from '../Icon/Svg';
import {Status} from '../ProfileIntro/labels';

export const UserCard: React.FC<UserWidgetProps> = ({user, hideReviews, hideVerified, label, userName}) => {
  return (
    <UserCardWrapper>
      <ContentWrapperFlex>
        <UserCardAvatarWrapper isOnline={user?.isOnline}>
          <Avatar src={user?.avatar} size={'md'} />
        </UserCardAvatarWrapper>
        <ContentWrapperFlex direction={'column'}>
          <TextContainer>
            <UserCardUsername>{userName ? userName : getFullName(user, 20)}</UserCardUsername>
          </TextContainer>
          <CardLinkWrapper spaceBetween={true}>
            {label?.name && label?.status && <Status status={label.status} name={label.name} />}
            {!hideVerified && user?.verified !== '' && (
              <CardUserInfoWrapper>
                <UserCardIcon width={16}>
                  <IconSvg type={'checkmark-circle'} stroke={'green'} viewBox={'0 0 16 16'} />
                </UserCardIcon>
                <TextContainerEntry bold={true}>{user?.verified}</TextContainerEntry>
              </CardUserInfoWrapper>
            )}
            {!hideReviews && user?.reviews !== '' && (
              <CardUserInfoWrapper>
                <UserCardIcon width={16}>
                  <IconSvg type={'star'} stroke={'yellow'} />
                </UserCardIcon>
                <TextContainerEntry bold={true}>{user?.reviews}</TextContainerEntry>
              </CardUserInfoWrapper>
            )}
          </CardLinkWrapper>
        </ContentWrapperFlex>
      </ContentWrapperFlex>
    </UserCardWrapper>
  );
};
