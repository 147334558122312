import {gql} from '@apollo/client';
import {LanguageFragment} from './fragments/language';
import {Connection} from './types/parse';
import {Language} from './types/language';

export interface GetLanguageByCodeRes {
  languages: Connection<Language>;
}

export interface GetLanguageByCodeParams {
  langCode: string;
}

export const GetLanguageByCodeQuery = gql`
  query getLanguage($langCode: String) {
    languages(where: {code: {equalTo: $langCode}}) {
      edges {
        node {
          ...LanguageFragment
        }
      }
    }
  }
  ${LanguageFragment}
`;

export interface GetLanguagesRes {
  languages: Connection<Language>;
}

export const GetLanguages = gql`
  query languages {
    languages {
      edges {
        node {
          ...LanguageFragment
        }
      }
    }
  }
  ${LanguageFragment}
`;
