import {gql} from '@apollo/client';
import {Session} from './types/auth';
import sessionFragment from './fragments/session';

export interface ViewerResponseType {
  viewer: Session;
}

export const ViewerQuery = gql`
  query viewer {
    viewer {
      ...SessionFragment
    }
  }
  ${sessionFragment}
`;

export interface LogInResponseType {
  logIn: {
    viewer: Session;
  };
}
export interface LogInRequestType {
  username: string;
  password: string;
}

export const LogInQuery = gql`
  mutation logIn($username: String!, $password: String!) {
    logIn(input: {username: $username, password: $password}) {
      viewer {
        ...SessionFragment
      }
    }
  }
  ${sessionFragment}
`;

export interface SignOutResponseType {
  logOut: {
    ok: boolean;
  };
}

export const SignOutQuery = gql`
  mutation logOut {
    logOut(input: {clientMutationId: "logOut"}) {
      ok
    }
  }
`;

export interface SignUpResponseType {
  signUp: {
    viewer: Session;
  };
}
export interface SignUpRequestType {
  fields: {
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    work?: string;
  };
}

export const SignUpQuery = gql`
  mutation signUp($fields: CreateUserFieldsInput) {
    signUp(input: {fields: $fields}) {
      viewer {
        ...SessionFragment
      }
    }
  }
  ${sessionFragment}
`;

export type ResetPasswordParams = {
  email: string;
};

export type ResetPasswordResponse = {
  resetPassword: {
    ok: boolean;
  };
};

export const ResetPassword = gql`
  mutation resetPassword($email: String!, $clientMutationId: String! = "resetPassword") {
    resetPassword(input: {email: $email, clientMutationId: $clientMutationId}) {
      ok
    }
  }
`;

export type ChangePasswordParams = {
  id: string;
  password: string;
  token: string;
};

export type ChangePasswordResponse = {
  changePassword: {
    ok: boolean;
  };
};

export const ChangePassword = gql`
  mutation changePassword(
    $id: ID!
    $token: String!
    $password: String!
    $clientMutationId: String! = "changePassword"
  ) {
    changePassword(input: {id: $id, token: $token, password: $password, clientMutationId: $clientMutationId}) {
      ok
    }
  }
`;

export interface PhoneVerificationResponse {
  verifyPhone: {
    ok?: boolean;
  };
}

export interface PhoneVerificationRequest {
  phone: string;
}

export const VerifyPhone = gql`
  mutation verifyUserPhone($phone: String!) {
    verifyPhone(input: {phone: $phone}) {
      ok
    }
  }
`;

export interface CodeVerificationRequest {
  input: {
    phone: string;
    code: string;
  };
}

export interface CodeVerificationResponse {
  verifyCode: {
    ok?: boolean;
  };
}

export const VerifyCode = gql`
  mutation verifyPhoneCode($input: VerifyCodeInp!) {
    verifyCode(input: $input) {
      ok
    }
  }
`;

export interface VerifyEmailRequest {
  input: {
    id: string;
    token: string;
  };
}

export interface VerifyEmailResponse {
  verifyEmail: {
    ok: boolean;
  };
}

export const VerifyEmail = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      ok
    }
  }
`;

export interface TResetPasswordRequest {
  email: string;
}
export interface TResetPasswordResponse {
  resetPassword: {
    ok: boolean;
  };
}

export const ResetPasswordQuery = gql`
  mutation resetPassword($email: String!) {
    resetPassword(input: {email: $email}) {
      ok
    }
  }
`;

export interface TChangePasswordResponse {
  changePassword: {
    ok: boolean;
  };
}

export interface TChangePasswordRequest {
  id: string;
  password: string;
  token: string;
}

export const ChangePasswordQuery = gql`
  mutation changePassword($id: ID!, $password: String!, $token: String!) {
    changePassword(input: {id: $id, password: $password, token: $token}) {
      ok
    }
  }
`;

export interface DirectAddMemberResponse {
  directAddMember: {
    added: string;
  };
}

export interface DirectAddMemberRequest {
  autoAddKey: string;
}

export const DirectAddMember = gql`
  mutation directAddMember($autoAddKey: ID!) {
    directAddMember(input: {autoAddKey: $autoAddKey}) {
      added
    }
  }
`;

export interface SignWithResponseType {
  loginWith: {
    viewer: Session;
  };
}
export interface SignWithFBRequestType {
  authData: {
    facebook: {
      id: string;
      access_token: string;
      expiration_date: string;
    };
  };
  fields?: {
    email: string;
  };
}
export interface SignWithGoogleRequestType {
  authData: {
    google: {
      id: string;
      id_token: string;
      access_token: string;
    };
  };
  fields?: {
    email: string;
  };
}

export interface SignWithAppleRequestType {
  authData: {
    apple: {
      id: string;
      token: string;
    };
  };
  fields?: {
    email: string;
  };
}

export const SignWithSocial = gql`
  mutation loginWithSocial($authData: Object!, $fields: UserLoginWithInput) {
    logInWith(input: {authData: $authData, fields: $fields}) {
      viewer {
        ...SessionFragment
      }
    }
  }
  ${sessionFragment}
`;

export const SocialConnect = gql`
  mutation socialConnect($provider: String!, $authData: Object!) {
    linkWithSocial(input: {provider: $provider, authData: $authData})
  }
`;

export interface TSocialDisconnectRequest {
  provider: string;
}

export const SocialDisconnect = gql`
  mutation socialDisconnect($provider: String!) {
    unlinkWithSocial(input: {provider: $provider})
  }
`;
