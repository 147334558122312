import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';
import {BasePortal} from '../../../ui-kit/Popup';

export const SettingsMobBtnWrapper = styled.div`
  visibility: hidden;
  ${Media.down.l} {
    visibility: visible;
    max-height: 24px;
    flex-grow: 0;
    padding: 0 13px;
    margin-right: 0px;
  }
`;

export const SettingsPortal = styled(BasePortal)<{$isOpened?: boolean}>`
  width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  display: ${({$isOpened}) => ($isOpened ? 'block' : 'none')};
`;
