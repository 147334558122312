import {MISSING_TRANSLATION_KEY} from '../constants/common';
import {format as dateFnsFormat, formatDistance} from 'date-fns';
import {ResourceKey} from 'i18next';
import * as ru from 'date-fns/locale/ru';

export enum localeType {
  en = 'en',
  ru = 'ru',
  // fr = 'fr',
}

export const dateFnsLocales = {
  [localeType.en]: undefined,
  [localeType.ru]: ru.default,
  // [localeType.fr]: fr,
};

export enum NamespaceType {
  auth = 'auth',
  profile = 'profile',
  community = 'community',
  resident = 'resident',
  listings = 'listings',
  messages = 'messages',
  requests = 'requests',
  common = 'common',
  layout = 'layout',
  buttons = 'buttons',
  settings = 'settings',
  forms = 'forms',
  utils = 'utils',
  error = 'error',
  success = 'success',
  dashboard = 'dashboard',
  people = 'people',
  search = 'search',
  notifications = 'notifications',
  documents = 'documents',
  events = 'events',
  amenities = 'amenities',
  helpDesk = 'helpDesk',
  groups = 'groups',
  company = 'company',
}

export const fallbackLng = localeType.en;
export const defaultNS = NamespaceType.common;
export const ns = Object.values(NamespaceType);
export const locales = Object.values(localeType);

export interface TranslationType {
  [NamespaceType.auth]: ResourceKey;
  [NamespaceType.profile]: ResourceKey;
  [NamespaceType.community]: ResourceKey;
  [NamespaceType.resident]: ResourceKey;
  [NamespaceType.common]: ResourceKey;
  [NamespaceType.layout]: ResourceKey;
  [NamespaceType.buttons]: ResourceKey;
  [NamespaceType.settings]: ResourceKey;
  [NamespaceType.listings]: ResourceKey;
  [NamespaceType.messages]: ResourceKey;
  [NamespaceType.requests]: ResourceKey;
  [NamespaceType.forms]: ResourceKey;
  [NamespaceType.utils]: ResourceKey;
  [NamespaceType.error]: ResourceKey;
  [NamespaceType.success]: ResourceKey;
  [NamespaceType.dashboard]: ResourceKey;
  [NamespaceType.people]: ResourceKey;
  [NamespaceType.search]: ResourceKey;
  [NamespaceType.notifications]: ResourceKey;
  [NamespaceType.documents]: ResourceKey;
  [NamespaceType.events]: ResourceKey;
  [NamespaceType.amenities]: ResourceKey;
  [NamespaceType.helpDesk]: ResourceKey;
  [NamespaceType.groups]: ResourceKey;
  [NamespaceType.company]: ResourceKey;
}

export type translationFileLoader = () => TranslationType;

export interface LocaleType {
  name: string;
  translationFileLoader: translationFileLoader;
}

export interface SupportedLocalesType {
  [localeType.en]: LocaleType;
  [localeType.ru]: LocaleType;
  // [localeType.fr]: LocaleType;
}

export const supportedLocales: SupportedLocalesType = {
  [localeType.en]: {
    name: 'English',
    translationFileLoader: (): TranslationType => ({
      [NamespaceType.auth]: require('../translations/en/auth.json'),
      [NamespaceType.profile]: require('../translations/en/profile.json'),
      [NamespaceType.community]: require('../translations/en/community.json'),
      [NamespaceType.resident]: require('../translations/en/resident.json'),
      [NamespaceType.common]: require('../translations/en/common.json'),
      [NamespaceType.layout]: require('../translations/en/layout.json'),
      [NamespaceType.buttons]: require('../translations/en/buttons.json'),
      [NamespaceType.settings]: require('../translations/en/settings.json'),
      [NamespaceType.listings]: require('../translations/en/listings.json'),
      [NamespaceType.messages]: require('../translations/en/messages.json'),
      [NamespaceType.requests]: require('../translations/en/requests.json'),
      [NamespaceType.forms]: require('../translations/en/forms.json'),
      [NamespaceType.utils]: require('../translations/en/utils.json'),
      [NamespaceType.error]: require('../translations/en/error.json'),
      [NamespaceType.success]: require('../translations/en/success.json'),
      [NamespaceType.dashboard]: require('../translations/en/dashboard.json'),
      [NamespaceType.people]: require('../translations/en/people.json'),
      [NamespaceType.search]: require('../translations/en/search.json'),
      [NamespaceType.notifications]: require('../translations/en/notifications.json'),
      [NamespaceType.documents]: require('../translations/en/documents.json'),
      [NamespaceType.events]: require('../translations/en/events.json'),
      [NamespaceType.amenities]: require('../translations/en/amenities.json'),
      [NamespaceType.helpDesk]: require('../translations/en/helpDesk.json'),
      [NamespaceType.groups]: require('../translations/en/groups.json'),
      [NamespaceType.company]: require('../translations/en/company.json'),
    }),
  },
  [localeType.ru]: {
    name: 'Russian',
    translationFileLoader: (): TranslationType => ({
      [NamespaceType.auth]: require('../translations/ru/auth.json'),
      [NamespaceType.profile]: require('../translations/ru/profile.json'),
      [NamespaceType.community]: require('../translations/ru/community.json'),
      [NamespaceType.resident]: require('../translations/ru/resident.json'),
      [NamespaceType.common]: require('../translations/ru/common.json'),
      [NamespaceType.layout]: require('../translations/ru/layout.json'),
      [NamespaceType.buttons]: require('../translations/ru/buttons.json'),
      [NamespaceType.settings]: require('../translations/ru/settings.json'),
      [NamespaceType.listings]: require('../translations/ru/listings.json'),
      [NamespaceType.messages]: require('../translations/ru/messages.json'),
      [NamespaceType.requests]: require('../translations/ru/requests.json'),
      [NamespaceType.forms]: require('../translations/ru/forms.json'),
      [NamespaceType.utils]: require('../translations/ru/utils.json'),
      [NamespaceType.error]: require('../translations/ru/error.json'),
      [NamespaceType.success]: require('../translations/ru/success.json'),
      [NamespaceType.dashboard]: require('../translations/ru/dashboard.json'),
      [NamespaceType.people]: require('../translations/ru/people.json'),
      [NamespaceType.search]: require('../translations/ru/search.json'),
      [NamespaceType.notifications]: require('../translations/ru/notifications.json'),
      [NamespaceType.documents]: require('../translations/ru/documents.json'),
      [NamespaceType.events]: require('../translations/ru/documents.json'),
      [NamespaceType.amenities]: require('../translations/ru/documents.json'),
      [NamespaceType.helpDesk]: require('../translations/ru/helpDesk.json'),
      [NamespaceType.groups]: require('../translations/ru/groups.json'),
      [NamespaceType.company]: require('../translations/ru/company.json'),
    }),
  },
  // [localeType.fr]: {
  //   name: 'Français',
  //   translationFileLoader: (): TranslationType => ({
  // [NamespaceType.auth]: require('../translations/fr/auth.json'),
  // [NamespaceType.common]: require('../translations/fr/common.json'),
  // [NamespaceType.layout]: require('../translations/fr/layout.json'),
  // [NamespaceType.buttons]: require('../translations/fr/buttons.json'),
  // [NamespaceType.settings]: require('./translations/fr/settings.json'),
  // [NamespaceType.forms]: require('../translations/fr/forms.json'),
  // [NamespaceType.error]: require('../translations/fr/error.json'),
  // }),
  // },
};

export const optionsI18n = {
  fallbackLng,
  whitelist: locales,
  ns,
  defaultNS,
  parseMissingKeyHandler: (key: string) => {
    return `${MISSING_TRANSLATION_KEY} "${key}"`;
  },
  // debug: true,
  interpolation: {
    format: (value: any, format?: string, lang?: string): string => {
      if (format === 'uppercase') {
        return value.toUpperCase();
      }
      if (format === 'lowercase') {
        return value.toLowerCase();
      }
      if (format === 'capitalize') {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
      if (format === 'distance') {
        return formatDistance(value, new Date(), {
          locale: dateFnsLocales[lang as localeType],
        });
      }
      if (value instanceof Date) {
        return dateFnsFormat(value, format || 'Pp', {
          locale: dateFnsLocales[lang as localeType],
        });
      }
      return value;
    },
  },
};
