import React, {useState} from 'react';
import {PersonCardSmallT} from '../types';
import {CardWrapper, InfoWrapperSmall, LastColumn, PeopleCard, PersonLabels, PersonName} from '../styles';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Menu} from '../../../ui-kit/Menu';
import {MenuButton} from '../../../ui-kit/Button/MenuButton';
import {MenuItemType} from '../../../ui-kit/Menu/types';
import {useTranslation} from 'react-i18next';
import {Listings} from '../../../ui-kit/ProfileIntro/labels';
import {route} from '../../../constants/routes';
import {useHasPlanOption} from '../../../helpers/community';
import {PlanOptionsValues} from '../../../constants/plan';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {Media} from '../../../ui-kit/theme';
import {MobileMenuButton} from '../../../ui-kit/Button/MobileMenuButton';
import {PersonType} from './PersonType';
import {useLinks} from '../../../hooks/common';
import {useRecoilState} from 'recoil';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {MobileMenuEntry} from '../../../ui-kit/Menu/MobileMenu';
import {Confirmation} from '../../../ui-kit/Modals/Confirmation';
import {entities} from '../../../hooks/report';
import {getResize} from '../../../helpers/file';
import {getUserName} from '../../../helpers/user';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../../ui-kit/Popup';
import {EmptyTag} from '../../../containers/MobileTabs';
import {userPermissions} from '../../../states/community';
import {PermissionsList} from '../../../types/settings';
import {IsModalProvider} from '../../../containers/Navigation/IsModalProvider';

export const PersonCardSmall: React.FC<PersonCardSmallT> = ({
  firstName,
  lastName,
  isOnline,
  Avatar: avatar,
  isVerified,
  aptSuite,
  objectId,
  manageActions,
  communityId,
  isAdmin,
  isManager,
  viewerId,
  listingCount,
  reportActions,
  refetch,
  adminLabels,
}) => {
  const item = {objectId: objectId};
  const {t} = useTranslation();
  const canManageAdmins = useHasPlanOption(PlanOptionsValues.multipleAdmins);
  const {getLink} = useLinks();
  const btnView = t('people:manage.buttons.view');
  const btnMakeAdmin = t('people:manage.buttons.makeAdmin');
  const btnRemoveAdmin = t('people:manage.buttons.removeAdmin');
  const btnRemove = t('people:manage.buttons.remove');
  const unit = t('people:person.labels.unit');
  const profileLink = viewerId !== objectId ? getLink(route.profile.get({id: objectId})) : getLink(route.pageUser.path);
  const [userType] = useRecoilState(typeUser);
  const [permissions] = useRecoilState(userPermissions);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const isOwner = viewerId === objectId;
  const canManage = permissions.includes(PermissionsList.managePeople);
  const currentCanToAdmin = userType === TypeCommunity.manager || userType === TypeCommunity.admin;

  const report = () => {
    setIsShowReportModal(true);
  };

  const block = () => {
    setIsShowBlockModal(true);
  };

  const menuOptionsResidents: MenuItemType[] = [
    ...(viewerId !== objectId
      ? ([
          {
            title: t('people:actions.report'),
            onClick: report,
            render: 'danger',
          },
        ] as MenuItemType[])
      : []),
  ];

  const menuOptionsMobileResidents: MobileMenuEntry[] = [
    ...(viewerId !== objectId
      ? ([
          {
            title: t('people:actions.report'),
            onClick: report,
            type: 'danger',
          },
        ] as MobileMenuEntry[])
      : []),
  ];

  const getRemoveButton = () => {
    const removeBtn: MenuItemType = {
      title: btnRemove,
      onClick: () => {
        manageActions?.personRemove?.(objectId);
      },
      render: 'danger',
    };
    if (userType === TypeCommunity.manager || canManage) return [removeBtn];

    return [];
  };

  const menuOptionsManage: MenuItemType[] = [
    {
      title: btnView,
      to: () => profileLink,
    },
    ...(userType === TypeCommunity.manager || canManage
      ? [
          {
            title: isAdmin ? btnRemoveAdmin : btnMakeAdmin,
            onClick: () => {
              if (isAdmin) {
                communityId && manageActions?.personRemoveAdmin?.(objectId, communityId);
              } else {
                communityId && manageActions?.personMakeAdmin?.(objectId, communityId);
              }
            },
          },
        ]
      : []),
    ...getRemoveButton(),
    ...(viewerId !== objectId
      ? ([
          {
            title: t('people:actions.report'),
            onClick: report,
            render: 'danger',
          },
        ] as MenuItemType[])
      : []),
    {
      title: t('people:actions.block'),
      onClick: block,
      render: 'danger',
    },
  ];

  const menuOptionsRegular: MenuItemType[] = [
    {
      title: btnView,
      to: () => profileLink,
    },
    ...getRemoveButton(),
    ...(viewerId !== objectId
      ? ([
          {
            title: t('people:actions.report'),
            onClick: report,
            render: 'danger',
          },
        ] as MenuItemType[])
      : []),
    ...(viewerId !== objectId
      ? ([
          {
            title: t('people:actions.block'),
            onClick: block,
            render: 'danger',
          },
        ] as MenuItemType[])
      : []),
  ];

  const menuOptions = isOwner
    ? []
    : isManager
    ? []
    : currentCanToAdmin
    ? canManageAdmins
      ? menuOptionsManage
      : menuOptionsRegular
    : menuOptionsResidents;

  const mobileMenuOptions = isManager
    ? []
    : currentCanToAdmin
    ? menuOptions.map((o) => ({
        title: o.title ?? '',
        onClick: () => o.onClick?.(objectId),
        to: o.to?.(''),
        type: o.render as 'regular' | 'danger',
      }))
    : menuOptionsMobileResidents;

  const reportParams = {
    title: t('common:report.title', {entity: t(`common:entities.${entities.user}`)}),
    subtitle: t('common:report.subtitle'),
    okButton: t('common:report.confirmReport'),
    textAreaLabel: t('common:report.textAreaLabel'),
    close: () => {
      setIsShowReportModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowReportModal(false);
        setIsShowInfoModal(true);
      };

      return reportActions?.report?.({id: objectId, onSuccess, text});
    },
  };

  const blockParams = {
    title: t('people:block.title'),
    subtitle: t('people:block.subtitle'),
    okButton: t('people:block.block'),
    textAreaLabel: t('people:block.textAreaLabel'),
    close: () => {
      setIsShowBlockModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowBlockModal(false);
        refetch?.();
      };

      return reportActions?.blockUser?.({id: objectId, onSuccess, text});
    },
  };

  const infoParams = {
    title: t('common:report.success.title', {entity: t(`common:entities.${entities.user}`)}),
    subtitle: t('common:report.success.subtitle'),
    close: () => {
      setIsShowInfoModal(false);
    },
  };
  const PortalWrapper = isMobile ? BasePortal : EmptyTag;

  return (
    <>
      <PeopleCard to={profileLink}>
        <CardWrapper>
          <Avatar src={getResize(avatar?.file?.url, 'md')} size={'sm'} $isOnline={isOnline} />
          <InfoWrapperSmall>
            <PersonName>
              <span>{getUserName(userType, firstName, lastName)}</span>
              {isVerified && <IconSvg type={'verified'} stroke={'green'} />}
            </PersonName>
            <PersonLabels>
              <PersonType
                isAdmin={isAdmin}
                isManager={isManager}
                aptSuite={aptSuite}
                unit={unit}
                adminLabels={adminLabels}
                userId={objectId}
              />
              <Listings count={listingCount || 0} label={t('search:labels.listings')} />
            </PersonLabels>
          </InfoWrapperSmall>
          <LastColumn className={'menu-btn__wrapper'}>
            {!!menuOptions.length && (
              <HiddenElement mq={Media.down.s}>
                <MenuButton item={item} menu={Menu} options={menuOptions} prevent={true} />
              </HiddenElement>
            )}
            {!!mobileMenuOptions.length && (
              <HiddenElement mq={Media.up.m}>
                <MobileMenuButton isPortal={true} entries={mobileMenuOptions} portalProvider={IsModalProvider} />
              </HiddenElement>
            )}
          </LastColumn>
        </CardWrapper>
      </PeopleCard>
      {isShowReportModal && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              isHaveTextArea
              title={reportParams.title}
              subTitle={reportParams.subtitle}
              buttonType={'danger'}
              textAreaLabel={reportParams.textAreaLabel}
              okText={reportParams.okButton}
              onClose={reportParams.close}
              onOk={reportParams.onOk}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
      {isShowBlockModal && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              isHaveTextArea
              title={blockParams.title}
              subTitle={blockParams.subtitle}
              textAreaLabel={blockParams.textAreaLabel}
              buttonType={'danger'}
              okText={blockParams.okButton}
              onClose={blockParams.close}
              onOk={blockParams.onOk}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
      {isShowInfoModal && (
        <PortalWrapper>
          <IsModalProvider>
            <Confirmation
              title={infoParams.title}
              subTitle={infoParams.subtitle}
              buttonType={'primary'}
              onClose={infoParams.close}
            />
          </IsModalProvider>
        </PortalWrapper>
      )}
    </>
  );
};
