import React, {useEffect} from 'react';
import {GroupMembersColumn} from '../../components/Groups/GroupMembersColumn';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {useGetGroupMembers} from '../../hooks/groups';

type GroupMembersT = {
  objectId?: string;
  isJoined?: boolean;
  onJoin?: () => void;
};
export const GroupMembers: React.FC<GroupMembersT> = ({objectId, isJoined, onJoin}) => {
  const [comm] = useRecoilState(currentCommunity);
  const {data: members, loading, refetch} = useGetGroupMembers({groupId: objectId, first: 8});
  useEffect(() => {
    isJoined && refetch?.();
  }, [isJoined]);

  return (
    <GroupMembersColumn members={members} community={comm} loading={loading} onJoin={onJoin} isJoined={isJoined} />
  );
};
