import React, {useCallback, useEffect, useState} from 'react';
import i18next, {BackendModule} from 'i18next';
import {initReactI18next} from 'react-i18next';
import {fallbackLng, localeType, NamespaceType, optionsI18n, supportedLocales} from './i18nextUtils';

const translationLoader: BackendModule = {
  type: 'backend',
  init: (): boolean => {
    return true;
  },
  create: (): boolean => {
    return true;
  },
  read: function (language: localeType, namespace: NamespaceType, callback) {
    let resource = null;
    let error = null;

    try {
      resource = supportedLocales[language].translationFileLoader()[namespace];
    } catch (_error) {
      error = _error;
    }

    if (!resource) {
      return callback(Error('Not found resource'), '');
    }

    callback(error, resource);
  },
};

export const setLocale = async (language: localeType): Promise<boolean> => {
  if (language === i18next.language) {
    return false;
  }

  await i18next.changeLanguage(language);
  await window.localStorage.setItem('language', language);
  return true;
};

const Provider: React.FC = ({children}) => {
  const [isInit, setInit] = useState<boolean>(false);

  const _init = async (): Promise<boolean> => {
    const lng = (await window.localStorage.getItem('language')) || fallbackLng;

    await i18next
      .use(translationLoader)
      .use(initReactI18next)
      .init({...optionsI18n, lng});

    setInit(true);
    return true;
  };

  const init = useCallback(_init, []);

  useEffect(() => {
    init();
  }, [init]);

  if (!isInit) {
    return null;
  }

  return <>{children}</>;
};

export default Provider;
