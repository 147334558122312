import React from 'react';
import {RequestCardReviewBase} from './Base';
import {CardTags} from '../CardTags';
import {PaymentLabel} from '../PaymentLabel';
import {TopCardMessageWrapper, TextContainer, TopCardMessageText, RedText, CardInfoText} from '../styles';
import {PaymentInfo} from '../PaymentInfo';
import {CardCancelledProps} from '../types';
import {CreditBlock, DatesTextInfo} from '../Card';

export const Cancelled: React.FC<CardCancelledProps> = ({
  title,
  cancelled,
  descr,
  tags,
  dateLabel,
  buttonLabel,
  paymentLabel,
  rewardType,
  payments,
  pricePeriod,
  dateInfoLabels,
  showCredit,
  toggleCredit,
  creditInfo,
}) => {
  const cancelledParts = cancelled.title.split(' ');
  const cancelledWord = cancelledParts[cancelledParts.length - 1];
  const topMsg = (
    <TopCardMessageWrapper>
      <TextContainer>
        <TopCardMessageText>
          {cancelledParts.slice(0, cancelledParts.length - 1).join(' ')} <RedText>{cancelledWord}</RedText>.
        </TopCardMessageText>
      </TextContainer>
    </TopCardMessageWrapper>
  );

  return (
    <RequestCardReviewBase title={title} buttonLabel={buttonLabel} cardTopMessage={topMsg}>
      <CardTags tags={tags} />
      {!showCredit && <DatesTextInfo dateLabel={dateLabel} pricePeriod={pricePeriod} dateInfoLabels={dateInfoLabels} />}
      {!showCredit ? (
        <PaymentLabel
          rewardType={rewardType}
          descr={paymentLabel.descr}
          title={paymentLabel.title}
          onClick={() => toggleCredit?.(true)}
        />
      ) : (
        <CreditBlock close={() => toggleCredit?.(false)} creditInfo={creditInfo} />
      )}
      <PaymentInfo payments={payments} />
      <CardInfoText>{descr}</CardInfoText>
    </RequestCardReviewBase>
  );
};
