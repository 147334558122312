import {ListingType, RewardType} from '../queries/types/item';
import {searchPeopleOptionValue} from '../ui-kit/Search/types';
import {MenuItemType} from '../ui-kit/Menu/types';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {LabelValuePair} from '../types/settings';
import {ItemOptions} from '../constants/items';
import {datePickerDataT, PricePeriodType} from '../ui-kit/RequestCard/types';
import {TDateValues} from '../hooks/order';

export const getListingsTypes = (t: (key: string) => string) => [
  {label: t('search:listings.rent.label'), hint: t('search:listings.rent.hint'), value: ListingType.rent},
  {label: t('search:listings.free.label'), hint: t('search:listings.free.hint'), value: RewardType.free},
  {label: t('search:listings.coffee.label'), hint: t('search:listings.coffee.hint'), value: RewardType.coffee},
  {label: t('search:listings.service.label'), hint: t('search:listings.service.hint'), value: ListingType.service},
  {label: t('search:listings.sell.label'), hint: t('search:listings.sell.hint'), value: ListingType.sell},
];

export const getTypes = (listingType: searchPeopleOptionValue[]) => {
  if (listingType.length === 0) return [];
  const searchByListingType = listingType.map((el) => ({listingType: {equalTo: el as string}}));
  const searchByRewordType = listingType.map((el) => ({rewardType: {equalTo: el as string}}));
  return [{OR: [...searchByListingType, ...searchByRewordType]}];
};

export const isAdditionalListingOption = (value?: string) =>
  value === ItemOptions.myListings || value === ItemOptions.allListings || value === ItemOptions.liked;

export const getShowListingsParams = (value?: string, viewerId?: string) => {
  if (value === ItemOptions.myListings && viewerId) {
    return {
      Lister: {
        have: {
          id: {
            equalTo: viewerId,
          },
        },
      },
    };
  }
  if (value === ItemOptions.liked && viewerId) {
    return {
      likes: {have: {id: {equalTo: viewerId}}},
    };
  }

  return {};
};

export const getListingTypeParams = (listingType?: Partial<LabelValuePair>) =>
  listingType?.value
    ? {
        OR: [
          {
            listingType: {
              equalTo: listingType?.value?.toLocaleLowerCase() ?? '',
            },
          },
          listingType?.value?.toLocaleLowerCase() === RewardType.free
            ? {
                rewardType: {
                  in: [RewardType.free, RewardType.coffee],
                },
              }
            : {
                rewardType: {
                  equalTo: listingType?.value?.toLocaleLowerCase() ?? '',
                },
              },
        ],
      }
    : {};

export const getListingOptions = (t: (key: string, option?: {lng: string}) => string) => [
  {
    label: t('listings:options.allListings'),
    value: ItemOptions.allListings,
  },
  {
    label: t('listings:options.service'),
    value: ItemOptions.service,
  },
  {
    label: t('listings:options.rent'),
    value: ItemOptions.rent,
  },
  {
    label: t('listings:options.sell'),
    value: ItemOptions.sell,
  },
  {
    label: t('listings:options.free'),
    value: ItemOptions.free,
  },
  // {
  //   label: t('listings:options.coffee'),
  //   value: ItemOptions.coffee,
  // },
  {
    label: t('listings:options.liked'),
    value: ItemOptions.liked,
  },
];

export interface GetMenuItemsOptions {
  isPublished?: boolean;
  hasApprovalRequest?: boolean;
  isAdmHidden?: boolean;
  isFeatured?: boolean;
  isOwner?: boolean;
  canFeature?: boolean;
  objectId?: string;
  to?: string;
}

export interface GetMenuItemsFuncOptions {
  hideCallBack?: (itemId?: string) => () => void;
  featureCallBack?: (itemId?: string) => () => void;
  removeFeatureCallBack?: (itemId?: string) => () => void;
  approveCallBack?: (itemId?: string) => () => void;
  unListCallBack?: (itemId?: string) => () => void;
  onPublishCallBack?: (itemId?: string) => () => void;
  t: (key: string) => string;
}

export const getMenuItems = (options: GetMenuItemsOptions, functions: GetMenuItemsFuncOptions): MenuItemType[] => {
  const {canFeature, isFeatured, isPublished, hasApprovalRequest, isOwner, isAdmHidden, objectId, to} = options;
  const {t, hideCallBack, featureCallBack, removeFeatureCallBack, approveCallBack, unListCallBack, onPublishCallBack} =
    functions;
  const addedOptions: MenuItemType[] = [];

  if (isOwner) {
    addedOptions.push({
      title: t('listings:card.buttons.edit'),
      to: () => to || '/',
    });
    addedOptions.push({
      render: 'line',
    });
  }

  if (isAdmHidden) {
    addedOptions.length === 2 && addedOptions.pop();

    return addedOptions;
  }

  if (!hasApprovalRequest && isOwner && !isPublished && onPublishCallBack) {
    addedOptions.push({
      title: t('listings:card.buttons.publish'),
      onClick: onPublishCallBack(objectId),
    });
  }

  if (!isFeatured && canFeature && isPublished && featureCallBack) {
    addedOptions.push({
      title: t('listings:card.buttons.feature'),
      onClick: featureCallBack(objectId),
    });
  }

  if (hasApprovalRequest && !isAdmHidden && approveCallBack) {
    addedOptions.push({
      title: t('listings:card.buttons.approve'),
      onClick: approveCallBack(objectId),
    });
  }

  if (isFeatured && canFeature && isPublished && removeFeatureCallBack) {
    addedOptions.push({
      render: 'danger',
      title: t('listings:card.buttons.removeFeature'),
      onClick: removeFeatureCallBack(objectId),
    });
  }

  if (isOwner && unListCallBack) {
    addedOptions.push({
      render: 'danger',
      title: t('listings:card.buttons.unlist'),
      onClick: unListCallBack(objectId),
    });
  }

  if (!isAdmHidden && hideCallBack && !addedOptions.some((opt) => opt.title === t('listings:card.buttons.unlist'))) {
    addedOptions.push({
      render: 'danger',
      title: t('listings:card.buttons.hide'),
      onClick: hideCallBack(objectId),
    });
  }

  if (addedOptions[addedOptions.length - 1]?.render === 'line') addedOptions.pop();

  return addedOptions;
};

export const getMobileMenuItems = (
  options: GetMenuItemsOptions,
  functions: GetMenuItemsFuncOptions,
): MobileMenuEntry[] => {
  return getMenuItems(options, functions)
    .filter((i) => i.render !== 'line')
    .map((i) => {
      return {
        title: i.title ?? '',
        onClick: () => i.onClick?.(''),
        type: i.render as 'regular' | 'danger',
        to: i.to?.(''),
      };
    });
};

export const checkIsNotSelected = (
  datePickerData?: datePickerDataT,
  listingType?: ListingType,
  pricePeriod?: PricePeriodType | null,
  dates?: TDateValues[],
) => {
  if (listingType === ListingType.sell && pricePeriod === PricePeriodType.fixed)
    return !datePickerData?.startdate?.value || !datePickerData?.time?.value;
  if (listingType === ListingType.sell) return !datePickerData?.startdate?.value;
  if (pricePeriod === PricePeriodType.hour)
    return !datePickerData?.startdate?.value || !datePickerData?.time?.value || !datePickerData?.duration?.value;
  return (dates?.filter((i) => !!i)?.length || 0) < 2;
};
