import React, {useEffect} from 'react';
import {
  Button,
  HeaderMainCard,
  HiddenButton,
  InnerBodyCard,
  InnerChat,
  SubInnerHeaderCard,
  TitleHeaderCard,
  ContactInfoLine,
} from './styles';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {IDataHookMessage, MobTabMsgs, TContact} from '../../types/messages';
import {Media} from '../../ui-kit/theme';
import {ChatArea} from './ChatArea';
import {Message} from '../../queries/types/message';
import {useDetectKeyboardOpen} from '../../hooks/common';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {scrollLock} from '../../helpers/common';
import {isMobile} from 'react-device-detect';

type TProps = {
  firstName?: string;
  lastName?: string;
  msgs?: Message[] | null;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  dataHookMessage: IDataHookMessage;
  activeContact?: TContact | null;
  setActiveMobTab?: (tab: MobTabMsgs | null) => void;
  setIsActiveRequests?: (isActive: boolean) => void;
  setActiveOrder: (id?: string) => void;
  requestsCount?: number;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  commId?: string;
  setInputFocus?: (val: boolean) => void;
  inputFocus?: boolean;
  activeOrder?: boolean;
  backToMsgs?: () => void;
  redirectToProfile?: () => void;
};

export const MessagesChat: React.FC<TProps> = ({
  activeContact,
  setActiveMobTab,
  setIsActiveRequests,
  dataHookMessage,
  msgs,
  viewerId,
  setActiveOrder,
  viewerData,
  requestsCount = 0,
  isSearching,
  setIsSearching,
  commId,
  setInputFocus,
  inputFocus,
  activeOrder,
  redirectToProfile,
}) => {
  const handleActiveTab = (tab: MobTabMsgs | null) => () => {
    if (setIsActiveRequests) {
      setIsActiveRequests(true);
    }

    if (!setActiveMobTab) return;
    setActiveMobTab(tab);
  };
  const backToMsgs = () => {
    handleActiveTab(MobTabMsgs.messages)();
    backToMsgs();
  };
  const keyboardOpened = useDetectKeyboardOpen(300);
  useEffect(() => {
    if (isMobile) {
      if (inputFocus && keyboardOpened) {
        window?.scrollTo(0, 0);
        scrollLock();
      } else {
        scrollLock(false);
      }
    }

    return () => {
      if (isMobile) scrollLock(false);
    };
  }, [inputFocus, keyboardOpened]);
  return (
    <InnerChat>
      <HeaderMainCard $isFocused={inputFocus}>
        <SubInnerHeaderCard>
          <HiddenButton mq={Media.up.m}>
            <Button type={'button'} onClick={backToMsgs}>
              <IconSvg type={'arrow-left'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
            </Button>
          </HiddenButton>
          <ContactInfoLine onClick={redirectToProfile}>
            {activeContact?.name && (
              <TitleHeaderCard>
                <Avatar src={activeContact?.avatar} size={'sm'} className={'preview-message_avatar'} />
                <span>{activeContact?.name}</span>
              </TitleHeaderCard>
            )}
          </ContactInfoLine>
        </SubInnerHeaderCard>
        {requestsCount > 0 && (
          <Button type={'button'} onClick={!!requestsCount ? handleActiveTab(MobTabMsgs.requests) : undefined}>
            <IconSvg type={'circle-double'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
          </Button>
        )}
      </HeaderMainCard>
      <InnerBodyCard $isFocused={inputFocus && keyboardOpened}>
        <ChatArea
          setActiveOrder={setActiveOrder}
          setActiveMobTab={setActiveMobTab}
          messages={msgs}
          dataHookMessage={dataHookMessage}
          contact={activeContact}
          viewerId={viewerId}
          viewerData={viewerData}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          commId={commId}
          setInputFocus={setInputFocus}
          activeOrder={activeOrder}
          keyboardOpened={inputFocus && keyboardOpened}
        />
      </InnerBodyCard>
    </InnerChat>
  );
};
