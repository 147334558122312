import React, {useState, useEffect} from 'react';
import {ContactList} from './ContactList';
import {NavContainer} from '../../containers/Navigation';
import {ContentWrapper, InnerMsgs, MobInnerMsgs, Tab} from './styles';
import {MessagesRequests} from './Requests';
import {MessagesChat} from './Chat';
import {Media} from '../../ui-kit/theme';
import {IDataHookMessage, MobTabMsgs, OrderHelpersT, TContact} from '../../types/messages';
import {Message} from '../../queries/types/message';
import {TResActiveOrder} from '../../hooks/message';
import {Order} from '../../queries/types/order';
import {editReviewDataT, TDataInvoices, TResCreateReview, TResEditOrder, TResManageOrder} from '../../hooks/order';
import {TReview} from '../../types/reviews';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {EmptyTag} from '../../containers/MobileTabs';
import {SearchingContact} from './SearchingContact';
import {MemberT} from '../../types/people';
import {SkeletonLoader} from './Sceleton/Loader';
import {SkeletonContactCard} from './Sceleton/Card/ContactCard';
import {useHistory} from 'react-router-dom';
import {useLinks} from '../../hooks/common';
import {useUrlSearchManager} from '../../hooks/urlSearchManager';
import {paths, route, SearchKey} from '../../constants/routes';
import {isMobile} from 'react-device-detect';
import {BlockHint} from '../common/InfoHint';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {messageHints} from '../../states/preload';
import {markInboxHints} from '../../helpers/user';

type TProps = {
  msgs?: Message[] | null;
  contactsList?: TContact[] | null;
  activeContact?: TContact | null;
  setActiveContact: (user: TContact | null) => void;
  dataMessage: IDataHookMessage;
  contactsLoading: boolean;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  countUnReadMsg?: number;
  dataActiveOrder: TResActiveOrder;
  dataOrders: {
    fetchData: () => void;
    hasMore: boolean;
    loading: boolean;
    invoiceLoading?: boolean;
    orders?: Order[] | null;
    total: number;
  };
  dataInvoice: TDataInvoices | null;
  dataManageOrder: TResManageOrder;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  activeMobTab: MobTabMsgs | null;
  setActiveMobTab: React.Dispatch<React.SetStateAction<MobTabMsgs | null>>;
  showStripePopup: () => void;
  setCardErrorPopup: (v: string | null) => void;
  hideNav?: boolean;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  searchText: string;
  setPeopleSearchText: (value: string) => void;
  members: MemberT[];
  forwardToUser: (id: string) => void;
  hasMore: boolean;
  fetchMore: () => void;
  clearSearchText: () => void;
  loading: boolean;
  commId?: string;
};

export const Messages: React.FC<TProps> = ({
  contactsList,
  contactsLoading,
  activeContact,
  setActiveContact,
  msgs,
  dataMessage,
  countUnReadMsg,
  viewerId,
  viewerData,
  dataActiveOrder,
  dataOrders,
  dataInvoice,
  dataManageOrder,
  reviewOrderData,
  editOrderData,
  reviewsData,
  editReviewData,
  reviewsDataLoading,
  orderHelpers,
  activeMobTab,
  setActiveMobTab,
  showStripePopup,
  setCardErrorPopup,
  hideNav,
  isSearching,
  setIsSearching,
  searchText,
  setPeopleSearchText,
  members,
  forwardToUser,
  hasMore,
  fetchMore,
  clearSearchText,
  loading,
  commId,
}) => {
  const {t} = useTranslation();
  const [isActiveRequests, setIsActiveRequests] = useState(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [hints, setHints] = useRecoilState(messageHints);
  const hideChatHint = () => {
    setHints((prev) => ({...prev, chat: false}));
    markInboxHints('chat');
  };
  const handleSetSearch = () => setIsSearching(true);
  const params = useUrlSearchManager();
  const orderIdUrl = params?.get(SearchKey.orderId);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const resetFromUrl = () => {
    if (orderIdUrl) {
      activeContact?.objectId
        ? push(getLink(route.messages.get({contactId: activeContact?.objectId})))
        : push(getLink(route.messages.path));
    }
  };
  useEffect(() => {
    setIsActiveRequests(!!dataOrders.total);
  }, [dataOrders.total]);
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  const onInputFocus = (value: boolean) => {
    setInputFocus(value);
  };

  const backToMsgs = () => {
    push(getLink(`/${paths.messages}`));
  };

  const redirectToProfile = () => {
    if (!activeContact?.objectId) return;
    push(getLink(route.profile.get({id: activeContact?.objectId})));
  };

  return (
    <>
      <Wrapper
        viewMode={
          activeMobTab === MobTabMsgs.chat || activeMobTab === MobTabMsgs.requests || isSearching
            ? 'hideNavOnMobile'
            : undefined
        }
        verifyEmailMsgAppendTop={activeMobTab === MobTabMsgs.chat}>
        <ContentWrapper>
          {!isMobile ? (
            <HiddenElement mq={Media.down.m}>
              <InnerMsgs>
                {isSearching ? (
                  <SearchingContact
                    value={searchText}
                    onChange={setPeopleSearchText}
                    setIsSearching={setIsSearching}
                    members={members}
                    forwardToUser={forwardToUser}
                    hasMore={hasMore}
                    fetchMore={fetchMore}
                    clearSearchText={clearSearchText}
                  />
                ) : (
                  <ContactList
                    contactsList={contactsList}
                    contactsLoading={contactsLoading}
                    setActiveContact={setActiveContact}
                    count={countUnReadMsg}
                    activeContact={activeContact}
                    isSearching={isSearching}
                    setIsSearching={setIsSearching}
                  />
                )}
                <MessagesChat
                  msgs={msgs}
                  dataHookMessage={dataMessage}
                  activeContact={activeContact}
                  requestsCount={dataOrders.total}
                  setIsActiveRequests={setIsActiveRequests}
                  viewerId={viewerId}
                  viewerData={viewerData}
                  setActiveOrder={dataActiveOrder.setActiveOrder}
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                  commId={commId}
                  backToMsgs={backToMsgs}
                  redirectToProfile={redirectToProfile}
                />
                {isActiveRequests && (
                  <MessagesRequests
                    viewerId={viewerId}
                    dataActiveOrder={dataActiveOrder}
                    dataOrders={dataOrders}
                    activeContact={activeContact}
                    setIsActiveRequests={setIsActiveRequests}
                    dataInvoice={dataInvoice}
                    dataManageOrder={dataManageOrder}
                    count={dataOrders.total}
                    reviewOrderData={reviewOrderData}
                    editOrderData={editOrderData}
                    reviewsData={reviewsData}
                    editReviewData={editReviewData}
                    reviewsDataLoading={reviewsDataLoading}
                    orderHelpers={orderHelpers}
                    showStripePopup={showStripePopup}
                    setCardErrorPopup={setCardErrorPopup}
                    setActiveContact={setActiveContact}
                    redirectToProfile={redirectToProfile}
                  />
                )}
              </InnerMsgs>
            </HiddenElement>
          ) : (
            <HiddenElement mq={Media.up.m}>
              <MobInnerMsgs $isActiveChat={activeMobTab === 'chat'} $isFocused={inputFocus}>
                <Tab $active={activeMobTab === MobTabMsgs.messages}>
                  {hints.chat && <BlockHint hintText={t('messages:hints.chat')} onClose={hideChatHint} offsets />}
                  {loading ? (
                    <SkeletonLoader card={SkeletonContactCard} />
                  ) : isSearching ? (
                    <SearchingContact
                      value={searchText}
                      onChange={setPeopleSearchText}
                      setIsSearching={setIsSearching}
                      members={members}
                      forwardToUser={forwardToUser}
                      hasMore={hasMore}
                      fetchMore={fetchMore}
                      clearSearchText={clearSearchText}
                    />
                  ) : (
                    <ContactList
                      contactsLoading={contactsLoading}
                      contactsList={contactsList}
                      setActiveContact={setActiveContact}
                      setActiveMobTab={setActiveMobTab}
                      count={countUnReadMsg}
                      isSearching={isSearching}
                      setIsSearching={setIsSearching}
                      showSearchButton={!isSearching && !activeContact && !loading && !contactsLoading}
                      handleSetSearch={handleSetSearch}
                    />
                  )}
                </Tab>
                <Tab $active={activeMobTab === MobTabMsgs.chat}>
                  <MessagesChat
                    msgs={msgs}
                    dataHookMessage={dataMessage}
                    activeContact={activeContact}
                    setActiveMobTab={setActiveMobTab}
                    viewerId={viewerId}
                    viewerData={viewerData}
                    setActiveOrder={dataActiveOrder.setActiveOrder}
                    isSearching={isSearching}
                    setIsSearching={setIsSearching}
                    commId={commId}
                    requestsCount={dataOrders.total}
                    setInputFocus={onInputFocus}
                    inputFocus={inputFocus}
                    activeOrder={!!dataActiveOrder.activeOrder?.objectId}
                    backToMsgs={backToMsgs}
                    redirectToProfile={redirectToProfile}
                  />
                </Tab>
                <Tab $active={activeMobTab === MobTabMsgs.requests}>
                  <MessagesRequests
                    viewerId={viewerId}
                    dataActiveOrder={dataActiveOrder}
                    dataOrders={dataOrders}
                    activeContact={activeContact}
                    setActiveMobTab={setActiveMobTab}
                    dataInvoice={dataInvoice}
                    dataManageOrder={dataManageOrder}
                    count={dataOrders.total}
                    reviewOrderData={reviewOrderData}
                    editOrderData={editOrderData}
                    reviewsData={reviewsData}
                    editReviewData={editReviewData}
                    reviewsDataLoading={reviewsDataLoading}
                    orderHelpers={orderHelpers}
                    showStripePopup={showStripePopup}
                    setCardErrorPopup={setCardErrorPopup}
                    setActiveContact={setActiveContact}
                    resetFromUrl={resetFromUrl}
                    redirectToProfile={redirectToProfile}
                  />
                </Tab>
              </MobInnerMsgs>
            </HiddenElement>
          )}
        </ContentWrapper>
      </Wrapper>
    </>
  );
};
