import {Route} from '../helpers/Route';
import {AuthTab, CreateProfileSteps, PhoneVerifySteps, TypeCommunity, SocialAuth} from '../types/auth';
import {ItemOptions} from './items';

export enum SearchKey {
  id = 'id',
  token = 'token',
  auth = 'auth-type',
  type = 'type',
  section = 'section',
  view = 'view',
  preview = 'preview',
  orderId = 'orderId',
  stripeConnectId = 'stripeConnectId',
  ref = 'ref',
}

export const paths = {
  messages: 'messages',
  listingView: 'listing-view',
  eventView: 'event-view',
  amenityView: 'amenity-view',
  profile: '/user/',
  pageUser: '/page-user',
  listingCreate: '/add-listings/',
  listingCreated: '/listing-created/',
  preferences: 'settings/preferences',
  payments: 'settings/payments',
  account: 'settings/account',
  personal: 'settings/personal',
  loop: '/loop',
  people: '/people',
  communityList: 'configure-list-communities',
  eventCreate: 'add-event',
  eventCreated: '/event-created/',
  referrals: '/referrals',
  group: '/group',
};
export const hideNavPaths = [
  paths.listingView,
  paths.eventView,
  paths.amenityView,
  //paths.profile,
  //paths.pageUser,
  paths.listingCreate,
  paths.eventCreate,
  paths.preferences,
  paths.payments,
  paths.account,
  paths.personal,
  paths.eventCreated,
  paths.listingCreated,
  paths.referrals,
];

export const visibleNavPaths = [paths.loop];

export const route = {
  home: Route.of({path: '/'}),
  //auth
  auth: Route.of<{tab?: AuthTab}>({path: '/auth'}),
  signUp: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/:user'}),
  logIn: Route.of<{user: TypeCommunity}>({path: '/auth/logIn/:user'}),
  joinIn: Route.of({path: '/auth/joinIn'}),
  social: Route.of<{tab?: SocialAuth}>({path: '/auth/social/:tab'}),
  verifyPhone: Route.of<{steps: PhoneVerifySteps; user: TypeCommunity}>({
    path: '/auth/signUp/profile-phone/:steps/:user',
  }),
  allowPush: Route.of<{user: TypeCommunity}>({
    path: '/auth/signUp/allow-push/:user',
  }),
  address: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/profile-address/:user'}),
  //account manager
  profileCreateManager: Route.of({path: '/create-profile-manager'}),
  selectPlan: Route.of({path: '/select-plan'}),
  createCommunity: Route.of({path: '/create-community'}),
  paymentCommunity: Route.of({path: '/create-payment-details'}),
  inviteCommunity: Route.of({path: '/invite-community'}),
  //account resident
  profileCreateResident: Route.of<{steps: CreateProfileSteps}>({path: '/create-profile-resident/:steps?'}),
  searchCommunityResident: Route.of({path: '/search-community-resident'}),
  joinCommunity: Route.of<{id: string}>({path: '/join-community/:id'}),
  joinByInvite: Route.of<{id: string}>({path: '/:alias/:id?'}),
  requestCommunity: Route.of({path: '/request-community'}),
  communityCreationConfirm: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/community-creation-confirm/:user'}),
  communityNotFound: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/community-not-found/:user'}),
  communitySuperResident: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/community-super-resident/:user'}),
  communityFound: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/community-found/:user'}),
  communityRequest: Route.of<{user: TypeCommunity}>({path: '/auth/signUp/community-request-confirmation/:user'}),

  // pages
  pageUser: Route.of({path: '/page-user'}),
  verifyUser: Route.of({path: 'verify-user=true'}),
  loop: Route.of({path: '/loop'}),
  post: Route.of<{id: string}>({path: '/post/:id'}),
  createItem: Route.of({
    path: '/add-listings/create',
  }),
  generateItem: Route.of({
    path: '/add-listings/generate',
  }),
  editItem: Route.of<{id: string}>({
    path: '/add-listings/:id',
  }),
  listings: Route.of({path: '/listings'}),
  listingView: Route.of<{id: string}>({path: '/listing-view/:id'}),
  listingViewEdit: Route.of<{id: string}>({path: '/listing-view-edit/:id'}),
  listingCreated: Route.of<{id: string}>({path: '/listing-created/:id'}),
  configureListCommunities: Route.of<{id: string}>({path: '/configure-list-communities/:id'}),
  dashboard: Route.of({path: '/dashboard'}),
  people: Route.of({path: '/people'}),
  profile: Route.of<{id: string}>({path: '/user/:id'}),
  personalSettings: Route.of({path: '/settings/personal'}),
  accountSettings: Route.of({path: '/settings/account'}),
  communitySettings: Route.of({path: '/settings/community'}),
  preferencesSettings: Route.of({path: '/settings/preferences'}),
  paymentsSettings: Route.of({path: '/settings/payments'}),
  payoutsSettings: Route.of({path: '/settings/payments/payouts'}),
  messages: Route.of<{contactId?: string | null}>({path: `/${paths.messages}/:contactId?`}),
  verifyEmail: Route.of({path: '/verify-email'}),
  plansAndPricing: Route.of({path: '/settings/plansAndPricing'}),
  documents: Route.of({path: '/documents'}),
  documentView: Route.of<{id: string}>({path: '/document-view/:id'}),
  //events
  events: Route.of({path: '/events'}),
  createEvent: Route.of({
    path: '/add-event/create',
  }),
  editEvent: Route.of<{id: string}>({
    path: '/edit-event/:id',
  }),
  eventView: Route.of<{id: string}>({path: '/event-view/:id'}),
  eventViewEdit: Route.of<{id: string}>({path: '/event-view-edit/:id'}),
  eventCreated: Route.of<{id: string}>({path: '/event-created/:id'}),
  configureListCommunitiesEvent: Route.of<{id: string}>({path: '/configure-list-communities-event/:id'}),
  //amenities
  amenities: Route.of({path: '/amenities'}),
  createAmenity: Route.of({
    path: '/add-amenity/create',
  }),
  editAmenity: Route.of<{id: string}>({
    path: '/edit-amenity/:id',
  }),
  amenityView: Route.of<{id: string}>({path: '/Amenity-view/:id'}),
  amenityViewEdit: Route.of<{id: string}>({path: '/Amenity-view-edit/:id'}),
  amenityCreated: Route.of<{id: string}>({path: '/Amenity-created/:id'}),
  configureListCommunitiesAmenity: Route.of<{id: string}>({path: '/configure-list-communities-amenity/:id'}),
  forgotPassword: Route.of({path: '/forgot-password'}),
  changePassword: Route.of({path: '/change-password'}),
  confirmDeleteUser: Route.of({path: '/confirm_delete'}),
  confirmDeactivateUser: Route.of({path: '/confirm_deactivate'}),
  helpDesk: Route.of({path: '/help-desk'}),
  groups: Route.of({path: '/groups'}),
  groupView: Route.of<{id: string}>({path: '/group/:id'}),

  // local biz

  localBiz: Route.of({path: '/local-biz'}),
  createBiz: Route.of({
    path: '/add-biz/create',
  }),
  editBiz: Route.of<{id: string}>({
    path: '/edit-biz/:id',
  }),
  bizView: Route.of<{id: string}>({path: '/biz-view/:id'}),
  bizViewEdit: Route.of<{id: string}>({path: '/biz-view-edit/:id'}),
  bizCreated: Route.of<{id: string}>({path: '/biz-created/:id'}),
  configureBizPublishing: Route.of<{id: string}>({path: '/configure-biz-publishing/:id'}),
  referrals: Route.of<{id: string}>({path: '/referrals'}),
};

export const initalViewParam = {
  loop: `?${SearchKey.view}=all`,
  listings: `?${SearchKey.view}=${ItemOptions.allListings}`,
};
