import React, {useEffect, useMemo, useState, useRef} from 'react';
import {NavContainer} from '../Navigation';
import {TypeCommunity} from '../../types/auth';
import {ProfilePage} from '../../components/Profile/ProfilePage';
import {useCommunity, useGetMessagesAllows} from '../../hooks/community';
import {getUserInfo, useCheckUserType, useGetAdminsLabels} from '../../hooks/people';
import {useItems} from '../../hooks/item';
import {GQLItemOrder} from '../../graphql.schema';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/routes';
import {entities, useReport} from '../../hooks/report';
import {useRecoilState, useRecoilValue} from 'recoil';
import {currentCommunity, onlineUsers} from '../../states/community';
import {useGetCurrencyValue} from '../../hooks/currency';
import {toPointer} from '../../helpers/parse';
import {analyticsTrackFN} from '../../helpers/account';
import {ListingType} from '../../queries/types/item';
import {useViewer, useUserReviews} from '../../hooks/user';
import {useLinks} from '../../hooks/common';
import {reviewsToReceivedAndGiven} from '../../helpers/reviews';
import {useWaveUser} from '../../hooks/item';
import {prevPaths} from '../../states/navState';
import {useGetGroupLinks} from '../../hooks/groups';

type PageProps = {
  id: string;
  userType: TypeCommunity;
  isOwner?: boolean;
};

export const UserPage: React.FC<PageProps> = ({id, userType, isOwner}) => {
  const {push, goBack} = useHistory();
  const isFullName = userType === TypeCommunity.admin || userType === TypeCommunity.manager;
  const result = getUserInfo({id, fullName: isFullName});
  const requestedUserType = useCheckUserType(result?.data?.objectId);
  const {data: community} = useCommunity(id, requestedUserType);
  const actions = useReport(entities.listing);
  const [comm] = useRecoilState(currentCommunity);
  const {data: interestsLink} = useGetGroupLinks(comm?.objectId);
  const {getSignValue} = useGetCurrencyValue();
  const allows = useGetMessagesAllows({directUserId: result?.data?.objectId});
  const adminLabels = useGetAdminsLabels();
  const viewer = useViewer();
  const {getLink} = useLinks();
  const {
    data: listingItems,
    loading: listingLoading,
    liked,
    onLike,
  } = useItems({
    where: {
      Lister: {have: {objectId: {equalTo: result?.data?.objectId}}},
      Published: {
        contains: [
          toPointer({
            __typename: comm?.__typename || '',
            objectId: comm?.objectId || '',
          }),
        ],
      },
      AdmHidden: {exists: false},
    },
    order: GQLItemOrder.updatedAt_DESC,
  });
  const [isSent, setIsSent] = useState(false);
  const {data: reviewsData, loading: reviewsLoading} = useUserReviews(result?.data?.objectId);
  const usersOnline = useRecoilValue(onlineUsers);
  const reviews = reviewsToReceivedAndGiven(reviewsData, result?.data?.id, usersOnline);
  const reviewsContainerRef = useRef<HTMLDivElement>(null);
  const {handleWave} = useWaveUser(comm?.objectId, result?.data?.objectId);
  const [isWaved, setIsWaved] = useState<boolean | undefined>();
  const previousPaths = useRecoilValue(prevPaths);

  const onWaveClick = async () => {
    const wave = await handleWave();
    setIsWaved(wave);
  };

  const goToGroup = (link?: string) => {
    push(getLink(link || route.groups.path));
  };

  useEffect(() => {
    if (!isOwner && viewer?.objectId && community?.objectId && !isSent) {
      analyticsTrackFN('Profile Viewed', {
        profileId: result?.data?.objectId,
        profileType: requestedUserType,
        name: `${result?.data?.firstName}`,
        bio: Boolean(result?.data?.bio?.length), // false is bio is empty
        photo: Boolean(result?.data?.Avatar?.file?.url), // if any photo added
        recommendations:
          result?.data?.recommendations?.eatery ||
          result?.data?.recommendations?.places ||
          result?.data?.recommendations?.movies, // false if no recommendations added
        verifiedBadge: result?.data?.isVerified,
        verifiedEmail: result?.data?.emailVerified,
        verifiedPhone: result?.data?.phoneVerified,
        listings: listingItems?.length, // 0 if no listings
        serviceListings: listingItems?.reduce(
          (acc, item) => (item?.listingType === ListingType.service ? acc + 1 : acc),
          0,
        ),
        rentalListings: listingItems?.reduce(
          (acc, item) => (item?.listingType === ListingType.rent ? acc + 1 : acc),
          0,
        ),
        sellListings: listingItems?.reduce((acc, item) => (item?.listingType === ListingType.sell ? acc + 1 : acc), 0),
        visitorName: `${viewer?.firstName}`,
        visitorEmail: viewer?.email,
        visitorId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });
      setIsSent(true);
      setIsWaved(result?.data && result?.data?.isWaved);
    }
  }, [viewer, community, listingItems, result?.data, requestedUserType, isOwner]);

  const items = useMemo(
    () => listingItems.filter((el) => !!el?.Published?.find((el) => el.objectId === comm?.objectId)),
    [listingItems],
  );

  const openMessages = (contactId?: string) => {
    return isOwner ? push(getLink(route.personalSettings.path)) : push(route.messages.get({contactId}));
  };

  const onRedirect = () => push(getLink(route.people.path));

  const onGoBack = () => {
    if (previousPaths.prev && previousPaths?.current !== previousPaths.prev && previousPaths.prev !== '/') {
      goBack();
    } else {
      onRedirect();
    }
  };

  const onScrollToReviews = () => {
    reviewsContainerRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <NavContainer hideHeader={true}>
      <ProfilePage
        community={community || {}}
        user={result?.data}
        typeUser={userType}
        reqUserType={requestedUserType}
        isOwner={isOwner}
        listingItems={items}
        listingLoading={listingLoading}
        openMessages={openMessages}
        reportActions={actions}
        getSignValue={getSignValue}
        communityAllows={allows}
        adminLabels={adminLabels}
        goBack={onGoBack}
        reviewsItems={reviews}
        reviewsLoading={reviewsLoading}
        reviewsContainerRef={reviewsContainerRef}
        onScrollToReviews={onScrollToReviews}
        handleWave={onWaveClick}
        isWaved={isWaved}
        liked={liked}
        onLike={onLike}
        interestsLink={interestsLink}
        goToGroup={goToGroup}
      />
    </NavContainer>
  );
};
``;
