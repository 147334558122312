import styled from 'styled-components';
import {Media, theme} from '../../../theme';
import {Text} from '../../../Typography/Text';
import {DateRangePickerWrapper} from '../../styles';

export const InputWrapper = styled.div<{$isActive?: boolean; half?: boolean}>`
  position: relative;
  width: ${({half}) => (half ? `49%` : `calc(100vw - 30px)`)};
  background-color: ${({$isActive, theme}) => ($isActive ? theme.palette.grayscale._0 : theme.palette.grayscale._3)};
  padding: 12px 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50px;
  margin-top: 4px;
  margin-bottom: 4px;
  ${theme.font.primary.semiBold};
`;

export const InputOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & svg {
    margin-top: 2px;
  }
  & .selected-options__text {
    max-width: 240px;
  }
  & span {
    padding: 2px 2px 2px 0;
    font-size: 13px;
  }
`;

export const SearchInputField = styled.input`
  flex-grow: 1;
  color: ${({theme}) => theme.palette.grayscale._12};
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  & ::placeholder {
    color: ${({theme}) => theme.palette.grayscale._5};
  }
  padding: 2px 2px 2px 0;
`;

export const InputActionButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  width: auto;
  height: auto;
  display: inline-flex;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const SeparatedDatePickerWrapper = styled(DateRangePickerWrapper)`
  width: 280px;

  & .react-datepicker {
    justify-content: center;
    align-items: center;
  }

  & .react-datepicker__month-container {
    width: max-content;
  }

  & .react-datepicker__day {
    aspect-ratio: 1 / 1;
    ${theme.font.primary.medium}
    ${Media.down.m} {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
`;

export const SearchHint = styled(Text)`
  font-size: 13px;
  color: ${({theme}) => theme.palette.grayscale._5};
  display: block;
  padding: 2px 2px 2px 0;
  cursor: pointer;
`;

export const InputHidden = styled.div`
  ${Media.up.m} {
    display: none;
  }
`;
