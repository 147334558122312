import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {LottieLoader} from '../../../components/Loader/LottieLoader';
import {PayoutsSettings} from '../../../components/Settings/Payouts/PayoutsSettings';
import {GQLOrderOrder} from '../../../graphql.schema';
import {createIncome, createPayoutsWhere, PayoutStatusMap} from '../../../helpers/payment';
import {getPlanFeeString} from '../../../helpers/settings';
import {ListingType} from '../../../queries/types/item';
import {OrderPayMethod} from '../../../queries/types/order';
import {currentCommunity} from '../../../states/community';
import {typeUser} from '../../../states/typeUser';
import {TypeCommunity} from '../../../types/auth';
import {NavContainer} from '../../Navigation';

import {useGetCurrencyValue} from '../../../hooks/currency';
import {useOrders} from '../../../hooks/order';
import {usePagination} from '../../../hooks/pagination';
import {useGetAdminsLabels} from '../../../hooks/people';
import {useGetPayoutsOrdersInfo} from '../../../hooks/transactions';
import {useViewer} from '../../../hooks/user';

export const Payouts: React.FC = () => {
  const viewer = useViewer();
  const [community] = useRecoilState(currentCommunity);
  const [typeCommunity] = useRecoilState(typeUser);
  const {page, rows} = usePagination();
  const isManager = typeCommunity === TypeCommunity.manager;
  const where = createPayoutsWhere({isManager, listerId: viewer?.objectId, communityId: community?.objectId});
  const {t} = useTranslation();
  const {getSignValue} = useGetCurrencyValue();
  const adminLabels = useGetAdminsLabels();

  const {
    data: items,
    count,
    loading,
  } = useOrders({
    skip: (page - 1) * rows,
    first: rows || 10,
    where: where,
    order: GQLOrderOrder.createdAt_DESC,
  });

  const {totalCount, totalAmount} = useGetPayoutsOrdersInfo({
    where: where,
    isManager,
    listerId: viewer?.objectId,
  });

  if (!viewer) return <LottieLoader allScreen={true} $isVisible={true} />;

  const payoutItems = useMemo(
    () =>
      items?.map((i) => ({
        ...i?.Item,
        objectId: i.objectId ?? '',
        planFee: getPlanFeeString(i?.listingType as ListingType, community?.Subscr?.PaymentPlan),
        status: i.payMethod === OrderPayMethod.cash ? t('settings:payouts.external') : PayoutStatusMap(i?.status),
        calcIncome: createIncome(
          {
            cflister: i.cfListerAmt,
            cfRequester: i.cfRequesterAmt,
            isLister: viewer.objectId === i.Lister.objectId,
            isManager,
            listerTotal: i.listerTotalAmt,
          },
          getSignValue,
        ),
      })),
    [items, community, viewer],
  );

  return (
    <NavContainer>
      <PayoutsSettings
        items={payoutItems}
        community={community}
        user={viewer}
        itemsCount={Number(count)}
        allItemsCount={Number(totalCount)}
        isManager={isManager}
        loading={loading}
        totalAmount={totalAmount}
        getSignValue={getSignValue}
        adminLabels={adminLabels}
      />
    </NavContainer>
  );
};
