import styled, {css} from 'styled-components';
import {theme, Media} from '../theme';
import {Text} from '../Typography/Text';
import {InputSizes} from './types';
import ReactDatePicker from 'react-datepicker';

export const SearchContainer = styled.div<{size?: 'lg' | 'md'; $isTransparent?: boolean}>`
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding-right: 70px;
  width: ${(props) => (props.size === 'lg' ? `auto` : `auto`)};
  max-width: 900px;
  box-sizing: border-box;
  position: relative;
  height: 70px;
  background-color: ${(props) => (props.$isTransparent ? theme.palette.grayscale._3 : theme.palette.grayscale._0)};
  transition: 0.2s all;
  ${Media.down.xs} {
    padding-right: 30px;
  }
  ${theme.font.primary.semiBold};

  &,
  & * {
    outline: none;
  }
`;

const dividerLineStyles = css`
  width: 1px;
  height: 43px;
  background-color: ${({theme}) => theme.palette.grayscale._13};
`;

const dividerLineAfterStyles = css`
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  ${dividerLineStyles};
`;

const focusedInputStyles = css`
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  padding-left: 30px;
  transform: translateX(-28px);
  transition: 0.4s all;
  & > div {
    padding-left: 0;
  }
  &:first-of-type {
    transform: none;
    padding-left: 0;
    & > div {
      padding-left: 46px;
      ${Media.down.m} {
        padding-left: 12px;
      }
    }
  }
  background-color: ${({theme}) => theme.palette.grayscale._0};
  ${Media.down.m} {
    padding-left: 10px;
    transform: translateX(-17px);
  }
`;

export const InputBlock = styled.div<{size?: InputSizes; $isSelected?: boolean; $isActive?: boolean}>`
  height: 100%;
  box-sizing: border-box;
  margin-right: 35px;
  background-color: transparent;
  padding-right: 10px;
  display: flex;
  isolation: isolate;

  &:first-of-type {
    & > div {
      padding-left: 46px;
      ${Media.down.m} {
        padding-left: 12px;
      }
    }
  }

  & > div {
    &::after {
      ${dividerLineAfterStyles};
      ${(props) => props.$isSelected && `display: none;`};
    }
  }

  &:last-of-type {
    & > div {
      &::after {
        display: none;
      }
    }
  }

  position: relative;
  ${(props) => props.$isSelected && focusedInputStyles};
  ${Media.down.m} {
    margin-right: 10px;
    min-width: 120px;
    max-width: ${(props) => (props.size === 'lg' ? `280px` : `220px`)};
  }
  ${Media.down.xs} {
    padding-right: 5px;
  }
`;

export const InputWrapper = styled.div`
  padding: 12px 2px 12px 2px;
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
  z-index: -1;

  & :is(input, button, button *) {
    pointer-events: all;
  }
`;

export const SearchInputLabel = styled(Text)`
  color: ${({theme}) => theme.palette.grayscale._10};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const SearchInputField = styled.input`
  color: ${({theme}) => theme.palette.grayscale._12};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  border: none;
  outline: none;
  font-size: 16px;
  width: 85%;
  background-color: transparent;
  z-index: -1;

  & ::placeholder {
    color: ${({theme}) => theme.palette.grayscale._5};
    font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  }
  padding: 5px 2px 2px 0;

  ${Media.down.m} {
    font-size: 12px;
  }
`;

export const SearchButton = styled.button<{$showText?: boolean}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 12px;
  min-width: 52px;
  height: 52px;
  margin-left: auto;

  background-color: ${(props) => (props.$showText ? theme.palette.grayscale._4 : theme.palette.grayscale._5)};
  color: ${({theme}) => theme.palette.grayscale._0};
  padding: ${(props) => (props.$showText ? `0 13px` : `0`)};

  &:hover {
    background-color: ${(props) => (props.$showText ? theme.palette.grayscale._4 : theme.palette.grayscale._6)};
  }

  & span {
    width: ${(props) => (props.$showText ? `50px` : `0px`)};
    transition: 0.2s all;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    ${Media.down.m} {
      font-size: 11px;
      width: ${(props) => (props.$showText ? `40px` : `0px`)};
    }
    ${Media.down.xs} {
      display: none;
    }
  }
  transition: 0.2s all;
  cursor: pointer;
  border-radius: ${(props) => (props.$showText ? `50px` : `50%`)};
  border: none;

  ${Media.down.m} {
    & svg {
      width: 20px;
      height: 20px;
    }
    min-width: 40px;
    height: 40px;
    padding: ${(props) => (props.$showText ? `0 8px` : `0`)};
  }
`;

export const DivideLine = styled.div`
  ${dividerLineStyles};
`;

export const Icon = styled.img`
  max-width: 25px;
  max-height: 25px;
  width: auto;
  height: auto;
  object-fit: cover;
`;

export const CancelBtn = styled.button<{$isShow?: boolean}>`
  outline: none;
  background: transparent;
  border: none;
  display: ${(props) => (props.$isShow ? `flex` : `none`)};
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  box-sizing: border-box;
  z-index: 1;

  & img {
    width: 30px;
    height: 30px;
  }

  &.date__cancel-btn {
    right: 0;
  }
`;

export const SelectedText = styled(Text)`
  font-size: 16px;
  color: ${({theme}) => theme.palette.grayscale._12};
  display: block;
  padding: 5px 2px 2px 0;
  min-width: 88px;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  ${Media.down.m} {
    font-size: 12px;
    max-width: 90px;
    min-width: auto;
  }
  ${Media.down.s} {
    font-size: 11px;
  }
`;

export const SearchHint = styled(Text)`
  font-size: 15px;
  color: ${({theme}) => theme.palette.grayscale._5};
  display: block;
  padding: 5px 2px 2px 0;
  cursor: pointer;
  white-space: nowrap;
  ${Media.down.m} {
    font-size: 12px;
  }
  ${Media.down.s} {
    font-size: 11px;
  }
`;

export const OptionsWrapper = styled.div`
  padding: 13px 35px 22px 27px;
  ${Media.down.m} {
    padding: 9px 20px 9px 20px;
  }
`;

export const OptionRow = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
  transition: 0.15s all;
`;

export const OptionDelimetr = styled.div`
  height: 1px;
  margin-top: 5px;
  background-color: ${({theme}) => theme.palette.grayscale._3};
`;

export const OptionTextWrapper = styled.div`
  width: 175px;
  ${Media.down.s} {
    width: 145px;
  }
`;

export const OptionName = styled(Text)`
  display: block;
  font-size: 16px;
  padding: 13px 0 6px 0;
  ${Media.down.m} {
    font-size: 12px;
    padding: 8px 0 3px 0;
  }
  ${Media.down.s} {
    font-size: 10px;
  }
`;

export const OptionHint = styled(Text)`
  display: block;
  font-size: 13px;
  color: ${({theme}) => theme.palette.grayscale._5};
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${Media.down.m} {
    font-size: 11px;
    max-width: 150px;
  }
`;

export const HintName = styled(Text)`
  display: block;
  font-size: 16px;
  padding: 13px 0;
  color: ${({theme}) => theme.palette.grayscale._5};
  &:hover {
    color: ${({theme}) => theme.palette.grayscale._6};
  }
  ${Media.down.m} {
    font-size: 12px;
    padding: 6px 0 6px 0;
  }
`;

export const OptionCheckboxWrapper = styled.div`
  margin-right: 17px;
  ${Media.down.m} {
    margin-right: 8px;
    transform: scale(0.85);
  }
`;

export const DateInputBlock = styled.div<{$isSelected?: boolean}>`
  width: auto;
  min-width: 215px;
  height: 100%;
  box-sizing: border-box;
  margin-right: 20px;
  background-color: transparent;
  position: relative;
  & > div {
    padding-right: 20px;
    ${Media.down.s} {
      padding-right: 10px;
    }
    &::after {
      ${dividerLineAfterStyles};
      ${(props) => props.$isSelected && `display: none;`};
    }
  }
  &:last-of-type {
    & > div {
      &::after {
        display: none;
      }
    }
  }
  ${(props) => props.$isSelected && `min-width:250px; padding-left: 20px; background-color:#ffffff;`};

  & button {
    ${(props) => props.$isSelected && `right:8px;`};
  }
  ${(props) => props.$isSelected && focusedInputStyles};

  ${Media.down.m} {
    margin-right: 10px;
    min-width: auto;
    ${(props) => props.$isSelected && `min-width:150px; padding-left: 10px; background-color:#ffffff;`};
  }
  ${Media.down.xs} {
    margin-right: 5px;
  }
`;

export const DateInputWrapper = styled.div`
  display: flex;
  padding: 12px 2px;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  justify-content: center;
  ${Media.down.s} {
    justify-content: flex-start;
  }
`;

export const DatePickInput = styled.div<{type?: 'from' | 'until'}>`
  ${(props) => (props.type === 'from' ? `margin-right: 15px;` : `margin-left: 20px;`)};

  ${Media.down.m} {
    ${(props) => (props.type === 'from' ? `margin-right: 5px;` : `margin-left: 5px;`)};
  }
  ${Media.down.m} {
    margin-top: -8px;
  }
`;

const dateRangeArrowBtnStyles = css`
  background-color: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0;
  border: none;
`;
const dateRangeArrowStyles = css`
  width: 1px;
  height: 1px;
  border: solid ${theme.palette.text.quindecimal};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
`;

export const DateRangePickerWrapper = styled.div<{size?: 'small' | 'default' | undefined | false}>`
  overflow: hidden;
  display: flex;
  background-color: ${({theme}) => theme.palette.background.primary};
  ${({size}) => size === 'small' && ' width: 190px !important; padding: 4px 6px;'};
  & > div {
    width: 100%;
    display: flex;
    position: relative;
  }

  & .react-datepicker {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    border: none;
    ${({size}) => size === 'small' && ' flex-direction: column !important;'};
  }

  & .react-datepicker__header {
    background: transparent;
    border: none;
  }

  & .react-datepicker__triangle {
    position: absolute;
  }

  & button.react-datepicker__navigation {
    position: absolute;
    top: 8px;

    &.react-datepicker__navigation--next {
      right: 75px;
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '>';
        ${dateRangeArrowStyles};
        transform: rotate(-45deg);
      }
      & span {
        display: none;
      }
    }
    &.react-datepicker__navigation--previous {
      left: 75px;
      ${dateRangeArrowBtnStyles};
      &::after {
        content: '<';
        ${dateRangeArrowStyles};
        transform: rotate(135deg);
      }
      & span {
        display: none;
      }
    }
  }
  & .react-datepicker__month-container {
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    ${({size}) => size === 'small' && ' width: 100%;'};
  }

  & .react-datepicker__current-month {
    text-align: center;
    color: ${({theme}) => theme.palette.grayscale._12};
    ${theme.font.primary.semiBold};
  }

  & .react-datepicker__day-names {
    display: flex;
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 8px;
    color: ${({theme}) => theme.palette.grayscale._5};
    ${theme.font.primary.semiBold};
    & .react-datepicker__day-name {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({size}) => size === 'small' && ' width: 18px !important; height: 18px !important; font-size: 12px;'};
    }
  }

  & .react-datepicker__week {
    display: flex;
    margin: 2px 0;
  }

  & .react-datepicker__day {
    flex-grow: 1;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.166rem;
    margin: 0;
    ${theme.font.primary.medium}
    ${({size}) => size === 'small' && ' width: 22px !important; height: 22px !important; font-size: 10px;'};
  }

  & .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: ${({theme}) => theme.palette.grayscale._12};
  }
  & .react-datepicker__day.react-datepicker__day--in-range,
  & .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0;
    color: ${theme.palette.text.quaternary};
    position: relative;
    box-sizing: border-box;
  }

  & .react-datepicker__day.react-datepicker__day--range-start {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--range-end {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 13px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  & .react-datepicker__day.react-datepicker__day--selected,
  & .react-datepicker__day.react-datepicker__day--range-end {
    background-color: ${({theme}) => theme.palette.grayscale._12};
    border-radius: 50%;
    color: ${({theme}) => theme.palette.grayscale._0};
  }

  & .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
  }
  & .react-datepicker__day-name {
    font-size: 12px;
    color: ${({theme}) => theme.palette.text.septenary};
  }
  & .react-datepicker__header {
    padding-bottom: 0;
  }
`;

export const DatePickerInput = styled(ReactDatePicker)`
  display: flex;
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: ${({theme}) => theme.palette.grayscale._12};
  }
`;
