import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import ApolloProvider from './ApolloProvider';
import I18nextProvider from './libs/i18next';
import 'emoji-mart/css/emoji-mart.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import './ui-kit/assets/css/index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import Parse from 'parse';
import {API_KEY, APPLICATION_ID, LQ_SERVER_URL, SERVER_URI} from './constants/env';
import {CommunityIdKeyInLS} from './constants/community';
// import {DndProvider} from 'react-dnd';
// import {HTML5Backend} from 'react-dnd-html5-backend';

Parse.initialize(APPLICATION_ID || 'hangeh', API_KEY);
Parse.serverURL = SERVER_URI || 'https://server.hangeh.dev';
Parse.liveQueryServerURL = LQ_SERVER_URL || 'wss://server.hangeh.dev/ws/';
const communityId = localStorage?.getItem(CommunityIdKeyInLS);
const alias = window?.location?.pathname?.split('/')?.length >= 3 ? window?.location?.pathname?.split('/')?.[1] : null;

ReactDOM.hydrate(
  <React.StrictMode>
    <ApolloProvider>
      <I18nextProvider>
        <BrowserRouter>
          <App currentCommId={communityId} communityAlias={alias} />
        </BrowserRouter>
      </I18nextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
