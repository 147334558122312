import React from 'react';
import {ProfileSectionProps} from './types';
import {ProfileSectionBlock, ProfileSectionTitle, CardWrapper} from './styles';

export const ProfileSection: React.FC<ProfileSectionProps> = ({
  children,
  title,
  bordered,
  className,
  icon,
  another,
}) => {
  return (
    <ProfileSectionBlock $bordered={!!bordered} className={className} $another={another}>
      <CardWrapper className={'profile-section__card'}>
        {title && (
          <ProfileSectionTitle>
            {icon}
            {title}
          </ProfileSectionTitle>
        )}
        {children}
      </CardWrapper>
    </ProfileSectionBlock>
  );
};
