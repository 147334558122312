import {i18n} from 'i18next';
import {useEffect, useMemo, useState} from 'react';
import {inputPostType} from '../components/common/PostInputs/types';
import {getArrByCondition} from '../helpers/common';
import {localeType} from '../libs/i18nextUtils';
import {User} from '../queries/types/user';
import {CommunityStateType} from '../states/community';
import {TypeCommunity} from '../types/auth';
import {PointerFile, translateFn} from '../types/common';
import {TCommunity} from '../types/community';
import {PostActions, PostFormValues, PostTypeT, TPollOption, TPost} from '../types/post';
import {MenuItemType} from '../ui-kit/Menu/types';
import {entities} from './report';

type usePostStatesT = {
  getPollData?: (id: string) => Promise<(User[] | undefined)[] | undefined>;
  objectId?: string;
  setEditingUpper?: (b: boolean) => void;
  originalListingCount?: number;
};

export const usePostStates = ({getPollData, objectId, setEditingUpper, originalListingCount}: usePostStatesT) => {
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const [isShowInteractions, setIsShowInteractions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pollData, setPollData] = useState<(User[] | undefined)[] | undefined>();
  const [editMode, setEditMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedState, setEditedState] = useState<Partial<TPost>>();
  const [listingsCount, setListingsCount] = useState<number>(0);

  const report = () => {
    setIsShowReportModal(true);
  };
  const enableEditMode = () => {
    setEditingUpper?.(true);
    setEditMode(true);
  };
  const disableEditMode = () => {
    setEditingUpper?.(false);
    setEditMode(false);
  };
  const closeInteractions = () => {
    setIsShowInteractions(false);
  };
  const openInteractions = async () => {
    if (!getPollData || !objectId) return;
    setIsShowInteractions(true);
    setLoading(true);
    const data = await getPollData(objectId);
    setPollData(data);
    setLoading(false);
  };
  const addToLocalEdited = (post: Partial<TPost>) => setEditedState(post);
  const resetLocalEdited = () => setEditedState(undefined);
  useEffect(() => {
    if (originalListingCount) setListingsCount(originalListingCount);
  }, [originalListingCount]);
  return {
    isShowReportModal,
    isShowInfoModal,
    isShowInteractions,
    setIsShowInfoModal,
    loading,
    pollData,
    editMode,
    isEditing,
    setIsEditing,
    report,
    enableEditMode,
    disableEditMode,
    closeInteractions,
    openInteractions,
    setIsShowReportModal,
    editedState,
    addToLocalEdited,
    resetLocalEdited,
    listingsCount,
  };
};

type PostTranslationT = {
  allTranslates: Record<localeType, string> | undefined;
  i18n: i18n;
  originalText?: string;
  originalOptions?: TPollOption[];
};

export const usePostTranlation = ({allTranslates, i18n, originalText, originalOptions}: PostTranslationT) => {
  const translates = {...allTranslates};
  const translatedText = allTranslates?.[i18n.language as localeType];
  const [isTranslated, setIsTranslated] = useState(!!translatedText);
  const text = (isTranslated ? translatedText : originalText) || originalText;

  if (i18n.language in translates) delete translates[i18n.language as localeType];
  const haveTranslates = !!translatedText && !!Object.values(translates || []).length;

  const showOriginal = () => {
    setIsTranslated(false);
  };
  const showTranslate = () => {
    setIsTranslated(true);
  };

  const Options = useMemo(() => {
    return originalOptions?.map((el) => ({
      ...el,
      text: (isTranslated && el.translates?.[i18n.language as localeType]) || el.text,
    })) as Array<TPollOption>;
  }, [originalOptions, isTranslated]);
  const votedTotalCount = Options?.reduce((acc, item) => {
    const plus = (item && item?.countVotes) || 0;
    return acc + plus;
  }, 0);

  return {showOriginal, showTranslate, text, haveTranslates, votedTotalCount, Options, isTranslated};
};

type usePostMenuT = {
  isOwner?: boolean;
  enableEditMode?: () => void;
  type?: PostTypeT;
  objectId?: string;
  inputType?: inputPostType;
  getPollData?: (id: string) => Promise<(User[] | undefined)[] | undefined>;
  votedTotalCount: number;
  t: translateFn;
  createMenuOptions?: (id?: string | undefined, isPinned?: boolean | undefined) => MenuItemType[];
  openInteractions: () => Promise<void>;
  isPinned?: boolean;
  report: () => void;
  isComment?: boolean;
  typeUser?: TypeCommunity;
  handleDelete: (id?: string | undefined, cb?: (() => Promise<void>) | undefined) => void;
  id?: string;
  refetchComments?: () => Promise<void>;
  setIsShowReportModal: (value: boolean) => void;
  setIsShowInfoModal: (value: boolean) => void;
  actions?: PostActions;
};

export const usePostMenu = ({
  isOwner,
  enableEditMode,
  type,
  objectId,
  inputType,
  getPollData,
  votedTotalCount,
  t,
  createMenuOptions,
  openInteractions,
  isPinned,
  report,
  typeUser,
  handleDelete,
  id,
  refetchComments,
  isComment,
  setIsShowInfoModal,
  setIsShowReportModal,
  actions,
}: usePostMenuT) => {
  const menuOptions = useMemo(
    () => [
      ...(isOwner
        ? [
            ...(getArrByCondition(!!inputType, {
              title: t('community:loop.buttons.edit'),
              onClick: enableEditMode,
            }) as MenuItemType[]),
          ]
        : []),
      ...(isOwner && type === PostTypeT.poll && getPollData && votedTotalCount
        ? [
            {
              title: t('community:loop.buttons.Interactions'),
              onClick: openInteractions,
            } as MenuItemType,
          ]
        : []),
      ...(createMenuOptions?.(objectId, isPinned) || []),
      ...(isOwner
        ? []
        : [
            {
              title: t('people:actions.report'),
              onClick: report,
              render: 'danger',
            } as MenuItemType,
          ]),
      ...((isOwner && isComment) || (isOwner && typeUser !== TypeCommunity.manager && typeUser !== TypeCommunity.admin)
        ? ([
            {
              render: 'line',
            },
            {
              title: t('community:loop.post.menuMore.delete'),
              onClick: () => {
                handleDelete(id, refetchComments);
              },
              render: 'danger',
            },
          ] as Array<MenuItemType>)
        : []),
    ],
    [typeUser, isOwner, isPinned, inputType, votedTotalCount],
  );

  const mobileMenuOptions = useMemo(
    () => [
      ...(menuOptions
        .filter((o) => o.render !== 'line')
        .map((o) => ({
          title: o.title ?? '',
          onClick: () => o.onClick?.(objectId ?? ''),
          to: o.to?.(''),
          type: o.render as 'regular' | 'danger',
        })) as any),
    ],
    [menuOptions],
  );

  const reportParams = {
    title: t('common:report.title', {entity: t(`common:entities.${entities.post}`)}),
    subtitle: t('common:report.subtitle'),
    okButton: t('common:report.confirmReport'),
    textAreaLabel: t('common:report.textAreaLabel'),
    close: () => {
      setIsShowReportModal(false);
    },
    onOk: (text: string) => {
      const onSuccess = () => {
        setIsShowReportModal(false);
        setIsShowInfoModal(true);
      };

      return actions?.report?.({id: objectId, onSuccess, text});
    },
  };
  const infoParams = {
    title: t('common:report.success.title', {entity: t(`common:entities.${entities.post}`)}),
    subtitle: t('common:report.success.subtitle'),
    close: () => {
      setIsShowInfoModal(false);
    },
  };
  return {menuOptions, infoParams, mobileMenuOptions, reportParams};
};

export const toLocalPostState = (
  values: Partial<PostFormValues>,
  viewer: User,
  localRef: string,
  community?: CommunityStateType,
  attachments?: PointerFile[],
  localCreationData?: any,
): TPost => {
  if (!community) return {};
  return {
    objectId: localRef,
    text: values?.text,
    Attachments: attachments,
    Author: {
      id: viewer.id,
      objectId: viewer.objectId,
      Avatar: viewer.Avatar,
      lastName: viewer.lastName,
      firstName: viewer.firstName,
      isVerified: viewer.isVerified,
      Reviews: viewer.Reviews,
      username: viewer.username,
      status: viewer.status,
    },
    Community: {...community} as TCommunity,
    Items: undefined,
    Options: values?.options?.map((el, i) => ({
      Post: undefined,
      Voted: undefined,
      countVotes: 0,
      id: String(i),
      isVoted: false,
      objectId: String(i),
      originalLang: localeType.en,
      position: i,
      text: el?.text,
    })),
    Pin: [],
    comments: 0,
    createdAt: new Date(),
    id: localRef,
    isDeleted: false,
    isHidden: false,
    likes: 0,
    originalLang: localeType.en,
    sentAs: 'resident',
    smiles: 0,
    type: values.type,
    waves: 0,
    __typename: 'Post',
    isLocal: true,
    isMulti: values?.isMulti,
    localRef,
    localCreationData,
  };
};
