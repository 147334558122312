import React from 'react';
import {PaymentEntry, PaymentInfoProps} from './types';
import {
  CardPaymentInfoWrapper,
  ContentWrapper,
  ContentWrapperFlex,
  TextContainer,
  TextContainerEntry,
  TextContainerTotal,
} from './styles';
import {currency} from '../utils/helpers';

const PaymentFragment: React.FC<{payment?: PaymentEntry}> = ({payment}) => {
  if (!payment?.label || !payment?.value || payment.value === `-${currency}0` || payment.value === `${currency}0`)
    return <></>;

  return (
    <ContentWrapper>
      <TextContainer>
        <TextContainerEntry underline={true}>{payment?.label}</TextContainerEntry>
        <TextContainerEntry>{payment?.value}</TextContainerEntry>
      </TextContainer>
    </ContentWrapper>
  );
};

export const PaymentInfo: React.FC<PaymentInfoProps> = ({payments, rewardType}) => {
  if (rewardType === 'free' || rewardType === 'coffee') return null;
  const paymentEntries = payments.map((p, i) => {
    return (
      <React.Fragment key={i}>
        <ContentWrapperFlex direction={'column'}>
          <PaymentFragment payment={p.price} />
          <PaymentFragment payment={p.period} />
          <PaymentFragment payment={p.serviceFee} />
          <PaymentFragment payment={p.refundFee} />
          <PaymentFragment payment={p.paymentProcessing} />
          <PaymentFragment payment={p.discount} />
          <PaymentFragment payment={p.tax} />
        </ContentWrapperFlex>
        {!!p?.total?.value && (
          <TextContainerTotal>
            <TextContainerEntry bold={true}>{p?.total?.label}</TextContainerEntry>
            <TextContainerEntry bold={true}>{p?.total?.value}</TextContainerEntry>
          </TextContainerTotal>
        )}
      </React.Fragment>
    );
  });

  return <CardPaymentInfoWrapper>{paymentEntries}</CardPaymentInfoWrapper>;
};
