import {gql} from '@apollo/client';
import {LocationFragment} from './locations';
import {LanguageFragment} from './language';

export default gql`
  fragment UserFragment on User {
    id
    objectId
    stripeId
    createdAt
    updatedAt
    username
    email
    emailVerified
    isVerified
    phone
    status
    firstName
    lastName
    birthDate
    work
    study
    bio
    languages
    howDidYouHear
    aptSuite
    address
    zip
    gender
    onlineDate
    isOnline
    checkListSeen
    profileViewOff
    Communities {
      count
    }
    interests {
      ... on Element {
        value
      }
    }
    personInterests {
      ... on Element {
        value
      }
    }
    recommendations
    Reviews {
      count
    }
    Location {
      ...LocationFragment
    }
    Avatar {
      objectId
      id
      file {
        name
        url
      }
    }
    checkList
    Blocked {
      ... on Community {
        objectId
      }
    }
    Language {
      ...LanguageFragment
    }
    occupation
    tips
    phoneVerified
    lastVisitedCommunity
    ReferralCode
    authData
  }
  ${LocationFragment}
  ${LanguageFragment}
`;
