import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  AdminWrapper,
  AdminWrapperAddAddress,
  ButtonWrapper,
  CancelButton,
  CapitalizedText,
  ContentWrapper,
  EditButton,
  LoadingText,
  MenuWrapper,
  SectionWrapper,
  TextLabel,
  TextValueWrapper,
  TextWrapper,
  UpdateButton,
  UserLabel,
  UserLabelAddAddress,
  WrapperRow,
} from './commonStyles';
import {ConfirmationAdress} from './Community/ConfirmationAdress';
import {AdminInfoT} from '../../queries/community';
import {CommunityAdmin} from '../../queries/types/community';
import {CommunityType} from '../../types/settings';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Message} from '../../ui-kit/Form/Message';
import {Label} from '../../ui-kit/Labels';
import {Menu} from '../../ui-kit/Menu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {Text} from '../../ui-kit/Typography/Text';
import {RichText} from '../common/RichText/RichText';

interface SubsectionProps {
  label: string;
  value: string;
  onClick?: () => void;
}

export const SettingsSubsection: React.FC<Partial<SubsectionProps>> = ({label, value}) => {
  return (
    <TextWrapper>
      <Text color={'octonary'} size={18}>
        {label}
      </Text>
      {value && (
        <Text color={'octonary'} size={12}>
          {value}
        </Text>
      )}
    </TextWrapper>
  );
};

export const SettingsEntry: React.FC<Partial<SubsectionProps>> = ({label, value, onClick}) => {
  const {t} = useTranslation();
  return (
    <ContentWrapper onClick={onClick} $isClickable={!!onClick}>
      <TextLabel>{label}</TextLabel>
      <TextValueWrapper>
        <RichText isForProfile={true} noLinkify={true} html={value || t('settings:personal.notSpecified')} />
      </TextValueWrapper>
    </ContentWrapper>
  );
};

export const SettingsEntryAdmin: React.FC<Partial<SubsectionProps>> = ({label, value, onClick}) => {
  const {t} = useTranslation();

  return (
    <ContentWrapper onClick={onClick} $isClickable={!!onClick}>
      <TextLabel>{label}</TextLabel>
      <TextValueWrapper>
        <CapitalizedText variant={'tertiary'} color={'quinary'} size={16}>
          {value || t('settings:personal.notSpecified')}
        </CapitalizedText>
      </TextValueWrapper>
    </ContentWrapper>
  );
};

interface AdminEntryProps {
  isOwner?: boolean;
  admin: CommunityAdmin;
  removeAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  editAdmin: (id: string) => Promise<void | undefined>;
  makeOwner: ((id: string) => Promise<boolean>) | undefined;
  adminsInfo?: AdminInfoT[];
}

export const AdminFormEntry: React.FC<AdminEntryProps> = ({
  isOwner,
  removeAdmin,
  editAdmin,
  admin,
  adminsInfo,
  makeOwner,
}) => {
  const {t} = useTranslation();
  const [removed, setRemoved] = useState<boolean>(false);
  const [isShowModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const handleCloseConfirm = () => {
    setShowModalConfirm(false);
  };

  const handleMakeOwner = async () => {
    if (admin.User.objectId && makeOwner) {
      const isSuccess = await makeOwner(admin.User?.objectId);
      isSuccess ? setShowModalConfirm(false) : setIsError(true);
    }
  };

  if (removed) return null;

  const userLabel = admin.label;
  const removeAdminItem: MenuItemType = {
    title: t('settings:community.admins.removeAdmin'),
    render: 'danger',
    onClick: (id) => {
      try {
        removeAdmin(admin?.id ?? id).then(() => setRemoved(true));
      } catch (err) {
        console.log(err);
      }
    },
  };
  const transferOwner: MenuItemType = {
    title: t('settings:community.admins.transferOwner'),
    render: 'danger',
    onClick: () => {
      setShowModalConfirm(true);
    },
  };
  const editAdminItem: MenuItemType = {
    title: t('settings:community.admins.editAdmin'),
    onClick: (id) => {
      try {
        editAdmin(admin.id ?? id);
      } catch (err) {
        console.log(err);
      }
    },
  };

  const modalsText = {
    title: t('settings:modals.transfer.title'),
    error: t('settings:modals.transfer.error'),
    description: t('settings:modals.transfer.description', {adminName: admin.User?.firstName}),
    okText: t('settings:modals.transfer.button'),
    cancelText: t('settings:modals.transfer.close'),
  };

  const options: MenuItemType[] = makeOwner
    ? [editAdminItem, transferOwner, removeAdminItem]
    : [editAdminItem, removeAdminItem];
  const admEmail = adminsInfo?.find((el) => admin.User?.objectId === el?.id)?.email || admin.User?.email;
  return (
    <>
      <AdminWrapper>
        <SettingsEntry
          label={`${admin?.User?.firstName} ${admin?.User?.lastName || admin?.User?.firstLetter}`}
          value={admEmail}
        />
        <WrapperRow>
          {isOwner ? (
            <UserLabel>
              <Label>{t('settings:community.admins.owner')}</Label>
            </UserLabel>
          ) : (
            <>
              {!!userLabel && (
                <UserLabel>
                  <Label>{userLabel}</Label>
                </UserLabel>
              )}
            </>
          )}
          {!isOwner && (
            <MenuWrapper>
              <MenuButton item={{objectId: admin?.id ?? ''}} menu={Menu} options={options} />
            </MenuWrapper>
          )}
        </WrapperRow>
      </AdminWrapper>
      {isShowModalConfirm && (
        <Confirmation
          title={isError ? modalsText.error : modalsText.title}
          subTitle={isError ? undefined : modalsText.description}
          onClose={isError ? undefined : handleCloseConfirm}
          okText={isError ? modalsText.cancelText : modalsText.okText}
          onOk={isError ? handleCloseConfirm : handleMakeOwner}
          buttonType={'danger'}
          cancelText={isError ? undefined : modalsText.cancelText}
        />
      )}
    </>
  );
};

interface HeaderProps {
  title: string;
  subtitle: string;
  noLine?: boolean;
  hideMob?: boolean;
  mobpt?: string;
}

export const SettingsHeader: React.FC<HeaderProps> = ({title, subtitle, noLine, hideMob, mobpt}) => {
  return (
    <SectionWrapper $noBorder={noLine} $topBorder={noLine} $hideMob={hideMob} mobpt={mobpt}>
      <TextWrapper noPadding={true}>
        <Text color={'quaternary'} weight={'medium'} size={26}>
          {title}
        </Text>
        <Text color={'septenary'} weight={'semi-bold'} size={12}>
          {subtitle}
        </Text>
      </TextWrapper>
    </SectionWrapper>
  );
};

interface FormButtonProps {
  variant: 'edit' | 'update';
  onClick?: (e: React.SyntheticEvent) => void;
  onCancel?: () => void;
  disabled?: boolean;
  loading?: boolean;
  noCancel?: boolean;
}

export const FormButtons: React.FC<FormButtonProps> = ({variant, onClick, onCancel, disabled, loading, noCancel}) => {
  const {t} = useTranslation();

  return (
    <ButtonWrapper>
      {variant === 'edit' ? (
        <EditButton onClick={onClick} disabled={disabled} $isDisabled={disabled}>
          {t('settings:personal.edit')}
        </EditButton>
      ) : (
        <UpdateButton onClick={onClick} disabled={disabled || loading}>
          {loading ? <LoadingText>{t('settings:personal.loading')}</LoadingText> : t('settings:personal.update')}
        </UpdateButton>
      )}
      {variant === 'update' && !noCancel && (
        <CancelButton onClick={onCancel} disabled={loading}>
          {t('settings:personal.cancel')}
        </CancelButton>
      )}
    </ButtonWrapper>
  );
};

interface ErrorMessageProps {
  error?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({error}) => {
  const {t} = useTranslation();
  return Boolean(error) ? <Message variant={'error'}>{t(error ?? '')}</Message> : <></>;
};

interface AddAddressFormEntryProps {
  unit?: string;
  zip?: string;
  address?: string;
  removeAddAddress: (id: string) => void;
  id: any;
  community?: Partial<CommunityType> | null;
}

export const AddAddressFormEntry: React.FC<AddAddressFormEntryProps> = ({
  id,
  unit,
  zip,
  address,
  removeAddAddress,
  community,
}) => {
  const {t} = useTranslation();
  const [isShowModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  // const [isError, setIsError] = useState<boolean>(false);
  const handleCloseConfirm = () => {
    setShowModalConfirm(false);
  };

  const removeAdminItem: MenuItemType = {
    title: t('settings:community.address.delete'),
    render: 'danger',
    onClick: (id) => {
      try {
        removeAddAddress(id);
      } catch (err) {
        console.log(err);
      }
    },
  };

  const editAdminItem: MenuItemType = {
    title: t('settings:community.address.edit'),
    onClick: () => {
      try {
        setShowModalConfirm(true);
      } catch (err) {
        console.log(err);
      }
    },
  };

  const modalsText = {
    title: t('settings:modals.addAdress.editAddress'),
    okText: t('settings:modals.addAdress.button'),
  };

  const options: MenuItemType[] = [editAdminItem, removeAdminItem];

  return (
    <>
      <AdminWrapperAddAddress>
        <SettingsEntry label={t('settings:community.address.address')} value={address} />

        <SettingsEntry label={t('settings:community.address.zip')} value={zip} />
        <WrapperRow>
          <UserLabelAddAddress>
            <Label>{t('settings:community.address.unitsCount', {count: Number(unit?.replaceAll(' ', ''))})}</Label>
          </UserLabelAddAddress>
          <MenuWrapper>
            <MenuButton item={{objectId: id}} menu={Menu} options={options} />
          </MenuWrapper>
        </WrapperRow>
      </AdminWrapperAddAddress>
      {isShowModalConfirm && (
        <ConfirmationAdress
          community={community}
          title={modalsText.title}
          okText={modalsText.okText}
          // cancelText={modalsText.cancelText}
          onClose={handleCloseConfirm}
          itemId={id}
          zip={zip}
          unit={unit}
          address={address}
        />
      )}
    </>
  );
};
