import styled from 'styled-components';
import {Link as ReactLink} from 'react-router-dom';
import {fontPrimaryMedium, fontPrimaryReg, fontPrimarySemiBold} from '../styles';
import {Media, theme} from '../../../ui-kit/theme';
import {Link} from '../../Listings/styles';
import {BasePortal} from '../../../ui-kit/Popup';

export const LinkBackWrapper = styled(ReactLink)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  & > svg {
    margin-right: 6px;
  }
  width: auto;
`;

export const LinkText = styled.div`
  font-size: 13px;
  color: ${({theme}) => theme.palette.grayscale._12};
  ${fontPrimaryMedium};
  width: auto;
  display: inline-flex;
`;

export const LinkfyLink = styled.a`
  color: ${({theme}) => theme.palette.common.aqua};
`;

export const AddPlusBtn = styled(Link)`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.palette.common.aqua};
  display: none;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 1px 0 9px rgba(0, 0, 0, 0.5);

  &::after {
    content: '';
    display: block;
    height: 0;
    width: 24px;
    border-top: 1px solid ${({theme}) => theme.palette.grayscale._0};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    content: '';
    display: block;
    height: 24px;
    width: 0;
    border-right: 1px solid ${({theme}) => theme.palette.grayscale._0};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${Media.down.s} {
    display: flex;
  }
`;

export const AddBtnPortal = styled(BasePortal)<{$isAnimated?: boolean; $isWide?: boolean}>`
  display: none;
  ${({$isAnimated}) =>
    $isAnimated &&
    ` transition: 0.15s all;
      overflow-x: hidden;
      padding: 9px;
    `};
  ${Media.down.m} {
    display: flex;
    ${({$isWide}) =>
      $isWide &&
      `
    width: 84px;
    `};
  }
  z-index: 100;
`;

export const AddBtnEmpty = styled(Link)<{$isAnimated?: boolean}>`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.palette.common.aqua};
  display: none;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 1px 0 9px rgba(0, 0, 0, 0.5);

  ${Media.down.s} {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({$isAnimated}) =>
      $isAnimated &&
      `
    width: 84px;
    border-radius: 25px;
    display: flex;
    box-sizing: border-box;
    padding: 0px 10px;
    & svg {
      transform: scale(0.84);
    }
    `};
  }
`;

export const AnimatedText = styled.div`
  font-size: 14px;
  width: 100%;
  color: ${({theme}) => theme.palette.text.primary};
  margin-left: 1px;
  width: 48px;
  text-align: center;
`;

export const GoBackTitle = styled.div`
  font-size: 23px;
  width: 100%;
  color: ${({theme}) => theme.palette.text.sevendecimal};
  ${fontPrimarySemiBold};
  margin-left: 4px;
`;

export const GoBackHeader = styled.div<{$scrolled?: boolean}>`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 65px;
  background: ${theme.palette.background.primary};
  ${Media.down.s} {
    ${({$scrolled, theme}) =>
      $scrolled &&
      `
      border-bottom: 1px solid ${theme.palette.background.secondary};
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    `}
  }
`;

export const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  &:checked + label {
    background: #bada55;
  }
  &:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.label<{$isChecked?: boolean}>`
  position: relative;
  display: inline-block;
  width: 63px;
  height: 35px;
  border-radius: 37px;
  background-color: ${({theme, $isChecked}) => ($isChecked ? theme.palette.common.aqua : theme.palette.grayscale._21)};
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  input {
    display: none;
  }
  input:checked + div {
    left: calc(63px - 32px);
  }
  div {
    position: absolute;
    width: 29px;
    height: 29px;
    border-radius: 27px;
    background-color: ${({theme}) => theme.palette.background.primary};
    top: 3px;
    left: 3px;
    transition: all 0.3s;
  }
`;

export const ToggleText = styled.div`
  font-size: 16px;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._6};
  margin-left: 10px;
`;
