import React from 'react';
import {CheckboxPairWrapper, CheckboxWrapper, CheckboxWrapperMobile, NotificationSettingsEntryWrapper} from './styles';
import {Text} from '../../../ui-kit/Typography/Text';
import {Checkbox} from '../../../ui-kit/Checkbox/Checkbox';
import {useTranslation} from 'react-i18next';
import {handleModifyOptions, hasEmail, hasPush, hasSMS} from '../../../helpers/settings';
import {HiddenElement} from '../../common/Hidden/HiddenElement';
import {Media} from '../../../ui-kit/theme';
import {NotifyType, StateNotificationSettingsType} from '../../../types/notifications';

interface NotificationSettingsEntryProps {
  title: string;
  valuePush: boolean;
  valueEmail: boolean;
  valueSMS: boolean;
}

export const NotificationSettingsEntry: React.FC<NotificationSettingsEntryProps> = ({
  title,
  valuePush,
  valueEmail,
  valueSMS,
}) => {
  const {t} = useTranslation();
  const handleCheckMock = () => null;

  return (
    <NotificationSettingsEntryWrapper>
      <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
        {title}
      </Text>
      <HiddenElement mq={Media.down.s}>
        <CheckboxWrapper viewOnly={true}>
          <Checkbox $isChecked={valuePush} onChange={handleCheckMock} />
          <Checkbox $isChecked={valueEmail} onChange={handleCheckMock} />
          <Checkbox $isChecked={valueSMS} onChange={handleCheckMock} />
        </CheckboxWrapper>
      </HiddenElement>
      <HiddenElement mq={Media.up.m}>
        <CheckboxWrapperMobile viewOnly={true}>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.push')}
            </Text>
            <Checkbox $isChecked={valuePush} onChange={handleCheckMock} />
          </CheckboxPairWrapper>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.email')}
            </Text>
            <Checkbox $isChecked={valueEmail} onChange={handleCheckMock} />
          </CheckboxPairWrapper>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.sms')}
            </Text>
            <Checkbox $isChecked={valueSMS} onChange={handleCheckMock} />
          </CheckboxPairWrapper>
        </CheckboxWrapperMobile>
      </HiddenElement>
    </NotificationSettingsEntryWrapper>
  );
};

interface NotificationSettingsInfoEntryProps {
  options?: string[];
  name: string;
}

export const NotificationSettingsInfoEntry: React.FC<NotificationSettingsInfoEntryProps> = ({options, name}) => {
  const {t} = useTranslation();

  return (
    <NotificationSettingsEntry
      title={t(`settings:notifications.labels.${name}`)}
      valuePush={hasPush(options)}
      valueEmail={hasEmail(options)}
      valueSMS={hasSMS(options)}
    />
  );
};

interface NotificationSettingsFormEntryBaseProps extends NotificationSettingsEntryProps {
  onPush: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSMS: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSettingsFormBase: React.FC<NotificationSettingsFormEntryBaseProps> = ({
  title,
  valuePush,
  valueEmail,
  valueSMS,
  onPush,
  onEmail,
  onSMS,
}) => {
  const {t} = useTranslation();

  return (
    <NotificationSettingsEntryWrapper>
      <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
        {title}
      </Text>
      <HiddenElement mq={Media.down.s}>
        <CheckboxWrapper>
          <Checkbox $isChecked={valuePush} onChange={onPush} />
          <Checkbox $isChecked={valueEmail} onChange={onEmail} />
          <Checkbox $isChecked={valueSMS} onChange={onSMS} />
        </CheckboxWrapper>
      </HiddenElement>
      <HiddenElement mq={Media.up.l}>
        <CheckboxWrapperMobile>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.push')}
            </Text>
            <Checkbox $isChecked={valuePush} onChange={onPush} />
          </CheckboxPairWrapper>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.email')}
            </Text>
            <Checkbox $isChecked={valueEmail} onChange={onEmail} />
          </CheckboxPairWrapper>
          <CheckboxPairWrapper>
            <Text variant={'primary'} size={12} color={'octonary'} weight={'regular'}>
              {t('settings:notifications.sms')}
            </Text>
            <Checkbox $isChecked={valueSMS} onChange={onSMS} />
          </CheckboxPairWrapper>
        </CheckboxWrapperMobile>
      </HiddenElement>
    </NotificationSettingsEntryWrapper>
  );
};

interface NotificationSettingsFormEntryProps {
  values: StateNotificationSettingsType;
  name: keyof StateNotificationSettingsType;
  onChange: (next: {name: keyof StateNotificationSettingsType; value: string[]}) => void;
}

export const NotificationSettingsFormEntry: React.FC<NotificationSettingsFormEntryProps> = ({
  name,
  values,
  onChange,
}) => {
  const {t} = useTranslation();

  const handleChangePush = (name: keyof StateNotificationSettingsType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: handleModifyOptions(e.target.checked ? 'add' : 'remove', values[name], NotifyType.Push),
    });
  };

  const handleChangeEmail = (name: keyof StateNotificationSettingsType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: handleModifyOptions(e.target.checked ? 'add' : 'remove', values[name], NotifyType.Email),
    });
  };

  const handleChangeSMS = (name: keyof StateNotificationSettingsType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: handleModifyOptions(e.target.checked ? 'add' : 'remove', values[name], NotifyType.SMS),
    });
  };

  return (
    <NotificationSettingsFormBase
      onPush={handleChangePush(name)}
      onEmail={handleChangeEmail(name)}
      onSMS={handleChangeSMS(name)}
      title={t(`settings:notifications.labels.${name}`)}
      valuePush={hasPush(values[name])}
      valueEmail={hasEmail(values[name])}
      valueSMS={hasSMS(values[name])}
    />
  );
};

export const Subtitle: React.FC = () => {
  const {t} = useTranslation();

  return (
    <NotificationSettingsEntryWrapper $noBorder>
      <Text variant={'primary'} size={18} color={'octonary'} weight={'regular'}>
        {t('settings:notifications.subtitles.notifications')}
      </Text>
      {/* <HiddenElement mq={Media.down.s}>
        <CheckboxWrapper>
          <Text variant={'primary'} size={14} color={'octonary'} weight={'bold'}>
            {t('settings:notifications.push')}
          </Text>
          <Text variant={'primary'} size={14} color={'octonary'} weight={'bold'}>
            {t('settings:notifications.email')}
          </Text>
          <Text variant={'primary'} size={14} color={'octonary'} weight={'bold'}>
            {t('settings:notifications.sms')}
          </Text>
        </CheckboxWrapper>
      </HiddenElement> */}
    </NotificationSettingsEntryWrapper>
  );
};

export const InfoSubtitle: React.FC<{name: string}> = ({name}) => {
  const {t} = useTranslation();

  return (
    <NotificationSettingsEntryWrapper>
      <Text variant={'primary'} size={12} color={'octonary'} weight={'bold'}>
        {t(`settings:notifications.subtitles.${name}`)}
      </Text>
    </NotificationSettingsEntryWrapper>
  );
};
