import React, {useEffect, useMemo} from 'react';
import {TDataCreator, useCreateEventOrder} from '../../hooks/event';
import {EventPublicView} from '../../components/Events/View';
import {EventFormAction, TEvent} from '../../types/event';
import {CommunityStateType} from '../../states/community';
import {getResize} from '../../helpers/file';
import {useSetRecoilState} from 'recoil';
import {signUpRedirect} from '../../states/navState';
import {useLocation} from 'react-router-dom';

type TProps = {
  id?: string;
  community: CommunityStateType;
  dataCreator: TDataCreator;
  loading?: boolean;
  event: Partial<TEvent>;
};

export type CustomAttendees = {
  firstName?: string;
  firstLetter?: string;
  avatar: {
    file: {
      name: string;
      url: string;
    };
  };
  totalListings?: number;
}[];

export const ViewPublicEvent: React.FC<TProps> = () => {
  const {pathname} = useLocation();
  const setRedirectOnSignUp = useSetRecoilState(signUpRedirect);
  const windowOGData = typeof window !== 'undefined' ? window?.OGData : null;
  const event = {
    ...windowOGData?.event,
    startTime: windowOGData?.event?.startTime?.iso,
    endTime: windowOGData?.event?.endTime?.iso,
  };
  const available = {
    availableData: {
      check: true,
      text: '',
    },
  };
  const requestAccess = true;
  const dataOrder = useCreateEventOrder({
    communityId: '',
    eventId: event?.objectId,
    rewardType: event?.rewardType,
    startTime: event?.startTime,
    eventType: event?.eventType,
  });
  const dataCreator = {
    verified: '',
    reviews: '',
    isOnline: false,
    avatar: getResize(event?.lister?.avatar, 'lg') || '',
    firstName: event?.lister?.firstName || '',
    lastName: event?.lister?.lastName || '',
  };
  const attendees: CustomAttendees = useMemo(() => {
    return event?.attendees?.map((el: any) => ({
      firstName: el?.firstName,
      firstLetter: el?.firstLetter,
      avatar: {
        file: {
          url: el?.avatar,
        },
      },
      totalListings: el?.totalListings,
    }));
  }, [event?.attendees?.length]);
  useEffect(() => {
    setRedirectOnSignUp(pathname);
  }, [!!pathname]);
  if (!event?.name) return null;
  return (
    <EventPublicView
      isAuthor={false}
      action={EventFormAction.view}
      event={event}
      $loading={false}
      isAvailable={available.availableData}
      requestAccess={requestAccess}
      dataOrder={dataOrder}
      adminLabels={{}}
      attendees={event?.attendees?.length}
      isPublicView={true}
      dataCreator={dataCreator}
      customAttendees={attendees}
    />
  );
};
