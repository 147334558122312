import styled from 'styled-components';
import {Link as ReactLink} from 'react-router-dom';
import {Media} from '../../../ui-kit/theme';
import {Button} from '../../../ui-kit/Button/Button';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 625px;
  max-height: max-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
`;

export const Link = styled(ReactLink)`
  text-decoration: underline;
  font-size: 16px;
  line-height: 150%;
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  color: ${({theme}) => theme.palette.text.septenary};

  ${Media.down.s} {
    font-size: 12px;
  }
`;

export const ButtonContinueToPersonal = styled(Button).attrs({
  ghost: true,
  variant: 'primary',
  rounded: true,
  width: '250px',
})`
  margin-top: -40px;

  & .button-value {
    margin: 0;
    font-size: 14px;
    font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
    font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
    color: ${({theme}) => theme.palette.common.aqua};
  }
  ${Media.down.m} {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

export const ImageWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: -50px;

  ${Media.down.s} {
    margin-top: 50px;
    margin-bottom: -60px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  height: 10vh;
  margin-bottom: 20px;

  ${Media.up.s} {
    display: none;
  }
`;
