import React from 'react';
import {DropdownContainer} from './styles';
import {DropdownBase} from './types';

export const Dropdown: React.FC<DropdownBase> = ({children, offsetX, offsetY, position, $isOpen = false, padding}) => {
  return (
    <DropdownContainer offsetX={offsetX} offsetY={offsetY} position={position} $isOpen={$isOpen} padding={padding}>
      {children}
    </DropdownContainer>
  );
};
