import styled, {css} from 'styled-components';
import {Media, theme} from '../../../../../ui-kit/theme';

const votedLabelStyles = css`
  & .poll__row > label {
    opacity: 0.85;
    //pointer-events: none;
  }
`;

export const PollContainer = styled.div<{$isVoted?: boolean; $disabled?: boolean}>`
  width: 100%;
  margin-top: 15px;
  ${({$isVoted}) => $isVoted && votedLabelStyles};
  ${({$disabled}) => $disabled && `opacity: 0.5; pointer-events: none;`};
`;

export const PollRow = styled.div.attrs({className: 'poll__row'})`
  border-top: 1px solid ${({theme}) => theme.palette.grayscale._3};
  display: flex;
  justify-content: space-between;
  padding: 9px min(26px, 3vw) 9px min(100px, 8vw);

  &.poll__row:last-of-type {
    border-top: 1px solid ${({theme}) => theme.palette.grayscale._3};
    border-bottom: 1px solid ${({theme}) => theme.palette.grayscale._3};
  }

  & > label > span {
    padding-right: 6px;
    box-sizing: border-box;
    ${Media.down.s} {
      font-size: 14px;
    }
  }

  ${Media.down.s} {
    padding-left: 17px;
  }
`;

export const VotedInfo = styled.div`
  font-size: 12px;
  ${theme.font.primary.regular};
  color: ${({theme}) => theme.palette.grayscale._13};
  display: flex;
  text-align: right;
  align-items: center;

  & i {
    ${theme.font.primary.regular};
    font-style: normal;
  }
`;

export const VotedInfoPercent = styled.span`
  min-width: 37px;
`;
export const VotedInfoCount = styled.span`
  min-width: 45px;
`;
