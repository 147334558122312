import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Text} from '../../ui-kit/Typography/Text';
import {Media} from '../../ui-kit/theme';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {fontPrimaryReg} from '../common/styles';
import {SubTitle} from '../Listings/styles';

export const PageWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 15px;
  box-sizing: border-box;
  ${Media.down.l} {
    padding: 0 12px;
    margin-bottom: 50px;
  }
`;

export const ViewPageWrapper = styled(PageWrapper)`
  ${Media.down.l} {
    padding: 20px 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled(Button).attrs({
  ghost: true,
  rounded: true,
  variant: 'secondary',
})`
  width: initial;
  padding: 10px 25px;

  & > div {
    padding: 0;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  ${Media.down.xs} {
    width: 100%;
  }
`;

export const CoverWrapper = styled.div<{$isHaveLabel?: boolean}>`
  width: 300px;
  //border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  opacity: ${({$isHaveLabel}) => ($isHaveLabel ? 0.4 : 1)};
  aspect-ratio: 3/2;

  max-width: 100%;

  ${Media.down.xs} {
    width: 100%;
    height: calc(100vw / 1.5);
  }
`;
export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const EmptyCoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({theme}) => theme.palette.background.octodecimal};
`;

export const EmptyCoverWrapper = styled.div`
  position: relative;

  path {
    stroke-width: 1;
  }
`;

export const EmptyCoverFormat = styled(Text).attrs({
  size: 14,
  weight: 'medium',
  color: 'quaternary',
})`
  position: absolute;
  left: 28px;
  bottom: 20px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const FormatLabel = styled.div`
  height: 18px;
  padding: 0 6px;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.septenary};
`;

export const DocumentCardTitle = styled(SubTitle)`
  color: ${({theme}) => theme.palette.text.quaternary};
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 8px;
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px 25px;

  ${Media.down.m} {
    grid-template-columns: 1fr 1fr;
  }
  ${Media.down.xs} {
    grid-template-columns: 1fr;
  }
`;

export const MenuBtnWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 8px;
`;

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.palette.background.primary};
  padding: 18px 21px 12px;
  min-width: 376px;
  border-radius: 12px;
  box-sizing: border-box;

  ${Media.down.xs} {
    width: 100%;
  }
`;

export const LoadersWrapper = styled.div`
  display: flex;
  gap: 35px;
`;

export const FileLoaderWrapper = styled.div`
  flex: 142px 0 0;

  span {
    white-space: nowrap;
  }
`;

export const DocShapeWrapper = styled.div`
  position: relative;
`;

export const DocIcon = styled(IconSvg).attrs({
  type: 'file',
  height: '81px',
  width: '81px',
  viewBox: '0 0 50 50',
  stroke: 'gray-2',
})`
  path {
    stroke-width: 1;
  }
`;

export const DocIconFormatLabel = styled(Text).attrs({
  size: 10,
  color: 'quaternary',
  weight: 'medium',
})`
  position: absolute;
  bottom: 16px;
  left: 22px;
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;

export const StateLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  text-align: center;
  background: ${({theme}) => theme.palette.background.octonary};
  border: 1px solid ${({theme}) => theme.palette.background.octonary};
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 12px;
  padding: 2px 14px;
  color: ${({theme}) => theme.palette.text.octodecimal};
  ${fontPrimaryReg};
  z-index: 1;
  opacity: 1;
`;

export const BackBtn = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;

  ${Media.down.l} {
    padding: 0 18px;
  }
`;
export const Title = styled.div`
  position: relative;
  display: flex;
  gap: 5px 12px;
  align-items: center;

  ${Media.down.l} {
    padding: 0 18px;
    flex-wrap: wrap;

    & > *:first-child {
      flex-basis: 100%;
    }
  }
`;

export const DownloadLink = styled.a`
  position: absolute;
  right: 0;

  ${Media.down.l} {
    right: 24px;
    top: 0;
  }
`;

export const DocumentContainer = styled.div`
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
  }
`;

export const FileInputWrapper = styled.div`
  position: relative;
`;

export const DeleteBtnWrapper = styled.div`
  position: absolute;
  inset: 6px 6px auto auto;
  cursor: pointer;
  z-index: 1;
  line-height: 0;
`;

export const PDFPlaceholderWrapper = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    animation: 2s linear 0s normal none infinite rotate;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const DocNameWrapper = styled.div`
  max-width: 75%;
`;

export const DocName = styled(Text)`
  ${Media.down.m} {
    padding-right: 26px;
    font-size: 23px;
  }
`;
