import React, {useEffect, useMemo, useState} from 'react';
import {route} from '../../constants/routes';
import {getResize} from '../../helpers/file';
import {useLinks} from '../../hooks/common';
import {IReport} from '../../hooks/report';
import {TCompany} from '../../types/company';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {ItemsSkeleton, ItemsSkeletonSection} from '../common/Skeletons/ItemsSkeleton';
import {ItemsDivider} from '../Listings/styles';
import {ItemCard} from './ItemCard';
import {InnerAllItems, InnerTitle, ItemsCardsGrid, SubTitle} from './styles';
import {TActionsOnItems} from './types';

type IProps = {
  communityId?: string;
  userId?: string;
  items?: Array<TCompany>;
  hasMore: boolean;
  title?: string;
  loading?: boolean;
  fetchData: () => void;
  actionsOnItems?: TActionsOnItems;
  onReport: IReport;
  isManager?: boolean;
  isOwner?: boolean;
};

export const ListCompanies: React.FC<IProps> = ({
  communityId,
  fetchData,
  hasMore,
  title,
  loading,
  items,
  actionsOnItems,
  isManager,
  onReport,
  userId,
  isOwner,
}) => {
  const {getLink} = useLinks();

  const lastFeaturedItem = useMemo(() => items?.findIndex((el) => !Boolean(el?.Featured)), [items]);

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (items) {
      setInitialLoad(false);
    }
  }, [items]);

  return (
    <InnerAllItems>
      {title && (
        <InnerTitle>
          <SubTitle>{title}</SubTitle>
        </InnerTitle>
      )}

      <InfiniteScroll
        loadMore={fetchData}
        hasMore={hasMore}
        pageStart={-50}
        loader={initialLoad ? <></> : <ItemsSkeleton />}>
        <ItemsCardsGrid minWidth={280}>
          {loading && !items?.length ? (
            <ItemsSkeletonSection />
          ) : (
            items?.map((el, idx) => {
              const to = isManager
                ? getLink(route.bizViewEdit.get({id: el?.objectId || ''}))
                : getLink(route.bizView.get({id: el?.objectId || ''}));

              return (
                <>
                  {idx !== 0 && idx === lastFeaturedItem ? <ItemsDivider /> : null}

                  <ItemCard
                    key={el.id}
                    to={to}
                    communityId={communityId}
                    item={el}
                    src={getResize(el?.images?.[0]?.file?.url, 'md')}
                    editPath={isManager ? getLink(route.editBiz.get({id: el.objectId || ''})) : ''}
                    isManager={Boolean(isManager)}
                    isOwner={Boolean(isOwner)}
                    userId={userId}
                    actions={actionsOnItems}
                    onReport={onReport}
                    isFeature={idx < (lastFeaturedItem || 0)}
                  />
                </>
              );
            })
          )}
        </ItemsCardsGrid>
      </InfiniteScroll>
    </InnerAllItems>
  );
};
