import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {PageManager} from '../../../components/Events/PageManager';
import {SearchKey} from '../../../constants/routes';
import {GQLEventManageAction, GQLEventOrder, GQLEventWhereInput} from '../../../graphql.schema';
import {getDateHourBorder, getEventTypeParams} from '../../../helpers/event';
import {CommunityStateType} from '../../../states/community';

import {useGetCurrencyValue} from '../../../hooks/currency';
import {
  useEventPageContent,
  useListEvents,
  useResidentActionsEvent,
  useSetEventStatusDeleted,
} from '../../../hooks/event';
import {entities, useReport} from '../../../hooks/report';
import {useUrlSearchManager} from '../../../hooks/urlSearchManager';
import {EventRepeatType} from '../../../queries/types/event';

type TProps = {
  viewerId?: string;
  community?: CommunityStateType;
};

export const PageManagerContainer: React.FC<TProps> = ({viewerId, community}) => {
  const urlSearch = useUrlSearchManager();
  const searchType = urlSearch.get(SearchKey.type);
  const today = getDateHourBorder();

  const [searchParams, setSearchParams] = useState<GQLEventWhereInput[]>([
    !searchType
      ? {
          OR: [
            {
              AND: [
                {
                  endTime: {
                    greaterThan: today,
                  },
                },
                {OR: [{eventType: {equalTo: EventRepeatType.one}}, {eventType: {exists: false}}]},
              ],
            },
            {
              AND: [
                {
                  expiresDate: {
                    greaterThan: today,
                  },
                },
                {eventType: {notEqualTo: EventRepeatType.one}},
                {eventType: {exists: true}},
              ],
            },
          ],
        }
      : getEventTypeParams(searchType),
  ]);

  const location = useLocation();
  const actions = useReport(entities.event);
  const {getSignValue} = useGetCurrencyValue();
  const {
    loadingAllEvents,
    loadingFeaturedEvents,
    refetchAllEvents,
    refetchFeaturedEvents,
    hasMoreAllEvents,
    fetchDataAllEvents,
    communityEvents,
    liked,
    onLike,
  } = useEventPageContent(searchParams, community, true);

  const {
    data: newEvents,
    fetchData: fetchDataNewEvents,
    hasMore: hasMoreNewEvents,
    loading: loadingNewEvents,
    refetch: refetchNewEvents,
  } = useListEvents({
    where: {},
    first: 12,
    order: [GQLEventOrder.startTime_ASC],
  });

  const onSuccess = () => {
    refetchFeaturedEvents();
    refetchAllEvents();
    refetchNewEvents();
  };

  useEffect(() => {
    refetchFeaturedEvents();
    refetchAllEvents();
    refetchNewEvents();
  }, [location]);

  const {onSubmit: onPublishSubmit} = useResidentActionsEvent({
    communityId: community?.objectId,
    onSuccess,
  });
  const onUnPublish = (id?: string) => () => onPublishSubmit({eventId: id, typeBtn: GQLEventManageAction.unpublish});
  const {onDelete} = useSetEventStatusDeleted({onSuccess});
  const onPublish = (id?: string) => () => onPublishSubmit({eventId: id, typeBtn: GQLEventManageAction.publish});

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     communityEvents.length && refetchFeaturedEvents({first: communityEvents.length});
  //     communityEvents.length && refetchAllEvents({first: communityEvents.length});
  //     newEvents.length && refetchNewEvents({first: newEvents.length});
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [communityEvents.length, newEvents.length]);

  return (
    <PageManager
      community={community}
      loading={loadingAllEvents || loadingNewEvents || loadingFeaturedEvents}
      allEvents={communityEvents}
      fetchDataAllEvents={fetchDataAllEvents}
      hasMoreAllEvents={hasMoreAllEvents}
      newEvents={[] || newEvents}
      fetchDataNewEvents={fetchDataNewEvents}
      hasMoreNewEvents={hasMoreNewEvents}
      setSearchParams={setSearchParams}
      userId={viewerId}
      onDelete={onDelete}
      reportActions={actions}
      getSignValue={getSignValue}
      onUnPublish={onUnPublish}
      onPublish={onPublish}
      liked={liked}
      onLike={onLike}
    />
  );
};
