import React, {useMemo} from 'react';
import {PostProps} from '../../components/Feed/Posts/types';
import {Inputs} from '../../components/common/PostInputs';
import {useEditPost} from '../../hooks/feed';
import {inputPostType, PollOptions} from '../../components/common/PostInputs/types';
import {TAttachment, TPost} from '../../types/post';
import {PointerFile} from '../../types/common';

type TProps = {
  disableEditMode: () => void;
  inputType: inputPostType;
  refetch?: (first?: number, cb?: () => void) => void;
  onLocalEdit?: (values: Partial<TPost>) => void;
  resetLocalEdited?: () => void;
} & PostProps;

export const EditPostContainer: React.FC<TProps> = ({
  objectId,
  disableEditMode,
  inputType,
  text,
  type,
  Attachments,
  refetch,
  Author,
  Options,
  hideAttach,
  onLocalEdit,
  resetLocalEdited,
}) => {
  const attachments = useMemo(
    () => Attachments?.map((el) => ({file: el.file, value: el as Partial<PointerFile>} as TAttachment)),
    [Attachments],
  );
  const pollOptions = Options?.map((el, idx) => ({key: idx, value: el}));
  const initialPollOptions = useMemo(
    () => pollOptions?.reduce((acc, el) => ({...acc, [el.key]: {text: el.value?.text}}), {}) as PollOptions,
    [pollOptions],
  );
  const {values, error, onChange, onSubmit} = useEditPost({
    postId: objectId,
    prevAttachments: Attachments,
    pollOptions,
    initialState: {
      text,
      type,
      Attachments: attachments,
    },
    onSuccess: async () => {
      disableEditMode();
      await refetch?.(undefined, resetLocalEdited);
    },
    onLocalEdit,
    onLocalSuccess: () => {
      disableEditMode();
    },
  });
  return (
    <>
      <Inputs
        values={values}
        menuOptions={[]}
        error={error}
        handleChange={onChange}
        handleSubmit={onSubmit}
        resetType={disableEditMode}
        isManager={false}
        inputType={inputType}
        isEdit={true}
        user={{avatar: Author?.Avatar?.file.url}}
        initialPollOptions={initialPollOptions}
        ManagerOrAdmin={false}
        hideAttach={hideAttach}
      />
    </>
  );
};
