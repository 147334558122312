import React from 'react';
import {useTranslation} from 'react-i18next';

import {SettingsHeader} from '../components';
import {CurrentPlanCard} from './CurrentPlanCard';
import {SettingsInvoices} from './SettingsInvoices';
import {SettingsPaymentMethods, SettingsReferralsSection} from './SettingsPaymentMethods';
import {SettingsPayoutsSection} from './SettingsPayoutsSection';
import {StripeInfo} from '../../../queries/payments';
import {PaymentPlan, TCreditCard} from '../../../queries/types/payments';
import {SubscrPayment, Transaction} from '../../../queries/types/transactions';
import {IGetCurrValue} from '../../../types/common';
import {LottieLoader} from '../../Loader/LottieLoader';
import {PageWrapper, SettingsWrapper} from '../commonStyles';

import {useSubscrPayDataT} from '../../../hooks/transactions';
import {GoBack} from '../../common/Buttons/GoBack';

interface PaymentSettingsProps {
  cards?: TCreditCard[];
  payments?: Transaction[];
  subscriptions?: SubscrPayment[];
  promos?: Transaction[];
  stripeInfo?: StripeInfo | null;
  isManager?: boolean;
  promoBalance?: number;
  refetchCards: () => void;
  currentPlan?: PaymentPlan;
  subscrPayData: useSubscrPayDataT;
  refetchSubscr?: () => void;
  activeCardId?: string;
  getAmount: IGetCurrValue;
  paymentsLoading?: boolean;
  referralsLoading?: boolean;
  totalCredits?: number;
  totalReferrals?: number;
}

export const PaymentSettings: React.FC<PaymentSettingsProps> = ({
  cards,
  payments,
  subscriptions,
  promos,
  isManager,
  stripeInfo,
  refetchCards,
  currentPlan,
  refetchSubscr,
  subscrPayData,
  activeCardId,
  getAmount,
  paymentsLoading,
  referralsLoading,
  totalReferrals,
  totalCredits,
}) => {
  const {t} = useTranslation();
  const goBack = () => window?.history.back();
  return (
    <PageWrapper>
      <LottieLoader $isVisible={stripeInfo === null} allScreen={true} />
      <GoBack title={t('settings:payments.title')} goBack={goBack} />
      <SettingsWrapper>
        <SettingsHeader
          title={t('settings:payments.title')}
          hideMob={true}
          subtitle={t('settings:payments.subtitle')}
        />
        {stripeInfo !== null && <SettingsPayoutsSection stripeInfo={stripeInfo} />}
        <SettingsPaymentMethods activeCardId={activeCardId} cards={cards} refetch={refetchCards} />
        <SettingsReferralsSection
          totalCredits={totalCredits}
          totalReferrals={totalReferrals}
          referralsLoading={referralsLoading}
        />
        <SettingsInvoices
          payments={payments}
          refetchSubscr={refetchSubscr}
          subscriptions={subscriptions}
          promos={promos}
          isManager={isManager}
          subscrPayData={subscrPayData}
          getAmount={getAmount}
          paymentsLoading={paymentsLoading}
        />
        {currentPlan && isManager && <CurrentPlanCard currentPlan={currentPlan} />}
      </SettingsWrapper>
    </PageWrapper>
  );
};
