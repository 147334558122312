import React from 'react';
import {inputPostType} from './types';
import {PostFormValues} from '../../../types/post';
import {UserPostInfo} from '../../../types/community';
import {Media} from '../../../ui-kit/theme';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {icon} from '../../../ui-kit/Icon/Svg/types';
import {HiddenElement} from '../Hidden/HiddenElement';
import {FileInput} from './components/FileInput';
import {FileInputWrapper, InnerMediaBtn, MediaPanelWrapper} from '../../Loop/PostCreate/styles';
import {TypeCommunity} from '../../../types/auth';

type TButtonData = {
  iconType: string;
  postType: inputPostType;
  isFileInput?: boolean;
};

type TButton = {
  iconType: string;
  callback?: () => void | React.ChangeEventHandler<HTMLInputElement>;
  active?: boolean;
  handleChangePostType?: (t: inputPostType) => void;
  postType?: inputPostType;
};

const dataButtons: TButtonData[] = [
  {
    iconType: 'sticker',
    postType: inputPostType.message,
  },
  {
    iconType: 'clipboard',
    postType: inputPostType.announcement,
  },
  {
    iconType: 'previous',
    postType: inputPostType.request,
  },
  {
    iconType: 'poll',
    postType: inputPostType.poll,
  },
  /*{
    iconType: 'video',
    postType: inputPostType.video,
  }*/
];

const dataGroupButtons: TButtonData[] = [
  {
    iconType: 'sticker',
    postType: inputPostType.message,
  },
  {
    iconType: 'poll',
    postType: inputPostType.poll,
  },
];

export const MediaButton: React.FC<TButton> = ({iconType, active, handleChangePostType, postType}) => {
  const handleOnClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event?.preventDefault();
    postType && handleChangePostType?.(postType);
  };

  return (
    <InnerMediaBtn onClick={handleOnClick}>
      <IconSvg
        type={iconType as icon}
        stroke={active ? 'gray-2' : 'grayLight-2'}
        width={'22px'}
        height={'22px'}
        viewBox={'0 0 22 22'}
      />
    </InnerMediaBtn>
  );
};

interface IFileLoader {
  handleFile?: React.ChangeEventHandler<HTMLInputElement>;
  values?: PostFormValues;
  filesLimit: number;
}

const FileLoader: React.FC<IFileLoader> = ({handleFile, values, filesLimit}) => {
  return (
    <FileInputWrapper>
      <FileInput
        handleChange={handleFile}
        btnDisabled={(values?.Attachments?.length && values?.Attachments?.length >= filesLimit) || false}
      />
      <IconSvg type={'pin'} stroke={'grayLight-2'} width={'22px'} height={'22px'} viewBox={'0 0 22 22'} />
    </FileInputWrapper>
  );
};

interface IProps {
  handleFile?: React.ChangeEventHandler<HTMLInputElement>;
  handleChangePostType?: (t: inputPostType) => void;
  activeType?: inputPostType;
  values?: PostFormValues;
  filesLimit?: number;
  user?: UserPostInfo;
  typeUser?: TypeCommunity;
  isGroup?: boolean;
}

export const MobileMediaPanel: React.FC<IProps> = ({
  handleChangePostType,
  activeType,
  handleFile,
  filesLimit,
  values,
  user,
  typeUser,
  isGroup,
}) => {
  const getButtons = () => {
    if (isGroup) return dataGroupButtons;
    if (typeUser !== TypeCommunity.resident) {
      if (!user?.isManager) return dataButtons.filter((b) => b.postType !== inputPostType.announcement);
      return dataButtons;
    }
    return [];
  };
  const userDataButtons: TButtonData[] = getButtons();
  return (
    <HiddenElement mq={Media.up.l}>
      <MediaPanelWrapper>
        {userDataButtons.map((el) => (
          <MediaButton
            iconType={el.iconType}
            postType={el.postType}
            key={el.postType}
            handleChangePostType={handleChangePostType}
            active={activeType === el.postType}
          />
        ))}
        {handleFile && <FileLoader handleFile={handleFile} filesLimit={filesLimit || 0} values={values} />}
      </MediaPanelWrapper>
    </HiddenElement>
  );
};
