import React from 'react';
import {TypeCommunity} from '../../types/auth';
import {CommunityPeople} from '../../components/People/CommunityPeople';
import {NavContainer} from '../Navigation';
import {User} from '../../queries/types/user';
import {useGetAdminsLabels, useGetCommunityPeople} from '../../hooks/people';
import {CommunityStateType} from '../../states/community';
import {ItemsSearchParams, SetItemsSearchParams} from './types';
import {PeopleSearchType} from '../../types/people';
import {entities, useReport} from '../../hooks/report';
import {EmptyTag} from '../MobileTabs';

type Props = {
  user: User | null;
  searchParams: ItemsSearchParams;
  setSearchParams: SetItemsSearchParams;
  setPeopleType: React.Dispatch<React.SetStateAction<PeopleSearchType>>;
  loading?: boolean;
  peopleType: PeopleSearchType;
  community?: CommunityStateType;
  isAdmin?: boolean;
  hideNav?: boolean;
  typeUser?: TypeCommunity;
  canInvite?: boolean;
  canManage?: boolean;
};

export const CommunityPeoplePage: React.FC<Props> = ({
  user,
  searchParams,
  setSearchParams,
  loading,
  peopleType,
  setPeopleType,
  community,
  canManage,
  canInvite,
  hideNav,
  typeUser,
}) => {
  const {
    data: members,
    loading: loadingPeople,
    refetch,
    fetchMore,
    hasNextPage,
  } = useGetCommunityPeople({
    id: community?.id,
    where: searchParams,
    peopleType,
  });
  //const membersItemsCount = useMembersListingsCount(members?.AllMembers, community?.objectId, community?.__typename);
  const adminLabels = useGetAdminsLabels();
  const Wrapper = hideNav ? EmptyTag : NavContainer;
  const actions = useReport(entities.user);
  const loadMore = () => {
    fetchMore({
      variables: {
        skip: members?.Residents?.length,
      },
    });
  };
  return (
    <Wrapper>
      <CommunityPeople
        typeUser={typeUser}
        user={user}
        members={members}
        loading={loadingPeople || loading}
        setSearchParams={setSearchParams}
        peopleType={peopleType}
        setPeopleType={setPeopleType}
        //membersItemsCount={membersItemsCount}
        community={community}
        reportActions={actions}
        refetch={refetch}
        adminLabels={adminLabels}
        canInvite={canInvite}
        canManage={canManage}
        loadMore={loadMore}
        hasNextPage={hasNextPage}
      />
    </Wrapper>
  );
};
