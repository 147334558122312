import {gql} from '@apollo/client';
import {Connection} from './types/parse';
import {Notification, NotificationSettingsType} from './types/notifications';
import NotificationFragment, {NotificationSettingsFragment} from './fragments/notifications';
import {GQLCreateNotificationFieldsInput} from '../graphql.schema';

export interface GetNotificationResponse {
  notifications: Connection<Notification>;
}

export const GetNotifications = gql`
  query getNotifications(
    $where: NotificationWhereInput
    $skip: Int
    $cursor: String
    $first: Int
    $order: [NotificationOrder!]
  ) {
    notifications(where: $where, skip: $skip, after: $cursor, first: $first, order: $order) {
      count
      edges {
        node {
          ...NotificationFragment
        }
      }
    }
  }

  ${NotificationFragment}
`;

export interface UpdateNotificationRequest {
  input: {
    id: string;
    fields: Partial<Notification>;
  };
}

export interface UpdateNotificationResponse {
  updateNotification: {
    notification: Notification;
  };
}

export const UpdateNotification = gql`
  mutation updateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      notification {
        ...NotificationFragment
      }
    }
  }

  ${NotificationFragment}
`;

export interface NewViewerRequest {
  input: {
    id: string;
    objectType: 'User' | 'Item' | 'Event' | 'Amenity';
  };
}

export interface NewViewerResponse {
  newViewer: {
    success: boolean;
  };
}

export const NewViewer = gql`
  mutation addViewer($input: ViewObjectInp!) {
    newViewer(input: $input) {
      success
    }
  }
`;

export interface GetNotificationSettingsRequest {
  id: string;
}

export interface GetNotificationSettingsResponse {
  user: NotificationSettingsType;
}

export const GetNotificationSettings = gql`
  query getNotificationSettings($id: ID!) {
    user(id: $id) {
      ...NotificationSettingsFragment
    }
  }

  ${NotificationSettingsFragment}
`;

export interface ArchiveNotificationsRequest {
  input: {
    communityId: string;
  };
}

export const ArchiveNotifications = gql`
  mutation archiveNotifications($input: ArchiveNotificationsInp!) {
    archiveNotifications(input: $input)
  }
`;

export interface TCreateNoficationResponse {
  createNotification: {
    notification: Notification;
  };
}

export interface TCreateNoficationRequest {
  fields: GQLCreateNotificationFieldsInput;
}

export const CreateNoficationQuery = gql`
  mutation createNotification($fields: CreateNotificationFieldsInput) {
    createNotification(input: {fields: $fields}) {
      notification {
        ...NotificationFragment
      }
    }
  }
  ${NotificationFragment}
`;
