import {atom, DefaultValue, selector} from 'recoil';
import {KeyMode} from '../ui-kit/theme';
import Cookies from 'js-cookie';

const cookiesTheme = {
  get: () => {
    return Cookies.get('theme');
  },
  set: (value: string | DefaultValue) => {
    Cookies.set('theme', value, {expires: 365});
  },
};

const getInitState = () => {
  const mode = cookiesTheme.get();
  const defaultMode = KeyMode.light;

  if (!mode) {
    cookiesTheme.set(defaultMode);
  }

  return mode || defaultMode;
};

export type ThemeModeStateType = string;
export const themeModeState = atom<ThemeModeStateType>({
  key: 'themeMode',
  default: getInitState(),
});

export const modeState = selector<string>({
  key: 'mode',
  get: ({get}) => {
    return get(themeModeState);
  },
  set: ({set}, newValue) => {
    cookiesTheme.set(newValue);
    set(themeModeState, newValue);
  },
});

export const isModal = atom<boolean>({
  key: 'isModal',
  default: false,
});
