import React from 'react';
import {
  Wrapper,
  BlockWrapper,
  TextBottom,
  EntryLinkWrapper,
  Title,
  TextMenuBlock,
  EntryOutsideLinkWrapper,
} from './styles';
import {useTranslation} from 'react-i18next';
import {helpCentreLinks} from '../../../../constants/links';
import {routes} from '../../../../containers/Navigation/constans';
import {Copyrights} from '../Settings/components';

interface HelpCentreLinks {
  guide: string;
  sendMessage: string;
  feedback: string;
  reportBug: string;
  whatsNew: string;
  terms: string;
}

interface TProps {
  active: boolean;
  nameCommunity?: string;
  close: () => void;
}

export const HelpCentre: React.FC<TProps> = ({active, nameCommunity, close}) => {
  const {t} = useTranslation();
  const links: HelpCentreLinks = helpCentreLinks;

  if (!active) return null;

  return (
    <Wrapper onClick={close}>
      <BlockWrapper borderBottom={true}>
        <EntryLinkWrapper to={routes?.paymentsSettings}>
          <TextMenuBlock>
            <Title>{t('settings:popup.payments.title')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
        <EntryLinkWrapper to={routes?.preferencesSettings}>
          <TextMenuBlock>
            <Title as={'span'}>{t('settings:popup.preferences.title')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
        <EntryLinkWrapper to={routes?.accountSettings}>
          <TextMenuBlock>
            <Title>{t('settings:popup.accountSettings.title')}</Title>
          </TextMenuBlock>
        </EntryLinkWrapper>
        <EntryOutsideLinkWrapper href={links.terms} target={'_blank'}>
          <TextMenuBlock>
            <Title>{t('common:helpCentre.terms')}</Title>
          </TextMenuBlock>
        </EntryOutsideLinkWrapper>
        <EntryOutsideLinkWrapper href={links.whatsNew} target={'_blank'}>
          <TextMenuBlock>
            <Title>{t('common:helpCentre.whatsNew')}</Title>
          </TextMenuBlock>
        </EntryOutsideLinkWrapper>
        {nameCommunity && (
          <EntryOutsideLinkWrapper>
            <TextMenuBlock>
              <TextBottom as={'span'}>{nameCommunity}</TextBottom>
            </TextMenuBlock>
          </EntryOutsideLinkWrapper>
        )}
        {/*<EntryOutsideLinkWrapper>
          <TextMenuBlock>
            <TextBottom as={'span'}>Hangeh: {HANGEH_VERSION}</TextBottom>
          </TextMenuBlock>
        </EntryOutsideLinkWrapper>*/}
      </BlockWrapper>
      <Copyrights />
    </Wrapper>
  );
};
