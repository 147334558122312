import {useCallback, useEffect, useState} from 'react';
import Parse from 'parse';
import {useHistory} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import {useGetSupportData} from './helpDesk';
import {useFetchOrder} from './order';
import {toPOJO, useLiveQuery} from './parse';
import {useViewer, useViewerId} from './user';
import {GQLMessageWhereInput, GQLUserOrder} from '../graphql.schema';
import {getObjByCondition} from '../helpers/common';
import {
  checkLastOrderIsReady,
  getContactsList,
  getCountUnreadMsgs,
  getDataWithAvatars,
  sortDescByDate,
  toCreateLocalMessage,
  toPointerMessage,
} from '../helpers/message';
import {isString} from '../helpers/validations';
import {CreateAppFile, CreateFileResponseType} from '../queries/file';
import {
  CreateMessage,
  CreateMessageRequestType,
  CreateMessageResponseType,
  getMessagesWithUser,
  GetMessagesWithUserResponse,
  SetSeenMessage,
  SetSeenMsgRequestType,
  SetSeenMsgResponseType,
} from '../queries/message';
import {GetUserRequest, GetUserResType} from '../queries/people';
import {Message, TChat} from '../queries/types/message';
import {Order} from '../queries/types/order';
import {
  GetUsersCommunities,
  GetUsersCommunitiesResponse,
  SetOnline,
  UpdateProfile,
  UpdateProfileResponseType,
} from '../queries/user';
import {typeUser} from '../states/typeUser';
import {
  AppFileFields,
  ChatFields,
  CreateMsgFields,
  ItemFields,
  MessageData,
  MessageFields,
  OrderFields,
  OrderItem,
  TContact,
  TOrder,
  UserChat,
  UserFields,
} from '../types/messages';
import {useGetResidents} from './residents';
import debounce from 'lodash.debounce';
import {analyticsTrackFN} from '../helpers/account';
import {currentCommunity, onlineUsers} from '../states/community';
import {messageHints} from '../states/preload';
import {RewardType} from '../queries/types/item';
import {User} from '../queries/types/user';
import {SupportName} from '../constants/common';
import {useTranslation} from 'react-i18next';
import {getInboxHints} from '../helpers/user';
import {format} from 'date-fns';
import {PointerFile} from '../types/common';
import {toPointer} from '../helpers/parse';

export const mapAppFileParseObject = (object: Parse.Object): PointerFile => {
  return toPOJO<PointerFile>(Object.values(AppFileFields), object, {
    file: (item: Parse.File | undefined): PointerFile['file'] => {
      if (!item) return {name: '', url: ''};
      return {name: item.name(), url: item.url()};
    },
  });
};

export const mapUsersParseObject = (object: Parse.Object): UserChat => {
  return toPOJO<UserChat>(Object.values(UserFields), object, {
    Avatar: (item: Parse.Object | undefined): UserChat['Avatar'] => {
      if (!item) return;
      return mapAppFileParseObject(item);
    },
  });
};
export const mapItemParseObject = (object: Parse.Object): OrderItem => {
  return toPOJO<OrderItem>(Object.values(ItemFields), object);
};

export const mapChatParseObject = (object: Parse.Object): TChat => {
  return toPOJO<TChat>(Object.values(ChatFields), object);
};
export const mapMessageParseObject = (object: Parse.Object): Message => {
  return toPOJO<Message>(Object.values(MessageFields), object, {
    Author: (item: Parse.Object | undefined): Message['Author'] => {
      if (!item) return;
      return mapUsersParseObject(item);
    },
    Attachments: (items: Parse.Object[]): Message['Attachments'] => {
      return items?.map(mapAppFileParseObject);
    },
    ShowTo: (items: Parse.Object[]): Message['ShowTo'] => {
      return items?.map(mapUsersParseObject);
    },
    Seen: (items: Parse.Object[]): Message['Seen'] => {
      return items?.map(mapUsersParseObject);
    },
    Chat: (item: Parse.Object): Message['Chat'] => {
      if (!item) return;
      return mapChatParseObject(item);
    },
    Order: (item: Parse.Object | undefined): Message['Order'] => {
      if (!item) return;
      return toPOJO<TOrder>(Object.values(OrderFields), item, {
        Lister: (item: Parse.Object | undefined): TOrder['Lister'] => {
          if (!item) return;
          return mapUsersParseObject(item);
        },
        Requester: (item: Parse.Object | undefined): TOrder['Lister'] => {
          if (!item) return;
          return mapUsersParseObject(item);
        },
        Item: (item: Parse.Object | undefined): TOrder['Item'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
        Event: (item: Parse.Object | undefined): TOrder['Event'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
        Amenity: (item: Parse.Object | undefined): TOrder['Amenity'] => {
          if (!item) return;
          return mapItemParseObject(item);
        },
      });
    },
  });
};

export const useGetMessages = (commId?: string, toUser?: string, preload?: Message[]) => {
  const viewerObjectId = useViewerId('objectId');
  const {supportUser} = useGetSupportData();
  const [queryMsg, setQueryMsg] = useState<Parse.Query>();
  const [localMessages, setLocalMessages] = useState<Message[]>(preload || []);
  const [refsToDelete, setRefToDelete] = useState<string[]>([]);
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapMessageParseObject(object);
    },
    [queryMsg],
  );

  const {data, refetch, loading, unsubscribe, handleSubscribe} = useLiveQuery<Message>({
    query: queryMsg as Parse.Query,
    map: memoMap,
    // onFailure,
  });
  useEffect(() => {
    if (!viewerObjectId) return unsubscribe();
    if (!commId) return;
    setLocalMessages([]);
    const user = new Parse.User();
    const supUser = new Parse.User();
    const community = new Parse.Object('Community');
    user.id = viewerObjectId;
    community.id = commId;
    supUser.id = supportUser?.objectId || '';
    const queryMsg = Parse.Query.or(
      new Parse.Query('Message').equalTo('Community', community),
      new Parse.Query('Message').doesNotExist('Community'),
    )
      .equalTo('ShowTo', user)
      .notEqualTo('type', 'internalNote')
      .include([
        'Attachments',
        'Author.Avatar',
        'Chat',
        'ShowTo',
        'Seen.User',
        'Order.Item',
        'Order.Event',
        'Order.Amenity',
      ])
      .addAscending('createdAt')
      .limit(20000);
    setQueryMsg(queryMsg);
  }, [viewerObjectId, commId]);

  const messagesData = Object.values(data || {});
  const lastMessage = [...messagesData]?.pop();
  const lastOrderReady = checkLastOrderIsReady(lastMessage);
  useEffect(() => {
    if (!messagesData?.length) return;
    if (!refsToDelete?.length) {
      setLocalMessages(messagesData);
    } else {
      setLocalMessages((prev) => [
        ...prev,
        ...messagesData?.filter((el) => {
          if (prev?.some((l) => l?.objectId === el?.objectId)) return false; //same ids
          if (!el?.localRef) return true; // other messages
          if (refsToDelete.includes(el.localRef)) return false; //check if local
          return true;
        }),
      ]);
    }
  }, [messagesData?.length, lastOrderReady]);
  const addLocalmessage = (message: Message) => {
    if (!message?.localRef) return;
    setRefToDelete((prev) => [...prev, message.localRef || '']);
    setLocalMessages((prev) => [...prev, message]);
  };
  const deleteLocalRefs = () => {
    setRefToDelete([]);
    setLocalMessages(messagesData);
  };
  const setDelivered = (ref: string) => {
    return ref;
    // setLocalMessages((prev) => {
    //   return prev?.map((el) => (el?.localRef === ref ? {...el, localStatus: MsgStatus.delivered} : el));
    // }); //hard function
  };

  return {
    data: localMessages,
    refetch,
    loading,
    addLocalmessage,
    deleteLocalRefs,
    setDelivered,
    unsubscribe,
    handleSubscribe,
    supportUser,
  };
};

export const useGetContactsForChat = (params: {
  initUsers?: UserChat[] | null;
  msgs?: Message[];
  loading?: boolean;
  contactsIds?: string[] | [];
  supportUser?: User;
  commId?: string;
}) => {
  const viewerId = useViewerId('objectId');
  const [userType] = useRecoilState(typeUser);
  const [query, setQuery] = useState<Parse.Query>();
  const usersOnline = useRecoilValue(onlineUsers);
  const [contacts, setContacts] = useState<TContact[] | null | undefined>();
  const history = useHistory();
  const {t} = useTranslation();
  const excludeFields = [
    'Communities',
    'Currency',
    'Orders',
    'Reviews',
    'Viewers',
    'address',
    'aptSuite',
    'bio',
    'birthDate',
    'earned',
    'emailVerified',
    'gender',
    'homepage',
    'howDidYouHear',
    'isVerified',
    'languages',
    'phone',
    'spent',
    'status',
    'stripeId',
    'study',
    'username',
    'work',
    'zip',
  ];

  useEffect(() => {
    if (!params?.contactsIds?.length) return;
    const query = new Parse.Query(Parse.User)
      .include(['objectId', 'isOnline', 'Avatar', 'firstName', 'firstLetter', 'isSupport'])
      .exclude(...excludeFields)
      .containedIn('objectId', params.contactsIds);
    setQuery(query);
  }, [params?.contactsIds?.length]);

  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapUsersParseObject(object);
    },
    [query],
  );
  const {
    data,
    refetch,
    loading: loadingLQ,
    unsubscribe,
  } = useLiveQuery<UserChat>({
    query: query as Parse.Query,
    map: memoMap,
    // onFailure,
  });
  useEffect(() => {
    if (!params?.initUsers?.length) return;
    setContacts(
      // eslint-disable-next-line
      // @ts-ignore
      getDataWithAvatars(
        Object.values(data || {}),
        getContactsList(params?.initUsers, viewerId, params?.msgs, userType, t, usersOnline),
      ),
    );
  }, [data, params?.initUsers?.length, params?.contactsIds?.[0], params?.msgs?.length, history.location.pathname]);
  useEffect(() => {
    if (!viewerId) unsubscribe();
  }, [viewerId]);
  useEffect(() => {
    const dataUsers = Object.values(data || {});
    dataUsers?.forEach((userLQ) => {
      contacts?.forEach((user, index) => {
        if (userLQ.objectId !== user.objectId) return;
        const isGotOnline = !user.isOnline && userLQ.isOnline;
        const isLostOnline = user.isOnline && !userLQ.isOnline;
        if (!isGotOnline && !isLostOnline) return;
        const copyUsers = [...contacts];
        copyUsers[index] = {...user, isOnline: userLQ.isOnline};
        setContacts(copyUsers);
      });
    });
  }, [data, contacts]);

  const changeContacts = contacts?.map((contact) => {
    if (params.supportUser?.objectId !== contact.objectId) return contact;
    return {...contact, name: SupportName};
  });
  const isPrevContacts = !!changeContacts?.length && params?.contactsIds?.length !== changeContacts?.length;
  const loading = params.loading || loadingLQ || (isPrevContacts && !!params?.contactsIds?.length);
  return {contacts: isPrevContacts ? [] : changeContacts, refetch, loading};
};

export type TState = Omit<MessageData, 'ShowTo'> & {
  ShowTo?: string;
  receiverName?: string;
  receiverEmail?: string;
};

type UseCreateMessageType = (options: {
  initialState: TState;
  onSuccess?: () => void;
  idActiveContact?: string;
  communityId?: string;
  contactIsSupport?: boolean;
  idActiveChat?: string;
  addLocalmessage?: (message: Message) => void;
  activeContact?: TContact | null;
  setDelivered?: (localRef: string) => void;
  prepend?: string;
}) => {
  onSubmit: () => void;
  loading: boolean;
  success: boolean;
  values: TState;
  onChange: (params: {name: CreateMsgFields; value: string | File[] | null}) => void;
  deleteImage: (index: number) => void;
};

export const useCreateMessage: UseCreateMessageType = (options) => {
  const viewer = useViewer();
  const community = useRecoilValue(currentCommunity);
  const [values, setValues] = useState(options?.initialState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [receiver, setReceiver] = useState(options?.activeContact);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [CreateMessageRequest, {loading: requestLoading}] = useMutation<
    CreateMessageResponseType,
    CreateMessageRequestType
  >(CreateMessage);

  useEffect(() => {
    if (!isString(options?.idActiveContact)) return;
    setValues((prev) => ({...prev, ShowTo: options?.idActiveContact, Attachments: []}));
  }, [options?.idActiveContact]);

  const handleChange = (params: {name: CreateMsgFields; value: string | File[] | null}) => {
    setSuccess(false);
    if (params.name === CreateMsgFields.Attachments) {
      return setValues((prev) => ({
        ...prev,
        Attachments: [...((prev.Attachments as File[]) || []), ...(params.value as File[])],
      }));
    }
    return setValues((prev) => ({...prev, [params.name]: params.value}));
  };
  useEffect(() => {
    if (!options?.activeContact?.name) return;
    setReceiver(options?.activeContact);
  }, [options?.activeContact?.name]);

  const deleteImage = (index: number) => {
    const newImgs = (values?.Attachments as File[])?.filter((it, inx) => inx !== index);
    setValues((prev) => ({...prev, Attachments: newImgs}));
  };

  const createMessage = async (text?: string): Promise<void> => {
    try {
      setLoading(true);
      if (!values?.ShowTo || (!values?.text && !values?.Attachments?.length && !text) || !viewer) return;
      const parseImages = await Promise.all(
        (values?.Attachments ?? [])?.map(async (file) => {
          const result = await createAppFile({
            variables: {
              fields: {
                file: {upload: file},
                Owner: {link: viewer.objectId},
              },
            },
          });
          return toPointer(result.data?.createAppFile?.appFile);
        }),
      );

      const textWithPrepend = options?.prepend
        ? `${options?.prepend}${text || values?.text || ''}`
        : text || values?.text || '';
      const fields = toPointerMessage({
        ...values,
        text: textWithPrepend,
        Author: viewer.objectId,
        ShowTo: values.ShowTo,
        Attachments: parseImages,
        Community: options?.contactIsSupport ? undefined : options?.communityId,
      });
      setValues((prev) => ({...prev, text: '', Attachments: []}));

      const localRef = !values?.Attachments?.length ? String(new Date().getTime()) : '';
      if (localRef && text) {
        const locMessage = toCreateLocalMessage(text, localRef, options?.activeContact, viewer);
        options?.addLocalmessage?.(locMessage);
      }
      const result = await CreateMessageRequest({
        variables: {
          fields: {
            localRef,
            ...fields,
            ...getObjByCondition(Boolean(options?.idActiveChat), {
              Chat: {
                link: options?.idActiveChat,
              },
            }),
          },
        },
      });

      const message = result?.data?.createMessage?.message;
      if (!message) throw new Error();
      setSuccess(true);
      options?.setDelivered?.(localRef);
      analyticsTrackFN('Message Sent', {
        messageId: message?.objectId,
        senderName: `${viewer?.firstName} ${viewer?.lastName}`,
        senderEmail: viewer?.email,
        senderId: viewer?.objectId,
        receiverName: receiver?.name || values?.receiverName,
        receiverEmail: values?.receiverEmail,
        receiverId: values?.ShowTo,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type, // building, neighbourhood, circle
        includePhoto: Boolean(message?.Attachments?.length), // true if message contained photos
        messageLenght: message?.text?.length, // message lenght
      });
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(false);
      console.log(error);
      return;
    } finally {
      setLoading(requestLoading);
    }
  };

  return {
    values,
    onSubmit: createMessage,
    success,
    loading,
    onChange: handleChange,
    deleteImage,
  };
};

type UseUpdateUserStatus = (options: {onSuccess?: () => void}) => {
  setOnlineUser: () => Promise<boolean>;
  loading: boolean;
};

export const useSearchCommunityMember = (viewerId?: string) => {
  const [searchText, setSearchText] = useState('');
  const {
    data: members,
    loading,
    fetchData,
    hasMore,
    refetch,
  } = useGetResidents({
    first: 25,
    order: GQLUserOrder.firstName_ASC,
  });

  const fetch = (searchText: string) => {
    if (!searchText) {
      refetch({
        where: {},
      });
      return;
    }
    refetch({
      where: {
        firstName: {
          matchesRegex: searchText?.toLocaleLowerCase?.() ?? '',
          options: 'i',
        },
      },
    });
  };

  const debounceFetch = useCallback(debounce(fetch, 1000), []);

  const setPeopleSearchText = (searchText: string) => {
    setSearchText(searchText);
    debounceFetch(searchText);
  };

  const clearSearchText = () => setPeopleSearchText('');

  return {
    setPeopleSearchText,
    searchText,
    members: members?.filter((item) => item?.objectId !== viewerId),
    loading,
    hasMore,
    fetchData,
    clearSearchText,
  };
};

export const useSetOnlineUser: UseUpdateUserStatus = (options) => {
  const [setOnline, {loading}] = useMutation<boolean>(SetOnline);

  const setOnlineUser = async (): Promise<boolean> => {
    try {
      const result = await setOnline();
      if (!result) return false;
      options?.onSuccess?.();
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  return {
    setOnlineUser,
    loading,
  };
};

type UseSeenMessageType = (options: {onSuccess?: () => void}) => {
  setSeenMessage: (params: {seen: string[]; msg: Message}) => void;
  loading: boolean;
};

export const useSetSeenMessage: UseSeenMessageType = (options) => {
  const viewer = useViewer();
  const [community] = useRecoilState(currentCommunity);
  const [UpdateMessageRequest, {loading}] = useMutation<SetSeenMsgResponseType, SetSeenMsgRequestType>(SetSeenMessage);
  const setSeenMessage = async (params: {seen: string[]; msg: Message}): Promise<boolean | void> => {
    try {
      if (!params?.seen?.length) return;

      const result = await UpdateMessageRequest({
        variables: {messageIds: params.seen},
      });

      const res = result?.data?.messageSetSeen;
      analyticsTrackFN('Message Seen', {
        messageId: params?.seen?.[0],
        senderName: params?.msg?.Author?.firstName,
        senderId: params?.msg?.Author?.objectId,
        receiverName: viewer?.firstName,
        receiverEmail: viewer?.email,
        receiverId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle
        includePhoto: Boolean(params?.msg?.Attachments?.length), // true if message contained photos
        messageLenght: params?.msg?.text?.length, // message lenght
        timeSent: params?.msg?.createdAt, // when the message was sent
        timeSeen: new Date(), // when seen
      });
      if (!res) throw new Error();
      options?.onSuccess?.();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  return {
    setSeenMessage,
    loading,
  };
};

type useActiveContactOptions = {
  contactId?: string;
  contacts?: TContact[] | null;
  supportUser?: User;
  unsetOrder?: () => void;
  orderUsers?: string[];
};

export const useActiveContact = ({
  contactId,
  contacts,
  supportUser,
  unsetOrder,
  orderUsers,
}: useActiveContactOptions) => {
  const [activeContact, setActiveContact] = useState<TContact | null>();

  useEffect(() => {
    if (!contactId || !contacts?.length) return;
    const activeC = contacts?.find((it) => it?.objectId === contactId);
    if (!orderUsers?.includes(contactId)) unsetOrder?.();
    if (supportUser?.objectId !== contactId) return activeC && setActiveContact(activeC);
    activeC && setActiveContact({...activeC, name: SupportName});
  }, [contactId, contacts?.length, contacts?.[contacts?.length - 1]?.objectId, orderUsers, contacts?.[0]?.avatar]);

  return [activeContact, setActiveContact] as const;
};

export type TResActiveOrder = {
  activeOrder: Order | null;
  setActiveOrder: (id?: string | null) => void;
  clearActiveOrder: () => void;
  refetchActiveOrder?: () => void;
};

export const useActiveOrder = (orders?: Order[] | null, contactId?: string): TResActiveOrder => {
  const [activeOrder, setActiveOrder] = useState<Order | null>(null);
  const fetchOrder = useFetchOrder();
  const actualOrderStatus = orders?.find((el) => el?.objectId === activeOrder?.objectId)?.status;
  const handleActiveOrder = (id?: string | null) => {
    const activeC = orders?.find((it) => it?.objectId === id);

    if (activeC) {
      setActiveOrder(activeC);
    } else {
      if (id) {
        fetchOrder(id).then((order) => {
          setActiveOrder(order || null);
        });
      }
    }
  };

  const clearActiveOrder = () => {
    setActiveOrder(null);
  };

  useEffect(() => {
    if (!orders?.length || !activeOrder) return;
    handleActiveOrder(activeOrder.objectId);
  }, [orders?.length]);

  useEffect(() => {
    setActiveOrder(null); //clear order when switch contact
  }, [contactId]);

  useEffect(() => {
    if (actualOrderStatus && activeOrder && actualOrderStatus !== activeOrder.status) {
      setActiveOrder({...activeOrder, status: actualOrderStatus});
    }
  }, [actualOrderStatus]);

  return {activeOrder, setActiveOrder: handleActiveOrder, clearActiveOrder};
};

export const useUnreadMessages = (commId?: string) => {
  const viewerObjectId = useViewerId('objectId');
  const [queryMsg, setQueryMsg] = useState<Parse.Query>();
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapMessageParseObject(object);
    },
    [queryMsg],
  );
  const {data, unsubscribe} = useLiveQuery<Message>({
    query: queryMsg as Parse.Query,
    map: memoMap,
  });
  useEffect(() => {
    if (!viewerObjectId || !commId) return;
    const user = new Parse.User();
    const community = new Parse.Object('Community');
    user.id = viewerObjectId;
    community.id = commId;
    const queryMsg = Parse.Query.or(
      new Parse.Query('Message').equalTo('Community', community),
      new Parse.Query('Message').doesNotExist('Community'),
    )
      .notEqualTo('type', 'internalNote')
      .equalTo('ShowTo', user)
      .notEqualTo('Author', user)
      .exclude('Attachments', 'Order', 'text');
    setQueryMsg(queryMsg);
  }, [viewerObjectId, commId]);
  useEffect(() => {
    if (!viewerObjectId) unsubscribe();
  }, [viewerObjectId]);
  return {count: getCountUnreadMsgs(Object.values(data || {}), viewerObjectId)};
};

export const useContacts = (msgs?: Message[], viewerId?: string, activeId?: string, commId?: string) => {
  const {supportUser} = useGetSupportData();
  const [activeUser, setActiveUser] = useState<UserChat | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();
  const mapping: Record<string, boolean> = {};
  const contacts: UserChat[] = [];
  const contactsIds: string[] = [];

  const getActiveContact = async () => {
    try {
      setLoading(true);
      const {data} = await client.query<GetUserResType>({
        query: GetUserRequest,
        variables: {
          id: activeId || '',
        },
      });

      if (data?.user) {
        setActiveUser({
          objectId: data.user.objectId,
          Avatar: data.user.Avatar,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          firstLetter: data.user.firstLetter,
          isOnline: data.user.isOnline,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!activeId?.trim()) return;
    if (contacts?.find((it) => it.objectId === activeId)) return;
    getActiveContact();
  }, [activeId]);

  sortDescByDate(msgs, 'createdAt')?.forEach((it) => {
    const user = (it.ShowTo || [])?.find((el) => el?.objectId !== viewerId);
    if (!user?.objectId) return;
    if (!mapping[user.objectId]) {
      mapping[user.objectId] = true;
      contacts.push(user);
      contactsIds.push(user.objectId);
    }
  });
  const {loading: loadingCheck, checkedContactsIds} =
    useCheckContacts({contactsIds, commId, usersCount: contactsIds?.length}) || [];
  const checkedContacts = contacts?.filter((el) => checkedContactsIds?.includes(el.objectId));
  if (supportUser?.objectId && contactsIds?.includes(supportUser?.objectId)) {
    if (!loadingCheck) {
      checkedContactsIds.push(supportUser?.objectId);
      checkedContacts?.push(supportUser);
    }
  }
  if (activeUser && !contactsIds?.includes(activeUser.objectId)) {
    return {
      contacts: [activeUser, ...checkedContacts],
      contactsIds: [activeUser.objectId, ...checkedContactsIds],
    };
  }
  // useEffect(() => {
  //   contacts = [];
  //   contactsIds = [];
  // }, [commId]);
  return {
    loading: loadingCheck || loading,
    contacts: checkedContacts,
    contactsIds: checkedContactsIds,
  };
};

type useCheckContactsOptions = {
  contactsIds: string[];
  commId?: string;
  usersCount?: number;
};

export const useCheckContacts = (options: useCheckContactsOptions) => {
  const {data: response, loading} = useQuery<GetUsersCommunitiesResponse>(GetUsersCommunities, {
    skip: !options.contactsIds?.length,
    variables: {
      where: {
        objectId: {
          in: options.contactsIds,
        },
        Communities: {
          have: {
            objectId: {equalTo: options.commId},
          },
        },
      },
      usersFirst: options?.usersCount,
    },
  });
  const checkedContactsIds = (response?.users?.edges?.map((el) => el.node.objectId as string) as string[]) || [];
  return {checkedContactsIds, loading};
};

export const useCheckUserHasMessages = (selfId?: string, userId?: string, skip?: boolean) => {
  const where: GQLMessageWhereInput = {Author: {have: {objectId: {in: [selfId || '', userId || '']}}}};

  const data = useQuery<GetMessagesWithUserResponse>(getMessagesWithUser, {
    variables: {
      where: where,
      ...(skip ? {first: 0} : {}),
    },
    skip: !selfId && !userId,
  });
  const showToArray = data.data?.messages.edges.map((el) => el.node.ShowTo?.map((el) => el.objectId));
  const exists = showToArray?.filter((el) => el?.includes(selfId) && el?.includes(userId)).length;

  return !!exists;
};

export const useExistsUserMessages = (options?: {objectId?: string; commId?: string}) => {
  const viewerObjectId = options?.objectId;
  const commId = options?.commId;

  const [queryMsg, setQueryMsg] = useState<Parse.Query>();
  const memoMap = useCallback(
    (object: Parse.Object) => {
      return mapMessageParseObject(object);
    },
    [queryMsg],
  );
  const {data, unsubscribe} = useLiveQuery<Message>({
    query: queryMsg as Parse.Query,
    map: memoMap,
  });
  useEffect(() => {
    if (!viewerObjectId || !commId) return;
    const user = new Parse.User();
    const community = new Parse.Object('Community');
    user.id = viewerObjectId;
    community.id = commId;
    const queryMsg = new Parse.Query('Message').equalTo('ShowTo', user).equalTo('Community', community).limit(1);
    setQueryMsg(queryMsg);
  }, [viewerObjectId, commId]);
  useEffect(() => {
    if (!viewerObjectId) unsubscribe();
  }, [viewerObjectId]);
  return data ? !!Object.keys(data).length : false;
};

type UseUpdateUserLastVisited = (options: {onSuccess?: () => void}) => {
  setLastVisited: (params: {objectId?: string; commId?: string}) => Promise<boolean>;
  loading: boolean;
};

interface UpdateLastVisitedRequestType {
  id: string;
  fields: {lastVisitedCommunity?: string};
}

export const useSetUserLastVisited: UseUpdateUserLastVisited = (options) => {
  const [UpdateMessageRequest, {loading}] = useMutation<UpdateProfileResponseType, UpdateLastVisitedRequestType>(
    UpdateProfile,
  );

  const setLastVisited = async (params: {objectId?: string; commId?: string}): Promise<boolean> => {
    try {
      if (!params?.objectId) return false;

      const result = await UpdateMessageRequest({
        variables: {id: params.objectId, fields: {lastVisitedCommunity: params?.commId}},
      });

      const message = result?.data?.updateUser?.user;
      if (!message) return false;
      options?.onSuccess?.();
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  return {
    setLastVisited,
    loading,
  };
};

export const manageMessageHints = (msgsCount?: number, orders?: Order[] | null) => {
  const setHints = useSetRecoilState(messageHints);
  useEffect(() => {
    const hints = getInboxHints();
    const today = format(new Date(), 'P');
    const chat = hints?.chat;
    const date = hints?.date;

    if (hints && msgsCount) {
      if (today === date) {
        setHints((prev) => ({...prev, chat: chat === false ? false : true}));
        return;
      }
    }
    if ((!hints || chat === undefined) && msgsCount) {
      setHints((prev) => ({...prev, chat: true}));
      return;
    }
    if (!msgsCount) {
      setHints((prev) => ({...prev, chat: false}));
    }
  }, [!!msgsCount]);
  useEffect(() => {
    const paid = orders?.find((el) => el?.rewardType === RewardType.free);
    const hints = getInboxHints();
    if (hints && paid) {
      const today = format(new Date(), 'P');
      const requests = hints?.requests;
      const date = hints?.date;
      if (today === date) {
        setHints((prev) => ({...prev, requests: requests === false ? false : true}));
        return;
      }
    }
    if (paid) {
      setHints((prev) => ({...prev, requests: true}));
    }
  }, [orders?.length]);
};
