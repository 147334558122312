import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {DocumentsSkeletonSection} from '../common/Skeletons/DocumentSkeleton';
import {CardGroup} from './CardGroup';
import {ModalGroupForm} from './ModalGroupForm';
import {
  AddButton,
  HeaderWrapper,
  ItemsContainer,
  PageWrapper,
  EmptyGroupsRow,
  EmptyGroupsRowTitle,
  EmptyGroupsRowDescr,
} from './styles';
import {isMobile} from 'react-device-detect';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {BasePortal} from '../../ui-kit/Popup';
import {TGroup} from '../../types/group';
import {IActionsOnGroupsData, FormMode, TModalData} from './types';
import {Seo} from '../SEO';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {JoinedGroupsList} from '../../hooks/groups';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {reportActions} from '../../hooks/report';
import {ItemsDivider} from '../Listings/styles';
import {getIsIncludesCmnt} from '../../helpers/item';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';

interface IProps {
  items?: Array<TGroup>;
  actionsData: IActionsOnGroupsData;
  loading: boolean;
  canEdit: boolean;
  joinedGroups: JoinedGroupsList;
  viewerId?: string;
  report: reportActions;
}

export const PageGroups: React.FC<IProps> = ({items, actionsData, loading, canEdit, joinedGroups, report}) => {
  const {t} = useTranslation();
  const [comm] = useRecoilState(currentCommunity);
  const textSeo = t('common:seo.groups.groups', {text: comm?.name});
  const [groupModal, setModal] = useState<TModalData | null>(null);
  const {clearValues} = actionsData;

  const closeModal = () => {
    setModal(null);
    clearValues();
  };
  const openAddGroupModal = () => {
    setModal({mode: FormMode.Add});
  };
  const openEditGroupModal = (group: TGroup) => {
    setModal({mode: FormMode.Edit, selectedGroup: group});
  };
  const joinedIds = useMemo(() => joinedGroups?.map((el) => el.objectId), [joinedGroups]);
  const arreyFeatured = useMemo(
    () =>
      items?.filter((it) => {
        if (getIsIncludesCmnt(it?.Featured, comm?.objectId)) {
          return it;
        }
      }),
    [items],
  );
  const arreyNotFeatured = useMemo(
    () =>
      items?.filter((it) => {
        if (!getIsIncludesCmnt(it?.Featured, comm?.objectId)) {
          return it;
        }
      }),
    [items],
  );
  const itemsSorted = useMemo(
    () => [...(arreyFeatured || []), ...(arreyNotFeatured || [])],
    [arreyFeatured, arreyNotFeatured],
  );

  const endFeatured = useMemo(
    () => itemsSorted?.findIndex((el) => !getIsIncludesCmnt(el?.Featured, comm?.objectId)),
    [itemsSorted],
  );

  return (
    <>
      <PageWrapper>
        <Seo title={textSeo} />
        {!isMobile && (
          <HeaderWrapper>
            <Text size={26} color={'quaternary'} weight={'medium'} variant={'primary'}>
              {t('groups:pageTitle')}
            </Text>
            {canEdit ? <AddButton onClick={openAddGroupModal}>{t('groups:buttons.add')}</AddButton> : null}
          </HeaderWrapper>
        )}
        <Br indent={32} />
        <ItemsContainer>
          {loading ? (
            <DocumentsSkeletonSection />
          ) : (
            !!itemsSorted?.length && (
              <>
                {itemsSorted?.map((el, i) => (
                  <>
                    {endFeatured && i === endFeatured ? <ItemsDivider /> : <></>}
                    <CardGroup
                      group={el}
                      key={el.objectId}
                      actionsData={actionsData}
                      openEditGroupModal={openEditGroupModal}
                      canEdit={canEdit}
                      isJoined={joinedIds?.includes(el?.objectId)}
                      report={report}
                    />
                  </>
                ))}
              </>
            )
          )}
        </ItemsContainer>
        {!loading && !itemsSorted?.length && <EmptyState />}
        {canEdit ? <AddButtonPortal onClick={openAddGroupModal} /> : null}
      </PageWrapper>
      {groupModal && (
        <>
          {isMobile ? (
            <BasePortal>
              <IsModalProvider>
                <ModalGroupForm {...groupModal} close={closeModal} actionsData={actionsData} />
              </IsModalProvider>
            </BasePortal>
          ) : (
            <ModalGroupForm {...groupModal} close={closeModal} actionsData={actionsData} />
          )}
        </>
      )}
    </>
  );
};

const EmptyState = () => {
  const {t} = useTranslation();
  const noGroupsTitle = t('groups:noGroups.title');
  const noGroupsText = t('groups:noGroups.descr');

  return (
    <EmptyGroupsRow>
      <IconSvg type={'animal'} width={'330px'} height={'330px'} viewBox={'0 0 330 330'} />
      <EmptyGroupsRowTitle>{noGroupsTitle}</EmptyGroupsRowTitle>
      <EmptyGroupsRowDescr>{noGroupsText}</EmptyGroupsRowDescr>
    </EmptyGroupsRow>
  );
};
