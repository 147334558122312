import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmptyCard} from '../../ui-kit/Chat/EmptyCard';
import {IconSvg} from '../../ui-kit/Icon/Svg';

export const EmptyAttendees = () => {
  const {t} = useTranslation();

  const title = t('events:empty.attendees.title');
  const descr = t('events:empty.attendees.descr');

  return (
    <EmptyCard
      title={title}
      description={descr}
      imageSvg={
        <IconSvg stroke={'none'} type={'no-attendees'} width={'200px'} height={'200px'} viewBox="0 0 200 200" />
      }
    />
  );
};
