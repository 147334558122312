import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {userState} from '../../../states';
import {Button} from '../../../ui-kit/Button/Button';
import {IconImage} from '../../../ui-kit/Icon/Image';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {
  InnerAuth,
  SubtitleWrapper,
  TitleAuth,
  WrapperContainer,
  LeftPartCard,
  RightPartCard,
  StateUserLive,
  StateUserAdress,
  TextList,
  ContainerDifferentTextWrapper,
  ProgressBar,
  ProgressLine,
  SpinnerActionSvg,
} from '../styles';

interface IProps {
  onOk: () => void;
  loading: boolean;
  progress: number;
  label: string;
}

export const GreatingSuperResident: React.FC<IProps> = ({onOk, loading, progress, label}) => {
  const [user] = useRecoilState(userState);
  const {t} = useTranslation();

  const title = t('auth:foundCommunity.superResident.greating.title');
  const subTitle = t('auth:foundCommunity.superResident.greating.subTitle');
  const firstCTA = t('auth:foundCommunity.superResident.greating.continue');

  return (
    <>
      <InnerAuth>
        <TitleAuth>{title}</TitleAuth>
        <SubtitleWrapper>{subTitle}</SubtitleWrapper>
        <WrapperContainer>
          <LeftPartCard>
            <StateUserLive>{user?.address}</StateUserLive>
            <StateUserAdress>{user?.Location?.name}</StateUserAdress>
            <StateUserAdress> {user?.zip}</StateUserAdress>
            <ContainerDifferentTextWrapper>
              <SpinnerActionSvg $animation={progress !== 100}>
                {progress === 100 ? (
                  <IconSvg type={'circle-ok'} stroke={'green'} />
                ) : (
                  <IconSvg type="loading" width="18px" height="18px" viewBox="-3 -3 19 19" />
                )}
              </SpinnerActionSvg>
              <TextList>{label}</TextList>
            </ContainerDifferentTextWrapper>
          </LeftPartCard>
          <RightPartCard>
            <IconImage type="yoga-relax" width="140px" height="140px" />
          </RightPartCard>
          <ProgressBar>
            <ProgressLine value={progress}></ProgressLine>
          </ProgressBar>
        </WrapperContainer>
        <Button disabled={loading} loading={loading} onClick={onOk}>
          {firstCTA}
        </Button>
      </InnerAuth>
    </>
  );
};
