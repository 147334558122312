import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmptyCard} from '../../ui-kit/Chat/EmptyCard';
import {IconSvg} from '../../ui-kit/Icon/Svg';

export const EmptyBookings = () => {
  const {t} = useTranslation();

  const title = t('amenities:empty.bookings.title');
  const descr = t('amenities:empty.bookings.descr');

  return (
    <EmptyCard
      title={title}
      description={descr}
      imageSvg={<IconSvg stroke={'none'} type={'no-bookings'} width={'200px'} height={'200px'} viewBox="0 0 200 200" />}
    />
  );
};
