import {Connection, ParseObject} from './parse';
import {User} from './user';
import {Community} from './community';
import {Review} from './review';
import {Order} from './order';
import {BookingPeriod, BookingTimingT} from '../../types/amenity';
import {PointerFile} from '../../types/common';

export enum RewardType {
  free = 'free',
  coffee = 'coffee',
  fee = 'fee',
  paid = 'paid',
}

export interface Amenity extends ParseObject {
  objectId?: string;
  name: string;
  isBookable?: boolean;
  rewardType: RewardType;
  maxResidentsPerBooking?: number;
  maxBookingsPerDay?: number;
  autoBooking?: boolean;
  allowGuests?: boolean;
  bookingSlots?: string;
  maxBookingPerSlot?: number;
  bookingPeriod?: BookingPeriod;
  descr: string;
  images: PointerFile[];
  isPublished: boolean;
  isDeleted: boolean;
  rating?: number;
  totalViews?: number;
  totalBookings?: number;
  popularDay?: string;
  bookingTiming?: BookingTimingT;
  Featured?: Community[];
  AdmHidden?: Community[];
  Published?: Community[];
  Updated?: Community[];
  Reviews?: Connection<Review>;
  Bookings?: Connection<Order>;
  popularTime?: Date;
  Location?: Location;
  Lister?: User;
  openFrom?: undefined;
  openUntil?: undefined;
}
