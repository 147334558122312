import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {FormValidation} from '../helpers/FormValidation';
import {isString} from '../helpers/validations';
import {FormErrorFields, useFormError} from './error';
import useMutableState from './useMutableState';
import {
  CreateMultiplyListingType,
  ItemFields,
  ItemFormValue,
  ItemFormValues,
  OnboardingCategories,
  ServiceCategories,
  ServiceItem,
  TImage,
  TItem,
} from '../types/item';
import {
  ActionsManagerQuery,
  ActionsManagerRequestType,
  ActionsManagerResponseType,
  ActionsResidentQuery,
  ActionsResidentRequestType,
  ActionsResResponseType,
  CreateItemQuery,
  CreateItemRequestType,
  CreateItemResponseType,
  CreateMultiplyItemQuery,
  CreateMultiplyItemRequestType,
  CreateMultiplyResponseType,
  DeleteItemQuery,
  DeleteItemRequestType,
  DeleteItemResponseType,
  GetApprovedAndFeaturedItemsQuery,
  GetApprovedAndFeaturedItemsRequestType,
  GetApprovedAndFeaturedItemsResponseType,
  GetBaseItemsQuery,
  GetItemReqType,
  GetItemRequest,
  GetItemResType,
  GetItemsQuery,
  GetPublishedItemsQuery,
  GetUserItemsIdsQuery,
  HandleLikeItem,
  HandleWaveUser,
  ItemsResponseType,
  PublishedItemsResponseType,
  UpdateItemQuery,
  UpdateItemRequestType,
  UpdateItemResponseType,
  UserItemsIdsResponseType,
} from '../queries/item';
import {useViewer, useViewerId} from './user';
import {checkDeletedItems, toPointerCreateItem, toPointerEditItem, toStateItem} from '../helpers/item';
import {imageType, PointerFile, SelectOption} from '../types/common';
import {Item, ListingType, PricePeriodType, RewardType} from '../queries/types/item';
import {
  GQLButtonsManagerTypes,
  GQLButtonsResidentTypes,
  GQLItemOrder,
  GQLItemWhereInput,
  GQLOrderStatuses,
  GQLOrderWhereInput,
} from '../graphql.schema';
import {
  CreateAppFile,
  CreateFileResponseType,
  getAppFile,
  GetFilesRequestType,
  GetFilesResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {StatusOrderType} from '../queries/types/order';
import {useOrders} from './order';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {CommunityStateType, currentCommunity} from '../states/community';
import {toPointer} from '../helpers/parse';
import {TCommunity} from '../types/community';
import {Community} from '../queries/types/community';
import common from '../translations/en/common.json';
import {IconTypes} from '../ui-kit/Icon/Image/types';
import {analyticsTrackFN} from '../helpers/account';
import {useGetCurrencyValue} from './currency';
import {convertDollarToCent} from '../helpers/payment';
import {Currency} from '../queries/types/currency';
import {deletedState, preloadFeatured, preloadListings} from '../states/preload';
import {checkItemImagesFormat} from '../helpers/file';
import {isMobile} from 'react-device-detect';
import {openAIGenerate, OpenAIGenerateRequest, OpenAIGenerateResponse} from '../queries/user';
import {PIXELS_API} from '../constants/env';
import {generatedItem} from '../states/item';
import {createClient} from 'pexels';
export type TItemFormData = {
  categoriesOptions: SelectOption<string>[];
  categoriesServiceOptions: SelectOption<string>[];
  listingTypeOptions: SelectOption<ListingType>[];
  rewardTypeOptions: SelectOption<RewardType>[];
  pricePeriodOptions: SelectOption<PricePeriodType>[];
  pricePeriodOptionsService: SelectOption<PricePeriodType>[];
  dataImages: TImage[];
};
const categories = common.categories;
const categories_services = common.categories_services;
export const onboardingServices = {
  provideAssistance: {
    image: 'hello' as IconTypes,
    services: {
      plant: {
        price: 5,
        images: 'plant',
        period: PricePeriodType.day as PricePeriodType,
        category: 'assistance',
      },
      move: {
        price: 20,
        images: 'move',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'assistance',
      },
      pet: {
        price: 15,
        images: 'pet',
        period: PricePeriodType.day as PricePeriodType,
        category: 'assistance',
      },
      childcare: {
        price: 20,
        images: 'child',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'assistance',
      },
      errands: {
        price: 10,
        images: 'errands',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'assistance',
      },
      cleaning: {
        price: 15,
        images: 'cleaning',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'assistance',
      },
      troubleshoot: {
        price: 5,
        images: 'troubleshoot',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'assistance',
      },
      grocery: {
        price: 0,
        images: 'grocery',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'assistance',
      },
      emergency: {
        price: 0,
        images: 'emergency',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'assistance',
      },
    },
  },
  teachLessons: {
    image: 'celebrate' as IconTypes,
    services: {
      language: {
        price: 10,
        images: 'language',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'lessons',
      },
      music: {
        price: 20,
        images: 'music',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'lessons',
      },
      traditional: {
        price: 10,
        images: 'traditional',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'lessons',
      },
      sport: {
        price: 20,
        images: 'sport',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'lessons',
      },
      dance: {
        price: 20,
        images: 'dance',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'lessons',
      },
    },
  },
  healthWellness: {
    image: 'yoga-relax' as IconTypes,
    services: {
      meal: {
        price: 25,
        images: 'meal',
        period: PricePeriodType.day as PricePeriodType,
        category: 'healthAndWellness',
      },
      nutrition: {
        price: 35,
        images: 'nutrition',
        period: PricePeriodType.week as PricePeriodType,
        category: 'healthAndWellness',
      },
      strength: {
        price: 15,
        images: 'strength',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'healthAndWellness',
      },
      yoga: {
        price: 20,
        images: 'yoga',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'healthAndWellness',
      },
      emergency: {
        price: 30,
        images: 'emergency_first_aid',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'healthAndWellness',
      },
      gym: {
        price: 0,
        images: 'gym',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'healthAndWellness',
      },
    },
  },
  hobbiesInterests: {
    image: 'videoCall2' as IconTypes,
    services: {
      gift: {
        price: 75,
        images: 'gift',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      song: {
        price: 100,
        images: 'song',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      painting: {
        price: 100,
        images: 'painting',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      organizing: {
        price: 20,
        images: 'organizing',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      event: {
        price: 20,
        images: 'event',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      trip: {
        price: 20,
        images: 'trip',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      photography: {
        price: 30,
        images: 'photography',
        period: PricePeriodType.hour as PricePeriodType,
        category: 'hobbiesAndSports',
      },
      boardgames: {
        price: 0,
        images: 'boardgames',
        period: PricePeriodType.fixed as PricePeriodType,
        category: 'hobbiesAndSports',
      },
    },
  },
  others: {
    image: 'landscape' as IconTypes,
    images: 'other',
    services: {},
  },
};

export const useItemFormData = (options?: {images?: Partial<PointerFile>[]; isSignUp?: boolean}): TItemFormData => {
  const {t} = useTranslation();

  const categoriesOptions: SelectOption<string>[] =
    Object.keys(categories).map((key) => {
      return {
        value: key,
        label: t(`common:categories.${key}`),
        key: key,
      };
    }) || [];

  const categoriesServiceOptions: SelectOption<string>[] =
    Object.keys(categories_services).map((key) => {
      return {
        value: key,
        label: t(`common:categories_services.${key}`),
        key: key,
      };
    }) || [];

  const listingTypeOptions: SelectOption<ListingType>[] = [
    {
      value: ListingType.service,
      label: options?.isSignUp ? t('listings:optionsSignUp.service') : t('listings:options.service'),
      key: ListingType.service,
    },
    {
      value: ListingType.rent,
      label: options?.isSignUp ? t('listings:optionsSignUp.rent') : t('listings:options.rent'),
      key: ListingType.rent,
    },
    {
      value: ListingType.sell,
      label: options?.isSignUp ? t('listings:optionsSignUp.sell') : t('listings:options.sell'),
      key: ListingType.sell,
    },
  ];

  const rewardTypeOptions: SelectOption<RewardType>[] = [
    {
      value: RewardType.fee,
      label: t('listings:options.fee'),
      key: RewardType.fee,
    },
    {
      value: RewardType.coffee,
      label: t('listings:options.coffee'),
      key: RewardType.coffee,
    },
    {
      value: RewardType.free,
      label: t('listings:options.free'),
      key: RewardType.free,
    },
  ];

  const pricePeriodOptions: SelectOption<PricePeriodType>[] = [
    {
      value: PricePeriodType.hour,
      label: t('listings:options.hour'),
      key: PricePeriodType.hour,
    },
    {
      value: PricePeriodType.day,
      label: t('listings:options.day'),
      key: PricePeriodType.day,
    },
    {
      value: PricePeriodType.week,
      label: t('listings:options.week'),
      key: PricePeriodType.week,
    },
    {
      value: PricePeriodType.month,
      label: t('listings:options.month'),
      key: PricePeriodType.month,
    },
  ];

  const pricePeriodOptionsRent = [
    ...pricePeriodOptions,
    {
      value: PricePeriodType.fixed,
      label: t('listings:options.fixed'),
      key: PricePeriodType.fixed,
    },
  ];

  const dataImages = Array.from({length: 6}, (v, k) => ({
    id: options?.images?.[k]?.objectId || String(k),
    value: options?.images?.[k]?.file?.url || '',
    order: k,
  }));

  return {
    categoriesOptions,
    categoriesServiceOptions,
    listingTypeOptions,
    rewardTypeOptions,
    pricePeriodOptions,
    pricePeriodOptionsService: pricePeriodOptionsRent,
    dataImages,
  };
};

const ProfileSchema = FormValidation.schema<Partial<ItemFormValues>>({
  name: FormValidation.string('error:allRequired'),
  descr: FormValidation.string('error:allRequired'),
  category: FormValidation.string('error:allRequired'),
  listingType: FormValidation.string('error:allRequired'),
  rewardType: FormValidation.string('error:allRequired'),
  /*images: FormValidation.handler((value, data) => {
    if (data?.listingType === ListingType.service) return true;
    const addedImage = data.images?.filter((el) => !!el.value);
    if (!addedImage?.length) return false;
    return true;
  }, 'error:allRequired'),*/
  // originalPrice: FormValidation.number('error:allRequired'),
  // yearPurchased: FormValidation.string('error:allRequired'),
  pricePeriod: FormValidation.handler((value, data) => {
    if (
      data.rewardType === RewardType.free ||
      data.rewardType === RewardType.coffee ||
      data.listingType === ListingType.sell
    )
      return true;
    return Boolean(data.pricePeriod);
  }, 'error:allRequired'),
  price: FormValidation.handler((value, data) => {
    if (data.rewardType === RewardType.free || data.rewardType === RewardType.coffee) return true;

    return !(!value || (typeof value === 'number' && value <= 0));
  }, 'error:allRequired'),
});

export interface generateState {
  suggestions: Array<{label: string; value: string}>;
  listing: {
    category: string;
    price: number;
    descr: string;
    images: string[] | null;
  };
}

export const useGenerateItem = () => {
  const [generate] = useMutation<OpenAIGenerateResponse, OpenAIGenerateRequest>(openAIGenerate);
  const setGeneratedItem = useSetRecoilState(generatedItem);
  const {refetch: getFiles} = useQuery<GetFilesResponseType, GetFilesRequestType>(getAppFile, {skip: true});
  const [values, setValues] = useState<generateState>({
    suggestions: [],
    listing: {
      price: 0,
      category: '',
      descr: '',
      images: [],
    },
  });
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const viewer = useViewer();

  const handleFetch = async (title: string, endpoint: 'listing_suggestions' | 'price' | 'category') => {
    const result = await fetch(`https://listings-service-f24vdhpgya-uw.a.run.app/${endpoint}`, {
      body: JSON.stringify({title: title, name: viewer?.firstName, city: viewer?.Location?.name}),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await result.json();
  };

  useEffect(() => {
    const getCategory = async () => {
      try {
        const data = await handleFetch('', 'listing_suggestions');
        setValues({
          ...values,
          suggestions: data?.all_listings?.map((item: string) => {
            return {
              label: item,
              value: item,
            };
          }),
        });
      } catch (e) {
        console.log(e);
      }
    };

    getCategory();
  }, []);

  const generateImages = async (title: string) => {
    try {
      setLoadingImage(true);
      const client = createClient(PIXELS_API || '');
      const result = await client.photos.search({query: title, per_page: 20});
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const generatedImages = result?.photos?.map(
        (item: {src: {landscape: string}}) => item?.src?.landscape,
      ) as string[];
      const files = await getFiles({
        where: {
          name: {
            in: generatedImages,
          },
        },
      });
      return (
        generatedImages?.filter(
          (item) =>
            !files?.data?.appFiles?.edges?.find((file) => file?.node?.name?.toLowerCase() === item?.toLowerCase()),
        ) || []
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingImage(false);
    }
  };

  const deleteImages = () => {
    setValues({
      ...values,
      listing: {
        ...values?.listing,
        images: null,
      },
    });
  };

  const nextImage = async (title: string) => {
    let images: string[] = [...(values?.listing?.images || [])];
    images?.shift();
    if (!images?.length) images = (await generateImages(title)) || [];
    setValues({
      ...values,
      listing: {
        ...values?.listing,
        images: images,
      },
    });
    setGeneratedItem({
      price: values?.listing?.price,
      category: values?.listing?.category,
      descr: values?.listing?.descr,
      images: images?.[0] || '',
    });
  };

  const getImageFromUrl = async (url: string) => {
    try {
      setLoadingImage(true);
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], 'image.jpg', {type: blob.type});
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingImage(false);
    }
  };

  const generateListing = async (title: string) => {
    try {
      setLoadingGenerate(true);
      const [dataP, dataC, result, images] = await Promise.all([
        handleFetch(title, 'price'),
        handleFetch(title, 'category'),
        generate({
          variables: {
            textType: 'listingDescription',
            promptData: [
              {
                firstName: viewer?.firstName,
                city: viewer?.Location?.name,
                serviceTitle: title,
              },
            ],
          },
        }),
        generateImages(title),
      ]);

      const price = Math.ceil(dataP?.price || 0);
      const category = dataC?.category || 'Others';
      const listing = {
        price: price,
        category: category,
        descr: result?.data?.generateAiOpenApiText?.gptResponseText || '',
        images: images || [],
      };
      setValues({
        ...values,
        listing,
      });
      setGeneratedItem({
        price: price,
        category: category,
        descr: result?.data?.generateAiOpenApiText?.gptResponseText || '',
        images: images?.[0] || '',
      });
      return listing;
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGenerate(false);
    }
  };

  return {
    generateListing,
    deleteImages,
    nextImage,
    getImageFromUrl,
    values,
    loadingImage,
    loadingGenerate,
  };
};

export type TResCreateItem = {
  onSubmit: (fileName?: string) => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<ItemFormValues>;
  values: Partial<ItemFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: ItemFormValue}) => void;
  setValues: React.Dispatch<React.SetStateAction<Partial<ItemFormValues>>>;
  // options: TOptionsSelect;
};

type UseCreateItemType = (options: {
  initialState: Partial<ItemFormValues>;
  onSuccess?: (item?: Item) => void;
  onEarlySuccess?: () => void;
  onError?: () => void;
  community?: Community;
  currency?: Currency;
}) => TResCreateItem;

export const useCreateItem: UseCreateItemType = (options) => {
  const viewerId = useViewerId();
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<ItemFormValues>>(options?.initialState);
  const [error, setError] = useFormError<ItemFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);

  useEffect(() => {
    if (options?.initialState?.listingType) {
      setValues((prev) => ({...prev, listingType: options?.initialState?.listingType as ListingType}));
    }
  }, [options?.initialState?.listingType]);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name) && !options.initialState?.listingType) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);

  const [CreateItemRequest, {loading}] = useMutation<CreateItemResponseType, CreateItemRequestType>(CreateItemQuery);

  const handleChange = (next: {name: string; value?: ItemFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;

    if (next.name === ItemFields.rewardType && next.value !== RewardType.fee) {
      return setValues((prev) => ({
        ...prev,
        minDuration: 0,
        price: 0,
        pricePeriod: null,
        discount: 0,
        [next.name]: value,
      }));
    }

    return setValues((prev) => ({...prev, [next.name]: value}));
  };

  const _values = {
    ...values,
    name: values?.name?.trim(),
  };

  const onSubmit = async (fileName?: string): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!ProfileSchema.validate<Partial<ItemFormValues>>(_values)) {
        return false;
      }

      if (!viewerId) {
        throw new Error('error:id');
      }
      checkItemImagesFormat(_values.images);
      options?.onEarlySuccess?.();
      const parseImages = await Promise.all(
        (_values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            const result = await createAppFile({
              variables: {
                fields: {
                  name: fileName || '',
                  file: {upload: file.value},
                  Owner: {link: viewerId},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );
      const response = await CreateItemRequest({
        variables: {
          fields: {
            ...toPointerCreateItem(_values, viewerId, parseImages),
            ...(options?.community ? {ApproveReq: [options?.community]} : {}),
          },
        },
      });
      setIsLoading(loading);
      const data = response?.data?.createItem?.item;
      if (!data && !options?.community) throw new Error();
      // analyticsTrackFN('Listing Posted', {
      //   listingId: data?.objectId,
      //   listingType: data?.listingType, // service, rental, buy/sell
      //   listedFor: data?.rewardType, // coffee, free, money
      //   photoCount: data?.images?.length,
      //   title: data?.name,
      //   description: data?.descr,
      //   rate: data?.price,
      //   currency: currency?.code,
      //   bookingType: data?.pricePeriod,
      //   category: data?.category,
      //   listerName: `${data?.Lister?.firstName}`,
      //   listerEmail: `${data?.Lister?.email}`,
      //   listerId: data?.Lister?.objectId,
      //   communitiesCount: data?.Published?.length,
      //   communities: [
      //     ...(data?.Published?.map((item) => {
      //       return {
      //         communityName: item?.name,
      //         communityId: item?.objectId,
      //         listingStatus: 'published',
      //         postedIn: true,
      //         communityType: item?.type,
      //       };
      //     }) || []),
      //     ...(data?.ApproveReq?.map((item) => {
      //       return {
      //         communityName: item?.name,
      //         communityId: item?.objectId,
      //         listingStatus: 'pending',
      //         postedIn: true,
      //         communityType: item?.type,
      //       };
      //     }) || []),
      //   ],
      // });
      setSuccess('success:createItem');
      setError(null);
      if (data) options?.onSuccess?.(data);
      setValues(options?.initialState);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      setIsLoading(loading);
    }

    return true;
  };

  return {
    values,
    onSubmit,
    setValues,
    success,
    loading: isLoading,
    onChange: handleChange,
    error,
  };
};

export const useCreateMultiplyListings = (param: {
  communityIds: string[];
  onSuccess?: (
    listId?: string[],
    init?: {[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>},
  ) => void;
}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [CreateItemRequest] = useMutation<CreateMultiplyResponseType, CreateMultiplyItemRequestType>(
    CreateMultiplyItemQuery,
  );
  const [initValue, setInitValue] =
    useState<{[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>}>();
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [listings, setListings] =
    useState<{[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>}>();

  useEffect(() => {
    let init: {[category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>} = {} as {
      [category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>;
    };
    selectedCategory
      .map((item) => {
        let obj: Record<ServiceCategories, ServiceItem> = {} as Record<ServiceCategories, ServiceItem>;
        const services = onboardingServices?.[item as keyof typeof onboardingServices]?.services as any;
        Object.keys(services)?.forEach((service) => {
          const price = services?.[service as keyof typeof services]?.price;
          const period = services?.[service as keyof typeof services]?.period;
          const images = services?.[service as keyof typeof services]?.images;
          const category = services?.[service as keyof typeof services]?.category;
          obj = {
            ...obj,
            [service]: {
              title: t(`common:onboarding_services.${item}.services.${service}.title`),
              description: t(`common:onboarding_services.${item}.services.${service}.description`),
              images: images,
              isChecked: false,
              isEdit: false,
              price,
              period,
              category,
            } as ServiceItem,
          } as Record<ServiceCategories, ServiceItem>;
        });
        return {
          [item]: obj,
        };
      })
      .forEach((item) => {
        init = {...init, ...item};
      });
    setInitValue(
      init as {
        [category in OnboardingCategories]: Record<ServiceCategories, ServiceItem>;
      },
    );
    setListings(init);
  }, [selectedCategory]);
  const items: CreateMultiplyListingType[] = [];
  if (listings)
    Object.keys(listings)?.map((category) => {
      Object.keys(listings?.[category as keyof typeof listings])?.map((service) => {
        const localCategory = listings?.[category as keyof typeof listings];
        const localService = localCategory?.[service as keyof typeof localCategory];
        if (localService?.isChecked) {
          items.push({
            price: convertDollarToCent(localService?.price),
            period: localService?.period,
            description: localService?.description?.trim(),
            title: localService?.title?.trim(),
            images: localService?.images,
            category: localService?.category || 'other',
          } as CreateMultiplyListingType);
        }
      });
    });
  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await CreateItemRequest({
        variables: {
          communityIds: param?.communityIds,
          items: items,
        },
      });
      param.onSuccess?.(result?.data?.createMultiplyItems?.listId || [], initValue);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    loading,
    listings,
    setListings,
    selectedCategory,
    setSelectedCategory,
    items,
  };
};

export type TResEditItem = {
  onSubmit: () => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<ItemFormValues>;
  values: Partial<ItemFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: ItemFormValue}) => void;
};

type UseEditItemType = (options: {
  initialState: Partial<ItemFormValues>;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResEditItem;

export const useEditItem: UseEditItemType = (options) => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<ItemFormValues>>(options?.initialState);
  const [error, setError] = useFormError<ItemFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [updateAppFile] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(UpdateAppFile);
  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.name)) return;
    setValues(options?.initialState);
    setState({hasSetInitialValues: true});
  }, [options?.initialState]);
  const {currency} = useGetCurrencyValue();
  const [UpdateItemRequest, {loading}] = useMutation<UpdateItemResponseType, UpdateItemRequestType>(UpdateItemQuery);

  const handleChange = (next: {name: string; value?: ItemFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    if (next.name === ItemFields.rewardType && next.value !== RewardType.fee) {
      return setValues((prev) => ({
        ...prev,
        minDuration: 0,
        price: 0,
        pricePeriod: null,
        discount: 0,
        [next.name]: value,
      }));
    }

    return setValues((prev) => ({...prev, [next.name]: value}));
  };

  const _values = {
    ...values,
    name: values?.name?.trim(),
  } as Partial<ItemFormValues>;
  const onSubmit = async (): Promise<boolean> => {
    try {
      if (!ProfileSchema.validate<Partial<ItemFormValues>>(values)) {
        return false;
      }

      if (!values.objectId) {
        throw new Error('error:id');
      }
      checkItemImagesFormat(values.images);
      const parseImages = await Promise.all(
        (values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            if (typeof file.value === 'string') return values?.serverImages?.find((it) => it.objectId === file.id);
            if (Number(file?.id?.length) > 1) {
              const result = await updateAppFile({
                variables: {
                  id: file.id as string,
                  fields: {
                    file: {upload: file.value as File},
                    type: imageType.itemImage,
                  },
                },
              });
              return toPointer(result.data?.updateAppFile?.appFile);
            }
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      );

      const {objectId, ...item} = _values;

      const response = await UpdateItemRequest({
        variables: {
          id: objectId || values.objectId,
          fields: toPointerEditItem({...item, images: parseImages}),
        },
      });

      const data = response?.data?.updateItem?.item;
      if (!data) throw new Error();
      analyticsTrackFN('Listing Updated', {
        listingId: objectId,
        listingType: data?.listingType, // service, rental, buy/sell
        listedFor: data?.rewardType, // coffee, free, money
        photoCount: data?.images?.length,
        title: data?.name,
        description: data?.descr,
        rate: data?.price,
        currency: currency?.code,
        bookingType: data?.pricePeriod,
        category: data?.category,
        listerName: `${data?.Lister?.firstName}`,
        listerEmail: `${data?.Lister?.email}`,
        listerId: data?.Lister?.objectId,
        communitiesCount: data?.Published?.length,
        communities: [
          ...(data?.Published?.map((item) => {
            return {
              communityName: item?.name,
              communityId: item?.objectId,
              listingStatus: 'published',
              postedIn: true,
              communityType: item?.type,
            };
          }) || []),
          ...(data?.ApproveReq?.map((item) => {
            return {
              communityName: item?.name,
              communityId: item?.objectId,
              listingStatus: 'pending',
              postedIn: true,
              communityType: item?.type,
            };
          }) || []),
        ],
      });
      setSuccess('success:editItem');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
  };
};

export const useResidentActionsItem = (params: {
  communityId?: string;
  onSuccess?: (type: GQLButtonsResidentTypes) => void;
  onError?: () => void;
}) => {
  const {onSuccess, onError, communityId} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionResRequest, {loading}] = useMutation<ActionsResResponseType, ActionsResidentRequestType>(
    ActionsResidentQuery,
  );

  const submit = async ({
    itemId,
    typeBtn,
    communityIds,
  }: {
    itemId: string;
    typeBtn: GQLButtonsResidentTypes;
    communityIds: string[];
  }) => {
    const response = await ActionResRequest({
      variables: {
        itemId: itemId,
        communityIds: communityIds,
        type: typeBtn,
      },
    });

    const data = response?.data?.itemResidentButtons;

    if (!data) throw new Error();
  };

  const onSubmit = async ({
    itemId,
    typeBtn,
    communityIds,
  }: {
    itemId?: string;
    typeBtn: GQLButtonsResidentTypes;
    communityIds?: Array<string>;
  }) => {
    if (!itemId) return;

    try {
      if (communityIds) await submit({communityIds, typeBtn, itemId});
      else {
        if (!communityId) return;
        await submit({communityIds: [communityId], typeBtn, itemId});
      }

      setError(null);
      onSuccess?.(typeBtn);
    } catch (error) {
      setError(error as any);
      onError?.();
    }
  };
  return {onSubmit, error, loading};
};

export const useManagerActionsItem = (params: {communityId?: string; onSuccess?: () => void; onError?: () => void}) => {
  const {onSuccess, onError, communityId} = params;
  const [error, setError] = useState<string | null>(null);
  const [ActionRequest, {loading}] = useMutation<ActionsManagerResponseType, ActionsManagerRequestType>(
    ActionsManagerQuery,
  );

  const onSubmit = async (params: {itemId?: string; typeBtn: GQLButtonsManagerTypes}) => {
    if (!params?.itemId || !communityId) return;

    try {
      const response = await ActionRequest({
        variables: {
          itemId: params?.itemId,
          communityId: communityId,
          type: params.typeBtn,
        },
      });

      const data = response?.data?.itemManagerButtons;
      if (!data) throw new Error();

      setError(null);
      onSuccess?.();
    } catch (error) {
      setError(error as any);
      onError?.();
    }
  };
  return {onSubmit, error, loading};
};

export const useItems = (options?: {
  where?: GQLItemWhereInput;
  order?: GQLItemOrder;
  skip?: number;
  first?: number;
  hideDeletedItems?: boolean;
}) => {
  const hideDeletedItems = options?.hideDeletedItems ?? true;
  const [liked, setLiked] = useState<LikeState>({});
  const {handleLike} = useItemLike();

  const whereParams: GQLItemWhereInput | undefined =
    options?.where || hideDeletedItems
      ? {
          AND: [
            ...(options?.where ? [options.where] : []),
            ...(hideDeletedItems ? [{isDeleted: {notEqualTo: true}}] : []),
          ],
        }
      : undefined;

  const {data, ...response} = useQuery<ItemsResponseType>(GetItemsQuery, {
    variables: {
      ...(whereParams ? {where: whereParams} : {}),
      ...(options?.order ? {order: options.order} : {}),
      ...(options?.first ? {first: options?.first} : {}),
      ...(options?.skip ? {skip: options?.skip} : {}),
    },
    ssr: true,
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    setLiked((prev) => {
      const likeMap = {...prev};
      data?.items?.edges?.forEach?.(({node: el}) => {
        if (el?.isLike && el?.objectId) if (likeMap[el.objectId] === undefined) likeMap[el.objectId] = true;
      });
      return likeMap;
    });
  }, [data?.items?.edges?.length]);
  const onLike = (id: string) => {
    handleLike(id);
    setLiked((prev) => ({...prev, [id]: !prev[id]}));
  };
  return {
    data: data?.items?.edges?.map((edge) => toStateItem(edge.node)) || [],
    count: data?.items?.count,
    onLike,
    liked,
    ...response,
  };
};

export const useGetItem = (params: {id?: string}) => {
  const {data, ...response} = useQuery<GetItemResType, GetItemReqType>(GetItemRequest, {
    variables: {
      id: params.id || '',
    },
    skip: !params?.id,
    ssr: true,
  });

  return {...response, data: toStateItem(data?.item) || null};
};

export const useGetItemNoCache = ({id}: {id?: string}) => {
  const {data, ...response} = useQuery<GetItemResType, GetItemReqType>(GetItemRequest, {
    variables: {
      id: id || '',
    },
    skip: !id,
    ssr: true,
    fetchPolicy: 'no-cache',
  });

  return {...response, data: toStateItem(data?.item) || null};
};

export type TGetInsightsItem = {
  totalCount?: number;
  completedCount?: number;
  amountIncome?: number;
};

export const useGetInsightsItem = (itemObjectId?: string): TGetInsightsItem => {
  const viewerId = useViewerId('objectId');
  const query = [
    {
      Item: {
        have: {
          objectId: {
            equalTo: itemObjectId,
          },
        },
      },
    },
    {
      Item: {
        have: {
          Lister: {
            have: {
              objectId: {
                equalTo: viewerId,
              },
            },
          },
        },
      },
    },
  ];
  const {data: completed, count: completedCount} = useOrders({
    where: {
      AND: [
        ...query,
        {
          status: {
            equalTo: StatusOrderType.completed,
          },
        },
      ],
    },
  });
  const {count: totalCount} = useOrders({
    where: {
      AND: query,
    },
  });
  const amountIncome = completed?.reduce((acc, it) => acc + it.price - (it?.discount || 0), 0);
  return {totalCount, completedCount, amountIncome};
};

export const useDeleteItem = (options: {id?: string; onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const setDeleted = useSetRecoilState(deletedState);
  const [success, setSuccess] = useState<string | null>(null);
  const [DeleteItemRequest, {loading}] = useMutation<DeleteItemResponseType, DeleteItemRequestType>(DeleteItemQuery);
  const {id, onSuccess} = options;

  const onDelete = async (): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('error:id');
      }

      const response = await DeleteItemRequest({
        variables: {id},
      });
      setDeleted((prev) => ({...prev, items: [...prev.items, id]}));
      const data = response?.data?.deleteItem?.item;
      if (!data) throw new Error();

      setSuccess('success:deleteItem');
      setError(null);
      onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    }

    return true;
  };

  return {
    onDelete,
    success,
    loading,
    error,
  };
};

export const useSetItemStatusDeleted = (options: {id?: string; onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [UpdateItemRequest, {loading}] = useMutation<UpdateItemResponseType, UpdateItemRequestType>(UpdateItemQuery);
  const {id, onSuccess} = options;
  const setDeleted = useSetRecoilState(deletedState);
  const onDelete = async (): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('error:id');
      }

      const response = await UpdateItemRequest({
        variables: {id, fields: {isDeleted: true}},
      });
      setDeleted((prev) => ({...prev, items: [...prev.items, id]}));

      const data = response?.data;
      if (!data) throw new Error();

      setSuccess('success:updatedItem');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      onSuccess?.();
    }

    return true;
  };

  return {
    onDelete,
    success,
    loading,
    error,
  };
};

export const useSetItemRenewOrSold = (options: {id?: string; onSuccess?: () => void; onError?: () => void}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [UpdateItemRequest, {loading}] = useMutation<UpdateItemResponseType, UpdateItemRequestType>(UpdateItemQuery);
  const {id, onSuccess} = options;

  const onRenew = async (): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('error:id');
      }

      const response = await UpdateItemRequest({
        variables: {id, fields: {isSold: false}},
      });

      const data = response?.data;
      if (!data) throw new Error();

      setSuccess('success:updatedItem');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      onSuccess?.();
    }

    return true;
  };

  const onSold = async (): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('error:id');
      }

      const response = await UpdateItemRequest({
        variables: {id, fields: {isSold: true}},
      });

      const data = response?.data;
      if (!data) throw new Error();

      setSuccess('success:updatedItem');
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error as any);
      return false;
    } finally {
      onSuccess?.();
    }

    return true;
  };

  return {
    onRenew,
    onSold,
    success,
    loading,
    error,
  };
};

type ItemsParams = {
  where?: GQLItemWhereInput;
  order?: GQLItemOrder[];
  cursor?: string;
  first?: number;
};

export const useListItems = (params: {
  where?: GQLItemWhereInput;
  order?: GQLItemOrder[];
  skip?: boolean;
  first?: number;
  hideDeletedItems?: boolean;
}) => {
  const previousCursor = useRef<string | null>(null);
  const hideDeletedItems = params?.hideDeletedItems ?? true;

  const whereParams: GQLItemWhereInput | undefined =
    params?.where || hideDeletedItems
      ? {
          AND: [
            ...(params.where ? [params.where] : []),
            ...(hideDeletedItems ? [{isDeleted: {notEqualTo: true}}] : []),
          ],
        }
      : undefined;

  const variables: ItemsParams = {
    ...(whereParams ? {where: whereParams} : {}),
    ...(params?.order ? {order: params.order} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<ItemsResponseType, ItemsParams>(GetItemsQuery, {
    variables,
    // notifyOnNetworkStatusChange: true,
    ssr: true,
  });

  const fetch = async () => {
    const {hasNextPage, endCursor} = data?.items?.pageInfo || {};

    if (!hasNextPage || !endCursor) return;

    previousCursor.current = endCursor;

    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          if (!previousResult?.items?.pageInfo?.hasNextPage) return previousResult;
          const prevChunk = previousResult.items.edges;
          const nextChunk = fetchMoreResult.items.edges;

          return {
            items: {
              ...fetchMoreResult.items,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    data: data?.items?.edges?.map((edge) => toStateItem(edge.node)) || [],
    loading,
    total: data?.items?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.items?.pageInfo?.hasNextPage),
    refetch,
    count: data?.items?.count,
  };
};

type PublishedItemsParams = {
  where?: GQLItemWhereInput;
  order?: GQLItemOrder[] | GQLItemOrder;
  cursor?: string;
  first?: number;
  id?: string;
};

export const usePublishedItems = (params: {
  where?: GQLItemWhereInput;
  order?: GQLItemOrder[] | GQLItemOrder;
  skip?: boolean;
  first?: number;
  hideDeletedItems?: boolean;
}) => {
  const previousCursor = useRef<string | null>(null);
  const [community] = useRecoilState(currentCommunity);

  const hideDeletedItems = params?.hideDeletedItems ?? true;

  const whereParams: GQLItemWhereInput | undefined =
    params?.where || hideDeletedItems
      ? {
          AND: [
            ...(params.where ? [params.where] : []),
            ...(hideDeletedItems ? [{isDeleted: {notEqualTo: true}}] : []),
          ],
        }
      : undefined;

  const variables = {
    ...(whereParams ? {where: whereParams} : {}),
    ...(params?.order ? {order: params.order} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };

  const {data, loading, fetchMore, refetch} = useQuery<PublishedItemsResponseType, PublishedItemsParams>(
    GetPublishedItemsQuery,
    {
      variables,
      skip: !community?.id,
      fetchPolicy: 'cache-first',
      // notifyOnNetworkStatusChange: true,
      ssr: true,
    },
  );

  const fetch = async () => {
    const pageInfoItems = data?.items?.pageInfo;
    if (!pageInfoItems || !community?.id) return;
    const {hasNextPage, endCursor} = pageInfoItems;
    if (!hasNextPage || !endCursor || endCursor === previousCursor.current) return;

    previousCursor.current = endCursor;
    try {
      await fetchMore({
        variables: {
          ...variables,
          cursor: endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!fetchMoreResult) return previousResult;
          if (!previousResult?.items?.pageInfo?.hasNextPage) return previousResult;
          const prevChunk = previousResult.items.edges;
          const nextChunk = fetchMoreResult.items.edges;
          return {
            items: {
              ...fetchMoreResult.items,
              edges: prevChunk.concat(nextChunk),
            },
          };
        },
      });
    } catch (e) {
      console.log('fetching', Number(data?.items?.edges?.length || 0));
      refetch({first: Number(data?.items?.edges?.length || 0)});
      console.error(e);
    }
  };
  useEffect(() => {
    previousCursor.current = null;
  }, [JSON.stringify(params.where || {})]);

  return {
    data: data?.items?.edges?.map((edge) => toStateItem(edge.node)) || [],
    loading,
    total: data?.items?.count || 0,
    fetchData: fetch,
    hasMore: Boolean(data?.items?.pageInfo?.hasNextPage),
    refetch,
  };
};

type checkAvailabilityOoptions = {
  itemId?: string;
  strictCheck?: boolean;
};
export type LikeState = Record<string, boolean>;

export const useCheckItemForAvailability = (options: checkAvailabilityOoptions) => {
  const notAvailableStatuses = [GQLOrderStatuses.accepted, GQLOrderStatuses.performing, StatusOrderType.paymentPending];
  const where: GQLOrderWhereInput = {
    AND: [{status: {in: notAvailableStatuses}}, {Item: {have: {objectId: {equalTo: options.itemId}}}}],
  };
  const {count, data, loading} = useOrders({where});
  const dates = useMemo(() => data?.map((el) => ({start: el?.startTime, end: el?.endTime})), [data]);
  return {available: options.strictCheck ? !count : true, dates, loading};
};

export const useItemPageContent = (searchParams: GQLItemWhereInput[], community?: CommunityStateType) => {
  const viewer = useViewer();
  const [preloaded] = useRecoilState(preloadListings);
  const [deleted] = useRecoilState(deletedState);
  const [preloadedFeatured] = useRecoilState(preloadFeatured);
  const [liked, setLiked] = useState<LikeState>({});
  const {handleLike} = useItemLike();
  const {
    approved,
    // featured,
    refetch: refetchApp,
    // loading: loadingAppAndFeat,
  } = useGetUsesApprovedItems(community?.objectId);
  const {
    data: featured,
    fetch: refetchFeat,
    loading: featureLoading,
  } = useGetUsesFeaturedItems(community, searchParams);
  const refetchAppAndFeat = () => {
    refetchApp();
    refetchFeat();
  };
  // const filteredFeatured = filterFeatured(featured, searchParams);
  const {
    data: allItems,
    fetchData: fetchDataAllItems,
    hasMore: hasMoreAllItems,
    loading: loadingAllItems,
    refetch: refetchAllItems,
  } = usePublishedItems({
    order: [GQLItemOrder.isSold_ASC, 'createdAt_custom_DESC' as GQLItemOrder],
    first: isMobile ? 12 : 30,
    where: {
      AND: [
        {Lister: {have: {objectId: {exists: true}}}},
        {
          Published: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ],
      NOR: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
      ],
    },
  });
  useEffect(() => {
    if (searchParams?.[0]?.name?.matchesRegex && !!allItems?.length)
      analyticsTrackFN('Searched Listing', {
        query: searchParams?.[0]?.name?.matchesRegex,
        results: allItems?.length || 0,
        userName: viewer?.username,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
  }, [!!allItems?.length]);
  const approvedItems = approved;
  const withPreloaded = !searchParams?.length ? preloaded : [];
  const withPreloadedFeatured = !searchParams?.length ? (featured?.length ? featured : preloadedFeatured) : featured;
  const communityItemsWithoutDeleted = [...withPreloadedFeatured, ...(allItems?.length ? allItems : withPreloaded)];

  const communityItems = checkDeletedItems(deleted.items, communityItemsWithoutDeleted);
  useEffect(() => {
    setLiked((prev) => {
      const likeMap = {...prev};
      allItems?.forEach?.((el) => {
        if (el?.isLike && el?.objectId) if (likeMap[el.objectId] === undefined) likeMap[el.objectId] = true;
      });
      return likeMap;
    });
  }, [allItems?.length]);
  const onLike = (id: string) => {
    handleLike(id);
    setLiked((prev) => ({...prev, [id]: !prev[id]}));
  };
  return {
    featureLoading: featureLoading,
    loadingAllItems,
    refetchAppAndFeat,
    refetchAllItems,
    fetchDataAllItems,
    hasMoreAllItems,
    communityItems,
    approvedItems,
    featured: withPreloadedFeatured,
    onLike,
    liked,
  };
};

export const useGetUsesApprovedItems = (communityId?: string) => {
  const [getApprovedAndFeatured, {loading}] = useMutation<
    GetApprovedAndFeaturedItemsResponseType,
    GetApprovedAndFeaturedItemsRequestType
  >(GetApprovedAndFeaturedItemsQuery);
  const [approved, setApproved] = useState<Partial<TItem>[]>([]);
  // const [featured, setFeatured] = useState<Partial<TItem>[]>([]);
  const getData = async () => {
    if (!communityId) return;
    const result = await getApprovedAndFeatured({variables: {communityId: communityId}});
    setApproved(result?.data?.getItemArrays?.approvedItems || []);
    // if (!searchParams?.[0]?.OR?.[0]?.listingType?.equalTo) {
    //   return setFeatured(result?.data?.getItemArrays?.featuredItems || []);
    // } else {
    //   const filterFeatured = result?.data?.getItemArrays?.featuredItems?.filter(
    //     (el) => el?.listingType === searchParams?.[0]?.OR?.[0]?.listingType?.equalTo,
    //   );
    //   return setFeatured(filterFeatured || []);
    // }
  };

  const refetch = () => getData();
  // const featuredWithPrice = featured.map((el) => {
  //   return {...el, price: convertCentToDollar(el?.price)};
  // });

  return {approved, loading, refetch};
};

export const useGetUsesFeaturedItems = (
  community?: CommunityStateType,
  searchParams?: GQLItemWhereInput[],
  skip?: boolean,
) => {
  const whereParams: GQLItemWhereInput | undefined = {
    AND: [
      {Lister: {have: {objectId: {exists: true}}}},
      {
        Published: {
          contains: [
            toPointer({
              __typename: community?.__typename || '',
              objectId: community?.objectId || '',
            }),
          ],
        },
        AdmHidden: {
          notIn: [
            toPointer({
              __typename: community?.__typename || '',
              objectId: community?.objectId || '',
            }),
          ],
        },
      },
      {
        Featured: {
          contains: [
            toPointer({
              __typename: community?.__typename || '',
              objectId: community?.objectId || '',
            }),
          ],
        },
      },
      {
        isDeleted: {
          equalTo: false,
        },
      },
      ...(searchParams || []),
    ],
  };

  const variables = {
    ...(whereParams ? {where: whereParams} : {}),
  };

  const {data, loading, refetch} = useQuery<ItemsResponseType, PublishedItemsParams>(GetBaseItemsQuery, {
    variables,
    skip: !community?.id || skip,
    // notifyOnNetworkStatusChange: true,
  });
  const fetch = () => refetch();
  return {data: data?.items?.edges?.map((edge) => toStateItem(edge.node)) || [], loading, fetch};
};

export const useGetUsersItemsIds = (options: {where?: GQLItemWhereInput}) => {
  const hideDeletedItems = true;

  const whereParams: GQLItemWhereInput | undefined = options?.where
    ? {
        AND: [
          ...(options.where ? [options.where] : []),
          ...(hideDeletedItems ? [{isDeleted: {notEqualTo: true}}] : []),
        ],
      }
    : undefined;

  const {data} = useQuery<UserItemsIdsResponseType>(GetUserItemsIdsQuery, {
    variables: {
      ...(whereParams ? {where: whereParams} : {}),
    },
    ssr: false,
  });

  return data?.items?.edges?.map((e) => e.node) || [];
};

export const useGetUserItems = (userId?: string, community?: Partial<TCommunity> | null) => {
  const where: GQLItemWhereInput = community
    ? {
        AND: [
          {
            Lister: {
              have: {
                objectId: {equalTo: userId},
              },
            },
          },
          {
            Published: {
              contains: [
                toPointer({
                  __typename: community?.__typename || '',
                  objectId: community?.objectId || '',
                }),
              ],
            },
          },
        ],
      }
    : {};

  const {data} = useQuery<UserItemsIdsResponseType>(GetUserItemsIdsQuery, {
    variables: {
      where: where,
    },
    ssr: false,
  });
  return {data: data?.items?.edges?.map((e) => e.node) || [], count: data?.items?.count};
};

export const useItemLike = (id?: string) => {
  const [like] = useMutation<undefined, {id: string}>(HandleLikeItem);

  const handleLike = async (targetId?: string) => {
    if (!id && !targetId) return false;
    try {
      await like({
        variables: {
          id: id || targetId || '',
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  };
  return {handleLike};
};

export const useWaveUser = (communityId?: string, userId?: string) => {
  const [wave] = useMutation<undefined, {communityId: string; userId: string}>(HandleWaveUser);

  const handleWave = async () => {
    if (!communityId || !userId) return false;
    try {
      await wave({
        variables: {
          communityId,
          userId,
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  };
  return {handleWave};
};

export const filterFeatured = (featured: Partial<TItem>[], searchParams?: any[]) => {
  const filtered = [...featured];
  const byName = searchParams?.find((el) => !!el?.name?.matchesRegex)?.name?.matchesRegex || '';
  if (byName) {
    return filtered?.filter((el) => el?.name?.toLowerCase()?.includes(byName?.toLowerCase()));
  }
  return featured;
};
