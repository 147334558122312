import React from 'react';
import {ContentLoaderProps} from './types';
import {ContentLoaderWrapper, LoadingTextLabel, DefaultText, StyledSpinnerLoader} from './styles';
import {IconSvg} from '../Icon/Svg';
import {SpinnerLoader as LoaderSpinner} from './Loaders';

export const ContentLoader: React.FC<ContentLoaderProps> = ({
  leftAdornment,
  text,
  textPosition,
  padding,
  skeletonLoader,
  className,
}) => {
  return (
    <>
      {skeletonLoader}
      <ContentLoaderWrapper textPosition={textPosition} padding={padding} className={className}>
        {leftAdornment === 'none' ? null : leftAdornment ? (
          leftAdornment
        ) : (
          <IconSvg type="bike" width={'22'} height={'22'} />
        )}
        <LoadingTextLabel>
          <DefaultText>{text || 'Loading'}</DefaultText>
        </LoadingTextLabel>
      </ContentLoaderWrapper>
    </>
  );
};

export const SpinnerLoader: React.FC<{padding?: string}> = ({padding, ...props}) => {
  return (
    <StyledSpinnerLoader padding={padding} {...props}>
      <LoaderSpinner />
    </StyledSpinnerLoader>
  );
};
