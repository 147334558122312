import React, {useEffect, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {ViewEventCreator} from '../../components/Events/ViewCreator';
import {route} from '../../constants/routes';
import {GQLOrderWhereInput} from '../../graphql.schema';
import {typeOrder} from '../../queries/types/order';
import {currentCommunity, userPermissions} from '../../states/community';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {EventFormAction} from '../../types/event';
import {PermissionsList} from '../../types/settings';

import {useLinks} from '../../hooks/common';
import {useGetEventNoCache, useSetEventStatusDeleted} from '../../hooks/event';
import {getEventOrders, useManageOrder, useOrdersList} from '../../hooks/order';
import {useCheckJoined, useViewerId} from '../../hooks/user';
import {useGetActualCommunityList} from '../../hooks/community';

export const ViewEditEventContainer = () => {
  const viewerId = useViewerId('objectId');
  const {push} = useHistory();
  const [typeUser] = useRecoilState(StateTypeUser);
  const [community] = useRecoilState(currentCommunity);
  const {id} = useParams<{id?: string}>();
  const {data: event, loading} = useGetEventNoCache({id});
  const {getLink} = useLinks();
  const [permissions] = useRecoilState(userPermissions);
  const onSuccess = () => {
    if (!typeUser) return;
    push(getLink(route.events.path));
  };
  const communities = useGetActualCommunityList();
  const commList = useMemo(
    () => communities?.map((el) => ({objectId: el?.objectId, name: el?.name})),
    [communities?.length],
  );
  const {onDelete} = useSetEventStatusDeleted({id, onSuccess});
  const {communityCount} = useCheckJoined();
  // const {onSubmit, loading: actionLoading} = useResidentActionsItem({
  //   communityId: community?.objectId,
  //   onSuccess: onSuccess,
  // });

  const handlePublish = () => {
    // onSubmit({
    //   itemId: item?.objectId,
    //   typeBtn: GQLButtonsResidentTypes.publish,
    // });
  };

  const handleUnlist = () => {
    // onSubmit({
    //   itemId: item?.objectId,
    //   typeBtn: GQLButtonsResidentTypes.unlist,
    // });
  };
  const whereOrders: GQLOrderWhereInput = {
    orderType: {equalTo: typeOrder.eventOrder},
    Event: {have: {objectId: {equalTo: event?.objectId}}},
  };
  const orders = useOrdersList({where: whereOrders});
  const {data: eventOrders} = getEventOrders(event?.id);
  const manageOrder = useManageOrder({onSuccess: () => orders.refetch()});
  useEffect(() => {
    if (event?.objectId && typeUser) {
      const pass =
        permissions.includes(PermissionsList.manageEvent) ||
        typeUser === TypeCommunity.manager ||
        event.Lister?.objectId === viewerId;
      if (!pass) push(getLink(route.events.path));
    }
  }, [event?.objectId, typeUser]);
  const canEditComms = communityCount >= 2;
  return (
    <ViewEventCreator
      communityAlias={community?.alias}
      communityId={community?.objectId}
      action={EventFormAction.edit}
      event={event}
      onPublish={handlePublish}
      onUnlist={handleUnlist}
      $loading={loading}
      onDelete={onDelete}
      viewerIsNotOwner={false}
      orders={orders.data}
      ordersLoading={orders.loading}
      manageOrder={manageOrder}
      canEditCommunities={canEditComms}
      commList={commList}
      eventOrders={eventOrders}
    />
  );
};
