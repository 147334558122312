import React from 'react';
import {EmptyState} from './EmptyState';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from './styles';

type TProps = {linkInvite: string; linkVerify: string; isGroup?: boolean};

const EmptyRes: React.FC<TProps> = ({linkInvite, linkVerify}) => (
  <Trans i18nKey={'common:emptyState.home.community.subText'}>
    {linkInvite ? <Link to={linkInvite} /> : <Link as={'span'} />}
    {linkVerify ? <Link to={linkVerify} /> : <Link as={'span'} />}
  </Trans>
);

const EmptyStateCommunityLoop: React.FC<TProps> = ({linkInvite, linkVerify, isGroup}) => {
  const {t} = useTranslation();
  const mainText = t('common:emptyState.home.community.mainText');
  const groupText = t('common:emptyState.home.community.group');
  if (isGroup) return <EmptyState mainText={groupText} />;
  return <EmptyState mainText={mainText} ElemSubText={<EmptyRes linkInvite={linkInvite} linkVerify={linkVerify} />} />;
};

const MemoEmptyStateCommunityLoop = React.memo(EmptyStateCommunityLoop);
export {MemoEmptyStateCommunityLoop};
