import React, {useMemo, useState} from 'react';
import {
  getAmenityDataButtonLabel,
  getTextAmenityDescription,
  getTextsAmenityPaymentLabel,
  getTextsAmenitySecondaryLabel,
} from '../../helpers/order';
import {CustomerType} from '../../ui-kit/RequestCard/types';
import {RewardType} from '../../queries/types/item';
import {InnerRequestCard} from './styles';
import {useTranslation} from 'react-i18next';
import {Order, StatusOrderType} from '../../queries/types/order';
import {editReviewDataT, TDataInvoices, TResCreateReview, TResEditOrder, TResManageOrder} from '../../hooks/order';
import {GQLOrderStatuses} from '../../graphql.schema';
import {SkeletonRequestCard} from './Sceleton/Card/SkeletonRequestCard';
import {TReview} from '../../types/reviews';
import {OrderHelpersT} from '../../types/messages';
import {getResize} from '../../helpers/file';
import {AmenityMessageRequestCard} from '../Amenities/AmenityRequestCard/AmenityMessageRequestCard';
import {getAmenityLabels} from '../../helpers/amenity';
import {Confirmation} from '../../ui-kit/Modals/Confirmation';
import {isMobile} from 'react-device-detect';
import {BasePortal} from '../../ui-kit/Popup';
import {IsModalProvider} from '../../containers/Navigation/IsModalProvider';
import {CloseButtonRound} from '../common/Modal/MessagesRequestModal';

type TProps = {
  activeOrder: Order;
  viewerId?: string;
  dataManageOrder: TResManageOrder;
  dataInvoice: TDataInvoices | null;
  invoiceLoading?: boolean;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  showStripePopup: () => void;
  stripeError?: boolean | null;
  isModal?: boolean;
  onClose?: () => void;
  setIsCanceled?: (val: boolean) => void;
  isCanceled?: boolean;
};

export const RequestCardAmenity: React.FC<TProps> = ({
  activeOrder: _activeOrder,
  viewerId,
  dataManageOrder,
  dataInvoice,
  invoiceLoading,
  orderHelpers,
  isModal,
  onClose,
  setIsCanceled,
  isCanceled,
}) => {
  const {t} = useTranslation();
  const activeOrder = {..._activeOrder, ...(isCanceled ? {status: StatusOrderType.rejected} : {})};
  const {manageOrder, loading: manageOrderLoading} = dataManageOrder;
  const amenity = useMemo(() => activeOrder.Amenity, [activeOrder]);
  const typeUser = useMemo(
    () => (viewerId === amenity.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester),
    [viewerId, amenity],
  );
  // const tagRewardType = getAmountLabel(event);
  const isRequester = typeUser === CustomerType.Requester;
  const params = useMemo(() => {
    return {
      rewardType: amenity.rewardType as RewardType,
      typeUser,
      orderStatus: activeOrder.status,
    };
  }, [amenity, activeOrder, typeUser]);
  const descr = getTextAmenityDescription(params);
  const paymentLabel = getTextsAmenityPaymentLabel(params);
  const secondaryLabel = getTextsAmenitySecondaryLabel(params);
  const {label: primaryBtnLabel, status: newStatus} = getAmenityDataButtonLabel(params);
  const [isShowModalDelete, setShowModalDelete] = useState<boolean>(false);

  const handleManageOrder = () => {
    // if (stripeError === true || stripeError === null) {
    //     showStripePopup();
    //     return;
    // }
    return manageOrder({orderId: activeOrder.objectId, status: newStatus});
  };

  const handleCancelOrder = async () => {
    await manageOrder({
      orderId: activeOrder.objectId,
      status: GQLOrderStatuses.canceled,
    });
    setIsCanceled?.(true);
    handleCloseDelete();
  };

  const listerInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Lister?.firstName || '',
      lastName: activeOrder?.Lister?.lastName,
      avatar: getResize(activeOrder?.Lister?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const requesterInfo = useMemo(() => {
    return {
      firstName: activeOrder?.Requester?.firstName || '',
      lastName: activeOrder?.Requester?.lastName,
      avatar: getResize(activeOrder?.Requester?.Avatar?.file?.url, 'md'),
    };
  }, [activeOrder]);
  const amenityInfo = useMemo(() => {
    return {
      avatar: getResize(activeOrder?.Amenity?.images[0]?.file?.url, 'sm'),
      name: activeOrder?.Amenity.name,
    };
  }, [activeOrder]);
  const handleOpenDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };
  // const creditInfo = getCreditInfo({activeOrder, isRequester, t});
  const tags = getAmenityLabels(t, amenity);
  const modalsText = useMemo(() => {
    return {
      delete: {
        title: t('events:modals.cancel.title'),
        okText: t('events:modals.cancel.okText'),
        cancelText: t('events:modals.cancel.cancelText'),
      },
    };
  }, []);
  if (invoiceLoading) {
    return <SkeletonRequestCard />;
  }
  return (
    <InnerRequestCard>
      {isModal && <CloseButtonRound onClose={onClose} />}
      <AmenityMessageRequestCard
        isDeleted={amenity?.isDeleted}
        paymentLabel={{
          title: t(paymentLabel.title, {value: dataInvoice?.total?.value}),
          descr: t(paymentLabel.descr),
        }}
        buttonLabel={{
          primary: {
            label: primaryBtnLabel ? t(primaryBtnLabel) : '',
            onClick: handleManageOrder,
            loading: manageOrderLoading,
          },
          secondary: {label: secondaryLabel ? t(secondaryLabel) : '', onClick: handleOpenDelete},
          hideBase: activeOrder.status === StatusOrderType.attending,
          disabled: orderHelpers.orderLoading,
        }}
        cancelled={{title: t('requests:buttons.cancelRequest')}}
        users={[listerInfo, requesterInfo]}
        descr={t(descr)}
        userType={viewerId === amenity?.Lister?.objectId ? CustomerType.Lister : CustomerType.Requester}
        cardType={activeOrder.status === StatusOrderType.completed ? 'review' : 'regular'}
        image={getResize(amenity?.images?.[0]?.file?.url, 'md') || ''}
        title={amenity?.name}
        opponentInfo={isRequester ? listerInfo : requesterInfo}
        amenityInfo={amenityInfo}
        tags={tags}
        isRequester={isRequester}
        buttonSelectText={t('requests:buttonSelectText')}
        guests={activeOrder?.attendeeGuests}
        startDate={activeOrder?.startTime}
      />
      {!isMobile && isShowModalDelete && (
        <Confirmation
          title={modalsText.delete.title}
          onClose={handleCloseDelete}
          okText={modalsText.delete.okText}
          onOk={handleCancelOrder}
          buttonType={'danger'}
          cancelText={modalsText.delete.cancelText}
        />
      )}
      {isMobile && isShowModalDelete && (
        <BasePortal>
          <IsModalProvider>
            <Confirmation
              title={modalsText.delete.title}
              onClose={handleCloseDelete}
              okText={modalsText.delete.okText}
              onOk={handleCancelOrder}
              buttonType={'danger'}
              cancelText={modalsText.delete.cancelText}
            />
          </IsModalProvider>
        </BasePortal>
      )}
    </InnerRequestCard>
  );
};
