import React from 'react';
import {useTranslation} from 'react-i18next';
import {SetItemsSearchParams} from '../../containers/Listings/types';
import {AddListingBtn, Hidden, PlusIcon} from './styles';
import {
  getListingOptions,
  getListingTypeParams,
  getShowListingsParams,
  isAdditionalListingOption,
} from '../../helpers/listings';
import {useViewerId} from '../../hooks/user';
import {SearchBlock} from '../common/SearchBlock/SearchBlock';
import {route} from '../../constants/routes';

interface SelectOptions {
  label?: string;
  value?: string;
}

interface Props {
  setSearchParams: SetItemsSearchParams;
  searchButtonText?: string;
}

export const Search: React.FC<Props> = ({setSearchParams}) => {
  const {t} = useTranslation();
  const listingOptions = getListingOptions(t);
  const viewerId = useViewerId('id');

  const onSearch = (searchText?: string, listingType?: SelectOptions) => {
    const searchParams = isAdditionalListingOption(listingType?.value)
      ? getShowListingsParams(listingType?.value, viewerId)
      : getListingTypeParams(listingType);

    setSearchParams([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        ...searchParams,
      },
    ]);
  };

  return (
    <Hidden>
      <SearchBlock
        title={t('search:labels.listings')}
        placeholder={t('search:labels.whatLooking')}
        options={listingOptions}
        onSearch={onSearch}
        moveLast
        buttons={
          <AddListingBtn to={route.createItem.get()} rounded width={'154px'}>
            <PlusIcon type={'plus'} stroke={'white'} width={'10px'} height={'10px'} />
            <span>{t('listings:buttons.form.create')}</span>
          </AddListingBtn>
        }
      />
    </Hidden>
  );
};
