import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';

import {LottieLoader} from '../../components/Loader/LottieLoader';
import {PageJoinCommunity} from './PageJoinCommunity';
import {route} from '../../constants/routes';
//import {TypeCommunity} from '../../types/auth';
import {TCommunity} from '../../types/community';
import {LoaderItemWrapper} from '../../ui-kit/Loader/styles';

import {useHomePage} from '../../hooks/user';
import PageHome from '../../components/Home/PageHome';
import {ApplyReferral} from './ApplyReferral';
import {EmptyTag} from '../MobileTabs';
import {NavContainer} from '../Navigation';
import {isFromApp} from '../../ui-kit/Navigation/mobile/styles';

const PageHomeContainer: React.FC = () => {
  const {
    getLink,
    pageLoading,
    referralUser,
    signUpPath,
    isAuth,
    lastVisited,
    loopEnabled,
    forwardLogin,
    forwardSignUp,
    forwardJoinIn,
    viewer,
    code,
    urlAlias,
    zeroCommunityAndNoInvite,
    haveCommunityAndNoInvite,
    defaultHomePage,
    aliasedIsCurrent,
    withoutAliasAndNotAuthorized,
    aliasedComm,
  } = useHomePage();
  const [lottie, setLottieLoaded] = useState(false);
  const lottieLoaded = isFromApp() ? true : viewer?.objectId ? lottie : true;
  const LoaderWrapper = isFromApp() ? NavContainer : EmptyTag;
  if (pageLoading || !lottieLoaded) {
    return (
      <LoaderWrapper>
        <LoaderItemWrapper>
          <LottieLoader $isVisible={!isFromApp()} loading={pageLoading} setLoaded={setLottieLoaded} />
        </LoaderItemWrapper>
      </LoaderWrapper>
    );
  }

  if (haveCommunityAndNoInvite) {
    return <Redirect to={getLink(route.pageUser.path, lastVisited.alias)} />;
  }

  if (zeroCommunityAndNoInvite) {
    return <Redirect to={route.pageUser.path} />;
  }

  if (defaultHomePage) {
    return <Redirect to={loopEnabled ? getLink(route.loop.path) : route.pageUser.path} />;
  }

  if (aliasedIsCurrent) {
    return <Redirect to={loopEnabled ? getLink(route.loop.path) : route.pageUser.path} />;
  }

  if (code) {
    return <ApplyReferral code={code} user={referralUser} forwardJoinIn={forwardSignUp} />;
  }

  if (withoutAliasAndNotAuthorized) {
    return <PageHome forwardLogin={forwardLogin} forwardJoinIn={forwardJoinIn} />;
  }

  return (
    <PageJoinCommunity
      aliasedComm={aliasedComm.data as TCommunity}
      urlAlias={urlAlias}
      isAuth={isAuth}
      signUpPath={signUpPath}
    />
  );
};

export default PageHomeContainer;
