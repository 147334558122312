import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Seo} from '../../components/SEO';
import {makeSeoKeywords} from '../../helpers/common';
import {currentCommunity, userPermissions} from '../../states/community';
import {typeUser as StateTypeUser} from '../../states/typeUser';
import {TypeCommunity} from '../../types/auth';
import {EmptyTag, MobContainerProps} from '../MobileTabs';
import {NavContainer} from '../Navigation';
import {PageManagerContainer} from './ManagerPage';
import {PageResidentContainer} from './ResidentPage';
import {PermissionsList} from '../../types/settings';

export const LocalBizPageContainer: React.FC<MobContainerProps> = ({hideNav}) => {
  const {t} = useTranslation();

  const typeUser = useRecoilValue(StateTypeUser);
  const {name: communityName} = useRecoilValue(currentCommunity) || {};
  const [permissions] = useRecoilState(userPermissions);

  const textSeo = t('common:seo.localBiz.localBiz', {
    text: communityName || '',
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);
  const canManage = useMemo(
    () => permissions.includes(PermissionsList.manageCompany) && typeUser === TypeCommunity.admin,
    [permissions, typeUser],
  );
  const Wrapper = hideNav ? EmptyTag : NavContainer;

  return (
    <Wrapper>
      <Seo title={textSeo} keywords={seoKeywords} />
      {(typeUser === TypeCommunity.manager || canManage) && <PageManagerContainer />}
      {(typeUser === TypeCommunity.resident || typeUser === TypeCommunity.admin) && !canManage && (
        <PageResidentContainer />
      )}
    </Wrapper>
  );
};
