import styled from 'styled-components';
import {Media, theme} from '../../ui-kit/theme';

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.grayscale._2};
  ${Media.down.l} {
    padding: 2px 22px 75px 22px;
    min-height: 700px;
  }
  ${Media.down.m} {
    padding: 0 12px 60px 12px;
  }
`;

export const ProfileSectionBlock = styled.div<{$bordered?: boolean; $another?: boolean}>`
  padding: 30px 0 44px 0;
  box-sizing: border-box;
  ${theme.font.primary.regular};
  ${Media.down.xxl} {
    padding: 30px 0 44px 0;
  }
  ${Media.down.xl} {
    padding: 54px 70px 44px 50px;
  }
  ${Media.down.l} {
    padding: 29px 16px 39px 12px;
  }
  ${Media.down.m} {
    padding: ${({$another}) => ($another ? '2px 0 0 0' : '29px 0 0 0')};
  }
  &.profile-reviews__tabs {
    .profile-section__card {
      & > div:first-of-type > div {
        & > span {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
`;

export const CardWrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
  & .intro__footer-labels {
    span {
      word-break: break-all;
    }
    ${Media.down.xl} {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      span {
        font-size: 16px;
      }
    }
    ${Media.down.s} {
      span {
        max-width: 100%;
      }
    }
    ${Media.down.xxs} {
      grid-template-columns: repeat(2, max-content);
      span {
        font-size: 16px;
      }
    }
  }
`;

export const ProfileSectionTitle = styled.p`
  ${theme.font.primary.regular};
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: ${({theme}) => theme.palette.text.octonary};
  margin-bottom: 12px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 10px;
  }
`;

// review

export const ReviewsSectionWrapper = styled.section`
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  ${Media.down.m} {
    padding: 15px 15px 15px 0;
  }
`;

export const ReviewWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding: 10px 0;
  position: relative;

  // &:not(:last-child)::after {
  //   content: '';
  //   position: absolute;
  //   height: 1px;
  //   width: 100%;
  //   bottom: 0;
  //   background-color: ${({theme}) => theme.palette.background.secondary};
  // }
`;

export const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
`;

export const ReviewLabelsWrapper = styled.ul`
  display: flex;
  gap: 5px;
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: ${({theme}) => theme.palette.text.octonary};
  ${({theme}) => theme.font.primary.semiBold};

  & svg {
    width: 12px;
    height: 12px;
  }

  & :last-child:not(:only-child) {
    margin-left: 15px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: -11px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      background-color: ${({theme}) => theme.palette.background.octonary};
      border-radius: 50%;
    }
  }
`;

export const ReviewLabel = styled.div``;
