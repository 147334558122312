import React from 'react';
import {SetEventsSearchParams} from '../../containers/Events/types';
import {useTranslation} from 'react-i18next';
import {MobileSearchBlock} from '../common/SearchBlock/MobileSearchBlock';

interface TProps {
  setSearchParams: SetEventsSearchParams;
}

export const MobileSearch: React.FC<TProps> = ({setSearchParams}) => {
  const {t} = useTranslation();

  const onSearch = (searchText?: string) => {
    setSearchParams([
      {
        name: {
          matchesRegex: searchText?.toLocaleLowerCase() ?? '',
          options: 'i',
        },
        // ...searchParams,
      },
    ]);
  };

  return <MobileSearchBlock placeholder={t('search:labels.whatLooking')} onSearch={onSearch} />;
};
