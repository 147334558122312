import styled from 'styled-components';

export const ModalOverlayFullScreen = styled.div`
  display: ${($isForEmoji) => ($isForEmoji ? `block` : `none`)};
  z-index: 298;
  position: fixed;
  top: -50%;
  left: -50%;
  height: 200vh;
  width: 200vw;
`;

export const ModalWindow = styled.div<{offset?: number}>`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({offset}) => `translateY(${offset}px`});
  display: flex;
  justify-content: flex-end;
  z-index: 299;
  cursor: initial;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  position: relative;
  left: -45px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
`;
