import {CurrentPlanCardContainer, PlanDescription} from './styles';
import React, {useMemo} from 'react';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {Br} from '../../../ui-kit/Typography/Br';
import {Text} from '../../../ui-kit/Typography/Text';
import {useTranslation} from 'react-i18next';
import {PaymentPlan, PaymentPlanName} from '../../../queries/types/payments';
import {LinkButton} from '../../../ui-kit/Button/LinkButton';
import {useLinks} from '../../../hooks/common';
import {route} from '../../../constants/routes';
import {MainPlans} from '../../../constants/community';

interface Props {
  currentPlan: PaymentPlan;
}

export const CurrentPlanCard: React.FC<Props> = ({currentPlan}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  const currentPlanName = currentPlan.name.toLowerCase();
  const planName = useMemo(
    () =>
      MainPlans.includes(currentPlanName)
        ? t(`community:plan.type.${currentPlanName}`)
        : t(`community:plan.type.custom`),
    [MainPlans],
  );
  const planType = planName.toLowerCase();

  if (planType === PaymentPlanName.custom) return null;

  return (
    <CurrentPlanCardContainer>
      <IconSvg
        type={planType === PaymentPlanName.starter || planType === PaymentPlanName.basic ? 'rocket' : 'plane'}
        stroke={'aqua'}
      />
      <Br indent={8} />
      <Text size={16} weight={'medium'} color={'octonary'}>
        {t(`settings:payments.currentPlanCard.title.${planType}`)}
      </Text>
      <Br indent={9} />
      <PlanDescription>{t(`settings:payments.currentPlanCard.descriptions.${planType}`)}</PlanDescription>
      <Br indent={19} />
      <LinkButton to={getLink(route.plansAndPricing.get())}>
        {t('settings:payments.currentPlanCard.buttons.upgrade')}
      </LinkButton>
    </CurrentPlanCardContainer>
  );
};
