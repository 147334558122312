import React from 'react';
import {InfoIcon, PrimaryEarnContainer, SecondaryEarnContainer} from '../styles';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Br} from '../../../../ui-kit/Typography/Br';
import {TFees} from '../../../../queries/types/payments';
import {IGetCurrValue} from '../../../../types/common';
import {getCFeeAbs, getCFeePrc} from '../../../../helpers/payment';

interface Props {
  isHaveCommunityFee?: boolean;
  fees?: {
    serviceFees?: TFees;
    salesFees?: TFees;
    rentFees?: TFees;
  };
  getSignValue: IGetCurrValue;
}

export const Earn: React.FC<Props> = ({isHaveCommunityFee, fees, getSignValue}) => {
  const {t} = useTranslation();

  const {rentFees, serviceFees, salesFees} = fees || {};

  return !isHaveCommunityFee ? (
    <PrimaryEarnContainer>
      <Text color={'octonary'} size={12}>
        {t('community:plan.earnInfo.title')}
      </Text>
      <Br indent={6} />
      <Text color={'octonary'} size={16}>
        {t('community:plan.earnInfo.notApplicable')}
      </Text>
    </PrimaryEarnContainer>
  ) : (
    <SecondaryEarnContainer>
      <Text color={'octonary'} size={12}>
        {t('community:plan.earnInfo.title')}
      </Text>
      <Br indent={2} />
      <Text color={'octonary'} size={16}>
        {Math.max(getCFeePrc(rentFees), getCFeePrc(serviceFees), getCFeePrc(salesFees)).toFixed(2)}
        {'% + '}
        {getSignValue(Math.max(getCFeeAbs(rentFees), getCFeeAbs(serviceFees), getCFeeAbs(salesFees)).toFixed(2))}
      </Text>
      <Br indent={0} />
      <Text color={'octonary'} size={12}>
        {t('community:plan.earnInfo.maxPerTransaction')}
      </Text>
      <InfoIcon />
    </SecondaryEarnContainer>
  );
};
