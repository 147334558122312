import React from 'react';
import {ListingPairWrapper, ListingSkeletonWrapper, SkeletonItems} from './styles';
import {MemoLoadingSkeleton} from './LoadingSkeleton';

export const ItemsSkeleton: React.FC = () => {
  return (
    <ListingSkeletonWrapper>
      <MemoLoadingSkeleton width={36} height={36} circle={true} />
      <ListingPairWrapper>
        <MemoLoadingSkeleton width={160} height={6} />
        <MemoLoadingSkeleton width={110} height={9} />
        <MemoLoadingSkeleton width={50} height={7} />
      </ListingPairWrapper>
    </ListingSkeletonWrapper>
  );
};

export const ItemsSkeletonSection: React.FC = () => {
  return (
    <>
      <ItemsSkeleton />
      <ItemsSkeleton />
      <ItemsSkeleton />
    </>
  );
};

export const ItemsSkeletonLoaderSection: React.FC = () => {
  return (
    <SkeletonItems>
      <ItemsSkeleton />
      <ItemsSkeleton />
      <ItemsSkeleton />
    </SkeletonItems>
  );
};
