import React, {FC} from 'react';
import {RangeDatePickerValues} from '../Search/types';
import {status} from '../ProfileIntro/types';

export enum RewardType {
  coffee = 'coffee',
  free = 'free',
  fee = 'fee',
}

export enum ListingType {
  rent = 'rent',
  sell = 'sell',
  service = 'service',
}

export enum CustomerType {
  Lister = 'lister',
  Requester = 'requester',
}

export enum OrderStatus {
  Draft = 'draft',
  Request = 'request',
  Accepted = 'accepted',
  Paid = 'paid',
  Performing = 'performing',
  Completed = 'completed',
  Rejected = 'rejected',
  Failed = 'failed',
}

export enum ReviewState {
  Writing = 'writing',
  Ready = 'ready',
}

export enum ReviewTypes {
  Rating = 'rating',
  Review = 'review',
  ReviewReady = 'reviewReady',
  Check = 'check',
  Cancelled = 'cancelled',
}

export enum RequestCardType {
  Free = 'free',
  Coffee = 'coffee',
  Fee = 'fee',
  Sell = 'sell',
  Service = 'service',
  Rating = 'rating',
  Review = 'review',
  Refund = 'refund',
  Check = 'check',
}

export enum TagType {
  listingType = 'listingType',
  plain = 'plain',
  sell = 'sell',
  purchased = 'purchased',
  coffee = 'coffee',
  free = 'free',
}

export enum ListingCardType {
  Check = 'check',
  Request = 'request',
}

export enum SendMessageStatus {
  Send = 'send',
  Write = 'write',
  Done = 'done',
}

export interface DataLabelEntry {
  title: string;
  // value: string;
}

export interface ButtonLabelEntry {
  label: string;
  onClick?: (props?: any) => void;
  loading?: boolean;
}

export interface PaymentEntry {
  label: string;
  value: string | number;
}

export interface CheckEntry {
  label: string;
  value: string;
}

export interface TagEntryType {
  label: string;
  type: TagType;
}

export interface ReviewInfo {
  title: string;
  descr: string;
  completedDescr: string;
  noReview: string;
  input: {
    user: {
      placeholder: string;
      value?: string;
      onChange?: (value: string) => void;
    };
    item: {
      placeholder: string;
      value?: string;
      onChange?: (value: string) => void;
    };
  };
}

export interface RatingInfo {
  title: string;
  descr: string;
  input: {
    user: {
      value?: number;
      onChange?: (t: number) => void;
    };
    item: {
      value?: number;
      onChange?: (t: number) => void;
    };
  };
}

export interface ReviewT {
  id?: string;
  objectId?: string;
  User?: UserType;
  Item?: Partial<ItemType>;
  subject?: string;
  text?: string;
  stars?: number;
}

export interface PaymentsType {
  total?: PaymentEntry;
  price?: PaymentEntry;
  serviceFee?: PaymentEntry;
  refundFee?: PaymentEntry;
  period?: PaymentEntry;
  paymentProcessing?: PaymentEntry;
  tax?: PaymentEntry;
  discount?: PaymentEntry;
}

export interface CheckType {
  type: string;
  id: string;
  date: string;
  entries: CheckEntry[];
  buttons: {
    viewCard: ButtonLabelEntry;
    paymentType: ButtonLabelEntry;
  };
}

export interface DateLabelProps {
  from: DataLabelEntry;
  to: DataLabelEntry;
  dates: RangeDatePickerValues[];
  setDates?: (dates: RangeDatePickerValues[]) => void;
  canEdit: boolean;
  placeholder?: {default: string; selectStart: string; selectEnd: string; start: string; end: string};
  submitOnEdit?: () => void;
  small?: boolean;
  time?: Date | null;
  duration?: number | null;
  durationUnit?: {
    single?: string;
    many?: string;
  };
  captions?: {
    date?: string;
    dateDuration?: string;
    time?: string;
    duration?: string;
  };
  buttonSelectText?: string;
  busyDates?: Date[];
  pricePeriod?: PricePeriodType | null;
  showWarnPopup?: () => void;
  accessRequest?: boolean;
}

export interface PaymentLabelType {
  title: string;
  descr: string;
}

export interface ButtonLabelType {
  primary: ButtonLabelEntry;
  secondary: ButtonLabelEntry;
  primaryReview?: ButtonLabelEntry;
  secondaryReview?: ButtonLabelEntry;
  hideBase?: boolean;
  disabled?: boolean;
}

export interface ButtonLabelProps extends ButtonLabelType {
  type: 'primary' | 'secondary';
}

export interface CancelledType {
  title: string;
}

export type editOrderDataT = {
  onSubmit: () => Promise<boolean>;
  error?: {message?: string} | null;
  dates: TDateValues[];
  onChange: (dates: TDateValues[]) => void;
  datePickerData: datePickerDataT;
};

type creditOption = {label: string; value?: string | number};

export type CreditInfoT = {
  duration: creditOption;
  dates: creditOption;
  requestedBy: creditOption;
  listingBy: creditOption;
  DateAndTime: creditOption;
  soldBy: creditOption;
  receivedBy: creditOption;
  offeredBy: creditOption;
};

export interface RequestCardProps {
  Gallery?: FC<any>;
  Item?: FC<any>;
  title?: string;
  tags: TagEntryType[];
  image: string;
  descr: string;
  maxPeriod?: number;
  cardType: 'regular' | 'review';
  userType: CustomerType;
  listingType: ListingType;
  rewardType?: RewardType;
  reviewType: ReviewTypes;
  handlePushToListing?: () => void;
  users: [UserType, UserType];
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  buttonLabel: ButtonLabelType;
  payments: PaymentsType[];
  rating: RatingInfo;
  cancelled: CancelledType;
  review: ReviewInfo;
  check?: CheckType;
  pickerLabels?: DatePickerLabels;
  pricePeriod?: PricePeriodType;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  editOrderData?: editOrderDataT | null;
  opponentInfo: UserType;
  itemInfo: ItemType;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  isDeleted?: boolean;
  durationUnit?: {
    single?: string;
    many?: string;
  };
  captions?: {
    date?: string;
    dateDuration?: string;
    time?: string;
    duration?: string;
  };
  buttonSelectText?: string;
  toggleCredit?: (b: boolean) => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
  textMode?: boolean;
  dateInfoLabels?: {
    duration: string;
    from: string;
    until: string;
    hours: string;
  };
}

export interface CardProps {
  Gallery?: FC<any>;
  Item?: FC<any>;
  title?: string;
  tags: TagEntryType[];
  image: string;
  descr: string;
  userType: CustomerType;
  listingType: ListingType;
  rewardType?: RewardType;
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  buttonLabel: ButtonLabelType;
  payments: PaymentsType[];
  pickerLabels?: DatePickerLabels;
  pricePeriod?: PricePeriodType;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  editOrderData?: editOrderDataT | null;
  readyReviewsData?: ReviewT[] | null;
  isDeleted?: boolean;
  durationUnit?: {
    single?: string;
    many?: string;
  };
  captions?: {
    date?: string;
    dateDuration?: string;
    time?: string;
    duration?: string;
  };
  buttonSelectText?: string;
  toggleCredit?: (b: boolean) => void;
  handlePushToListing?: () => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
  textMode?: boolean;
  dateInfoLabels?: {
    duration: string;
    from: string;
    until: string;
    hours: string;
  };
}

export type DatePickerLabels = {
  labelDate?: string;
  labelPickUp?: string;
  labelTime?: string;
  labelDuration?: string;
  labelHours?: string;
};

export type DatePickerPlaceholders = {
  addTime?: string;
  addDate?: string;
  addHours?: string;
};

export interface CardTagsProps {
  tags: TagEntryType[];
}

export interface PaymentLabelProps extends PaymentLabelType {
  rewardType?: RewardType;
  onClick?: () => void;
}

export interface PaymentInfoProps {
  payments: PaymentsType[];
  rewardType?: string;
}

export interface CardRatingSectionProps {
  info: [UserType, ItemType];
  rating: RatingInfo;
  isRequester?: boolean;
}

export interface CardReviewSectionProps {
  info: [UserType, ItemType];
  reviewState: ReviewState;
  review: ReviewInfo;
  rating: RatingInfo;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
}

export interface ReviewType {
  rating: number;
  review: string;
}

export interface UserType {
  avatar: string;
  firstName: string;
  lastName: string;
  reviews?: ReviewType;
}

export interface ItemType {
  avatar?: string;
  name: string;
  reviews?: ReviewType;
}

export interface RequestCardReviewBaseProps {
  title?: string;
  buttonLabel: ButtonLabelType;
  cardTopMessage?: JSX.Element;
  dateInfoLabels?: dateInfoLabelsT;
}

export interface CardCheckProps extends RequestCardReviewBaseProps {
  descr: string;
  tags: TagEntryType[];
  checkInfo?: CheckType;
  payments: PaymentsType[];
}

export interface CardRatingProps extends RequestCardReviewBaseProps {
  descr: string;
  tags: TagEntryType[];
  rating: RatingInfo;
  rewardType?: RewardType;
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  itemInfo: ItemType;
  opponentInfo: UserType;
  listingType: ListingType;
  pricePeriod?: PricePeriodType;
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  toggleCredit?: (b: boolean) => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
}

export interface CardReviewProps extends RequestCardReviewBaseProps {
  descr: string;
  tags: TagEntryType[];
  rewardType?: RewardType;
  reviewType: ReviewTypes;
  opponentInfo: UserType;
  itemInfo: ItemType;
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  review: ReviewInfo;
  rating: RatingInfo;
  listingType: ListingType;
  pricePeriod?: PricePeriodType;
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  toggleCredit?: (b: boolean) => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
}

export interface CardCancelledProps extends RequestCardReviewBaseProps {
  descr: string;
  tags: TagEntryType[];
  rewardType?: RewardType;
  payments: PaymentsType[];
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  cancelled: CancelledType;
  listingType: ListingType;
  pricePeriod?: PricePeriodType;
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  toggleCredit?: (b: boolean) => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
}

export type dateInfoLabelsT = {
  duration: string;
  from: string;
  until: string;
  hours: string;
};
export interface ReviewProps {
  title?: string;
  tags: TagEntryType[];
  descr: string;
  maxPeriod?: number;
  userType: CustomerType;
  listingType: ListingType;
  rewardType?: RewardType;
  reviewType: ReviewTypes;
  users: [UserType, UserType];
  dateLabel: DateLabelProps;
  paymentLabel: PaymentLabelType;
  buttonLabel: ButtonLabelType;
  payments: PaymentsType[];
  rating: RatingInfo;
  cancelled: CancelledType;
  review: ReviewInfo;
  check?: CheckType;
  opponentInfo: UserType;
  itemInfo: ItemType;
  pricePeriod?: PricePeriodType;
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  smallPicker?: boolean;
  readyReviewsData?: ReviewT[] | null;
  isRequester?: boolean;
  reviewLoading?: boolean;
  toggleCredit?: (b: boolean) => void;
  showCredit?: boolean;
  creditInfo?: CreditInfoT;
  dateInfoLabels?: dateInfoLabelsT;
}

export type datePickerDataT = {
  time?: {
    value?: Date | null;
    set: (t: Date) => void;
  };
  duration?: {
    value?: number | '' | undefined;
    set: (t: number | '') => void;
  };
  startdate?: {
    value?: Date | null;
    set: (t: Date) => void;
  };
};

export interface ListingCardProps {
  title: string;
  descr: string;
  canEdit: boolean;
  type: ListingCardType;
  dateLabel: DateLabelProps;
  buttonLabel: ButtonLabelEntry;
  tags: TagEntryType[];
  payments: PaymentsType[];
  message?: {
    error?: string | null;
    success?: string | null;
  };
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  listingType?: ListingType;
  pricePeriod?: PricePeriodType | null;
  datePickerData?: datePickerDataT;
  smallPicker?: boolean;
  successOrder?: string | null;
  requestSent?: string;
  pathLink?: string;
  linkTitle?: string;
  durationUnit?: {
    single?: string;
    many?: string;
  };
  captions?: {
    date?: string;
    dateDuration?: string;
    time?: string;
    duration?: string;
  };
  buttonSelectText?: string;
  isAvailable?: boolean;
  notAvailableText?: string;
  disabled?: boolean;
  busyDates?: Date[];
  showWarnPopup?: () => void;
  accessRequest?: boolean;
  isCustom?: boolean;
  bookingLabel?: string;
  isNotSelected?: boolean;
  rewardType?: string;
}

export interface MessageCardUserType extends Omit<UserType, 'reviews'> {
  verified: string;
  reviews: string;
  isOnline?: boolean;
}

export interface MessageRequestCardProps {
  descr: string;
  buttonTitle: string;
  linkTitle: string;
  messageSent?: string;
  type: SendMessageStatus;
  user?: MessageCardUserType;
  handleWriteMsg: (e: React.SyntheticEvent) => void;
  handleSendMsg: (e: React.SyntheticEvent) => void;
  pathLink: string;
  itemTitle?: string;
  message: {
    placeholder: string;
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    loading?: boolean;
  };
  profileLink?: string;
  hideReviews?: boolean;
  canSend?: boolean;
  userLabel?: {
    status: status;
    name?: string;
  };
  hideVerified?: boolean;
  userName?: string;
  isPreview?: boolean;
  isPublicView?: boolean;
  goSignUp?: () => void;
}

export interface UserWidgetProps {
  user?: MessageCardUserType;
  hideReviews?: boolean;
  hideVerified?: boolean;
  label?: {
    status: status;
    name?: string;
  };
  userName?: string;
}

export enum PickDateTypes {
  rangeDate = 'rangeDate',
  fullDate = 'fullDate',
  dateTime = 'dateTime',
  TimeAndDuration = 'TimeAndDuration',
}

export type DateTimePickerProps = {
  pickerLabels?: DatePickerLabels;
  pickerPlaceholders?: DatePickerPlaceholders;
  date?: Date | null;
  time?: Date | null;
  duration?: number | '' | null;
  pickType: PickDateTypes;
  setDate?: (date: Date) => void;
  setTime?: (date: Date) => void;
  canEdit: boolean;
  offsetBottom?: number;
  setDuration?: (val: number | '') => void;
  smallPicker?: boolean;
  submitOnEdit?: () => void;
  durationUnit?: {
    single?: string;
    many?: string;
  };
  captions?: {
    date?: string;
    dateDuration?: string;
    time?: string;
    duration?: string;
  };
  buttonSelectText?: string;
  busyDates?: Date[];
  busyTimes?: Date[];
  filterDate?: (d: Date) => boolean;
  filterTime?: (d: Date) => boolean;
  timeIntervals?: number;
  hidePastTimes?: boolean;
  includeDates?: Date[];
};

export enum PricePeriodType {
  month = 'month',
  week = 'week',
  hour = 'hour',
  day = 'day',
  fixed = 'fixed',
}

export type TDateValues = Date | null;
