import {PointerFile} from '../../types/common';
import {Community} from './community';
import {ParseObject} from './parse';
import {User} from './user';

export enum TTypeOfDocument {
  default = 'default',
  custom = 'custom',
}
export interface Document extends ParseObject {
  name: string;
  DocFile: PointerFile;
  Cover: PointerFile;
  User: User;
  Community: Community;
  isPublished: boolean;
  isDeleted: boolean;
  type?: TTypeOfDocument;
  alias?: string;
}
