import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {FormCommunity} from '../../../../components/Account/Create/Forms/Community';
import {LottieLoader} from '../../../../components/Loader/LottieLoader';
import {Steps} from './Steps';
import {route} from '../../../../constants/routes';
import {getRouteStepCreate} from '../../../../helpers/account';
import {isPaymentPlanSelected, isViewerCards} from '../../../../helpers/payment';
import {isProfileCreated} from '../../../../helpers/user';
import {currentCommunity} from '../../../../states/community';
import {TypeCommunity} from '../../../../types/auth';

import {useLinks} from '../../../../hooks/common';
import {useCommunity, useUpdateCommunity} from '../../../../hooks/community';
import {useViewerCards} from '../../../../hooks/payments';
import {useViewer} from '../../../../hooks/user';
import {CommunityTypes} from '../../../../helpers/community';

const CreateCommunityContainer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const {push} = useHistory();
  const viewer = useViewer();
  const {data: firstCommunity, communities, loading: loadingCmnt} = useCommunity(viewer?.id, TypeCommunity.manager);
  const [currComm] = useRecoilState(currentCommunity);
  const {cards, loading: loadingCard} = useViewerCards(viewer?.id);
  const {getLink} = useLinks();
  const isProfile = isProfileCreated(viewer);
  const isCreate = !!communities.filter((el) => !!el?.alias)?.length;
  const community = currComm?.objectId ? firstCommunity : currComm;

  const isPlan = isPaymentPlanSelected(community);
  const isAddCard = isViewerCards(cards);

  const params = useUpdateCommunity({
    initialState: {
      name: isCreate ? '' : community?.name,
      id: community?.id,
      Subscr: community?.Subscr,
      type: CommunityTypes.building,
    },
    isCreate: isCreate,
    onSuccess: () => push(getLink(route.inviteCommunity.path)),
  });

  useEffect(() => {
    if (!viewer || loadingCmnt || loadingCard) {
      return setLoading(true);
    }
    return setLoading(false);
  }, [viewer, loadingCmnt, loadingCard]);

  useEffect(() => {
    if (!viewer || !community || !cards) return;

    const successPath = getLink(
      getRouteStepCreate({
        isProfile,
        isPlan,
        isAddCard,
      }),
    );
    successPath && push(successPath);
  }, [viewer, community, cards]);

  if (loading && (!viewer || !community)) {
    return <LottieLoader $isVisible={loading} allScreen={true} />;
  }

  return (
    <Steps step={'community'}>
      <FormCommunity params={params} />
    </Steps>
  );
};
export default CreateCommunityContainer;
