import {useEffect, useMemo, useState} from 'react';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import Parse from 'parse';
import {useTranslation} from 'react-i18next';
import {route} from '../constants/routes';

import {
  AuthTab,
  CreateProfileSteps,
  LogInValues,
  PhoneVerifySteps,
  SignUpValues,
  TypeCommunity,
  GoogleParamsType,
  FBparamsType,
  UserSocialDataT,
} from '../types/auth';
import {useSetRecoilState, useRecoilState, useRecoilValue} from 'recoil';
import {
  ChangePasswordQuery,
  DirectAddMember,
  DirectAddMemberRequest,
  DirectAddMemberResponse,
  LogInQuery,
  LogInRequestType,
  LogInResponseType,
  ResetPasswordQuery,
  SignOutQuery,
  SignOutResponseType,
  SignUpQuery,
  SignUpRequestType,
  SignUpResponseType,
  TChangePasswordRequest,
  TChangePasswordResponse,
  TResetPasswordRequest,
  TResetPasswordResponse,
  VerifyEmail,
  VerifyEmailRequest,
  VerifyEmailResponse,
  ViewerQuery,
  ViewerResponseType,
  SignWithResponseType,
  SignWithFBRequestType,
  SignWithGoogleRequestType,
  SignWithAppleRequestType,
  SignWithSocial as SignUpWithQuery,
} from '../queries/auth';
import {session as sessionState} from '../states/session';
import {setToken} from '../libs/auth';
import {localeType} from '../libs/i18nextUtils';
import {toParseLogInUser, toParseSignUpUser} from '../helpers/auth';
import {FormValidation} from '../helpers/FormValidation';
import {FormErrorFields, useError, useFormError} from './error';
import {CommunityRequestType, CreateCommunityQuery, CreateCommunityResponseType} from '../queries/community';
import {useCheckIsSupport, useGenerateUsername, useSetUserPushNotifications, useViewer} from './user';
import {isString} from '../helpers/validations';
import {
  aliasCommunity,
  aliasPart,
  currentCommunity,
  inviteToCommunityState,
  skipParams,
  userCommunities,
  userPermissions,
  passCodeToCommunity,
  inviteReferralState,
} from '../states/community';
import {getCurrentCommunity, toStateCommunity} from '../helpers/community';
import {typeUser} from '../states/typeUser';
import {getTypeUser} from '../helpers/user';
import {LocalStorageItems, SUPPORT_EMAIL_MASK} from '../constants/common';
import {useSetUserLang} from './language';
import {useHistory, useParams} from 'react-router-dom';
import {RNMessages, SendMessageToRNApp, checkFailedFetchError} from '../helpers/common';
import {useUpdateProfileForm} from './settings';
import {Profile} from '../types/profile';
import {AddressFormSchema} from '../components/Settings/schemas';
import {AddressFormData} from '../components/Account/Create/Forms/AddressForm';
import {Session} from '../queries/types/auth';
import {analyticsTrackFN} from '../helpers/account';
import {useResetPreload} from './preload';
import {useLinks} from './common';
import {UpdateProfileResponseType, UpdateProfileShort, UpdateProfile} from '../queries/user';
import {User} from '../queries/types/user';
import {useGetCommunity, useGetListCommunities, useJoinToCommunity} from './community';
import {signUpRedirect} from '../states/navState';
import {AccessType} from '../constants/community';
import {CreateUserReferralParams, createUserReferral} from '../queries/referral';
import {useGoogleAuth, useFacebookAuth, useAppleAuth} from './social';
import {useUpdateProfilePhoto} from './user';

export function useFetchSession(alias?: string | null) {
  const setSession = useSetRecoilState(sessionState);
  const [mounted, setMounted] = useState(false);
  const setTypeUser = useSetRecoilState(typeUser);
  const setCommunities = useSetRecoilState(userCommunities);
  const setCommunity = useSetRecoilState(currentCommunity);
  //const setPermissions = useSetRecoilState(userPermissions);
  const client = useApolloClient();
  //const getPermissions = useGetUserPermissionsClient();
  const {refetch: getSession} = useQuery<ViewerResponseType>(ViewerQuery, {
    skip: true,
    fetchPolicy: 'no-cache',
    ssr: false,
  });

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
  }, []);

  useEffect(() => {
    const setData = async () => {
      try {
        const response = await getSession();
        const viewer = response?.data?.viewer;
        const isSupport = !!response?.data?.viewer?.user?.email?.toLocaleLowerCase()?.includes(SUPPORT_EMAIL_MASK);
        const sessionToken = response?.data?.viewer?.sessionToken;
        if (!sessionToken) {
          setToken(null);
          setSession(null);
          return null;
        }
        if (!viewer?.user?.objectId) return;
        setToken(sessionToken);
        setSession(viewer);
        if (isSupport) {
          setTypeUser(TypeCommunity.resident);
          return;
        }
        const {community, communities} = await getCurrentCommunity(
          viewer?.user?.objectId,
          client,
          isSupport,
          viewer?.user?.lastVisitedCommunity,
          alias,
        );
        if (!community?.objectId) {
          setCommunity(null);
          setTypeUser(TypeCommunity.resident);
          return;
        }
        /*const permissions = (await getPermissions({
          userId: viewer?.user?.objectId,
          communityId: community?.objectId,
        })) as PermissionsList[];*/
        const userType = getTypeUser(viewer.user.objectId, community);
        //const permissionsList = userType === TypeCommunity.resident ? [] : permissions;
        //setPermissions(permissionsList);
        setTypeUser(userType);
        setCommunities(communities?.map(toStateCommunity));
        setCommunity(toStateCommunity(community));
      } catch (e) {
        if (checkFailedFetchError(e)) return;
        setSession(null);
        return null;
      }
    };
    setData();
  }, [mounted]);
}

export const useAuthPath = () => {
  return {
    logInPath: route.auth.get({tab: AuthTab.logIn}),
    signUpPath: route.auth.get({tab: AuthTab.signUp}),
  };
};

export interface ISignUpRes {
  values: Partial<SignUpValues>;
  onChange: (next: {name: keyof SignUpValues; value: SignUpValues[keyof SignUpValues]}) => void;
  onSubmit: () => Promise<boolean>;
  error: FormErrorFields<SignUpValues>;
  loading: boolean;
}

type SignUpType = (options?: {
  initialState?: Partial<SignUpValues>;
  onSuccess?: (id?: string) => void;
  onError?: () => void;
}) => ISignUpRes;

const SignUpSchema = FormValidation.schema<Partial<SignUpValues>>({
  firstName: FormValidation.string('error:firstName'),
  lastName: FormValidation.string('error:lastName'),
  work: FormValidation.handler((value, data) => {
    if (data.typeCommunity === TypeCommunity.resident) return true;
    return Boolean(value);
  }, 'error:nameCommunity'),
  email: FormValidation.string().email('error:email'),
  password: FormValidation.string().length({gte: 6}, 'error:password'),
  confirmPassword: FormValidation.handler((value, data) => {
    return data.password === value;
  }, 'error:matchPassword'),
});

const useSuccessRegister = () => {
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const [inviteState] = useRecoilState(inviteToCommunityState);
  const [passCodeState] = useRecoilState(passCodeToCommunity);
  const [deviceToken, setDeviceToken] = useState<null | string>(null);
  const {subscribeUserForPush} = useSetUserPushNotifications();

  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setDeviceToken(key);
    return key;
  };

  useEffect(() => {
    getDeviceToken();
  }, []);

  const successRegister = async (viewer: User, accountType: string, provider?: string) => {
    try {
      SendMessageToRNApp(
        JSON.stringify({
          [RNMessages.userData]: {...viewer, communityInfo: {...aliasedComm}, passCode: passCodeState?.passCode},
        }),
      );
      SendMessageToRNApp(RNMessages?.authorized);
      const dToken = await getDeviceToken();
      await subscribeUserForPush({userId: viewer.objectId, token: deviceToken || dToken});
      analyticsTrackFN('User Registered', {
        firstName: viewer?.firstName,
        lastName: viewer?.lastName,
        accountType, // Member vs Manager
        inviteCode: inviteState ? inviteState?.inviteId : passCodeState ? passCodeState?.passCode : false, // false if private code is not provided
        inviteCommunity: aliasedComm ? aliasedComm?.type : 'none', // building, community, neighbourhood
        source: provider,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {successRegister};
};

export const useSignUp: SignUpType = (options) => {
  const setTypeUser = useSetRecoilState(typeUser);
  const setSession = useSetRecoilState(sessionState);
  const [values, setValue] = useState<Partial<SignUpValues>>(options?.initialState || {});
  const [error, setError] = useFormError<SignUpValues>();
  const [CreateCommunityRequest, {loading: CreateCommunityLoading}] = useMutation<
    CreateCommunityResponseType,
    CommunityRequestType
  >(CreateCommunityQuery);
  const [SignUpRequest, {loading: SignUpLoading}] = useMutation<SignUpResponseType, SignUpRequestType>(SignUpQuery);
  const generateUsername = useGenerateUsername();
  const {successRegister} = useSuccessRegister();

  const loading = useMemo(() => CreateCommunityLoading || SignUpLoading, [CreateCommunityLoading, SignUpLoading]);

  const changeValue = (next: {name: keyof SignUpValues; value: SignUpValues[keyof SignUpValues]}): void => {
    setError(null);
    setValue({...values, [next.name]: next.value});
  };

  const signUp = async (): Promise<boolean> => {
    try {
      const _values = {
        ...values,
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
      } as Partial<SignUpValues>;

      if (!SignUpSchema.validate<SignUpValues>(_values) || !values?.typeCommunity) return false;

      const ParseValues = await toParseSignUpUser({
        ..._values,
        username:
          (await generateUsername((_values.firstName as string).toLowerCase())) ??
          (_values.firstName as string).toLowerCase(),
      } as SignUpValues);
      const result = await SignUpRequest({
        variables: {fields: ParseValues},
      });
      const data = result?.data?.signUp?.viewer;
      if (!data) throw new Error('error:signUp');

      await setToken(data.sessionToken);
      await setSession(data);
      const accountType = _values.typeCommunity === TypeCommunity.manager ? _values.typeCommunity : 'Member';
      await successRegister(data.user, accountType, 'email');
      await Parse.User.become(data.sessionToken);
      await setTypeUser(values.typeCommunity);
      if (values.typeCommunity === TypeCommunity.manager) {
        const resCmt = await CreateCommunityRequest({
          variables: {
            fields: {
              name: values.work as string,
              Owner: {link: data.user.objectId as string},
            },
          },
        });
        const dataCmt = resCmt?.data?.createCommunity?.community;
        if (!dataCmt) throw new Error('error:createCommunity');
      }
      options?.onSuccess?.(data.user.objectId);
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        analytics.identify(data?.user?.email, {
          name: `${data?.user?.firstName} ${data?.user?.lastName}`,
          email: data?.user?.email,
        });
      } catch (e) {
        console.log(e);
      }
    } catch (error) {
      setError(error);
      return false;
    }
    return true;
  };

  return {values, onChange: changeValue, onSubmit: signUp, error, loading};
};

const useSuccessLogin = () => {
  const setSession = useSetRecoilState(sessionState);
  const setTypeUser = useSetRecoilState(typeUser);
  const setCommunity = useSetRecoilState(currentCommunity);
  //const setPermissions = useSetRecoilState(userPermissions);
  const client = useApolloClient();
  const {subscribeUserForPush} = useSetUserPushNotifications();
  const [deviceToken, setDeviceToken] = useState<null | string>(null);
  const setCommunities = useSetRecoilState(userCommunities);
  //const getPermissions = useGetUserPermissionsClient();
  const {syncLang} = useSetUserLang();
  const {onLoginRedirect, loading} = useLoginRedirects();

  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setDeviceToken(key);
    return key;
  };

  useEffect(() => {
    getDeviceToken();
  }, []);

  const successLogin = async (viewer: Session, isRemember?: boolean, values?: LogInValues) => {
    try {
      await setToken(viewer.sessionToken);
      await setSession(viewer);
      SendMessageToRNApp(JSON.stringify({[RNMessages.userData]: {...viewer.user}}));
      SendMessageToRNApp(RNMessages?.authorized);
      SendMessageToRNApp(JSON.stringify({isLogin: true, isRemember, ...values}));
      const dToken = await getDeviceToken();
      await subscribeUserForPush({userId: viewer.user.objectId, token: deviceToken || dToken});
      await Parse.User.become(viewer.sessionToken);
      setCommunity(null);
      const isSupport = useCheckIsSupport({email: viewer.user.email});
      const {community, communities} = await getCurrentCommunity(
        viewer.user.objectId,
        client,
        isSupport,
        viewer.user?.lastVisitedCommunity,
      );
      /*const permissions = (await getPermissions({
        userId: viewer.user.objectId,
        communityId: community?.objectId,
        skip: isSupport,
      })) as PermissionsList[];*/
      const typeUser = getTypeUser(viewer.user.objectId, community);
      //const permissionsList = typeUser === TypeCommunity.resident ? [] : permissions;
      //setPermissions(permissionsList);
      const commCount = Number(viewer?.user?.Communities?.count || 0);
      setCommunity(toStateCommunity(community));
      setTypeUser(typeUser);
      setCommunities(communities?.map(toStateCommunity));
      syncLang(viewer.user.objectId, viewer.user.Language?.code as localeType);
      const isHaveCommunities = commCount > 0;

      onLoginRedirect(isHaveCommunities, community?.alias, viewer, typeUser);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics.identify(viewer?.user?.email, {
        name: `${viewer?.user?.firstName} ${viewer?.user?.lastName}`,
        email: viewer?.user?.email,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {successLogin, loading};
};

const LoginSchema = FormValidation.schema<Partial<LogInValues>>({
  email: FormValidation.string().email('error:login'),
  password: FormValidation.string().length({gte: 6}, 'error:login'),
});

export interface IDataLogIn {
  values: Partial<LogInValues>;
  onChange: (next: {name: keyof LogInValues; value: string}) => void;
  onSubmit: () => Promise<boolean>;
  error: FormErrorFields<LogInValues>;
  loading?: boolean;
  disabled?: boolean;
  isHaveCommunities?: boolean;
  isRemember: boolean;
  setIsRemember: (value: boolean) => void;
}

type LogInType = (options?: {
  initialState?: Partial<LogInValues>;
  typeUser?: TypeCommunity;
  onError?: () => void;
}) => IDataLogIn;

export const useLogIn: LogInType = (options) => {
  const [values, setValues] = useState<Partial<LogInValues>>(options?.initialState || {});
  const [isRemember, setIsRemember] = useState(false);
  const [error, setError] = useFormError<LogInValues>();
  const [loading, setLoading] = useState(false);
  const [LogInRequest] = useMutation<LogInResponseType, LogInRequestType>(LogInQuery);
  const {successLogin, loading: loadingLogin} = useSuccessLogin();
  const changeValue = (next: {name: keyof LogInValues; value: string}): void => {
    setError(null);
    setValues((prev) => ({...prev, [next.name]: next.value}));
  };

  const logIn = async (): Promise<boolean> => {
    try {
      setLoading(true);
      if (!LoginSchema.validate<LogInValues>(values)) return false;
      const ParseValues = toParseLogInUser(values);
      const result = await LogInRequest({variables: ParseValues});
      const viewer = result?.data?.logIn.viewer;
      if (!viewer) throw new Error('error:auth');

      await successLogin(viewer, isRemember, values);
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setLoading(false);
    }

    return true;
  };
  return {
    values,
    onChange: changeValue,
    onSubmit: logIn,
    error,
    loading: loading || loadingLogin,
    setIsRemember,
    isRemember,
  };
};

export const useIsAuthenticated = (): boolean => {
  return isString(useViewer()?.id);
};

type SignOutType = (options?: {onSuccess?: () => void}) => {
  signOut: () => Promise<boolean>;
  error: string;
  loading: boolean;
};

export const useSignOut: SignOutType = (options) => {
  const setSession = useSetRecoilState(sessionState);
  const setCommunity = useSetRecoilState(currentCommunity);
  const setCommunities = useSetRecoilState(userCommunities);
  const setPermissions = useSetRecoilState(userPermissions);
  const setTypeUser = useSetRecoilState(typeUser);
  const setAlias = useSetRecoilState(aliasPart);
  const setAliasComm = useSetRecoilState(aliasCommunity);
  const setSkipParams = useSetRecoilState(skipParams);
  const setInviteState = useSetRecoilState(inviteToCommunityState);
  const setPassCodeToCommunity = useSetRecoilState(passCodeToCommunity);
  const resetPreload = useResetPreload();
  const [error, setError] = useError();
  const [SignOutRequest, {loading}] = useMutation<undefined, SignOutResponseType>(SignOutQuery);
  const {removeUserForPush} = useSetUserPushNotifications();
  const [deviceToken, setDeviceToken] = useState<null | string>(null);
  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setDeviceToken(key);
  };
  const viewer = useViewer();
  useEffect(() => {
    getDeviceToken();
  }, []);

  const _signOut = async (): Promise<boolean> => {
    let data = false;

    try {
      await removeUserForPush({token: deviceToken});
      await SignOutRequest();
      data = true;
    } catch (error) {
      setError(error);
      return false;
    }

    if (!data) {
      return false;
    }
    SendMessageToRNApp(RNMessages?.logout);
    await setToken(null);
    await setSession(null);
    await setCommunities([]);
    await setCommunity(null);
    await setTypeUser(null);
    await setAlias('');
    await setAliasComm(null);
    await setPassCodeToCommunity(null);
    await setPermissions([]);
    await setSkipParams({});
    await setInviteState(undefined);
    resetPreload();
    // await localStorage.removeItem(LocalStorageItems.deviceToken);
    await localStorage.removeItem('Parse/hangeh/currentUser');
    options?.onSuccess?.();
    analyticsTrackFN('User Logout', {
      name: viewer?.firstName,
      email: viewer?.email,
    });
    return true;
  };

  return {signOut: _signOut, error, loading};
};

export const useVerifyEmail = () => {
  const [mutate] = useMutation<VerifyEmailResponse, VerifyEmailRequest>(VerifyEmail);

  return async (id: string, token: string) => {
    return await mutate({
      variables: {
        input: {
          id,
          token,
        },
      },
    });
  };
};

export const useResetPass = () => {
  const {push} = useHistory();

  const [error, setError] = useError();
  const [callReset] = useMutation<TResetPasswordResponse, TResetPasswordRequest>(ResetPasswordQuery);

  const resetPass = async (email?: string) => {
    try {
      if (!email) throw new Error('error:resetPassword.typeEmail');

      const {data: resetData} = await callReset({
        variables: {
          email,
        },
      });

      if (resetData?.resetPassword.ok) push(route.forgotPassword.get(), email);
    } catch (e) {
      setError(e);
    }
  };

  const clearError = () => {
    setError('');
  };

  return {resetPass, error, clearError};
};

export type TChangePassValues = Partial<{
  password: string;
  confirmPassword: string;
}>;

export const useChangePassword = () => {
  const [values, setValues] = useState<TChangePassValues>({});
  const [error, setError] = useError();
  const [showSuccess, setShowSuccess] = useState(false);
  const [callChange, otherData] = useMutation<TChangePasswordResponse, TChangePasswordRequest>(ChangePasswordQuery);

  const changePass = async ({id, token}: {id: string; token: string}) => {
    const {password, confirmPassword} = values;

    try {
      if (password !== confirmPassword) throw new Error('error:matchPassword');

      const {data} = await callChange({
        variables: {
          id: id as string,
          password: password as string,
          token: token as string,
        },
      });

      if (data?.changePassword.ok) setShowSuccess(true);
    } catch (e) {
      setError(e);
    }
  };

  const changeValue = (key: keyof TChangePassValues, value: string) => {
    setValues((prev) => ({...prev, [key]: value}));
  };

  return {...otherData, changePass, error, changeValue, showSuccess, values};
};

export const useSetAutoAddress = ({
  initialFromState,
  skipAddress,
  locationId,
}: {
  initialFromState?: {address?: string; zip?: string};
  skipAddress?: boolean;
  locationId?: string;
}) => {
  const form = useUpdateProfileForm<AddressFormData, Profile>({
    initialState: initialFromState,
    validationSchema: AddressFormSchema,
  });
  const submitAutoAddress = async (viewerId?: string) => {
    if (!skipAddress) return;
    if (!form.values.Location?.link) {
      form.values.Location = {link: locationId};
    }
    await form.onSubmit(viewerId, true);
  };
  return {submitAutoAddress};
};

export const useCheckUserOnboarding = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const [updateProfile] = useMutation<UpdateProfileResponseType>(UpdateProfileShort);

  return (typeUser: TypeCommunity, user: User, onSuccess?: () => void) => {
    if (user?.status === 'recreate' && user?.Communities?.count && user?.Location) {
      updateProfile({variables: {id: user?.id, fields: {status: 'new'}}});
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Profile})));
      return;
    }
    const recommendation =
      !user?.recommendations?.eatery || !user?.recommendations?.movies || !user?.recommendations?.places;
    const likes = user?.personInterests?.length === 0;
    //const interests = user?.interests?.length === 0;
    const profile = !user?.bio || !user?.languages || !user?.occupation;
    const community = !user?.lastVisitedCommunity;
    const address = !user?.address;
    const location = !user?.Location;
    const verified = !user?.phoneVerified;
    const phone = !user?.phone;
    const avatar = !user?.Avatar;
    const post = !user?.checkList?.addPost?.isChecked;
    const listing = !user?.checkList?.addListing?.isChecked;

    if (phone && address && community && likes && profile && recommendation && avatar && post && listing) {
      push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.number, user: typeUser as TypeCommunity})));
    } else if (verified && address && community && likes && profile && recommendation && avatar && post && listing) {
      push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.verification, user: typeUser as TypeCommunity})));
    } else if (address && community && likes && profile && recommendation && avatar && post && listing) {
      push(getLink(route.address.get({user: typeUser as TypeCommunity})));
    } else if (location) {
      push(getLink(route.address.get({user: typeUser as TypeCommunity})));
    } else if (community && likes && profile && recommendation && avatar && post && listing) {
      push(getLink(route.communityFound.get({user: typeUser as TypeCommunity})));
    } /*else if (interests && likes && profile && recommendation && avatar && post && listing) {
        push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Interests})));
      }*/ else if (likes && profile && recommendation && avatar && post && listing) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})));
    } else if (profile && recommendation && avatar && post && listing) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Profile})));
    } else if (recommendation && avatar && post && listing) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PhotoUpload})));
    } else if (recommendation && post && listing) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.ListingCategory})));
    } else if (post && recommendation) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.Recommendations})));
    } else if (post) {
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.FirstPost})));
    } else {
      updateProfile({variables: {id: user?.id, fields: {status: 'active'}}});
      onSuccess?.();
    }
  };
};

export const useAutoAddToCommunity = () => {
  const [add] = useMutation<DirectAddMemberResponse, DirectAddMemberRequest>(DirectAddMember);
  const {refetch: getCommunity} = useGetCommunity({short: true});
  const setCommunity = useSetRecoilState(currentCommunity);
  const [loading, setLoading] = useState<boolean>(false);
  const {refetch: getSession} = useQuery<ViewerResponseType>(ViewerQuery, {
    skip: true,
    fetchPolicy: 'no-cache',
    ssr: false,
  });
  const setSession = useSetRecoilState(sessionState);
  const {refetch: getCommunities} = useGetListCommunities(undefined, 15, true);
  const setCommunities = useSetRecoilState(userCommunities);

  const addByKey = async (autoAddKey?: string): Promise<boolean> => {
    if (!autoAddKey) return false;
    try {
      setLoading(true);
      const res = await add({variables: {autoAddKey}});
      if (res?.data?.directAddMember?.added) {
        const newCommunity = await getCommunity({
          id: autoAddKey,
        });
        const user = await getSession();
        setSession(user?.data?.viewer);
        setCommunity(toStateCommunity(newCommunity?.data?.community));
        if (user?.data?.viewer?.user?.objectId) {
          const communities = await getCommunities(user?.data?.viewer?.user?.objectId);
          setCommunities(communities);
        }
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setLoading(false);
    }
  };
  return {addByKey, loading};
};

export const useResetInstallation = () => {
  const {removeUserForPush} = useSetUserPushNotifications();
  const [deviceToken, setDeviceToken] = useState<null | string>(null);
  const getDeviceToken = async () => {
    const key = await localStorage.getItem(LocalStorageItems.deviceToken);
    setDeviceToken(key);
  };
  useEffect(() => {
    getDeviceToken();
  }, []);

  const reset = async () => {
    try {
      await removeUserForPush({token: deviceToken});
    } catch (error) {
      return false;
    }
  };
  useEffect(() => {
    if (deviceToken) reset();
  }, [!!deviceToken]);
};

export const useRedirectOnSignUp = () => {
  const {push} = useHistory();
  const [community] = useRecoilState(currentCommunity);
  const [redirectLogin, setRedirect] = useRecoilState(signUpRedirect);
  useEffect(() => {
    if (redirectLogin && community?.objectId) {
      push(redirectLogin);
      setRedirect(null);
    }
  }, [!!community?.objectId]);
};

type LoginRedirectsT = () => {
  onLoginRedirect: (isHaveCommunity?: boolean, localAlies?: string, user?: Session, typeUser?: TypeCommunity) => void;
  loading?: boolean;
};

export const useLoginRedirects: LoginRedirectsT = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const checkUser = useCheckUserOnboarding();
  const viewer = useViewer();
  const [{autoAddKey}] = useRecoilState(skipParams);
  const [redirectLogin, setRedirect] = useRecoilState(signUpRedirect);
  const [alias] = useRecoilState(aliasPart);
  const [aliasedComm, setAliasComm] = useRecoilState(aliasCommunity);
  const {addByKey, loading: loadingAddByKey} = useAutoAddToCommunity();
  const [joinResult, setJoinResult] = useState<User | null>();
  const {onSubmit, loading: loadingJoinToComunity} = useJoinToCommunity({
    initialState: {communityId: aliasedComm?.objectId},
    isPrivate: aliasedComm?.accessType === AccessType.private.toLowerCase(),
    onSuccess: (viewer: User) => setJoinResult(viewer),
    onError: () => {
      push(getLink(route.pageUser.path));
    },
  });

  const joinSuccess = (viewer: User) => {
    setAliasComm(null);
    checkUser(TypeCommunity.resident, viewer, forward);
  };

  useEffect(() => {
    if (!joinResult) return;
    joinSuccess(joinResult);
  }, [!!joinResult]);

  useEffect(() => {
    if (viewer?.objectId && !aliasedComm) {
      if (!viewer?.Communities?.count) forwardMainPage(!!viewer?.Communities?.count);
    }
  }, []);

  const forward = (isHaveCommunity?: boolean, localAlies?: string) => {
    if (alias) {
      push(getLink(route.home.path, alias));
      return;
    }
    if (isHaveCommunity && localAlies && !alias) {
      push(getLink(route.loop.path, localAlies));
      return;
    }
    push(getLink(route.pageUser.path));
  };
  const onLoginRedirect = useMemo(
    () => async (isHaveCommunity?: boolean, localAlies?: string, user?: Session, typeUser?: TypeCommunity) => {
      if (aliasedComm && !autoAddKey) {
        await onSubmit({
          localUserId: user?.user?.objectId,
          email: user?.user?.email,
          firstName: user?.user?.firstName,
          lastName: user?.user?.lastName,
          userName: user?.user?.username,
        });
        return;
      }

      if (aliasedComm && autoAddKey) {
        await addByKey(autoAddKey);
      }

      if (redirectLogin) {
        push(redirectLogin);
        setRedirect(null);
        return;
      }
      forwardMainPage(isHaveCommunity, localAlies, user?.user, typeUser);
    },
    [!!typeUser, !!aliasedComm, !!autoAddKey, !!redirectLogin],
  );

  const forwardMainPage = (isHaveCommunity?: boolean, localAlies?: string, user?: User, typeUser?: TypeCommunity) => {
    if (typeUser === TypeCommunity.resident && (user?.status == 'new' || user?.status == 'recreate')) {
      checkUser(typeUser, user, forward);
      return;
    }

    forward(isHaveCommunity, localAlies);
  };

  return {onLoginRedirect, loading: loadingAddByKey || loadingJoinToComunity};
};

interface IUpdateProfileRequestType {
  id: string;
  fields: {
    username: string;
    firstName: string;
    lastName: string;
    firstLetter?: string;
  };
}

type SocialAuth = () => {
  reqWIthGoogle: (response?: GoogleParamsType) => void;
  reqWIthFacebook: (response?: FBparamsType) => void;
  reqWIthApple: (response?: any) => void;
  error?: string | null;
  loading?: boolean;
};

export const useSocialAuth: SocialAuth = () => {
  const setSession = useSetRecoilState(sessionState);
  const [SignUpWithRequest] = useMutation<
    SignWithResponseType | SignWithFBRequestType | SignWithGoogleRequestType | SignWithAppleRequestType
  >(SignUpWithQuery);
  const [ChangeProfileRequest] = useMutation<UpdateProfileResponseType, IUpdateProfileRequestType>(UpdateProfile);
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const generateUsername = useGenerateUsername();
  const {reqWIthGoogle} = useGoogleAuth(signUpWith, resetError);
  const {reqWIthFacebook} = useFacebookAuth(signUpWith, resetError);
  const {reqWIthApple} = useAppleAuth(signUpWith, resetError);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const {user} = useParams<{user: TypeCommunity}>();
  const {successRegister} = useSuccessRegister();
  const {successLogin, loading: loadingLogin} = useSuccessLogin();
  const {call: updateAvatar} = useUpdateProfilePhoto();
  const {t} = useTranslation();

  const handleUpdate = async (url: string, objectId: string, provider?: string) => {
    try {
      let newUrl = url;
      if (provider === 'google') {
        newUrl = url.replace('s96-c', 's400-c');
      }

      const response = await fetch(newUrl);
      const buffer = await response.arrayBuffer();
      const filename = `${newUrl}_avatar.jpg`;
      const photo = new File([buffer], filename, {type: 'image/jpeg'});

      await updateAvatar(photo, objectId);
    } catch (error) {
      console.log(error);
    }
  };

  function resetError() {
    setError(null);
  }

  const redirectSignUp = () =>
    push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.number, user: user ?? TypeCommunity.resident})));

  async function signUpWith<T>(
    authData?: T,
    email?: string,
    userData?: UserSocialDataT,
    provider?: string,
  ): Promise<boolean> {
    try {
      setLoading(true);
      const result = await SignUpWithRequest({
        variables: {
          authData: authData,
          fields: {
            email: email,
          },
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const viewer = result?.data?.logInWith?.viewer;
      if (!viewer) throw new Error('Registration failed');

      if (!viewer.user.firstName) {
        const correctData = {...viewer, user: {...viewer.user, username: email}};
        await setToken(correctData.sessionToken);
        if (userData?.imageUrl) {
          handleUpdate(userData.imageUrl, viewer.user.objectId, provider);
        }
        const resp = await ChangeProfileRequest({
          variables: {
            id: viewer.user.objectId || viewer.user.id,
            fields: {
              firstName: userData?.firstName || '',
              lastName: userData?.lastName || '',
              firstLetter: userData?.lastName?.trim()?.charAt(0),
              username:
                (await generateUsername((userData?.firstName as string).toLowerCase())) ??
                (userData?.firstName as string).toLowerCase(),
            },
          },
        });

        const updatedData = resp?.data?.updateUser?.user;
        if (!updatedData) throw new Error('account update failed');

        await setSession({...viewer, user: {...updatedData}});
        const accountType = user === TypeCommunity.manager ? user : 'Member';
        await successRegister(updatedData, accountType, provider);

        redirectSignUp();
        return true;
      }
      if (viewer.user.firstName) {
        await successLogin(viewer);

        return true;
      }
    } catch (error) {
      if (error.message.includes('undefined')) {
        setError(t('auth:social.notEmailError'));
        return false;
      }
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
    return true;
  }

  return {
    reqWIthGoogle,
    reqWIthFacebook,
    reqWIthApple,
    error: error,
    loading: loading || loadingLogin,
  };
};

export const useCheckReferral = () => {
  const session = useRecoilValue(sessionState);
  const [referral, setReferral] = useRecoilState(inviteReferralState);
  const referralCode = referral?.code;
  const userId = session?.user?.objectId;
  const [createReferral] = useMutation<undefined, CreateUserReferralParams>(createUserReferral);

  useEffect(() => {
    const applyReferral = async () => {
      if (!referralCode || !userId) return;
      try {
        await createReferral({variables: {referralCode, userId}});
      } catch (error) {
        console.log(error);
      }
      setReferral(null);
    };
    if (referralCode && userId) applyReferral();
  }, [referralCode, userId]);
};
