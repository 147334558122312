import {gql} from '@apollo/client';
import PostFragment, {CommentFragment, PostAuthorFragment, PostAuthorFragmentFull} from './fragments/post';
import {Connection} from './types/parse';
import {PollOption, Post, ReactionTypes} from './types/post';
import {CreatePostFieldsInput} from '../types/post';
import {GQLUpdatePollOptionFieldsInput, GQLUpdatePostFieldsInput} from '../graphql.schema';
import {MemberT} from '../types/people';

export const GetCommunityPostsQuery = (isManager?: boolean) => gql`
  query posts(
    $skip: Int
    $first: Int
    $where: PostWhereInput
    $order: [PostOrder!]
    $optionsOrder: [PollOptionOrder!]
  ) {
    posts(skip: $skip, first: $first, where: $where, order: $order) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...PostFragment
              Author {
              ${isManager ? `...PostAuthorFragmentFull` : `...PostAuthorFragment`}
    }
        }
      }
    }
  }
  ${isManager ? PostAuthorFragmentFull : PostAuthorFragment}
  ${PostFragment}
`;

export const GetCommunityPostsPollInfoQuery = (isManager?: boolean) => gql`
  query posts(
    $skip: Int
    $first: Int
    $where: PostWhereInput
    $order: [PostOrder!]
    $optionsOrder: [PollOptionOrder!]
  ) {
    posts(skip: $skip, first: $first, where: $where, order: $order) {
      edges {
        cursor
        node {
          Options(order: $optionsOrder) {
            ... on PollOptionConnection {
              edges {
                node {
                  Voted {
                    edges {
                      node {
                        objectId
                        Avatar {
                          file {
                            name
                            url
                          }
                        }
                        firstName
                        ${isManager ? 'lastName' : `firstLetter`}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetPostsCount = gql`
  query postsCount($where: PostWhereInput) {
    posts(where: $where) {
      count
    }
  }
`;

export const isHasPost = gql`
  query isHasPost($where: PostWhereInput) {
    posts(where: $where, first: 1) {
      edges {
        node {
          objectId
        }
      }
    }
  }
`;
export type CommunityPostsCountResponse = {
  posts: {
    count: number;
  };
};

export type CommunityPostsResponse = {
  posts: Connection<Post>;
};

export type likeCommunityPostReqType = {
  id: string;
  newResident?: boolean;
};

export type likeCommunityPostRespType = {
  likeHandler: {
    post: any;
  };
};

export const likeCommunityPost = gql`
  mutation likeHandler($id: String!, $newResident: Boolean) {
    likeHandler(input: {postId: $id, newResident: $newResident}) {
      post
    }
  }
`;

export type ReactionCommunityPostReqType = {
  postId: string;
  type?: ReactionTypes;
};

export const ReactionCommunityCommunityPost = gql`
  mutation reactionHandler($postId: String!, $type: ReactionHandlerTypes!) {
    reactionHandler(input: {postId: $postId, type: $type}) {
      success
    }
  }
`;

export type deletePostReqType = {
  id: string;
};

export type deletePostResType = {
  deletePost: {
    post: {
      objectId: string;
      type: string;
      sentAs: string;
      text: string;
    };
  };
};

export const deleteCommunityPost = gql`
  mutation deletePost($id: ID!) {
    deletePost(input: {id: $id}) {
      post {
        objectId
        type
        sentAs
        text
      }
    }
  }
`;

export type PostCommentsResponse = {
  posts: Connection<Post>;
};

export const getPostComments = gql`
  query posts(
    $cursor: String
    $first: Int
    $where: PostWhereInput
    $commentsWhere: PostWhereInput
    $order: [PostOrder!]
  ) {
    posts(where: $where, after: $cursor, first: $first, order: $order) {
      edges {
        cursor
        node {
          comments(after: $cursor, first: $first, where: $commentsWhere, order: $order) {
            ... on PostConnection {
              edges {
                node {
                  ...CommentFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${CommentFragment}
`;

export interface CreatePostResponseType {
  createPost: {
    post: Post;
  };
}

export interface CreatePostRequestType {
  fields: CreatePostFieldsInput;
}

export const createPost = gql`
  mutation createPost($fields: CreatePostFieldsInput) {
    createPost(input: {fields: $fields}) {
      post {
        id
        objectId
      }
    }
  }
`;

export interface CreatePollOptionResponseType {
  createPollOption: {
    pollOption: PollOption;
  };
}

export type createPollOptionReq = {
  fields: {
    text?: string;
    position?: number;
    Post?: {
      link?: string;
    };
  };
};
export const createPollOption = gql`
  mutation createPollOption($fields: CreatePollOptionFieldsInput) {
    createPollOption(input: {fields: $fields}) {
      pollOption {
        objectId
      }
    }
  }
`;

export interface TUpdatePollOptionRes {
  updatePollOption: {
    pollOption: PollOption;
  };
}

export interface TUpdatePollOptionReq {
  id: string;
  fields: GQLUpdatePollOptionFieldsInput;
}

export const UpdatePollOptionQuery = gql`
  mutation updatePollOprion($id: ID!, $fields: UpdatePollOptionFieldsInput) {
    updatePollOption(input: {id: $id, fields: $fields}) {
      pollOption {
        objectId
      }
    }
  }
`;

export const voteInPoll = gql`
  mutation voteInPoll($postId: ID!, $optionId: ID!) {
    voteInPoll(input: {postId: $postId, optionId: $optionId})
  }
`;

export type VoteInPollReqType = {
  postId: string;
  optionId: string;
};

export const removeVoteInPoll = gql`
  mutation removeVoteInPoll($postId: ID!, $optionId: ID!) {
    removeVoteInPoll(input: {postId: $postId, optionId: $optionId})
  }
`;

export type RemoveVoteInPollReqType = {
  postId: string;
  optionId: string;
};

export interface UpdatePostRequest {
  input: {
    id: string;
    fields: {
      Hide?: {
        add?: string;
      };
    };
  };
}

export interface UpdatePostResponse {
  updatePost: {
    post: Post;
  };
}

export const UpdatePost = gql`
  mutation updatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      post {
        id
        objectId
        createdAt
        Community {
          id
          objectId
        }
        type
        text
      }
    }
  }
`;

export const HidePost = gql`
  mutation hidePost($input: HidePostInput!) {
    hidePost(input: $input) {
      post
    }
  }
`;

export type hidePostResType = {
  hidePost: {
    post: {
      objectId: string;
      type: string;
      sentAs: string;
      text: string;
      Author: MemberT;
    };
  };
};

export interface PinUnpinVariables {
  postID: string;
}

export const PinUnpinPost = gql`
  mutation updatePost($postID: ID!) {
    pinUnpinPost(input: {postID: $postID})
  }
`;

export interface TUpdatePostRes {
  updatePost: Post;
}

export interface TUpdatePostReq {
  id: string;
  fields: GQLUpdatePostFieldsInput;
}

export const UpdatePostQuery = gql`
  mutation updatePost($id: ID!, $fields: UpdatePostFieldsInput) {
    updatePost(input: {id: $id, fields: $fields}) {
      post {
        id
        objectId
      }
    }
  }
`;
