import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StripeInfo} from '../../queries/payments';
import {useConnectStripe} from '../../hooks/payments';
import {ModalTemplate} from '../../components/common/Modal/ModalTemplate';
import {Button} from '../../ui-kit/Button/Button';
import {Modal} from '../../components/common/Modal';
import {ModalTemplateRow} from '../../components/common/Modal/styles';

type TProp = {
  stripePopup: boolean;
  setStripePopup: (v: boolean) => void;
  cardError?: string | null;
  setCardErrorPopup: (v: string | null) => void;
  returnUrl?: string;
};

export const Modals: React.FC<TProp> = ({setStripePopup, stripePopup, cardError, setCardErrorPopup, returnUrl}) => {
  if (stripePopup)
    return (
      <Modal active={stripePopup} setActive={setStripePopup}>
        <ModalBodyStripe onClose={() => setStripePopup(false)} returnUrl={returnUrl} />
      </Modal>
    );

  const handleCardPopup = (b: boolean) => {
    if (!b) setCardErrorPopup(null);
  };

  if (cardError)
    return (
      <Modal active={!!cardError} setActive={handleCardPopup}>
        <ModalBodyCard error={cardError} onClose={() => setCardErrorPopup(null)} />
      </Modal>
    );
  return null;
};

const ModalBodyStripe: React.FC<{onClose?: () => void; returnUrl?: string}> = ({onClose, returnUrl}) => {
  const {t} = useTranslation();
  const title = t('common:modals.addStripe.title');
  const descr = t('common:modals.addStripe.descr');
  const buttonText = t('common:modals.addStripe.button');
  const [stripeInfo, setStripeInfo] = useState<StripeInfo | undefined | null>(null);
  const getStripeInfo = useConnectStripe();

  useEffect(() => {
    getStripeInfo(returnUrl)
      .then((response) => setStripeInfo(response || undefined))
      .catch(() => {
        setStripeInfo(undefined);
      });
  }, []);
  return (
    <ModalTemplate
      title={title}
      descr={descr}
      onClose={onClose}
      Button={
        <a href={stripeInfo?.accountLink || '#'} target={'_blank'} rel="noreferrer">
          <Button variant={'tertiary'}>{buttonText}</Button>
        </a>
      }
    />
  );
};

const ModalBodyCard: React.FC<{onClose?: () => void; error?: string | null}> = ({onClose, error}) => {
  const {t} = useTranslation();
  const title = t('common:modals.CCDeclined.title');
  const descr = (
    <>
      <ModalTemplateRow>{t('common:modals.CCDeclined.descr')}</ModalTemplateRow>
      <ModalTemplateRow>{error}</ModalTemplateRow>
    </>
  );

  return <ModalTemplate title={title} descr={descr} onClose={onClose} />;
};
