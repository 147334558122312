import React from 'react';

import {getIdForEmbedYoutube} from '../../../../helpers/feed';
import {PostTypeT} from '../../../../types/post';
import {RichText} from '../../../common/RichText/RichText';
import {PostText} from '../styles';

type TProps = {
  type?: PostTypeT;
  text?: string;
};

const getEmbedVideo = (link?: string) => {
  if (link?.includes('youtube.com') || link?.includes('youtu.be/')) {
    const id = getIdForEmbedYoutube(link);
    return `https://www.youtube.com/embed/${id}`;
  }
  if (link?.includes('facebook.com/')) {
    return `https://www.facebook.com/plugins/video.php?height=225&href=${link}&show_text=false&width=380&t=0`;
  }
  if (link?.includes('vimeo.com/')) {
    return link.replace('vimeo.com/', 'player.vimeo.com/video/');
  }
  return null;
};

const PostTextContent: React.FC<TProps> = ({type, text}) => {
  if (type === PostTypeT.video) {
    const videoLink = getEmbedVideo(text);
    if (!videoLink)
      return (
        <PostText>
          <a href={text} target="_blank" rel="noreferrer">
            {text}
          </a>
        </PostText>
      );
    return (
      <iframe
        width="380"
        height="225"
        src={videoLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }

  const handleClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      <PostText onClick={handleClick}>
        <RichText html={text} />
      </PostText>
    </>
  );
};

const MemoPostTextContent = React.memo(PostTextContent);
export {MemoPostTextContent};
