import {TCommunity} from './community';

export enum NotificationStatus {
  New = 'new',
  Seen = 'seen',
  Archived = 'archived',
  Hidden = 'hidden',
}

export enum NotificationsEventTypes {
  joinRequest = 'joinRequest',
  approveRequest = 'approvedJoinRequest',
  rejectRequest = 'rejectedJoinRequest',
  newResident = 'newResident',
  newResidentForManager = 'newResidentForManager',
  newPost = 'newPost',
  newPostRequest = 'newPostRequest',
  newTag = 'newTag',
  newGroupPost = 'newGroupPost',
  newComment = 'newComment',
  newLike = 'newLike',
  newJoinLike = 'newJoinLike',
  newJoinWave = 'newJoinWave',
  newJoinSmile = 'newJoinSmile',
  newListing = 'newListing',
  newListingMultiply = 'newListingMultiply',
  listingUpdate = 'listingUpdate',
  orderRentRequest = 'orderRentRequest',
  orderRentAccepted = 'orderRentAccepted',
  orderRentRejected = 'orderRentRejected',
  orderSellRequest = 'orderBuyRequest',
  orderSellAccepted = 'orderBuyAccepted',
  orderSellRejected = 'orderBuyRejected',
  orderServiceRequest = 'orderServiceRequest',
  orderServiceAccepted = 'orderServiceAccepted',
  orderServiceRejected = 'orderServiceRejected',
  itemView = 'itemView',
  userView = 'userView',
  itemReview = 'itemReview',
  userReview = 'userReview',
  transactionCredit = 'transactionCredit',
  transactionInvoice = 'transactionInvoice',
  newMessage = 'newMessage',
  reportPostListing = 'reportPostListing',
  reviewRequestAboutCooperation = 'reviewRequestAboutCooperation',
  listingPublishRequest = 'listingPublishRequest',
  listingApproved = 'listingApproved',
  newManagerAdminPost = 'newManagerAdminPost',
  newNotice = 'newNotice',
  reportListing = 'reportListing',
  reportUser = 'reportUser',
  reportPost = 'reportPost',
  subscrPaymentFail = 'subscrPaymentFail',
  eventOrderPending = 'eventOrderPending',
  eventOrderAttending = 'eventOrderAttending',
  eventOrderCanceled = 'eventOrderCanceled',
  eventReview = 'eventReview',
  amenityReview = 'amenityReview',
  amenityOrderPending = 'amenityOrderPending',
  amenityOrderBooked = 'amenityOrderBooked',
  amenityOrderCanceled = 'amenityOrderCanceled',
  amenityAutoBooked = 'amenityAutoBooked',
  eventAndAmenityReminder = 'eventAndAmenityReminder',
  eventAmenityReminder = 'eventAmenityReminder',
  orderRequest = 'orderRequest',
  newLikeRecommendation = 'newLikeRecommendation',
  waveUser = 'waveUser',
  inviteNeighbours = 'inviteNeighbours',
  pollAdded = 'pollAdded',
  gettingViewed = 'gettingViewed',
  gettingViewedListing = 'gettingViewedListing',
  peopleLooking = 'peopleLooking',
  recommendationCheckOut = 'recommendationCheckOut',
  pollAddedSecond = 'pollAddedSecond',
  recommendationCheckOutSecond = 'recommendationCheckOutSecond',
}

export enum NotificationFields {
  id = 'id',
  objectId = 'objectId',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  event = 'event',
  data = 'data',
  status = 'status',
  to = 'to',
  from = 'from',
}

export enum UserPartFields {
  id = 'id',
  Avatar = 'Avatar',
}

export interface UserPartData {
  id: string;
  objectId: string;
  Avatar: {
    objectId?: string;
    file: {
      name: string;
      url: string;
    };
  };
}

export interface NotificationsType {
  id?: string;
  objectId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  event: NotificationsEventTypes;
  data: {
    time?: string;
    personFirstName?: string;
    personLastName?: string;
    communityName?: string;
    communityId?: string;
    groupId?: string;
    post?: string;
    comment?: string;
    listingName?: string;
    totalDays?: number;
    orderType?: string;
    listingID?: string;
    bookingTime?: string;
    requesterId?: string;
    listerId?: string;
    receiverId?: string;
    orderId?: string;
    listingImg?: string;
    amenityImg?: string;
    eventImg?: string;
    reportedUserName?: string;
    deepLink?: string;
    companyName?: string;
    subscrPaymentId?: string;
    subscrId?: string;
    amenityId?: string;
    amenityName?: string;
    eventId?: string;
    eventName?: string;
    toManager?: boolean;
    text?: string;
    groupName?: string;
    documentName?: string;
    requestStatus?: string;
    numberOfListings?: number | string;
    numberOfItems?: number | string;
    creator?: 'community' | 'resident' | 'manager';
  };
  status: string;
  to: UserPartData[];
  from: UserPartData;
  Community?: Partial<TCommunity>;
}

export interface StateNotificationSettingsType {
  notifyDigest?: string[];
  notifyJoinRequest?: string[];
  notifyLikeOrComment?: string[];
  notifyListingView?: string[];
  notifyListingsRequest?: string[];
  notifyLoopPost?: string[];
  notifyManagerAdminPost?: string[];
  notifyMarketingAndFeatures?: string[];
  notifyMessage?: string[];
  notifyNewCommunities?: string[];
  notifyNewListings?: string[];
  notifyNewMembers?: string[];
  notifyNewRequests?: string[];
  notifyNewResidentListings?: string[];
  notifyNewResidents?: string[];
  notifyNewTransaction?: string[];
  notifyOrderRequest?: string[];
  notifyProfileView?: string[];
  //new
  notifyNewMember?: string[];
  notifyAmenityReminder?: string[];
  notifyEventReminder?: string[];
  notifyHelpCentre?: string[];
  notifyNewListing?: string[];
  notifyEventAttendees?: string[];
  notifyUnmanagedAmenities?: string[];
  notifyManagedAmenities?: string[];
}

export enum NotifyType {
  Push = 'push',
  Email = 'email',
  SMS = 'sms',
}

export enum SettingNotificationTypes {
  communityPromotional = 'communityPromotional',
  communityDigest = 'communityDigest',
  communityActivity = 'communityActivity',
  personalInteractions = 'personalInteractions',
  bookingRequests = 'bookingRequests',
  contentView = 'contentView',
  contentModeration = 'contentModeration',
}

export type SettingNotificationValuesT = {
  push?: boolean;
  email?: boolean;
  sms?: boolean;
};

export type SettingNotificationT = {
  type: SettingNotificationTypes;
  name: string;
  descr: string;
  notifications: string[];
  values?: SettingNotificationValuesT;
  required?: boolean;
};

export type receivingVariants = 'push' | 'sms' | 'email';
