import styled from 'styled-components';
import {Media} from '../../../../ui-kit/theme';
import {Link} from '../../../../ui-kit/Typography/Link';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {fontPrimaryReg} from '../../../common/styles';

export const SettingsWrapper = styled.div<{$isSettingsActive: boolean}>`
  display: grid;
  grid-auto-flow: row;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px;
  width: 336px;
  height: ${({$isSettingsActive}) => ($isSettingsActive ? '0' : 'revert')};
  max-height: 575px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;

  padding: 10px 20px 25px;

  ${Media.down.s} {
    width: 100vw;
  }
`;

export const EntryLinkWrapper = styled(Link)`
  display: block;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
`;

export const EntryButtonWrapper = styled.button`
  border: 0;
  background-color: transparent;
  display: block;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.background.secondary};
  padding: 6px 0;
  cursor: pointer;
`;

export const HeaderEntry = styled(EntryLinkWrapper)`
  ${Media.down.s} {
    display: none;
  }
`;

export const JoinButton = styled.button<{$disabled?: boolean}>`
  ${fontPrimaryReg};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 20px;
  font-size: 12px;
  border: none;
  background: ${({theme}) => theme.palette.grayscale._3};
  border-radius: 12px;
  text-transform: capitalize;
  cursor: pointer;
  :hover {
    background: ${({theme}) => theme.palette.grayscale._11};
  }
  color: ${({theme}) => theme.palette.grayscale._12};
  transition: 0.2s all;
  ${({$disabled, theme}) =>
    $disabled &&
    `
    background: ${theme.palette.grayscale._10};
    color: ${theme.palette.text.primary};
    opacity: 0.9;
    pointer-events: none;
    & > div {
      padding-right: 0;
      padding-left: 4px;
    }
  `}
`;

export const WrapperFlex = styled.div<{flexStart?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: ${({flexStart}) => (flexStart ? 'flex-start' : 'space-between')};
  align-items: center;
`;

export const HeaderWrapper = styled(WrapperFlex)`
  margin-bottom: 12px;
`;

export const ButtonWrapper = styled(EntryLinkWrapper)`
  display: none;

  ${Media.down.s} {
    display: block;
  }
`;

export const TextBlock = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TextMenuBlock = styled.div`
  height: max-content;
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  margin-left: 5px;
  position: relative;
  p {
    margin-top: 5px;
  }
`;

export const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Title = styled.p<{$overflow?: boolean}>`
  font-family: ${({theme}) => theme.font.primary.medium.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.medium.fontWeight};
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
  cursor: pointer;
  text-decoration: none;
  margin-top: 4px;
  margin-bottom: 4px;
  ${({$overflow}) =>
    $overflow &&
    `
    max-width: 156px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};
  ${Media.down.m} {
    margin-top: 3px;
    margin-bottom: 3px;
    max-width: unset;
  }
`;

export const BtnText = styled(Title)`
  font-size: 14px;
`;

export const Subtitle = styled.span`
  height: 100%;
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 12px;
  color: ${({theme}) => theme.palette.text.septenary};
  text-align: left;
`;

export const Header = styled(Title)`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 20px;
  cursor: text;
`;

export const CopyrightYear = styled.span`
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.regular.fontWeight};
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.septenary};
`;

export const LogOutBtn = styled.span`
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 14px;
  text-decoration-line: underline;
  color: ${({theme}) => theme.palette.text.duodecimal};
  cursor: pointer;
`;

export const SettingsBtn = styled(Link)`
  display: block;
  background-color: ${({theme}) => theme.palette.background.septenary};
  border-radius: 12px;
  padding: 15px 18px;
  box-sizing: border-box;

  &:first-of-type {
    margin-bottom: 11px;
  }

  &:only-of-type {
    margin-bottom: 0;
  }
`;

export const WrapperSettingsButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & svg {
    stroke-width: 1px;
  }
  & svg * {
    stroke-width: 1px;
  }
`;

export const PopupWrapper = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  ${Media.down.xxl} {
    max-height: 98%;
    overflow-y: scroll;
  }

  ${Media.down.s} {
    max-height: 75vh;
    overflow-y: auto;
  }
`;

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;

  ${Media.down.s} {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const BackgroundSettings = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: initial;

  ${Media.down.s} {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const SelectCommunityWrapper = styled.div``;

export const RelativeWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const DropdownArrow = styled(IconSvg).attrs({type: 'chevrone-right'})<{$isOpen?: boolean}>`
  transform: ${({$isOpen}) => ($isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

export const ArrowRight = styled(IconSvg).attrs({
  type: 'chevrone-right',
  stroke: 'grayLight-2',
})<{$isOpen?: boolean}>`
  pointer-events: none;
  position: absolute;
  right: 0;

  path {
    stroke-width: 1px;
  }
`;

export const ButtonArea = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const DropdownArrowWrapper = styled(ButtonArea)`
  position: absolute;
  right: 12px;
`;

export const MobileWrapper = styled.div`
  ${Media.up.s} {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  ${Media.down.s} {
    display: none;
  }
`;

export const SelectCommunityPopupWrapper = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.primary};
  width: 100%;
  padding: 20px;
  ${Media.down.l} {
    overflow-y: scroll;
    max-height: 70vh;
  }
`;

export const CommunitiesListWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const LabelsWrapper = styled.div`
  & > * {
    margin-right: 5px;
  }
`;

export const LinkLabel = styled.div`
  position: absolute;
  right: 10px;
`;
