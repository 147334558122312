import React, {useEffect, useState} from 'react';
import {AddPlusBtn, AddBtnPortal, AddBtnEmpty, AnimatedText} from './styles';
import {icon} from '../../../ui-kit/Icon/Svg/types';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {isFromApp} from '../../../ui-kit/Navigation/mobile/styles';
// import throttle from 'lodash.throttle';

type AddButtonPortalProps = {
  link?: string;
  onClick?: () => void;
  iconType?: icon;
  viewBox?: string;
  animated?: boolean;
  animatedText?: string;
  externalScrolling?: boolean;
  blockInterval?: boolean;
};

export const AddButtonPortal: React.FC<AddButtonPortalProps> = ({
  link,
  onClick,
  viewBox,
  iconType,
  animatedText,
  animated,
  externalScrolling,
  blockInterval,
}) => {
  const [showText, setShowText] = useState(animated);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    if (!animated || blockInterval) return;
    let currentPos = 0;
    const interval: NodeJS.Timeout = setInterval(() => {
      const currentPosition = window.pageYOffset;
      if (currentPosition !== currentPos) {
        setScrolling(true);
        setShowText(false);
        currentPos = currentPosition;
      } else {
        setScrolling(false);
        currentPos = currentPosition;
      }
    }, 200);
    return () => clearInterval(interval);
  }, [animated, blockInterval]);

  useEffect(() => {
    if (!animated) return;
    if (externalScrolling) setShowText(false);
    const timeout = setTimeout(() => {
      const animatedAndNotScrolling = externalScrolling !== undefined ? !externalScrolling : !scrolling;
      setShowText(animatedAndNotScrolling);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [scrolling, externalScrolling]);

  const animateFlag = showText && animated;

  return (
    <AddBtnPortal
      right={animated ? '6px' : '15px'}
      bottom={isFromApp() ? (animated ? '65px' : '74px') : animated ? '90px' : '99px'}
      width={'42px'}
      height={'42px'}
      zIndex={100}
      $isAnimated={animated}
      $isWide={animateFlag}>
      {iconType ? (
        <AddBtnEmpty to={link || '#'} onClick={onClick} $isAnimated={animateFlag}>
          {animateFlag && animatedText && <AnimatedText>{animatedText}</AnimatedText>}
          <IconSvg type={iconType} width={'26px'} height={'29px'} stroke={'white'} viewBox={viewBox} />
        </AddBtnEmpty>
      ) : (
        <AddPlusBtn to={link || '#'} onClick={onClick} />
      )}
    </AddBtnPortal>
  );
};
