import React from 'react';

import {ButtonsWrapper, TitleJoin, JoinWrapper, DescrJoin, ExternalLink, BottomImage} from './styles';
import {AuthTab} from '../../../types/auth';
import {Avatar} from '../../../ui-kit/Avatar/Avatar';
import {WrapperAuth} from '../../Auth/WrapperAuth';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../ui-kit/Button/Button';
import {hangehCom} from '../../../constants/links';
import {referralReward} from '../../../constants/common';
import Landscape from '../../../files/images/Landscape-lg.png';

type TProps = {
  name?: string;
  avatar?: string;
  onApply: () => void;
};

export const JoinByReferral: React.FC<TProps> = ({name, avatar, onApply}) => {
  const {t} = useTranslation();
  const title = t('common:inviteReferral.title', {name: name, amount: `$${referralReward}`});
  const descr = t('common:inviteReferral.description');
  const buttonText = t('common:inviteReferral.claim', {amount: `$${referralReward}`});
  const learnMore = t('common:inviteReferral.learnMore');
  return (
    <WrapperAuth tab={AuthTab.signUp} joinPage={true}>
      <JoinWrapper>
        <Avatar src={avatar} size={'xl'} />
        <TitleJoin>{title}</TitleJoin>
        <DescrJoin>{descr}</DescrJoin>
        <ButtonsWrapper>
          <Button onClick={onApply}>{buttonText}</Button>
        </ButtonsWrapper>
      </JoinWrapper>
      <ExternalLink href={hangehCom} target={'_blank'}>
        {learnMore}
      </ExternalLink>
      <BottomImage src={Landscape} />
    </WrapperAuth>
  );
};
