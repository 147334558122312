import {useMutation, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {acceptedMimes, TDocumentMime} from '../components/Documents/types';
import {GQLDocumentActions} from '../graphql.schema';
import {getObjByCondition} from '../helpers/common';
import {documentsToState, documentToState} from '../helpers/documents';
import {changeFileName} from '../helpers/file';
import {
  ActionOnDocumentParams,
  ActionOnDocumentQuery,
  AddDocumentQuery,
  AddDocumentsParams,
  AddDocumentsRes,
  GetDocumentParams,
  GetDocumentQuery,
  GetDocumentRes,
  GetDocumentsParams,
  GetDocumentsQuery,
  GetDocumentsRes,
  UpdateDocumentParams,
  UpdateDocumentQuery,
  UpdateDocumentRes,
} from '../queries/documents';
import {
  CreateAppFile,
  CreateFileResponseType,
  DeleteAppFile,
  DeleteFileRequestType,
  DeleteFileResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {currentCommunity} from '../states/community';
import {userState} from '../states/session';
import {TDocument} from '../types/document';
import {useTranslation} from 'react-i18next';

export interface actionOnDocFn {
  (id: string): Promise<void>;
}

export const useGetDocument = () => {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const {data, ...otherData} = useQuery<GetDocumentRes, GetDocumentParams>(GetDocumentQuery, {
    variables: {
      id: id,
    },
    skip: !id,
  });

  return {
    data:
      data &&
      documentToState({
        doc: data.document,
        t,
      }),
    ...otherData,
  };
};

export const useGetDocuments = () => {
  const {t} = useTranslation();
  const community = useRecoilValue(currentCommunity);
  const user = useRecoilValue(userState);

  const {data, ...otherData} = useQuery<GetDocumentsRes, GetDocumentsParams>(GetDocumentsQuery, {
    variables: {
      where: {
        AND: [
          {
            Community: {
              have: {
                objectId: {
                  equalTo: community?.objectId,
                },
              },
            },
          },
          {
            isDeleted: {
              equalTo: false,
            },
          },
        ],
      },
    },
    skip: !user,
  });

  return {
    data: data?.documents && documentsToState(data?.documents, t, user?.objectId),
    count: data?.documents.count,
    ...otherData,
  };
};

export type AddDocumentData = {
  name?: string;
  docFile?: File | null;
  cover?: File | null;
};

export interface ISetValue {
  ({key, value}: {key: keyof AddDocumentData; value: any}): void;
}

export const useAddDocument = (params: {
  onSuccess?: () => void;
  values: AddDocumentData;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const {onSuccess, values, setError} = params;

  const [add, addData] = useMutation<AddDocumentsRes, AddDocumentsParams>(AddDocumentQuery);
  const community = useRecoilValue(currentCommunity);
  const viewer = useRecoilValue(userState);
  const viewerId = viewer?.objectId;
  const _values = {
    ...values,
    name: values.name?.trim(),
  };
  const addDocument = async (cb?: () => void) => {
    const {name, cover, docFile} = _values;

    try {
      if (!docFile) throw new Error('error:documents.file');
      if (!name) throw new Error('error:documents.name');

      await add({
        variables: {
          fields: {
            name,
            ...getObjByCondition(!!cover, {
              Cover: {
                createAndLink: {
                  file: {
                    upload: cover,
                  },
                },
              },
            }),
            DocFile: {
              createAndLink: {
                file: {
                  upload: docFile,
                },
              },
            },
            Community: {
              link: community?.objectId,
            },
            User: {
              link: viewerId,
            },
          },
        },
      });

      onSuccess?.();
      cb?.();
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    addData,
    addDocument,
  };
};

export const useUpdateDocument = (params: {
  onSuccess?: () => void;
  values: AddDocumentData;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const {onSuccess, values, setError} = params;

  const [update, updateData] = useMutation<UpdateDocumentRes, UpdateDocumentParams>(UpdateDocumentQuery);
  const [updateFile, {loading: updateLoading}] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(
    UpdateAppFile,
  );
  const [createFile, {loading: createLoading}] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [deleteFile, {loading: deleteLoading}] = useMutation<DeleteFileResponseType, DeleteFileRequestType>(
    DeleteAppFile,
  );
  const _values = {
    ...values,
    name: values.name?.trim(),
  };
  const updateDoc = async (doc: TDocument, cb?: () => void) => {
    const {Cover: initialCover, DocFile: initialDocFile, objectId: id} = doc;
    const {cover, name, docFile} = _values;

    try {
      if (!id) throw new Error('error:id');
      if (name?.trim() === '') throw new Error('error:documents.name');

      let coverObjectId: string | undefined | null;

      if (cover && initialCover) {
        const {data} = await updateFile({
          variables: {
            id: initialCover?.objectId as string,
            fields: {
              file: {
                upload: cover,
              },
            },
          },
        });

        coverObjectId = data?.updateAppFile.appFile.objectId;
      } else if (cover && !initialCover) {
        const {data} = await createFile({
          variables: {
            fields: {
              file: {
                upload: cover,
              },
            },
          },
        });

        coverObjectId = data?.createAppFile.appFile.objectId;
      } else if ('cover' in values && !cover && initialCover?.objectId) {
        await deleteFile({
          variables: {
            id: initialCover.objectId,
          },
        });

        coverObjectId = null;
      }

      let docFileObjectId: string | undefined;
      if (!!docFile) {
        const {data} = await updateFile({
          variables: {
            id: initialDocFile.objectId,
            fields: {
              file: {
                upload: docFile,
              },
            },
          },
        });

        docFileObjectId = data?.updateAppFile.appFile.objectId;
      }

      await update({
        variables: {
          id,
          fields: {
            ...getObjByCondition(!!name, {
              name: name,
            }),
            ...getObjByCondition(!!docFileObjectId, {
              DocFile: {
                link: docFileObjectId,
              },
            }),
            ...getObjByCondition(coverObjectId !== undefined, {
              Cover: {
                link: coverObjectId,
              },
            }),
          },
        },
      });

      onSuccess?.();
      cb?.();
    } catch (err) {
      setError(err.message);
    }
  };
  return {
    update: updateDoc,
    updateData: {...updateData, loading: createLoading || updateLoading || deleteLoading || updateData.loading},
  };
};

export const useActionOnDocument = (params: {onSuccess?: () => void}) => {
  const {onSuccess} = params;

  const [action] = useMutation<unknown, ActionOnDocumentParams>(ActionOnDocumentQuery);
  const [values, setValues] = useState<AddDocumentData>({});
  const [error, setError] = useState<string>();

  const {addDocument, addData} = useAddDocument({
    onSuccess,
    values,
    setError,
  });
  const {updateData, update} = useUpdateDocument({
    onSuccess,
    values,
    setError,
  });

  const publish: actionOnDocFn = async (id: string) => {
    await action({
      variables: {
        docId: id,
        type: GQLDocumentActions.publish,
      },
    });
    onSuccess?.();
  };
  const unPublish: actionOnDocFn = async (id: string) => {
    await action({
      variables: {
        docId: id,
        type: GQLDocumentActions.unlist,
      },
    });
    onSuccess?.();
  };
  const deleteDoc: actionOnDocFn = async (id: string) => {
    await action({
      variables: {
        docId: id,
        type: GQLDocumentActions.delete,
      },
    });
    onSuccess?.();
  };

  const setValue = ({key, value}: {key: keyof AddDocumentData; value: any}) => {
    setError(undefined);
    setValues((prev) => ({...prev, [key]: value}));
  };
  const setFile = ({key, value}: {key: keyof AddDocumentData; value?: File | null}) => {
    setError(undefined);

    if (!value) return setValue({key: key, value: value});
    if (key === 'docFile') {
      if (!acceptedMimes.includes(value?.type as TDocumentMime)) return setError('error:documents.fileNotSupported');
    }
    if (key === 'cover') {
      if (!value?.type.startsWith('image/')) return setError('error:documents.fileNotSupported');
    }

    setValue({key: key, value: changeFileName(value as File)});
  };
  const clearValues = () => {
    setError(undefined);
    setValues({});
  };

  return {
    publish,
    unPublish,
    deleteDoc,
    setValue,
    setFile,
    clearValues,
    addDocument,
    updateDocument: update,
    loading: addData.loading || updateData.loading,
    error,
    values,
  };
};
