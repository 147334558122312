import {useMutation} from '@apollo/client';
import {createReportRequest} from '../queries/report';
import {
  GQLCommunityPointerInput,
  GQLCompanyPointerInput,
  GQLEventPointerInput,
  GQLItemPointerInput,
  GQLPostPointerInput,
  GQLUserPointerInput,
} from '../graphql.schema';
import {useRecoilValue} from 'recoil';
import {currentCommunity} from '../states/community';
import {useViewer} from './user';
import {typeUser} from '../states/typeUser';
import {TypeCommunity} from '../types/auth';
import {analyticsTrackFN} from '../helpers/account';

export enum entities {
  post = 'post',
  user = 'user',
  listing = 'listing',
  group = 'group',
  company = 'company',
  event = 'event',
}

interface ReportFieldsResp {
  createReport: {
    report: {
      id: string;
      Post: {
        objectId: string;
        text: string;
        sentAs: string;
        type: string;
        Author: {
          firstName: string;
          lastName: string;
          objectId: string;
          email: string;
        };
      };
    };
  };
}

interface ReportFields {
  blockUserText?: string;
  reportText?: string;
  Community: GQLCommunityPointerInput;
  Reporter: GQLUserPointerInput;
  Item?: GQLItemPointerInput;
  Group?: GQLItemPointerInput;
  Event?: GQLEventPointerInput;
  Company?: GQLCompanyPointerInput;
  Post?: GQLPostPointerInput;
  User?: GQLUserPointerInput;
}

export interface IReport {
  (params: {id?: string; text?: string; onSuccess?: () => void}): Promise<void>;
}

interface IBlockUser {
  (params: {id?: string; text?: string; onSuccess?: () => void}): Promise<void>;
}

export interface reportActions {
  report: IReport;
  blockUser: IBlockUser;
}

export const useReport = (reportEntity: entities): reportActions => {
  const community = useRecoilValue(currentCommunity);
  const viewer = useViewer();
  const [_report] = useMutation<ReportFieldsResp, ReportFields>(createReportRequest);
  const userType = useRecoilValue(typeUser);

  const getReportEntity = (id: string) => {
    switch (reportEntity) {
      case entities.user:
        return {
          User: {
            link: id,
          },
        };
      case entities.post:
        return {
          Post: {
            link: id,
          },
        };
      case entities.listing:
        return {
          Item: {
            link: id,
          },
        };
      case entities.group:
        return {
          Group: {
            link: id,
          },
        };
      case entities.company:
        return {
          Company: {
            link: id,
          },
        };
      case entities.event:
        return {
          Event: {
            link: id,
          },
        };
    }
  };

  const report: IReport = async ({id, text, onSuccess}) => {
    console.log(id);
    if (!community?.objectId || !id) return;
    try {
      const result = await _report({
        variables: {
          reportText: text,
          Community: {
            link: community.objectId,
          },
          Reporter: {
            link: viewer?.objectId,
          },
          ...getReportEntity(id),
        } as ReportFields,
      });
      analyticsTrackFN('Post Reported', {
        postId: result?.data?.createReport?.report.Post?.objectId,
        type: result?.data?.createReport?.report?.Post?.type, // options: poll, post, notice, request etc.
        content: result?.data?.createReport?.report?.Post?.text,
        sentAs: result?.data?.createReport?.report?.Post?.sentAs,
        reportedByName: `${viewer?.firstName} ${viewer?.lastName}`,
        reportedByEmail: viewer?.email,
        reportedById: viewer?.objectId, // objectID or UID of user
        authorName: `${result?.data?.createReport?.report?.Post?.Author?.firstName}`,
        authorEmail: result?.data?.createReport?.report?.Post?.Author?.email,
        authorId: result?.data?.createReport?.report?.Post?.Author?.objectId, // objectID or UID of user
        communityName: community?.name,
        communityId: community?.objectId, // objectID or ID of community
        communityType: community?.type, // building, neighbourhood, circle,
      });

      onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  // only for users
  const blockUser: IBlockUser = async ({id, text, onSuccess}) => {
    if (reportEntity !== entities.user) return;
    if (!(userType === TypeCommunity.manager || userType === TypeCommunity.admin)) return;
    if (!community?.id || !id) return;

    try {
      await _report({
        variables: {
          blockUserText: text,
          Community: {
            link: community.objectId,
          },
          Reporter: {
            link: viewer?.objectId,
          },
          User: {
            link: id,
          },
        },
      });

      onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {report, blockUser};
};
