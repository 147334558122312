import React, {useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {FoundedCommunity} from '../../components/Auth/FoundCommunityPage';
import {WrapperAuth} from '../../components/Auth/WrapperAuth';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {useMutation} from '@apollo/client';
import {AccessType} from '../../constants/community';
import {route} from '../../constants/routes';
import {UpdateProfileResponseType, UpdateProfileShort} from '../../queries/user';
import {aliasCommunity, skipParams} from '../../states/community';
import {CreateProfileSteps, TypeCommunity} from '../../types/auth';
import {TCommunity} from '../../types/community';
import {useLinks} from '../../hooks/common';
import {useCommunities, useJoinToCommunity} from '../../hooks/community';
import {useViewer} from '../../hooks/user';
import {formatSearchZipAdd} from '../../helpers/community';

export const FoundCommunity: React.FC = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  // const [apartment] = useRecoilState(isApartment);
  const {user} = useParams<{user: TypeCommunity}>();
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const setSkip = useSetRecoilState(skipParams);
  const [notFounded, setNotFounded] = useState(false);
  const [isRequested] = useState(false);
  const viewer = useViewer();
  const [updateProfile] = useMutation<UpdateProfileResponseType>(UpdateProfileShort);

  useEffect(() => {
    setSkip({autoAddKey: ''});
  }, []);

  const onSuccess = (communityId: string) => {
    const successPath = getLink(route.joinCommunity.get({id: communityId}));
    push(successPath);
  };
  const searchZip = formatSearchZipAdd(viewer?.zip?.trim());
  const {data, loading} = useCommunities({
    where: {
      OR: [
        {
          zip: {in: searchZip?.string_array || []},
        },
        {zip: {equalTo: viewer?.zip}},
        ...(searchZip?.zip_add_array ? [{OR: searchZip.zip_add_array}] : []),
      ],
    },
    first: 6,
    skip: !viewer?.zip,
  });

  const comms = data?.filter((item) => item?.objectId !== aliasedComm?.objectId) as Partial<TCommunity>[];
  const {onSubmit: joinToComm, loading: loadCommunity} = useJoinToCommunity({
    initialState: {
      userId: viewer?.objectId,
    },
    isPrivate: false,
  });

  const setNoFounded = () => setNotFounded(true);
  const joinAndContinue = async (communityId: string) => {
    const community = comms?.find((item) => item?.id === communityId);
    if (community?.accessType?.toLowerCase() === AccessType.private.toLowerCase()) {
      onSuccess(community?.objectId || '');
    } else {
      updateProfile({variables: {id: viewer?.id, fields: {lastVisitedCommunity: community?.objectId}}});
      await joinToComm({localCommId: communityId});
      push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})));
    }
  };
  if (loading || loadCommunity) return <LottieLoader $isVisible={loading || loadCommunity} allScreen={true} />;
  return (
    <WrapperAuth>
      {isRequested ? (
        <Redirect to={getLink(route.communityRequest.get({user: user}))} />
      ) : (
        <>
          {notFounded ? (
            <>
              <Redirect to={getLink(route.communityCreationConfirm.get({user: user}))} />
              {/* {apartment ? (
                <Redirect to={getLink(route.communityNotFound.get({user: user}))} />
              ) : (
                <Redirect to={route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})} />
              )} */}
            </>
          ) : (
            <>
              {!data?.length && !loading ? (
                <>
                  <Redirect to={getLink(route.communityCreationConfirm.get({user: user}))} />
                </>
              ) : (
                <FoundedCommunity communities={comms} onJoin={joinAndContinue} onClickSecond={setNoFounded} />
              )}
            </>
          )}
        </>
      )}
    </WrapperAuth>
  );
  return null;
};
