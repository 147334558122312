import React, {useEffect, useState} from 'react';
import Lottie, {LottieProps} from 'react-lottie';

import {LoaderContainer, LoaderItemWrapper, LoaderOverlay} from './styles';
import {LottieLoaderProps} from './type';
import animationData from '../../files/loaderFileAnimation.json';
import {isDesktop} from 'react-device-detect';

export const LottieLoader: React.FC<LottieLoaderProps> = ({
  $isVisible,
  zIndex,
  allScreen,
  setLoaded,
  loading,
  hideFromApp,
}) => {
  const [reset, setReset] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const onComplete = () => {
    setReset(true);
    if (!loading) setLoaded?.(true);
  };
  const handlers: LottieProps['eventListeners'] = [{eventName: 'complete', callback: onComplete}];

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  if (reset) {
    return null;
  }

  if (allScreen) {
    return (
      <LoaderContainer $isVisible={$isVisible} zIndex={zIndex} $hideFromApp={hideFromApp}>
        <LoaderOverlay>
          <LoaderItemWrapper>
            <Lottie width={isDesktop ? '50%' : '100%'} speed={1} options={defaultOptions} eventListeners={handlers} />
          </LoaderItemWrapper>
        </LoaderOverlay>
      </LoaderContainer>
    );
  } else
    return (
      <LoaderContainer $isVisible={$isVisible} zIndex={zIndex} $hideFromApp={hideFromApp}>
        <Lottie width={isDesktop ? '50%' : '100%'} speed={1} options={defaultOptions} eventListeners={handlers} />
      </LoaderContainer>
    );
};
