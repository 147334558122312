import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FileInput} from '../../ui-kit/Form/ImageLoader/FileInput';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {getSourceUrl} from '../../helpers/file';
import {isFile} from '../../helpers/validations';
import {PhotoCard} from '../common/PhotoCard/PhotoCard';
import {FormMode} from './types';
import {DeleteBtnWrapper, FileInputWrapper, FileLoaderWrapper} from './styles';

interface IProps {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleDelete?: () => void;
  coverUrl?: string;
  coverFile?: File | null;
  mode: FormMode;
}

const getLabel = (params: {url?: string; coverFile?: File | null}) => {
  const {coverFile, url} = params;

  const cover = coverFile === null || isFile(coverFile) ? coverFile : url;

  const Label: React.FC = ({children}) => {
    return (
      <PhotoCard height={'95px'} src={cover ? getSourceUrl(cover) : ''} bordered>
        {children}
      </PhotoCard>
    );
  };

  return Label;
};

export const CoverInput: React.FC<IProps> = ({coverFile, coverUrl, handleChange, mode, handleDelete}) => {
  const {t} = useTranslation();

  const Label = useMemo(() => {
    return getLabel({coverFile, url: coverUrl});
  }, [coverFile, coverUrl]);

  const showDeleteBtn = !!coverFile || (coverFile === undefined && coverUrl && handleDelete);

  return (
    <FileLoaderWrapper>
      <Text size={18} color={'octonary'}>
        {t('documents:modalForm.titles.cover')}
      </Text>
      <Br indent={9} />
      <FileInputWrapper>
        {showDeleteBtn && (
          <DeleteBtnWrapper onClick={handleDelete}>
            <IconSvg type={'close'} width={'15px'} height={'15px'} viewBox={'0 0 20 20'} stroke={'lightGray'} />
          </DeleteBtnWrapper>
        )}
        <FileInput label={Label} handleChange={handleChange} />
      </FileInputWrapper>
      <Br indent={5} />
      <Text size={12} color={'octonary'}>
        {t('documents:modalForm.labels.cover', {context: mode})}
      </Text>
    </FileLoaderWrapper>
  );
};
