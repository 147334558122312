import React, {useState} from 'react';
import {TCreditCard} from '../../../queries/types/payments';
import {SectionContent, SectionWrapper} from '../commonStyles';
import {SettingsSubsection} from '../components';
import {useTranslation} from 'react-i18next';
import {PaymentCardContainer as PaymentCard} from '../../../containers/Account/Payment/Card';
import {CardsWrapper} from './styles';
import {Button} from '../../../ui-kit/Button/Button';
import {AddCreditCardPopup} from '../../../containers/Account/Popups/AddCreditCard/AddCreditCardPopup';
import {sortCards} from '../../../helpers/payment';
import {ReferralCards} from '../../Referrals/ReferralsSettings';
import {SpinnerLoader} from '../../../ui-kit/Loader/ContentLoader';
import {PrimaryLinkButton} from '../../../ui-kit/Button/styles';
import {useLinks} from '../../../hooks/common';
import {route} from '../../../constants/routes';

export interface SettingsPaymentMethodsProps {
  cards?: TCreditCard[];
  refetch: () => void;
  activeCardId?: string;
}

export const SettingsPaymentMethods: React.FC<SettingsPaymentMethodsProps> = ({cards, activeCardId, refetch}) => {
  const {t} = useTranslation();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleOpen = () => setIsOpened(true);
  const handleClose = () => {
    setIsOpened(false);
    refetch();
  };
  return (
    <SectionWrapper>
      <SettingsSubsection
        label={t('settings:payments.paymentMethods.title')}
        value={t('settings:payments.paymentMethods.subtitle')}
      />
      {Boolean(cards?.length) && (
        <CardsWrapper>
          {sortCards(cards)?.map((card) => (
            <PaymentCard
              card={card}
              isActive={activeCardId === card.id}
              refetchCards={refetch}
              key={card.id}
              width={'240px'}
            />
          ))}
        </CardsWrapper>
      )}
      <Button variant={'primary'} ghost={Boolean(cards?.length)} onClick={handleOpen} width={'240px'}>
        {t('settings:payments.paymentMethods.button')}
      </Button>
      {isOpened && <AddCreditCardPopup handleClose={handleClose} />}
    </SectionWrapper>
  );
};

export interface SettingsReferralsSectionProps {
  referralsLoading?: boolean;
  totalCredits?: number;
  totalReferrals?: number;
}

export const SettingsReferralsSection: React.FC<SettingsReferralsSectionProps> = ({
  referralsLoading,
  totalCredits,
  totalReferrals,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  return (
    <SectionWrapper>
      <SettingsSubsection label={t('settings:referrals.sectionTitle')} value={t('settings:referrals.sectionDescr')} />
      <SectionContent>
        {referralsLoading ? <SpinnerLoader /> : <ReferralCards credits={totalCredits} referrals={totalReferrals} />}
      </SectionContent>
      <PrimaryLinkButton to={getLink(route.referrals.path)} width={'240px'}>
        {t('settings:referrals.details')}
      </PrimaryLinkButton>
    </SectionWrapper>
  );
};
