import React, {useMemo} from 'react';
import {Table} from '../../ui-kit/Table';
import {Columns, ItemType} from '../../ui-kit/Table/types';
import {TableEventItem} from '../../types/event';
import {useTranslation} from 'react-i18next';
import {useGetLanguage} from '../../ui-kit/utils/language';
import {optionsI18n} from '../../libs/i18nextUtils';
import {
  ExpandTableButton,
  ExpandTableCount,
  ExpandTableWrapper,
  TableAttendee,
  TableGuests,
  TableStatusLabel,
  TableUnit,
  TableUpdated,
  TableWrapper,
} from './styles';
import {Label} from '../../ui-kit/Labels';
import {Order, StatusOrderType} from '../../queries/types/order';
import {TAmenity} from '../../types/amenity';
import {
  getMenuTableEvents,
  GetMenuTableEventsFuncOptions,
  GetMenuTableEventsOptions,
  getMobileTableMenuEvents,
  ordersToEventItems,
} from '../../helpers/event';
import {useLinks} from '../../hooks/common';
import {route} from '../../constants/routes';
import {GQLOrderStatuses} from '../../graphql.schema';
import {MobileMenuEntry} from '../../ui-kit/Menu/MobileMenu';
import {HiddenMenu, MenuButtonWrapper, TableMenuItem} from '../Amenities/styles';
import {MobileMenuButton} from '../../ui-kit/Button/MobileMenuButton';
import {TResManageOrder} from '../../hooks/order';
import {ContentLoader} from '../../ui-kit/Loader/ContentLoader';
import {EmptyAttendees} from './EmptyState';
import {Media} from '../../ui-kit/theme';
import {MenuButton} from '../../ui-kit/Button/MenuButton';
import {Menu} from '../../ui-kit/Menu';
import {MenuItemType} from '../../ui-kit/Menu/types';
import {getLastName} from '../../helpers/common';

type EventsTableProps = {
  orders?: Order[];
  amenity?: Partial<TAmenity>;
  limit?: number;
  onViewALl?: () => void;
  manageOrder?: TResManageOrder;
  loading?: boolean;
};

export const EventsTable: React.FC<EventsTableProps> = ({limit, onViewALl, orders, manageOrder, loading}) => {
  const {t} = useTranslation();
  const items: ItemType<TableEventItem>[] = ordersToEventItems(orders, manageOrder);
  const columns: Columns<TableEventItem> = useMemo(() => {
    return [
      {
        title: t('events:table.name'),
        width: '180px',
        render: renderName,
        dataIndex: 'Attendee',
      },
      {
        title: t('events:table.unit'),
        width: '90px',
        render: renderUnit,
        dataIndex: 'unit',
      },
      {
        title: t('events:table.guests'),
        width: '90px',
        render: renderGuests,
        dataIndex: 'guests',
      },
      {
        title: t('events:table.status'),
        width: '110px',
        render: renderStatus,
        dataIndex: 'status',
      },
      {
        title: t('events:table.updated'),
        width: '110px',
        render: renderUpdatedAt,
        dataIndex: 'updatedAt',
      },
      {
        title: '',
        width: '10px',
        render: renderMenu,
        dataIndex: 'menu',
      },
    ];
  }, []);
  const limitedItems: ItemType<TableEventItem>[] = [];
  const length = limit ? (limit >= items.length ? items.length : limit) : items.length;

  if (limit)
    items.forEach((e, i) => {
      if (i < length) limitedItems.push(e);
    });
  const countShowMore = limit === items.length ? 0 : limit ? items.length - limit : 0;
  return (
    <TableWrapper>
      <Table variant={'secondary'} items={limit ? limitedItems : items} columns={columns} />
      <ExpandTable count={countShowMore} onViewAll={onViewALl} />
      {!items.length && loading && <ContentLoader padding={'20px'} />}
      {!items.length && !loading && <EmptyAttendees />}
    </TableWrapper>
  );
};

const renderName = (event: TableEventItem) => {
  return (
    <TableAttendee>
      {event?.Attendee?.firstName} {getLastName(event.Attendee?.lastName, event.Attendee?.firstLetter)}
    </TableAttendee>
  );
};
const renderUnit = (event: TableEventItem) => {
  return <TableUnit>{event?.unit}</TableUnit>;
};
const renderGuests = (event: TableEventItem) => {
  return <TableGuests>{event?.guests}</TableGuests>;
};
const renderStatus = (event: TableEventItem) => {
  const {t} = useTranslation();
  const type = event.status === StatusOrderType.attending ? 'quaternary' : 'quinary';
  return (
    <TableStatusLabel>
      <Label variant={type}>{t(`events:status.${event?.status}`)}</Label>
    </TableStatusLabel>
  );
};
const renderUpdatedAt = (event: TableEventItem) => {
  const getLanguage = useGetLanguage();
  const {interpolation} = optionsI18n;
  const {format} = interpolation;

  return <TableUpdated>{format(event?.updatedAt, 'dd MMM u', getLanguage())}</TableUpdated>;
};

export const ExpandTable: React.FC<{count: number; onViewAll?: () => void}> = ({count, onViewAll}) => {
  const {t} = useTranslation();

  if (count === 0) return null;

  return (
    <ExpandTableWrapper>
      <ExpandTableCount>
        + {count} {t('events:table.more')}
      </ExpandTableCount>
      <ExpandTableButton onClick={onViewAll}>{t('events:table.viewAll')}</ExpandTableButton>
    </ExpandTableWrapper>
  );
};

const renderMenu = (event: TableEventItem) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();

  const getMenuTableEventsOptions: GetMenuTableEventsOptions = {
    objectId: event?.objectId,
    profileLink: getLink(route.profile.get({id: event?.Attendee?.objectId || '/'})),
    requestLink: getLink(route.messages.get({contactId: event?.Attendee?.objectId || ''})),
    isManager: event?.isManager,
    isBooked: event?.isBooked,
  };

  const getMenuItemsFunctions: GetMenuTableEventsFuncOptions = {
    t,
    cancelCallBack: () => {
      event.manage?.manageOrder({orderId: event.orderId, status: GQLOrderStatuses.canceled});
    },
    acceptCallBack: () => {
      event.manage?.manageOrder({orderId: event.orderId, status: GQLOrderStatuses.attending});
    },
  };
  const menuItems: MenuItemType[] = [...getMenuTableEvents(getMenuTableEventsOptions, getMenuItemsFunctions)];
  const mobileMenuItems: MobileMenuEntry[] = [
    ...getMobileTableMenuEvents(getMenuTableEventsOptions, getMenuItemsFunctions),
  ];
  const pramenityRedirect = (e: React.SyntheticEvent) => e.preventDefault();

  return (
    <TableMenuItem>
      <MenuButtonWrapper onClick={pramenityRedirect}>
        {!!menuItems.length && (
          <HiddenMenu mq={Media.down.s}>
            <MenuButton menu={Menu} item={{objectId: 'null'}} options={menuItems} />
          </HiddenMenu>
        )}
        {!!mobileMenuItems.length && (
          <HiddenMenu mq={Media.up.m}>
            <MobileMenuButton entries={mobileMenuItems} />
          </HiddenMenu>
        )}
      </MenuButtonWrapper>
    </TableMenuItem>
  );
};
