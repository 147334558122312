import styled from 'styled-components';
import {Media} from '../../../../ui-kit/theme';
import {Button} from '../../../../ui-kit/Button/Button';

export const ConfigureAdminsSectionWrapper = styled.div`
  padding: 25px 25px 30px;

  border: 1px solid ${({theme}) => theme.palette.text.hexadecimal};
  border-radius: 12px;
`;

export const PermissionsWrapper = styled.div`
  --gap-size: 16px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-size);

  & > * {
    flex-basis: 100%;
  }

  ${Media.down.s} {
    flex-direction: column;
    flex-basis: 100%;
  }
`;

export const ConfirmBtn = styled(Button).attrs({
  ghost: true,
  rounded: true,
})`
  width: initial;
  & {
    padding: 0 50px;
  }
  * {
    padding: 0;
  }
  .button-value {
    padding: 0 1em;
  }
`;

export const WrapperWithOffset = styled.div`
  margin-bottom: 10px;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
