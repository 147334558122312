import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDeleteUser} from '../../../hooks/user';
import {route} from '../../../constants/routes';

export const DeleteAccountContainer: React.FC = () => {
  const {search} = useLocation();
  const {push} = useHistory();
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');
  const email = urlParams.get('email');

  const {deleteUser} = useDeleteUser();

  useEffect(() => {
    const deleteAwait = async () => {
      if (token && email) {
        await deleteUser(token, Buffer.from(email, 'base64').toString());
      }
      push(route.logIn.path);
    };
    deleteAwait();
  }, []);

  return null;
};
