import React, {useEffect, useMemo, useState} from 'react';
import {MessagesRequests} from '../../components/Messages/Requests';
import {useLocation, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {useViewer} from '../../hooks/user';
import {IDataHookMessage, MobTabMsgs, OrderHelpersT, TContact} from '../../types/messages';
import {
  TResActiveOrder,
  useActiveContact,
  useActiveOrder,
  useContacts,
  useCreateMessage,
  useGetContactsForChat,
  useGetMessages,
  useSetSeenMessage,
} from '../../hooks/message';
import {getOrdersIds} from '../../helpers/order';
import {
  editReviewDataT,
  TDataInvoices,
  TResCreateReview,
  TResEditOrder,
  TResManageOrder,
  useCalcOrderData,
  useCreateOrderReview,
  useDataInvoiceForMsgs,
  useEditOrder,
  useGetOrderReviews,
  useLQOrders,
  useManageOrder,
  useOrdersList,
} from '../../hooks/order';
import {GQLOrderOrder, GQLOrderStatuses} from '../../graphql.schema';
import {formatOrderLink, getCountUnreadMsgs} from '../../helpers/message';
import {Message} from '../../queries/types/message';
import {Order} from '../../queries/types/order';
import {TReview} from '../../types/reviews';
import {Modals} from './Modals';
import {MobInnerMsgs, Tab} from '../../components/Messages/styles';
import {getUserName, markInboxHints} from '../../helpers/user';
import {typeUser} from '../../states/typeUser';
import {SITE_URL} from '../../constants/env';
import {useLinks} from '../../hooks/common';
import {analyticsTrackFN} from '../../helpers/account';
import {getTypeOrder} from './index';
import {messageHints} from '../../states/preload';
import {format} from 'date-fns';
import {SearchKey} from '../../constants/routes';

export const RequestsColumn = () => {
  const {contactId} = useParams<{contactId?: string}>();
  const [community] = useRecoilState(currentCommunity);
  const [userType] = useRecoilState(typeUser);
  const {search} = useLocation();
  const viewer = useViewer();
  const [activeMobTab, setActiveMobTab] = useState<MobTabMsgs | null>(MobTabMsgs.messages);
  const {data: msgs, loading: LoadingMsg, refetch} = useGetMessages(community?.objectId);
  const {contacts: users, contactsIds} = useContacts(msgs, viewer?.objectId, contactId);
  const {contacts} = useGetContactsForChat({
    initUsers: users,
    msgs,
    loading: LoadingMsg,
    contactsIds,
  });
  const [activeContact, setActiveContact] = useActiveContact({contactId, contacts});
  const {setSeenMessage: updateSeenMsg, loading: LoadingUpdMsg} = useSetSeenMessage({onSuccess: refetch});
  const params = new URLSearchParams(search);
  const orderId = params.get(SearchKey.orderId);
  const ordersIds = getOrdersIds(msgs);
  const {getLink} = useLinks();
  const {data: GQLOrders, ...dataOrders} = useOrdersList({
    first: 10,
    where: {
      objectId: {in: ordersIds},
      ...(activeContact
        ? {
            OR: [
              {Lister: {have: {objectId: {equalTo: activeContact.objectId}}}},
              {Requester: {have: {objectId: {equalTo: activeContact.objectId}}}},
            ],
          }
        : {}),
    },
    skip: !ordersIds?.length,
    order: GQLOrderOrder.updatedAt_DESC,
  });
  const {orders, refetch: refetchOrders, loading: orderLoading} = useLQOrders({orders: GQLOrders, ordersIds});
  const {activeOrder, setActiveOrder, clearActiveOrder, refetchActiveOrder} = useActiveOrder(orders);
  const {calcDataOrder, loading: invoiceLoading} = useCalcOrderData({orderId: activeOrder?.objectId});
  const dataInvoice = useDataInvoiceForMsgs(calcDataOrder, activeOrder);
  const dataManageOrder = useManageOrder({
    eventStartTime: activeOrder?.Event?.startTime,
    eventEndTime: activeOrder?.Event?.endTime,
    eventType: activeOrder?.Event?.eventType,
    orderStartTime: activeOrder?.startTime,
    onSuccess: async (status?: GQLOrderStatuses) => {
      await refetchOrders();
      await refetch();
      refetchActiveOrder?.();
      const key = getTypeOrder(status, activeOrder?.listingType);
      if (!key) return;
      if (key === 'Request Cancelled') {
        analyticsTrackFN(key, {
          listingId: activeOrder?.Item?.id,
          listingType: activeOrder?.Item?.listingType,
          listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
          photoCount: activeOrder?.Item?.images?.length,
          title: activeOrder?.Item?.name,
          description: activeOrder?.Item?.descr,
          rate: activeOrder?.price,
          currency: activeOrder?.Currency?.code,
          bookingType: activeOrder?.Item?.pricePeriod,
          category: activeOrder?.Item?.category,
          listerName: `${activeOrder?.Lister?.firstName}`,
          listerEmail: activeOrder?.Lister?.email,
          listerId: activeOrder?.Lister?.objectId,
          requesterName: `${activeOrder?.Requester?.firstName}`,
          requesterEmail: activeOrder?.Requester?.email,
          requesterId: activeOrder?.Requester?.objectId,
          communityName: activeOrder?.Community?.name,
          communityId: activeOrder?.Community?.objectId,
          communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
          requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
          requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
          requestDuration: `${activeOrder?.period} hours`,
          paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
          paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
          paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
          paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
          canceledBy: activeOrder?.Lister?.objectId === viewer?.objectId ? 'lister' : 'requester', // lister or requester
          transactionsState: activeOrder?.status, // pending or accepted
        });
      } else {
        analyticsTrackFN(key, {
          listingId: activeOrder?.Item?.id,
          listingType: activeOrder?.Item?.listingType,
          listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
          photoCount: activeOrder?.Item?.images?.length,
          title: activeOrder?.Item?.name,
          description: activeOrder?.Item?.descr,
          rate: activeOrder?.price,
          currency: activeOrder?.Currency?.code,
          bookingType: activeOrder?.Item?.pricePeriod,
          category: activeOrder?.Item?.category,
          listerName: `${activeOrder?.Lister?.firstName}`,
          listerEmail: activeOrder?.Lister?.email,
          listerId: activeOrder?.Lister?.objectId,
          requesterName: `${activeOrder?.Requester?.firstName}`,
          requesterEmail: activeOrder?.Requester?.email,
          requesterId: activeOrder?.Requester?.objectId,
          communityName: activeOrder?.Community?.name,
          communityId: activeOrder?.Community?.objectId,
          communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
          requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
          requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
          requestDuration: `${activeOrder?.period} hours`,
          paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
          paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
          paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
          paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
        });
      }
    },
  });
  const [editReview, setEditReview] = useState(false);
  const setEditRev = (edit: boolean) => setEditReview(edit);
  const [stripePopup, setStripePopup] = useState<boolean>(false);

  const showStripePopup = () => {
    setStripePopup(true);
    analyticsTrackFN('Stripe Connect Prompt', {
      listingId: activeOrder?.Item?.id,
      listingType: activeOrder?.Item?.listingType,
      listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
      photoCount: activeOrder?.Item?.images?.length,
      title: activeOrder?.Item?.name,
      description: activeOrder?.Item?.descr,
      rate: activeOrder?.itemAmount,
      currency: activeOrder?.Currency?.code,
      bookingType: activeOrder?.Item?.pricePeriod,
      category: activeOrder?.Item?.category,
      listerName: `${activeOrder?.Lister?.firstName}`,
      listerEmail: activeOrder?.Lister?.email,
      listerId: activeOrder?.Lister?.objectId,
      requesterName: `${activeOrder?.Requester?.firstName}`,
      requesterEmail: activeOrder?.Requester?.email,
      requesterId: activeOrder?.Requester?.objectId,
      communityName: activeOrder?.Community?.name,
      communityId: activeOrder?.Community?.objectId,
      communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
      requestDate: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'P'),
      requestTime: activeOrder?.createdAt && format(new Date(activeOrder?.createdAt), 'p'),
      requestDuration: `${activeOrder?.period} hours`,
      paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
      paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
      paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
      paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
    });
  };
  const [cardError, setCardError] = useState<string | null>(null);
  const setCardErrorPopup = (v: string | null) => setCardError(v);
  useEffect(() => {
    if (orderId) {
      setActiveOrder(orderId);
      setActiveMobTab(MobTabMsgs.requests);
    }
  }, [orders, orderId]);

  const editOrderData = useEditOrder({
    dates: [
      (activeOrder?.startTime && new Date(activeOrder?.startTime)) || null,
      (activeOrder?.endTime && new Date(activeOrder?.endTime)) || null,
    ],
    orderId: activeOrder?.id,
    listingType: activeOrder?.listingType,
  });

  const reviewsData = useGetOrderReviews({orderId: activeOrder?.id});
  const handleRefetchReview = () => {
    if (activeOrder?.Item?.id && (reviewOrderData?.data?.userReview || reviewOrderData?.data?.itemReview)) {
      try {
        analyticsTrackFN('Review Provided', {
          listingId: activeOrder?.Item?.id,
          listingType: activeOrder?.Item?.listingType,
          listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
          photoCount: activeOrder?.Item?.images?.length,
          title: activeOrder?.Item?.name,
          description: activeOrder?.Item?.descr,
          rate: activeOrder?.price,
          currency: activeOrder?.Currency?.code,
          bookingType: activeOrder?.Item?.pricePeriod,
          category: activeOrder?.Item?.category,
          listerName: `${activeOrder?.Lister?.firstName}`,
          listerEmail: activeOrder?.Lister?.email,
          listerId: activeOrder?.Lister?.objectId,
          requesterName: `${activeOrder?.Requester?.firstName}`,
          requesterEmail: activeOrder?.Requester?.email,
          requesterId: activeOrder?.Requester?.objectId,
          communityName: activeOrder?.Community?.name,
          communityId: activeOrder?.Community?.objectId,
          communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
          requestDate: format(new Date(activeOrder?.createdAt), 'P'),
          requestTime: format(new Date(activeOrder?.createdAt), 'p'),
          requestDuration: `${activeOrder?.period} hours`,
          paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
          paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
          paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
          paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
          reviewBy: activeOrder?.Lister?.objectId === viewer?.objectId ? 'lister' : 'requester', // requester or lister
          listingReview: reviewOrderData?.data?.itemReview,
          personReview: reviewOrderData?.data?.userReview,
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (activeOrder?.Item?.id && (reviewOrderData?.data?.itemRating || reviewOrderData?.data?.userRating)) {
      try {
        analyticsTrackFN('Rating Provided', {
          listingId: activeOrder?.Item?.id,
          listingType: activeOrder?.Item?.listingType,
          listedFor: activeOrder?.Item?.rewardType, // coffee, free, money
          photoCount: activeOrder?.Item?.images?.length,
          title: activeOrder?.Item?.name,
          description: activeOrder?.Item?.descr,
          rate: activeOrder?.price,
          currency: activeOrder?.Currency?.code,
          bookingType: activeOrder?.Item?.pricePeriod,
          category: activeOrder?.Item?.category,
          listerName: `${activeOrder?.Lister?.firstName}`,
          listerEmail: activeOrder?.Lister?.email,
          listerId: activeOrder?.Lister?.objectId,
          requesterName: `${activeOrder?.Requester?.firstName}`,
          requesterEmail: activeOrder?.Requester?.email,
          requesterId: activeOrder?.Requester?.objectId,
          communityName: activeOrder?.Community?.name,
          communityId: activeOrder?.Community?.objectId,
          communityType: activeOrder?.Community?.type, // building, neighbourhood, circle
          requestDate: format(new Date(activeOrder?.createdAt), 'P'),
          requestTime: format(new Date(activeOrder?.createdAt), 'p'),
          requestDuration: `${activeOrder?.period} hours`,
          paymentPrice: Number(dataInvoice?.price?.value.substring(2)),
          paymentServiceFee: Number(dataInvoice?.serviceFee?.value.substring(2)),
          paymentProcessing: Number(dataInvoice?.paymentProcessing?.value.substring(2)),
          paymentTotal: Number(dataInvoice?.total?.value.substring(2)),
          ratingBy: activeOrder?.Lister?.objectId === viewer?.objectId ? 'lister' : 'requester', // requester or lister
          listingRating: reviewOrderData?.data?.itemRating,
          personRating: reviewOrderData?.data?.userRating,
        });
      } catch (e) {
        console.log(e);
      }
    }
    reviewsData.refetch();
  };
  const reviewOrderData = useCreateOrderReview({
    orderId: activeOrder?.id,
    itemId: activeOrder?.Item?.id,
    eventId: activeOrder?.Event?.id,
    amenityId: activeOrder?.Amenity?.id,
    opponentId: viewer?.id === activeOrder?.Requester.id ? activeOrder?.Lister.id : activeOrder?.Requester.id,
    isRequester: viewer?.id === activeOrder?.Requester.id,
    onSuccess: handleRefetchReview,
    reviewData: reviewsData?.data,
  });
  const {
    values,
    onChange,
    deleteImage,
    onSubmit,
    loading: LoadingCreateMsg,
  } = useCreateMessage({
    idActiveContact: contactId,
    communityId: community?.objectId,
    initialState: {
      Author: viewer?.objectId,
      text: '',
    },
    onSuccess: refetch,
  });

  const loadingMsg = useMemo(
    () => LoadingMsg || LoadingCreateMsg || LoadingUpdMsg,
    [LoadingMsg, LoadingCreateMsg, LoadingUpdMsg],
  );
  const countUnReadMsg = getCountUnreadMsgs(msgs, viewer?.objectId);
  const orderHelpers: OrderHelpersT = {
    refetchMsgs: refetch,
    orderLoading: orderLoading,
  };
  // Stripe Connected api
  const stripeConnectedId = params.get(SearchKey.stripeConnectId);
  const stripeConnectId = localStorage.getItem(SearchKey.stripeConnectId);
  useEffect(() => {
    if (stripeConnectedId && stripeConnectedId === stripeConnectId) {
      analyticsTrackFN('Stripe Connected', {
        name: `${viewer?.firstName} ${viewer?.lastName}`,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        stripeId: viewer?.stripeId,
      });
      localStorage.removeItem('stripeConnectId');
    }
  }, [stripeConnectedId]);

  const returnUrl =
    SITE_URL + getLink(formatOrderLink(activeContact?.objectId, activeOrder?.objectId, String(new Date().getTime())));

  return (
    <>
      <Modals
        stripePopup={stripePopup}
        setStripePopup={(b: boolean) => setStripePopup(b)}
        cardError={cardError}
        setCardErrorPopup={setCardErrorPopup}
        returnUrl={returnUrl}
      />
      <AllRequestsPart
        dataMessage={{
          values,
          onChange,
          onSubmit,
          deleteImage,
          loading: loadingMsg,
          LoadingCreateMsg,
          updateSeenMsg,
        }}
        countUnReadMsg={countUnReadMsg}
        contactsLoading={LoadingMsg}
        contactsList={contacts}
        activeContact={activeContact}
        setActiveContact={setActiveContact}
        viewerId={viewer?.objectId}
        viewerData={{
          name: `${getUserName(userType, viewer?.firstName, viewer?.lastName || viewer?.firstLetter)}`,
          avatar: viewer?.Avatar?.file.url,
        }}
        dataActiveOrder={{activeOrder, setActiveOrder, clearActiveOrder}}
        dataOrders={{...dataOrders, orders, invoiceLoading}}
        dataInvoice={dataInvoice}
        dataManageOrder={dataManageOrder}
        editOrderData={editOrderData}
        reviewOrderData={reviewOrderData}
        reviewsData={reviewsData?.data}
        reviewsDataLoading={reviewsData?.loading}
        editReviewData={{
          isEditing: editReview,
          setEditReview: setEditRev,
        }}
        orderHelpers={orderHelpers}
        activeMobTab={activeMobTab}
        setActiveMobTab={setActiveMobTab}
        showStripePopup={showStripePopup}
        setCardErrorPopup={setCardErrorPopup}
        hideNav={true}
      />
    </>
  );
};

type AllRequestsPartProps = {
  msgs?: Message[] | null;
  contactsList?: TContact[] | null;
  activeContact?: TContact | null;
  setActiveContact: (user: TContact | null) => void;
  dataMessage: IDataHookMessage;
  contactsLoading: boolean;
  viewerId?: string;
  viewerData?: {
    name?: string;
    avatar?: string;
  };
  countUnReadMsg?: number;
  dataActiveOrder: TResActiveOrder;
  dataOrders: {
    fetchData: () => void;
    hasMore: boolean;
    loading: boolean;
    invoiceLoading?: boolean;
    orders?: Order[] | null;
    total: number;
  };
  dataInvoice: TDataInvoices | null;
  dataManageOrder: TResManageOrder;
  reviewOrderData: TResCreateReview;
  editOrderData: TResEditOrder;
  reviewsData?: TReview[] | null;
  editReviewData: editReviewDataT;
  reviewsDataLoading?: boolean;
  orderHelpers: OrderHelpersT;
  activeMobTab: MobTabMsgs | null;
  setActiveMobTab: React.Dispatch<React.SetStateAction<MobTabMsgs | null>>;
  showStripePopup: () => void;
  setCardErrorPopup: (v: string | null) => void;
  hideNav?: boolean;
};

const AllRequestsPart: React.FC<AllRequestsPartProps> = ({
  activeContact,
  setActiveContact,
  viewerId,
  dataActiveOrder,
  dataOrders,
  dataInvoice,
  dataManageOrder,
  reviewOrderData,
  editOrderData,
  reviewsData,
  editReviewData,
  reviewsDataLoading,
  orderHelpers,
  setActiveMobTab,
  showStripePopup,
  setCardErrorPopup,
}) => {
  const [separateLoading, setSeparateLoading] = useState(true);
  const [hints, setHints] = useRecoilState(messageHints);
  const hideRequestHint = () => {
    setHints((prev) => ({...prev, requests: false}));
    markInboxHints('requests');
  };
  useEffect(() => {
    activeContact?.objectId && setActiveMobTab(MobTabMsgs.chat);
  }, [activeContact?.objectId]);
  useEffect(() => {
    setTimeout(() => {
      setSeparateLoading(false);
    }, 1500);
  }, []);
  return (
    <MobInnerMsgs $isActiveChat={true}>
      <Tab $active={true}>
        <MessagesRequests
          viewerId={viewerId}
          dataActiveOrder={dataActiveOrder}
          dataOrders={dataOrders}
          activeContact={activeContact}
          setActiveMobTab={setActiveMobTab}
          dataInvoice={dataInvoice}
          dataManageOrder={dataManageOrder}
          count={dataOrders.total}
          reviewOrderData={reviewOrderData}
          editOrderData={editOrderData}
          reviewsData={reviewsData}
          editReviewData={editReviewData}
          reviewsDataLoading={reviewsDataLoading}
          orderHelpers={orderHelpers}
          showStripePopup={showStripePopup}
          setCardErrorPopup={setCardErrorPopup}
          setActiveContact={setActiveContact}
          separatePage={true}
          separateLoading={separateLoading}
          hideHint={hideRequestHint}
          hintShown={hints.requests}
        />
      </Tab>
    </MobInnerMsgs>
  );
};
