import {useLocation} from 'react-router-dom';

export const LIMIT_ELEMENTS_PAGINATION = 10;

export const usePagination = () => {
  const location = useLocation();

  const page = Number(new URLSearchParams(location.search).get('page')) || 1;
  const rows = Number(new URLSearchParams(location.search).get('elements')) || LIMIT_ELEMENTS_PAGINATION;

  const skip = () => {
    return (page > 0 ? page - 1 : 0) * rows;
  };

  const first_skip = {
    first: rows,
    skip: skip(),
  };

  return {page, rows, first_skip};
};
