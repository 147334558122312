import React from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {LottieLoader} from '../../components/Loader/LottieLoader';
import {PageLoaderWrapper} from '../../components/LocalBiz/styles';
import {ViewCompany} from '../../components/LocalBiz/View';
import {userState} from '../../states';
import {currentCommunity} from '../../states/community';
import {TPromoCTA} from '../../types/company';
import {NavContainer} from '../Navigation';

import {useCompanyCTA, useGetCompany} from '../../hooks/company';

export const ViewCompanyContainer = () => {
  const {id: companyId} = useParams<{id: string}>();
  const community = useRecoilValue(currentCommunity);
  const {id: userId} = useRecoilValue(userState) || {};
  const {data: company, loading, refetch} = useGetCompany(companyId);
  const {onInterested, incrementClick} = useCompanyCTA(company?.objectId as string);

  if (loading && !company?.objectId) {
    return (
      <NavContainer>
        <PageLoaderWrapper>
          <LottieLoader allScreen={true} $isVisible={true} />
        </PageLoaderWrapper>
      </NavContainer>
    );
  }

  const handleClickCTA = () => {
    incrementClick?.();

    if (company?.promoCTA === TPromoCTA.interested) {
      onInterested?.().then(() => refetch());
    }
  };

  return (
    <ViewCompany
      item={company}
      community={community}
      loading={loading}
      isManager={false}
      viewerId={userId as string}
      onCTA={handleClickCTA}
    />
  );
};
