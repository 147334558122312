import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';

import {ConfirmChanging} from './ConfirmChanging';
import {PlanCard} from './PlanCard';
import {PaymentPlanName, PaymentPlanValues} from '../../../../queries/types/payments';
import {currentCommunity} from '../../../../states/community';
import {IGetCurrValue} from '../../../../types/common';
import {PaymentPeriodType} from '../../../../types/payment';
import {Switch} from '../../../../ui-kit/Button/SwitchButton/PrimarySwitch';
import {Br} from '../../../../ui-kit/Typography/Br';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  ChangePlanWrapper,
  ContPlanCard,
  DiscountPeriodPlan,
  GreetingTitle,
  InnerPeriodPlan,
  TitlePeriodPlan,
} from '../styles';

import {SelectPlanRes, TPaymentParams} from '../../../../hooks/community';

type PlansProps = {
  params: SelectPlanRes;
  getCodeValue: IGetCurrValue;
  getSignValue: IGetCurrValue;
  currency: string;
};

export const SelectPlanCommunity: React.FC<PlansProps> = ({params, getCodeValue, getSignValue, currency}) => {
  const {paymentPlans, values, loading, onChange} = params;

  const {t} = useTranslation();
  const community = useRecoilValue(currentCommunity);

  const currentPlan = community?.Subscr?.PaymentPlan;
  const currentPlanType = t(`community:plan.type.${currentPlan?.name?.toLowerCase()}`).toLowerCase() as PaymentPlanName;

  const [openedModal, setOpened] = useState<boolean>(false);
  const [submitParams, setParams] = useState<TPaymentParams | undefined>();
  const [newIsUpgrade, setIsUpgrade] = useState<boolean | undefined>();

  const handleChange = () => {
    onChange({
      name: 'billing',
      value: values.billing === PaymentPeriodType.year ? PaymentPeriodType.month : PaymentPeriodType.year,
    });
  };

  const closeModal = () => {
    setOpened(false);
  };

  const onOk = () => {
    return submitParams && params.onSubmit(submitParams);
  };

  return (
    <>
      <ChangePlanWrapper>
        <GreetingTitle>{t('community:plan.title.secondary')}</GreetingTitle>
        <Br indent={40} />
        <InnerPeriodPlan>
          <TitlePeriodPlan>{t('community:plan.descr.billing')}</TitlePeriodPlan>
          <Switch
            variant={'primary'}
            width={'207px'}
            checked={values.billing === PaymentPeriodType.year}
            textChecked={t('community:plan.button.switch.annually')}
            textUnchecked={t('community:plan.button.switch.monthly')}
            onChange={handleChange}
          />
          <DiscountPeriodPlan>{t('community:plan.descr.discountBilling')}</DiscountPeriodPlan>
        </InnerPeriodPlan>

        <ContPlanCard>
          {paymentPlans?.map((it) => {
            const planType = t(`community:plan.type.${it.name?.toLowerCase()}`).toLowerCase() as PaymentPlanName;

            const isUpgrade =
              currentPlanType && planType ? PaymentPlanValues[currentPlanType] < PaymentPlanValues[planType] : false;

            const confirmChangePlan = () => {
              return (params: TPaymentParams) => {
                setIsUpgrade(isUpgrade);
                setParams(params);
                setOpened(true);
              };
            };

            return (
              <>
                <PlanCard
                  key={it.id}
                  data={it}
                  params={{...params, onSubmit: confirmChangePlan()}}
                  isChecked={false}
                  updateMode={{
                    isCurrent: currentPlan?.objectId === it.objectId,
                    isRecommended: planType === PaymentPlanName.pro,
                    isUpgrade,
                  }}
                  getCodeValue={getCodeValue}
                  getSignValue={getSignValue}
                  currency={currency}
                />
              </>
            );
          })}
        </ContPlanCard>
        <LottieLoader $isVisible={loading} allScreen={true} />
      </ChangePlanWrapper>
      {openedModal && <ConfirmChanging onOk={onOk} onClose={closeModal} isUpgrade={newIsUpgrade} />}
    </>
  );
};
