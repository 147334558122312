import {Connection, ParseObject} from './parse';
import {Community} from './community';
import {User} from './user';

export enum HelpDeskItemType {
  dailyQuote = 'dailyQuote',
  message = 'message',
  link = 'link',
  faq = 'faq',
  linkView = 'linkView',
}

export interface HelpDeskItem extends ParseObject {
  descr?: string;
  layOutOrder?: number;
  isPublished?: boolean;
  buttonTitle?: string;
  Community?: Connection<Community>;
  Assignee?: User;
  assignTo?: string;
  dailyQuoteAuthor?: string;
  isDeleted?: boolean;
  title: string;
  type: HelpDeskItemType;
}
