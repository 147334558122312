import styled from 'styled-components';
import {Button} from '../../Button/Button';
import {Media} from '../../theme';
import {Text} from '../../Typography/Text';
import {TextArea as UiTextArea} from '../../Form/TextArea/TextArea';

export const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
  max-width: unset !important;
`;

export const ModalWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  top: 200px;
  width: 623px;
  max-width: 100%;
  padding: 30px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.primary};
  ${Media.down.s} {
    padding: 16px 21px 40px;
    position: fixed;
    top: initial;
    bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const TransparentButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  top: 25px;
  right: 25px;
  ${Media.down.s} {
    top: 18px;
    right: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 20px;
`;

export const OkButton = styled(Button)`
  display: inline-flex;
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const CancelButton = styled(Button)`
  display: inline-flex;
  max-width: 120px;
  ${Media.down.s} {
    width: 100%;
    justify-content: center;
  }
`;

export const Title = styled(Text)<{mb?: string}>`
  ${Media.down.s} {
    display: inline-block;
    width: 250px;
  }
  ${({mb}) => mb && ` margin-bottom: ${mb}; display: block;`}
`;

export const TextArea = styled(UiTextArea)`
  height: 56px !important;
  ${Media.down.m} {
    margin-top: 8px;
  }
  ${Media.down.s} {
    height: 128px !important;
  }
`;
