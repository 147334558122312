import {useMutation, useQuery} from '@apollo/client';
import {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {route} from '../constants/routes';
import {
  GQLCompanyManageAction,
  GQLCompanyOrder,
  GQLCompanyWhereInput,
  GQLCreateCompanyFieldsInput,
} from '../graphql.schema';
import {CompanySchema, toFormValuesCompany, toStateCompany} from '../helpers/company';
import {toPointer} from '../helpers/parse';
import {
  CompanyManageQuery,
  CountCompanyClickQuery,
  CreateCompanyQuery,
  DeleteCompanyQuery,
  GetCompanyQuery,
  GetLocalBizQuery,
  IGetCompanyRequest,
  IGetCompanyResponse,
  TCompanyManageRequest,
  TCountCompanyClickRequest,
  TCreateCompanyRequest,
  TCreateCompanyResponse,
  TDeleteCompanyRequest,
  TGetLocalBizRequest,
  TGetLocalBizResponse,
  TUpdateCompanyRequest,
  TUpdateCompanyResponse,
  UpdateCompanyQuery,
} from '../queries/company';
import {
  CreateAppFile,
  CreateFileResponseType,
  UpdateAppFile,
  UpdateFileRequestType,
  UpdateFileResponseType,
} from '../queries/file';
import {currentCommunityState, userState} from '../states';
import {AmenityFormValues} from '../types/amenity';
import {PointerFile, imageType} from '../types/common';
import {TCompanyFormValues, TPromoCTA} from '../types/company';
import {FormErrorFields, useFormError} from './error';
import {analyticsTrackFN} from '../helpers/account';
import {useViewer} from './user';
import {checkItemImagesFormat} from '../helpers/file';

export const useGetLocalBiz = (searchParams: Array<GQLCompanyWhereInput>) => {
  const community = useRecoilValue(currentCommunityState);
  const viewer = useViewer();
  const variables: TGetLocalBizRequest = {
    where: {
      AND: [
        {
          Published: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
        ...searchParams,
      ] as Array<GQLCompanyWhereInput>,
    },
    order: GQLCompanyOrder.Featured_DESC,
  };

  const {data: _data, ...otherData} = useQuery<TGetLocalBizResponse, TGetLocalBizRequest>(GetLocalBizQuery, {
    variables,
    fetchPolicy: 'network-only',
  });

  const data = useMemo(
    () =>
      _data?.companies.edges.map((el) =>
        toStateCompany({
          item: el.node,
          communityId: community?.id,
        }),
      ),
    [_data, community?.id],
  );

  useEffect(() => {
    if (searchParams?.[1]?.name?.matchesRegex && !!data?.length) {
      analyticsTrackFN('Searched Biz', {
        query: searchParams?.[1]?.name?.matchesRegex,
        results: data?.length || 0,
        userName: viewer?.username,
        userEmail: viewer?.email,
        userId: viewer?.objectId,
        communityName: community?.name,
        communityId: community?.objectId,
        communityType: community?.type,
      });
    }
  }, [!!data?.length]);

  return {
    data,
    hasMore: _data?.companies.pageInfo.hasNextPage ?? true,
    ...otherData,
  };
};

export const useGetCompany = (id: string) => {
  const {id: communityId} = useRecoilValue(currentCommunityState) || {};
  const {data, ...other} = useQuery<IGetCompanyResponse, IGetCompanyRequest>(GetCompanyQuery, {variables: {id}});

  return {
    data: useMemo(() => (data ? toStateCompany({item: data.company, communityId}) : data), [data]),
    ...other,
  };
};

export interface IPrimaryActionOnItem {
  (companyId: string): Promise<void>;
}

export const useGetActionsOnCompanyInCommunity = () => {
  const community = useRecoilValue(currentCommunityState);
  const [call] = useMutation<unknown, TCompanyManageRequest>(CompanyManageQuery);

  const handleCall = async (params: {companyId: string; action: GQLCompanyManageAction}) => {
    const {companyId, action} = params;
    try {
      if (!community?.objectId) throw new Error();

      await call({
        variables: {
          action,
          companyId,
          communityIdList: [community?.objectId],
        },
      });
    } catch (e) {
      console.log('Error', e);
    }
  };

  const publishItem: IPrimaryActionOnItem = (companyId) => {
    return handleCall({action: GQLCompanyManageAction.publish, companyId});
  };
  const unpublishItem: IPrimaryActionOnItem = (companyId) => {
    return handleCall({action: GQLCompanyManageAction.unpublish, companyId});
  };
  const featureItem: IPrimaryActionOnItem = (companyId) => {
    return handleCall({action: GQLCompanyManageAction.feature, companyId});
  };
  const unfeatureItem: IPrimaryActionOnItem = (companyId) => {
    return handleCall({action: GQLCompanyManageAction.unfeature, companyId});
  };

  return {
    publishItem,
    unpublishItem,
    featureItem,
    unfeatureItem,
  };
};

export interface ISecondaryActionOnItem {
  (communitiesIds: Array<string>): Promise<void>;
}

export const useGetActionsOnCompany = (companyId: string) => {
  const [call] = useMutation<unknown, TCompanyManageRequest>(CompanyManageQuery);

  const publishItem: ISecondaryActionOnItem = (communitiesIds) => {
    return call({
      variables: {action: GQLCompanyManageAction.publish, companyId, communityIdList: communitiesIds},
    }) as any as Promise<void>;
  };
  const unpublishItem: ISecondaryActionOnItem = async (communitiesIds) => {
    return call({
      variables: {action: GQLCompanyManageAction.unpublish, companyId, communityIdList: communitiesIds},
    }) as any as Promise<void>;
  };

  return {
    publishItem,
    unpublishItem,
  };
};

export const useDeleteCompany = () => {
  const [call] = useMutation<unknown, TDeleteCompanyRequest>(DeleteCompanyQuery);

  return (companyId: string) => {
    return call({variables: {id: companyId}}) as unknown as Promise<void>;
  };
};

export const useListingCompany = () => {
  const [call] = useMutation<TUpdateCompanyResponse, TUpdateCompanyRequest>(UpdateCompanyQuery);

  const listItem: IPrimaryActionOnItem = (itemId) => {
    return call({variables: {id: itemId, fields: {isPublished: true}}}) as unknown as Promise<void>;
  };

  const unlistItem: IPrimaryActionOnItem = (itemId) => {
    return call({variables: {id: itemId, fields: {isPublished: false}}}) as unknown as Promise<void>;
  };

  return {
    listItem,
    unlistItem,
  };
};

export interface TCompanyFormData {
  values: Partial<TCompanyFormValues>;
  onSubmit: () => Promise<void>;
  onChange: (params: {key: keyof TCompanyFormValues; value: unknown}) => void;
  loading: boolean;
  error: FormErrorFields<TCompanyFormValues>;
}

interface IUseCreateCompany {
  (): TCompanyFormData;
}

export const useCreateCompany: IUseCreateCompany = () => {
  const {push} = useHistory();
  const {id: viewerId} = useRecoilValue(userState) || {};

  const [callCreate, {loading}] = useMutation<TCreateCompanyResponse, TCreateCompanyRequest>(CreateCompanyQuery);
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);

  const [values, setValues] = useState<Partial<TCompanyFormValues>>({
    images: Array.from({length: 6}, (v, k) => ({id: String(k), value: '', order: k})),
  });
  const [error, setError] = useFormError<TCompanyFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const _values = {
    ...values,
    name: values?.name?.trim(),
  };
  const {name, category, description, website, phone, isPromotion, promoTag, promoDescr, promoCTA, valueCTA} = _values;

  const onSubmit: TCompanyFormData['onSubmit'] = async () => {
    try {
      setIsLoading(true);
      const fields: GQLCreateCompanyFieldsInput = {
        name,
        category,
        description,
        website,
        phone,
        promotion: isPromotion,
        promoTagline: promoTag,
        promoDescr,
        promoCTA: promoCTA,
        Owner: {
          link: viewerId,
        },
      };

      if (promoCTA === TPromoCTA.revealCode) {
        fields.revealCode = valueCTA;
      }
      if (promoCTA === TPromoCTA.visitWebsite) {
        fields.visitedWebsite = valueCTA;
      }
      if (promoCTA === TPromoCTA.interested) {
        fields.interestedUsers = [];
      }

      CompanySchema.validate<Partial<AmenityFormValues>>(values);
      checkItemImagesFormat(values.images);

      fields.images = (await Promise.all(
        (values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  Owner: {link: viewerId},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      )) as Array<Partial<PointerFile>>;

      const response = await callCreate({
        variables: {
          fields,
        },
      });

      const data = response?.data?.createCompany?.company;
      if (!data) throw new Error();

      push(route.bizCreated.get({id: data?.id as string}));
    } catch (error) {
      setError(error as any);
    } finally {
      setIsLoading(loading);
    }
  };

  const onChange: TCompanyFormData['onChange'] = ({key, value}) => {
    setError(null);
    setValues((prev) => ({...prev, [key]: value}));
  };

  return {
    values,
    onSubmit,
    onChange,
    loading: isLoading,
    error,
  };
};

interface IUseUpdateCompany {
  (): TCompanyFormData;
}

export const useUpdateCompany: IUseUpdateCompany = () => {
  const {push} = useHistory();
  const {id: companyId} = useParams<{id: string}>();

  const {data, loading: loadingItem} = useGetCompany(companyId);

  const [callUpdate, {loading: loadingUpdate}] = useMutation<TUpdateCompanyResponse, TUpdateCompanyRequest>(
    UpdateCompanyQuery,
  );
  const [createAppFile] = useMutation<CreateFileResponseType>(CreateAppFile);
  const [updateAppFile] = useMutation<UpdateFileResponseType, UpdateFileRequestType>(UpdateAppFile);

  const [values, setValues] = useState<Partial<TCompanyFormValues>>({
    images: Array.from({length: 6}, (v, k) => ({id: String(k), value: '', order: k})),
  });
  const [error, setError] = useFormError<TCompanyFormValues>();
  const _values = {
    ...values,
    name: values?.name?.trim(),
  };
  const {name, category, description, website, phone, isPromotion, promoTag, promoDescr, promoCTA, valueCTA} = _values;

  const onSubmit: TCompanyFormData['onSubmit'] = async () => {
    try {
      const fields: GQLCreateCompanyFieldsInput = {
        name,
        category,
        description,
        website,
        phone,
        promotion: isPromotion,
        promoTagline: promoTag,
        promoDescr,
        promoCTA,
      };

      if (promoCTA === TPromoCTA.revealCode) {
        fields.revealCode = valueCTA;

        fields.visitedWebsite = undefined;
        fields.interestedUsers = undefined;
      }
      if (promoCTA === TPromoCTA.visitWebsite) {
        fields.visitedWebsite = valueCTA;

        fields.revealCode = undefined;
        fields.interestedUsers = undefined;
      }
      if (promoCTA === TPromoCTA.interested) {
        fields.interestedUsers = [];

        fields.revealCode = undefined;
        fields.visitedWebsite = undefined;
      }

      CompanySchema.validate<Partial<AmenityFormValues>>(values);
      checkItemImagesFormat(values.images);

      fields.images = (await Promise.all(
        (values.images || [])
          ?.filter((it) => Boolean(it.value))
          ?.map(async (file) => {
            if (typeof file.value === 'string') return values?.serverImages?.find((it) => it.objectId === file.id);
            if (Number(file?.id?.length) > 1) {
              const result = await updateAppFile({
                variables: {
                  id: file.id as string,
                  fields: {
                    file: {upload: file.value as File},
                    type: imageType.itemImage,
                  },
                },
              });
              return toPointer(result.data?.updateAppFile?.appFile);
            }
            const result = await createAppFile({
              variables: {
                fields: {
                  file: {upload: file.value},
                  type: imageType.itemImage,
                },
              },
            });
            return toPointer(result.data?.createAppFile?.appFile);
          }),
      )) as Array<Partial<PointerFile>>;

      await callUpdate({
        variables: {
          fields,
          id: companyId,
        },
      });

      push(route.bizCreated.get({id: companyId}));
    } catch (error) {
      setError(error as any);
    }
  };

  const onChange: TCompanyFormData['onChange'] = ({key, value}) => {
    setError(null);
    setValues((prev) => ({...prev, [key]: value}));
  };

  useEffect(() => {
    if (!data) return;
    setValues(toFormValuesCompany(data));
  }, [data]);

  return {
    values,
    onSubmit,
    onChange,
    loading: loadingUpdate || loadingItem,
    error,
  };
};

export const useCompanyCTA = (id: string) => {
  const [callIncrement] = useMutation<unknown, TCountCompanyClickRequest>(CountCompanyClickQuery);
  const [callManage] = useMutation<unknown, TCompanyManageRequest>(CompanyManageQuery);

  const incrementClick = () => {
    return callIncrement({variables: {id}});
  };
  const onInterested = () => {
    return callManage({variables: {companyId: id, action: GQLCompanyManageAction.interested, communityIdList: []}});
  };

  return {
    incrementClick,
    onInterested,
  };
};
