import React from 'react';
import styled from 'styled-components';
import {Text} from '../Typography/Text';
import {IconSvg} from '../Icon/Svg';

interface GenerateProps {
  loading: boolean;
  initText: string;
  className: string;
  onClick?: () => void;
  count?: number;
}

const Wrapper = styled.div<{$isLoading: boolean; changed: boolean}>`
  width: ${({$isLoading, changed}) => ($isLoading || changed ? '28px' : 'max-content')};
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 5px;
  background-color: ${({$isLoading}) => ($isLoading ? '#F2F2F2' : '#FFEFCE')};
  transition: all 250ms linear;
  transition-property: width, color;
  padding: 2px 4px;
  cursor: pointer;
  .loading {
    animation: loading 1.5s infinite linear;
  }

  @keyframes loading {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const GenerateButton: React.FC<GenerateProps> = ({loading, initText, className, onClick, count = 0}) => {
  return (
    <Wrapper onClick={onClick} className={className} $isLoading={loading} changed={count >= 2}>
      {!loading && count < 2 && <Text size={12}>{initText}</Text>}
      {loading ? (
        <IconSvg type={'loading'} className={'loading'} viewBox={'-2.5 -2.5 18 18'} />
      ) : (
        <IconSvg type={'stars'} width={'12px'} viewBox={'1 1 16 16'} />
      )}
    </Wrapper>
  );
};
