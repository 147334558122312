import {gql} from '@apollo/client';
import {DocumentFragment} from './fragments/document';
import {Connection} from './types/parse';
import {Document} from './types/document';
import {
  GQLCreateDocumentFieldsInput,
  GQLDocumentActions,
  GQLDocumentOrder,
  GQLDocumentWhereInput,
  GQLUpdateDocumentFieldsInput,
} from '../graphql.schema';

export interface GetDocumentRes {
  document: Document;
}

export interface GetDocumentParams {
  id: string;
}

export const GetDocumentQuery = gql`
  query getDocument($id: ID!) {
    document(id: $id) {
      ...DocumentFragment
    }
  }
  ${DocumentFragment}
`;

export interface GetDocumentsRes {
  documents: Connection<Document>;
}

export interface GetDocumentsParams {
  where?: GQLDocumentWhereInput;
  order?: Array<GQLDocumentOrder>;
}

export const GetDocumentsQuery = gql`
  query GetDocuments($where: DocumentWhereInput, $order: [DocumentOrder!]) {
    documents(where: $where, order: $order) {
      count
      edges {
        node {
          ...DocumentFragment
        }
      }
    }
  }
  ${DocumentFragment}
`;

export interface AddDocumentsRes {
  createDocument: Document;
}

export interface AddDocumentsParams {
  fields: GQLCreateDocumentFieldsInput;
}

export const AddDocumentQuery = gql`
  mutation addDocument($fields: CreateDocumentFieldsInput!) {
    createDocument(input: {fields: $fields}) {
      document {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragment}
`;
export interface ActionOnDocumentParams {
  docId: string;
  type: GQLDocumentActions;
}

export const ActionOnDocumentQuery = gql`
  mutation actionOnDocument($docId: String!, $type: DocumentActions!) {
    setDocumentStatus(input: {docId: $docId, type: $type})
  }
`;

export interface UpdateDocumentRes {
  document: Document;
}

export interface UpdateDocumentParams {
  id: string;
  fields: GQLUpdateDocumentFieldsInput;
}

export const UpdateDocumentQuery = gql`
  mutation updateDocument($id: ID!, $fields: UpdateDocumentFieldsInput) {
    updateDocument(input: {id: $id, fields: $fields}) {
      document {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragment}
`;
