import styled from 'styled-components';

export const LoaderContainer = styled.div<{zIndex?: number; $isVisible?: boolean; $hideFromApp?: boolean}>`
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1001)};
  opacity: 1;
  visibility: visible;
  transition: 0.51s all;
  ${(props) => !props.$isVisible && ` opacity:0; visibility:hidden;`}
  ${({$hideFromApp}) => $hideFromApp && ` opacity:0; height: 1px; width: 1px;`}
`;

export const LoaderItemWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  padding: 45px 30px;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LoaderOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
`;
