import React from 'react';
import {PageWrapper, SettingsWrapper} from '../commonStyles';
import {SettingsHeader} from '../components';
import {useTranslation} from 'react-i18next';
import {SettingsPayouts} from './SettingsPayouts';
import {CommunityStateType} from '../../../states/community';
import {User} from '../../../queries/types/user';
import {PayoutsItem} from '../../../types/settings';
import {IGetCurrValue} from '../../../types/common';
import {GoBack} from '../../common/Buttons/GoBack';

interface PayoutsSettingsProps {
  items?: PayoutsItem[];
  // items?: Transaction[];
  community: CommunityStateType;
  user: User;
  itemsCount: number;
  allItemsCount: number;
  isManager?: boolean;
  loading?: boolean;
  totalAmount?: number | null;
  getSignValue: IGetCurrValue;
  adminLabels: Record<string, string>;
}

export const PayoutsSettings: React.FC<PayoutsSettingsProps> = ({
  items,
  community,
  user,
  itemsCount,
  allItemsCount,
  isManager,
  loading,
  totalAmount,
  getSignValue,
  adminLabels,
}) => {
  const {t} = useTranslation();
  const goBack = () => window?.history.back();

  return (
    <PageWrapper>
      <SettingsWrapper max={950}>
        <GoBack title={t('settings:payouts.title')} goBack={goBack} />
        <SettingsHeader title={t('settings:payouts.title')} subtitle={t('settings:payouts.subtitle')} hideMob />
        <SettingsPayouts
          items={items}
          community={community}
          user={user}
          itemsCount={itemsCount}
          allItemsCount={allItemsCount}
          isManager={isManager}
          loading={loading}
          totalAmount={totalAmount}
          getSignValue={getSignValue}
          adminLabels={adminLabels}
        />
      </SettingsWrapper>
    </PageWrapper>
  );
};
