import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TEXTAREA_MAX_LENGTH} from '../../../../constants/common';
import {correctTranslate, getSiteAlias} from '../../../../helpers/common';
import {getResize} from '../../../../helpers/file';
import {User as UserType} from '../../../../queries/types/user';
import {TypeCommunity} from '../../../../types/auth';
import {TOptions} from '../../../../types/community';
import {MemberT} from '../../../../types/people';
import {ProfileField} from '../../../../types/profile';
import {Avatar} from '../../../../ui-kit/Avatar/Avatar';
import {Button} from '../../../../ui-kit/Button/Button';
import {Input} from '../../../../ui-kit/Form/Input';
import {TextArea} from '../../../../ui-kit/Form/TextArea/TextArea';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {ErrorMessage} from '../../../Auth/styles';
import {Box} from '../../../common/Box';
import {MemoSymbolsCounter} from '../../../common/SymbolsCounter';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  FormTopTitle,
  GreetingTitle,
  InnerText,
  ProfileForm,
  WrapperForm,
  CommunityTip,
  MemberWrapper,
  TextUI,
  TextAreaWrapper,
} from '../styles';
import {ResProfileType} from '../../../../hooks/user';
import {analyticsTrackFN} from '../../../../helpers/account';
import {GenerateButton} from '../../../../ui-kit/Button/GenerateButton';

interface IProps {
  user?: UserType;
  typeCommunity: TypeCommunity;
  name?: string;
  params: ResProfileType;
  optionsHowDidYouHear?: TOptions[];
  optionsLivingWith?: TOptions[];
  generateUsername: (name: string) => Promise<string | undefined>;
  members?: MemberT[];
  onSkipProfile: () => void;
  loadingGenerate?: boolean;
  generateData?: (type: 'bio' | 'post', additional?: {language: string; occupation: string}) => Promise<string>;
}

export const FormProfile: React.FC<IProps> = ({
  typeCommunity,
  params,
  generateUsername,
  members,
  loadingGenerate,
  user,
  generateData,
  //onSkipProfile,
}) => {
  const {values, onSubmit, loading, onChange, error} = params;
  const {t} = useTranslation();
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });
  // const [isInvited] = useRecoilState(inviteToCommunityState);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true);
  const [suggestedUsername, setSuggestedUsername] = useState<string>(values.username ?? '');
  const [changed, setChanged] = useState(true);
  const [generatedData, setGeneratedData] = useState<{count: number; text: string}>({count: 0, text: ''});

  const handleChangeUsername = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 20) return;
    if (e.target.value.indexOf(' ') !== -1) return;
    setChanged(false);
    onChange({name: e.target.name, value: e.target.value});

    const suggestedUsername = await generateUsername(e.target.value);

    setUsernameAvailable(suggestedUsername === e.target.value);
    setSuggestedUsername(suggestedUsername ?? e.target.value);
  };
  const isResident = typeCommunity === TypeCommunity.resident;

  const handleChange = ({target}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (target.name === 'bio') {
      if (target.value.length > TEXTAREA_MAX_LENGTH) return;
    }
    if (target.name === 'username') {
      if (target.value.length > 20) return;
    }
    onChange({name: target.name, value: target.value});
  };

  // const handleSelect = (name: string) => (data: TOptions[]) => {
  //   const value = data[0]?.label;
  //   onChange({name: name, value: value || ''});
  // };

  // const handleChangeDate = (value: Date) => {
  //   onChange({name: ProfileField.birthDate, value});
  // };

  const handleSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    values.username = suggestedUsername;
    analyticsTrackFN('Profile Created', {
      name: `${user?.firstName} ${user?.lastName}`,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      userName: values?.username,
      defaultUserName: changed, // true if the username is not changed by user
      bio: values?.bio,
      canSpeak: values?.languages,
      occupation: values?.occupation,
      zipCode: user?.zip,
      city: user?.Location?.City,
      state: user?.Location?.State,
      country: user?.Location?.Country,
      phone: user?.phone,
    });
    onSubmit();
  };

  // const getLang = useGetLanguage();
  // useEffect(() => {
  //   if (isInvited?.communityId) {
  //     handleSubmit();
  //   }
  // }, [!!isInvited?.communityId]);

  const handleGenerate = async () => {
    if (loadingGenerate) return;
    const data = await generateData?.('bio', {occupation: values?.occupation || '', language: values?.languages || ''});
    setGeneratedData({text: data || '', count: generatedData?.count + 1});
  };

  useEffect(() => {
    if (generatedData?.text) {
      onChange({name: 'bio', value: generatedData?.text});
    }
  }, [generatedData?.text]);

  useEffect(() => {
    setTimeout(() => handleGenerate(), 500);
  }, []);

  return (
    <WrapperForm paddingX={isResident ? '34px' : '30px'}>
      {/*<HeaderLogo centeredLogo={true} />*/}
      <FormTopTitle>
        <GreetingTitle>{t('profile:titles.createProfile')}</GreetingTitle>
        {/*isResident && (
          <SkipBtn onClick={onSkipProfile}>
            <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
              {t('auth:firstPost.skip')}
            </Text>
          </SkipBtn>
        )*/}
      </FormTopTitle>
      <Box margin={'0 0 18px 0'} textAlign="left">
        {members && members.length >= 3 && (
          <MemberWrapper>
            {members?.map((mb, index) => (
              <Avatar
                className={'avatar'}
                key={mb?.objectId || index}
                size={'sm'}
                src={getResize(mb?.Avatar?.file?.url, 'lg')}
              />
            ))}
            <CommunityTip>{t('profile:haveCommunity')}</CommunityTip>
          </MemberWrapper>
        )}
      </Box>
      <ProfileForm name={'form-profile'} onSubmit={handleSubmit}>
        <div>
          <Input
            $isError={Boolean(error.username)}
            name={ProfileField.username}
            value={values.username || ''}
            onChange={handleChangeUsername}
            label={t('forms:username')}
            prefix={`${getSiteAlias()}/`}
            maxLength={20}
            icon={
              usernameAvailable ? (
                <IconSvg type={'circle-ok'} stroke={'green'} />
              ) : (
                <IconSvg type={'spam-2'} stroke={'red'} />
              )
            }
          />
          <InnerText>
            <TextUI offsetTop={5}>{t(`profile:description.account.form.username.${typeCommunity}`)}</TextUI>
            <MemoSymbolsCounter value={values.username?.length} limit={20} />
          </InnerText>
        </div>
        <Input
          $isError={Boolean(error.languages)}
          name={ProfileField.languages}
          value={values.languages || ''}
          onChange={handleChange}
          label={t('forms:lang')}
        />
        {isResident ? (
          <>
            <Input
              $isError={Boolean(error.occupation)}
              name={ProfileField.occupation}
              value={values.occupation || ''}
              onChange={handleChange}
              label={t('forms:occupation')}
            />
          </>
        ) : (
          <>
            <Input
              $isError={Boolean(error.study)}
              name={ProfileField.study}
              value={values.study || ''}
              onChange={handleChange}
              label={t('forms:study')}
              icon={<IconSvg width={'24px'} height={'24px'} type={'news'} />}
            />
            <Input
              $isError={Boolean(error.work)}
              name={ProfileField.work}
              value={values.work || ''}
              onChange={handleChange}
              label={t('forms:work')}
              icon={<IconSvg width={'24px'} height={'24px'} type={'walking'} />}
            />
          </>
        )}
        <TextAreaWrapper>
          {generateData && generatedData?.count <= 3 && (
            <GenerateButton
              loading={Boolean(loadingGenerate)}
              initText={t('auth:auth.buttons.generate')}
              className={'generate'}
              onClick={handleGenerate}
              count={generatedData?.count}
            />
          )}
          <TextArea
            $isError={Boolean(error.bio)}
            name={ProfileField.bio}
            value={values.bio || ''}
            onChange={handleChange}
            label={t('forms:bio')}
            maxLength={TEXTAREA_MAX_LENGTH}
            rows={8}
          />
          <Box margin={'3px 0 0 0'} textAlign={'right'} padding={'0 16px'}>
            <TextUI offsetTop={5}>
              <MemoSymbolsCounter value={values.bio?.length} limit={TEXTAREA_MAX_LENGTH} />
            </TextUI>
          </Box>
        </TextAreaWrapper>
        <Button type={'submit'} loading={loading}>
          {t('profile:button.createProfile')}
        </Button>
      </ProfileForm>
      <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
      <LottieLoader $isVisible={loading} allScreen={true} />
    </WrapperForm>
  );
};
