import {PointerFile} from '../../types/common';
import {Community} from './community';
import {Connection, ParseObject} from './parse';
import {User} from './user';

export enum TTypeOfGroup {
  default = 'default',
  custom = 'custom',
}
export interface Group extends ParseObject {
  name: string;
  description?: string;
  Avatar?: PointerFile;
  Community?: Community;
  Members?: Connection<User>;
  isDeleted?: boolean;
  type?: TTypeOfGroup;
  alias?: string;
}
