/* eslint-disable react/display-name */
import React, {memo, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {paths} from '../constants/routes';
import {usePreloadFeed, usePreloadInbox, usePreloadListings, usePreloadPeople, useResetPreload} from '../hooks/preload';
import {CommunityStateType, currentCommunity} from '../states/community';

/*const EmptyProvider: React.FC = ({children}) => {
  return children as React.ReactElement;
};

const ProviderWithPreload: React.FC = ({children}) => {
  const community = useRecoilValue(currentCommunity);
  return (
    <>
      <MemoPreloadInbox community={community} />
      <MemoPreloadPeople community={community} />
      <MemoPreloadListings community={community} />
      <MemoPreloadFeed community={community} />
      {children}
    </>
  );
};*/

const ProviderWithPreload: React.FC = ({children}) => {
  const community = useRecoilValue(currentCommunity);
  return (
    <>
      {community && <MemoPreloadInbox community={community} />}
      {community && <MemoPreloadPeople community={community} />}
      {community && <MemoPreloadListings community={community} />}
      {community && <MemoPreloadFeed community={community} />}
      {children}
    </>
  );
};

export const PreloadProvider: React.FC = ({children}) => {
  const reset = useResetPreload();
  const community = useRecoilValue(currentCommunity);
  //const Provider = community ? ProviderWithPreload : EmptyProvider;
  useEffect(() => {
    reset();
  }, [community?.objectId]);

  //return <Provider>{children}</Provider>;

  return <ProviderWithPreload>{children}</ProviderWithPreload>;
};

const MemoPreloadInbox: React.FC<{community: CommunityStateType}> = memo(({community}) => {
  const {pathname} = useLocation();
  if (pathname?.includes(paths.messages)) return null;
  usePreloadInbox(community);
  return null;
});

const MemoPreloadPeople: React.FC<{community: CommunityStateType}> = memo(({community}) => {
  usePreloadPeople(community);
  return null;
});

const MemoPreloadListings: React.FC<{community: CommunityStateType}> = memo(({community}) => {
  usePreloadListings(community);
  return null;
});

const MemoPreloadFeed: React.FC<{community: CommunityStateType}> = memo(({community}) => {
  usePreloadFeed(community);
  return null;
});
