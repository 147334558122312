import React from 'react';
import {Route, RouteProps, Redirect, useLocation} from 'react-router-dom';
import {PlanOptionsValues} from '../constants/plan';
import {useResolveRoute} from '../helpers/community';
import {useIsAuthenticated} from '../hooks/auth';
import {TypeCommunity} from '../types/auth';
import {useLinks} from '../hooks/common';
import {route} from '../constants/routes';
import {getMobPath, pathIndexMap, PathsList} from '../helpers/mobNavigation';
import {isMobile} from 'react-device-detect';
import {MobileTabs} from './MobileTabs';

interface IPrivateRoute extends RouteProps {
  planOption?: PlanOptionsValues;
  roles?: Array<TypeCommunity>;
}

export const PrivateRoute: React.FC<IPrivateRoute> = ({planOption, roles, component: Component, ...rest}) => {
  const isAuth = useIsAuthenticated();
  const {pathname} = useLocation();
  const resolveRoute = useResolveRoute(planOption, roles);
  const {getLink} = useLinks();

  if (!Component) return null;
  const path = getMobPath(pathname as string);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentIndex = pathIndexMap?.[path as PathsList];
  const showTabs = isMobile && currentIndex !== undefined && currentIndex >= 0;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth && resolveRoute ? (
          showTabs ? (
            <MobileTabs current={currentIndex} {...props} />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={isAuth ? getLink(route.home.path) : route.home} />
        )
      }
    />
  );
};
