import React from 'react';
import {MemoLoadingSkeleton} from './LoadingSkeleton';
import {FeedSkeletonWrapper, PairWrapper} from './styles';

export const FeedSkeleton: React.FC = () => {
  return (
    <FeedSkeletonWrapper>
      <MemoLoadingSkeleton circle={true} width={36} height={36} />
      <PairWrapper>
        <MemoLoadingSkeleton height={16} />
        <MemoLoadingSkeleton width={100} height={16} />
      </PairWrapper>
    </FeedSkeletonWrapper>
  );
};

export const FeedSkeletonSection: React.FC = () => {
  return (
    <>
      <FeedSkeleton key={1} />
      <FeedSkeleton key={2} />
      <FeedSkeleton key={3} />
    </>
  );
};
