import React from 'react';
import {PersonTypeWrapper} from '../styles';
import {Label} from '../../../ui-kit/Labels';
import {Status} from '../../../ui-kit/ProfileIntro/labels';
import {useTranslation} from 'react-i18next';

type TProps = {
  isManager?: boolean;
  isAdmin?: boolean;
  aptSuite?: string | number;
  unit?: string;
  adminLabels: Record<string, string>;
  userId: string;
};

export const PersonType: React.FC<TProps> = ({isAdmin, isManager, aptSuite, adminLabels, userId}) => {
  const {t} = useTranslation();
  const managerText = t('people:person.labels.admin');
  const adminText: string = adminLabels[userId] || t('people:person.labels.manager');

  if (isManager) {
    return (
      <PersonTypeWrapper>
        <Status status={'tertiary'} name={managerText} />
      </PersonTypeWrapper>
    );
  }

  if (isAdmin) {
    return (
      <PersonTypeWrapper>
        <Status status={'tertiary'} name={adminText} />
      </PersonTypeWrapper>
    );
  }

  if (aptSuite) {
    return (
      <PersonTypeWrapper>
        <Label squared={true} variant={'quinary'}>
          {aptSuite}
        </Label>
      </PersonTypeWrapper>
    );
  }
  return null;
};
