export const InterestsKey = <const>[
  'sports',
  'food',
  'travel',
  'movies',
  'arts',
  'fitness',
  'games',
  'photo',
  'videoGames',
  'music',
  'book',
  'biking',
];

export const InterestsGroup = {
  sports: 'hikingRunning',
  food: 'cookingBaking',
  travel: 'travelTours',
  movies: 'moviesShows',
  arts: 'artsHandicrafts',
  fitness: 'yogaMeditation',
  videoGames: 'videoConsoleGames',
  games: 'boardCardGames',
  photo: 'photographyVideography',
  music: 'musicFestivals',
  book: 'bookClub',
  biking: 'biking',
};
