import React from 'react';
import {WrapperPageListings, WrapperSearch} from './styles';
import {TItem} from '../../types/item';
import {CommunityStateType} from '../../states/community';
import {ListItemsWithPreview} from './ListItemsWithPreview';
import {TypeCommunity} from '../../types/auth';
import {SetItemsSearchParams} from '../../containers/Listings/types';
import {Search} from './Search';
import {MobileSearch} from './MobileSearch';
import {route} from '../../constants/routes';
import {EmptyState} from '../EmptyState/EmptyState';
import {useLinks} from '../../hooks/common';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {LikeState} from '../../hooks/item';
import {useTranslation} from 'react-i18next';

interface IProps {
  community: CommunityStateType;
  ownItems?: Partial<TItem>[];
  loading?: boolean;
  allItems?: Partial<TItem>[];
  featured?: Partial<TItem>[];
  fetchDataAllItems: () => void;
  hasMoreAllItems: boolean;
  newItems?: Partial<TItem>[];
  fetchDataNewItems?: () => void;
  hasMoreNewItems?: boolean;
  hideItem: (itemId?: string) => () => void;
  featureItem: (itemId?: string) => () => void;
  unFeatureItem: (itemId?: string) => () => void;
  approveItem: (itemId?: string) => () => void;
  onUnlist: (itemId?: string) => () => void;
  onPublish: (itemId?: string) => () => void;
  setSearchParams: SetItemsSearchParams;
  userId?: string;
  reportActions?: reportActions;
  getSignValue: IGetCurrValue;
  onLike?: (id: string) => void;
  liked?: LikeState;
}

export const PageManager: React.FC<IProps> = ({
  allItems,
  hasMoreAllItems,
  fetchDataAllItems,
  newItems,
  fetchDataNewItems,
  hasMoreNewItems,
  community,
  loading,
  hideItem,
  featureItem,
  approveItem,
  setSearchParams,
  onPublish,
  onUnlist,
  userId,
  unFeatureItem,
  reportActions,
  getSignValue,
  featured,
  liked,
  onLike,
}) => {
  const {t} = useTranslation();
  const {getLink} = useLinks();
  return (
    <WrapperPageListings>
      <WrapperSearch>
        <Search setSearchParams={setSearchParams} />
        <MobileSearch setSearchParams={setSearchParams} />
      </WrapperSearch>
      {newItems?.[0] && (
        <ListItemsWithPreview
          items={newItems}
          communityId={community?.objectId}
          fetchDataItems={fetchDataNewItems}
          hasMoreItems={hasMoreNewItems}
          approveItem={approveItem}
          hideItem={hideItem}
          isPreview={true}
          typeUser={TypeCommunity.manager}
          loading={loading}
          userId={userId}
          onUnlist={onUnlist}
          reportActions={reportActions}
          getSignValue={getSignValue}
        />
      )}
      {!allItems?.length && !newItems?.length && !loading ? (
        <EmptyState />
      ) : (
        <ListItemsWithPreview
          communityId={community?.objectId}
          items={allItems}
          featured={featured}
          fetchDataItems={fetchDataAllItems}
          hasMoreItems={hasMoreAllItems}
          isPreview={false}
          hideItem={hideItem}
          featureItem={featureItem}
          unFeatureItem={unFeatureItem}
          typeUser={TypeCommunity.manager}
          featuredDivided={true}
          loading={loading}
          onPublish={onPublish}
          onUnlist={onUnlist}
          userId={userId}
          reportActions={reportActions}
          getSignValue={getSignValue}
          liked={liked}
          onLike={onLike}
        />
      )}
      <AddButtonPortal
        link={getLink(route.createItem.path)}
        iconType={'cube'}
        viewBox={'-1 -1 22 22'}
        animated
        animatedText={t('common:addButtons.list')}
      />
    </WrapperPageListings>
  );
};
