import React, {useEffect} from 'react';
import {useGetTargetTypeUser, useViewerId} from '../../hooks/user';
import {useNewViewer} from '../../hooks/notifications';
import {TDataCreator, useCheckAmenityForAvailability, useCreateAmenityOrder} from '../../hooks/amenity';
import {useCreateMessage} from '../../hooks/message';
import {ViewAmenity} from '../../components/Amenities/View';
import {AmenityFormAction} from '../../types/amenity';
import {CommunityStateType} from '../../states/community';
import {useGetAdminsLabels} from '../../hooks/people';
import {Amenity} from '../../queries/types/amenity';

type TProps = {
  id?: string;
  community: CommunityStateType;
  dataCreator: TDataCreator;
  loading?: boolean;
  amenity?: Partial<Amenity>;
};

export const ViewAmenityItem: React.FC<TProps> = ({community, dataCreator, id, loading, amenity}) => {
  const viewerObjId = useViewerId('objectId');
  const createViewer = useNewViewer();

  const available = useCheckAmenityForAvailability({
    amenity: amenity,
    userId: viewerObjId,
    strictCheck: false,
  });

  const dataCreateMsg = useCreateMessage({
    idActiveContact: amenity?.Lister?.objectId,
    communityId: community?.objectId,
    initialState: {
      Author: viewerObjId,
      text: '',
      receiverEmail: amenity?.Lister?.email,
      receiverName: `${amenity?.Lister?.firstName} ${amenity?.Lister?.lastName}`,
    },
  });

  useEffect(() => {
    if (amenity?.id && amenity?.Lister?.objectId !== viewerObjId) {
      createViewer(amenity.id, 'Amenity');
    }
  }, [id, amenity?.Lister?.objectId, viewerObjId]);
  const requestAccess = true;
  const dataOrder = useCreateAmenityOrder({
    communityId: community?.objectId,
    amenityId: amenity?.objectId,
    rewardType: amenity?.rewardType,
  });
  const adminLabels = useGetAdminsLabels();
  const targetTypeUser = useGetTargetTypeUser(amenity?.Lister?.objectId);
  return (
    <ViewAmenity
      communityAlias={community?.alias}
      dataCreateMsg={dataCreateMsg}
      viewerObjId={viewerObjId}
      action={AmenityFormAction.view}
      amenity={amenity}
      $loading={loading}
      dataCreator={dataCreator}
      isAvailable={available.availableData}
      requestAccess={requestAccess}
      dataOrder={dataOrder}
      adminLabels={adminLabels}
      targetTypeUser={targetTypeUser}
    />
  );
};
