import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {getCorrectExternalLink, getPhoneLink} from '../../helpers/common';
import {TPromoCTA} from '../../types/company';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Label} from '../../ui-kit/Labels';
import {Media} from '../../ui-kit/theme';
import {zIndex} from '../../ui-kit/theme/zIndex';
import {Text} from '../../ui-kit/Typography/Text';
import {Box} from '../common/Box';
import {BlankExternalLink} from '../common/Links';
import {RichText} from '../common/RichText/RichText';
import {Line} from './styles';

const AsideWrapper = styled.aside`
  display: inline-flex;
  flex-direction: column;
  gap: 20px;

  z-index: ${zIndex._1};

  ${Media.up.m} {
    position: absolute;
    inset: 39px 0 auto auto;
    width: 300px;
  }

  ${Media.down.m} {
    width: 100%;
    box-sizing: border-box;
  }
`;

const InnerCompanyInfo = styled.div`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-radius: 4px;
  padding: 32px 0 24px;
`;

const InnerPromotionInfo = styled.div`
  border: 1px solid ${({theme}) => theme.palette.background.secondary};
  border-radius: 4px;
  padding: 25px 32px;
`;

const ButtonCTA = styled(Button)`
  border-radius: 10px;
  height: 40px;

  .button-value {
    display: flex;
    align-items: center;
  }
`;

const LongLink = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoCTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.text.octonary};
  border-radius: 10px;
  height: 40px;
`;

const Icon = styled(IconSvg)`
  flex-shrink: 0;
`;

export const AsideCompanyInfo: React.FC<ICompanyInfo & Partial<IPromotionInfo & IPromoCTA> & {isPromo: boolean}> = ({
  isPromo,
  ...props
}) => {
  return (
    <AsideWrapper>
      <MemoCompanyInfo {...props} />

      {isPromo && <PromotionInfo {...(props as IPromotionInfo & IPromoCTA)} />}
    </AsideWrapper>
  );
};

interface ICompanyInfo {
  name: string;
  category: string;
  website?: string;
  phone?: string;
  companyAvatar?: string;
}

const CompanyInfo: React.FC<ICompanyInfo> = ({name, category, website, phone, companyAvatar}) => {
  return (
    <InnerCompanyInfo>
      <Box display={'flex'} gap={'12px'} padding={'0 0 14px 32px'} items={'center'}>
        <Avatar src={companyAvatar} size={'xs'} />
        <Box display={'flex'} direction={'column'} gap={'4px'}>
          <Text color={'octonary'} size={18} weight={'medium'}>
            {name}
          </Text>

          <span>
            <Label squared variant={'quinary'}>
              {category}
            </Label>
          </span>
        </Box>
      </Box>

      <Line />

      <Box display={'flex'} direction={'column'} gap={'6px'} padding={'14px 0 0 40px'}>
        {website && (
          <BlankExternalLink to={getCorrectExternalLink(website)}>
            <Box display={'flex'} gap={'12px'} items={'center'}>
              <Icon type={'link'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />
              <LongLink color={'octonary'} size={14}>
                {website}
              </LongLink>
            </Box>
          </BlankExternalLink>
        )}
        {phone && (
          <BlankExternalLink to={getPhoneLink(phone)} openInNewTab={false}>
            <Box display={'flex'} gap={'12px'} items={'center'}>
              <Icon type={'phone'} width={'24px'} height={'24px'} viewBox={'0 0 24 24'} />
              <Text color={'octonary'} size={14}>
                {phone}
              </Text>
            </Box>
          </BlankExternalLink>
        )}
      </Box>
    </InnerCompanyInfo>
  );
};

const MemoCompanyInfo = React.memo(CompanyInfo);

interface IPromotionInfo {
  promoTag: string;
  promoDescr: string;
}

export const PromotionInfo: React.FC<IPromotionInfo & IPromoCTA> = ({promoTag, promoDescr, ...props}) => {
  const {t} = useTranslation();

  return (
    <InnerPromotionInfo>
      <Box display={'flex'} direction={'column'} gap={'4px'}>
        <Text color={'octonary'} size={18} weight={'medium'}>
          {promoTag}
        </Text>

        <span>
          <Label squared variant={'quaternary'}>
            {t('company:localBiz.labels.promotion')}
          </Label>
        </span>
      </Box>
      <Box padding={'16px 0 8px 0'}>
        <RichText html={promoDescr} />
      </Box>
      <PromoCTA {...props} />
    </InnerPromotionInfo>
  );
};

interface IPromoCTA {
  onCTA?: () => void;
  typeCTA: TPromoCTA;
  revealCode?: string;
  visitWebsite?: string;
  isInterested?: boolean;
}

const PromoCTA: React.FC<IPromoCTA> = ({typeCTA, onCTA, revealCode, visitWebsite, isInterested}) => {
  const {t} = useTranslation();

  const [showCode, setShowCode] = useState(false);

  const handleShowCode = () => {
    setShowCode(true);
    onCTA?.();
  };

  if (typeCTA === TPromoCTA.visitWebsite)
    return (
      <BlankExternalLink to={getCorrectExternalLink(visitWebsite as string)}>
        <ButtonCTA onClick={onCTA}>{t('company:localBiz.buttonsCTA.visitWebsite')}</ButtonCTA>
      </BlankExternalLink>
    );

  if (typeCTA === TPromoCTA.interested && !isInterested)
    return <ButtonCTA onClick={onCTA}>{t('company:localBiz.buttonsCTA.interested')}</ButtonCTA>;

  if (typeCTA === TPromoCTA.interested && isInterested)
    return (
      <InfoCTA>
        <Text size={16} weight={'medium'} variant={'octonary'}>
          {t('company:localBiz.buttonsCTA.interested')}
        </Text>
      </InfoCTA>
    );

  if (typeCTA === TPromoCTA.revealCode && showCode)
    return (
      <InfoCTA>
        <Text size={16} weight={'medium'} variant={'octonary'}>
          {revealCode}
        </Text>
      </InfoCTA>
    );

  if (typeCTA === TPromoCTA.revealCode && !showCode)
    return <ButtonCTA onClick={handleShowCode}>{t('company:localBiz.buttonsCTA.revealCode')}</ButtonCTA>;

  return null;
};
