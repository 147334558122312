import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {route} from '../../constants/routes';
import {useLinks} from '../../hooks/common';
import {useChangePassword} from '../../hooks/auth';
import {NewPassword} from '../../components/Auth/NewPassword';

export const NewPasswordContainer: React.FC = () => {
  const changePassData = useChangePassword();
  const {search} = useLocation();
  const {getLink} = useLinks();

  const urlParams = new URLSearchParams(search);

  const token = urlParams.get('token');
  const id = urlParams.get('id');
  const email = urlParams.get('email');

  if (!token || !id) return <Redirect to={getLink(route.home.get())} />;

  return <NewPassword {...changePassData} token={token || ''} id={id || ''} email={email || ''} />;
};
