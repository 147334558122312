import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../../ui-kit/Typography/Text';
import {Button} from '../../../../ui-kit/Button/Button';
import {PressButton} from '../../../../ui-kit/Button/PressButton';
import {MainWrapper, SelectInterestsOptionsList, FormTopTitle} from '../styles';
import {FormValue, LabelValuePair} from '../../../../types/settings';
import {Input} from '../../../../ui-kit/Form/Input';
import {Box} from '../../../common/Box';
import {IconSvg} from '../../../../ui-kit/Icon/Svg';
import {Profile} from '../../../../types/profile';
import {TRecommendantions} from '../../../../types/interests';
import {icon} from '../../../../ui-kit/Icon/Svg/types';
import {analyticsTrackFN} from '../../../../helpers/account';
import {ErrorMessage} from '../../../Settings/components';

export interface TPersonInterestsData {
  skipInterests: () => Promise<void> | void;
  skipRecs: () => Promise<void> | void;
  submit: () => Promise<void> | void;
  change: (next: {name: string; value: FormValue}) => void;
  options: Array<LabelValuePair>;
  values: {
    interests: Profile['personInterests'];
    recmnds: Profile['recommendations'];
  };
  loading: boolean;
}

interface IProps {
  data: TPersonInterestsData;
}

export const PersonInterests: React.FC<IProps> = ({data}) => {
  const {options, submit, change, loading} = data || {};
  const {t} = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  /*const handleSkip = () => {
    skipInterests();
  };*/
  const handleSubmit = () => {
    let listTrue = {};
    let listFalse = {};
    data?.options?.forEach((item) => {
      if (selectedOptions?.includes(item?.value)) {
        listTrue = {...listTrue, [item?.value]: true};
      } else listFalse = {...listFalse, [item?.value]: false};
    });
    analyticsTrackFN('Hobbies Selected', {
      hobbiesCount: Object.keys(listTrue)?.length || 0,
      ...listTrue,
      ...listFalse,
    });
    submit();
  };
  const handleSelect = (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOptions((prev) => (e.target.checked ? [...prev, value] : prev.filter((el) => el !== value)));
  };

  useEffect(() => {
    change({name: 'personInterests', value: selectedOptions});
  }, [selectedOptions]);

  return (
    <>
      <MainWrapper>
        <FormTopTitle $noOffset>
          <Text variant={'secondary'} color={'quaternary'} size={38}>
            {t('auth:personalInterests.title')}
          </Text>
          {/*<SkipBtn onClick={handleSkip}>
            <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
              {t('auth:interests.skip')}
            </Text>
          </SkipBtn>*/}
        </FormTopTitle>
        <Box textAlign="left">
          <Text variant={'primary'} color={'octonary'} size={16}>
            {t('auth:personalInterests.firstSubtitle')}
          </Text>
        </Box>
        <SelectInterestsOptionsList>
          {options?.map((el) => (
            <PressButton
              key={el.label}
              label={el.label}
              icon={el.value as icon}
              onChange={handleSelect(el.value)}
              isChecked={selectedOptions.includes(el.value)}
            />
          ))}
        </SelectInterestsOptionsList>
        <Box margin="5px 0 10px 0">
          <Button loading={loading} type={'button'} variant={'primary'} onClick={handleSubmit}>
            {t('auth:personalInterests.button')}
          </Button>
        </Box>
      </MainWrapper>
    </>
  );
};

export const PersonRecomendations: React.FC<IProps> = ({data}) => {
  const {submit, values, change, loading} = data || {};
  const {eatery, movies, places} = values.recmnds || {};
  const [error, setError] = useState('');
  const {t} = useTranslation();

  /*const handleSkip = () => {
    skipRecs();
  };*/
  const handleSubmit = () => {
    if (!eatery?.trim() && !movies?.trim() && !places?.trim()) {
      setError('error:recommendations');
      return;
    }
    submit();
  };
  const handleChange = (key: keyof TRecommendantions) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    change({name: 'recommendations', value: {...values.recmnds, [key]: e.target.value}});
  };

  return (
    <>
      <MainWrapper>
        <FormTopTitle $noOffset>
          <Text variant={'secondary'} color={'quaternary'} size={38}>
            {t('auth:personalInterests.secondSubtitle')}
          </Text>
          {/*
          <SkipBtn onClick={handleSkip}>
            <Text variant={'primary'} color={'duodecimal'} size={14} weight="semi-bold">
              {t('auth:interests.skip')}
            </Text>
          </SkipBtn>*/}
        </FormTopTitle>
        <Box direction="column" display="flex" gap="16px">
          <Box margin="0 0 2px 0" textAlign="left">
            <Text variant={'primary'} color={'octonary'} size={16}>
              {t('auth:personalInterests.secondUnderTitle')}
            </Text>
          </Box>
          <Input
            label={t('auth:recmdsLabels.eatery')}
            icon={<IconSvg type="coffee" stroke="grayLight-2" />}
            onChange={handleChange('eatery')}
            value={eatery}
          />
          <Input
            label={t('auth:recmdsLabels.movies')}
            icon={<IconSvg type="tv" stroke="grayLight-2" />}
            onChange={handleChange('movies')}
            value={movies}
          />
          <Input
            label={t('auth:recmdsLabels.places')}
            icon={<IconSvg type="car-alt" stroke="grayLight-2" />}
            onChange={handleChange('places')}
            value={places}
          />
          <Box margin="5px 0 10px 0">
            <Button loading={loading} type={'button'} variant={'primary'} onClick={handleSubmit}>
              {t('auth:personalInterests.button')}
            </Button>
          </Box>
          <ErrorMessage error={error} />
        </Box>
      </MainWrapper>
    </>
  );
};
