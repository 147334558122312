import {useTranslation} from 'react-i18next';
import {SettingsMobileButton} from '../components/common/SettingsMobileButton';
import {PlanOptionsValues} from '../constants/plan';
import {useHasPlanOption} from '../helpers/community';
// import React, {useState} from 'react';
import {TypeUserStateType} from '../states/typeUser';
//import {SettingsPopup} from '../components/Account/Popups/Settings/Settings';
import {TypeCommunity} from '../types/auth';
import {TRoutes} from '../types/navigation';
import {PermissionsList} from '../types/settings';
import {icon} from '../ui-kit/Icon/Svg/types';
import React, {useState} from 'react';
import {TLinks} from '../components/Account/Popups/Settings/types';
import {routes} from '../containers/Navigation/constans';
import {referralReward} from '../constants/common';

type TNavigationRoutes = {
  routes: TRoutes;
  nameCommunity?: string;
  typeUser?: TypeUserStateType;
  msgsNotices?: number;
  itemsNotices?: number;
  isJoinedCommunity?: boolean;
  isResident?: boolean;
  homeLabel?: string;
  homeIcon?: string;
  documentsCount?: number;
  messagesExists?: boolean;
  isSupport?: boolean | null;
  permissions?: PermissionsList[];
  listedOwner?: boolean;
  listedAdmin?: boolean;
  communityCount?: number;
};

const useNavItems = ({
  routes,
  nameCommunity,
  typeUser,
  msgsNotices,
  itemsNotices,
  isJoinedCommunity,
  documentsCount,
}: TNavigationRoutes) => {
  const {t} = useTranslation();
  const hasListingFeed = useHasPlanOption(PlanOptionsValues.listingsFeed);
  const hasPeopleFeed = useHasPlanOption(PlanOptionsValues.peoplesFeed);
  const hasReportingDashboard = useHasPlanOption(PlanOptionsValues.reportingDashboard);
  const hasDocuments = useHasPlanOption(PlanOptionsValues.communityDocuments);
  const hasEvents = useHasPlanOption(PlanOptionsValues.events);
  const hasAmenities = useHasPlanOption(PlanOptionsValues.amenities);
  const hasHelpDesk = useHasPlanOption(PlanOptionsValues.helpDesk);
  const hasGroups = useHasPlanOption(PlanOptionsValues.groups);
  const hasComapnies = useHasPlanOption(PlanOptionsValues.company);

  const {
    loop,
    people,
    listings,
    messages,
    dashboard,
    addListing,
    searchCommunity,
    profile,
    // personalSettings,
    // accountSettings,
    // communitySettings,
    // preferencesSettings,
    // paymentsSettings,
    // inviteCommunity,
    // createCommunity,
    documents,
    events,
    amenities,
    groups,
    localBiz,
    helpDesk,
    referrals,
  } = routes;
  // const [settingsActive, setSettingsActive] = useState(false);
  // const handleSettingsClick = (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   setSettingsActive(!settingsActive);
  // };
  // const settingsLinks = {
  //   community: communitySettings,
  //   profile: personalSettings,
  //   payments: paymentsSettings,
  //   preferences: preferencesSettings,
  //   accountSettings,
  //   invite: inviteCommunity,
  //   addListing: addListing,
  //   searchCommunity: searchCommunity,
  //   createCommunity: createCommunity,
  // };
  const [settingsActive, setSettingsActive] = useState(false);

  const handleSettingsClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSettingsActive(!settingsActive);
  };
  return {
    homeDeskTop: {
      content: isJoinedCommunity ? nameCommunity : t('common:menu.myPage'),
      type: (isJoinedCommunity ? 'home-alt' : 'person') as icon,
      to: isJoinedCommunity ? loop : profile,
      path: isJoinedCommunity ? 'loop' : 'page-user',
    },
    homeMobile: {
      content: nameCommunity ? t('common:menu.home') : t('common:menu.myPage'),
      type: (nameCommunity ? 'home-alt' : 'person') as icon,
      to: nameCommunity ? loop : profile,
      path: nameCommunity ? 'loop' : 'page-user',
    },
    // loop: {},
    people: hasPeopleFeed
      ? {
          content: t('common:menu.people'),
          type: 'people' as icon,
          to: people,
          path: 'people',
        }
      : {},
    groups: hasGroups
      ? {
          content: t('common:menu.groups'),
          type: 'groups' as icon,
          to: groups,
          path: 'groups',
        }
      : {},
    listings: hasListingFeed
      ? {
          content: t('common:menu.listings'),
          type: 'Box' as icon,
          to: listings,
          path: 'listings',
          notices: itemsNotices,
        }
      : {},
    listingsMobile: hasListingFeed
      ? {
          content: t('common:menu.listings'),
          type: 'Box' as icon,
          to: listings,
          path: 'listings',
          notices: itemsNotices,
        }
      : {},
    localBiz: hasComapnies
      ? {
          content: t('common:menu.localBiz'),
          type: 'biz-box' as icon,
          path: 'local-biz',
          to: localBiz,
        }
      : {},
    messages: {
      content: t('common:menu.inbox'),
      type: 'chat' as icon,
      to: messages,
      path: 'messages',
      notices: msgsNotices,
    },
    messagesMobile: {
      content: t('common:menu.inbox'),
      type: 'chat' as icon,
      to: messages,
      path: 'messages',
      notices: msgsNotices,
    },
    dashboard: hasReportingDashboard
      ? {
          content: t('common:menu.dashboard'),
          type: 'grid' as icon,
          to: dashboard,
          path: 'dashboard',
        }
      : {},
    events: hasEvents
      ? {
          content: t('common:menu.events'),
          type: 'calendar-2' as icon,
          to: events,
          path: 'events',
        }
      : {},
    amenities: hasAmenities
      ? {
          content: t('common:menu.amenities'),
          type: 'layers' as icon,
          to: amenities,
          path: 'amenities',
        }
      : {},
    more: {
      content: t('common:menu.more'),
      type: 'hamburger' as icon,
      onClick: handleSettingsClick,
      children: SettingsMobileButton({
        handleSettingsClick: handleSettingsClick,
        isActive: settingsActive,
        setActive: setSettingsActive,
      }) as JSX.Element,
    },
    helpDesk: hasHelpDesk
      ? {
          content: t('common:menu.helpDesk'),
          type: 'help' as icon,
          to: helpDesk,
          path: 'help-desk',
        }
      : {},
    referralInvite: {
      content: t('common:menu.inviteFriends'),
      type: 'star-3' as icon,
      to: referrals,
      path: 'referrals',
      viewbox: '0 0 20 20',
      label: t('settings:referrals.earn', {value: `$${referralReward}`}),
    },
    documents:
      hasDocuments && !(documentsCount === 0 && typeUser === TypeCommunity.resident)
        ? {
            content: t('common:menu.documents'),
            type: 'folder' as icon,
            to: documents,
            path: 'documents',
          }
        : {},
    addListing: hasListingFeed
      ? {
          content: t('common:menu.addListing'),
          type: 'plus' as icon,
          to: addListing,
          path: 'add-listings',
        }
      : {},
    searchCommunity: {
      content: t('common:menu.searchCommunity'),
      type: 'search-2' as icon,
      to: searchCommunity,
      path: 'searchCommunity',
    },
    // settingsDesktop: {
    //   content: t('common:menu.settings'),
    //   type: 'settings-2' as icon,
    //   onClick: handleSettingsClick,
    //   children: SettingsPopup({
    //     type: typeUser,
    //     active: settingsActive,
    //     setActive: setSettingsActive,
    //     links: settingsLinks,
    //   }),
    // },
    // settingsMobile: {
    //   content: t('common:menu.more'),
    //   type: 'ellypsis' as icon,
    //   onClick: handleSettingsClick,
    //   children: SettingsPopup({
    //     type: typeUser,
    //     active: settingsActive,
    //     setActive: setSettingsActive,
    //     links: settingsLinks,
    //   }),
    // },
    dashboardMobile: {
      content: t('common:menu.dashboard'),
      path: 'dashboard',
    },
    documentsMobile: {
      content: t('common:menu.documents'),
      path: 'documents',
    },
    addListingMobile: {
      content: t('common:menu.addListing'),
      path: 'add-listings',
    },
    personalSettingsMobile: {
      content: t('common:menu.otherSettings.personal'),
      path: 'settings/account ',
    },
    managePayoutsSettingsMobile: {
      content: t('common:menu.otherSettings.managePayouts'),
      path: '',
    },
    paymentsAndPayoutsSettingsMobile: {
      content: t('common:menu.otherSettings.paymentsAndPayouts'),
      path: 'settings/payments',
    },
    preferencesSettingsMobile: {
      content: t('common:menu.otherSettings.preferences'),
      path: 'settings/preferences',
    },
    communitySettingsMobile: {
      content: t('common:menu.settings'),
      path: 'settings/community',
    },
    AmenitiesMobile: {
      content: t('common:menu.amenities'),
      path: 'amenities',
    },
    EventsMobile: {
      content: t('common:menu.events'),
      path: 'events',
    },
    LocalBizMobile: {
      content: t('common:menu.localBiz'),
      path: 'local-biz',
    },
    GroupsMobile: {
      content: t('common:menu.groups'),
      path: 'groups',
    },
    HelpDeskMobile: {
      content: t('common:menu.helpDesk'),
      path: 'help-desk',
    },
  };
};

export const useNavigation = ({
  routes,
  nameCommunity,
  typeUser,
  msgsNotices,
  isJoinedCommunity,
  isResident,
  documentsCount,
  messagesExists,
  isSupport,
  permissions,
}: TNavigationRoutes) => {
  const {
    people,
    listings,
    messages,
    dashboard,
    addListing,
    searchCommunity,
    // settingsMobile,
    homeDeskTop,
    documents,
    events,
    amenities,
    listingsMobile,
    messagesMobile,
    groups,
    more,
    helpDesk,
    localBiz,
    referralInvite,
    ...mobilePaths
  } = useNavItems({
    routes,
    nameCommunity,
    typeUser,
    msgsNotices,
    isJoinedCommunity,
    documentsCount,
  });
  const msgs = messagesExists ? [messages] : [];
  const msgsMob = messagesExists ? [messagesMobile] : [];
  const showDashboard = permissions?.includes(PermissionsList.dashboardAccess) || typeUser === TypeCommunity.manager;
  const getMenuItemsTop = () => {
    if (isSupport) return [messages];
    if (!isJoinedCommunity && isResident) {
      return [addListing, searchCommunity, ...msgs];
    }

    return showDashboard
      ? [listings, localBiz, amenities, events, people, groups, ...msgs, documents, dashboard]
      : [listings, localBiz, amenities, events, people, groups, ...msgs, documents];
  };

  const getMenuItemsMobile = () => {
    if (isSupport) return [messages];

    if (!isJoinedCommunity && isResident) {
      return [...Object.values(mobilePaths), listingsMobile, ...msgsMob, more];
    }
    return [...Object.values(mobilePaths), people, listingsMobile, ...msgsMob, more];
  };

  const menuItemsBottom = [referralInvite, helpDesk];
  return {
    headMenu: homeDeskTop,
    menuItemsTop: getMenuItemsTop(),
    menuItemsMobile: getMenuItemsMobile(),
    menuItemsBottom,
  };
};

export const useMobCommunitiesDropdown = (typeUser?: TypeUserStateType, count?: number) => {
  const [active, setActive] = useState(false);
  const {communitySettings, createCommunity, searchCommunity} = routes;
  const links: TLinks = {
    community: communitySettings,
    searchCommunity: searchCommunity,
    createCommunity: createCommunity,
    event: '',
    localBiz: '',
    groups: '',
    bookings: '',
    documents: '',
    insights: '',
    helpDesk: '',
    profile: '',
    payments: '',
    preferences: '',
    accountSettings: '',
    invite: '',
    addListing: '',
  };
  const openCD = () => setActive(true);

  const cdShowArrow =
    count === 1 ? typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin : Number(count || 0) > 1;
  return {active, setActive, links, openCD, cdShowArrow};
};
