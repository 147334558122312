import {ParseObject} from './parse';
import {Currency} from './currency';

export enum PaymentPlanName {
  starter = 'starter',
  basic = 'basic',
  pro = 'pro',
  custom = 'custom',
}

export const PaymentPlanValues: Record<PaymentPlanName, number> = {
  custom: 0,
  starter: 10,
  basic: 30,
  pro: 50,
};

// export type TFees = {
//   [key: string]: {[key: string]: number};
// };

export type TFees = {
  hFee: {
    hfListerPrc: number;
    hfListerAbs: number;
    hfRequesterPrc: number;
    hfRequesterAbs: number;
  };
  cFee: {
    cfListerPrc: number;
    cfListerAbs: number;
    cfRequesterPrc: number;
    cfRequesterAbs: number;
  };
  Pp: {
    ppListerPrc: number;
    ppListerAbs: number;
    ppRequesterPrc: number;
    ppRequesterAbs: number;
  };
};

export interface PaymentPlan extends ParseObject {
  name: PaymentPlanName;
  descr: string;
  priceYear: number;
  priceMonth: number;
  options: Array<any>;
  listingCap: number;
  residentCap: number;
  extraListing: number;
  extraResident: number;
  communityFee: boolean;
  serviceFees: TFees;
  salesFees: TFees;
  rentFees: TFees;
  rentalExternal?: boolean;
  salesExternal?: boolean;
  serviceExternal?: boolean;
  Currency: Currency;
}

export type TCreditCard = {
  id: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  expiryDate: string;
  cvc: string;
  zipCode: string;
  saveCard: boolean;
  brand: string;
  country: string;
  last4?: string;
};

export interface TGetCreditCard {
  id: string;
  name: string;
  last4: string;
  expiryYear: number;
  expiryMonth: number;
  brand: string;
  country: string;
  zipCode: string;
}
