import React from 'react';
import {useEditItem, useGetInsightsItem, useGetItem, useItemFormData, useResidentActionsItem} from '../../hooks/item';
import {useHistory, useParams} from 'react-router-dom';
import {ItemFormAction} from '../../types/item';
import {ItemForm} from '../../components/Listings/Form';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';
import {GQLButtonsResidentTypes} from '../../graphql.schema';
import {route} from '../../constants/routes';
import {useTranslation} from 'react-i18next';
import {makeSeoKeywords} from '../../helpers/common';
import {Seo} from '../../components/SEO';
import {useLinks} from '../../hooks/common';
import {useGetCurrencyValue} from '../../hooks/currency';

export const EditItemContainer = () => {
  const [community] = useRecoilState(currentCommunity);
  const {id} = useParams<{id?: string}>();
  const {push} = useHistory();
  const dataInsights = useGetInsightsItem(id);
  const {data: item, loading: loadingGetItem} = useGetItem({id});
  const formData = useItemFormData({images: item?.images});
  const {getLink} = useLinks();
  const {currency} = useGetCurrencyValue();
  const {t} = useTranslation();
  const textSeo = t('common:seo.listings.edit', {text: item?.name});
  const seoKeywords = makeSeoKeywords([t('common:project.name'), textSeo]);

  const {loading: loadingEditItem, ...data} = useEditItem({
    initialState: {...item, images: formData.dataImages},
    onSuccess: () => push(getLink(route.listingViewEdit.get({id: id ?? ''}))),
  });

  const {onSubmit} = useResidentActionsItem({
    communityId: community?.objectId,
  });

  const handlePublish = () => {
    onSubmit({itemId: id, typeBtn: GQLButtonsResidentTypes.publish});
  };

  return (
    <>
      <Seo title={textSeo} keywords={seoKeywords} />
      <ItemForm
        communityId={community?.id}
        action={ItemFormAction.edit}
        data={{...data, loading: loadingGetItem || loadingEditItem}}
        formData={formData}
        dataInsights={dataInsights}
        onPublish={handlePublish}
        currSign={currency?.sign || ''}
      />
    </>
  );
};
