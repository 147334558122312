import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {route} from '../../constants/routes';
import {SetItemsSearchParams} from '../../containers/People/types';
import {getPeopleOptions} from '../../helpers/people';
import {PeopleSearchType} from '../../types/people';
import {LabelValuePair} from '../../types/settings';
import {SearchBlock} from '../common/SearchBlock/SearchBlock';
import {AddListingBtn, PlusIcon} from '../Listings/styles';

import {useLinks} from '../../hooks/common';
import {CommunityStateType} from '../../states/community';

interface Props {
  setSearchParams: SetItemsSearchParams;
  setPeopleType: React.Dispatch<React.SetStateAction<PeopleSearchType>>;
  invite: boolean;
  community?: CommunityStateType;
}

export const Search: React.FC<Props> = ({setSearchParams, setPeopleType, invite, community}) => {
  const {t} = useTranslation();
  const peopleOptions = getPeopleOptions(t, community);
  const {push} = useHistory();
  const {getLink} = useLinks();
  const onSearch = (searchText?: string, next?: Partial<LabelValuePair>) => {
    if (next?.value === t('search:people.myProfile')) push(getLink(route.pageUser.path));
    if (searchText?.search(/\d/) != -1) {
      setSearchParams({
        aptSuite: {
          matchesRegex: searchText?.toLocaleLowerCase?.() ?? '',
          options: 'i',
        },
      });
    } else {
      if (searchText)
        setSearchParams({
          firstName: {matchesRegex: searchText?.toLocaleLowerCase?.() ?? '', options: 'i'},
        });
      else {
        setSearchParams(undefined);
      }
    }
    setPeopleType(next?.value ? (next.value as PeopleSearchType) : PeopleSearchType.Everyone);
  };
  return (
    <SearchBlock
      title={t('search:labels.people')}
      placeholder={t('search:labels.whoLooking')}
      options={peopleOptions}
      onSearch={onSearch}
      buttons={
        invite && (
          <AddListingBtn to={getLink(route.inviteCommunity.get())} rounded width={'154px'}>
            <PlusIcon type={'plus'} stroke={'white'} width={'10px'} height={'10px'} />
            <span>{t('people:manage.invite')}</span>
          </AddListingBtn>
        )
      }
    />
  );
};
