import React from 'react';
import {CardReviewProps, ReviewState, ReviewTypes} from '../types';
import {RequestCardReviewBase} from './Base';
import {CardTags} from '../CardTags';
import {PaymentLabel} from '../PaymentLabel';
import {CardReviewSection} from '../CardReviewSection';
import {CardButton} from '../CardButton';
import {CardInfoText} from '../styles';
import {CreditBlock, DatesTextInfo} from '../Card';

export const ReviewCard: React.FC<CardReviewProps> = ({
  review,
  opponentInfo,
  itemInfo,
  paymentLabel,
  buttonLabel,
  dateLabel,
  rewardType,
  tags,
  descr,
  reviewType,
  pricePeriod,
  rating,
  readyReviewsData,
  isRequester,
  showCredit,
  toggleCredit,
  creditInfo,
  dateInfoLabels,
}) => {
  const isEditing = reviewType === ReviewTypes.Review;
  return (
    <RequestCardReviewBase title={review.title} buttonLabel={buttonLabel}>
      <CardTags tags={tags} />
      {!showCredit && <DatesTextInfo dateLabel={dateLabel} pricePeriod={pricePeriod} dateInfoLabels={dateInfoLabels} />}
      {!showCredit ? (
        <PaymentLabel
          rewardType={rewardType}
          descr={paymentLabel.descr}
          title={paymentLabel.title}
          onClick={() => toggleCredit?.(true)}
        />
      ) : (
        <CreditBlock close={() => toggleCredit?.(false)} creditInfo={creditInfo} />
      )}

      <CardReviewSection
        info={[opponentInfo, itemInfo]}
        reviewState={isEditing ? ReviewState.Writing : ReviewState.Ready}
        review={review}
        rating={rating}
        readyReviewsData={readyReviewsData}
        isRequester={isRequester}
      />
      {isEditing && buttonLabel.primaryReview && buttonLabel.secondaryReview && (
        <CardButton primary={buttonLabel.primaryReview} secondary={buttonLabel.secondaryReview} type={'primary'} />
      )}
      <CardInfoText>{reviewType === ReviewTypes.ReviewReady ? review.completedDescr : descr}</CardInfoText>
      {buttonLabel.primaryReview && buttonLabel.secondaryReview && (
        <CardButton primary={buttonLabel.primaryReview} secondary={buttonLabel.secondaryReview} type={'secondary'} />
      )}
    </RequestCardReviewBase>
  );
};
