import React from 'react';
import {WrapperPageListings, WrapperSearch} from './styles';
import {TypeCommunity} from '../../types/auth';
import {CommunityStateType} from '../../states/community';
import {ListEventsWithPreview} from './ListEventsWithPreview';
import {Search} from './Search';
import {SetEventsSearchParams} from '../../containers/Events/types';
import {MobileSearch} from './MobileSearch';
import {EmptyState} from '../EmptyState/EmptyState';
import {reportActions} from '../../hooks/report';
import {IGetCurrValue} from '../../types/common';
import {TEvent} from '../../types/event';
import {useLinks} from '../../hooks/common';
import {route} from '../../constants/routes';
import {AddButtonPortal} from '../common/Buttons/AddButtonPortal';
import {useRecoilState} from 'recoil';
import {mobTab} from '../../states/navState';
import {PathsList} from '../../helpers/mobNavigation';
import {LikeState} from '../../hooks/item';

interface IProps {
  community: CommunityStateType;
  loading?: boolean;
  allEvents?: Partial<TEvent>[];
  fetchDataAllEvents: () => void;
  hasMoreAllEvents: boolean;
  setSearchParams: SetEventsSearchParams;
  userId?: string;
  reportActions: reportActions;
  getSignValue: IGetCurrValue;
  canAdd?: boolean;
  typeUser?: TypeCommunity | null;
  onDelete?: (id?: string) => Promise<boolean>;
  onLike?: (id: string) => void;
  liked?: LikeState;
}

export const PageResident: React.FC<IProps> = ({
  allEvents,
  hasMoreAllEvents,
  fetchDataAllEvents,
  community,
  loading,
  setSearchParams,
  userId,
  reportActions,
  getSignValue,
  canAdd,
  typeUser,
  onDelete,
  liked,
  onLike,
}) => {
  const [tab] = useRecoilState(mobTab);
  const {getLink} = useLinks();

  return (
    <>
      <WrapperPageListings>
        <WrapperSearch>
          <Search setSearchParams={setSearchParams} cantAdd={!canAdd} />
          <MobileSearch setSearchParams={setSearchParams} />
        </WrapperSearch>
        {!allEvents?.length && !loading ? (
          <EmptyState />
        ) : (
          <ListEventsWithPreview
            communityId={community?.id}
            onDelete={onDelete}
            events={allEvents}
            fetchDataEvents={fetchDataAllEvents}
            hasMoreEvents={hasMoreAllEvents}
            isPreview={false}
            typeUser={typeUser}
            loading={loading}
            userId={userId}
            reportActions={reportActions}
            getSignValue={getSignValue}
            isHideLabel={true}
            liked={liked}
            onLike={onLike}
          />
        )}
        {canAdd && tab === PathsList.events && <AddButtonPortal link={getLink(route.createEvent.path)} />}
      </WrapperPageListings>
    </>
  );
};
