import React from 'react';
import {
  ButtonWrapper,
  MessageCardContentWrapper,
  CardWrapper,
  InputsWrapper,
  MessageSuccessWrapper,
  OutlinedButton,
  ReviewInputWrapper,
  ReviewTextAreaWrapper,
  TextContainer,
  TextContainerEntry,
  UserCardIcon,
} from './styles';
import {MessageRequestCardProps, SendMessageStatus} from './types';
import {IconSvg} from '../Icon/Svg';
import {TextArea} from '../Form/TextArea/TextArea';
import {Button} from '../Button/Button';
import {UserCard} from './UserCard';
import {LinkButton} from '../Button/LinkButton';
import {Link} from '../Typography/Link';

export const MessageRequestCard: React.FC<MessageRequestCardProps> = ({
  user,
  buttonTitle,
  type,
  message,
  messageSent,
  handleWriteMsg,
  handleSendMsg,
  pathLink,
  itemTitle,
  linkTitle,
  profileLink,
  hideReviews,
  canSend,
  userLabel,
  hideVerified,
  userName,
  isPreview,
  isPublicView,
  goSignUp,
}) => {
  const onReqClick = (e: React.SyntheticEvent) => {
    if (isPublicView) {
      e.preventDefault();
      e.stopPropagation();
      goSignUp?.();
    }
  };
  return (
    <CardWrapper padding={0}>
      <MessageCardContentWrapper
        $isPreview={isPreview}
        $isMessaging={type === SendMessageStatus.Send}
        $isDone={type === SendMessageStatus.Done}
        onClick={onReqClick}>
        <Link to={profileLink || '#'}>
          <UserCard
            user={user}
            userName={userName}
            hideReviews={hideReviews}
            label={userLabel}
            hideVerified={hideVerified}
          />
        </Link>
        {canSend && (
          <>
            {type === SendMessageStatus.Write ? (
              <ButtonWrapper pad={10}>
                <OutlinedButton onClick={handleWriteMsg}>
                  {buttonTitle} <IconSvg type={'message'} viewBox={'0 0 14 14'} width="14px" height="14px" />
                </OutlinedButton>
              </ButtonWrapper>
            ) : type === SendMessageStatus.Send ? (
              <>
                <InputsWrapper>
                  <ReviewInputWrapper>{itemTitle}</ReviewInputWrapper>
                  <ReviewTextAreaWrapper>
                    <TextArea placeholder={message.placeholder} value={message.value} onChange={message.onChange} />
                  </ReviewTextAreaWrapper>
                </InputsWrapper>
                <Button onClick={handleSendMsg} variant={'primary'} loading={message.loading}>
                  {buttonTitle}
                </Button>
              </>
            ) : (
              <>
                <MessageSuccessWrapper>
                  <TextContainer>
                    <UserCardIcon width={22}>
                      <IconSvg type={'checkmark'} stroke={'green'} />
                    </UserCardIcon>
                    <TextContainerEntry variant={'quaternary'}>{messageSent}</TextContainerEntry>
                  </TextContainer>
                  {pathLink && (
                    <LinkButton to={pathLink} ghost={true} variant={'primary'}>
                      {linkTitle}
                    </LinkButton>
                  )}
                </MessageSuccessWrapper>
              </>
            )}
            {/* <CardInfoText>{descr}</CardInfoText> */}
          </>
        )}
      </MessageCardContentWrapper>
    </CardWrapper>
  );
};
