import {useMutation, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {route} from '../constants/routes';
import {GQLHelpDeskOrder, GQLHelpDeskWhereInput} from '../graphql.schema';
import {FormValidation} from '../helpers/FormValidation';
import {getMainPositions, HelpDeskCreateTypes, helpDeskToEditState, toPointerEditHelpDesk} from '../helpers/helpDesk';
import {isString} from '../helpers/validations';
import {
  CreateHelpDeskQuery,
  CreateHelpDeskRequestType,
  CreateHelpDeskResponseType,
  getHelpDesk,
  GetHelpDesksParams,
  GetHelpDesksRes,
  GetSupportUserQuery,
  helpDeskListOrderParams,
  UpdateHelpDeskListOrder,
  UpdateHelpDeskParams,
  UpdateHelpDeskQuery,
  UpdateHelpDeskRes,
} from '../queries/helpDesk';
import {HelpDeskItem} from '../queries/types/helpDesk';
import {UsersResponseType} from '../queries/user';
import {CommunityStateType, currentCommunity} from '../states/community';
import {
  helpDeskButtonType,
  HelpDeskFields,
  HelpDeskFormValue,
  HelpDeskFormValues,
  HelpDeskItemT,
} from '../types/helpDesk';
import {FormErrorFields, useFormError} from './error';
import useMutableState from './useMutableState';
import {useViewerId} from './user';
import {helpDeskFAQ} from '../constants/links';
import {HelpDeskItemType} from '../queries/types/helpDesk';

export type useGetHelpDeskDataT = {
  where?: GQLHelpDeskWhereInput;
};

export const useGetHelpDeskData = (options?: useGetHelpDeskDataT) => {
  const data = useQuery<GetHelpDesksRes, GetHelpDesksParams>(getHelpDesk, {
    variables: {
      where: options?.where ? options.where : {},
      order: [GQLHelpDeskOrder.layOutOrder_ASC],
    },
    fetchPolicy: 'cache-and-network',
  });

  const lists = getMainPositions(helpDeskToState(data.data?.helpDesks));

  if (!!lists.main.length) {
    lists.main.push({
      id: String(new Date().getTime()) + 'id',
      isPublished: true,
      objectId: String(new Date().getTime()) + 'objectId',
      buttonTitle: helpDeskButtonType.faqView,
      descr: 'faqView',
      title: 'faqView',
      assignTo: helpDeskFAQ,
      type: HelpDeskItemType.linkView,
      __typename: 'HelpDesk',
    });
  }

  return {...data, data: helpDeskToState(data.data?.helpDesks), lists};
};

const helpDeskToState = (data?: GetHelpDesksRes['helpDesks']): HelpDeskItemT[] | undefined => {
  return data?.edges.map((el) => ({
    ...el.node,
    Assignee: el.node.Assignee,
    buttonTitle: el.node.buttonTitle as helpDeskButtonType,
  }));
};

export type deskButtonActionsRes = {
  [helpDeskButtonType.bugReport]: {onClick?: () => void; to: string};
  [helpDeskButtonType.shareFeedback]: {onClick?: () => void; to: string};
};

export const useGetButtonActions = (): deskButtonActionsRes => {
  return {
    [helpDeskButtonType.bugReport]: {onClick: () => console.log('report bug'), to: '#'},
    [helpDeskButtonType.shareFeedback]: {onClick: undefined, to: '#'},
  };
};

const HelpDeskSchema = FormValidation.schema<Partial<HelpDeskFormValues>>({
  title: FormValidation.string('error:allRequired'),
  descr: FormValidation.string('error:allRequired'),
  type: FormValidation.string('error:allRequired'),
  buttonTitle: FormValidation.handler((v, d) => {
    if (d.type === HelpDeskCreateTypes.faq) return true;
    return !!v;
  }, 'error:allRequired'),
  assignTo: FormValidation.handler((v, d) => {
    if (d.type === HelpDeskCreateTypes.link) return !!v;
    return true;
  }, 'error:allRequired'),
  Assignee: FormValidation.handler((v, d) => {
    if (d.type === HelpDeskCreateTypes.message) return !!v;
    return true;
  }, 'error:allRequired'),
});

export type TResCreateHelpDesk = {
  onSubmit: (onSuccess?: () => void) => Promise<boolean>;
  error: FormErrorFields<HelpDeskFormValues>;
  values: Partial<HelpDeskFormValues>;
  success: string | null;
  loading?: boolean;
  onChange: (next: {name: string; value?: HelpDeskFormValue}) => void;
  // options: TOptionsSelect;
  reset: () => void;
  community: CommunityStateType;
};

type UseCreateHelpDeskType = (options: {
  initialState: Partial<HelpDeskFormValues>;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResCreateHelpDesk;

export const useCreateHelpDesk: UseCreateHelpDeskType = (options) => {
  const viewerId = useViewerId();
  const [community] = useRecoilState(currentCommunity);
  const [values, setValues] = useState<Partial<HelpDeskFormValues>>(options?.initialState);
  const [error, setError] = useFormError<HelpDeskFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [CreateHelpDeskRequest] = useMutation<CreateHelpDeskResponseType, CreateHelpDeskRequestType>(
    CreateHelpDeskQuery,
  );
  const handleChange = (next: {name: string; value?: HelpDeskFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };
  useEffect(() => {
    if (values.type !== HelpDeskCreateTypes.message) handleChange({name: HelpDeskFields.Assignee, value: undefined});
    if (values.type !== HelpDeskCreateTypes.link) handleChange({name: HelpDeskFields.assignTo, value: undefined});
    if (values.type === HelpDeskCreateTypes.faq) handleChange({name: HelpDeskFields.buttonTitle, value: undefined});
  }, [values.type]);

  const reset = () => setValues({});

  const onSubmit = async (onSuccess?: () => void): Promise<boolean> => {
    try {
      setIsLoading(true);
      if (!HelpDeskSchema.validate<Partial<HelpDeskFormValues>>(values)) {
        return false;
      }
      if (!viewerId) {
        throw new Error('error:id');
      }
      const assignee = values.Assignee ? {Assignee: {link: values.Assignee}} : {};
      const commLink = {Community: {link: community?.objectId}};
      const response = await CreateHelpDeskRequest({
        variables: {
          fields: {...values, ...assignee, ...commLink, isPublished: false},
        },
      });

      const data = response?.data?.createHelpDesk?.helpDesk;
      if (!data) throw new Error();

      setSuccess('success:createItem');
      setError(null);
      options?.onSuccess?.();
      setValues(options?.initialState);
      setIsLoading(false);
      onSuccess?.();
    } catch (error) {
      setIsLoading(false);
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    onSubmit,
    error,
    loading: isLoading,
    onChange: handleChange,
    reset,
    success,
    values,
    community,
  };
};

export type hdManageActionsRes = {
  handlePublish: (flag: boolean, id?: string) => Promise<false | HelpDeskItem | undefined>;
  handleDelete: (id?: string) => void;
};

export type hdManageActionsT = (options: {onSuccess?: () => void}) => hdManageActionsRes;

export const useHelpDeskManageActions: hdManageActionsT = (options) => {
  const [update] = useMutation<UpdateHelpDeskRes, UpdateHelpDeskParams>(UpdateHelpDeskQuery);

  const handlePublish = async (flag: boolean, id?: string) => {
    try {
      if (!id) return false;
      const res = await update({
        variables: {
          id: id,
          fields: {
            isPublished: flag,
          },
        },
      });
      options?.onSuccess?.();
      return res.data?.updateHelpDesk.helpDesk;
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (id?: string) => {
    try {
      if (!id) return false;
      await update({
        variables: {
          id: id,
          fields: {
            isDeleted: true,
          },
        },
      });
      options?.onSuccess?.();
      return true;
    } catch (e) {}
  };

  return {handleDelete, handlePublish};
};

export type useChangeOrderT = () => {
  handleChangeOrder: (ids: string[]) => Promise<boolean>;
};

export const useChangeOrder: useChangeOrderT = () => {
  const [update] = useMutation<undefined, helpDeskListOrderParams>(UpdateHelpDeskListOrder);

  const handleChangeOrder = async (ids: string[]) => {
    try {
      if (!ids.length) return false;
      await update({
        variables: {
          input: {orderList: ids},
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  return {handleChangeOrder};
};

export type TResEditHelpDesk = {
  onSubmit: (onSuccess?: () => void) => Promise<boolean>;
  loading?: boolean;
  error: FormErrorFields<HelpDeskFormValues>;
  values: Partial<HelpDeskFormValues>;
  success: string | null;
  onChange: (next: {name: string; value?: HelpDeskFormValue}) => void;
  reset: () => void;
  community: CommunityStateType;
};

type UseEditHelpDeskType = (options: {
  initialState?: HelpDeskItemT;
  onSuccess?: () => void;
  onError?: () => void;
}) => TResEditHelpDesk;

export const useEditHelpDesk: UseEditHelpDeskType = (options) => {
  const [state, setState] = useMutableState({hasSetInitialValues: false});
  const [values, setValues] = useState<Partial<HelpDeskFormValues>>({});
  const [error, setError] = useFormError<HelpDeskFormValues>();
  const [success, setSuccess] = useState<string | null>(null);
  const [community] = useRecoilState(currentCommunity);
  const [UpdateHelpDeskRequest, {loading}] = useMutation<UpdateHelpDeskRes, UpdateHelpDeskParams>(UpdateHelpDeskQuery);

  useEffect(() => {
    if (state.hasSetInitialValues) return;
    if (!isString(options.initialState?.title)) return;
    options?.initialState && setValues(helpDeskToEditState(options?.initialState));
    options?.initialState && setState({hasSetInitialValues: true});
  }, [options?.initialState?.objectId]);

  const reset = () => {
    setState({hasSetInitialValues: false});
    setValues({});
  };
  const handleChange = (next: {name: string; value?: HelpDeskFormValue}) => {
    setError(null);
    setSuccess(null);
    const value = next.value;
    return setValues((prev) => ({...prev, [next.name]: value}));
  };
  useEffect(() => {
    if (values.type !== HelpDeskCreateTypes.message) handleChange({name: HelpDeskFields.Assignee, value: undefined});
    if (values.type !== HelpDeskCreateTypes.link) handleChange({name: HelpDeskFields.assignTo, value: undefined});
    if (values.type === HelpDeskCreateTypes.faq) handleChange({name: HelpDeskFields.buttonTitle, value: undefined});
  }, [values.type]);

  const onSubmit = async (onSuccess?: () => void): Promise<boolean> => {
    try {
      if (!HelpDeskSchema.validate<Partial<HelpDeskFormValues>>(values)) {
        return false;
      }

      if (!values.objectId) {
        return false;
      }

      const assignee = values.Assignee ? {Assignee: {link: values.Assignee}} : {};
      const response = await UpdateHelpDeskRequest({
        variables: {
          id: values.objectId,
          fields: {...toPointerEditHelpDesk(values), ...assignee},
        },
      });
      const data = response?.data?.updateHelpDesk?.helpDesk;
      if (!data) throw new Error();
      onSuccess?.();
      setSuccess('success:editItem');
      setError(null);
      options?.onSuccess?.();
    } catch (error) {
      setSuccess(null);
      setError(error);
      return false;
    }

    return true;
  };

  return {
    values,
    onSubmit,
    success,
    loading,
    onChange: handleChange,
    error,
    reset,
    community,
  };
};

export const useGetSupportData = () => {
  const {data} = useQuery<UsersResponseType>(GetSupportUserQuery);

  const supportUser = data?.users.edges?.[0]?.node;
  const linkOnChat = route.messages.get({contactId: supportUser?.objectId});

  return {
    supportUser,
    linkOnChat,
  };
};
