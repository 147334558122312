import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {JoinToCommunity} from '../../../../components/Account/JoinCommunity';
import {LottieLoader} from '../../../../components/Loader/LottieLoader';
import {useMutation} from '@apollo/client';
import {AccessType} from '../../../../constants/community';
import {route} from '../../../../constants/routes';
import {setCurrentCommunity as setCurrentCommunityInLS} from '../../../../helpers/community';
import {User} from '../../../../queries/types/user';
import {UpdateProfileResponseType, UpdateProfileShort} from '../../../../queries/user';
import {currentCommunity} from '../../../../states/community';
import {CreateProfileSteps} from '../../../../types/auth';
import {useAutoAddToCommunity} from '../../../../hooks/auth';
import {useLinks} from '../../../../hooks/common';
import {useGetCommunity, useGetCommunityRequests, useJoinToCommunity} from '../../../../hooks/community';
import {useGetMembersAvatar} from '../../../../hooks/people';
import {useViewer} from '../../../../hooks/user';

export const JoinOptions = [
  {icon: 'support', text: 'community:join.options.first'},
  {
    icon: 'Box',
    text: 'community:join.options.second',
    viewBox: '0 0 24 24',
    width: '28px',
  },
  {icon: 'sun', text: 'community:join.options.third'},
  {
    icon: 'verified',
    text: 'community:join.options.fourth',
    viewBox: '0 0 18 18',
    width: '34px',
  },
];

export const JoinToCommunityContainer = () => {
  const {push} = useHistory();
  const viewer = useViewer();
  const viewerId = viewer?.id;
  const [currentComm] = useRecoilState(currentCommunity);
  const {id: communityId} = useParams<{id: string}>();
  const {data: community, loading} = useGetCommunity({id: communityId, short: true});
  const {getLink} = useLinks();
  const [reqSend, setReqSend] = useState(false);
  const [members, setMembers] = useState<User[]>([]);
  const {getMembersAvatars, loading: loadingMembers} = useGetMembersAvatar();
  const [updateProfile] = useMutation<UpdateProfileResponseType>(UpdateProfileShort);
  const {addByKey, loading: loadingAddByKey} = useAutoAddToCommunity();

  const params = useJoinToCommunity({
    initialState: {communityId, userId: viewerId},
    onSuccess: () => {
      reqRefetch?.();
      setReqSend(true);
      if (viewer?.status === 'new') {
        updateProfile({variables: {id: viewer?.id, fields: {lastVisitedCommunity: communityId}}});
        push(getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests})));
      }
    },
    isPrivate: community?.accessType === AccessType.private.toLowerCase(),
  });
  const isCurrentCommunity = currentComm?.objectId === communityId;
  const {data: requests, refetch: reqRefetch} = useGetCommunityRequests({
    commId: communityId,
    userId: viewerId,
  });
  const setCurrentCom = useSetRecoilState(currentCommunity);

  const goToCommunity = async () => {
    await setCurrentCom(community);
    community?.objectId && setCurrentCommunityInLS(community.objectId);
    push(getLink(route.loop.get(), community?.alias));
  };

  useEffect(() => {
    const getMembers = async () => {
      if (!community?.objectId) return;
      const result = await getMembersAvatars({
        variables: {
          communityId: community?.objectId,
        },
      });
      setMembers(result?.data?.getResidentsAvatar?.users || []);
    };
    getMembers();
  }, [community?.objectId]);

  const joinRequestStatus = requests?.[0]?.JoinRequests?.[0]?.status || undefined;
  if (loading || loadingMembers) {
    return <LottieLoader $isVisible={loading || loadingMembers} allScreen={true} />;
  }

  return (
    <JoinToCommunity
      addByKey={addByKey}
      loadingAddByKey={loadingAddByKey}
      members={members}
      options={JoinOptions}
      name={community?.name}
      avatar={community?.avatar}
      dataJoin={params}
      joinRequestStatus={joinRequestStatus}
      refetchRequests={reqRefetch}
      communityId={communityId}
      isCurrentCommunity={isCurrentCommunity}
      accessType={community?.accessType}
      userId={viewerId}
      goToCommunity={goToCommunity}
      reqSend={reqSend}
      setReqSend={setReqSend}
    />
  );
};
