import React from 'react';
import {useTranslation} from 'react-i18next';

import {SettingsHeader} from '../components';
import {SettingsAdmin} from './SettingsAdmin';
import {SettingsProfile} from './SettingsProfile';
import {SetUserAdminResType} from '../../../queries/people';
import {CommunityAdmin} from '../../../queries/types/community';
import {
  CommunityAdminValues,
  CommunityProfileValues,
  CommunityType,
  FormEditType,
  FormType,
} from '../../../types/settings';
import {TLoadingStates} from '../../Feed/types';
import {PageWrapper, SettingsWrapper} from '../commonStyles';

import {LocationOptionsType} from '../../../hooks/locations';
import {editAdminT} from '../../../hooks/people';
import {ChangeLocationType} from '../../../hooks/settings';
import {User} from '../../../queries/types/user';

export interface AddAddressProps {
  unit: string;
  zip: string;
  address: string;
  id: string;
}

interface addAddressContainer {
  value: AddAddressProps;
  __typename: string;
}

interface TProps {
  community?: Partial<CommunityType> | null;
  formProfile: FormType<CommunityProfileValues>;
  formAdmin: FormType<CommunityAdminValues>;
  editProfile: FormEditType;
  editAdmin: FormEditType;
  changeLocationProfile: ChangeLocationType;
  locationOptions: LocationOptionsType;
  removeAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  removeAddAddress: (id: string, params: addAddressContainer[], idComm: string | undefined) => Promise<any | undefined>;
  makeOwner: ((adminId: string, communityId: string) => Promise<boolean>) | undefined;
  makeAdmin: (options: {
    id: string;
    communityId: string;
    label?: string;
    onSuccess?: (data?: SetUserAdminResType | null) => void;
  }) => Promise<boolean>;
  getAdmin: (id: string) => Promise<CommunityAdmin | undefined>;
  updateAdmin: editAdminT;
  updateAvatar: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoError: string;
  loadingStates: TLoadingStates;
  loadingAvatar: boolean;
  residents?: User[];
}

export const CommunitySettings: React.FC<TProps> = ({
  community,
  formProfile,
  formAdmin,
  editAdmin,
  editProfile,
  changeLocationProfile,
  locationOptions,
  removeAdmin,
  removeAddAddress,
  makeAdmin,
  getAdmin,
  updateAvatar,
  updateAdmin,
  updatePhotoError,
  loadingStates,
  loadingAvatar,
  makeOwner,
  residents,
}) => {
  const {t} = useTranslation();

  return (
    <PageWrapper>
      <SettingsWrapper>
        <SettingsHeader title={t('settings:community.title')} subtitle={t('settings:community.subtitle')} />
        <SettingsProfile
          community={community}
          edit={editProfile}
          form={formProfile}
          changeLocation={changeLocationProfile}
          locationOptions={locationOptions}
          canEdit={!editAdmin.isEditing}
          updateAvatar={updateAvatar}
          updatePhotoError={updatePhotoError}
          loadingAvatar={loadingAvatar}
          removeAddAddress={removeAddAddress}
        />
        <SettingsAdmin
          community={community}
          edit={editAdmin}
          form={formAdmin}
          canEdit={!editProfile.isEditing}
          makeOwner={makeOwner}
          removeAdmin={removeAdmin}
          makeAdmin={makeAdmin}
          getAdmin={getAdmin}
          updateAdmin={updateAdmin}
          loadingStates={loadingStates}
          residents={residents}
        />
      </SettingsWrapper>
    </PageWrapper>
  );
};
