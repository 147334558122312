import SlickCarouselReact from 'react-slick';
import styled from 'styled-components';
import {Media} from '../../../ui-kit/theme';

export const SlickCarousel = styled(SlickCarouselReact)`
  position: relative;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  z-index: 0;
  overflow: hidden;
  & {
    .slick-list {
      margin: 0 -5px;
      ${Media.down.xs} {
        margin: 0;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      max-height: 300px;
      & > div {
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > div > a {
        max-height: 220px;
        max-width: 300px;
      }
    }
    .slider_wrapper {
      max-height: 300px;
      background-color: #fff;
    }
    .slick-dots {
      width: 23px;
      height: 23px;
      display: flex !important;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      position: absolute;
      button::after {
        content: '';
        width: 15px;
        height: 15px;
        background: ${({theme}) => theme.palette.background.primary};
        border: none;
        outline: none;
        border-radius: 50%;
        margin: 0 auto;
        display: block;
        opacity: 0.6;
      }
      .slick-active {
        button::after {
          opacity: 1;
        }
      }
      li::marker {
        font-size: 0;
      }
      button {
        color: transparent;
        background: transparent;
        outline: none;
        border: none;
      }
    }
    .slick-arrow {
      display: none !important;
    }

    .slick-prev {
      left: 0;
      & svg {
        left: 0;
      }
    }

    .slick-next {
      right: 0;
      & svg {
        transform: rotate(180deg);
        right: 5px;
      }
    }

    .slick-prev::after {
      content: '';
      display: block;
    }

    .slick-next::after {
      content: '';
      display: block;
    }
  }
`;
