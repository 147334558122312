import styled, {css, StyledProps} from 'styled-components';
import {StyledSvgIconProps} from './types';

const getColor = ({theme, color, defaultColor}: {theme: any; color?: string; defaultColor: string}) => {
  switch (color) {
    case 'orange':
      return theme.palette.common.orange;
    case 'darkgray':
      return theme.palette.background.quinary;
    case 'aqua':
      return theme.palette.common.aqua;
    case 'aqua-2':
      return theme.palette.common.aqua2;
    case 'red':
      return theme.palette.common.red;
    case 'yellow':
      return theme.palette.common.yellow;
    case 'green':
      return theme.palette.common.greenLight;
    case 'blue':
      return theme.palette.common.blueSky;
    case 'blueLight':
      return theme.palette.common.blueLight;
    case 'lightGray':
      return theme.palette.background.septenary;
    case 'white':
      return theme.palette.background.primary;
    case 'gray':
      return theme.palette.background.octonary;
    case 'gray-2':
      return theme.palette.grayscale._6;
    case 'grayLight':
      return theme.palette.common.grayLight;
    case 'grayLight-2':
      return theme.palette.grayscale._13;
    case 'darkBlue':
      return theme.palette.grayscale._18;
    case 'lightYellow':
      return theme.palette.common.yellowLight3;
    case 'black':
      return theme.palette.common.black;
    case 'none':
      return 'none';
    default:
      return defaultColor;
  }
};

const stroke = ({theme, stroke}: StyledProps<StyledSvgIconProps>) => {
  return getColor({theme, color: stroke, defaultColor: theme.palette.background.quinary});
};

export const fill = ({theme, fill}: StyledProps<StyledSvgIconProps>) => {
  return getColor({theme, color: fill, defaultColor: 'transparent'});
};

export const StyledSvg = styled.svg`
  & > * {
    stroke: ${stroke};
  }
  & > path {
    ${({strokeWidth}) =>
      strokeWidth &&
      css`
        stroke-width: ${strokeWidth};
      `};
  }
  fill: ${fill};
  .fillFromStroke {
    fill: ${stroke} !important;
  }
`;

export const StyledPath = styled.path`
  fill: ${fill};
  stroke: ${stroke};
`;
