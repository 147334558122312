import React from 'react';
import {CardTag, CardTagsWrapper} from './styles';
import {CardTagsProps} from './types';

export const CardTags: React.FC<CardTagsProps> = ({tags}) => {
  return (
    <CardTagsWrapper>
      {tags.map((t) => (
        <CardTag type={t.type} key={t.label}>
          {t.label}
        </CardTag>
      ))}
    </CardTagsWrapper>
  );
};
