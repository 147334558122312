import {Connection} from './types/parse';
import {gql} from '@apollo/client';
import {PaymentPlan, TGetCreditCard} from './types/payments';
import paymentPlanFragment from './fragments/paymentPlan';
import {PaymentPeriodType} from '../types/payment';

export type PaymentPlansResponse = {
  paymentPlans: Connection<PaymentPlan>;
};

export const GetPaymentPlansRequest = gql`
  query paymentPlans($cursor: String, $first: Int, $where: PaymentPlanWhereInput, $order: [PaymentPlanOrder!]) {
    paymentPlans(after: $cursor, order: $order, first: $first, where: $where) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        cursor
        node {
          ...PaymentPlanFragment
        }
      }
    }
  }

  ${paymentPlanFragment}
`;

export const GetPaymentPlanByIdRequest = gql`
  query paymentPlan($id: ID!) {
    paymentPlan(id: $id) {
      ...PaymentPlanFragment
    }
  }

  ${paymentPlanFragment}
`;

export type TAddCardRequest = {
  fields: {
    nameOnCard: string;
    cardNumber: string;
    expiryDate: string;
    cvc: string;
    zipCode: string;
  };
};

export type TAddCardResponse = {
  paymentAddCard: boolean;
};

export const AddCardRequest = gql`
  mutation addCard($fields: AddCardToUserInp!) {
    paymentAddCard(input: $fields)
  }
`;

export type TGetCardsRequest = {
  id: string;
};

export type TGetCardsResponse = {
  paymentGetUserCards: {
    defaultCardId?: string;
    cards: Array<TGetCreditCard>;
  };
};

export const GetCardsRequest = gql`
  mutation getCards($id: String!) {
    paymentGetUserCards(input: {userId: $id}) {
      defaultCardId
      cards {
        ... on CardInfoPayload {
          id
          name
          last4
          expiryYear: exp_year
          expiryMonth: exp_month
          brand
          country
          zipCode: address_zip
        }
      }
    }
  }
`;

export type TDeleteCardRequest = {
  cardId: string;
};

export type TDeleteCardResponse = {
  paymentDeleteCard: boolean;
};

export const DeleteCardRequest = gql`
  mutation deleteCard($cardId: String!) {
    paymentDeleteCard(input: {cardId: $cardId})
  }
`;

export interface StripeInfo {
  accountConnected?: boolean;
  accountLink?: string;
}

export interface ConnectStripeRequest {
  returnUrl?: string;
}

export interface ConnectStripeResponse {
  connectStripeAccount: StripeInfo;
}

export const ConnectStripe = gql`
  mutation connectStripe($returnUrl: String) {
    connectStripeAccount(input: {returnUrl: $returnUrl}) {
      accountConnected
      accountLink
    }
  }
`;

export interface ChangePlanVariablesType {
  newPayPeriod: PaymentPeriodType;
  newPayPlanId: string;
  subscrId: string;
}

export interface ChangePlanResponseType {
  changeSubscriptionPlan: boolean;
}

export const ChangePlanRequest = gql`
  mutation changePlan($newPayPeriod: PayPeriod!, $newPayPlanId: String!, $subscrId: String!) {
    changeSubscriptionPlan(input: {newPayPeriod: $newPayPeriod, newPayPlanId: $newPayPlanId, subscrId: $subscrId})
  }
`;

export interface PaySubscriptionRequestType {
  subscrPaymentId: string;
}

export interface PaySubscriptionResponseType {
  paySubscription: {
    success: boolean;
    error?: string;
  };
}

export const PaySubscription = gql`
  mutation paySubscription($subscrPaymentId: String!) {
    paySubscription(input: {subscrPaymentId: $subscrPaymentId}) {
      success
      error
    }
  }
`;

export interface SetDefaultCardRequestType {
  cardId: string;
}

export const SetDefaultCard = gql`
  mutation paymentSetDefaultCard($cardId: String!) {
    paymentSetDefaultCard(input: {cardId: $cardId})
  }
`;
