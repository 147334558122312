import styled from 'styled-components';
import {Media, theme} from '../../ui-kit/theme';
import {IconSvg} from '../../ui-kit/Icon/Svg';

export const WrapperPage = styled.div<{$noPadding?: boolean}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 84px);
  padding: ${({$noPadding}) => ($noPadding ? 0 : '42px 0')};

  ${Media.up.m} {
    padding: 20px 0;
    background: none;
  }
  ${Media.down.m} {
    justify-content: center;
    min-height: 100vh;
  }

  img {
    height: 205px;
    width: 280px;
  }
`;

export const WrapperCard = styled.div`
  width: 500px;
  background-color: ${theme.palette.common.white};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 100px 36px 100px;
  box-sizing: border-box;
  margin-top: 30px;

  ${Media.down.m} {
    width: inherit;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: inherit;
    align-items: inherit;
    flex-direction: inherit;
    padding: 0 0;
    margin-top: 0px;
  }
`;

export const WraparoundContainer = styled.div`
  width: 500px;
  height: 630px;
  background-color: ${theme.palette.common.white};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 0 36px 0;
  box-sizing: border-box;
  margin-top: 30px;

  ${Media.down.m} {
    width: inherit;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: inherit;
    align-items: inherit;
    flex-direction: inherit;
    padding: 0 0;
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
`;

export const WrapperDownload = styled.div`
  width: 500px;
  height: 90px;
  background-color: ${theme.palette.common.white};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px;
  box-sizing: border-box;
  margin-top: 25px;
  gap: 12px;

  img {
    width: 120px;
    height: 40px;
    transform: translateY(2%);
  }

  ${Media.down.m} {
    display: none;
  }
`;

export const Indicator = styled.span<{$isSelected: boolean}>`
  display: inline-block;
  height: 8px;
  width: ${(props) => (props?.$isSelected ? '18px' : '8px')};
  margin: 5px;
  transform: translateY(150%);
  transition: width 0.5s;
  border-radius: 5px;
  background-color: ${(props) => (props?.$isSelected ? theme.palette.grayscale._14 : theme.palette.grayscale._21)};
`;

export const ImgWrapper = styled.div`
  width: 100%;
  height: 220px;
  margin-top: 0px;

  ${Media.up.m} {
    margin-top: 20px;
  }
`;

export const HangehLogo = styled(IconSvg)`
  ${Media.down.m} {
    display: none;
  }
`;

export const ImgWrapperWide = styled.div`
  width: 100%;
  ${Media.down.m} {
    height: 50vh;
    margin-bottom: -48px;
    overflow-x: hidden;
  }
  /* height: 220px; */
`;

export const TextWrapperWide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${Media.down.m} {
    height: auto;
    margin-top: 50px;
  }
  /* height: 220px; */
`;

export const LandingImage = styled.img`
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  ${Media.down.m} {
    /* object-fit: contacon !important; */
    transform: scale(1.1);
    margin-top: -40px;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 20px;
  color: ${theme.palette.grayscale._19};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;
  height: 106px;
  text-align: center;
  gap: 2px;
  margin-bottom: 10px;
  ${Media.down.m} {
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 335px;
    gap: 1px;
    margin-bottom: 20px;
  }
`;
export const SpaceLine = styled.div`
  margin-bottom: 14px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;
  gap: 15px;
  margin-top: 20px;
  ${Media.down.m} {
    padding: 0 15px;
    box-sizing: border-box;
  }

  & button:nth-child(1) {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  }
  & button:nth-child(2) {
    background: ${theme.palette.common.white};
    border: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    color: ${theme.palette.text.octonary};
    :hover {
      background: ${theme.palette.grayscale._1};
    }
  }
`;
