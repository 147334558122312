import {Community} from './community';
import {ParseObject} from './parse';
import {User} from './user';
import {Item} from './item';

//add Order

export enum ReviewSubject {
  user = 'user',
  item = 'item',
}

export interface Review extends ParseObject {
  Community: Community;
  Author: User;
  User: User;
  Item: Item;
  subject: ReviewSubject;
  text: string;
  stars: number;
}
