import React from 'react';
import {TGroup} from '../../types/group';
import {
  GroupCardLabelJoinBtn,
  GroupCardLabelJoined,
  GroupCardLabelLeft,
  GroupCardLabelMembers,
  GroupCardLabelName,
  GroupCardLabelWrapper,
} from './styles';

type GroupLabelT = {
  group?: TGroup;
  onJoin?: (a?: any) => void;
  joinBtn?: string;
  membersCount?: string;
  isJoined?: boolean;
  joinedText?: string;
  joinLoading?: boolean;
};

export const GroupLabel: React.FC<GroupLabelT> = ({
  group,
  joinBtn,
  onJoin,
  membersCount,
  isJoined,
  joinedText,
  joinLoading,
}) => {
  return (
    <GroupCardLabelWrapper>
      <GroupCardLabelLeft>
        <GroupCardLabelName>{group?.name}</GroupCardLabelName>
        <GroupCardLabelMembers>{membersCount}</GroupCardLabelMembers>
      </GroupCardLabelLeft>
      {isJoined ? (
        <GroupCardLabelJoined>{joinedText}</GroupCardLabelJoined>
      ) : onJoin ? (
        <GroupCardLabelJoinBtn onClick={onJoin} $loading={joinLoading} disabled={joinLoading}>
          {joinBtn}
        </GroupCardLabelJoinBtn>
      ) : null}
    </GroupCardLabelWrapper>
  );
};
