import {TStringObj} from '../types/helpers';
import {TOptions} from '../types/community';
import {DefaultOption} from '../constants/common';
import {MISSING_TRANSLATION_KEY} from '../constants/common';
import {SITE_URL} from '../constants/env';
import React from 'react';
import {MobileMenuEntry} from '../ui-kit/Menu/MobileMenu';
import {MenuItemType} from '../ui-kit/Menu/types';
import {paths, SearchKey} from '../constants/routes';

export const makeSeoKeywords = (data: string[]) => data.join(', ');

export const getOptions = (data: TStringObj): TOptions[] => {
  const arr = Object.keys(data);
  if (!arr?.length) return [DefaultOption];
  return Object.keys(data).map((key) => ({
    label: data[key],
    value: key,
    type: data[key],
  }));
};

type OptionsJson = {
  create: {
    account: {
      optionsInterestedIn: {
        [key: string]: string;
      };
    };
  };
};

export const getOptionsFromJSON = (data: OptionsJson): TOptions[] => {
  const arr = Object.keys(data.create.account.optionsInterestedIn);
  if (!arr?.length) return [DefaultOption];
  return arr.map((key) => ({
    label: data.create.account.optionsInterestedIn[key],
    value: key,
    type: data.create.account.optionsInterestedIn[key],
  }));
};

export const correctTranslate = ({text, defaultText}: {text?: string; defaultText?: string}) => {
  return text?.includes(MISSING_TRANSLATION_KEY) ? defaultText : text;
};

export const getSiteAlias = () => {
  const parts = SITE_URL?.split('/');
  return parts?.[parts?.length - 1] ?? '';
};

export const getDomainWithoutAlias = () => {
  const parts = SITE_URL?.split('/');
  const domain = parts?.[parts?.length - 1] ?? '';

  const result = domain?.split('.');
  if (result.length === 1) {
    return result;
  }
  if (result.length > 1) {
    return [result[result.length - 2], result[result.length - 1]].join('.');
  }
  return '';
};

export const getCommunityLink = (alias?: string) => {
  return alias ? `${SITE_URL}/${alias}` : '';
};

export const getRouteWithAlias = (route: string) => {
  return '/:alias?' + route;
};
export const invidKey = 'invID';
export const getInviteData = (inviteId: string) => {
  const params = new URLSearchParams(inviteId);
  const email = params.get('email');
  return {
    inviteEmail: email ? Buffer.from(email, 'base64').toString('ascii') : '',
    inviteId: inviteId?.split('/')?.pop()?.split('&email')[0]?.replace(`${invidKey}_`, '') as string,
  };
};

export const getReactLinkFromDeep = (link: string) => {
  const reactLink = link?.replace(SITE_URL as string, '');
  const withoutAlias = reactLink.split('/').slice(2);
  return '/' + withoutAlias.join('/');
};

export const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

export const preventDefault = (e: React.SyntheticEvent) => {
  e.preventDefault();
};

export const getArrByCondition = (condition: boolean, firstObj: any, secondObj?: any) => {
  const fElement = firstObj ? [firstObj] : [];
  const sElement = secondObj ? [secondObj] : [];

  return condition ? fElement : sElement;
};

export const getObjByCondition = (condition: boolean, firstObj: any, secondObj?: any) => {
  const fElement = firstObj ? firstObj : {};
  const sElement = secondObj ? secondObj : {};

  return condition ? fElement : sElement;
};

export enum YesNoOptions {
  yes = 'yes',
  no = 'no',
}

export const YesNotoBool = {
  [YesNoOptions.no]: false,
  [YesNoOptions.yes]: true,
};

export const YesNotoBoolReverse = (b?: boolean) => (b ? YesNoOptions.yes : YesNoOptions.no);

export enum DaysList {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
}

export enum FullDayList {
  'monday' = 'monday',
  'tuesday' = 'tuesday',
  'wednesday' = 'wednesday',
  'thursday' = 'thursday',
  'friday' = 'friday',
  'saturday' = 'saturday',
  'sunday' = 'sunday',
}

export const shortToFullDay = {
  [DaysList.mon]: FullDayList.monday,
  [DaysList.tue]: FullDayList.tuesday,
  [DaysList.wed]: FullDayList.wednesday,
  [DaysList.thu]: FullDayList.thursday,
  [DaysList.fri]: FullDayList.friday,
  [DaysList.sat]: FullDayList.saturday,
  [DaysList.sun]: FullDayList.sunday,
};

export const RNMessages = {
  authorized: 'authorized',
  logout: 'logout',
  areaColor: 'areaColor',
  openPushAllow: 'openPushAllow',
  isModal: 'isModal',
  userData: 'userData',
  facebookLoginClicked: 'facebookLoginClicked',
  googleLoginClicked: 'googleLoginClicked',
};

export const RNreceiveKey = {
  loginFacebook: 'login_facebook',
  loginGoogle: 'login_google',
};

export const SendMessageToRNApp = (message?: string | any) => {
  // eslint-disable-next-line
  // @ts-ignore
  window?.ReactNativeWebView?.postMessage(message || '');
};

export const getHtmlTextLength = (str?: string): number => {
  const newStr = str
    ?.trim()
    ?.replaceAll('\\', '')
    ?.replace(/<\/?[^>]+(>|$)/g, '');
  return newStr?.length || 0;
};

export const declOfNum = (options: {count: number; wordOne: string; wordMany: string}) => {
  const {count, wordOne, wordMany} = options;
  const last = count.toString().slice(-1);
  if (Number(last) >= 2 || Number(last) === 0) return wordMany;
  return wordOne;
};

export const getMobileMenuItems = (items: Array<MenuItemType>): Array<MobileMenuEntry> => {
  return items
    .filter(({render}) => render !== 'line')
    .map(
      ({title, onClick, to, render}) =>
        ({
          title,
          onClick,
          to,
          type: render,
        } as MobileMenuEntry),
    );
};

export const getCorrectExternalLink = (link: string) => (link.startsWith('http') ? link : `https://${link}`);

export const getPhoneLink = (phoneNumber: string) => `tel:${phoneNumber}`;

export const closeTags = (html?: string) => {
  let newHtml = '';
  const opened = html?.split('<p>').length;
  const closed = html?.split('</p>').length;

  if (opened !== closed) {
    newHtml = `${html}</p>`;
    return newHtml.replaceAll('<</p>', '</p>').replaceAll('</</p>', '</p>').replaceAll('</p</p>', '</p>');
  }
  return html;
};

const getDiff = (str1: string, str2: string) => {
  let diff = '';
  let i = 0;
  let indexIden = -1;
  str2?.split('').forEach((val: string, j: number) => {
    if (val != str1?.charAt(i) && str1?.length - i <= str2?.length - j) {
      diff += val;
      indexIden = indexIden === -1 ? i : indexIden;
    } else {
      i++;
    }
  });
  return {diff, indexIden};
};

const handleRandomClick = (
  setUserMenuOpen: (param: React.SetStateAction<boolean>) => void,
  setEventMenuOpen: (param: React.SetStateAction<boolean>) => void,
  setSearchText: (param: React.SetStateAction<string>) => void,
) => {
  setTimeout(() => {
    setUserMenuOpen(false);
    setEventMenuOpen(false);
    setSearchText('');
  }, 500);
  document.removeEventListener('mousedown', () => handleRandomClick(setUserMenuOpen, setEventMenuOpen, setSearchText));
};

const removeSpaceFromTag = (val: string): string => {
  if (val?.includes('@ ')) {
    const newVal = val?.replace('@ ', '@');
    return removeSpaceFromTag(newVal);
  } else return val;
};

export const handleEditorChangeForUserEvent = (
  next: {name: string; value: string; key: string},
  pos: any,
  existingValues: string,
  positionSubMenu: any,
  setPositionSubMenu: (param: any) => void,
  userMenuOpen: boolean,
  setUserMenuOpen: (param: React.SetStateAction<boolean>) => void,
  eventMenuOpen: boolean,
  setEventMenuOpen: (param: React.SetStateAction<boolean>) => void,
  setSearchText: (param: React.SetStateAction<string>) => void,
) => {
  const existingValutStr = existingValues?.replace(/&nbsp;/g, ' ');
  const valueStr = removeSpaceFromTag(next?.value?.replace(/&nbsp;/g, ' '));
  const {diff: diffChar, indexIden: indexIden} = getDiff(existingValutStr, valueStr);
  const {diff: diffCharExisting} = getDiff(valueStr, existingValutStr);
  pos = pos !== null ? pos : document?.querySelector('[data-offset-key^="' + next.key + '"]')?.getBoundingClientRect();
  const editorPosition = document?.querySelector('.rt-wrapper.rdw-editor-wrapper')?.getBoundingClientRect();

  if (diffChar === '@') {
    positionSubMenu.top = pos.top - (editorPosition?.top || 0) + 25;
    positionSubMenu.left = pos.left - (editorPosition?.left || 0) - 25;
    setPositionSubMenu(positionSubMenu);
    setUserMenuOpen(true);
    setEventMenuOpen(false);
    document.addEventListener('mousedown', () => handleRandomClick(setUserMenuOpen, setEventMenuOpen, setSearchText));
  } else if (diffChar === '&gt;') {
    positionSubMenu.top = pos.top - (editorPosition?.top || 0) + 25;
    positionSubMenu.left = pos.left - (editorPosition?.left || 0) - 25;
    setPositionSubMenu(positionSubMenu);
    setUserMenuOpen(false);
    setEventMenuOpen(true);
    document.addEventListener('mousedown', () => handleRandomClick(setUserMenuOpen, setEventMenuOpen, setSearchText));
  } else if (diffChar === ' ' || diffCharExisting === '@' || diffCharExisting === '&gt;') {
    setUserMenuOpen(false);
    setEventMenuOpen(false);
    setSearchText('');
    document.removeEventListener('mousedown', () =>
      handleRandomClick(setUserMenuOpen, setEventMenuOpen, setSearchText),
    );
  } else if ((userMenuOpen || eventMenuOpen) && (diffChar !== '' || diffCharExisting !== '')) {
    const startIndex = valueStr?.substring(0, indexIden + 1)?.lastIndexOf(userMenuOpen ? '@' : ';') + 1;
    setSearchText(valueStr?.substring(startIndex, indexIden + 1));
  }
};

export const handleSetUser = (
  e: React.SyntheticEvent,
  user: string,
  name: string,
  setUserProfile: (param: string) => void,
) => {
  setUserProfile(
    '<a href="' +
      window.location.origin +
      '/' +
      window.location.pathname?.split('/')[1] +
      '/user/' +
      user +
      '">' +
      name +
      ' </a>',
  );
  e.preventDefault();
};

export const handleSetEvent = (
  e: React.SyntheticEvent,
  eventId: string,
  eventNameStr: string,
  setEventName: (param: string) => void,
) => {
  setEventName(
    '<a href="' +
      window.location.origin +
      '/' +
      window.location.pathname?.split('/')[1] +
      '/event-view/' +
      eventId +
      '">' +
      eventNameStr +
      ' </a>',
  );
  e.preventDefault();
};

export const getLastName = (last?: string, letter?: string) => {
  return last || (letter ? `${letter}.` : '');
};

export const leftOnlyLinkTag = (str?: string) => {
  return str?.replace(/<\/?([b-z]+)[^>]*>/gi, function (match, tag) {
    return tag === 'a' || tag === 'br' || tag === 'p' ? match : '';
  });
};

export const insertChar = (str1: string, str2: string, position: number) => {
  return [str1.slice(0, position), str2, str1.slice(position)].join('');
};

export const formatInviteLink = (commPassCode: string) => {
  return `https://hangeh.com/invite?code=${commPassCode}`;
};

export const handleSharing = async (shareDetails: {url: string; title: string; text: string}) => {
  if (navigator?.share) {
    try {
      await navigator.share(shareDetails);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  } else {
    console.log('Web share is currently not supported on this browser. Please provide a callback');
  }
};

function preventMotion(event: any) {
  window.scrollTo(0, 0);
  event.preventDefault();
  event.stopPropagation();
}

export const scrollLock = (flag = true) => {
  if (typeof document === 'undefined' || typeof window === 'undefined') return;
  if (document?.body) {
    document.body.style.position = flag ? 'relative' : 'static';
    document.body.style.overflow = flag ? 'hidden' : 'auto';
  }

  if (flag) {
    window?.addEventListener('scroll', preventMotion, false);
    window?.addEventListener('touchmove', preventMotion, false);
  } else {
    window?.removeEventListener('scroll', preventMotion, false);
    window?.removeEventListener('touchmove', preventMotion, false);
  }
};

export const getOrderFromUrl = (url?: string) => {
  const urlParams = new URLSearchParams(url?.split('?')?.[1] || '');
  return urlParams.get(SearchKey.orderId);
};

export const getEventFromUrl = (url?: string) => {
  if (url?.includes(paths.eventView)) return url?.split('/').pop();
  return null;
};

export const alphabetSort = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const checkFailedFetchError = (e: any) => {
  if (e?.message && e.message?.toLowerCase().includes('failed')) return true;
  return false;
};
