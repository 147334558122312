import React from 'react';
import {DocIcon, DocIconFormatLabel, DocShapeWrapper} from './styles';
import {TDocumentFormat} from '../../types/document';

interface IProps {
  type: TDocumentFormat;
}

export const DocShape: React.FC<IProps> = ({type}) => {
  return (
    <DocShapeWrapper>
      <DocIcon />
      <DocIconFormatLabel>{type}</DocIconFormatLabel>
    </DocShapeWrapper>
  );
};
