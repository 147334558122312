import {useRecoilState, useSetRecoilState} from 'recoil';
import {CommunityStateType, userPermissions} from '../states/community';
import {typeUser as StateTypeUser} from '../states/typeUser';
import {useGetCommunityPeople} from './people';
import {TypeCommunity} from '../types/auth';
import {PermissionsList} from '../types/settings';
import {useContacts, useGetContactsForChat, useGetMessages} from './message';
import {
  deletedState,
  messageHints,
  preloadContactIdsState,
  preloadContactsState,
  preloadFeatured,
  preloadListings,
  preloadMessages,
} from '../states/preload';
import {useEffect, useState} from 'react';
import {useViewer} from './user';
import {getPostOptions} from '../helpers/feed';
import {PostTypeT} from '../types/post';
import {useTranslation} from 'react-i18next';
import {useCommunityFeed} from './feed';
import {GQLItemOrder} from '../graphql.schema';
import {toPointer} from '../helpers/parse';
import {GetPublishedItemsQuery, PublishedItemsResponseType} from '../queries/item';
import {useQuery} from '@apollo/client';
import {toStateItem} from '../helpers/item';
import {isMobile} from 'react-device-detect';
import {useGetUsesFeaturedItems} from './item';

export const usePreloadPeople = (community: CommunityStateType) => {
  const [typeUser] = useRecoilState(StateTypeUser);
  const isManager = typeUser === TypeCommunity.manager;
  const [permissions] = useRecoilState(userPermissions);
  const canManage =
    (permissions?.includes(PermissionsList.managePeople) && typeUser === TypeCommunity.admin) || isManager;
  useGetCommunityPeople({
    id: community?.id,
    withManager: isManager || canManage,
  });
};

export const usePreloadInbox = (community: CommunityStateType) => {
  const viewer = useViewer();
  const setMessages = useSetRecoilState(preloadMessages);
  const setContatIds = useSetRecoilState(preloadContactIdsState);
  const setContacts = useSetRecoilState(preloadContactsState);
  const {data: msgs, unsubscribe, handleSubscribe} = useGetMessages(community?.objectId);
  const {contactsIds, contacts: users} = useContacts(msgs, viewer?.objectId, undefined, community?.objectId);
  const {contacts} = useGetContactsForChat({
    initUsers: users,
    msgs,
    contactsIds,
  });

  useEffect(() => {
    handleSubscribe();
  }, [community?.objectId]);

  useEffect(() => {
    if (msgs?.length) {
      unsubscribe();
    }
  }, [community?.objectId, !!msgs?.length]);

  useEffect(() => {
    if (msgs?.length) {
      setMessages(msgs);
    }
  }, [community?.objectId, msgs?.length]);

  useEffect(() => {
    if (contactsIds?.length) {
      setContatIds(contactsIds);
    }
  }, [community?.objectId, !!contactsIds?.length]);

  useEffect(() => {
    if (contacts?.length) {
      setContacts(contacts);
    }
  }, [community?.objectId, !!contacts?.length, !!msgs?.length]);
};

export const usePreloadListings = (community: CommunityStateType) => {
  const setPreloadListings = useSetRecoilState(preloadListings);
  const setPreloadFeatured = useSetRecoilState(preloadFeatured);
  const [skips, setSkips] = useState<{listingSkip: boolean; featuredSkips: boolean}>({
    featuredSkips: false,
    listingSkip: false,
  });
  const {data: featured} = useGetUsesFeaturedItems(community, undefined, skips?.featuredSkips);

  const params = {
    order: [GQLItemOrder.isSold_ASC, 'createdAt_custom_DESC' as GQLItemOrder],
    first: isMobile ? 12 : 30,
    where: {
      AND: [
        {Lister: {have: {objectId: {exists: true}}}},
        {
          Published: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
          AdmHidden: {
            notIn: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
      ],
      NOR: [
        {
          Featured: {
            contains: [
              toPointer({
                __typename: community?.__typename || '',
                objectId: community?.objectId || '',
              }),
            ],
          },
        },
      ],
    },
  };
  const variables = {
    id: community?.id,
    ...(params?.where ? {where: params.where} : {}),
    ...(params?.order ? {order: params.order} : {}),
    ...(params?.first ? {first: params?.first} : {}),
  };
  const {data} = useQuery<PublishedItemsResponseType>(GetPublishedItemsQuery, {
    variables,
    fetchPolicy: 'cache-first',
    // notifyOnNetworkStatusChange: true,
    ssr: true,
    skip: skips?.listingSkip,
  });
  useEffect(() => {
    if (data) {
      const formatted = data?.items?.edges?.map((edge) => toStateItem(edge.node)) || [];
      setPreloadListings(formatted);
      setSkips((prevState) => {
        return {
          ...prevState,
          listingSkip: true,
        };
      });
    }
  }, [!!data]);

  useEffect(() => {
    if (featured?.length) {
      setPreloadFeatured(featured);
      setSkips((prevState) => {
        return {
          ...prevState,
          featuredSkips: true,
        };
      });
    }
  }, [!!featured?.length]);
};

export const usePreloadFeed = (community: CommunityStateType) => {
  const viewerId = useViewer()?.objectId;
  const [typeUser] = useRecoilState(StateTypeUser);
  const {t} = useTranslation();
  const postToLoad = 8;
  const postOptions = getPostOptions(t);
  const isManagerOrAdm = typeUser === TypeCommunity.manager || typeUser === TypeCommunity.admin;
  useCommunityFeed({
    where: {
      type: {notEqualTo: PostTypeT.recommendation},
    },
    communityId: community?.objectId,
    userId: viewerId,
    first: postToLoad,
    isManager: isManagerOrAdm,
    postType: postOptions[0]?.value,
  });
};

export const useResetPreload = () => {
  const setMessages = useSetRecoilState(preloadMessages);
  const setContatsIds = useSetRecoilState(preloadContactIdsState);
  const setContacts = useSetRecoilState(preloadContactsState);
  const setHints = useSetRecoilState(messageHints);
  const setListings = useSetRecoilState(preloadListings);
  const setFeatured = useSetRecoilState(preloadFeatured);
  const setDeleted = useSetRecoilState(deletedState);

  return () => {
    setMessages([]);
    setContatsIds([]);
    setContacts([]);
    setHints({chat: true, requests: false});
    setListings([]);
    setFeatured([]);
    setDeleted({items: []});
  };
};
