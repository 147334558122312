import styled, {css} from 'styled-components';
import {GoogleLogin} from '@react-oauth/google';

import {LinkButtonWithArrow as UiLinkButtonWithArrow} from '../../ui-kit/Button/LinkButtonWithArrow';
import {Input} from '../../ui-kit/Form/Input';
import {Message} from '../../ui-kit/Form/Message';
import {TextArea} from '../../ui-kit/Form/TextArea/TextArea';
import {Media, theme} from '../../ui-kit/theme';
import {zIndex} from '../../ui-kit/theme/zIndex';
import {Link} from '../../ui-kit/Typography/Link';
import {Text} from '../../ui-kit/Typography/Text';
import {BlankButton} from '../common/Buttons';
import {fontPrimaryReg} from '../common/styles';
import {Button} from '../../ui-kit/Button/Button';

const scrollBarStyles = css`
  scrollbar-color: ${({theme}) => theme.palette.background.tertiary} ${({theme}) => theme.palette.background.denary};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: transparent;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.background.tertiary};
    border-radius: 9em;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.background.octonary};
  }
`;

export const WrapperAuthContainer = styled.div<{$box?: boolean; $isApp?: boolean; $fixed?: boolean}>`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #f8fafa;
  position: relative;
  padding-top: ${({$isApp}) => ($isApp ? '112px' : '108px')};
  padding-bottom: 100px;
  box-sizing: border-box;
  ${Media.down.xxl} {
    padding-top: ${({$isApp}) => ($isApp ? '110px' : '100px')};
  }
  ${Media.down.m} {
    padding-top: ${({$isApp}) => ($isApp ? '110px' : '100px')};
    box-sizing: ${({$box}) => ($box ? 'border-box' : 'unset')};
  }
  ${Media.down.xs} {
    justify-content: flex-start;
  }
  ${({$fixed}) => $fixed && ` position: fixed; top: 0; z-index: 1005;`};
  * {
    ${scrollBarStyles};
  }
`;

export const InnerAuth = styled.div<{maxHeight?: string}>`
  box-sizing: border-box;
  padding: 28px 30px 25px 30px;
  max-width: 623px;
  width: 100%;
  z-index: ${zIndex._1};
  background: #ffffff;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  ${Media.down.xs} {
    padding: 28px 16px 25px 16px;
  }
  ${Media.down.m} {
    width: 92%;
    position: static;
    padding: 20px 20px;
  }
  ${Media.up.m} {
    ${({maxHeight}) => maxHeight && `max-height: ${maxHeight};`}
  }
`;

export const LinkButtonWithArrow = styled(UiLinkButtonWithArrow)`
  margin-bottom: 22px;
  ${Media.down.xs} {
    padding: 19px 60px 19px 19px;
    &::after {
      width: 25px;
      height: 25px;
      right: 23px;
    }
  }
  &:hover {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
  }
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._14};
`;

export const WrapBtnContext = styled.div``;

export const TitleBtnAuth = styled(Text).attrs({
  variant: 'primary',
  color: 'nonary',
  weight: 'medium',
})`
  font-size: 26px;
  display: block;
  margin-bottom: 8px;
  width: 100%;
  text-align: left;
  ${Media.down.xs} {
    font-size: 20px;
  }
`;

export const SubtitleJoinInReady = styled(Text).attrs({
  variant: 'secondary',
  color: 'quaternary',
  size: 16,
})`
  text-align: center;
  width: 100%;
  display: inline-block;
`;

export const FooterTextAuth = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
  weight: 'regular',
})`
  font-size: 16px;
  ${Media.down.m} {
    left: 13px;
  }
`;

export const BtnTextAuth = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
  weight: 'regular',
})`
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  width: 100%;
  display: block;
  ${Media.down.xs} {
    font-size: 14px;
  }
`;

export const LinkAuth = styled(Link).attrs({
  variant: 'primary',
  color: 'octonary',
  decoration: 'underline',
})<{$noBorder?: boolean}>`
  border: 1px solid ${({theme, $noBorder}) => ($noBorder ? 'transparent' : theme.palette.common.aqua)};
  font-weight: 700;
  border-radius: 10px;
  padding: ${({$noBorder}) => ($noBorder ? 'none' : '9px 16px')};
  font-size: 16px;
  margin-left: 8px;
  text-decoration: none;
  color: ${({theme}) => theme.palette.common.aqua};
  ${Media.down.xs} {
    font-size: 16px;
  }
`;

export const LinkForgotPswd = styled(Link).attrs({
  variant: 'primary',
  color: 'octonary',
  weight: 'regular',
  decoration: 'underline',
})`
  font-size: 12px;
`;
export const InnerHeaderAuth = styled.div`
  margin-bottom: 20px;
  ${Media.down.m} {
    margin-bottom: 12px;
  }
`;

export const TitleAuth = styled(Text).attrs({
  variant: 'secondary',
  color: 'sevendecimal',
  weight: 'medium',
})`
  font-size: 38px;
  display: block;
  margin-bottom: 14px;
  &:first-letter {
    text-transform: uppercase;
  }
  ${Media.down.m} {
    font-size: 26px;
  }
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
`;

export const SubtitleAuth = styled(Text).attrs({
  variant: 'secondary',
  color: 'octonary',
  weight: 'medium',
})`
  font-size: 16px;
  display: block;
  margin-bottom: 14px;
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
`;

export const HeaderTextAuth = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
  weight: 'regular',
})`
  font-size: 16px;
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  ${Media.down.m} {
    font-size: 16px;
  }
`;

export const MainWrapper = styled.div`
  max-width: 580px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: min(25px, 18px);
  text-align: left;
  padding: 24px 24px;
  box-sizing: border-box;
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: ${({theme}) => theme.palette.grayscale._0};
  position: relative;
  ${Media.down.m} {
    gap: min(25px, 14px);
    width: auto;
    padding: 20px 20px;
    margin: 0 20px;
  }
`;

export const FormTopTitle = styled.div<{$noOffset?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: unset;
    & > a {
      text-decoration: unset;
    }
  }
  & > span {
    margin-top: 0;
    margin-bottom: 0;
  }
  margin-bottom: ${({$noOffset}) => ($noOffset ? '0px' : '26px')};
  ${Media.down.m} {
    margin-bottom: ${({$noOffset}) => ($noOffset ? '0px' : '16px')};
    & > span {
      font-size: 26px;
    }
  }
`;

export const InnerForm = styled.form`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
  .button-value {
    font-size: 16px;
  }
`;

export const InnerName = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > *:first-child {
    margin-right: 21px;
  }
  & > * {
    flex-grow: 1;
  }

  ${Media.down.m} {
    flex-direction: column;
    gap: 15px;
  }
`;

export const Terms = styled.div`
  ${theme.font.primary.regular};
  font-size: 14px;
  line-height: 150%;
  color: ${theme.palette.text.octonary};
`;

export const TermsResident = styled(Terms)`
  ${Media.down.s} {
    display: none;
  }
`;

export const TermsLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${theme.font.primary.regular};
  color: ${theme.palette.text.octonary};
  text-decoration: underline;
  font-size: 14px;
  margin-left: 5px;
`;

export const ImageSignUp = styled.div`
  display: none;
  ${Media.down.xs} {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    & > img {
      position: absolute;
      transform: rotate(-20.06deg);
      width: 130%;
      height: auto;
      left: -10%;
    }
  }
`;

export const ImageLogIn = styled(ImageSignUp)`
  display: none;
  ${Media.down.xs} {
    display: block;
    width: 100%;
    padding-bottom: 85%;
    position: relative;
    & > img {
      position: absolute;
      transform: rotate(-2.06deg);
      width: 84%;
      height: auto;
      left: 8%;
      top: 14px;
    }
  }
`;

export const ImgContSignUpResident = styled(ImageSignUp)`
  z-index: -1;
  ${Media.down.xs} {
    & > img {
      transform: rotate(10deg);
      top: 30px;
      left: -12%;
    }
  }
`;

export const InnerButtonsResident = styled.div`
  z-index: ${zIndex._1};
  & > * {
    margin-bottom: 12px;
  }
`;

export const InnerlabelEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 28px;
  margin-bottom: 34px;
`;

export const LabelEmail = styled(Text).attrs({
  variant: 'primary',
  color: 'octonary',
  weight: 'semi-bold',
})`
  font-size: 14px;
  position: relative;
  padding: 0 40px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  will-change: transform;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${({theme}) => theme.palette.grayscale._11};
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 120%;
    right: 100%;
    background-color: ${({theme}) => theme.palette.grayscale._11};
  }
`;

export const ErrorMessage = styled(Message).attrs({variant: 'error'})`
  margin-bottom: 15px;
`;

export const AddressWrapper = styled.form`
  width: 600px;
  height: max-content;
  box-sizing: border-box;
  padding: 25px 30px;
  background-color: ${({theme}) => theme.palette.background.primary};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;

  ${Media.down.s} {
    margin: 0 auto;
    width: 90%;
    padding: 6px 18px;
  }
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 20px 0;

  ${Media.down.s} {
    margin: 14px 0;
  }
  .button-value {
    font-size: 16px;
  }
`;

export const ContentWrapperFlex = styled(ContentWrapper)<{$isRow?: boolean}>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({$isRow}) => $isRow && ` flex-direction: row;`};
`;

export const SubtitleWrapper = styled(ContentWrapper)`
  margin-bottom: 20px;
  font-size: 16px;
  color: ${({theme}) => theme.palette.text.octonary};
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
  white-space: break-spaces;
  line-height: 150%;

  ${Media.down.s} {
    margin-bottom: 20px;
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  padding-top: 18px;
  padding-left: 25px;
  border: 1px solid ${({theme}) => theme.palette.grayscale._14};
  border-radius: 12px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const LeftPartCard = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RightPartCard = styled.div``;

export const StateUserLive = styled(Text).attrs({
  variant: 'secondary',
  color: 'sevendecimal',
  weight: 'medium',
})`
  font-size: 26px;
  display: block;
  &:first-letter {
    text-transform: uppercase;
  }
  ${Media.down.m} {
    font-size: 20px;
  }
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
`;

export const StateUserAdress = styled(Text).attrs({
  variant: 'secondary',
  color: 'octonary',
  weight: 'regular',
})`
  font-size: 16px;
  display: block;
  &:first-letter {
    text-transform: uppercase;
  }
  ${Media.down.m} {
    font-size: 14px;
    display: inline;
  }
  font-family: ${({theme}) => theme.font.primary.regular.fontFamily};
`;

export const SpinnerActionSvg = styled.div<{$animation?: boolean}>`
  display: flex;
  align-items: center;
  width: 24px;

  & > svg {
    animation: ${({$animation}) => ($animation ? '2s linear 0s normal none infinite rotate' : 'none')};
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const SpinnerAction = styled(SpinnerActionSvg)`
  color: ${({theme}) => theme.palette.blueDark2};
  transition: width 0.4s ease;

  & > svg {
    animation: 2s linear 0s normal none infinite rotate;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

export const ContainerDifferentTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 25px;
`;

// export const ContainerDifferentText = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   max-height: 20px;
//   background-color: red;
//   overflow: hidden;
//   justify-content: center;
// `;

export const TextList = styled.div``;

export const ProgressBar = styled.div`
  border-radius: 0 0 13px 13px;
  background: ${({theme}) => theme.palette.grayscale._3};
  overflow: hidden;
  display: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
`;

export const ProgressLine = styled.span<{value?: number}>`
  height: 100%;
  display: block;
  width: ${({value}) => value + '%'};
  background: ${({theme}) => theme.palette.common.orange};
  line-height: 30px;
  text-align: end;
  padding-right: 5px;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  width: 48%;

  ${Media.down.s} {
    width: 100%;

    &:first-of-type {
      margin-bottom: 13px;
    }
  }
`;

export const AddressTitle = styled(Text).attrs({
  size: 38,
  variant: 'secondary',
  color: 'octonary',
})`
  ${fontPrimaryReg};
  ${Media.down.s} {
    font-size: 26px;
  }
`;

export const JoinInTitle = styled(Text).attrs({
  size: 38,
  variant: 'secondary',
  color: 'octonary',
  weight: 'bold',
})`
  ${fontPrimaryReg};
  ${Media.down.s} {
    font-size: 26px;
  }
  font-weight: 600;
`;

export const JoinInTitleReady = styled(Text).attrs({
  size: 38,
  variant: 'secondary',
  color: 'octonary',
  weight: 'bold',
})`
  ${fontPrimaryReg};
  ${Media.down.s} {
    font-size: 26px;
  }
  font-weight: 600;
  width: 100%;
  display: inline-block;
  text-align: center;
`;

export const JoinInAvatarWrapper = styled.div`
  width: 91px;
  height: 91px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const BackIconWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

export const HeaderLogoWrapper = styled.div<{$centeredLogo?: boolean}>`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: ${({$centeredLogo}) => ($centeredLogo ? 'flex-end' : 'space-between')};
  align-items: center;
  margin: 15px 0;

  & > * {
    cursor: pointer;
  }
`;

export const HangehLogoWrapper = styled.div`
  margin-left: -10px;
`;

export const SwitchWrapper = styled.div``;

export const ForgotPassBtn = styled.div`
  cursor: pointer;
  margin-top: 12px;
  & > span {
    ${fontPrimaryReg};
  }
`;

export const DisabledInput = styled(Input)`
  background-color: ${({theme}) => theme.palette.background.septenary};
`;

export const TopLogoWrapper = styled(Link)<{$isApp?: boolean; $isLogged?: boolean}>`
  width: auto;
  height: max-content;
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px;
  top: ${({$isApp}) => ($isApp ? '34px' : '20px')};
  & > * {
    cursor: pointer;
  }
  ${Media.down.m} {
    left: 10px;
  }
  ${Media.down.m} {
    display: ${({$isLogged}) => ($isLogged ? 'none' : 'flex')};
  }
`;

export const BottomLogoWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 35px;
  & > * {
    cursor: pointer;
  }
  ${Media.down.l} {
    display: none;
  }
`;

export const HaveAccountWrapper = styled.div<{$isApp?: boolean}>`
  position: absolute;
  top: ${({$isApp}) => ($isApp ? '60px' : '30px')};
  right: 20px;
  width: max-content;
  ${Media.down.m} {
    & > span {
      display: none;
    }
  }
`;

export const WelcomeUserWrapper = styled.div<{$isApp?: boolean}>`
  display: flex;
  position: absolute;
  align-items: center;
  top: ${({$isApp}) => ($isApp ? '68px' : '30px')};
  left: 50%;
  width: 100%;
  transform: translateX(-110px);
  ${Media.down.m} {
    top: 50px;
    left: 20px;
    transform: translateX(0);
  }
`;

export const UpdateAvatarError = styled.div`
  margin: 0 15px 15px 15px;
  text-align: center;
`;

export const WelcomeAvatarWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: transparent;
  padding-top: 2px;
  padding-left: 1.5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Media.down.m} {
    left: 6px;
  }
`;

export const WelcomeUserText = styled.div`
  color: ${({theme}) => theme.palette.grayscale._18};
  font-size: 16px;
  line-height: 150%;
  ${fontPrimaryReg};
  ${Media.down.m} {
    font-size: 16px;
  }
`;
const aqua = theme.palette.common.aqua;
const calcSteps = (steps?: number) => {
  if (!steps) return `transparent transparent transparent transparent`;
  if (steps === 1) return `${aqua} transparent transparent transparent`;
  if (steps === 2) return `${aqua} ${aqua} transparent transparent`;
  if (steps === 3) return `${aqua} ${aqua} ${aqua} transparent`;
  if (steps === 4) return `${aqua} ${aqua} ${aqua} ${aqua}`;
};
export const StepProgress = styled.div<{stepsCount?: number}>`
  width: 43px;
  height: 43px;
  position: absolute;
  left: -3.5px;
  top: -3.5px;
  border-radius: 100%;
  ${Media.down.m} {
    width: 38px;
    height: 38px;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 43px;
    height: 43px;
    ${Media.down.m} {
      width: 38px;
      height: 38px;
    }
    ${Media.down.s} {
      width: 38px;
      height: 38px;
    }
    border-radius: 100%;
    border: 2px solid #fff;
  }
  & > div {
    border-color: ${({stepsCount}) => calcSteps(stepsCount)};
    transform: rotate(45deg);
  }
  //& > div:nth-child(odd) {
  //  border-color: transparent transparent transparent transparent;
  //  transform: rotate(90deg);
  //}
`;

export const WrapperJoinIn = styled.div`
  width: 600px;
  height: max-content;
  box-sizing: border-box;
  padding: 25px 30px;
  background-color: ${({theme}) => theme.palette.background.primary};
  box-shadow: -10px 10px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;

  ${Media.down.s} {
    width: 90%;
    margin: 0 auto;
  }
`;

export const MoreButtonWrapper = styled.div<{$isApp?: boolean}>`
  position: absolute;
  right: 50px;
  top: ${({$isApp}) => ($isApp ? '93px' : '55px')};
  cursor: pointer;
  svg {
    transform: rotate(-90deg);
  }

  ${Media.down.xs} {
    right: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 25px;
`;

export const PopupWrapper = styled.div`
  inset: 0;
  top: unset;
  bottom: 0;
  position: absolute;
`;
export const BodyWrapper = styled.div<{$fullWidth?: boolean}>`
  width: ${({$fullWidth}) => ($fullWidth ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NavigationButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  margin-top: 35px;
  padding: 0 20px 20px 0;
  box-sizing: border-box;
  .next {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .back {
    position: absolute;
    left: 20px;
    cursor: pointer;
  }
`;

export const DarkBackground = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 501;
`;

export const PanelWrapper = styled.div`
  max-height: max-content;
  min-height: 145px;
  max-width: 100%;

  width: 100vw;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.palette.background.primary};
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    box-sizing: border-box;
  }
`;

export const ContentJoinInWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
`;

export const NoCodeBtn = styled(Link)`
  margin-top: 24px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;
export const MemberWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 8px;
  //border-top: ${({theme}) => `1px solid ${theme.palette.background.secondary}`};

  .avatar {
    height: 24px;
    width: 24px;
    margin-left: -5px;
  }
`;

export const MembersText = styled(Text).attrs({variant: 'primary', size: 14})`
  margin-bottom: 22px;
  width: 100%;
  width: 200px;
  text-align: center;
  display: inline-block;
`;
export const SignupBgImage = styled.div<{$isStart: boolean}>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: ${({$isStart}) => ($isStart ? 'flex' : 'none')};
  justify-content: start;
  ${Media.down.m} {
    align-items: flex-end;
    transform: translateY(-20%);
  }
  & > img {
    margin-left: 12%;
    margin-top: 11%;
    max-width: 800px;
    max-height: 800px;
    transform: rotate(20deg);
    ${Media.down.xxl} {
      margin-left: 8%;
      margin-top: 14%;
      max-width: 700px;
      max-height: 700px;
    }
    ${Media.down.m} {
      margin-top: 0;
      margin-left: 0;
      max-width: 500px;
      max-height: 400px;
    }
  }
`;

export const JoinButton = styled.button`
  font-size: 14px;
  background: ${({theme}) => theme.palette.grayscale._3};
  border: 1.2px solid ${({theme}) => theme.palette.grayscale._3};
  border-radius: 25px;
  padding: 10px 25px;
  text-transform: capitalize;
  cursor: pointer;
  ${Media.down.m} {
    font-size: 12px;
    align-self: flex-start;
    margin-top: 15px;
  }
  :hover {
    background: ${({theme}) => theme.palette.grayscale._11};
  }
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._12};
`;

export const CommunityLabelWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

export const CommunityAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  ${Media.down.m} {
    object-fit: cover;
  }
`;

export const CommunityLabelText = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.grayscale._18};
  ${fontPrimaryReg};
`;

export const CommItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({theme}) => theme.palette.grayscale._14};
  border-radius: 12px;
  padding: 18px 13px;
  margin-bottom: 25px;
  justify-content: space-between;
  ${Media.down.m} {
    padding: 2px 13px 16px 2px;
  }
`;

export const CommItemLeftPart = styled.div`
  display: flex;
  align-items: center;
  ${Media.down.m} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CommItemRightPart = styled.div`
  margin-left: 20px;
  ${Media.down.m} {
    margin-left: 10px;
  }
`;

export const CommItemAvatar = styled.img`
  width: ${({width}) => (width ? width : '126px')};
  height: ${({height}) => (height ? height : '126px')};
  object-fit: cover;
  border-radius: 100%;
  ${Media.down.m} {
    width: ${({width}) => (width ? width : '90px')};
    height: ${({height}) => (height ? height : '90px')};
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
`;
export const CommItemName = styled.div`
  ${fontPrimaryReg};
  font-size: 26px;
  color: ${({theme}) => theme.palette.grayscale._18};
  margin-bottom: 2px;
  ${Media.down.m} {
    font-size: 18px;
    margin-bottom: 2px;
  }
`;
export const CommItemDescr = styled.div`
  line-height: 150%;
  ${fontPrimaryReg};
  font-size: 16px;
  color: ${({theme}) => theme.palette.grayscale._6};
  ${Media.down.m} {
    font-size: 14px;
  }
`;

export const TextButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  ${fontPrimaryReg};
  color: ${({theme}) => theme.palette.grayscale._6};
  margin: 0 auto;
  margin-top: 15px;
  display: block;
`;

export const StyledTextArea = styled(TextArea)`
  height: 200px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 15px;
`;

export const OkIcon = styled.div``;

export const InnerContinueBtn = styled(BlankButton)`
  display: flex;
  align-items: center;

  position: absolute;

  ${Media.down.m} {
    top: 30px;
    right: 34px;
    height: 35px;
  }

  ${Media.up.m} {
    top: 42px;
    right: 46px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxText = styled.div`
  margin-left: 11px;
`;

export const SocialTitle = styled(Text).attrs({
  size: 38,
  variant: 'secondary',
  color: 'octonary',
  weight: 'bold',
})`
  display: block;
  text-align: center;
  margin-bottom: 16px;
  ${fontPrimaryReg};
  ${Media.down.s} {
    font-size: 26px;
  }
  font-weight: 600;
`;

export const SocialSubtitle = styled(Text).attrs({
  size: 16,
  variant: 'primary',
  color: 'octonary',
})`
  display: block;
  text-align: center;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.text.hexadecimal};
  margin: 25px 0;
`;

export const DividerElement = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.grayscale.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DividerText = styled(Text).attrs({
  size: 14,
  variant: 'primary',
  color: 'octonary',
})``;

export const SocialButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LoaderWrapper = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConnectionButtonText = styled(Text).attrs({
  size: 12,
  color: 'primary',
})`
  display: block;
  margin-left: auto;
`;

export const ConnectionButton = styled.div<{isConnect?: boolean}>`
  padding: 4px 14px;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${({theme, isConnect}) => (isConnect ? theme.palette.grayscale._3 : theme.palette.common.aqua)};
`;

export const GoogleButtonWrapper = styled.div<{isConnect?: boolean}>`
  position: relative;
  width: 100%;
  & > div {
    width: 100%;
    position: absolute;
    top: 7px;
    left: 20%;
    z-index: ${({isConnect}) => (isConnect ? -1 : 1)};
    transform: scaleX(1.4) scaleY(1.2);
    opacity: 0;
  }
`;

export const GoogleLoginStyled = styled(GoogleLogin).attrs({
  width: '1000px',
})``;

export const SocialTermsWrapper = styled.div`
  margin-top: 14px;
  & > div {
    text-align: center;
  }
`;

export const NoteWrapper = styled.div`
  margin-bottom: 17px;
`;

export const CreatingCommButtons = styled.div`
  margin-top: 50px;
  ${Media.down.m} {
    margin-top: 32px;
  }
`;

export const CCbutton = styled(Button)`
  color: ${({theme}) => theme.palette.common.aqua};
  height: 46px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
