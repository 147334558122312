import styled from 'styled-components';
import {ViewAllBtn} from '../styles';
import {fontPrimarySemiBold} from '../../common/styles';

export const SliderWrapper = styled.div`
  position: relative;
`;

export const InnerPhotoCard = styled.div<{isActive?: boolean}>`
  opacity: ${({isActive}) => (isActive ? 1 : 0.5)};
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
`;

export const InnerImagesBtns = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-start;
  width: 100%;

  img {
    margin-bottom: 15px;
    width: 70px;
    height: 50px;
    margin-right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
    object-fit: cover;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  width: 0px;
  height: 0px;
  position: absolute;
`;

export const FooterSlider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 15px;
  box-sizing: border-box;
`;

export const ViewBtn = styled(ViewAllBtn)`
  flex-shrink: 0;
`;

export const InnerPhoto = styled.div`
  position: relative;
`;

export const SliderButton = styled.button<{isActive?: boolean}>`
  width: 23px;
  height: 23px;
  display: none;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    background: ${({theme}) => theme.palette.background.primary};
    opacity: ${({isActive}) => (isActive ? 1 : 0.6)};
    border: none;
    outline: none;
    border-radius: 50%;
    margin: 0 auto;
  }
`;

export const InnerMobileBtns = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

export const MobileBtn = styled.button<{$align: 'left' | 'right'; top?: string}>`
  position: fixed;
  top: ${({top}) => (top ? top : '45px')};
  ${({$align}) => $align}: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background: ${({theme}) => theme.palette.background.primary};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 5;
  & > svg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
`;

export const MobileBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 201;
  padding: 15px 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  background-color: ${({theme}) => theme.palette.background.primary};
`;

export const CopiedMsg = styled.div<{$isVisible?: boolean}>`
  position: fixed;
  top: ${({$isVisible}) => ($isVisible ? '30px' : '-30px')};
  visibility: ${({$isVisible}) => ($isVisible ? 'visible' : 'hidden')};
  width: 100px;
  height: 30px;
  z-index: 501;
  left: calc(50vw - (100px / 2));
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({theme}) => theme.palette.common.greenDark} 2px solid;
  background-color: ${({theme}) => theme.palette.background.primary};
  color: ${({theme}) => theme.palette.common.greenDark};
  transition: visibility ease 0.5s, top ease 0.5s;
  ${fontPrimarySemiBold}
`;
