import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../ui-kit/Typography/Text';
import {Br} from '../../ui-kit/Typography/Br';
import {Input} from '../../ui-kit/Form/Input';
import {Button} from '../../ui-kit/Button/Button';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {Message} from '../../ui-kit/Form/Message';
import {stopPropagation} from '../../helpers/common';
import {TDocument} from '../../types/document';
import {FormMode, IActionsOnDocumentsData, TFormDataType, TModalData} from './types';
import {DocInput} from './DocInput';
import {CoverInput} from './CoverInput';
import {CloseBtnWrapper, DarkBackground, LoadersWrapper, ModalWrapper} from './styles';

interface IProps extends TModalData {
  actionsData: IActionsOnDocumentsData;
  close?: () => void;
}

export const ModalDocumentForm: React.FC<IProps> = ({mode, close, actionsData, selectedDoc}) => {
  const {t} = useTranslation();

  const {setValue, setFile, addDocument, updateDocument, values, loading, error} = actionsData;
  const isDisableSubmit = isDisableSbmtBtn(values, selectedDoc);

  const handleSetValue = (params: {key: keyof TFormDataType; value: any}) => {
    if (!loading) setValue(params);
  };
  const handleClose = () => {
    if (!loading) close?.();
  };
  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSetValue({key: 'name', value: event.target.value});
  };
  const getHandleSetFile = (key: keyof TFormDataType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.files?.[0];

    setFile({key: key, value});
  };
  const handleDeleteCover = () => {
    setFile({key: 'cover', value: null});
  };

  const handleSubmit = async () => {
    if (mode === FormMode.Add) await addDocument(close);
    if (mode === FormMode.Edit && selectedDoc) await updateDocument(selectedDoc, close);
  };

  return (
    <DarkBackground onClick={handleClose}>
      <ModalWrapper onClick={stopPropagation}>
        <Text size={20} color={'octonary'} weight={'medium'}>
          {t('documents:modalForm.title', {context: mode})}
        </Text>
        <Br indent={16} />
        <LoadersWrapper>
          <DocInput
            handleChange={getHandleSetFile('docFile')}
            docFile={values.docFile}
            docFormat={selectedDoc?.format}
            mode={mode}
          />
          <CoverInput
            handleChange={getHandleSetFile('cover')}
            handleDelete={selectedDoc?.Cover?.objectId ? handleDeleteCover : undefined}
            coverFile={values.cover}
            coverUrl={selectedDoc?.Cover?.file.url}
            mode={mode}
          />
        </LoadersWrapper>
        <Br indent={14} />
        <Text size={18} color={'octonary'}>
          {t('documents:modalForm.titles.name')}
        </Text>
        <Br indent={9} />
        <Input
          label={t('documents:modalForm.labels.name')}
          onChange={handleOnChangeName}
          value={values.name ?? selectedDoc?.name}
        />
        <Br indent={20} />
        <Button loading={loading} onClick={handleSubmit} disabled={isDisableSubmit}>
          {t('documents:modalForm.button', {context: mode})}
        </Button>
        <Br indent={5} />
        {error ? <Message variant={'error'}>{t(error)}</Message> : null}
        <CloseBtnWrapper onClick={handleClose}>
          <IconSvg type={'close'} height={'24px'} width={'24px'} viewBox={'0 0 20 20'} />
        </CloseBtnWrapper>
      </ModalWrapper>
    </DarkBackground>
  );
};

function isDisableSbmtBtn(values: TFormDataType, initial?: TDocument) {
  if (!Object.values(values).length) return true;
  if (!initial) return false;

  if (values.cover || values.docFile) return false;
  if (values.name === initial.name) return true;

  return false;
}
