import React, {RefObject, useState} from 'react';
import {CustomInput, InnerSelect, LabelSelect, StyledSelect, WrapperItem} from './styles';
import {CSSProperties} from 'styled-components';
import {SelectItemRenderer, SelectRenderer} from 'react-dropdown-select';

export interface PropsSelect {
  label?: string;
  dropdownHandle?: boolean;
  className?: string;
  style?: CSSProperties;
  values: any;
  options: any;
  multi?: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
  name: string;
  width?: string;
  height?: string;
  $isError?: boolean;
  searchable?: boolean;
  shouldCreate?: boolean;
  onKeyDown?: (value: string) => void;
}
// eslint-disable-next-line
export const Select = <T extends any>({
  className,
  name,
  values,
  onChange,
  options,
  label,
  width,
  height,
  $isError,
  searchable,
  shouldCreate,
  onKeyDown,
  ...props
}: PropsSelect) => {
  const [isActive, setIsActive] = useState(false);
  const [shouldSuggest, setShouldSuggest] = useState(false);
  const [localState, setLocalstate] = useState('');
  const handleFocus = () => {
    setIsActive(true);
  };
  const handleBlur = () => {
    setIsActive(false);
    setShouldSuggest(false);
  };
  const ItemRenderer = <T,>({item, props, methods}: SelectItemRenderer<T>) => {
    const addItem = () => {
      methods.addItem(item);
    };

    const onSelect = () => {
      if (!shouldCreate) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onKeyDown?.(item?.label || '');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLocalstate(item?.label || '');
    };
    return (
      <WrapperItem
        isChecked={methods.isSelected(item)}
        onMouseDown={onSelect}
        onTouchStart={onSelect}
        key={(item as any)[props.valueField as string]}
        onClick={addItem}>
        {(item as any)[props.labelField as string]}
      </WrapperItem>
    );
  };

  const InputRenderer = <T,>({
    props,
    methods,
    inputRef,
  }: SelectRenderer<T> & {
    inputRef: RefObject<HTMLInputElement>;
  }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      methods.setSearch(e);
      setLocalstate(e.target.value || '');
      onKeyDown?.(e.target.value || '');
      if (e.target.value?.length >= 2) {
        setShouldSuggest(true);
      } else setShouldSuggest(false);
    };

    return (
      <CustomInput name={props?.name} value={localState} ref={inputRef} onChange={handleChange} autoComplete="off" />
    );
  };

  const handleChange = (value: any) => {
    onChange(value);
  };

  return (
    <InnerSelect
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
      $isError={$isError}
      height={height}
      width={width}
      $create={shouldCreate}>
      {label && (
        <LabelSelect $isActive={isActive || values.length} $isError={$isError}>
          {label}
        </LabelSelect>
      )}
      <StyledSelect
        name={name}
        placeholder={''}
        className={'select-filter ' + className}
        values={values}
        onChange={handleChange}
        options={shouldCreate ? (shouldSuggest ? options : []) : options}
        itemRenderer={ItemRenderer}
        searchable={searchable}
        create={shouldCreate}
        dropdownHandle={!shouldCreate}
        inputRenderer={shouldCreate ? InputRenderer : undefined}
        {...props}
      />
    </InnerSelect>
  );
};
