import React from 'react';
import {InnerAuth, TitleAuth, SubtitleWrapper, CCbutton, CreatingCommButtons} from './styles';
import {useTranslation} from 'react-i18next';

type CommunityCreatingPropsT = {
  onCreateCommunity: () => void;
  skipCreateCommunity: () => Promise<void>;
};

export const CommunityCreating: React.FC<CommunityCreatingPropsT> = ({onCreateCommunity, skipCreateCommunity}) => {
  const {t} = useTranslation();
  return (
    <InnerAuth>
      <TitleAuth>{t('auth:communityCreating.title')}</TitleAuth>
      <SubtitleWrapper>{t('auth:communityCreating.description')}</SubtitleWrapper>
      <CreatingCommButtons>
        <CCbutton ghost onClick={onCreateCommunity}>
          {t('common:commonButtons.yes')}
        </CCbutton>
        <br />
        <CCbutton ghost onClick={skipCreateCommunity}>
          {t('common:commonButtons.no')}
        </CCbutton>
      </CreatingCommButtons>
    </InnerAuth>
  );
};
