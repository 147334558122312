import styled, {css, StyledProps} from 'styled-components';
import {SwitchProps} from './types';

const getBG = ({theme, variant, checked}: StyledProps<SwitchProps>) => {
  switch (variant) {
    case 'primary':
      return checked ? theme.palette.background.quinary : 'transparent';
    default:
      return checked ? theme.palette.background.quinary : 'transparent';
  }
};

const color = ({theme, variant, checked}: StyledProps<SwitchProps>) => {
  switch (variant) {
    case 'primary':
      return checked ? theme.palette.text.primary : theme.palette.text.octonary;
    default:
      return checked ? theme.palette.text.primary : theme.palette.text.octonary;
  }
};

const styles = css`
  cursor: pointer;
  width: max-content;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  border: 1.5px solid ${({theme}) => theme.palette.background.tertiary};
  border-radius: 25px;
  transition: background-color 0.5s ease;
  background-color: transparent;
  padding: 5px 7px;
`;

export const Wrapper = styled.label<SwitchProps>`
  ${styles};
  width: ${({width}) => width || 'max-content'};
`;

export const Text = styled.div.attrs({className: 'switch-btn__text'})<
  {checked: boolean; variant: 'primary'} & StyledProps<any>
>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({theme}) => theme.font.primary.semiBold.fontFamily};
  font-weight: ${({theme}) => theme.font.primary.semiBold.fontWeight};
  font-size: 12px;
  line-height: 150%;
  color: ${color};
  white-space: nowrap;
  padding: 5px 20px;
  flex-grow: 0.5;
  background-color: ${getBG};
  border-radius: 25px;
`;

export const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
`;

export const SwitchBaseRoot = styled.div`
  outline: none;
  overflow: hidden;
  // will-change: transform: Fix safari overflow hidden with border-radius
  // credits https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  will-change: transform;
  width: min-content;
`;
