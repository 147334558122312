import React from 'react';
import {SectionWrapper} from '../commonStyles';
import {PayoutsTable} from './components/table';
import {CardsRow, PayoutsWrapper, TableWrapper} from './styles';
import {Pagination} from '../../../ui-kit/Pagination';
import {CommunityLabel} from '../../Listings/CommunityLabel';
import {CommunityStateType} from '../../../states/community';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {PersonCard} from '../../People/PersonCard/PersonCard';
import {User} from '../../../queries/types/user';
import {PayoutsItem} from '../../../types/settings';
import {ContentLoader} from '../../../ui-kit/Loader/ContentLoader';
import {Card} from '../../../ui-kit/Cards';
import {useTranslation} from 'react-i18next';
import {IGetCurrValue} from '../../../types/common';

interface SettingsPayoutsProps {
  items?: PayoutsItem[];
  // items?: Transaction[];
  community: CommunityStateType;
  user: User;
  itemsCount: number;
  allItemsCount: number;
  isManager?: boolean;
  loading?: boolean;
  totalAmount?: number | null;
  getSignValue: IGetCurrValue;
  adminLabels: Record<string, string>;
}

export const SettingsPayouts: React.FC<SettingsPayoutsProps> = ({
  items,
  itemsCount,
  community,
  user,
  allItemsCount,
  isManager,
  loading,
  totalAmount,
  getSignValue,
  adminLabels,
}) => {
  const {t} = useTranslation();

  return (
    <SectionWrapper $noBorder={true}>
      <PayoutsWrapper>
        {isManager ? (
          <CardsRow>
            <CommunityLabel
              icon={<IconSvg type={'Box'} stroke={'aqua'} />}
              count={allItemsCount}
              community={community}
            />
            <Card title={t('settings:payouts.totalEarned')} value={getSignValue(totalAmount?.toFixed(2) || 0)} />
            <Card title={t('settings:payouts.pendingPayout')} />
          </CardsRow>
        ) : (
          <PersonCard
            id={user?.id}
            objectId={user?.objectId}
            username={user?.username}
            firstName={user?.firstName}
            lastName={user?.lastName}
            isVerified={user?.isVerified}
            status={user?.status}
            Avatar={user?.Avatar}
            Reviews={user?.Reviews}
            listingsCount={itemsCount}
            adminLabels={adminLabels}
          />
        )}
        <TableWrapper>
          <PayoutsTable items={items} getSignValue={getSignValue} />
          {!items?.length && loading && <ContentLoader padding={'10px'} />}
          <Pagination
            count={itemsCount || 0}
            t={t}
            stateTitleAlias={'common:pagination.state'}
            rowsTitleAlias={'common:pagination.rows'}
          />
        </TableWrapper>
      </PayoutsWrapper>
    </SectionWrapper>
  );
};
