import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageWrapper} from './styles';
import {makeSeoKeywords} from '../../../helpers/common';
import {Seo} from '../../SEO';
import {TypeSteps} from '../../../types/resident';

type PageProps = {
  step: TypeSteps;
  dataSteps: {[key: string]: {code: number; title: string; link: string}};
};

export const PageCreateResident: React.FC<PageProps> = ({children}) => {
  const {t} = useTranslation();
  const seoTitle = t('common:seoTitle', {
    text: t('common:seo.create.resident'),
  });
  const seoKeywords = makeSeoKeywords([t('common:project.name'), t('common:seo.create.resident')]);

  return (
    <PageWrapper>
      <Seo title={seoTitle} keywords={seoKeywords} />
      {/*<HeaderPage>*/}
      {/*  <Breadcrumbs dataSteps={dataSteps} step={step} />*/}
      {/*</HeaderPage>*/}
      {children}
    </PageWrapper>
  );
};
