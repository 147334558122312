import {Handler, IValidator, Message} from './types';
import {anyObject} from '../../types/helpers';

export class Validator<T extends anyObject> implements IValidator<T> {
  public static of<T extends anyObject>(validator: Handler<T>, message?: Message): Validator<T> {
    return new Validator(validator, message);
  }

  constructor(private $validate: Handler<T>, public message?: Message) {}

  public validate(value: T[keyof T], data: T) {
    return this.$validate(value, data);
  }
}
