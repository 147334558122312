import React from 'react';

import {StyledSvgIconProps} from '../Icon/Svg/types';
import {NoteWrapper, NoteIconWrapper, NoteIcon, NoteText} from './styles';

export const Note: React.FC<{
  iconType?: StyledSvgIconProps['type'];
  text?: string;
}> = ({iconType, text}) => {
  return (
    <NoteWrapper>
      <NoteIconWrapper>
        <NoteIcon type={iconType} />
      </NoteIconWrapper>
      <NoteText>{text}</NoteText>
    </NoteWrapper>
  );
};
