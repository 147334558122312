import React, {useEffect} from 'react';
import {AuthField, AuthTab, CreateProfileSteps, PhoneVerifySteps, TypeCommunity} from '../../types/auth';
import {useHistory, useParams} from 'react-router-dom';
import {SignUp} from '../../components/Auth/SignUp';
import {getAuthPathUser} from '../../helpers/auth';
import {useAutoAddToCommunity, useIsAuthenticated, useSetAutoAddress, useSignUp} from '../../hooks/auth';
import {route} from '../../constants/routes';
import {useRecoilState, useRecoilValue} from 'recoil';
import {aliasCommunity, inviteToCommunityState, skipParams} from '../../states/community';
import {useLinks} from '../../hooks/common';
import {CommunityOptionsFields} from '../../types/community';

export const SignUpContainer = () => {
  const {push} = useHistory();
  const {getLink} = useLinks();
  const isAuth = useIsAuthenticated();
  const {user} = useParams<{user: TypeCommunity}>();
  const [aliasedComm] = useRecoilState(aliasCommunity);
  const path = getAuthPathUser(AuthTab.logIn, user);
  const {addByKey} = useAutoAddToCommunity();
  const [{skipPhone: skipPhoneValue, skipAddress: skipAddressValue, autoAddKey}] = useRecoilState(skipParams);
  const inviteData = useRecoilValue(inviteToCommunityState);
  const skipAddress = skipAddressValue || aliasedComm?.options?.[CommunityOptionsFields.requireAddress] === false;
  const skipPhone = skipPhoneValue || aliasedComm?.options?.[CommunityOptionsFields.requirePhone] === false;
  const successPath =
    user === TypeCommunity.manager
      ? getLink(route.profileCreateManager.path)
      : getLink(route.profileCreateResident.get({steps: CreateProfileSteps.PersonInterests}));

  const onSuccess = () => {
    if (aliasedComm?.objectId === autoAddKey) addByKey(autoAddKey);
    push(successPath);
  };

  const {submitAutoAddress} = useSetAutoAddress({
    skipAddress,
    locationId: aliasedComm?.city?.objectId,
    initialFromState: {address: aliasedComm?.address, zip: aliasedComm?.zip},
  });

  const dataAuth = useSignUp({
    initialState: {
      typeCommunity: user,
      [AuthField.email]: inviteData?.inviteEmail,
      [AuthField.status]: user === TypeCommunity.resident ? 'new' : 'active',
    },
    onSuccess: async (id) => {
      await submitAutoAddress(id);
      skipPhone
        ? skipAddress
          ? handleAddressSubmit()
          : push(getLink(route.address.get({user: user})))
        : push(getLink(route.verifyPhone.get({steps: PhoneVerifySteps.number, user: user})));
    },
  });

  const skipFound = aliasedComm?.objectId || user === TypeCommunity.manager;

  function handleAddressSubmit() {
    return skipFound ? onSuccess() : push(getLink(route.communityFound.get({user: user})));
  }
  useEffect(() => {
    if (isAuth) push(getLink(route.pageUser.path));
  }, []);
  return (
    <>
      <SignUp typeCommunity={user} linkUrl={path} dataAuth={dataAuth} />
    </>
  );
};
