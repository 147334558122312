import React, {useState} from 'react';
import {nanoid} from 'nanoid';
import {useTranslation} from 'react-i18next';

import {
  Br,
  ButtonWrapper,
  // CancelButton,
  CloseButton,
  InfoPartFormWrapper,
  ModalAdressWrapper,
  OkButtonAddress,
  SettingsInfoFormWrapper,
  Title,
} from './styles';
import {AdditionalAddressFields, CommunityType} from '../../../types/settings';
import {CleaveInput, Input} from '../../../ui-kit/Form/Input';
import {IconSvg} from '../../../ui-kit/Icon/Svg';
import {AddAdressProfileSchema} from '../schemas';

import {useUpdateAddressCommForm} from '../../../hooks/settings';

interface Props {
  community?: Partial<CommunityType> | null;
  title?: string;
  okText?: string;
  // cancelText?: string;
  onClose?: () => void | Promise<any>;
  itemId?: number;
  zip?: string;
  unit?: string;
  address?: string;
}

export const ConfirmationAdress: React.FC<Props> = ({
  community,
  title,
  okText,
  // cancelText,
  onClose,
  itemId,
  zip,
  unit,
  address,
}) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const idNano = nanoid();
  const profileInit: AdditionalAddressFields = {
    zip: zip || '',
    unit: unit || '',
    address: address || '',
    id: community?.id,
    itemId: itemId,
    idCard: idNano,
  };
  const closeForm = async () => {
    await onClose?.();
  };

  const formProfile = useUpdateAddressCommForm({
    validationSchema: AddAdressProfileSchema,
    initialState: profileInit,
    onSuccess: () => {
      closeForm();
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    formProfile.onSubmit();
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formProfile.onChange({name: e.target.name, value: e.target.value});
  };

  return (
    <ModalAdressWrapper>
      <Title size={20} weight={'medium'} color={'octonary'}>
        {title}
      </Title>
      <Br indent={14} />

      <Br indent={13} mobileIndent={24} />
      <SettingsInfoFormWrapper>
        <InfoPartFormWrapper>
          <Input
            $isError={Boolean(formProfile.error.address)}
            label={t('settings:community.address.address')}
            name={'address'}
            type={'text'}
            value={formProfile.values.address}
            onChange={handleChange}
          />

          <CleaveInput
            options={{
              blocks: [3, 3],
              uppercase: true,
            }}
            $isError={Boolean(formProfile.error.zip)}
            label={t('settings:community.address.zip')}
            name={'zip'}
            type={'text'}
            value={formProfile.values.zip}
            onChange={handleChange}
          />

          <CleaveInput
            options={{
              blocks: [],
            }}
            $isError={Boolean(formProfile.error.unit)}
            label={t('settings:community.address.NoOfUnits')}
            name={'unit'}
            type={'text'}
            value={formProfile.values.unit}
            onChange={handleChange}
          />
        </InfoPartFormWrapper>
      </SettingsInfoFormWrapper>

      <Br indent={20} mobileIndent={18} />

      <ButtonWrapper>
        {okText && (
          <OkButtonAddress variant={'primary'} onClick={handleSubmit} loading={loading} width={'240px'}>
            {okText}
          </OkButtonAddress>
        )}
        {/* {cancelText && (
          <CancelButton variant={'danger'} onClick={onClose}>
            {cancelText}
          </CancelButton>
        )} */}
      </ButtonWrapper>

      <CloseButton onClick={closeForm}>
        <IconSvg type={'close'} viewBox={'0 0 20 20'} />
      </CloseButton>
    </ModalAdressWrapper>
  );
};
