import React, {useRef} from 'react';
import {Field, Label, StyledTextArea} from './styles';

export interface TextAreaProps {
  name?: string;
  label?: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  className?: string;
  rows?: number;
  resize?: boolean;
  $isError?: boolean;
}

export const TextArea: React.FC<TextAreaProps & JSX.IntrinsicElements['textarea']> = ({
  className,
  disabled,
  label,
  name,
  onChange,
  placeholder,
  required,
  value,
  rows,
  resize,
  $isError,
  ...props
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const internalValue = typeof value === 'string' ? value : '';

  const internalRows = rows || 4;

  return (
    <Field $isError={$isError} className={className} rows={internalRows}>
      {label && <Label $isError={$isError}>{label}</Label>}
      <StyledTextArea
        {...props}
        $isError={$isError}
        disabled={disabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        required={Boolean(required)}
        resize={Boolean(resize)}
        rows={internalRows}
        value={internalValue}
      />
    </Field>
  );
};
