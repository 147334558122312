import {gql} from '@apollo/client';
import {Connection} from './types/parse';
import {SubscrPayment, Transaction} from './types/transactions';
import {
  GQLSubscrPaymentOrder,
  GQLSubscrPaymentWhereInput,
  GQLTransactionOrder,
  GQLTransactionWhereInput,
} from '../graphql.schema';
import {SubscrPaymentFragment, TransactionFragment} from './fragments/transactions';

export interface GetTransactionsRequest {
  where?: GQLTransactionWhereInput;
  order?: GQLTransactionOrder;
  skip?: number;
  first?: number;
}

export interface GetTransactionsResponse {
  transactions: Connection<Transaction>;
}

export const GetTransactions = gql`
  query getTransactions($where: TransactionWhereInput, $order: [TransactionOrder!], $skip: Int, $first: Int) {
    transactions(where: $where, order: $order, skip: $skip, first: $first) {
      count
      edges {
        node {
          ...TransactionFragment
        }
      }
    }
  }

  ${TransactionFragment}
`;

export const GetTransactionsInfo = gql`
  query getTransactions($where: TransactionWhereInput, $order: [TransactionOrder!], $skip: Int, $first: Int) {
    transactions(where: $where, order: $order, skip: $skip, first: $first) {
      count
      edges {
        node {
          objectId
          type
          amount
        }
      }
    }
  }
`;

export interface GetSubscrPaymentsRequest {
  where?: GQLSubscrPaymentWhereInput;
  order?: GQLSubscrPaymentOrder;
  skip?: number;
  first?: number;
}

export interface GetSubscrPaymentsResponse {
  subscrPayments: Connection<SubscrPayment>;
}

export const GetSubscrPayments = gql`
  query getSubscrPayments($where: SubscrPaymentWhereInput, $order: [SubscrPaymentOrder!], $skip: Int, $first: Int) {
    subscrPayments(where: $where, order: $order, skip: $skip, first: $first) {
      edges {
        node {
          ...SubscrPaymentFragment
        }
      }
    }
  }

  ${SubscrPaymentFragment}
`;
