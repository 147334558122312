import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  CloseButton,
  HeaderMainCard,
  HeaderSideCard,
  InnerSideCard,
  MemberCardWrapper,
  MembersWrapper,
  SearchWrapper,
  SubInnerHeaderCard,
  TitleHeaderCard,
} from './styles';
import {Media} from '../../ui-kit/theme';
import {HiddenElement} from '../common/Hidden/HiddenElement';
import {SearchInput} from '../../ui-kit/Form/Input/SearchInput';
import {IconSvg} from '../../ui-kit/Icon/Svg';
import {ContactSkeleton} from '../common/Skeletons/PeopleSkeleton';
import {InfiniteScroll} from '../common/InfiniteScroll';
import {MemberT} from '../../types/people';
import {Avatar} from '../../ui-kit/Avatar/Avatar';
import {Text} from '../../ui-kit/Typography/Text';

type TProps = {
  value: string;
  onChange: (value: string) => void;
  setIsSearching: (value: boolean) => void;
  members: MemberT[];
  forwardToUser: (id: string) => void;
  hasMore: boolean;
  fetchMore: () => void;
  clearSearchText: () => void;
};

export const SearchingContact: React.FC<TProps> = ({
  value,
  onChange,
  setIsSearching,
  members,
  forwardToUser,
  hasMore,
  fetchMore,
  clearSearchText,
}) => {
  const {t} = useTranslation();
  const onClear = () => clearSearchText();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event?.target?.value);

  const handleSetSearching = () => {
    onClear();
    setIsSearching(false);
  };

  const handleFetchMore = () => fetchMore();

  const ref = useRef<HTMLDivElement | null>(null);

  const handleGetScrollParent = () => {
    return ref.current;
  };

  return (
    <InnerSideCard>
      <HiddenElement mq={Media.up.m}>
        <HeaderMainCard>
          <SubInnerHeaderCard>
            <Button type={'button'} onClick={handleSetSearching}>
              <IconSvg type={'arrow-left'} height={'24px'} width={'24px'} viewBox="0 0 24 24" stroke={'darkgray'} />
            </Button>
            <TitleHeaderCard>{t('messages:emptyState.chat.new')}</TitleHeaderCard>
          </SubInnerHeaderCard>
        </HeaderMainCard>
      </HiddenElement>
      <HiddenElement mq={Media.down.m}>
        <HeaderSideCard>
          <TitleHeaderCard>{t('messages:messaging')}</TitleHeaderCard>
          <CloseButton onClick={handleSetSearching}>
            <IconSvg type={'close-3'} />
          </CloseButton>
        </HeaderSideCard>
      </HiddenElement>
      <SearchWrapper>
        <SearchInput
          placeHolder={t('messages:emptyState.chat.placeholder')}
          value={value}
          onChange={handleChange}
          canClear={true}
          onClear={onClear}
        />
      </SearchWrapper>
      <MembersWrapper ref={ref}>
        <InfiniteScroll
          pageStart={0}
          loadMore={handleFetchMore}
          loader={<ContactSkeleton key={0} />}
          initialLoad={false}
          useWindow={true}
          getScrollParent={handleGetScrollParent}
          hasMore={hasMore}
          threshold={1000}>
          {members.map((item) => {
            return (
              <MemberCardWrapper key={item?.objectId} onClick={() => forwardToUser(item?.objectId || '')}>
                <Avatar src={item?.Avatar?.file?.url} size={'xs'} />
                <Text>
                  {item?.firstName} {item?.firstLetter || item?.lastName}
                </Text>
              </MemberCardWrapper>
            );
          })}
        </InfiniteScroll>
      </MembersWrapper>
    </InnerSideCard>
  );
};
