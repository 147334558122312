import React from 'react';
import {useTranslation} from 'react-i18next';

import {correctTranslate} from '../../../../helpers/common';
import {TCreditCard} from '../../../../queries/types/payments';
import {TOptions} from '../../../../types/community';
import {OnChange, OnCleaveInputChange} from '../../../../types/helpers';
import {PaymentField} from '../../../../types/payment';
import {Button} from '../../../../ui-kit/Button/Button';
import {CleaveInput, Input} from '../../../../ui-kit/Form/Input';
import {Select} from '../../../../ui-kit/Form/Select';
import {IconImage} from '../../../../ui-kit/Icon/Image';
import {LottieLoader} from '../../../Loader/LottieLoader';
import {
  BoxCardDescr,
  BoxCardTitle,
  ErrorMessage,
  InnerBoxCard,
  InnerCardsImage,
  InnerItemsForm,
  ProfileForm,
} from '../styles';

import {TAddCardReturn} from '../../../../hooks/payments';

interface AddCreditCardProps {
  cardData: TAddCardReturn;
  options?: TOptions[];
}

export const AddCreditCard: React.FC<AddCreditCardProps> = ({cardData, options}) => {
  const {t} = useTranslation();
  const {values, onChange, onSubmit, error, loading} = cardData;
  const errorMsg = correctTranslate({
    text: t(error?.message || ''),
    defaultText: error?.message,
  });

  const handleChange = ({target}: OnChange<keyof TCreditCard>) => {
    return onChange({name: target.name, value: target.value});
  };

  const handleSelect = (name: keyof TCreditCard) => (data: TOptions[]) => {
    onChange({name: name, value: data[0]?.label || ''});
  };

  const handleCleaveChange = ({target}: OnCleaveInputChange<keyof TCreditCard>) => {
    let value = target.rawValue;

    if (target.name === 'expiryDate') {
      value = target.value;
    }

    return onChange({name: target.name, value: value});
  };
  const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const handleChangeCvc = ({target}: OnChange<keyof TCreditCard>) => {
    const value = target.value;
    const lastChar = value.length > 1 ? value[value.length - 1] : value;

    if (value.length > 3 || (!nums.some((n) => lastChar === n.toString()) && value.length >= 1)) return;

    return onChange({name: target.name, value});
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <InnerBoxCard>
      <BoxCardTitle>{t('community:payment.titleCard')}</BoxCardTitle>
      <BoxCardDescr>{t('community:payment.descr.titleCard')}</BoxCardDescr>
      <InnerCardsImage>
        <IconImage type={'visa'} width={'32px'} height={'21px'} />
        <IconImage type={'mastercard'} width={'32px'} height={'21px'} />
        <IconImage type={'amex'} width={'32px'} height={'21px'} />
      </InnerCardsImage>
      <ProfileForm name={'form-payment'} onSubmit={handleSubmit}>
        <InnerItemsForm>
          <Input
            $isError={Boolean(error.firstName)}
            value={values.firstName}
            onChange={handleChange}
            type={'text'}
            name={PaymentField.firstName}
            label={t('forms:firstName')}
          />
          <Input
            $isError={Boolean(error.lastName)}
            value={values.lastName}
            onChange={handleChange}
            type={'text'}
            name={PaymentField.lastName}
            label={t('forms:lastName')}
          />
        </InnerItemsForm>
        <CleaveInput
          $isError={Boolean(error.cardNumber)}
          onChange={handleCleaveChange}
          value={values.cardNumber}
          label={t('forms:cardNumber')}
          options={{creditCard: true}}
          name={PaymentField.cardNumber}
        />
        <InnerItemsForm>
          <CleaveInput
            $isError={Boolean(error.expiryDate)}
            onChange={handleCleaveChange}
            value={values.expiryDate}
            label={t('forms:expiration')}
            options={{
              date: true,
              datePattern: ['m', 'y'],
            }}
            name={PaymentField.expiryDate}
          />
          <Input
            $isError={Boolean(error.cvc)}
            value={values.cvc}
            type={'password'}
            onChange={handleChangeCvc}
            label={t('forms:cvc')}
            name={PaymentField.cvc}
            autoComplete={'cc-cvc'}
          />
        </InnerItemsForm>
        <InnerItemsForm>
          <Select
            $isError={Boolean(error.country)}
            width={'50%'}
            name={PaymentField.country}
            label={t('forms:country')}
            values={options?.filter((it) => it.label === values.country)}
            options={options}
            onChange={handleSelect(PaymentField.country)}
          />
          <Input
            value={values.zipCode}
            onChange={handleChange}
            $isError={Boolean(error.zipCode)}
            name={PaymentField.zipCode}
            label={t('forms:zip')}
          />
        </InnerItemsForm>
        <ErrorMessage open={!!error?.message}>{errorMsg}</ErrorMessage>
        <Button type={'submit'} loading={loading}>
          {t('community:payment.button.addCard')}
        </Button>
      </ProfileForm>
      <LottieLoader $isVisible={loading} allScreen={true} />
    </InnerBoxCard>
  );
};
