import {Profile} from './profile';
import {Schema} from '../helpers/FormValidation/Schema';
import {CommunityAdmin, JoinRequest, Subscription} from '../queries/types/community';
import {Item} from '../queries/types/item';
import {Location} from '../queries/types/locations';
import {User} from '../queries/types/user';

import {FormErrorFields} from '../hooks/error';

export type FormValue = NonNullable<Profile[keyof Profile]>;
export interface FormOptions<T> {
  initialState?: Partial<T>;
  validationSchema?: Schema<Partial<T>>;
  onSuccess?: () => void;
  loading?: boolean;
}

export interface FormType<TValues, TFormValue = NonNullable<TValues[keyof TValues]>> {
  onSubmit: (id?: string, skip?: boolean) => Promise<boolean> | undefined;
  loading?: boolean;
  error: FormErrorFields<TValues>;
  values: Partial<TValues>;
  success: string | null;
  onChange: (next: {name: string; value: TFormValue; isOptions?: boolean}) => void;
  setError: (error: Error | null) => void;
}

export interface FormEditType {
  isEditing: boolean;
  handleEditStart: () => void;
  handleEditEnd: () => void;
}

export interface ContactsFormValues {
  email: string;
  phone: string;
  aptSuite: string;
  address: string;
  zip: string;
  Location: {
    link?: string;
  };
}

export interface TInterestsRecmndsValues {
  personInterests: Profile['personInterests'];
  recommendations: Profile['recommendations'];
}

export interface AccountFormValues {
  username: string;
  email: string;
  phone: string;
}

export interface ProfileFormValues {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate?: Date;
  username: string;
  bio: string;
  languages: string;
  study: string;
  work: string;
  occupation: string;
}

export interface CommunityProfileValues {
  id: string;
  zip: string;
  units: string;
  buildings: string;
  address: string;
  name: string;
  descr: string;
  alias: string;
  type?: string;
  Location: {
    link?: string;
  };
}

export interface AdditionalAddressFields {
  id?: string;
  itemId?: number;
  zip: string;
  unit: string;
  address: string;
  idCard: string;
}

export interface CommunityAdminValues {
  id: string;
  accessType: string;
  listingApproval: string;
  allowChat?: boolean;
  allowEvents?: boolean;
  options: {
    passCode?: boolean;
    requireAddress?: boolean;
    requirePhone?: boolean;
    requireListing?: boolean;
  };
}

interface addAddressSettingProps {
  id: string;
  zip: string;
  unit: string;
  address: string;
  itemId?: number;
}

interface addAddressSettingContainer {
  value: addAddressSettingProps;
  __typename: string;
}

export interface CommunityType {
  zip: string;
  units: string;
  address_add: addAddressSettingContainer[];
  zip_add: string[];
  buildings: string;
  listingApproval: string;
  Owner: User;
  address: string;
  Subscr: Subscription;
  Location: Location;
  isVerified: boolean;
  invitation: string;
  avatar: string;
  countMembers: number;
  Residents: User[];
  accessType: string;
  descr: string;
  createdAt: Date;
  name: string;
  alias: string;
  Items: Item[];
  JoinRequests: JoinRequest[];
  id: string;
  Admins: CommunityAdmin[];
  objectId: string;
  allowChat?: boolean;
  allowEvents?: boolean;
  options: Record<string, boolean>;
  type?: string;
}

export type CommunityValidation = CommunityType & {Location: {link?: string}};
export type AddAdressValidation = addAddressSettingProps;

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface LabelValuePairBolean {
  label: string;
  value: boolean;
}

export interface PayoutsItem extends Partial<Item> {
  objectId: string;
  planFee?: string;
  status?: string;
  calcIncome?: string | number;
}

export interface TAdminsPermissions {
  editCommunity: boolean;
  manageAdmin: boolean;
  manageLoop: boolean;
  managePeople: boolean;
  manageListing: boolean;
  manageCompany: boolean;
  manageAmenity: boolean;
  postAsCommunity: boolean;
  postNotice: boolean;
  manageInvitation: boolean;
  manageEvent: boolean;
  manageReport: boolean;
  manageDocument: boolean;
  dashboardAccess: boolean;
  manageHelpDesk: boolean;
  manageGroups: boolean;
}

export interface TUserData extends LabelValuePair {
  userLabel?: string;
  adminId?: string;
  permissions?: Array<keyof TAdminsPermissions>;
}

export enum PermissionsList {
  dashboardAccess = 'dashboardAccess',
  manageListing = 'manageListing',
  manageCompany = 'manageCompany',
  manageInvitation = 'manageInvitation',
  editCommunity = 'editCommunity',
  manageDocument = 'manageDocument',
  manageHelpDesk = 'manageHelpDesk',
  resident = 'resident',
  manageReport = 'manageReport',
  postAsCommunity = 'postAsCommunity',
  manageAdmin = 'manageAdmin',
  managePeople = 'managePeople',
  manageEvent = 'manageEvent',
  postNotice = 'postNotice',
  manageAmenity = 'manageAmenity',
  manageLoop = 'manageLoop',
  manager = 'manager',
  manageGroups = 'manageGroups',
}
