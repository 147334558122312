import React from 'react';
import {GenerateListing} from '../../components/Listings/GenerateListing';
import {useCreateItem, useGenerateItem} from '../../hooks/item';
import {ListingType, PricePeriodType, RewardType} from '../../queries/types/item';
import {route} from '../../constants/routes';
import {useGetCurrencyValue} from '../../hooks/currency';
import {useLinks} from '../../hooks/common';
import {useHistory} from 'react-router-dom';
import {ItemFields} from '../../types/item';
import {analyticsTrackFN} from '../../helpers/account';
import {useViewer} from '../../hooks/user';
import {useRecoilState} from 'recoil';
import {currentCommunity} from '../../states/community';

const GenerateListingContainer: React.FC = () => {
  const {getLink} = useLinks();
  const {push, goBack} = useHistory();
  const viewer = useViewer();
  const [community] = useRecoilState(currentCommunity);
  const {currency} = useGetCurrencyValue();
  const {
    values: generatedData,
    generateListing,
    loadingImage,
    loadingGenerate,
    nextImage,
    deleteImages,
    getImageFromUrl,
  } = useGenerateItem();
  const data = useCreateItem({
    initialState: {
      rewardType: RewardType.fee,
      listingType: ListingType.service,
      pricePeriod: PricePeriodType.hour,
      category: '',
      images: [],
    },
    currency,
    onSuccess: async (item) => {
      push(getLink(route.listingCreated.get({id: item?.id ?? ''})));
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await data?.onSubmit(generatedData?.listing?.images?.[0]);
  };

  const handleGenerate = async (changed: boolean) => {
    if (!data?.values?.name || loadingGenerate) return;
    const result = await generateListing(data?.values?.name);
    let file: File | undefined = undefined;
    if (result?.images?.[0]) {
      file = await getImageFromUrl(result?.images?.[0]);
      data?.onChange({name: ItemFields.images, value: [{value: file}]});
    }
    data?.setValues({
      ...data?.values,
      descr: result?.descr,
      category: result?.category,
      price: changed ? result?.price : data?.values?.price,
      images: file ? [{value: file}] : undefined,
    });

    analyticsTrackFN('AI Listing Started', {
      listingType: ListingType.service,
      listedFor: RewardType.fee,
      photoCount: 1,
      photoURL: generatedData?.listing?.images?.[0],
      title: data?.values?.name,
      description: result?.descr,
      rate: result?.price,
      currency: currency?.code,
      bookingType: data?.values?.pricePeriod,
      category: result?.category,
      listerName: `${viewer?.firstName} ${viewer?.lastName}`,
      listerEmail: viewer?.email,
      listerId: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type,
    });
  };

  const handleNextFile = async () => {
    let file: File | undefined = undefined;
    if (generatedData?.listing?.images?.[0]) {
      file = await getImageFromUrl(generatedData?.listing?.images?.[0]);
      data?.onChange({name: ItemFields.images, value: [{value: file}]});
    }
    await nextImage(data?.values?.name || '');
  };

  const handleRedirect = () => {
    analyticsTrackFN('AI Listing Cancelled', {
      listingType: ListingType.service,
      listedFor: RewardType.fee,
      photoCount: 1,
      photoURL: generatedData?.listing?.images?.[0],
      title: data?.values?.name,
      description: data?.values?.descr,
      rate: data?.values?.price,
      currency: currency?.code,
      bookingType: data?.values?.pricePeriod,
      category: data?.values?.category,
      listerName: `${viewer?.firstName} ${viewer?.lastName}`,
      listerEmail: viewer?.email,
      listerId: viewer?.objectId,
      communityName: community?.name,
      communityId: community?.objectId,
      communityType: community?.type,
    });
    push(getLink(route.loop.path));
  };

  const cur = {sign: currency?.sign, code: currency?.code};

  return (
    <GenerateListing
      data={data}
      generateState={generatedData}
      generate={handleGenerate}
      generateLoading={loadingGenerate}
      imageLoading={loadingImage}
      currency={cur}
      redirect={handleRedirect}
      nextImage={handleNextFile}
      deleteImages={deleteImages}
      submit={handleSubmit}
      goBack={goBack}
    />
  );
};

export default GenerateListingContainer;
