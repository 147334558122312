import React, {useEffect, useState} from 'react';
import {Redirect, useLocation, useParams} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {JoinToCommunity} from '../../components/Account/JoinCommunity';
import {LottieLoader} from '../../components/Loader/LottieLoader';
import {AccessType} from '../../constants/community';
import {PlanOptionsValues} from '../../constants/plan';
import {route} from '../../constants/routes';
import {getInviteData} from '../../helpers/common';
import {hasPlanOption} from '../../helpers/community';
import {User} from '../../queries/types/user';
import {aliasPart, currentCommunity, inviteToCommunityState, skipParams} from '../../states/community';
import {TCommunity} from '../../types/community';
import {JoinOptions} from '../Account/Create/Resident/JoinToCommunity';

import {useAutoAddToCommunity} from '../../hooks/auth';
import {useLinks} from '../../hooks/common';
import {useGetCommReqs, useJoinToCommunity} from '../../hooks/community';
import {useGetMembersAvatar} from '../../hooks/people';
import {useUserJoinedCommunity, useViewer} from '../../hooks/user';

type PropsT = {
  aliasedComm: TCommunity;
  urlAlias: string | null;
  isAuth: boolean;
  signUpPath: string;
};

enum JoinRequestTypes {
  Approved = 'approved',
  Pending = 'pending',
}

export const PageJoinCommunity: React.FC<PropsT> = ({aliasedComm, urlAlias, signUpPath}) => {
  const {getLink} = useLinks();
  const {id: urlInviteId} = useParams<{id?: string}>();
  const [currentComm] = useRecoilState(currentCommunity);
  const setInvite = useSetRecoilState(inviteToCommunityState);
  const viewer = useViewer();
  const viewerId = viewer?.id;
  const location = useLocation();
  const setAlias = useSetRecoilState(aliasPart);
  const setUrlParams = useSetRecoilState(skipParams);
  const [reqSend, setReqSend] = useState(false);
  const {getMembersAvatars, loading} = useGetMembersAvatar();
  const [members, setMembers] = useState<User[]>([]);
  const isCurrentCommunity = currentComm?.objectId === aliasedComm?.objectId || currentComm?.id === aliasedComm?.id;
  const isJoinedCommunity = useUserJoinedCommunity(viewerId, aliasedComm?.objectId || aliasedComm?.id);
  const {addByKey, loading: loadingAddByKey} = useAutoAddToCommunity();
  const params = useJoinToCommunity({
    initialState: {communityId: aliasedComm?.objectId, userId: viewerId},
    isPrivate: aliasedComm?.accessType === AccessType.private.toLowerCase(),
  });

  const {
    data: requests,
    refetch: reqRefetch,
    loading: commLoading,
  } = useGetCommReqs({
    commId: aliasedComm?.objectId,
    userId: viewerId,
    ssr: true,
  });
  let joinRequestStatus = requests?.[0]?.status;
  if (isJoinedCommunity) joinRequestStatus = JoinRequestTypes.Approved;
  useEffect(() => {
    if (urlAlias) setAlias(urlAlias);
  }, [urlAlias]);

  useEffect(() => {
    setUrlParams((prev) => ({...prev, autoAddress: true}));
    const getMembers = async () => {
      const result = await getMembersAvatars({
        variables: {
          communityId: aliasedComm?.objectId,
        },
      });
      setMembers(result?.data?.getResidentsAvatar?.users || []);
    };
    getMembers();
  }, []);

  useEffect(() => {
    if (!urlInviteId || urlInviteId == 'auth') return;
    setInvite({
      communityId: aliasedComm?.objectId,
      ...getInviteData(urlInviteId),
    });
    setUrlParams((prev) => ({
      ...prev,
      skipPhone: true,
    }));
  }, [urlInviteId]);
  if (urlInviteId && urlAlias && getInviteData(urlInviteId).inviteEmail !== viewer?.email && viewer) {
    return <Redirect to={getLink(route.pageUser.path)} />;
  }
  if (
    (location?.pathname === `/${urlAlias}` || location?.pathname === `/${urlAlias}/` || !!urlInviteId) &&
    (isCurrentCommunity || isJoinedCommunity) &&
    urlAlias
  ) {
    const hasLoop = !!hasPlanOption(currentComm?.Subscr?.PaymentPlan.options, PlanOptionsValues.communityFeed);
    setAlias(undefined);
    return <Redirect to={hasLoop ? getLink(route.loop.path, urlAlias) : getLink(route.pageUser.path)} />;
  }

  if (loading || params?.loading || commLoading) return <LottieLoader allScreen={true} $isVisible={true} />;

  return (
    <JoinToCommunity
      loadingAddByKey={loadingAddByKey}
      addByKey={addByKey}
      members={members}
      options={JoinOptions}
      name={aliasedComm?.name}
      avatar={aliasedComm?.avatar}
      descr={aliasedComm?.descr}
      dataJoin={params}
      signUpPath={signUpPath}
      accessType={aliasedComm?.accessType}
      userId={viewerId}
      communityId={aliasedComm?.objectId}
      refetchRequests={reqRefetch}
      joinRequestStatus={joinRequestStatus}
      isCurrentCommunity={isCurrentCommunity}
      joinFromLink={true}
      reqSend={reqSend}
      setReqSend={setReqSend}
    />
  );
};
