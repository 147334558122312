import {NotificationsEventTypes} from '../types/notifications';
import {route} from './routes';

export enum termsLinks {
  Service = 'https://hangeh.com/terms',
  PrivacyPolicy = 'https://hangeh.com/privacy',
  NondiscrPolicy = 'https://hangeh.notion.site/Nondiscrimination-Policy-bfdf1f57dd5f4a99af8e2a2773ef2038',
}

export const helpCentreLinks = {
  guide: 'https://www.notion.so/hangeh/Help-Support-Guide-708f8b5f8a954c32b768226080531428',
  sendMessage: '',
  feedback: 'https://dwjntrqdlob.typeform.com/to/vv44Mtpm',
  reportBug: '',
  whatsNew: 'https://www.notion.so/hangeh/What-s-New-7a8689bff22940fd93e3973766fdd222',
  terms: 'https://hangeh.notion.site/Terms-Privacy-8593ae129a1a498ca7ec71df2404552b',
};

export const notificationRoutes = {
  [NotificationsEventTypes.joinRequest]: route.people.path,
  [NotificationsEventTypes.newComment]: '',
  [NotificationsEventTypes.newLike]: '',
  [NotificationsEventTypes.userReview]: '',
  [NotificationsEventTypes.newListing]: route.listings.path,
  [NotificationsEventTypes.listingUpdate]: route.listings.path,
  [NotificationsEventTypes.approveRequest]: route.loop.path,
  [NotificationsEventTypes.itemReview]: '',
  [NotificationsEventTypes.itemView]: '',
  [NotificationsEventTypes.newPost]: '',
  [NotificationsEventTypes.newResident]: '',
  [NotificationsEventTypes.orderRentAccepted]: '',
  [NotificationsEventTypes.orderRentRequest]: '',
  [NotificationsEventTypes.orderSellAccepted]: '',
  [NotificationsEventTypes.orderSellRequest]: '',
  [NotificationsEventTypes.orderServiceRequest]: '',
  [NotificationsEventTypes.rejectRequest]: route.searchCommunityResident.path,
  [NotificationsEventTypes.userView]: '',
  [NotificationsEventTypes.transactionCredit]: '',
  [NotificationsEventTypes.transactionInvoice]: '',
  [NotificationsEventTypes.listingPublishRequest]: '',
  [NotificationsEventTypes.newManagerAdminPost]: route.loop.path,
  [NotificationsEventTypes.newMessage]: route.messages.path,
  [NotificationsEventTypes.reportPostListing]: '',
};

export const SuperResidentProgramInfo = 'https://www.hangeh.com/super-resident';
export const hangehCom = 'https://www.hangeh.com/';

export const helpDeskFAQ = 'https://hangeh.notion.site/Social-Marketplace-FAQs-9d0d076e8dda440daa83aaa46c944f93';
