import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainWrapper = styled.main.attrs({className: 'page-content'})`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
`;

export const AppLayout: React.FC = ({children}) => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StyledLayout>
      <MainWrapper>{children}</MainWrapper>
    </StyledLayout>
  );
};
